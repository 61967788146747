import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'
import {TotalNumberOfItemsByStatus} from '../../../incidents-v3/contexts/types/type-of-interfaces'
import {IncidentsCount} from './incidents-count'
import {WrapperOfStatusesCount} from './status-of-incidents-raised.styled'

export function TotalNumberOfIncidentsByStatus({
    totalNumberOfItemsByStatus,
}: {
    totalNumberOfItemsByStatus: TotalNumberOfItemsByStatus
}): JSX.Element {
    return (
        <WrapperOfStatusesCount>
            <IncidentsCount
                incidentsNumber={totalNumberOfItemsByStatus.NEW ?? 0}
                incidentStatus={IncidentStatus.NEW}
            />
            <IncidentsCount
                incidentsNumber={totalNumberOfItemsByStatus.OPEN ?? 0}
                incidentStatus={IncidentStatus.OPEN}
            />
            <IncidentsCount
                incidentsNumber={
                    totalNumberOfItemsByStatus.CLOSED +
                        totalNumberOfItemsByStatus.CLOSED_FALSE_POSITIVE +
                        totalNumberOfItemsByStatus.CLOSED_MONITOR ?? 0
                }
                incidentStatus={IncidentStatus.CLOSED}
            />
        </WrapperOfStatusesCount>
    )
}
