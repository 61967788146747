import {REST} from '../../../..'
import {GuidType} from '../../../../values/generic-type-defintions'

export interface OsInfo {
    node: string
    timestamp: string
    os: string
    build: string
    version: string
}

const OS_INFO_ENDPOINT = '/api/v1/raw-message/os-info'
export function getOsData(assetId: GuidType, setState: (value: OsInfo | null) => void): void {
    REST.get<OsInfo>(`${OS_INFO_ENDPOINT}?node=${assetId}`)
        .then((response) => {
            if (response.status === 200) {
                setState(response.data)
            }
        })
        .catch(() => {
            setState(null)
            // eslint-disable-next-line no-console
            console.error('Could not find osInfo for: ' + assetId)
        })
}
