import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {ContentArea} from '../../general/content-area/content-area.styled'
import {WidgetWrapper} from './widget-wrapper'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import {MetricsWidgetType} from '../contexts/types/metrics-widget-type'
import {SummaryScoreCard} from './summary-score-card'
import {BenchmarkSummary} from './benchmark-summary'
import {FrameworkSummaryTrend} from './framework-summary-trend'
import {FrameworkSummaryTarget} from './framework-summary-target'
import {NoData} from './no-data'

export function PopulatedData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const {metricsWidgetData} = useMetricsWidget()
    const hasData = metricsWidgetData && metricsWidgetData.size != 0
    return (
        <WidgetWrapper gridLayout={gridLayout}>
            <ContentArea gridLayout={gridLayout}>
                {hasData ? <GetWidgetType /> : <NoData />}
            </ContentArea>
        </WidgetWrapper>
    )
}
function GetWidgetType(): JSX.Element {
    const {selectedMetricWidget} = useMetricsWidget()

    switch (selectedMetricWidget) {
        case MetricsWidgetType.BENCHMARK_SUMMARY:
            return <BenchmarkSummary />
        case MetricsWidgetType.TARGET_SUMMARY:
            return <FrameworkSummaryTarget />
        case MetricsWidgetType.TREND_SUMMARY:
            return <FrameworkSummaryTrend />
        case MetricsWidgetType.SCORECARD_SUMMARY:
        default:
            return <SummaryScoreCard />
    }
}
