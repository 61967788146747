import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {hasLocationsSelector} from '../../../../../store/state/locations/selectors'
import {NoDataMessageStyle} from '../_styled/no-data-message.styled'
import {VesselsCardsPopulated} from './vessels-cards-populated'

export function VesselsCardsLoaded(): JSX.Element {
    if (!useTypedSelector(hasLocationsSelector)) {
        return <NoDataMessageStyle>There are no vessels</NoDataMessageStyle>
    }
    return <VesselsCardsPopulated />
}
