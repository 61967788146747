import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styled from './saved-filter-alert-row.styled'
import {AlertDetails} from './alert-details'
import {AlertDetailsEditable} from './alert-details-editable'
import {useDispatch} from 'react-redux'
import {
    resetAlertChanges,
    updateUserSubscription,
    deleteUserSubscription,
    findUserSubscriptions,
} from '../../../../../../store/state/saved-filters/action-creators'
import {useUserManagement} from '../../../../context/use-user-management'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {SavedFilterSubscription} from '../../../../../../store/state/saved-filters/state'
import {newSubscriptionSelector} from '../../../../../../store/state/saved-filters/selectors'
import {isEmailValid} from '../../../../../../utils/Utils'
import {isEqual} from 'lodash'

interface Props {
    subscriptionData: SavedFilterSubscription
}

export function SavedFilterAlertRow({subscriptionData}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {currentSelectedAlertId, toggleAlertDetailEdit} = useUserManagement()
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const edit = currentSelectedAlertId === subscriptionData.identity

    const isValidEmail =
        newSubscription.notificationEmail?.length !== 0
            ? isEmailValid(newSubscription.notificationEmail)
            : true
    const isUpdated =
        (newSubscription.schedule !== undefined &&
            !isEqual(newSubscription.schedule, subscriptionData.schedule)) ||
        (newSubscription.notificationEmail !== undefined &&
            !isEqual(newSubscription.notificationEmail, subscriptionData.notificationEmail)) ||
        (newSubscription.subjectPrefix !== undefined &&
            !isEqual(newSubscription.subjectPrefix, subscriptionData.subjectPrefix))

    const onSaveClickFn = () => {
        toggleAlertDetailEdit(undefined)
        dispatch(
            updateUserSubscription({
                ...subscriptionData,
                schedule: newSubscription.schedule ?? subscriptionData.schedule,
                notificationEmail:
                    newSubscription.notificationEmail ?? subscriptionData.notificationEmail,
                subjectPrefix: newSubscription.subjectPrefix ?? subscriptionData.subjectPrefix,
                userId: subscriptionData.userId,
                searchDefinitionId: subscriptionData.searchDefinitionId,
                templateDefinitionId: subscriptionData.templateDefinitionId,
            }),
        )
        dispatch(findUserSubscriptions(subscriptionData.userId))
    }

    const onCancelClickFn = () => {
        toggleAlertDetailEdit(undefined)
        dispatch(resetAlertChanges())
    }

    const onDeletelClickFn = () => {
        toggleAlertDetailEdit(undefined)
        dispatch(deleteUserSubscription(subscriptionData.identity))
    }

    return (
        <Styled.SavedFilterWrapper>
            <Styled.NameRow>
                <Styled.FilterName>{subscriptionData.searchDefinition.name}</Styled.FilterName>
                <Styled.DeleteButton isDeletable={true} onClick={onDeletelClickFn}>
                    <Icon glyph="Delete" width={13} />
                </Styled.DeleteButton>
            </Styled.NameRow>
            <Styled.Details>
                {!edit ? (
                    <AlertDetails subscriptionData={subscriptionData} />
                ) : (
                    <AlertDetailsEditable subscriptionData={subscriptionData} />
                )}

                {edit ? (
                    <Styled.ButtonsWrapper>
                        <Styled.TextButton
                            disabled={!isUpdated || !isValidEmail}
                            onClick={onSaveClickFn}
                        >
                            Save
                        </Styled.TextButton>
                        <Styled.TextButton onClick={onCancelClickFn}>Cancel</Styled.TextButton>
                    </Styled.ButtonsWrapper>
                ) : (
                    <Styled.TextButton
                        disabled={currentSelectedAlertId !== undefined && !edit}
                        onClick={() => toggleAlertDetailEdit(subscriptionData.identity)}
                    >
                        Edit
                    </Styled.TextButton>
                )}
            </Styled.Details>
        </Styled.SavedFilterWrapper>
    )
}
