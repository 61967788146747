import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {ClearButton} from '../../../../../components/page-filter/components/shared/clear-button/clear-button'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {toggleAllAssetsValue} from '../../../../../store/state/metrics-filter-beta/action-creators'
import * as Styles from './view-all-row.styled'

interface Props {
    state: TriStateButtonState
    viewAllChanged: (newState: TriStateButtonState) => void
    showClearButton: boolean
}

export function ViewAllRow({state, viewAllChanged, showClearButton}: Props): JSX.Element {
    return (
        <Styles.ViewAllCell>
            <TriStateButton
                id="asset-filter-viewall"
                label="View All"
                state={state}
                onClick={viewAllChanged}
            />
            {showClearButton && (
                <ClearButton
                    clearFn={toggleAllAssetsValue}
                    id={`${PageType.METRICS}_filter-bar_clear-view-all-assets`}
                />
            )}
        </Styles.ViewAllCell>
    )
}
