import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {ReactNode, ReactNodeArray} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DetailIconText, DetailIconWrapper} from './_styled/detail-icon.styled'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    titleText: string
    children?: ReactNode | ReactNodeArray
}

export function DisabledDataWidgetWrapper({
    gridLayout,
    titleText,
    children,
}: WidgetWrapperProps): JSX.Element {
    return (
        <Widget>
            <Header gridLayout={gridLayout} id={`${titleText}-widget-header`}>
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout}>{titleText}</TitleText>
                    <DetailIconWrapper>
                        <Icon glyph="Details" height={19} width={19} />
                        <DetailIconText>Details</DetailIconText>
                    </DetailIconWrapper>
                </HeaderContent>
            </Header>
            {children}
        </Widget>
    )
}
