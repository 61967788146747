import * as Styled from './_styled/user-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {DetailsSection} from './details-section/details-section'
import {ReminderSection} from './reminder-section/reminder-section'
import {AddNewButton} from './save-button/add-new-button'
import {ContentWrapper} from '../_styled/policy-details-modal.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'

export function AddNewIncidentPolicyDetails(): JSX.Element | null {
    const {width} = useDimensions()

    return (
        <ContentWrapper width={width}>
            <Styled.Container onClick={(e) => e.stopPropagation()}>
                <DetailPanelHeader text="Add new incident policy" />
                <DetailsSection />
                <ReminderSection />
                <AddNewButton />
            </Styled.Container>
        </ContentWrapper>
    )
}
