export type QueryCancellation = () => void

export const threatScoresWithAlerts: QueryName = 'threatScoresWithAlerts'
export const nodeThreatScores: QueryName = 'nodeThreatScores'
export const nodeThreatScoresByPeerGroup: QueryName = 'nodeThreatScoresByPeerGroup'
export const nodeThreatAlerts: QueryName = 'nodeThreatAlerts'
export const threatAlerts: QueryName = 'threatAlerts'
export const latestEvents: QueryName = 'latestEvents'
export const moreEvents: QueryName = 'moreEvents'
export const ets: QueryName = 'ets'
export const vitals: QueryName = 'vitals'
export const vitalsMetrics: QueryName = 'vitalsMetrics'
export const watchedNodesScores: QueryName = 'watchedNodesScores'
export const watchedNodes: QueryName = 'watchedNodes'
export const toastsThreatAlerts: QueryName = 'toastsThreatAlerts'
export const nodesOverThresholdCounts: QueryName = 'nodesOverThresholdCounts'
export const highValueNodesOverThreshold: QueryName = 'highValueNodesOverThreshold'
export const nodePeerGroupScores: QueryName = 'nodePeerGroupScores'
export const malwareFamilySummary: QueryName = 'malwareFamilySummary'
export const latestEventTimestamps: QueryName = 'latestEventTimestamps'
export const latestMonaAnomalies: QueryName = 'latestMonaAnomalies'
export const incidentsOverview: QueryName = 'incidentsOverview'
export const locationTagsSummary: QueryName = 'locationTagsSummary'
export const locationTagThreatScore: QueryName = 'locationTagThreatScore'

export type QueryName =
    | 'vitals'
    | 'vitalsMetrics'
    | 'ets'
    | 'threatScoresWithAlerts'
    | 'nodeThreatScores'
    | 'nodeThreatAlerts'
    | 'threatAlerts'
    | 'latestEvents'
    | 'moreEvents'
    | 'watchedNodesScores'
    | 'watchedNodes'
    | 'nodesOverThresholdCounts'
    | 'toastsThreatAlerts'
    | 'highValueNodesOverThreshold'
    | 'nodeThreatScoresByPeerGroup'
    | 'nodePeerGroupScores'
    | 'malwareFamilySummary'
    | 'latestEventTimestamps'
    | 'latestMonaAnomalies'
    | 'incidentsOverview'
    | 'locationTagsSummary'
    | 'locationTagThreatScore'

export class QueryManager {
    private readonly queries: Map<string, QueryCancellation> = new Map<string, QueryCancellation>()

    public save(key: string, id: QueryCancellation): void {
        if (this.queries.has(key)) {
            this.cancel(key)
        }
        this.queries.set(key, id)
    }

    public cancel(key: string): void {
        if (this.queries.has(key)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.queries.get(key)!()
        }
        this.queries.delete(key)
    }

    public cancelAll(): void {
        this.queries.forEach((value) => value())
        this.queries.clear()
    }
}
