import * as Styled from './_styled/framework-summary-trend.styled'
import POSITIVE_VECTOR from './_styled/positive-vector-trend.svg'
import NEGATIVE_VECTOR from './_styled/negative-vector-trend.svg'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {SectionText, StatusFilterContainer} from './widget-commonstyling.styled'
import {WidgetIconsSection} from './widget-icons-section'
import {TrendsChangeType} from '../../../../metrics-beta/contexts/types/metrics-summary'
import {useState} from 'react'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import {
    findMetricCategory,
    getAnalysisPeriodDisplayName,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {StringUtils} from '../../../../../utils/Utils'

export function FrameworkSummaryTrend(): JSX.Element {
    const {metricsWidgetData} = useMetricsWidget()

    const trendsProtection = metricsWidgetData?.get(MetricType.PROTECTION)?.trendsChange
    const trendsMaintenance = metricsWidgetData?.get(MetricType.MAINTENANCE)?.trendsChange
    const trendsBehaviour = metricsWidgetData?.get(MetricType.BEHAVIOUR)?.trendsChange

    return (
        <StatusFilterContainer>
            <SectionText>METRICS TRENDS SUMMARY</SectionText>
            <Styled.TrendsWrapper id="framework-trends-wrapper">
                <WidgetIconsSection />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <TrendsChangeScore
                        trendsChangeData={trendsProtection}
                        metricType={MetricType.PROTECTION}
                    />
                    <TrendsChangeScore
                        trendsChangeData={trendsMaintenance}
                        metricType={MetricType.MAINTENANCE}
                    />
                    <TrendsChangeScore
                        trendsChangeData={trendsBehaviour}
                        metricType={MetricType.BEHAVIOUR}
                    />
                </div>
            </Styled.TrendsWrapper>
        </StatusFilterContainer>
    )
}
interface TrendsChangeProps {
    trendsChangeData: TrendsChangeType | undefined
    metricType: MetricType
}
function TrendsChangeScore({trendsChangeData, metricType}: TrendsChangeProps): JSX.Element {
    const [visibleTippy, setVisibleTippy] = useState(false)
    return (
        <Styled.TrendsScoreWrapper
            onMouseEnter={() => {
                setVisibleTippy(true)
            }}
            onMouseLeave={() => {
                setVisibleTippy(false)
            }}
        >
            <Styled.ImgComparison
                src={getTrendsPerformanceComparisionImg(trendsChangeData?.performance ?? 0)}
                metricType="protection"
                id={`trendsPerformanceComparisionImg-${metricType}`}
            />
            <Styled.ScoreSection>
                <Styled.TrendScore metricType={metricType} id={`trendScore-${metricType}`}>
                    <div style={{display: 'flex'}} id={`trendSummaryData-${metricType}`}>
                        {getFormatedPercentNumber(trendsChangeData?.performance ?? 0)}
                        <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                    </div>
                </Styled.TrendScore>
                {visibleTippy && (
                    <TippyContent
                        metricType={metricType}
                        score={getFormatedPercentNumber(trendsChangeData?.performance ?? 0)}
                    />
                )}
            </Styled.ScoreSection>
        </Styled.TrendsScoreWrapper>
    )
}
function getTrendsPerformanceComparisionImg(selectedPerformance: number): string {
    return selectedPerformance >= 0 ? POSITIVE_VECTOR : NEGATIVE_VECTOR
}

function getFormatedPercentNumber(value: number): string {
    const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2)
    const prefix = value > 0 ? '+' : ''
    return `${prefix}${formattedValue}`
}

interface TippyContentProps {
    metricType: MetricType
    score: string
}
function TippyContent({metricType, score}: TippyContentProps): JSX.Element {
    const {selectedAnalysisPeriod} = useMetricsWidget()
    return (
        <Styled.TippyWrapper>
            <div style={{display: 'flex', gap: '5px', fontWeight: 'bold'}}>
                <img
                    src={getMetricTypeIcon(findMetricCategory(metricType))}
                    id={`metricTypeIconTippy-${metricType}-${score}`}
                    width={20}
                    height={20}
                />
                {StringUtils.capitaliseString(metricType)}
            </div>
            <div>Trends change: {score}% of assets OK</div>
            <div> {getAnalysisPeriodDisplayName(selectedAnalysisPeriod)}</div>
        </Styled.TippyWrapper>
    )
}
