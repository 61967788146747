import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {TimestampFilterType, formatTimestampCriteria} from './timestamp-filter'
import {NETWORK_ASSET_AGGREGATION_ENDPOINT} from './vessels-beta-endpoints'
export interface MissingMonitoredAssetsResponse {
    locationId: string
    totalMissingMonitoredAssets: number | undefined
}
export async function buildMissingMonitoredAssetsInventoryAllTypes(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<MissingMonitoredAssetsResponse[]> {
    try {
        const totalAssetsPromise = await REST.post(
            `${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                states: ['monitored'],
                toRelativeLastSeen: formatTimestampCriteria(analysisPeriod),
            },
        )
        const findTotalAssets: {location: string; totalAssets: number}[] =
            totalAssetsPromise?.data as Array<{
                location: string
                totalAssets: number
            }>

        return findTotalAssets?.map((itemToFetch) => {
            return {
                locationId: itemToFetch.location,
                totalMissingMonitoredAssets: itemToFetch?.totalAssets,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<MissingMonitoredAssetsResponse>()
    }
}
