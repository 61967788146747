export interface IncidentType {
    name: string
    displayName: string
    icon: string
    defaultType: boolean
}

export function getMatchingIcon(types: IncidentType[], incidentType: string): string {
    if (!types) {
        return 'DEFAULT'
    }
    const matchingType = types?.find((type) => type.name === incidentType)

    if (!matchingType) {
        return getDefaultIcon(types)
    }

    return matchingType.icon
}

export function getTypeDisplayName(types: IncidentType[], incidentType: string): string {
    if (!types) {
        return 'Default'
    }
    const matchingType = types.find((type) => type.name === incidentType)

    if (!matchingType) {
        return 'Default'
    }

    return matchingType.displayName
}

function getDefaultIcon(types: IncidentType[]): string {
    if (!types) {
        return 'DEFAULT'
    }
    const defaultType = types.find((type) => type.defaultType === true)

    if (!defaultType) {
        return 'DEFAULT'
    }

    return defaultType.icon
}
