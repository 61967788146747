import {MouseEvent} from 'react'
import {Button} from './button.styled'
import {usePagedIncidents} from '../../../../contexts/use-paged-incidents'

export function SaveButton(): JSX.Element | null {
    const {monitorLinkedItems, saveMonitoredItems, incidentModalId} = usePagedIncidents()
    const isChanged = monitorLinkedItems.length > 0

    function onClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        incidentModalId && saveMonitoredItems(monitorLinkedItems, incidentModalId)
    }
    return (
        <Button id="save-changes-button" disabled={!isChanged} onClick={onClick}>
            Close incident with monitoring
        </Button>
    )
}
