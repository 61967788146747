import {useRef} from 'react'
import {BoundsContext} from '../../../../components/bounds-context/bounds-context'
import {ZoomLevelGrid} from './zoom-level-grid'
import {ScrollableContent} from './scrollable-content.styled'
import {ActionBar} from './action-bar/action-bar'

export function PopulatedData(): JSX.Element {
    const layoutRef = useRef(null)
    return (
        <BoundsContext.Provider value={{ref: layoutRef}}>
            <ScrollableContent ref={layoutRef} flexGrow id="scrollable-content-my-vessels-page">
                <ActionBar />
                <ZoomLevelGrid />
            </ScrollableContent>
        </BoundsContext.Provider>
    )
}
