import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getWidth(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.THREE_COLUMNS_LARGE:
            return '165px'
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '139px'
        default:
            return '122px'
    }
}

interface LabelProps {
    gridLayout: GridLayout
}

export const Label = styled.label<LabelProps>`
    width: ${(props) => getWidth(props.gridLayout)};
    margin: 0;
    padding: 0;
`
