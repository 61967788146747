import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {useVesselManagement} from '../../../contexts/use-vessel-management'

interface Props {
    text?: string
}
export function CloseFilterButton({text}: Props): JSX.Element {
    const {displayFilterBar} = useVesselManagement()

    return (
        <Button
            onClick={() => {
                displayFilterBar(false)
            }}
        >
            <XCircle />
            {text}
        </Button>
    )
}
