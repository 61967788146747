import {SelfMonitoringEngineEvent} from '../../../values/self-monitoring-event'
import AppState from '../../types/app-state'
import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'

export const isLoadingSelfMonitoringEngineEventsSelector = (state: AppState): boolean =>
    state.selfMonitoringEngineEvents.loadingEvents === LoadingState.NotPopulated ||
    state.selfMonitoringEngineEvents.loadingEvents === LoadingState.RequestingData

export const selfMonitoringEngineEventsLoadingStateSelector = (state: AppState): LoadingState =>
    state.selfMonitoringEngineEvents.loadingEvents

export const selfMonitoringEngineEventsSelector = (state: AppState): SelfMonitoringEngineEvent[] =>
    state.selfMonitoringEngineEvents.data

export const lastUpdatedSelector = (state: AppState): Moment | null =>
    state.selfMonitoringEngineEvents.lastUpdate
