import * as Styled from './notify-me-popup.styled'
import CLOSE_ICON from '../../../../@assets/icons/close-panel.svg'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {AddCustomEmail} from './add-custom-email'
import {AddCustomPrefix} from './add-custom-prefix'
import {SetSchedule} from './set-schedule'
import {FilterDetails} from './filter-details'
import {ButtonArea} from './button-area'
import {SetNotificationOnChange} from './set-notification-on-change'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'

interface NotifyMePopupProps {
    closePopup: (value: boolean) => void
    model: NotifyMeOutputModel
}

export function NotifyMePopup({closePopup, model}: NotifyMePopupProps): JSX.Element {
    const showNotifyOnChange =
        model.pageType === SavedFilterPageType.USB_INVENTORY ||
        model.pageType === SavedFilterPageType.SOFTWARE_INVENTORY

    return (
        <>
            <Styled.NotifyMePopupWrapper>
                <Styled.ArrowUp />
                <Styled.NotifyMePopup>
                    <Styled.TitleRow>
                        <Styled.Title>Notify me about...</Styled.Title>
                        <Styled.CloseImage
                            src={CLOSE_ICON}
                            onClick={() => {
                                closePopup(false)
                            }}
                            id="close-popup-icon"
                        />
                    </Styled.TitleRow>
                    <FilterDetails model={model} />
                    <SetSchedule schedule={model.schedule} />
                    <AddCustomEmail model={model} />
                    <AddCustomPrefix model={model} />
                    {showNotifyOnChange && (
                        <SetNotificationOnChange
                            notifyOnChange={model.currentSubscription?.notifyOnChange ?? false}
                        />
                    )}
                    <ButtonArea model={model} closePopup={closePopup} />
                </Styled.NotifyMePopup>
                <Styled.ArrowUpInner />
            </Styled.NotifyMePopupWrapper>
        </>
    )
}
