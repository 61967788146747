import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {CloseButton} from './close-button/close-button'
import * as Styles from './_styled/incident-details.styled'
export function LoadingDataModal(): JSX.Element {
    return (
        <Styles.Container>
            <Styles.NoDataContent>
                <Styles.NoDataBox>
                    <DataLoading />
                    <CloseButton />
                </Styles.NoDataBox>
            </Styles.NoDataContent>
        </Styles.Container>
    )
}
