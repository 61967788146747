import {ToggleLeft, ToggleRight} from 'react-feather'
import * as Styled from './switch-toggle.styled'
interface SwitchToggleProps {
    onValue: boolean
    setToggleRightValue(e: React.MouseEvent<unknown>): void
    setToggleLeftValue(e: React.MouseEvent<unknown>): void
    identifier: string
}
export function SwitchToggle({
    onValue,
    setToggleRightValue,
    setToggleLeftValue,
    identifier,
}: SwitchToggleProps): JSX.Element {
    return (
        <Styled.SwitchToggle>
            <Styled.ToggleButton selectable>
                {onValue ? (
                    <ToggleRight
                        onClick={(e: React.MouseEvent<SVGElement>) => setToggleLeftValue(e)}
                        fill="#1f88e5"
                        color="#f7f7f7"
                        width={27}
                        height={27}
                        id={`${identifier}_toggle-right`}
                    />
                ) : (
                    <ToggleLeft
                        onClick={(e: React.MouseEvent<SVGElement>) => setToggleRightValue(e)}
                        fill="#c9c9c9"
                        color="#f7f7f7"
                        width={27}
                        height={27}
                        id={`${identifier}_toggle-left`}
                    />
                )}
            </Styled.ToggleButton>
        </Styled.SwitchToggle>
    )
}
