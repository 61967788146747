import * as Styled from './saved-filter-alert-row.styled'
import {PrefixInput} from './editable-input-prefix'
import {EmailInput} from './editable-input-email'
import {SetSchedule} from './editable-input-schedule'
import {SavedFilterSubscription} from '../../../../../../store/state/saved-filters/state'

interface Props {
    subscriptionData: SavedFilterSubscription
}

export function AlertDetailsEditable({subscriptionData}: Props): JSX.Element {
    return (
        <Styled.AlertDetails>
            <Styled.DetailRow>
                Notification:
                <SetSchedule schedule={subscriptionData.schedule} />
            </Styled.DetailRow>
            <Styled.DetailRow>
                Email:
                <EmailInput initialValue={subscriptionData.notificationEmail} />
            </Styled.DetailRow>
            <Styled.DetailRow>
                Email prefix:
                <PrefixInput initialValue={subscriptionData.subjectPrefix} />
            </Styled.DetailRow>
        </Styled.AlertDetails>
    )
}
