import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import type {VesselDeploymentStatus as VesselDeploymentStatusPayload} from '../../../../../contexts/type/deployment-status-types'
import AlertHigh from '../../../../../../../@assets/icons/Alert high.svg'
import AlertMedium from '../../../../../../../@assets/icons/Alert medium.svg'
import {Check} from 'react-feather'
import {Panel} from '../_styled/panel-section.styled'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {ExpandPanelNoButton} from '../expand-panel-button/expand-panel-no-button'
import * as Styled from './vessel-deployment.styled'

export type deploymentStatusBreakdown = 'incus' | 'agents' | 'traffic' | 'dashboard'

export function VesselDeploymentStatusInfo({locationId}: {locationId: GuidType}): JSX.Element {
    const {vesselDeploymentStatusInfo} = useVesselManagement()
    const vesselOperationalStatus = vesselDeploymentStatusInfo?.operationalStatus?.[locationId]
    if (!vesselOperationalStatus) {
        return <></>
    }
    const incus = getIncusStatus(vesselDeploymentStatusInfo, locationId)
    const agents = getAgents(vesselDeploymentStatusInfo, locationId)
    const traffic = getTraffic(vesselDeploymentStatusInfo, locationId)
    const dashboard = getDashboard(vesselDeploymentStatusInfo, locationId)
    return (
        <Panel>
            <ExpandPanelNoButton headingRow={VesselManagementDetailType.DeploymentStatus} />
            <Styled.DetailsLayout>
                <Styled.Row>
                    <Styled.Column>{incus}</Styled.Column>
                    <Styled.Column>{agents}</Styled.Column>
                    <Styled.Column>{traffic}</Styled.Column>
                    <Styled.Column>{dashboard}</Styled.Column>
                </Styled.Row>
            </Styled.DetailsLayout>
        </Panel>
    )
}

function getDashboard(
    vesselDeploymentStatus: VesselDeploymentStatusPayload,
    locationId: GuidType,
): JSX.Element {
    const hasDashboard = vesselDeploymentStatus.vesselDashboard[locationId]
    const icon = getIcon('dashboard', vesselDeploymentStatus, locationId)
    if (hasDashboard) {
        return (
            <>
                <Styled.Label>Dashboard</Styled.Label>
                <Styled.StatusRow>{icon}</Styled.StatusRow>
            </>
        )
    }
    return (
        <>
            <Styled.Label>Dashboard</Styled.Label>
            <Styled.StatusRow>
                {icon}
                Not deployed
            </Styled.StatusRow>
        </>
    )
}

function getTraffic(
    vesselDeploymentStatus: VesselDeploymentStatusPayload,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('traffic', vesselDeploymentStatus, locationId)
    const traffic = vesselDeploymentStatus?.trafficStatus?.[locationId]
    if (!vesselDeploymentStatus || !traffic) {
        return (
            <>
                <Styled.Label>Traffic</Styled.Label>
                <Styled.StatusRow>{icon}</Styled.StatusRow>
            </>
        )
    }
    const trafficInfo = traffic.join('\n')

    return (
        <>
            <Styled.Label>Traffic</Styled.Label>
            <Styled.StatusRow title={trafficInfo}>
                {icon}
                {traffic.length} Asset(s) not seen
            </Styled.StatusRow>
        </>
    )
}

function getAgents(
    vesselDeploymentStatus: VesselDeploymentStatusPayload,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('agents', vesselDeploymentStatus, locationId)
    const vesselOperationalStatus = vesselDeploymentStatus?.operationalStatus?.[locationId] ?? null
    const agentsNeedUpdating = vesselOperationalStatus?.upgradeAssets
    if (!vesselOperationalStatus || !agentsNeedUpdating) {
        return (
            <>
                <Styled.Label>Agents</Styled.Label>
                <Styled.StatusRow>{icon}</Styled.StatusRow>
            </>
        )
    }
    const angetsInfo = agentsNeedUpdating.map((value) => value.alias ?? value.identity).join('\n')
    return (
        <>
            <Styled.Label>Agents</Styled.Label>
            <Styled.StatusRow title={angetsInfo}>
                {icon}
                {agentsNeedUpdating.length} agents need updating
            </Styled.StatusRow>
        </>
    )
}

function getIncusStatus(
    vesselDeploymentStatus: VesselDeploymentStatusPayload,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('incus', vesselDeploymentStatus, locationId)

    return (
        <>
            <Styled.Label>Incus</Styled.Label>
            <Styled.StatusRow>{icon}</Styled.StatusRow>
        </>
    )
}

function getIcon(
    type: deploymentStatusBreakdown,
    vesselDeploymentStatus: VesselDeploymentStatusPayload,
    locationId: GuidType,
): JSX.Element {
    const vesselOperationalStatus = vesselDeploymentStatus?.operationalStatus?.[locationId] ?? null
    if (!vesselOperationalStatus) {
        return <Check color="green" style={{paddingLeft: '2px'}} />
    }
    switch (type) {
        case 'incus':
            const hasIncus = vesselOperationalStatus.operating
            if (hasIncus) {
                return <Check color="green" style={{paddingLeft: '2px'}} />
            }
            return <img src={AlertHigh} title={'Incus not installed'} width={25} />
        case 'agents':
            const agentsNeedUpdating = vesselOperationalStatus.upgradeAssets
            if (!agentsNeedUpdating || agentsNeedUpdating.length === 0) {
                return <Check color="green" style={{paddingLeft: '2px'}} />
            }
            return <img src={AlertMedium} width={24} />
        case 'traffic':
            const traffic = vesselDeploymentStatus.trafficStatus?.[locationId]
            if (!traffic) {
                return <Check color="green" style={{paddingLeft: '2px'}} />
            }
            return <img src={AlertHigh} width={24} />
        case 'dashboard':
            const hasDashboard = vesselDeploymentStatus.vesselDashboard[locationId]
            return hasDashboard ? (
                <Check color="green" style={{paddingLeft: '2px'}} />
            ) : (
                <img src={AlertMedium} width={24} />
            )
    }
}
