import {MouseEvent, useState} from 'react'
import {usePagedAlerts} from '../../contexts/alerts/use-paged-alerts'
import * as Styled from './filter-form.styled'
import 'react-datepicker/dist/react-datepicker.css'
import {removeMultipleQueryParam, replaceDateParams} from '../../../../helpers/navigation'
import {useDispatch} from 'react-redux'
import {logAnalysisPeriodOnFilter} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'

export function FilterForm(): JSX.Element {
    const {fromDate: alertsFromDate, toDate: alertsToDate, displayDateFilter} = usePagedAlerts()

    const filterSet = alertsFromDate || alertsToDate

    const [endDate, setEndDate] = useState(
        filterSet ? (alertsToDate ? new Date(alertsToDate) : null) : null,
    )

    const [startDate, setStartDate] = useState(
        filterSet ? (alertsFromDate ? new Date(alertsFromDate) : null) : null,
    )
    const dispatch = useDispatch()

    function setFilter(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        const fromDate = startDate ? startDate.toISOString() : null
        const toDate = endDate ? endDate.toISOString() : null
        replaceDateParams(fromDate, toDate)
        displayDateFilter(false)
        dispatch(
            logAnalysisPeriodOnFilter(
                `fromDate:${fromDate ? fromDate : 'not provided'} - toDate:${toDate ? toDate : 'not provided'}`,
                'period filter',
                AuditLogPageType.ASSET_MODAL,
            ),
        )
    }

    function clearFilter(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        removeMultipleQueryParam(['fromDate', 'toDate'])
        displayDateFilter(false)
    }

    function cancelChanges(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        displayDateFilter(false)
    }

    return (
        <Styled.Filter onClick={(e) => e.stopPropagation()}>
            <Styled.Header>
                <Styled.Title>Filter</Styled.Title>
            </Styled.Header>
            <Styled.Settings>
                <Styled.DateRow>
                    <Styled.Label>From:</Styled.Label>
                    <Styled.DateEntry
                        id="fromDate"
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        showTimeSelect
                        dateFormat="MMM d, yyyy h:mm aa"
                        isClearable
                        popperPlacement="right"
                    />
                </Styled.DateRow>
                <Styled.DateRow>
                    <Styled.Label>To:</Styled.Label>
                    <Styled.DateEntry
                        id="toDate"
                        selected={endDate}
                        onChange={(date: Date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        showTimeSelect
                        dateFormat="MMM d, yyyy h:mm aa"
                        isClearable
                        popperPlacement="right"
                    />
                </Styled.DateRow>
            </Styled.Settings>
            <Styled.Actions>
                <Styled.CancelButtonWrapper>
                    <Styled.SecondaryButton onClick={cancelChanges} id="filter_cancel">
                        Cancel Changes
                    </Styled.SecondaryButton>
                </Styled.CancelButtonWrapper>
                <Styled.ClearButtonWrapper>
                    <Styled.PrimaryButton onClick={clearFilter} id="filter_clear">
                        Clear Filter
                    </Styled.PrimaryButton>
                </Styled.ClearButtonWrapper>
                <Styled.PrimaryButton onClick={setFilter} id="filter_set">
                    Set Filter
                </Styled.PrimaryButton>
            </Styled.Actions>
        </Styled.Filter>
    )
}
