import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {TableHeaderGrid} from './table-header-grid.styled'
import {TableHeader} from './table-header'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()
    return (
        <TableHeaderGrid width={width} showReducedGrid={false}>
            <TableHeader />
        </TableHeaderGrid>
    )
}
