import {AddNewUser} from './components/add-new-user'
import {Overlay} from './components/modal-overlay'

export function AddNewUserModal(): JSX.Element {
    return (
        <Overlay>
            <AddNewUser />
        </Overlay>
    )
}
