import {GuidType} from '../../../../values/generic-type-defintions'

export interface IncidentLink {
    identity: GuidType
    incidentResponseIdentity: GuidType
    itemType: IncidentLinkType
    itemIdentity: GuidType
    monitored: boolean
}

export enum IncidentLinkType {
    NETWORK_ASSET = 'UNKNOWN_ASSET',
    USB = 'USB',
    SOFTWARE = 'SOFTWARE',
}
