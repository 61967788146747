import {CardDataCell} from '../data-cell'
import {PurposeDropdown} from './purpose-dropdown'

export function Purpose(): JSX.Element {
    return (
        <CardDataCell gridArea="PURPOSE" label="What do you need this for?">
            <PurposeDropdown />
        </CardDataCell>
    )
}
