import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './expand-panel-button.styled'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {
    DetailExpandedType,
    VesselManagementDetailType,
} from '../../../../../contexts/type/vessel-management-state'

interface ExpandPanelButtonProps {
    headingRow: VesselManagementDetailType
}

export function ExpandPanelButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    const {toggleDetailExpanded, detailExpanded} = useVesselManagement()
    const isExpanded = getCurrentDetailExpanded(headingRow, detailExpanded)

    function setNewDetailExpanded() {
        const newDetailExpanded: DetailExpandedType = getNewDetailExpanded(
            headingRow,
            detailExpanded,
        )
        toggleDetailExpanded(newDetailExpanded)
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow>{headingRow}</Styles.DetailHeadingRow>
            <Styles.Button onClick={() => setNewDetailExpanded()}>
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewDetailExpanded = (
    toggledDetail: VesselManagementDetailType,
    currentDetailExpanded: DetailExpandedType,
): DetailExpandedType => {
    switch (toggledDetail) {
        case VesselManagementDetailType.NetworkSettings:
            return {
                ...currentDetailExpanded,
                networkSettingsExpanded: !currentDetailExpanded.networkSettingsExpanded,
            }
        case VesselManagementDetailType.CommsSettings:
            return {
                ...currentDetailExpanded,
                commsSettingsExpanded: !currentDetailExpanded.commsSettingsExpanded,
            }
        case VesselManagementDetailType.PolicySettings:
            return {
                ...currentDetailExpanded,
                policySettingsExpanded: !currentDetailExpanded.policySettingsExpanded,
            }
        case VesselManagementDetailType.VesselTagging:
            return {
                ...currentDetailExpanded,
                vesselTaggingExpanded: !currentDetailExpanded.vesselTaggingExpanded,
            }
        case VesselManagementDetailType.OTSources:
            return {
                ...currentDetailExpanded,
                otSourcesExpanded: !currentDetailExpanded.otSourcesExpanded,
            }
        default:
            return currentDetailExpanded
    }
}

const getCurrentDetailExpanded = (
    toggledDetail: VesselManagementDetailType,
    currentDetailExpanded: DetailExpandedType,
): boolean => {
    switch (toggledDetail) {
        case VesselManagementDetailType.NetworkSettings:
            return currentDetailExpanded.networkSettingsExpanded
        case VesselManagementDetailType.CommsSettings:
            return currentDetailExpanded.commsSettingsExpanded
        case VesselManagementDetailType.PolicySettings:
            return currentDetailExpanded.policySettingsExpanded
        case VesselManagementDetailType.VesselTagging:
            return currentDetailExpanded.vesselTaggingExpanded
        case VesselManagementDetailType.OTSources:
            return currentDetailExpanded.otSourcesExpanded
        default:
            return false
    }
}
