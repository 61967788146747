import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {
    BREAK_POINT_CARD_VIEW,
    SHOW_DETAILS_BREAK_POINT_CARD_VIEW,
} from '../../../policy-management.styled'

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier
        ? width < BREAK_POINT_CARD_VIEW
        : width < SHOW_DETAILS_BREAK_POINT_CARD_VIEW
}

export function getGridTemplateColumns(width: number): string {
    if (width > 1400) {
        return `${spacing(2)} 300px auto 300px 300px ${spacing(2)}`
    }
    if (width > 1300) {
        return `${spacing(2)} 300px auto 250px 300px ${spacing(2)}`
    }
    if (width > 1000) {
        return `${spacing(2)} 250px auto 200px 250px ${spacing(2)}`
    }
    if (width > 900) {
        return `${spacing(2)} 200px auto 180px 200px ${spacing(2)}`
    }
    return `${spacing(2)} 180px auto 150px 200px ${spacing(2)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(1)} 230px 400px 180px 230px ${spacing(1)}`
    }
    if (width > 1500) {
        return `${spacing(1)} 220px 350px 160px 220px ${spacing(1)}`
    }
    if (width > 1440) {
        return `${spacing(1)} 210px 330px 150px 200px ${spacing(1)}`
    }
    if (width > 1330) {
        return `${spacing(1)} 175px 280px 150px 175px ${spacing(1)}`
    }
    return `0 150px 220px 150px 150px 0`
}

interface TableHeaderGridProps {
    width: number
    showDetailsBtn: boolean
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showDetailsBtn
            ? getGridTemplateColumns(props.width)
            : getReducedGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    padding: 0 ${spacing(1)};
`

interface TableHeaderCellProps {
    gridColumn: number
    width: number
    centered?: boolean
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
`
