import {batch, useDispatch} from 'react-redux'
import {OldestOpenIncidentResponse} from '../../../contexts/types/oldest-open-incident-api'
import * as Styled from './oldest-open-incident.styled'
import {toggleVesselForVesselBetaInterlinking} from '../../../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logInterlinksClickForVesselsBeta} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {SeverityLevelSquare} from '../../../../incidents-v3/components/shared/severity-level/_styled/severity-level.styled'
import {analysisPeriodVesselsBetaFilterSelector} from '../../../../../store/state/vessels-beta-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'

interface OldestIncidentProps {
    findoldestIncidentData: OldestOpenIncidentResponse
}
export function OldestIncident({findoldestIncidentData}: OldestIncidentProps): JSX.Element {
    const dispatch = useDispatch()
    const analysisPeriod = useTypedSelector(analysisPeriodVesselsBetaFilterSelector)

    return (
        <Styled.IncidentWrapper
            id={`oldest-incidents-wrapper-${findoldestIncidentData.locationId}`}
        >
            <SeverityLevelSquare
                withBorder={false}
                severityLevel={findoldestIncidentData.severity}
                id={`oldest-incidents-severity-${findoldestIncidentData.locationId}-${findoldestIncidentData.severity}`}
            >
                {findoldestIncidentData.severity}
            </SeverityLevelSquare>
            <Link
                onClick={() =>
                    batch(() => {
                        dispatch(
                            toggleVesselForVesselBetaInterlinking(
                                findoldestIncidentData.locationId,
                                PageType.INCIDENTS,
                                analysisPeriod,
                            ),
                        )
                        dispatch(
                            logInterlinksClickForVesselsBeta(
                                AuditLogPageType.INCIDENTS,
                                findoldestIncidentData.locationId,
                                'oldest open incident',
                            ),
                        )
                    })
                }
                to="/incidents"
                style={{color: '#1f88e5'}}
                id={`oldest-incidents-number-${findoldestIncidentData.locationId}-${findoldestIncidentData.incidentNo}`}
            >
                {findoldestIncidentData.incidentNo}
            </Link>
            <Styled.IncidentAgeDisplay
                id={`oldest-incidents-age-display-${findoldestIncidentData.locationId}-${findoldestIncidentData.ageOfOpenState}`}
            >
                Open for: {moment(findoldestIncidentData.ageOfOpenState).fromNow(true) ?? 'Unknown'}
            </Styled.IncidentAgeDisplay>
        </Styled.IncidentWrapper>
    )
}
