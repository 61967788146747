import {StrictMode, User} from '../../../../store/state/users/state'
import {Role} from '../../../../values/Role'
import {GuidType} from '../../../../values/generic-type-defintions'
import {DetailExpandedType, ProcessingStatus} from '../type/user-management-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function showUserDetailModal(userId: GuidType): Actions.ShowUserDetailModalAction {
    return {
        type: ActionType.SHOW_USER_DETAIL_MODAL,
        payload: userId,
    }
}

export function closeUserDetailModal(): Actions.CloseUserDetailModalAction {
    return {
        type: ActionType.CLOSE_USER_DETAIL_MODAL,
    }
}

export function receivedRequestedUserDetails(
    value: User,
): Actions.ReceivedRequestedUserDetailsAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_USER_DETAILS,
        payload: value,
    }
}

export function setChangeUserName(value: string): Actions.SetChangeUserNameAction {
    return {type: ActionType.SET_CHANGE_USER_NAME, payload: value}
}

export function setChangeUserEmail(value: string): Actions.SetChangeUserEmailAction {
    return {type: ActionType.SET_CHANGE_USER_EMAIL, payload: value}
}

export function setChangeNotificationEmail(
    value: string,
): Actions.SetChangeNotificationEmailAction {
    return {type: ActionType.SET_CHANGE_NOTIFICATION_EMAIL, payload: value}
}

export function assignUserRole(role: string): Actions.AssignUserRoleAction {
    return {type: ActionType.ASSIGN_USER_ROLE, payload: role}
}

export function deassignUserRole(role: string): Actions.DeassignUserRoleAction {
    return {type: ActionType.DEASSIGN_USER_ROLE, payload: role}
}

export function SetSubscription(value: {
    initialSubscriptionStatus: boolean
    newStatus: boolean
    userId: string
    emailId: string
}): Actions.SetSubscriptionAction {
    return {type: ActionType.SET_SUBSCRIPTION, payload: value}
}

export function changesSaved(): Actions.ChangesSavedAction {
    return {
        type: ActionType.CHANGES_SAVED,
    }
}

export function userDisabled(): Actions.UserDisabledAction {
    return {
        type: ActionType.USER_DISABLED,
    }
}

export function userEnabled(): Actions.UserEnabledAction {
    return {
        type: ActionType.USER_ENABLED,
    }
}

export function setSaveMessage(
    message: 'Save failed' | 'Saved successfully' | undefined,
): Actions.SetSaveResultMessageAction {
    return {type: ActionType.SET_SAVE_RESULT_MESSAGE, payload: message}
}

export function setDisableUserMessage(
    message:
        | 'Disable user failed'
        | 'Disabled successfully'
        | 'Enable user failed'
        | 'Enabled successfully'
        | undefined,
): Actions.SetDisableUserResultMessageAction {
    return {type: ActionType.SET_DISABLE_USER_RESULT_MESSAGE, payload: message}
}

export function displayAddNewUserModal(value: boolean): Actions.DisplayAddNewUserModalAction {
    return {
        type: ActionType.DISPLAY_ADD_NEW_USER_MODAL,
        payload: value,
    }
}

export function setNewUserNameAction(username: string): Actions.SetNewUserNameAction {
    return {type: ActionType.SET_NEW_USER_NAME, payload: username}
}
export function setNewUserEmailAction(email: string): Actions.SetNewUserEmailAction {
    return {type: ActionType.SET_NEW_USER_EMAIL, payload: email}
}
export function setNewUserRoleAction(role: Role, value: boolean): Actions.SetNewUserRolesAction {
    return {type: ActionType.SET_NEW_USER_ROLES, payload: {role, value}}
}
export function setNewUserSubscriptionAction(value: boolean): Actions.SetNewUserSubscriptionAction {
    return {type: ActionType.SET_NEW_USER_SUBSCRIPTION, payload: value}
}
export function setNewUserExternalAction(value: boolean): Actions.SetNewUserExternalAction {
    return {type: ActionType.SET_NEW_USER_EXTERNAL, payload: value}
}

export function newUserAdded(): Actions.NewUserAddedAction {
    return {
        type: ActionType.NEW_USER_ADDED,
    }
}

export function setSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_PROCESSING_STATUS,
        payload: value,
    }
}

export function setAssignedTagId(
    searchedVesselTagId: GuidType | undefined,
): Actions.SetAssignedTagIdAction {
    return {
        type: ActionType.SET_ASSIGNED_TAG_ID,
        payload: searchedVesselTagId,
    }
}

export function setTagStrictMode(
    strictMode: StrictMode | undefined,
): Actions.SetTagStrictModeAction {
    return {
        type: ActionType.SET_TAG_STRICT_MODE,
        payload: strictMode,
    }
}

export function viewAsExternal(value: boolean): Actions.ViewAsExternalAction {
    return {
        type: ActionType.VIEW_AS_EXTERNAL,
        payload: value,
    }
}

export function toggleDetailExpanded(
    detailExpanded: DetailExpandedType,
): Actions.ToggleDetailExpandedAction {
    return {
        type: ActionType.TOGGLE_DETAIL_EXPANDED,
        payload: detailExpanded,
    }
}

export function toggleAlertDetailEdit(
    currentAlertId: GuidType | undefined,
): Actions.ToggleAlertDetailEditAction {
    return {
        type: ActionType.TOGGLE_ALERT_DETAIL_EDIT,
        payload: currentAlertId,
    }
}
