import NetworkAnomalyMatrixRecord from '../../../../../../values/anomalies/network-anomaly-matrix-record'
import CommonEventMonaDetails from '../../../../../../values/common-event/common-event-mona-details'
import AnomalyMessageFormatter from './anomaly-message-formatter'

class DefaultAnomalyMessageFormatter implements AnomalyMessageFormatter {
    format(
        commonEvent: CommonEventMonaDetails,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _matrixRecord: NetworkAnomalyMatrixRecord,
    ): string {
        if (!commonEvent.details) {
            return ''
        }

        return commonEvent.details.trim()
    }
}

export default DefaultAnomalyMessageFormatter
