import * as Styled from './period-select.styled'
import {DefaultPeriodOptions} from '../../period-filter-type-dashboard'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
export function PeriodSelect(): JSX.Element {
    const {periodWidgetOnDashboard, setPeriodWidgetOnDashboard} = useIncidentsWidget()

    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        setPeriodWidgetOnDashboard(e.target.value as DefaultPeriodOptions)
    }

    return (
        <Styled.Input id="period-selector">
            <Styled.Label>Time Period</Styled.Label>
            <Styled.Select
                onChange={onChangeWrapper}
                value={periodWidgetOnDashboard}
                id="select-period-value"
            >
                <option value="1d" id="PT24H">
                    1 day
                </option>
                <option value="7d" id="PT168H">
                    7 days
                </option>
                <option value="30d" id="PT720H">
                    30 days
                </option>
                <option value="90d" id="PT2160H">
                    90 days
                </option>
            </Styled.Select>
        </Styled.Input>
    )
}
