import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {CurrentIncidentsPerformanceTooltip} from './current-incidents-performance-tooltip'
import {IncidentAvgPerformanceType} from '../../contexts/types/incidents-report-output'

export function IncidentsResponsePerformanceWidget({
    incidentsByAvgPerformance,
}: {
    incidentsByAvgPerformance: IncidentAvgPerformanceType[]
}): JSX.Element {
    const formattedResponse = incidentsByAvgPerformance.map((oneElement) => ({
        ...oneElement,
        displayAvgTimeToOpen: extractDays(oneElement.averageTimeToOpen),
        displayAvgTimeToClose: extractDays(oneElement.averageTimeToClose),
    }))
    return (
        <ResponsiveContainer minHeight={150}>
            <BarChart
                height={150}
                data={formattedResponse}
                margin={{
                    top: 0,
                    right: 0,
                    left: -10,
                    bottom: -10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" fontSize="14px" />
                <YAxis
                    fontSize="14px"
                    label={<Label value="DAYS" angle={-90} position="center" dx={-10} />}
                />
                <Tooltip
                    content={
                        <CurrentIncidentsPerformanceTooltip data={incidentsByAvgPerformance} />
                    }
                />
                <Bar dataKey="displayAvgTimeToOpen" fill="#77CDEB" name="Average time to Open" />
                <Bar dataKey="displayAvgTimeToClose" fill="#0F84AB" name="Average time to Close" />
            </BarChart>
        </ResponsiveContainer>
    )
}
function extractDays(duration: string) {
    const matchDays = duration.match(/^P(\d+)D/)
    const matchHours = duration.match(/T(\d+)H/)

    let days = matchDays ? parseInt(matchDays[1], 10) : 0
    let hours = matchHours ? parseInt(matchHours[1], 10) : 0

    // Convert hours to days if greater than 24
    if (hours >= 24) {
        const additionalDays = Math.floor(hours / 24)
        days += additionalDays
        hours = hours % 24
    }

    return days
}
