import {normaliseScore} from '../../../../../../helpers/formatting'
import {ThreatValue} from '../../../../../../helpers/threatHelper'
import {Square} from './square.styled'
import {Value} from './value.styled'

interface ThreatScoreProps {
    id: string
    score: number | undefined
    threatLevel: ThreatValue | undefined
    roundedCorners?: boolean
}

export function ThreatScore({
    id,
    score,
    threatLevel,
    roundedCorners = true,
}: ThreatScoreProps): JSX.Element {
    return (
        <Square id={id} threatLevel={threatLevel} roundedCorners={roundedCorners}>
            <Value threatLevel={threatLevel}>
                {score != undefined ? normaliseScore(score) : '?'}
            </Value>
        </Square>
    )
}
