import produce from 'immer'
import type {AssetForm} from '../../location-inventory-page-context'
import {Action} from './actions'
import ActionType from './action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import {defaultLocationInventoryContextState} from '../../location-inventory-page-context'
import _ from 'lodash'
import {addOTAsset, updateOTAsset} from '../../../../../store/state/ot-assets/action-creators'

export const AssetFormReducer = produce((draft: AssetForm['state'], action: Action) => {
    switch (action.type) {
        // Form values related
        // -------------------
        case ActionType.SET_FORM_STRING_VALUE: {
            draft.formData[action.payload.field] = action.payload.value
            break
        }
        case ActionType.SET_FORM_DROPDOWN_VALUE: {
            draft.formData[action.payload.field] = action.payload.value
            break
        }
        case ActionType.SET_FORM_DROPDOWN_VALUES:
            const idx = draft.formData[action.payload.field].findIndex(
                (x) => x.value === action.payload.value.value,
            )
            if (idx === -1) {
                draft.formData[action.payload.field].push(action.payload.value)
            } else {
                draft.formData[action.payload.field].splice(idx, 1)
            }
            break
        case ActionType.SET_ASSET_FORM_ERRORS:
            draft.formFieldError = action.payload
            draft.formSubmitLoadingState = LoadingState.Errored

            break
        case ActionType.SET_ASSET_FORM_SUBMIT_ERROR:
            draft.formSubmitError = action.payload.error
            draft.formSubmitLoadingState = LoadingState.Errored
            break

        case ActionType.RESET_ASSET_CREATION_FORM: {
            // Clear form errors
            draft.formFieldError = _.cloneDeep(
                defaultLocationInventoryContextState.assetForm.state.formFieldError,
            )

            // Clear form submit error
            draft.formSubmitError = ''

            // Reset loading state of form submission
            draft.formSubmitLoadingState = LoadingState.NotPopulated

            // Reset form field data
            draft.formData = _.cloneDeep(
                defaultLocationInventoryContextState.assetForm.state.formData,
            )

            // Reset editing state
            draft.editingAssetId = null
            draft.editingAssetIdRevision = null
            break
        }

        // Form submission related
        // -----------------------
        case ActionType.CREATE_ASSET_REQUEST:
            draft.formSubmitLoadingState = LoadingState.RequestingData
            break

        case ActionType.CREATE_ASSET_SUCCESS:
            // Todo: Change this once we have refactor
            // the asset list to also be in context.
            action.payload.reduxDispatch(addOTAsset(action.payload.data))
            draft.formSubmitLoadingState = LoadingState.Loaded
            break

        case ActionType.SET_IS_EDITING_ID: {
            const data = action.payload.asset

            // No asset being edited
            if (draft.editingAssetId === null && data !== null) {
                draft.editingAssetId = action.payload.assetId
                draft.editingAssetIdRevision = data.revision

                // Take the first one cause we dont support
                // multiple physical interfaces yet
                const physicalInterfaces = data.physicalInterfaces?.map((entry) => ({
                    label: entry.name,
                    value: entry.id,
                }))
                draft.formData = {
                    name: data.name,
                    assetTypeID: {
                        label: data.assetTypeName,
                        value: data.assetTypeID,
                    },
                    systemID: {
                        label: data.systemName,
                        value: data.systemID,
                    },
                    zoneID: {
                        label: data.zoneName,
                        value: data.zoneID,
                    },
                    vendor: data.vendor,

                    model: data.model,
                    description: data.description,
                    physicalInterfaceIDs: physicalInterfaces,
                    softwareID: {
                        label: data.softwareName,
                        value: data.softwareID,
                    },
                    softwareVersion: data.softwareVersion,
                    communicationProtocolID: {
                        label: data.communicationProtocolName,
                        value: data.communicationProtocolID,
                    },
                    customFields: data.customFields,
                }
                return
            }

            // Other asset is being edited
            // Don't do anything cause we are
            // allowing only 1 asset to be edited at a time
            // This else is just here to communicate that intent.
            else if (draft.editingAssetId !== null) {
                // noop
            }

            break
        }

        case ActionType.EDIT_ASSET_REQUEST:
            draft.formSubmitLoadingState = LoadingState.RequestingData
            break

        case ActionType.EDIT_ASSET_SUCCESS:
            draft.formSubmitLoadingState = LoadingState.Loaded
            draft.editingAssetId = null
            draft.editingAssetIdRevision = null

            // Edit the asset in redux
            // TODO: Change this once we have refactor
            // everything to use context
            action.payload.reduxDispatch(updateOTAsset(action.payload.data))

            break

        case ActionType.ADD_CUSTOM_FIELD:
            draft.formData.customFields.push({name: '', value: ''})
            break

        case ActionType.REMOVE_CUSTOM_FIELD:
            draft.formData.customFields.splice(action.payload.index, 1)
            break

        case ActionType.SET_FORM_ADDITIONAL_FIELD_VALUE:
            // Find the custom Field
            const customField = draft.formData.customFields[action.payload.index]
            // Set the custom field value
            customField[action.payload.field] = action.payload.value
            break

        default:
            break
    }
})
