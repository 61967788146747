import {useRef, useState} from 'react'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import * as Styled from './view-screen-level.styled'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {Link} from 'react-router-dom'
interface ViewScreenLevelProps {
    landingOption: 'Table' | 'Report'
}
export function ViewScreenLevel({landingOption}: ViewScreenLevelProps): JSX.Element {
    const {width} = useDimensions()
    const [optionsVisible, setOptionsVisible] = useState(false)
    const [viewLevel, setViewLevel] = useState<'Table' | 'Report'>(landingOption)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })
    return (
        <Styled.ContentWrapper width={width} id="ViewScreenLevel" ref={clickOutside}>
            <Styled.Label>View level:</Styled.Label>
            <Styled.Input id="filter-option">
                <Styled.WrapperOption onClick={() => setOptionsVisible(true)} id="filter-option">
                    {viewLevel}
                </Styled.WrapperOption>
                {optionsVisible && (
                    <Styled.ValueOptions>
                        <ViewLevelOption
                            path="incidents"
                            linkText="Table"
                            setViewLevel={setViewLevel}
                            setOptionsVisible={setOptionsVisible}
                        />
                        <ViewLevelOption
                            path="incidentsReport"
                            linkText="Report"
                            setViewLevel={setViewLevel}
                            setOptionsVisible={setOptionsVisible}
                        />
                    </Styled.ValueOptions>
                )}
            </Styled.Input>
        </Styled.ContentWrapper>
    )
}
interface ViewLevelOptionProps {
    path: string
    linkText: 'Table' | 'Report'
    setViewLevel: (value: 'Table' | 'Report') => void
    setOptionsVisible: (value: boolean) => void
}
function ViewLevelOption({
    path,
    linkText,
    setViewLevel,
    setOptionsVisible,
}: ViewLevelOptionProps): JSX.Element {
    return (
        <Styled.ValueOption
            onClick={() => {
                setViewLevel(linkText)
                setOptionsVisible(false)
            }}
        >
            <Link to={path}>{linkText}</Link>
        </Styled.ValueOption>
    )
}
