import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {smallFont} from '../../../../theme/font-styles'
import {
    METRIC_FILTER_BAR_VIEW_CARD_WIDTH,
    METRIC_FULL_CARD_WIDTH,
    METRIC_SHRINK_VIEW_CARD_WIDTH,
} from '../../metrics-page.styled'

interface ContentAreaProps {
    width: number
}
export const ContentArea = styled.div<ContentAreaProps>`
    padding: ${(props) =>
        props.width < 600 ? `0 ${spacing(2)} 0 ${spacing(2)}` : `0 ${spacing(4)} 0 ${spacing(4)}`};
    background-color: ${(props) => props.theme.colors.background.page};
`

export const Card = styled.div<ContentAreaProps>`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: ${spacing(2)};
    padding: ${spacing(2)};
    ${smallFont()};
    overflow-x: auto;
`

interface ContainerProps {
    width: number
    gridArea: string
}
export const FilterContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    grid-area: ${(props) => props.gridArea};
`

export const StatusFilterContainer = styled(FilterContainer)`
    align-items: ${(props) => (props.width < 600 ? 'center' : 'flex-start')};
    width: 100%;
`
interface Props {
    width: number
    showFilterBar: boolean
}
export const ContentLevelGrid = styled.div<Props>`
    padding: 0;
    display: grid;
    justify-content: flex-start;
    justify-items: center;
    grid-template-columns: ${(props) =>
        props.showFilterBar
            ? `repeat(auto-fill, minmax(${METRIC_FILTER_BAR_VIEW_CARD_WIDTH}px, 1fr))`
            : props.width > 540
              ? `repeat(auto-fill, minmax(${METRIC_FULL_CARD_WIDTH}px, 1fr))`
              : `repeat(auto-fill, minmax(${METRIC_SHRINK_VIEW_CARD_WIDTH}px, 1fr))`};
    column-gap: ${spacing(4)};
    row-gap: ${spacing(4)};
    flex: 1;
    background-color: ${(props) => props.theme.colors.background.page};
`
export const ScrollableContent = styled.div`
    padding: ${spacing(2)} 0;
    overflow-y: auto;
    flex: 1;
    width: 100%;
`
