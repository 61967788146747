import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import * as Styled from './assign-policy-dialog.styled'
import {CurrentAppliedVessels} from './current-applied-vessels/current-applied-vessels'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {VesselFilter} from './vessel-filter/vessel-filter'
import {Button} from './assign-policy-dialog.styled'
import {
    AllPolicyData,
    PolicyType,
} from '../../../contexts/policy-management/type/policy-management-state'
import {isEqual} from 'lodash'

interface DialogContentProps {
    policy: AllPolicyData
}

export function DialogContent({policy}: DialogContentProps): JSX.Element {
    const {
        closeAssignPolicyDialog,
        assignVesselsToMetricsPolicy,
        assignPolicyDialogState,
        assignVesselToEnforcementPolicy,
    } = usePolicyManagement()

    const disabled = isEqual(
        policy.locations,
        Array.from(assignPolicyDialogState.newLocations ?? []),
    )
    return (
        <>
            <Styled.TitleRow>
                <Styled.Title>Apply policy to...</Styled.Title>
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => closeAssignPolicyDialog()}
                    id="close-popup-icon"
                />
            </Styled.TitleRow>
            {policy.locations && policy.locations.length > 0 && <CurrentAppliedVessels />}
            <VesselFilter />
            <Styled.ButtonsArea>
                <Button
                    id="assign-vessels-to-policy-button"
                    disabled={disabled}
                    onClick={() => {
                        switch (policy.policyType) {
                            case PolicyType.METRICS:
                                assignVesselsToMetricsPolicy()
                                break
                            case PolicyType.ENFORCEMENT:
                                assignVesselToEnforcementPolicy()
                                break
                            case PolicyType.INCIDENT:
                                break
                        }
                    }}
                >
                    Apply
                </Button>
            </Styled.ButtonsArea>
        </>
    )
}
