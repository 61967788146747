import {LocationIdType} from '../../../../store/state/locations/state'

export interface SoftwareInventoryFilter {
    searchedFirstDetected: TimestampFilter
    searchedLastActive: TimestampFilter
    searchedCompanyName: string | undefined
    searchedProductName: string | undefined
    searchedVessels: Set<LocationIdType> | undefined
    sortColumn: SortColumnType
    searchedVesselTagTerm: string[]
    searchedVesselNameTerm: string
    searchedVesselTerm: string
    showXButton: boolean
}

export interface SortColumnType {
    orderBy: SoftwareInventorySortType
    orderAscending: boolean
}

export enum SoftwareInventorySortType {
    TIMESTAMP = 'firstSeen',
    LATEST_TIMESTAMP = 'lastSeen',
    COMPANY_NAME = 'company',
    PRODUCT_NAME = 'product',
    NUMBER_OF_HOSTS_PER_ITEM = 'numberOfHostsPerItem',
    NUMBER_OF_VESSELS_PER_ITEM = 'numberOfVesselsPerItem',
    NEWEST_ACTIVE_VERSION = 'newestActiveVersion',
    OLDEST_ACTIVE_VERSION = 'oldestActiveVersion',
}

export type TimestampFilter = 'Last 24h' | 'Last 7 days' | 'Last 30 days' | 'All'

export interface SoftwareInventoryFilterForCriteria {
    searchedFirstDetected: TimestampFilter | undefined
    searchedLastActive: TimestampFilter | undefined
    searchedCustomerName: string | undefined
    searchedProductName: string | undefined
    searchedVessels: Array<LocationIdType> | undefined
    sortColumn: SortColumnType
    searchedVesselTagTerm: string[] | undefined
    searchedVesselNameTerm: string | undefined
}

export const DEFAULT_SOFTWARE_INVENTORY_FILTERS: SoftwareInventoryFilter = {
    searchedFirstDetected: 'All',
    searchedLastActive: 'All',
    searchedCompanyName: '',
    searchedProductName: '',
    searchedVessels: undefined,
    sortColumn: {
        orderBy: SoftwareInventorySortType.TIMESTAMP,
        orderAscending: false,
    },
    searchedVesselTagTerm: [],
    searchedVesselNameTerm: '',
    searchedVesselTerm: '',
    showXButton: false,
}
