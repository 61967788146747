import * as Styled from './_styled/table.styled'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'

interface Props {
    monaEngineMode: MonaEngineMode
    gridRow: number
}
export function TableModeCell({monaEngineMode, gridRow}: Props): JSX.Element {
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={3}
            id={`message-value_${monaEngineMode.anomalyProcessingMode}`}
        >
            {monaEngineMode.mode}
        </Styled.TableDataCell>
    )
}
