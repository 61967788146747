import {GuidType} from '../../../../values/generic-type-defintions'
import {ConfigInputType} from '../type/config-input-type'
import {LocationInIsolationMode} from '../type/location-in-isolation.mode'
import {DetailExpandedType, VesselConfigModal} from '../type/vessel-management-state'

import {ActionType} from './action-type'
import * as Actions from './actions'
import {DeploymentType, PayloadUnion, VesselDeploymentStatus} from '../type/deployment-status-types'
import {Location} from '../../../../store/state/locations/state'
import {OTSourceModalDetails} from '../type/ot-sources-modal-details'
import {LicenseSummery} from '../type/license-types'

type VesselDeploymentReturnTypes = {
    [DeploymentType.Operational]: Actions.GetVesselDeploymentStatusOperational
    [DeploymentType.Traffic]: Actions.GetVesselDeploymentStatusTraffic
}

export function showVesselDetailModal(userId: GuidType): Actions.ShowVesselDetailModalAction {
    return {
        type: ActionType.SHOW_VESSEL_DETAIL_MODAL,
        payload: userId,
    }
}

export function closeVesselDetailModal(): Actions.CloseVesselDetailModalAction {
    return {
        type: ActionType.CLOSE_VESSEL_DETAIL_MODAL,
    }
}

export function requestVesselDeploymentStatus(): Actions.RequestVesselDeploymentStatus {
    return {
        type: ActionType.REQUEST_VESSEL_DEPLOYMENT_STATUS,
    }
}

export function getPolicyLicenseSummery(
    payload: LicenseSummery,
): Actions.PolicyLicenseSummeryAction {
    return {
        type: ActionType.POLICY_LICENSE_SUMMERY,
        payload,
    }
}

export function getVesselDeploymentStatus<K extends DeploymentType>(
    payload: PayloadUnion[K],
    key: K,
): VesselDeploymentReturnTypes[K] {
    return {
        [DeploymentType.Operational]: {
            type: ActionType.GET_VESSEL_DEPLOYMENT_STATUS_OPERATIONAL,
            payload,
        },
        [DeploymentType.Traffic]: {
            type: ActionType.GET_VESSEL_DEPLOYMENT_STATUS_TRAFFIC,
            payload,
        },
    }[key] as VesselDeploymentReturnTypes[K]
}

export function setVesselDeploymentStatus(
    deploymentStatus: VesselDeploymentStatus,
): Actions.SetVesselDeploymentStatus {
    return {
        type: ActionType.SET_VESSEL_DEPLOYMENT_STATUS,
        payload: deploymentStatus,
    }
}

export function setInitialValue(payload: VesselConfigModal): Actions.SetInitialValueAction {
    return {type: ActionType.SET_INITIAL_VALUE, payload}
}

export function setChangeBusinessNetworks(
    value: string[],
): Actions.SetChangeBusinessNetworksAction {
    return {type: ActionType.CHANGE_BUSINESS_NETWORKS, payload: value}
}

export function setChangeOtNetworks(value: string[]): Actions.SetChangeOtNetworksAction {
    return {type: ActionType.CHANGE_OT_NETWORKS, payload: value}
}

export function setChangeCrewNetworks(value: string[]): Actions.SetChangeCrewNetworksAction {
    return {type: ActionType.CHANGE_CREW_NETWORKS, payload: value}
}

export function setChangeDashboardAgentIds(
    value: string[],
): Actions.SetChangeDashboardAgentIdsAction {
    return {type: ActionType.CHANGE_DASHBOARD_AGENT_IDS, payload: value}
}

export function setChangeVesselEmail(
    value: string | null | undefined,
): Actions.SetChangeVesselEmailAction {
    return {
        type: ActionType.CHANGE_VESSEL_EMAIL,
        payload: value,
    }
}

export function setValidChange(payload: {
    inputType: ConfigInputType
    value: boolean
}): Actions.SetValidChangeAction {
    return {type: ActionType.SET_VALID_CHANGE, payload}
}

export function toggleDetailExpanded(
    detailExpanded: DetailExpandedType,
): Actions.ToggleDetailExpandedAction {
    return {
        type: ActionType.TOGGLE_DETAIL_EXPANDED,
        payload: detailExpanded,
    }
}

export function receivedLocationsControl(
    items: LocationInIsolationMode[],
): Actions.ReceiveLocationControlAction {
    return {
        type: ActionType.RECEIVE_LOCATIONS_CONTROL,
        payload: items,
    }
}

export function setSelectedIncidentPolicy(
    selectedIncidentPolicy: GuidType | undefined,
): Actions.SetSelectedIncidentPolicyAction {
    return {
        type: ActionType.SET_SELECTED_INCIDENT_POLICY,
        payload: selectedIncidentPolicy,
    }
}

export function setSelectedMetricsPolicy(
    selectedMetricsPolicy: GuidType | undefined,
): Actions.SetSelectedMetricsPolicyAction {
    return {
        type: ActionType.SET_SELECTED_METRICS_POLICY,
        payload: selectedMetricsPolicy,
    }
}

export function setSelectedEnforcementPolicy(
    selectedEnforcementPolicy: GuidType | undefined,
): Actions.SetSelectedEnforcementPolicyAction {
    return {
        type: ActionType.SET_SELECTED_ENFORCEMENT_POLICY,
        payload: selectedEnforcementPolicy,
    }
}

export function displaySetOTSourcesModal(
    oTSourceModalDetails: OTSourceModalDetails,
): Actions.DisplaySetOTSourcesModalAction {
    return {
        type: ActionType.OPEN_SET_OT_SOURCES_MODAL,
        payload: oTSourceModalDetails,
    }
}

export function closeSetOTSourcesModal(): Actions.CloseSetOTSourcesModalAction {
    return {
        type: ActionType.CLOSE_SET_OT_SOURCES_MODAL,
    }
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function setFilter(filteredLocations: Location[]): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER, payload: filteredLocations}
}

export function setChangeOTSourcesType(value: string): Actions.ChangeOTSourceTypeAction {
    return {type: ActionType.CHANGE_OT_SOURCES_TYPE, payload: value}
}

export function setChangeOTSourcesIp(
    newIpValue: string,
    label: 'source' | 'destination',
    index?: number,
): Actions.ChangeOTSourceIPAction {
    return {type: ActionType.CHANGE_OT_SOURCES_IP, payload: {newIpValue, label, index}}
}

export function setChangeOTSourcesPort(
    newPortValue: string,
    label: 'source' | 'destination',
    index?: number,
): Actions.ChangeOTSourcePortAction {
    return {type: ActionType.CHANGE_OT_SOURCES_PORT, payload: {newPortValue, label, index}}
}

export function updateOTSourcesDisplay(): Actions.UpdateOTSourcesDisplayAction {
    return {
        type: ActionType.UPDATE_OT_DISPLAY_AFTER_SAVE,
    }
}
