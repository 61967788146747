import * as Styled from './_styled/asset-info-area.styled'
import {OverviewRecord} from '../tabs/overview-reselector'
import {DataCell} from './data-cell'
import {usePagedIndicators} from '../../contexts/indicators/use-paged-indicators'
import {useState, useEffect} from 'react'
import {OsInfo, getOsData} from './get-os-data'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {assetDetailsCachedReselector} from '../../../../components/asset-details/re-reselectors/asset-details-cached-reselector'
import useTypedSelector from '../../../../hooks/use-typed-selector'

interface AssetInfoProps {
    overview: OverviewRecord
}

export function AssetInfo({overview}: AssetInfoProps): JSX.Element {
    const {assetId} = usePagedIndicators()
    const asset = useTypedSelector((state) => assetDetailsCachedReselector(state, assetId))

    const [osData, setOsData] = useState<OsInfo | null>(null)
    const {width} = useDimensions()
    useEffect(() => {
        if (asset.lastEvent !== 'unknown') {
            getOsData(assetId, setOsData)
        }
    }, [asset.lastEvent, assetId])

    return (
        <Styled.AssetInfoArea width={width}>
            <DataCell gridArea="VESSEL" label="Vessel">
                {overview.location}
            </DataCell>
            <DataCell gridArea="ASSET-VALUE" label="Asset Value">
                {overview.assetValueLevel}
            </DataCell>
            <DataCell gridArea="IP-ADDRESS" label="IP Address">
                {overview.assetCode}
            </DataCell>
            {osData && (
                <>
                    <DataCell gridArea="OS" label="OS">
                        {osData.os}
                    </DataCell>
                    <DataCell gridArea="OS-BUILD" label="Build">
                        {osData.build}
                    </DataCell>
                    <DataCell gridArea="OS-VERSION" label="Version">
                        {osData.version}
                    </DataCell>
                </>
            )}
        </Styled.AssetInfoArea>
    )
}
