import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const AnalysisPeriod = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.background.default};
`
interface Props {
    isInactive: boolean
}
export const FilterSearchWrapper = styled.div<Props>`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    cursor: pointer;
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`

interface Props {
    isInactive: boolean
}

export const SearchInput = styled.div`
    display: inline-block;
    width: 200px;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    outline: none;
    align-items: center;
    padding: ${spacing(1)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    padding: 1px;
`

export const SavedFiltersWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`

export const PeriodDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: ${spacing(1)};
    padding: ${spacing(2)};
    background: white;
    flex-wrap: wrap;
    z-index: 1;
    pointer-events: auto;
`

export const ValueOption = styled.div`
    position: relative;
    cursor: pointer;
    ${mediumSmallFont()};
    padding: ${spacing(1)};
    &:hover {
        background-color: rgba(206, 218, 236, 1);
    }
`
