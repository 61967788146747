import {usePagedIndicators} from '../../../../contexts/indicators/use-paged-indicators'
import {Card} from '../../common/cards/card.styled'
import {NoDataMessage} from '../../common/cards/no-data-message.styled'

function hasValue(value: string | undefined): boolean {
    return value != undefined && value.length > 0
}

function getMessage(fromDate: string | undefined, toDate: string | undefined): string {
    return hasValue(fromDate) || hasValue(toDate)
        ? 'For the selected period, there are no indicators for this asset'
        : 'There are no indicators for this asset'
}

export function NoIndicatorDataCard(): JSX.Element {
    const {fromDate, toDate} = usePagedIndicators()

    return (
        <Card>
            <NoDataMessage id="indicators_no_data_message">
                {getMessage(fromDate, toDate)}
            </NoDataMessage>
        </Card>
    )
}
