import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellHostLogExceptions(): JSX.Element {
    const {data, initialData, displayData, changeHostLogExceptions} = useVesselConfig()
    const changed = data.hostLogExceptions?.toString() !== initialData.hostLogExceptions?.toString()
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeHostLogExceptions(e.target.value.split(','))
    }
    return (
        <DataInput
            label="Host Log Exceptions"
            initialValue={displayData.hostLogExceptions}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
