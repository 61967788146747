import {Line} from './new-open-incidents-details.styled'

interface Props {
    index: number
    lastItem: number
}
export function TimelineCircle({index, lastItem}: Props): JSX.Element {
    return (
        <div style={{position: 'absolute', top: '40px', left: `${index * 120 + 15}px`}}>
            <Line lastItem={lastItem - index === 1} />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
            >
                <ellipse cx="12.1522" cy="10" rx="11.7504" ry="10" fill="#424B59" />
            </svg>
        </div>
    )
}
