import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleNetwork} from '../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {NetworkType} from '../../../context/types/network-type'
import {StatusRowWrapper} from '../network-asset-state-filter/network-asset-state-filter.styled'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface Props {
    networkLabel: NetworkType
}
export function NetworkRow({networkLabel}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {networks} = useTypedSelector(networkAssetsFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)
    const allNetworks = new Array<NetworkType>(
        NetworkType.NetworkTypeBusiness,
        NetworkType.NetworkTypeCrew,
        NetworkType.NetworkTypeOT,
        NetworkType.NetworkTypeOther,
    )

    function isChecked(givenValue: NetworkType): boolean {
        return !networks ? true : networks.includes(givenValue)
    }

    function setChecked(currentSelectedNetwork: NetworkType, newValue: boolean): void {
        dispatch(toggleNetwork(allNetworks, currentSelectedNetwork, newValue))
        dispatch(
            logFilterByTypes(
                'Network types',
                `Network value: ${currentSelectedNetwork}`,
                newValue,
                AuditLogPageType.NETWORK_INVENTORY,
            ),
        )
    }

    const formmattedLabel =
        networkLabel === NetworkType.NetworkTypeOther ? networkLabel : `${networkLabel} Network`

    return (
        <StatusRowWrapper>
            <Checkbox
                id={`${PageType.NETWORK_ASSETS}_filter-bar_network-filter_${networkLabel}`}
                label={formmattedLabel}
                checked={isChecked(networkLabel)}
                onChange={(newValue) => setChecked(networkLabel, newValue)}
                isInactive={isInactive ?? false}
            />
        </StatusRowWrapper>
    )
}
