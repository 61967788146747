import React, {useEffect, useRef} from 'react'
import * as Style from './modal.styled'
import {ModalProps} from './notification-modal'
import {Spinner} from '../loading/spinner'

interface ActionModalProps extends ModalProps {
    onConfirm?: () => void
    closeOnConfirm?: boolean
    isLoading?: boolean
    errorText?: string
    // Actions button provided by
    // the component that uses this modal,
    // if this is provided, we won't use the
    // onConfirm callback.
    actions?: React.ReactNode
}

/**
 * A modal component for user to act on. Contains a cancel and an action button.
 * Side effect can be executed by passing a callback to onClose and onConfirm parameters.
 * Pass in custom string to confirmBtnText, message and title to format the
 * modal accordingly.
 * This modal does not manage its own state and instead relies on its ancestor
 * to manage its state through setting `isOpen` property. It tracks `isOpen`
 * property and will open and close the modal accordingly.
 *
 * @param actionModal - Properties of modal
 * @param actionModal.isOpen - State of display for modal
 * @param actionModal.onClose - Callback function when closing the modal. Needs
 * to change state of `actionModal.isOpen` to false.
 * @param actionModal.onConfirm - Callback function for action button. Can
 * execute side effect here. The callback here will be followed by call to
 * `actionModal.onClose`
 * @param actionModal.confirmBtnText - Text on confirm action button
 * @param actionModal.message - Text for body of modal
 * @param actionModal.title - Text for header of modal
 * @param actionModal.isLoading - Will show spinner in the confirm button if true
 * @param actionModal.closeOnConfirm - Will close modal after confirm button is clicked if true
 * @param actionModal.errorText - Text to display as error message, will only display if its a valid string
 *
 */
export function ActionModal({
    isOpen,
    onClose,
    onConfirm,
    confirmBtnText = 'Yes, submit',
    message = 'Are you sure you want to make this action?',
    title = 'Action',
    isLoading = false,
    closeOnConfirm = true,
    actions,
}: ActionModalProps): JSX.Element {
    const modalRef = useRef<HTMLDialogElement | null>(null)
    useEffect(() => {
        const modalElement = modalRef.current
        if (modalElement) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dialog = modalElement as any
            if (isOpen) {
                dialog.showModal()
            } else {
                dialog.close()
            }
        }
    }, [isOpen])
    const handleSubmitModal = () => {
        if (onConfirm) {
            onConfirm()
        }

        if (onClose && closeOnConfirm) {
            onClose()
        }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (onClose && event.key === 'Escape') {
            onClose()
        }
    }

    return (
        <Style.Dialog ref={modalRef} onKeyDown={handleKeyDown}>
            <Style.Wrapper>
                <Style.TopDiv>
                    <Style.Title>{title}</Style.Title>
                    <Style.Message>{message}</Style.Message>
                </Style.TopDiv>

                <Style.ActionDiv>
                    {actions ? (
                        actions
                    ) : (
                        <>
                            <Style.CancelBtn disabled={isLoading} onClick={onClose}>
                                No, cancel
                            </Style.CancelBtn>
                            <Style.ConfirmBtn onClick={handleSubmitModal}>
                                {isLoading && <Spinner />}
                                {confirmBtnText}
                            </Style.ConfirmBtn>
                        </>
                    )}
                </Style.ActionDiv>
            </Style.Wrapper>
        </Style.Dialog>
    )
}
