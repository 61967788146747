import {WarningMessage} from '../input-area/data-cell.styled'
import {SubmitArea} from '../submit-area/submit-area'
import * as Styled from './data-area.styled'

export function ChangeNotSaved(): JSX.Element {
    return (
        <>
            <Styled.Text>Save failed</Styled.Text>
            <WarningMessage>The email address is already used by an existing user.</WarningMessage>
            <Styled.Actions>
                <SubmitArea closeOnly />
            </Styled.Actions>
        </>
    )
}
