import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${spacing(1)} ${spacing(2)} ${spacing(1)} ${spacing(1)};
`

export const ToggleButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${mediumSmallFont()}
    column-gap: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
