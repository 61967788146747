import {Value} from './_styled/value.styled'
import {TimeStamp} from './_styled/timestamp.styled'
import {Label} from './_styled/label.styled'
import {Wrapper} from './_styled/wrapper.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {lastUpdatedSelector} from '../../../../../store/state/locations/selectors'

export function DataLastUpdated(): JSX.Element {
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    return (
        <Wrapper>
            <Label>Data last updated:</Label>
            {lastUpdate ? (
                <Value>
                    {lastUpdate.fromNow()}
                    <TimeStamp>({lastUpdate.format('HH:mm')})</TimeStamp>
                </Value>
            ) : (
                <Value>N/A</Value>
            )}
        </Wrapper>
    )
}
