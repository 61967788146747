import {
    allKeys,
    License,
    LicenseKeysTypes,
    LicenseKeyType,
    LicenseValue,
} from '../../../store/state/config/state'
import {LicenseSummery, LicenseSummeryLicense} from '../contexts/type/license-types'

export type LicenseSummeryFleetConfigData = Record<
    LicenseKeyType<LicenseKeysTypes>,
    LicenseSummeryFleetConfigDataItem
>
export type LicenseSummeryFleetConfigDataItem = LicenseValue & {
    summeryData: LicenseSummeryLicense<LicenseKeysTypes> | null
    isExpired: boolean
    key: LicenseKeyType<LicenseKeysTypes>
}

export function getDateAwareLicense(
    currentLicenseSummery: LicenseSummery,
    fleetConfigLicense: Partial<
        Record<LicenseKeyType<LicenseKeysTypes>, License<LicenseKeysTypes>>
    >,
): LicenseSummeryFleetConfigData {
    const retObj: Partial<LicenseSummeryFleetConfigData> = {}

    for (const key of allKeys) {
        const summeryValue: LicenseSummeryLicense<LicenseKeysTypes> | undefined =
            currentLicenseSummery[key] ?? null
        const fleetConfigValue = fleetConfigLicense[key]
        let isExpired = false
        let enabled = false
        let numberOfLicenses = null
        let endTimestamp: Date | null = null
        if (fleetConfigValue) {
            if (fleetConfigValue.value.endTimestamp) {
                isExpired = fleetConfigValue.value.endTimestamp.getTime() < Date.now()
                endTimestamp = fleetConfigValue.value.endTimestamp
            }
            enabled = fleetConfigValue.value.enabled
            numberOfLicenses = fleetConfigValue.value.numberOfLicenses
        }
        retObj[key] = {
            summeryData: summeryValue,
            isExpired,
            numberOfLicenses,
            enabled,
            endTimestamp,
            key,
        }
    }
    return retObj as LicenseSummeryFleetConfigData
}
