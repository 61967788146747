import {usePagedIndicators} from '../../../contexts/indicators/use-paged-indicators'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfIndicators, selectedPage, pageSize} = usePagedIndicators()

    if (
        totalNumberOfIndicators == undefined ||
        pageSize == undefined ||
        selectedPage == undefined
    ) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfIndicators
            ? totalNumberOfIndicators
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfIndicators !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfIndicators}
                />
            )}
        </>
    )
}
