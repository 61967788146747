import {DataLoading} from '../../../../components/data-loading/data-loading'
import {Content} from './loading-data.styled'

export function LoadingData(): JSX.Element {
    return (
        <Content>
            <DataLoading />
        </Content>
    )
}
