import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const DetailsAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
    border-bottom: 2px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
`

export const DetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    gap: ${spacing(1)};
`

interface DataCellProps {
    gridArea?: string
}

export const DataCell = styled.div<DataCellProps>`
    flex: 1;
    grid-area: ${(props) => props.gridArea};
    padding: ${spacing(1)};
`
export const DetailPanelSection = styled.section`
    width: 100%;
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
`
export const DetailedAreaWrapper = styled(DetailsAreaWrapper)`
    border-bottom: none;
`
