import {ShowPagingButtons} from './show-buttons'

export const FOOTER_FLEX_PIVOT_WIDTH = 600

const MAXIMUM_NUUMBER_OF_PAGES_TO_SHOW = 999
export function getMaximumNumberOfPagesToShow(totalNumberOfPages: number): number {
    return totalNumberOfPages < MAXIMUM_NUUMBER_OF_PAGES_TO_SHOW
        ? totalNumberOfPages
        : MAXIMUM_NUUMBER_OF_PAGES_TO_SHOW
}

export function getPagesToShow(width: number, showFilterBar: boolean = false): number {
    const MAX_PAGES = 19
    const BUTTON_WRAPPER_PADDING = 40
    const BUTTON_WIDTH_WITH_MARGINS = 37
    const RECORDSET_SPACER = showFilterBar ? 600 : 200

    const pagesToShow = Math.floor(
        ((width >= FOOTER_FLEX_PIVOT_WIDTH ? width - RECORDSET_SPACER : width) -
            BUTTON_WRAPPER_PADDING) /
            BUTTON_WIDTH_WITH_MARGINS,
    )
    return pagesToShow > MAX_PAGES ? MAX_PAGES : pagesToShow
}

function showJump100Pages(width: number, totalNumberOfPages: number): boolean {
    const MIN_WIDTH_FOR_THOUSANDS = 800
    return totalNumberOfPages > 100 && width >= MIN_WIDTH_FOR_THOUSANDS
}

function showJump10Pages(width: number, totalNumberOfPages: number): boolean {
    const LAYOUT1_MIN_WIDTH_FOR_HUNDREDS = 500
    const LAYOUT2_MIN_WIDTH_FOR_HUNDREDS = 700

    return (
        totalNumberOfPages > 10 &&
        ((width >= LAYOUT1_MIN_WIDTH_FOR_HUNDREDS && width < FOOTER_FLEX_PIVOT_WIDTH) ||
            width >= LAYOUT2_MIN_WIDTH_FOR_HUNDREDS)
    )
}

function showArrows(width: number): boolean {
    const LAYOUT1_MIN_WIDTH_FOR_ARROWS = 450
    const LAYOUT2_MIN_WIDTH_FOR_ARROWS = 600

    return (
        (width >= LAYOUT1_MIN_WIDTH_FOR_ARROWS && width < FOOTER_FLEX_PIVOT_WIDTH) ||
        width >= LAYOUT2_MIN_WIDTH_FOR_ARROWS
    )
}

function showEndNumbers(width: number): boolean {
    const LAYOUT1_MIN_WIDTH_FOR_END_NUMBERS = 370
    const LAYOUT2_MIN_WIDTH_FOR_END_NUMBERS = 360

    return (
        (width >= LAYOUT1_MIN_WIDTH_FOR_END_NUMBERS && width < FOOTER_FLEX_PIVOT_WIDTH) ||
        width >= LAYOUT2_MIN_WIDTH_FOR_END_NUMBERS
    )
}

export function getPagingButtonsToShow(
    width: number,
    totalNumberOfPages: number,
): ShowPagingButtons {
    return {
        jump100Pages: showJump100Pages(width, totalNumberOfPages),
        jump10Pages: showJump10Pages(width, totalNumberOfPages),
        arrows: showArrows(width),
        endNumbers: showEndNumbers(width),
    } as ShowPagingButtons
}
