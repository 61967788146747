import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {ContentArea} from '../../general/content-area/content-area.styled'
import {WidgetWrapper} from './widget-wrapper'
import {LatestIncidentWidget} from './latest-incident-widget'
import {CurrentStatusWidget} from './current-status-widget'
import {CurrentAssignmentWidget} from './current-assignment-widget'
import {TypesIncidentsWidget} from './types-incidents-widget'
import {SeverityIncidentsWidget} from './severity-incidents-widget'
import {TrendsIncidentsWidget} from './trends-incidents-widget'
import {IncidentsPerformanceWidget} from './incidents-response-performance-widget'
import {VesselsPerformanceWidget} from './vessels-performance-widget'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import {IncidentWidgetType} from '../contexts/types/incident-widget-type'

export function PopulatedData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const {selectedIncidentWidget, latestIncidentResponse} = useIncidentsWidget()
    const lessPadding = selectedIncidentWidget === IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE

    return (
        <WidgetWrapper level={latestIncidentResponse.level} gridLayout={gridLayout}>
            <ContentArea gridLayout={gridLayout} centredContent={true} lessPadding={lessPadding}>
                <GetWidgetType />
            </ContentArea>
        </WidgetWrapper>
    )
}
function GetWidgetType(): JSX.Element {
    const {selectedIncidentWidget} = useIncidentsWidget()

    switch (selectedIncidentWidget) {
        case IncidentWidgetType.CURRENT_STATUS:
            return <CurrentStatusWidget />
        case IncidentWidgetType.CURRENT_ASSIGNMENT:
            return <CurrentAssignmentWidget />
        case IncidentWidgetType.TYPES_INCIDENTS:
            return <TypesIncidentsWidget />
        case IncidentWidgetType.SEVERITY_INCIDENTS:
            return <SeverityIncidentsWidget />
        case IncidentWidgetType.RAISED_INCIDENTS_TRENDS:
            return <TrendsIncidentsWidget />
        case IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE:
            return <IncidentsPerformanceWidget />
        case IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE:
            return <VesselsPerformanceWidget />
        case IncidentWidgetType.LATEST_INCIDENT:
        default:
            return <LatestIncidentWidget />
    }
}
