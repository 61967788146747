import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

interface ButtonProps {
    isChanged: boolean
}

export const Button = styled.button<ButtonProps>`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 3px;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    ${(props) => (props.isChanged ? '' : 'pointer-events: none; opacity: 0.5;')}
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${spacing(2)};
    background-color: ${(props) => props.theme.colors.background.default};
`

export const WarningMessage = styled.div`
    text-align: center;
    ${smallFont()}
    color: ${(props) => props.theme.incidents.incidentStatus.new};
    padding-top: ${spacing(1)};
`
