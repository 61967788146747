import styled from 'styled-components'
import {mediumSmallFont} from '../../../theme/font-styles'
import {spacing} from '../../../theme/spacing'

export const NoDataLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.75rem;
`

export const NoDataMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: ${spacing(10)} 0;
    ${mediumSmallFont()};
`
