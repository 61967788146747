import * as Styled from './submit-area.styled'
import {CancelButton} from './cancel-button'
import {AddButton} from './add-button'

export function SubmitArea(): JSX.Element {
    return (
        <Styled.ButtonsArea id="edit-asset-ButtonsArea">
            <CancelButton />
            <AddButton />
        </Styled.ButtonsArea>
    )
}
