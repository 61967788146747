import {Button} from '../row-actions/common/button.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {IncidentStatusWrapper, Square, StatusAnnotation} from './incident-status.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {StatusUpdatePopover} from './incident-status-popover'
import {addAnnotation, displayStatus, getNextIncidentStatuses} from '../../../data-helpers'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import {useEffect, useRef} from 'react'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {SaveMessage} from '../../../contexts/types/save-note-result'

interface IncidentStatusValueProps {
    incidentStatus: IncidentStatus
    id: GuidType
}

export function IncidentStatusValue({id, incidentStatus}: IncidentStatusValueProps): JSX.Element {
    const {width} = useDimensions()
    const {value: dropdownOpen, setValue: setDropdownOpen} = useToggle()
    const {saveResultMessage} = usePagedIncidents()
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setDropdownOpen(false))

    useEffect(() => {
        if (
            saveResultMessage?.message === SaveMessage.STATUS_UPDATED &&
            saveResultMessage.identity === id
        ) {
            setDropdownOpen(false)
        }
    }, [id, saveResultMessage?.message, saveResultMessage?.identity, setDropdownOpen])

    return (
        <IncidentStatusWrapper width={width} ref={clickOutside}>
            <Square incidentStatus={incidentStatus} id={`status-${incidentStatus}-${id}`}>
                {displayStatus(incidentStatus)} <br />
                <StatusAnnotation id={`status-annotation-${incidentStatus}-${id}`}>
                    {addAnnotation(incidentStatus)}
                </StatusAnnotation>
            </Square>
            <Button
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                id={`expand-details-button_${id}`}
            >
                <Icon glyph={dropdownOpen ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            </Button>

            {dropdownOpen && (
                <StatusUpdatePopover
                    id={id}
                    currentStatus={incidentStatus}
                    avaliableStatuses={getNextIncidentStatuses(incidentStatus)}
                />
            )}
        </IncidentStatusWrapper>
    )
}
