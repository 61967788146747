import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../../../store/state/locations/state'
// import {usePolicyManagement} from '../../../../contexts/policy-management/use-policy-management'
import * as Styles from './_styled/vessel-filters.styled'

interface Props {
    locationId: LocationIdType
    checked: boolean
    setChecked: (locationId: LocationIdType, checked: boolean) => void
}

export function VesselRow({locationId, checked, setChecked}: Props): JSX.Element {
    const location = useTypedSelector(locationMapSelector).get(locationId)
    // const {assignPolicyDialogState} = usePolicyManagement()

    // const isInactive = assignPolicyDialogState.currentLocations?.includes(locationId)

    return (
        <Styles.VesselRow>
            <Checkbox
                id={`filter-${locationId}`}
                checked={checked}
                onChange={() => setChecked(locationId, !checked)}
                label={location?.description || 'Unknown'}
            />
        </Styles.VesselRow>
    )
}
