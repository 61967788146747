import {RadioButton} from '../../../../components/form-elements/radio-button'
import * as Styles from './sort-order-dialog.styles'
import {SortField} from './values'

interface Props {
    sortKey: string
    sortableFields: SortField[]
    groupName: string
    optionSelected: (key: string) => void
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SortOrderDialog({
    sortKey,
    sortableFields,
    groupName,
    optionSelected,
}: Props): JSX.Element {
    return (
        <Styles.Options>
            {sortableFields.map((sortableField) => (
                <Styles.Option key={sortableField.key}>
                    <RadioButton
                        sortKey={sortKey}
                        sortableField={sortableField}
                        groupName={groupName}
                        optionSelected={optionSelected}
                    />
                </Styles.Option>
            ))}
        </Styles.Options>
    )
}
