import * as Styled from './severity-filter.styled'
import {
    IncidentSeverityValue,
    IncidentSeverityValues,
} from '../../../../../../values/incident-response-values'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

import {getPositionOnDonut} from './severity-filter-data-helper'
import {useDispatch} from 'react-redux'
import {incidentsFilterSelector} from '../../../../../../store/state/incidents-filter/selectors'
import {setFilteredIncidentSeverity} from '../../../../../../store/state/incidents-filter/action-creators'
import {usePagedIncidents} from '../../../../contexts/use-paged-incidents'
import {severities} from '../../../filter-bar/incident-severity/incident-severity-filter'
import {remove} from 'lodash'
import {logFilterByTypes} from '../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../../store/state/audit-log/state'

export function SeverityFilter(): JSX.Element {
    const {lowIncidents, mediumIncidents, highIncidents, criticalIncidents} = usePagedIncidents()

    const positionOnDonut = getPositionOnDonut(
        lowIncidents,
        mediumIncidents,
        highIncidents,
        criticalIncidents,
    )
    const dispatch = useDispatch()
    let {filterBySeverities} = useTypedSelector(incidentsFilterSelector)
    function onClickWrapper(filterBySeveritiesL: IncidentSeverityValue) {
        if (!filterBySeverities) {
            filterBySeverities = []
        }
        filterBySeverities = [...filterBySeverities]
        if (filterBySeverities.length === severities.flat().length) {
            remove(filterBySeverities, (s) => filterBySeveritiesL !== s)
        } else if (!filterBySeverities.find((s) => filterBySeveritiesL === s)) {
            filterBySeverities.push(filterBySeveritiesL)
        } else {
            remove(filterBySeverities, (s) => filterBySeveritiesL === s)
        }
        dispatch(
            logFilterByTypes(
                'filterBySeverities',
                filterBySeverities.join(','),
                true,
                AuditLogPageType.INCIDENTS,
            ),
        )
        dispatch(setFilteredIncidentSeverity(filterBySeverities))
    }

    function activeFilter(v: IncidentSeverityValues): boolean {
        return (
            filterBySeverities?.includes(v) ||
            filterBySeverities === undefined ||
            filterBySeverities?.length === 0
        )
    }

    return (
        <>
            <Styled.DonutWrapper>
                <Styled.DonutIndicator>
                    <Styled.DonutBorder>
                        {lowIncidents !== 0 && (
                            <Styled.DonutLayer
                                onClick={() => onClickWrapper(IncidentSeverityValues.LOW)}
                                severityLevel={IncidentSeverityValues.LOW}
                                positionOnDonut={positionOnDonut.lowPosition}
                                activeFilter={activeFilter(IncidentSeverityValues.LOW)}
                                firstElement={true}
                                id="donut-layer-severity-lowIncidents"
                            />
                        )}
                        {mediumIncidents !== 0 && (
                            <Styled.DonutLayer
                                onClick={() => onClickWrapper(IncidentSeverityValues.MEDIUM)}
                                severityLevel={IncidentSeverityValues.MEDIUM}
                                positionOnDonut={positionOnDonut.mediumPosition}
                                activeFilter={activeFilter(IncidentSeverityValues.MEDIUM)}
                                id="donut-layer-severity-mediumIncidents"
                            />
                        )}
                        {highIncidents !== 0 && (
                            <Styled.DonutLayer
                                onClick={() => onClickWrapper(IncidentSeverityValues.HIGH)}
                                severityLevel={IncidentSeverityValues.HIGH}
                                positionOnDonut={positionOnDonut.highPosition}
                                activeFilter={activeFilter(IncidentSeverityValues.HIGH)}
                                id="donut-layer-severity-highIncidents"
                            />
                        )}
                        {criticalIncidents !== 0 && (
                            <Styled.DonutLayer
                                onClick={() => onClickWrapper(IncidentSeverityValues.CRITICAL)}
                                severityLevel={IncidentSeverityValues.CRITICAL}
                                positionOnDonut={positionOnDonut.criticalPosition}
                                activeFilter={activeFilter(IncidentSeverityValues.CRITICAL)}
                                id="donut-layer-severity-criticalIncidents"
                            />
                        )}
                    </Styled.DonutBorder>
                    <Styled.DonutCenter />
                </Styled.DonutIndicator>
            </Styled.DonutWrapper>
            <div style={{display: 'flex', columnGap: '5px'}}>
                {lowIncidents !== 0 && (
                    <Styled.FilterCard
                        onClick={() => onClickWrapper(IncidentSeverityValues.LOW)}
                        activeFilter={activeFilter(IncidentSeverityValues.LOW)}
                        severityLevel={IncidentSeverityValues.LOW}
                        id="filter-card-severity-lowIncidents"
                    >
                        <Styled.FilterText>LOW</Styled.FilterText>
                        <Styled.FilterCount id="lowIncidents-count">
                            {lowIncidents}
                        </Styled.FilterCount>
                    </Styled.FilterCard>
                )}
                {mediumIncidents !== 0 && (
                    <Styled.FilterCard
                        onClick={() => onClickWrapper(IncidentSeverityValues.MEDIUM)}
                        activeFilter={activeFilter(IncidentSeverityValues.MEDIUM)}
                        severityLevel={IncidentSeverityValues.MEDIUM}
                        id="filter-card-severity-mediumIncidents"
                    >
                        <Styled.FilterText>MED</Styled.FilterText>
                        <Styled.FilterCount id="mediumIncidents-count">
                            {mediumIncidents}
                        </Styled.FilterCount>
                    </Styled.FilterCard>
                )}
                {highIncidents !== 0 && (
                    <Styled.FilterCard
                        onClick={() => onClickWrapper(IncidentSeverityValues.HIGH)}
                        activeFilter={activeFilter(IncidentSeverityValues.HIGH)}
                        severityLevel={IncidentSeverityValues.HIGH}
                        id="filter-card-severity-highIncidents"
                    >
                        <Styled.FilterText>HIGH</Styled.FilterText>
                        <Styled.FilterCount id="highIncidents-count">
                            {highIncidents}
                        </Styled.FilterCount>
                    </Styled.FilterCard>
                )}
                {criticalIncidents !== 0 && (
                    <Styled.FilterCard
                        onClick={() => onClickWrapper(IncidentSeverityValues.CRITICAL)}
                        activeFilter={activeFilter(IncidentSeverityValues.CRITICAL)}
                        severityLevel={IncidentSeverityValues.CRITICAL}
                        id="filter-card-severity-criticalIncidents"
                    >
                        <Styled.FilterText>CRITICAL</Styled.FilterText>
                        <Styled.FilterCount id="criticalIncidents-count">
                            {criticalIncidents}
                        </Styled.FilterCount>
                    </Styled.FilterCard>
                )}
            </div>
        </>
    )
}
