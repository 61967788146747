import {useContext, useEffect} from 'react'
import Overlay from '../../../modals/shared-components/overlay'
import {LocationInventoryPageContext} from '../contexts/location-inventory-page-context'
import {Button} from '../../../components/base/button'
import {ActionType as AssetUploadModalActionType} from '../contexts/state/asset-upload-modal/action-type'
import CLOSE_ICON from '../../../@assets/icons/close-panel.svg'
import DOWNLOAD_ICON from '../../../@assets/icons/download-icon.svg'
import {NativeDragAndDrop} from './drag-and-drop/components/native-drag-and-drop'
import {useFileUpload} from './drag-and-drop/components/use-file-upload'
import {ProcessingStatus} from '../../../values/processing-save-state-enum'
import {ExcelIcon} from './excel-icon'
import {format} from 'date-fns'
import {AssetBulkFileStatus} from '../contexts/types/ot-types'
import {FileStatusIcon} from './file-status-icon'
import {
    UploadError,
    TitleText,
    Content,
    TitleRow,
    CloseRow,
    GrayDivider,
    UploadWarningText,
    TemplateText,
    DragAndDropWrapper,
    FileListTitle,
    FileListWrapper,
    FileListItemsWrapper,
    FileItem,
    FileItemElement,
    ModalDialog,
} from './asset-upload-modal.styled'

function FileList(): JSX.Element {
    const {fileList} = useFileUpload()

    function formatIsoDateToDayMonthYear(date: string | undefined): string {
        if (date == null) {
            return ''
        }

        return format(new Date(date), 'dd/MM/yy')
    }

    function statusToText(status: AssetBulkFileStatus | undefined): string {
        switch (status) {
            case AssetBulkFileStatus.UPLOADING:
                return 'Uploading'
            case AssetBulkFileStatus.UPLOAD_ERROR:
                return 'Upload error'
            case AssetBulkFileStatus.UPLOADED:
                return 'Uploaded'
            case AssetBulkFileStatus.PROCESSING:
                return 'Processing request'
            case AssetBulkFileStatus.COMPLETED:
                return 'Request completed'
            case AssetBulkFileStatus.REJECTED:
                return 'Request rejected'
            case AssetBulkFileStatus.CANCELLED:
                return 'Request aborted'
            default:
                return 'Unknown'
        }
    }

    return (
        <FileListWrapper>
            <FileListTitle>
                <svg
                    width="20"
                    height="23"
                    viewBox="0 0 20 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 10.61V4.5C18 3.96957 17.7893 3.46086 17.4142 3.08579C17.0391 2.71071 16.5304 2.5 16 2.5H11.82C11.4 1.34 10.3 0.5 9 0.5C7.7 0.5 6.6 1.34 6.18 2.5H2C0.9 2.5 0 3.4 0 4.5V18.5C0 19.0304 0.210714 19.5391 0.585786 19.9142C0.960859 20.2893 1.46957 20.5 2 20.5H8.11C9.37 21.74 11.09 22.5 13 22.5C16.87 22.5 20 19.37 20 15.5C20 13.59 19.24 11.87 18 10.61ZM9 2.5C9.55 2.5 10 2.95 10 3.5C10 4.05 9.55 4.5 9 4.5C8.45 4.5 8 4.05 8 3.5C8 2.95 8.45 2.5 9 2.5ZM2 18.5V4.5H4V6.5H14V4.5H16V9.18C15.09 8.75 14.08 8.5 13 8.5H4V10.5H8.1C7.5 11.07 7.04 11.75 6.68 12.5H4V14.5H6.08C6.03 14.83 6 15.16 6 15.5C6 16.58 6.25 17.59 6.68 18.5H2ZM13 20.5C10.24 20.5 8 18.26 8 15.5C8 12.74 10.24 10.5 13 10.5C15.76 10.5 18 12.74 18 15.5C18 18.26 15.76 20.5 13 20.5ZM13.5 15.75L16.36 17.44L15.61 18.66L12 16.5V11.5H13.5V15.75Z"
                        fill="black"
                    />
                </svg>
                <h3>List of files</h3>
            </FileListTitle>

            <FileListItemsWrapper>
                {fileList.map((file, index) => (
                    <FileItem key={index}>
                        <ExcelIcon />
                        <FileItemElement flexGrow={3} color="#000C1E">
                            {file.name}
                        </FileItemElement>
                        <FileItemElement flexGrow={2} textAlign="center" color="#A7B0BE">
                            {formatIsoDateToDayMonthYear(file.createdAt)}
                        </FileItemElement>

                        <FileItemElement flexGrow={2} textAlign="center" color="#424B59">
                            {statusToText(file.status)}
                        </FileItemElement>

                        <FileItemElement flexGrow={2} textAlign="right">
                            <FileStatusIcon status={file.status} />
                        </FileItemElement>
                    </FileItem>
                ))}
            </FileListItemsWrapper>
        </FileListWrapper>
    )
}

export function AssetUploadModal(): JSX.Element | null {
    const {
        assetUploadModal,
        page: {
            state: {locationID},
        },
    } = useContext(LocationInventoryPageContext)
    const {setUploadingStatus, serverMessage, fetchFiles} = useFileUpload()

    const handleClose = () => {
        assetUploadModal.dispatch?.({
            type: AssetUploadModalActionType.TOGGLE_SHOW_ASSET_UPLOAD_MODAL,
            payload: {isOpen: false},
        })

        // Reset the processing state
        setUploadingStatus(ProcessingStatus.NOT_PROCESSING)

        // Clear all files
        assetUploadModal.dispatch?.({
            type: AssetUploadModalActionType.SET_FILES,
            payload: {
                files: [],
            },
        })

        // Clear error message
        assetUploadModal.dispatch?.({
            type: AssetUploadModalActionType.SET_SERVER_MESSAGE,
            payload: {
                type: 'success',
                message: '',
            },
        })
    }

    if (locationID !== null) {
        useEffect(() => fetchFiles(locationID), [])
    }

    return (
        <Overlay closing={false}>
            <ModalDialog>
                <Content>
                    <CloseRow>
                        <Button onClick={handleClose} variant="icon" size="xs">
                            <img src={CLOSE_ICON} alt="close" />
                        </Button>
                    </CloseRow>
                    <TitleRow>
                        <TitleText>Import Assets</TitleText>
                    </TitleRow>
                    <GrayDivider />
                    <UploadWarningText>
                        Please do not close this window during the upload process.
                    </UploadWarningText>
                    <TemplateText>
                        <span>Use Our Template</span>
                        <Button onClick={() => {}} variant="icon" size="sm">
                            <a href="/ot-inventory/OT Inventory Template.xlsx" target="_blank">
                                <img src={DOWNLOAD_ICON} alt="download" height={20} width={20} />
                            </a>
                        </Button>
                    </TemplateText>

                    <TemplateText>Import your own spreadsheet</TemplateText>
                    <DragAndDropWrapper>
                        <NativeDragAndDrop />
                    </DragAndDropWrapper>

                    {serverMessage.type !== null && (
                        <UploadError status={serverMessage.type}>
                            {serverMessage.message}
                        </UploadError>
                    )}

                    <FileList />
                </Content>
            </ModalDialog>
        </Overlay>
    )
}
