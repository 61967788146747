import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellMaxOSAge(): JSX.Element {
    const {data, initialData, displayData, changeMaxOSAge} = useVesselConfig()
    const changed = data.maxOSAge !== initialData.maxOSAge
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeMaxOSAge(e.target.value)
    }
    return (
        <DataInput
            label="Max OS Age (days)"
            gridArea="MAX-OS-AGE"
            initialValue={displayData.maxOSAge}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
