import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'
export const SHOW_FILTER_BAR_ON_BREAK_POINT = 1100

export const WideContentArea = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    width: 100%;
`

export const LHFilterBar = styled.div`
    padding: ${spacing(6)} 0;
    width: 360px;
    min-width: 320px;
    overflow-y: auto;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`
function narrowFilterLayout(width: number): boolean {
    return width > 400
}

interface FilterBarWrapperProps {
    width: number
}
export const FilterBarWrapper = styled.div<FilterBarWrapperProps>`
    min-width: 320px;
    width: ${(props) => (narrowFilterLayout(props.width) ? '388px' : 'fit-content')};
    display: flex;
    flex-direction: column;
    margin: ${spacing(12)} auto;
`
export const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
`
export function narrowLayoutFn(width: number): boolean {
    return width < 700
}
export const NoDataComponent = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-style: italic;
`
