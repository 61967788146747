export enum NmeaInventorySortType {
    FIRST_SEEN_TIMESTAMP = 'firstSeenTimestamp',
    LAST_SEEN_TIMESTAMP = 'lastSeenTimestamp',
    VESSEL_NAME = 'locationName',
    NMEA_TALKER = 'talkerName',
    NMEA_SENTENCE = 'sentenceName',
    RELAY_IP = 'relayIP',
    BROADCAST = 'broadcast',
    PARAMETERS = 'parameters',
}

export interface SortColumnType {
    orderBy: NmeaInventorySortType
    orderAscending: boolean
}

export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.orderBy) {
        return false
    }
    switch (currentSortColumn.orderAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}
