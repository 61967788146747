import {Banner, FlexRowItem} from './banner.styled'

export type BannerProps = {
    cardView: boolean
}

export function BannerElement({cardView}: BannerProps): JSX.Element {
    return (
        <Banner isCard={cardView}>
            <FlexRowItem>Limited Time Offer: Enjoy Your Free OT Inventory Trial Now!</FlexRowItem>
        </Banner>
    )
}
