import ActionType from './action-type'
import * as Actions from './actions'
import {GuidType} from '../../../values/generic-type-defintions'

export function closeModal(): Actions.CancelAction {
    return {
        type: ActionType.CLOSE_MODAL,
    }
}
export function displayModal(): Actions.DisplayAction {
    return {
        type: ActionType.DISPLAY_MODAL,
    }
}
export function reviewNote(): Actions.ReviewAction {
    return {
        type: ActionType.REVIEW_NOTE,
    }
}
export function setProcessingEditNoteAction(
    noteIdentity: GuidType,
    noteToEdit: string,
): Actions.ProcessingEditNoteAction {
    return {
        type: ActionType.PROCESSING_EDIT_NOTE,
        payload: {noteIdentity, noteToEdit},
    }
}
export function setProcessingNewNoteAction(
    incidentIdentity: GuidType,
    newNoteText: string,
): Actions.ProcessingNewNoteAction {
    return {
        type: ActionType.PROCESSING_NEW_NOTE,
        payload: {incidentIdentity, newNoteText},
    }
}
export function setSaveEditNoteAction(noteIdentity: GuidType): Actions.SaveEditNoteAction {
    return {
        type: ActionType.SAVE_EDIT_NOTE,
        payload: noteIdentity,
    }
}
export function setSaveNewNoteAction(): Actions.SaveNewNoteAction {
    return {
        type: ActionType.SAVE_NEW_NOTE,
    }
}
export function setPendingNavigation(
    pendingNavigation: string | null,
): Actions.SePendingNavigationAction {
    return {
        type: ActionType.SET_PENDING_NAVIGATION,
        payload: pendingNavigation,
    }
}
