import {SwitchToggle} from '../../../../../components/switch-toggle/switch-toggle'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {ToggleAssetWithIssue} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {AnalysisType} from '../../../../../store/state/metrics-filter-beta/state'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {BackArrowDiv} from '../../back-arrow/back-arrow-div'
import * as Styled from './_styled/table-action-row.styled'
import {useDispatch} from 'react-redux'

export function TableActionRow(): JSX.Element {
    const dispatch = useDispatch()
    const {onlyAssetsWithIssues, analysisTypes} = useTypedSelector(metricsBetaFilterSelector)
    const {previousPage} = usePagedMetricsBeta()

    return (
        <Styled.ActionRow>
            <BackArrowDiv viewScreenLevel={previousPage ?? 'framework'} />
            {analysisTypes === AnalysisType.SCORECARD && (
                <Styled.ToggleButton id="toggle-button">
                    Show only assets with issues
                    <SwitchToggle
                        onValue={onlyAssetsWithIssues}
                        setToggleLeftValue={() => {
                            dispatch(ToggleAssetWithIssue(false))
                        }}
                        setToggleRightValue={() => {
                            dispatch(ToggleAssetWithIssue(true))
                        }}
                        id="show-assets-with-issue"
                    />
                </Styled.ToggleButton>
            )}
        </Styled.ActionRow>
    )
}
