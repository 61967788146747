import styled from 'styled-components'
import {smallFont} from '../../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../../theme/spacing'

export const Wrapper = styled.div`
    width: 260px;
    /* margin-bottom: ${spacing(2)}; */
    background-color: ${(props) => props.theme.colors.background.default};
`
export const Label = styled.label`
    position: absolute;
    top: 11px;
    ${smallFont()}
    margin-bottom: ${spacing(1)};
`
