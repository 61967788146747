import {LocationOperationMerge} from '../../../../store/state/locations/state'
import {Check} from 'react-feather'
import AlertHigh from '../../../../@assets/icons/Alert high.svg'
import {VesselDeploymentStatus} from '../../contexts/type/deployment-status-types'
import {GuidType} from '../../../../values/generic-type-defintions'

export function getDeploymentStatus(locationInfo: LocationOperationMerge): JSX.Element {
    function append(msg: string, append: string): string {
        return `${msg === '' ? '' : '\n'}${append}`
    }
    const hasIssues =
        locationInfo.trafficStatus != null ||
        locationInfo.upgradeAssets != null ||
        locationInfo.operating === false ||
        locationInfo.vesselDashboard === false
    if (!hasIssues) {
        return <Check color="green" style={{paddingLeft: '2px'}} />
    }
    let msg = ``
    let issues = 0

    if (locationInfo.operating === false) {
        issues += 1
        msg += append(msg, '- Incus not installed')
    }

    if (locationInfo.vesselDashboard === false) {
        msg += append(msg, `- Vessel dashboard Not deployed`)
        issues += 1
    }

    if (locationInfo.trafficStatus != null && locationInfo.trafficStatus.length > 0) {
        msg += append(msg, `- No traffic for ${locationInfo.trafficStatus.length} assets`)
        issues += locationInfo.trafficStatus.length
    }

    if (locationInfo.upgradeAssets != null && locationInfo.upgradeAssets.length > 0) {
        msg += append(msg, `- ${locationInfo.upgradeAssets.length} agents need updating`)
        issues += locationInfo.upgradeAssets.length
    }

    return (
        <>
            <img src={AlertHigh} title={msg} width={24} />{' '}
            {issues && <span>{issues} issue(s)</span>}
        </>
    )
}

export function getMergedLocation(
    vesselDeploymentStatusInfo: VesselDeploymentStatus,
    location: GuidType,
): LocationOperationMerge {
    const vesselOperationalStatus = vesselDeploymentStatusInfo?.operationalStatus?.[location]
    const traffic = vesselDeploymentStatusInfo?.trafficStatus?.[location]
    const dashBoard = vesselDeploymentStatusInfo?.vesselDashboard?.[location]
    return {
        location: location,
        operating: vesselOperationalStatus?.operating ?? null,
        upgradeAssets: vesselOperationalStatus?.upgradeAssets ?? null,
        trafficStatus: traffic ?? null,
        vesselDashboard: dashBoard ?? null,
    }
}
