import LoadingState from '../../../../values/loading-state-enum'
import {PagedVesselsBetaState, PossibleColumnsVesselBeta} from './paged-vessels-beta-state'
import {VesselBetaTableDetailsModel} from './vessel-beta-table-details-model'

export const DEFAULT_PAGED_VESSELS_BETA_STATE: PagedVesselsBetaState = {
    showFilterBar: false,
    selectedColumns: [],
    highlightedFilerValue: false,
    loadingFilteredDataState: LoadingState.NotPopulated,
    tableVesselsDataMap: new Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>(),
}
