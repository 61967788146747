import * as Styled from './radio-button.styled'

interface Props {
    label: string
    isChecked: boolean
    onClick: () => void
}

export function RadioButton({label, isChecked, onClick}: Props): JSX.Element {
    return (
        <Styled.RadioButtonWrapper>
            <Styled.Container
                disabled={false}
                onClick={onClick}
                id={`container_radio-button_${label}`}
            >
                <Styled.Input
                    type="radio"
                    id={`${label}-radio`}
                    value={label}
                    checked={isChecked}
                />
                <Styled.Label htmlFor={`-radio`} id={`Label_radio-button_${label}`}>
                    {label}
                </Styled.Label>
            </Styled.Container>
        </Styled.RadioButtonWrapper>
    )
}
