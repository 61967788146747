import {useDispatch} from 'react-redux'
import * as Styled from './timestamp.styled'
import {setSearchedFirstDetected} from '../../../../../store/state/usb-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {TimestampFilter} from '../../../../../store/state/usb-inventory-filter/state'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function FirstDetectedFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {searchedFirstDetected} = useTypedSelector(usbInventoryFilterSelector)

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(
            logFilterByTypes(
                'filterByFirstDetected',
                event.currentTarget.value as string,
                true,
                AuditLogPageType.USB_INVENTORY,
            ),
        )
        dispatch(setSearchedFirstDetected(event.target.value as TimestampFilter))
    }
    const allAvailableTimestampOptions: TimestampFilter[] = [
        'Last 24h',
        'Last 7 days',
        'Last 30 days',
    ]
    return (
        <Styled.Wrapper id={`${PageType.USB_DEVICES}_filter-bar_first-detected-filter`}>
            <Styled.Label>First Detected:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={searchedFirstDetected}
                    id={`${PageType.USB_DEVICES}_filter-bar_searched-first-detected-value`}
                >
                    <option
                        key="default-option"
                        value="All"
                        id={`${PageType.USB_DEVICES}_filter-bar_first-detected_default-option`}
                    >
                        - All -
                    </option>
                    {allAvailableTimestampOptions?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.USB_DEVICES}_filter-bar_first-detected_${value}-option`}
                        >
                            {value}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
