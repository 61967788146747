import React from 'react'
import {FilterableColumn} from './filterable-column'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {useDispatch} from 'react-redux'
import {setSearchHostname} from '../../../../../store/state/network-assets-filter/action-creators'
import {NetworkAssetsSortType} from '../../../../../store/state/network-assets-filter/state'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface ColumnProps {
    gridColumn: number
}

export function HostnameColumn({gridColumn}: ColumnProps): JSX.Element {
    const dispatch = useDispatch()
    const {searchedHostname} = useTypedSelector(networkAssetsFilterSelector)
    const colName = NetworkAssetsSortType.HOSTNAME

    const search = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (event.target.value.length !== 0) {
            dispatch(
                logFilterByTypes(
                    'Hostname',
                    `${event.target.value}`,
                    true,
                    AuditLogPageType.NETWORK_INVENTORY,
                ),
            )
        }
        dispatch(setSearchHostname(event.target.value))
    }

    return (
        <FilterableColumn
            text="Hostname"
            colName={colName}
            searchValue={searchedHostname}
            searchFunc={search}
            gridColumn={gridColumn}
        />
    )
}
