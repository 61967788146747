import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {smallFont} from '../../../../../../theme/font-styles'
import {CyberOwlTheme} from '../../../../../../theme/theme'

function getBackgroundColor(theme: CyberOwlTheme, isHovering: boolean): string {
    return isHovering ? theme.vessels.list.hoverBackgroundColor : theme.vessels.dataRow.background
}
function getTextColor(theme: CyberOwlTheme, isHovering: boolean): string {
    return isHovering ? theme.vessels.list.hoverTextColor : theme.vessels.dataRow.text
}
interface TableDataRowProps {
    gridRow: number
    span?: number
    isHovering: boolean
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    margin: ${spacing(1)} 0;
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => getTextColor(props.theme, props.isHovering)};
    background-color: ${(props) => getBackgroundColor(props.theme, props.isHovering)};
    min-height: 64px;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.vessels.dataRow.dropShadow};
`

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
    isHovering: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: ${(props) => (props.inset ? spacing(2) : 0)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => getTextColor(props.theme, props.isHovering)};
    ${smallFont()}
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold || false)};
`

export function getGridTemplateColumns(): string {
    return '0 auto 150px 0'
}

export const TableDataGrid = styled.div`
    display: grid;
    grid-template-columns: ${getGridTemplateColumns()};
    grid-column-gap: ${spacing(2)};
    flex: 1;
    background-color: ${(props) => props.theme.vessels.dataArea.background};
    cursor: pointer;
`
