import * as Styled from './_styled/table.styled'
import {Location} from '../../../../../store/state/locations/state'

interface TableNameCellProps {
    location: Location
    gridRow: number
    isHovering: boolean
}

export function TableNameCell({location, gridRow, isHovering}: TableNameCellProps): JSX.Element {
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={2}
            isHovering={isHovering}
            id={`location-description_${location.location}`}
        >
            {location.description}
        </Styled.TableDataCell>
    )
}
