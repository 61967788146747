import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getCellWidth(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '80px'
        default:
            return '112px'
    }
}

interface ContainerProps {
    gridLayout: GridLayout
}

export const Container = styled.div<ContainerProps>`
    width: ${(props) => getCellWidth(props.gridLayout)};
    display: flex;
`
