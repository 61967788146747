import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSearchIncidentNo} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {INCIDENT_DETAILS} from '../../../data-helpers'
import * as Styles from './incident-search.styled'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function IncidentSearch(): JSX.Element {
    const {searchIncidentNo} = useTypedSelector(incidentsFilterSelector)
    const dispatch = useDispatch()
    const {closeIncidentDetailsModal} = usePagedIncidents()

    return (
        <Styles.Container>
            <Styles.SearchInput
                id="incident-search"
                type="text"
                placeholder=" Incident No."
                value={searchIncidentNo ?? ''}
                autoComplete="off"
                onChange={(e) => {
                    dispatch(setSearchIncidentNo(e.target.value))
                    closeIncidentDetailsModal()
                    dispatch(
                        logFilterByTypes(
                            'filterBySearch',
                            e.target.value,
                            true,
                            AuditLogPageType.INCIDENTS,
                        ),
                    )

                    removeQueryParam(INCIDENT_DETAILS)
                }}
            />
            <Styles.SearchIcon>
                <Icon glyph="Search" height={15} width={15} />
            </Styles.SearchIcon>
        </Styles.Container>
    )
}
