import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
`

export const Value = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
`
