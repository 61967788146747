import {LocationIdType} from '../../../../../../store/state/locations/state'
import {TimestampFilterType} from '../../../../../../store/state/metrics-filter-beta/state'
import {MetricsWidgetType} from './metrics-widget-type'

export interface MetricsWidgetFilter {
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    selectedAnalysisPeriod: TimestampFilterType
    selectedMetricWidget: MetricsWidgetType
}

export const DEFAULT_METRICS_FILTERS: MetricsWidgetFilter = {
    locations: undefined,
    searchVesselTagTerm: [],
    selectedAnalysisPeriod: TimestampFilterType.LAST_30_DAYS,
    selectedMetricWidget: MetricsWidgetType.SCORECARD_SUMMARY,
}
