import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {
    showOneCol,
    showOneColNarrow,
    showTwoColNarrow,
    showTwoColWide,
} from '../shared/grid-layout-helper'
import {GridLayout} from '../shared/grid-layout.enum'

interface ReportConfigProps {
    gridLayout: GridLayout
}

function getGridTemplate(gridLayout: GridLayout): string {
    if (showTwoColNarrow(gridLayout) || showOneColNarrow(gridLayout)) {
        return "'OT-DEVICES' 'OT-DEVICES-VALUE' 'OT-CROSSINGS' 'OT-CROSSINGS-VALUE' 'BUSINESS-DEVICES' 'BUSINESS-DEVICES-VALUE' 'BUSINESS-CROSSINGS' 'BUSINESS-CROSSINGS-VALUE' 'EMAIL-HOSTS' 'EMAIL-HOSTS-VALUE' 'HOST-LOG-EXCEPTIONS' 'HOST-LOG-EXCEPTIONS-VALUE' 'NETWORK-LOG-EXCEPTIONS' 'NETWORK-LOG-EXCEPTIONS-VALUE' 'USB-HOSTS' 'USB-HOSTS-VALUE' 'USB-DEVICES' 'USB-DEVICES-VALUE' 'ADMIN-LOGONS' 'ADMIN-LOGONS-VALUE' 'REMOTE-ACCESS' 'REMOTE-ACCESS-VALUE' 'ENCRYPTION-RATIO' 'ENCRYPTION-RATIO-VALUE' 'MONITORING-COVERAGE' 'MONITORING-COVERAGE-VALUE' 'MAX-OS-AGE' 'MAX-OS-AGE-VALUE' 'MAX-SCORE' 'MAX-SCORE-VALUE'"
    }
    return "'OT-DEVICES OT-DEVICES-VALUE' 'OT-CROSSINGS OT-CROSSINGS-VALUE' 'BUSINESS-DEVICES BUSINESS-DEVICES-VALUE' 'BUSINESS-CROSSINGS BUSINESS-CROSSINGS-VALUE' 'EMAIL-HOSTS EMAIL-HOSTS-VALUE' 'HOST-LOG-EXCEPTIONS HOST-LOG-EXCEPTIONS-VALUE' 'NETWORK-LOG-EXCEPTIONS NETWORK-LOG-EXCEPTIONS-VALUE' 'USB-HOSTS USB-HOSTS-VALUE' 'USB-DEVICES USB-DEVICES-VALUE' 'ADMIN-LOGONS ADMIN-LOGONS-VALUE' 'REMOTE-ACCESS REMOTE-ACCESS-VALUE' 'ENCRYPTION-RATIO ENCRYPTION-RATIO-VALUE' 'MONITORING-COVERAGE MONITORING-COVERAGE-VALUE' 'MAX-OS-AGE MAX-OS-AGE-VALUE' 'MAX-SCORE MAX-SCORE-VALUE'"
}

export const ReportConfigStyle = styled.div<ReportConfigProps>`
    display: grid;
    ${(props) => (showOneColNarrow(props.gridLayout) ? '' : 'justify-content: flex-start;')}
    grid-template-areas: ${(props) => getGridTemplate(props.gridLayout)};
    grid-row-gap: ${(props) => (showTwoColWide(props.gridLayout) ? spacing(3) : spacing(1))};
    grid-column-gap: ${(props) => (showOneCol(props.gridLayout) ? spacing(8) : spacing(11))};
`
