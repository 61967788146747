import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useTheme} from 'styled-components'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../../../store/routerSelectors'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {setVesselFilterPref} from '../../../../../store/state/vessel-filter/action-creators'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../../store/state/vessel-filter/selectors'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {GuidType} from '../../../../../values/generic-type-defintions'
import LoadingState from '../../../../../values/loading-state-enum'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {compareFilters, getSortOrderValue, INCIDENT_DETAILS, showCards} from '../../../data-helpers'
import {ResetFilterButton} from '../reset-filter/reset-filter-button'
import {IncidentSearch} from '../search/incident-search'
import {ClosedIncidentsFilter} from './closed-incidents-filter'
import {FilterText} from './filter-card.styled'
import {NewIncidentsFilter} from './new-incidents-filter'
import {OpenIncidentsFilter} from './open-incidents-filter'
import {SeverityFilter} from './severity-filter/severity-filter'
import * as Styled from './top-filter-section.styled'
import {savedIncidentsFiltersSelector} from '../../../../../store/state/saved-filters/selectors'
import {
    DEFAULT_INCIDENTS_FILTER,
    IncidentsFilter,
    IncidentsFilterCriteria,
} from '../../../../../values/user-preferences/incidents-filter'
import {setSelectedFilterName} from '../../../../../store/state/incidents-filter/action-creators'
import {getFormattedIncidentsFilter} from '../../../../../components/saved-search/components/shared/helper'

export function TopFilterSection(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const theme = useTheme()

    const {setFilter, closeIncidentDetailsModal, incidentModalId} = usePagedIncidents()

    const {
        filterByStatus,
        filterBySeverities,
        searchIncidentNo,
        orderIncidentsBy,
        selectedIncidentType,
        selectedCreatedFromTimeRange,
        selectedCreatedToTimeRange,
        selectedUpdatedFromTimeRange,
        selectedUpdatedToTimeRange,
        assignedTo,
        guestAssignedToEmail,
        watchedByCurrentUser,
        unseenIncidentResponses,
        locations,
        searchVesselNameTerm,
        searchVesselTagTerm,
        selectedFilterName,
        createdFromRelative,
        createdToRelative,
        updatedToRelative,
        updatedFromRelative,
        assignedToVesselEmail,
    } = useTypedSelector(incidentsFilterSelector)

    const newFilter = useTypedSelector(incidentsFilterSelector)
    const currentFilter = useTypedSelector(savedIncidentsFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria

    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)

    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryId = queryString?.incidentDetails as GuidType
    const externalGuid =
        incidentModalId == null ? queryId != undefined : incidentModalId === queryId

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm ?? [],
                        searchVesselNameTerm: searchVesselNameTerm ?? '',
                    },
                    PageType.INCIDENTS,
                ),
            )
        }
    }, [dispatch, locations, searchVesselNameTerm, searchVesselTagTerm, vesselFilterLoading])

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated && !externalGuid) {
            setFilter(
                filterByStatus,
                filterBySeverities,
                searchIncidentNo,
                {
                    column: orderIncidentsBy.column,
                    ascending: getSortOrderValue(orderIncidentsBy.direction),
                },
                selectedIncidentType,
                createdFromRelative,
                createdToRelative,
                updatedFromRelative,
                updatedToRelative,
                selectedCreatedFromTimeRange,
                selectedCreatedToTimeRange,
                selectedUpdatedFromTimeRange,
                selectedUpdatedToTimeRange,
                locations,
                searchVesselTagTerm,
                searchVesselNameTerm,
                assignedTo,
                guestAssignedToEmail,
                watchedByCurrentUser,
                unseenIncidentResponses,
                externalGuid,
                queryId,
                assignedToVesselEmail,
            )
        } else {
            compareFilters(
                currentFilter
                    ? (getFormattedIncidentsFilter(
                          currentFilter as IncidentsFilterCriteria,
                      ) as IncidentsFilter)
                    : DEFAULT_INCIDENTS_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))

            setFilter(
                filterByStatus,
                filterBySeverities,
                searchIncidentNo,
                {
                    column: orderIncidentsBy.column,
                    ascending: getSortOrderValue(orderIncidentsBy.direction),
                },
                selectedIncidentType,
                createdFromRelative,
                createdToRelative,
                updatedFromRelative,
                updatedToRelative,
                selectedCreatedFromTimeRange,
                selectedCreatedToTimeRange,
                selectedUpdatedFromTimeRange,
                selectedUpdatedToTimeRange,
                vesselFilterLocations,
                vesselFilterTagTerm,
                vesselFilterNameTerm,
                assignedTo,
                guestAssignedToEmail,
                watchedByCurrentUser,
                unseenIncidentResponses,
                externalGuid,
                queryId,
                assignedToVesselEmail,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filterByStatus,
        filterBySeverities,
        searchIncidentNo,
        orderIncidentsBy,
        selectedIncidentType,
        createdFromRelative,
        createdToRelative,
        updatedFromRelative,
        updatedToRelative,
        selectedCreatedToTimeRange,
        selectedUpdatedToTimeRange,
        vesselFilterLocations,
        vesselFilterTagTerm,
        vesselFilterNameTerm,
        assignedTo,
        guestAssignedToEmail,
        watchedByCurrentUser,
        unseenIncidentResponses,
        assignedToVesselEmail,
    ])

    return (
        <Styled.ContentContainerMiddle
            width={width}
            backgroundColor={theme.incidents.dataArea.background}
            id="incidents-page-top-filter-section"
        >
            <Styled.ContentArea width={width}>
                <Styled.Card width={width}>
                    <Styled.FilterSection width={width}>
                        <Styled.StatusFilterContainer
                            width={width}
                            gridArea="STATUS"
                            onClick={() => {
                                removeQueryParam(INCIDENT_DETAILS)
                                closeIncidentDetailsModal()
                            }}
                            id="incidents-page-top-filter-section-status-fiter-container"
                        >
                            <FilterText>INCIDENTS BY STATUS</FilterText>
                            <div style={{display: 'flex', width: '100%'}}>
                                <NewIncidentsFilter />
                                <OpenIncidentsFilter />
                                <ClosedIncidentsFilter />
                            </div>
                        </Styled.StatusFilterContainer>
                        <Styled.FilterContainer
                            width={width}
                            gridArea="SEVERITY"
                            onClick={() => {
                                removeQueryParam(INCIDENT_DETAILS)
                                closeIncidentDetailsModal()
                            }}
                            id="incidents-page-top-filter-section-severity-fiter-container"
                        >
                            <FilterText>OPEN INCIDENTS BY SEVERITY</FilterText>
                            <SeverityFilter />
                        </Styled.FilterContainer>
                        {!showCards(width) && <ResetFilterButton />}
                    </Styled.FilterSection>
                    {!showCards(width) && <IncidentSearch />}
                </Styled.Card>
            </Styled.ContentArea>
        </Styled.ContentContainerMiddle>
    )
}
