export enum TrendDirection {
    Up,
    Same,
    Down,
}
export interface TrendValue {
    score: number
    direction: TrendDirection
}
export interface WidgetOutputModel {
    score: number
    trend: {
        hours24: TrendValue
        days7: TrendValue
        days7Average: number
    }
}
