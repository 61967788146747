import styled from 'styled-components'

export const NoDataMessageGrid = styled.div`
    display: grid;
    grid-template-columns: 10px 10px auto 10px 10px;
    grid-template-rows: max-content;
    padding: 0 10px 10px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.incidents.dataArea.background};
`
