import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellEmailHosts(): JSX.Element {
    const {data, initialData, displayData, changeEmailHosts} = useVesselConfig()
    const changed = data.emailHosts?.toString() !== initialData.emailHosts?.toString()
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeEmailHosts(e.target.value.split(','))
    }
    return (
        <DataInput
            label="Email Hosts"
            initialValue={displayData.emailHosts}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
