enum ActionType {
    SET_FORM_STRING_VALUE = '[OT ASSET FORM] SET FORM STRING VALUE',
    SET_FORM_DROPDOWN_VALUE = '[OT ASSET FORM] SET FORM DROPDOWN VALUE',
    SET_FORM_DROPDOWN_VALUES = '[OT ASSET FORM] SET FORM DROPDOWN VALUES',
    SET_FORM_ADDITIONAL_FIELD_VALUE = '[OT ASSET FORM] SET FORM ADDITIONAL FIELD VALUE',
    SET_ALL_FORM_VALUES = '[OT ASSET FORM] SET ALL FORM VALUES',
    SET_ASSET_FORM_ERRORS = '[OT ASSETS] SET FORM ERRORS',
    SET_ASSET_FORM_SUBMIT_ERROR = '[OT ASSETS] SET FORM SUBMIT ERROR',
    RESET_ASSET_CREATION_FORM = '[OT ASSETS] RESET ASSET CREATION FORM',

    CREATE_ASSET_REQUEST = '[OT ASSETS FORM] CREATE ASSET REQUEST',
    CREATE_ASSET_SUCCESS = '[OT ASSETS FORM] CREATE ASSET SUCCESS',

    SET_IS_EDITING_ID = '[OT ASSET FORM] SET EDITING ASSET ID',

    EDIT_ASSET_REQUEST = '[OT ASSET FORM] EDIT ASSET REQUEST',
    EDIT_ASSET_SUCCESS = '[OT ASSET FORM] EDIT ASSET SUCCESS',
    EDIT_ASSET_FAILURE = '[OT ASSET FORM] EDIT ASSET FAILURE',

    ADD_CUSTOM_FIELD = '[OT ASSET FORM] ADD CUSTOM FIELD',
    REMOVE_CUSTOM_FIELD = '[OT ASSET FORM] REMOVE CUSTOM FIELD',
}

export default ActionType
