import produce from 'immer'
import {LoggingOutAction} from '../../../../../../store/state/session-data/actions'
import {MetricsWidgetState} from '../types/metrics-widget-state'
import {AllActions} from './actions'
import SessionActionType from '../../../../../../store/state/session-data/action-type'
import {ActionType} from './action-type'
import LoadingState from '../../../../../../values/loading-state-enum'
import {DEFAULT_METRIC_WIDGET_STATE} from '../types/default-metrics-widget-state'
import {MetricSummary} from '../../../../../metrics-beta/contexts/types/metrics-summary'
import {MetricType} from '../../../../../metrics-beta/contexts/types/metrics-response'
import {isEqual} from 'lodash'

export const metricsWidgetReducer = produce(
    (draft: MetricsWidgetState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingFilterState = LoadingState.RequestingData
                break

            case ActionType.RECEIVE_PREFS_FILTER:
                draft.loadingFilterState = LoadingState.Loaded
                draft.metricsWidgetFilter.selectedAnalysisPeriod =
                    action.payload.selectedAnalysisPeriod
                draft.metricsWidgetFilter.selectedMetricWidget = action.payload.selectedMetricWidget
                draft.metricsWidgetFilter.searchVesselTagTerm = action.payload.searchVesselTagTerm
                break
            case ActionType.SHOW_FILTER:
                draft.showFilter = action.payload
                break
            case ActionType.SET_TIME_RANGE:
                draft.metricsWidgetFilter.selectedAnalysisPeriod = action.payload
                break
            case ActionType.SET_METRICS_WIDGET:
                draft.metricsWidgetFilter.selectedMetricWidget = action.payload
                break
            case ActionType.SET_FILTER:
                draft.loadingDataState = LoadingState.RequestingData
                draft.metricsWidgetData = new Map<MetricType, MetricSummary>()
                break
            case ActionType.RECEIVED_REQUESTED_WIDGET_DATA:
                draft.loadingDataState = LoadingState.Loaded
                draft.locationSelectionNumber = action.payload.locationSelectionNumber
                if (!draft.metricsWidgetData) {
                    draft.metricsWidgetData = new Map<MetricType, MetricSummary>()
                }
                if (!isEqual(draft.metricsWidgetData, action.payload.metricsWidgetData)) {
                    draft.metricsWidgetData = action.payload.metricsWidgetData
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_METRIC_WIDGET_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
