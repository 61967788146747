import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
`

export const Value = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const Input = styled.div`
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`

export const Select = styled.select`
    width: 100%;
    text-align-last: center;
    padding: 3px ${spacing(3)};
    ${mediumSmallFont()}
    border-radius: 3px;
`

export const NoAssetsMessage = styled.div`
    ${mediumSmallFont()};
    line-height: ${spacing(5)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
