import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsTypesWidget} from '../../../../incidents-report/components/incidents-types-and-severities/incidents-types-widget'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import * as Styled from './widget-commonstyling.styled'

export function TypesIncidentsWidget(): JSX.Element {
    const {loadedIncidentsCounts, totalNumberOfItemsByTypes} = useIncidentsWidget()
    return (
        <Styled.TypesFilterContainer id="status-section-status-count-container">
            <Styled.SectionText>TYPES OF INCIDENTS</Styled.SectionText>
            {loadedIncidentsCounts ? (
                <IncidentsTypesWidget
                    fromDashboard={true}
                    totalNumberOfItemsByTypes={totalNumberOfItemsByTypes}
                />
            ) : (
                <DataLoading />
            )}
        </Styled.TypesFilterContainer>
    )
}
