import {ColourBar} from './styled/colour-bar.styled'
import {useTheme} from 'styled-components'

export function HighRiskColourBar(): JSX.Element {
    const theme = useTheme()

    return (
        <ColourBar
            start={0}
            end={100}
            colour={theme.threatScores.score.high.background}
            id="highRisk-colour-bar"
        />
    )
}
