import {MetricsWidgetState} from './metrics-widget-state'
import {DEFAULT_METRICS_FILTERS} from './metrics-widget-filter'
import LoadingState from '../../../../../../values/loading-state-enum'

export const DEFAULT_METRIC_WIDGET_STATE: MetricsWidgetState = {
    loadingFilterState: LoadingState.NotPopulated,
    loadingDataState: LoadingState.NotPopulated,
    metricsWidgetFilter: DEFAULT_METRICS_FILTERS,
    showFilter: false,
    metricsWidgetData: undefined,
    locationSelectionNumber: 0,
}
