import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface BehaviourStatusWrapperProps {
    popup: boolean
}
export const BehaviourStatusWrapper = styled.div<BehaviourStatusWrapperProps>`
    padding: ${spacing(1)};
    width: fit-content;
    background-color: #eddfc9;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    ${(props) =>
        props.popup &&
        `&:hover {
        cursor: pointer;
    }`}
`

interface PopupContainerProps {
    cardView?: boolean
}
export const PopupContainer = styled.div<PopupContainerProps>`
    position: absolute;
    top: -30px;
    ${(props) => (props.cardView ? 'left: 0' : 'right:0')};
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.background.selectedLocationPage};
    padding: 3px;
    ${mediumSmallFont()}
`
