import {createSelector} from 'reselect'
import {NotifyMeOutputModel} from './notify-me-detail.model'
import {
    currentAppliedSavedFilterSelector,
    pageTypeSelector,
    templateDefinitionsSelector,
    userSubscriptionsSelector,
} from '../../../store/state/saved-filters/selectors'
import {currentUserSelector} from '../../../store/state/current-user/selectors'
import {
    AllSavedFilterType,
    SavedFilterPageType,
    SavedFilterSubscription,
    SavedIncidentsFilter,
    SavedNetworkAssetsFilter,
    SavedSoftwareInventoryFilter,
    SavedUsbInventoryFilter,
    SavedMetricsBetaFilter,
    SavedReportsFilter,
    TemplateDefinitions,
    SavedIncidentsReportFilter,
} from '../../../store/state/saved-filters/state'
import {UserData} from '../../../store/state/current-user/state'
import {GuidType} from '../../../values/generic-type-defintions'

export const notifyMeReselector = createSelector(
    templateDefinitionsSelector,
    currentUserSelector,
    currentAppliedSavedFilterSelector,
    userSubscriptionsSelector,
    pageTypeSelector,
    (
        templateDefinitions: TemplateDefinitions[] | undefined,
        currentUser: UserData,
        currentSavedFilter: AllSavedFilterType | undefined,
        subscriptions: SavedFilterSubscription[] | undefined,
        currentPage: SavedFilterPageType,
    ): NotifyMeOutputModel | null => {
        if (!currentSavedFilter) {
            return null
        }

        const currentFilter = (
            pageType: SavedFilterPageType,
            currentSavedFilter: AllSavedFilterType,
        ): AllSavedFilterType => {
            switch (pageType) {
                case SavedFilterPageType.INCIDENTS:
                    return currentSavedFilter as SavedIncidentsFilter
                case SavedFilterPageType.METRICS:
                    return currentSavedFilter as SavedMetricsBetaFilter
                case SavedFilterPageType.NETWORK_ASSETS:
                    return currentSavedFilter as SavedNetworkAssetsFilter
                case SavedFilterPageType.USB_INVENTORY:
                    return currentSavedFilter as SavedUsbInventoryFilter
                case SavedFilterPageType.SOFTWARE_INVENTORY:
                    return currentSavedFilter as SavedSoftwareInventoryFilter
                case SavedFilterPageType.REPORTS:
                    return currentSavedFilter as SavedReportsFilter
                case SavedFilterPageType.INCIDENTS_REPORT:
                    return currentSavedFilter as SavedIncidentsReportFilter
            }
        }

        const templateDefinitionId = (
            pageType: SavedFilterPageType,
            templateDefinitions: TemplateDefinitions[] | undefined,
        ): GuidType | undefined => {
            switch (pageType) {
                case SavedFilterPageType.INCIDENTS:
                    return templateDefinitions?.find((el) => el.page === 'incidents')?.identity
                case SavedFilterPageType.NETWORK_ASSETS:
                    return templateDefinitions?.find((el) => el.page === 'network-assets')?.identity
                case SavedFilterPageType.USB_INVENTORY:
                    return templateDefinitions?.find((el) => el.page === 'usb-devices')?.identity
                case SavedFilterPageType.SOFTWARE_INVENTORY:
                    return templateDefinitions?.find((el) => el.page === 'software')?.identity
                case SavedFilterPageType.METRICS:
                    return templateDefinitions?.find((el) => el.page === 'metrics')?.identity
                case SavedFilterPageType.REPORTS:
                    return templateDefinitions?.find((el) => el.page === 'reports')?.identity
                case SavedFilterPageType.INCIDENTS_REPORT:
                    return templateDefinitions?.find((el) => el.page === 'incidents-report')
                        ?.identity
            }
        }

        const existingSubscription = subscriptions?.find(
            (data) => data.searchDefinitionId === currentSavedFilter.identity,
        )
        const isDeletedEmail = existingSubscription
            ? existingSubscription.notificationEmail === currentUser.notificationEmail ||
              existingSubscription.notificationEmail === currentUser.email
            : false
        const formattedExistingSubscription = existingSubscription && {
            ...existingSubscription,
            notificationEmail: isDeletedEmail ? undefined : existingSubscription?.notificationEmail,
        }

        return {
            currentFilter: currentFilter(currentPage, currentSavedFilter),
            currentSubscription: formattedExistingSubscription,
            schedule: formattedExistingSubscription?.schedule,
            userId: currentUser.user,
            userEmail: currentUser.email,
            pageType: currentPage,
            templateDefinitionId: templateDefinitionId(currentPage, templateDefinitions),
            notificationEmail:
                formattedExistingSubscription?.notificationEmail ??
                currentUser.notificationEmail ??
                currentUser.email,
            subjectPrefix: formattedExistingSubscription?.subjectPrefix ?? undefined,
            subscriptionId: formattedExistingSubscription?.identity ?? undefined,
        }
    },
)
