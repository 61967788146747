import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfUnknownAssets, selectedPage, pageSize, isInterlinking} =
        usePagedUnknownAssetsBeta()
    if (
        totalNumberOfUnknownAssets == undefined ||
        selectedPage == undefined ||
        pageSize == undefined ||
        isInterlinking
    ) {
        return null
    }
    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfUnknownAssets
            ? totalNumberOfUnknownAssets
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfUnknownAssets !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfUnknownAssets}
                />
            )}
        </>
    )
}
