import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`

export const Wrapper = styled.div`
    display: grid;
    row-gap: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: inherit;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`

export const FilterHeadingRow = styled.div`
    grid-column: 1 / span 1;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
