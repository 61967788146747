import * as Styled from './policy-name.styled'
import {useEffect, useState} from 'react'
import {isEqual} from 'lodash'
import {useEnforcementPolicy} from '../../../../../contexts/enforcement-policy/use-enforcement-policy'

export function PolicyName(): JSX.Element {
    const {policyName, changePolicyName, isDefaultPolicy, discardChanges, requestDiscardChanges} =
        useEnforcementPolicy()
    const [changedName, setChangedName] = useState(policyName)

    useEffect(() => {
        if (discardChanges && !isEqual(policyName, changedName)) {
            setChangedName(policyName)
            requestDiscardChanges(false)
        }
    }, [changedName, discardChanges, policyName, requestDiscardChanges])

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setChangedName(e.target.value)
        changePolicyName(e.target.value)
    }
    return (
        <>
            <Styled.Label id={policyName}>Policy Name:</Styled.Label>
            {isDefaultPolicy ? (
                <Styled.ReadOnlyValue id={`${policyName}-value`}>
                    {changedName}
                </Styled.ReadOnlyValue>
            ) : (
                <Styled.Value id={`${policyName}-value`}>
                    <Styled.inputNonText value={changedName ?? ''} onInput={onChangeHandler} />
                </Styled.Value>
            )}
        </>
    )
}
