import moment from 'moment'
import createCachedSelector from 're-reselect'
import {BEGINNING_OF_TIME} from '../../../../helpers/consts'
import {normaliseScore} from '../../../../helpers/formatting'
import threatHelper, {ThreatValues} from '../../../../helpers/threatHelper'
import {latestTimestampSelector} from '../../../../store/state/latest-event-timestamps/selectors'
import {LatestEventTimestampMap} from '../../../../store/state/latest-event-timestamps/state'
import {LocationIdType} from '../../../../store/state/locations/state'
import {myVesselsFiltersSelector} from '../../../../store/state/my-vessels-filters/selectors'
import {
    SortValue,
    VesselFiltersState,
} from '../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {nodesForLocationsSelector} from '../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../store/state/sfm-scores/selectors'
import {SFMScoreMap} from '../../../../store/state/sfm-scores/state'
import {threatMeasuresSelector} from '../../../../store/state/threat-measures/selectors'
import AppState from '../../../../store/types/app-state'
import {NodeData, NodeId, NodeValues} from '../../../../values/nodes/NodeData'
import {ThreatMeasureMap} from '../../../../values/ThreatMeasure'
import {vesselFilterSelector} from '../../../../store/state/vessel-filter/selectors'

function getModifier(sortValue: SortValue): 1 | -1 {
    return sortValue.direction === 'asc' ? 1 : -1
}

function filterByThreatValue(
    node: NodeData,
    score: number,
    threatMeasures: ThreatMeasureMap,
    filter: {low: boolean; medium: boolean; high: boolean},
): boolean {
    switch (threatHelper(score, threatMeasures, node.value)) {
        case ThreatValues.HIGH:
            return filter.high
        case ThreatValues.MEDIUM:
            return filter.medium
        case ThreatValues.LOW:
            return filter.low
        default:
            return false
    }
}

function filterByNodeValue(
    node: NodeData,
    sfmScores: SFMScoreMap,
    threatMeasures: ThreatMeasureMap,
    filter: VesselFiltersState,
): boolean {
    const score = Math.round(normaliseScore(sfmScores?.get(node.node) || 0))
    switch (node.value) {
        case NodeValues.HIGH:
            return filterByThreatValue(node, score, threatMeasures, filter.HIGH)
        case NodeValues.MEDIUM:
            return filterByThreatValue(node, score, threatMeasures, filter.MEDIUM)
        case NodeValues.LOW:
            return filterByThreatValue(node, score, threatMeasures, filter.LOW)
        default:
            return false
    }
}

function filterByVessel(node: NodeData, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(node.location)
}

function filterBySearchTerm(node: NodeData, searchTerm: string): boolean {
    if (searchTerm?.length === 0) {
        return true
    }

    return node.alias.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

const compareByNodeValue = (a: NodeData, b: NodeData): number => {
    switch (a.value) {
        case NodeValues.HIGH:
            switch (b.value) {
                case NodeValues.HIGH:
                    return 0
                case NodeValues.MEDIUM:
                case NodeValues.LOW:
                default:
                    return 1
            }
        case NodeValues.MEDIUM:
            switch (b.value) {
                case NodeValues.HIGH:
                    return -1
                case NodeValues.MEDIUM:
                    return 0
                case NodeValues.LOW:
                default:
                    return 1
            }
        case NodeValues.LOW:
            switch (b.value) {
                case NodeValues.HIGH:
                case NodeValues.MEDIUM:
                    return -1
                case NodeValues.LOW:
                    return 0
                default:
                    return 1
            }
        default:
            return 1
    }
}

function compareByThreatScore(a: NodeData, b: NodeData, sfmScores: SFMScoreMap): number {
    return (
        Math.round(normaliseScore(sfmScores.get(a.node) || 0)) -
        Math.round(normaliseScore(sfmScores.get(b.node) || 0))
    )
}

function getTimeStamp(nodeId: NodeId, latestTimeStamps: LatestEventTimestampMap): number {
    return moment(latestTimeStamps.get(nodeId) || BEGINNING_OF_TIME).unix()
}

function compareByNewestEvent(
    a: NodeData,
    b: NodeData,
    latestTimeStamps: LatestEventTimestampMap,
): number {
    return getTimeStamp(a.node, latestTimeStamps) - getTimeStamp(b.node, latestTimeStamps)
}

export const filteredNodeIdsForLocationCachedReselector = createCachedSelector(
    [
        nodesForLocationsSelector,
        sfmScoresSelector,
        threatMeasuresSelector,
        myVesselsFiltersSelector,
        latestTimestampSelector,
        vesselFilterSelector,
        (_state: AppState, locationId: LocationIdType) => locationId,
    ],
    (
        nodesForLocations,
        sfmScores,
        threatMeasures,
        filter,
        latestTimeStamps,
        vesselFilter,
        locationId,
    ) => {
        try {
            if (!nodesForLocations || !nodesForLocations.has(locationId)) {
                return []
            }

            const newestEventOrderSortFunc = (a: NodeData, b: NodeData): number => {
                return compareByNewestEvent(a, b, latestTimeStamps) * getModifier(filter.assetSort)
            }
            const threatScoreSortFunc = (a: NodeData, b: NodeData): number => {
                return compareByThreatScore(a, b, sfmScores) * getModifier(filter.assetSort)
            }

            const assetValueSortFunc = (a: NodeData, b: NodeData): number => {
                return compareByNodeValue(a, b) * getModifier(filter.assetSort)
            }

            function getSortOrderFunc(assetSort: SortValue): (a: NodeData, b: NodeData) => number {
                if (assetSort.field === 'newest-event') {
                    return newestEventOrderSortFunc
                }
                if (assetSort.field === 'threat-score') {
                    return threatScoreSortFunc
                }
                return assetValueSortFunc
            }

            const nodesForLocation = nodesForLocations.get(locationId)
            if (!nodesForLocation) {
                return []
            }

            return nodesForLocation
                .filter((node) => filterByNodeValue(node, sfmScores, threatMeasures, filter))
                .filter((node) => filterByVessel(node, vesselFilter.locations))
                .filter((node) => filterBySearchTerm(node, filter.searchAssetTerm))
                .sort(getSortOrderFunc(filter.assetSort))
                .map((node) => node.node)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({msg: 'Unable to get nodes for location', error})
            return []
        }
    },
)((_state, locationId) => locationId)
