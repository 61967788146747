import {CardArea} from '../../common/cards/card-area.styled'
import {NoUniqueFlowDataCard} from './no-unique-flow-data-card'

export function NoData(): JSX.Element {
    return (
        <CardArea>
            <NoUniqueFlowDataCard />
        </CardArea>
    )
}
