import {CustomerReports, CustomerReportsNotes} from '../../../../values/customer-reports'
import LoadingState from '../../../../values/loading-state-enum'
import {DataReportMap, PagedReportsState} from '../types/paged-reports-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {DEFAULT_PAGED_REPORTS_STATE} from '../types/default-paged-reports-state'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import {GuidType} from '../../../../values/generic-type-defintions'
import produce from 'immer'
import {isEqual} from 'lodash'

export const pagedReportsReducer = produce(
    (draft: PagedReportsState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break

            case ActionType.TOGGLE_NOTES_EXPANDED:
                if (!draft.notesExpanded) {
                    draft.notesExpanded = new Map<GuidType, boolean>()
                }
                const existingState = draft.notesExpanded.get(action.payload) ?? false
                draft.notesExpanded.set(action.payload, existingState === false)
                break
            case ActionType.NOTES_EXPAND:
                draft.notesExpanded.set(action.payload.guild, action.payload.toggle)
                break
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.dataReportsMap = undefined
                draft.totalNumberOfReports = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loadingDataState = LoadingState.Loaded
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loadingDataState = LoadingState.RequestingData
                break
            case ActionType.ADD_NOTE_ACTION:
                const newDataReportsMap = addNotesToDataReports(
                    draft.dataReportsMap,
                    action.payload,
                    draft.selectedPage || 0,
                )
                draft.dataReportsMap = newDataReportsMap
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.totalNumberOfReports = action.payload.total
                draft.totalNumberOfPages = Math.ceil(action.payload.total / draft.pageSize)
                draft.earliestPeriodEndDate = action.payload.earliestPeriodEndDate
                draft.latestPeriodEndDate = action.payload.latestPeriodEndDate

                if (!isEqual(draft.allReportTypes, action.payload.allReportTypes)) {
                    draft.allReportTypes = action.payload.allReportTypes
                }
                const currSelectedPage = draft.selectedPage || 0

                if (!draft.dataReportsMap) {
                    draft.dataReportsMap = new Map<number, CustomerReports[]>()
                }
                if (
                    !draft.dataReportsMap.has(currSelectedPage) ||
                    !isEqual(draft.dataReportsMap.get(currSelectedPage), action.payload.items)
                ) {
                    draft.dataReportsMap.set(currSelectedPage, action.payload.items)
                }
                break

            case ActionType.GET_FILTERED_DATA:
                draft.dataReportsMap = undefined
                draft.selectedPage = 0
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_REPORTS_STATE
                break
            case ActionType.RECEIVED_REQUESTED_REPORT_DETAILS:
                if (!isEqual(draft.findReportDetailForModal, action.payload)) {
                    draft.findReportDetailForModal = action.payload
                }
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)

function addNotesToDataReports(
    dataReportsMap: DataReportMap | undefined,
    payload: CustomerReportsNotes,
    selectedPage: number,
): DataReportMap {
    const updatedMap = new Map<number, CustomerReports[]>(
        dataReportsMap || new Map<number, CustomerReports[]>(),
    )
    const reports = updatedMap.get(selectedPage)
    return updatedMap.set(
        selectedPage,
        addNoteToCustomerReport(payload.customerReportIdentity, reports, payload),
    )
}
function addNoteToCustomerReport(
    reportIdentity: GuidType,
    reports: CustomerReports[] | undefined,
    payload: CustomerReportsNotes,
): CustomerReports[] {
    if (!reports) {
        return new Array<CustomerReports>()
    }

    const findReport = reports.find((report) => report.identity === reportIdentity)
    if (!findReport) {
        return reports
    }

    const updatedReport = {
        ...findReport,
        notes: addNoteToNotes(findReport.notes, payload),
    }
    const shallowCopyReports = [...reports]
    shallowCopyReports.splice(reports.indexOf(findReport), 1, updatedReport)

    return shallowCopyReports
}
function addNoteToNotes(
    notes: CustomerReportsNotes[] | undefined,
    newNote: CustomerReportsNotes,
): CustomerReportsNotes[] {
    if (!notes) {
        return [newNote]
    }

    return [...notes, newNote]
}
