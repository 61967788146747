import GraphLegendPrimary from '../../../../../../@assets/images/graph-legend-primary.png'
import GraphLegendSecondary from '../../../../../../@assets/images/graph-legend-secondary.png'
import {LegendItem} from './legend-item.styled'
import {ColourSquare} from './colour-square.styled'
import {LegendItems} from './legend-items.styled'
import {Container} from './container.styled'
import {useTheme} from 'styled-components'
import {Period} from './period.styled'
import {PeriodValue} from './period-value.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../../general/helpers'
import {WidgetOutputModel} from '../../widget.model'
import {formatDate} from '../../../../../../helpers/formatting'
import moment from 'moment'
import {useFleetScoreOverTime} from '../../contexts/use-fleet-score-over-time'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../../store/state/users/state'

interface FooterProps {
    model: WidgetOutputModel
}
export function Footer({model}: FooterProps): JSX.Element {
    const theme = useTheme()
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const {searchedVesselTagTerm} = useFleetScoreOverTime()
    const {strictMode} = useTypedSelector(currentUserSelector)
    const showTagLegend = searchedVesselTagTerm !== undefined && strictMode !== StrictMode.Strict

    return (
        <Container gridLayout={gridLayout}>
            <Period>
                Period{' '}
                <PeriodValue id={`period-value-${formatDate(moment(model.startTime))}`}>
                    {formatDate(moment(model.startTime))}
                </PeriodValue>{' '}
                -{' '}
                <PeriodValue id={`period-value-${formatDate(moment(model.endTime))}`}>
                    {formatDate(moment(model.endTime))}
                </PeriodValue>
            </Period>
            {model.data.length !== 0 && (
                <LegendItems gridLayout={gridLayout} id="legend-items">
                    {showTagLegend && (
                        <LegendItem id={`${searchedVesselTagTerm.name}-score`}>
                            <img
                                src={GraphLegendSecondary}
                                style={{width: '23px', marginRight: '10px'}}
                            />
                            {searchedVesselTagTerm.name} Score
                        </LegendItem>
                    )}
                    <LegendItem id="risk-score">
                        <img
                            src={GraphLegendPrimary}
                            style={{width: '23px', marginRight: '10px'}}
                        />
                        Risk Score
                    </LegendItem>
                    <LegendItem id="amber-risk">
                        <ColourSquare background={theme.threatScores.score.medium.background30p} />
                        Amber Risk
                    </LegendItem>
                    <LegendItem last id="red-risk">
                        <ColourSquare background={theme.threatScores.score.high.background30p} />
                        Red Risk
                    </LegendItem>
                </LegendItems>
            )}
        </Container>
    )
}
