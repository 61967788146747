import {ThunkDispatch} from 'redux-thunk'
import {PossibleColumnsVesselBeta} from '../types/paged-vessels-beta-state'
import {TimestampFilterType} from '../types/timestamp-filter'
import {VesselBetaTableDetailsMap} from '../types/vessel-beta-table-details-model'
import {ActionType} from './action-type'
import * as Actions from './actions'
import AppState from '../../../../store/types/app-state'
import {Api} from '../../../../api/Api'
import {Action} from '../../../../store/state/vessels-beta-filter/actions'
import {
    changeAnalysisPeriod,
    setSelectedColumns,
} from '../../../../store/state/vessels-beta-filter/action-creators'

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function toggleSelectedColumns(
    selectedColumnType: PossibleColumnsVesselBeta,
    selectedColumnNewValue: boolean,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    thunkDispatch(setSelectedColumns(selectedColumnType, selectedColumnNewValue))
}

export function toggleHighlightedValue(
    highlightedFilerValue: boolean,
): Actions.ToggleHighlightedValueAction {
    return {
        type: ActionType.TOGGLE_HIGHLIGHTED_FILTER_VALUE,
        payload: highlightedFilerValue,
    }
}

export function requestTablePageData(): Actions.RequestTableDataAction {
    return {type: ActionType.REQUEST_TABLE_PAGE_DATA_ACTION}
}

export function receivedRequestedTablePageData(
    data: VesselBetaTableDetailsMap,
): Actions.ReceivedTableDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_TABLE_PAGE_DATA_ACTION,
        payload: data,
    }
}

export function setAnalysisPeriod(
    analysisPeriod: TimestampFilterType,
    thunkDispatch: ThunkDispatch<AppState, Api, Action>,
): void {
    thunkDispatch(changeAnalysisPeriod(analysisPeriod))
}
