import {useMetricsPolicy} from '../../../../../contexts/metrics-policy/use-metrics-policy'
import * as Styled from './discharge-button.styled'
export function DiscardChangesButton(): JSX.Element {
    const {isMetricsUpdated, requestDiscardChanges} = useMetricsPolicy()
    return (
        <Styled.Button
            id="discharge-button"
            isChanged={isMetricsUpdated}
            onClick={() => requestDiscardChanges(true)}
        >
            <Styled.Text>Discard changes</Styled.Text>
        </Styled.Button>
    )
}
