import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'

interface SavedFilterWrapperProps {
    isHovering: boolean
}

export const SavedFilterWrapper = styled.div<SavedFilterWrapperProps>`
    position: relative;
    width: 100%;
    align-items: center;
    border-radius: ${spacing(1)};
    border: 1px solid
        ${(props) =>
            props.isHovering
                ? props.theme.colors.border.active
                : props.theme.incidents.filterBar.titleSeparator};
    padding: ${spacing(1)};
    margin-bottom: ${spacing(1)};
    cursor: pointer;
`

export const FilterName = styled.div`
    display: inline-block;
    justify-content: flex-start;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

interface DeleteProps {
    isDeletable: boolean
}

export const DeleteButton = styled.button<DeleteProps>`
    display: flex;
    justify-content: center;
    gap: ${spacing(1)};
    ${smallFont()}
    border: none;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.link.primary};
    &:hover {
        background-color: rgba(206, 218, 236, 1);
    }
    cursor: ${(props) => (props.isDeletable ? 'pointer' : 'default')};
    ${(props) => (props.isDeletable ? '' : 'pointer-events: none; opacity: 0.3;')};
`
