import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {useEffect} from 'react'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ContentContainerMiddle} from '../../templates/fixed-page/content-container-middle.styled'
import {ContentArea} from './system-page-content.styled'
import {useTheme} from 'styled-components'
import {EmailManagement} from './components/email-management/email-management'
import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'

export default function EmailRecipientsManagementPage(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.EMAIL_MANAGEMENT))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FixedPageTemplate>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.colors.background.lowContrast}
            >
                <ContentArea width={width}>
                    <EmailManagement />
                </ContentArea>
            </ContentContainerMiddle>
        </FixedPageTemplate>
    )
}
