import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {getBorder} from '../../../cards/_styled/incident-details.styled'

export const Container = styled.div`
    background: ${(props) => props.theme.incidents.dataRow.background};
    width: 360px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`
interface IncidentDetailsProps {
    incidentStatus: string
}
export const IncidentDetailsHeader = styled.div<IncidentDetailsProps>`
    color: ${(props) => props.theme.incidents.dataRow.text};
    border-top: ${(props) => getBorder(props.incidentStatus, props.theme)};
    border-bottom: ${(props) => getBorder(props.incidentStatus, props.theme)};
    padding: ${spacing(1)} ${spacing(2)};
    height: 110px;
    z-index: 1;
`

export const TitleRow = styled.div`
    display: grid;
    position: relative;
    align-items: center;
    grid-template-columns: 100px 100px 110px;
    grid-template-areas: 'INCIDENT-TYPE  INCIDENT-NUMBER  VESSEL';
    column-gap: ${spacing(2)};
    padding: ${spacing(1)} 0 ${spacing(1)} 0;
    border-bottom: 1px solid ${(props) => props.theme.reports.dataRow.separator};
`
export const DataRow = styled.div`
    display: grid;
    grid-template-columns: 100px 100px 110px;
    grid-template-areas: 'STATUS SEVERITY ASSIGNED';
    gap: ${spacing(2)};
    align-items: center;
    padding-top: 10px;
`
interface DataCellProps {
    gridArea?: string
    noBorder?: boolean
}

export const DataCell = styled.div<DataCellProps>`
    flex: 1;
    grid-area: ${(props) => props.gridArea};
    border-right: ${(props) =>
        props.noBorder ? '' : `1px solid ${props.theme.reports.dataRow.separator};`};
`
interface Props {
    gridArea: string
}
export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    ${mediumSmallFont()};
`
export const IncidentNumber = styled(Label)<Props>`
    grid-area: ${(props) => props.gridArea};
    text-align: center;
`
export const VesselCell = styled(Label)<Props>`
    grid-area: ${(props) => props.gridArea};
`
export const SubTitle = styled.div`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding-left: 8px;
`
export const IncidentDetailsContent = styled.div`
    padding: ${spacing(2)};
    height: 500px;
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
`
export const NoDataContent = styled.div`
    padding: ${spacing(2)};
    height: 500px;
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
`
export const NoDataBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
export const NoDataMessage = styled.h2`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.incidents.dataRow.errorModal};
`
export const ModalTitle = styled(Label)`
    font-size: 16px;
    text-align: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const DescriptionRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${spacing(1)} 0;
`
export const WarningMessage = styled.h2`
    text-align: flex-start;
    ${mediumSmallFont()}
    color: ${(props) => props.theme.incidents.incidentStatus.new};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
`
export const DescriptionMessage = styled.h2`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: 0;
    ${mediumSmallFont()};
`
export const ModalLabel = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    width: 120px;
`
export const EventModal = styled(Label)`
    text-align: flex-start;
    padding-right: ${spacing(1)};
`

export const AssetsSection = styled.div`
    padding: ${spacing(1)} 0;
`

interface AssetsRowProps {
    centered?: boolean
}

export const AssetsRow = styled.div<AssetsRowProps>`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
`
export const TimeSection = styled.div`
    padding: ${spacing(1)} 0;
`

export const IncidentDetailsMessage = styled.div`
    color: ${(props) => props.theme.incidents.dataRow.text};
    border-bottom: ${(props) => `1px solid ${props.theme.incidents.dataRow.statusBar.closed}`};
    padding: ${spacing(1)} ${spacing(2)};
`

export const MessageTitle = styled(Label)`
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Subtitle = styled.div`
    ${smallFont()}
    padding: ${spacing(1)}
`

export const Highlighted = styled.span`
    font-weight: ${(props) => props.theme.font.weight.bold};
`
