import styled from 'styled-components'
import {NodeValues} from '../../../../../values/nodes/NodeData'
import {ThreatValue} from '../../../../../helpers/threatHelper'
import {getBackgroundColor, getColor} from '../../../../../theme/helpers/risk-score'
export const AssetsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`

export const AssetsCell = styled.div`
    height: 36px;
    width: 36px;
`
interface AssetSquareProps {
    score: number
    nodeValue: NodeValues
    threatLevel: ThreatValue
}

export const AssetSquare = styled.div<AssetSquareProps>`
    padding: 3px 0;
    margin: 2px;
    border-radius: 3px;
    font-size: 18px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: center;
    height: 100%;
    width: 100%;
    min-height: 20px;
    min-width: 20px;
    color: ${(props) => getColor(props.theme, props.score, props.nodeValue, props.threatLevel)};
    background-color: ${(props) =>
        getBackgroundColor(props.theme, props.score, props.nodeValue, props.threatLevel)};
`
