import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
