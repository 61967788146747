import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const DetailPanelHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    border-top: ${(props) => `2px solid ${props.theme.unknownAssets.filterBar.titleSeparator}`};
    border-bottom: ${(props) => `2px solid ${props.theme.myVessels.filterBar.titleSeparator}`};
    padding: ${spacing(2)};
    z-index: 1;
    position: relative;
    min-height: 30px;
`
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing(1)};
`
export const Title = styled.h3`
    text-align: center;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
`
export const VesselTitle = styled.p`
    text-align: center;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`
