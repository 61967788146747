import {ButtonsArea} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/buttons-area/buttons-area'
import {DetailsSection} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/details-section/details-section'
import {PolicyName} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/policy-name/policy-name'
import {DetailPanelHeader} from '../../shared/header/detail-panel-header'
import {
    DetailsExpandedSection,
    PolicyNameWrapper,
} from './enforcement-policy-detail-expanded.styled'

export function AddNewEnforcementPolicyDetailExpanded(): JSX.Element {
    return (
        <DetailsExpandedSection>
            <DetailPanelHeader text="Add new enforcement policy" />
            <PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea />
            </PolicyNameWrapper>
            <DetailsSection />
        </DetailsExpandedSection>
    )
}
