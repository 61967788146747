import {Dispatch, useContext} from 'react'
import {PagedReportsContext} from './paged-reports-context'
import {UsePagedReportsResult} from './use-paged-reports-output'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {REST} from '../../..'
import {CustomerReportAttachmentData} from '../../../values/customer-reports'
import downloadFile from '../../../helpers/downloadFile'
import {GuidType} from '../../../values/generic-type-defintions'
import {Location} from '../../../store/state/locations/state'
import {warn} from '../../../helpers/logging'
import {filteredVesselIds} from '../helper/filtered-vessel-ids.reselector'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {VesselFilter} from '../../../store/state/vessel-filter/types/vessel-filter-state'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {reportsFilterSelector} from '../../../store/state/reports-filter/selectors'

const REPORTS_ENDPOINT = '/api/v1/customerReports'

export function usePagedReports(): UsePagedReportsResult {
    const {state, dispatch} = useContext(PagedReportsContext)

    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedReports must be used within a PagedReportsContext')
    }

    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(reportsFilterSelector)
    const vesselFilter = useTypedSelector(vesselFilterSelector)
    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())
        getData(
            dispatch,
            false,
            0,
            state.pageSize,
            currentFilter.selectedRating,
            currentFilter.selectedFromTimeRange,
            currentFilter.selectedToTimeRange,
            currentFilter.selectedReportType,
            currentFilter.includeWholeFleetReports,
            allLocations,
            vesselFilter,
        )
    }

    function selectPage(requestedPage: number | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (requestedPage == undefined) {
            warn('requestedPage is not defined')
            return
        }

        if (state.dataReportsMap?.has(requestedPage)) {
            dispatch(ActionCreators.switchToCachedPageData(requestedPage))
            return
        }

        const offset = requestedPage * state.pageSize

        dispatch(ActionCreators.requestPageData(requestedPage))

        getData(
            dispatch,
            false,
            offset,
            state.pageSize,
            currentFilter.selectedRating,
            currentFilter.selectedFromTimeRange,
            currentFilter.selectedToTimeRange,
            currentFilter.selectedReportType,
            currentFilter.includeWholeFleetReports,
            allLocations,
            vesselFilter,
        )
    }

    function toggleNotesExpanded(id: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleNotesExpanded(id))
    }

    function setNoteExpanded(id: GuidType, expanded: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNotesExpanded(id, expanded))
    }

    function saveReportNotes(identity: GuidType, note: string, user: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        postNotes(dispatch, identity, note, user)
    }
    function downloadCustomerReports(
        identity: GuidType,
        attachment: CustomerReportAttachmentData,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        getAttachmentCustomerReports(dispatch, identity, attachment)
    }

    function getFilteredData(
        selectedReportType: string | undefined,
        selectedRating: number,
        selectedFromTimeRange: string | undefined,
        selectedToTimeRange: string | undefined,
        includeWholeFleetReports: boolean,
        currentVesselFilter: VesselFilter,
        reportModelId: GuidType | null,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        if (reportModelId) {
            getReportModalId(
                dispatch,
                state.pageSize,
                selectedRating ? selectedRating : currentFilter.selectedRating,
                undefined,
                undefined,
                undefined,
                includeWholeFleetReports,
                reportModelId,
            )
            return
        }
        dispatch(ActionCreators.getFilteredData())
        getData(
            dispatch,
            false,
            0,
            state.pageSize,
            selectedRating ? selectedRating : currentFilter.selectedRating,
            selectedFromTimeRange ? selectedFromTimeRange : currentFilter.selectedFromTimeRange,
            selectedToTimeRange ? selectedToTimeRange : currentFilter.selectedToTimeRange,
            selectedReportType ? selectedReportType : currentFilter.selectedReportType,
            includeWholeFleetReports,
            allLocations,
            currentVesselFilter,
        )
    }

    function getReportModalId(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        rating: number,
        timeRangeFrom: string | undefined,
        timeRangeTo: string | undefined,
        selectedReportType: string | undefined,
        includeWholeFleet: boolean,
        reportModelId: GuidType,
        allLocations?: Location[],
    ): void {
        const locations = allLocations ?? null
        REST.post(`${REPORTS_ENDPOINT}/${reportModelId}/find`, {
            rating,
            locations,
            includeWholeFleet,
            from: timeRangeFrom,
            to: timeRangeTo,
            reportType: selectedReportType,
            pagination: {count: pageSize},
        }).then((response) => {
            const requestedPage =
                response.data.criteria.pagination.offset / response.data.criteria.pagination.count
            dispatch(ActionCreators.requestPageData(requestedPage))
            dispatch(
                ActionCreators.receivedRequestedPageData(
                    response.data.data,
                    response.data.totalNumberOfReports || 0,
                    response.data.earliestPeriodEndDate,
                    response.data.latestPeriodEndDate,
                    response.data.allReportTypes,
                ),
            )
            const findReportDetails = response.data.data.find(
                (data: Record<string, unknown>) => data.identity === reportModelId,
            )
            if (!findReportDetails) {
                updateReportDetails(dispatch, pageSize, reportModelId)
            } else {
                dispatch(ActionCreators.setFindReportDetails(findReportDetails))
            }
            setNoteExpanded(reportModelId, true)
        })
    }

    function updateReportDetails(
        dispatch: Dispatch<AllActions>,
        pageSize: number,
        reportModelId: GuidType,
    ): void {
        REST.post(`${REPORTS_ENDPOINT}/${reportModelId}/find`, {
            pagination: {count: pageSize},
        }).then((response) => {
            const findReportDetails = response.data.data.find(
                (data: Record<string, unknown>) => data.identity === reportModelId,
            )
            dispatch(ActionCreators.setFindReportDetails(findReportDetails))
            setNoteExpanded(reportModelId, true)
        })
    }

    const hasData = state.totalNumberOfReports != undefined
    const dataReports = hasData
        ? state.dataReportsMap?.get(state.selectedPage || 0) ?? undefined
        : undefined

    return {
        loadingDataState: state.loadingDataState,
        loadingFilterState: state.loadingFilterState,
        pageSize: state.pageSize,
        dataReports: dataReports,
        totalNumberOfReports: state.totalNumberOfReports,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        notesExpanded: state.notesExpanded,
        toggleNotesExpanded,
        saveReportNotes,
        downloadCustomerReports,
        earliestPeriodEndDate: state.earliestPeriodEndDate,
        latestPeriodEndDate: state.latestPeriodEndDate,
        allReportTypes: state.allReportTypes,
        getFilteredData,
        setNoteExpanded,
        findReportDeviceForModal: state.findReportDetailForModal,
    }
}

function getFormattedFilteredVessels(
    allLocations: Location[],
    filteredVessels: string[],
): string[] | undefined {
    return filteredVessels.length === allLocations.length ? undefined : filteredVessels
}

function getData(
    dispatch: Dispatch<AllActions>,
    showLoader = true,
    offset: number,
    pageSize: number,
    rating: number,
    timeRangeFrom: string | undefined,
    timeRangeTo: string | undefined,
    selectedReportType: string | undefined,
    includeWholeFleet: boolean,
    allLocations: Location[],
    currentFilter: VesselFilter,
): void {
    if (showLoader) {
        dispatch(ActionCreators.requestInitialPageData())
    }
    const filteredVessels = filteredVesselIds(allLocations, currentFilter)
    REST.post(`${REPORTS_ENDPOINT}/find`, {
        from: timeRangeFrom,
        to: timeRangeTo,
        pagination: {offset: offset, count: pageSize},
        rating: rating,
        locations: getFormattedFilteredVessels(allLocations, filteredVessels),
        reportType: selectedReportType ? selectedReportType : undefined,
        includeWholeFleet: includeWholeFleet,
    }).then((response) => {
        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data,
                response.data.totalNumberOfReports || 0,
                response.data.earliestPeriodEndDate,
                response.data.latestPeriodEndDate,
                response.data.allReportTypes,
            ),
        )
    })
}

function buildNotesUrl(identity: GuidType): string {
    return `${REPORTS_ENDPOINT}/${identity}/notes`
}

function postNotes(
    dispatch: Dispatch<AllActions>,
    identity: GuidType,
    note: string,
    user: string,
): void {
    REST.post(buildNotesUrl(identity), {
        customerReportIdentity: identity,
        note,
    }).then((response) => {
        dispatch(
            ActionCreators.addNoteToCustomerReport(
                response.data.note,
                response.data.customerReportIdentity,
                user,
            ),
        )
    })
}

function buildDownloadUrl(identity: GuidType, attachment: CustomerReportAttachmentData): string {
    return `${REPORTS_ENDPOINT}/${identity}/files/${attachment.identity}`
}

function getAttachmentCustomerReports(
    _dispatch: Dispatch<AllActions>,
    identity: GuidType,
    attachment: CustomerReportAttachmentData,
): void {
    REST.get(buildDownloadUrl(identity, attachment), {
        responseType: 'blob',
    })
        .then((resp) => resp.data.arrayBuffer())
        .then((responseArray) => {
            if (responseArray) {
                const blob = new Blob([responseArray], {type: attachment.contentType})
                downloadFile(blob, attachment.name)
            }
        })
}
