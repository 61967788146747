import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface ContainerProps {
    disabled?: boolean
}

export const Wrapper = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)} 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
    font-style: italic;
`
export const TitleText = styled.a`
    ${mediumSmallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    text-decoration: underline;
`
export const FileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${spacing(3)};
`
