import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {MetricsSortType, SortColumnType} from '../../../../../store/state/metrics-filter-beta/state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    metricsBetaFilterLoadingSelector,
    metricsBetaFilterSelector,
} from '../../../../../store/state/metrics-filter-beta/selectors'
import {getNewSortByDirection} from './_styled/metrics-table.styled'
import {setSortColumn} from '../../../../../store/state/metrics-filter-beta/action-creators'
import * as Styled from './_styled/metrics-table.styled'
import {getLines} from '../../data-helper'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: MetricsSortType
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const isLoadingFilter = useTypedSelector(metricsBetaFilterLoadingSelector)
    const {orderBy} = useTypedSelector(metricsBetaFilterSelector)
    const dispatch = useDispatch()

    function setSortBy(selectedColumn: MetricsSortType) {
        const newSortColumn: SortColumnType = {
            column: selectedColumn,
            isAscending: getNewSortByDirection(selectedColumn, orderBy),
        }
        dispatch(setSortColumn(newSortColumn))
    }
    return (
        <Styled.TableHeaderCell
            id={`sortable-column_${colName}`}
            gridColumn={gridColumn}
            selectable
        >
            <Styled.TableHeaderText
                onClick={() => setSortBy(colName)}
                active={!isLoadingFilter && orderBy.column === colName}
                id={`text_${colName}`}
            >
                <Styled.Text>
                    {getLines(text).map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </Styled.Text>
                <Styled.IconStyled arrowDec={orderBy.column === colName && !orderBy.isAscending}>
                    {!isLoadingFilter && <Icon width={12} height={17} glyph="Arrow" />}
                </Styled.IconStyled>
            </Styled.TableHeaderText>
        </Styled.TableHeaderCell>
    )
}
