import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ReportsCardsFormat} from './components/cards/reports-cards-format'
import {showCards} from './components/data-helpers'
import {ReportsTableFormat} from './components/table/reports-table-format'

export function Content(): JSX.Element {
    const {width} = useDimensions()

    return showCards(width) ? <ReportsCardsFormat /> : <ReportsTableFormat />
}
