import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './_styled/data-cell-fixed.styled'

interface DataCellFixedProps {
    label: string
    gridArea?: string
    children: ReactNode | ReactNodeArray
}

export function DataCellFixed({label, children, gridArea}: DataCellFixedProps): JSX.Element {
    const formattedGridArea = gridArea || label.toLocaleUpperCase().replace(/ /g, '-')
    const id = `vessel-configuration_${formattedGridArea.toLowerCase()}`
    return (
        <>
            <Styled.Label gridArea={formattedGridArea} id={`${id}-label`}>
                {label}
            </Styled.Label>
            <Styled.Value gridArea={`${formattedGridArea}-VALUE`} id={`${id}-value`}>
                {children}
            </Styled.Value>
        </>
    )
}
