import styled from 'styled-components'

export const TableHeaderGrid = styled.div`
    display: grid;
    grid-template-columns: 10px 135px auto 140px 200px 60px 10px;
    grid-template-rows: 48px;
    column-gap: 10px;
    padding: 10px;
    background-color: ${(props) => props.theme.modalDialog.dataArea.background};
`
