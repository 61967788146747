import moment from 'moment'
import {formatDate, normaliseScore} from '../../../../../../../helpers/formatting'
import {EtsTrendScore} from '../../../../../../../values/ets-trend-score/EtsTrendScore'
import {Container} from './styled/container.styled'
import {Title} from './styled/title.styled'
import {SubTitle} from './styled/sub-title.styled'
import {LocationTagScore} from '../../../../../../../values/location-tag-score'
import {useFleetScoreOverTime} from '../../../contexts/use-fleet-score-over-time'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../../../store/state/users/state'

export interface CurrentScoreTooltipProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label?: any
    data: EtsTrendScore[]
    secondData: LocationTagScore[]
}

export function CurrentScoreTooltip({
    active,
    payload,
    label,
    data,
    secondData,
}: CurrentScoreTooltipProps): JSX.Element | null {
    const {searchedVesselTagTerm} = useFleetScoreOverTime()
    const {strictMode} = useTypedSelector(currentUserSelector)

    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.time === label)
        const secondDataPoint = secondData.find((element) => element.time === label)
        const showSecondData =
            searchedVesselTagTerm && secondDataPoint && strictMode !== StrictMode.Strict

        return (
            <Container>
                <Title>
                    <div id={`risk-score-${Math.round(normaliseScore(dataPoint?.score ?? 0))}`}>
                        Risk Score: {Math.round(normaliseScore(dataPoint?.score ?? 0))}
                    </div>
                    {showSecondData && (
                        <div id={`tag-score-${Math.round(normaliseScore(dataPoint?.score ?? 0))}`}>
                            Tag Score: {Math.round(normaliseScore(secondDataPoint?.tagScore ?? 0))}
                        </div>
                    )}
                </Title>
                <SubTitle id={`date-${formatDate(moment(label))}`}>
                    {formatDate(moment(label))}
                </SubTitle>
            </Container>
        )
    }

    return null
}
