import {PagedUnknownAssetsBetaState, ProcessingStatus} from './paged-unknown-assets-state'
import LoadingState from '../../../../values/loading-state-enum'
import {Anomaly} from './formatted-anomaly'
import {DEFAULT_ASSET_DATA, DEFAULT_ASSET_UPDATE_DATA} from './asset-data-type'

export const DEFAULT_PAGED_UNKNOWN_ASSETS_BETA_STATE: PagedUnknownAssetsBetaState = {
    loadingDataState: LoadingState.NotPopulated,
    loadingFilterState: LoadingState.NotPopulated,
    loadingToggleState: {id: undefined, loading: LoadingState.NotPopulated},
    dataUnknownAssets: new Map<number, Anomaly[]>(),
    totalNumberOfUnknownAssets: undefined,
    pageSize: 10,
    selectedPage: 0,
    totalNumberOfPages: undefined,
    activeLocationId: undefined,
    showFilterBar: false,
    isolatedAssetsStatus: undefined,
    acEnabled: false,
    networkAssetModalId: null,
    isProcessingnetworkAssetModal: ProcessingStatus.NOT_PROCESSING,
    findNetworkAsset: undefined,
    showEnableMonitoringModal: false,
    assetData: DEFAULT_ASSET_DATA,
    updateAssetData: DEFAULT_ASSET_UPDATE_DATA,
    displayedHostname: undefined,
    isProcessingSave: ProcessingStatus.NOT_PROCESSING,
    allNetworkBehaviours: [],
    networkAssetBehaviourData: [],
    isInterlinking: false,
    isInactive: false,
    reportUrl: null,
    showGenerateReportPopup: false,
} as PagedUnknownAssetsBetaState
