/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useState, useEffect, useRef, TextareaHTMLAttributes} from 'react'
import * as Styles from './add-notes.styled'

export function CommentEntry(props: TextareaHTMLAttributes<HTMLTextAreaElement>): JSX.Element {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const [text, setText] = useState('')
    const [textAreaHeight, setTextAreaHeight] = useState('auto')
    const [parentHeight, setParentHeight] = useState('auto')

    useEffect(() => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
        setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`)
    }, [text])

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (textAreaHeight !== parentHeight) {
            setTextAreaHeight('auto')
            setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
        }
        setText(event.target.value)

        if (props.onChange) {
            props.onChange(event)
        }
    }

    return (
        <Styles.TextAreaWrapper minHeight={parentHeight}>
            <Styles.TextArea
                {...props}
                ref={textAreaRef}
                rows={1}
                height={textAreaHeight}
                onChange={onChangeHandler}
            >
                {text}
            </Styles.TextArea>
        </Styles.TextAreaWrapper>
    )
}
