import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {
    showOneColNarrow,
    showOneColWide,
    showTwoColNarrow,
    showTwoColWide,
    showOneCol,
    showTwoCol,
} from '../../shared/grid-layout-helper'
import {GridLayout} from '../../shared/grid-layout.enum'

interface VesselInfoStyleProps {
    gridLayout: GridLayout
}

function getGridTemplate(gridLayout: GridLayout): string {
    if (showTwoColNarrow(gridLayout)) {
        return "'GUID' 'GUID-VALUE' 'DESCRIPTION' 'DESCRIPTION-VALUE' 'CODE' 'CODE-VALUE' 'BUTTON' 'BUTTON-VALUE'"
    } else if (showOneColWide(gridLayout)) {
        return "'GUID GUID-VALUE' 'DESCRIPTION DESCRIPTION-VALUE' 'CODE CODE-VALUE' 'BUTTON BUTTON-VALUE' 'COPYBUTTON COPYBUTTON-VALUE'"
    } else if (showOneColNarrow(gridLayout)) {
        return "'GUID' 'GUID-VALUE' 'DESCRIPTION' 'DESCRIPTION-VALUE' 'CODE' 'CODE-VALUE' 'BUTTON' 'BUTTON-VALUE' 'COPYBUTTON' 'COPYBUTTON-VALUE'"
    }
    return "'GUID GUID-VALUE' 'DESCRIPTION DESCRIPTION-VALUE' 'CODE CODE-VALUE' 'BUTTON BUTTON-VALUE'"
}

export const VesselInfoStyle = styled.div<VesselInfoStyleProps>`
    display: grid;
    ${(props) => (showOneColNarrow(props.gridLayout) ? '' : 'justify-content: flex-start;')}
    grid-template-areas: ${(props) => getGridTemplate(props.gridLayout)};
    grid-row-gap: ${(props) => (showTwoColWide(props.gridLayout) ? spacing(3) : spacing(1))};
    grid-column-gap: ${(props) => (showOneCol(props.gridLayout) ? spacing(8) : spacing(5))};
    padding: ${(props) =>
        showTwoColWide(props.gridLayout)
            ? spacing(8)
            : showOneColNarrow(props.gridLayout)
              ? spacing(4)
              : spacing(6)};
    padding-bottom: ${(props) => (showTwoCol(props.gridLayout) ? spacing(6) : spacing(4))};
`

interface ButtonWrapperProps {
    gridLayout: GridLayout
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
    display: flex;
    column-gap: ${spacing(3)};
    ${(props) => (showOneColWide(props.gridLayout) ? `margin: ${spacing(1)} 0;` : '')}
`
