import {useDispatch} from 'react-redux'
import * as Styled from './vessel-depolyment-status-filter.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {hasDeploymentIssueSelector} from '../../../../../store/state/vessel-management-filter/selectors'
import {toggleVesselDeploymentStatus} from '../../../../../store/state/vessel-management-filter/action-creators'

export function VesselDeploymentStatusFilter(): JSX.Element {
    const dispatch = useDispatch()
    const hasDeploymentIssue = useTypedSelector(hasDeploymentIssueSelector)

    const deploymentIssueOptions = ['Has deployment issues', 'No deployment issues']

    const selectValue =
        hasDeploymentIssue === undefined
            ? 'All'
            : hasDeploymentIssue
              ? 'Has deployment issues'
              : 'No deployment issues'

    const getPayload = (value: string) => {
        switch (value) {
            case 'Has deployment issues':
                return true
            case 'No deployment issues':
                return false
            default:
                return undefined
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const payload = getPayload(event.currentTarget.value as string)
        dispatch(toggleVesselDeploymentStatus(payload))
    }

    return (
        <Styled.Wrapper>
            <Styled.FilterHeadingRow>Vessel Deployment Issue</Styled.FilterHeadingRow>
            <Styled.Input>
                <Styled.Select onChange={onChange} value={selectValue}>
                    <option key="default-option" value="All">
                        - All -
                    </option>
                    {deploymentIssueOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
