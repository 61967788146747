import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {rolesSelector} from '../../../../../store/state/roles/selectors'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {CustomerReports} from '../../../../../values/customer-reports'
import {isUserAllowed} from '../../../../../values/Role'
import {getNotesFormatted} from '../../data-helpers'
import {Notes} from '../../shared/report-details/notes/notes'
import {CardDataCell} from '../card-data-cell'
import {NotesSection} from './reports-details-card.styled'

interface ReportDetailsCardProps {
    report: CustomerReports
}
export function ReportsDetailsCard({report}: ReportDetailsCardProps): JSX.Element {
    const users = useTypedSelector(usersSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const isInternalUser = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])

    return (
        <CardDataCell label="" report={report} identifier="notes">
            <NotesSection id={`reports-details-card-${report.identity}`}>
                <Notes
                    notes={getNotesFormatted(report.notes, users, isInternalUser)}
                    card={true}
                    identity={report.identity}
                />
            </NotesSection>
        </CardDataCell>
    )
}
