import * as Styled from './_styled/button.styled'

interface ButtonProps {
    onClick: () => void
    disabled?: boolean
    label: string
}

export function SimpleButton({onClick, disabled, label}: ButtonProps): JSX.Element {
    return (
        <Styled.Button id={`${label}-button`} onClick={onClick} disabled={disabled}>
            <Styled.ButtonText>{label}</Styled.ButtonText>
        </Styled.Button>
    )
}
