import {CardDataCell} from './data-cell'
import {RadioButton} from './radio-button/radio-button'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {extendedDataRequestStateSelector} from '../../../store/state/extended-data-request/selectors'
import {useDispatch} from 'react-redux'
import {setLocation} from '../../../store/state/extended-data-request/action-creators'
import {DeviceName} from './_styled/data-cell.styled'
import {locationMapSelector} from '../../../store/state/locations/selectors'
import {getLocationDescription} from '../../../store/state/locations/state'

export function Information(): JSX.Element {
    const {data, currentLocations} = useTypedSelector(extendedDataRequestStateSelector)
    const dispatch = useDispatch()
    const locationMap = useTypedSelector(locationMapSelector)

    const locationDescriptionArray = currentLocations
        .map((locationId) => ({
            id: locationId,
            name: getLocationDescription(locationMap, locationId),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, 3)

    return (
        <CardDataCell gridArea="INFORMATION" label="Request more information about">
            <DeviceName>{data.title}</DeviceName>

            <RadioButton
                label="Include data for this item on other vessels"
                isChecked={data.location === null}
                onClick={() => dispatch(setLocation(null))}
            />

            {locationDescriptionArray.map((location) => (
                <RadioButton
                    key={location.id}
                    label={`Include data for other items on ${location.name}`}
                    isChecked={data.location === location.id}
                    onClick={() => dispatch(setLocation(location.id))}
                />
            ))}
        </CardDataCell>
    )
}
