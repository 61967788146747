import NetworkAnomalyMatrixRecord from '../../../../../../values/anomalies/network-anomaly-matrix-record'
import CommonEventMonaDetails from '../../../../../../values/common-event/common-event-mona-details'
import AnomalyMessageFormatter from './anomaly-message-formatter'
import buildCombinedKey from './build-combined-key'
import nameIfPopulated from './name-if-populated'
import showValueOrUnknown from './show-value-or-unknown'

class ClientAnomalyMessageFormatterForPrivateServer implements AnomalyMessageFormatter {
    format(
        commonEvent: CommonEventMonaDetails,
        matrixRecord: NetworkAnomalyMatrixRecord,
    ): string | null | undefined {
        const combinedKey = buildCombinedKey(matrixRecord)
        switch (combinedKey) {
            case 'false_false_false':
                return `New outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(commonEvent.destinationIpAddress)}${nameIfPopulated(
                    commonEvent.destinationAlias,
                )} at new port ${showValueOrUnknown(commonEvent.port)}${nameIfPopulated(
                    commonEvent.serviceName,
                )}`
            case 'false_false_true':
                return `New outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(commonEvent.destinationIpAddress)}${nameIfPopulated(
                    commonEvent.destinationAlias,
                )} at port ${showValueOrUnknown(commonEvent.port)}${nameIfPopulated(
                    commonEvent.serviceName,
                )}`
            case 'false_true_false':
                return `New outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at new port ${showValueOrUnknown(commonEvent.port)}`
            case 'false_true_true':
                return `New outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at port ${showValueOrUnknown(commonEvent.port)}`
            case 'true_false_false':
                return `Outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at new port ${showValueOrUnknown(commonEvent.port)}`
            case 'true_false_true':
                return `Outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at port ${showValueOrUnknown(commonEvent.port)}`
            case 'true_true_false':
                return `Outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at new port ${showValueOrUnknown(commonEvent.port)}`
            case 'true_true_true':
                return `Outbound ${showValueOrUnknown(
                    commonEvent.protocol,
                )} to ${showValueOrUnknown(
                    commonEvent.destinationIpAddress,
                )} at port ${showValueOrUnknown(commonEvent.port)}`
            /* istanbul ignore next */
            default:
                // eslint-disable-next-line no-console
                console.error('Could not match combined key of: ' + combinedKey)
                return commonEvent.details
        }
    }
}

export default ClientAnomalyMessageFormatterForPrivateServer
