import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {
    IncidentSeverityValue,
    IncidentSeverityValues,
} from '../../../../../values/incident-response-values'

function getBackgroundColor(incidentSeverity: IncidentSeverityValue, theme: CyberOwlTheme): string {
    switch (incidentSeverity) {
        case IncidentSeverityValues.LOW:
            return theme.incidents.incidentSeverity.active.low
        case IncidentSeverityValues.MEDIUM:
            return theme.incidents.incidentSeverity.active.medium
        case IncidentSeverityValues.HIGH:
            return theme.incidents.incidentSeverity.active.high
        case IncidentSeverityValues.CRITICAL:
            return theme.incidents.incidentSeverity.active.critical
        default:
            return theme.incidents.incidentSeverity.active.low
    }
}

interface SquareProps {
    incidentSeverity: IncidentSeverityValue
    isClosed?: boolean
}

export const Square = styled.span<SquareProps>`
    color: white;
    background-color: ${(props) => getBackgroundColor(props.incidentSeverity, props.theme)};
    height: ${spacing(6)};
    width: ${spacing(12)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin-right: ${(props) => (props.isClosed ? `${spacing(5)}` : '')};
`
