import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'

interface Props {
    text?: string
}
export function CloseFilterButton({text}: Props): JSX.Element {
    const {displayFilterBar} = usePagedSoftwareInventory()

    return (
        <Button
            onClick={() => {
                displayFilterBar(false)
            }}
            id="CloseFilterButton"
        >
            <XCircle />
            {text}
        </Button>
    )
}
