import moment from 'moment'
import {AverageTimeToResolveIncidentsResponse} from './types/average-time-to-resolve-incidents'
import {CurrentVesselScoreResponse} from './types/current-vessel-score-model-api'
import {FrameworkScoreCardResponse} from './types/framework-score-card-api'
import {FrameworkSummaryBenchmarkResponse} from './types/framework-summary-benchmark-api'
import {FrameworkSummaryTargetResponse} from './types/framework-summary-target-api'
import {FrameworkSummaryTrendResponse} from './types/framework-summary-trends-api'
import {MissingInvetoryAllTypesResponse} from './types/missing-inventory-all-types-api'
import {MissingMonitoredAssetsResponse} from './types/missing-monitored-assets-inventory-api'
import {NumberOfDaysSinceLastDataResponse} from './types/number-of-days-since-last-data-api'
import {NumberOfIncidentsResponse} from './types/number-of-incidents-raised-and-open'
import {OldestOpenIncidentResponse} from './types/oldest-open-incident-api'
import {PossibleColumnsVesselBeta} from './types/paged-vessels-beta-state'
import {SortColumnType} from './types/sort-column'
import {TotalInventoryAllTypesResponse} from './types/total-inventory-all-types-api'
import {
    VesselBetaTableDetailsMap,
    VesselBetaTableDetailsModel,
} from './types/vessel-beta-table-details-model'
import {VesselDeploymentStatus} from '../../vessel-management/contexts/type/deployment-status-types'
import {LocationIdType} from '../../../store/state/locations/state'
import {AssetThreatScoreOutputModel} from '../../my-vessels-v2/listing/reselectors/asset-threat-score.model'

export function getDisplayColumnName(selectedColumn: PossibleColumnsVesselBeta): string {
    const displayNameMappings: Record<PossibleColumnsVesselBeta, string> = {
        newInventoryAllTypes: 'New inventory (all types)',
        totalInventoryAllTypes: 'Total inventory (all types)',
        untrustedInventoryAllTypes: 'Untrusted inventory (all types)',
        trustedInventoryAllTypes: 'Trusted inventory (all types)',
        newInventoryAllTypesPreviousPeriod: 'New inventory (all types) in the previous period',
        missingNetworkDevicesBusinessNetwork: 'Missing network devices - Business network',
        missingNetworkDevicesOTNetwork: 'Missing network devices - OT network',
        missingNetworkDevicesMonitoredAssets: 'Missing network devices - Monitored assets',
        numberOfIncidentsRaised: 'Number of incidents raised',
        numberOfCurrentOpenIncidents: 'Number of current open incidents',
        numberOfIncidentsOpen: 'Number of incidents open',
        monitoredAssetsInOpenIncidents: 'Monitored assets in open incidents',
        monitoredAssetsInOpenIncidentsAverageTime: 'Total average time to resolve incident',
        incidentsBySeverityHigh: 'Incidents by severity - High',
        oldestOpenIncident: 'Oldest open incident',
        incidentsRaisedPreviousPeriod: 'Incidents raised in the previous period',
        incidentsRaisedComparedToWholeFleet: 'Incidents raised compared to whole fleet',
        numberOfIncidentsAssignedToVessel: 'Number of incidents assigned to vessel',
        frameworkProtection: 'Framework: Protection',
        frameworkMaintenance: 'Framework: Maintenance',
        frameworkBehaviour: 'Framework: Behaviour',
        assetsWithWorstMetrics: 'Assets with worst metrics',
        frameworkSummaryScorecard: 'Framework summary: Scorecard',
        frameworkSummaryTarget: 'Framework summary: Target',
        frameworkSummaryBenchmark: 'Framework summary: Benchmark',
        frameworkSummaryTrend: 'Framework summary: Trend',
        assetsAtRisk: 'Assets at risk',
        currentVesselScore: 'Current vessel score',
        numberOfDaysSinceLastData: 'Number of days since last data',
        numberOfDaysSinceLastNetworkData: 'Number of days since last network data',
        deploymentSummary: 'Deployment summary',
        assetsAtRiskHighValue: 'Assets at risk - High value',
    }

    return displayNameMappings[selectedColumn]
}

export function orderFunc(
    selectedColumn: SortColumnType,
    tableVesselsDataMap: VesselBetaTableDetailsMap | undefined,
    filteredLocationsIds: string[],
): string[] {
    const valuesToDisplay =
        tableVesselsDataMap?.get(selectedColumn.orderBy) ?? ({} as VesselBetaTableDetailsModel)
    switch (selectedColumn.orderBy) {
        case PossibleColumnsVesselBeta.CurrentVesselScore:
            const currentVesselOutput =
                valuesToDisplay.selectedColumnContent as CurrentVesselScoreResponse[]
            if (!currentVesselOutput || currentVesselOutput?.length === 0) {
                return filteredLocationsIds
            }
            const sortedCurrentVesselOutput = selectedColumn.orderAscending
                ? [...currentVesselOutput]?.sort((a, b) => a.score - b.score)
                : [...currentVesselOutput]?.sort((a, b) => b.score - a.score)
            return sortedCurrentVesselOutput?.map((item) => item.locationId)
        case PossibleColumnsVesselBeta.FrameworkProtection:
            const protectionScorecardContent =
                valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
            if (!protectionScorecardContent || protectionScorecardContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedProtectionScoreOutput = selectedColumn.orderAscending
                ? [...protectionScorecardContent]?.sort(
                      (a, b) => (a.protectionScore ?? -1) - (b.protectionScore ?? -1),
                  )
                : [...protectionScorecardContent]?.sort(
                      (a, b) => (b.protectionScore ?? -1) - (a.protectionScore ?? -1),
                  )
            const resultProtectionScore = sortedProtectionScoreOutput?.map(
                (item) => item.locationId,
            )
            if (resultProtectionScore.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultProtectionScore.includes(locationId),
                )

                return resultProtectionScore.concat(missingLocationIds)
            }
            return resultProtectionScore
        case PossibleColumnsVesselBeta.FrameworkMaintenance:
            const maintenanceScorecardContent =
                valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
            if (!maintenanceScorecardContent || maintenanceScorecardContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedMaintenanceScoreOutput = selectedColumn.orderAscending
                ? [...maintenanceScorecardContent]?.sort(
                      (a, b) => (a.maintenanceScore ?? -1) - (b.maintenanceScore ?? -1),
                  )
                : [...maintenanceScorecardContent]?.sort(
                      (a, b) => (b.maintenanceScore ?? -1) - (a.maintenanceScore ?? -1),
                  )
            const resultMaintenanceScore = sortedMaintenanceScoreOutput?.map(
                (item) => item.locationId,
            )
            if (resultMaintenanceScore.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultMaintenanceScore.includes(locationId),
                )

                return resultMaintenanceScore.concat(missingLocationIds)
            }
            return resultMaintenanceScore
        case PossibleColumnsVesselBeta.FrameworkBehaviour:
            const behaviourScorecardContent =
                valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
            if (!behaviourScorecardContent || behaviourScorecardContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedBehaviourScoreOutput = selectedColumn.orderAscending
                ? [...behaviourScorecardContent]?.sort(
                      (a, b) => (a.behaviourScore ?? -1) - (b.behaviourScore ?? -1),
                  )
                : [...behaviourScorecardContent]?.sort(
                      (a, b) => (b.behaviourScore ?? -1) - (a.behaviourScore ?? -1),
                  )
            const resultBehaviourScore = sortedBehaviourScoreOutput?.map((item) => item.locationId)
            if (resultBehaviourScore.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultBehaviourScore.includes(locationId),
                )

                return resultBehaviourScore.concat(missingLocationIds)
            }
            return resultBehaviourScore
        case PossibleColumnsVesselBeta.FrameworkSummaryScorecard:
            const summaryScorecardContent =
                valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
            if (!summaryScorecardContent || summaryScorecardContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedFrameworkSummaryScoreOutput = selectedColumn.orderAscending
                ? [...summaryScorecardContent]?.sort(
                      (a, b) =>
                          ((a.protectionScore ?? -1) +
                              (a.maintenanceScore ?? -1) +
                              (a.behaviourScore ?? -1) ?? -1) -
                          ((b.protectionScore ?? -1) +
                              (b.maintenanceScore ?? -1) +
                              (b.behaviourScore ?? -1) ?? -1),
                  )
                : [...summaryScorecardContent]?.sort(
                      (a, b) =>
                          ((b.protectionScore ?? -1) +
                              (b.maintenanceScore ?? -1) +
                              (b.behaviourScore ?? -1) ?? -1) -
                          ((a.protectionScore ?? -1) +
                              (a.maintenanceScore ?? -1) +
                              (a.behaviourScore ?? -1) ?? -1),
                  )
            const resultFrameworkSummaryScore = sortedFrameworkSummaryScoreOutput?.map(
                (item) => item.locationId,
            )
            if (resultFrameworkSummaryScore.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultFrameworkSummaryScore.includes(locationId),
                )

                return resultFrameworkSummaryScore.concat(missingLocationIds)
            }
            return resultFrameworkSummaryScore
        case PossibleColumnsVesselBeta.TotalInventoryAllTypes:
        case PossibleColumnsVesselBeta.UntrustedInventoryAllTypes:
        case PossibleColumnsVesselBeta.TrustedInventoryAllTypes:
        case PossibleColumnsVesselBeta.NewInventoryAllTypes:
            const totalInventoryAllTypesContent =
                valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
            if (!totalInventoryAllTypesContent || totalInventoryAllTypesContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedTotalInventoryOutput = selectedColumn.orderAscending
                ? [...totalInventoryAllTypesContent]?.sort(
                      (a, b) =>
                          ((a.totalAssets ?? -1) +
                              (a.totalSoftware ?? -1) +
                              (a.totalUsbDevices ?? -1) ?? -1) -
                          ((b.totalAssets ?? -1) +
                              (b.totalSoftware ?? -1) +
                              (b.totalUsbDevices ?? -1) ?? -1),
                  )
                : [...totalInventoryAllTypesContent]?.sort(
                      (a, b) =>
                          ((b.totalAssets ?? -1) +
                              (b.totalSoftware ?? -1) +
                              (b.totalUsbDevices ?? -1) ?? -1) -
                          ((a.totalAssets ?? -1) +
                              (a.totalSoftware ?? -1) +
                              (a.totalUsbDevices ?? -1) ?? -1),
                  )
            const resultSortedTotalInventory = sortedTotalInventoryOutput?.map(
                (item) => item.locationId,
            )
            if (resultSortedTotalInventory.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultSortedTotalInventory.includes(locationId),
                )

                return resultSortedTotalInventory.concat(missingLocationIds)
            }
            return resultSortedTotalInventory
        case PossibleColumnsVesselBeta.FrameworkSummaryBenchmark:
            const behaviourSummaryContent =
                valuesToDisplay.selectedColumnContent as FrameworkSummaryBenchmarkResponse[]
            if (!behaviourSummaryContent || behaviourSummaryContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedBehaviourSummaryScoreOutput = selectedColumn.orderAscending
                ? [...behaviourSummaryContent]?.sort(
                      (a, b) =>
                          ((a.protectionBenchmark ?? -1) +
                              (a.maintenanceBenchmark ?? -1) +
                              (a.behaviourBenchmark ?? -1) ?? -1) -
                          ((b.protectionBenchmark ?? -1) +
                              (b.maintenanceBenchmark ?? -1) +
                              (b.behaviourBenchmark ?? -1) ?? -1),
                  )
                : [...behaviourSummaryContent]?.sort(
                      (a, b) =>
                          ((b.protectionBenchmark ?? -1) +
                              (b.maintenanceBenchmark ?? -1) +
                              (b.behaviourBenchmark ?? -1) ?? -1) -
                          ((a.protectionBenchmark ?? -1) +
                              (a.maintenanceBenchmark ?? -1) +
                              (a.behaviourBenchmark ?? -1) ?? -1),
                  )
            const resultsortedBehaviour = sortedBehaviourSummaryScoreOutput?.map(
                (item) => item.locationId,
            )
            if (resultsortedBehaviour.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedBehaviour.includes(locationId),
                )

                return resultsortedBehaviour.concat(missingLocationIds)
            }

            return resultsortedBehaviour
        case PossibleColumnsVesselBeta.OldestOpenIncident:
            const oldestIncidentContent =
                valuesToDisplay.selectedColumnContent as OldestOpenIncidentResponse[]
            if (!oldestIncidentContent || oldestIncidentContent?.length === 0) {
                return filteredLocationsIds
            }

            const sortedOldestIncidentContent = selectedColumn.orderAscending
                ? [...oldestIncidentContent].sort((a, b) => {
                      const dateA = new Date(a.ageOfOpenState).getTime()
                      const dateB = new Date(b.ageOfOpenState).getTime()

                      if (!isNaN(dateA) && !isNaN(dateB)) {
                          return dateA - dateB
                      }

                      return 0
                  })
                : [...oldestIncidentContent].sort((a, b) => {
                      const dateA = new Date(a.ageOfOpenState).getTime()
                      const dateB = new Date(b.ageOfOpenState).getTime()

                      if (!isNaN(dateA) && !isNaN(dateB)) {
                          return dateB - dateA
                      }

                      return 0
                  })

            const resultsortedOldestIncident = sortedOldestIncidentContent.map(
                (item) => item.locationId,
            )
            if (resultsortedOldestIncident.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedOldestIncident.includes(locationId),
                )

                return resultsortedOldestIncident.concat(missingLocationIds)
            }
            return resultsortedOldestIncident
        case PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork:
        case PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork:
            const missingAssetsContent =
                valuesToDisplay.selectedColumnContent as MissingInvetoryAllTypesResponse[]
            if (!missingAssetsContent || missingAssetsContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedMissingAssetsOutput = [...missingAssetsContent].sort((a, b) => {
                return selectedColumn.orderAscending
                    ? (a.totalMissingAssets ?? -1) - (b.totalMissingAssets ?? -1)
                    : (b.totalMissingAssets ?? -1) - (a.totalMissingAssets ?? -1)
            })

            const resultMissingAssets = sortedMissingAssetsOutput.map((item) => item.locationId)
            if (resultMissingAssets.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultMissingAssets.includes(locationId),
                )

                return resultMissingAssets.concat(missingLocationIds)
            }
            return resultMissingAssets
        case PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets:
            const missingMonitoredAssetsContent =
                valuesToDisplay.selectedColumnContent as MissingMonitoredAssetsResponse[]
            if (!missingMonitoredAssetsContent || missingMonitoredAssetsContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedMissingMonitoredAssetsOutput = [...missingMonitoredAssetsContent].sort(
                (a, b) => {
                    return selectedColumn.orderAscending
                        ? (a.totalMissingMonitoredAssets ?? -1) -
                              (b.totalMissingMonitoredAssets ?? -1)
                        : (b.totalMissingMonitoredAssets ?? -1) -
                              (a.totalMissingMonitoredAssets ?? -1)
                },
            )

            const resultMissingMonitoredAssets = sortedMissingMonitoredAssetsOutput.map(
                (item) => item.locationId,
            )
            if (resultMissingMonitoredAssets.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultMissingMonitoredAssets.includes(locationId),
                )

                return resultMissingMonitoredAssets.concat(missingLocationIds)
            }
            return resultMissingMonitoredAssets

        case PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod:
            const newInventoryPrevPeriodAllTypesContent =
                valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
            if (
                !newInventoryPrevPeriodAllTypesContent ||
                newInventoryPrevPeriodAllTypesContent?.length === 0
            ) {
                return filteredLocationsIds
            }
            const sortedNewInvePrePeriodOutput = [...newInventoryPrevPeriodAllTypesContent].sort(
                (a, b) => {
                    const averageA = calculateAverage(
                        a.totalAssets,
                        a.totalUsbDevices,
                        a.totalSoftware,
                    )
                    const averageB = calculateAverage(
                        b.totalAssets,
                        b.totalUsbDevices,
                        b.totalSoftware,
                    )

                    return selectedColumn.orderAscending ? averageA - averageB : averageB - averageA
                },
            )

            const resultNewInvePrePer = sortedNewInvePrePeriodOutput.map((item) => item.locationId)
            if (resultNewInvePrePer.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultNewInvePrePer.includes(locationId),
                )

                return resultNewInvePrePer.concat(missingLocationIds)
            }
            return resultNewInvePrePer
        case PossibleColumnsVesselBeta.NumberOfDaysSinceLastData:
        case PossibleColumnsVesselBeta.NumberOfDaysSinceLastNetworkData:
            const numberOfDaysSinceLastData =
                valuesToDisplay.selectedColumnContent as NumberOfDaysSinceLastDataResponse[]
            if (!numberOfDaysSinceLastData || numberOfDaysSinceLastData?.length === 0) {
                return filteredLocationsIds
            }

            const sortedNumberOfDaysSinceLastData = selectedColumn.orderAscending
                ? [...numberOfDaysSinceLastData].sort((a, b) => {
                      const dateA = new Date(a.lastSeenTimestamp).getTime()
                      const dateB = new Date(b.lastSeenTimestamp).getTime()

                      if (!isNaN(dateA) && !isNaN(dateB)) {
                          return dateA - dateB
                      }

                      return 0
                  })
                : [...numberOfDaysSinceLastData].sort((a, b) => {
                      const dateA = new Date(a.lastSeenTimestamp).getTime()
                      const dateB = new Date(b.lastSeenTimestamp).getTime()

                      if (!isNaN(dateA) && !isNaN(dateB)) {
                          return dateB - dateA
                      }

                      return 0
                  })

            const resultsortedNumberOfDaysSinceLastData = sortedNumberOfDaysSinceLastData.map(
                (item) => item.locationId,
            )
            if (resultsortedNumberOfDaysSinceLastData?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedNumberOfDaysSinceLastData.includes(locationId),
                )

                return resultsortedNumberOfDaysSinceLastData?.concat(missingLocationIds)
            }
            return resultsortedNumberOfDaysSinceLastData
        case PossibleColumnsVesselBeta.FrameworkSummaryTrend:
            const frameworkSummaryTrenContent =
                valuesToDisplay.selectedColumnContent as FrameworkSummaryTrendResponse[]
            if (!frameworkSummaryTrenContent || frameworkSummaryTrenContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedframeworkSummaryTrendOutput = selectedColumn.orderAscending
                ? [...frameworkSummaryTrenContent]?.sort(
                      (a, b) =>
                          ((a.protectionTrend ?? -1) +
                              (a.maintenanceTrend ?? -1) +
                              (a.behaviourTrend ?? -1) ?? -1) -
                          ((b.protectionTrend ?? -1) +
                              (b.maintenanceTrend ?? -1) +
                              (b.behaviourTrend ?? -1) ?? -1),
                  )
                : [...frameworkSummaryTrenContent]?.sort(
                      (a, b) =>
                          ((b.protectionTrend ?? -1) +
                              (b.maintenanceTrend ?? -1) +
                              (b.behaviourTrend ?? -1) ?? -1) -
                          ((a.protectionTrend ?? -1) +
                              (a.maintenanceTrend ?? -1) +
                              (a.behaviourTrend ?? -1) ?? -1),
                  )
            const resultsortedframeworkSummaryTrendOutput = sortedframeworkSummaryTrendOutput?.map(
                (item) => item.locationId,
            )
            if (resultsortedframeworkSummaryTrendOutput?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedframeworkSummaryTrendOutput.includes(locationId),
                )

                return resultsortedframeworkSummaryTrendOutput?.concat(missingLocationIds)
            }
            return resultsortedframeworkSummaryTrendOutput
        case PossibleColumnsVesselBeta.FrameworkSummaryTarget:
            const frameworkSummaryTargetContent =
                valuesToDisplay.selectedColumnContent as FrameworkSummaryTargetResponse[]
            if (!frameworkSummaryTargetContent || frameworkSummaryTargetContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedframeworkSummaryTargetOutput = selectedColumn.orderAscending
                ? [...frameworkSummaryTargetContent]?.sort(
                      (a, b) =>
                          ((a.protectionTargetPerformance ?? -1) +
                              (a.maintenanceTargetPerformance ?? -1) +
                              (a.behaviourTargetPerformance ?? -1) ?? -1) -
                          ((b.protectionTargetPerformance ?? -1) +
                              (b.maintenanceTargetPerformance ?? -1) +
                              (b.behaviourTargetPerformance ?? -1) ?? -1),
                  )
                : [...frameworkSummaryTargetContent]?.sort(
                      (a, b) =>
                          ((b.protectionTargetPerformance ?? -1) +
                              (b.maintenanceTargetPerformance ?? -1) +
                              (b.behaviourTargetPerformance ?? -1) ?? -1) -
                          ((a.protectionTargetPerformance ?? -1) +
                              (a.maintenanceTargetPerformance ?? -1) +
                              (a.behaviourTargetPerformance ?? -1) ?? -1),
                  )
            const resultsortedframeworkSummaryTargetOutput =
                sortedframeworkSummaryTargetOutput?.map((item) => item.locationId)
            if (resultsortedframeworkSummaryTargetOutput?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedframeworkSummaryTargetOutput.includes(locationId),
                )

                return resultsortedframeworkSummaryTargetOutput?.concat(missingLocationIds)
            }
            return resultsortedframeworkSummaryTargetOutput
        case PossibleColumnsVesselBeta.NumberOfIncidentsRaised:
        case PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod:
        case PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents:
        case PossibleColumnsVesselBeta.NumberOfIncidentsOpen:
        case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents:
            const incidentsNumberContent =
                valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
            if (!incidentsNumberContent || incidentsNumberContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedIncidentsNumberOutput = selectedColumn.orderAscending
                ? [...incidentsNumberContent]?.sort((a, b) => a?.incidentsCount - b?.incidentsCount)
                : [...incidentsNumberContent]?.sort((a, b) => b?.incidentsCount - a?.incidentsCount)

            const resultsortedIncidentsOutput = sortedIncidentsNumberOutput?.map(
                (item) => item.locationId,
            )
            if (resultsortedIncidentsOutput?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedIncidentsOutput.includes(locationId),
                )

                return resultsortedIncidentsOutput?.concat(missingLocationIds)
            }
            return resultsortedIncidentsOutput
        case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidentsAverageTime:
            const averageResolveTimeContent =
                valuesToDisplay.selectedColumnContent as AverageTimeToResolveIncidentsResponse[]
            if (!averageResolveTimeContent || averageResolveTimeContent?.length === 0) {
                return filteredLocationsIds
            }
            const sortedAverageTimeOutput = [...averageResolveTimeContent]?.sort((a, b) => {
                const timeA: moment.Duration = moment.duration(a.averageResolveTime)
                const timeB: moment.Duration = moment.duration(b.averageResolveTime)

                return selectedColumn.orderAscending ? +timeB - +timeA : +timeA - +timeB
            })
            const resultsortedAverageTimeOutput = sortedAverageTimeOutput?.map(
                (item) => item.locationId,
            )
            if (resultsortedAverageTimeOutput?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedAverageTimeOutput.includes(locationId),
                )

                return resultsortedAverageTimeOutput?.concat(missingLocationIds)
            }
            return resultsortedAverageTimeOutput
        case PossibleColumnsVesselBeta.AssetsAtRisk:
        case PossibleColumnsVesselBeta.AssetsAtRiskHighValue:
            const nodeListForAssetsAtRisk = valuesToDisplay.selectedColumnContent as Map<
                LocationIdType,
                AssetThreatScoreOutputModel[]
            >
            const averageScoreByLocation: {locationId: LocationIdType; averageScore: number}[] = []

            nodeListForAssetsAtRisk?.forEach((scores, locationId) => {
                const totalScore = scores?.reduce((sum, score) => sum + score?.score, 0)
                const averageScore = isNaN(totalScore / scores?.length)
                    ? 0
                    : totalScore / scores?.length
                averageScoreByLocation?.push({locationId, averageScore})
            })
            const sortedAssetsAtRiskScoreOutput = selectedColumn.orderAscending
                ? [...averageScoreByLocation]?.sort(
                      (a, b) => (a.averageScore ?? -1) - (b.averageScore ?? -1),
                  )
                : [...averageScoreByLocation]?.sort(
                      (a, b) => (b.averageScore ?? -1) - (a.averageScore ?? -1),
                  )
            const resultAssetsAtRiskScore = sortedAssetsAtRiskScoreOutput
                ?.filter((element) => !(element.averageScore === 0))
                .map((item) => item.locationId)
            if (resultAssetsAtRiskScore.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultAssetsAtRiskScore.includes(locationId),
                )

                return resultAssetsAtRiskScore.concat(missingLocationIds)
            }
            return resultAssetsAtRiskScore

        case PossibleColumnsVesselBeta.DeploymentSummary:
            const deploymentSummaryContent =
                valuesToDisplay.selectedColumnContent as VesselDeploymentStatus

            const orderAscending = selectedColumn.orderAscending
            const sortedDeploymentOutput = buildSortedDeploymentOutput(
                deploymentSummaryContent,
                filteredLocationsIds,
                orderAscending,
            )
            const resultsortedDeploymentOutput = sortedDeploymentOutput?.map(
                (item) => item.locationId,
            )
            if (resultsortedDeploymentOutput?.length !== filteredLocationsIds.length) {
                const missingLocationIds = filteredLocationsIds.filter(
                    (locationId) => !resultsortedDeploymentOutput.includes(locationId),
                )

                return resultsortedDeploymentOutput?.concat(missingLocationIds)
            }
            return resultsortedDeploymentOutput
        default:
            return filteredLocationsIds
    }
}
function calculateAverage(
    a: number | undefined,
    b: number | undefined,
    c: number | undefined,
): number {
    const validValues = [a, b, c].filter((value) => value !== undefined) as number[]
    if (validValues.length === 0) {
        return 0
    }

    const sum = validValues.reduce((acc, val) => acc + val, 0)
    return sum / validValues.length
}
type LocationScore = {
    locationId: LocationIdType
    score: number
}

export const calculateScoreOfIssues = (
    locationId: LocationIdType,
    deploymentSummaryContent: VesselDeploymentStatus,
): number => {
    let scoreOfIssues = 0
    const vesselOperationalStatus =
        deploymentSummaryContent?.operationalStatus?.[locationId] ?? null

    if (!vesselOperationalStatus) {
        scoreOfIssues += 1
    }

    const hasIncus = vesselOperationalStatus?.operating
    if (hasIncus) {
        scoreOfIssues += 1
    } else {
        scoreOfIssues -= 2
    }

    const agentsNeedUpdating = vesselOperationalStatus?.upgradeAssets
    if (!agentsNeedUpdating || agentsNeedUpdating.length === 0) {
        scoreOfIssues += 1
    } else {
        scoreOfIssues -= 1
    }

    const traffic = deploymentSummaryContent?.trafficStatus?.[locationId]
    if (!traffic) {
        scoreOfIssues += 1
    } else {
        scoreOfIssues -= 2
    }

    const hasDashboard = deploymentSummaryContent?.vesselDashboard?.[locationId]
    if (hasDashboard) {
        scoreOfIssues += 1
    } else {
        scoreOfIssues -= 1
    }

    return scoreOfIssues
}
const buildSortedDeploymentOutput = (
    deploymentSummaryContent: VesselDeploymentStatus,
    filteredLocationsIds: LocationIdType[],
    orderAscending: boolean,
): LocationScore[] => {
    const sortedDeploymentOutput = filteredLocationsIds.map((locationId) => ({
        locationId,
        score: calculateScoreOfIssues(locationId, deploymentSummaryContent),
    }))

    return orderAscending
        ? sortedDeploymentOutput.sort((a, b) => a.score - b.score)
        : sortedDeploymentOutput.sort((a, b) => b.score - a.score)
}
