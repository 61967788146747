import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardArea} from '../_styled/software-inventory-cards.styled'
import {NoDataCard} from './no-data-card'

interface NoDataProps {
    text: string
}
export function NoData({text}: NoDataProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <CardArea width={width}>
            <NoDataCard text={text} />
        </CardArea>
    )
}
