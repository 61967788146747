import styled from 'styled-components'

interface RiskScoreTickProps {
    score: number
}
export const RiskScoreTick = styled.div<RiskScoreTickProps>`
    border-left: 2px solid ${(props) => props.theme.colors.background.default};
    border-right: 2px solid ${(props) => props.theme.colors.background.default};
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    left: ${(props) => `${props.score}%`};
    margin-left: -3px;
    height: 10px;
    width: 6px;
    background-color: ${(props) => props.theme.colors.text.default};
`
