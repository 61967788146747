import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {WidgetWrapper} from './widget-wrapper'
import {LoadingSpinner} from '../../../../../components/loading/loading'
import {LoadingSpinnerWrapper} from '../../general/content-area/Loading/loading-spinner-wrapper.styled'
import {Level} from '../../../../../values/location-level-enum'

export function Loading(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)

    return (
        <WidgetWrapper level={Level.UNKNOWN} gridLayout={gridLayout} noData={true}>
            <LoadingSpinnerWrapper>
                <LoadingSpinner />
            </LoadingSpinnerWrapper>
        </WidgetWrapper>
    )
}
