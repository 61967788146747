import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {getGridTemplateColumns} from './table.styled'

export const TableHeaderGrid = styled.div`
    display: grid;
    grid-template-columns: ${getGridTemplateColumns()};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    margin-bottom: -${spacing(1)};
    background-color: ${(props) => props.theme.vessels.dataArea.background};
`

interface TableHeaderCellProps {
    gridColumn: number
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
