import {MouseEvent} from 'react'
import {usePagedIndicators} from '../../../../contexts/indicators/use-paged-indicators'
import {Card} from '../../common/cards/card.styled'
import {ErrorRow} from '../../common/cards/error-row.styled'
import {ErrorMessage} from '../../common/cards/error-message.styled'
import {RetryButton} from '../../common/retry-button.styled'

export function RequestErrorCard(): JSX.Element {
    const {refreshData} = usePagedIndicators()

    function onClickHandler(e: MouseEvent) {
        refreshData()
        e.preventDefault()
    }

    return (
        <Card>
            <ErrorRow>
                <ErrorMessage id="indicators_error_message">
                    Sorry, an error occured whilst requesting indicator data
                </ErrorMessage>
                <RetryButton id="indicators_error_retry" onClick={onClickHandler}>
                    Retry
                </RetryButton>
            </ErrorRow>
        </Card>
    )
}
