import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
`
export const Value = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const inputNonText = styled.input`
    width: 100%;
    height: 27px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`
export const Input = styled.textarea`
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    border-radius: 3px;
`

export const WarningMessage = styled.div`
    color: ${(props) => props.theme.colors.text.default};
    background-color: #fcd5db;
    ${smallFont()};
`
export const FixedTextValue = styled.div`
    width: 100%;
    height: 27px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    border: 1px solid;
    cursor: pointer;
`
