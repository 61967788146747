import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {useUserManagement} from '../context/use-user-management'
import * as Styled from './_styled/add-new-user-area.styled'

export function AddNewUserArea(): JSX.Element {
    const {width} = useDimensions()
    const {setDisplayAddNewUserModal, closeUserDetailModal} = useUserManagement()
    return (
        <Styled.ButtonWrapper width={width}>
            <Styled.Button
                onClick={() => {
                    setDisplayAddNewUserModal(true)
                    closeUserDetailModal()
                }}
            >
                + Add User
            </Styled.Button>
        </Styled.ButtonWrapper>
    )
}
