import {SaveMessage} from '../../../contexts/types/save-note-result'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {IncidentDetails} from './incident-details'
import {LoadingDataModal} from './loading-data-modal'
import {NoDataModal} from './no-data-modal'
import {ContentWrapper} from './_styled/content-wrapper.styled'

export function IncidentDetailsModal(): JSX.Element {
    const {isProcessingModal, saveResultMessage} = usePagedIncidents()

    if (isProcessingModal) {
        return <LoadingDataModal />
    }
    if (saveResultMessage?.message === SaveMessage.FAILED) {
        return <NoDataModal />
    }
    return (
        <ContentWrapper id="incident-details-modal-wrapper">
            <IncidentDetails />
        </ContentWrapper>
    )
}
