import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
    active?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
    ${(props) => props.gridColumn === 9 && 'align-items:center;'};
    color: ${(props) =>
        props.active ? props.theme.colors.text.warning : props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
    padding-top: ${spacing(1)};
`
interface TableHeaderContentProps {
    active?: boolean
}
export const TableHeaderContent = styled.div<TableHeaderContentProps>`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: flex-start;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
    position: relative;
`

export const InputFieldWrapper = styled.div`
    display: flex;
    position: relative;
`
interface InputProps {
    showReducedGrid: boolean
}

export const Input = styled.input<InputProps>`
    max-width: ${(props) => (props.showReducedGrid ? '80px' : '100px')};
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    margin: 0 0 1px 1px;
`
export const SearchIconStyle = styled.div`
    position: absolute;
    align-items: center;
    right: ${spacing(1)};
    color: ${(props) => props.theme.colors.text.deemphasize};
`
interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transition:
        transform 0.2s,
        color 0.2s;
    transition-timing-function: ease-in-out;
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`
export const RecommendationContainer = styled.div`
    display: flex;
    width: 232px;
    height: 60px;
    position: absolute;
    right: 0;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(2)};
    background-color: rgba(194, 226, 255, 1);
    overflow: visible;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-align: center;
    padding: 2px;
`
