import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

interface ZoomLevelProps {
    inFilterBar?: boolean
}

export const Options = styled.ul`
    list-style-type: none;
    white-space: nowrap;
    padding: 0;
`
export const ZoomLevel = styled.div<ZoomLevelProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & ${Options} {
        margin: 3px 0;
    }
`

export const Label = styled.label`
    flex-grow: 1;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    ${mediumSmallFont()};
`

interface OptionProps {
    selected: boolean
}

export const Option = styled.li<OptionProps>`
    display: inline-block;
    margin: 0;
    padding: 0 3px;
    ${(props) => (props.selected ? '' : 'opacity: 0.4;')}
`

export const ToggleButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    column-gap: ${spacing(1)};
`

export const TitleLabel = styled.div`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const TitleOption = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 160px;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 3px;
`
