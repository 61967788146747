import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {DEFAULT_PAGED_METRICS_BETA_STATE} from './types/default-paged-metrics-state'
import {pagedMetricsReducer} from './state/reducer'
import {PagedMetricsBetaState} from './types/paged-metrics-state'
import {PagedMetricsBetaContext} from './paged-metrics-beta-context'

type PagedMetricsBetaProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedMetricsBetaProvider({
    children,
    pageSize,
}: PagedMetricsBetaProviderProps): JSX.Element {
    const initialState: PagedMetricsBetaState = cloneDeep(DEFAULT_PAGED_METRICS_BETA_STATE)
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedMetricsReducer, initialState)

    return (
        <PagedMetricsBetaContext.Provider value={{state, dispatch}}>
            {children}
        </PagedMetricsBetaContext.Provider>
    )
}
