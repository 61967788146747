import {CloseButton} from '../submit-area/close-button'
import * as Styled from './data-area.styled'

export function ChangeNotSaved(): JSX.Element {
    return (
        <>
            <Styled.Text>Save failed</Styled.Text>
            <Styled.Actions>
                <CloseButton />
            </Styled.Actions>
        </>
    )
}
