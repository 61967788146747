import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Cell = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const VesselDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: ${spacing(1)};
    box-shadow: 0 1px 2px ${(props) => props.theme.myVessels.cards.boxShadow};
    border: 1px solid ${(props) => props.theme.incidents.dataRow.statusBar.draft};
`

export const Details = styled.div`
    display: flex;
    padding: ${spacing(2)};
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    min-width: 0;
`

export const DetailRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${spacing(1)};
`

const DetailCell = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const TitleCell = styled(DetailCell)`
    font-size: 16px;
    line-height: 24px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    word-break: break-word;
`

export const RightHandCell = styled(DetailCell)`
    display: flex;
    align-items: center;
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
`

export const HostsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(1)};
`

export const HostnameDetails = styled(Details)`
    ${smallFont()};
    background-color: #f5f5f5;
`

export const AssetTimeStampValue = styled(DetailCell)`
    padding-left: ${spacing(1)};
`

export const DataCellValue = styled.div`
    font-size: 13px;
    line-height: 17px;
    font-weight: bold;
    padding: ${spacing(1)} 0;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const SubTitleCell = styled(DetailCell)`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
`
