import {useState, useEffect, useRef} from 'react'
import * as Style from '../_styled/multiselect-checkbox.styled'
import {ChevronDown} from 'react-feather'
import {DropdownOption} from './multiselect-dropdown'
import {Checkbox} from '../../../../../../../components/form-elements/checkbox'

type MultiselectCheckboxProps = {
    options: DropdownOption[]
    selectedOptions: DropdownOption[]
    onChange: (option: DropdownOption) => void
    placeholder: string
    required?: boolean
    disabled?: boolean
}

// referencing multiselect on ot-inventory
export function MultiselectCheckbox(props: MultiselectCheckboxProps): JSX.Element {
    const [showOptions, setShowOptions] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')

    const boxElem = useRef<HTMLDivElement>(null)
    const selectedElem = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        const close = (event: MouseEvent) => {
            if (!boxElem.current) {
                return
            }

            if (!boxElem.current.contains(event.target as Node)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', close, true)

        return () => {
            window.removeEventListener('click', close)
        }
    }, [])

    useEffect(() => {
        if (showOptions) {
            return
        }
    }, [props.selectedOptions, showOptions])

    // Escape regex special characters and create a regex for search filter
    const filterRegex = new RegExp(searchFilter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value)
    }

    const handleOptionClick = (event: React.MouseEvent) => {
        event.preventDefault()
        setShowOptions(!showOptions)
    }

    let renderOptions = props.options
        .filter((option) => filterRegex.test(option.label))
        .map((option) => (
            <Checkbox
                id={'physical-interfaces-' + option.value}
                checked={props.selectedOptions.some((x) => x.label === option.label)}
                onChange={() => props.onChange(option)}
                label={option.label}
                key={option.value}
            />
        ))

    if (renderOptions.length === 0) {
        renderOptions = [<Style.Option key="no-option">No options</Style.Option>]
    }

    let selectedOptions: JSX.Element
    if (props.selectedOptions.length > 0) {
        selectedOptions = (
            <Style.Panel>
                {props.selectedOptions.map((x) => (
                    <Style.Label key={x.value}>{x.label}</Style.Label>
                ))}
            </Style.Panel>
        )
    } else {
        selectedOptions = <Style.Placeholder>{props.placeholder}</Style.Placeholder>
    }

    return (
        <Style.Box ref={boxElem}>
            {props.disabled ? (
                <Style.DisabledPanel>No assets found</Style.DisabledPanel>
            ) : (
                <Style.Input ref={selectedElem} onClick={handleOptionClick} href="#">
                    {selectedOptions}
                    <ChevronDown />
                </Style.Input>
            )}
            {showOptions && (
                <Style.CheckboxContainer>
                    <Style.SearchInput
                        placeholder="Search"
                        value={searchFilter}
                        onChange={handleSearchChange}
                    />
                    <Style.OptionContainer>{renderOptions}</Style.OptionContainer>
                </Style.CheckboxContainer>
            )}
        </Style.Box>
    )
}
