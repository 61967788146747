import styled from 'styled-components'

interface TableValueProps {
    first?: boolean
    last?: boolean
}

export const TableValue = styled.td<TableValueProps>`
    padding-top: ${(props) => (props.first ? 0 : '7.5px')};
    padding-bottom: ${(props) => (props.last ? 0 : '7.5px')};
`
