import * as Styled from './data-input.styled'

interface DataInputProps {
    label: string
    value: string | undefined
}

export function DataReadOnly({label, value}: DataInputProps): JSX.Element {
    return (
        <>
            <Styled.Label id={label}>{label}:</Styled.Label>
            <Styled.ReadOnlyValue id={`${label}-value`}>{value}</Styled.ReadOnlyValue>
        </>
    )
}
