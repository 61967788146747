import {ContentWrapper} from './_styled/software-details.styled'
import {SoftwareDetails} from './software-details'

export function SoftwareDetailsModal(): JSX.Element {
    return (
        <ContentWrapper id="software-details-modal_wrapper">
            <SoftwareDetails />
        </ContentWrapper>
    )
}
