import React, {useState, useCallback, createContext, useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {StyledToast, ToastMessage, CloseButton, ToastContainer} from './toast.styled'
import {ToastType, ToastProps, ToastContextType, ToastPosition, ToastManagerProps} from './types'

const DEFAULT_DELAY = 3000 // 3 seconds
const DEFAULT_POSITION: ToastPosition = 'bottom-right'

const Toast: React.FC<ToastProps> = ({id, message, type, onClose}) => {
    return (
        <StyledToast type={type}>
            <ToastMessage>{message}</ToastMessage>
            <CloseButton onClick={() => onClose(id)}>×</CloseButton>
        </StyledToast>
    )
}

const ToastContext = createContext<ToastContextType | null>(null)

interface ToastPortalProps {
    children: React.ReactNode
}

const ToastPortal: React.FC<ToastPortalProps> = ({children}) => {
    const [portalNode, setPortalNode] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        const node = document.createElement('div')
        document.body.appendChild(node)
        setPortalNode(node)

        return () => {
            document.body.removeChild(node)
        }
    }, [])

    return portalNode ? ReactDOM.createPortal(children, portalNode) : null
}

export const ToastManager: React.FC<ToastManagerProps> = ({children}) => {
    const [toasts, setToasts] = useState<ToastProps[]>([])

    const addToast = useCallback(
        (
            message: string,
            options: {
                type?: ToastType
                delay?: number
                position?: ToastPosition
            } = {},
        ) => {
            const {type = 'info', delay = DEFAULT_DELAY, position = DEFAULT_POSITION} = options
            const id = Date.now()
            setToasts((prevToasts) => [
                ...prevToasts,
                {id, message, type, position, onClose: removeToast},
            ])
            setTimeout(() => removeToast(id), delay)
        },
        [],
    )

    const removeToast = useCallback((id: number) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
    }, [])

    // Organize toasts by their position
    // This creates an object where each key is a toast position (e.g., 'top-left', 'bottom-right')
    // and the corresponding value is an array of toasts that should appear in that position.
    // This makes it easy to render toast containers for each position with the correct toasts.
    const toastsByPosition = toasts.reduce(
        (acc, toast) => {
            if (!acc[toast.position]) {
                acc[toast.position] = []
            }
            acc[toast.position].push(toast)
            return acc
        },
        {} as Record<ToastPosition, ToastProps[]>,
    )

    return (
        <ToastContext.Provider value={{addToast}}>
            {children}
            <ToastPortal>
                {Object.entries(toastsByPosition).map(([position, positionToasts]) => (
                    <ToastContainer key={position} position={position as ToastPosition}>
                        {positionToasts.map((toast) => (
                            <Toast key={toast.id} {...toast} />
                        ))}
                    </ToastContainer>
                ))}
            </ToastPortal>
        </ToastContext.Provider>
    )
}

export const useToast = (): ToastContextType['addToast'] => {
    const context = useContext(ToastContext)
    if (!context) {
        throw new Error('useToast must be used within a ToastManager')
    }
    return context.addToast
}
