import useTypedSelector from '../../../../hooks/use-typed-selector'
import {dataSelector} from '../../../../store/state/engine-status/selectors'
import {NoData} from '../shared/no-data/no-data'
import {Sections} from './sections/sections'

interface EngineStatusesLoadedProps {
    environment: string
}

export function EngineStatusesLoaded({environment}: EngineStatusesLoadedProps): JSX.Element {
    const engineStatuses = useTypedSelector(dataSelector)

    return engineStatuses?.length === 0 ? (
        <NoData message={`There are no engines for ${environment}`} />
    ) : (
        <Sections engineStatuses={engineStatuses} />
    )
}
