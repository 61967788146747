import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
interface ReducedTextProps {
    fixedWidth: boolean
}

export const ReducedText = styled.div<ReducedTextProps>`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    ${(props) => (props.fixedWidth ? 'width: 50px;' : '')}
`
