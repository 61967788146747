import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;

    width: 110px;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
export const BottomContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    margin: 0;
    padding: ${spacing(2)};
    z-index: 1;
`
