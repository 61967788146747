import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {networkAssetsFilterSelector} from '../../../../../store/state/network-assets-filter/selectors'
import {ExpandDetailsButton} from '../shared/expand-details-button/expand-details-button'
import * as Styles from './properties-filter.styled'
import {AppliedPropertiesFilterIndicator} from '../shared/applied-filter-indicator/applied-properties-filter-indicator'
import {HeadingRowType} from '../../../../../store/state/network-assets-filter/state'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {BehavioursSearch} from './behaviours-search/behaviours-search'
import {AdditionalIdentifierFilter} from './additional-identifier/additional-identifier'
import {PeriodFilter} from './period-filter/period-filter'

export function PropertiesFilter(): JSX.Element {
    const {filterExpanded, properties} = useTypedSelector(networkAssetsFilterSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.propertiesExpanded && properties && properties.length > 0

    return (
        <Styles.Properties>
            <ExpandDetailsButton headingRow={HeadingRowType.FilterBarProperties} />
            {showFilteringIndicator && (
                <AppliedPropertiesFilterIndicator searchedProperties={properties} />
            )}
            {filterExpanded && filterExpanded.propertiesExpanded && (
                <Styles.Container isInactive={isInactive}>
                    <Styles.FilterWrapper>
                        <Styles.Label>Last seen</Styles.Label>
                        <PeriodFilter />
                    </Styles.FilterWrapper>
                    <Styles.FilterWrapper>
                        <Styles.Label>Additional identifier</Styles.Label>
                        <AdditionalIdentifierFilter />
                    </Styles.FilterWrapper>
                    <Styles.FilterWrapper>
                        <Styles.Label>Behaviours</Styles.Label>
                        <BehavioursSearch />
                    </Styles.FilterWrapper>
                </Styles.Container>
            )}
        </Styles.Properties>
    )
}
