import {LocationIdType} from '../../../../../../../store/state/locations/state'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {assetListCachedReselector} from './re-reselectors/asset-list-cached-selector'
import {AssetDetailList} from '../../../../general/asset-details/asset-detail-list'

interface AssetsListForVesselsAtRiskProps {
    locationId: LocationIdType
}

export function AssetsListForVesselsAtRisk({
    locationId,
}: AssetsListForVesselsAtRiskProps): JSX.Element {
    const nodeIds = useTypedSelector((state) => assetListCachedReselector(state, locationId))
    return <AssetDetailList nodeIds={nodeIds} />
}
