import {useDispatch} from 'react-redux'
import * as Styled from './radio-button.styled'
import {setRequirements} from '../../../../store/state/extended-data-request/action-creators'

interface Props {
    label: string
    value: string[]
}

export function RequirementsRadioButton({label, value}: Props): JSX.Element {
    const dispatch = useDispatch()
    const isChecked = value?.length > 0 ? value.includes(label) : false

    return (
        <Styled.RadioButtonWrapper>
            <Styled.Container
                disabled={false}
                onClick={() => {
                    dispatch(setRequirements(label, !isChecked))
                }}
                id="contact-soc-RequirementsRadioButton"
            >
                <Styled.Input
                    type="radio"
                    id={`${label}-radio`}
                    value={label}
                    checked={isChecked}
                />

                <Styled.Label htmlFor={`-radio`} id={`${label}-Label`}>
                    {label}
                </Styled.Label>
            </Styled.Container>
        </Styled.RadioButtonWrapper>
    )
}
