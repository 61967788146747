import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellCustomerLocationCode(): JSX.Element {
    const {data, initialData, displayData, changeCustomerLocationCode} = useVesselConfig()
    const changed = data.customerLocationCode !== initialData.customerLocationCode
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeCustomerLocationCode(e.target.value)
    }
    return (
        <DataInput
            label="Customer Location Code"
            initialValue={displayData.customerLocationCode}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
