import * as Styled from './_styled/no-data.styled'
import ERROR_IMG from '../../../../../@assets/images/owl-error.png'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'

export function NoDataModal(): JSX.Element {
    const {closeNmeaDetailsModal} = usePagedNmeaInventory()

    return (
        <Styled.NoDataContainer>
            <Styled.NoDataContent>
                <Styled.NoDataBox>
                    <img src={ERROR_IMG} />
                    <Styled.NoDataMessage id="no-data-component">
                        Sorry, the link you are trying to access is not valid.
                    </Styled.NoDataMessage>
                </Styled.NoDataBox>
            </Styled.NoDataContent>
            <Styled.BottomContentWrapper>
                <Styled.Button id="close-button" onClick={() => closeNmeaDetailsModal()}>
                    <Styled.ButtonText>Close</Styled.ButtonText>
                </Styled.Button>
            </Styled.BottomContentWrapper>
        </Styled.NoDataContainer>
    )
}
