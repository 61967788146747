import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {smallFont} from '../../../../../../../theme/font-styles'

interface EditableAreaProps {
    disabled?: boolean
}

export const DetailsLayout = styled.div<EditableAreaProps>`
    padding: 0 ${spacing(3)};
    padding-bottom: 0;
    margin: 0;
    width: 100%;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`

export const Details = styled.div`
    padding: ${spacing(1)} 0;
`

export const TypeWrapper = styled.div`
    padding: ${spacing(1)};
`

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    padding-top: 3px;
    margin: 0;
`

export const EditableArea = styled.div<EditableAreaProps>`
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`
