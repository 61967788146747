import {useEffect, useRef} from 'react'
import {BoundsContext} from '../bounds-context/bounds-context'
import * as CommonStyles from './components/_styled/common.styled'
import {SectionHeader} from './components/section-header/section-header'
import {PageType} from './data-helper'
import {VesselFilter} from './components/vessel-filter/vessel-filter'
import useTypedSelector from '../../hooks/use-typed-selector'
import {myVesselsFiltersSelector} from '../../store/state/my-vessels-filters/selectors'
import {vesselsFilterLoadingStateSelector} from '../../store/state/vessel-filter/selectors'
import {setVesselFilterPref} from '../../store/state/vessel-filter/action-creators'
import {useDispatch} from 'react-redux'
import {incidentsFilterSelector} from '../../store/state/incidents-filter/selectors'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../store/state/vessel-filter/types/default-vessel-filter-state'
import {reportsFilterSelector} from '../../store/state/reports-filter/selectors'
import {networkAssetsFilterSelector} from '../../store/state/network-assets-filter/selectors'
import {usbInventoryFilterSelector} from '../../store/state/usb-inventory-filter/selectors'
import {softwareInventoryFilterSelector} from '../../store/state/software-inventory-filter/selectors'
import {AllFilterStateType} from '../../store/state/vessel-filter/types/all-filter-state-type'
import LoadingState from '../../values/loading-state-enum'
import {isInactiveSelector} from '../../store/state/saved-filters/selectors'
import {metricsBetaFilterSelector} from '../../store/state/metrics-filter-beta/selectors'
import {vesselsBetaFilterSelector} from '../../store/state/vessels-beta-filter/selectors'
import {vesselManagementFilterSelector} from '../../store/state/vessel-management-filter/selectors'
import {incidentsReportFilterSelector} from '../../store/state/incidents-report-filter/selectors'

interface VesselFiltersProp {
    pageType: PageType
    highlightedFilerValueForMetrics?: boolean
}

export function VesselFilters({
    pageType,
    highlightedFilerValueForMetrics = false,
}: VesselFiltersProp): JSX.Element {
    const layoutRef = useRef(null)
    const dispatch = useDispatch()
    const myVesselsFilter = useTypedSelector(myVesselsFiltersSelector)
    const incidentsFilter = useTypedSelector(incidentsFilterSelector)
    const reportsFilter = useTypedSelector(reportsFilterSelector)
    const networkAssetFilter = useTypedSelector(networkAssetsFilterSelector)
    const usbInventoryFilter = useTypedSelector(usbInventoryFilterSelector)
    const softwareInventoryFilter = useTypedSelector(softwareInventoryFilterSelector)
    const metricsBetaFilter = useTypedSelector(metricsBetaFilterSelector)
    const vesselsBetaFilter = useTypedSelector(vesselsBetaFilterSelector)
    const vesselsManagementFilter = useTypedSelector(vesselManagementFilterSelector)
    const incidentsReportFilter = useTypedSelector(incidentsReportFilterSelector)
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    function getAllFilter(pageType: PageType): AllFilterStateType {
        switch (pageType) {
            case PageType.MY_VESSELS:
                return myVesselsFilter
            case PageType.INCIDENTS:
                return incidentsFilter
            case PageType.REPORTS:
                return reportsFilter
            case PageType.NETWORK_ASSETS:
                return networkAssetFilter
            case PageType.USB_DEVICES:
                return usbInventoryFilter
            case PageType.SOFTWARE_INVENTORY:
                return softwareInventoryFilter
            case PageType.METRICS:
                return metricsBetaFilter
            case PageType.VESSELS_BETA:
                return vesselsBetaFilter
            case PageType.VESSEL_MANAGEMENT:
                return vesselsManagementFilter
            case PageType.INCIDENTS_REPORT:
                return incidentsReportFilter
            default:
                return myVesselsFilter
        }
    }

    const allFilter = getAllFilter(pageType)

    useEffect(() => {
        if (
            allFilter.loadingState === LoadingState.Loaded &&
            vesselFilterLoading === LoadingState.NotPopulated
        ) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: allFilter.locations,
                        searchVesselTagTerm: allFilter.searchVesselTagTerm ?? [],
                        searchVesselNameTerm: allFilter.searchVesselNameTerm ?? '',
                        vesselSort: allFilter.vesselSort,
                    },
                    pageType,
                ),
            )
        }
    }, [allFilter, dispatch, pageType, vesselFilterLoading])

    return (
        <BoundsContext.Provider value={{ref: layoutRef}}>
            <CommonStyles.Section isInactive={isInactive} id="vessels-filter-section">
                <SectionHeader
                    sectionTitle="Vessel Filters"
                    groupName="vessel-sort"
                    pageType={pageType}
                />
                <VesselFilter
                    highlightedFilerValue={
                        (pageType === PageType.METRICS || pageType === PageType.INCIDENTS_REPORT) &&
                        highlightedFilerValueForMetrics
                    }
                />
            </CommonStyles.Section>
        </BoundsContext.Provider>
    )
}
