import {User} from '../../../../store/state/users/state'
import LoadingState from '../../../../values/loading-state-enum'
import {ProcessingStatus, UserManagementState} from './user-management-state'

const emptyUserDetail = {
    user: '',
    username: '',
    email: '',
    emailNotificationEnabled: false,
    active: false,
    deleted: false,
    roles: [],
    internalUser: false,
    assignedTag: undefined,
    strictMode: undefined,
    notificationEmail: undefined,
} as User

export const DEFAULT_USER_MANAGEMENT_STATE: UserManagementState = {
    loadingDataState: LoadingState.NotPopulated,
    activeUserId: null,
    initialUserDetails: emptyUserDetail,
    userDetails: emptyUserDetail,
    displayUserDetails: emptyUserDetail,
    subscriptionStatus: {
        initialSubscriptionStatus: false,
        newStatus: false,
        userId: '',
        emailId: '',
    },
    isSubscriptionChanged: false,
    isChanged: false,
    isRoleChanged: false,
    saveResultMessage: undefined,
    disableUserResultMessage: undefined,
    displayAddNewUserModal: false,
    isSaveProcessing: ProcessingStatus.NOT_PROCESSING,
    newUserData: {
        username: '',
        email: '',
        external: true,
        roles: [],
        subscription: false,
    },
    viewAsExternal: true,
    detailExpanded: {
        detailsExpanded: true,
        rolesExpanded: true,
        subscriptionsExpanded: true,
        savedFiltersAlertsExpanded: false,
        historyExpanded: false,
    },
    currentSelectedAlertId: undefined,
}
