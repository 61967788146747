import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const Container = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: ${spacing(2)};
`

export const SearchIcon = styled(Icon)`
    right: ${spacing(1)};
    top: ${spacing(1)};
    position: absolute;
    color: ${(props) => props.theme.myVessels.filterBar.search.text};
`

export const SearchInput = styled.input.attrs({type: 'text'})`
    width: 100%;
    color: ${(props) => props.theme.myVessels.filterBar.search.text};
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.search.border};
    padding: ${spacing(1)} ${spacing(6)} ${spacing(1)} ${spacing(2)};
    font-size: 12px;
    line-height: 17px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
