import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'
import * as Styles from './save-button.styled'

export function AddNewButton(): JSX.Element {
    const {addNewIncidentPolicyDetails, isSavAbleNewIncident, isValidIncidentReminder} =
        useIncidentsPolicy()

    return (
        <Styles.ButtonWrapper>
            <Styles.Button
                id="add-new-button-incident-policy"
                onClick={(e) => {
                    e.preventDefault()
                    addNewIncidentPolicyDetails()
                }}
                isChanged={isSavAbleNewIncident}
            >
                Add
            </Styles.Button>
            {!isValidIncidentReminder && (
                <Styles.WarningMessage>
                    To enable incident reminders, <br />
                    select a duration and a user to escalate to
                </Styles.WarningMessage>
            )}
        </Styles.ButtonWrapper>
    )
}
