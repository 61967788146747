import styled from 'styled-components'
import {animated} from 'react-spring'

interface ModalDialog {
    offset?: number
    overflowy?: string
}

export const ModalDialog = styled(animated.section)<ModalDialog>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    display: flex;
    flex-direction: column;
    width: 1330px;
    max-width: 95vw;
    min-width: 300px;
    height: 800px;
    min-height: 500px;
    max-height: 95vh;
    margin-left: ${(props) => (props.offset ? `${props.offset}px` : 0)};
    margin-top: ${(props) => (props.offset ? `${props.offset}px` : '20px')};
    filter: drop-shadow(10px 10px 30px ${(props) => props.theme.modalDialog.dropShadow});
    overflow-y: ${(props) => props.overflowy ?? 'visible'};
`
