import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'
import {IncidentResponse} from './incident-response'
import {PagedIncidentsState} from './paged-incidents-state'
import {ProcessingStatus} from './type-of-interfaces'

export const DEFAULT_PAGED_INCIDENTS_STATE: PagedIncidentsState = {
    loading: LoadingState.NotPopulated,
    dataIncidentsMap: new Map<number, IncidentResponse[]>(),
    totalNumberOfIncidents: undefined,
    totalNumberOfPages: undefined,
    selectedPage: 0,
    pageSize: 10,
    totalNumberOfItemsBySeverity: undefined,
    totalNumberOfItemsByStatus: undefined,
    incidentNo: null,
    saveResultMessage: undefined,
    noteIdentityToEdit: undefined,
    editableComments: new Map<GuidType, boolean>(),
    incidentModalId: null,
    isProcessingIncidentModal: ProcessingStatus.NOT_PROCESSING,
    guestEmails: [],
    displayFilterBar: false,
    findIncident: undefined,
    linkedItemsData: [],
    monitorLinkedItems: [],
    isFetchingItems: false,
    showCloseMonitoredIncidentModal: false,
    processingSaveCloseMonitor: ProcessingStatus.NOT_PROCESSING,
    showIncidentManagementModal: false,
} as PagedIncidentsState
