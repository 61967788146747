import * as Styled from './_styled/self-monitoring-card.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'
import {getLocationDescription} from '../../../../../store/state/locations/state'

interface CardTitleProps {
    monaEngineMode: MonaEngineMode
}
export function CardTitle({monaEngineMode}: CardTitleProps): JSX.Element {
    const locations = useTypedSelector(locationMapSelector)
    return (
        <Styled.CardTitleRow>
            <Styled.CardTitleData id={`message-value_${monaEngineMode.anomalyProcessingMode}`}>
                {getLocationDescription(locations, monaEngineMode.location)}
            </Styled.CardTitleData>
        </Styled.CardTitleRow>
    )
}
