import {MouseEvent} from 'react'
import {Tab} from './tab'
import * as Styled from './tab-section.styled'
import {ContentPageType} from '../../contexts/types/content-page'
interface TabHeaderProps {
    activeTab: ContentPageType
    toggle: (event: MouseEvent, tab: ContentPageType) => void
}

export function TabHeader({activeTab, toggle}: TabHeaderProps): JSX.Element {
    return (
        <Styled.Container>
            <Tab activeTab={activeTab} index={ContentPageType.ASSET_LEVEL} toggle={toggle}>
                Assets
            </Tab>
            <Tab activeTab={activeTab} index={ContentPageType.SYSTEM_LEVEL} toggle={toggle}>
                Vessel Systems
            </Tab>
        </Styled.Container>
    )
}
