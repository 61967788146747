import {NodeValue} from '../values/nodes/NodeData'
import {ThreatMeasureMap} from '../values/ThreatMeasure'

export enum ThreatValues {
    HIGH = 'high',
    MEDIUM = 'medium',
    LOW = 'low',
}

export type ThreatValue = ThreatValues.HIGH | ThreatValues.MEDIUM | ThreatValues.LOW

const threatHelper = (
    score: number | undefined,
    threatMeasuresObject: ThreatMeasureMap,
    node: NodeValue,
): ThreatValue => {
    const value = threatMeasuresObject?.get(node)
    if (value && score) {
        if (value && score >= value.upperThreshold) {
            return ThreatValues.HIGH
        } else if (score >= value.lowerThreshold && score <= value.upperThreshold) {
            return ThreatValues.MEDIUM
        }
    }

    return ThreatValues.LOW
}

export default threatHelper
