import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {showCards, SOFTWARE_INVENTORY_DETAILS} from './data-helpers'
import {SoftwareInventoryCards} from './cards/software-inventory-cards'
import {SoftwareInventoryTable} from './table/software-inventory-table'
import {DataActions} from './data-actions/data-actions'
import {ContentContainerBottom} from '../../../templates/fixed-page/content-container-bottom.styled'
import {SoftwareInventoryPaging} from './footer/paging/software-inventory-paging'
import {RecordSet} from './footer/record-set/record-set'
import {usePagedSoftwareInventory} from '../contexts/use-paged-software-inventory'
import {ContentWrapper} from '../software-inventory.styled'
import {removeQueryParam} from '../../../helpers/navigation'
import {Footer} from '../../../components/table-view-components/footer.styled'

export function Content(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, closeSoftwareDetailsModal, modalIdForSoftwareDetails} =
        usePagedSoftwareInventory()
    const showCardsEarlier = showFilterBar || modalIdForSoftwareDetails !== null

    return (
        <ContentWrapper cardsView={showCards(width, showCardsEarlier)}>
            <DataActions />
            {showCards(width, showCardsEarlier) ? (
                <SoftwareInventoryCards />
            ) : (
                <SoftwareInventoryTable />
            )}
            <ContentContainerBottom width={width}>
                <Footer
                    width={width}
                    onClick={() => {
                        removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
                        closeSoftwareDetailsModal()
                    }}
                >
                    <SoftwareInventoryPaging />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </ContentWrapper>
    )
}
