import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'
import {usePagedReports} from '../../../contexts/use-paged-reports'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfReports, selectedPage, pageSize} = usePagedReports()
    if (totalNumberOfReports == undefined || selectedPage == undefined || pageSize == undefined) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfReports
            ? totalNumberOfReports
            : startingIndicator + pageSize - 1
    return (
        <>
            {totalNumberOfReports !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfReports}
                />
            )}
        </>
    )
}
