import {spacing} from '../../../../theme/spacing'

export function getGridTemplateColumns(width: number): string {
    if (width > 1620) {
        return `${spacing(1)} 70px 110px 280px 100px 120px 280px 120px 120px 120px auto ${spacing(
            1,
        )}`
    }
    if (width > 1560) {
        return `${spacing(1)} 70px 110px 280px 100px 115px 280px 120px 120px 120px auto ${spacing(
            2,
        )}`
    }
    if (width > 1460) {
        return `${spacing(1)} 70px 110px 230px 100px 115px 240px 110px 110px 110px auto ${spacing(
            1,
        )}`
    }
    if (width > 1400) {
        return `${spacing(1)} 70px 105px 200px 100px 115px 200px 110px 110px 110px auto ${spacing(
            1,
        )}`
    }
    if (width > 1320) {
        return `${spacing(1)} 70px 105px 160px 100px 100px 160px 110px 110px 110px auto ${spacing(
            1,
        )}`
    }
    if (width > 1250) {
        return `${spacing(1)} 70px 105px 140px 80px 100px 140px 110px 110px 110px auto ${spacing(
            1,
        )}`
    }
    return `${spacing(1)} 60px 105px 120px 70px 100px 120px 100px 100px 100px auto ${spacing(1)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1620) {
        return `${spacing(1)} 70px 105px 150px 100px 120px 150px 120px 120px 120px 0 ${spacing(1)}`
    }
    if (width > 1560) {
        return `${spacing(1)} 70px 105px 135px 100px 115px 135px 120px 120px 120px 0 ${spacing(1)}`
    }
    if (width > 1500) {
        return `${spacing(1)} 70px 105px 130px 100px 115px 130px 100px 100px 100px 0 ${spacing(1)}`
    }
    if (width > 1450) {
        return `${spacing(1)} 70px 105px 130px 100px 115px 130px 90px 100px 100px 0 ${spacing(1)}`
    }
    if (width > 1400) {
        return `${spacing(1)} 70px 105px 120px 80px 115px 120px 90px 100px 100px 0 ${spacing(1)}`
    }
    if (width > 1310) {
        return `0 60px 105px 110px 70px 115px 110px 80px 90px 100px 0 0`
    }
    return `0 60px 103px 90px 70px 112px 95px 80px 90px 100px 0 0`
}
