import {DisplayFormatType} from '../../../contexts/types/display-format-type'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentHistoryOutputModel} from '../../../models/history-record.model'
import {IncidentStatusChangeRecord} from '../incident-status/incident-status-change-record'
import {IncidentNoteRecord} from '../notes/incident-note'
import {IncidentAssignmentChangeRecord} from '../severity-level/incident-assignment-change-record'
import {IncidentSeverityChangeRecord} from '../severity-level/incident-severity-change-record'

interface IncidentHIstoryRecordProps {
    record: IncidentHistoryOutputModel
    incidentIdentity: GuidType
    displayFormat: DisplayFormatType
}

export function IncidentHistoryRecord({
    record,
    incidentIdentity,
    displayFormat,
}: IncidentHIstoryRecordProps): JSX.Element {
    const isNoteRecord = record.noteRecord != undefined
    const isStatusChangeRecord = record.statusChangeRecord != undefined
    const isSeverityChangeRecord = record.severityChangeRecord != undefined
    const isAssignmentChangeRecord = record.assignmentChangeRecord != undefined

    return (
        <>
            {isNoteRecord && (
                <IncidentNoteRecord
                    key={record.noteRecord.id}
                    note={record.noteRecord}
                    incidentidentity={incidentIdentity}
                />
            )}
            {isStatusChangeRecord && (
                <IncidentStatusChangeRecord
                    key={record.statusChangeRecord.id}
                    statusRecord={record.statusChangeRecord}
                    displayFormat={displayFormat}
                />
            )}
            {isSeverityChangeRecord && (
                <IncidentSeverityChangeRecord
                    key={record.severityChangeRecord.id}
                    severityRecord={record.severityChangeRecord}
                />
            )}
            {isAssignmentChangeRecord && (
                <IncidentAssignmentChangeRecord
                    key={record.assignmentChangeRecord.id}
                    assignmentRecord={record.assignmentChangeRecord}
                />
            )}
        </>
    )
}
