import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const AreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const LockImage = styled.img`
    width: ${spacing(3)};
    height: ${spacing(3)};
`
export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 7px;
`
