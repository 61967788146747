import {StrictMode} from '../../../../../../store/state/users/state'
import {StrictModeLabel} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './radio-button.styled'

interface Props {
    label: StrictMode
}

export function RadioButton({label}: Props): JSX.Element {
    const {setTagStrictMode, activeUserDetails} = useUserManagement()
    const discription =
        label === StrictMode.Strict ? StrictModeLabel.LIMIT : StrictModeLabel.DEFAULT

    return (
        <Styled.Container
            disabled={!activeUserDetails.strictMode}
            onClick={() => setTagStrictMode(label)}
        >
            <Styled.Input
                type="radio"
                id={`${label}-radio`}
                value={label}
                checked={activeUserDetails.strictMode === label}
                disabled={false}
                readOnly
            />

            <Styled.Label htmlFor={`-radio`}>{discription}</Styled.Label>
        </Styled.Container>
    )
}
