import styled from 'styled-components'
import {mediumLargeFont, mediumSmallFont} from '../../../theme/font-styles'
import {spacing} from '../../../theme/spacing'

type BannerProps = {
    isCard: boolean
}

export const Banner = styled.div<BannerProps>`
    background-color: #303030;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: ${(props) => (props.isCard ? '0px' : '20px')};
    padding: ${spacing(6)} ${spacing(12)} ${spacing(6)} ${spacing(12)};
`

export const FlexRowItem = styled.span``

export const Button = styled.button<BannerProps>`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    ${(props) => (props.isCard ? mediumSmallFont() : mediumLargeFont())}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    min-width: 110px;
`

export const Text = styled.span`
    margin-left: 5px;
`
