import styled from 'styled-components'

interface TableDataRowProps {
    gridRow: number
    span?: number
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.softwareInventory.dataRow.text};
    background-color: ${(props) => props.theme.softwareInventory.dataRow.background};
    min-height: 40px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
`
