import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'
interface ContainerProps {
    active: boolean
}
export const Container = styled.div`
    display: flex;
    width: 90px;
    height: 30px;
    cursor: pointer;
`

export const SearchIcon = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) =>
        props.active
            ? props.theme.softwareInventory.resetButton.text
            : props.theme.colors.link.primary};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-right: none;
    border-radius: ${spacing(1)} 0 0 ${spacing(1)};
    background-color: ${(props) =>
        props.active && props.theme.softwareInventory.resetButton.background};
`
export const TextWrapper = styled.div<ContainerProps>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-left-width: 0;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0;
    background-color: ${(props) =>
        props.active && props.theme.softwareInventory.resetButton.background};
    color: ${(props) =>
        props.active
            ? props.theme.softwareInventory.resetButton.text
            : props.theme.colors.link.primary};
`
export const Text = styled.span`
    ${mediumSmallFont()}
`
