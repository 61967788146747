import {ReactNode, useReducer} from 'react'
import {GuidType} from '../../../../values/generic-type-defintions'
import {PagedUniqueFlowsContext} from './paged-unique-flows-context'
import {pagedUniqueFlowsReducer} from './state/reducer'
import {DEFAULT_PAGED_UNIQUE_FLOWS_STATE} from './types/default-paged-unique-flows-state'
import {PagedUniqueFlowsState} from './types/paged-unique-flows-state'

type PagedUniqueFlowsProviderProps = {
    children: ReactNode | ReactNode[]
    assetId: GuidType
    fromDate: string | undefined
    toDate: string | undefined
    pageSize: number
}

export function PagedUniqueFlowsProvider({
    children,
    assetId,
    fromDate,
    toDate,
    pageSize,
}: PagedUniqueFlowsProviderProps): JSX.Element {
    const initialState: PagedUniqueFlowsState = DEFAULT_PAGED_UNIQUE_FLOWS_STATE
    initialState.assetId = assetId
    initialState.fromDate = fromDate
    initialState.toDate = toDate
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedUniqueFlowsReducer, initialState)

    return (
        <PagedUniqueFlowsContext.Provider value={{state, dispatch}}>
            {children}
        </PagedUniqueFlowsContext.Provider>
    )
}
