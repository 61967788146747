import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface CardAreaProps {
    width: number
}

export const CardArea = styled.div<CardAreaProps>`
    flex: 1;
    padding-top: ${spacing(2)};
    background-color: ${(props) => props.theme.systemPage.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
