import {
    findMetricCategory,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {SectionText, WidgetIconsWrapper} from './widget-commonstyling.styled'

export function WidgetIconsSection(): JSX.Element {
    return (
        <WidgetIconsWrapper>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '130px',
                }}
            >
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.PROTECTION))}
                    style={{width: '35px', height: '35px'}}
                    id="metricTypeIcon-protection"
                />
                <SectionText>Protection</SectionText>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '130px',
                }}
            >
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.MAINTENANCE))}
                    style={{width: '35px', height: '35px'}}
                    id="metricTypeIcon-maintenance"
                />
                <SectionText>Maintenance</SectionText>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '130px',
                }}
            >
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.BEHAVIOUR))}
                    style={{width: '35px', height: '35px'}}
                    id="metricTypeIcon-behaviour"
                />
                <SectionText>Behaviour</SectionText>
            </div>
        </WidgetIconsWrapper>
    )
}
