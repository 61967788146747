import * as Styled from './vessel-tag.styled'
import {X} from 'react-feather'

interface Props {
    tagName: string
    onClickFn?: () => void
    onDeleteFn?: () => void
}

export function VesselTag({tagName, onClickFn, onDeleteFn}: Props): JSX.Element {
    return (
        <Styled.TagResult cursorPointer={onClickFn != undefined} onClick={onClickFn}>
            <Styled.TagItem>{tagName}</Styled.TagItem>
            {onDeleteFn && (
                <Styled.XIcon onClick={onDeleteFn}>
                    <X onClick={onDeleteFn} height={12} width={12} />
                </Styled.XIcon>
            )}
        </Styled.TagResult>
    )
}
