import * as Styled from './_styled/self-monitoring-card.styled'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'

interface Props {
    monaEngineMode: MonaEngineMode
    fixedWidthReducedText: boolean
}

export function CardModeCell({monaEngineMode, fixedWidthReducedText}: Props): JSX.Element {
    return (
        <Styled.CardDataCell titleOnSameLine={fixedWidthReducedText}>
            <Styled.ReducedText fixedWidth={fixedWidthReducedText}>Mode:</Styled.ReducedText>
            <div id={`location-value_${monaEngineMode.anomalyProcessingMode}`}>
                {monaEngineMode.mode}
            </div>
        </Styled.CardDataCell>
    )
}
