import {animated} from 'react-spring'
import styled from 'styled-components'

export const Value = styled(animated.div)`
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 1.3125rem;
    line-height: 1.75rem;
    letter-spacing: 0.02rem;
    padding: 0 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
