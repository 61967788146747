import {useSpring} from 'react-spring'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {sfmSelector} from '../../../../../store/state/sfm-scores/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {Box} from './box.styled'
import {Value} from './value.styled'
import {useTheme} from 'styled-components'
import {nodesStateSelector} from '../../../../../store/state/nodes/selectors'
import {getColor, getBackgroundColor} from '../../../../../theme/helpers/risk-score'
import threatHelper, {ThreatValues} from '../../../../../helpers/threatHelper'
import {threatMeasuresSelector} from '../../../../../store/state/threat-measures/selectors'
import {SFMReduxState} from '../../../../../store/state/sfm-scores/state'
import {normaliseScore} from '../../../../../helpers/formatting'
interface RiskScoreProps {
    assetId: GuidType
}

function getScore(sfmState: SFMReduxState, assetId: GuidType): number | undefined {
    const score = !sfmState.fetching ? sfmState?.scores?.get(assetId) : undefined
    return score == undefined ? score : Math.round(normaliseScore(score))
}

function RiskScore({assetId}: RiskScoreProps): JSX.Element {
    const theme = useTheme()
    const score = getScore(useTypedSelector(sfmSelector), assetId)

    const nodesState = useTypedSelector(nodesStateSelector)
    const node = !nodesState.isFetchingNodes ? nodesState?.nodes?.get(assetId) : undefined

    const threatMeasureMap = useTypedSelector(threatMeasuresSelector)
    const threatLevel = node ? threatHelper(score, threatMeasureMap, node.value) : ThreatValues.LOW

    const boxProps = useSpring({
        background: getBackgroundColor(theme, score, node?.value, threatLevel),
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueProps: any = useSpring({color: getColor(theme, score, node?.value, threatLevel)})
    return (
        <Box style={boxProps}>
            <Value style={valueProps} id="asset_score">
                {score != undefined ? score : '?'}
            </Value>
        </Box>
    )
}

export default RiskScore
