import * as Styled from './_styled/vessels-card.styled'
import {Location} from '../../../../../store/state/locations/state'

interface CardTitleProps {
    location: Location
}
export function CardTitle({location}: CardTitleProps): JSX.Element {
    return (
        <Styled.CardTitleRow>
            <Styled.CardTitleData id={`location-description_${location.location}`}>
                {location.description}
            </Styled.CardTitleData>
        </Styled.CardTitleRow>
    )
}
