import {X} from 'react-feather'
import * as Styled from './_styled/vessel-tag.styled'

interface Props {
    tagName: string
    onClickFn?: () => void
    onDeleteFn?: () => void
    maxWidth?: string
}

export function VesselTag({tagName, onClickFn, onDeleteFn, maxWidth}: Props): JSX.Element {
    return (
        <Styled.TagResult
            cursorPointer={onClickFn != undefined}
            onClick={onClickFn}
            id={`tag-result-${tagName}-tags-widget`}
        >
            <Styled.TagItem maxWidth={maxWidth} id={`tag-name-${tagName}-tags-widget`}>
                {tagName}
            </Styled.TagItem>
            <Styled.XIcon onClick={onDeleteFn} id="delete-tag-button-tags-widget">
                <X onClick={onDeleteFn} height={12} width={12} id="delete-tag-icon-tags-widget" />
            </Styled.XIcon>
        </Styled.TagResult>
    )
}
