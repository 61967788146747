import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../theme/theme'

interface SystemPageStyleProps {
    width: number
}

export const BREAK_POINT_REFRESH_SWITCH = 600
export const BREAK_POINT_SINGLE_COLUMN = 709

export const AuthTokenLayout = styled.div<SystemPageStyleProps>`
    margin: ${(props) => (props.width > MAX_SCREEN_WIDTH ? spacing(2) : 0)} 0;
    padding: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? spacing(4) : spacing(2))}
        ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? spacing(8) : spacing(4))};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
    color: ${(props) => props.theme.colors.text.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
`

export const Header = styled.div<SystemPageStyleProps>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'row' : 'column')};
`
export const PageTitle = styled.div<SystemPageStyleProps>`
    font-size: 20px;
    line-height: 27px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const UpdatedTimeArea = styled.div<SystemPageStyleProps>`
    display: flex;
    padding-top: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 0 : spacing(2))};
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'column' : 'row')};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`
interface PropsLink {
    activeLink: boolean
}
export const SubPagesLink = styled.a<PropsLink>`
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
    text-decoration: none;
    padding: 0 ${spacing(2)} ${spacing(1)} 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
    transition: all 1s ease;
    :hover {
        color: ${(props) => props.theme.navigationResponsive.active.underLine};
        text-decoration: inherit;
    }
    ${(props) => props.activeLink && `color: ${props.theme.navigationResponsive.active.underLine};`}
`
interface ContentAreaProps {
    width: number
}

export const ContentArea = styled.div<ContentAreaProps>`
    flex: 1;
    padding: ${(props) =>
        props.width < 600
            ? `${spacing(4)} ${spacing(2)} ${spacing(2)} ${spacing(2)}`
            : `${spacing(4)} ${spacing(4)} ${spacing(2)} ${spacing(4)}`};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
interface ContentContainerMiddleProps {
    width: number
    backgroundColor?: ColorType
}

export const LinksArea = styled.div<ContentContainerMiddleProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    margin: ${(props) => (props.width > MAX_SCREEN_WIDTH ? `0 ${spacing(2)}` : 0)};
    align-self: center;
`
