import {LocationIdType} from '../../../../../store/state/locations/state'
import {VesselDetails} from '../common/vessel-details/vessel-details'
import {AssetThreatScoresGrid} from '../common/level-3-and-4-asset-threat-scores-grid'
import * as Styles from './cell.styled'
import {AssetDetailsGrid} from './asset-details-grid'

interface Props {
    id: LocationIdType
}

export function ZoomLevel4Cell({id}: Props): JSX.Element {
    return (
        <Styles.Cell>
            <div>
                <VesselDetails id={id} fixedWidthContent />
            </div>
            <AssetThreatScoresGrid id={id} />
            <AssetDetailsGrid id={id} />
        </Styles.Cell>
    )
}
