import {GridLayout} from '../../general/grid-layout.enum'
import {Content} from '../../general/content-area/headline-values/content.styled'
import {VesselsCount} from './headline-values-vessels-count'
import {VesselsAtRiskCount} from './headline-values-vessels-risk-count'
import {AssetsAtRiskCount} from './headline-values-assets-risk-count'

interface HeadlineValuesProps {
    gridLayout: GridLayout
}
export function HeadlineValues({gridLayout}: HeadlineValuesProps): JSX.Element {
    return (
        <Content gridLayout={gridLayout} id="vessels-at-risk-HeadlineValues">
            <VesselsCount gridLayout={gridLayout} />
            <VesselsAtRiskCount gridLayout={gridLayout} />
            <AssetsAtRiskCount gridLayout={gridLayout} />
        </Content>
    )
}
