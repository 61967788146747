import moment from 'moment'
import {normaliseScore} from '../../../../helpers/formatting'
import threatHelper from '../../../../helpers/threatHelper'
import {LocationIdType} from '../../../../store/state/locations/state'
import {LocationNodeArrayMapType} from '../../../../store/state/nodes/state'
import {VesselFilterReduxState} from '../../../../store/state/vessel-filter/types/vessel-filter-state'
import {NodeData, NodeDataMap} from '../../../../values/nodes/NodeData'
import {getScoreForNodes} from '../../../../values/nodes/reselectors/helpers'
import {AssetThreatScoreOutputModel} from '../../../my-vessels-v2/listing/reselectors/asset-threat-score.model'
import {TimestampFilterType} from './timestamp-filter'
import {SFMScoreMap} from '../../../../store/state/sfm-scores/state'
import {ThreatMeasureMap} from '../../../../values/ThreatMeasure'

export function buildAssetsAtRiskModel(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
    nodesForLocations: LocationNodeArrayMapType,
    vesselFilter: VesselFilterReduxState,
    sfmScores: SFMScoreMap,
    threatMeasures: ThreatMeasureMap,
    nodes: NodeDataMap,
): Map<LocationIdType, AssetThreatScoreOutputModel[]> {
    const updatedNodeListMap = new Map<LocationIdType, AssetThreatScoreOutputModel[]>()
    for (const locationId of locationIds) {
        const filteredNodeIds = filteredNodeIdsForLocation(
            nodesForLocations,
            vesselFilter,
            analysisPeriod,
            locationId,
        )
        const updatedNodeList = (filteredNodeIds || [])
            .map((nodeId) => {
                const node = nodes.get(nodeId)
                if (!node) {
                    throw Error('Could not find node')
                }

                const score = getScoreForNodes(node, sfmScores)
                const threatLevel = threatHelper(score, threatMeasures, node.value)

                return {
                    id: nodeId,
                    nodeValue: node.value,
                    score: normaliseScore(score),
                    threatLevel: threatLevel,
                } as AssetThreatScoreOutputModel
            })
            .filter((asset) => normaliseScore(asset.score) > 30)

        updatedNodeListMap.set(locationId, updatedNodeList)
    }

    return updatedNodeListMap
}
function filterByVessel(node: NodeData, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(node.location)
}

function filterByGivenPeriod(node: NodeData, analysisPeriod: TimestampFilterType): boolean {
    const updatedAtTimestamp = moment(node.updatedAt).unix()
    const currentDateTimestamp = moment().unix()

    switch (analysisPeriod) {
        case '30d':
            return currentDateTimestamp - updatedAtTimestamp <= 30 * 24 * 60 * 60
        case '7d':
            return currentDateTimestamp - updatedAtTimestamp <= 7 * 24 * 60 * 60
        case '1d':
            return currentDateTimestamp - updatedAtTimestamp <= 24 * 60 * 60
        default:
            return true
    }
}

export function filteredNodeIdsForLocation(
    nodesForLocations: LocationNodeArrayMapType,
    vesselFilter: VesselFilterReduxState,
    analysisPeriod: TimestampFilterType,
    locationId: LocationIdType,
): string[] {
    try {
        if (!nodesForLocations || !nodesForLocations.has(locationId)) {
            return []
        }
        const nodesForLocation = nodesForLocations.get(locationId)
        if (!nodesForLocation) {
            return []
        }

        return nodesForLocation
            .filter((node) => filterByVessel(node, vesselFilter.locations))
            .filter((node) => filterByGivenPeriod(node, analysisPeriod))
            .map((node) => node.node)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({msg: 'Unable to get nodes for location', error})
        return []
    }
}
