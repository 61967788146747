import * as Styled from './vessels.styled'
import {ScrollablePageTemplate} from '../../../templates/scrollable-page/scrollable-page-template'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {VesselsList} from './components/vessels-list'

export default function Vessels(): JSX.Element {
    const {width} = useDimensions()

    return (
        <ScrollablePageTemplate>
            <Styled.Vessels width={width}>
                <Styled.Layout width={width}>
                    <VesselsList />
                </Styled.Layout>
            </Styled.Vessels>
        </ScrollablePageTemplate>
    )
}
