import styled from 'styled-components'

interface CardAreaProps {
    width: number
}

function getCardWidth(width: number): string {
    if (width > 320) {
        return '20px'
    }
    return '10px'
}

export const CardArea = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: ${(props) => getCardWidth(props.width)};
    justify-content: space-between;
`
