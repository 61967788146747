import {useEffect} from 'react'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {Loading} from './components/loading'
import {DataLoaded} from './components/data-loaded'
import {MetricsWidgetProvider} from './contexts/metrics-widget-provider'
import {useMetricsWidget} from './contexts/use-metrics-component'
import LoadingState from '../../../../values/loading-state-enum'

export function MetricsWidget({location}: {location: string | null}): JSX.Element {
    const formattedLocation = location != null ? location : undefined

    return (
        <MetricsWidgetProvider>
            <MetricsWidgetContent location={formattedLocation} />
        </MetricsWidgetProvider>
    )
}
function MetricsWidgetContent({location}: {location: string | undefined}): JSX.Element {
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const {fetchFilter, loadedFilterState, loadingFilterState} = useMetricsWidget()

    useEffect(() => {
        if (loadingFilterState === LoadingState.NotPopulated) {
            fetchFilter()
        }
    }, [assignedTag, strictMode, location, loadingFilterState])

    return loadedFilterState ? <DataLoaded location={location} /> : <Loading />
}
