import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {VesselTags} from '../../../../../store/state/vessel-tags/state'
import {VesselTag} from '../../../../../components/vessel-tag/vessel-tag'
import {CardContainer} from './vessel-tags-card.styled'

interface VesselTagsPopupProps {
    tags: VesselTags[]
}

export function VesselTagsCard({tags}: VesselTagsPopupProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <CardContainer width={width} id="VesselTagsCard">
            {tags.map((tag, index) => (
                <VesselTag key={index} tagName={tag.name} />
            ))}
        </CardContainer>
    )
}
