import * as Styled from './_styled/self-monitoring-table-populated.styled'
import {TableRow} from './self-monitoring-engine-table'
import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {TableHeader} from './self-monitoring-table-header'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'

interface SelfMonitoringEngineTablePopulatedProps {
    selfMonitoringEngineEvents: SelfMonitoringEngineEvent[]
}
export function SelfMonitoringEngineTablePopulated({
    selfMonitoringEngineEvents,
}: SelfMonitoringEngineTablePopulatedProps): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <>
            <Styled.ContentContainer
                width={width}
                backgroundColor={theme.systemPage.dataArea.background}
            >
                <TableHeader />
            </Styled.ContentContainer>
            <Styled.ContentContainer width={width}>
                <Styled.TableDataGrid>
                    {selfMonitoringEngineEvents.map((selfMonitoringEngineEvent, index) => (
                        <TableRow
                            key={`${selfMonitoringEngineEvent.identity}`}
                            selfMonitoringEngineEvent={selfMonitoringEngineEvent}
                            index={index}
                        />
                    ))}
                </Styled.TableDataGrid>
            </Styled.ContentContainer>
        </>
    )
}
