import createCachedSelector from 're-reselect'
import {normaliseScore} from '../../../../helpers/formatting'
import threatHelper from '../../../../helpers/threatHelper'
import {getLocationScore} from '../../../../store/state/locations/helpers'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../../store/state/locations/state'
import {nodesForLocationsSelector} from '../../../../store/state/nodes/selectors'
import {threatMeasuresSelector} from '../../../../store/state/threat-measures/selectors'
import AppState from '../../../../store/types/app-state'
import {NodeValues} from '../../../../values/nodes/NodeData'
import {latestLocationThreatScoreSelector} from '../../../../store/state/latest-location-threat-scores/selectors'
import {VesselDetailsOutputModel} from './vessel-details.model'
import {vesselTagsForLocationsSelector} from '../../../../store/state/vessel-tags/selectors'
import {VesselTags} from '../../../../store/state/vessel-tags/state'
import {formatVesseltagsForUI, VesselTagsAPI} from '../../../../store/state/vessel-tags/helper'

function formatNumberOfAssets(numberOfAssets: number): string {
    return numberOfAssets === 1 ? '1 asset' : `${numberOfAssets} assets`
}

function sortedTagsForLocation(vesselTags: VesselTagsAPI[] | undefined): VesselTags[] {
    if (!vesselTags) {
        return []
    }
    return [...vesselTags]
        ?.map((element) => formatVesseltagsForUI(element))
        .sort((a: VesselTags, b: VesselTags) => a.name?.localeCompare(b.name))
}

export const vesselDetailsCachedReselector = createCachedSelector(
    [
        locationMapSelector,
        nodesForLocationsSelector,
        latestLocationThreatScoreSelector,
        threatMeasuresSelector,
        vesselTagsForLocationsSelector,
        (_state: AppState, locationId: LocationIdType) => locationId,
    ],
    (
        locations,
        nodesForLocations,
        latestLocationThreatScores,
        threatMeasures,
        vesselTagsForLocations,
        locationId,
    ) => {
        const location: Location | undefined = locations.get(locationId)

        if (!location) {
            throw Error('Could not find location')
        }

        const score = getLocationScore(location.location, latestLocationThreatScores)

        return {
            id: locationId,
            name: location.description,
            shipType: location.type,
            imoNumber: location?.customerLocationCode || 'Unknown',
            numberOfAssets: formatNumberOfAssets(
                nodesForLocations.get(location.location)?.length || 0,
            ),
            score: normaliseScore(score),
            threatLevel: threatHelper(score, threatMeasures, NodeValues.HIGH),
            vesselTags: sortedTagsForLocation(vesselTagsForLocations.get(location.location)),
            metadata: {
                networkConfiguration: {
                    BusinessNetworks: location.metaData?.network?.BusinessNetworks,
                    CrewNetworks: location.metaData?.network?.CrewNetworks,
                    OTNetworks: location.metaData?.network?.OTNetworks,
                },
                vesselDashboardAgentIds:
                    location.metaData?.vesselDashboardAgentIds === null
                        ? undefined
                        : location.metaData?.vesselDashboardAgentIds,
            },
            nodesForLocation: nodesForLocations.get(locationId)?.filter((node) => node.agentId),
            vesselEmail:
                location.emailAddress === null || location.emailAddress === 'null'
                    ? undefined
                    : location.emailAddress,
            vesselDashboard: location.vesselDashboard || false,
        } as VesselDetailsOutputModel
    },
)((_state, locationId) => locationId)
