import {GuidType} from '../../values/generic-type-defintions'

export function getNetworkAssetName(networkAsset: NetworkAssetData | undefined): string {
    if (!networkAsset) {
        return 'N/A'
    }

    if (networkAsset.name?.length > 0) {
        return `${networkAsset.name} (${networkAsset.mainIpAddress})`
    } else if (networkAsset.mainIpAddress?.length > 0) {
        return networkAsset.mainMacAddressVendor?.length > 16
            ? `${networkAsset.mainIpAddress} (${networkAsset.mainMacAddressVendor.slice(0, 16)}...)`
            : `${networkAsset.mainIpAddress} (${networkAsset.mainMacAddressVendor})`
    } else if (networkAsset.mainMacAddressVendor?.length > 0) {
        return networkAsset.mainMacAddressVendor?.length > 16
            ? `${networkAsset.mainMacAddressVendor?.slice(0, 16)}... (${
                  networkAsset.mainMacAddress
              })`
            : `${networkAsset.mainMacAddressVendor} (${networkAsset.mainMacAddress})`
    } else {
        return networkAsset.mainMacAddress
    }
}

export function getUsbItemName(usbItem: UsbItemData | undefined): string {
    if (!usbItem) {
        return 'N/A'
    }

    const vendorNamePart =
        usbItem.vendorName?.length > 16
            ? `${usbItem.vendorName.slice(0, 16)}...`
            : usbItem.vendorName
    const productNamePart =
        usbItem.productName?.length > 16
            ? `${usbItem.productName.slice(0, 16)}...`
            : usbItem.productName
    const idPart = `(${usbItem.deviceId?.slice(0, 3)})`
    return `${vendorNamePart} ${productNamePart} ${idPart}`
}

export function getSoftwareItemName(softwareItem: SoftwareItemData | undefined): string {
    if (!softwareItem) {
        return 'N/A'
    }

    const companyNamePart =
        softwareItem.company?.length > 16
            ? `${softwareItem.company.slice(0, 16)}...`
            : softwareItem.company
    const productNamePart =
        softwareItem.product?.length > 16
            ? `${softwareItem.product.slice(0, 16)}...`
            : softwareItem.product

    return `${companyNamePart} ${productNamePart}`
}

export interface NetworkAssetData {
    identity: GuidType
    name: string
    mainMacAddress: string
    mainMacAddressVendor: string
    mainIpAddress: string
    label: string
}

export interface UsbItemData {
    identity: GuidType
    vendorName: string
    productName: string
    deviceId: string
    label: string
}

export interface SoftwareItemData {
    identity: GuidType
    company: string
    product: string
    label: string
}
