import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

export const Note = styled.div`
    text-align: start;
    ${smallFont()}
    font-style: italic;
    padding: 0 ${spacing(1)} ${spacing(1)};
`

export const Vessels = styled.div`
    height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

interface VesselRowProps {
    isInactive?: boolean
}

export const VesselRow = styled.div<VesselRowProps>`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    ${(props) => (props.isInactive ? 'pointer-events: none; opacity: 0.3;' : '')}
`
