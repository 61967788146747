import {useState, useRef} from 'react'
import {ChevronUp, ChevronDown} from 'react-feather'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import * as Styled from '../_styled/dropdown.styled'
import {setPurpose} from '../../../../store/state/extended-data-request/action-creators'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {extendedDataRequestStateSelector} from '../../../../store/state/extended-data-request/selectors'

export function PurposeDropdown(): JSX.Element {
    const dispatch = useDispatch()
    const {inventory, data} = useTypedSelector(extendedDataRequestStateSelector)
    const [optionsVisible, setOptionsVisible] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    const allPurposeOption: string[] = [
        'Help with investigation of an incident raised by CyberOwl',
        'Help with investigation of an incident from another source',
        `A regular review or audit of ${inventory}`,
        `A one off review of ${inventory}`,
        'Something else',
    ]

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.FilterSearchWrapper
                onClick={() => setOptionsVisible(!optionsVisible)}
                id="purpose-drop-down"
            >
                <Styled.SearchInput id="filter-search">
                    {data.purpose ?? 'Help with investigation of an incident raised by CyberOwl'}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {optionsVisible ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.FilterSearchWrapper>
            <Styled.SavedFiltersWrapper ref={clickOutside}>
                {optionsVisible && (
                    <Styled.PeriodDropdown>
                        {allPurposeOption.map((value, index) => (
                            <Styled.ValueOption
                                key={index}
                                onClick={() => {
                                    dispatch(setPurpose(value))
                                    setOptionsVisible(false)
                                }}
                                id={`value-option_${value}`}
                            >
                                {value}
                            </Styled.ValueOption>
                        ))}
                    </Styled.PeriodDropdown>
                )}
            </Styled.SavedFiltersWrapper>
        </Styled.Container>
    )
}
