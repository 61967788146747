import * as Styled from './header.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {RefreshButton} from './refresh-button/refresh-button'
import {AddNewPolicyArea} from './add-new-policy-area'
import {usePolicyManagement} from '../contexts/policy-management/use-policy-management'
import {useIncidentsPolicy} from '../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../contexts/metrics-policy/use-metrics-policy'
import {useEnforcementPolicy} from '../contexts/enforcement-policy/use-enforcement-policy'

interface HeaderProp {
    text: string
}
export function Header({text}: HeaderProp): JSX.Element {
    const {width} = useDimensions()
    const {closePolicyDetailModal} = usePolicyManagement()
    const {setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {setDefaultEnforcementPolicyData} = useEnforcementPolicy()

    function refresh() {
        closePolicyDetailModal(true)
        setDefaultIncidentPolicyData()
        setDefaultMetricsPolicyData()
        setDefaultEnforcementPolicyData()
    }

    return (
        <Styled.Header width={width}>
            <Styled.TitleWrapper>
                <Styled.PageTitle width={width}>{text}</Styled.PageTitle>
                <AddNewPolicyArea />
            </Styled.TitleWrapper>

            <Styled.HeaderAreaWrapper width={width}>
                <Styled.UpdatedTimeArea width={width}>
                    <RefreshButton refreshFunction={refresh} />
                </Styled.UpdatedTimeArea>
            </Styled.HeaderAreaWrapper>
        </Styled.Header>
    )
}
