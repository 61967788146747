import {IncidentTypeText, IncidentTypeWrapper} from './incident-type.styled'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentTypesSelector} from '../../../../../store/state/incident-types/selectors'
import {getMatchingIcon, getTypeDisplayName} from '../../../contexts/types/incident-type'
import {getIncidentTypeIcon} from '../../../data-helpers'
interface IncidentTypeProps {
    incidentType: string
    id: GuidType
}

export function IncidentType({incidentType, id}: IncidentTypeProps): JSX.Element {
    const {width} = useDimensions()
    const incidentTypes = useTypedSelector(incidentTypesSelector)

    return (
        <IncidentTypeWrapper id={id} width={width}>
            <img
                src={getIncidentTypeIcon(getMatchingIcon(incidentTypes, incidentType))}
                height={16}
                width={16}
            />
            <IncidentTypeText>{getTypeDisplayName(incidentTypes, incidentType)}</IncidentTypeText>
        </IncidentTypeWrapper>
    )
}
