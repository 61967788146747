import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface TagResultProps {
    cursorPointer?: boolean
}
export const TagResult = styled.div<TagResultProps>`
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 3px;
    border-radius: ${spacing(1)};
    background-color: rgba(31, 136, 229, 0.24);
    padding: 3px ${spacing(1)};
    ${(props) => props.cursorPointer && `cursor: pointer; margin-top: ${spacing(1)};`}
`

export const TagItem = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    max-width: 114px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const XIcon = styled.div`
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
`
