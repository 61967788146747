import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {IncidentOutputModel} from '../../models/incident.model'
import {VesselTagsCard} from './vessel-tags-card/vessel-tags-card'
import {VesselCell, VesselCellWrapper} from './_styled/incident-card.styled'

interface VesselDataCellProps {
    model: IncidentOutputModel
}

export function VesselDataCardCell({model}: VesselDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const showPopup = model.vesselTags.length > 0

    return (
        <VesselCell id={`vessel-value_${model.id}`} gridArea="VESSEL" width={width}>
            <VesselCellWrapper>
                {model.vessel}
                {showPopup && <VesselTagsCard tags={model.vesselTags} />}
            </VesselCellWrapper>
        </VesselCell>
    )
}
