import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface CardAreaProps {
    width: number
}

export const CardArea = styled.div<CardAreaProps>`
    flex: 1;
    display: grid;
    gap: ${spacing(2)};
    padding: ${(props) => (props.width < 600 ? `${spacing(2)}` : `${spacing(2)} ${spacing(4)}`)};
    background-color: ${(props) => props.theme.softwareInventory.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
export const MetricsDetails = styled.div`
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    overflow: auto;
`

export const Card = styled.div`
    color: ${(props) => props.theme.softwareInventory.dataRow.text};
    background-color: ${(props) => props.theme.softwareInventory.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    ${mediumSmallFont()}
`

export const CardAreaContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, [col-start] 1fr);
    grid-template-areas: 'METRIC FRAMEWORK' 'TIME  CONTROL-ISSUE';
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div`
    display: grid;
    grid-template-columns: repeat(3, [col-start] 1fr);
    grid-template-areas: 'VESSEL-NAME POLICY-NAME ANALYSIS-NAME';
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`
interface CardCustomerTitleDataProps {
    gridArea: string
}
export const CardCustomerTitleData = styled.h2<CardCustomerTitleDataProps>`
    display: flex;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`
interface CardProductTitleDataProps {
    gridArea: string
}
export const CardProductTitleData = styled.h2<CardProductTitleDataProps>`
    display: flex;
    margin: 0px;
    align-items: center;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`
