import {spacing} from '../../../theme/spacing'
import * as Style from './system-table.styled'
import LoadingState from '../../../values/loading-state-enum'
import {LoadingSpinner} from '../../../components/loading/loading'
import {systemTableConfig} from './system-table.config'
import {useOTSystemsContext} from '../contexts/hooks/use-ot-systems'
import {SystemTableExpandedRow} from './system-table-expanded-row'

import {useContext, useEffect} from 'react'
import {LocationInventoryPageContext} from '../contexts/location-inventory-page-context'
import {useSystemAssessmentModal} from '../contexts/hooks/use-system-assessment-modal'

export function SystemTable(): JSX.Element {
    const {
        page: {
            state: {locationID},
        },
    } = useContext(LocationInventoryPageContext)
    const {otSystems, fetchOTSystems} = useOTSystemsContext()
    const {openModal} = useSystemAssessmentModal()

    const {
        state: {data, loadingState},
    } = otSystems

    useEffect(() => {
        if (locationID) {
            fetchOTSystems(locationID)
        }
    }, [locationID])

    const renderedHeaders = systemTableConfig.map((item) => (
        <Style.Header key={item.label} style={{width: item.width}}>
            {item.label}
        </Style.Header>
    ))

    const renderedRows = []
    for (const [systemName, val] of Object.entries(data)) {
        // No need to display system if it has no asset
        const assetLen = val.hardwareAssets.length
        if (assetLen === 0) {
            continue
        }
        const assessmentLen = val.assessments.length
        const isExpanded = otSystems.state.expandedSystemNames.includes(systemName)

        let renderedRow: JSX.Element[]
        if (assessmentLen) {
            // Use latest (first element in array) for display
            const assessment = val.assessments[0]
            renderedRow = systemTableConfig.map((item) => (
                <Style.Cell key={item.label}>
                    {item.render({
                        systemName,
                        isExpanded: isExpanded,
                        assetTotal: assetLen,
                        dispatch: otSystems.dispatch,
                        systemID: val.hardwareAssets[0].systemID,
                        inE26Scope: assessment.inE26Scope,
                        vulnerabilityScore: assessment.vulnerabilityScore,
                        criticalityLevel: assessment.criticalityLevel,
                        openModal,
                    })}
                </Style.Cell>
            ))
        } else {
            renderedRow = systemTableConfig.map((item) => (
                <Style.Cell key={item.label}>
                    {item.render({
                        systemName,
                        isExpanded: isExpanded,
                        assetTotal: assetLen,
                        dispatch: otSystems.dispatch,
                        systemID: val.hardwareAssets[0].systemID,
                        openModal,
                    })}
                </Style.Cell>
            ))
        }

        renderedRows.push(<Style.Row key={'main-row-' + systemName}>{renderedRow}</Style.Row>)

        // Render the detail row if it is expanded
        if (isExpanded) {
            renderedRows.push(<SystemTableExpandedRow assets={val.hardwareAssets} />)
        }
    }

    if (renderedRows.length === 0) {
        renderedRows.push(
            <Style.Row>
                <td colSpan={systemTableConfig.length} style={{padding: spacing(3)}}>
                    No system data available
                </td>
            </Style.Row>,
        )
    }

    const isLoading =
        loadingState === LoadingState.NotPopulated || loadingState === LoadingState.RequestingData

    if (isLoading) {
        return <LoadingSpinner />
    }
    return (
        <>
            <Style.Table>
                <thead>
                    <Style.Row>{renderedHeaders}</Style.Row>
                </thead>
                <tbody>{renderedRows}</tbody>
            </Style.Table>
        </>
    )
}
