import styled, {css, keyframes} from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const Name = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
export const Assets = styled.span`
    white-space: nowrap;
`
export const ViewAllRowWrapper = styled.span`
    display: flex;
    justify-content: space-between;
`
export const VesselRowWrapper = styled.div`
    padding-left: ${spacing(2)};
`
const blinkAnimation = keyframes`
  0%, 100% {
    background-color: rgba(31, 136, 229, 0.1);
  }
  50% {
    background-color: inherit;
  }
`
interface WrapperProps {
    highlightedFilerValue: boolean
}
export const TableDataRow = styled.div<WrapperProps>`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    animation: ${(props) =>
        props.highlightedFilerValue
            ? css`
                  ${blinkAnimation} 1s infinite
              `
            : 'none'};
`

export const NoDataMessage = styled.div`
    margin: ${spacing(4)} 0;
`
