import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../theme/theme'

interface SquareProps {
    value: string
    expanded: boolean
}

function getColor(value: string, theme: CyberOwlTheme): string {
    if (value === 'High') {
        return theme.threatScores.score.lowScoreHighValue.text
    } else if (value === 'Med') {
        return theme.threatScores.score.lowScoreMediumValue.text
    }
    return theme.threatScores.score.lowScoreLowValue.text
}

function getBackgroundColor(value: string, theme: CyberOwlTheme): string {
    if (value === 'High') {
        return theme.threatScores.score.lowScoreHighValue.background
    } else if (value === 'Med') {
        return theme.threatScores.score.lowScoreMediumValue.background
    }
    return theme.threatScores.score.lowScoreLowValue.background
}

export const Square = styled.div<SquareProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: ${(props) => getColor(props.value, props.theme)};
    background-color: ${(props) => getBackgroundColor(props.value, props.theme)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 20px;
    line-height: 27px;
    position: relative;
    border-top-left-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.expanded ? 0 : '6px')};
`
