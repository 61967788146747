import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import {CheckboxType} from '../../../../../../components/form-elements/checkbox-type'
import {ThreatValue, ThreatValues} from '../../../../../../helpers/threatHelper'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {AssetThresholds} from '../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {NodeValue, NodeValues} from '../../../../../../values/nodes/NodeData'
import {assetThreatValueCountCachedReselector} from '../../../reselectors/asset-threat-value-count.cached-reselector'
import {Cell} from './asset-value-row.styled'

interface Props {
    nodeValue: NodeValue
    thresholdsSelected: AssetThresholds
    onChange: (value: NodeValue, threatValue: ThreatValue, checked: boolean) => void
}

function getLabelText(value: NodeValue): string {
    switch (value) {
        case NodeValues.HIGH:
            return 'High'
        case NodeValues.MEDIUM:
            return 'Medium'
        case NodeValues.LOW:
            return 'Low'
        default:
            return 'Unknown'
    }
}

function getType(value: NodeValue): CheckboxType {
    switch (value) {
        case NodeValues.HIGH:
            return CheckboxType.HIGH
        case NodeValues.MEDIUM:
            return CheckboxType.MEDIUM
        case NodeValues.LOW:
            return CheckboxType.LOW
        default:
            return CheckboxType.DEFAULT
    }
}

export function AssetValueRow({nodeValue, thresholdsSelected, onChange}: Props): JSX.Element {
    const lowerThresholdCount = useTypedSelector((state) =>
        assetThreatValueCountCachedReselector(state, {
            nodeValue,
            threatValue: ThreatValues.MEDIUM,
        }),
    )
    const upperThresholdCount = useTypedSelector((state) =>
        assetThreatValueCountCachedReselector(state, {
            nodeValue,
            threatValue: ThreatValues.HIGH,
        }),
    )

    return (
        <>
            <Cell>
                <Checkbox
                    id={`asset-filter-${nodeValue}-nominal`}
                    label={`${getLabelText(nodeValue)} Value Assets`}
                    type={getType(nodeValue)}
                    checked={thresholdsSelected.low}
                    onChange={(newValue) => onChange(nodeValue, ThreatValues.LOW, newValue)}
                />
            </Cell>
            <Cell>
                <Checkbox
                    id={`asset-filter-${nodeValue}-warning`}
                    label={lowerThresholdCount.toLocaleString()}
                    type={CheckboxType.WARNING}
                    checked={thresholdsSelected.medium}
                    onChange={(newValue) => onChange(nodeValue, ThreatValues.MEDIUM, newValue)}
                />
            </Cell>
            <Cell>
                <Checkbox
                    id={`asset-filter-${nodeValue}-danger`}
                    label={upperThresholdCount.toLocaleString()}
                    type={CheckboxType.DANGER}
                    checked={thresholdsSelected.high}
                    onChange={(newValue) => onChange(nodeValue, ThreatValues.HIGH, newValue)}
                />
            </Cell>
        </>
    )
}
