import {WarningMessageContainer} from './warning-message.styled'
import AlertBlue from '../../../../../../../@assets/icons/alert-high-blue.svg'

export function WarningMessage(): JSX.Element {
    return (
        <WarningMessageContainer>
            <img src={AlertBlue} width={20} style={{paddingRight: '5px'}} />
            Vessel does not support incident automation
        </WarningMessageContainer>
    )
}
