import {DataLoading} from '../../../../components/data-loading/data-loading'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {incidentsFilterLoadingSelector} from '../../../../store/state/incidents-filter/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {SaveMessage} from '../../contexts/types/save-note-result'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {IncidentTablePopulated} from './incident-table-populated'
import {NoData} from './no-data/no-data'

export function IncidentsTableContent(): JSX.Element {
    const {loading, totalNumberOfIncidents, saveResultMessage} = usePagedIncidents()
    const filterLoading = useTypedSelector(incidentsFilterLoadingSelector)
    const showLoading =
        (totalNumberOfIncidents == undefined ||
            loading === LoadingState.NotPopulated ||
            loading === LoadingState.RequestingData ||
            filterLoading) &&
        saveResultMessage?.message !== SaveMessage.FAILED

    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfIncidents === 0) {
        return <NoData text="There are no incidents" />
    }
    return <IncidentTablePopulated />
}
