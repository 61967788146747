import produce from 'immer'
import {AllActions} from './actions'
import {ActionType} from './action-type'
import {VesselConfigState} from '../types/vessel-config-state'
import {isEqual} from 'lodash'

export const vesselConfigReducer = produce((draft: VesselConfigState, action: AllActions) => {
    switch (action.type) {
        case ActionType.SET_CHANGE_TYPE:
            draft.displayData.type = action.payload
            draft.data.type = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_CUSTOMER_LOCATION_CODE:
            draft.displayData.customerLocationCode = action.payload
            draft.data.customerLocationCode = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_BUSINESS_NETWORKS:
            draft.displayData.businessNetworks = action.payload
            if (!isEqual(draft.data.businessNetworks, action.payload)) {
                draft.data.businessNetworks = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_OT_NETWORKS:
            draft.displayData.otNetworks = action.payload
            if (!isEqual(draft.data.otNetworks, action.payload)) {
                draft.data.otNetworks = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_CREW_NETWORKS:
            draft.displayData.crewNetworks = action.payload
            if (!isEqual(draft.data.crewNetworks, action.payload)) {
                draft.data.crewNetworks = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_GATEWAY_IP:
            draft.displayData.gatewayIp = action.payload
            draft.data.gatewayIp = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_GATEWAY_MAC:
            draft.displayData.gatewayMac = action.payload
            draft.data.gatewayMac = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_INCUS_IP:
            draft.displayData.incusIp = action.payload
            draft.data.incusIp = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_INCUS_MAC:
            draft.displayData.incusMac = action.payload
            draft.data.incusMac = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_SENSOR_IP:
            draft.displayData.sensorIp = action.payload
            draft.data.sensorIp = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_SENSOR_MAC:
            draft.displayData.sensorMac = action.payload
            draft.data.sensorMac = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_OT_DEVICES:
            draft.displayData.otDevices = action.payload
            if (!isEqual(draft.data.otDevices, action.payload)) {
                draft.data.otDevices = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_OT_CROSSINGS:
            draft.displayData.otCrossings = action.payload
            if (!isEqual(draft.data.otCrossings, action.payload)) {
                draft.data.otCrossings = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_BUSINESS_DEVICES:
            draft.displayData.businessDevices = action.payload
            if (!isEqual(draft.data.businessDevices, action.payload)) {
                draft.data.businessDevices = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_BUSINESS_CROSSINGS:
            draft.displayData.businessCrossings = action.payload
            if (!isEqual(draft.data.businessCrossings, action.payload)) {
                draft.data.businessCrossings = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_EMAIL_HOSTS:
            draft.displayData.emailHosts = action.payload
            if (!isEqual(draft.data.emailHosts, action.payload)) {
                draft.data.emailHosts = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_HOST_LOG_EXCEPTIONS:
            draft.displayData.hostLogExceptions = action.payload
            if (!isEqual(draft.data.hostLogExceptions, action.payload)) {
                draft.data.hostLogExceptions = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_NETWORK_LOG_EXCEPTIONS:
            draft.displayData.networkLogExceptions = action.payload
            if (!isEqual(draft.data.networkLogExceptions, action.payload)) {
                draft.data.networkLogExceptions = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_USB_HOSTS:
            draft.displayData.usbHosts = action.payload
            if (!isEqual(draft.data.usbHosts, action.payload)) {
                draft.data.usbHosts = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_USB_DEVICES:
            draft.displayData.usbDevices = action.payload
            if (!isEqual(draft.data.usbDevices, action.payload)) {
                draft.data.usbDevices = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_ADMIN_LOGONS:
            draft.displayData.adminLogons = action.payload
            if (!isEqual(draft.data.adminLogons, action.payload)) {
                draft.data.adminLogons = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_REMOTE_ACCESS:
            draft.displayData.remoteAccess = action.payload
            if (!isEqual(draft.data.remoteAccess, action.payload)) {
                draft.data.remoteAccess = action.payload
                    .filter((e) => e.trim().length !== 0)
                    .map((e) => e.trim())
            }
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_ENCRYPTION_RATIO:
            draft.displayData.encryptionRatio = action.payload
            draft.data.encryptionRatio = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_MONITORING_COVERAGE:
            draft.displayData.monitoringCoverage = action.payload
            draft.data.monitoringCoverage = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_MAX_OS_AGE:
            draft.displayData.maxOSAge = action.payload
            draft.data.maxOSAge = action.payload.trim()
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.SET_CHANGE_MAX_SCORE:
            draft.data.maxScore = action.payload
            draft.isChanged = isEqual(draft.initialData, draft.data) ? false : true
            break
        case ActionType.CHANGES_SAVED:
            draft.initialData = draft.data
            draft.displayData = draft.initialData
            draft.isChanged = false
            draft.saveResultMessage = 'Saved successfully'
            break
        case ActionType.SET_SAVE_RESULT_MESSAGE:
            draft.saveResultMessage = action.payload
            break
        case ActionType.RESET_CHANGES:
            draft.data = draft.initialData
            draft.displayData = draft.initialData
            draft.isChanged = false
            draft.saveResultMessage = undefined
            break
        case ActionType.BULK_ASSIGN_CONFIG:
            if (!isEqual(draft.data, action.payload)) {
                draft.data = action.payload
                draft.displayData = action.payload
            }
            draft.isChanged = isEqual(draft.initialData, action.payload) ? false : true
            break
    }
    return draft
})
