import VESSEL from '../../../@assets/icons/ot-inventory-vessel.svg'
import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

interface LocationInfoProps {
    name: string
    code: string
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: ${spacing(2)};
`

const Image = styled.img`
    height: 42px;
    width: 42px;
    margin-right: ${spacing(1)};
`

const LocationName = styled.div`
    font-size: 20px;
    line-height: 27.24px;
    font-weight: 600;
`
const LocaionCode = styled.div`
    font-size: 16px;
    line-height: 21.79px;
    font-weight: 600;
`

export function LocationInfo({name, code}: LocationInfoProps): JSX.Element {
    return (
        <Container>
            <Image src={VESSEL} alt={name} />
            <div>
                <LocationName>{name}</LocationName>
                <LocaionCode>{code}</LocaionCode>
            </div>
        </Container>
    )
}
