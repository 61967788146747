export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET SELECTED PAGE',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED UNKNOWN ASSETS BETA CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    SWITCH_TO_CACHED_PAGE_DATA_ACTION = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SWITCH TO CACHED PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED UNKNOWN ASSETS BETA CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    SET_FILTER = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET FILTER',
    RECEIVE_ISOLATED_ASSETS_STATUS = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVE ISOLATED ASSETS STATUS',
    DISPLAY_FILTER_BAR = '[PAGED UNKNOWN ASSETS BETA CONTEXT] DISPLAY FILTER BAR',
    DISPLAY_NETWORK_ASSET_DETAILS_MODAL = '[PAGED UNKNOWN ASSETS BETA CONTEXT] DISPLAY NETWORK ASSET DETAILS MODAL',
    SWITCH_TO_CACHED_DATA_MODAL = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SWITCH TO CACHED DATA MODAL',
    CLOSE_NETWORK_ASSET_DETAILS_MODAL = '[PAGED UNKNOWN ASSETS BETA CONTEXT] CLOSE NETWORK ASSET DETAILS MODAL',
    RECEIVED_REQUESTED_NETWORK_ASSETS = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVED REQUESTED NETWORK ASSET',
    DISPLAY_ENABLE_MONITORING_MODAL = '[PAGED UNKNOWN ASSETS BETA CONTEXT] DISPLAY ENABLE MONITORING MODAL',
    CLOSE_ENABLE_MONITORING_MODAL = '[PAGED UNKNOWN ASSETS BETA CONTEXT] CLOSE ENABLE MONITORING MODAL',
    REQUEST_ASSET_VALUE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] REQUEST ASSET VALUE',
    RECEIVE_REQUESTED_ASSET_DATA = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVE REQUESTED ASSET DATA',
    SET_CURRENT_LOCATION_CODE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET CURRENT LOCATION CODE',
    SET_HOSTNAME_DATA = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET HOSTNAME DATA',
    SET_CHANGE_ASSET_VALUE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET CHANGE ASSET VALUE',
    SET_CHANGE_HOSTNAME = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET CHANGE HOSTNAME',
    SAVE_MONITORING_CHANGE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SAVE MONITORING CHANGE',
    CHANGE_SAVED = '[PAGED UNKNOWN ASSETS BETA CONTEXT] CHANGE SAVED',
    SET_SAVE_PROCESSING_STATUS = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET SAVE PROCESSING STATUS',
    RECEIVE_ALL_NETWORK_BEHAVIOURS = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVE ALL NETWORK BEHAVIOURS',
    RECEIVE_NETWORK_BEHAVIOURS_FOR_ASSET = '[PAGED UNKNOWN ASSETS BETA CONTEXT] RECEIVE NETWORK BEHAVIOURS FOR ASSET',
    SET_IS_INTERLINKING = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET IS INTERLINKING',
    TOGGLE_TRUST = '[PAGED UNKNOWN ASSETS BETA CONTEXT] TOGGLE TRUST',
    SET_ACTIVE_SAVED_FILTER_DROPDOWN = '[PAGED UNKNOWN ASSETS BETA CONTEXT] SET ACTIVE SAVED FILTER DROPDOWN',
    REPORT_CREATE = '[PAGED UNKNOWN ASSETS BETA CONTEXT] REPORT CREATE',
    SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[PAGED UNKNOWN ASSETS BETA CONTEXT] show report popup',
}
