import styled from 'styled-components'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {spacing} from '../../../../../../../theme/spacing'
import {SORT_DIRECTION_TYPE} from '../../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`

export const SortOrderFakeInput = styled.div`
    display: flex;
    height: 25px;
    border-radius: 3px;
    overflow: hidden;
    padding: 0;
    margin: 0 ${spacing(1)};
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.border};
`

export const SortValue = styled.div`
    padding: 0 ${spacing(2)};
    margin: 0;
    font-size: 13px;
    line-height: 23px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => props.theme.myVessels.filterBar.sortOrder.text};
`

export const Arrows = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 14px;
    font-size: 13px;
    line-height: 23px;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.background};
`

export const UpArrow = styled.span`
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.icon};
`

export const DownArrow = styled.span`
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.icon};
`

interface ArrowIconProps {
    direction: SORT_DIRECTION_TYPE
}
export const ArrowIcon = styled(Icon)<ArrowIconProps>`
    color: ${(props) => props.theme.myVessels.filterBar.sortOrder.direction};
    transition:
        transform 0.2s,
        color 0.2s;
    transition-timing-function: ease-in-out;
    transform: rotate(${(props) => (props.direction === 'desc' ? '180' : '0')}deg);
`
