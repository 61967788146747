import produce from 'immer'
import {LicenseState} from '../type/licenses-state'
import {AllActions} from './actions'
import {ActionType} from './action-type'

export const licenseReducer = produce((draft: LicenseState, action: AllActions) => {
    switch (action.type) {
        case ActionType.SET_NETWORK_ENFORCEMENT_END_DATE:
            draft.networkEnforcementEndDate = action.payload
            break
        case ActionType.TOGGLE_REMOVABLE_ENFORCEMENT:
            draft.removableEnforcementEnabled = action.payload
            break
        case ActionType.SET_REMOVABLE_ENFORCEMENT_MAX_VESSELS:
            draft.removableEnforcementMaxVessels = action.payload
            break
        case ActionType.SET_REMOVABLE_ENFORCEMENT_END_DATE:
            draft.removableEnforcementEndDate = action.payload
            break
        case ActionType.SET_NETWORK_ENFORCEMENT_MAX_VESSELS:
            draft.networkEnforcementMaxVessels = action.payload
            break
        case ActionType.TOGGLE_INCIDENTS_LICENSE:
            draft.incidentsPolicyEnabled = action.payload
            break
        case ActionType.INCIDENT_POLICY_END_DATE:
            draft.incidentsPolicyEndDate = action.payload
            break
        case ActionType.SAVE_BUTTON_ENABLED_STATE:
            draft.saveButtonEnabled = action.payload
            break
        case ActionType.TOGGLE_ONBOARD_ENGAGEMENT:
            draft.onboardEngagementEnabled = action.payload
            break
        case ActionType.SET_ONBOARD_ENGAGEMENT_END_DATE:
            draft.onboardEngagementEndDate = action.payload
            break
        case ActionType.TOGGLE_NETWORK_ENFORCEMENT:
            draft.networkEnforcementEnabled = action.payload
            break
    }
})
