import {TableHeaderRow} from './table-header-row'
import {NoDataMessageGrid} from './styled/no-data-table-row.styled'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {TableDataCell} from '../../common/table/table-data-cell.styled'
import {NoDataMessage} from '../../common/table/no-data-message.styled'
import {usePagedAlerts} from '../../../../contexts/alerts/use-paged-alerts'

function hasValue(value: string | undefined): boolean {
    return value != undefined && value.length > 0
}

function getMessage(fromDate: string | undefined, toDate: string | undefined): string {
    return hasValue(fromDate) || hasValue(toDate)
        ? 'For the selected period, there are no alerts for this asset'
        : 'There are no alerts for this asset'
}

export function NoData(): JSX.Element {
    const {fromDate, toDate} = usePagedAlerts()

    return (
        <>
            <TableHeaderRow />
            <NoDataMessageGrid>
                <TableDataRow gridRow={1} />
                <TableDataCell gridRow={1} gridColumn={3} centered>
                    <NoDataMessage id="alerts_no_data_message">
                        {getMessage(fromDate, toDate)}
                    </NoDataMessage>
                </TableDataCell>
            </NoDataMessageGrid>
        </>
    )
}
