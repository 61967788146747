import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {ResetFilterButton} from './reset-filter/reset-filter-button'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {NetworkFilter} from './network-filter/network-filter'
import {removeQueryParam} from '../../../../helpers/navigation'
import {NETWORK_ASSET_DETAILS} from '../helpers/data-helpers'
import {NetworkAssetStateFilter} from './network-asset-state-filter/network-asset-state-filter'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {networkAssetsFilterLoadingSelector} from '../../../../store/state/network-assets-filter/selectors'
import {PropertiesFilter} from './properties-filter/properties-filter'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {displayFilterBar, closeNetworkAssetDetailsModal} = usePagedUnknownAssetsBeta()
    const isInactive = useTypedSelector(isInactiveSelector)

    if (useTypedSelector(networkAssetsFilterLoadingSelector)) {
        return (
            <Styled.Loading>
                <LoadingSpinner />
            </Styled.Loading>
        )
    }
    return (
        <Styled.FilterBarWrapper width={width} id={`${PageType.NETWORK_ASSETS}_filter-bar`}>
            <div
                id={`${PageType.NETWORK_ASSETS}_filter-bar_close-icon`}
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(false)
                    removeQueryParam(NETWORK_ASSET_DETAILS)
                    closeNetworkAssetDetailsModal()
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styled.FilterBarStyled>
                <FilterBarHeader />
                <Styled.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.NETWORK_ASSETS} />
                </Styled.SavedFilterContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.NETWORK_ASSETS}_filter-bar_section-content-1`}
                >
                    <NetworkAssetStateFilter />
                </Styled.SectionContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.NETWORK_ASSETS}_filter-bar_section-content-2`}
                >
                    <NetworkFilter />
                </Styled.SectionContent>
                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.NETWORK_ASSETS}_filter-bar_section-content-3`}
                >
                    <PropertiesFilter />
                </Styled.SectionContent>
                <VesselFilters pageType={PageType.NETWORK_ASSETS} />
                <ResetFilterButton />
            </Styled.FilterBarStyled>
        </Styled.FilterBarWrapper>
    )
}
