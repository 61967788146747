import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {PlaceholderType} from '../../../../../../values/placeholder'
import {StyledLink} from './styled-link.styled'
import {Text} from './text.styled'
import {logAssetOverlayOpen} from '../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
interface DetailsButtonProps {
    uniqueId: string
    linkTo: string
    where: PlaceholderType
}

export function DetailsButton({linkTo, uniqueId, where}: DetailsButtonProps): JSX.Element {
    const dispatch = useDispatch()

    return (
        <StyledLink
            to={linkTo}
            id={`${where}-${uniqueId}_details_link`}
            onClick={() =>
                dispatch(
                    logAssetOverlayOpen(
                        AuditLogPageType.DASHBOARD,
                        AuditLogAuditType.DETAIL_PANEL_ACCESS,
                        uniqueId,
                    ),
                )
            }
        >
            <Icon glyph="Details" height={16} width={16} />
            <Text>Details</Text>
        </StyledLink>
    )
}
