import {
    EnforcementPolicy,
    policyForMasterOverrideEnum,
    policyForNewDevicesEnum,
} from './enforcement-policy'
import {EnforcementPolicyState} from './enforcement-policy-state'
import LoadingState from '../../../../../values/loading-state-enum'

export const emptyEnforcementPolicyDetail: EnforcementPolicy = {
    createdTimestamp: '',
    id: '',
    isDefault: false,
    locations: [],
    name: '',
    networkProtection: {isEnabled: false},
    updatedTimestamp: '',
    usbProtection: {
        isBlockAccessToOfflineAssetsEnabled: false,
        isEnabled: false,
        policyForMasterOverride: policyForMasterOverrideEnum.NEVER,
        policyForMasterOverrideDuration: null,
        policyForNewDevices: policyForNewDevicesEnum.ALWAYS_UNWANTED,
        isAllowAnyOnMasterEnabled: false,
        isUsbAvProtectionEnabled: false,
    },
}

export const DEFAULT_ENFORCEMENT_POLICY_STATE: EnforcementPolicyState = {
    loadingRequestedEnforcementDataState: LoadingState.NotPopulated,
    selectedPolicy: undefined,
    policyToCreateOrUpdate: emptyEnforcementPolicyDetail,
    isUpdated: false,
    discardChanges: false,
    allPolicies: [],
}
