import {StringUtils} from '../../../../../utils/Utils'
import {
    findMetricCategory,
    getAnalysisPeriodDisplayName,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import * as Styled from './_styled/score-bar.styled'
import {useState} from 'react'

interface ScoreBarProps {
    score: number
    metricType: MetricType
    displayGraph: boolean
}
export function ScoreBar({score, metricType, displayGraph}: ScoreBarProps): JSX.Element {
    const [visibleTippy, setVisibleTippy] = useState(false)
    return (
        <>
            {displayGraph && (
                <Styled.ScoreBarWrapper id={`scoreBar-Wrapper-${metricType}-${score}`}>
                    <Styled.ColorWrapper
                        metricType={metricType}
                        id={`color-Wrapper-${metricType}-${score}`}
                    >
                        <Styled.MetricIcon
                            src={getMetricTypeIcon(findMetricCategory(metricType))}
                            id={`metricTypeIcon-${metricType}-${score}`}
                        />
                        <Styled.Score id={`score-${metricType}-${score}`}>
                            {score}
                            <Styled.ScoreTextWrapper>
                                <div style={{fontSize: '16px'}}>%</div>
                            </Styled.ScoreTextWrapper>
                        </Styled.Score>
                    </Styled.ColorWrapper>
                    <Styled.ScoreBar
                        onMouseEnter={() => {
                            setVisibleTippy(true)
                        }}
                        onMouseLeave={() => {
                            setVisibleTippy(false)
                        }}
                    >
                        <Styled.ScoreBarNoIssue
                            score={score}
                            metricType={metricType}
                            id={`score-bar-no-issues-${metricType}-${score}`}
                        />
                        {visibleTippy && <TippyContent metricType={metricType} score={score} />}
                    </Styled.ScoreBar>
                </Styled.ScoreBarWrapper>
            )}
        </>
    )
}
interface TippyContentProps {
    metricType: MetricType
    score: number
}
function TippyContent({metricType, score}: TippyContentProps): JSX.Element {
    const {selectedAnalysisPeriod} = useMetricsWidget()
    return (
        <Styled.TippyWrapper positionOfText={metricType === MetricType.BEHAVIOUR}>
            <div style={{display: 'flex', gap: '5px', fontWeight: 'bold'}}>
                <img
                    src={getMetricTypeIcon(findMetricCategory(metricType))}
                    id={`metricTypeIconTippy-${metricType}-${score}`}
                    width={20}
                    height={20}
                />
                {StringUtils.capitaliseString(metricType)}
            </div>
            <div>Asset OK: {score}%</div>
            <div> {getAnalysisPeriodDisplayName(selectedAnalysisPeriod)}</div>
        </Styled.TippyWrapper>
    )
}
