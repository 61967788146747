import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
interface Props {
    gridLayout: GridLayout
}
export function NewIncidents({gridLayout}: Props): JSX.Element {
    const {newIncidents} = useIncidentsWidget()

    return (
        <ValueForType
            type="New"
            value={newIncidents}
            gridLayout={gridLayout}
            id="incident-response-headline-values-new"
        />
    )
}
