import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns} from '../../data-helpers'
interface TableHeaderGridProps {
    width: number
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    padding: 0 ${spacing(4)} ${spacing(2)} ${spacing(4)};
    background-color: ${(props) => props.theme.reports.dataArea.background};
`
