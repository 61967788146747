import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {showCards, USB_DEVICES_DETAILS} from './data-helpers'
import {USBDevicesCards} from './cards/usb-devices-cards'
import {USBDevicesTable} from './table/usb-devices-table'
import {DataActions} from './data-actions/data-actions'
import {ContentContainerBottom} from '../../../templates/fixed-page/content-container-bottom.styled'
import {USBDevicesPaging} from './footer/paging/usb-devices-paging'
import {RecordSet} from './footer/record-set/record-set'
import {ContentWrapper} from '../usb-devices.styled'
import {usePagedUSBDevices} from '../contexts/use-paged-usb-devices'
import {removeQueryParam} from '../../../helpers/navigation'
import {Footer} from '../../../components/table-view-components/footer.styled'

export function Content(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, setIdForUSBDetailsModal, modalIdForUSBDetails} = usePagedUSBDevices()
    const showCardsEarlier = showFilterBar || modalIdForUSBDetails !== null

    return (
        <ContentWrapper cardsView={showCards(width, showCardsEarlier)}>
            <DataActions />
            {showCards(width, showCardsEarlier) ? <USBDevicesCards /> : <USBDevicesTable />}
            <ContentContainerBottom width={width}>
                <Footer
                    width={width}
                    onClick={() => {
                        removeQueryParam(USB_DEVICES_DETAILS)
                        setIdForUSBDetailsModal(null)
                    }}
                    id="footer-row"
                >
                    <USBDevicesPaging />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </ContentWrapper>
    )
}
