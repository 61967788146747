import styled from 'styled-components'

interface TypesProps {
    width: number
}

export const Types = styled.div<TypesProps>`
    display: flex;
    flex-direction: ${(props) => (props.width <= 400 ? 'column' : 'row')};
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 10px 0 10px;
`
