import NetworkEnforcementTooltipIcon from '../../../../../@assets/icons/ic_outline-shield.svg'
import RemovablesTooltipIcon from '../../../../../@assets/icons/remoable-sec.svg'
import OnboardEngagementTooltipIcon from '../../../../../@assets/icons/new-vessel-icon-blk.svg'
import IncidentsPolicyTooltipIcon from '../../../../../@assets/icons/folder-iwarning-blk.svg'
import * as StyledShared from './license.styled'
import {LicenseKeysTypes, LicenseKeyType} from '../../../../../store/state/config/state'
import {LicenseSummeryFleetConfigDataItem} from '../../lincense-utils'
import {Policy} from '../../../reselectors/vessel-details.model'
import {EnforcementPolicy} from '../../../../policy-management/contexts/enforcement-policy/type/enforcement-policy'
import LicenseGreyIcon from '../../../../../@assets/icons/license-greey.svg'
import LicenseBlackIcon from '../../../../../@assets/icons/license.svg'
import LicenseGreenIcon from '../../../../../@assets/icons/license-green.svg'

export function getToolTipBox(
    text: string,
    key: LicenseKeyType<LicenseKeysTypes>,
    includeIcon = true,
): JSX.Element {
    let icon: string
    switch (key) {
        case 'License:Enforcement:Networks':
            icon = NetworkEnforcementTooltipIcon
            break
        case 'License:Enforcement:Removables':
            icon = RemovablesTooltipIcon
            break
        case 'License:Metrics:OnboardEngagement':
            icon = OnboardEngagementTooltipIcon
            break
        case 'License:Incidents':
            icon = IncidentsPolicyTooltipIcon
            break
    }
    return (
        <StyledShared.tooltipContainer>
            {includeIcon && (
                <span style={{marginRight: '10px'}}>
                    <img src={icon} />
                </span>
            )}
            <span>{text}</span>
        </StyledShared.tooltipContainer>
    )
}

export function getLicenseSummeryInfo(
    colLicense: LicenseSummeryFleetConfigDataItem,
    policy: Policy,
    allEnforcementPolicies: EnforcementPolicy[],
): [string, JSX.Element | null] {
    const greyLicenseNoMoreLicensesText = 'Insufficient licenses. Policy not applied.'

    const greenTuple: [string, JSX.Element] = [
        LicenseGreenIcon,
        getToolTipBox('Available licence to apply.', colLicense.key, false),
    ]
    const blackTuple: [string, null] = [LicenseBlackIcon, null]

    const noMoreLicensesLeftTuple: [string, JSX.Element] = [
        LicenseGreyIcon,
        getToolTipBox(greyLicenseNoMoreLicensesText, colLicense.key, false),
    ]

    if (!colLicense.enabled) {
        return [LicenseGreyIcon, getToolTipBox('Licences not available', colLicense.key, false)]
    } else if (colLicense.isExpired) {
        return [LicenseGreyIcon, getToolTipBox('Licences have expired', colLicense.key, false)]
    }
    const hasPolicy = !!policy.id

    if (colLicense.key === 'License:Incidents') {
        return blackTuple
    }

    if (colLicense.key === 'License:Metrics:OnboardEngagement') {
        if (!hasPolicy) {
            return greenTuple
        }
        return blackTuple
    }

    if (
        colLicense.key === 'License:Enforcement:Removables' ||
        (colLicense.key === 'License:Enforcement:Networks' && hasPolicy)
    ) {
        const hasLicensesLeft =
            (typeof colLicense.summeryData?.unusedLicenseCount === 'number' &&
                colLicense.summeryData.unusedLicenseCount > 0) ||
            colLicense.numberOfLicenses === null

        if (!hasPolicy) {
            if (hasLicensesLeft) {
                return greenTuple
            }
            return noMoreLicensesLeftTuple
        }
        const enforcementPolicy = allEnforcementPolicies.find((pol) => pol.id === policy.id)
        if (!enforcementPolicy) {
            if (hasLicensesLeft) {
                return greenTuple
            }
            return noMoreLicensesLeftTuple
        }
        const isNetworksKey = colLicense.key === 'License:Enforcement:Networks'
        const isRemovablesKey = colLicense.key === 'License:Enforcement:Removables'

        if (isNetworksKey || isRemovablesKey) {
            const enabledProtection = isNetworksKey
                ? enforcementPolicy.networkProtection.isEnabled
                : enforcementPolicy.usbProtection.isEnabled

            if (enabledProtection) {
                return blackTuple
            } else if (hasLicensesLeft) {
                return greenTuple
            }
            return noMoreLicensesLeftTuple
        }
    }

    return greenTuple
}
