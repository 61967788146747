import {Selected} from './selected.styled'
import {ClickableLink} from './clickable-link.styled'
import {logButtonClickFor} from '../../../../../../store/state/audit-log/action-creators'
import {useDispatch} from 'react-redux'
import {AuditLogPageType} from '../../../../../../store/state/audit-log/state'

interface LinkToGraphTypeProps {
    selected: boolean
    graphType: string
    text: string
}

export function LinkToGraphType({selected, graphType, text}: LinkToGraphTypeProps): JSX.Element {
    const dispatch = useDispatch()
    if (selected) {
        return <Selected id={`selected_risk_timeline_graph_period_${graphType}`}>{text}</Selected>
    }

    return (
        <ClickableLink
            to={`/dashboard?graph=${graphType}`}
            id={`selectable_risk_timeline_graph_period_${graphType}`}
            onClick={() =>
                dispatch(
                    logButtonClickFor(
                        `changeFleetTimelineGraphPeriod_${graphType}`,
                        AuditLogPageType.DASHBOARD,
                    ),
                )
            }
        >
            {text}
        </ClickableLink>
    )
}
