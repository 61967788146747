import {UnknownAssetsTableFormat} from './components/table/unknown-assets-table-format'
import {UnknownAssetsCardsFormat} from './components/cards/unknown-assets-cards-format'
import {Pagination} from './components/footer/paging/pagination'
import {RecordSet} from './components/footer/record-set/record-set'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {NETWORK_ASSET_DETAILS, showCards} from './components/helpers/data-helpers'
import {ContentWrapper} from './unkown-assets.styled'
import {ContentContainerBottom} from '../../templates/fixed-page/content-container-bottom.styled'
import {UnknownAssetsHeader} from './page-header/unknown-assets-header'
import {usePagedUnknownAssetsBeta} from './context/use-paged-unknown-assets'
import {removeQueryParam} from '../../helpers/navigation'
import {Footer} from '../../components/table-view-components/footer.styled'

export function UnknownAssetsContent(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, closeNetworkAssetDetailsModal, networkAssetModalId} =
        usePagedUnknownAssetsBeta()
    const showCardsEarlier = showFilterBar || networkAssetModalId !== null

    return (
        <ContentWrapper cardsView={showCards(width, showCardsEarlier)}>
            <UnknownAssetsHeader />
            {showCards(width, showCardsEarlier) ? (
                <UnknownAssetsCardsFormat />
            ) : (
                <UnknownAssetsTableFormat />
            )}
            <ContentContainerBottom width={width}>
                <Footer
                    width={width}
                    onClick={() => {
                        removeQueryParam(NETWORK_ASSET_DETAILS)
                        closeNetworkAssetDetailsModal()
                    }}
                    id="footer-row"
                >
                    <Pagination />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </ContentWrapper>
    )
}
