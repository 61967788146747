enum ActionType {
    ASSET_OPTIONS_REQUEST = '[OT ASSET OPTIONS] REQUEST',
    SET_ASSET_OPTIONS = '[OT ASSET OPTIONS] SET',
    CREATE_ASSET_OPTION_SUCCESS = '[OT ASSET OPTION] CREATE SUCCESS',
    CREATE_ASSET_OPTION_REQUEST = '[OT ASSET OPTION] CREATE REQUEST',
    SET_CREATE_ASSET_OPTION_ERROR = '[OT ASSET OPTION] SET CREATE ERROR',
    SET_CREATE_ASSET_OPTION_FORM_ERRORS = '[OT ASSET OPTION] SET CREATE FORM ERRORS',
    CREATE_ASSET_OPTION_RESET = '[OT ASSET OPTION] RESET FORM',
    ASSET_OPTIONS_FAILURE = '[OT ASSET OPTIONS] FAILURE',
}

export default ActionType
