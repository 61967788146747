export enum ActionType {
    REQUEST_POLICY_DATA_ACTION = '[INCIDENTS POLICY CONTEXT] REQUEST POLICY DATA ACTION',
    RECEIVED_REQUESTED_POLICY_DATA_ACTION = '[INCIDENTS POLICY CONTEXT] RECEIVED REQUESTED POLICY DATA ACTION',
    RECEIVED_RECOMMENDATION_PLAYBOOKS_TYPES_ACTION = '[INCIDENTS POLICY CONTEXT] RECEIVED RECOMMENDATION PLAYBOOKS TYPES ACTION',
    SET_CHANGE_POLICY_NAME = '[INCIDENTS POLICY CONTEXT] SET CHANGE POLICY NAME',
    SET_CHANGE_CC_VESSEL_EMAILS_TO = '[INCIDENTS POLICY CONTEXT] SET CHANGE CC VESSEL EMAILS TO',
    SET_CHANGE_REASSIGN_USER = '[INCIDENTS POLICY CONTEXT] SET CHANGE REASSIGN USER',
    SET_CHANGE_AUTO_ASSIGN_USER = '[INCIDENTS POLICY CONTEXT] SET CHANGE AUTO ASSIGN USER',
    ENABLE_AUTO_ASSIGN = '[INCIDENTS POLICY CONTEXT] ENABLE AUTO ASSIGN',
    SELECT_SEVERITY = '[INCIDENTS POLICY CONTEXT] SELECT SEVERITY',
    ENABLE_INCIDENT_REMINDER = '[INCIDENTS POLICY CONTEXT] ENABLE INCIDENT REMINDERS',
    SET_CHANGE_REMINDER_DURATION = '[INCIDENTS POLICY CONTEXT] SET CHANGE REMINDER DURATION',
    SET_CHANGE_ESCALATE_TO_USER = '[INCIDENTS POLICY CONTEXT] SET CHANGE ESCALATE TO USER',
    SET_DEFAULT_POLICY_VALUES = '[INCIDENTS POLICY CONTEXT] SET DEFAULT POLICY VALUES',
}
