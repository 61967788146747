import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const DropDownWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
`
export const InputFieldWrapper = styled.div`
    display: flex;
    background: ${(props) => props.theme.incidents.dataArea.historyBackground};
    width: 150px;
    border-radius: 2px;
    position: relative;
    padding: ${spacing(1)};
    align-items: center;
`
export const InputFieldText = styled.span`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    flex: 1;
`

export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`

export const Options = styled.div`
    background-color: ${(props) => props.theme.incidents.dataArea.historyBackground};
    width: 100%;
    border-radius: 2px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(1)} ${spacing(1)} 3px;
    z-index: 1;
    position: absolute;
    top: 18px;
    left: 0;
`
interface OptionProps {
    allValue: boolean
    lastElement: boolean
}
export const Option = styled.div<OptionProps>`
    ${smallFont()};
    padding: 2px;
    cursor: pointer;
    ${(props) =>
        props.allValue ? `border-top: 1px solid ${props.theme.colors.border.lowContrast};` : ''};
    ${(props) =>
        props.lastElement
            ? ''
            : `border-bottom: 1px solid ${props.theme.colors.border.lowContrast};`};
`
interface OptionValueProps {
    allValue: boolean
}
export const OptionValue = styled.span<OptionValueProps>`
    padding-left: 2px;
    ${(props) => (props.allValue ? 'margin-left: 7px;' : '')};
`
