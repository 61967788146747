import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CustomerReports} from '../../../../values/customer-reports'
import {StarRating} from '../shared/star-rating/star-rating'
import {CardDataCell} from './card-data-cell'
import {ReportsActionsCard} from './report-actions-card/report-actions-card'
import * as Styles from './_styled/report-card.styled'
import {ReportsAttachmentsCard} from './reports-attachments-card/reports-attachments-card'
import {ReportsDetailsCard} from './reports-details-card/reports-details-card'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {getLocationDescription} from '../data-helpers'
interface ReportCardProps {
    report: CustomerReports
}
export function ReportCard({report}: ReportCardProps): JSX.Element {
    const {width} = useDimensions()
    const {notesExpanded} = usePagedReports()
    const locationMap = useTypedSelector(locationMapSelector)
    const noteExpanded = notesExpanded.get(report.identity) === true

    return (
        <Styles.Card id={`reports-card_${report.identity}`}>
            <Styles.CardAreaTitle width={width}>
                <Styles.CardLocationTitleData
                    id={`reports-vessel_${report.identity}`}
                    gridArea="TITLE"
                >
                    {getLocationDescription(locationMap, report.location)}
                </Styles.CardLocationTitleData>
                <Styles.CardRatingTitleData
                    id={`reports-rating${report.identity}`}
                    gridArea="STARS"
                >
                    <StarRating rating={report.rating} />
                </Styles.CardRatingTitleData>
            </Styles.CardAreaTitle>
            <Styles.CardAreaContent width={width}>
                <CardDataCell
                    gridArea="REPORT-TYPE"
                    label="Report Type:"
                    report={report}
                    identifier="report-type"
                >
                    {report.reportType}
                </CardDataCell>
                <CardDataCell
                    gridArea="REPORT-ID"
                    label="Report ID:"
                    report={report}
                    identifier="report-ID"
                >
                    {report.reportCode}
                </CardDataCell>
                <CardDataCell
                    gridArea="PERIOD-FROM"
                    label="Period From:"
                    report={report}
                    identifier="period-from"
                >
                    {formatDateWithoutTime(report.periodStartDate)}
                </CardDataCell>
                <CardDataCell
                    gridArea="PERIOD-TO"
                    label="Period To:"
                    report={report}
                    identifier="period-to"
                >
                    {formatDateWithoutTime(report.periodEndDate)}
                </CardDataCell>
                <ReportsAttachmentsCard report={report} />
                <ReportsActionsCard report={report} />
            </Styles.CardAreaContent>
            {noteExpanded && <ReportsDetailsCard report={report} />}
        </Styles.Card>
    )
}
