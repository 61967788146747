import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedUniqueFlows} from '../../../contexts/unique-flows/use-paged-unique-flows'

export function UniqueFlowsPaging(): JSX.Element {
    const {selectedPage, totalNumberOfPages, selectPage, pageSize} = usePagedUniqueFlows()
    const {width} = useDimensions()

    if (
        selectedPage == undefined ||
        selectPage == null ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }

    return (
        <BasicPaging
            selectedPage={selectedPage}
            totalNumberOfPages={totalNumberOfPages}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, totalNumberOfPages)}
        />
    )
}
