import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {LocationIdType} from '../locations/state'
import {isEqual} from 'lodash'
import {VesselFilterReduxState} from '../vessel-filter/types/vessel-filter-state'
import {DEFAULT_VESSEL_FILTER_STATE} from '../vessel-filter/types/default-vessel-filter-state'
import {PossibleColumnsVesselBeta} from '../../../pages/vessels-beta/contexts/types/paged-vessels-beta-state'
import {DEFAULT_USER_PREFS, DEFAULT_VESSELS_BETA_FILTER, VesselsBetaFilterReduxState} from './state'
import {VesselsBetaFilterForUserPref} from '../../../values/user-preferences/vessels-beta-filter'
import {SortColumnType} from '../../../pages/vessels-beta/contexts/types/sort-column'
import {TimestampFilterType} from '../../../pages/vessels-beta/contexts/types/timestamp-filter'
import {Dispatch} from 'redux'
import {AxiosResponse} from 'axios'

export const USER_PREFS_URL = '/api/v1/userPreferences/vessels_beta'

export const fetchUserPrefsIfExist = (
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return async (dispatch, getState) => {
        dispatch(requestFilter())

        const currentFilter = getState().vesselsBetaFilter
        const vesselFilter = getState().vesselFilter
        try {
            const response = await REST.get(USER_PREFS_URL)

            if (isDefaultTagUser === true) {
                await handleDefaultTagUserCase(dispatch, response, currentFilter, vesselFilter)
            } else {
                await handleNonDefaultTagUserCase(dispatch, response, currentFilter, vesselFilter)
            }
        } catch (error) {
            await REST.put(USER_PREFS_URL, {
                ...DEFAULT_USER_PREFS,
                selectedColumns: [],
                sortColumn: {
                    orderBy: PossibleColumnsVesselBeta.CurrentVesselScore,
                    orderAscending: true,
                },
                analysisPeriod: '30d',
                locations: populateLocationsForFilterCriteria(vesselFilter.locations),
                searchVesselTagTerm: vesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
            })
        }
    }
}

const handleDefaultTagUserCase = async (
    dispatch: Dispatch,
    response: AxiosResponse,
    currentFilter: VesselsBetaFilterReduxState,
    vesselFilter: VesselFilterReduxState,
) => {
    try {
        const res = await REST.get(`${USER_PREFS_URL}.default_tag`)
        const selectedColumns: PossibleColumnsVesselBeta[] =
            res.data.value.selectedColumns ?? currentFilter.selectedColumns

        dispatch(
            receiveFilter({
                ...DEFAULT_USER_PREFS,
                selectedColumns,
                sortColumn: {
                    orderBy:
                        response.data.value.sortColumn?.orderBy ??
                        PossibleColumnsVesselBeta.CurrentVesselScore,
                    orderAscending: response.data.value.sortColumn?.orderAscending ?? true,
                },
                analysisPeriod: res.data.value.analysisPeriod ?? currentFilter.analysisPeriod,
                locations: populateLocations(res.data.value?.locations),
                searchVesselTagTerm: res.data.value.default_tag,
                searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
                searchVesselTerm: vesselFilter.searchVesselTerm,
            }),
        )

        await REST.put(USER_PREFS_URL, {
            ...DEFAULT_USER_PREFS,
            selectedColumns,
            sortColumn: {
                orderBy:
                    response.data.value.sortColumn?.orderBy ??
                    PossibleColumnsVesselBeta.CurrentVesselScore,
                orderAscending: response.data.value.sortColumn?.orderAscending ?? true,
            },
            analysisPeriod: res.data.value.analysisPeriod ?? currentFilter.analysisPeriod,
            locations: populateLocations(res.data.value?.locations),
            searchVesselTagTerm: res.data.value.default_tag,
        })

        await REST.delete(`${USER_PREFS_URL}.default_tag`)
    } catch (error) {
        dispatch(
            receiveFilter({
                ...DEFAULT_USER_PREFS,
                selectedColumns: currentFilter.selectedColumns,
                sortColumn: {
                    orderBy:
                        response.data.value.sortColumn?.orderBy ??
                        PossibleColumnsVesselBeta.CurrentVesselScore,
                    orderAscending: response.data.value.sortColumn?.orderAscending ?? true,
                },
                analysisPeriod: currentFilter.analysisPeriod,
                locations: populateLocations(response.data.value?.locations),
                searchVesselTagTerm: vesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
                searchVesselTerm: vesselFilter.searchVesselTerm,
            }),
        )
    }
}
const handleNonDefaultTagUserCase = async (
    dispatch: Dispatch,
    response: AxiosResponse,
    currentFilter: VesselsBetaFilterReduxState,
    vesselFilter: VesselFilterReduxState,
) => {
    const selectedColumns: PossibleColumnsVesselBeta[] =
        response.data.value.selectedColumns ?? currentFilter.selectedColumns

    dispatch(
        receiveFilter({
            ...DEFAULT_USER_PREFS,
            selectedColumns,
            sortColumn: {
                orderBy:
                    response.data.value.sortColumn?.orderBy ??
                    PossibleColumnsVesselBeta.CurrentVesselScore,
                orderAscending: response.data.value.sortColumn?.orderAscending ?? true,
            },
            analysisPeriod: response.data.value.analysisPeriod ?? currentFilter.analysisPeriod,
            locations: populateLocations(response.data.value?.locations),
            searchVesselTagTerm: vesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
            searchVesselTerm: vesselFilter.searchVesselTerm,
        }),
    )
}
function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

function receiveFilter(data: {
    selectedColumns: PossibleColumnsVesselBeta[]
    sortColumn: SortColumnType
    analysisPeriod: TimestampFilterType
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
}): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload: data,
    }
}

export function setSortColumn(
    sortColumn: SortColumnType,
): ThunkAction<void, AppState, Api, Actions.SetSortColumnAction> {
    return async (dispatch, getState) => {
        const currentFilter = getState().vesselsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SORT_COLUMN,
            payload: sortColumn,
        })
        await REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, sortColumn}, currentVesselFilter),
        })
    }
}

export function changeAnalysisPeriod(
    analysisPeriod: TimestampFilterType,
): ThunkAction<void, AppState, Api, Actions.SetAnslysisPeriodAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().vesselsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_ANALYSIS_PERIOD,
            payload: analysisPeriod,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, analysisPeriod}, currentVesselFilter),
        })
    }
}

export function setSelectedColumns(
    selectedColumnType: PossibleColumnsVesselBeta,
    selectedColumnNewValue: boolean,
): ThunkAction<void, AppState, Api, Actions.SetActiveColumnsAction> {
    return async (dispatch, getState) => {
        const currentFilter = getState().vesselsBetaFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_ACTIVE_COLUMNS,
            payload: {
                selectedColumnType,
                selectedColumnNewValue,
            },
        })
        const getActiveColumns: PossibleColumnsVesselBeta[] = selectedColumnNewValue
            ? [...currentFilter.selectedColumns, selectedColumnType]
            : currentFilter.selectedColumns.filter(
                  (selectedColumn) => selectedColumn !== selectedColumnType,
              )
        await REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(
                {...currentFilter, selectedColumns: getActiveColumns},
                currentVesselFilter,
            ),
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, Actions.ResetFilter> {
    return (dispatch) => {
        dispatch({type: ActionType.RESET_FILTER})
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(DEFAULT_VESSELS_BETA_FILTER, DEFAULT_VESSEL_FILTER_STATE),
        )
    }
}

function buildFilterPrefs(
    state: VesselsBetaFilterReduxState,
    vesselFilterState: VesselFilterReduxState,
): VesselsBetaFilterForUserPref {
    return {
        analysisPeriod: state.analysisPeriod,
        selectedColumns: state.selectedColumns,
        sortColumn: state.sortColumn,
        locations: populateLocationsForFilterCriteria(vesselFilterState.locations),
        searchVesselTagTerm: vesselFilterState.searchVesselTagTerm,
        searchVesselNameTerm: vesselFilterState.searchVesselNameTerm,
        searchVesselTerm: vesselFilterState.searchVesselTerm,
    }
}

function populateLocations(
    locations: string | Array<LocationIdType>,
): Set<LocationIdType> | undefined {
    try {
        if (!locations || isEqual(locations, {}) || locations === 'all-selected') {
            return undefined
        }

        if (locations === 'all-deselected') {
            return new Set<LocationIdType>()
        }

        return new Set<LocationIdType>(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export function populateLocationsForFilterCriteria(
    locations: Set<LocationIdType> | undefined,
): Array<LocationIdType> | undefined {
    try {
        if (!locations) {
            return undefined
        }
        return Array.from(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}
