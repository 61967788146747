import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {WarningMessageContainerTable} from './warning-message.styled'

export function WarningMessageTable(): JSX.Element {
    const {width} = useDimensions()

    return (
        <WarningMessageContainerTable width={width}>
            Vessel does not support incident automation
        </WarningMessageContainerTable>
    )
}
