import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    column-gap: ${spacing(1)};
    margin: ${spacing(2)} 0;
`

export const ToggleLabel = styled.div`
    ${mediumSmallFont()};
    width: 280px;
`
