export enum ActionType {
    REQUEST_POLICY_DATA_ACTION = '[ENFORCEMENT POLICY CONTEXT] REQUEST POLICY DATA ACTION',
    RECEIVED_REQUESTED_POLICY_DATA_ACTION = '[ENFORCEMENT POLICY CONTEXT] RECEIVED REQUESTED POLICY DATA ACTION',
    RECEIVED_ALL_POLICY_DATA_ACTION = '[ENFORCEMENT POLICY CONTEXT] RECEIVED ALL POLICY DATA ACTION',
    SET_CHANGE_POLICY_NAME = '[ENFORCEMENT POLICY CONTEXT] SET CHANGE POLICY NAME',
    CHANGE_NETWORK_PROTECTION = '[ENFORCEMENT POLICY CONTEXT] CHANGE NETWORK PROTECTION',
    CHANGE_USB_AV_PROTECTION = '[ENFORCEMENT POLICY CONTEXT] CHANGE USB AV PROTECTION',
    CHANGE_REMOVABLES_PROTECTION = '[ENFORCEMENT POLICY CONTEXT] CHANGE REMOVABLES PROTECTION',
    CHANGE_POLICY_FOR_NEW_DEVICES = '[ENFORCEMENT POLICY CONTEXT] CHANGE POLICY FOR NEW DEVICES',
    CHANGE_POLICY_FOR_MASTER_OVERRIDE = '[ENFORCEMENT POLICY CONTEXT] CHANGE POLICY FOR MASTER OVERRIDE',
    CHANGE_BLOCK_ALL_USB_FOR_OFFLINE_ASSETS = '[ENFORCEMENT POLICY CONTEXT] CHANGE BLOCK ALL USB FOR OFFLINE ASSETS',
    CHANGE_ALLOW_ANY_ON_MASTER = '[ENFORCEMENT POLICY CONTEXT] CHANGE ALLOW ANY ON MASTERS COMPUTER',
    SET_DEFAULT_POLICY_DATA = '[ENFORCEMENT POLICY CONTEXT] SET DEFAULT POLICY DATA',
    SET_DISCARD_CHANGES = '[ENFORCEMENT POLICY CONTEXT] SET DISCARD CHANGES',
    SET_POLICY_AFTER_UPDATE = '[ENFORCEMENT POLICY CONTEXT] SET POLICY AFTER UPDATE',
}
