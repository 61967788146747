import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface ContentWrapperProps {
    width: number
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    position: sticky;
    top: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const Base = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const DetailHeadingRow = styled(Base)`
    grid-column: 1 / span 1;
`

export const Panel = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(1)};
    align-items: center;
    ${smallFont()}
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`
