import {useContext} from 'react'
import {ProcessingStatus} from '../../../../../values/processing-save-state-enum'
import {LocationInventoryPageContext} from '../../../contexts/location-inventory-page-context'
import {ActionType} from '../../../contexts/state/asset-upload-modal/action-type'
import {REST} from '../../../../..'
import {AssetBulkFileStatus} from '../../../contexts/types/ot-types'
import axios from 'axios'

export function useFileUpload() {
    const {assetUploadModal, page} = useContext(LocationInventoryPageContext)
    const {state, dispatch} = assetUploadModal

    if (state === undefined || dispatch === undefined) {
        throw new Error('useFileUpload must be used within a LocationInventoryPageProvider')
    }

    function setFileValue(value: File | null): void {
        dispatch?.({
            type: ActionType.SET_FILE,
            payload: {
                file: value,
            },
        })
    }
    function setUploadStatus(value: ProcessingStatus): void {
        dispatch?.({
            type: ActionType.SET_UPLOADING_STATUS,
            payload: {
                status: value,
            },
        })
    }

    function setServerMessage(value: {type: 'error' | 'success' | null; message: string}): void {
        dispatch?.({
            type: ActionType.SET_SERVER_MESSAGE,
            payload: {
                type: value.type,
                message: value.message,
            },
        })
    }

    function uploadFile(file: File) {
        if (page.state.locationID === null) {
            // This should never happen
            throw new Error('Error,Location ID is not set')
        }
        setUploadStatus(ProcessingStatus.PROCESSING)

        const formData = new FormData()
        formData.append('file', file, file.name)
        formData.append('locationID', page.state.locationID)

        dispatch?.({
            type: ActionType.ADD_FILE,
            payload: {
                file: {
                    name: file.name,
                    status: AssetBulkFileStatus.UPLOADING,
                    locationID: page.state.locationID,
                    createdAt: new Date().toISOString(),
                },
            },
        })

        REST.post('/ot-inventory/api/v1/assetBulkFiles', formData)
            .then(() => {
                setUploadStatus(ProcessingStatus.PROCESSED)
                // set success server message
                dispatch?.({
                    type: ActionType.SET_SERVER_MESSAGE,
                    payload: {
                        type: 'success',
                        message:
                            'We are now processing your document to update your inventory data',
                    },
                })
                // Set latest file uploaded to success
                dispatch?.({
                    type: ActionType.SET_FILE_UPLOAD_SUCCESS,
                })
            })
            .catch((error) => {
                // Generic Error
                const errorMsg = 'An error occurred while uploading the file'

                if (!axios.isAxiosError(error) || !error.response) {
                    dispatch?.({
                        type: ActionType.SET_SERVER_MESSAGE,
                        payload: {
                            type: 'error',
                            message: errorMsg,
                        },
                    })
                    dispatch?.({
                        type: ActionType.SET_FILE_UPLOAD_ERROR,
                    })
                    setUploadStatus(ProcessingStatus.FAILED)
                    return
                }

                if (error.response?.status !== 201) {
                    // It can be undefined if status is 405, when file upload endpoint is not yet deployed
                    const errorMsgFromServer = error.response.data.message ?? errorMsg
                    dispatch?.({
                        type: ActionType.SET_SERVER_MESSAGE,
                        payload: {
                            type: 'error',
                            message: errorMsgFromServer,
                        },
                    })
                    dispatch?.({
                        type: ActionType.SET_FILE_UPLOAD_ERROR,
                    })
                }

                setUploadStatus(ProcessingStatus.FAILED)
            })
            .finally(() => {
                setFileValue(null)
            })
    }

    function fetchFiles(locationID: string) {
        dispatch?.({
            type: ActionType.FILES_REQUEST,
        })

        REST.get(`/ot-inventory/api/v1/assetBulkFiles?locationID=${locationID}`, {
            retry: 3,
            retryDelay: 1000,
        })
            .then((res) => {
                // Set latest file uploaded to success
                dispatch?.({
                    type: ActionType.FILES_REQUEST_SUCCESS,
                    payload: {
                        files: res.data,
                    },
                })
            })
            .catch((error) => {
                // Generic Error
                const errorMsg = 'An error occurred while getting the files'

                if (!axios.isAxiosError(error) || !error.response) {
                    dispatch?.({
                        type: ActionType.SET_SERVER_MESSAGE,
                        payload: {
                            type: 'error',
                            message: errorMsg,
                        },
                    })
                    dispatch?.({
                        type: ActionType.FILES_REQUEST_FAILURE,
                    })
                    return
                }

                if (error.response?.status !== 201) {
                    // It can be undefined if status is 405, when file upload endpoint is not yet deployed
                    const errorMsgFromServer = error.response.data.message ?? errorMsg
                    dispatch?.({
                        type: ActionType.SET_SERVER_MESSAGE,
                        payload: {
                            type: 'error',
                            message: errorMsgFromServer,
                        },
                    })

                    dispatch?.({
                        type: ActionType.FILES_REQUEST_FAILURE,
                    })
                }
            })
    }
    return {
        uploadingStatus: state.uploadingStatus,
        serverMessage: state.serverMessage,
        setServerMessage,
        setFileValue,
        setUploadingStatus: setUploadStatus,
        uploadFile,
        fetchFiles,
        fileList: state.files,
    }
}
