import * as Actions from './actions'
import * as uuid from 'uuid'
import ActionType from './action-type'
import {Api, QuerySpecification} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {QueryCancellation} from '../../../websocket/Queries'
import {LatestAssetData} from './types/latest-unknown-asset'
import {LocationIdType} from '../locations/state'
import {QUERY_PATH} from './state'

const QUERY_SCHEDULE = '15s'

function latestUnknownDataQuery(activeLocation: LocationIdType | null): QuerySpecification {
    return {
        path: QUERY_PATH.ASSET_AGGREGATION_ENDPOINT,
        localId: `${QUERY_PATH.ASSET_AGGREGATION_ENDPOINT}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
        params: {
            durations: ['24h', '168h', '720h'],
            location: activeLocation,
        },
    }
}

export function registerLatestUnknownDataQuery(
    activeLocation: LocationIdType | null,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestLatestUnknownData())

        const query: QuerySpecification = latestUnknownDataQuery(activeLocation)
        api.newQuery(query, (data) => {
            dispatch(receiveLatestUnknownData(data))
        })
        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

function requestLatestUnknownData(): Actions.RequestLatestUnknownData {
    return {
        type: ActionType.REQUEST_LATEST_UNKNOWN_ASSET_DATA,
    }
}

function receiveLatestUnknownData(payload: LatestAssetData): Actions.ReceiveLatestUnknownData {
    return {
        type: ActionType.RECEIVE_LATEST_UNKNOWN_ASSET_DATA,
        payload,
    }
}
