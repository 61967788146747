import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'

type wrapperProps = {
    disabled?: boolean
}

export const Wrapper = styled.div<wrapperProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const VolumeSliderContainer = styled.div`
    width: 170px;
    height: 5px;
    display: flex;
    align-items: center;
    background-color: #ccc;
    position: relative;
`

interface Props {
    volume: number
    seeking: boolean
}

const transition = 'height 0.15s 0s ease, width 0.15s 0s ease'
const colorAfter = '#E1E1E6'
const colorBefore = '#1f88e5'
export const VolumeSliderTrack = styled.input<Props>`
    cursor: pointer;
    background: linear-gradient(
        to right,
        ${colorBefore} 0%,
        ${colorBefore} ${(props) => props.volume}%,
        ${colorAfter} ${(props) => props.volume}%,
        ${colorAfter} 100%
    );
    border-radius: 8px;
    height: 5px;
    width: 100%;
    outline: none;
    padding: 0;
    -webkit-transition: ${transition};
    -moz-transition: ${transition};
    -o-transition: ${transition};
    transition: ${transition};
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        border: none;
        -webkit-appearance: none;
        width: ${(props) => (props.seeking ? 14 : 10)}px;
        height: ${(props) => (props.seeking ? 14 : 10)}px;
        cursor: pointer;
        background: ${colorBefore};
        border-radius: 50%;
    }

    &::-ms-thumb {
        border: none;
        height: ${(props) => (props.seeking ? 14 : 10)}px;
        width: ${(props) => (props.seeking ? 14 : 10)}px;
        border-radius: 50%;
        background: ${colorBefore};
        cursor: pointer;
    }

    &::-moz-range-thumb {
        border: none;
        height: ${(props) => (props.seeking ? 14 : 10)}px;
        width: ${(props) => (props.seeking ? 14 : 10)}px;
        border-radius: 50%;
        background: ${colorBefore};
        cursor: pointer;
    }
`

interface SubValueProps {
    volume: number
}

export const SubValue = styled.span<SubValueProps>`
    ${smallFont()};
    position: absolute;
    top: 10px;
    left: ${(props) => props.volume}%;
    transform: translateX(-50%);
`
