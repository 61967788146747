import {getHorizontalPagePadding} from '../../theme/helpers/page-layout'
import styled from 'styled-components'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'
import {spacing} from '../../theme/spacing'

interface WideContentAreaProps {
    width: number
    fullHeight: boolean
}

export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    padding: 0 ${(props) => getHorizontalPagePadding(props.width)};
    margin: 0 auto;
    width: 100%;
    ${(props) => (props.fullHeight ? 'height: 100%;' : '')}
`

interface ScrollableContentProps {
    flexGrow?: boolean
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
    padding: ${spacing(3)} 0;
    overflow-y: auto;
    ${(props) => (props.flexGrow ? 'flex: 1;' : '')}
    width: 100%;
`

interface WideContentHeaderAreaProps {
    width: number
}

export const WideContentHeaderArea = styled.div<WideContentHeaderAreaProps>`
    display: flex;
    justify-content: space-between;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    padding: 10px ${(props) => getHorizontalPagePadding(props.width)};
    margin-left: ${(props) => (props.width > MAX_SCREEN_WIDTH ? (props.width - 1640) / 2 : 0)}px;
`
