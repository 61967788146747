import moment from 'moment'
import {NumBerOfDaysDisplay} from './number-of-days-since-last-data.styled'

interface NumberOfDaysItemsProps {
    lastSeenTimestamp: string
    identifier: string
}
export function NumberOfDaysItems({
    lastSeenTimestamp,
    identifier,
}: NumberOfDaysItemsProps): JSX.Element {
    return (
        <NumBerOfDaysDisplay id={`number-of-days-items-${identifier}-${lastSeenTimestamp}`}>
            {moment(lastSeenTimestamp).fromNow() ?? 'Unknown'}
        </NumBerOfDaysDisplay>
    )
}
