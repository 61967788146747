import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import {TagFilter} from '../../../shared/tag-limit/tag-filter'
import {TagLimit} from '../../../shared/tag-limit/tag-limit'
import {Panel} from '../_styled/panel-section.styled'
import {DataInput} from '../data-input/data-input'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'

import * as Styled from './details-section.styled'

export function DetailsSection(): JSX.Element {
    const {
        activeUserDetails,
        initialUserDetails,
        displayUserDetails,
        changeUserName,
        changeUserEmail,
        detailExpanded,
    } = useUserManagement()

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.UserDetails} />
            {detailExpanded.detailsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <DataInput
                            label="Name"
                            initialValue={displayUserDetails.username}
                            setChange={changeUserName}
                            changed={activeUserDetails.username !== initialUserDetails.username}
                        />
                        <DataInput
                            label="Email"
                            initialValue={displayUserDetails.email}
                            setChange={changeUserEmail}
                            changed={activeUserDetails.email !== initialUserDetails.email}
                        />
                        <TagFilter
                            label="Tag"
                            changed={
                                activeUserDetails.assignedTag !== initialUserDetails.assignedTag
                            }
                        />
                        <TagLimit label="Lock" />
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
