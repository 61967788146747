import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {ClearButton} from '../../../../../components/page-filter/components/shared/clear-button/clear-button'
import {ViewAllRowWrapper} from './software-inventory-status-filter.styled'
import {toggleAllSoftwareStatuses} from '../../../../../store/state/software-inventory-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

interface Props {
    state: TriStateButtonState
    setState: (value: TriStateButtonState) => void
    showClearButton: boolean
}

export function ViewAllSoftwareStatusRow({state, setState, showClearButton}: Props): JSX.Element {
    return (
        <ViewAllRowWrapper>
            <TriStateButton
                id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_view-all`}
                label="View All"
                state={state}
                onClick={setState}
            />
            {showClearButton && (
                <ClearButton
                    clearFn={toggleAllSoftwareStatuses}
                    id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_clear-view-all`}
                />
            )}
        </ViewAllRowWrapper>
    )
}
