import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import * as Styled from './data-cell.styled'

interface DataCellProps {
    label: string
    gridArea?: string
    children: ReactNode | ReactNodeArray
}
export function DataCell({label, children, gridArea}: DataCellProps): JSX.Element {
    const formattedGridArea = gridArea || label.toLocaleUpperCase().replace(/ /g, '-')
    const {width} = useDimensions()
    return (
        <Styled.DataCell width={width} id={`enable-monitoring-DataCell_${label}`}>
            <Styled.Label gridArea={formattedGridArea} id={`enable-monitoring-Label_${label}`}>
                {label}
            </Styled.Label>
            <Styled.Value
                gridArea={`${formattedGridArea}-VALUE`}
                id={`enable-monitoring-Value_${formattedGridArea}`}
            >
                {children}
            </Styled.Value>
        </Styled.DataCell>
    )
}
