import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'

export const DropdownContentStyle = styled.div`
    background-color: ${(props) => props.theme.incidents.incidentStatusPopup.background};
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    margin: 0;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between
    min-height: 160px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 1;
`
export const Options = styled.ul`
    width: 100%;
    padding-inline-start: 0;
    list-style-type: none;
    max-height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    cursor: default;
    float: left;
`

export const Option = styled.li`
    padding: ${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(2)};
    flex-direction: row;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
`

export const Label = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: black;
`

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const CancelButton = styled.button`
    border: 1px solid ${(props) => props.theme.incidents.resetButton.background};
    background-color: white;
    color: ${(props) => props.theme.incidents.resetButton.background};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    padding: 0;
    margin-right: ${spacing(1)};
`

export const AddButton = styled.button`
    border: none;
    background-color: ${(props) => props.theme.incidents.resetButton.background};
    color: ${(props) => props.theme.incidents.resetButton.text};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    padding: 0;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    margin: ${spacing(1)};
    :disabled {
        opacity: 0.5;
        cursor: auto;
    }
`
export const CloseImage = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: -8px;
`
export const ErrorMessage = styled.h2`
    width: 240px;
    margin: 0;
    padding: ${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(2)};
    ${smallFont()};
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.text.error};
`
