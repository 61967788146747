import {PolicyType} from '../../contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {EnforcementPolicyDetailExpanded} from './enforcement-policy-detail-expanded/enforcement-policy-detail-expanded'
import {IncidentPolicyDetailExpanded} from './incident-policy-detail-expanded/incident-policy-detail-expanded'
import {MetricsPolicyDetailExpanded} from './metrics-policy-detail-expanded/metrics-policy-detail-expanded '

export function PolicyDetailsExpanded(): JSX.Element {
    const {selectedPolicyType} = usePolicyManagement()

    switch (selectedPolicyType) {
        case PolicyType.INCIDENT:
            return <IncidentPolicyDetailExpanded />
        case PolicyType.METRICS:
            return <MetricsPolicyDetailExpanded />
        case PolicyType.ENFORCEMENT:
            return <EnforcementPolicyDetailExpanded />
        default:
            return <div />
    }
}
