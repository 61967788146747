import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/usb-device-card.styled'
import {USBDevicesResponse} from '../../contexts/types/usb-devices-response'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import {getFormattedUSBDeviceData, getImageForUsbDevicesState} from '../data-helpers'
import {USBDeviceDetailsExpanded} from './usb-device-details-expanded/usb-device-details-expanded'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../store/state/audit-log/state'
import NEW_VESSEL_ICON from '../../../../@assets/icons/new-vessel-icon.svg'
import ASSOCIATED_ASSET_ICON from '../../../../@assets/icons/associated-asset-icon.svg'

interface USBDeviceCardProps {
    usbDevice: USBDevicesResponse
    onClickFn?: () => void
}

export function USBDeviceCard({usbDevice, onClickFn}: USBDeviceCardProps): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const {setIdForUSBDetailsModal, modalIdForUSBDetails, displayUsbModal} = usePagedUSBDevices()
    const nodes = useTypedSelector(nodesSelector)
    const formattedUSDeviceData = getFormattedUSBDeviceData(usbDevice, nodes)
    const showExpandedModal = modalIdForUSBDetails === usbDevice.identity

    function onClickWrapper(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation()
        if (onClickFn) {
            onClickFn()
        }
        setIdForUSBDetailsModal(formattedUSDeviceData.identity)
        displayUsbModal(formattedUSDeviceData.identity)
        dispatch(
            logDetailsOpen(
                AuditLogPageType.USB_INVENTORY,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                formattedUSDeviceData.identity,
            ),
        )
    }

    return (
        <Styled.UsbInventoryDetails
            activeUsb={showExpandedModal}
            id={`UsbInventoryDetails_${formattedUSDeviceData.identity}`}
        >
            <Styled.Card
                onClick={onClickWrapper}
                id={`data-card_${formattedUSDeviceData.identity}`}
            >
                <Styled.CardAreaTitle width={width}>
                    <Styled.CardVendorTitleData
                        gridArea="VENDOR-TITLE"
                        id={`usb-devices-CardVendorTitleData_${formattedUSDeviceData.identity}`}
                    >
                        <div id={`usb-devices-vendor_${formattedUSDeviceData.identity}`}>
                            <img
                                src={getImageForUsbDevicesState(formattedUSDeviceData.deviceStatus)}
                                style={{marginRight: '5px'}}
                            />
                            {formattedUSDeviceData.vendorName}
                        </div>
                    </Styled.CardVendorTitleData>
                    <Styled.CardProductTitleData
                        gridArea="PRODUCT-TITLE"
                        id={`usb-devices-CardProductTitleData_${formattedUSDeviceData.identity}`}
                    >
                        <div id={`usb-devices-productName_${formattedUSDeviceData.identity}`}>
                            {formattedUSDeviceData.productName}
                        </div>
                    </Styled.CardProductTitleData>
                </Styled.CardAreaTitle>
                <Styled.CardAreaContent
                    width={width}
                    id={`usb-devices-CardAreaContent_${formattedUSDeviceData.identity}`}
                >
                    <CardDataCell
                        gridArea="WHEN"
                        label="First Detected:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="first_detected"
                    >
                        {formattedUSDeviceData.firstSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="VOLUME-LABELS"
                        label="Volume labels:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="identityDevice"
                    >
                        {formattedUSDeviceData.volumeLabels}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="TYPE"
                        label="Type:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="type"
                    >
                        {formattedUSDeviceData.deviceType}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="LAST-ACTIVE"
                        label="Last Active:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="last-active"
                    >
                        {formattedUSDeviceData.lastSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="PURPOSE"
                        label="Purpose:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="purpose"
                    >
                        {formattedUSDeviceData.purpose ? (
                            <Styled.PurposeText
                                id={`usb-devices-PurposeText_${formattedUSDeviceData.identity}`}
                            >{`"${formattedUSDeviceData.purpose}"`}</Styled.PurposeText>
                        ) : (
                            <Styled.PurposeText
                                id={`usb-devices-PurposeText_${formattedUSDeviceData.identity}`}
                            >
                                No purpose set
                            </Styled.PurposeText>
                        )}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="CONNECTIONS"
                        label="Connections:"
                        formattedUSDeviceData={formattedUSDeviceData}
                        identifier="connections"
                    >
                        <Styled.ConnectionsWrapper>
                            <Styled.NumberText>
                                {formattedUSDeviceData.totalNumberOfVesselsPerItem}
                                <img src={NEW_VESSEL_ICON} style={{marginLeft: '5px'}} />
                            </Styled.NumberText>
                            <Styled.NumberText>
                                {formattedUSDeviceData.totalNumberOfHostsPerItem}
                                <img
                                    src={ASSOCIATED_ASSET_ICON}
                                    width={19}
                                    style={{marginLeft: '5px'}}
                                />
                            </Styled.NumberText>
                        </Styled.ConnectionsWrapper>
                    </CardDataCell>
                </Styled.CardAreaContent>
            </Styled.Card>
            {showExpandedModal && <USBDeviceDetailsExpanded />}
        </Styled.UsbInventoryDetails>
    )
}
