import _, {isEqual} from 'lodash'
import {AllPolicyData} from '../../../../../../policy-management/contexts/policy-management/type/policy-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import * as Styled from './policy-select.styled'
import {WarningMessage} from './warning-message'
import {Check} from 'react-feather'

interface PolicySelectProps {
    label: 'Incidents' | 'Metrics' | 'Automation' | 'Enforcement'
    active: boolean
    allPolicyData: AllPolicyData[]
    noVesselDashboard?: boolean
}
export function PolicySelect({
    label,
    active,
    allPolicyData,
    noVesselDashboard,
}: PolicySelectProps): JSX.Element {
    const {
        setSelectedMetricsPolicy,
        setSelectedIncidentPolicy,
        setSelectedEnforcementPolicy,
        selectedMetricsPolicy,
        selectedIncidentPolicy,
        selectedEnforcementPolicy,
        initialValue,
    } = useVesselManagement()

    const selectedPolicy = (() => {
        switch (label) {
            case 'Metrics':
                return {
                    policy: selectedMetricsPolicy,
                    initialValue: initialValue?.metricsPolicy?.id,
                }
            case 'Incidents':
                return {
                    policy: selectedIncidentPolicy,
                    initialValue: initialValue?.incidentPolicy?.id,
                }
            case 'Enforcement':
                return {
                    policy: selectedEnforcementPolicy,
                    initialValue: initialValue?.enforcementPolicy?.id,
                }
            default:
                return undefined
        }
    })()

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        if (label === 'Metrics') {
            setSelectedMetricsPolicy(event.target.value === '' ? undefined : event.target.value)
        }
        if (label === 'Incidents') {
            setSelectedIncidentPolicy(event.target.value === '' ? undefined : event.target.value)
        }
        if (label === 'Enforcement') {
            setSelectedEnforcementPolicy(event.target.value === '' ? undefined : event.target.value)
        } else {
            // automation
        }
    }

    const isChanged = active && !isEqual(selectedPolicy?.policy, selectedPolicy?.initialValue)
    const filteredArray = _.uniqBy(allPolicyData, 'identity')
    return (
        <>
            <Styled.Label id={`vessel`}>{label}</Styled.Label>
            <Styled.Value>
                <Styled.Input>
                    <Styled.Select
                        onChange={onChangeWrapper}
                        value={selectedPolicy?.policy}
                        disabled={!active}
                    >
                        {filteredArray?.map((value, index) => (
                            <option key={`${value.identity}-${index}`} value={value.identity}>
                                {value.policyName}
                            </option>
                        ))}
                    </Styled.Select>
                </Styled.Input>
                {isChanged && (
                    <Check color="green" width={19} height={19} style={{paddingLeft: '2px'}} />
                )}
            </Styled.Value>
            {noVesselDashboard && <WarningMessage />}
        </>
    )
}
