import styled from 'styled-components'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../theme/theme'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

interface ContentAreaProps {
    width: number
}

interface ContainerWrapperProps {
    width: number
    gridArea: string
}

export const ContentContainerMiddle = styled.div<{
    backgroundColor?: ColorType
}>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    align-self: center;
    z-index: 0;
`

export const ContentArea = styled.div<ContentAreaProps>`
    background-color: ${(props) => props.theme.incidents.dataArea.background};
`

export const Card = styled.div<ContentAreaProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)} ${spacing(12)};
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) => props.theme.incidents.dataRow.background};
`

export const FilterSection = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${spacing(2)};
`

export const FilterContainer = styled.div<ContainerWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    grid-area: ${(props) => props.gridArea};
`

export const StatusFilterContainer = styled(FilterContainer)`
    align-items: ${(props) => (props.width < 600 ? 'center' : 'flex-start')};
    width: 100%;
`

export function showCards(width: number): boolean {
    return width < 1260
}

export const FilterText = styled.h2`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    display: flex;
    align-items: center;
    gap: 15px;
`

export const FilterCard = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    color: ${(props) => props.theme.incidents.dataRow.text};
    justify-content: center;
`
