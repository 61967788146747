import {DetailsSection} from '../../policy-list-table/policy-details-modal/incident-policy-details/details-section/details-section'
import {ReminderSection} from '../../policy-list-table/policy-details-modal/incident-policy-details/reminder-section/reminder-section'
import {SaveButton} from '../../policy-list-table/policy-details-modal/incident-policy-details/save-button/save-button'
import {DetailsExpandedSection} from './incident-policy-detail-expanded.styled'

export function IncidentPolicyDetailExpanded(): JSX.Element {
    return (
        <DetailsExpandedSection>
            <DetailsSection />
            <ReminderSection />
            <SaveButton />
        </DetailsExpandedSection>
    )
}
