import {IncidentSeverityValue} from '../../../../values/incident-response-values'
import * as Styled from './severity-level.styled'
interface SeverityLevelValueeProps {
    id: string
    incidentSeverity: IncidentSeverityValue
}

export function SeverityLevelValue({id, incidentSeverity}: SeverityLevelValueeProps): JSX.Element {
    return (
        <Styled.Square
            incidentSeverity={incidentSeverity}
            id={`incident-severity-square_${incidentSeverity}-${id}`}
        >
            {incidentSeverity}
        </Styled.Square>
    )
}
