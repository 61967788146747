import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentSeverityValue} from '../../../../../values/incident-response-values'
import {Square} from './severity-level.styled'
import {Label, Option, Options, PopoverContentStyle} from './severity-level-popover.styles'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {useDispatch} from 'react-redux'

interface Props {
    id: GuidType
    availableSeverities: IncidentSeverityValue[]
}

export function SeverityLevelPopover({id, availableSeverities}: Props): JSX.Element {
    const {width} = useDimensions()

    const {changeIncidentResponseSeverity} = usePagedIncidents()
    const dispatch = useDispatch()

    function updateIncidentSeverity(id: string, severity: string): void {
        changeIncidentResponseSeverity(id, severity, dispatch)
    }

    return (
        <PopoverContentStyle width={width}>
            <Options width={width}>
                {availableSeverities.map((severity) => (
                    <Option key={severity}>
                        <Label
                            htmlFor={`update-button_${severity}`}
                            id={`update-label_${severity}`}
                        >
                            <Square
                                incidentSeverity={severity}
                                id={`update-severity_${severity}-${id}`}
                                onClick={() => updateIncidentSeverity(id, severity)}
                            >
                                {severity}
                            </Square>
                        </Label>
                    </Option>
                ))}
            </Options>
        </PopoverContentStyle>
    )
}
