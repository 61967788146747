import {SoftwareStatus} from '../../../../../store/state/software-inventory-filter/state'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import * as Styled from './actions-content.styled'
import DOWNLOAD_ICON from '../../../../../@assets/icons/download-icon.svg'
import {getFormattedSoftwareInventoryData} from '../../data-helpers'
import {logDownloadFile} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, DownloadFileType} from '../../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {openModal} from '../../../../../store/state/extended-data-request/action-creators'
import {InventoryType} from '../../../../../store/state/extended-data-request/state'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import MAIL_ICON from '../../../../../@assets/icons/mail-icon.svg'
import {
    SoftwareItemData,
    getSoftwareItemName,
} from '../../../../../components/contact-soc-modal/helper'
import {getVesselForGivenAsset} from '../../../contexts/context-helper'
import {chain} from 'lodash'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {isUserAllowed} from '../../../../../values/Role'

export function ActionsContents(): JSX.Element {
    const {showChangeSoftwareStatusModal, findSoftwareDetailsForModal, downloadSoftwareReport} =
        usePagedSoftwareInventory()
    const status = findSoftwareDetailsForModal?.status ?? SoftwareStatus.unknown
    const onClickWrapper = (status: SoftwareStatus): void => {
        showChangeSoftwareStatusModal(true, status)
    }
    const dispatch = useDispatch()
    const nodes = useTypedSelector(nodesSelector)
    const {roles} = useTypedSelector(currentUserSelector)
    const isAdminRole = isUserAllowed(roles, ['CUSTOMER_ADMIN', 'DEVELOPER', 'THREAT_ANALYST'])

    const deviceName = getSoftwareItemName({
        company: findSoftwareDetailsForModal?.company,
        product: findSoftwareDetailsForModal?.product,
    } as SoftwareItemData)

    const nodeFirstDetectedLocation = getVesselForGivenAsset(
        findSoftwareDetailsForModal?.firstDetected,
        nodes,
    )?.location

    const nodeMostRecentLocation = getVesselForGivenAsset(
        findSoftwareDetailsForModal?.lastDetected,
        nodes,
    )?.location

    const nodesOldestVersionLocation = getVesselForGivenAsset(
        findSoftwareDetailsForModal?.oldestVersion,
        nodes,
    )?.location

    const locationIds = chain([
        nodeFirstDetectedLocation,
        nodeMostRecentLocation,
        nodesOldestVersionLocation,
    ])
        .compact()
        .uniq()
        .value()

    const formattedSoftwareDetails = getFormattedSoftwareInventoryData(findSoftwareDetailsForModal)

    return (
        <Styled.DetailsLayout id="software-inventory-details-modal_Actions_wrapper">
            {isAdminRole && (
                <>
                    <Styled.TextLink
                        disabled={status === SoftwareStatus.trusted}
                        onClick={() => onClickWrapper(SoftwareStatus.trusted)}
                        id="software-inventory-TextLink-trusted"
                    >
                        Change status to Trusted
                    </Styled.TextLink>
                    <Styled.TextLink
                        disabled={status === SoftwareStatus.unwanted}
                        onClick={() => onClickWrapper(SoftwareStatus.unwanted)}
                        id="software-inventory-TextLink-unwanted"
                    >
                        Change status to Unwanted
                    </Styled.TextLink>
                    <Styled.TextLink
                        disabled={status === SoftwareStatus.unknown}
                        onClick={() => onClickWrapper(SoftwareStatus.unknown)}
                        id="software-inventory-TextLink-unknown"
                    >
                        Change status to Unknown
                    </Styled.TextLink>
                </>
            )}

            <Styled.Label>
                Download report:
                <Styled.TextLink
                    onClick={() => {
                        downloadSoftwareReport(
                            formattedSoftwareDetails.identity,
                            formattedSoftwareDetails.productName,
                        )
                        dispatch(
                            logDownloadFile(
                                AuditLogPageType.SOFTWARE_INVENTORY,
                                formattedSoftwareDetails.identity,
                                DownloadFileType.CSV,
                            ),
                        )
                    }}
                    id="software-inventory-downloadSoftwareReport"
                >
                    <img src={DOWNLOAD_ICON} style={{marginRight: '5px'}} />
                    Download here
                </Styled.TextLink>
            </Styled.Label>
            <Styled.ContactSOCAreaWrapper>
                <Styled.Label>
                    Get help with this asset:
                    <Styled.TextLink
                        onClick={() =>
                            dispatch(openModal(InventoryType.SOFTWARE, deviceName, locationIds))
                        }
                        id="get-help-contact-soc"
                    >
                        <img src={MAIL_ICON} style={{marginRight: '5px'}} />
                        Contact SOC
                    </Styled.TextLink>
                </Styled.Label>
            </Styled.ContactSOCAreaWrapper>
        </Styled.DetailsLayout>
    )
}
