import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {VesselPerformanceWidget} from '../../../../incidents-report/components/incidents-response-performance/vessel-performance-widget'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import * as Styled from './widget-commonstyling.styled'

export function VesselsPerformanceWidget(): JSX.Element {
    const {loadedIncidentsByVesselPerformance, incidentsByVesselPerformance} = useIncidentsWidget()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>VESSELS PERFORMANCE</Styled.SectionText>
            {loadedIncidentsByVesselPerformance ? (
                <VesselPerformanceWidget
                    incidentsByVesselPerformance={incidentsByVesselPerformance}
                />
            ) : (
                <DataLoading />
            )}
        </Styled.StatusFilterContainer>
    )
}
