import {PropsWithChildren} from 'react'
import styled from 'styled-components'
import {Colors} from '../../theme/colours'
import {ButtonHTMLAttributes} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'solid' | 'outlined' | 'icon'
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

// TODO: Change once design system has specific color
const DISABLED_COLOR = '#E2E5E9'

// TODO: Change sizing based on design system once we have it
const StyledButton = styled.button<ButtonProps>`
    background-color: ${(props) => {
        if (props.disabled) {
            return DISABLED_COLOR
        } else if (props.variant === 'solid') {
            // In design system it is this color
            return Colors.blue2
        } else if (props.variant === 'outlined' || props.variant === 'icon') {
            return 'inherit'
        }
        // This should not happen cause we already set default in the component
        else {
            throw new Error('Undefined button variant')
        }
    }};

    color: ${(props) => {
        if (props.variant === 'solid' || props.disabled) {
            return Colors.white
        } else if (props.variant === 'outlined' || props.variant === 'icon') {
            return Colors.blue2
        } else {
            throw new Error('Undefined button variant')
        }
    }};

    border: ${(props) => {
        if (props.disabled) {
            return 'none'
        } else if (props.variant === 'solid' || props.variant === 'outlined') {
            return `2px solid ${Colors.blue2}`
        } else if (props.variant === 'icon') {
            return 'none'
        }
        throw new Error('Undefined button variant')
    }}};

    border-radius: 4px;


    padding-inline: ${(props) => {
        if (props.size === 'lg') {
            return '14px'
        } else if (props.size === 'md') {
            return '12px'
        } else if (props.size === 'sm') {
            return '10px'
        } else if (props.size === 'xs') {
            return '8px'
        }
        throw new Error('Undefined button size')
    }};
    padding-block: ${(props) => {
        if (props.size === 'lg') {
            return '11.5px'
        } else if (props.size === 'md') {
            return '10.5px'
        } else if (props.size === 'sm') {
            return '9.5px'
        } else if (props.size === 'xs') {
            return '8.5px'
        }
        throw new Error('Undefined button size')
    }};
    font-size: 14px;

    // Stole from tailwind
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
        background-color: ${(props) => {
            if (props.disabled) {
                return DISABLED_COLOR
            } else if (props.variant === 'solid') {
                return Colors.blue3
            } else if (props.variant === 'outlined' || props.variant === 'icon') {
                return Colors.blue1
            } else {
                throw new Error('Undefined button variant')
            }
        }};
    }
`

export function Button(props: PropsWithChildren<ButtonProps>) {
    // Assign default value if not given
    const {variant = 'solid', size = 'md', ...restOfProps} = props

    const _props = {
        variant,
        size,
        ...restOfProps,
    }

    return <StyledButton {..._props}>{props.children}</StyledButton>
}
