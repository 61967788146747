import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.myVessels.cards.background};
    width: 100%;
    height: 48px;
    border-radius: 6px;
    box-shadow: 0 1px 2px ${(props) => props.theme.myVessels.cards.boxShadow};
`
