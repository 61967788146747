import {GuidType} from '../../../../values/generic-type-defintions'
import {NodeValue} from '../../../../values/nodes/NodeData'
import {AssetDataType} from '../types/asset-data-type'
import {Anomaly} from '../types/formatted-anomaly'
import {IsolatedAssetsType} from '../types/isolated-assets-type'
import {ProcessingStatus} from '../types/paged-unknown-assets-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {NetworkAssetBehaviour} from '../types/network-asset-behaviour'
import {NetworkAssetBehaviourData} from '../types/network-asset-behaviour-data'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function switchToCachedPageData(requestedPage: number): Actions.SwitchToCachedPagedAction {
    return {type: ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION, payload: requestedPage}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function receivedRequestedPageData(
    items: Anomaly[],
    totalNumberOfItems: number,
    totalNumberOfPages: number,
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {items, totalNumberOfItems, totalNumberOfPages},
    }
}

export function receiveAllBehaviours(
    networkBehaviours: NetworkAssetBehaviour[],
): Actions.ReceiveAllBehavioursAction {
    return {
        type: ActionType.RECEIVE_ALL_NETWORK_BEHAVIOURS,
        payload: networkBehaviours,
    }
}

export function showGenerateReportPopup(show: boolean): Actions.ShowGenerateReportPopup {
    return {
        type: ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP,
        payload: show,
    }
}

export function receiveBehavioursForAsset(
    networkBehaviourData: NetworkAssetBehaviourData[],
): Actions.ReceiveBehavioursForAssetAction {
    return {
        type: ActionType.RECEIVE_NETWORK_BEHAVIOURS_FOR_ASSET,
        payload: networkBehaviourData,
    }
}

export function setFilter(): Actions.SetFilterAction {
    return {
        type: ActionType.SET_FILTER,
    }
}

export function receivedIsolatedAssetsStatusData(
    items: IsolatedAssetsType[],
): Actions.ReceiveIsolatedAssetsStatusAction {
    return {
        type: ActionType.RECEIVE_ISOLATED_ASSETS_STATUS,
        payload: items,
    }
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function displayNetworkAssetDetailsModal(
    networkAssetModalId: GuidType,
): Actions.DisplayNetworkAssetDetailsModalAction {
    return {
        type: ActionType.DISPLAY_NETWORK_ASSET_DETAILS_MODAL,
        payload: networkAssetModalId,
    }
}
export function closeNetworkAssetDetailsModal(): Actions.CloseNetworkAssetDetailsModalAction {
    return {
        type: ActionType.CLOSE_NETWORK_ASSET_DETAILS_MODAL,
    }
}
export function getNetworkAssetModalCashData(): Actions.SwitchToCashDataModalAction {
    return {type: ActionType.SWITCH_TO_CACHED_DATA_MODAL}
}

export function setFindNetworkAsset(
    findNetworkAsset: Anomaly | undefined,
): Actions.ReceivedRequestedNetworkAsset {
    return {
        type: ActionType.RECEIVED_REQUESTED_NETWORK_ASSETS,
        payload: findNetworkAsset,
    }
}

export function DisplayEnableMonitoringModal(
    value: boolean,
): Actions.DisplayEnableMonitoringModalAction {
    return {
        type: ActionType.DISPLAY_ENABLE_MONITORING_MODAL,
        payload: value,
    }
}

export function CloseEnableMonitoringModal(): Actions.CloseEnableMonitoringModalAction {
    return {
        type: ActionType.CLOSE_ENABLE_MONITORING_MODAL,
    }
}

export function requestAssetValue(identity: GuidType): Actions.RequestAssetValueAction {
    return {type: ActionType.REQUEST_ASSET_VALUE, payload: identity}
}

export function receivedRequestedAssetData(
    value: AssetDataType,
): Actions.ReceivedRequestedAssetDataAction {
    return {
        type: ActionType.RECEIVE_REQUESTED_ASSET_DATA,
        payload: value,
    }
}

export function SetCurretLocationCode(value: string): Actions.SetCurretLocationCodeAction {
    return {type: ActionType.SET_CURRENT_LOCATION_CODE, payload: value}
}

export function setHostnameData(value: string): Actions.SetHostnameDataAction {
    return {type: ActionType.SET_HOSTNAME_DATA, payload: value}
}

export function setChangeHostname(value: string): Actions.SetChangeHostnameAction {
    return {type: ActionType.SET_CHANGE_HOSTNAME, payload: value}
}

export function setChangeAssetValue(value: NodeValue): Actions.SetChangeAssetValueAction {
    return {type: ActionType.SET_CHANGE_ASSET_VALUE, payload: value}
}

export function SetSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_PROCESSING_STATUS,
        payload: value,
    }
}

export function setIsInterlinking(value: boolean): Actions.SetIsInterlinkingAction {
    return {
        type: ActionType.SET_IS_INTERLINKING,
        payload: value,
    }
}

export function toggleTrust(id: GuidType, loading: boolean): Actions.ToggleTrustAction {
    return {
        type: ActionType.TOGGLE_TRUST,
        payload: {id, loading},
    }
}

export function setSavedFilterDropdown(value: boolean): Actions.SetActiveSavedFilterDropdownAction {
    return {
        type: ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN,
        payload: value,
    }
}

export function setReportCreate(payload: string): Actions.ReportCreateAction {
    return {
        type: ActionType.REPORT_CREATE,
        payload,
    }
}
