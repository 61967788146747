import {SwaggerListItem} from '../swagger-list-item/swagger-list-item'
import {useEffect, useState} from 'react'
import {SwaggerLinksWrapper} from './swagger-link-area.styled'

export function SwaggerLinkArea(): JSX.Element {
    const [link, setLink] = useState<string | undefined>(undefined)
    const swaggerSuffix = '/swagger/index.html'

    useEffect(() => {
        async function fetchSwaggerLinks() {
            try {
                const result = await fetch(
                    `${process.env.PUBLIC_URL}/config/${
                        process.env.REACT_APP_ALT_BACKEND ?? 'medulla-ui'
                    }.json`,
                )
                const response = await result.json()
                setLink(`${response.baseUrl}`)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }
        fetchSwaggerLinks()
    }, [])

    return (
        <SwaggerLinksWrapper>
            <SwaggerListItem
                id="active-control-link"
                appName="Active Control"
                swaggerLink={link + '/active-control' + swaggerSuffix}
            />
            <SwaggerListItem
                id="aimee-link"
                appName="Aimee"
                swaggerLink={link + '/aimee' + swaggerSuffix}
            />
            <SwaggerListItem
                id="audit-module-link"
                appName="Audit Module"
                swaggerLink={link + '/audit' + swaggerSuffix}
            />
            <SwaggerListItem
                id="basal-ganglia-link"
                appName="Basal Ganglia"
                swaggerLink={link + '/swagger-ui.html'}
            />
            <SwaggerListItem
                id="Cloud-Message Synchronizer-link"
                appName="Cloud Message Synchronizer"
                swaggerLink={link + '/cms' + swaggerSuffix}
            />
            <SwaggerListItem
                id="Crew behaviours-link"
                appName="Crew behaviours"
                swaggerLink={link + '/crew-behaviours' + swaggerSuffix}
            />
            <SwaggerListItem
                id="File-Inventory-service-link"
                appName="File Inventory service"
                swaggerLink={link + '/fis' + swaggerSuffix}
            />
            <SwaggerListItem
                id="medulla-metrics-link"
                appName="Medulla Metrics"
                swaggerLink={link + '/metrics' + swaggerSuffix}
            />
            <SwaggerListItem
                id="network-asset-aggregations-link"
                appName="Network Asset Aggregation"
                swaggerLink={link + '/network-asset-aggregation' + swaggerSuffix}
            />
            <SwaggerListItem
                id="network-behaviour-link"
                appName="Network Behaviour"
                swaggerLink={link + '/network-behaviour' + swaggerSuffix}
            />
            <SwaggerListItem
                id="NMEA-Inventory-link"
                appName="NMEA Inventory"
                swaggerLink={link + '/nmea' + swaggerSuffix}
            />
            <SwaggerListItem
                id="OT-Inventory-link"
                appName="OT Inventory"
                swaggerLink={link + '/ot-inventory' + swaggerSuffix}
            />
            <SwaggerListItem
                id="search-definitions-link"
                appName="Search Definitions"
                swaggerLink={link + '/search-definitions' + swaggerSuffix}
            />
            <SwaggerListItem
                id="software-inventory-link"
                appName="Software Inventory"
                swaggerLink={link + '/software-inventory' + swaggerSuffix}
            />
            <SwaggerListItem
                id="usb-inventory-link"
                appName="USB Inventory"
                swaggerLink={link + '/usb-inventory' + swaggerSuffix}
            />
            <SwaggerListItem
                id="VCMM-link"
                appName="VCMM"
                swaggerLink={link + '/vcmm' + swaggerSuffix}
            />
        </SwaggerLinksWrapper>
    )
}
