import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../../store/state/software-inventory-filter/selectors'
import {
    FilterBarType,
    FilterExpandedType,
} from '../../../../../../store/state/software-inventory-filter/state'
import {toggleFilterExpanded} from '../../../../../../store/state/software-inventory-filter/action-creators'
import {
    Button,
    FilterHeadingRow,
    HeadingWrapper,
    IconWrapper,
} from './expand-filter-details-button.styled'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

interface ExpandFilterDetailsButtonProps {
    headingRow: FilterBarType
}

export function ExpandFilterDetailsButton({
    headingRow,
}: ExpandFilterDetailsButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded} = useTypedSelector(softwareInventoryFilterSelector)
    const isExpanded = getCurrentFilterExpanded(headingRow, filterExpanded)
    function setNewFilterExpanded() {
        const newFilterExpanded: FilterExpandedType = getNewFilterExpanded(
            headingRow,
            filterExpanded,
        )
        dispatch(toggleFilterExpanded(newFilterExpanded))
    }

    return (
        <HeadingWrapper>
            <FilterHeadingRow id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_expand-heading-row`}>
                {headingRow}
            </FilterHeadingRow>
            <Button
                onClick={() => setNewFilterExpanded()}
                id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_expand-button`}
            >
                <IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </IconWrapper>
            </Button>
        </HeadingWrapper>
    )
}

const getNewFilterExpanded = (
    toggledFilter: FilterBarType,
    currentFilterExpanded: FilterExpandedType,
): FilterExpandedType => {
    switch (toggledFilter) {
        case FilterBarType.softwareInventoryStatus:
            return {
                ...currentFilterExpanded,
                softwareStatuses: !currentFilterExpanded.softwareStatuses,
            }
        default:
            return currentFilterExpanded
    }
}

const getCurrentFilterExpanded = (
    toggledFilter: FilterBarType,
    currentFilterExpanded: FilterExpandedType,
): boolean => {
    switch (toggledFilter) {
        case FilterBarType.softwareInventoryStatus:
            return currentFilterExpanded.softwareStatuses
        default:
            return false
    }
}
