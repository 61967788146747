import {Link} from 'react-router-dom'
import * as Styled from './vessel-not-found.styled'

export function VesselNotFound(): JSX.Element {
    return (
        <Styled.NoDataLayout>
            <Styled.NoDataMessage>Vessel can not be found</Styled.NoDataMessage>
            <Link to={'/vessels'}>
                <Styled.BackButton>Back</Styled.BackButton>
            </Link>
        </Styled.NoDataLayout>
    )
}
