import LoadingState from '../../../../../values/loading-state-enum'
import {PagedIndicatorsState} from './paged-indicators-state'

export const DEFAULT_PAGED_INDICATORS_STATE: PagedIndicatorsState = {
    assetId: undefined,
    loading: LoadingState.NotPopulated,
    error: '',
    dataPages: undefined,
    totalNumberOfIndicators: undefined,
    totalNumberOfPages: undefined,
    selectedPage: undefined,
    pageSize: 10,
    toDate: undefined,
    fromDate: undefined,
} as PagedIndicatorsState
