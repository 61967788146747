import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {TableHeader} from './table-header'
import {TableHeaderGrid} from './table-header-grid.styled'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, modalIdForUSBDetails} = usePagedUSBDevices()

    return (
        <TableHeaderGrid
            width={width}
            showReducedGrid={showFilterBar || modalIdForUSBDetails !== null}
            id="table-header-grid"
        >
            <TableHeader />
        </TableHeaderGrid>
    )
}
