import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './card-data-cell.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface CardDataCellProps {
    label: string
    gridArea: string
    children?: ReactNode | ReactNodeArray
}
export function CardDataCell({label, children, gridArea}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `incidents-${label}`

    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            <Styled.Label width={width} htmlFor={id}>
                {label}
            </Styled.Label>
            <div id={id}>{children}</div>
        </Styled.CardDataCell>
    )
}
