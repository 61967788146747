import {EnableMonitoring} from './components/enable-monitoring'
import {Overlay} from './components/modal-overlay'

export function EnableMonitoringModal(): JSX.Element {
    return (
        <Overlay>
            <EnableMonitoring />
        </Overlay>
    )
}
