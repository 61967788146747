import {StyledTippy} from './vessel-card.styled'
import {DefaultTheme} from 'styled-components'
import * as Style from './system-table.styled'
import {ExpandButton} from '../../../components/expand-button/expand-button'
import Clipboard from '../../../@assets/icons/clipboard.svg'
import type {Action} from '../contexts/state/ot-systems/actions'
import {toggleOTSystemDetails} from '../contexts/state/ot-systems/action-creators'
import {Dispatch} from 'react'
import {GuidType} from '../../../values/generic-type-defintions'

interface RenderProp {
    systemID: GuidType
    systemName: string
    assetTotal: number
    isExpanded: boolean
    inE26Scope?: boolean
    criticalityLevel?: string
    vulnerabilityScore?: number
    dispatch?: Dispatch<Action>
    openModal: (systemID: string) => void
}

interface ConfigItem {
    label: string
    render: (prop: RenderProp) => JSX.Element | string
    width: string
}

export type Config = ConfigItem[]

export const systemTableConfig: Config = [
    {
        label: 'System',
        render: ({systemName, isExpanded, dispatch}) => (
            <Style.SystemContent>
                <ExpandButton
                    id={`expand-btn-${systemName}`}
                    isExpanded={isExpanded}
                    onClick={() => {
                        dispatch?.(toggleOTSystemDetails(systemName))
                    }}
                />
                <StyledTippy
                    content={systemName}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <Style.CellDiv>{systemName}</Style.CellDiv>
                </StyledTippy>
            </Style.SystemContent>
        ),
        width: '18%',
    },
    {
        label: 'Criticality level',
        render: ({criticalityLevel}) => {
            if (criticalityLevel === undefined) {
                return '-'
            }
            return criticalityLevel
        },
        width: '18%',
    },
    {
        label: 'Vulnerability score',
        render: ({vulnerabilityScore}) => {
            if (vulnerabilityScore === undefined) {
                return '-'
            }
            return vulnerabilityScore.toString()
        },
        width: '18%',
    },
    {
        label: 'In E26 scope',
        render: ({inE26Scope}) => {
            if (inE26Scope === true) {
                return 'yes'
            } else if (inE26Scope === false) {
                return 'no'
            }
            return '-'
        },
        width: '18%',
    },
    {
        label: 'Number of assets',
        render: ({assetTotal}) => `${assetTotal}`,
        width: '15%',
    },
    {
        label: 'Actions',
        render: ({systemName, systemID, openModal}) => {
            return (
                <Style.ActionContent>
                    <Style.StyledButton onClick={() => openModal(systemID)}>
                        <img src={Clipboard} id={`assessment-btn-${systemName}`} />
                    </Style.StyledButton>
                </Style.ActionContent>
            )
        },
        width: '13%',
    },
]
