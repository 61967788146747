export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED REPORTS CONTEXT] SET SELECTED PAGE',
    CHANGE_PAGE_SIZE_ACTION = '[PAGED ALERT CONTEXT] CHANGE PAGE SIZE ACTION',
    TOGGLE_NOTES_EXPANDED = '[PAGED REPORTS CONTEXT] TOGGLE NOTES EXPANDED',
    NOTES_EXPAND = '[PAGED REPORTS CONTEXT] EXPANDS NOTES EXPANDED',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    SWITCH_TO_CACHED_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] SWITCH TO CACHED PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED REPORTS CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    ADD_NOTE_ACTION = '[PAGED REPORTS CONTEXT] ADD NOTE ACTION',
    GET_FILTERED_DATA = '[PAGED REPORTS CONTEXT] GET FILTERED DATA',
    RECEIVED_REQUESTED_REPORT_DETAILS = '[PAGED REPORTS CONTEXT] RECEIVED REQUESTED REPORT DETAILS',
}
