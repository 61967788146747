import styled from 'styled-components'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'

interface ContentWrapperProps {
    cardsView: boolean
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${(props) => (props.cardsView ? 'min-width: 300px' : 'justify-content: space-between')};
`

export function showCards(width: number): boolean {
    return width < 1200
}

export const WideContentArea = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    width: 100%;
`
