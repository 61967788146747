import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {TableHeaderRow} from './table-header/table-header-row'
import {NMEAInventoryTableContent} from './nmea-table-content'

export function NMEAInventoryTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.commonTableViewPage.dataArea.background}
            >
                <TableHeaderRow />
            </ContentContainerMiddle>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.commonTableViewPage.dataArea.background}
            >
                <NMEAInventoryTableContent />
            </ContentContainerMiddle>
        </>
    )
}
