import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'
import {getBackgroundColor} from '../../../../../../theme/helpers/risk-score'
import {spacing} from '../../../../../../theme/spacing'
import {AssetOutputModel} from '../../../../models/asset.model'

export const AssetsListWrapper = styled.div`
    justify-content: space-between;
`

interface SquareProps {
    asset: AssetOutputModel
}

export const AssetSquare = styled.span<SquareProps>`
    color: ${(props) => props.theme.incidents.incidentAsset.text};
    background-color: ${(props) =>
        getBackgroundColor(
            props.theme,
            props.asset.threatScore,
            props.asset.value,
            props.asset.threatLevel,
        )};
    height: ${spacing(6)};
    width: ${spacing(24)};
    padding: ${spacing(1)};
    margin: ${spacing(1)} 0;
    border-radius: ${spacing(1)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 'none';
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: center;
`

export const NoAssetsText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    font-style: italic;
`
