import {useEffect} from 'react'
import * as Style from './vessel-list-page.styled'
import {VesselCard} from './components/vessel-card'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {Link} from 'react-router-dom'
import {useOTLocations} from './contexts/hooks/use-ot-locations'
import {setOTLocationsFilter} from './contexts/state/vessels/action-creators'
import {VesselListProvider} from './contexts/vessel-list-provider'
import {LoadingSpinner} from '../../components/loading/loading'
import LoadingState from '../../values/loading-state-enum'
import {ErrorBox} from './ot-inventory.styled'
import {ScrollablePageContentTemplate} from '../../templates/scrollable-page/scrollable-page-content-template'
import {RefreshButton} from './components/refresh-button'
import {BannerElement} from './components/banner-element'
import {showCards} from './location-inventory-page'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {GenerateReportButton} from '../../components/generate-report-button/generate-report-button/generate-report-button'
import {PageType} from '../../components/vessel-filters/data-helper'

/**
 * Component for listing of OTLocation. Always display filteredData from context
 * and if length of filteredData is 0, display NoVesselContent.
 */
function OTLocations(): JSX.Element {
    const {filteredData: locations, refreshData, loadingState} = useOTLocations()
    useEffect(() => {
        refreshData()
    }, [])

    switch (loadingState) {
        case LoadingState.RequestingData:
            return <LoadingSpinner size={200} />
        case LoadingState.Errored:
            return <ErrorBox>Error occurred. Please refresh the page.</ErrorBox>
        default:
            if (locations.length === 0) {
                return <Style.NoVesselContent>No Vessel Data Available</Style.NoVesselContent>
            }

            return (
                <>
                    <Style.VesselListContentArea>
                        {locations.map((location) => (
                            <Link to={`/ot-inventory/${location.id}/assets`} key={location.id}>
                                <VesselCard vessel={location} />
                            </Link>
                        ))}
                    </Style.VesselListContentArea>
                </>
            )
    }
}

/**
 * Component for content area of OTLocations with a filter that filters out
 * OTLocation.
 */
function VesselListContent(): JSX.Element {
    const {width} = useDimensions()
    const btnText = 'Download Inventory Report'
    const glyph = 'Pdf'
    const {
        filter,
        dispatch,
        createReport,
        reportUrl,
        showGenerateReportPopup,
        reportDialogShown,
        resetCreateReportError,
        createReportError,
        isErrorOpen,
    } = useOTLocations()
    const onChangeFilter = (value: string) => {
        dispatch(setOTLocationsFilter(value))
    }

    return (
        <>
            <Style.VesselContentArea>
                <Style.TitleRow>
                    <Style.PageTitle id="title">OT Systems</Style.PageTitle>
                    <BannerElement cardView={showCards(width)} />
                    <Style.ButtonWrapper>
                        <GenerateReportButton
                            createReport={createReport}
                            reportUrl={reportUrl}
                            showGenerateReportPopup={showGenerateReportPopup}
                            reportDialogShown={reportDialogShown}
                            pageType={PageType.OT_INVENTORY}
                            isDisabled={false}
                            glyph={glyph}
                            btnText={btnText}
                            onErrorClose={resetCreateReportError}
                            errorText={createReportError}
                            isErrorOpen={isErrorOpen}
                        />
                        <RefreshButton />
                    </Style.ButtonWrapper>
                </Style.TitleRow>
                <Style.VesselSearchContentArea>
                    <Style.SearchInput
                        id="vessel-search"
                        placeholder="Search vessel"
                        value={filter}
                        onChange={(e) => onChangeFilter(e.currentTarget.value)}
                    />

                    <Style.SearchIcon glyph="Search" height={20} width={20} />
                </Style.VesselSearchContentArea>
                <OTLocations />
            </Style.VesselContentArea>
        </>
    )
}

/**
 * Component for list page of OTLocation with a context provider for OTLocation
 */
export function VesselListPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.OT_INVENTORY))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <ScrollablePageTemplate>
            <ScrollablePageContentTemplate>
                <VesselListProvider>
                    <VesselListContent></VesselListContent>
                </VesselListProvider>
            </ScrollablePageContentTemplate>
        </ScrollablePageTemplate>
    )
}
