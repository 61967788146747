import {Network, OTSource, SourceDestinationOt} from '../../../../store/state/locations/state'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import {isEmailValid} from '../../../../utils/Utils'
import {isValidCidr} from '../../reselectors/network-data-validation'
import {DEFAULT_VESSEL_CONFIG_MODAL, VesselManagementState} from '../type/vessel-management-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {isEqual} from 'lodash'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {DEFAULT_VESSEL_MANAGEMENT_STATE} from '../type/default-vessel-management-state'
import LoadingState from '../../../../values/loading-state-enum'
import {
    DEFAULT_OT_SOURCE_DATA_ARRAY,
    DEFAULT_OT_SOURCE_MODAL_DETAILS,
} from '../type/ot-sources-modal-details'

export const vesselManagementReducer = produce(
    (draft: VesselManagementState, action: Actions.AllActions | LoggingOutAction) => {
        if (!draft.initialValue.networkConfiguration) {
            draft.initialValue.networkConfiguration = {} as Network
        }
        if (!draft.displayValue.networkConfiguration) {
            draft.displayValue.networkConfiguration = {} as Network
        }
        if (!draft.updatedValue.networkConfiguration) {
            draft.updatedValue.networkConfiguration = {} as Network
        }
        if (!draft.displayValue.otSources) {
            draft.displayValue.otSources = {} as OTSource
        }
        switch (action.type) {
            case ActionType.RECEIVE_LOCATIONS_CONTROL:
                draft.locationsInIsolationMode = action.payload
                break

            case ActionType.SHOW_VESSEL_DETAIL_MODAL:
                draft.activeVesselId = action.payload
                draft.showFilterBar = action.payload === null
                break

            case ActionType.CLOSE_VESSEL_DETAIL_MODAL:
                draft.activeVesselId = null
                draft.initialValue = DEFAULT_VESSEL_CONFIG_MODAL
                draft.displayValue = DEFAULT_VESSEL_CONFIG_MODAL
                draft.updatedValue = DEFAULT_VESSEL_CONFIG_MODAL
                break

            case ActionType.SET_INITIAL_VALUE:
                draft.initialValue = action.payload
                draft.displayValue = action.payload
                draft.updatedValue = DEFAULT_VESSEL_CONFIG_MODAL
                draft.isValidBusinessNetworkChange = true
                draft.isValidOTNetworkChange = true
                draft.isValidCrewNetworkChange = true
                draft.isValidEmailChange = true
                draft.isOnboardAgentIdChanged = true
                draft.isIncidentPolicyChanged = true
                draft.isMetricsPolicyChanged = true
                draft.isEnforcementPolicyChanged = true
                draft.isValidChange = false
                break

            case ActionType.CHANGE_BUSINESS_NETWORKS:
                draft.displayValue.networkConfiguration.BusinessNetworks =
                    action.payload?.toString().length === 0 ? undefined : action.payload

                if (
                    !isEqual(
                        draft.initialValue.networkConfiguration.BusinessNetworks,
                        draft.displayValue.networkConfiguration.BusinessNetworks,
                    ) &&
                    isValidCidr(action.payload) !== 'invalid_subnet'
                ) {
                    draft.updatedValue.networkConfiguration.BusinessNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                    draft.isValidBusinessNetworkChange = true
                    draft.isValidChange = true
                } else {
                    draft.updatedValue.networkConfiguration.BusinessNetworks = undefined
                    draft.isValidBusinessNetworkChange = false
                    draft.isValidChange = false
                }
                break

            case ActionType.CHANGE_OT_NETWORKS:
                draft.displayValue.networkConfiguration.OTNetworks =
                    action.payload?.toString().length === 0 ? undefined : action.payload

                if (
                    !isEqual(
                        draft.initialValue.networkConfiguration.OTNetworks,
                        draft.displayValue.networkConfiguration.OTNetworks,
                    ) &&
                    isValidCidr(action.payload) !== 'invalid_subnet'
                ) {
                    draft.updatedValue.networkConfiguration.OTNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                    draft.isValidOTNetworkChange = true
                    draft.isValidChange = true
                } else {
                    draft.updatedValue.networkConfiguration.OTNetworks = undefined
                    draft.isValidOTNetworkChange = false
                    draft.isValidChange = false
                }
                break

            case ActionType.POLICY_LICENSE_SUMMERY:
                draft.policyLicenseSummery = action.payload
                break

            case ActionType.CHANGE_CREW_NETWORKS:
                draft.displayValue.networkConfiguration.CrewNetworks =
                    action.payload?.toString().length === 0 ? undefined : action.payload

                if (
                    !isEqual(
                        draft.initialValue.networkConfiguration.CrewNetworks,
                        draft.displayValue.networkConfiguration.CrewNetworks,
                    ) &&
                    isValidCidr(action.payload) !== 'invalid_subnet'
                ) {
                    draft.updatedValue.networkConfiguration.CrewNetworks = action.payload
                        ?.filter((e) => e.trim().length !== 0)
                        .map((e) => e.trim())
                    draft.isValidCrewNetworkChange = true
                    draft.isValidChange = true
                } else {
                    draft.updatedValue.networkConfiguration.CrewNetworks = undefined
                    draft.isValidCrewNetworkChange = false
                    draft.isValidChange = false
                }
                break

            case ActionType.CHANGE_DASHBOARD_AGENT_IDS:
                draft.displayValue.vesselDashboardAgentIds = action.payload
                if (
                    !isEqual(draft.initialValue.vesselDashboardAgentIds?.toString, action.payload)
                ) {
                    draft.updatedValue.vesselDashboardAgentIds = action.payload
                    draft.isOnboardAgentIdChanged = true
                    draft.isValidChange = true
                } else {
                    draft.updatedValue.vesselDashboardAgentIds = undefined
                    draft.isOnboardAgentIdChanged = false
                    draft.isValidChange = false
                }
                break

            case ActionType.CHANGE_VESSEL_EMAIL:
                draft.displayValue.vesselEmail =
                    action.payload?.length === 0 ? undefined : action.payload
                if (
                    !isEqual(draft.initialValue.vesselEmail, draft.displayValue.vesselEmail) &&
                    isEmailValid(action.payload)
                ) {
                    draft.updatedValue.vesselEmail = action.payload
                    draft.isValidEmailChange = true
                    draft.isValidChange = true
                } else {
                    draft.updatedValue.vesselEmail = undefined
                    draft.isValidEmailChange = false
                    draft.isValidChange = false
                }
                break

            case ActionType.SET_VALID_CHANGE:
                switch (action.payload.inputType) {
                    case 'business':
                        draft.isValidBusinessNetworkChange = action.payload.value
                        break
                    case 'ot':
                        draft.isValidOTNetworkChange = action.payload.value
                        break
                    case 'crew':
                        draft.isValidCrewNetworkChange = action.payload.value
                        break
                    case 'onboardAgentId':
                        draft.isOnboardAgentIdChanged = action.payload.value
                        break
                    case 'email':
                        draft.isValidEmailChange = action.payload.value
                        break
                    case 'incidentPolicy':
                        draft.isIncidentPolicyChanged = action.payload.value
                        break
                    case 'metricsPolicy':
                        draft.isMetricsPolicyChanged = action.payload.value
                        break
                    case 'enforcementPolicy':
                        draft.isEnforcementPolicyChanged = action.payload.value
                        break
                }
                break

            case ActionType.SET_SELECTED_INCIDENT_POLICY:
                draft.selectedIncidentPolicy = action.payload
                if (!isEqual(draft.initialValue.incidentPolicy.id, action.payload)) {
                    draft.updatedValue.incidentPolicy.id = action.payload
                    draft.isValidChange = true
                } else {
                    draft.isValidChange = false
                }
                break

            case ActionType.SET_SELECTED_METRICS_POLICY:
                draft.selectedMetricsPolicy = action.payload
                if (!isEqual(draft.initialValue.metricsPolicy.id, action.payload)) {
                    draft.updatedValue.metricsPolicy.id = action.payload
                    draft.isValidChange = true
                } else {
                    draft.isValidChange = false
                }
                break

            case ActionType.SET_SELECTED_ENFORCEMENT_POLICY:
                draft.selectedEnforcementPolicy = action.payload
                if (!isEqual(draft.initialValue.enforcementPolicy.id, action.payload)) {
                    draft.updatedValue.enforcementPolicy.id = action.payload
                    draft.isValidChange = true
                } else {
                    draft.isValidChange = false
                }
                break

            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case ActionType.OPEN_SET_OT_SOURCES_MODAL:
                draft.showOTSourceModalDisplay = true
                draft.oTSourceModalDetails = action.payload
                break
            case ActionType.CLOSE_SET_OT_SOURCES_MODAL:
                draft.showOTSourceModalDisplay = false
                draft.oTSourceModalDetails = DEFAULT_OT_SOURCE_MODAL_DETAILS
                break
            case ActionType.CHANGE_OT_SOURCES_TYPE:
                draft.oTSourceModalDetails.sourceType = action.payload
                break

            case ActionType.CHANGE_OT_SOURCES_IP:
                const {label: ipLabel} = action.payload
                let updatedSourceDataIP: SourceDestinationOt[] = []
                if (draft.oTSourceModalDetails.sourceData.length !== 0) {
                    updatedSourceDataIP = draft.oTSourceModalDetails?.sourceData?.map(
                        (entry, index) => {
                            if (index === action.payload.index) {
                                return {
                                    ...entry,
                                    [ipLabel]: `${action.payload.newIpValue ?? ''}:${entry[ipLabel].split(':')[1] ?? ''}`,
                                }
                            }
                            return entry
                        },
                    )
                } else {
                    updatedSourceDataIP = [
                        {
                            ...DEFAULT_OT_SOURCE_DATA_ARRAY,
                            [ipLabel]: `${action.payload.newIpValue ?? ''}:`,
                        },
                    ]
                }
                draft.oTSourceModalDetails.sourceData = updatedSourceDataIP
                break
            case ActionType.CHANGE_OT_SOURCES_PORT:
                let updatedSourceDataPort: SourceDestinationOt[] = []
                const {label: portLabel} = action.payload
                if (draft.oTSourceModalDetails.sourceData.length !== 0) {
                    updatedSourceDataPort = draft.oTSourceModalDetails.sourceData.map(
                        (entry, index) => {
                            if (index === action.payload.index) {
                                return {
                                    ...entry,
                                    [portLabel]: `${entry[portLabel].split(':')[0] ?? ''}:${action.payload.newPortValue ?? ''}`,
                                }
                            }
                            return entry
                        },
                    )
                } else {
                    updatedSourceDataPort = [
                        {
                            ...DEFAULT_OT_SOURCE_DATA_ARRAY,
                            [portLabel]: `:${action.payload.newPortValue ?? ''}`,
                        },
                    ]
                }
                draft.oTSourceModalDetails.sourceData = updatedSourceDataPort

                break
            case ActionType.UPDATE_OT_DISPLAY_AFTER_SAVE:
                if (draft.oTSourceModalDetails.sourceType === 'nmea') {
                    draft.displayValue.otSources.nmea = draft.oTSourceModalDetails.sourceData
                } else if (draft.oTSourceModalDetails.sourceType === 'syslog') {
                    draft.displayValue.otSources.syslog = draft.oTSourceModalDetails.sourceData
                }
                break
            case ActionType.REQUEST_VESSEL_DEPLOYMENT_STATUS:
                draft.deploymentOperationalLoadingState = LoadingState.RequestingData
                draft.deploymentTrafficLoadingState = LoadingState.RequestingData
                draft.vesselDeploymentLoadingState = LoadingState.RequestingData
                break

            case ActionType.GET_VESSEL_DEPLOYMENT_STATUS_OPERATIONAL:
                draft.operationalStatus = action.payload
                draft.deploymentOperationalLoadingState = LoadingState.Loaded
                break

            case ActionType.GET_VESSEL_DEPLOYMENT_STATUS_TRAFFIC:
                draft.trafficStatus = action.payload
                draft.deploymentTrafficLoadingState = LoadingState.Loaded
                break

            case ActionType.SET_VESSEL_DEPLOYMENT_STATUS:
                draft.vesselDeploymentStatus = action.payload
                draft.vesselDeploymentLoadingState = LoadingState.Loaded
                break

            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break

            case ActionType.SET_FILTER:
                draft.filteredLocations = action.payload
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_VESSEL_MANAGEMENT_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
