import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {ScrollablePageContentTemplate} from '../../templates/scrollable-page/scrollable-page-content-template'
import {LocationInfo} from './components/location-info'
import {Link, RouteComponentProps} from 'react-router-dom'
import {PageTitle} from './components/page-title'
import {Panel} from './components/panel'
import {locationMapSelector} from '../../store/state/locations/selectors'
import {getLocationCode, getLocationDescription} from '../../store/state/locations/state'
import useTypedSelector from '../../hooks/use-typed-selector'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {useContext, useEffect} from 'react'
import {OTAssetsLoadingStateSelector} from '../../store/state/ot-assets/selectors'
import LoadingState from '../../values/loading-state-enum'
import {ErrorBox, FlexPanelContent} from './ot-inventory.styled'
import {LocationInventoryPageProvider} from './contexts/location-inventory-page-provider'
import {LocationInventoryPageContext} from './contexts/location-inventory-page-context'
import ActionType from './contexts/state/asset-form/action-type'
import PageActionType from './contexts/state/page/action-type'
import {ActionType as AssetUploadModalActionType} from './contexts/state/asset-upload-modal/action-type'
import {Button} from '../../components/base/button'
import {AssetUploadModal} from './components/asset-upload-modal'
import {BannerElement} from './components/banner-element'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import * as Style from './location-inventory-page.styled'
import {ToastManager} from '../../components/toast/toast'
import {TabSection} from './components/tab-section/tab-section'

const UNKNOWN_VALUE = 'Unknown'

export function showCards(width: number): boolean {
    return width < 1260
}

// This wrapper purpose is so that we can use the context provider
function PageContent({match}: RouteComponentProps<{location: string}>): JSX.Element {
    const {assetForm, page, assetUploadModal} = useContext(LocationInventoryPageContext)

    const locationID = match.params.location
    const allLocationMap = useTypedSelector(locationMapSelector)
    const {width} = useDimensions()

    const locationCode = getLocationCode(allLocationMap, locationID)

    const state = useTypedSelector(OTAssetsLoadingStateSelector)
    const assetCreationState = assetForm.state.formSubmitLoadingState

    useEffect(() => {
        // Reset form state when asset creation is successful
        if (assetCreationState === LoadingState.Loaded) {
            page.dispatch?.({
                type: PageActionType.TOGGLE_SHOW_ASSET_CREATION_FORM,
                payload: {showForm: false},
            })
            assetForm.dispatch?.({type: ActionType.RESET_ASSET_CREATION_FORM})
        }
        page.dispatch?.({
            type: PageActionType.SET_LOCATION_ID,
            payload: {locationID: locationID},
        })
    }, [assetCreationState])

    if (locationCode === UNKNOWN_VALUE || state === LoadingState.Errored) {
        return (
            <FixedPageTemplate>
                <Panel>
                    <ErrorBox>Error occurred. Please refresh the page.</ErrorBox>
                </Panel>
            </FixedPageTemplate>
        )
    }

    const locationName = getLocationDescription(allLocationMap, locationID)

    const onClickImportAssets = () => {
        assetUploadModal.dispatch?.({
            type: AssetUploadModalActionType.TOGGLE_SHOW_ASSET_UPLOAD_MODAL,
            payload: {isOpen: true},
        })
    }

    return (
        <>
            <Style.TitleRow>
                <Link to="/ot-inventory-vessels">
                    <PageTitle>OT Systems &gt; {locationName}</PageTitle>
                </Link>
                <BannerElement cardView={showCards(width)} />
            </Style.TitleRow>
            <Panel>
                <FlexPanelContent>
                    <LocationInfo name={locationName} code={locationCode} />
                    <Button onClick={onClickImportAssets} variant="outlined">
                        Import Assets
                    </Button>
                </FlexPanelContent>
            </Panel>
            <Panel>
                <TabSection />
            </Panel>
            {assetUploadModal.state.isOpen && <AssetUploadModal />}
        </>
    )
}

export function LocationOTInventoryPage(
    props: RouteComponentProps<{location: string}>,
): JSX.Element {
    return (
        <LocationInventoryPageProvider>
            <ScrollablePageTemplate>
                <ScrollablePageContentTemplate centered>
                    <ToastManager>
                        <PageContent {...props} />
                    </ToastManager>
                </ScrollablePageContentTemplate>
            </ScrollablePageTemplate>
        </LocationInventoryPageProvider>
    )
}
