import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsTrendsWidget} from '../../../../incidents-report/components/incidents-types-and-severities/incidents-trends-widget'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import * as Styled from './widget-commonstyling.styled'

export function TrendsIncidentsWidget(): JSX.Element {
    const {loadedIncidentsTrends, incidentsTrendsBySeverity} = useIncidentsWidget()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>RAISED INCIDENTS TREND</Styled.SectionText>
            {loadedIncidentsTrends ? (
                <IncidentsTrendsWidget incidentsTrendsBySeverity={incidentsTrendsBySeverity} />
            ) : (
                <DataLoading />
            )}
        </Styled.StatusFilterContainer>
    )
}
