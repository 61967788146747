import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'
import * as Styled from './_styled/nmea-details-modal.styled'
import {ActivitySection} from './insights-section/insights-section'
import {DetailPanelHeader} from './header/detail-panel-header'
import {NmeaDetailedSection} from './nmea-detailed-section/nmea-detailed-section'
import {NoDataModal} from './no-data-modal'

export function NMEADetailsModal(): JSX.Element {
    const {width} = useDimensions()
    const {findNmeaDetailsForModal} = usePagedNmeaInventory()
    if (!findNmeaDetailsForModal) {
        return <NoDataModal />
    }
    return (
        <Styled.ContentWrapper width={width}>
            <Styled.Container
                onClick={(e) => e.stopPropagation()}
                id="nmea-details-modal_container"
            >
                <DetailPanelHeader />
                <NmeaDetailedSection />
                <ActivitySection />
            </Styled.Container>
        </Styled.ContentWrapper>
    )
}
