import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './action-button.styled'
interface ActionButtonProps {
    id: string
    children: ReactNode | ReactNodeArray
    onClick: () => void
}
export function ActionButton({id, children, onClick}: ActionButtonProps): JSX.Element {
    return (
        <Styled.Button id={id} onClick={onClick}>
            {children}
        </Styled.Button>
    )
}
