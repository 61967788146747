import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {TableHeader} from './table-header'
import * as Styled from './_styled/vessel-management-list.styled'
import {TableRow} from './table-row'
import {Location} from '../../../../store/state/locations/state'
import {VesselDetailsModal} from './vessel-details-modal/vessel-details-modal'
import {useVesselManagement} from '../../contexts/use-vessel-management'

interface VesselListTableProps {
    locations: Location[]
}

export function VesselListTable({locations}: VesselListTableProps): JSX.Element {
    const {width} = useDimensions()
    const {activeVesselId, showFilterBar} = useVesselManagement()
    const displayVesselDetailsModal = activeVesselId != null
    return (
        <div style={{minHeight: '200px'}}>
            <TableHeader />
            <Styled.ContentContainer width={width}>
                <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
                    <Styled.TableDataGrid
                        width={width}
                        showReducedGrid={displayVesselDetailsModal || showFilterBar}
                    >
                        {locations.map((location, index) => (
                            <TableRow key={location.location} location={location} index={index} />
                        ))}
                    </Styled.TableDataGrid>
                    {displayVesselDetailsModal && <VesselDetailsModal />}
                </div>
            </Styled.ContentContainer>
        </div>
    )
}
