import {difference, remove} from 'lodash'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setFilteredIncidentStatus} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import * as Styled from './incident-status-filter.styled'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export const avaliableStatuses = [
    [IncidentStatus.NEW],
    [IncidentStatus.OPEN],
    [IncidentStatus.CLOSED, IncidentStatus.CLOSED_FALSE_POSITIVE, IncidentStatus.CLOSED_MONITOR],
]
export function IncidentStatusFilter(): JSX.Element {
    const dispatch = useDispatch()
    let {filterByStatus} = useTypedSelector(incidentsFilterSelector)

    return (
        <Styled.ContentWrapper id="IncidentStatusFilter-wrapper">
            <Styled.Label>Status:</Styled.Label>
            <Styled.StatusesWrapper>
                {avaliableStatuses.map((status) => (
                    <Styled.Square
                        incidentStatus={status[0]}
                        key={status[0]}
                        activeFilter={
                            difference(status, filterByStatus ?? []).length === 0 ||
                            filterByStatus === undefined ||
                            filterByStatus?.length === 0
                        }
                        onClick={() => {
                            if (!filterByStatus) {
                                filterByStatus = []
                            }
                            filterByStatus = [...filterByStatus]
                            if (filterByStatus.length === avaliableStatuses.flat().length) {
                                remove(filterByStatus, (s) => !status.includes(s))
                            } else if (!filterByStatus.find((s) => status.includes(s))) {
                                filterByStatus.push(...status)
                            } else {
                                remove(filterByStatus, (s) => status.includes(s))
                            }
                            dispatch(setFilteredIncidentStatus(filterByStatus))
                            dispatch(
                                logFilterByTypes(
                                    'filterByStatus',
                                    status[0],
                                    true,
                                    AuditLogPageType.INCIDENTS,
                                ),
                            )
                        }}
                        id={`filter-by-status-${status[0]}`}
                    >
                        {status[0]}
                    </Styled.Square>
                ))}
            </Styled.StatusesWrapper>
        </Styled.ContentWrapper>
    )
}
