import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {
    MetricsResponseData,
    MetricsResponseForTarget,
} from '../../../metrics-beta/contexts/types/metrics-response'
import {TimestampFilterType} from './timestamp-filter'
import {METRICS_ENDPOINT} from './vessels-beta-endpoints'
export interface FrameworkSummaryTargetResponse {
    locationId: string
    protectionTargetPerformance: number | undefined
    behaviourTargetPerformance: number | undefined
    maintenanceTargetPerformance: number | undefined
}
export async function buildFrameworkSummaryTarget(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<FrameworkSummaryTargetResponse[]> {
    try {
        const response = await REST.post(`${METRICS_ENDPOINT}/metrics/tables`, {
            locations: locationIds,
            period: analysisPeriod,
            analysisTypes: ['target'],
            aggregationLevel: 'framework',
        })

        const receivedData = new Map(Object.entries(response.data.tables))?.get(
            'target',
        ) as MetricsResponseData
        const result = (receivedData?.data as MetricsResponseForTarget[]).reduce(
            (
                accumulator: {
                    [key: string]: {
                        locationId: string
                        protectionTargetPerformance: number | undefined
                        behaviourTargetPerformance: number | undefined
                        maintenanceTargetPerformance: number | undefined
                    }
                },
                current: MetricsResponseForTarget,
            ) => {
                const locationId = current.location

                if (!accumulator[locationId]) {
                    accumulator[locationId] = {
                        locationId,
                        protectionTargetPerformance: undefined,
                        behaviourTargetPerformance: undefined,
                        maintenanceTargetPerformance: undefined,
                    }
                }

                if (current.framework === 'protection') {
                    accumulator[locationId].protectionTargetPerformance = current.performance
                } else if (current.framework === 'behaviour') {
                    accumulator[locationId].behaviourTargetPerformance = current.performance
                } else if (current.framework === 'maintenance') {
                    accumulator[locationId].maintenanceTargetPerformance = current.performance
                }

                return accumulator
            },
            {},
        )
        const resultArray = Object.values(result)
        return resultArray
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<FrameworkSummaryTargetResponse>()
    }
}
