import {useMemo} from 'react'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {Period} from './searched-period.styled'
import locationSelectionNumberReselector from '../../../contexts/reselector/location-selection-number-reselector'
import {ClickableHeaderFilter} from './clickable-header-filter'
import {
    fromDatePeriodSelector,
    toDatePeriodSelector,
} from '../../../../../store/state/incidents-report-filter/selectors'

export function SearchedPeriod(): JSX.Element {
    const fromDate = useTypedSelector(fromDatePeriodSelector)
    const toDate = useTypedSelector(toDatePeriodSelector)

    const displayPeriod = useMemo(() => {
        if (!fromDate && !toDate) {
            return 'All'
        } else if (fromDate !== undefined && !toDate) {
            return `from ${formatDateRange(fromDate)}`
        } else if (!fromDate && toDate !== undefined) {
            return `to ${formatDateRange(toDate)}`
        } else {
            return `${formatDateRange(fromDate)} - ${formatDateRange(toDate)}`
        }
    }, [fromDate, toDate])

    const locationSelectionNumber = useTypedSelector(locationSelectionNumberReselector)

    return (
        <Period id="clickable-header-filter-wrapper">
            <ClickableHeaderFilter name="Period" value={displayPeriod} />
            {'>'}

            <ClickableHeaderFilter name="Vessels" value={locationSelectionNumber} />
        </Period>
    )
}
const formatDateRange = (dateRange: string | null) => {
    const today = new Date()
    const from = dateRange ? new Date(dateRange) : today
    const fromDay = from.getDate()

    const fromMonth = from.toLocaleString('default', {month: 'long'})

    let daySuffix = 'th'
    if (fromDay === 1 || fromDay === 21 || fromDay === 31) {
        daySuffix = 'st'
    } else if (fromDay === 2 || fromDay === 22) {
        daySuffix = 'nd'
    } else if (fromDay === 3 || fromDay === 23) {
        daySuffix = 'rd'
    }

    const formattedFromDate = `${fromDay}${daySuffix} of ${fromMonth}`

    return formattedFromDate
}
