import * as Styled from './card-area.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {Card} from './card'
import {ConsumerState} from '../../../../../values/engine_status/consumer-state'

interface CardsProps {
    consumerStates: ConsumerState[]
}
export function Cards({consumerStates}: CardsProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.CardArea width={width}>
            {consumerStates.map((consumerState) => (
                <Card consumerState={consumerState} key={consumerState.topicName} />
            ))}
        </Styled.CardArea>
    )
}
