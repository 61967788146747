import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {NumberOfIncidentsResponse} from './number-of-incidents-raised-and-open'
import {INCIDENTS_ENDPOINT} from './vessels-beta-endpoints'

export async function buildNumberOfMonitoredAssetsInOpenInc(
    locationIds: LocationIdType[],
): Promise<NumberOfIncidentsResponse[]> {
    try {
        const response = await REST.post(`${INCIDENTS_ENDPOINT}/associatedAssets/summary`, {
            locations: locationIds,
            statuses: ['OPEN'],
        })
        const findNodesForLocation: {location: string; nodes: string[]}[] =
            response?.data as Array<{
                location: string
                nodes: string[]
            }>
        return findNodesForLocation?.map((oneLocation) => {
            return {
                locationId: oneLocation?.location,
                incidentsCount: oneLocation?.nodes?.length ?? 0,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<NumberOfIncidentsResponse>()
    }
}
