import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../theme/theme'

export const BREAK_POINT_REFRESH_SWITCH = 500
export const BREAK_POINT_CARD_VIEW = 1200
export const SHOW_DETAILS_BREAK_POINT_CARD_VIEW = 1350

interface UserManagementPageStyleProps {
    width: number
}

export const Header = styled.div<UserManagementPageStyleProps>`
    width: 100%;
`

export const PageTitle = styled.div<UserManagementPageStyleProps>`
    font-size: 20px;
    line-height: 27px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const HeaderAreaWrapper = styled.div<UserManagementPageStyleProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'row' : 'column-reverse')};
    align-items: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'center' : 'flex-start')};
    justify-content: center;
    column-gap: ${spacing(4)};
    row-gap: ${spacing(1)};
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: ${spacing(1)};
`

interface ContentContainerTopProps {
    width: number
}
export const ContentContainerTop = styled.div<ContentContainerTopProps>`
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    align-self: center;
`

interface ContainerProps {
    width: number
}

export const Container = styled.div<ContainerProps>`
    padding: ${(props) => (props.width < 600 ? `${spacing(4)} ${spacing(2)}` : spacing(4))};
    display: flex;
    justify-content: space-between;
`
