import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const AssignedToWrapper = styled.div`
    display: flex;
    justify-content: center;
    column-gap: ${spacing(2)};
    padding-right: 2px;
    position: relative;
`
export const ButtonText = styled.div`
    ${mediumSmallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    text-decoration: underline;
    font-weight: ${(props) => props.theme.font.weight.normal};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    &:hover {
        outline: 0;
        cursor: pointer;
    }
`

export const AssignedToIcon = styled.img`
    width: 16px;
    height: 16px;
`

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`
export const AssignedToGuestIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-right: 3px;
`
