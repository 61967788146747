import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const Panel = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`
