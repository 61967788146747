import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface Props {
    zoomLevel4: boolean
    width: number
}
export const ZoomLevelGrid = styled.div<Props>`
    padding: 0;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: ${(props) =>
        props.zoomLevel4
            ? 'repeat(1, minmax(0, 1fr))'
            : props.width > 340
              ? 'repeat(auto-fill, minmax(300px, 1fr))'
              : 'repeat(auto-fill, minmax(280px, 1fr))'};
    column-gap: ${spacing(4)};
    row-gap: ${spacing(8)};
    flex: 1;
`
