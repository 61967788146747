import {UniqueFlow} from '../../../../../../values/unique-flow'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {TableDataCell} from '../../common/table/table-data-cell.styled'
import {formatDate} from '../../../../../../helpers/formatting'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {getDirectionValue, getProximityValue} from '../data-helpers'
import {usePagedUniqueFlows} from '../../../../contexts/unique-flows/use-paged-unique-flows'

interface TableRowProps {
    uniqueFlow: UniqueFlow
    index: number
}

function TableRow({uniqueFlow, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const node = useTypedSelector(nodesSelector)?.get(uniqueFlow.identifier.knownDestination)
    const {assetId} = usePagedUniqueFlows()

    return (
        <>
            <TableDataRow gridRow={gridRow} />
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_timestamp}`}
                gridRow={gridRow}
                gridColumn={2}
            >
                {formatDate(uniqueFlow.uniqueFlowTimestamp)}
            </TableDataCell>
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_node}`}
                gridRow={gridRow}
                gridColumn={3}
            >
                {node?.alias || 'Unknown'}
            </TableDataCell>
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_port}`}
                gridRow={gridRow}
                gridColumn={4}
            >
                {uniqueFlow.identifier.destinationPort}
            </TableDataCell>
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_transport}`}
                gridRow={gridRow}
                gridColumn={5}
            >
                {uniqueFlow.identifier?.transport?.toUpperCase() || 'Unknown'}
            </TableDataCell>
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_proximity}`}
                gridRow={gridRow}
                gridColumn={6}
            >
                {getProximityValue(uniqueFlow.identifier)}
            </TableDataCell>
            <TableDataCell
                id={`unique_flows_${uniqueFlow.identifier}_direction}`}
                gridRow={gridRow}
                gridColumn={7}
            >
                {getDirectionValue(assetId, uniqueFlow.identifier)}
            </TableDataCell>
        </>
    )
}

export default TableRow
