import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
export function notesFont(): string {
    return `${smallFont()} letter-spacing: 0.24px;`
}
interface NoteProps {
    result: string
}
export const Note = styled.div<NoteProps>`
    position: relative;
    background-color: ${(props) => props.result};
    padding-top: ${spacing(1)};
    padding-bottom: ${spacing(1)};
    &:hover {
        background-color: ${(props) => props.result};
    }
`
export const NoteDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const When = styled.div`
    ${notesFont()}
    padding-left: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const Who = styled.div`
    ${notesFont()}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    max-width: 180px;
`

export const NewSquare = styled.span`
    display: flex;
    background-color: ${(props) => props.theme.incidents.newRecordLabel.background};
    color: ${(props) => props.theme.incidents.newRecordLabel.text};
    height: 8px;
    width: ${spacing(5)};
    border-radius: 4px;
    margin-left: ${spacing(1)};
    justify-content: center;
    font-size: 6px;
    line-height: 8px;
`

export const Text = styled.div`
    ${notesFont()};
    word-break: break-word;
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: ${spacing(1)} 0 0 0;
    text-align: justify;
    display: flex;
    flex-direction: column;
`

export const BottomLine = styled.div`
    display: flex;
    justify-content: space-between;
`
interface EditedProps {
    width: number
}
export const Edited = styled.div<EditedProps>`
    display: flex;
    justify-content: ${(props) =>
        props.width < 1260 && props.width > 600 ? 'flex-start' : 'flex-end'};
    flex: 1;
    font-style: italic;
    color: ${(props) => props.theme.incidents.dataRow.editText};
`
export const ExpandImage = styled.img`
    height: 7px;
    width: 23px;
    cursor: pointer;
    align-self: center;
    margin-right: ${spacing(1)};
`
