import {MouseEvent, ReactNode} from 'react'
import {NotSelectedTab, SelectedTab} from './tab-section.styled'
import {ContentPageType} from '../../contexts/types/content-page'

interface TabProps {
    activeTab: ContentPageType
    index: ContentPageType
    children: ReactNode
    toggle: (event: MouseEvent, tab: ContentPageType) => void
}
export function Tab({activeTab, index, children, toggle}: TabProps): JSX.Element {
    if (activeTab === index) {
        return <SelectedTab>{children}</SelectedTab>
    }

    return <NotSelectedTab onClick={(e: MouseEvent) => toggle(e, index)}>{children}</NotSelectedTab>
}
