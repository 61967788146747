import {SoftwareInventoryResponse} from '../types/software-inventory-response'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {GuidType} from '../../../../values/generic-type-defintions'
import {SoftwareStatus} from '../../../../store/state/software-inventory-filter/state'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function receivedRequestedPageData(
    data: SoftwareInventoryResponse[],
    totalNumberOfItems: number,
    totalNumberOfPages: number,
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {
            data,
            totalNumberOfItems,
            totalNumberOfPages,
        },
    }
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function displaySoftwareDetailsModal(
    softwareModalId: GuidType,
): Actions.DisplaySoftwareDetailsModalAction {
    return {
        type: ActionType.DISPLAY_SOFTWARE_DETAILS_MODAL,
        payload: softwareModalId,
    }
}
export function closeSoftwareDetailsModal(): Actions.CloseNetworkAssetDetailsModalAction {
    return {
        type: ActionType.CLOSE_SOFTWARE_DETAILS_MODAL,
    }
}
export function getSoftwareDetailsModalCashData(): Actions.SwitchToCashDataModalAction {
    return {type: ActionType.SWITCH_TO_CACHED_DATA_MODAL}
}

export function setFindSoftwareDetails(
    findSoftwareDetails: SoftwareInventoryResponse | undefined,
): Actions.ReceivedRequestedSoftwareDetails {
    return {
        type: ActionType.RECEIVED_REQUESTED_SOFTWARE_DETAILS,
        payload: findSoftwareDetails,
    }
}

export function setFilter(): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER}
}

export function displayChangeSoftwareStatusModal(
    value: boolean,
    status: SoftwareStatus,
): Actions.DisplayChangeSoftwareStatusModalAction {
    return {
        type: ActionType.DISPLAY_CHANGE_SOFTWARE_STATUS_MODAL,
        payload: {state: value, status},
    }
}

export function setSoftwareStatus(status: SoftwareStatus): Actions.SetSoftwareStatus {
    return {
        type: ActionType.SET_SOFTWARE_STATUS,
        payload: status,
    }
}

export function setReportCreate(payload: string): Actions.ReportCreateAction {
    return {
        type: ActionType.REPORT_CREATE,
        payload,
    }
}

export function showGenerateReportPopup(show: boolean): Actions.ShowGenerateReportPopup {
    return {
        type: ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP,
        payload: show,
    }
}

export function setModalSelections(
    forCompany: boolean,
    forAllSoftware: boolean,
): Actions.SetModalSelectionsAction {
    return {
        type: ActionType.SET_SOFTWARE_MODAL_OPTIONS,
        payload: {forCompany, forAllSoftware},
    }
}

export function closeChangeSoftwareStatusModal(): Actions.CloseChangeSoftwareStatusModalAction {
    return {
        type: ActionType.CLOSE_CHANGE_SOFTWARE_STATUS_MODAL,
    }
}
