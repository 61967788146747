import {ScrollablePageContent} from './scrollable-page.styled'
import type {ScrollablePageContentProps} from './scrollable-page.styled'

interface ScrollablePageContentTemplate extends ScrollablePageContentProps {
    children: React.ReactNode
}

export function ScrollablePageContentTemplate({
    centered = true,
    children,
}: ScrollablePageContentTemplate): JSX.Element {
    return <ScrollablePageContent centered={centered}>{children}</ScrollablePageContent>
}
