import {useDispatch} from 'react-redux'
import {HighlightedFilerValueType} from '../../../contexts/types/highlighted-filter-type'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {LinkText} from './searched-period.styled'
import {logButtonClickFor} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface ClickableHeaderFilterProps {
    name: HighlightedFilerValueType
    value: number | string
}
export function ClickableHeaderFilter({name, value}: ClickableHeaderFilterProps): JSX.Element {
    const {
        displayFilterBar,
        showFilterBar,
        displayHighlightedFilterValue,
        closeMetricsDetailsModal,
    } = usePagedMetricsBeta()
    const dispatch = useDispatch()
    return (
        <LinkText
            onClick={() => {
                !showFilterBar && displayFilterBar(!showFilterBar)
                displayHighlightedFilterValue(name)
                closeMetricsDetailsModal()
                dispatch(logButtonClickFor(`Filter by Header ${name}`, AuditLogPageType.METRICS))
            }}
            id={`clickable-header-filter-${name}`}
        >
            {name} ({value})
        </LinkText>
    )
}
