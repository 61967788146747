import {AlertCircle, Check} from 'react-feather'
import * as Styled from '../_styled/data-input.styled'
import {ValidationResult} from '../../../../../reselectors/network-data-validation'

interface DataInputProps {
    label: string
    initialValue: string[]
    onChangeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    isValidCidr: ValidationResult | null
    isChanged: boolean
}

export function NetworkDataInput({
    label,
    initialValue,
    onChangeHandler,
    isValidCidr,
    isChanged,
}: DataInputProps): JSX.Element {
    return (
        <>
            <Styled.Label id={`${label}-vessel-tagging`}>{label}</Styled.Label>
            <Styled.Value id={`${label}-vessel-tagging-value`}>
                <Styled.Input
                    value={initialValue?.join('\n')}
                    onChange={onChangeHandler}
                    rows={initialValue.length}
                />
                {isChanged ? (
                    <Check color="green" width={19} height={19} style={{paddingLeft: '2px'}} />
                ) : isValidCidr === 'invalid_subnet' ? (
                    <AlertCircle color="red" width={19} height={19} style={{paddingLeft: '2px'}} />
                ) : (
                    ''
                )}
            </Styled.Value>
            {isValidCidr === 'invalid_subnet' && (
                <Styled.WarningMessage>
                    Specify a private range in CIDR notation eg 10.1.2.0/24
                </Styled.WarningMessage>
            )}
        </>
    )
}
