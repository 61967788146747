import {CardArea} from '../../common/cards/card-area.styled'
import {NoIndicatorDataCard} from './no-indicator-data-card'

export function NoData(): JSX.Element {
    return (
        <CardArea>
            <NoIndicatorDataCard />
        </CardArea>
    )
}
