import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../theme/theme'

interface ContentContainerMiddleProps {
    width: number
    scrollable?: boolean
    backgroundColor?: ColorType
}

function scrollableStyles(scrollable: boolean | undefined): string | null {
    if (!scrollable) {
        return 'z-index: 100;'
    }

    return 'overflow-y: auto; overflow-x:none; flex: 1; z-index: 0;'
}

export const ContentContainerMiddle = styled.div<ContentContainerMiddleProps>`
    background-color: ${(props) => props.theme.unknownAssets.dataArea.background};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    margin: ${(props) => (props.width > MAX_SCREEN_WIDTH ? `0 ${spacing(2)}` : 0)};
    align-self: center;
    ${(props) => scrollableStyles(props.scrollable)}
`
