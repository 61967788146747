import {OnboardEngagementConfigType} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'

interface SelectableCircleProps {
    configOption: OnboardEngagementConfigType
    previewSelectedConfig: OnboardEngagementConfigType | null
    mouseOverOption: (value: OnboardEngagementConfigType) => void
    selectConfig: (newRating: OnboardEngagementConfigType) => void
    selectedConfig: OnboardEngagementConfigType
    disabled?: boolean
}

const configOptionMap: Record<OnboardEngagementConfigType, number> = {
    off: 1,
    on: 2,
    master: 3,
    crew: 4,
    max: 5,
}

function isButtonSelected(
    configOption: OnboardEngagementConfigType,
    selectedConfig: OnboardEngagementConfigType,
): boolean {
    const convertedConfigOption = configOptionMap[configOption] || 0
    const convertedSelectedConfig = configOptionMap[selectedConfig] || 0
    return convertedConfigOption <= convertedSelectedConfig
}

export function SelectableCircle({
    configOption,
    previewSelectedConfig,
    mouseOverOption,
    selectConfig,
    selectedConfig,
    disabled,
}: SelectableCircleProps): JSX.Element {
    const shownSelectedConfig = previewSelectedConfig || selectedConfig
    const buttonIsSelected = isButtonSelected(configOption, shownSelectedConfig)

    function getCirclePosition(configOption: OnboardEngagementConfigType) {
        return configOption === 'off'
            ? '-2px'
            : configOption === 'on'
              ? '35px'
              : configOption === 'master'
                ? '74px'
                : configOption === 'crew'
                  ? '113px'
                  : ''
    }

    function getFillColor(selected: boolean): string {
        if (!selected) {
            return 'white'
        }
        return '#1F88E5'
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onMouseEnter={(e) => {
                if (disabled) {
                    e.preventDefault()
                    return
                }
                mouseOverOption(configOption)
            }}
            onClick={(e) => {
                if (disabled) {
                    e.preventDefault()
                    return
                }
                selectConfig(configOption)
            }}
            style={{
                position: 'absolute',
                cursor: 'pointer',
                top: '-8px',
                left: getCirclePosition(configOption),
                right: configOption === 'max' ? '-2px' : '',
            }}
            id={`rating-button-${configOption}`}
        >
            <circle
                cx="10"
                cy="10"
                r="9.5"
                fill={getFillColor(buttonIsSelected)}
                stroke="#1F88E5"
                id={`rating-icon-${configOption}`}
            />
        </svg>
    )
}
