import {BarChart, Bar, Tooltip, XAxis} from 'recharts'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {MetricsDetailType} from '../../../../contexts/types/modal-types'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'
import {Panel} from '../_styled/panel-section.styled'
import * as Styled from './assets-affected.styled'
import {AssetStateOverTimestamp, AssetsAffected} from '../../../../contexts/types/metrics-summary'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Button, IconWrapper} from '../expand-panel-button/expand-panel-button.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../../store/state/metrics-filter-beta/selectors'
import moment from 'moment'
import {TimestampFilterType} from '../../../../../../store/state/metrics-filter-beta/state'
import {getStartAndEndDate} from '../../../../contexts/context-data-helper'
import {DataCell} from '../shared/data-cell'
import {formatDate} from '../../../../../../helpers/formatting'

export function AssetsAffectedSection(): JSX.Element {
    const {modalDetailsExpanded, isProcessingMetricDetailsModal} = usePagedMetricsBeta()
    return (
        <Panel>
            <ExpandPanelButton headingRow={MetricsDetailType.Assets} />
            {modalDetailsExpanded.assetsExpanded &&
                (!isProcessingMetricDetailsModal ? <DataLoading /> : <AssetsAffectedList />)}
        </Panel>
    )
}

function AssetsAffectedList(): JSX.Element {
    const {findMetricDetailsForModal, displayMetricsContextData} = usePagedMetricsBeta()

    useEffect(() => {
        displayMetricsContextData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (findMetricDetailsForModal?.assetsAffected?.length === 0) {
        return <Styled.NoAssetsText>No affected assets found</Styled.NoAssetsText>
    }
    return (
        <Styled.AssetsListWrapper id="assets-list-wrapper">
            {findMetricDetailsForModal?.assetsAffected?.map((asset, index) => (
                <AssetSection assetAffected={asset} key={`${asset}-${index}`} />
            ))}
        </Styled.AssetsListWrapper>
    )
}

function AssetSection({assetAffected}: {assetAffected: AssetsAffected}): JSX.Element {
    const [expandAssetView, setExpandAssetView] = useState(false)
    return (
        <Styled.AssetSection>
            <Styled.AssetWrapper>
                <AssetItem asset={assetAffected} />
                <ExpandAssetButton
                    expandAssetView={expandAssetView}
                    setExpandAssetView={setExpandAssetView}
                    assetAffected={assetAffected.asset}
                />
            </Styled.AssetWrapper>
            <MetricBar assetStateDynamics={assetAffected.assetStateDynamics} />
            {expandAssetView && <GetExpandMetricContextSection assetId={assetAffected.asset} />}
        </Styled.AssetSection>
    )
}

interface AssetItemProps {
    asset: AssetsAffected
}

export function AssetItem({asset}: AssetItemProps): JSX.Element {
    return <Styled.AssetSquare>{asset.assetName}</Styled.AssetSquare>
}

interface ExpandAssetButtonProps {
    expandAssetView: boolean
    setExpandAssetView: Dispatch<SetStateAction<boolean>>
    assetAffected: string
}
function ExpandAssetButton({
    expandAssetView,
    setExpandAssetView,
    assetAffected,
}: ExpandAssetButtonProps): JSX.Element {
    return (
        <Button
            onClick={() => {
                setExpandAssetView(!expandAssetView)
            }}
            id={`expand-button-${assetAffected}`}
        >
            <IconWrapper>
                <Icon
                    glyph={expandAssetView ? 'DropdownUp' : 'DropdownDown'}
                    height={15}
                    width={15}
                />
            </IconWrapper>
        </Button>
    )
}
function MetricBar({
    assetStateDynamics,
}: {
    assetStateDynamics: AssetStateOverTimestamp[]
}): JSX.Element {
    const {selectedAnalysisPeriod} = useTypedSelector(metricsBetaFilterSelector)
    const {format} = getTickIntervals(selectedAnalysisPeriod)
    const chartData = assetStateDynamics?.map((item, index) => ({
        name: moment.unix(item.timestamp).format(format),
        timestamp: item.timestamp,
        state: item.value,
        index: index,
    }))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CustomBarShape = (props: any) => {
        const {background, state} = props
        return (
            <rect
                x={background.x}
                y={background.y}
                width={background.width ?? 1}
                height={background.height}
                fill={state === 'OK' ? 'green' : 'red'}
            />
        )
    }

    return (
        <Styled.ChartContainer>
            <BarChart
                width={350}
                height={15}
                data={chartData}
                layout="horizontal"
                margin={{top: 0, right: 0, bottom: 0, left: 0}}
                barGap={1}
                barCategoryGap={1}
            >
                <Tooltip
                    content={<TippyContent chartData={chartData} />}
                    cursor={{fill: 'transparent'}}
                />
                <XAxis hide={true} dataKey="timestamp" type="category" />
                <Bar dataKey="state" shape={<CustomBarShape />} />
            </BarChart>
            <CustomXAxis />
        </Styled.ChartContainer>
    )
}

function CustomXAxis(): JSX.Element {
    const {selectedAnalysisPeriod} = useTypedSelector(metricsBetaFilterSelector)
    const {startDate, endDate} = getStartAndEndDate(selectedAnalysisPeriod)

    const xTicks = generateTicks(startDate, endDate, selectedAnalysisPeriod)
    const tickWidth = 350 / (xTicks?.length - 1)
    const oneTick = xTicks?.length === 1
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '350px',
                paddingTop: '8px',
            }}
        >
            {xTicks.map((tick, index) => (
                <div
                    key={index}
                    style={{
                        position: 'relative',
                        width: tickWidth,
                        opacity: 0.5,
                    }}
                >
                    <Styled.XTick
                        index={index}
                        lastItem={index === xTicks.length - 1}
                        oneTick={oneTick}
                    >
                        <div
                            style={{
                                height: '8px',
                                width: '2px',
                                border: '1px solid black',
                                opacity: '0.2',
                                marginLeft: '50%',
                            }}
                        />
                        {tick}
                    </Styled.XTick>
                </div>
            ))}
        </div>
    )
}
interface TippyContentProps {
    chartData: {
        name: string
        timestamp: number
        state: 'OK' | 'ISSUE'
        index: number
    }[]
    active?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    label?: number
    coordinate?: {
        x: number
        y: number
    }
}
function TippyContent({
    chartData,
    active,
    payload,
    label,
    coordinate,
}: TippyContentProps): JSX.Element | null {
    const dataPoint = chartData?.find((element) => element.timestamp === label)
    if (active && payload && payload.length) {
        const lastIndex = chartData.length - 1
        const isLastElement = dataPoint && dataPoint.index === lastIndex
        return (
            <Styled.TippyWrapper
                isLastElement={isLastElement ?? false}
                xCoordinate={(coordinate?.x ?? 0) - 10}
                yCoordinate={(coordinate?.y ?? 0) - 20}
            >
                <div style={{fontWeight: 'bold'}}>
                    Timestamp: {dataPoint?.timestamp && formatDate(dataPoint?.timestamp)}
                </div>
                <div>Asset state: {dataPoint?.state}</div>
            </Styled.TippyWrapper>
        )
    }
    return null
}

function GetExpandMetricContextSection({assetId}: {assetId: string}): JSX.Element {
    const {loadedMetricContextData} = usePagedMetricsBeta()

    return (
        <>
            {loadedMetricContextData ? (
                <MetricContextData assetId={assetId} />
            ) : (
                <LoadingMetricContextData />
            )}
        </>
    )
}

function LoadingMetricContextData(): JSX.Element {
    return (
        <>
            <DataLoading size={80} />
            <Styled.AssetsText>Fetching data - please wait</Styled.AssetsText>
        </>
    )
}

function MetricContextData({assetId}: {assetId: string}): JSX.Element {
    const {metricContextData} = usePagedMetricsBeta()
    const getMetricContextDataForGivenAsset = metricContextData?.get(assetId)
    if (!getMetricContextDataForGivenAsset) {
        return <Styled.AssetsText>No context data</Styled.AssetsText>
    }
    const filterKeys = (contextData: Record<string, string>) => {
        const keysToFilter = ['from', 'to', 'asset', 'location']
        return Object.entries(contextData).filter(([key]) => !keysToFilter.includes(key))
    }
    return (
        <Styled.DetailedAreaWrapper>
            {getMetricContextDataForGivenAsset?.map((contextData, index) => (
                <Styled.DetailsAreaContent key={index}>
                    {filterKeys(contextData).map(([key, value]) => (
                        <DataCell key={key} label={`${key}:`}>
                            {value}
                        </DataCell>
                    ))}
                    <DataCell label="First seen:">{formatDate(contextData.from)}</DataCell>
                    <DataCell label="Last seen:">{formatDate(contextData.to)}</DataCell>
                </Styled.DetailsAreaContent>
            ))}
        </Styled.DetailedAreaWrapper>
    )
}
const getTickIntervals = (period: TimestampFilterType) => {
    switch (period) {
        case 'latest':
            return {interval: 0, unit: 'latest', format: 'HH:mm'}
        case '2h':
            return {interval: 1, unit: 'hours', format: 'HH:mm'}
        case '1d':
            return {interval: 3, unit: 'hours', format: 'HH:mm'}
        case '7d':
            return {interval: 1, unit: 'days', format: 'MM/DD'}
        case '30d':
            return {interval: 5, unit: 'days', format: 'MM/DD'}
        case '14d':
            return {interval: 2, unit: 'days', format: 'MM/DD'}
        case '71d':
            return {interval: 10, unit: 'days', format: 'MM/DD'}
        case '365d':
            return {interval: 2, unit: 'months', format: 'MM/YY'}
        default:
            return {interval: 1, unit: 'days', format: 'MM/DD'}
    }
}

// Generate ticks based on the selected period
const generateTicks = (startDate: Date, endDate: Date, period: TimestampFilterType): string[] => {
    const {interval, unit, format} = getTickIntervals(period)
    const ticks: string[] = []
    const currentDate = moment(startDate)
    if (unit === 'latest') {
        ticks.push(currentDate.format(format))
    } else {
        while (currentDate <= moment(endDate)) {
            ticks.push(currentDate.format(format))
            currentDate.add(interval, unit as moment.unitOfTime.DurationConstructor)
        }
    }

    return ticks
}
