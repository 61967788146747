import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {useToggle} from '../../../../contexts/toggle/use-toggle'

export function CloseFilterButton(): JSX.Element {
    const {setValue: setShowFilter} = useToggle()

    return (
        <Button onClick={() => setShowFilter(false)} id="close-filter-button">
            <XCircle />
            Close Filter
        </Button>
    )
}
