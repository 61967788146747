import * as Styled from './network-asset-details-extended.styled'
import * as VesselStyled from './vessel-status-panel.styled'
import * as AssetStyled from './asset-details-panel.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {vesselTagsForLocationsSelector} from '../../../../../store/state/vessel-tags/selectors'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NetworkAssetModalOutputModel} from '../../../models/network-asset-modal.model'
import {NoDataCard} from '../no-data-card/no-data-card'
import {DataCell} from './data-cell'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {
    NetworkAssetMonitoringState,
    NetworkAssetState,
} from '../../../context/types/isolated-assets-type'
import {getImageForNetworkAssetState} from '../../../context/helpers'
import {StringUtils} from '../../../../../utils/Utils'
import {VesselTag} from '../../../../../components/vessel-tag/vessel-tag'
import {BehaviourStatus} from '../../shared/behaviour-status/behaviour-status'
import CIRCLE_MONITORED from '../../../../../@assets/icons/circle-monitored.svg'
import MAIL_ICON from '../../../../../@assets/icons/mail-icon.svg'
import {useDispatch} from 'react-redux'
import {openModal} from '../../../../../store/state/extended-data-request/action-creators'
import {InventoryType} from '../../../../../store/state/extended-data-request/state'
import {NetworkAssetsDetailType} from '../../../../../store/state/network-assets-filter/state'

export function NetworkAssetDetailsExpanded(): JSX.Element | null {
    const {
        findNetworkAsset,
        isolatedAssetsStatus,
        displayEnableMonitoringModal,
        setCurrentLocationCode,
        currentAssetData,
        networkAssetBehaviourData,
    } = usePagedUnknownAssetsBeta()
    const locationMap = useTypedSelector(locationMapSelector)
    const vesselTags = useTypedSelector(vesselTagsForLocationsSelector)
    const {width} = useDimensions()
    const dispatch = useDispatch()

    if (!findNetworkAsset) {
        return <NoDataCard text="No network assets found." />
    }

    const model = new NetworkAssetModalOutputModel(
        findNetworkAsset,
        locationMap,
        isolatedAssetsStatus,
        vesselTags,
        currentAssetData,
    )

    if (!model) {
        return null
    }

    const isAllowed =
        model.monitoringState === NetworkAssetMonitoringState.UNKNOWN &&
        model.state !== NetworkAssetState.BLOCKED

    const onClickWrapper = (): void => {
        displayEnableMonitoringModal(true)
        setCurrentLocationCode(model.locationCode)
    }
    const behaviourNameToMap = networkAssetBehaviourData?.map(
        (behaviour) => behaviour.behaviour.displayName,
    )
    const behaviorsToDisplay = behaviourNameToMap.length !== 0
    return (
        <Styled.DetailsSection id={`network-assets-details-card-extended_${model?.id}`}>
            <VesselStyled.VesselStatusAreaWrapper
                id={`network-assets-details-card-extended_${model?.id}_wrapper`}
            >
                <VesselStyled.VesselStatusAreaContent
                    id={`network-assets-details-card-extended_${model?.id}_content`}
                >
                    <DataCell label="Status:" gridArea="VESSEL-STATUS">
                        {StringUtils.capitaliseString(model.state)}{' '}
                        <img src={getImageForNetworkAssetState(model.state)} />
                    </DataCell>
                    <VesselStyled.TagItemsWrapper
                        gridArea="VESSEL-TAG"
                        width={width}
                        id={`network-assets-details-card-extended_${model?.id}_TagItemsWrapper`}
                    >
                        {model.vesselTags.map((tag, index) => (
                            <VesselTag key={index} tagName={tag.name} showHover={true} />
                        ))}
                    </VesselStyled.TagItemsWrapper>
                </VesselStyled.VesselStatusAreaContent>
            </VesselStyled.VesselStatusAreaWrapper>
            <Styled.ModalTitle
                id={`network-assets-details-card-extended_${model?.id}_ModalTitle-1`}
            >
                {NetworkAssetsDetailType.AssetDetailsState}
            </Styled.ModalTitle>
            <AssetStyled.AssetDetailsAreaWrapper
                id={`network-assets-details-card-extended_${model?.id}_AreaWrapper-1`}
            >
                <AssetStyled.AssetDetailsAreaContent
                    id={`network-assets-details-card-extended_${model?.id}_AreaContent-1`}
                >
                    <DataCell label="VLAN:" gridArea="VLAN">
                        {model.vlan ?? 'Unknown'}
                    </DataCell>
                    <DataCell label="MAC Address:" gridArea="MAC-ADDRESS">
                        {model.macAddress ?? 'Unknown'}
                    </DataCell>
                    <DataCell label="First detected:" gridArea="FIRST-DETECTED">
                        {model.firstDetected ?? 'Unknown'}
                    </DataCell>
                    <DataCell label="Last active:" gridArea="LAST-ACTIVE">
                        {model.lastActive ?? 'Unknown'}
                    </DataCell>
                </AssetStyled.AssetDetailsAreaContent>
            </AssetStyled.AssetDetailsAreaWrapper>
            <Styled.ModalTitle
                id={`network-assets-details-card-extended_${model?.id}_ModalTitle-2`}
            >
                {NetworkAssetsDetailType.AssetInsightsState}
            </Styled.ModalTitle>
            <AssetStyled.AssetDetailsAreaWrapper
                id={`network-assets-details-card-extended_${model?.id}_AreaWrapper-2`}
            >
                <AssetStyled.AssetBehaviourAreaContent
                    id={`network-assets-details-card-extended_${model?.id}_AreaContent-2`}
                >
                    <AssetStyled.DescriptionText>
                        Detected behaviours summary
                    </AssetStyled.DescriptionText>

                    <AssetStyled.BehaviourList
                        id={`network-assets-details-card-extended_${model?.id}_BehaviourList`}
                    >
                        {behaviorsToDisplay ? (
                            <>
                                {behaviourNameToMap?.map((name, index) => (
                                    <BehaviourStatus
                                        key={index}
                                        displayName={name}
                                        cardView={true}
                                    />
                                ))}
                            </>
                        ) : (
                            <div style={{fontStyle: 'italic', fontSize: '14px'}} id="no-behaviour">
                                No behaviour detected on this asset
                            </div>
                        )}
                    </AssetStyled.BehaviourList>
                </AssetStyled.AssetBehaviourAreaContent>
            </AssetStyled.AssetDetailsAreaWrapper>

            <Styled.ModalTitle
                id={`network-assets-details-card-extended_${model?.id}_ModalTitle-2`}
            >
                {NetworkAssetsDetailType.AssetActionsState}
            </Styled.ModalTitle>
            <AssetStyled.AssetDetailsAreaWrapper
                id={`network-assets-details-card-extended_${model?.id}_AreaWrapper-3`}
            >
                {isAllowed && (
                    <Styled.ActionLabel
                        id={`network-assets-details-card-extended_${model?.id}_ActionLabel-1`}
                    >
                        Monitor this asset:
                        <Styled.ActionsTextLink
                            onClick={() => onClickWrapper()}
                            id={`network-assets-details-card-extended_${model?.id}_ActionsTextLink-1`}
                        >
                            <img src={CIRCLE_MONITORED} style={{marginRight: '5px'}} />
                            Enable monitoring
                        </Styled.ActionsTextLink>
                    </Styled.ActionLabel>
                )}

                <Styled.ActionLabel
                    id={`network-assets-details-card-extended_${model?.id}_ActionLabel-2`}
                >
                    Get help with this asset:
                    <Styled.ActionsTextLink
                        onClick={() =>
                            dispatch(
                                openModal(InventoryType.NETWORK, model.deviceName, [
                                    findNetworkAsset.location,
                                ]),
                            )
                        }
                        id={`network-assets-details-card-extended_${model?.id}_ActionsTextLink-2`}
                    >
                        <img src={MAIL_ICON} style={{marginRight: '5px'}} />
                        Contact SOC
                    </Styled.ActionsTextLink>
                </Styled.ActionLabel>
            </AssetStyled.AssetDetailsAreaWrapper>
        </Styled.DetailsSection>
    )
}
