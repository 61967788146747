import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useUserManagement} from '../../context/use-user-management'
import * as Styled from './_styled/user-management-list-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    const {activeUserId} = useUserManagement()

    return (
        <Styled.TableHeaderGrid width={width} showDetailsBtn={activeUserId == null}>
            <Styled.TableHeaderEmailCell gridColumn={2} width={width}>
                Name
            </Styled.TableHeaderEmailCell>
            <Styled.TableHeaderEmailCell gridColumn={3} width={width}>
                Email
            </Styled.TableHeaderEmailCell>
            <Styled.TableHeaderEmailCell gridColumn={4} width={width}>
                Roles
            </Styled.TableHeaderEmailCell>
            <Styled.TableHeaderEmailCell gridColumn={5} width={width}>
                Tag
            </Styled.TableHeaderEmailCell>
            <Styled.TableHeaderCell gridColumn={6} width={width}>
                Subscriptions
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={7} width={width} />
        </Styled.TableHeaderGrid>
    )
}
