import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const VesselStatusAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
    border-bottom: ${(props) => `2px solid ${props.theme.myVessels.filterBar.titleSeparator}`};
`

export const VesselStatusAreaContent = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    gap: ${spacing(1)};
`

export const TagItemsWrapper = styled.div`
    display: flex;
    gap: ${spacing(1)};
    padding: ${spacing(2)};
`
