import {ReactNode, useReducer} from 'react'
import {PagedUnknownAssetsBetaContext} from './paged-unknown-assets-context'
import {pagedUnknownAssetsBetaReducer} from './state/reducer'
import {DEFAULT_PAGED_UNKNOWN_ASSETS_BETA_STATE} from './types/default-paged-unknown-assets-state'
import {PagedUnknownAssetsBetaState} from './types/paged-unknown-assets-state'
import {cloneDeep} from 'lodash'

type PagedUnknownAssetsProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
    acEnabled: boolean
}

export function PagedUnknownAssetsBetaProvider({
    children,
    pageSize,
    acEnabled,
}: PagedUnknownAssetsProviderProps): JSX.Element {
    const initialState: PagedUnknownAssetsBetaState = cloneDeep(
        DEFAULT_PAGED_UNKNOWN_ASSETS_BETA_STATE,
    )
    initialState.pageSize = pageSize
    initialState.acEnabled = acEnabled

    const [state, dispatch] = useReducer(pagedUnknownAssetsBetaReducer, initialState)

    return (
        <PagedUnknownAssetsBetaContext.Provider value={{state, dispatch}}>
            {children}
        </PagedUnknownAssetsBetaContext.Provider>
    )
}
