import {EtsTrendScore} from '../../../values/ets-trend-score/EtsTrendScore'
import AppState from '../../types/app-state'
import {EtsTrendReduxState} from './state'

export const etsTrendSelector = (state: AppState): EtsTrendReduxState => state.etsTrend

export const isFetching12HourTrendDataSelector = (state: AppState): boolean =>
    state.etsTrend.isFetchingLast12H

export const isFetching24HourTrendDataSelector = (state: AppState): boolean =>
    state.etsTrend.isFetchingLast24H

export const isFetching7DaysTrendDataSelector = (state: AppState): boolean =>
    state.etsTrend.isFetchingLast7D

export const isFetching30DaysTrendDataSelector = (state: AppState): boolean =>
    state.etsTrend.isFetchingLast30D

export const isFetching90DaysTrendDataSelector = (state: AppState): boolean =>
    state.etsTrend.isFetchingLast90D

export const etsTrend12HourDataSelector = (state: AppState): EtsTrendScore[] =>
    state.etsTrend.trendLast12H

export const etsTrend24HourDataSelector = (state: AppState): EtsTrendScore[] =>
    state.etsTrend.trendLast24H

export const etsTrend7DaysDataSelector = (state: AppState): EtsTrendScore[] =>
    state.etsTrend.trendLast7D

export const etsTrend30DaysDataSelector = (state: AppState): EtsTrendScore[] =>
    state.etsTrend.trendLast30D

export const etsTrend90DaysDataSelector = (state: AppState): EtsTrendScore[] =>
    state.etsTrend.trendLast90D
