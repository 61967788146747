import styled from 'styled-components'

interface ContainerProps {
    last: boolean
}

export const Container = styled.div<ContainerProps>`
    margin-top: 17px;
    margin-bottom: ${(props) => (props.last ? '17px' : 0)};
`
