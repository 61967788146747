import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'

interface VesselsCountProps {
    gridLayout: GridLayout
}
export function VesselsCount({gridLayout}: VesselsCountProps): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    return (
        <ValueForType
            type="Vessels"
            value={locations.length}
            gridLayout={gridLayout}
            id="vessels-at-risk-vessels-count"
        />
    )
}
