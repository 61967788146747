import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const SubmitArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`

export const SaveChangesSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${spacing(2)} 0 0;
`
export const SaveButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    height: 30px;
    width: 150px;
    ${smallFont()}
    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    :hover:not([disabled]) {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`

interface MessageAreaProps {
    saved: boolean
}

export const MessageArea = styled.label<MessageAreaProps>`
    color: ${(props) =>
        props.saved ? props.theme.colors.text.success : props.theme.colors.text.error};
    ${smallFont()};
    margin: ${spacing(1)};
    display: flex;
    align-items: center;
`
