import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {DEFAULT_PAGED_INCIDENTS_REPORT_STATE} from './types/default-paged-incidents-report-state'
import {pagedIncidentsReportReducer} from './state/reducer'
import {PagedIncidentsReportState} from './types/paged-incidents-report-state'
import {PagedIncidentsReportBetaContext} from './paged-incidents-report-context'

type PagedIncidentsReportProviderProps = {
    children: ReactNode | ReactNode[]
}

export function PagedIncidentsReportProvider({
    children,
}: PagedIncidentsReportProviderProps): JSX.Element {
    const initialState: PagedIncidentsReportState = cloneDeep(DEFAULT_PAGED_INCIDENTS_REPORT_STATE)

    const [state, dispatch] = useReducer(pagedIncidentsReportReducer, initialState)

    return (
        <PagedIncidentsReportBetaContext.Provider value={{state, dispatch}}>
            {children}
        </PagedIncidentsReportBetaContext.Provider>
    )
}
