import {usePagedVesselsBeta} from '../../contexts/use-paged-vessels-beta'
import * as Styled from './_styled/table-header.styled'
import EXPAND_COLUMNS from './_styled/expand-columns.svg'
import {getDisplayColumnName} from '../../contexts/context-data-helper'
import {TimestampFilterType, getTableHeaderDisplayName} from '../../contexts/types/timestamp-filter'
import {PossibleColumnsVesselBeta} from '../../contexts/types/paged-vessels-beta-state'
import {getLines} from '../../../metrics-beta/components/data-helper'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {SortColumnType, getNewSortByDirection} from '../../contexts/types/sort-column'
import {logSortByColumn} from '../../../../store/state/audit-log/action-creators'
import {useDispatch} from 'react-redux'
import {vesselsBetaFilterSelector} from '../../../../store/state/vessels-beta-filter/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {setSortColumn} from '../../../../store/state/vessels-beta-filter/action-creators'

export function TableHeader(): JSX.Element {
    const {numberOfColumns, toggleHighlightedValue} = usePagedVesselsBeta()
    const {selectedColumns, analysisPeriod, sortColumn} =
        useTypedSelector(vesselsBetaFilterSelector)

    const defaultState = numberOfColumns === 0
    const dispatch = useDispatch()
    function setSortBy(selectedColumn: PossibleColumnsVesselBeta) {
        const newSortColumn: SortColumnType = {
            orderAscending: getNewSortByDirection(selectedColumn, sortColumn),
            orderBy: selectedColumn,
        }
        dispatch(setSortColumn(newSortColumn))
        dispatch(logSortByColumn(newSortColumn))
    }

    return (
        <Styled.TableHeaderGrid numberOfColumns={numberOfColumns} id="table-header-grid">
            <Styled.TableHeaderCell gridColumn={2} id="vessel-name-column">
                Vessel
            </Styled.TableHeaderCell>
            {defaultState && (
                <Styled.TableHeaderCell
                    gridColumn={3}
                    defaultChecked={defaultState}
                    onClick={() => {
                        toggleHighlightedValue(true)
                    }}
                    id="select-columns"
                >
                    <img src={EXPAND_COLUMNS} style={{width: '14px', height: '14px'}} />
                    Select columns
                </Styled.TableHeaderCell>
            )}
            {selectedColumns?.map((selectedColumn, index) => (
                <Styled.TableHeaderCell
                    key={selectedColumn}
                    gridColumn={3 + index}
                    onClick={() => setSortBy(selectedColumn)}
                    defaultChecked={sortColumn.orderBy === selectedColumn}
                    id={`sortable-column_${selectedColumn}`}
                >
                    <Styled.Text id={`text_${selectedColumn}`}>
                        {getLines(getFormattedColumnHeader(selectedColumn, analysisPeriod)).map(
                            (line, index) => (
                                <div key={index}>{line}</div>
                            ),
                        )}
                    </Styled.Text>
                    <Styled.IconStyled
                        arrowDec={
                            sortColumn.orderBy === selectedColumn && !sortColumn.orderAscending
                        }
                    >
                        <Icon width={12} height={17} glyph="Arrow" />
                    </Styled.IconStyled>
                </Styled.TableHeaderCell>
            ))}
        </Styled.TableHeaderGrid>
    )
}
function getFormattedColumnHeader(
    selectedColumn: PossibleColumnsVesselBeta,
    analysisPeriod: TimestampFilterType,
): string {
    const displayColumnName = getDisplayColumnName(selectedColumn)
    switch (selectedColumn) {
        case PossibleColumnsVesselBeta.NewInventoryAllTypes:
        case PossibleColumnsVesselBeta.TotalInventoryAllTypes:
        case PossibleColumnsVesselBeta.UntrustedInventoryAllTypes:
        case PossibleColumnsVesselBeta.TrustedInventoryAllTypes:
        case PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork:
        case PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork:
        case PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets:
        case PossibleColumnsVesselBeta.NumberOfIncidentsRaised:
        case PossibleColumnsVesselBeta.NumberOfIncidentsOpen:
        case PossibleColumnsVesselBeta.IncidentsBySeverityHigh:
        case PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod:
        case PossibleColumnsVesselBeta.IncidentsRaisedComparedToWholeFleet:
        case PossibleColumnsVesselBeta.NumberOfIncidentsAssignedToVessel:
        case PossibleColumnsVesselBeta.FrameworkProtection:
        case PossibleColumnsVesselBeta.FrameworkMaintenance:
        case PossibleColumnsVesselBeta.FrameworkBehaviour:
        case PossibleColumnsVesselBeta.AssetsWithWorstMetrics:
        case PossibleColumnsVesselBeta.FrameworkSummaryScorecard:
        case PossibleColumnsVesselBeta.FrameworkSummaryTarget:
        case PossibleColumnsVesselBeta.FrameworkSummaryBenchmark:
        case PossibleColumnsVesselBeta.FrameworkSummaryTrend:
        case PossibleColumnsVesselBeta.AssetsAtRisk:
        case PossibleColumnsVesselBeta.AssetsAtRiskHighValue:
            return `${displayColumnName} -\n Period: ${getTableHeaderDisplayName(analysisPeriod)}`
        case PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod:
            return `New inventory (all types) -\n Previous period: ${getTableHeaderDisplayName(
                analysisPeriod,
            )}`
        case PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents:
        case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents:
        case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidentsAverageTime:
        case PossibleColumnsVesselBeta.OldestOpenIncident:
        case PossibleColumnsVesselBeta.CurrentVesselScore:
        case PossibleColumnsVesselBeta.NumberOfDaysSinceLastData:
        case PossibleColumnsVesselBeta.NumberOfDaysSinceLastNetworkData:
        case PossibleColumnsVesselBeta.DeploymentSummary:
        default:
            return displayColumnName
    }
}
