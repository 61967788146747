import {ReactNode, useReducer} from 'react'
import {DEFAULT_METRIC_WIDGET_STATE} from './types/default-metrics-widget-state'
import {metricsWidgetReducer} from './state/reducer'
import {MetricsWidgetContext} from './metrics-widget-context'

type MetricsWidgetProviderProps = {
    children: ReactNode | ReactNode[]
}

export function MetricsWidgetProvider({children}: MetricsWidgetProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(metricsWidgetReducer, DEFAULT_METRIC_WIDGET_STATE)

    return (
        <MetricsWidgetContext.Provider value={{state, dispatch}}>
            {children}
        </MetricsWidgetContext.Provider>
    )
}
