import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ContentContainer} from '../../vessel-list-table/_styled/vessel-management-list.styled'
// import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
// import {AddNewIncindentPolicyDetailExpanded} from '../../policy-list-card/incident-policy-detail-expanded/add-new-incident-policy-detail-expanded'
// import {showCards} from '../../policy-list-table/_styled/policy-management-list-header.styled'
// import {AddNewPolicyDetailsModal} from '../../policy-list-table/policy-details-modal/add-new-policy-details-modal'
import {NoDataMessage} from './no-data-message.styled'

interface NoDataProps {
    message: string
}

export function NoData({message}: NoDataProps): JSX.Element {
    const {width} = useDimensions()
    // const {showPolicyModal} = usePolicyManagement()
    // const addInCardView = showPolicyModal && showCards(width, true)

    return (
        <ContentContainer width={width}>
            {/* {addInCardView ? (
                <AddNewIncindentPolicyDetailExpanded />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flex: 1,
                    }}
                > */}
            <NoDataMessage id="no-data-component">{message}</NoDataMessage>
            {/* {showPolicyModal && <AddNewPolicyDetailsModal />}
                </div>
            )} */}
        </ContentContainer>
    )
}
