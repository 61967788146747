import {HighlightedFilerValueType} from '../../../contexts/types/highlighted-filter-type'
import {usePagedIncidentsReport} from '../../../contexts/use-paged-incidents-report'
import {LinkText} from './searched-period.styled'

interface ClickableHeaderFilterProps {
    name: HighlightedFilerValueType
    value: number | string
}
export function ClickableHeaderFilter({name, value}: ClickableHeaderFilterProps): JSX.Element {
    const {displayFilterBar, showFilterBar, displayHighlightedFilterValue} =
        usePagedIncidentsReport()

    return (
        <LinkText
            onClick={() => {
                !showFilterBar && displayFilterBar(!showFilterBar)
                displayHighlightedFilterValue(name)
            }}
            id={`clickable-header-filter-${name}`}
        >
            {name} ({value})
        </LinkText>
    )
}
