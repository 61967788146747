import {PossibleColumnsVesselBeta} from './paged-vessels-beta-state'

export interface SortColumnType {
    orderBy: PossibleColumnsVesselBeta
    orderAscending: boolean
}
export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.orderBy) {
        return false
    }
    switch (currentSortColumn.orderAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}
