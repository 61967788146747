import {ResponsiveContainer, Sankey, Tooltip} from 'recharts'
import {IncidentVesselsPerformanceType} from '../../contexts/types/incidents-report-output'
import {NoDataComponent} from '../../incidents-report-page.styled'
import {getIncidentsStatusColour} from '../status-of-incidents-raised/incidents-count.styled'
import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'
import {useTheme} from 'styled-components'

type SanKeyProps = {
    index: number
    x: number
    y: number
    width: number
    height: number
    sourceX: number
    sourceY: number
    sourceControlX: number
    targetControlX: number
    targetY: number
    targetX: number
    linkWidth: string
    payload: {
        color: string
        stroke: string
        name: string
        value: string
    }
}
export function VesselPerformanceWidget({
    incidentsByVesselPerformance,
}: {
    incidentsByVesselPerformance: IncidentVesselsPerformanceType
}): JSX.Element {
    const totalNumberOfIncidents = sumIncidentCategory(
        incidentsByVesselPerformance?.assignedToVessel,
    )
    if (totalNumberOfIncidents === 0) {
        return <NoDataComponent>No data to display</NoDataComponent>
    }
    const sankeyData = formatDataSankeyArray(incidentsByVesselPerformance)

    const MyCustomNodeComponent = (props: SanKeyProps) => {
        function getPositionOfTheText(index: number): string {
            if (index === 0) {
                return 'start'
            } else if (index === 1 || index === 2) {
                return 'middle'
            } else {
                return 'end'
            }
        }
        const positionOfTheText = getPositionOfTheText(props.index)
        const positionOfTheX = props.index === 0 ? props.x + 10 : props.x
        const positionOfY =
            props.index === 0 ? props.y + props.height / 2 - 5 : props.y + props.height / 2 + 5
        return (
            <g className="custom-node">
                <path
                    fill={props.payload.color}
                    fillOpacity={1}
                    stroke={props.payload.stroke}
                    strokeWidth={1}
                    x={props.x}
                    y={props.y}
                    width={props.width}
                    height={props.height}
                    radius={0}
                    className="recharts-rectangle recharts-sankey-node"
                    d={`M${props.x},${props.y} h ${props.width} v ${props.height} h -${props.width} Z`}
                />
                <text
                    x={positionOfTheX}
                    y={positionOfY}
                    textAnchor={positionOfTheText}
                    dominantBaseline={positionOfTheText}
                    fill="#000"
                    fontSize="12px"
                    fontWeight={600}
                >
                    {`${props.payload.name}: ${props.payload.value}`}
                </text>
            </g>
        )
    }
    const MyCustomLinkComponent = (props: SanKeyProps) => {
        return (
            <path
                d={`M${props.sourceX},${props.sourceY}C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}`}
                fill="none"
                stroke={props.payload.stroke}
                strokeWidth={props.linkWidth}
                strokeOpacity={0.3}
            />
        )
    }
    return (
        <ResponsiveContainer minHeight={150}>
            <Sankey
                dataKey="value"
                node={MyCustomNodeComponent}
                link={MyCustomLinkComponent}
                data={sankeyData}
                margin={{
                    left: 10,
                    right: 20,
                    top: 0,
                    bottom: 0,
                }}
                width={740}
                height={150}
                linkCurvature={0.3}
            >
                <Tooltip isAnimationActive={false} />
            </Sankey>
        </ResponsiveContainer>
    )
}

function formatDataSankeyArray(incidentsVesselsPerformance: IncidentVesselsPerformanceType) {
    const theme = useTheme()
    const totalNumberOfIncidents = sumIncidentCategory(
        incidentsVesselsPerformance?.assignedToVessel,
    )
    const viewedByVessel = sumIncidentResolvedStatus(
        incidentsVesselsPerformance?.assignedToVessel?.viewedByVessel,
    )
    const notViewedByVessel = sumIncidentResolvedStatus(
        incidentsVesselsPerformance?.assignedToVessel?.notViewedByVessel,
    )
    const notResolvedByVessel =
        sumIncidentsByStatusCounts(
            incidentsVesselsPerformance?.assignedToVessel?.viewedByVessel?.notResolved,
        ) +
        sumIncidentsByStatusCounts(
            incidentsVesselsPerformance?.assignedToVessel?.notViewedByVessel?.notResolved,
        )
    const resolvedByVessel =
        sumIncidentsByStatusCounts(
            incidentsVesselsPerformance?.assignedToVessel?.viewedByVessel?.resolved,
        ) +
        sumIncidentsByStatusCounts(
            incidentsVesselsPerformance?.assignedToVessel?.notViewedByVessel?.resolved,
        )
    // Calculate the sums for NEW, OPEN, CLOSED in both resolved and notResolved categories
    const totalNewResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'NEW',
        'resolved',
    )
    const totalOpenResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'OPEN',
        'resolved',
    )
    const totalClosedResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'CLOSED',
        'resolved',
    )

    const totalNewNotResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'NEW',
        'notResolved',
    )
    const totalOpenNotResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'OPEN',
        'notResolved',
    )
    const totalClosedNotResolved = sumSpecificStatusInCategory(
        incidentsVesselsPerformance.assignedToVessel,
        'CLOSED',
        'notResolved',
    )
    const allNodes = [
        {
            name: 'Assigned to vessels',
            value: totalNumberOfIncidents,
            color: '#740165',
            stroke: '#740165',
        },
        {
            name: 'Viewed by vessels',
            value: viewedByVessel,
            color: '#7A87AE',
            stroke: '#7A87AE',
        },
        {
            name: 'Not viewed by vessels',
            value: notViewedByVessel,
            color: '#FCCCD2',
            stroke: '#FCCCD2',
        },
        {
            name: 'Resolved',
            value: resolvedByVessel,
            color: '#B5E793',
            stroke: '#B5E793',
        },
        {
            name: 'Not resolved',
            value: notResolvedByVessel,
            color: '#85C1F9',
            stroke: '#85C1F9',
        },
        {
            name: 'Status Open',
            value: totalOpenResolved + totalOpenNotResolved,
            color: getIncidentsStatusColour(IncidentStatus.OPEN, theme),
            stroke: getIncidentsStatusColour(IncidentStatus.OPEN, theme),
        },
        {
            name: 'Status Closed',
            value: totalClosedResolved + totalClosedNotResolved,
            color: getIncidentsStatusColour(IncidentStatus.CLOSED, theme),
            stroke: getIncidentsStatusColour(IncidentStatus.CLOSED, theme),
        },
        {
            name: 'Status New',
            value: totalNewResolved + totalNewNotResolved,
            color: getIncidentsStatusColour(IncidentStatus.NEW, theme),
            stroke: getIncidentsStatusColour(IncidentStatus.NEW, theme),
        },
    ]

    const allLinks = [
        {
            source: 0,
            target: 1,
            value: viewedByVessel,
            stroke: '#7A87AE',
        }, // Total incidents assigned to vessel -> Incidents viewed
        {source: 0, target: 2, value: notViewedByVessel, stroke: '#FCCCD2'}, // Total incidents assigned to vessel -> Incidents not viewed
        {
            source: 1,
            target: 3,
            value: sumIncidentsByStatusCounts(
                incidentsVesselsPerformance?.assignedToVessel?.viewedByVessel?.resolved,
            ),
            stroke: '#B5E793',
        }, // Incidents viewed -> Incidents resolved
        {
            source: 1,
            target: 4,
            value: sumIncidentsByStatusCounts(
                incidentsVesselsPerformance?.assignedToVessel?.viewedByVessel?.notResolved,
            ),
            stroke: '#85C1F9',
        }, // Incidents viewed -> Pending resolution
        {
            source: 2,
            target: 3,
            value: sumIncidentsByStatusCounts(
                incidentsVesselsPerformance?.assignedToVessel?.notViewedByVessel?.resolved,
            ),
            stroke: '#B5E793',
        }, // Incidents not viewed -> Incidents resolved
        {
            source: 2,
            target: 4,
            value: sumIncidentsByStatusCounts(
                incidentsVesselsPerformance?.assignedToVessel?.notViewedByVessel?.notResolved,
            ),
            stroke: '#85C1F9',
        }, // Incidents not viewed -> Pending resolution
        {
            source: 3,
            target: 6,
            value: totalClosedResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.CLOSED, theme),
        }, // Incidents resolved -> Closed
        {
            source: 3,
            target: 5,
            value: totalOpenResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.OPEN, theme),
        }, // Incidents resolved -> Open
        {
            source: 3,
            target: 7,
            value: totalNewResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.NEW, theme),
        }, // Incidents resolved -> New
        {
            source: 4,
            target: 6,
            value: totalClosedNotResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.CLOSED, theme),
        }, // Incidents  not resolved -> Closed
        {
            source: 4,
            target: 5,
            value: totalOpenNotResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.OPEN, theme),
        }, // Incidents  not resolved -> Open
        {
            source: 4,
            target: 7,
            value: totalNewNotResolved,
            stroke: getIncidentsStatusColour(IncidentStatus.NEW, theme),
        }, // Incidents  not resolved -> New
    ]

    // Filter nodes with value > 0
    const filteredNodes = allNodes.filter((node) => node.value > 0)

    // Create a map of node names to their indices in the filteredNodes array
    const nodeNameToIndex = new Map(filteredNodes.map((node, index) => [node.name, index]))

    // Filter links with value > 0 and ensure source and target nodes exist in the filtered nodes list
    const filteredLinks = allLinks
        .filter(
            (link) =>
                link.value > 0 &&
                nodeNameToIndex.has(allNodes[link.source].name) &&
                nodeNameToIndex.has(allNodes[link.target].name),
        )
        .map((link) => {
            const sourceIndex = nodeNameToIndex.get(allNodes[link.source].name)
            const targetIndex = nodeNameToIndex.get(allNodes[link.target].name)

            if (sourceIndex !== undefined && targetIndex !== undefined) {
                return {
                    ...link,
                    source: sourceIndex,
                    target: targetIndex,
                }
            }
            return null
        })
        .filter((link) => link !== null) as {
        source: number
        target: number
        value: number
        stroke: string
    }[]
    return {
        nodes: filteredNodes,
        links: filteredLinks,
    }
}
type IncidentCounts = {
    CLOSED?: number
    NEW?: number
    OPEN?: number
}

type IncidentResolvedStatus = {
    notResolved?: IncidentCounts
    resolved?: IncidentCounts
}

type IncidentCategory = {
    viewedByVessel?: IncidentResolvedStatus
    notViewedByVessel?: IncidentResolvedStatus
}

function sumIncidentsByStatusCounts(incidentCounts?: IncidentCounts): number {
    if (!incidentCounts) {
        return 0
    }
    return (incidentCounts?.CLOSED ?? 0) + (incidentCounts?.NEW ?? 0) + (incidentCounts?.OPEN ?? 0)
}

function sumIncidentResolvedStatus(incidentStatus?: IncidentResolvedStatus): number {
    if (!incidentStatus) {
        return 0
    }
    return (
        sumIncidentsByStatusCounts(incidentStatus?.notResolved) +
        sumIncidentsByStatusCounts(incidentStatus?.resolved)
    )
}

function sumIncidentCategory(incidentCategory?: IncidentCategory): number {
    if (!incidentCategory) {
        return 0
    }
    return (
        sumIncidentResolvedStatus(incidentCategory?.viewedByVessel) +
        sumIncidentResolvedStatus(incidentCategory?.notViewedByVessel)
    )
}

function sumSpecificStatusInCategory(
    incidentCategory: IncidentCategory,
    statusType: keyof IncidentCounts,
    resolutionStatus: 'resolved' | 'notResolved',
): number {
    if (!incidentCategory) {
        return 0
    }
    return (
        (incidentCategory?.viewedByVessel?.[resolutionStatus]?.[statusType] ?? 0) +
        (incidentCategory?.notViewedByVessel?.[resolutionStatus]?.[statusType] ?? 0)
    )
}
