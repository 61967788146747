import {ReactNode, useReducer} from 'react'
import {ModalEditAssetContext} from './modal-edit-asset-context'
import {modalEditAssetReducer} from './state/reducer'
import {DEFAULT_MODAL_EDIT_ASSET_STATE} from './types/default-modal-edit-asset-state'
import {ModalEditAssetState} from './types/modal-edit-asset-state'

type ModalEditAssetProviderProps = {
    children: ReactNode | ReactNode[]
}

export function ModalEditAssetProvider({children}: ModalEditAssetProviderProps): JSX.Element {
    const initialState: ModalEditAssetState = DEFAULT_MODAL_EDIT_ASSET_STATE

    const [state, dispatch] = useReducer(modalEditAssetReducer, initialState)

    return (
        <ModalEditAssetContext.Provider value={{state, dispatch}}>
            {children}
        </ModalEditAssetContext.Provider>
    )
}
