import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {TimestampFilterType, getFormatedPreiousPeriodTimestamp} from './timestamp-filter'
import {TotalInventoryAllTypesResponse} from './total-inventory-all-types-api'
import {
    NETWORK_ASSET_AGGREGATION_ENDPOINT,
    SOFTWARE_SUMMARY_ENDPOINT,
    USB_SUMMARY_ENDPOINT,
} from './vessels-beta-endpoints'

export async function buildTotalInventoryPreviousPeriodAllTypes(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<TotalInventoryAllTypesResponse[]> {
    const {firstCall, secondCall} = getFormatedPreiousPeriodTimestamp(analysisPeriod)
    try {
        const totalAssetsPromiseFirstCall = await REST.post(
            `${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: firstCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: firstCall.toRelativeFirstSeen,
            },
        )
        const totalUsbDevicesPromiseFirstCall = await REST.post(
            `${USB_SUMMARY_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: firstCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: firstCall.toRelativeFirstSeen,
            },
        )
        const totalSoftwarePromiseFirstCall = await REST.post(
            `${SOFTWARE_SUMMARY_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: firstCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: firstCall.toRelativeFirstSeen,
            },
        )
        const totalAssetsPromiseSecondCall = await REST.post(
            `${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: secondCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: secondCall.toRelativeFirstSeen,
            },
        )
        const totalUsbDevicesPromiseSecondCall = await REST.post(
            `${USB_SUMMARY_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: secondCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: secondCall.toRelativeFirstSeen,
            },
        )
        const totalSoftwarePromiseSecondCall = await REST.post(
            `${SOFTWARE_SUMMARY_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                fromRelativeFirstSeen: secondCall.fromRelativeFirstSeen,
                toRelativeFirstSeen: secondCall.toRelativeFirstSeen,
            },
        )
        const [
            totalAssetsFirstResponse,
            totalUsbDevicesFirstResponse,
            totalSoftwareFirstResponse,
            totalAssetsSecondResponse,
            totalUsbDevicesSecondResponse,
            totalSoftwareSecondResponse,
        ] = await Promise.all([
            totalAssetsPromiseFirstCall,
            totalUsbDevicesPromiseFirstCall,
            totalSoftwarePromiseFirstCall,
            totalAssetsPromiseSecondCall,
            totalUsbDevicesPromiseSecondCall,
            totalSoftwarePromiseSecondCall,
        ])

        const result = locationIds.map((locationId) => {
            const findTotalAssetsFirst: {location: string; totalAssets: number} =
                totalAssetsFirstResponse.data.find(
                    (itemToFetch: {location: string; totalAssets: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalUsbDevicesFirst: {location: string; totalUsbDevices: number} =
                totalUsbDevicesFirstResponse.data.find(
                    (itemToFetch: {location: string; totalUsbDevices: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalSoftwareFirst: {location: string; totalSoftware: number} =
                totalSoftwareFirstResponse.data.find(
                    (itemToFetch: {location: string; totalSoftware: number}) =>
                        itemToFetch.location === locationId,
                )

            const findTotalAssetsSecond: {location: string; totalAssets: number} =
                totalAssetsSecondResponse.data.find(
                    (itemToFetch: {location: string; totalAssets: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalUsbDevicesSecond: {location: string; totalUsbDevices: number} =
                totalUsbDevicesSecondResponse.data.find(
                    (itemToFetch: {location: string; totalUsbDevices: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalSoftwareSecond: {location: string; totalSoftware: number} =
                totalSoftwareSecondResponse.data.find(
                    (itemToFetch: {location: string; totalSoftware: number}) =>
                        itemToFetch.location === locationId,
                )

            return {
                locationId: locationId,
                totalAssets: getComparisonValues(
                    findTotalAssetsFirst?.totalAssets,
                    findTotalAssetsSecond?.totalAssets,
                ),
                totalUsbDevices: getComparisonValues(
                    findTotalUsbDevicesFirst?.totalUsbDevices,
                    findTotalUsbDevicesSecond?.totalUsbDevices,
                ),
                totalSoftware: getComparisonValues(
                    findTotalSoftwareFirst?.totalSoftware,
                    findTotalSoftwareSecond?.totalSoftware,
                ),
            }
        })

        return result.filter(
            (onlyValuesItems) =>
                !(
                    onlyValuesItems.totalAssets == undefined &&
                    onlyValuesItems.totalUsbDevices == undefined &&
                    onlyValuesItems.totalSoftware == undefined
                ),
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<TotalInventoryAllTypesResponse>()
    }
}

function getComparisonValues(
    totalAssetsFirstCall: number | undefined,
    totalAssetsSecondCall: number | undefined,
): number | undefined {
    const comparison = (totalAssetsFirstCall ?? 0) - (totalAssetsSecondCall ?? 0)
    return comparison === 0 ? undefined : comparison
}
