import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

interface FilterBarStyledProps {
    width: number
}

export const FilterBarStyled = styled.div<FilterBarStyledProps>`
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    min-height: 250px;
    box-shadow: ${(props) => props.theme.softwareInventory.filterBar.boxShadow};
`

export const Loading = styled.div`
    padding: 0;
    height: 400px;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background.default};
    box-shadow: ${(props) => props.theme.reports.filterBar.boxShadow};
`

interface SectionContentProps {
    isInactive: boolean
}

export const SectionContent = styled.div<SectionContentProps>`
    overflow-y: auto;
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
    z-index: 0;
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
    ${mediumSmallFont()}
`

export const SavedFilterContent = styled.div<SectionContentProps>`
    padding: ${spacing(1)} ${spacing(4)};
    margin: 0;
    width: 100%;
    overflow-y: none;
    overflow-x: none;
    z-index: 0;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.border.lowContrast}`};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`
