import * as Styled from './_styled/vessel-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {NetworkSettingsSection} from './network-settings-section/network-settings-section'
import {CommsSettingsSection} from './comms-settings-section/comms-settings-section'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {vesselDetailsCachedReselector} from '../../../../reselectors/vessel-details.cached-reselector'
import {VesselTaggingSection} from './vessel-tagging-section/vessel-tagging-section'
import {useVesselManagement} from '../../../../contexts/use-vessel-management'
import {NoDataModal} from '../no-data-modal'
import {
    DEFAULT_VESSEL_CONFIG_MODAL,
    VesselConfigModal,
} from '../../../../contexts/type/vessel-management-state'
import {useEffect} from 'react'
import {PolicySettingsSection} from './policy-settings-section/policy-settings-section'
import {usePolicyManagement} from '../../../../../policy-management/contexts/policy-management/use-policy-management'
import {VesselDeploymentStatusInfo} from './vessel-deployment-section/vessel-deployment-status-info'
import {OTSourcesSection} from './ot-sources-section/ot-sources-section'

export function VesselDetails(): JSX.Element | null {
    const {
        activeVesselId,
        setInitialValue,
        locationsInIsolationMode,
        setSelectedIncidentPolicy,
        setSelectedMetricsPolicy,
        setSelectedEnforcementPolicy,
    } = useVesselManagement()
    const {allPolicyData} = usePolicyManagement()

    const model = useTypedSelector((state) =>
        vesselDetailsCachedReselector(
            state,
            activeVesselId,
            locationsInIsolationMode,
            allPolicyData,
        ),
    )

    const initialVesselConfig = !model
        ? DEFAULT_VESSEL_CONFIG_MODAL
        : ({
              networkConfiguration: model.metadata.networkConfiguration,
              vesselDashboardAgentIds: model.metadata.vesselDashboardAgentIds,
              vesselEmail: model.vesselEmail,
              incidentPolicy: model.incidentPolicy,
              metricsPolicy: model.metricsPolicy,
              enforcementPolicy: model.enforcementPolicy,
              otSources: model.metadata.otSources,
          } as VesselConfigModal)

    useEffect(() => {
        setInitialValue(initialVesselConfig)
        setSelectedIncidentPolicy(initialVesselConfig.incidentPolicy.id)
        setSelectedMetricsPolicy(initialVesselConfig.metricsPolicy.id)
        setSelectedEnforcementPolicy(initialVesselConfig.enforcementPolicy.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVesselId])

    if (!model) {
        return <NoDataModal />
    }

    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader text={model.name} />
            <VesselDeploymentStatusInfo locationId={model.id} />
            <NetworkSettingsSection locationId={model.id} />
            <OTSourcesSection locationId={model.id} />
            <CommsSettingsSection
                allAssets={model.nodesForLocation}
                vesselDashboard={model.vesselDashboard}
            />
            <PolicySettingsSection
                locationId={model.id}
                hasVesselDashboard={model.vesselDashboard}
            />
            <VesselTaggingSection locationId={model.id} vesselTags={model.vesselTags} />
        </Styled.Container>
    )
}
