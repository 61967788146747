import createCachedSelector from 're-reselect'
import {nodesSelector} from '../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../store/state/sfm-scores/selectors'
import AppState from '../../../store/types/app-state'
import {GuidType} from '../../../values/generic-type-defintions'
import {AssetOutputModel} from './asset.model'
import {threatMeasuresSelector} from '../../../store/state/threat-measures/selectors'
import {getNodeLatestEvent} from '../../../values/nodes/NodeData'
import threatHelper, {ThreatValues} from '../../../helpers/threatHelper'
import {getLocationDescription} from '../../../store/state/locations/state'
import {latestTimestampSelector} from '../../../store/state/latest-event-timestamps/selectors'
import {locationMapSelector} from '../../../store/state/locations/selectors'
import {normaliseScore} from '../../../helpers/formatting'

export const assetDetailsCachedReselector = createCachedSelector(
    [
        nodesSelector,
        sfmScoresSelector,
        latestTimestampSelector,
        threatMeasuresSelector,
        locationMapSelector,
        (_state: AppState, assetId: GuidType) => assetId,
    ],
    (nodes, sfmScores, latestEventTimestamps, threatMeasures, locationMap, assetId) => {
        const sfmScore = Math.round(normaliseScore((sfmScores && sfmScores?.get(assetId)) || 0))
        const nodeData = nodes && nodes?.get(assetId)
        return {
            assetId: nodeData?.node,
            threatScore: sfmScore,
            lastEvent: getNodeLatestEvent(assetId, latestEventTimestamps),
            threatLevel: nodeData
                ? threatHelper(sfmScore, threatMeasures, nodeData.value)
                : ThreatValues.LOW,
            location: nodeData ? getLocationDescription(locationMap, nodeData.location) : 'N/A',
            value: nodeData?.value,
            alias: nodeData?.alias,
            ipAddress: nodeData?.ipAddress,
        } as AssetOutputModel
    },
)((_state, assetId) => assetId)
