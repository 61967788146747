import * as Styled from './_styled/table.styled'
import {Location} from '../../../../../store/state/locations/state'

interface TableCodeCellProps {
    location: Location
    gridRow: number
    isHovering: boolean
}
export function TableCodeCell({location, gridRow, isHovering}: TableCodeCellProps): JSX.Element {
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={3}
            isHovering={isHovering}
            id={`location-code_${location.location}`}
        >
            {location.code}
        </Styled.TableDataCell>
    )
}
