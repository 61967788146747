import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

interface PopupContainerProps {
    lastRow?: boolean
}

export const PopupContainer = styled.div<PopupContainerProps>`
    display: inline-block;
    position: absolute;
    ${(props) => (props.lastRow ? `bottom: ${spacing(1)};` : `top: ${spacing(2)};`)}
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    background-color: ${(props) => props.theme.colors.background.default};
    padding: ${spacing(1)};
    pointer-events: none;
    z-index: 1;
    ${smallFont()};
`

export const TitleRow = styled.div`
    display: flex;
`

export const Title = styled.div`
    padding-left: ${spacing(1)};
`

export const TimestampRow = styled.div`
    display: flex;
`

export const Timestamp = styled.div`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-left: 3px;
`
