import AppState from '../../types/app-state'
import {DataRequest, ExtendedDataRequestReduxState, InventoryType, ProcessingStatus} from './state'

export const isModalOpenSelector = (state: AppState): boolean =>
    state.extendedDataRequest.isModalOpen

export const currentInventorySelector = (state: AppState): InventoryType | undefined =>
    state.extendedDataRequest.inventory

export const requestedDataSelector = (state: AppState): DataRequest =>
    state.extendedDataRequest.data

export const extendedDataRequestStateSelector = (state: AppState): ExtendedDataRequestReduxState =>
    state.extendedDataRequest

export const isSubmitAllowedSelector = (state: AppState): boolean =>
    state.extendedDataRequest.data.title?.length > 0 &&
    state.extendedDataRequest.data.reference?.length > 0 &&
    (state.extendedDataRequest.isAdditionalRequirementExist
        ? state.extendedDataRequest.additionalRequirement !== undefined
        : state.extendedDataRequest.data.requirements?.length > 0)

export const isProcessingSaveSelector = (state: AppState): ProcessingStatus =>
    state.extendedDataRequest.isProcessingSave

export const isAdditionalRequirementExistSelector = (state: AppState): boolean =>
    state.extendedDataRequest.isAdditionalRequirementExist
