import {LicenseState} from './licenses-state'

export const DEFAULT_LICENSE_STATE: LicenseState = {
    incidentsPolicyEnabled: true,
    onboardEngagementEnabled: true,
    incidentsPolicyEndDate: null,
    saveButtonEnabled: false,
    networkEnforcementEnabled: false,
    networkEnforcementEndDate: null,
    networkEnforcementMaxVessels: null,
    onboardEngagementEndDate: null,
    removableEnforcementEnabled: false,
    removableEnforcementEndDate: null,
    removableEnforcementMaxVessels: null,
}
