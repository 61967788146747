import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardArea} from '../_styled/reports-cards-populated.styled'
import * as Styles from './no-data.styled'

interface NoDataCardProps {
    text: string
}
export function NoDataCard({text}: NoDataCardProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <CardArea width={width}>
            <Styles.NoDataCardStyle>
                <Styles.NoDataMessage>{text}</Styles.NoDataMessage>
            </Styles.NoDataCardStyle>
        </CardArea>
    )
}
