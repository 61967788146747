import * as Styled from './license-header.styled'
import LicenseIcon from '../../../../@assets/icons/license.svg'
import LicenseNotAvailable from '../../../../@assets/icons/forbidden-circle-outline.svg'
import Tick from '../../../../@assets/icons/tick.svg'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useTheme} from 'styled-components'
import {Circle} from '../../../../components/shared/circle/circle.styled'
import {ContactLink} from '../../../../components/shared/outline-button/outline-button.styled'
import {allKeys, LicenseKeysTypes, LicenseKeyType} from '../../../../store/state/config/state'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {useVesselManagement} from '../../contexts/use-vessel-management'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import Tippy from '@tippyjs/react'
import {
    getDateAwareLicense,
    LicenseSummeryFleetConfigData,
    LicenseSummeryFleetConfigDataItem,
} from '../lincense-utils'
import {getToolTipBox} from '../shared/license/licnese'

export function LicenseHeader(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const {policyLicenseSummery} = useVesselManagement()
    let licenseSummeryDateAware: LicenseSummeryFleetConfigData | undefined

    if (policyLicenseSummery && fleetConfig.LicenseState) {
        licenseSummeryDateAware = getDateAwareLicense(
            policyLicenseSummery,
            fleetConfig.LicenseState,
        )
    }

    // if there is no config, then either the fleet config is still loading or the policy summery endpoint is
    if (!licenseSummeryDateAware) {
        return <DataLoading />
    }

    return (
        <Styled.ContentContainerMiddle
            backgroundColor={theme.incidents.dataArea.background}
            id="vessel-management-licenses"
        >
            <Styled.ContentArea width={width}>
                <Styled.Card width={width}>
                    <Styled.FilterSection>
                        <Styled.StatusFilterContainer width={width} gridArea="STATUS">
                            <Styled.FilterText>Vessel Settings</Styled.FilterText>
                            <div style={{display: 'flex', width: '100%'}}>
                                <Styled.FilterCard>
                                    <Styled.FilterText>
                                        <img src={LicenseIcon} /> Your licences
                                    </Styled.FilterText>
                                </Styled.FilterCard>
                                {allKeys.map((key) => {
                                    const [bool, tooltip] = isLicenseAvailable(
                                        licenseSummeryDateAware![key],
                                    )
                                    return (
                                        <Tippy content={tooltip} key={key}>
                                            <Styled.FilterCard>
                                                <Styled.FilterText>
                                                    <div>{mapTitles(key)}</div>
                                                    {bool ? (
                                                        <Circle width={4}>
                                                            <img src={Tick} />
                                                        </Circle>
                                                    ) : (
                                                        <Circle width={4} outlineColour={'#A7B0BE'}>
                                                            <img src={LicenseNotAvailable} />
                                                        </Circle>
                                                    )}
                                                </Styled.FilterText>
                                            </Styled.FilterCard>
                                        </Tippy>
                                    )
                                })}
                                <Styled.FilterCard>
                                    <Styled.FilterText
                                        style={{display: 'flex', justifyContent: 'flex-end'}}
                                    >
                                        <ContactLink href="mailto:customersuccess@cyberowl.io">
                                            Contact us to upgrade
                                        </ContactLink>
                                    </Styled.FilterText>
                                </Styled.FilterCard>
                            </div>
                        </Styled.StatusFilterContainer>
                    </Styled.FilterSection>
                </Styled.Card>
            </Styled.ContentArea>
        </Styled.ContentContainerMiddle>
    )
}

function isLicenseAvailable(license: LicenseSummeryFleetConfigDataItem): [boolean, JSX.Element] {
    // Do not change the order of these if statements
    if (!license.enabled) {
        return [false, getToolTipBox('Licences not enabled', license.key)]
    }

    if (license.isExpired) {
        return [false, getToolTipBox('Licences have expired', license.key)]
    }

    if (license.numberOfLicenses === null) {
        return [true, getToolTipBox('Licences available', license.key)]
    }

    if (typeof license.summeryData?.unusedLicenseCount === 'number') {
        if (license.summeryData.unusedLicenseCount > 0) {
            return [true, getToolTipBox('Licences available', license.key)]
        }
        return [false, getToolTipBox('Licences not available', license.key)]
    }

    return [false, getToolTipBox('Licences not available', license.key)]
}

function mapTitles(key: LicenseKeyType<LicenseKeysTypes>): string {
    switch (key) {
        case 'License:Enforcement:Networks':
            return 'Network enforcement'
        case 'License:Enforcement:Removables':
            return 'Removable enforcement'
        case 'License:Metrics:OnboardEngagement':
            return 'Onboard engagement'
        case 'License:Incidents':
            return 'Incidents policy'
    }
}
