import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentSeverityValues} from '../../../values/incident-response-values'
import {IncidentPolicyTypes} from './incidents-policy/type/incident-response-policy'
import {FormattedIncidentPolicyType} from './incidents-policy/type/incidents-policy-state'
import {Location2Policy} from './metrics-policy/type/metrics-policy'

export function getFormattedIncidentPolicyType(
    incidentResponsePolicyIdentity: GuidType,
    incidentTypes: string[],
    incidentPolicyTypesData: IncidentPolicyTypes[],
): FormattedIncidentPolicyType[] {
    const severityMap = new Map<string, IncidentSeverityValues[]>()

    incidentPolicyTypesData.forEach((incident) => {
        const key = `${incident.incidentResponsePolicyIdentity}-${incident.incidentResponseType}`
        if (!severityMap.has(key)) {
            severityMap.set(key, [])
        }
        severityMap.get(key)!.push(incident.incidentResponseSeverity)
    })

    const formattedArray = incidentTypes.map((incidentResponseType) => {
        const key = `${incidentResponsePolicyIdentity}-${incidentResponseType}`
        const incidentResponseSeverities = severityMap.get(key) || []
        return {
            incidentResponsePolicyIdentity,
            incidentResponseType,
            incidentResponseSeverities,
        } as FormattedIncidentPolicyType
    })

    return formattedArray
}

export function reverseFormatArray(
    formattedArray: FormattedIncidentPolicyType[],
): IncidentPolicyTypes[] {
    const originalArray: IncidentPolicyTypes[] = []

    formattedArray.forEach((formattedIncident) => {
        const {incidentResponsePolicyIdentity, incidentResponseType, incidentResponseSeverities} =
            formattedIncident

        incidentResponseSeverities.forEach((severity) => {
            originalArray.push({
                incidentResponsePolicyIdentity,
                incidentResponseType,
                incidentResponseSeverity: severity,
            })
        })
    })

    return originalArray
}

export function getFormattedReminderDuration(value: number | null): string | null {
    if (!value) {
        return null
    }
    return `PT${value * 24}H`
}

export function reverseFormattedReminderDuration(value: string | null): number | null {
    if (!value) {
        return null
    }
    return parseInt(value.slice(2, -1)) / 24
}

export function getFormattedLocations(
    policyId: GuidType,
    newLocations: Set<GuidType> | undefined,
): Location2Policy[] {
    if (!newLocations) {
        return []
    }

    return Array.from(newLocations).map((locationId) => ({
        locationId: locationId,
        policyId: policyId,
    }))
}
