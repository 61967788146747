import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export function generateGridTemplateColumns(numColumns: number): string {
    const columnWidth = '1fr '
    if (numColumns === 0) {
        return '0 1fr 1fr 0'
    }
    return `0 250px ${columnWidth.repeat(numColumns)} 0`
}

interface TableHeaderGridProps {
    numberOfColumns: number
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) => generateGridTemplateColumns(props.numberOfColumns)};
    grid-template-rows: 75px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    padding: 0 ${spacing(1)};
`

interface TableHeaderCellProps {
    gridColumn: number
    centered?: boolean
    defaultChecked?: boolean
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) =>
        props.defaultChecked ? props.theme.colors.link.primary : props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    ${(props) => props.defaultChecked && 'gap: 10px'};
    align-items: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
    cursor: pointer;
`
export const Text = styled.div`
    word-break: break-word;
    text-align: center;
    display: flex;
    flex-direction: column;
`
interface IconStyledProps {
    arrowDec: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transition:
        transform 0.2s,
        color 0.2s;
    transition-timing-function: ease-in-out;
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`
