import LoadingState from '../../../../../values/loading-state-enum'
import {PagedAlertsState} from './paged-alerts-state'

export const DEFAULT_PAGED_ALERT_STATE: PagedAlertsState = {
    assetId: undefined,
    loading: LoadingState.NotPopulated,
    error: '',
    dataPages: undefined,
    totalNumberOfEvents: undefined,
    totalNumberOfPages: undefined,
    selectedPage: undefined,
    pageSize: 10,
    toDate: undefined,
    fromDate: undefined,
    showDateFilter: false,
} as PagedAlertsState
