import {useDispatch} from 'react-redux'
import {Button} from '../save-filter-popup/save-filter-popup.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {updateUserSubscription} from '../../../../store/state/saved-filters/action-creators'
import {isEqual} from 'lodash'
import {isEmailValid} from '../../../../utils/Utils'

interface Props {
    model: NotifyMeOutputModel
    text: string
}

export function UpdateAlertButton({model, text}: Props): JSX.Element {
    const dispatch = useDispatch()
    const newSubscription = useTypedSelector(newSubscriptionSelector)

    const isUpdated =
        (newSubscription.schedule &&
            !isEqual(newSubscription.schedule, model.currentSubscription?.schedule)) ||
        (newSubscription.notificationEmail &&
            !isEqual(
                newSubscription.notificationEmail,
                model.currentSubscription?.notificationEmail,
            ) &&
            newSubscription.notificationEmail?.length !== 0 &&
            isEmailValid(newSubscription.notificationEmail)) ||
        (newSubscription.subjectPrefix &&
            !isEqual(newSubscription.subjectPrefix, model.currentSubscription?.subjectPrefix)) ||
        (newSubscription.notifyOnChange !== undefined &&
            newSubscription.notifyOnChange !== model.currentSubscription?.notifyOnChange)

    const onClickFn = () => {
        model.currentSubscription &&
            model.templateDefinitionId &&
            dispatch(
                updateUserSubscription({
                    ...model.currentSubscription,
                    schedule: newSubscription.schedule ?? model.currentSubscription.schedule,
                    notificationEmail:
                        newSubscription.notificationEmail !== undefined ||
                        newSubscription.notificationEmail === ''
                            ? model.userEmail
                            : newSubscription.notificationEmail,

                    subjectPrefix:
                        newSubscription.subjectPrefix ?? model.currentSubscription.subjectPrefix,
                    notifyOnChange:
                        newSubscription.notifyOnChange ?? model.currentSubscription.notifyOnChange,
                    userId: model.userId,
                    searchDefinitionId: model.currentSubscription.searchDefinitionId,
                    templateDefinitionId: model.templateDefinitionId,
                }),
            )
    }

    return (
        <Button id="create-or-update-alert-button" disabled={!isUpdated} onClick={onClickFn}>
            {text}
        </Button>
    )
}
