import {GridLayout} from '../../general/grid-layout.enum'
import {Content} from '../../general/content-area/headline-values/content.styled'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {WidgetOutputModel} from '../widget.model'

interface HeadlineValuesProps {
    gridLayout: GridLayout
    model: WidgetOutputModel | null
}
export function HeadlineValues({gridLayout, model}: HeadlineValuesProps): JSX.Element | null {
    if (!model) {
        return null
    }
    return (
        <Content gridLayout={gridLayout} id="asset-discovery-HeadlineValues">
            <ValueForType
                type="24h"
                value={model.recentlyDiscoveredAssets.past24Hours}
                gridLayout={gridLayout}
                id="asset-discovery-24h"
            />
            <ValueForType
                type="7d"
                value={model.recentlyDiscoveredAssets.past7Days}
                gridLayout={gridLayout}
                id="asset-discovery-7d"
            />
            <ValueForType
                type="30d"
                value={model.recentlyDiscoveredAssets.past30Days}
                gridLayout={gridLayout}
                id="asset-discovery-30d"
            />
        </Content>
    )
}
