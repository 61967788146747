import {LinkedTitle, Title} from '../../general/content-area/title.styled'
import {IncidentData} from './incident-data'
import {HeadlineValues} from './headline-values'
import {TopContentSection} from '../../general/content-area/top-content-section.styled'
import {BottomContentSection} from '../../general/content-area/bottom-content-section'
import {Link} from 'react-router-dom'
import {logInterlinksClick} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
import {getGridLayout} from '../../general/helpers'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import {DataLoading} from '../../../../../components/data-loading/data-loading'

export function LatestIncidentWidget(): JSX.Element {
    const dispatch = useDispatch()
    const {hasNewIncidents, latestIncidentResponse, loadedIncidentsDetails} = useIncidentsWidget()
    const titleText = hasNewIncidents ? 'Latest Incident Update' : 'Latest Incident Alert'
    const {width} = useDimensions()

    const gridLayout = getGridLayout(width)
    return (
        <>
            {loadedIncidentsDetails ? (
                <>
                    <TopContentSection>
                        <Link
                            to={{
                                pathname: `/incidents`,
                                search: `?incidentDetails=${latestIncidentResponse.identity}`,
                                state: latestIncidentResponse.incidentCode,
                            }}
                            onClick={() =>
                                latestIncidentResponse.identity != null &&
                                dispatch(
                                    logInterlinksClick(
                                        AuditLogPageType.INCIDENTS,
                                        AuditLogAuditType.INTERLINK_CLICK,
                                        latestIncidentResponse.identity,
                                    ),
                                )
                            }
                            id="link-to-incidents-page"
                        >
                            <LinkedTitle gridLayout={gridLayout} id="link-title">
                                {titleText}
                            </LinkedTitle>
                        </Link>
                        <IncidentData model={latestIncidentResponse} gridLayout={gridLayout} />
                    </TopContentSection>
                    <BottomContentSection>
                        <Title gridLayout={gridLayout} id="incidents-bottom-section-title">
                            Incident Overview
                        </Title>
                        <HeadlineValues gridLayout={gridLayout} />
                    </BottomContentSection>
                </>
            ) : (
                <DataLoading />
            )}
        </>
    )
}
