import styled from 'styled-components'
import {GridLayout} from '../grid-layout.enum'

function getFontSize(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '12px'
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '14px'
        default:
            return '16px'
    }
}

function getLineHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '17px'
        default:
            return '22px'
    }
}

function getLetterSpacing(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '0.24px'
        default:
            return '0.28px'
    }
}

interface TitleProps {
    gridLayout: GridLayout
}

export const Title = styled.div<TitleProps>`
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    letter-spacing: ${(props) => getLetterSpacing(props.gridLayout)};
    padding: 0;
    margin: 0;
`

export const LinkedTitle = styled.div<TitleProps>`
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    letter-spacing: ${(props) => getLetterSpacing(props.gridLayout)};
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.link.primary};
`
