import {ButtonText} from '../../shared/row-actions/common/button-text.styled'
import {Button} from '../../shared/row-actions/common/button.styled'

export function ViewDetailsButton(): JSX.Element {
    return (
        <Button id={`view-details-button`}>
            <ButtonText>See details</ButtonText>
        </Button>
    )
}
