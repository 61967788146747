import * as Styled from './_styled/self-monitoring-card.styled'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {getLocationDescription} from '../data-helpers'

interface CardLocationCellProps {
    event: SelfMonitoringEngineEvent
    fixedWidthReducedText: boolean
}

export function CardLocationCell({
    event,
    fixedWidthReducedText,
}: CardLocationCellProps): JSX.Element {
    const nodes = useTypedSelector(nodesSelector)
    const locations = useTypedSelector(locationMapSelector)

    return (
        <Styled.CardDataCell titleOnSameLine={fixedWidthReducedText}>
            <Styled.ReducedText fixedWidth={fixedWidthReducedText}>Location:</Styled.ReducedText>
            <div id={`location-value_${event.identity}`}>
                {getLocationDescription(event, locations, nodes)}
            </div>
        </Styled.CardDataCell>
    )
}
