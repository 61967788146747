import {useEnforcementPolicy} from '../../../../../contexts/enforcement-policy/use-enforcement-policy'
import * as Styled from './buttons-area.styled'

interface ButtonsAreaProps {
    policyToCreate?: boolean
}
export function ButtonsArea({policyToCreate}: ButtonsAreaProps): JSX.Element {
    const {
        isEnforcementPolicyUpdated,
        addNewEnforcementPolicy,
        updateEnforcementPolicy,
        requestDiscardChanges,
    } = useEnforcementPolicy()
    const text = policyToCreate ? 'Add' : 'Save'

    return (
        <Styled.ButtonsWrapper>
            <Styled.DiscardButton
                id="discharge-button"
                isChanged={isEnforcementPolicyUpdated}
                onClick={() => requestDiscardChanges(true)}
            >
                <Styled.Text>Discard changes</Styled.Text>
            </Styled.DiscardButton>

            <Styled.Button
                id="save-button-metrics-policy"
                onClick={(e) => {
                    e.preventDefault()
                    if (policyToCreate) {
                        addNewEnforcementPolicy()
                    } else {
                        updateEnforcementPolicy()
                    }
                }}
                isChanged={isEnforcementPolicyUpdated}
            >
                <Styled.Text>{text}</Styled.Text>
            </Styled.Button>
        </Styled.ButtonsWrapper>
    )
}
