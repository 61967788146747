import {createSelector} from 'reselect'
import {rolesSelector} from '../../../store/state/roles/selectors'
import {Role, isUserAllowed} from '../../../values/Role'

const isUserAllowedToEditAssetReselector = createSelector(
    rolesSelector,
    (roles: Role[]): boolean => {
        return isUserAllowed(roles, ['DEVELOPER', 'CUSTOMER_ADMIN'])
    },
)

export default isUserAllowedToEditAssetReselector
