import {EtsTrendScore} from './EtsTrendScore'
import {first, last} from 'lodash'

export const getDifference = (trendArray: EtsTrendScore[]): number => {
    if (trendArray && trendArray.length > 0) {
        const score1 = first(trendArray)
        const score2 = last(trendArray)

        if (score1 == undefined || score2 == undefined) {
            return 0
        }

        const returnScore = Math.round(score2.score - score1.score)

        if (returnScore > 0) {
            return Number(`+${returnScore}`)
        }

        return returnScore
    }

    return 0
}
