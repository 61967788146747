import {formatDate} from '../../../../../helpers/formatting'
import {DateTimeEventOutputModel} from '../../../models/date-time-event.model'
import {When} from './when.styled'
import {Who} from './who.styled'

interface EventProps {
    data: DateTimeEventOutputModel
}

export function RaisedEvent({data}: EventProps): JSX.Element | null {
    if (!data) {
        return null
    }

    return (
        <>
            <When>{formatDate(data.when)}</When>
            <Who>{data.who}</Who>
        </>
    )
}
