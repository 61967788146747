import * as Styled from './_styled/self-monitoring-engine-table.styled'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {getLocationDescription} from '../data-helpers'

interface TableLocationCellProps {
    event: SelfMonitoringEngineEvent
    gridRow: number
}

export function TableLocationCell({event, gridRow}: TableLocationCellProps): JSX.Element {
    const nodes = useTypedSelector(nodesSelector)
    const locations = useTypedSelector(locationMapSelector)
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={4}
            id={`location-value_${event.identity}`}
        >
            {getLocationDescription(event, locations, nodes)}
        </Styled.TableDataCell>
    )
}
