import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getLeftMargin(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.THREE_COLUMNS_LARGE:
            return '23px'
        default:
            return '10px'
    }
}

interface TimeStampProps {
    gridLayout: GridLayout
}

export const TimeStamp = styled.span<TimeStampProps>`
    margin-left: ${(props) => getLeftMargin(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
