import {CustomerReports} from '../../../../values/customer-reports'
import {ActionType} from './action-type'
import * as Actions from './actions'
import * as uuid from 'uuid'
import moment from 'moment'
import {GuidType} from '../../../../values/generic-type-defintions'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}
export function changePageSize(newPageSize: number): Actions.ChangePageSizeAction {
    return {type: ActionType.CHANGE_PAGE_SIZE_ACTION, payload: newPageSize}
}
export function toggleNotesExpanded(id: string): Actions.ToggleNotesExpandedAction {
    return {type: ActionType.TOGGLE_NOTES_EXPANDED, payload: id}
}

export function setFindReportDetails(
    findReportDetails: Record<string, unknown>,
): Actions.ReceivedRequestedReportDetails {
    return {type: ActionType.RECEIVED_REQUESTED_REPORT_DETAILS, payload: findReportDetails}
}

export function setNotesExpanded(reportGuid: GuidType, expanded: boolean): Actions.NotesExpand {
    return {type: ActionType.NOTES_EXPAND, payload: {guild: reportGuid, toggle: expanded}}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function switchToCachedPageData(requestedPage: number): Actions.SwitchToCachedPagedAction {
    return {type: ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION, payload: requestedPage}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}
export function receivedRequestedPageData(
    items: CustomerReports[],
    total: number,
    earliestPeriodEndDate: string,
    latestPeriodEndDate: string,
    allReportTypes: string[],
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {items, total, earliestPeriodEndDate, latestPeriodEndDate, allReportTypes},
    }
}

export function addNoteToCustomerReport(
    note: string,
    reportIdentity: GuidType,
    user: string,
): Actions.AddNoteAction {
    return {
        type: ActionType.ADD_NOTE_ACTION,
        payload: {
            identity: `${note}-${uuid.v4()}`,
            customerReportIdentity: reportIdentity,
            createdWhen: moment().toISOString(),
            createdBy: user,
            note: note,
        },
    }
}

export function getFilteredData(): Actions.GetFilteredDataAction {
    return {type: ActionType.GET_FILTERED_DATA}
}
