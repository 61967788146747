import {EventDetail} from '../types/event-detail'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function requestEventDetail(): Actions.RequestEventDetailAction {
    return {type: ActionType.REQUEST_EVENT_DETAIL_ACTION}
}

export function receiveEventDetail(data: EventDetail): Actions.ReceiveRequestedEventDetailAction {
    return {type: ActionType.RECEIVED_REQUESTED_EVENT_DETAIL_ACTION, payload: data}
}
