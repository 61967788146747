import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT_CARD_SINGLE_COLUMN = 600

function getColumnTemplate(width: number): string {
    if (width > BREAK_POINT_CARD_SINGLE_COLUMN) {
        return 'repeat(2,1fr);'
    }
    return 'repeat(1, [col-start] 1fr);'
}

function getGridTemplate(width: number): string {
    if (width > BREAK_POINT_CARD_SINGLE_COLUMN) {
        return `
        "NAME TAGS"
         "NETWORK COMMS"
         "INCIDENT-POLICY METRICS-POLICY"
         "ENFORCEMENT-REMOVABLE-POLICY DEPLOYMENT"
         "ENFORCEMENT-NETWORK-POLICY ."
         "VIEW-DETAIL EMPTY"
         `
    }
    return `
    "NAME"
     "TAGS" 
     "NETWORK" 
     "COMMS" 
     "INCIDENT-POLICY" 
     "METRICS-POLICY" 
     "ENFORCEMENT-REMOVABLE-POLICY" 
     "ENFORCEMENT-NETWORK-POLICY"
     "DEPLOYMENT" "VIEW-DETAIL"
     `
}
interface CardProps {
    width: number
}
export const Card = styled.div<CardProps>`
    color: ${(props) => props.theme.userManagement.dataRow.text};
    background-color: ${(props) => props.theme.userManagement.dataRow.background};
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    padding: ${spacing(2)};
    ${smallFont()};
    position: relative;
`

interface CardTitleDataProps {
    gridArea: string
}
export const CardTitleData = styled.h2<CardTitleDataProps>`
    display: flex;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    margin: 0;
    grid-area: ${(props) => props.gridArea};
`

export const TagWrapper = styled.div`
    display: flex;
    column-gap: 3px;
`

export const Settings = styled.div`
    display: flex;
`
