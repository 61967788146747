import * as Styled from './header.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {RefreshButton} from './refresh-button/refresh-button'
import {MouseEvent, useEffect} from 'react'
import {batch, useDispatch} from 'react-redux'
import {fetchLocations} from '../../../store/state/locations/action-creators'
import {fetchNodes} from '../../../store/state/nodes/action-creators'
import {fetchAllLocationTagsByLocation} from '../../../store/state/vessel-tags/action-creators'
import {usePolicyManagement} from '../../policy-management/contexts/policy-management/use-policy-management'
import {useVesselManagement} from '../contexts/use-vessel-management'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    isActiveFilterSelector,
    vesselManagementFilterSelector,
} from '../../../store/state/vessel-management-filter/selectors'
import {
    activeVesselFilterSelector,
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../store/state/vessel-filter/selectors'
import {FilterBarButton} from '../../../components/filter-button/filter-button'
import {setVesselFilterPref} from '../../../store/state/vessel-filter/action-creators'
import LoadingState from '../../../values/loading-state-enum'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {LicenseHeader} from './license/license-header'
import {isLocationsLoadingReSelector} from '../reselectors/is-loading-reselector'
import {DataLoading} from '../../../components/data-loading/data-loading'

interface HeaderProp {
    text: string
}
export function Header({text}: HeaderProp): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {fetchPolicyData} = usePolicyManagement()
    const {setFilter} = useVesselManagement()
    const {
        displayFilterBar,
        closeVesselDetailModal,
        showFilterBar,
        isGetDeploymentStatusLoading,
        isVesselDeploymentStatusLoading,
    } = useVesselManagement()
    const isActiveFilter = useTypedSelector(isActiveFilterSelector)
    const isLoading =
        useTypedSelector(isLocationsLoadingReSelector) ||
        isGetDeploymentStatusLoading ||
        isVesselDeploymentStatusLoading
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)
    const {hasDeploymentIssues, locations, searchVesselTagTerm, searchVesselNameTerm} =
        useTypedSelector(vesselManagementFilterSelector)

    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm ?? [],
                        searchVesselNameTerm: searchVesselNameTerm ?? '',
                    },
                    PageType.VESSEL_MANAGEMENT,
                ),
            )
        }
    }, [dispatch, locations, searchVesselNameTerm, searchVesselTagTerm, vesselFilterLoading])

    useEffect(() => {
        setFilter(
            hasDeploymentIssues,
            vesselFilterLocations,
            vesselFilterNameTerm,
            vesselFilterTagTerm,
        )
    }, [hasDeploymentIssues, vesselFilterNameTerm, vesselFilterTagTerm, vesselFilterLocations])

    function refresh(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        batch(() => {
            dispatch(fetchLocations())
            dispatch(fetchNodes())
            dispatch(fetchAllLocationTagsByLocation())
        })
        fetchPolicyData()
    }

    return (
        <Styled.Header width={width}>
            <Styled.PageTitle width={width}>{text}</Styled.PageTitle>
            <Styled.ContentContainerTop width={width}>
                <Styled.Container width={width}>
                    <div style={{display: 'flex', columnGap: '10px'}}>
                        <FilterBarButton
                            onClick={() => {
                                displayFilterBar(!showFilterBar)
                                closeVesselDetailModal()
                            }}
                            active={isActiveFilter || activeVesselFilter}
                        />
                    </div>
                    <RefreshButton refreshFunction={refresh} />
                </Styled.Container>
                {isLoading ? <DataLoading /> : <LicenseHeader />}
            </Styled.ContentContainerTop>
        </Styled.Header>
    )
}
