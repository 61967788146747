import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${spacing(4)};
`

export const ButtonStyled = styled.button`
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    min-width: 110px;
    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
