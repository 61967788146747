import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {BREAK_POINT_REFRESH_SWITCH} from '../../system-page-content.styled'
import * as Styled from './_styled/email-management-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    const wideDisplay = width > BREAK_POINT_REFRESH_SWITCH
    const notificationColumn = wideDisplay ? 'Send new incident to' : 'New Incident'
    const removeColumn = wideDisplay ? 'Remove from email list' : 'Remove'

    return (
        <Styled.TableHeaderGrid width={width}>
            <Styled.TableHeaderEmailCell gridColumn={2} width={width}>
                Name / email
            </Styled.TableHeaderEmailCell>
            <Styled.TableHeaderCell gridColumn={3} width={width}>
                {notificationColumn}
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={4} width={width}>
                {removeColumn}
            </Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
