import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface LabelProps {
    gridArea: string
    width?: number
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    padding-top: 3px;
    margin: 0;
    width: ${(props) => props.width ?? 60}px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    display: flex;
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

interface InputProps {
    isChanged: boolean
    width?: number
}

export const Input = styled.input<InputProps>`
    width: ${(props) => props.width ?? 220}px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-right: ${(props) => (props.isChanged ? 0 : `${spacing(4)}`)};
`
