import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {showOneColNarrow} from '../../shared/grid-layout-helper'
import {GridLayout} from '../../shared/grid-layout.enum'

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    padding-top: 3px;
    margin: 0;
    width: 140px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    display: flex;
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

interface InputProps {
    gridLayout: GridLayout
    isChanged: boolean
}

export const Input = styled.input<InputProps>`
    width: ${(props) => (showOneColNarrow(props.gridLayout) ? '100%' : '320px')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-right: ${(props) => (props.isChanged ? 0 : `${spacing(4)}`)};
`

interface TextAreaProps {
    gridLayout: GridLayout
    isChanged: boolean
}

export const TextArea = styled.textarea<TextAreaProps>`
    width: ${(props) => (showOneColNarrow(props.gridLayout) ? '100%' : '320px')};
    height: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-right: ${(props) => (props.isChanged ? 0 : `${spacing(4)}`)};
`
export const ColumnTitle = styled.h3`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin-bottom: ${spacing(4)};
`
