import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../../theme/theme'

interface ContentContainerProps {
    width: number
    backgroundColor?: ColorType
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
`
export function getGridTemplateColumns(): string {
    return '0 auto 150px 0'
}

export const TableDataGrid = styled.div`
    display: grid;
    grid-template-columns: ${getGridTemplateColumns()};
    gap: ${spacing(2)};
    flex: 1;
    background-color: ${(props) => props.theme.systemPage.dataArea.background};
`
