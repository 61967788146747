import AnomalyMessageFormatter from './anomaly-message-formatter'
import DefaultAnomalyMessageFormatter from './default-anomaly-message-formatter'
import ClientAnomalyMessageFormatterForPublicServer from './client-anomaly-message-formatter-for-public-server'
import ClientAnomalyMessageFormatterForPrivateServer from './client-anomaly-message-formatter-for-private-server'
import ServerAnomalyMessageFormatterForPrivateServer from './server-anomaly-message-formatter-for-private-server'

class DetailStringFormatterFactory {
    static build(
        messageForServer: boolean,
        serverIsPublicOrBroadcast: boolean,
    ): AnomalyMessageFormatter {
        if (serverIsPublicOrBroadcast) {
            if (!messageForServer) {
                return new ClientAnomalyMessageFormatterForPublicServer()
            }
        } else {
            if (messageForServer) {
                return new ServerAnomalyMessageFormatterForPrivateServer()
            } else {
                return new ClientAnomalyMessageFormatterForPrivateServer()
            }
        }

        return new DefaultAnomalyMessageFormatter()
    }
}

export default DetailStringFormatterFactory
