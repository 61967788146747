import type {OTAsset} from '../../../store/state/ot-assets/state'
import * as Style from './system-table-expanded-row.styled'
import {systemTableConfig} from './system-table.config'
import {systemDetailsConfig} from './system-table-details.config'

export function SystemTableExpandedRow({assets}: {assets: OTAsset[]}) {
    let details = [<div></div>]
    details = [
        ...details,
        ...systemDetailsConfig.map((item) => (
            <Style.GridHeaderCell>{item.label}</Style.GridHeaderCell>
        )),
    ]
    details = [...details, <div></div>]
    for (const asset of assets) {
        details = [...details, <div></div>]
        details = [
            ...details,
            ...systemDetailsConfig.map((item) => (
                <Style.GridRowCell>
                    {item.render({
                        asset,
                    })}
                </Style.GridRowCell>
            )),
        ]
        details = [...details, <div></div>]
    }
    return (
        <tr>
            <Style.RowCell colSpan={systemTableConfig.length}>
                <Style.Content>{details}</Style.Content>
            </Style.RowCell>
        </tr>
    )
}
