import * as Styled from './cog-button.styled'
import COG from '../../../../../../@assets/icons/settings-cog.svg'
import COGWhite from '../../../../../../@assets/icons/settings-cog-white.svg'

interface CogButtonProps {
    filterApplied: boolean
    onClick: () => void
    widget: string
}

export function CogButton({filterApplied, onClick, widget}: CogButtonProps): JSX.Element {
    return (
        <Styled.SettingsIconCell filterApplied={filterApplied}>
            <Styled.CogImage
                src={filterApplied ? COGWhite : COG}
                onClick={onClick}
                id={`cog-button-${widget}`}
            />
        </Styled.SettingsIconCell>
    )
}
