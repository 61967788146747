import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface Props {
    rightAlign?: boolean
}

export const Container = styled.div<Props>`
    display: flex;
    justify-content: ${(props) => (props.rightAlign ? 'flex-end' : 'space-between')};
    align-items: center;
    margin-bottom: ${spacing(4)};
`

export const Buttons = styled.div`
    display: flex;
    column-gap: ${spacing(2)};
`
