import * as Styled from './_styled/metrics-widget-options.styled'
import {MetricsWidgetType} from '../contexts/types/metrics-widget-type'
import {useMetricsWidget} from '../contexts/use-metrics-component'

export function MetricWidgetOptions(): JSX.Element {
    const {selectedMetricWidget, displayMetricsWidgetOnDashboard} = useMetricsWidget()
    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        displayMetricsWidgetOnDashboard(event.target.value as MetricsWidgetType)
    }
    return (
        <Styled.Wrapper id="metrics-widgets-options-wrapper">
            <Styled.Label>Selected widget</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={selectedMetricWidget}
                    id="metrics-widgets_select"
                >
                    <option
                        key="default-option"
                        value={MetricsWidgetType.SCORECARD_SUMMARY}
                        id="metrics-widgets_default-option"
                    >
                        {getMetricsWidgetTypeDisplayName(MetricsWidgetType.SCORECARD_SUMMARY)}
                    </option>
                    {allMetricsWidgetsTypes?.map((value) => (
                        <option key={value} value={value} id={`metrics-widgets_${value}-option`}>
                            {getMetricsWidgetTypeDisplayName(value)}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
const allMetricsWidgetsTypes: MetricsWidgetType[] = [
    MetricsWidgetType.TARGET_SUMMARY,
    MetricsWidgetType.BENCHMARK_SUMMARY,
    MetricsWidgetType.TREND_SUMMARY,
]
function getMetricsWidgetTypeDisplayName(types: MetricsWidgetType): string {
    switch (types) {
        case MetricsWidgetType.BENCHMARK_SUMMARY:
            return 'METRICS BENCHMARK SUMMARY'
        case MetricsWidgetType.TARGET_SUMMARY:
            return 'METRICS TARGET SUMMARY'
        case MetricsWidgetType.TREND_SUMMARY:
            return 'METRICS TREND SUMMARY'
        case MetricsWidgetType.SCORECARD_SUMMARY:
        default:
            return 'METRICS SCORECARD SUMMARY'
    }
}
