import {useState} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {AddEmailEntry} from './add-email-entry/add-email-entry'
import {MedullaUsersDropDown} from './medulla-users-drop-down/medulla-users-drop-down'
import * as Styled from './_styled/add-new-user-area.styled'
export function AddNewUserArea(): JSX.Element {
    const {width} = useDimensions()

    const [newEmailEntry, setNewEmailEntry] = useState(false)
    const [openMedullaUsers, setOpenMedullaUsers] = useState(false)
    return (
        <Styled.ButtonWrapper width={width}>
            <Styled.Button
                onClick={() => {
                    setOpenMedullaUsers(true)
                    setNewEmailEntry(false)
                }}
            >
                + Add Medulla User
            </Styled.Button>
            <Styled.Button
                onClick={() => {
                    setNewEmailEntry(true)
                    setOpenMedullaUsers(false)
                }}
            >
                + Add email
            </Styled.Button>
            {newEmailEntry && <AddEmailEntry setNewEmailEntry={setNewEmailEntry} />}
            {openMedullaUsers && <MedullaUsersDropDown setOpenMedullaUsers={setOpenMedullaUsers} />}
        </Styled.ButtonWrapper>
    )
}
