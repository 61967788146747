import * as Styled from './card.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ConsumerState} from '../../../../../values/engine_status/consumer-state'

interface CardProps {
    consumerState: ConsumerState
}

export function Card({consumerState}: CardProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > 300

    return (
        <Styled.Card status={consumerState.status} width={width}>
            <Styled.Title>{consumerState.topicName || 'No Engine'}</Styled.Title>
            <Styled.DataCell titleOnSameLine={fixedWidthReducedText}>
                <Styled.SubTitle fixedWidth={fixedWidthReducedText}>Status:</Styled.SubTitle>
                <div>{consumerState.status || 'No Status'}</div>
            </Styled.DataCell>
        </Styled.Card>
    )
}
