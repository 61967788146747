import * as Styles from './filter-bar-header.styled'

interface FilterBarProps {
    title: string
}

export function FilterBarHeader({title}: FilterBarProps): JSX.Element {
    return (
        <Styles.SectionHeader>
            <Styles.SectionTitle>{title}</Styles.SectionTitle>
        </Styles.SectionHeader>
    )
}
