import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import * as Styles from './toggle-button.styled'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface ToggleReportDetailsButtonProps {
    id: string
}

export function ToggleReportDetailsButton({id}: ToggleReportDetailsButtonProps): JSX.Element {
    const {notesExpanded, toggleNotesExpanded} = usePagedReports()
    const isExpanded = notesExpanded.get(id) === true
    const dispatch = useDispatch()

    function onClickToggle() {
        toggleNotesExpanded(id)
        dispatch(
            logDetailsOpen(AuditLogPageType.REPORTS, AuditLogAuditType.DETAIL_PANEL_ACCESS, id),
        )
    }
    return (
        <Styles.Button onClick={onClickToggle} id={`expand-notes-button_${id}`}>
            <Styles.IconWrapper>
                <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            </Styles.IconWrapper>
            <Styles.ButtonText>Report Details</Styles.ButtonText>
        </Styles.Button>
    )
}
