import {ReportConfig} from '../report-config/report-config'
import {VesselMetadata} from '../metadata/vessel-metadata'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataAreaStyle} from './data-populated.styled'
import {MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {getGridType} from '../shared/grid-layout-helper'

export function DataPopulated(): JSX.Element {
    const {width} = useDimensions()

    return (
        <DataAreaStyle gridLayout={getGridType(width)} addMargin={width > MAX_SCREEN_WIDTH}>
            <VesselMetadata />
            <ReportConfig />
        </DataAreaStyle>
    )
}
