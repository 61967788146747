import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {ListOfAssociatedAssets} from '../../../contexts/types/usb-devices-response'
import {formattedUSBDeviceDetailsCachedReselector} from '../../../reselector/formatted-usb-device-details.cached-reselector'
import {getUIDataorHosts} from '../../data-helpers'
import NEW_VESSEL_ICON from '../../../../../@assets/icons/new-vessel-icon.svg'

import * as Styled from './vessel-details.styled'

interface Props {
    locationId: LocationIdType
    listOfAssociatedAssets: ListOfAssociatedAssets[] | undefined
}

export function VesselDetails({locationId, listOfAssociatedAssets}: Props): JSX.Element {
    const model = useTypedSelector((state) =>
        formattedUSBDeviceDetailsCachedReselector(state, locationId),
    )
    return (
        <Styled.Cell>
            <Styled.VesselDetails>
                <Styled.Details>
                    {model.hosts
                        ?.filter((element) =>
                            listOfAssociatedAssets?.find(
                                (findThisELement) => element.identity === findThisELement.identity,
                            ),
                        )
                        ?.map((givenAsset) => (
                            <Styled.DetailRow key={givenAsset.identity}>
                                <Styled.TitleCell id={`givenAsset-identity-${model.id}`}>
                                    {getUIDataorHosts(givenAsset, listOfAssociatedAssets).hostName}
                                </Styled.TitleCell>

                                <Styled.RightHandCell>
                                    Last seen:
                                    <Styled.AssetTimeStampValue
                                        id={`givenAsset-lastSeenTimestamp-${model.id}`}
                                    >
                                        {
                                            getUIDataorHosts(givenAsset, listOfAssociatedAssets)
                                                .lastSeenTimestamp
                                        }
                                    </Styled.AssetTimeStampValue>
                                </Styled.RightHandCell>
                            </Styled.DetailRow>
                        ))}
                </Styled.Details>
                <Styled.HostnameDetails>
                    <Styled.DetailRow>
                        <Styled.SubTitleCell id={`vessel-name-${model.id}`}>
                            <img src={NEW_VESSEL_ICON} style={{marginRight: '3px'}} />
                            {model.name}
                        </Styled.SubTitleCell>

                        <Styled.SubTitleCell id={`vessel-shipType-${model.id}`}>
                            {model.shipType}
                        </Styled.SubTitleCell>
                    </Styled.DetailRow>
                </Styled.HostnameDetails>
            </Styled.VesselDetails>
        </Styled.Cell>
    )
}
