import {useContext} from 'react'
import {
    LocationInventoryPageContext,
    SystemAssessmentModal,
} from '../location-inventory-page-context'
import {OTAssessmentReq} from '../types/ot-types'
import {GuidType} from '../../../../values/generic-type-defintions'
import {REST} from '../../../..'
import {
    toggleModalClose,
    submitAssessmentError,
    submitAssessmentSuccess,
    toggleModalOpen,
} from '../state/system-assessment-modal/action-creators'
import {updateOTSystem} from '../state/ot-systems/action-creators'

export interface UseSystemAssessmentModal {
    systemAssessmentModal: SystemAssessmentModal
    submitAssessment: (systemID: GuidType, assessmentReq: OTAssessmentReq) => void
    setSubmitAssessmentError: (error: string) => void
    openModal: (systemID: GuidType) => void
    closeModal: () => void
}

export function useSystemAssessmentModal(): UseSystemAssessmentModal {
    const {
        systemAssessmentModal,
        otSystems,
        page: {
            state: {locationID},
        },
    } = useContext(LocationInventoryPageContext)
    const {dispatch} = systemAssessmentModal
    const {dispatch: otSystemsDispatch} = otSystems

    if (!dispatch || !otSystemsDispatch) {
        throw Error(
            'dispatch is null! useSystemAssessmentModal cannot be used outside of provider!',
        )
    }

    const d = dispatch
    const od = otSystemsDispatch

    function submitAssessment(systemID: GuidType, assessmentReq: OTAssessmentReq) {
        REST.post(
            `ot-inventory/api/v1/locations/${locationID}/systems/${systemID}/assessment`,
            assessmentReq,
        )
            .then((response) => {
                d(submitAssessmentSuccess())
                od(updateOTSystem(response.data))
                d(toggleModalClose())
            })
            .catch(() => {
                const errorMsg = `An error occurred while submitting the assessment`

                d(submitAssessmentError(errorMsg))
            })
    }

    function setSubmitAssessmentError(error: string) {
        d(submitAssessmentError(error))
    }

    function openModal(systemID: GuidType) {
        d(toggleModalOpen(systemID))
    }

    function closeModal() {
        d(toggleModalClose())
    }

    return {
        systemAssessmentModal,
        submitAssessment,
        setSubmitAssessmentError,
        openModal,
        closeModal,
    }
}
