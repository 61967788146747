import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {smallFont} from '../../../../../../../theme/font-styles'

export const WarningMessageContainer = styled.div`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.text.default};
    padding: ${spacing(1)};
`
