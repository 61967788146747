import {BottomSection} from './_styled/bottom-section.styled'
import {Label} from './_styled/label.styled'
import {Value} from './_styled/value.styled'

interface BottomSectionInVesselProps {
    numberOfAssets: number
    imo: string
    identifier: string
}

export function BottomSectionInVessel({
    numberOfAssets,
    imo,
    identifier,
}: BottomSectionInVesselProps): JSX.Element {
    return (
        <BottomSection>
            <div>
                <Label>Configured Assets</Label>
                <Value id={`vessels-at-risk-vessel-total-assets-${numberOfAssets}-${identifier}`}>
                    {numberOfAssets}
                </Value>
            </div>
            <div id={`vessels-at-risk-vessel-imo-${identifier}-${imo}`}>{imo}</div>
        </BottomSection>
    )
}
