import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {BREAK_POINT_CARD_SINGLE_COLUMN} from '../../policy-list-card/_styled/policy-management-card.styled'

export const AssignPolicyDialogWrapper = styled.div`
    display: flex;
    position: sticky;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    white-space: break-spaces;
`

export const ArrowUp = styled.div`
    position: absolute;
    left: -20px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
`

export const ArrowUpInner = styled.div`
    position: absolute;
    left: -22px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid white;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    z-index: 101;
`

interface AssignPolicyDialogProps {
    isBottom: boolean
}

export const AssignPolicyDialog = styled.div<AssignPolicyDialogProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 300px;
    ${(props) => (props.isBottom ? 'bottom: -10px;' : 'top: -20px;')}
    left: -320px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(3)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    gap: ${spacing(1)};
    margin-top: -${spacing(2)};
    z-index: 100;
`

export const TitleRow = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: ${spacing(1)};
`

export const Title = styled.div`
    ${mediumSmallFont()}
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(2)};
    top: ${spacing(2)};
`

export const CurrentAppliedVessels = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${spacing(1)};
    height: 70px;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
`

export const CurrentVessel = styled.div`
    ${mediumSmallFont()};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const ButtonsArea = styled.div`
    display: flex;
    justify-content: center;
    column-gap: ${spacing(4)};
    padding-top: ${spacing(2)};
`

export const Button = styled.button`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    width: 70px;
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    :hover:not([disabled]) {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`

export const SectionContent = styled.div`
    padding: ${spacing(2)} ${spacing(1)} ${spacing(1)};
    margin: 0;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
`

export const SectionTitle = styled.div`
    ${mediumSmallFont()}
    display: flex;
    justify-content: flex-start;
    padding-bottom: ${spacing(1)};
`

export const AssignPolicyDialogWrapperCard = styled.div`
    display: flex;
    position: sticky;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    white-space: break-spaces;
    z-index: 100;
`
interface AssignPolicyDialogCardProps {
    width: number
}

export const ArrowUpCard = styled.div<AssignPolicyDialogCardProps>`
    position: absolute;
    ${(props) =>
        props.width <= BREAK_POINT_CARD_SINGLE_COLUMN
            ? `left: 15px; border-right: 15px solid ${props.theme.incidents.filterBar.titleSeparator};`
            : `left: -20px; border-left: 15px solid ${props.theme.incidents.filterBar.titleSeparator};`}
    top: -15px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
`

export const ArrowUpInnerCard = styled.div<AssignPolicyDialogCardProps>`
    position: absolute;
    ${(props) =>
        props.width <= BREAK_POINT_CARD_SINGLE_COLUMN
            ? `left: 17px; border-right: 15px solid white;`
            : `left: -22px; border-left: 15px solid white;`}
    top: -15px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    z-index: 101;
`

export const AssignPolicyDialogCard = styled.div<AssignPolicyDialogCardProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 300px;
    top: -20px;
    ${(props) =>
        props.width <= BREAK_POINT_CARD_SINGLE_COLUMN ? 'right: -170px;' : 'left: -320px;'}
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(3)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    gap: ${spacing(1)};
    margin-top: -${spacing(2)};
`
