import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(3)};
    margin: 0;
    width: 100%;
`
export const SectionTitle = styled.h2`
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
`
