import {IconWrapper} from './add-notes.styled'

/* istanbul ignore next */
export function NoteIcon(): JSX.Element {
    return (
        <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                <g id="Group_26948" data-name="Group 26948" transform="translate(-434 -242)">
                    <g id="Group_3339" data-name="Group 3339" transform="translate(434 243.7)">
                        <path
                            id="Path_2389"
                            data-name="Path 2389"
                            // tslint:disable-next-line: max-line-length
                            d="M447.467,259.3H435.683A1.694,1.694,0,0,1,434,257.6V245.7a1.694,1.694,0,0,1,1.683-1.7h5.892v1.7h-5.892v11.9h11.783v-7.65h1.683v7.65A1.694,1.694,0,0,1,447.467,259.3Z"
                            transform="translate(-434 -244)"
                            fill="#1f88e5"
                        />
                    </g>
                    <path
                        id="Path_2390"
                        data-name="Path 2390"
                        d="M441.7,250.365l2.4,2.4-3.005.6Z"
                        transform="translate(-1.134 -1.254)"
                        fill="#1f88e5"
                    />
                    <path
                        id="Path_2391"
                        data-name="Path 2391"
                        // tslint:disable-next-line: max-line-length
                        d="M451.036,244.9a1.7,1.7,0,1,0-2.4-2.4l-5.41,5.41,2.4,2.4Z"
                        transform="translate(-1.533 0)"
                        fill="#1f88e5"
                    />
                </g>
            </svg>
        </IconWrapper>
    )
}
