import {DeviceStatus} from '../../../../store/state/usb-inventory-filter/state'
import {UsbInventoryFilter} from '../../../../values/user-preferences/usb-inventory-filter'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: UsbInventoryFilter
    tooltip?: boolean
}

export function DetailFilterUsbInventory({criteria, tooltip}: DetailFilterProps): JSX.Element {
    const formattedStatus = criteria.selectedUsbDeviceStatus?.map((status) => {
        if (status === DeviceStatus.rejected) {
            return 'unwanted'
        }
        return status
    })

    return (
        <DetailsWrapper>
            {tooltip && (
                <Details id="orderBy">
                    • Order By: {criteria.sortColumn.orderBy} (
                    {criteria.sortColumn.orderAscending ? 'Ascending' : 'Descending'})
                </Details>
            )}
            {criteria.searchedFirstDetected && (
                <Details id="searchedFirstDetected">
                    • First Detect: {criteria.searchedFirstDetected.toString()}
                </Details>
            )}
            {criteria.searchedLastActive && (
                <Details id="searchedLastActive">
                    • Last Active: {criteria.searchedLastActive.toString()}
                </Details>
            )}
            {criteria.searchedVendor && (
                <Details id="searchedVendor">• Vendor: {criteria.searchedVendor}</Details>
            )}
            {criteria.searchedProductName && (
                <Details id="searchedProductName">
                    • Product Name: {criteria.searchedProductName}
                </Details>
            )}
            {criteria.searchedType && (
                <Details id="searchedType">• Type: {criteria.searchedType.toString()}</Details>
            )}
            {criteria.hasPurpose !== undefined && (
                <Details id="hasPurpose">
                    • Purpose: {criteria.hasPurpose ? 'Given' : 'Not given'}
                </Details>
            )}
            {formattedStatus && (
                <Details id="formattedStatus">• Status: {formattedStatus.toString()}</Details>
            )}
        </DetailsWrapper>
    )
}
