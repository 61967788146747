import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {IncidentSeverityValue} from '../../../../values/incident-response-values'
import {IncidentResponse} from '../../../incidents-v3/contexts/types/incident-response'
import {INCIDENTS_ENDPOINT} from './vessels-beta-endpoints'
export interface OldestOpenIncidentResponse {
    locationId: string
    incidentNo: string
    severity: IncidentSeverityValue
    ageOfOpenState: string
}
export async function buildOldestOpenIncident(
    locationIds: LocationIdType[],
): Promise<OldestOpenIncidentResponse[]> {
    try {
        const response = await REST.post(`${INCIDENTS_ENDPOINT}/find`, {
            locations: locationIds,

            orderBy: {
                column: 'CREATED',
                ascending: true,
            },
            statuses: ['OPEN'],
        })
        const oldestIncidents = getOldestIncidentPerLocation(response.data.data)

        return oldestIncidents.map((oneIncident) => {
            return {
                locationId: oneIncident.location,
                incidentNo: oneIncident.incidentCode,
                severity: oneIncident.severity,
                ageOfOpenState: oneIncident.updatedState.when,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<OldestOpenIncidentResponse>()
    }
}
interface IncidentMap {
    [location: string]: IncidentResponse | null
}
function getOldestIncidentPerLocation(incidents: IncidentResponse[]): IncidentResponse[] {
    const oldestIncidents: IncidentMap = {}

    incidents.forEach((incident) => {
        const {location, updatedState} = incident

        if (location && updatedState && updatedState.when) {
            const currentOldest = oldestIncidents[location]

            if (!currentOldest || updatedState.when < currentOldest.updatedState.when) {
                oldestIncidents[location] = incident
            }
        }
    })

    return Object.values(oldestIncidents).filter(
        (incident) => incident !== null,
    ) as IncidentResponse[]
}
