import ActionType from './action-type'
import {Action as ReduxAction} from 'redux'
import {LocationIdType} from '../locations/state'
import {
    SORT_DIRECTION_TYPE,
    VesselFiltersState,
    ZOOM_LEVEL_TYPE,
} from './types/my-vessel-filter-state'

export enum Values {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export type Value = Values.HIGH | Values.MEDIUM | Values.LOW

export interface RequestFilterAction extends ReduxAction {
    type: ActionType.REQUEST_FILTER
}

export interface ReceiveFilterAction extends ReduxAction {
    type: ActionType.RECEIVE_FILTER
    payload: VesselFiltersState
}

export interface SetBooleanValue extends ReduxAction {
    type: ActionType.TOGGLE_ALL_ASSETS | ActionType.TOGGLE_ALL_VESSELS
    payload: boolean
}

export interface ToggleValueThresholdAction extends ReduxAction {
    type: ActionType.TOGGLE_VALUE_THRESHOLD
    payload: {
        value: Value
        bool: boolean
        threshold: 'high' | 'medium' | 'low'
    }
}

export interface ToggleVessel extends ReduxAction {
    type: ActionType.TOGGLE_VESSEL
    payload: {
        allLocations: Array<LocationIdType>
        location: LocationIdType
        newValue: boolean
    }
}

export interface ToggleFilteredVessels extends ReduxAction {
    type: ActionType.TOGGLE_FILTERED_VESSELS
    payload: {
        allLocations: Array<LocationIdType>
        filteredLocations: Array<LocationIdType>
        newValue: boolean
    }
}

export interface DeepLinkVessel extends ReduxAction {
    type: ActionType.DEEP_LINK_VESSEL
    payload: LocationIdType
}

export interface SetSortField extends ReduxAction {
    type: ActionType.ASSET_SORT | ActionType.VESSEL_SORT
    payload: {
        field: string
        direction: SORT_DIRECTION_TYPE
    }
}

export interface SetSearchAssetTerm extends ReduxAction {
    type: ActionType.SET_SEARCH_ASSET_TERM
    payload: string
}

export interface SetSearchVesselTagTerm extends ReduxAction {
    type: ActionType.SET_SEARCH_VESSEL_TAG_TERM
    payload: string[]
}

export interface SetSearchVesselNameTerm extends ReduxAction {
    type: ActionType.SET_SEARCH_VESSEL_NAME_TERM
    payload: string
}

export interface SetSearchVesselTerm extends ReduxAction {
    type: ActionType.SET_SEARCH_VESSEL_TERM
    payload: string
}

export interface SetShowXButton extends ReduxAction {
    type: ActionType.SET_SHOW_X_BUTTON
    payload: boolean
}

export interface ChangeZoomLevel extends ReduxAction {
    type: ActionType.CHANGE_ZOOM_LEVEL
    payload: ZOOM_LEVEL_TYPE
}

export interface ResetFilter extends ReduxAction {
    type: ActionType.RESET_FILTER
}

export type Action =
    | SetBooleanValue
    | SetSortField
    | ToggleValueThresholdAction
    | ToggleVessel
    | SetSearchAssetTerm
    | SetSearchVesselTagTerm
    | SetSearchVesselNameTerm
    | SetSearchVesselTerm
    | SetShowXButton
    | ChangeZoomLevel
    | ResetFilter
    | ReceiveFilterAction
    | RequestFilterAction
    | DeepLinkVessel
    | ToggleFilteredVessels
