import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Button = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${spacing(2)};
    background: ${(props) => props.theme.usbDevices.filterBar.closeButton.background};
    color: ${(props) => props.theme.usbDevices.filterBar.closeButton.text};
    border: 1px solid ${(props) => props.theme.usbDevices.filterBar.closeButton.border};
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.28px;
`
