import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)};
    padding-bottom: 0;
    margin: 0;
    width: 100%;
`

export const Details = styled.div`
    padding: 0 ${spacing(1)} ${spacing(2)};
`
