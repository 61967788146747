import {
    VesselThreatScore,
    Size,
} from '../../../../vessel-threat-score-square/vessel-threat-score.styled'
import {useState} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Container} from './_styled/container.styled'
import {TopSection} from './_styled/top-section.styled'
import {ExpandButton} from './_styled/expand-button.styled'
import {Title} from './_styled/title.styled'
import {Type} from './_styled/type.styled'
import {LocationIdType} from '../../../../../../../store/state/locations/state'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {BottomSectionInVessel} from './bottom-section-in-vessel'
import {AssetsListForVesselsAtRisk} from './assets-list-vessels-at-risk'
import {threatMeasuresSelector} from '../../../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../../../values/nodes/NodeData'
import {VesselTag} from '../../../../../../../components/vessel-tag/vessel-tag'
import {vesselDetailsCachedReselector} from './re-reselectors/vessel-details.cached-reselector'
import {VesselTagsCell} from './_styled/vessel-tags-cell.styled'
import {logButtonClickFor} from '../../../../../../../store/state/audit-log/action-creators'
import {useDispatch} from 'react-redux'

interface VesselDetailsProps {
    locationId: LocationIdType
    last: boolean
}

export function VesselDetails({locationId, last}: VesselDetailsProps): JSX.Element {
    const [expanded, setExpanded] = useState(false)
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    const model = useTypedSelector((state) => vesselDetailsCachedReselector(state, locationId))
    const dispatch = useDispatch()
    function onClickWrapper() {
        if (expanded) {
            dispatch(
                logButtonClickFor(
                    `collapseVesselDetails_locationId:${locationId}_nodeScore:${model.score}`,
                    'dashboard-fleetlist',
                ),
            )
        } else {
            dispatch(
                logButtonClickFor(
                    `expandVesselDetails_locationId:${locationId}_nodeScore:${model.score}`,
                    'dashboard-fleetlist',
                ),
            )
        }
        setExpanded(!expanded)
    }
    const uniqueId = locationId || 'unknown'
    return (
        <Container last={last}>
            <TopSection expanded={expanded}>
                <VesselThreatScore
                    threatMeasure={threatMeasure}
                    size={Size.LARGE}
                    score={model.score}
                    id={`vessels-at-risk-vessel-threat-score-${uniqueId}`}
                >
                    {model.score}
                </VesselThreatScore>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', rowGap: '5px'}}>
                    <div style={{display: 'flex', flex: 1}}>
                        <Title id={`vessels-at-risk-vessel-title-${uniqueId}`}>{model.name}</Title>
                        <Type id={`vessels-at-risk-vessel-type-${uniqueId}`}>{model.shipType}</Type>
                        <ExpandButton
                            onClick={onClickWrapper}
                            id={`vessels-at-risk-vessel-expand-button-${uniqueId}`}
                        >
                            <Icon
                                glyph={expanded ? 'DropdownUp' : 'DropdownDown'}
                                height={15}
                                width={15}
                            />
                        </ExpandButton>
                    </div>
                    <VesselTagsCell>
                        {model.vesselTags?.map((element) => (
                            <VesselTag
                                key={element.identity}
                                tagName={element.name}
                                showHover={true}
                            />
                        ))}
                    </VesselTagsCell>
                </div>
            </TopSection>
            {expanded && (
                <BottomSectionInVessel
                    numberOfAssets={model.numberOfAssets}
                    imo={model.imoNumber}
                    identifier={uniqueId}
                />
            )}
            {expanded && <AssetsListForVesselsAtRisk locationId={locationId} />}
        </Container>
    )
}
