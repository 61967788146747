import styled from 'styled-components'

interface SelectedTabProps {
    firstTab?: boolean
}

export const SelectedTab = styled.div<SelectedTabProps>`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    text-align: center;
    align-items: center;
    padding: 10px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-left: ${(props) =>
        props.firstTab ? `solid 1px ${props.theme.colors.border.lowContrast}` : ''};
    border-right: solid 1px ${(props) => props.theme.colors.border.lowContrast};
    border-top: solid 1px ${(props) => props.theme.colors.border.lowContrast};
    border-bottom: solid 1px white;
    color: ${(props) => props.theme.modalDialog.tabs.selected.text};
    background-color: ${(props) => props.theme.modalDialog.tabs.selected.background};
`
