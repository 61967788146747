import styled from 'styled-components'
import {ThreatValue} from '../../../../../../helpers/threatHelper'
import {getBackGroundColorFromThreat} from '../../../../../../theme/helpers/node-title'

interface SquareProps {
    threatLevel: ThreatValue | undefined
    roundedCorners: boolean
}

export const Square = styled.div<SquareProps>`
    background-color: ${(props) => getBackGroundColorFromThreat(props.theme, props.threatLevel)};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: ${(props) => (props.roundedCorners ? '4px' : 0)};
`
