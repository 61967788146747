import * as Styled from './header.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {RefreshButton} from '../refresh-button/refresh-button'
import {DataLastUpdated} from '../last-updated/data-last-updated'
import {useDispatch} from 'react-redux'
import {fetchLocations} from '../../../../../store/state/locations/action-creators'

export function Header(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    function refresh() {
        dispatch(fetchLocations())
    }
    return (
        <Styled.Header width={width}>
            <Styled.PageTitle width={width}>Vessels</Styled.PageTitle>
            <Styled.RefreshArea width={width}>
                <RefreshButton refreshFunction={refresh} />
                <DataLastUpdated />
            </Styled.RefreshArea>
        </Styled.Header>
    )
}
