import {useEffect} from 'react'
import {DataLoading} from '../../../../../components/data-loading/data-loading'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {fetchAuditLog} from '../../../../../store/state/audit-log/action-creators'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import LoadingState from '../../../../../values/loading-state-enum'
import {isUserAllowed} from '../../../../../values/Role'
import {useUserManagement} from '../../../context/use-user-management'
import {NoDataModal} from '../../user-list-table/user-details-modal/no-data-modal'
import {UserDetails} from './user-details'
import * as Styled from './user-details-extended.styled'
import {UserDetailsReadOnly} from './user-details-read-only'
import {useDispatch} from 'react-redux'

export function UserDetailsExpanded(): JSX.Element | null {
    const {activeUserDetails, loadingDataState} = useUserManagement()
    const dispatch = useDispatch()
    const currentUser = useTypedSelector(currentUserSelector)
    const isAdminRole = isUserAllowed(currentUser.roles, [
        'CUSTOMER_ADMIN',
        'DEVELOPER',
        'THREAT_ANALYST',
    ])

    useEffect(() => {
        dispatch(fetchAuditLog(activeUserDetails.user))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUserDetails.user])

    if (!activeUserDetails) {
        return <NoDataModal />
    }

    return (
        <Styled.DetailsExpandedSection>
            {loadingDataState === LoadingState.Loaded ? (
                isAdminRole ? (
                    <UserDetails />
                ) : (
                    <UserDetailsReadOnly />
                )
            ) : (
                <DataLoading />
            )}
        </Styled.DetailsExpandedSection>
    )
}
