import React from 'react'
import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellUsbHosts(): JSX.Element {
    const {data, initialData, displayData, changeUsbHosts} = useVesselConfig()
    const changed = data.usbHosts?.toString() !== initialData.usbHosts?.toString()
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeUsbHosts(e.target.value.split(','))
    }

    return (
        <DataInput
            label="USB Hosts"
            initialValue={displayData.usbHosts}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
