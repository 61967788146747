import {VesselTags} from '../../../../../../store/state/vessel-tags/state'
import {TableDataCell} from './_styled/table-data-cell.styled'
import {TableDataRow} from './_styled/table-data-row.styled'
import {getWidth, TableData} from '../../data-helpers'
import {VesselTag} from '../../../../../../components/vessel-tag/vessel-tag'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {useDispatch} from 'react-redux'
import {setSearchVesselTagTerm} from '../../../../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'
import {Link} from 'react-router-dom'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../../store/state/users/state'

export type TableDataRow = TableData & VesselTags

type TableRowProps = {
    index: number
    tableDataRow: TableDataRow
    vesselCount: number
}

export function TableRow({index, tableDataRow, vesselCount}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const {width} = useDimensions()
    const maxWidth = getWidth(width)
    const dispatch = useDispatch()
    const {strictMode} = useTypedSelector(currentUserSelector)

    return (
        <>
            <TableDataRow gridRow={gridRow} id={`data-row_${tableDataRow.identity}`} />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`tagName-value_${tableDataRow.identity}`}
            >
                <div style={{display: 'flex', gap: '5px'}}>
                    <VesselTag tagName={tableDataRow.name} showHover={true} maxWidth={maxWidth} />
                    <span>({vesselCount})</span>
                </div>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`maxScore-value_${tableDataRow.identity}`}
                centered
            >
                {tableDataRow.maxScore}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`incidents-value_${tableDataRow.identity}`}
                centered
            >
                <Link
                    onClick={() =>
                        dispatch(
                            setSearchVesselTagTerm(
                                [tableDataRow.name],
                                PageType.INCIDENTS,
                                strictMode === StrictMode.DefaultVisibility,
                            ),
                        )
                    }
                    to="/incidents"
                    style={{color: '#1f88e5'}}
                >
                    {tableDataRow.incidents}
                </Link>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`assetDiscovery-value_${tableDataRow.identity}`}
                centered
            >
                <Link
                    onClick={() =>
                        dispatch(
                            setSearchVesselTagTerm(
                                [tableDataRow.name],
                                PageType.NETWORK_ASSETS,
                                strictMode === StrictMode.DefaultVisibility,
                            ),
                        )
                    }
                    to="/network-assets"
                    style={{color: '#1f88e5'}}
                >
                    {tableDataRow.assetDiscovery}
                </Link>
            </TableDataCell>
        </>
    )
}
