import {formatDate} from '../../../../../helpers/formatting'
import {DateTimeEventOutputModel} from '../../../models/date-time-event.model'
import {When} from '../../table/event/when.styled'
import {Who} from '../../table/event/who.styled'
import {ReducedText} from '../_styled/reduced-text.styled'
import * as styled from './_styled/event.styled'

interface EventProps {
    id: string
    data: DateTimeEventOutputModel
    title: string
    titleOnSameLine: boolean
    gridArea?: string
    lastElement?: boolean
}

export function Event({
    id,
    data,
    title,
    titleOnSameLine,
    gridArea,
    lastElement,
}: EventProps): JSX.Element {
    if (!data) {
        return (
            <styled.Event
                lastElement={true}
                gridArea={gridArea}
                id={id}
                titleOnSameLine={titleOnSameLine}
            />
        )
    }

    return (
        <styled.Event
            lastElement={lastElement}
            gridArea={gridArea}
            id={id}
            titleOnSameLine={titleOnSameLine}
        >
            <ReducedText fixedWidth={titleOnSameLine}>{title}</ReducedText>
            <styled.EventContainer titleOnTheSameLine={titleOnSameLine}>
                <When>{formatDate(data.when)}</When>
                <Who>{data.who}</Who>
            </styled.EventContainer>
        </styled.Event>
    )
}
