import {getLines} from '../../data-helper'
import * as Styled from './_styled/metrics-table.styled'

interface FixedColumnProps {
    text: string
    gridColumn: number
    centered?: boolean
}
export function FixedColumn({text, gridColumn, centered}: FixedColumnProps): JSX.Element {
    return (
        <Styled.TableHeaderCell id={`column_${text}`} gridColumn={gridColumn}>
            <Styled.TableHeaderText centered={centered} id={`text-${text}`}>
                {getLines(text).map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </Styled.TableHeaderText>
        </Styled.TableHeaderCell>
    )
}
