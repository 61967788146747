import {TableHeaderGrid} from './styled/table-header-grid.styled'
import TableHeader from './table-header'

export function TableHeaderRow(): JSX.Element {
    return (
        <TableHeaderGrid>
            <TableHeader />
        </TableHeaderGrid>
    )
}
