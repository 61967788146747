import styled from 'styled-components'
import {mediumFont, mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {IncidentStatus} from '../../../contexts/types/incident-status'
interface Props {
    incidentStatus: IncidentStatus
    activeFilter: boolean
    width: number
}
export const FilterCard = styled.div<Props>`
    display: flex;
    flex: 1;
    flex-direction: column;
    color: ${(props) => props.theme.incidents.dataRow.text};
    cursor: pointer;
    border-left: ${(props) => getBorderLeft(props.incidentStatus, props.theme)};
    opacity: ${(props) => (props.activeFilter ? 1 : 0.5)};
`
function getBorderLeft(status: string | undefined, theme: CyberOwlTheme): string {
    switch (status) {
        case 'NEW':
            return `8px solid ${theme.incidents.dataRow.statusBar.new}`
        case 'OPEN':
            return `8px solid ${theme.incidents.dataRow.statusBar.open}`
        case 'CLOSED':
            return `8px solid ${theme.incidents.dataRow.statusBar.closed}`
        default:
            return `8px solid inherit`
    }
}
export const FilterCount = styled.a`
    padding-left: ${spacing(1)};
    ${mediumFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const FilterText = styled.h2`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
