import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {OTAsset} from './state'
import axios from 'axios'

const BASE_URL = '/ot-inventory/api/v1/locations'

export function requestOTAssets(locationID: string): Actions.RequestOTAssets {
    return {
        type: ActionType.REQUEST_ASSETS,
        payload: {
            locationID,
        },
    }
}

export function setOTAssets(locationID: string, data: OTAsset[]): Actions.SetOTAssets {
    return {
        type: ActionType.SET_ASSETS,
        payload: {
            locationID,
            data,
        },
    }
}

export function failFetchOTAssets(locationID: string): Actions.FailFetchOTAssets {
    return {
        type: ActionType.FAIL_FETCH_ASSETS,
        payload: {
            locationID,
        },
    }
}

export function addOTAsset(data: OTAsset): Actions.AddOTAsset {
    return {
        type: ActionType.ADD_ASSET,
        payload: {
            data,
        },
    }
}

export function updateOTAsset(data: OTAsset): Actions.UpdateOTAsset {
    return {
        type: ActionType.UPDATE_ASSET,
        payload: {
            assetId: data.id,
            data,
        },
    }
}

export function openModal(asset?: OTAsset): Actions.OpenModal {
    return {
        type: ActionType.OPEN_MODAL,
        payload: {
            asset,
        },
    }
}

export function closeModal(): Actions.CloseModal {
    return {
        type: ActionType.CLOSE_MODAL,
    }
}

export const fetchOTAssets = (
    locationID: string,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestOTAssets(locationID))
        REST.get(`${BASE_URL}/${locationID}/assets`)
            .then((response) => {
                dispatch(setOTAssets(locationID, response.data))
            })
            .catch(() => {
                failFetchOTAssets(locationID)
            })
    }
}

export const removeOTAsset = (asset: OTAsset): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        // Change loading state
        dispatch({type: ActionType.REMOVE_ASSET_REQUEST})

        REST.delete(`${BASE_URL}/${asset.locationID}/assets/${asset.id}`, {
            transitional: {
                clarifyTimeoutError: true,
            },
        })
            .then(() => {
                dispatch({type: ActionType.REMOVE_ASSET_SUCCESS, payload: {id: asset.id}})
                dispatch(closeModal())
            })
            .catch((error) => {
                let errorMessage = 'Error occured, unable to delete asset'

                if (axios.isAxiosError(error)) {
                    // Use generic error message from axios
                    // This will be something like a timeout.
                    errorMessage = error.message

                    // Handle time out error with better message
                    if (error.code === 'ETIMEDOUT') {
                        // TODO: Add the custom error message
                        // from product regarding the timeout.
                        // For now we'll just stick to generic message
                        errorMessage = 'Request timed out.'
                    }

                    if (error?.response?.data?.message) {
                        // but if there is a response from server,
                        // use the server response
                        errorMessage = error.response.data.message
                    }
                }

                dispatch({
                    type: ActionType.REMOVE_ASSET_FAILURE,
                    payload: {error: errorMessage},
                })
            })
    }
}
