import {addQueryParam} from '../../../../../../helpers/navigation'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import * as Styled from './edit-asset-button.styled'
interface EditAssetButtonProps {
    assetIdCard: GuidType
}
export function EditAssetButton({assetIdCard}: EditAssetButtonProps): JSX.Element {
    return (
        <Styled.PrimaryButton
            id="edit-asset-button"
            onClick={() => {
                addQueryParam('editAsset', assetIdCard)
            }}
        >
            Edit Asset
        </Styled.PrimaryButton>
    )
}
