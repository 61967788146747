import {SavedFilterSubscription} from '../../../../../../store/state/saved-filters/state'
import * as Styled from './saved-filter-alert-row.styled'

interface Props {
    subscriptionData: SavedFilterSubscription
}

export function AlertDetails({subscriptionData}: Props): JSX.Element {
    return (
        <Styled.AlertDetails>
            <Styled.DetailRow>Notification: {subscriptionData.schedule}</Styled.DetailRow>
            <Styled.DetailRow>Email: {subscriptionData.notificationEmail}</Styled.DetailRow>
            <Styled.DetailRow>Email prefix: {subscriptionData.subjectPrefix}</Styled.DetailRow>
        </Styled.AlertDetails>
    )
}
