import {GuidType} from '../../../../values/generic-type-defintions'

export interface LocationInIsolationMode {
    identity: GuidType
    code: string
    locationDescription: string
    active: boolean
    processingMode: ProcessingMode
    isolationMode: IsolationMode
}
enum ProcessingMode {
    ProcessingModeUndefined = '',
    ProcessingModeLearning = 'LEARNING',
    ProcessingModeListening = 'LISTENING',
}
export enum IsolationMode {
    IsolationModeUndefined = '',
    IsolationModeOn = 'ON',
    IsolationModeOff = 'OFF',
}
