import {useContext} from 'react'
import {LicensesContext} from './licenses-context'
import {UseLicensesOutput} from './UseLicensesOutput'
import {warn} from '../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {License, LicenseKeysTypes} from '../../../store/state/config/state'
import {REST} from '../../..'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../store/state/config/selectors'
import {DeepPartial} from '../../../utils/Utils'

export const FLEET_CONFIG_BASEURL = 'api/v1/fleetConfiguration'

export function useLicenses(): UseLicensesOutput {
    const {state, dispatch} = useContext(LicensesContext)
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useLicenses must be used within a LicensesContext')
    }

    function toggleIncidentsPolicy(payload: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleIncidentsPolicy(payload))
    }

    function setIncidentsPolicyEndDate(payload: Date | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setIncidentsPolicyEndDate(payload))
    }

    function setSaveButtonState(payload: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveButtonState(payload))
    }

    function toggleOnboardEngagement(payload: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleOnboardEngagement(payload))
    }

    function setOnboardEngagementEndDate(payload: Date | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setOnboardEngagementEndDate(payload))
    }

    function toggleNetworkEnforcement(payload: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleNetworkEnforcement(payload))
    }

    function setNetworkEnforcementMaxVessels(payload: number | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNetworkEnforcementMaxVessels(payload))
    }

    function setNetworkEnforcementEndDate(payload: Date | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNetworkEnforcementEndDate(payload))
    }

    function toggleRemovableEnforcement(payload: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleRemovableEnforcement(payload))
    }

    function setRemovableEnforcementMaxVessels(payload: number | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setRemovableEnforcementMaxVessels(payload))
    }

    function setRemovableEnforcementEndDate(payload: Date | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setRemovableEnforcementEndDate(payload))
    }

    async function saveLicenses(): Promise<void> {
        const category = 'SOC'

        const payloads: DeepPartial<License<LicenseKeysTypes>[]> = [
            {
                key: 'License:Metrics:OnboardEngagement',
                value: {
                    endTimestamp: state.onboardEngagementEndDate,
                    numberOfLicenses: null,
                    enabled: state.onboardEngagementEnabled,
                },
                identity: fleetConfig.LicenseState?.['License:Metrics:OnboardEngagement']?.identity,
                category,
            },
            {
                key: 'License:Enforcement:Removables',
                value: {
                    endTimestamp: state.removableEnforcementEndDate,
                    numberOfLicenses: state.removableEnforcementMaxVessels,
                    enabled: state.removableEnforcementEnabled,
                },
                identity: fleetConfig.LicenseState?.['License:Enforcement:Removables']?.identity,
                category,
            },
            {
                key: 'License:Enforcement:Networks',
                value: {
                    endTimestamp: state.networkEnforcementEndDate,
                    numberOfLicenses: state.networkEnforcementMaxVessels,
                    enabled: state.networkEnforcementEnabled,
                },
                identity: fleetConfig.LicenseState?.['License:Enforcement:Networks']?.identity,
                category,
            },
            {
                key: 'License:Incidents',
                value: {
                    endTimestamp: state.incidentsPolicyEndDate,
                    numberOfLicenses: null,
                    enabled: state.incidentsPolicyEnabled,
                },
                identity: fleetConfig.LicenseState?.['License:Incidents']?.identity,
                category,
            },
        ]
        const pArr = payloads.map((payload) => REST.put(FLEET_CONFIG_BASEURL, payload))
        await Promise.all(pArr)
        const saveButton = document.getElementById('Save-button')
        if (!saveButton) {
            return
        }
        saveButton.innerText = 'Saved'
        setTimeout(() => {
            saveButton.innerText = 'Save'
        }, 1000)
    }

    const {
        incidentsPolicyEndDate,
        removableEnforcementEndDate,
        networkEnforcementEndDate,
        onboardEngagementEndDate,
        removableEnforcementMaxVessels,
        networkEnforcementMaxVessels,
    } = state
    return {
        toggleIncidentsPolicy,
        saveLicenses,
        incidentsPolicyEnabled: state.incidentsPolicyEnabled,
        metricsPolicyEnabled: state.onboardEngagementEnabled,
        setIncidentsPolicyEndDate,
        setSaveButtonState,
        toggleOnboardEngagement,
        setOnboardEngagementEndDate,
        toggleNetworkEnforcement,
        setNetworkEnforcementMaxVessels,
        setNetworkEnforcementEndDate,
        toggleRemovableEnforcement,
        setRemovableEnforcementMaxVessels,
        setRemovableEnforcementEndDate,
        incidentsPolicyEndDate,
        removableEnforcementEndDate,
        networkEnforcementEndDate,
        onboardEngagementEndDate,
        removableEnforcementMaxVessels,
        networkEnforcementMaxVessels,
    }
}
