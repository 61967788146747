import {useEffect, useState} from 'react'
import {NetworkDataInput} from './data-input'
import {isEqual} from 'lodash'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {ValidationResult, isValidCidr} from '../../../../../reselectors/network-data-validation'
import {formattedValue} from '../../../../../reselectors/helper'

interface VesselNetworksBusinessNetworksProps {
    businessNetworks: string[] | undefined
    locationIdentity: GuidType
}
export function VesselNetworksBusinessNetworks({
    businessNetworks,
    locationIdentity,
}: VesselNetworksBusinessNetworksProps): JSX.Element {
    const [resultValidation, setResultValidation] = useState<ValidationResult | null>(null)
    const {changeBusinessNetworks, initialValue} = useVesselManagement()

    const isChanged =
        !isEqual(businessNetworks, initialValue.networkConfiguration?.BusinessNetworks) &&
        resultValidation !== 'invalid_subnet'

    useEffect(() => {
        setResultValidation(null)
    }, [locationIdentity])

    useEffect(() => {
        if (
            businessNetworks &&
            (resultValidation === 'valid_subnet' ||
                (businessNetworks[0].length === 0 && resultValidation !== 'invalid_subnet'))
        ) {
            changeBusinessNetworks(businessNetworks)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessNetworks, resultValidation])

    function businessNetworksOnChangeHandler(e: React.ChangeEvent<HTMLTextAreaElement>): void {
        e.preventDefault()
        setResultValidation(isValidCidr(e.target.value.split('\n')))
        changeBusinessNetworks(e.target.value.split('\n'))
    }

    return (
        <NetworkDataInput
            label="Business Network"
            initialValue={formattedValue(businessNetworks)}
            onChangeHandler={businessNetworksOnChangeHandler}
            isValidCidr={resultValidation}
            isChanged={isChanged}
        />
    )
}
