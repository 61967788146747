import ActionType from './action-type'
import * as Actions from './actions'
import {Api, QuerySpecification} from '../../../api/Api'
import * as uuid from 'uuid'
import {ets, QueryCancellation} from '../../../websocket/Queries'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {EtsData} from './state'

const QUERY_PATH = 'latestEnterpriseThreatScore'
const QUERY_SCHEDULE = '10s'

const QUERY_PATH_LOCATION = 'latestLocationThreatScores'

export function fetchEts(
    location?: string | null,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): QueryCancellation => {
        dispatch(requestEtsAction())
        const query: QuerySpecification = location ? etsLocationQuery() : etsQuery()
        api.newQuery(query, (data) => {
            dispatch(receiveEtsAction(location ? data[location] : data))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

export function requestEtsAction(): Actions.RequestEtsAction {
    return {
        type: ActionType.REQUEST_ETS,
    }
}

export function receiveEtsAction(etsData: EtsData): Actions.ReceiveEtsAction {
    return {
        type: ActionType.RECEIVE_ETS,
        payload: etsData,
    }
}

function etsQuery(): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${ets}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
    }
}

function etsLocationQuery(): QuerySpecification {
    return {
        path: QUERY_PATH_LOCATION,
        localId: `${ets}-latestLocationThreatScores-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
    }
}
