import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {animated} from 'react-spring'

export const ModalOverlay = styled(animated.span)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props) => props.theme.modalDialog.overlay};
    z-index: 999;
    backdrop-filter: blur(8px);
    opacity: 1;
`

export const ModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    min-height: 100vh;
    overflow: hidden;
`

export const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 6px 0 ${(props) => props.theme.vessels.modal.boxShadow};
    background-color: ${(props) => props.theme.vessels.dataArea.background};
    border-radius: ${spacing(1)};
    width: 90vw;
    max-width: 800px;
    height: 80vh;
    max-height: 800px;
    margin: 0 auto;
    padding: ${spacing(4)};
    overflow: auto;
`
export const ModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${spacing(1)};
`

export const ContentArea = styled.div`
    overflow: auto;
    height: 100%;
`
