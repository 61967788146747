import styled from 'styled-components'

interface ExpandButtonProps {
    id: string
    onClick: () => void
    isEditing?: boolean
    disabled?: boolean
}

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
`

const StyledPath = styled.path<{isEditing: boolean}>`
    fill: ${(prop) => (!prop.isEditing ? '#1f88e5' : 'grey')};
`

export function EditButton({id, onClick, isEditing = false, disabled = false}: ExpandButtonProps) {
    return (
        <StyledButton onClick={onClick} id={id} disabled={disabled}>
            <svg width="15" height="15" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <StyledPath
                    isEditing={isEditing}
                    d="M0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM17.71 4.04C17.8027 3.94749 17.8762 3.8376 17.9264 3.71663C17.9766 3.59565 18.0024 3.46597 18.0024 3.335C18.0024 3.20403 17.9766 3.07435 17.9264 2.95338C17.8762 2.83241 17.8027 2.72252 17.71 2.63L15.37 0.290003C15.2775 0.197299 15.1676 0.123751 15.0466 0.07357C14.9257 0.0233886 14.796 -0.00244141 14.665 -0.00244141C14.534 -0.00244141 14.4043 0.0233886 14.2834 0.07357C14.1624 0.123751 14.0525 0.197299 13.96 0.290003L12.13 2.12L15.88 5.87L17.71 4.04Z"
                />
            </svg>
        </StyledButton>
    )
}
