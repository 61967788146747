import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {ClearButton} from '../../../../../components/page-filter/components/shared/clear-button/clear-button'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {toggleAllMetricType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {StringUtils} from '../../../../../utils/Utils'
import {ViewAllRowWrapper} from './metrics-type-filter.styled'

interface Props {
    state: TriStateButtonState
    setState: (value: TriStateButtonState) => void
    showClearButton: boolean
    label: string
}

export function ViewAllMetricTypeRow({
    state,
    setState,
    showClearButton,
    label,
}: Props): JSX.Element {
    return (
        <ViewAllRowWrapper>
            <TriStateButton
                id={`${PageType.METRICS}_filter-bar_view-all_${label}`}
                label={StringUtils.capitaliseString(label)}
                state={state}
                onClick={setState}
            />
            {showClearButton && (
                <ClearButton
                    clearFn={toggleAllMetricType}
                    id={`${PageType.METRICS}_filter-bar_clear-view-all`}
                />
            )}
        </ViewAllRowWrapper>
    )
}
