import {batch, useDispatch} from 'react-redux'
import * as Styled from './incidents-number.styled'
import {toggleVesselForVesselBetaInterlinking} from '../../../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logInterlinksClickForVesselsBeta} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {Link} from 'react-router-dom'
import {analysisPeriodVesselsBetaFilterSelector} from '../../../../../store/state/vessels-beta-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {NumberOfIncidentsResponse} from '../../../contexts/types/number-of-incidents-raised-and-open'

interface IncidentsNumberProps {
    findIncidentData: NumberOfIncidentsResponse
    columnName: string
}
export function IncidentsNumber({findIncidentData, columnName}: IncidentsNumberProps): JSX.Element {
    const dispatch = useDispatch()
    const analysisPeriod = useTypedSelector(analysisPeriodVesselsBetaFilterSelector)

    return (
        <Link
            onClick={() =>
                batch(() => {
                    dispatch(
                        toggleVesselForVesselBetaInterlinking(
                            findIncidentData.locationId,
                            PageType.INCIDENTS,
                            analysisPeriod,
                        ),
                    )
                    dispatch(
                        logInterlinksClickForVesselsBeta(
                            AuditLogPageType.INCIDENTS,
                            findIncidentData.locationId,
                            columnName,
                        ),
                    )
                })
            }
            to="/incidents"
            id={`incidents-number-${findIncidentData.locationId}-${columnName}`}
        >
            <Styled.IncidentAgeDisplay
                id={`incidents-count-${findIncidentData.incidentsCount}-${columnName}`}
            >
                {findIncidentData.incidentsCount}
            </Styled.IncidentAgeDisplay>
        </Link>
    )
}
