import {IncidentType, getTypeDisplayName} from '../../../incidents-v3/contexts/types/incident-type'
import * as Styled from './incidents-types-and-severities.styled'
export interface CurrentScoreTooltipProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label?: any
    data: {name: string; value: number; displayName: string}[]
    incidentTypes: IncidentType[]
}

export function CurrentTypesTooltip({
    active,
    payload,
    data,
    label,
    incidentTypes,
}: CurrentScoreTooltipProps): JSX.Element | null {
    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.displayName === label)
        if (!dataPoint) {
            return null
        }
        return (
            <Styled.ToolTipContainer id={`ToolTipContainer-${dataPoint?.name}`}>
                <Styled.ToolTipTitle>
                    <div>{getTypeDisplayName(incidentTypes, dataPoint?.name)}</div>
                </Styled.ToolTipTitle>
                <Styled.ToolTipSubTitle>{`${dataPoint?.value} incidents`}</Styled.ToolTipSubTitle>
            </Styled.ToolTipContainer>
        )
    }

    return null
}
