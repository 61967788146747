import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './expand-panel-button.styled'
import {SoftwareDetailType} from '../../../contexts/types/paged-software-inventory-state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {DetailExpandedType} from '../../../../../store/state/software-inventory-filter/state'
import {useDispatch} from 'react-redux'
import {toggleModalDetailsExpanded} from '../../../../../store/state/software-inventory-filter/action-creators'

interface ExpandPanelButtonProps {
    headingRow: SoftwareDetailType
}

export function ExpandPanelButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    const {detailExpanded} = useTypedSelector(softwareInventoryFilterSelector)
    const isExpanded = getCurrentDetailExpanded(headingRow, detailExpanded)
    const disptach = useDispatch()
    function setNewDetailExpanded() {
        const newDetailExpanded: DetailExpandedType = getNewDetailExpanded(
            headingRow,
            detailExpanded,
        )
        disptach(toggleModalDetailsExpanded(newDetailExpanded))
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow id={`text_${headingRow}`}>
                {headingRow}
            </Styles.DetailHeadingRow>
            <Styles.Button
                onClick={() => setNewDetailExpanded()}
                id={`expand-details-button_${headingRow}`}
            >
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewDetailExpanded = (
    toggledDetail: SoftwareDetailType,
    currentDetailExpanded: DetailExpandedType,
): DetailExpandedType => {
    switch (toggledDetail) {
        case SoftwareDetailType.Details:
            return {
                ...currentDetailExpanded,
                detailsExpanded: !currentDetailExpanded.detailsExpanded,
            }
        case SoftwareDetailType.Insights:
            return {
                ...currentDetailExpanded,
                insightsExpanded: !currentDetailExpanded.insightsExpanded,
            }
        case SoftwareDetailType.Actions:
            return {
                ...currentDetailExpanded,
                actionsExpanded: !currentDetailExpanded.actionsExpanded,
            }
        default:
            return currentDetailExpanded
    }
}

const getCurrentDetailExpanded = (
    toggledDetail: SoftwareDetailType,
    currentDetailExpanded: DetailExpandedType,
): boolean => {
    switch (toggledDetail) {
        case SoftwareDetailType.Details:
            return currentDetailExpanded.detailsExpanded
        case SoftwareDetailType.Insights:
            return currentDetailExpanded.insightsExpanded
        case SoftwareDetailType.Actions:
            return currentDetailExpanded.actionsExpanded
        default:
            return false
    }
}
