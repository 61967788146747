import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

interface LabelProps {
    gridLayout: GridLayout
}

function getMarginRight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '10px'
        default:
            return '0'
    }
}

function getMarginTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.ONE_COLUMN_LARGE:
            return '10px'
        default:
            return '0'
    }
}

export const Label = styled.label<LabelProps>`
    padding: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: ${(props) => getMarginRight(props.gridLayout)};
    margin-top: ${(props) => getMarginTop(props.gridLayout)};
    line-height: 17px;
    font-size: 12px;
    letter-spacing: 0.24px;
`
