import {formatDate} from '../../../../../helpers/formatting'
import {
    formatTechnique,
    getTacticFromCode,
    getTechniqueFromCode,
} from '../../../../../helpers/indicatorHelper'
import {IndicatorData} from '../../../../../values/IndicatorData'
import {Indicator} from '../../../contexts/indicators/types/indicator'

export class IndicatorOutputModel {
    public readonly timeStamp: string
    public readonly formattedTimeStamp: string
    public readonly title: string
    public readonly tatic: string | null | undefined
    public readonly technique: string | null
    public readonly id: string
    public constructor(
        event: Indicator,
        indicatorData: IndicatorData | undefined,
        gfcFlag: boolean,
    ) {
        this.timeStamp = event.timestamp
        this.formattedTimeStamp = formatDate(event.timestamp)
        this.title = indicatorData?.title ?? 'Unknown'
        this.tatic =
            event.type === 'INDICATOR'
                ? gfcFlag
                    ? null
                    : getTacticFromCode(indicatorData?.mitreStep, gfcFlag)
                : null
        this.technique =
            event.type === 'INDICATOR'
                ? gfcFlag
                    ? null
                    : formatTechnique(
                          indicatorData,
                          getTechniqueFromCode(indicatorData?.mitreTtps, gfcFlag),
                      )
                : null
        this.id = event.guid
    }
}
