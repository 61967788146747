import NetworkAnomalyMatrixRecordMap from '../../../values/anomalies/network-anomaly-matrix-record-map'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'

export const isloadingSelector = (state: AppState): boolean =>
    state.networkAnomalyMatrix.loading === LoadingState.NotPopulated ||
    state.networkAnomalyMatrix.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState =>
    state.networkAnomalyMatrix.loading

export const recordsSelector = (state: AppState): NetworkAnomalyMatrixRecordMap | null =>
    state.networkAnomalyMatrix.records
