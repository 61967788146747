import * as Styled from './_styled/table-header.styled'

export function TableHeader(): JSX.Element {
    return (
        <Styled.TableHeaderGrid>
            <Styled.TableHeaderCell gridColumn={2}>Location</Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={3}>Mode</Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
