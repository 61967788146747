export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED SOFTWARE INVENTORY CONTEXT] SET SELECTED PAGE',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED SOFTWARE INVENTORY CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED SOFTWARE INVENTORY CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED SOFTWARE INVENTORY CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    DISPLAY_FILTER_BAR = '[PAGED SOFTWARE INVENTORY CONTEXT] DISPLAY FILTER BAR',
    DISPLAY_SOFTWARE_DETAILS_MODAL = '[PAGED SOFTWARE INVENTORY CONTEXT] DISPLAY SOFTWARE DETAILS MODAL',
    SWITCH_TO_CACHED_DATA_MODAL = '[PAGED SOFTWARE INVENTORY CONTEXT] SWITCH TO CACHED DATA MODAL',
    CLOSE_SOFTWARE_DETAILS_MODAL = '[PAGED SOFTWARE INVENTORY CONTEXT] CLOSE SOFTWARE DETAILS MODAL',
    RECEIVED_REQUESTED_SOFTWARE_DETAILS = '[PAGED SOFTWARE INVENTORY CONTEXT] RECEIVED REQUESTED SOFTWARE DETAILS',
    DISPLAY_CHANGE_SOFTWARE_STATUS_MODAL = '[PAGED SOFTWARE INVENTORY CONTEXT] CHANGE SOFTWARE STATUS MODAL',
    SET_SOFTWARE_STATUS = '[PAGED SOFTWARE INVENTORY CONTEXT] SET SOFTWARE STATUS',
    SET_SOFTWARE_MODAL_OPTIONS = '[PAGED SOFTWARE INVENTORY CONTEXT] SET MODAL OPTIONS',
    CLOSE_CHANGE_SOFTWARE_STATUS_MODAL = '[PAGED SOFTWARE INVENTORY CONTEXT] CLOSE SOFTWARE STATUS MODAL',
    SET_FILTER = '[PAGED SOFTWARE INVENTORY CONTEXT] SET FILTER',
    REPORT_CREATE = '[PAGED SOFTWARE INVENTORY CONTEXT] REPORT CREATE',
    SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[PAGED SOFTWARE INVENTORY CONTEXT] show report popup',
}
