import {useEffect, useState} from 'react'
import {useSpring} from 'react-spring'
import {removeMultipleQueryParam} from '../../helpers/navigation'
import {queryStringObjectSelector} from '../../store/routerSelectors'
import {ModalDialog} from '../shared-components/modal-dialog.styled'
import Overlay from '../shared-components/overlay'
import {Title} from './components/title.styled'
import {LoadingSpinner} from '../../components/loading/loading'
import {EventDetailsLoading} from './components/event-details-loading.styled'
import {EventDetailsData} from './components/event-details-data'
import {useEventDetails} from './contexts/use-event-details'
import LoadingState from '../../values/loading-state-enum'
import {GuidType} from '../../values/generic-type-defintions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {nodesSelector} from '../../store/state/nodes/selectors'
import {NodeDataMap} from '../../values/nodes/NodeData'
import {indicatorsLoadingStateSelector} from '../../store/state/indicators/selectors'
import {fetchIndicators} from '../../store/state/indicators/action-creators'
import {useDispatch} from 'react-redux'

function getNodeAlias(nodeId: GuidType | undefined, nodes: NodeDataMap): string {
    if (nodeId == undefined) {
        return 'Unknown'
    }

    return nodes?.get(nodeId)?.alias || 'Unknown'
}

export function EventDetailsModal(): JSX.Element {
    const [closing, setClosing] = useState(false)
    const dispatch = useDispatch()

    const queryString = useTypedSelector(queryStringObjectSelector)
    const assetId = queryString ? (queryString.assetDetails as GuidType) : undefined
    const eventId = queryString ? (queryString.eventDetails as GuidType) : undefined
    const eventTimestamp = queryString ? (queryString.eventTimestamp as string) : undefined
    const nodes = useTypedSelector(nodesSelector)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const {loading, eventDetail, getEventDetail} = useEventDetails(eventTimestamp!, eventId!)
    const indicatorLoadingState = useTypedSelector(indicatorsLoadingStateSelector)

    useEffect(() => {
        if (loading === LoadingState.NotPopulated) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getEventDetail(eventTimestamp!, eventId!)
        }
    }, [loading, eventId, eventTimestamp, getEventDetail])

    useEffect(() => {
        if (indicatorLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchIndicators())
        }
    }, [dispatch, indicatorLoadingState])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const opacityAnimation: any = useSpring({
        opacity: closing ? 0 : 1,
        from: {opacity: closing ? 1 : 0.7},
        onRest: () => {
            if (closing) {
                removeMultipleQueryParam(['eventDetails', 'eventTimestamp'])
            }
        },
    })

    const isLoading =
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData ||
        indicatorLoadingState === LoadingState.NotPopulated ||
        indicatorLoadingState === LoadingState.RequestingData

    return (
        <Overlay
            closing={closing}
            onClick={() => {
                setClosing(true)
            }}
            z={950}
        >
            <ModalDialog offset={60} style={opacityAnimation} onClick={(e) => e.stopPropagation()}>
                <Title>Observation Details for {getNodeAlias(assetId, nodes)}</Title>
                {isLoading && (
                    <EventDetailsLoading>
                        <LoadingSpinner size={150} />
                    </EventDetailsLoading>
                )}
                {!isLoading && <EventDetailsData eventDetail={eventDetail} />}
            </ModalDialog>
        </Overlay>
    )
}
