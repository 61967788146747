import styled from 'styled-components'
import {getColor} from '../../../../../metrics-beta/components/metrics-summaries/score-bar.styled'
import {smallFont} from '../../../../../../theme/font-styles'

export const TrendsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`
export const TrendsScoreWrapper = styled.div`
    display: flex;
    column-gap: 25px;
    flex-direction: column;
    align-items: center;
    width: 130px;
    position: relative;
`
export const ScoreSection = styled.div`
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
`
interface TrendScoreProps {
    metricType: string
}
export const TrendScore = styled.div<TrendScoreProps>`
    position: relative;
    font-size: 30px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => getColor(props.metricType)};
`
export const PercentagWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: -18px;
    font-size: 20px;
`
export const ImgComparison = styled.img<TrendScoreProps>`
    width: 30px;
    height: 17px;

    filter: ${(props) =>
        props.metricType === 'protection'
            ? 'invert(18%) sepia(99%) saturate(2889%) hue-rotate(228deg) brightness(86%) contrast(101%)'
            : props.metricType === 'maintenance'
              ? 'invert(10%) sepia(97%) saturate(6207%) hue-rotate(270deg) brightness(92%) contrast(96%)'
              : props.metricType === 'behaviour'
                ? 'invert(56%) sepia(78%) saturate(853%) hue-rotate(156deg) brightness(90%) contrast(91%)'
                : ''};
`
export const TippyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    top: 5px;
    left: 20px;
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    z-index: 100;
`
