import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {DataCell} from '../shared/data-cell'
import {VesselDetails} from './vessel-details'

export function ActivityContents(): JSX.Element {
    const {usbDetailsOutput} = usePagedUSBDevices()

    return (
        <>
            {usbDetailsOutput.listOfVesselsForGivenAssets?.length > 0 && (
                <DataCell label="Associated assets:">
                    {usbDetailsOutput.listOfVesselsForGivenAssets.map((locationId) => (
                        <VesselDetails
                            key={`locationId_${locationId}`}
                            locationId={locationId}
                            listOfAssociatedAssets={usbDetailsOutput.assets}
                        />
                    ))}
                </DataCell>
            )}
        </>
    )
}
