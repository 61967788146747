import {Check, X} from 'react-feather'
import {DataCell} from './data-cell'
import {IconWrapper, Input, InputWrapper, WarningMessage} from './data-cell.styled'

interface DataInputProps {
    label: string
    gridArea?: string
    initialValue: string
    type?: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    changed: boolean
    blocked?: boolean
}

export function DataInput({
    label,
    gridArea,
    initialValue,
    type,
    onChangeHandler,
    changed,
    blocked,
}: DataInputProps): JSX.Element {
    return (
        <DataCell label={label} gridArea={gridArea}>
            <div>
                <InputWrapper id={`enable-monitoring-InputWrapper_${label}`}>
                    <Input
                        value={initialValue}
                        type={type}
                        onChange={onChangeHandler}
                        id={`enable-monitoring-Input_${initialValue}`}
                    />
                    <IconWrapper id={`enable-monitoring-IconWrapper_${label}`}>
                        {changed && <Check color={'green'} width={19} height={19} />}
                        {blocked && <X color={'#f34663'} width={19} height={19} />}
                    </IconWrapper>
                </InputWrapper>
                {blocked && (
                    <WarningMessage id={`enable-monitoring-WarningMessage_${label}`}>
                        The hostname is required.
                    </WarningMessage>
                )}
            </div>
        </DataCell>
    )
}
