import {GridLayout} from '../../general/grid-layout.enum'
import {Label} from '../../general/content-area/headline-values/label.styled'
import {OverviewValue} from '../../general/content-area/headline-values/overview-value.styled'
import {Value} from '../../general/content-area/headline-values/value.styled'
import UP_TREND from '../../../../../@assets/icons/feather-trending-up.svg'
import DOWN_TREND from '../../../../../@assets/icons/feather-trending-down.svg'
import {ImgValueWrapper} from './img-value-wrapper.styled'
import {Image} from './image.styled'
import {TrendDirection} from '../widget.model'

interface ValueForTypeProps {
    direction: TrendDirection
    type: string
    value: number
    gridLayout: GridLayout
}

export function IconAndValueForType({
    direction,
    type,
    value,
    gridLayout,
}: ValueForTypeProps): JSX.Element {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return (
                <OverviewValue gridLayout={gridLayout}>
                    <Label
                        gridLayout={gridLayout}
                        id={`my-fleet-widget-headline-values-label-${type}`}
                    >
                        {type}
                    </Label>
                    {direction !== TrendDirection.Same && (
                        <ImgValueWrapper gridLayout={gridLayout}>
                            <Image
                                gridLayout={gridLayout}
                                src={direction === TrendDirection.Up ? UP_TREND : DOWN_TREND}
                                width={17}
                                height={10}
                                id={`my-fleet-widget-headline-values-img-${direction}`}
                            />
                            <Value
                                gridLayout={gridLayout}
                                id={`my-fleet-widget-headline-values-${type}-${value}`}
                            >
                                {value}
                            </Value>
                        </ImgValueWrapper>
                    )}
                    {direction === TrendDirection.Same && (
                        <Value
                            gridLayout={gridLayout}
                            id={`my-fleet-widget-headline-values-${type}-${value}`}
                        >
                            {value}
                        </Value>
                    )}
                </OverviewValue>
            )
        default:
            return (
                <OverviewValue gridLayout={gridLayout}>
                    {direction !== TrendDirection.Same && (
                        <ImgValueWrapper gridLayout={gridLayout}>
                            <Image
                                gridLayout={gridLayout}
                                src={direction === TrendDirection.Up ? UP_TREND : DOWN_TREND}
                                width={17}
                                height={10}
                                id={`my-fleet-widget-headline-values-img-${direction}`}
                            />
                            <Value
                                gridLayout={gridLayout}
                                id={`my-fleet-widget-headline-values-${type}-${value}`}
                            >
                                {value}
                            </Value>
                        </ImgValueWrapper>
                    )}
                    {direction === TrendDirection.Same && (
                        <Value
                            gridLayout={gridLayout}
                            id={`my-fleet-widget-headline-values-${type}-${value}`}
                        >
                            {value}
                        </Value>
                    )}
                    <Label
                        gridLayout={gridLayout}
                        id={`my-fleet-widget-headline-values-label-${type}`}
                    >
                        {type}
                    </Label>
                </OverviewValue>
            )
    }
}
