import * as Styled from './_styled/vessel-management-table-data.styled'
import AlertWhite from '../../../../@assets/icons/alert-high-white.svg'
import AlertBlue from '../../../../@assets/icons/alert-high-blue.svg'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {vesselDetailsCachedReselector} from '../../reselectors/vessel-details.cached-reselector'
import {useState} from 'react'
import {WarningMessageTable} from '../shared/warning-message-table'
import {useVesselManagement} from '../../contexts/use-vessel-management'
import {useDispatch} from 'react-redux'
import {logVesselSettings} from '../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../store/state/audit-log/state'
import {usePolicyManagement} from '../../../policy-management/contexts/policy-management/use-policy-management'
import {Location} from '../../../../store/state/locations/state'
import {getDeploymentStatus, getMergedLocation} from '../shared/vessel-status'
import {getDateAwareLicense, LicenseSummeryFleetConfigData} from '../lincense-utils'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import Tippy from '@tippyjs/react'
import {useEnforcementPolicy} from '../../../policy-management/contexts/enforcement-policy/use-enforcement-policy'
import {getLicenseSummeryInfo} from '../shared/license/licnese'

interface TableRowProps {
    location: Location
    index: number
}
export function TableRow({index, location}: TableRowProps): JSX.Element {
    const dispatch = useDispatch()
    const gridRow = index + 1
    const [isHovering, setIsHovering] = useState(false)
    const {
        showVesselDetailModal,
        activeVesselId,
        locationsInIsolationMode,
        vesselDeploymentStatusInfo,
        policyLicenseSummery,
    } = useVesselManagement()
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const {allPolicyData} = usePolicyManagement()
    const {allPolicies} = useEnforcementPolicy()
    const locationId = location.location
    const model = useTypedSelector((state) =>
        vesselDetailsCachedReselector(state, locationId, locationsInIsolationMode, allPolicyData),
    )
    const selectedVessel = activeVesselId === locationId

    let licenseSummeryDateAware: LicenseSummeryFleetConfigData | undefined

    if (policyLicenseSummery && fleetConfig.LicenseState) {
        licenseSummeryDateAware = getDateAwareLicense(
            policyLicenseSummery,
            fleetConfig.LicenseState,
        )
    }

    // if there is no config, then either the fleet config is still loading or the policy summery endpoint is
    if (!licenseSummeryDateAware) {
        return <DataLoading />
    }

    function onClickWrapper(e?: React.MouseEvent<HTMLDivElement>) {
        e?.stopPropagation()
        showVesselDetailModal(locationId)
        dispatch(
            logVesselSettings(
                AuditLogPageType.VESSEL_MANAGEMENT,
                AuditLogAuditType.VESSEL_SETTINGS_ACCESS,
                model.id,
            ),
        )
    }
    const deploymentStatus = getDeploymentStatus(
        getMergedLocation(vesselDeploymentStatusInfo, locationId),
    )

    const [incidentIcon, incidentToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Incidents'],
        model.incidentPolicy,
        allPolicies,
    )
    const [enforcementNetworkIcon, enforcementNetworkToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Enforcement:Networks'],
        model.enforcementPolicy,
        allPolicies,
    )
    const [enforcementRemoveablesIcon, enforcementRemoveablesToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Enforcement:Removables'],
        model.enforcementPolicy,
        allPolicies,
    )
    const [onboardEngagementIcon, onboardEngagementToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Metrics:OnboardEngagement'],
        model.metricsPolicy,
        allPolicies,
    )

    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-vessel-management-row_${locationId}`}
                selectedVessel={selectedVessel}
                onClick={onClickWrapper}
            />
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`vessel-management-type${locationId}`}
                onClick={onClickWrapper}
            >
                {model.name}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`vessel-deployment-type${locationId}`}
                onClick={onClickWrapper}
            >
                {deploymentStatus}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`vessel-management-name${locationId}`}
                onClick={onClickWrapper}
            >
                {model.vesselTags?.map((tag) => tag.name).join(', ')}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`vessel-management-updated_${locationId}`}
                onClick={onClickWrapper}
            >
                {model.metadata?.networkConfiguration?.BusinessNetworks && (
                    <Styled.Settings style={{marginBottom: '3px'}}>
                        Business:{' '}
                        {model.metadata?.networkConfiguration?.BusinessNetworks.toString()}
                    </Styled.Settings>
                )}
                {model.metadata?.networkConfiguration?.OTNetworks && (
                    <Styled.Settings style={{marginBottom: '3px'}}>
                        OT: {model.metadata?.networkConfiguration?.OTNetworks.toString()}
                    </Styled.Settings>
                )}
                {model.metadata?.networkConfiguration?.CrewNetworks && (
                    <Styled.Settings>
                        Crew: {model.metadata?.networkConfiguration?.CrewNetworks.toString()}
                    </Styled.Settings>
                )}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`vessel-management-email_${locationId}`}
                onClick={onClickWrapper}
            >
                {model.dashboardPC !== undefined && (
                    <Styled.Settings style={{marginBottom: '3px'}}>
                        Dashboard PC: {model.dashboardPC}
                    </Styled.Settings>
                )}
                {model.vesselEmail && <Styled.Settings>Email: {model.vesselEmail}</Styled.Settings>}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                id={`vessel-management-incident_${locationId}`}
                onClick={onClickWrapper}
                style={{position: 'relative', overflow: 'visible'}}
            >
                {incidentToolTip ? (
                    <>
                        <Tippy
                            content={incidentToolTip}
                            key={`vessel-management-onboardEngagement_tooltip_${locationId}`}
                        >
                            <img src={incidentIcon} />
                        </Tippy>
                        Default
                        {!model.vesselDashboard && (
                            <img
                                src={isHovering ? AlertBlue : AlertWhite}
                                width={16}
                                onMouseOver={() => setIsHovering(true)}
                                onMouseOut={() => setIsHovering(false)}
                                style={{marginLeft: '5px'}}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <img src={incidentIcon} />
                        Default
                        {!model.vesselDashboard && (
                            <img
                                src={isHovering ? AlertBlue : AlertWhite}
                                width={16}
                                onMouseOver={() => setIsHovering(true)}
                                onMouseOut={() => setIsHovering(false)}
                                style={{marginLeft: '5px'}}
                            />
                        )}
                    </>
                )}

                {isHovering && <WarningMessageTable />}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                id={`vessel-management-metrics_pol_${locationId}`}
                onClick={onClickWrapper}
                style={{position: 'relative', overflow: 'visible'}}
            >
                <Styled.Settings>
                    {onboardEngagementToolTip ? (
                        <>
                            <Tippy
                                content={onboardEngagementToolTip}
                                key={`vessel-management-onboardEngagement_tooltip_${locationId}`}
                            >
                                <img src={onboardEngagementIcon} />
                            </Tippy>
                            {model.metricsPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={onboardEngagementIcon} />
                            {model.metricsPolicy.name}
                        </>
                    )}
                </Styled.Settings>
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={9}
                id={`vessel-management-enforcement_${locationId}`}
                onClick={onClickWrapper}
                style={{position: 'relative', overflow: 'visible'}}
            >
                <Styled.Settings>
                    {enforcementNetworkToolTip ? (
                        <>
                            <Tippy
                                content={enforcementNetworkToolTip}
                                key={`vessel-management-enforcement_tooltip_${locationId}`}
                            >
                                <img src={enforcementNetworkIcon} />
                            </Tippy>
                            {model.enforcementPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={enforcementNetworkIcon} />
                            {model.enforcementPolicy.name}
                        </>
                    )}
                </Styled.Settings>
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={10}
                id={`vessel-management-removable_${locationId}`}
                onClick={onClickWrapper}
                style={{position: 'relative', overflow: 'visible'}}
            >
                <Styled.Settings>
                    {enforcementRemoveablesToolTip ? (
                        <>
                            <Tippy
                                content={enforcementRemoveablesToolTip}
                                key={`vessel-management-removable_tooltip_${locationId}`}
                            >
                                <img src={enforcementRemoveablesIcon} />
                            </Tippy>
                            {model.enforcementPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={enforcementRemoveablesIcon} />
                            {model.enforcementPolicy.name}
                        </>
                    )}
                </Styled.Settings>
            </Styled.TableDataCell>
        </>
    )
}
