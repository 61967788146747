import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {fetchIncidentsFilter} from '../../../../../store/state/incidents-filter/action-creators'
import {fetchLocations} from '../../../../../store/state/locations/action-creators'
import {fetchNodes} from '../../../../../store/state/nodes/action-creators'
import {fetchUsers} from '../../../../../store/state/users/action-creators'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {INCIDENT_DETAILS} from '../../../data-helpers'
import {ButtonText} from './button-text.styled'
import {Button} from './button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {savedIncidentsFiltersSelector} from '../../../../../store/state/saved-filters/selectors'

export function RefreshButton(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const {refreshData, closeIncidentDetailsModal} = usePagedIncidents()
    const savedFilters = useTypedSelector(savedIncidentsFiltersSelector)
    const text = width >= 600 ? 'Refresh incidents' : width >= 350 ? 'Refresh' : ''

    return (
        <Button
            id="refresh-button"
            onClick={() => {
                refreshData()
                removeQueryParam(INCIDENT_DETAILS)
                closeIncidentDetailsModal()
                dispatch(fetchIncidentsFilter(savedFilters))
                dispatch(fetchUsers())
                dispatch(fetchLocations())
                dispatch(fetchNodes())
            }}
        >
            <Icon glyph="Reset" height={16} width={16} />
            <ButtonText id="refresh-button-text">{text}</ButtonText>
        </Button>
    )
}
