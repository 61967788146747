import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface ZoomLevelProps {
    inFilterBar?: boolean
    twoLine?: boolean
}

export const Options = styled.ul`
    list-style-type: none;
    white-space: nowrap;
    padding: 0;
`
export const ZoomLevel = styled.div<ZoomLevelProps>`
    display: flex;
    flex-direction: ${(props) => (props.twoLine ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${(props) => (props.twoLine ? 'flex-start' : 'center')};
    ${(props) => (props.inFilterBar ? 'width: 100%;' : '')}

    & ${Options} {
        margin: ${(props) => spacing(props.twoLine ? 1 : 0)} 0 0
            ${(props) => spacing(props.twoLine ? 0 : 2)};
    }
`

export const Label = styled.label`
    flex-grow: 1;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const BaseOption = styled.li`
    display: inline-block;
    margin: 0;
    padding: 0;
`

export const LeftMostOption = styled(BaseOption)`
    border-bottom: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-top: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-left: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
`

export const MiddleOption = styled(BaseOption)`
    border-bottom: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-top: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
`

export const RightMostOption = styled(BaseOption)`
    border-bottom: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-top: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-right: 1px solid ${(props) => props.theme.myVessels.zoomLevel.border};
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
`
