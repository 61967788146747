import {SoftwareInventorySortType} from '../../../contexts/types/software-inventory-filter'
import {SortableColumn} from './sortable-column'
import {TemporaryColumn} from './temporary-column'

export function TableHeader(): JSX.Element {
    return (
        <>
            <TemporaryColumn gridColumn={2} text="Status" />
            <SortableColumn
                gridColumn={3}
                text="First Detected"
                colName={SoftwareInventorySortType.TIMESTAMP}
            />
            <SortableColumn
                gridColumn={4}
                text="Last Active"
                colName={SoftwareInventorySortType.LATEST_TIMESTAMP}
            />
            <SortableColumn
                gridColumn={5}
                text="Company"
                colName={SoftwareInventorySortType.COMPANY_NAME}
            />
            <SortableColumn
                gridColumn={6}
                text="Product"
                colName={SoftwareInventorySortType.PRODUCT_NAME}
            />
            <TemporaryColumn gridColumn={7} text="Number of Hosts" centered />
            <TemporaryColumn gridColumn={8} text="Number of Vessels" centered />
            <TemporaryColumn gridColumn={9} text="Newest Active Version" centered />
            <TemporaryColumn gridColumn={10} text="Oldest Active Version" centered />
        </>
    )
}
