// "uploading" and "upload error" are client side statuses,
// It exist only to be used to display different messages.

import {OTAsset} from '../../../../store/state/ot-assets/state'
import {GuidType} from '../../../../values/generic-type-defintions'

// in the UI, it never goes to the server or will come from the server.
export enum AssetBulkFileStatus {
    UPLOADING = 'uploading',
    UPLOAD_ERROR = 'upload error',
    UPLOADED = 'uploaded',
    CANCELLED = 'cancelled',
    PROCESSING = 'processing',
    REJECTED = 'rejected',
    COMPLETED = 'completed',
}
// Server response
export interface AssetBulkFile {
    id: string
    locationID: string
    status: AssetBulkFileStatus
    name: string
    checksum: string
    createdAt: string
    updatedAt: string
}

export type OTSystemMap = Record<string, OTAssessments>

export interface OTAssessmentReq {
    q1: boolean
    q2: boolean
    q3: boolean
    q4: boolean
    q5: boolean
    q6: boolean
    q7: boolean
    q8: boolean
    q9: boolean
    q10: boolean
}

export interface OTAssessmentRes {
    id: GuidType
    locationID: GuidType
    systemID: GuidType
    questions: OTAssessmentReq
    vulnerabilityScore: number
    createdAt: string
    updatedAt: string
    inE26Scope: boolean
    criticalityLevel: string
}

export interface OTAssessments {
    assessments: OTAssessmentRes[]
    hardwareAssets: OTAsset[]
}
