import {AssetBulkFileStatus} from '../contexts/types/ot-types'
import {IconCancelled} from './file-upload/icon-cancelled'
import {IconCompleted} from './file-upload/icon-completed'
import {IconProcessing} from './file-upload/icon-processing'
import {IconRejected} from './file-upload/icon-rejected'
import {IconUploaded} from './file-upload/icon-uploaded'
import {IconUploading} from './file-upload/icon-uploading'

export function FileStatusIcon(props: {status: AssetBulkFileStatus | undefined}) {
    switch (props.status) {
        case AssetBulkFileStatus.UPLOADING:
            return <IconUploading />
        case AssetBulkFileStatus.UPLOADED:
            return <IconUploaded />
        case AssetBulkFileStatus.PROCESSING:
            return <IconProcessing />
        case AssetBulkFileStatus.COMPLETED:
            return <IconCompleted />
        // Rejected and upload error share the same icon
        case AssetBulkFileStatus.UPLOAD_ERROR:
        case AssetBulkFileStatus.REJECTED:
            return <IconRejected />

        case AssetBulkFileStatus.CANCELLED:
            return <IconCancelled />
        default:
            return null
    }
}
