import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../../values/nodes/NodeData'
import {RiskScoreIndicator} from './risk-score-indicator.styled'
import {Square} from './square.styled'

interface AssetScoreProps {
    score: number
    value: string
    expanded: boolean
    id: string
}

export function AssetThreatScore({score, value, expanded, id}: AssetScoreProps): JSX.Element {
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    return (
        <Square value={value} expanded={expanded}>
            {threatMeasure && score >= threatMeasure.lowerThreshold && (
                <RiskScoreIndicator score={score} id={id} threatMeasure={threatMeasure} />
            )}
            {score}
        </Square>
    )
}
