import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE, VesselManagementFilterReduxState} from './state'

export const isVesselManagementFilterLoadingStateSelector = (state: AppState): boolean =>
    state.vesselManagementFilter.loadingState !== LoadingState.Loaded

export const vesselManagementFilterSelector = (state: AppState): VesselManagementFilterReduxState =>
    state.vesselManagementFilter

export const hasDeploymentIssueSelector = (state: AppState): boolean | undefined =>
    state.vesselManagementFilter.hasDeploymentIssues

export const isActiveFilterSelector = (state: AppState): boolean =>
    state.vesselManagementFilter.hasDeploymentIssues !==
    DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE.hasDeploymentIssues
