import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const BREAK_POINT_REFRESH_SWITCH = 500
export const BREAK_POINT_CARD_VIEW = 1200
export const SHOW_DETAILS_BREAK_POINT_CARD_VIEW = 1350

interface UserManagementPageStyleProps {
    width: number
}

export const Header = styled.div<UserManagementPageStyleProps>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'row' : 'column')};
`

export const PageTitle = styled.div<UserManagementPageStyleProps>`
    font-size: 20px;
    line-height: 27px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const UpdatedTimeArea = styled.div<UserManagementPageStyleProps>`
    display: flex;
    padding-top: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 0 : spacing(2))};
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'column' : 'row')};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`

export const HeaderAreaWrapper = styled.div<UserManagementPageStyleProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'row' : 'column-reverse')};
    align-items: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'center' : 'flex-start')};
    justify-content: center;
    column-gap: ${spacing(4)};
    row-gap: ${spacing(1)};
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: ${spacing(1)};
`
