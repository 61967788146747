import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {toggleFilterExpanded} from '../../../../../../store/state/network-assets-filter/action-creators'
import {networkAssetsFilterSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import * as Styles from './expand-details-button.styled'
import {
    HeadingRowType,
    FilterExpandedType,
} from '../../../../../../store/state/network-assets-filter/state'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

interface ExpandFilterDetailsButtonProps {
    headingRow: HeadingRowType
}

export function ExpandDetailsButton({headingRow}: ExpandFilterDetailsButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded} = useTypedSelector(networkAssetsFilterSelector)
    const isExpanded = getCurrentFilterExpanded(headingRow, filterExpanded)
    function setNewFilterExpanded() {
        const newFilterExpanded: FilterExpandedType = getNewFilterExpanded(
            headingRow,
            filterExpanded,
        )
        dispatch(toggleFilterExpanded(newFilterExpanded))
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.FilterHeadingRow
                id={`${PageType.NETWORK_ASSETS}_filter-bar_expand-heading-row_${headingRow}`}
            >
                {headingRow}
            </Styles.FilterHeadingRow>
            <Styles.Button
                onClick={() => setNewFilterExpanded()}
                id={`${PageType.NETWORK_ASSETS}_filter-bar_expand-button`}
            >
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewFilterExpanded = (
    toggledFilter: HeadingRowType,
    currentFilterExpanded: FilterExpandedType,
): FilterExpandedType => {
    switch (toggledFilter) {
        case HeadingRowType.FilterBarAssetState:
            return {...currentFilterExpanded, assetExpanded: !currentFilterExpanded.assetExpanded}
        case HeadingRowType.FilterBarVessels:
            return {...currentFilterExpanded, vesselExpanded: !currentFilterExpanded.vesselExpanded}
        case HeadingRowType.FilterBarNetworks:
            return {
                ...currentFilterExpanded,
                networkExpanded: !currentFilterExpanded.networkExpanded,
            }
        case HeadingRowType.FilterBarBehaviours:
            return {
                ...currentFilterExpanded,
                behavioursExpanded: !currentFilterExpanded.behavioursExpanded,
            }
        case HeadingRowType.FilterBarProperties:
            return {
                ...currentFilterExpanded,
                propertiesExpanded: !currentFilterExpanded.propertiesExpanded,
            }
        default:
            return currentFilterExpanded
    }
}

const getCurrentFilterExpanded = (
    toggledFilter: HeadingRowType,
    currentFilterExpanded: FilterExpandedType,
): boolean => {
    switch (toggledFilter) {
        case HeadingRowType.FilterBarAssetState:
            return currentFilterExpanded.assetExpanded
        case HeadingRowType.FilterBarVessels:
            return currentFilterExpanded.vesselExpanded
        case HeadingRowType.FilterBarNetworks:
            return currentFilterExpanded.networkExpanded
        case HeadingRowType.FilterBarBehaviours:
            return currentFilterExpanded.behavioursExpanded
        case HeadingRowType.FilterBarProperties:
            return currentFilterExpanded.propertiesExpanded
        default:
            return false
    }
}
