import {DataLoading} from '../../../../components/data-loading/data-loading'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {UnknownAssetsCardsLoaded} from './unknown-assets-cards-loaded'

export function UnknownAssetsCardsContent(): JSX.Element {
    const {loadingDataState, totalNumberOfUnknownAssets} = usePagedUnknownAssetsBeta()

    const showLoading =
        totalNumberOfUnknownAssets == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData

    if (showLoading) {
        return <DataLoading />
    }
    return <UnknownAssetsCardsLoaded />
}
