import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getFontSize(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '16px'
        default:
            return '20px'
    }
}

function getLineHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '22px'
        default:
            return '27px'
    }
}

interface TextProps {
    gridLayout: GridLayout
}

export const Text = styled.span<TextProps>`
    color: ${(props) => props.theme.colors.newIndicator.text};
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
