import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface EditableAreaProps {
    disabled?: boolean
}

export const DetailsLayout = styled.div<EditableAreaProps>`
    padding: ${spacing(1)} ${spacing(3)};
    margin: 0;
    width: 100%;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`

export const Label = styled.label`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const PlaybooksWrapper = styled.div`
    display: flex;
    gap: ${spacing(1)};
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: none;
`
export const PlaybookRow = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    align-items: center;
`

export const DisplayName = styled.span`
    font-style: italic;
`
export const TitleText = styled.a`
    ${mediumSmallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    text-decoration: underline;
`
