import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const DetailsSection = styled.div`
    background-color: #ffffff;
    position: relative;
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(2)};
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`

export const DetailsContent = styled.div`
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
    margin-bottom: ${spacing(2)};
`

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    ${mediumSmallFont()};
`

export const ModalTitle = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin: 0;
`
