import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {
    showOneCol,
    showOneColNarrow,
    showTwoColNarrow,
    showTwoColWide,
} from '../shared/grid-layout-helper'
import {GridLayout} from '../shared/grid-layout.enum'

interface VesselMetadataStyleProps {
    gridLayout: GridLayout
}

function getGridTemplate(gridLayout: GridLayout): string {
    if (showTwoColNarrow(gridLayout) || showOneColNarrow(gridLayout)) {
        return "'VESSEL-TYPE' 'VESSEL-TYPE-VALUE' 'CUSTOMER-LOCATION-CODE' 'CUSTOMER-LOCATION-CODE-VALUE' 'BUSINESS-NETWORKS' 'BUSINESS-NETWORKS-VALUE' 'OT-NETWORKS' 'OT-NETWORKS-VALUE' 'CREW-NETWORKS' 'CREW-NETWORKS-VALUE' 'GATEWAY-IP' 'GATEWAY-IP-VALUE' 'GATEWAY-MAC' 'GATEWAY-MAC-VALUE' 'INCUS-IP' 'INCUS-IP-VALUE' 'INCUS-MAC' 'INCUS-MAC-VALUE' 'SENSOR-IP' 'SENSOR-IP-VALUE' 'SENSOR-MAC' 'SENSOR-MAC-VALUE'"
    }
    return "'VESSEL-TYPE VESSEL-TYPE-VALUE' 'CUSTOMER-LOCATION-CODE CUSTOMER-LOCATION-CODE-VALUE' 'BUSINESS-NETWORKS BUSINESS-NETWORKS-VALUE' 'OT-NETWORKS OT-NETWORKS-VALUE' 'CREW-NETWORKS CREW-NETWORKS-VALUE' 'GATEWAY-IP GATEWAY-IP-VALUE' 'GATEWAY-MAC GATEWAY-MAC-VALUE' 'INCUS-IP INCUS-IP-VALUE' 'INCUS-MAC INCUS-MAC-VALUE' 'SENSOR-IP SENSOR-IP-VALUE' 'SENSOR-MAC SENSOR-MAC-VALUE'"
}

export const VesselMetadataStyle = styled.div<VesselMetadataStyleProps>`
    display: grid;
    ${(props) => (showOneColNarrow(props.gridLayout) ? '' : 'justify-content: flex-start;')}
    grid-template-areas: ${(props) => getGridTemplate(props.gridLayout)};
    grid-row-gap: ${(props) => (showTwoColWide(props.gridLayout) ? spacing(3) : spacing(1))};
    grid-column-gap: ${(props) => (showOneCol(props.gridLayout) ? spacing(8) : spacing(5))};
    ${(props) => (showOneCol(props.gridLayout) ? `margin-bottom: ${spacing(8)};` : '')};
`
