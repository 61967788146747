import FLEET_COMPARISON_POSITIVE from '../../../../@assets/icons/fleet-comparision-positive.svg'
import FLEET_COMPARISON_NEGATIVE from '../../../../@assets/icons/fleet-comparision-negative.svg'
import BENCHMARK from '../../../../@assets/icons/benchmark-fleet-comparison.svg'
import * as Styled from './benchmark.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {MetricType} from '../../contexts/types/metrics-response'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {NoData} from '../no-data/no-data'
import LoadingState from '../../../../values/loading-state-enum'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'

interface BenchmarkFleetComparisonProps {
    metricType: MetricType
}

export function BenchmarkFleetComparison({metricType}: BenchmarkFleetComparisonProps): JSX.Element {
    const {width} = useDimensions()
    const {dataSummariesMetricsMap, showFilterBar, loadingFilteredDataState} = usePagedMetricsBeta()
    const benchmarkData = dataSummariesMetricsMap?.get(metricType)?.benchmark
    const selectedVesselsData = benchmarkData?.selectedVessels
    const wholeFleetData = benchmarkData?.wholeFleet
    const selectedVesselsTotalAssetsOk = selectedVesselsData?.totalAssetsOk ?? 0
    const selectedVesselsTotalAssetsWithIssue = selectedVesselsData?.totalAssetsWithIssue ?? 0
    const wholeFleetTotalAssetsOk = wholeFleetData?.totalAssetsOk ?? 0
    const wholeFleetTotalAssetsWithIssue = wholeFleetData?.totalAssetsWithIssue ?? 0
    const selectedVesselScore = calculateScore(
        selectedVesselsTotalAssetsOk,
        selectedVesselsTotalAssetsWithIssue,
    )
    const wholeFleetScore = calculateScore(wholeFleetTotalAssetsOk, wholeFleetTotalAssetsWithIssue)

    const noAssets =
        selectedVesselsTotalAssetsOk === 0 &&
        selectedVesselsTotalAssetsWithIssue === 0 &&
        wholeFleetTotalAssetsOk === 0 &&
        wholeFleetTotalAssetsWithIssue === 0

    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return <LoadingContent icon={BENCHMARK} title="Benchmark" />
    }

    if (noAssets) {
        return <NoDataContent icon={BENCHMARK} title="Benchmark" />
    }

    return (
        <Styled.Section
            showFilterBar={showFilterBar}
            width={width}
            id={`footer-benchmark-section-${metricType}`}
        >
            <ContentHeader icon={BENCHMARK} title="Benchmark" identifier={metricType} />
            <Styled.SubTitle>Comparison with whole fleet</Styled.SubTitle>
            <Styled.ScoreSection>
                <ScoreDisplay
                    score={selectedVesselScore}
                    showFilterBar={showFilterBar}
                    metricType={metricType}
                />
                <img
                    src={getFleetComparisionImg(selectedVesselScore, wholeFleetScore)}
                    style={{width: '30px', height: '60px', margin: '10px'}}
                    id={`footer-benchmark-Fleet-Comparision-Img-${metricType}`}
                />
                <ScoreDisplay
                    score={wholeFleetScore}
                    showFilterBar={showFilterBar}
                    metricType={metricType}
                />
            </Styled.ScoreSection>
            <Styled.SubTitleWrapper id={`footer-benchmark-subtitle-${metricType}`}>
                <Styled.SubTitle>Selected vessel(s) score</Styled.SubTitle>
                <Styled.SubTitle>Whole fleet score</Styled.SubTitle>
            </Styled.SubTitleWrapper>
        </Styled.Section>
    )
}

function calculateScore(totalAssetsOk: number, totalAssetsWithIssue: number): number {
    const totalAssets = totalAssetsOk + totalAssetsWithIssue
    return totalAssets === 0 ? 0 : Math.round((totalAssetsOk / totalAssets) * 100)
}

function getFleetComparisionImg(selectedVesselScore: number, wholeFleetScore: number): string {
    return selectedVesselScore >= wholeFleetScore
        ? FLEET_COMPARISON_POSITIVE
        : FLEET_COMPARISON_NEGATIVE
}

function LoadingContent({icon, title}: {icon: string; title: string}): JSX.Element {
    return (
        <div>
            <ContentHeader icon={icon} title={title} identifier="loading" />
            <DataLoading />
        </div>
    )
}

function NoDataContent({icon, title}: {icon: string; title: string}): JSX.Element {
    return (
        <div>
            <ContentHeader icon={icon} title={title} identifier="no-data" />
            <NoData text="No Assets are found" />
        </div>
    )
}

function ContentHeader({
    icon,
    title,
    identifier,
}: {
    icon: string
    title: string
    identifier: string
}): JSX.Element {
    return (
        <Styled.TitleWrapper id={`footer-benchmark-title-${identifier}`}>
            <img src={icon} alt="Icon" />
            {title}
        </Styled.TitleWrapper>
    )
}

function ScoreDisplay({
    score,
    showFilterBar,
    metricType,
}: {
    score: number
    showFilterBar: boolean
    metricType: string
}): JSX.Element {
    return (
        <Styled.Score showFilterBar={showFilterBar}>
            <div
                style={{display: 'flex', gap: '5px'}}
                id={`footer-benchmark-score-display-${metricType}-${score}`}
            >
                {score}
                <Styled.ScoreTextWrapper>
                    <div style={{fontSize: '24px'}}>%</div>
                    <Styled.Text showFilterBar={showFilterBar}>of assets are ok</Styled.Text>
                </Styled.ScoreTextWrapper>
            </div>
        </Styled.Score>
    )
}
