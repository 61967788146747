import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useState} from 'react'
import * as Styled from './behaviour-block.styled'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

interface Props {
    behaviourName: string
    onClickFn?: () => void
    onDeleteFn?: () => void
    showHover?: boolean
    maxWidth?: string
}

export function BehaviourBlock({
    behaviourName,
    onClickFn,
    onDeleteFn,
    showHover,
    maxWidth,
}: Props): JSX.Element {
    const [hoverOn, setHoverOn] = useState<boolean>(false)

    function mouseOverRow(): void {
        if (showHover || onDeleteFn) {
            setHoverOn(true)
        }
    }

    function mouseLeaveRow(): void {
        if (showHover || onDeleteFn) {
            setHoverOn(false)
        }
    }
    return (
        <Styled.TagResult
            onMouseEnter={() => mouseOverRow()}
            onMouseLeave={() => mouseLeaveRow()}
            cursorPointer={onClickFn != undefined}
            onClick={onClickFn}
            id={`${PageType.NETWORK_ASSETS}_filter-bar_behaviour-search_result-wrapper-${behaviourName}`}
        >
            <Styled.TagItem
                maxWidth={maxWidth}
                id={`${PageType.NETWORK_ASSETS}_filter-bar_behaviour-search_result-text-${behaviourName}`}
            >
                {behaviourName}
            </Styled.TagItem>
            {hoverOn && (
                <Styled.TagExpandedView>
                    <Styled.TagExpandedViewTagName
                        id={`${PageType.NETWORK_ASSETS}_filter-bar_behaviour-search_${behaviourName}`}
                    >
                        {behaviourName}
                    </Styled.TagExpandedViewTagName>
                    {onDeleteFn && (
                        <Styled.DeleteIcon
                            onClick={onDeleteFn}
                            id={`${PageType.NETWORK_ASSETS}_filter-bar_behaviour-search_delete-filter`}
                        >
                            Clear filter
                            <Icon glyph="Delete" width={12} height={12} />
                        </Styled.DeleteIcon>
                    )}
                </Styled.TagExpandedView>
            )}
        </Styled.TagResult>
    )
}
