import * as Styles from './new-note-filter.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentsFilterUnseenIncidentsSelector} from '../../../../../store/state/incidents-filter/selectors'
import {useDispatch} from 'react-redux'
import {setIncidentsWithNewNotes} from '../../../../../store/state/incidents-filter/action-creators'

export function NewNoteFilter(): JSX.Element {
    const unseenIncidentResponses = useTypedSelector(incidentsFilterUnseenIncidentsSelector)
    const dispatch = useDispatch()
    function onClickWrapper() {
        if (unseenIncidentResponses == null || unseenIncidentResponses === false) {
            dispatch(setIncidentsWithNewNotes(true))
        } else {
            dispatch(setIncidentsWithNewNotes(false))
        }
    }
    const action = unseenIncidentResponses ? 'Unseen' : 'All'

    return (
        <Styles.Wrapper id="show-incidents-filter">
            <Styles.Label>Show incidents:</Styles.Label>
            <Styles.Button id="watch-incidents" onClick={onClickWrapper}>
                {action === 'Unseen' ? (
                    <>
                        <Styles.NewSquare>NEW</Styles.NewSquare>
                        <Styles.Text id="show-new-incidents">With new items</Styles.Text>
                    </>
                ) : (
                    <Styles.Text id="show-all-incidents">All incidents</Styles.Text>
                )}
            </Styles.Button>
        </Styles.Wrapper>
    )
}
