import {GridLayout} from '../../general/grid-layout.enum'
import {Content} from '../../general/content-area/headline-values/content.styled'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {WidgetOutputModel} from '../widget.model'
import {IconAndValueForType} from './icon-and-value-for-type'

interface HeadlineValuesProps {
    gridLayout: GridLayout
    model: WidgetOutputModel
}
export function HeadlineValues({gridLayout, model}: HeadlineValuesProps): JSX.Element {
    return (
        <Content gridLayout={gridLayout} id="my-fleet-HeadlineValues">
            <IconAndValueForType
                direction={model.trend.hours24.direction}
                type="24h"
                value={model.trend.hours24.score}
                gridLayout={gridLayout}
            />
            <IconAndValueForType
                direction={model.trend.days7.direction}
                type="7d"
                value={model.trend.days7.score}
                gridLayout={gridLayout}
            />
            <ValueForType
                type="7d Average"
                value={model.trend.days7Average}
                gridLayout={gridLayout}
                id="my-fleet-7d-average"
            />
        </Content>
    )
}
