import {formatDateWithoutTime} from '../../../../helpers/formatting'
import {VesselTags} from '../../../../store/state/vessel-tags/state'
import {spacing} from '../../../../theme/spacing'
import {IsolationStatusType, NetworkAssetState} from '../../context/types/isolated-assets-type'

export const SHOW_FILTER_BAR_ON_BREAK_POINT = 1100

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier ? width < 1250 : width < 1500
}

export function getBetaPageGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(1)} 80px 180px 250px 150px 200px 200px 200px auto ${spacing(1)}`
    }
    if (width > 1500) {
        return `${spacing(1)} 70px 150px 240px 150px 190px 190px 190px auto ${spacing(1)}`
    }
    if (width > 1400) {
        return `${spacing(1)} 70px 140px 220px 140px 180px 180px 180px auto ${spacing(1)}`
    }
    if (width > 1300) {
        return `${spacing(1)} 60px 130px 200px 120px 170px 170px 170px auto ${spacing(1)}`
    }
    return `${spacing(1)} 50px 120px 180px 110px 160px 160px 160px auto ${spacing(1)}`
}

export function getBetaPageReducedGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(1)} 60px 130px 180px 120px 160px 160px 160px 120px ${spacing(1)}`
    }
    if (width > 1550) {
        return `${spacing(1)} 60px 120px 180px 100px 150px 150px 150px 120px ${spacing(1)}`
    }
    return `${spacing(1)} 60px 120px 180px 100px 130px 130px 130px 120px ${spacing(1)}`
}

export function getUpdatedDisplayValue(timestamp: string | undefined): string {
    return !timestamp ? 'N/A' : formatDateWithoutTime(timestamp)
}

export function showTrustToggleNoAc(blockedState: NetworkAssetState): boolean {
    switch (blockedState) {
        case NetworkAssetState.UNKNOWN:
            return true
        case NetworkAssetState.BLOCKED:
        case NetworkAssetState.TRUSTED:
        case NetworkAssetState.MONITORED:
            return false
        default:
            return false
    }
}

export function toggleOnNoAc(blockedState: NetworkAssetState): boolean {
    switch (blockedState) {
        case NetworkAssetState.TRUSTED:
            return true
        case NetworkAssetState.BLOCKED:
        case NetworkAssetState.UNKNOWN:
            return false
        default:
            return false
    }
}

export function showTrustToggle(isolationStatus: IsolationStatusType): boolean {
    switch (isolationStatus) {
        case IsolationStatusType.TRUSTED_MANUALLY:
        case IsolationStatusType.BLOCKED_AUTOMATICALLY:
        case IsolationStatusType.BLOCKED_MANUALLY:
        case IsolationStatusType.RE_BLOCKED_MANUALLY:
            return true
        case IsolationStatusType.ALLOWED_ACTIVE_CONTROL_OFF:
        case IsolationStatusType.ALLOWED_SWITCHED_TO_MANAGED_ASSET:
        case IsolationStatusType.ALLOWED_SWITCHED_TO_MONITORED_ASSET:
        case IsolationStatusType.UNDECIDED:
        case IsolationStatusType.ALLOWED_AUTOMATICALLY_DURING_LEARNING_PERIOD:
        case IsolationStatusType.ALLOWED_AUTOMATICALLY_DURING_ISOLATION_OFF:
            return false
        default:
            return false
    }
}

export function toggleOn(isolationStatus: IsolationStatusType): boolean {
    switch (isolationStatus) {
        case IsolationStatusType.TRUSTED_MANUALLY:
            return true
        case IsolationStatusType.BLOCKED_AUTOMATICALLY:
        case IsolationStatusType.BLOCKED_MANUALLY:
        case IsolationStatusType.RE_BLOCKED_MANUALLY:
            return false
        default:
            return false
    }
}

export function getGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(1)} 150px 250px 150px 200px 200px auto ${spacing(1)}`
    }
    if (width > 1450) {
        return `${spacing(1)} 140px 220px 130px 200px 190px auto ${spacing(1)}`
    }
    if (width > 1350) {
        return `${spacing(1)} 130px 200px 120px 190px 180px auto ${spacing(1)}`
    }
    return `${spacing(1)} 120px 190px 110px 180px 170px auto ${spacing(1)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(1)} 150px 230px 120px 220px 190px 190px ${spacing(1)}`
    }
    if (width > 1550) {
        return `${spacing(1)} 140px 210px 120px 200px 170px 170px ${spacing(1)}`
    }
    return `${spacing(1)} 130px 200px 110px 200px 170px 170px ${spacing(1)}`
}

export function showFilterBarOnScreen(width: number): boolean {
    return width > SHOW_FILTER_BAR_ON_BREAK_POINT
}

export const NETWORK_ASSET_DETAILS = 'networkAssetDetails'

export function findRecommendationFromEntryTag(
    existingTags: VesselTags[] | undefined,
    searchTag: string,
): VesselTags[] {
    if (!existingTags || !searchTag || !searchTag?.trim()) {
        return []
    }
    return existingTags
        ?.filter((element) =>
            element.name?.trim().toLowerCase().includes(searchTag?.trim().toLowerCase()),
        )
        .sort((a, b) => a.name?.localeCompare(b.name))
        .slice(0, 5)
}

export const allNetworkAssetStatus = [
    NetworkAssetState.TRUSTED,
    NetworkAssetState.MONITORED,
    NetworkAssetState.BLOCKED,
    NetworkAssetState.UNKNOWN,
]

export const allNetworkAssetStatusForAc = [
    NetworkAssetState.TRUSTED,
    NetworkAssetState.MONITORED,
    NetworkAssetState.UNKNOWN,
]
