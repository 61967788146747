import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import * as Styled from './items-list.styled'
import {ItemRow} from '../item-list/item-row'
import moment from 'moment'
import {SwitchToggle} from '../../../../../components/switch-toggle/switch-toggle'

function getMinutesDifference(givenTimestamp: string): boolean {
    return moment().diff(moment(givenTimestamp), 'minutes') < 10
}

export function ItemsListOnModal(): JSX.Element {
    const {linkedItemsData, monitorLinkedItems, setMonitoredItem} = usePagedIncidents()

    return (
        <Styled.Panel>
            {linkedItemsData.length === 0 ? (
                <Styled.DetailsLayout>
                    <Styled.NoItems>No Linked Items found</Styled.NoItems>
                </Styled.DetailsLayout>
            ) : (
                <Styled.DetailsLayout>
                    <Styled.DataRow>
                        <Styled.DataCell style={{fontWeight: 'bold'}}>
                            <Styled.DataCellKey>Linked inventory</Styled.DataCellKey>
                            <Styled.DataCellLastSeen>Last activity</Styled.DataCellLastSeen>
                            <Styled.DataCellToggle>
                                Reopen incident if new activity occurs
                            </Styled.DataCellToggle>
                        </Styled.DataCell>
                        {linkedItemsData.map((item, index) => (
                            <>
                                <Styled.DataCell id={`data-cell-${item.incidentLinkIdentity}`}>
                                    <Styled.DataCellKey>
                                        <ItemRow key={index} item={item} lastRow={false} />
                                    </Styled.DataCellKey>
                                    <Styled.DataCellLastSeen
                                        id={`data-lastSeen-${item.incidentLinkIdentity}`}
                                    >
                                        {moment(item.lastSeen).fromNow()}
                                    </Styled.DataCellLastSeen>
                                    <Styled.DataCellToggle
                                        disabled={
                                            item.monitored || getMinutesDifference(item.lastSeen)
                                        }
                                    >
                                        <SwitchToggle
                                            onValue={monitorLinkedItems.includes(
                                                item.incidentLinkIdentity,
                                            )}
                                            setToggleLeftValue={() => {
                                                setMonitoredItem(item.incidentLinkIdentity, false)
                                            }}
                                            setToggleRightValue={() => {
                                                setMonitoredItem(item.incidentLinkIdentity, true)
                                            }}
                                            align="flex-start"
                                            id={`linked-item-${item.incidentLinkIdentity}`}
                                        />
                                    </Styled.DataCellToggle>
                                </Styled.DataCell>
                            </>
                        ))}
                    </Styled.DataRow>
                </Styled.DetailsLayout>
            )}
        </Styled.Panel>
    )
}
