import styled from 'styled-components'
import {Colors} from '../../../../theme/colours'
import {spacing} from '../../../../theme/spacing'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SelectedTab = styled.div`
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    padding: 10px 20px;
    width: 100%;
    text-decoration: underline;
`

export const NotSelectedTab = styled.div`
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 10px 20px;
    color: ${(props) => props.theme.modalDialog.tabs.notSelected.text};
    background-color: ${Colors.grey1};
    cursor: pointer;
    width: 100%;
`

export const FlexRow = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
`

export const DownloadAssessmentBtn = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 6px;
    background-color: ${(props) => props.theme.myVessels.actionButton.background};
    color: ${(props) => props.theme.myVessels.actionButton.text};
    align-self: flex-start;
    padding: ${spacing(2)} ${spacing(4)};
`

export const Text = styled.span`
    margin-left: ${spacing(2)};
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.28px;
`
