import styled from 'styled-components'
import {ColorType} from '../../../theme/theme'

export type Props = {
    hoverColour?: ColorType
}

export const ContactLink = styled.a<Props>`
    display: inline-flex;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 2px solid #1f88e5;
    color: #1f88e5;

    &:hover {
        color: ${(props) =>
            props.hoverColour ? props.hoverColour : props.theme.colors.text.default};
    }
`
