import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {StyledButton} from '../../shared/button.styled'
import {showOneColNarrow, showTwoCol} from '../../shared/grid-layout-helper'
import {GridLayout} from '../../shared/grid-layout.enum'

interface CopyConfigAreaLayoutProps {
    gridLayout: GridLayout
}

export const CopyConfigAreaLayout = styled.div<CopyConfigAreaLayoutProps>`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: ${(props) => (showOneColNarrow(props.gridLayout) ? spacing(6) : spacing(8))};
    padding-bottom: ${spacing(6)};
`

interface CopyConfigButtonProps {
    gridLayout: GridLayout
}
export const CopyConfigButton = styled(StyledButton)<CopyConfigButtonProps>`
    width: 233px;
    ${(props) => (showTwoCol(props.gridLayout) ? `margin-right: ${spacing(4)};` : '')}
`
