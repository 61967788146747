import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './button.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useDispatch, batch} from 'react-redux'
import {fetchNodes} from '../../../../../store/state/nodes/action-creators'
import {fetchLocations} from '../../../../../store/state/locations/action-creators'
import {fetchThreatMeasures} from '../../../../../store/state/threat-measures/action-creators'
import {fetchMyVesselsFilter} from '../../../../../store/state/my-vessels-filters/action-creators'
import {fetchAllLocationTagsByLocation} from '../../../../../store/state/vessel-tags/action-creators'

export function RefreshButton(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        batch(() => {
            dispatch(fetchMyVesselsFilter())
            dispatch(fetchLocations())
            dispatch(fetchNodes())
            dispatch(fetchThreatMeasures())
            dispatch(fetchAllLocationTagsByLocation())
        })
    }

    return (
        <Styles.Button id="refresh-button" onClick={(e) => refreshFunction(e)}>
            <Icon glyph="Reset" height={16} width={16} />
            {width > 400 && <Styles.Text>{width > 550 ? 'Refresh Data' : 'Refresh'}</Styles.Text>}
        </Styles.Button>
    )
}
