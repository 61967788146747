import * as Styles from './asset-details.styled'
import {WatchListButton} from './watchlist-button/watchlist-button'
import {ActionButton} from './action-button/action-button'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {addMultipleQueryParam, addQueryParam} from '../../helpers/navigation'
import {GuidType} from '../../values/generic-type-defintions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {assetDetailsCachedReselector} from './re-reselectors/asset-details-cached-reselector'
import {normaliseScore} from '../../helpers/formatting'
import {useDispatch} from 'react-redux'
import {logAssetOverlayOpen} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../store/state/audit-log/state'
import {useLocation} from 'react-router-dom'
import EDIT_BUTTON from '../../@assets/icons/edit-pen.svg'

import isUserAllowedToEditAssetReselector from './re-reselectors/is-user-allowed-to-edit-asset-reselector'
interface Props {
    id: GuidType
}

export function AssetDetails({id}: Props): JSX.Element | null {
    const dispatch = useDispatch()

    const asset = useTypedSelector((state) => assetDetailsCachedReselector(state, id))
    const isAllowedToEdit = useTypedSelector(isUserAllowedToEditAssetReselector)

    const pathname = useLocation().pathname
    const currentPage = (pathname: string) => {
        switch (true) {
            case pathname.startsWith('/my-vessels'):
                return AuditLogPageType.MY_VESSELS
            case pathname.startsWith('/incidents'):
                return AuditLogPageType.INCIDENTS
            case pathname.startsWith('/dashboard'):
                return AuditLogPageType.DASHBOARD
            case pathname.startsWith('/vessels-beta'):
                return AuditLogPageType.VESSELS_BETA
            default:
                return AuditLogPageType.MY_VESSELS
        }
    }

    if (!asset) {
        return null
    }

    const onClickWrapper = (): void => {
        dispatch(
            logAssetOverlayOpen(currentPage(pathname), AuditLogAuditType.DETAIL_PANEL_ACCESS, id),
        )
        addMultipleQueryParam('assetDetails', asset.assetId, 'category', 'alerts')
    }
    return (
        <Styles.Container>
            <Styles.Header>
                <Styles.RiskScore
                    id={`asset-risk-score_${asset.assetId}`}
                    score={asset.threatScore}
                    value={asset.value}
                    threatLevel={asset.threatLevel}
                >
                    {normaliseScore(asset.threatScore)}
                </Styles.RiskScore>
                <Styles.Titles value={asset.value}>
                    <Styles.Names>
                        <Styles.AssetName id={`asset-name_${asset.assetId}`}>
                            {asset.alias}
                        </Styles.AssetName>
                        <Styles.VesselName id={`asset-vessel_${asset.assetId}`}>
                            {asset.location}
                        </Styles.VesselName>
                    </Styles.Names>
                    <Styles.AssetValue id={`asset-value_${asset.assetId}`} value={asset.value}>
                        {asset.value} value
                    </Styles.AssetValue>
                </Styles.Titles>
            </Styles.Header>
            <Styles.Details>
                <Styles.Label>Last Event</Styles.Label>
                <Styles.Value id={`asset-last-event_${asset.assetId}`}>
                    {asset.lastEvent}
                </Styles.Value>
                <Styles.Label>IP Address</Styles.Label>
                <Styles.Value id={`asset-ip-address_${asset.assetId}`}>
                    {asset.ipAddress}
                </Styles.Value>
            </Styles.Details>
            <Styles.Actions>
                <WatchListButton assetId={asset.assetId} />
                <ActionButton
                    id={`asset-details_${asset.assetId}`}
                    onClick={() => onClickWrapper()}
                >
                    <Icon glyph="Details" height={19} width={19} />
                    <Styles.DetailText>Details</Styles.DetailText>
                </ActionButton>
                {isAllowedToEdit && <EditAssetButton assetIdCard={asset.assetId} />}
            </Styles.Actions>
        </Styles.Container>
    )
}

interface EditAssetButtonProps {
    assetIdCard: GuidType
}
function EditAssetButton({assetIdCard}: EditAssetButtonProps): JSX.Element {
    return (
        <ActionButton
            id={`asset-edit_${assetIdCard}`}
            onClick={() => addQueryParam('editAsset', assetIdCard)}
        >
            <img src={EDIT_BUTTON} />
            <Styles.DetailText>Edit</Styles.DetailText>
        </ActionButton>
    )
}
