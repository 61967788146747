import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {Api, QuerySpecification} from '../../../api/Api'
import {locationTagThreatScore, QueryCancellation} from '../../../websocket/Queries'
import AppState from '../../types/app-state'
import {TimeRange} from '../../../values/TimeRange'
import {GuidType} from '../../../values/generic-type-defintions'
import {LocationTagScore} from '../../../values/location-tag-score'

const QUERY_PATH = 'locationTagThreatScore'
const QUERY_SCHEDULE = '10s'

function etsQueryLocationTag(timeRange: TimeRange, locationTag: string): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${locationTagThreatScore}-${timeRange}-${locationTag}`,
        schedule: QUERY_SCHEDULE,
        params: {
            identity: locationTag,
            fromRelative: `now() - ${timeRange}`,
        },
    }
}

export function fetchLocationTagThreatScore(
    timeRange: TimeRange,
    locationTag: GuidType,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api) => {
        dispatch(requestLocationTagThreatScore(timeRange))

        const query: QuerySpecification = etsQueryLocationTag(timeRange, locationTag)

        api.newQuery(query, (data) => {
            const scores: LocationTagScore[] = convertScores(data)
            dispatch(receiveLocationTagThreatScore(timeRange, scores))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

export function requestLocationTagThreatScore(
    timeRange: TimeRange,
): Actions.RequestLocationTagThreatScore {
    return {
        type: ActionType.REQUEST_LOCATION_TAG_THREAT_SCORE,
        payload: timeRange,
    }
}

export function receiveLocationTagThreatScore(
    timeRange: TimeRange,
    scores: LocationTagScore[],
): Actions.ReceiveLocationTagThreatScore {
    return {
        type: ActionType.RECEIVE_LOCATION_TAG_THREAT_SCORE,
        payload: {
            timeRange: timeRange,
            scores: scores,
        },
    }
}

export interface RawEts {
    timestamp: number
    score: number
}

interface RawEtsList {
    items: RawEts[]
}

function convertScores(data: RawEtsList): LocationTagScore[] {
    const scores: RawEts[] = data.items
    return scores.map((value) => {
        return {
            time: value.timestamp,
            tagScore: value.score,
        }
    })
}
