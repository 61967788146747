import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const DataRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin-bottom: ${spacing(3)};
`

export const Label = styled.label`
    padding: 0;
    margin: 0;
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Value = styled.span`
    text-align: right;
    padding: 0;
    margin: 0 0 0 ${spacing(2)};
`
