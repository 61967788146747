import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'

interface AddButtonProps {
    blocked?: boolean
}

export function AddButton({blocked}: AddButtonProps): JSX.Element | null {
    const {addNewUser, newUserData} = useUserManagement()
    const disabled =
        newUserData.username.length === 0 ||
        newUserData.email.length === 0 ||
        newUserData.roles.length === 0 ||
        blocked

    function onClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        addNewUser()
    }

    return (
        <Styled.Button disabled={disabled} id="add-button" onClick={onClick}>
            Add
        </Styled.Button>
    )
}
