import {DataLoading} from '../../../../components/data-loading/data-loading'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {incidentsFilterLoadingSelector} from '../../../../store/state/incidents-filter/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {IncidentCardsPopulated} from './incident-cards-populated'
import {NoData} from './no-data/no-data'

export function IncidentsCardsContent(): JSX.Element {
    const {loading, totalNumberOfIncidents} = usePagedIncidents()
    const filterLoading = useTypedSelector(incidentsFilterLoadingSelector)
    const showLoading =
        totalNumberOfIncidents == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData ||
        filterLoading

    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfIncidents === 0) {
        return <NoData text="There are no incidents" />
    }
    return <IncidentCardsPopulated />
}
