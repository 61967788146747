import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './data-cell-read-only.styled'

interface DataCellProps {
    label: string
    gridArea?: string
    children: ReactNode | ReactNodeArray
    cardView?: boolean
    width?: {label?: number; input?: number}
}
export function DataCellReadOnly({
    label,
    children,
    gridArea,
    cardView,
    width,
}: DataCellProps): JSX.Element {
    const formattedGridArea = gridArea || label.toLocaleUpperCase().replace(/ /g, '-')
    const id = `vessel-configuration_${formattedGridArea.toLowerCase()}`

    return (
        <>
            <Styled.Label gridArea={formattedGridArea} id={`${id}-label`} width={width?.label}>
                {label}
            </Styled.Label>
            <Styled.Value
                gridArea={`${formattedGridArea}-VALUE`}
                id={`${id}-value`}
                cardView={cardView}
                width={width?.input}
            >
                <Styled.Text>{children}</Styled.Text>
            </Styled.Value>
        </>
    )
}
