import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {BREAK_POINT_SINGLE_COLUMN} from '../../../system-page-content.styled'

interface CardProps {
    status?: string
    width: number
}

export const Card = styled.div<CardProps>`
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) => props.theme.incidents.dataRow.background};
    padding: ${spacing(1)};
    width: ${(props) => (props.width > BREAK_POINT_SINGLE_COLUMN ? '270px' : '100%')};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${smallFont()};
    border-left: thick solid
        ${(props) =>
            props.status === 'RUNNING'
                ? props.theme.incidents.dataRow.statusBar.closed
                : props.theme.incidents.dataRow.statusBar.new};
`

export const EmptyCard = styled.div<CardProps>`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) => props.theme.incidents.dataRow.background};
    padding: ${spacing(1)};
    width: ${(props) => (props.width > BREAK_POINT_SINGLE_COLUMN ? '270px' : '100%')};
    height: 50px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${smallFont()};
    border-left: thick solid ${(props) => props.theme.incidents.dataRow.statusBar.draft};
`

export const Title = styled.h2`
    font-size: 14px;
    line-height: 21px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-left: ${spacing(1)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
`

interface DataCellProps {
    titleOnSameLine: boolean
}

export const DataCell = styled.div<DataCellProps>`
    flex: 1;
    margin-top: ${spacing(1)};
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
`

interface SubTitleProps {
    fixedWidth: boolean
}

export const SubTitle = styled.div<SubTitleProps>`
    font-size: 12px;
    line-height: 17px;
    font-weight: ${(props) => props.theme.font.weight.light};
    padding-left: ${spacing(1)};
    ${(props) => (props.fixedWidth ? 'width: 50px;' : '')}
`
