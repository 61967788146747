import produce from 'immer'
import {LoggingOutAction} from '../../../../../store/state/session-data/actions'
import * as Actions from './actions'
import {EnforcementPolicyState} from '../type/enforcement-policy-state'
import {ActionType} from './action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {DEFAULT_ENFORCEMENT_POLICY_STATE} from '../type/default-enforcement-policy-state'
import {
    EnforcementPolicy,
    EnforcementPolicyToCreateOrUpdate,
    policyForMasterOverrideEnum,
    policyForNewDevicesEnum,
} from '../type/enforcement-policy'

// FIXME: masterOverrideDuration will be added later, currently it's always null
function getDuration(masterOverride: policyForMasterOverrideEnum): string | null {
    switch (masterOverride) {
        case policyForMasterOverrideEnum.TIME_LIMIT:
        // return '1h'
        case policyForMasterOverrideEnum.NEVER:
        case policyForMasterOverrideEnum.UNTIL_REVIEWED:
        default:
            return null
    }
}

export const enfocementPolicyReducer = produce(
    (draft: EnforcementPolicyState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_POLICY_DATA_ACTION:
                draft.loadingRequestedEnforcementDataState = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION:
                draft.loadingRequestedEnforcementDataState = LoadingState.Loaded
                if (!isEqual(draft.selectedPolicy, action.payload.receivedPolicy)) {
                    draft.selectedPolicy = action.payload.receivedPolicy
                }
                if (!isEqual(draft.policyToCreateOrUpdate, action.payload.receivedPolicy)) {
                    draft.policyToCreateOrUpdate = populatePolicyToCreateOrUpdate(
                        action.payload.receivedPolicy,
                    )
                }
                draft.isUpdated = DEFAULT_ENFORCEMENT_POLICY_STATE.isUpdated
                break
            case ActionType.SET_CHANGE_POLICY_NAME:
                draft.policyToCreateOrUpdate.name = action.payload
                break
            case ActionType.RECEIVED_ALL_POLICY_DATA_ACTION:
                draft.allPolicies = action.payload
                break
            case ActionType.CHANGE_NETWORK_PROTECTION:
                draft.policyToCreateOrUpdate.networkProtection.isEnabled = action.payload
                break
            case ActionType.CHANGE_REMOVABLES_PROTECTION:
                draft.policyToCreateOrUpdate.usbProtection.isEnabled = action.payload
                break
            case ActionType.CHANGE_POLICY_FOR_NEW_DEVICES:
                draft.policyToCreateOrUpdate.usbProtection.policyForNewDevices = action.payload
                break
            case ActionType.CHANGE_POLICY_FOR_MASTER_OVERRIDE:
                draft.policyToCreateOrUpdate.usbProtection.policyForMasterOverride = action.payload
                draft.policyToCreateOrUpdate.usbProtection.policyForMasterOverrideDuration =
                    getDuration(action.payload)
                break
            case ActionType.CHANGE_USB_AV_PROTECTION:
                draft.policyToCreateOrUpdate.usbProtection.isUsbAvProtectionEnabled = action.payload
                break
            case ActionType.CHANGE_BLOCK_ALL_USB_FOR_OFFLINE_ASSETS:
                draft.policyToCreateOrUpdate.usbProtection.isBlockAccessToOfflineAssetsEnabled =
                    action.payload
                break
            case ActionType.CHANGE_ALLOW_ANY_ON_MASTER:
                draft.policyToCreateOrUpdate.usbProtection.isAllowAnyOnMasterEnabled =
                    action.payload
                break
            case ActionType.SET_DEFAULT_POLICY_DATA:
                draft.selectedPolicy = DEFAULT_ENFORCEMENT_POLICY_STATE.selectedPolicy
                draft.policyToCreateOrUpdate =
                    DEFAULT_ENFORCEMENT_POLICY_STATE.policyToCreateOrUpdate
                draft.isUpdated = DEFAULT_ENFORCEMENT_POLICY_STATE.isUpdated
                break
            case ActionType.SET_DISCARD_CHANGES:
                draft.discardChanges = action.payload
                draft.isUpdated = false
                draft.policyToCreateOrUpdate = populatePolicyToCreateOrUpdate(draft.selectedPolicy)
                break
            case ActionType.SET_POLICY_AFTER_UPDATE:
                draft.policyToCreateOrUpdate = action.payload.receivedPolicy
                draft.selectedPolicy = action.payload.receivedPolicy
                break

            /* istanbul ignore next */
            default:
                break
        }
    },
)

export function populatePolicyToCreateOrUpdate(
    receivedPolicy: EnforcementPolicy | undefined,
): EnforcementPolicyToCreateOrUpdate {
    if (!receivedPolicy) {
        return {
            id: '',
            isDefault: false,
            name: '',
            networkProtection: {
                isEnabled: false,
            },
            usbProtection: {
                isBlockAccessToOfflineAssetsEnabled: false,
                isEnabled: false,
                policyForMasterOverride: policyForMasterOverrideEnum.NEVER,
                policyForMasterOverrideDuration: null,
                policyForNewDevices: policyForNewDevicesEnum.ALWAYS_UNWANTED,
                isAllowAnyOnMasterEnabled: false,
                isUsbAvProtectionEnabled: false,
            },
        }
    }
    return {
        id: receivedPolicy.id,
        isDefault: receivedPolicy.isDefault,
        name: receivedPolicy.name,
        networkProtection: receivedPolicy.networkProtection,
        usbProtection: receivedPolicy.usbProtection,
    }
}
