import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {
    METRIC_FILTER_BAR_VIEW_CARD_WIDTH,
    METRIC_FULL_CARD_WIDTH,
    METRIC_SHRINK_VIEW_CARD_WIDTH,
} from '../../metrics-page.styled'
import {animated} from 'react-spring'

interface HeaderSectionProps {
    width: number
    showFilterBar: boolean
}

export const HeaderSection = styled.div<HeaderSectionProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing(2)};
    width: ${(props) =>
        props.showFilterBar
            ? `${METRIC_FILTER_BAR_VIEW_CARD_WIDTH}px`
            : props.width > 540
              ? `${METRIC_FULL_CARD_WIDTH}px`
              : `${METRIC_SHRINK_VIEW_CARD_WIDTH}px`};
    height: 440px;
    padding: ${spacing(2)};
    background-color: ${(props) => props.theme.colors.background.default};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px / 25px;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
    will-change: opacity;
`

export const AnimatedDiv = styled(animated.div)``
export const Image = styled.img`
    padding-top: 10px;
`

export const Title = styled.div`
    font-size: 28px;
    line-height: normal;
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const Message = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing(1)};
    justify-content: center;
`
