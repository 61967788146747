import LoadingState from '../../../../../values/loading-state-enum'
import {PagedUniqueFlowsState} from './paged-unique-flows-state'

export const DEFAULT_PAGED_UNIQUE_FLOWS_STATE: PagedUniqueFlowsState = {
    assetId: undefined,
    loading: LoadingState.NotPopulated,
    error: '',
    dataPages: undefined,
    totalNumberOfFlows: undefined,
    totalNumberOfPages: undefined,
    selectedPage: undefined,
    pageSize: 10,
    toDate: undefined,
    fromDate: undefined,
} as PagedUniqueFlowsState
