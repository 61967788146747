import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {PagedIncidentsReportState} from '../types/paged-incidents-report-state'
import {DEFAULT_PAGED_INCIDENTS_REPORT_STATE} from '../types/default-paged-incidents-report-state'
import {isEqual} from 'lodash'
import {
    IncidentAssignmentsCounts,
    IncidentAvgPerformanceType,
    IncidentCountsType,
    IncidentTrendsType,
    IncidentVesselsPerformanceType,
} from '../types/incidents-report-output'
import {IncidentResponse} from '../../../incidents-v3/contexts/types/incident-response'

export const pagedIncidentsReportReducer = produce(
    (draft: PagedIncidentsReportState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsDetails = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsCounts = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAssignments = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsTrends = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAvgPerformance =
                    LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsVesselsPerformance =
                    LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_DETAILS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsDetails = LoadingState.Loaded

                if (!isEqual(draft.filteredDataContent, action.payload)) {
                    draft.filteredDataContent = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsCounts = LoadingState.Loaded

                if (!isEqual(draft.filteredDataCounts, action.payload)) {
                    draft.filteredDataCounts = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsAssignments = LoadingState.Loaded

                if (!isEqual(draft.filteredAssignementsCounts, action.payload)) {
                    draft.filteredAssignementsCounts = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsTrends = LoadingState.Loaded

                if (!isEqual(draft.incidentsTrends, action.payload)) {
                    draft.incidentsTrends = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsAvgPerformance = LoadingState.Loaded

                if (!isEqual(draft.incidentsAvgPerformance, action.payload)) {
                    draft.incidentsAvgPerformance = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsVesselsPerformance = LoadingState.Loaded

                if (!isEqual(draft.incidentsVesselsPerformance, action.payload)) {
                    draft.incidentsVesselsPerformance = action.payload
                }
                break
            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break
            case ActionType.SET_FILTER:
                draft.loadingPopulatedDataState.incidentsDetails = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsCounts = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAssignments = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsTrends = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAvgPerformance =
                    LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsVesselsPerformance =
                    LoadingState.RequestingData
                draft.filteredDataCounts = {} as IncidentCountsType
                draft.filteredDataContent = new Array<IncidentResponse>()
                draft.filteredAssignementsCounts = {} as IncidentAssignmentsCounts
                draft.incidentsTrends = new Array<IncidentTrendsType>()
                draft.incidentsAvgPerformance = new Array<IncidentAvgPerformanceType>()
                draft.incidentsVesselsPerformance = {} as IncidentVesselsPerformanceType
                break
            case ActionType.SET_HIGHLIGHTED_FILTER_VALUE:
                draft.highlightedFilerValue = action.payload
                break
            case ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN:
                draft.isInactive = action.payload
                break
            case ActionType.DISPLAY_INCIDENT_DETAILS_EXPANDED:
                draft.incidentDetailExpandedId = action.payload
                break
            case ActionType.REPORT_CREATE:
                draft.reportUrl = action.payload
                break
            case ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP:
                draft.showGenerateReportPopup = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_INCIDENTS_REPORT_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
