import {NodeData, NodeDataMap, NodeId} from '../../../values/nodes/NodeData'
import {LocationIdType} from '../locations/state'

export type LocationNodeArrayMapType = Map<LocationIdType, Array<NodeData>>
export interface LatestNodeDetails {
    id: NodeId
    timeStamp: string
}
export interface NodesReduxState {
    isFetchingNodes: boolean
    nodes: NodeDataMap
    nodesForLocations: LocationNodeArrayMapType
    latestNodeDetails: LatestNodeDetails | undefined
}

export const DEFAULT_NODES_STATE: NodesReduxState = {
    isFetchingNodes: true,
    nodes: new Map<NodeId, NodeData>(),
    nodesForLocations: new Map<LocationIdType, Array<NodeData>>(),
    latestNodeDetails: undefined,
}
