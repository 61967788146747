import * as Styled from './_styled/user-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {NoDataModal} from '../no-data-modal'
import {DetailsSection} from './details-section/details-section'
import {ReminderSection} from './reminder-section/reminder-section'
import {SaveButton} from './save-button/save-button'
import {useIncidentsPolicy} from '../../../../contexts/incidents-policy/use-incidents-policy'
import {ReccomendedPlaybooksSection} from './recommended-playbooks-section/recommended-playbooks-section'

export function IncidentPolicyDetails(): JSX.Element | null {
    const {activeIncidentPolicyData} = useIncidentsPolicy()

    if (!activeIncidentPolicyData) {
        return <NoDataModal />
    }

    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader text="Incident policy" />
            <DetailsSection />
            <ReminderSection />
            <ReccomendedPlaybooksSection />
            <SaveButton />
        </Styled.Container>
    )
}
