import styled from 'styled-components'
import {mediumFont, mediumSmallFont} from '../../../../../../theme/font-styles'

export const MessageSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const LineOne = styled.div`
    ${mediumFont()}
    color: ${(props) => props.theme.dashboard.noData.text};
    margin-bottom: 5px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const LineTwo = styled.div`
    ${mediumSmallFont()}
    color: ${(props) => props.theme.dashboard.noData.text};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
