import qs from 'qs'

import {history} from '../store/configure-store'

export const addQueryParam = (param: string, value: string): void => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    history.push({
        search: qs.stringify({...queryParams, [param]: value}, {addQueryPrefix: true}),
    })
}

export const addMultipleQueryParam = (
    param: string,
    value: string,
    param2: string,
    value2: string,
): void => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    history.push({
        search: qs.stringify(
            {...queryParams, [param]: value, [param2]: value2},
            {addQueryPrefix: true},
        ),
    })
}

export const hasQueryParam = (param: string, value: string): boolean => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    return !!queryParams[param] && queryParams[param] === value
}

export const removeQueryParam = (param: string): void => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    delete queryParams[param]

    history.push({
        search: qs.stringify(queryParams, {addQueryPrefix: true}),
    })
}

export const removeMultipleQueryParam = (params: string[]): void => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    params.forEach((param) => delete queryParams[param])

    history.push({
        search: qs.stringify(queryParams, {addQueryPrefix: true}),
    })
}

export const replaceDateParams = (fromDate: string | null, toDate: string | null): void => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    delete queryParams['fromDate']
    delete queryParams['toDate']

    if (fromDate) {
        if (toDate) {
            history.push({
                search: qs.stringify(
                    {...queryParams, ['fromDate']: fromDate, ['toDate']: toDate},
                    {addQueryPrefix: true},
                ),
            })
        } else {
            history.push({
                search: qs.stringify(
                    {...queryParams, ['fromDate']: fromDate},
                    {addQueryPrefix: true},
                ),
            })
        }
    } else {
        if (toDate) {
            history.push({
                search: qs.stringify({...queryParams, ['toDate']: toDate}, {addQueryPrefix: true}),
            })
        }
    }
}
