import {DataLoading} from '../../../../../components/data-loading/data-loading'
import LoadingState from '../../../../../values/loading-state-enum'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {NoData} from '../../no-data/no-data'
import {MetricsTablePopulated} from './metrics-table-populated'

export function MetricsTableContent(): JSX.Element {
    const {loadingFilteredDataState, totalNumberOfFilteredMetrics} = usePagedMetricsBeta()
    const showLoading =
        totalNumberOfFilteredMetrics == undefined ||
        loadingFilteredDataState === LoadingState.NotPopulated ||
        loadingFilteredDataState === LoadingState.RequestingData
    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfFilteredMetrics === 0) {
        return <NoData text="No metrics have been detected yet" />
    }
    return <MetricsTablePopulated />
}
