import {SortField} from '../../pages/my-vessels-v2/listing/components/filter/common/sort-order/values'
import * as Styles from './radio-button.styled'
interface Props {
    sortKey: string
    sortableField: SortField
    groupName: string
    optionSelected: (key: string) => void
}
export function RadioButton({
    sortKey,
    groupName,
    sortableField,
    optionSelected,
}: Props): JSX.Element {
    const id = `${groupName}-${sortableField.key}`
    return (
        <Styles.Label htmlFor={id}>
            <Styles.RadioButtonInput
                type="radio"
                id={id}
                name={groupName}
                value={sortableField.key}
                onChange={() => optionSelected(sortableField.key)}
                checked={sortKey === sortableField.key}
            />
            <Styles.StyledRadioButton />
            <Styles.Text>{sortableField.value}</Styles.Text>
        </Styles.Label>
    )
}
