import {useState} from 'react'
import {isEmailValid} from '../../../../../../../utils/Utils'
import * as Styled from './data-input.styled'
import {Check, X} from 'react-feather'

type EmailInputProps = {
    label: string
    ccEmails: string[]
    setChange: (value: string[]) => void
    isChanged: boolean
}

export function CcEmailInput({
    label,
    ccEmails,
    setChange,
    isChanged,
}: EmailInputProps): JSX.Element {
    const [newEmail, setNewEmail] = useState('')
    const [warningMessage, setWarningMessage] = useState('')
    const isValidEmail = isEmailValid(newEmail)
    const isSavable = isChanged && isValidEmail

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setWarningMessage('')
        setNewEmail(e.target.value.trim())
    }
    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (newEmail.length > 0 && isValidEmail && e.key === 'Enter') {
            if (ccEmails.includes(newEmail)) {
                setWarningMessage('This email address is already in use')
            } else if (ccEmails.length >= 3) {
                setWarningMessage('You have reached the limit of 3 cc emails')
            } else {
                setWarningMessage('')
                setChange([...ccEmails, newEmail])
                setNewEmail('')
            }
        }
    }
    function onDeleteClick(emailToBeDeleted: string) {
        setChange(ccEmails.filter((item) => item !== emailToBeDeleted))
        setNewEmail('')
    }

    return (
        <>
            <Styled.Label id={`email-vessel-tagging`}>{label}:</Styled.Label>
            <div>
                <Styled.Value id={`email-vessel-tagging-value`}>
                    <Styled.inputNonText
                        value={newEmail}
                        onInput={onChangeHandler}
                        onKeyPress={onEnterClick}
                    />
                    {isSavable && (
                        <Check color="green" width={19} height={19} style={{paddingLeft: '2px'}} />
                    )}
                </Styled.Value>
                {!isValidEmail && (
                    <Styled.WarningMessage id={'emailInvalidError'}>
                        Email is invalid
                    </Styled.WarningMessage>
                )}
                {warningMessage !== '' && (
                    <Styled.WarningMessage id={'emailInvalidError'}>
                        {warningMessage}
                    </Styled.WarningMessage>
                )}
            </div>

            {ccEmails?.map((email, index) => (
                <Styled.CcEmailWrapper key={index}>
                    <Styled.CcEmail>{email}</Styled.CcEmail>
                    <X
                        width={15}
                        height={15}
                        style={{
                            paddingLeft: '5px',
                            color: '#424242',
                        }}
                        onClick={() => onDeleteClick(email)}
                    />
                </Styled.CcEmailWrapper>
            ))}
        </>
    )
}
