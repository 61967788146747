import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/software-inventory-card.styled'
import {SoftwareInventoryResponse} from '../../contexts/types/software-inventory-response'
import {getFormattedSoftwareInventoryData, getImageForSoftwareState} from '../data-helpers'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import {SoftwareInventoryDetails} from './_styled/software-inventory-details.styled'
import {SoftwareDetailsExpanded} from './software-details-expanded/software-details-expanded'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

interface SoftwareInventoryCardProps {
    software: SoftwareInventoryResponse
    onClickFn?: () => void
}

export function SoftwareInventoryCard({
    software,
    onClickFn,
}: SoftwareInventoryCardProps): JSX.Element {
    const {width} = useDimensions()
    const {modalIdForSoftwareDetails, downloadSoftwareReport} = usePagedSoftwareInventory()
    const formattedSoftwareInventoryData = getFormattedSoftwareInventoryData(software)
    const detailExtended = modalIdForSoftwareDetails === formattedSoftwareInventoryData.identity

    return (
        <SoftwareInventoryDetails
            activeSoftware={detailExtended}
            id={`SoftwareInventoryDetails${formattedSoftwareInventoryData.identity}`}
        >
            <Styled.Card
                onClick={onClickFn}
                id={`data-card_${formattedSoftwareInventoryData.identity}`}
            >
                <Styled.CardAreaTitle width={width}>
                    <Styled.CardCustomerTitleData gridArea="COMPANY-TITLE">
                        <div
                            id={`software-inventory-company-name_${formattedSoftwareInventoryData.identity}`}
                        >
                            <img
                                src={getImageForSoftwareState(
                                    formattedSoftwareInventoryData.status,
                                )}
                                style={{marginRight: '5px'}}
                            />
                            {formattedSoftwareInventoryData.companyName}
                        </div>
                    </Styled.CardCustomerTitleData>
                    <Styled.CardProductTitleData gridArea="PRODUCT-TITLE">
                        <div
                            id={`software-inventory-product-name_${formattedSoftwareInventoryData.identity}`}
                        >
                            {formattedSoftwareInventoryData.productName}
                        </div>
                    </Styled.CardProductTitleData>
                </Styled.CardAreaTitle>
                <Styled.CardAreaContent width={width}>
                    <CardDataCell
                        gridArea="WHEN"
                        label="First Detected:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="first_detected"
                    >
                        {formattedSoftwareInventoryData.firstSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="LAST-ACTIVE"
                        label="Last Active:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="last_active"
                    >
                        {formattedSoftwareInventoryData.lastSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="NEWEST-VERSION"
                        label="Newest Active Ver:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="newest_active_ver"
                    >
                        {formattedSoftwareInventoryData.latestVersion}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="OLDEST-VERSION"
                        label="Oldest Active Ver:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="oldest_active_ver"
                    >
                        {formattedSoftwareInventoryData.oldestVersion}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="NUMBER-HOSTS"
                        label="Number of Hosts:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="totalNumberOfHostsPerItem"
                    >
                        <Styled.LinkText>
                            {formattedSoftwareInventoryData.totalNumberOfHostsPerItem}
                        </Styled.LinkText>
                    </CardDataCell>
                    <CardDataCell
                        gridArea="NUMBER-VESSELS"
                        label="Number of Vessels:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="totalNumberOfVesselsPerItem"
                    >
                        <Styled.LinkText>
                            {formattedSoftwareInventoryData.totalNumberOfVesselsPerItem}
                        </Styled.LinkText>
                    </CardDataCell>
                    <CardDataCell
                        gridArea="DOWNLOAD"
                        label="Download Report:"
                        formattedUSDeviceData={formattedSoftwareInventoryData}
                        identifier="downloadReport"
                    >
                        <Styled.AttachmentButton
                            onClick={() =>
                                downloadSoftwareReport(
                                    formattedSoftwareInventoryData.identity,
                                    formattedSoftwareInventoryData.productName,
                                )
                            }
                            id={`downloadSoftwareReport-button_${formattedSoftwareInventoryData.identity}`}
                        >
                            <Icon glyph="Csv" height={19} width={19} />
                        </Styled.AttachmentButton>
                    </CardDataCell>
                </Styled.CardAreaContent>
            </Styled.Card>
            {detailExtended && <SoftwareDetailsExpanded />}
        </SoftwareInventoryDetails>
    )
}
