import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {setSortByNotesField} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {INCIDENT_NOTES_SORT_ORDER} from '../../../data-helpers'
import * as Styles from './filter-drop-down.styled'
import NEWNOTES from './icons/new-notes.svg'
import OLDNOTES from './icons/old-notes.svg'
import STATUS from './icons/status-change.svg'
import SEVERITY from './icons/severity-change.svg'
import ASSIGNMENT from './icons/assignment.svg'

export function FilterDropDown(): JSX.Element {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dispatch = useDispatch()

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setDropdownOpen(false))
    const {sortByNotes} = useTypedSelector(incidentsFilterSelector)

    function onChangeWrapper(value: INCIDENT_NOTES_SORT_ORDER): void {
        dispatch(setSortByNotesField(value))
    }
    return (
        <Styles.DropDownWrapper ref={clickOutside} id="incident-history-drop-down-wrapper">
            <Styles.Label>Show:</Styles.Label>
            <Styles.InputFieldWrapper
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                id="incident-history-dropdownOpen"
            >
                <Styles.InputFieldText id={`incident-history-sort-by-${sortByNotes}`}>
                    - {sortByNotes} -
                </Styles.InputFieldText>

                <Icon glyph={dropdownOpen ? 'DropdownUp' : 'DropdownDown'} height={10} width={10} />

                {dropdownOpen && (
                    <Styles.Options>
                        {filterOptions?.map((value) => (
                            <Styles.Option
                                key={value}
                                allValue={value === 'All'}
                                lastElement={value === filterOptions[filterOptions.length - 1]}
                                onClick={() => onChangeWrapper(value)}
                                id={`incident-history-filter-option-${value}`}
                            >
                                {value !== 'All' && <img src={getFilterTypeIcon(value)} />}
                                <Styles.OptionValue
                                    allValue={value === 'All'}
                                    id={`incident-history-filter-option-styled-${value}`}
                                >
                                    {value}
                                </Styles.OptionValue>
                            </Styles.Option>
                        ))}
                    </Styles.Options>
                )}
            </Styles.InputFieldWrapper>
        </Styles.DropDownWrapper>
    )
}
const filterOptions: INCIDENT_NOTES_SORT_ORDER[] = [
    INCIDENT_NOTES_SORT_ORDER.NONE,
    INCIDENT_NOTES_SORT_ORDER.NEW_NOTES,
    INCIDENT_NOTES_SORT_ORDER.OLD_NOTES,
    INCIDENT_NOTES_SORT_ORDER.STATUS,
    INCIDENT_NOTES_SORT_ORDER.SEVERITY,
    INCIDENT_NOTES_SORT_ORDER.ASSIGNMENT_CHANGE,
]

function getFilterTypeIcon(filterOptions: INCIDENT_NOTES_SORT_ORDER): string {
    switch (filterOptions) {
        case 'New notes first':
            return NEWNOTES
        case 'Old notes first':
            return OLDNOTES
        case 'Status change':
            return STATUS
        case 'Severity change':
            return SEVERITY
        case 'Assignment change':
            return ASSIGNMENT
        default:
            return '-'
    }
}
