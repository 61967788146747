import {Check} from 'react-feather'
import {DataCell} from './data-cell'
import {Input} from './data-cell.styled'

interface DataInputProps {
    label: string
    gridArea?: string
    initialValue: string | string[] | number | undefined
    setChange: (value: string) => void
    changed: boolean
    width?: {label?: number; input?: number}
    type?: string
}

export function DataInput({
    label,
    gridArea,
    initialValue,
    setChange,
    changed,
    width,
    type,
}: DataInputProps): JSX.Element {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setChange(e.target.value)
    }

    return (
        <DataCell label={label} gridArea={gridArea} width={width?.label}>
            <Input
                value={initialValue}
                type={type}
                onChange={onChangeHandler}
                isChanged={changed}
                width={width?.input}
            />
            {changed && <Check color={'green'} width={19} height={19} />}
        </DataCell>
    )
}
