import {createSelector} from 'reselect'
import {LatestLocationThreatScores} from '../../../../store/state/latest-location-threat-scores/state'
import {getLocationScore} from '../../../../store/state/locations/helpers'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../../store/state/locations/state'
import {SortValue} from '../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {latestLocationThreatScoreSelector} from '../../../../store/state/latest-location-threat-scores/selectors'
import {VesselTags} from '../../../../store/state/vessel-tags/state'
import {vesselFilterSelector} from '../../../../store/state/vessel-filter/selectors'
import {VesselFilter} from '../../../../store/state/vessel-filter/types/vessel-filter-state'

function filterVessel(location: Location, locations: Set<LocationIdType> | undefined): boolean {
    try {
        return !locations ? true : locations.has(location.location)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({location, locations, error})
        return true
    }
}

export function filterVesselTags(
    vesselTag: VesselTags[] | undefined,
    searchTerm: string[],
): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

export function filterVesselName(vesselName: string, searchTerm: string): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return vesselName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

function sortByRiskScore(filter: VesselFilter): boolean {
    return filter?.vesselSort?.field === 'vessel-threat-score'
}

function getModifier(sortValue: SortValue): 1 | -1 {
    return sortValue.direction === 'asc' ? 1 : -1
}

export const filteredVesselIds = createSelector(
    locationsSelector,
    vesselFilterSelector,
    latestLocationThreatScoreSelector,
    (
        locations: Location[],
        filter: VesselFilter,
        latestLocationThreatScores: LatestLocationThreatScores,
    ) => {
        const modifier = getModifier(filter.vesselSort)
        function nameComparison(a: Location, b: Location): number {
            return (
                a.description?.localeCompare(b.description, undefined, {
                    sensitivity: 'base',
                }) * modifier
            )
        }

        const riskScoreComparison = (a: Location, b: Location): number => {
            const aScore = getLocationScore(a?.location, latestLocationThreatScores)
            const bScore = getLocationScore(b?.location, latestLocationThreatScores)
            return (aScore - bScore) * modifier
        }

        const orderFunc: (a: Location, b: Location) => number = sortByRiskScore(filter)
            ? riskScoreComparison
            : nameComparison
        return locations
            .filter((location) => filterVessel(location, filter.locations))
            .filter((location) => filterVesselTags(location.tags, filter.searchVesselTagTerm))
            .filter((location) =>
                filterVesselName(location.description, filter.searchVesselNameTerm),
            )
            .sort(orderFunc)
            .map((location) => location.location)
    },
)
