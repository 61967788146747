export enum ActionType {
    SHOW_USER_DETAIL_MODAL = '[USER MANAGEMENT CONTEXT] SHOW USER DETAIL MODAL',
    CLOSE_USER_DETAIL_MODAL = '[USER MANAGEMENT CONTEXT] CLOSE USER DETAIL MODAL',
    RECEIVED_REQUESTED_USER_DETAILS = '[USER MANAGEMENT CONTEXT] RECEIVED REQUESTED USER DETAILS',
    SET_CHANGE_USER_NAME = '[USER MANAGEMENT CONTEXT] SET CHANGE USER NAME',
    SET_CHANGE_USER_EMAIL = '[USER MANAGEMENT CONTEXT] SET CHANGE USER EMAIL',
    SET_CHANGE_NOTIFICATION_EMAIL = '[USER MANAGEMENT CONTEXT] SET CHANGE NOTIFICATION EMAIL',
    ASSIGN_USER_ROLE = '[USER MANAGEMENT CONTEXT] ASSIGN USER ROLE',
    DEASSIGN_USER_ROLE = '[USER MANAGEMENT CONTEXT] DEASSIGN USER ROLE',
    SET_SUBSCRIPTION = '[USER MANAGEMENT CONTEXT] SET SUBSCRIPTION',
    CHANGES_SAVED = '[USER MANAGEMENT CONTEXT] CHANGES SAVED',
    USER_DISABLED = '[USER MANAGEMENT CONTEXT] USER DISABLED',
    USER_ENABLED = '[USER MANAGEMENT CONTEXT] USER ENABLED',
    SET_SAVE_RESULT_MESSAGE = '[USER MANAGEMENT CONTEXT] SET SAVE RESULT MESSAGE',
    SET_DISABLE_USER_RESULT_MESSAGE = '[USER MANAGEMENT CONTEXT] SET DISABLE USER RESULT MESSAGE',
    DISPLAY_ADD_NEW_USER_MODAL = '[USER MANAGEMENT CONTEXT] DISPLAY ADD NEW USER MODAL',
    SET_NEW_USER_NAME = '[USER MANAGEMENT CONTEXT] SET NEW USER NAME',
    SET_NEW_USER_EMAIL = '[USER MANAGEMENT CONTEXT] SET NEW USER EMAIL',
    SET_NEW_USER_ROLES = '[USER MANAGEMENT CONTEXT] SET NEW USER ROLES',
    SET_NEW_USER_SUBSCRIPTION = '[USER MANAGEMENT CONTEXT] SET NEW USER SUBSCRIPTION',
    SET_NEW_USER_EXTERNAL = '[USER MANAGEMENT CONTEXT] SET NEW USER EXTERNAL',
    NEW_USER_ADDED = '[USER MANAGEMENT CONTEXT] NEW USER ADDED',
    SET_SAVE_PROCESSING_STATUS = '[USER MANAGEMENT CONTEXT] SET SAVE PROCESSING STATUS',
    SET_ASSIGNED_TAG_ID = '[USER MANAGEMENT CONTEXT] SET ASSIGNED TAG ID',
    SET_TAG_STRICT_MODE = '[USER MANAGEMENT CONTEXT] SET TAG STRICT MODE',
    VIEW_AS_EXTERNAL = '[USER MANAGEMENT CONTEXT] VIEW AS EXTERNAL',
    TOGGLE_DETAIL_EXPANDED = '[USER MANAGEMENT CONTEXT] TOGGLE DETAIL EXPANDED',
    TOGGLE_ALERT_DETAIL_EDIT = '[USER MANAGEMENT CONTEXT] TOGGLE ALERT DETAIL EDIT',
}
