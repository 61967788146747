import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import LoadingState from '../../../../../../values/loading-state-enum'
import {usePagedIndicators} from '../../../../contexts/indicators/use-paged-indicators'
import {CardArea} from '../../common/cards/card-area.styled'
import {IndicatorCard} from './indicator-card'
import {NoData} from './no-data'
import {RequestError} from './request-error'
import {LoadingData} from '../../common/cards/loading-data'
import {indicatorsLoadingStateSelector} from '../../../../../../store/state/indicators/selectors'
import {fetchIndicators} from '../../../../../../store/state/indicators/action-creators'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

export function IndicatorCards(): JSX.Element {
    const dispatch = useDispatch()
    const indicatorLoadingState = useTypedSelector(indicatorsLoadingStateSelector)
    const {refreshData, loading, dataPage, totalNumberOfIndicators} = usePagedIndicators()

    useEffect(() => {
        if (loading === LoadingState.NotPopulated) {
            refreshData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (indicatorLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchIndicators())
        }
    }, [dispatch, indicatorLoadingState])

    if (loading === LoadingState.Errored) {
        return <RequestError />
    }

    const showLoading =
        totalNumberOfIndicators == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData ||
        indicatorLoadingState === LoadingState.NotPopulated ||
        indicatorLoadingState === LoadingState.RequestingData

    if (showLoading || !dataPage) {
        return <LoadingData />
    }

    if (totalNumberOfIndicators === 0) {
        return <NoData />
    }

    return (
        <CardArea>
            {dataPage.map((indicator) => (
                <IndicatorCard key={indicator.guid} event={indicator} />
            ))}
        </CardArea>
    )
}
