import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface CardAreaProps {
    width: number
}

export const CardArea = styled.div<CardAreaProps>`
    flex: 1;
    padding: ${(props) =>
        props.width < 600
            ? `0 ${spacing(2)} ${spacing(2)} ${spacing(2)}`
            : `0 ${spacing(4)} ${spacing(2)} ${spacing(4)}`};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
