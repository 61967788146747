import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {VesselDetails} from './vessel-details/vessel-details'
import {ContentWrapper} from './_styled/vessel-details-modal.styled'

export function VesselDetailsModal(): JSX.Element {
    const {width} = useDimensions()

    const isLoaded = true
    return (
        <ContentWrapper width={width}>
            {isLoaded ? <VesselDetails /> : <DataLoading />}
        </ContentWrapper>
    )
}
