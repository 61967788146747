import {Overlay} from './modal-overlay'
import {ChangeSoftwareStatus} from './change-software-status'

export function SoftwareChangeStatusModal(): JSX.Element {
    return (
        <Overlay>
            <ChangeSoftwareStatus />
        </Overlay>
    )
}
