import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background-color: ${(props) => props.theme.softwareInventory.resetButton.background};
    color: ${(props) => props.theme.softwareInventory.resetButton.text};
    border-radius: 3px;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

interface SectionContentProps {
    isInactive: boolean
}

export const ButtonWrapper = styled.div<SectionContentProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing(2)};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`
