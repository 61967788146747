import styled from 'styled-components'
import {GridLayout} from '../../../../general/grid-layout.enum'

interface RiskScoreBarProps {
    gridLayout: GridLayout
}

function getTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '37px'
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '34px'
        default:
            return '42px'
    }
}

function getHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '35px'
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '39px'
        default:
            return '70px'
    }
}

export const RiskScoreBar = styled.div<RiskScoreBarProps>`
    position: relative;
    top: ${(props) => getTop(props.gridLayout)};
    height: ${(props) => getHeight(props.gridLayout)};
`
