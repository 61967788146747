import {useMetricsPolicy} from '../../../../../contexts/metrics-policy/use-metrics-policy'
import * as Styled from './save-button.styled'

interface SaveButtonProps {
    policyToCreate?: boolean
}
export function SaveButton({policyToCreate}: SaveButtonProps): JSX.Element {
    const {isMetricsUpdated, addNewMetricsPolicyDetails, updateMetricsPolicyDetails} =
        useMetricsPolicy()
    return (
        <Styled.Button
            id="save-button-metrics-policy"
            onClick={(e) => {
                e.preventDefault()
                if (policyToCreate) {
                    addNewMetricsPolicyDetails()
                } else {
                    updateMetricsPolicyDetails()
                }
            }}
            isChanged={isMetricsUpdated}
        >
            <Styled.Text>Save</Styled.Text>
        </Styled.Button>
    )
}
