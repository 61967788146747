import * as Styled from './configuration-asset-select.styled'
import {Check} from 'react-feather'
import {isEqual} from 'lodash'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {NodeData} from '../../../../../../../values/nodes/NodeData'
import {useMemo} from 'react'
import {MultiselectCheckbox} from './multiselect-checkbox'
import {DropdownOption} from './multiselect-dropdown'

interface ConfigurationOnboardDashboardAssetsProps {
    vesselDashboardAgentIds: string[] | undefined
    allAssets: NodeData[] | undefined
    active: boolean
}
export function ConfigurationOnboardDashboardAssets({
    vesselDashboardAgentIds,
    allAssets,
    active,
}: ConfigurationOnboardDashboardAssetsProps): JSX.Element {
    const {changeDashboardAgentIds, initialValue} = useVesselManagement()
    const selected = new Set(vesselDashboardAgentIds)

    function onChangeEvent(option: DropdownOption): void {
        if (selected.has(option.value)) {
            selected.delete(option.value)
        } else {
            selected.add(option.value)
        }
        changeDashboardAgentIds(Array.from(selected))
    }
    const isChanged =
        allAssets &&
        active &&
        !isEqual(vesselDashboardAgentIds, initialValue.vesselDashboardAgentIds)

    const options = useMemo(() => {
        return (allAssets || []).map((asset) => {
            return {label: asset.alias, value: asset.agentId}
        })
    }, [allAssets])

    return (
        <>
            <Styled.Label id={`vessel`}>Access to onboard dashboard</Styled.Label>
            <Styled.Value>
                <MultiselectCheckbox
                    options={options}
                    selectedOptions={options?.filter((x) => selected.has(x.value))}
                    placeholder="Please select assets"
                    onChange={onChangeEvent}
                    disabled={!active}
                />
                {isChanged && (
                    <Check color="green" width={19} height={19} style={{paddingLeft: '2px'}} />
                )}
            </Styled.Value>
        </>
    )
}
