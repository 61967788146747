import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumFont} from '../../../../theme/font-styles'

export const BREAK_POINT = 750

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width >= BREAK_POINT ? '700px' : '400px')};
`

export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
    position: relative;
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`

export const Content = styled.div`
    padding: ${spacing(4)};
    display: grid;
    grid-template-columns: 1fr;
    grid-template: 'INFORMATION' 'DATA_RANGE' 'REQUIREMENTS' 'PURPOSE' 'REFERENCE';
    column-gap: ${spacing(8)};
    row-gap: ${spacing(2)};
`

export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`
