import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

interface OverviewValueProps {
    gridLayout: GridLayout
}

function getFlexDirection(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return 'row'
        default:
            return 'column'
    }
}
export const OverviewValue = styled.div<OverviewValueProps>`
    display: flex;
    flex-direction: ${(props) => getFlexDirection(props.gridLayout)};
    align-items: center;
`
