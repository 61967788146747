import AppState from '../../types/app-state'
import {LatestAssetData} from './types/latest-unknown-asset'

export const latestUnknownAssetLoadingSelector = (state: AppState): boolean =>
    state.latestUnknownAsset.loading

export const latestUnknownAssetSelector = (state: AppState): LatestAssetData | null =>
    state.latestUnknownAsset.unknownAssetData

export const haslatestUnknownAssetSelector = (state: AppState): boolean =>
    state.latestUnknownAsset.unknownAssetData?.latestAsset != null
