import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface ContainerProps {
    disabled?: boolean
}

export const Wrapper = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)} 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    min-height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    padding: 3px;
`
export const Text = styled.div`
    ${mediumSmallFont()}
    word-break: break-word;
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-align: justify;
    display: flex;
    flex-direction: column;
`
export const TextAreaWrapper = styled.div`
    flex: 1;
`
export const TextArea = styled.textarea`
    border: 0;
    outline: none;
    width: 100%;
    ${mediumSmallFont()};
    font-style: italic;
    resize: auto;
`
