import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const BREAK_POINT = 680

interface DisplayAreaProps {
    width: number
}
export const DisplayArea = styled.div<DisplayAreaProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > BREAK_POINT ? 'row' : 'column')};
    justify-content: space-between;
    align-items: 'flex-start';
    gap: ${spacing(5)};
    padding: ${spacing(4)};
    background-color: ${(props) => props.theme.systemPage.dataArea.background};
`

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
