import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
interface Props {
    gridArea: string
}
export const IncidentTypeWrapper = styled.div<Props>`
    display: flex;
    justify-content: flex-start;
    column-gap: ${spacing(1)};
    padding-right: ${spacing(2)};
    grid-area: ${(props) => props.gridArea};
`

export const IncidentTypeText = styled.span`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
`
