import {getFormattedDuration} from '../../../vessels-beta/components/vessels-table/table-components/total-average-time-to-resolve-incidents'
import {IncidentAvgPerformanceType} from '../../contexts/types/incidents-report-output'
import * as Styled from './incidents-response-performance.styled'
export interface CurrentScoreTooltipProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    active?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label?: any
    data: IncidentAvgPerformanceType[]
}

export function CurrentIncidentsPerformanceTooltip({
    active,
    payload,
    data,
    label,
}: CurrentScoreTooltipProps): JSX.Element | null {
    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.label === label)
        if (!dataPoint) {
            return null
        }
        const formattedResponse = {
            ...dataPoint,
            displayAvgTimeToOpen: getFormattedDuration(dataPoint.averageTimeToOpen),
            displayAvgTimeToClose: getFormattedDuration(dataPoint.averageTimeToClose),
        }
        return (
            <Styled.ToolTipContainer id={`ToolTipContainer-${dataPoint?.label}`}>
                <Styled.ToolTipTitle>{dataPoint?.label}</Styled.ToolTipTitle>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle parameterToDisplay="averageTimeToOpen" />
                    <Styled.CountLink>{formattedResponse?.displayAvgTimeToOpen}</Styled.CountLink>
                    <Styled.ToolTipSubTitle>Average days to Open</Styled.ToolTipSubTitle>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle parameterToDisplay="averageTimeToClose" />
                    <Styled.CountLink>{formattedResponse?.displayAvgTimeToClose}</Styled.CountLink>
                    <Styled.ToolTipSubTitle>Average days to Close</Styled.ToolTipSubTitle>
                </div>
            </Styled.ToolTipContainer>
        )
    }

    return null
}
