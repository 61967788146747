import {Panel} from '../_styled/panel-section.styled'
import * as Styled from './recommended-playbooks-section.styled'
import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'
import {FormattedIncidentsPolicyPlaybooksForUI} from '../../../../../contexts/incidents-policy/type/incidents-recommendation-playbooks'
import DEFAULT_ICON from './default-icon.svg'
import CUSTOM_ICON from './custom-icon.svg'
import {addQueryParam} from '../../../../../../../helpers/navigation'

export function ReccomendedPlaybooksSection(): JSX.Element {
    const {formattedIncidentsPolicyPlaybooksForUI} = useIncidentsPolicy()
    return (
        <Panel>
            <Styled.DetailsLayout>
                <Styled.Label>Recommendation playbooks</Styled.Label>

                <Styled.PlaybooksWrapper>
                    {formattedIncidentsPolicyPlaybooksForUI?.map((incidentPlaybook) => (
                        <IncidentPlaybook
                            incidentPlaybook={incidentPlaybook}
                            key={incidentPlaybook.identity}
                        />
                    ))}
                </Styled.PlaybooksWrapper>
            </Styled.DetailsLayout>
        </Panel>
    )
}
function IncidentPlaybook({
    incidentPlaybook,
}: {
    incidentPlaybook: FormattedIncidentsPolicyPlaybooksForUI
}): JSX.Element {
    const {iconName, playBookText, onIconClick, onTextClick} = getPlaybookRow(incidentPlaybook)

    return (
        <Styled.PlaybookRow>
            <Styled.DisplayName>{incidentPlaybook.displayName}</Styled.DisplayName>
            <img src={iconName} style={{cursor: 'pointer'}} onClick={onIconClick} />
            <Styled.TitleText onClick={onTextClick}>{playBookText}</Styled.TitleText>
        </Styled.PlaybookRow>
    )
}
function getPlaybookRow(incidentPlaybook: FormattedIncidentsPolicyPlaybooksForUI): {
    iconName: string
    playBookText: string
    onIconClick: () => void
    onTextClick: () => void
} {
    const {downloadPlaybookFile, removeCustomFile} = useIncidentsPolicy()
    const defaultPlaybook = !incidentPlaybook.fileIdentity
    if (defaultPlaybook) {
        return {
            iconName: DEFAULT_ICON,
            playBookText: 'Upload custom file',
            onIconClick: () => downloadPlaybookFile(incidentPlaybook.defaultFileId),
            onTextClick: () =>
                addQueryParam(
                    'uploadDocument',
                    incidentPlaybook.incidentResponseRecommendationPlaybookTypeIdentity,
                ),
        }
    } else {
        return {
            iconName: CUSTOM_ICON,
            playBookText: 'Reset to default',
            onIconClick: () =>
                incidentPlaybook.fileIdentity != null &&
                downloadPlaybookFile(incidentPlaybook.fileIdentity),
            onTextClick: () =>
                removeCustomFile(
                    incidentPlaybook.incidentResponsePolicyIdentity,
                    incidentPlaybook.identity,
                ),
        }
    }
}
