import styled from 'styled-components'

interface TableDataRowProps {
    gridRow: number
}
export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 1;
    grid-column-end: -1;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    color: ${(props) => props.theme.modalDialog.dataRow.text};
    background-color: ${(props) => props.theme.modalDialog.dataRow.background};
`
