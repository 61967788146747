import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Panel = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(1)};
    align-items: center;
    ${smallFont()}
`
export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)} ${spacing(2)};
    margin: 0;
    width: 100%;
`

export const DataRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(1)};
`

export const DataCell = styled.div`
    display: flex;
    flex-direction: row;
`

export const DataCellKey = styled.div`
    ${smallFont()};
    width: 250px;
    padding: ${spacing(1)};
`

export const DataCellLastSeen = styled.div`
    ${smallFont()};
    width: 140px;
    padding: ${spacing(1)};
`

interface DataCellToggleProps {
    disabled?: boolean
}

export const DataCellToggle = styled.div<DataCellToggleProps>`
    ${smallFont()};
    flex: 1;
    padding: ${spacing(1)};
    display: flex;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`

export const NoItems = styled.div`
    text-align: center;
    padding: ${spacing(4)};
`
