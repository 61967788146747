import {useDispatch} from 'react-redux'
import {useVesselManagement} from '../../../../contexts/use-vessel-management'
import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {validateOTSourceModalDetails} from '../../../../contexts/type/ot-sources-modal-details'

export function SubmitArea(): JSX.Element {
    const {
        closeSetOTSourcesModal,
        saveUpdatedOTSourcesOnLocation,
        oTSourceModalDetails,
        displayValue,
    } = useVesselManagement()
    const dispatch = useDispatch()

    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        closeSetOTSourcesModal()
    }

    function addChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        saveUpdatedOTSourcesOnLocation(dispatch)
    }

    const disableSaving = validateOTSourceModalDetails(oTSourceModalDetails, displayValue.otSources)
    return (
        <Styled.ButtonsArea id="ot-sources-ButtonsArea">
            <Styled.Button id="cancel-changes-button" onClick={cancelChanges}>
                Cancel
            </Styled.Button>
            <Styled.Button disabled={!disableSaving} id="add-changes-button" onClick={addChanges}>
                Add
            </Styled.Button>
        </Styled.ButtonsArea>
    )
}
