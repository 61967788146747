import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import AssetDetailsModal from '../modals/asset-details/asset-details-modal'
import {locationSelector, queryStringObjectSelector} from '../store/routerSelectors'
import {EventDetailsModal} from '../modals/event-details/event-details-modal'
import {EventDetailsProvider} from '../modals/event-details/contexts/event-details-provider'
import useTypedSelector from '../hooks/use-typed-selector'
import {activeLocationSelector} from '../store/state/locations/selectors'
import {Location} from '../store/state/locations/state'
import {isUserAllowed} from '../values/Role'
import {rolesSelector} from '../store/state/roles/selectors'
import {Dashboard} from '../pages/dashboard-v2/dashboard'
import {MyVessels} from '../pages/my-vessels-v2/listing/my-vessels'
import {MyVesselsDeepLinking} from '../pages/my-vessels-v2/deep-linking/my-vessels-deep-linking'
import {Reports} from '../pages/reports-v2/reports'
import Vessels from '../pages/vessels/listing/vessels'
import {VesselConfiguration} from '../pages/vessels/configuration/vessel-configuration'
import {IncidentsBeta} from '../pages/incidents-v3/incidents'
import {UnknownAssetsPage} from '../pages/unknown-assets-v2/unknown-assets-page'
import {UsbDevicesPage} from '../pages/usb-devices/usb-devices-page'
import {SoftwareInventoryPage} from '../pages/software-inventory/software-inventory-page'
import {isInternalUserSelector} from '../store/state/current-user/selectors'
import {fleetConfigSelector} from '../store/state/config/selectors'
import UserManagement from '../pages/user-management/user-management'
import SwaggerLinksPage from '../pages/swagger-links-page/swagger-links-page'
import EngineStatusPage from '../pages/system-page/engine-status-page'
import MonaEngineStatusPage from '../pages/system-page/mona-engine-mode-page'
import SelfMonitoringEngineStatusPage from '../pages/system-page/self-monitoring-engine-status-page'
import EmailRecipientsManagementPage from '../pages/system-page/email-recipients-management-page'
import PolicyManagement from '../pages/policy-management/policy-management'
import {MetricsBetaPage} from '../pages/metrics-beta/metrics-beta-page'
import VesselManagement from '../pages/vessel-management/vessel-management'
import {VesselsBeta} from '../pages/vessels-beta/vessels-beta-page'
import {LicensesPage} from '../pages/licenses/licenses-page'
import {NMEAInventoryPage} from '../pages/nmea/nmea-page'
import {VesselListPage} from '../pages/ot-inventory/vessel-list-page'
import {LocationOTInventoryPage} from '../pages/ot-inventory/location-inventory-page'
import {IncidentsReportPage} from '../pages/incidents-report/incidents-report-page'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRedirectUrl(location: any): string {
    const {from} = location.state || {from: {pathname: '/dashboard'}}
    const url = from.search ? `${from.pathname}/${from.search}` : `${from.pathname}`
    return url
}

function legacyUpdateToWorkWithV1Layout(activeLocation: Location) {
    const root = document.getElementById('root')
    if (root && activeLocation) {
        if (activeLocation.code) {
            root.className = 'root--selected-location'
        } else if (activeLocation.location === 'fleet-view') {
            root.className = ''
        }
    }
}

export function AuthenticatedRoutes(): JSX.Element {
    const queryString = useTypedSelector(queryStringObjectSelector)
    const displayAssetDetailsModal = queryString?.hasOwnProperty('assetDetails')
    const displayEventDetailsModal =
        displayAssetDetailsModal &&
        queryString?.hasOwnProperty('eventDetails') &&
        queryString?.hasOwnProperty('eventTimestamp')

    const pathname = useLocation().pathname
    const location = useTypedSelector(locationSelector)
    legacyUpdateToWorkWithV1Layout(useTypedSelector(activeLocationSelector))
    const userRoles = useTypedSelector(rolesSelector)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const isUserRoleAllowed = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const isAllowed = isInternalUser && isUserRoleAllowed
    const isAllowedToPolicyManagement = isUserAllowed(userRoles, [
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
    ])

    if (pathname.startsWith('/login')) {
        return <Redirect to={getRedirectUrl(location)} />
    }
    if (pathname.startsWith('/my-vessels-beta')) {
        return <Redirect to={'/my-vessels'} />
    }
    if (pathname.startsWith('/reports-beta')) {
        return <Redirect to={'/reports'} />
    }
    if (pathname.startsWith('/incidentsbeta')) {
        return <Redirect to={'/incidents'} />
    }
    if (pathname.startsWith('/incidents-beta')) {
        return <Redirect to={'/incidents'} />
    }
    if (pathname.startsWith('/dashboardbeta')) {
        return <Redirect to={'/dashboard'} />
    }
    if (location?.search.match('assetManagementExpanded=true')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/unknown-assets-beta')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/unknown-assets')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/medullastatus')) {
        return <Redirect to={'/engine-status'} />
    }
    if (pathname.startsWith('/metrics-beta')) {
        return <Redirect to={'/metrics'} />
    }

    return (
        <>
            <Switch>
                <Route path="/dashboard" component={Dashboard} />
                {isAllowed && <Route path="/engine-status" component={EngineStatusPage} />}
                {isAllowed && <Route path="/mona-engine-mode" component={MonaEngineStatusPage} />}
                {isAllowed && (
                    <Route
                        path="/self-monitoring-engine-status"
                        component={SelfMonitoringEngineStatusPage}
                    />
                )}
                {isAllowed && (
                    <Route path="/email-recipients" component={EmailRecipientsManagementPage} />
                )}
                {isAllowed && <Route path="/swagger-links" component={SwaggerLinksPage} />}
                {isAllowed && <Route path="/customer-packages" component={LicensesPage} />}
                <Route path="/user-management" component={UserManagement} />
                {isAllowed && <Route path="/vessels/:location" component={VesselConfiguration} />}
                {isAllowed && <Route path="/vessels" component={Vessels} />}
                <Route path="/my-vessels/:location" component={MyVesselsDeepLinking} />
                <Route path="/my-vessels" component={MyVessels} />
                {isAllowedToPolicyManagement && (
                    <Route path="/policy-management" component={PolicyManagement} />
                )}
                {isAllowedToPolicyManagement && (
                    <Route path="/vessel-management" component={VesselManagement} />
                )}
                <Route path="/incidents" component={IncidentsBeta} />
                {(isInternalUser || fleetConfig.reports) && (
                    <Route path="/reports" component={Reports} />
                )}
                {(isInternalUser || fleetConfig.networkAssets) && (
                    <Route path="/network-assets" component={UnknownAssetsPage} />
                )}
                {(isInternalUser || fleetConfig.usbInventory) && (
                    <Route path="/usb-devices" component={UsbDevicesPage} />
                )}
                {(isInternalUser || fleetConfig.softwareInventory) && (
                    <Route path="/software" component={SoftwareInventoryPage} />
                )}
                {(isInternalUser || fleetConfig.nmeaInventory) && (
                    <Route path="/nmea" component={NMEAInventoryPage} />
                )}
                <Route path="/metrics" component={MetricsBetaPage} />
                {(isInternalUser ||
                    true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                    <Route
                        path="/ot-inventory/:location/assets"
                        component={LocationOTInventoryPage}
                    />
                )}
                <Route path="/vessels-beta" component={VesselsBeta} />

                {(isInternalUser ||
                    true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                    <Route path="/ot-inventory-vessels" component={VesselListPage} />
                )}
                {isUserRoleAllowed && (
                    <Route path="/incidentsReport" component={IncidentsReportPage} />
                )}

                <Route path="/" render={() => <Redirect to="/dashboard" />} />
            </Switch>

            {displayAssetDetailsModal && (
                <>
                    <AssetDetailsModal />
                    <EventDetailsProvider>
                        {displayEventDetailsModal && <EventDetailsModal />}
                    </EventDetailsProvider>
                </>
            )}
        </>
    )
}
