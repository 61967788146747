import {Header, Box} from './section.styled'
import {Cards} from '../card/cards'
import {EngineStatus} from '../../../../../values/engine_status/engine-status'
import {EmptyCard} from '../card/empty-card'

interface SectionProps {
    engineStatus: EngineStatus
}
export function Section({engineStatus}: SectionProps): JSX.Element {
    return (
        <Box>
            <Header>{engineStatus.engineName}</Header>
            {engineStatus.consumerStates.length !== 0 ? (
                <Cards consumerStates={engineStatus.consumerStates} />
            ) : (
                <EmptyCard />
            )}
        </Box>
    )
}
