import {TableCodeCell} from './table-code-cell'
import {TableNameCell} from './table-name-cell'
import {Location} from '../../../../../../store/state/locations/state'
import {useVesselConfig} from '../../../contexts/use-vessel-config'
import {getCopyedConfig} from '../../../contexts/helper'
import {useToggle} from '../../../../../../contexts/toggle/use-toggle'
import * as Styled from './_styled/table-content.styled'
import {useState} from 'react'

interface TableRowProps {
    location: Location
    index: number
    currentType: string | undefined
    currentCustomerLocationCode: string | undefined
}

export function TableRow({
    location,
    index,
    currentType,
    currentCustomerLocationCode,
}: TableRowProps): JSX.Element {
    const {bulkAssignConfig} = useVesselConfig()
    const {setValue: setModalOpen} = useToggle()
    const gridRow = index + 1
    const [hoverOn, setHoverOn] = useState<boolean>(false)
    function mouseOverRow(): void {
        setHoverOn(true)
    }
    function mouseLeaveRow(): void {
        setHoverOn(false)
    }
    const onClickWrapper = () => {
        bulkAssignConfig(getCopyedConfig(currentType, currentCustomerLocationCode, location))
        setModalOpen(false)
    }
    return (
        <Styled.TableDataGrid
            onClick={onClickWrapper}
            onMouseEnter={() => mouseOverRow()}
            onMouseLeave={() => mouseLeaveRow()}
        >
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`modal_location-row_${location.location}`}
                isHovering={hoverOn}
            />
            <TableNameCell location={location} gridRow={gridRow} isHovering={hoverOn} />
            <TableCodeCell location={location} gridRow={gridRow} isHovering={hoverOn} />
        </Styled.TableDataGrid>
    )
}
