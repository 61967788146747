import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface AddNoteBorderProps {
    isSaveDisabled: boolean
}
export const AddNoteBorder = styled.div<AddNoteBorderProps>`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    display: flex;
    flex: 1;
    opacity: ${(props) => (props.isSaveDisabled ? 0.5 : 1)};
`

export const TextAreaWrapper = styled.div`
    flex: 1;
`
export const TextArea = styled.textarea`
    border: 0;
    outline: none;
    width: 100%;
    ${smallFont()};
    font-style: italic;
    resize: auto;
`
export const SaveButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    height: 27px;
    width: 70px;
    ${smallFont()}
    :disabled {
        opacity: 0.5;
    }
    :hover:not([disabled]) {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
interface AddNoteContainerProps {
    noteIsReviewed: boolean
}
export const AddNoteContainer = styled.div<AddNoteContainerProps>`
    display: flex;
    column-gap: 5px;
    justify-content: space-between;
    align-items: flex-end;
    padding: ${spacing(1)} 0 ${spacing(3)};
    background-color: ${(props) =>
        props.noteIsReviewed && props.theme.incidents.dataRow.editNoteBackground};
`
