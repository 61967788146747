import {SoftwareInventoryFilter} from '../../../../values/user-preferences/software-inventory-filter'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: SoftwareInventoryFilter
    tooltip?: boolean
}

export function DetailFilterSoftwareInventory({criteria, tooltip}: DetailFilterProps): JSX.Element {
    return (
        <DetailsWrapper>
            {tooltip && (
                <Details id="orderBy">
                    • Order By: {criteria.sortColumn.orderBy} (
                    {criteria.sortColumn.orderAscending ? 'Ascending' : 'Descending'})
                </Details>
            )}
            {criteria.searchedFirstDetected && (
                <Details id="searchedFirstDetected">
                    • First Detect: {criteria.searchedFirstDetected.toString()}
                </Details>
            )}
            {criteria.searchedLastActive && (
                <Details id="searchedLastActive">
                    • Last Active: {criteria.searchedLastActive.toString()}
                </Details>
            )}
            {criteria.searchedCompanyName && (
                <Details id="searchedCompanyName">
                    • Company: {criteria.searchedCompanyName}
                </Details>
            )}
            {criteria.searchedProductName && (
                <Details id="searchedProductName">
                    • Product Name: {criteria.searchedProductName}
                </Details>
            )}
            {criteria.filteredSoftwareStatus && (
                <Details id="filteredSoftwareStatus">
                    • Status: {criteria.filteredSoftwareStatus.toString()}
                </Details>
            )}
        </DetailsWrapper>
    )
}
