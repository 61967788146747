import {ReactNode, useReducer} from 'react'
import {GuidType} from '../../../../values/generic-type-defintions'
import {PagedIndicatorsContext} from './paged-indicators-context'
import {pagedIndicatorsReducer} from './state/reducer'
import {DEFAULT_PAGED_INDICATORS_STATE} from './types/default-paged-indicators-state'
import {PagedIndicatorsState} from './types/paged-indicators-state'

type PagedIndicatorsProviderProps = {
    children: ReactNode | ReactNode[]
    assetId: GuidType
    fromDate: string | undefined
    toDate: string | undefined
    pageSize: number
}

export function PagedIndicatorsProvider({
    children,
    assetId,
    fromDate,
    toDate,
    pageSize,
}: PagedIndicatorsProviderProps): JSX.Element {
    const initialState: PagedIndicatorsState = DEFAULT_PAGED_INDICATORS_STATE
    initialState.assetId = assetId
    initialState.fromDate = fromDate
    initialState.toDate = toDate
    initialState.pageSize = pageSize
    const [state, dispatch] = useReducer(pagedIndicatorsReducer, initialState)

    return (
        <PagedIndicatorsContext.Provider value={{state, dispatch}}>
            {children}
        </PagedIndicatorsContext.Provider>
    )
}
