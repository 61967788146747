import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {showCards} from '../../../data-helpers'

interface ContentContainerMiddleProps {
    width: number
    backgroundColor?: ColorType
}

export const ContentContainerMiddle = styled.div<ContentContainerMiddleProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    margin: ${(props) => (props.width > MAX_SCREEN_WIDTH ? `0 ${spacing(2)}` : 0)};
    align-self: center;
    z-index: 0;
`
interface ContentAreaProps {
    width: number
}
export const ContentArea = styled.div<ContentAreaProps>`
    padding: ${(props) =>
        props.width < 600 ? `0 ${spacing(2)} 0 ${spacing(2)}` : `0 ${spacing(4)} 0 ${spacing(4)}`};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
`

export const Card = styled.div<ContentAreaProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${spacing(2)};
    padding: ${(props) =>
        showCards(props.width)
            ? `${spacing(2)}`
            : `${spacing(2)} ${spacing(2)} ${spacing(2)} ${spacing(12)}`};
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) => props.theme.incidents.dataRow.background};
`
export const FilterSection = styled.div<ContentAreaProps>`
    ${(props) =>
        showCards(props.width)
            ? 'display: flex; flex:1; justify-content: space-between; align-items: flex-start;'
            : "display: grid; grid-template-columns: 14fr 5fr 150px; align-items: flex-start; grid-template-areas: 'STATUS SEVERITY RESSET';"}
    ${(props) =>
        props.width < 600 ? 'flex-direction: column; align-items: center;' : 'flex-direction:row'};
    gap: ${spacing(2)};
`
interface ContainerProps {
    width: number
    gridArea: string
}
export const FilterContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    grid-area: ${(props) => props.gridArea};
`
export const StatusFilterContainer = styled(FilterContainer)`
    align-items: ${(props) => (props.width < 600 ? 'center' : 'flex-start')};
    width: 100%;
`
