import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Reports = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
