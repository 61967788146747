import {Check} from 'react-feather'
import * as Styled from './editable-input.styled'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../../../store/state/saved-filters/selectors'
import {setChangeAlertPrefix} from '../../../../../../store/state/saved-filters/action-creators'
import {isEqual} from 'lodash'

interface DataInputProps {
    initialValue: string | undefined
}

export function PrefixInput({initialValue}: DataInputProps): JSX.Element {
    const dispatch = useDispatch()
    const newSubscription = useTypedSelector(newSubscriptionSelector)

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        dispatch(setChangeAlertPrefix(e.target.value))
    }

    const hasNewValue =
        newSubscription.subjectPrefix !== undefined && newSubscription.subjectPrefix.length !== 0
    const isChanged = hasNewValue && !isEqual(initialValue, newSubscription.subjectPrefix)

    return (
        <Styled.InputWrapper>
            <Styled.Input
                defaultValue={initialValue}
                value={newSubscription.subjectPrefix}
                onChange={(e) => onChangeHandler(e)}
            />
            {isChanged && <Check color={'green'} width={15} height={15} />}
        </Styled.InputWrapper>
    )
}
