import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {AnalysisType} from '../../../../../store/state/metrics-filter-beta/state'
import {
    MetricsResponseForAssetStatus,
    MetricsResponseForBenchmark,
    MetricsResponseForTarget,
    MetricsResponseForTrends,
} from '../../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {NoData} from '../../no-data/no-data'
import {MetricsCardAssetStatus} from './metrics-card-asset-status'
import {MetricsCardBenchmark} from './metrics-card-benchmark'
import {MetricsCardTarget} from './metrics-card-target'
import {MetricsCardTrends} from './metrics-card-trends'
import {CardArea} from './metrics-cards.styled'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'

export function MetricsCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataTablesRow, displayMetricsDetailsModal, displayFilterBar} = usePagedMetricsBeta()
    const {analysisTypes} = useTypedSelector(metricsBetaFilterSelector)
    const dispatch = useDispatch()

    if (!dataTablesRow) {
        return <NoData text="There are no metrics matching your filtering criteria" />
    }

    return (
        <CardArea width={width} id="card-data-grid-style">
            {dataTablesRow?.map((metrics, index) => {
                return (
                    <GetCardRowType
                        key={`card-row-${metrics.location}-${index}`}
                        analysisTypes={analysisTypes}
                        values={metrics}
                        onClickFn={() => {
                            displayMetricsDetailsModal(metrics, index)
                            displayFilterBar(false)
                            dispatch(
                                logDetailsOpen(
                                    AuditLogPageType.METRICS,
                                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                                    metrics.metric,
                                ),
                            )
                        }}
                    />
                )
            })}
        </CardArea>
    )
}
interface GetCardRowTypeProps {
    analysisTypes: AnalysisType
    values:
        | MetricsResponseForAssetStatus
        | MetricsResponseForBenchmark
        | MetricsResponseForTarget
        | MetricsResponseForTrends
    onClickFn: () => void
}
function GetCardRowType({analysisTypes, values, onClickFn}: GetCardRowTypeProps): JSX.Element {
    switch (analysisTypes) {
        case AnalysisType.SCORECARD:
            return (
                <MetricsCardAssetStatus
                    metrics={values as MetricsResponseForAssetStatus}
                    onClickFn={onClickFn}
                />
            )
        case AnalysisType.BENCHMARK:
            return (
                <MetricsCardBenchmark
                    metrics={values as MetricsResponseForBenchmark}
                    onClickFn={onClickFn}
                />
            )
        case AnalysisType.TARGET:
            return (
                <MetricsCardTarget
                    metrics={values as MetricsResponseForTarget}
                    onClickFn={onClickFn}
                />
            )
        case AnalysisType.TRENDS:
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return (
                <MetricsCardTrends
                    metrics={values as MetricsResponseForTrends}
                    onClickFn={onClickFn}
                />
            )
        default:
            return <div />
    }
}
