import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const Container = styled.div`
    background: ${(props) => props.theme.myVessels.cards.background};
    width: 420px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

export const PolicyNameWrapper = styled.div`
    padding: 0 ${spacing(3)};
    width: 100%;
`
