import * as Styled from './insights-section.styled'
import {ParameterContent} from './parameter-content'
import {useState} from 'react'
import {
    Button,
    DetailHeadingRow,
    HeadingWrapper,
    IconWrapper,
} from '../_styled/expand-panel-button.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

export function ActivitySection(): JSX.Element {
    const [detailExpanded, setDetailExpanded] = useState(true)

    return (
        <Styled.Panel>
            <HeadingWrapper>
                <DetailHeadingRow id="text_Insights">Insights</DetailHeadingRow>
                <Button
                    onClick={() => setDetailExpanded(!detailExpanded)}
                    id="expand-details-button_Insights"
                >
                    <IconWrapper>
                        <Icon
                            glyph={detailExpanded ? 'DropdownUp' : 'DropdownDown'}
                            height={15}
                            width={15}
                        />
                    </IconWrapper>
                </Button>
            </HeadingWrapper>
            {detailExpanded && (
                <Styled.DetailsLayout id="nmea-details-modal_panel_ActivitySection_DetailsLayout">
                    <ParameterContent />
                </Styled.DetailsLayout>
            )}
        </Styled.Panel>
    )
}
