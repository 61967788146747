import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../../store/state/session-data/actions'
import SessionActionType from '../../../../../store/state/session-data/action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import {AllPolicyData, PolicyManagementState, PolicyType} from '../type/policy-management-state'
import {
    DEFAULT_ASSIGN_POLICY_DIALOG_STATE,
    DEFAULT_POLICY_MANAGEMENT_STATE,
} from '../type/default-policy-management-state'
import {IncidentResponsePolicy} from '../../incidents-policy/type/incident-response-policy'
import {MetricsPolicy} from '../../metrics-policy/type/metrics-policy'
import {isEqual} from 'lodash'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {EnforcementPolicy} from '../../enforcement-policy/type/enforcement-policy'

interface PolicyDataTransformer<T> {
    (policyData: T[]): AllPolicyData[]
}

const getFormattedIncidentPolicyData = memoize<IncidentResponsePolicy>((policyData) => {
    return policyData
        .map((data) => ({
            identity: data.identity,
            policyName: data.name,
            policyType: PolicyType.INCIDENT,
            lastUpdate: data.updatedWhen,
            isDefaultPolicy: data.defaultPolicy,
        }))
        .sort((a, b) => b.lastUpdate?.localeCompare(a.lastUpdate))
})

const getEnforcementPolicyData = memoize<EnforcementPolicy>((policyData) => {
    return policyData
        .map((data) => ({
            identity: data.id,
            policyName: data.name,
            policyType: PolicyType.ENFORCEMENT,
            lastUpdate: data.updatedTimestamp,
            isDefaultPolicy: data.isDefault,
            locations: data.locations
                ? data.locations.map((location2Policy) => location2Policy.locationId)
                : [],
        }))
        .sort((a, b) => b.lastUpdate?.localeCompare(a.lastUpdate))
})

const getFormattedMetricsPolicyData = memoize<MetricsPolicy>((policyData) => {
    return policyData
        .map((data) => ({
            identity: data.id,
            policyName: data.name,
            policyType: PolicyType.METRICS,
            lastUpdate: data.updatedTimestamp,
            isDefaultPolicy: data.isDefault,
            locations: data.locations
                ? data.locations.map((location2Policy) => location2Policy.locationId)
                : [],
        }))
        .sort((a, b) => b.lastUpdate?.localeCompare(a.lastUpdate))
})

export const policyManagementReducer = produce(
    (draft: PolicyManagementState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_ALL_POLICY_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.allPolicyData = DEFAULT_POLICY_MANAGEMENT_STATE.allPolicyData
                break
            case ActionType.RECEIVE_ALL_POLICY_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                if (action.payload.type === PolicyType.INCIDENT) {
                    const formattedData = getFormattedIncidentPolicyData(
                        action.payload.data as IncidentResponsePolicy[],
                    )
                    if (!isEqual(draft.allPolicyData, formattedData)) {
                        draft.allPolicyData.push(...formattedData)
                    }
                } else if (action.payload.type === PolicyType.METRICS) {
                    const payloadData = action.payload.data as MetricsPolicy[]
                    const formattedData = getFormattedMetricsPolicyData(payloadData)
                    if (!isEqual(draft.allPolicyData, formattedData)) {
                        draft.allPolicyData.push(...formattedData)
                    }
                } else if (action.payload.type === PolicyType.ENFORCEMENT) {
                    const payloadData = action.payload.data as EnforcementPolicy[]
                    const formattedData = getEnforcementPolicyData(payloadData)
                    if (!isEqual(draft.allPolicyData, formattedData)) {
                        draft.allPolicyData.push(...formattedData)
                    }
                }
                draft.allPolicyData.sort((a, b) => a.policyType?.localeCompare(b.policyType))
                draft.dataToBeFetched = false
                break
            case ActionType.SHOW_POLICY_DETAIL_MODAL:
                draft.showPolicyModal = true
                draft.selectedPolicyType = action.payload.type
                draft.selectedPolicyId = action.payload.policyId
                break
            case ActionType.ADD_NEW_POLICY_DETAIL:
                draft.showPolicyModal = true
                draft.selectedPolicyType = action.payload.policyType
                draft.selectedPolicyId = null
                break
            case ActionType.CLOSE_POLICY_DETAIL_MODAL:
                draft.selectedPolicyId = DEFAULT_POLICY_MANAGEMENT_STATE.selectedPolicyId
                draft.selectedPolicyType = DEFAULT_POLICY_MANAGEMENT_STATE.selectedPolicyType
                draft.showPolicyModal = false
                break
            case ActionType.CHANGE_DATA_TO_BE_FETCHED:
                if (!isEqual(draft.dataToBeFetched, action.payload)) {
                    draft.dataToBeFetched = action.payload
                }
                break
            case ActionType.SHOW_ASSIGN_POLICY_DIALOG:
                draft.showAssignPolicyDialog = true
                draft.assignPolicyDialogState.selectedPolicyId = action.payload.policyId
                draft.assignPolicyDialogState.currentLocations =
                    action.payload.currentAssignedLocations
                draft.assignPolicyDialogState.newLocations = new Set<LocationIdType>(
                    action.payload.currentAssignedLocations,
                )
                draft.dataToBeFetched = false
                const defaultPolicy = draft.allPolicyData.find(
                    (data) =>
                        data.policyType === action.payload.policyType &&
                        data.isDefaultPolicy === true,
                )
                if (defaultPolicy) {
                    draft.defaultPolicy.identity = defaultPolicy.identity
                    draft.defaultPolicy.locationsOfDefaultPolicy = defaultPolicy.locations
                }
                break
            case ActionType.CLOSE_ASSIGN_POLICY_DIALOG:
                draft.showAssignPolicyDialog = false
                draft.assignPolicyDialogState = DEFAULT_ASSIGN_POLICY_DIALOG_STATE
                break
            case ActionType.TOGGLE_VESSEL:
                const {location, newValue} = action.payload
                if (draft.assignPolicyDialogState.newLocations == undefined) {
                    draft.assignPolicyDialogState.newLocations = new Set<LocationIdType>()
                }
                if (newValue) {
                    draft.assignPolicyDialogState.newLocations.add(location)
                } else {
                    draft.assignPolicyDialogState.newLocations.delete(location)
                    if (draft.assignPolicyDialogState.deselectedLocations == undefined) {
                        draft.assignPolicyDialogState.deselectedLocations =
                            new Set<LocationIdType>()
                    }
                    draft.assignPolicyDialogState.deselectedLocations.add(location)
                }
                break

            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.assignPolicyDialogState.searchVesselTagTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_NAME_TERM:
                draft.assignPolicyDialogState.searchVesselNameTerm = action.payload
                break

            case ActionType.SET_SEARCH_VESSEL_TERM:
                draft.assignPolicyDialogState.searchVesselTerm = action.payload
                break

            case ActionType.SET_SHOW_X_BUTTON:
                draft.assignPolicyDialogState.showXButton = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_POLICY_MANAGEMENT_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)

function memoize<T>(func: PolicyDataTransformer<T>): PolicyDataTransformer<T> {
    const cache = new Map<string, AllPolicyData[]>()
    return (input) => {
        const key = JSON.stringify(input)
        if (cache.has(key)) {
            return cache.get(key)!
        }
        const result = func(input)
        cache.set(key, result)
        return result
    }
}
