import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import * as Styled from './activity-section.styled'
import {ActivityContents} from './activity-contents'
import {SoftwareDetailType} from '../../../contexts/types/paged-software-inventory-state'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'

export function ActivitySection(): JSX.Element {
    const {detailExpanded} = useTypedSelector(softwareInventoryFilterSelector)

    return (
        <Panel>
            <ExpandPanelButton headingRow={SoftwareDetailType.Insights} />
            {detailExpanded.insightsExpanded && (
                <Styled.DetailsLayout id="ActivitySection_DetailsLayout">
                    <ActivityContents />
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
