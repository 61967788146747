import styled from 'styled-components'
import {extraSmallFont, smallFont, mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

export const SectionContent = styled.div`
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
`

export const SearchInput = styled.input`
    ${mediumSmallFont()};
    box-sizing: border-box;
    border: none;
    outline: 0;
    border-bottom: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    margin: 2px;
    padding: 4px;
    width: 100%;
`

export const TagResultsWrapper = styled.div`
    position: relative;
    max-width: 240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 3px;
`
export const TagResultsPopUp = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    gap: 10px;
    padding: 3px;
    background: white;
    overflow: hidden;
    z-index: 1;
`
export const ErrorMessage = styled.h2`
    margin: 0;
    width: 100%;
    ${extraSmallFont()};
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.text.error};
`
interface CharsCountProps {
    reachedLimit: boolean
}
export const CharsCount = styled.h2<CharsCountProps>`
    margin: 0;
    width: 100%;
    ${extraSmallFont()};
    text-align: end;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.reachedLimit && props.theme.colors.text.error};
`
export const EditOption = styled.h2`
    ${smallFont()};
    margin-left: 5px;
    width: 100%;
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: rgba(151, 150, 150, 1);
    cursor: pointer;
`
interface DoneProps {
    disabledOption: boolean
}
export const DoneOption = styled.h2<DoneProps>`
    position: absolute;
    top: 5px;
    right: 0;
    ${smallFont()};
    margin: 0;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.link.primary};
    cursor: pointer;
    ${(props) => props.disabledOption && 'opacity:0.5;  cursor: not-allowed;'}
`
