import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const ContentWrapper = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex: 1;
    padding-right: ${spacing(4)};
    justify-content: center;
    align-items: center;
`
