import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {LocationIdType} from '../locations/state'
import {VesselFilterReduxState} from '../vessel-filter/types/vessel-filter-state'
import {DEFAULT_VESSEL_FILTER_STATE} from '../vessel-filter/types/default-vessel-filter-state'
import {DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE, VesselManagementFilterReduxState} from './state'
import {Dispatch} from 'redux'
import {AxiosResponse} from 'axios'
import {populateLocations, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {VesselManagementFilterForUserPref} from '../../../values/user-preferences/vessel-management-filter'

const USER_PREFS_URL = '/api/v1/userPreferences/vessel_management'

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

function receiveFilter(data: {
    hasDeploymentIssues: boolean | undefined
    locations: Set<LocationIdType> | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchVesselTerm: string
}): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload: data,
    }
}

export const fetchVesselManagementPrefsIfExist = (
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return async (dispatch, getState) => {
        dispatch(requestFilter())

        const currentFilter = getState().vesselManagementFilter
        const vesselFilter = getState().vesselFilter

        try {
            const userPrefsresponse = await REST.get(USER_PREFS_URL)
            if (isDefaultTagUser === true) {
                await handleDefaultTagUserCase(
                    dispatch,
                    userPrefsresponse,
                    currentFilter,
                    vesselFilter,
                )
            } else {
                await handleNonDefaultTagUserCase(
                    dispatch,
                    userPrefsresponse,
                    currentFilter,
                    vesselFilter,
                )
            }
        } catch (error) {
            await REST.put(USER_PREFS_URL, {
                hasDeploymentIssues: undefined,
                locations: populateLocationsForFilterCriteria(vesselFilter.locations),
                searchVesselTagTerm: vesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
            })
            dispatch(
                receiveFilter({
                    hasDeploymentIssues: currentFilter.hasDeploymentIssues,
                    locations: vesselFilter.locations,
                    searchVesselTagTerm: vesselFilter.searchVesselTagTerm,
                    searchVesselNameTerm: vesselFilter.searchVesselNameTerm,
                    searchVesselTerm: vesselFilter.searchVesselTerm,
                }),
            )
        }
    }
}

const handleDefaultTagUserCase = async (
    dispatch: Dispatch,
    response: AxiosResponse,
    currentFilter: VesselManagementFilterReduxState,
    vesselFilter: VesselFilterReduxState,
) => {
    try {
        const res = await REST.get(`${USER_PREFS_URL}.default_tag`)

        dispatch(
            receiveFilter({
                hasDeploymentIssues: response.data.value?.hasDeploymentIssues,
                locations: populateLocations(response.data.value?.locations),
                searchVesselTagTerm: res.data.value.default_tag,
                searchVesselNameTerm: response.data.value.searchVesselNameTerm,
                searchVesselTerm: response.data.value.searchVesselTerm,
            }),
        )

        await REST.put(USER_PREFS_URL, {
            hasDeploymentIssues:
                response.data.value.hasDeploymentIssues ?? currentFilter.hasDeploymentIssues,
            locations: populateLocations(response.data.value?.locations),
            searchVesselTagTerm: res.data.value.default_tag,
            searchVesselNameTerm: response.data.value.searchVesselNameTerm,
        })
    } catch (error) {
        dispatch(
            receiveFilter({
                hasDeploymentIssues:
                    response.data.value.hasDeploymentIssues ?? currentFilter.hasDeploymentIssues,
                locations: populateLocations(
                    response.data.value?.locations ?? vesselFilter.locations,
                ),
                searchVesselTagTerm:
                    response.data.value.searchVesselTagTerm ?? vesselFilter.searchVesselTagTerm,
                searchVesselNameTerm:
                    response.data.value.searchVesselNameTerm ?? vesselFilter.searchVesselNameTerm,
                searchVesselTerm:
                    response.data.value.searchVesselTerm ?? vesselFilter.searchVesselTerm,
            }),
        )
    }
}
const handleNonDefaultTagUserCase = async (
    dispatch: Dispatch,
    response: AxiosResponse,
    currentFilter: VesselManagementFilterReduxState,
    vesselFilter: VesselFilterReduxState,
) => {
    dispatch(
        receiveFilter({
            hasDeploymentIssues:
                response.data.value.hasDeploymentIssues ?? currentFilter.hasDeploymentIssues,
            locations: populateLocations(response.data.value?.locations),
            searchVesselTagTerm:
                response.data.value.searchVesselTagTerm ?? vesselFilter.searchVesselTagTerm,
            searchVesselNameTerm:
                response.data.value.searchVesselNameTerm ?? vesselFilter.searchVesselNameTerm,
            searchVesselTerm: response.data.value.searchVesselTerm ?? vesselFilter.searchVesselTerm,
        }),
    )
}

export function toggleVesselDeploymentStatus(
    hasDeploymentIssues: boolean | undefined,
): ThunkAction<void, AppState, Api, Actions.ToggleVesselDeploymentStatusAction> {
    return (dispatch, getState) => {
        const vesselFilter = getState().vesselFilter

        dispatch({
            type: ActionType.TOGGLE_VESSEL_DEPLOYMENT_STATUS,
            payload: hasDeploymentIssues,
        })
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(
                {...DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE, hasDeploymentIssues},
                vesselFilter,
            ),
        )
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, Actions.ResetFilter> {
    return (dispatch) => {
        dispatch({type: ActionType.RESET_FILTER})
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(DEFAULT_VESSEL_MANAGEMENT_FILTER_STATE, DEFAULT_VESSEL_FILTER_STATE),
        )
    }
}

function buildFilterPrefs(
    state: VesselManagementFilterReduxState,
    vesselFilterState: VesselFilterReduxState,
): VesselManagementFilterForUserPref {
    return {
        hasDeploymentIssues: state.hasDeploymentIssues,
        locations: populateLocationsForFilterCriteria(vesselFilterState.locations),
        searchVesselTagTerm: vesselFilterState.searchVesselTagTerm,
        searchVesselNameTerm: vesselFilterState.searchVesselNameTerm,
    }
}
