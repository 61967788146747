import {PopulatedData} from './populated-data'
import {useEffect} from 'react'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import {incidentsOverview, QueryManager} from '../../../../../websocket/Queries'
import {IncidentWidgetType} from '../contexts/types/incident-widget-type'
import {NoData} from './no-data'
import {Level} from '../../../../../values/location-level-enum'
interface Props {
    queryManager: React.MutableRefObject<QueryManager>
    location?: string
}

export function DataLoaded({queryManager, location}: Props): JSX.Element {
    const {
        setWidgetOnDashboardData,
        registerIncidentsOverviewQuery,
        periodWidgetOnDashboard,
        selectedIncidentWidget,
        noDataComponent,
        loadedIncidentsDetails,
    } = useIncidentsWidget()

    useEffect(() => {
        const currentQueryManager = queryManager.current
        if (selectedIncidentWidget === IncidentWidgetType.LATEST_INCIDENT) {
            const formattedLocation = location != undefined ? location : undefined
            currentQueryManager.save(
                incidentsOverview,
                registerIncidentsOverviewQuery(formattedLocation),
            )
        } else {
            setWidgetOnDashboardData(periodWidgetOnDashboard, selectedIncidentWidget, location)
        }
        return () => {
            currentQueryManager.cancel(incidentsOverview)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryManager, selectedIncidentWidget, periodWidgetOnDashboard, location])
    const noDataLastIncidentWidget =
        selectedIncidentWidget === IncidentWidgetType.LATEST_INCIDENT &&
        loadedIncidentsDetails &&
        noDataComponent
    return noDataLastIncidentWidget ? <NoData level={Level.UNKNOWN} /> : <PopulatedData />
}
