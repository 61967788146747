import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {IncidentsWidgetState} from '../types/incidents-widget-state'
import {
    DEFAULT_INCIDENTS_WIDGET_STATE,
    defaultIncidentsOverview,
} from '../types/default-incidents-widget-state'
import {isEqual} from 'lodash'
import {LoggingOutAction} from '../../../../../../store/state/session-data/actions'
import LoadingState from '../../../../../../values/loading-state-enum'
import {
    IncidentCountsType,
    IncidentAssignmentsCounts,
    IncidentTrendsType,
    IncidentAvgPerformanceType,
    IncidentVesselsPerformanceType,
} from '../../../../../incidents-report/contexts/types/incidents-report-output'
import SessionActionType from '../../../../../../store/state/session-data/action-type'

export const incidentsWidgetReducer = produce(
    (draft: IncidentsWidgetState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_FILTER:
                draft.loadingFilterState = LoadingState.RequestingData
                break
            case ActionType.RECEIVE_FILTER:
                draft.loadingFilterState = LoadingState.Loaded
                if (!isEqual(draft.incidentWidgetFilter.locations, action.payload.locations)) {
                    draft.incidentWidgetFilter.locations = action.payload.locations
                }
                if (
                    !isEqual(
                        draft.incidentWidgetFilter.searchVesselTagTerm,
                        action.payload.searchVesselTagTerm,
                    )
                ) {
                    draft.incidentWidgetFilter.searchVesselTagTerm =
                        action.payload.searchVesselTagTerm
                }
                draft.incidentWidgetFilter.incidentWidgetOnDashboard =
                    action.payload.incidentWidgetOnDashboard
                draft.incidentWidgetFilter.periodWidgetOnDashboard = action.payload.filterPeriod
                break
            case ActionType.SET_INCIDENT_WIDGET_VALUE:
                draft.incidentWidgetFilter.incidentWidgetOnDashboard = action.payload
                break
            case ActionType.SET_DATE_FILTER_ON_DASHBOARD:
                draft.incidentWidgetFilter.periodWidgetOnDashboard = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_OVERVIEW:
                draft.loadingPopulatedDataState.incidentsDetails = LoadingState.Loaded
                if (!isEqual(draft.latestIncidentOverview, action.payload)) {
                    draft.latestIncidentOverview = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsCounts = LoadingState.Loaded

                if (!isEqual(draft.filteredDataCounts, action.payload)) {
                    draft.filteredDataCounts = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsAssignments = LoadingState.Loaded

                if (!isEqual(draft.filteredAssignementsCounts, action.payload)) {
                    draft.filteredAssignementsCounts = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsTrends = LoadingState.Loaded

                if (!isEqual(draft.incidentsTrends, action.payload)) {
                    draft.incidentsTrends = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsAvgPerformance = LoadingState.Loaded

                if (!isEqual(draft.incidentsAvgPerformance, action.payload)) {
                    draft.incidentsAvgPerformance = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION:
                draft.loadingPopulatedDataState.incidentsVesselsPerformance = LoadingState.Loaded

                if (!isEqual(draft.incidentsVesselsPerformance, action.payload)) {
                    draft.incidentsVesselsPerformance = action.payload
                }
                break

            case ActionType.SET_FILTER:
                draft.loadingPopulatedDataState.incidentsDetails = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsCounts = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAssignments = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsTrends = LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsAvgPerformance =
                    LoadingState.RequestingData
                draft.loadingPopulatedDataState.incidentsVesselsPerformance =
                    LoadingState.RequestingData
                draft.latestIncidentOverview = defaultIncidentsOverview()
                draft.filteredDataCounts = {} as IncidentCountsType
                draft.filteredAssignementsCounts = {} as IncidentAssignmentsCounts
                draft.incidentsTrends = new Array<IncidentTrendsType>()
                draft.incidentsAvgPerformance = new Array<IncidentAvgPerformanceType>()
                draft.incidentsVesselsPerformance = {} as IncidentVesselsPerformanceType
                break
            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.loadingFilterState = LoadingState.NotPopulated
                if (!isEqual(draft.incidentWidgetFilter.searchVesselTagTerm, action.payload)) {
                    draft.incidentWidgetFilter.searchVesselTagTerm = action.payload
                }
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_INCIDENTS_WIDGET_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
