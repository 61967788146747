import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'

export function CancelButton(): JSX.Element {
    const {closeEnableMonitoringModal} = usePagedUnknownAssetsBeta()

    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        closeEnableMonitoringModal()
    }
    return (
        <Styled.Button id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.Button>
    )
}
