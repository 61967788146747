import {useRef} from 'react'
import {useDispatch} from 'react-redux'
import {BoundsContext} from '../../../../../../components/bounds-context/bounds-context'
import {TriStateButtonState} from '../../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {ThreatValue} from '../../../../../../helpers/threatHelper'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {
    onAssetSort,
    toggleAllAssetsFilter,
    toggleValueThreshold,
} from '../../../../../../store/state/my-vessels-filters/action-creators'
import {Value, Values} from '../../../../../../store/state/my-vessels-filters/actions'
import {myVesselsFiltersSelector} from '../../../../../../store/state/my-vessels-filters/selectors'
import {AssetThresholds} from '../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {NodeValue, NodeValues} from '../../../../../../values/nodes/NodeData'
import * as CommonStyles from '../common/common.styled'
import {SortOrder} from '../common/sort-order/sort-order'
import {SortDirection, SortField} from '../common/sort-order/values'
import * as Styles from './asset-filters.styled'
import {AssetValueRow} from './asset-value-row'
import {HeadingRow} from './heading-row'
import {ViewAllRow} from './view-all-row'
import {AssetSearch} from '../vessel-overview/asset-search/asset-search'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'
import {logFilterByTypes} from '../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../../store/state/audit-log/state'

function determineViewAll(
    low: AssetThresholds,
    medium: AssetThresholds,
    high: AssetThresholds,
): TriStateButtonState {
    if (allSet(low) && allSet(medium) && allSet(high)) {
        return TriStateButtonState.FULLY_SELECTED
    }

    if (noneSet(low) && noneSet(medium) && noneSet(high)) {
        return TriStateButtonState.NOT_SELECTED
    }

    return TriStateButtonState.PARTIAL_SELECTION
}

function allSet(value: AssetThresholds) {
    return value.low && value.medium && value.high
}

function noneSet(value: AssetThresholds) {
    return !value.low && !value.medium && !value.high
}

export function AssetFilters(): JSX.Element {
    const dispatch = useDispatch()
    const layoutRef = useRef(null)
    const filterValues = useTypedSelector(myVesselsFiltersSelector)

    const sortOrder = filterValues.assetSort
    const sortableFields: SortField[] = []
    sortableFields.push({key: 'asset-value', value: 'Asset Value'})
    sortableFields.push({key: 'threat-score', value: 'Threat Score'})
    sortableFields.push({key: 'newest-event', value: 'Newest Event'})

    function sortOrderVariableChanged(key: string): void {
        dispatch(onAssetSort(key, sortOrder.direction))
    }

    function sortOrderDirectionChanged(direction: SortDirection): void {
        dispatch(onAssetSort(sortOrder.field, direction))
    }

    const viewAll = determineViewAll(filterValues.LOW, filterValues.MEDIUM, filterValues.HIGH)

    function viewAllChanged(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllAssetsFilter(false))
            dispatch(
                logFilterByTypes(
                    'View all assets',
                    `${newState}`,
                    false,
                    AuditLogPageType.MY_VESSELS,
                ),
            )
        } else if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllAssetsFilter(true))
            dispatch(
                logFilterByTypes(
                    'View all assets',
                    `${newState}`,
                    true,
                    AuditLogPageType.MY_VESSELS,
                ),
            )
        }
    }

    function convertNodeValue(value: NodeValues): Value {
        switch (value) {
            case NodeValues.HIGH:
                return Values.HIGH
            case NodeValues.MEDIUM:
                return Values.MEDIUM
            case NodeValues.LOW:
            default:
                return Values.LOW
        }
    }

    function checkboxChanged(value: NodeValue, threatValue: ThreatValue, checked: boolean): void {
        dispatch(toggleValueThreshold(convertNodeValue(value), checked, threatValue))
        dispatch(
            logFilterByTypes(
                'assetFilterClicked',
                `${value}:${threatValue}`,
                checked,
                AuditLogPageType.MY_VESSELS,
            ),
        )
    }

    return (
        <BoundsContext.Provider value={{ref: layoutRef}}>
            <CommonStyles.Section id={`${PageType.MY_VESSELS}_filter-bar_asset-filters`}>
                <CommonStyles.SectionHeader>
                    <CommonStyles.SectionTitle>Asset Filters</CommonStyles.SectionTitle>
                    <SortOrder
                        id="asset-sort"
                        sortableFields={sortableFields}
                        sortOrder={sortOrder}
                        groupName="assetSortOrder"
                        sortOrderVariableChanged={sortOrderVariableChanged}
                        sortOrderDirectionChanged={sortOrderDirectionChanged}
                    />
                </CommonStyles.SectionHeader>
                <CommonStyles.SectionContent>
                    <AssetSearch />
                    <Styles.Grid>
                        <HeadingRow />
                        <ViewAllRow state={viewAll} viewAllChanged={viewAllChanged} />
                        <AssetValueRow
                            nodeValue={NodeValues.HIGH}
                            thresholdsSelected={filterValues.HIGH}
                            onChange={checkboxChanged}
                        />
                        <AssetValueRow
                            nodeValue={NodeValues.MEDIUM}
                            thresholdsSelected={filterValues.MEDIUM}
                            onChange={checkboxChanged}
                        />
                        <AssetValueRow
                            nodeValue={NodeValues.LOW}
                            thresholdsSelected={filterValues.LOW}
                            onChange={checkboxChanged}
                        />
                    </Styles.Grid>
                </CommonStyles.SectionContent>
            </CommonStyles.Section>
        </BoundsContext.Provider>
    )
}
