import * as Styled from './activity-section.styled'
import {DataCell} from '../shared/data-cell'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {UsbDeviceDetailsOutputModel} from '../../../reselector/usb-device-details.model'
import {StringUtils} from '../../../../../utils/Utils'

export function PurposeContents(): JSX.Element {
    const {usbDetailsOutput} = usePagedUSBDevices()

    return (
        <Styled.DetailsAreaContent id="usb-devices-details-modal_panel_PurposeContents_DetailsAreaContent">
            <DataCell label="Purpose for the device:">
                <span style={{fontStyle: 'italic'}}>{`"${getPurpose(usbDetailsOutput)}"`}</span>
            </DataCell>
            <DataCell label="Purpose submitted by:">{usbDetailsOutput.purposeSubmittedBy}</DataCell>
            <DataCell label="Purpose updated on:">{usbDetailsOutput.purposeSubmittedWhen}</DataCell>
        </Styled.DetailsAreaContent>
    )
}

function getPurpose(usbDetailsOutput: UsbDeviceDetailsOutputModel): string {
    const {purpose} = usbDetailsOutput
    return !StringUtils.validString(purpose) ? 'No purpose set' : purpose
}
