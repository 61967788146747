import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'

export function getBorder(status: string, theme: CyberOwlTheme): string {
    switch (status) {
        case 'NEW':
            return `thick solid ${theme.incidents.dataRow.statusBar.new}`
        case 'OPEN':
            return `thick solid ${theme.incidents.dataRow.statusBar.open}`
        case 'CLOSED':
        case 'CLOSED_FALSE_POSITIVE':
        case 'CLOSED_MONITOR':
            return `thick solid ${theme.incidents.dataRow.statusBar.closed}`
        case 'DRAFT':
            return `thick solid ${theme.incidents.dataRow.statusBar.draft}`
        default:
            return `thick solid inherit`
    }
}

interface IncidentDetailsProps {
    incidentStatus: string
}
export const IncidentDetails = styled.div<IncidentDetailsProps>`
    color: ${(props) => props.theme.incidents.dataRow.text};
    padding: ${spacing(1)} ${spacing(2)} ${spacing(2)} ${spacing(2)};
`
