import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'
import * as Styles from './save-button.styled'

export function SaveButton(): JSX.Element {
    const {
        updateIncidentPolicyDetails,
        activeIncidentPolicyData,
        isValidIncidentReminder,
        isValidUpdatedIncident,
    } = useIncidentsPolicy()

    return (
        <Styles.ButtonWrapper>
            <Styles.Button
                id="save-button-incident-policy"
                onClick={(e) => {
                    e.preventDefault()
                    updateIncidentPolicyDetails(activeIncidentPolicyData.identity)
                }}
                isChanged={isValidUpdatedIncident}
            >
                Save
            </Styles.Button>
            {!isValidIncidentReminder && (
                <Styles.WarningMessage>
                    To enable incident reminders, <br />
                    select a duration and a user to escalate to
                </Styles.WarningMessage>
            )}
        </Styles.ButtonWrapper>
    )
}
