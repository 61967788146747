import {USBDevicesSortType} from '../../../../../store/state/usb-inventory-filter/state'
import {SortableColumn} from './sortable-column'
import {TemporaryColumn} from './temporary-column'

export function TableHeader(): JSX.Element {
    return (
        <>
            <TemporaryColumn gridColumn={2} text="Status" centered />
            <SortableColumn
                gridColumn={3}
                text="First Detected"
                colName={USBDevicesSortType.TIMESTAMP}
            />
            <SortableColumn
                gridColumn={4}
                text="Last Active"
                colName={USBDevicesSortType.LATEST_TIMESTAMP}
            />
            <SortableColumn gridColumn={5} text="Vendor" colName={USBDevicesSortType.VENDOR} />
            <SortableColumn
                gridColumn={6}
                text="Product Name"
                colName={USBDevicesSortType.PRODUCT_NAME}
            />
            <SortableColumn gridColumn={7} text="Type" colName={USBDevicesSortType.TYPE} />
            <SortableColumn
                gridColumn={8}
                text="Volume labels"
                colName={USBDevicesSortType.DEVICE_ID}
            />
            <TemporaryColumn gridColumn={9} text="Connections" centered />
            <TemporaryColumn gridColumn={10} text="Purpose" />
        </>
    )
}
