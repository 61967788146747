import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)} ${spacing(2)};
    margin: 0;
    width: 100%;
`
export const DetailsAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
    border-bottom: 2px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
`

export const DetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: 180px auto;
    gap: ${spacing(1)};
`
export const DetailedAreaWrapper = styled(DetailsAreaWrapper)`
    border-bottom: none;
`
