import {Loading} from './components/loading'
import {DataLoaded} from './components/data-loaded'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../store/state/users/state'
import {useEffect} from 'react'
import {useIncidentsWidget} from './contexts/use-incidents-widget'
import LoadingState from '../../../../values/loading-state-enum'
import {QueryManager} from '../../../../websocket/Queries'
interface Props {
    queryManager: React.MutableRefObject<QueryManager>
    location: string | null
}
export function IncidentResponseWidget({queryManager, location}: Props): JSX.Element {
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const {fetchUserPrefsIfExistForDashboard, loadingFilter} = useIncidentsWidget()
    const formattedLocation = location != null ? location : undefined

    useEffect(() => {
        if (loadingFilter === LoadingState.NotPopulated) {
            fetchUserPrefsIfExistForDashboard(strictMode === StrictMode.DefaultVisibility)
        }
    }, [assignedTag, strictMode, location, loadingFilter, fetchUserPrefsIfExistForDashboard])

    const isLoading = loadingFilter !== LoadingState.Loaded

    return isLoading ? (
        <Loading />
    ) : (
        <DataLoaded location={formattedLocation} queryManager={queryManager} />
    )
}
