import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {AnalysisType} from '../../../../../store/state/metrics-filter-beta/state'
import {
    MetricsResponseForAssetStatus,
    MetricsResponseForBenchmark,
    MetricsResponseForTarget,
    MetricsResponseForTrends,
} from '../../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {NoData} from '../../no-data/no-data'
import {MetricsDetailsModal} from '../metrics-details-modal/metrics-details-modal'
import {TableDataGrid} from './_styled/metrics-table-format.styled'
import {TableRowAssetStatus} from './table-row-asset-status'
import {TableRowBenchmark} from './table-row-benchmark'
import {TableRowTarget} from './table-row-target'
import {TableRowTrends} from './table-row-trends'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'

export function MetricsTablePopulated(): JSX.Element {
    const {dataTablesRow, idForMetricDetailsModal, displayMetricsDetailsModal, displayFilterBar} =
        usePagedMetricsBeta()
    const {analysisTypes} = useTypedSelector(metricsBetaFilterSelector)
    const shouldDisplayMetricDetailsModal = idForMetricDetailsModal != null
    const dispatch = useDispatch()

    if (!dataTablesRow) {
        return <NoData text="There are no metrics matching your filtering criteria" />
    }
    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
            <TableDataGrid
                showReducedColumnNo={shouldDisplayMetricDetailsModal}
                id="table-data-grid-style"
            >
                {dataTablesRow?.map((values, index) => {
                    return (
                        <GetTableRowType
                            key={`table-row-${values.location}-${index}`}
                            analysisTypes={analysisTypes}
                            values={values}
                            index={index}
                            onClickFn={() => {
                                displayMetricsDetailsModal(values, index)
                                displayFilterBar(false)
                                dispatch(
                                    logDetailsOpen(
                                        AuditLogPageType.METRICS,
                                        AuditLogAuditType.DETAIL_PANEL_ACCESS,
                                        values.metric,
                                    ),
                                )
                            }}
                            activeRow={
                                values.metric === idForMetricDetailsModal?.metric &&
                                values.location === idForMetricDetailsModal.locationId &&
                                index === idForMetricDetailsModal.index
                            }
                        />
                    )
                })}
            </TableDataGrid>
            {shouldDisplayMetricDetailsModal && <MetricsDetailsModal />}
        </div>
    )
}
interface GetTableRowTypeProps {
    analysisTypes: AnalysisType
    index: number
    values:
        | MetricsResponseForAssetStatus
        | MetricsResponseForBenchmark
        | MetricsResponseForTarget
        | MetricsResponseForTrends
    onClickFn: () => void
    activeRow: boolean
}
function GetTableRowType({
    analysisTypes,
    index,
    values,
    onClickFn,
    activeRow,
}: GetTableRowTypeProps): JSX.Element {
    switch (analysisTypes) {
        case AnalysisType.SCORECARD:
            return (
                <TableRowAssetStatus
                    metrics={values as MetricsResponseForAssetStatus}
                    index={index}
                    onClickFn={onClickFn}
                    activeRow={activeRow}
                />
            )
        case AnalysisType.BENCHMARK:
            return (
                <TableRowBenchmark
                    metrics={values as MetricsResponseForBenchmark}
                    index={index}
                    onClickFn={onClickFn}
                    activeRow={activeRow}
                />
            )
        case AnalysisType.TARGET:
            return (
                <TableRowTarget
                    metrics={values as MetricsResponseForTarget}
                    index={index}
                    onClickFn={onClickFn}
                    activeRow={activeRow}
                />
            )
        case AnalysisType.TRENDS:
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return (
                <TableRowTrends
                    metrics={values as MetricsResponseForTrends}
                    index={index}
                    onClickFn={onClickFn}
                    activeRow={activeRow}
                />
            )
        default:
            return <div />
    }
}
