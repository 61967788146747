import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../table/_styled/unknown-assets-table-populated.styled'
import {UnknownAssetsCardsContent} from './unknown-assets-cards-content'

export function UnknownAssetsCardsFormat(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <ContentContainerMiddle
            width={width}
            scrollable
            backgroundColor={theme.unknownAssets.dataArea.background}
        >
            <UnknownAssetsCardsContent />
        </ContentContainerMiddle>
    )
}
