export enum RelativeTimestampType {
    RELATIVE_FROM,
    RELATIVE_TO,
}

export interface TimestampType {
    type: RelativeTimestampType
    key: string
    value: string
}

export const allAvailableRaisedTimestampOptions: TimestampType[] = [
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Raised today',
        value: '24 Hours',
    },
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Raised within last 7 days',
        value: '7 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Raised within the last 30 days',
        value: '30 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_TO,
        key: 'Raised before 7 days ago',
        value: '7 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_TO,
        key: 'Raised before 30 days ago',
        value: '30 Days',
    },
]

export const allAvailableUpdateTimestampOptions = [
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Updated today',
        value: '24 Hours',
    },
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Updated within last 7 days',
        value: '7 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_FROM,
        key: 'Updated within the last 30 days',
        value: '30 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_TO,
        key: 'Updated before 7 days ago',
        value: '7 Days',
    },
    {
        type: RelativeTimestampType.RELATIVE_TO,
        key: 'Updated before 30 days ago',
        value: '30 Days',
    },
]

export function getKeyFromTimestamp(
    option: 'created' | 'updated',
    type: RelativeTimestampType,
    value: string | null,
): string | null {
    return option === 'created'
        ? allAvailableRaisedTimestampOptions.find(
              (option) => option.type === type && option.value === value,
          )?.key ?? null
        : allAvailableUpdateTimestampOptions.find(
              (option) => option.type === type && option.value === value,
          )?.key ?? null
}
