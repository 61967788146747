import {
    getLocationDescription,
    LocationMap,
    Location,
    getLocationCode,
} from '../../../store/state/locations/state'
import {formatVesseltagsForUI, VesselTagsAPI} from '../../../store/state/vessel-tags/helper'
import {LocationTagArrayMapType, VesselTags} from '../../../store/state/vessel-tags/state'
import {GuidType, IpAddressType} from '../../../values/generic-type-defintions'
import {NodeValue} from '../../../values/nodes/NodeData'
import {
    formatNetworkLabel,
    getFirstDetectedDisplayValue,
    getHostnameDisplayValue,
    getIsolationStatusOfAsset,
    getMacAddressDisplayValue,
    getOccurredLastSeenDisplayValue,
} from '../context/helpers'
import {AssetDataType} from '../context/types/asset-data-type'
import {Anomaly} from '../context/types/formatted-anomaly'
import {
    IsolatedAssetsType,
    IsolationStatusFormatted,
    NetworkAssetMonitoringState,
    NetworkAssetState,
} from '../context/types/isolated-assets-type'
import {NetworkType} from '../context/types/network-type'
import {NetworkAssetData, getNetworkAssetName} from '../../../components/contact-soc-modal/helper'

export class NetworkAssetModalOutputModel {
    public readonly id: GuidType
    public readonly isolationStatus: IsolationStatusFormatted
    public readonly vessel: string
    public readonly vesselTags: VesselTags[]
    public readonly vlan: string | undefined
    public readonly macAddress: string | undefined
    public readonly firstDetected: string
    public readonly lastActive: string | undefined
    public readonly hostname: string
    public readonly locationCode: string
    public readonly state: NetworkAssetState
    public readonly value: NodeValue | undefined
    public readonly monitoringState: NetworkAssetMonitoringState | undefined
    public readonly ipAddress: IpAddressType | undefined
    public readonly vendor: string | undefined
    public readonly network: NetworkType
    public readonly deviceName: string

    public constructor(
        response: Anomaly,
        locationMap: LocationMap,
        isolatedAssetsStatus: IsolatedAssetsType[] | undefined,
        vesselTagsForLocations: LocationTagArrayMapType,
        assetData: AssetDataType,
    ) {
        const location: Location | undefined = locationMap.get(response.location)
        if (!location) {
            throw Error('Could not find location')
        }
        this.id = response.identity
        this.isolationStatus = getIsolationStatusOfAsset(isolatedAssetsStatus, response.identity)
        this.vessel = getLocationDescription(locationMap, response.location)
        this.vesselTags = sortedTagsForLocation(vesselTagsForLocations.get(location.location))
        this.vlan = response.vlan ?? 'Unknown'
        this.macAddress = getMacAddressDisplayValue(response.mainMacAddress)
        this.firstDetected = getFirstDetectedDisplayValue(response.firstSeen)
        this.lastActive = getOccurredLastSeenDisplayValue(response.lastSeen)
        this.hostname = getHostnameDisplayValue(response.name)
        this.locationCode = getLocationCode(locationMap, response.location)
        this.state = response.state ?? NetworkAssetState.UNKNOWN
        this.value = assetData.value
        this.monitoringState = assetData.state ?? NetworkAssetMonitoringState.UNKNOWN
        this.ipAddress = response.mainIpAddress ?? 'N/A'
        this.vendor = response.mainMacAddressVendor ?? 'Unknown'
        this.network = formatNetworkLabel(response.assetNetworkType)
        this.deviceName = getNetworkAssetName({
            identity: response.identity,
            name: response.name,
            mainMacAddress: response.mainMacAddress,
            mainMacAddressVendor: response.mainMacAddressVendor,
            mainIpAddress: response.mainIpAddress,
        } as NetworkAssetData)
    }
}

function sortedTagsForLocation(vesselTags: VesselTagsAPI[] | undefined): VesselTags[] {
    if (!vesselTags) {
        return []
    }
    return [...vesselTags]
        ?.map((element) => formatVesseltagsForUI(element))
        .sort((a: VesselTags, b: VesselTags) => a.name?.localeCompare(b.name))
}
