import * as Styled from './_styled/self-monitoring-engine-table.styled'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {TableRuleCell} from './table-rule-cell'
import {TableTimestampCell} from './table-timestamp-cell'
import {TableLocationCell} from './table-location-cell'

interface TableRowProps {
    selfMonitoringEngineEvent: SelfMonitoringEngineEvent
    index: number
}

export function TableRow({selfMonitoringEngineEvent, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-sme-row_${selfMonitoringEngineEvent.identity}`}
            />
            <TableRuleCell event={selfMonitoringEngineEvent} gridRow={gridRow} />
            <TableTimestampCell event={selfMonitoringEngineEvent} gridRow={gridRow} />
            <TableLocationCell event={selfMonitoringEngineEvent} gridRow={gridRow} />
        </>
    )
}
