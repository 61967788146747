import {IncidentHistoryOutputModel} from '../../../incidents-v3/models/history-record.model'
import {NoteOutputModel} from '../../../incidents-v3/models/note.model'

export type IncidentNotesMap = Map<number, NoteOutputModel>

export function buildIncidentNotesMap(history: IncidentHistoryOutputModel[]): IncidentNotesMap {
    const dataPageMap: IncidentNotesMap = new Map<number, NoteOutputModel>()
    const noteRecords = history.filter((record) => record.noteRecord != undefined)
    const sortedNotes = noteRecords
        .map((record) => record.noteRecord)
        .sort((a: NoteOutputModel, b: NoteOutputModel) => {
            return dateComparison(a.updatedWhen, b.updatedWhen, true)
        })
    sortedNotes.forEach((note, index) => {
        if (note) {
            dataPageMap.set(index, note)
        }
    })

    return dataPageMap
}

function dateComparison(aValue: string, bValue: string, descending: boolean): number {
    if (aValue < bValue) {
        return descending ? 1 : -1
    }

    if (aValue > bValue) {
        return descending ? -1 : 1
    }

    return 0
}
