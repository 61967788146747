import {useMemo} from 'react'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'
import {TableDataGrid} from './_styled/table-data-grid.styled'
import {TableRow} from './table-row'
import {NMEADetailsModal} from '../nmea-side-panel/nmea-details-modal'

export function NMEAInventoryTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataNmeaInventory, modalIdForNmeaDetails} = usePagedNmeaInventory()

    const displayNMEADetailsModal = useMemo(() => {
        return modalIdForNmeaDetails != null
    }, [modalIdForNmeaDetails])

    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
            <TableDataGrid width={width} id="table-data-grid-style">
                {dataNmeaInventory?.map((nmeaItem, index) => (
                    <TableRow
                        key={`${nmeaItem?.talkerId}-${nmeaItem?.sentenceId}-${nmeaItem.relayIp}`}
                        nmeaItem={nmeaItem}
                        index={index}
                    />
                ))}
            </TableDataGrid>
            {displayNMEADetailsModal && <NMEADetailsModal />}
        </div>
    )
}
