import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import AlertsPaging from './alerts-paging'
import {AlertsTable} from './table/alerts-table'
import {DataActions} from './data-actions'
import {RecordSet} from './record-set'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {AlertCards} from './cards/alert-cards'
import {fetchNetworkAnomalyMatrix} from '../../../../../store/state/network-anomaly-matrix/action-creators'
import {loadingStateSelector} from '../../../../../store/state/network-anomaly-matrix/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import LoadingState from '../../../../../values/loading-state-enum'
import {Footer} from '../../../../../components/table-view-components/footer.styled'

export function AlertsTab(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const matrixLoadingState = useTypedSelector(loadingStateSelector)

    useEffect(() => {
        if (matrixLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchNetworkAnomalyMatrix())
        }
    }, [dispatch, matrixLoadingState])

    return (
        <>
            <DataActions />
            {width >= 980 && <AlertsTable />}
            {width < 980 && <AlertCards />}
            <Footer width={width}>
                <AlertsPaging />
                <RecordSet />
            </Footer>
        </>
    )
}
