import moment from 'moment'
import {ReportsFilter} from '../../../../values/user-preferences/reports-filter'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: ReportsFilter
    tooltip?: boolean
}

function getRating(selectedRating: number): number {
    return Math.ceil(selectedRating / 25)
}

export function DetailFilterReports({criteria}: DetailFilterProps): JSX.Element {
    return (
        <DetailsWrapper>
            {criteria.selectedReportType && (
                <Details>• Report Type: {criteria.selectedReportType}</Details>
            )}
            {criteria.selectedRating && (
                <Details>• Compliance Rating: {getRating(criteria.selectedRating)}</Details>
            )}
            {criteria.selectedFromTimeRange && (
                <Details>
                    • From: {moment(criteria.selectedFromTimeRange).format('YYYY MMMM')}
                </Details>
            )}
            {criteria.selectedToTimeRange && (
                <Details>• To: {moment(criteria.selectedToTimeRange).format('YYYY MMMM')}</Details>
            )}
            {criteria.includeWholeFleetReports && <Details>• Whole Fleet Reports</Details>}
        </DetailsWrapper>
    )
}
