import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface EventProps {
    titleOnSameLine: boolean
    gridArea?: string
    lastElement?: boolean
}
export const Event = styled.div<EventProps>`
    flex: 1;
    display: flex;
    flex-direction: ${(props) => (props.titleOnSameLine ? 'row' : 'column')};
    grid-area: ${(props) => props.gridArea};
    padding: ${spacing(1)} 0 ${spacing(1)} 0;
    border-right: ${(props) =>
        props.lastElement ? '' : `1px solid ${props.theme.reports.dataRow.separator};`};
    width: 100%;
`

interface ContainerProps {
    titleOnTheSameLine: boolean
}
export const EventContainer = styled.div<ContainerProps>`
    max-width: ${(props) => (props.titleOnTheSameLine ? 'calc(100% - 50px)' : '100%')};
    flex: 1;
`
