import {GuidType} from '../../../../values/generic-type-defintions'
import {displayStatus, addAnnotation} from '../../../incidents-v3/data-helpers'
import * as Styled from './incident-status.styled'
import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'

interface IncidentStatusValueProps {
    incidentStatus: IncidentStatus
    id: GuidType
}

export function IncidentStatusValue({id, incidentStatus}: IncidentStatusValueProps): JSX.Element {
    return (
        <Styled.Square incidentStatus={incidentStatus} id={`status-${incidentStatus}-${id}`}>
            {displayStatus(incidentStatus)} <br />
            <Styled.StatusAnnotation id={`status-annotation-${incidentStatus}-${id}`}>
                {addAnnotation(incidentStatus)}
            </Styled.StatusAnnotation>
        </Styled.Square>
    )
}
