import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {formatNumber} from '../../../helpers/formatting'
import * as styled from './styling/record-set.styled'

interface RecordSetForPaginationProps {
    startingIndicator: number
    endIndicator: number
    totalNumberOfItems: number
}

export function RecordSetForPagination({
    startingIndicator,
    endIndicator,
    totalNumberOfItems,
}: RecordSetForPaginationProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <styled.RecordSet width={width}>
            Showing{' '}
            <styled.Value id="recordset-starting-value">
                {formatNumber(startingIndicator)}
            </styled.Value>{' '}
            to <styled.Value id="recordset-end-value">{formatNumber(endIndicator)}</styled.Value> of{' '}
            <styled.Value id="recordset-total-value">
                {formatNumber(totalNumberOfItems)}
            </styled.Value>
        </styled.RecordSet>
    )
}
