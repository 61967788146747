import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'

export const BREAK_POINT_CARD_REDUCED_TEXT = 750

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    grid-area: ${(props) => props.gridArea};
    margin-bottom: ${spacing(2)};
`

interface LabelProps {
    width: number
}

export const Label = styled.label<LabelProps>`
    ${smallFont()}
    padding-right: ${spacing(1)};
    width: 220px;
    margin: 0;
`

export const Children = styled.div`
    ${smallFont()}
    row-gap: 10px;
`

export const DeviceName = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-bottom: ${spacing(2)};
`
