import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './expand-panel-button.styled'
import {useUserManagement} from '../../../../context/use-user-management'
import {
    DetailExpandedType,
    UserManagementDetailType,
} from '../../../../context/type/user-management-state'

interface ExpandPanelButtonProps {
    headingRow: UserManagementDetailType
}

export function ExpandPanelButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    const {toggleDetailExpanded, detailExpanded} = useUserManagement()
    const isExpanded = getCurrentDetailExpanded(headingRow, detailExpanded)

    function setNewDetailExpanded() {
        const newDetailExpanded: DetailExpandedType = getNewDetailExpanded(
            headingRow,
            detailExpanded,
        )
        toggleDetailExpanded(newDetailExpanded)
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow>{headingRow}</Styles.DetailHeadingRow>
            <Styles.Button onClick={() => setNewDetailExpanded()}>
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewDetailExpanded = (
    toggledDetail: UserManagementDetailType,
    currentDetailExpanded: DetailExpandedType,
): DetailExpandedType => {
    switch (toggledDetail) {
        case UserManagementDetailType.UserDetails:
            return {
                ...currentDetailExpanded,
                detailsExpanded: !currentDetailExpanded.detailsExpanded,
            }
        case UserManagementDetailType.Roles:
            return {
                ...currentDetailExpanded,
                rolesExpanded: !currentDetailExpanded.rolesExpanded,
            }
        case UserManagementDetailType.Subscriptions:
            return {
                ...currentDetailExpanded,
                subscriptionsExpanded: !currentDetailExpanded.subscriptionsExpanded,
            }
        case UserManagementDetailType.SavedFiltersAlerts:
            return {
                ...currentDetailExpanded,
                savedFiltersAlertsExpanded: !currentDetailExpanded.savedFiltersAlertsExpanded,
            }
        case UserManagementDetailType.History:
            return {
                ...currentDetailExpanded,
                historyExpanded: !currentDetailExpanded.historyExpanded,
            }
        default:
            return currentDetailExpanded
    }
}

const getCurrentDetailExpanded = (
    toggledDetail: UserManagementDetailType,
    currentDetailExpanded: DetailExpandedType,
): boolean => {
    switch (toggledDetail) {
        case UserManagementDetailType.UserDetails:
            return currentDetailExpanded.detailsExpanded
        case UserManagementDetailType.Roles:
            return currentDetailExpanded.rolesExpanded
        case UserManagementDetailType.Subscriptions:
            return currentDetailExpanded.subscriptionsExpanded
        case UserManagementDetailType.SavedFiltersAlerts:
            return currentDetailExpanded.savedFiltersAlertsExpanded
        case UserManagementDetailType.History:
            return currentDetailExpanded.historyExpanded
        default:
            return false
    }
}
