import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(3)};
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    width: 100%;
`

export const SectionTitle = styled.h2`
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
`

interface DataRowProps {
    lastRow?: boolean
}
export const DataRow = styled.div<DataRowProps>`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin-bottom: ${(props) => (props.lastRow ? '0' : spacing(3))};
`

export const Label = styled.label`
    padding: 0;
    margin: 0;
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Value = styled.span`
    text-align: right;
    padding: 0;
    margin: 0 0 0 ${spacing(2)};
`
