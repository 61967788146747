import * as Styled from './enforcement-policy-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {NoDataModal} from '../no-data-modal'
import {DetailsSection} from './details-section/details-section'
import {PolicyName} from './policy-name/policy-name'
import {ButtonsArea} from './buttons-area/buttons-area'
import {useEnforcementPolicy} from '../../../../contexts/enforcement-policy/use-enforcement-policy'

export function EnforcementPolicyDetails(): JSX.Element {
    const {selectedEnforcementPolicyId} = useEnforcementPolicy()

    if (!selectedEnforcementPolicyId) {
        return <NoDataModal />
    }

    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader text="Enforcement policy" />
            <Styled.PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea />
            </Styled.PolicyNameWrapper>
            <DetailsSection />
        </Styled.Container>
    )
}
