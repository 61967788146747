import {useContext} from 'react'
import {PagedVesselsBetaContext} from './paged-vessels-beta-context'
import {UsePagedVesselsBetaResult} from './use-paged-vessels-beta-output'
import * as ActionCreators from './state/action-creators'
import {warn} from '../../../helpers/logging'
import {PossibleColumnsVesselBeta} from './types/paged-vessels-beta-state'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationMapSelector, locationsSelector} from '../../../store/state/locations/selectors'
import {nodesForLocationsSelector, nodesSelector} from '../../../store/state/nodes/selectors'
import {latestLocationThreatScoreSelector} from '../../../store/state/latest-location-threat-scores/selectors'
import {threatMeasuresSelector} from '../../../store/state/threat-measures/selectors'
import {LocationIdType} from '../../../store/state/locations/state'
import {VesselBetaTableDetailsModel} from './types/vessel-beta-table-details-model'
import {sfmScoresSelector} from '../../../store/state/sfm-scores/selectors'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {AssetThreatScoreOutputModel} from '../../my-vessels-v2/listing/reselectors/asset-threat-score.model'
import {TimestampFilterType} from './types/timestamp-filter'
import {orderFunc} from './context-data-helper'
import {
    FrameworkSummaryTargetResponse,
    buildFrameworkSummaryTarget,
} from './types/framework-summary-target-api'
import {
    FrameworkSummaryTrendResponse,
    buildFrameworkSummaryTrends,
} from './types/framework-summary-trends-api'
import {
    CurrentVesselScoreResponse,
    buildCurrentVesselScoreModel,
} from './types/current-vessel-score-model-api'
import {FrameworkScoreCardResponse, buildFrameworkScoreCard} from './types/framework-score-card-api'
import {
    TotalInventoryAllTypesResponse,
    buildTotalInventoryAllTypes,
} from './types/total-inventory-all-types-api'
import {
    FrameworkSummaryBenchmarkResponse,
    buildFrameworkSummaryBenchmark,
} from './types/framework-summary-benchmark-api'
import {OldestOpenIncidentResponse, buildOldestOpenIncident} from './types/oldest-open-incident-api'
import {
    MissingInvetoryAllTypesResponse,
    buildMissingInventoryAllTypes,
} from './types/missing-inventory-all-types-api'
import {
    MissingMonitoredAssetsResponse,
    buildMissingMonitoredAssetsInventoryAllTypes,
} from './types/missing-monitored-assets-inventory-api'
import {
    NumberOfDaysSinceLastDataResponse,
    buildNumberOfDaysSinceLastData,
} from './types/number-of-days-since-last-data-api'
import {buildTotalInventoryPreviousPeriodAllTypes} from './types/total-inventory-prev-period-api'
import {buildAssetsAtRiskModel} from './types/assets-at-risk-api'
import {ThunkDispatch} from 'redux-thunk'
import AppState from '../../../store/types/app-state'
import {Api} from '../../../api/Api'
import {Action} from '../../../store/state/vessels-beta-filter/actions'
import {vesselsBetaFilterSelector} from '../../../store/state/vessels-beta-filter/selectors'
import {filteredVesselIds} from './types/filtered-vessels-ids'
import {
    NumberOfIncidentsResponse,
    buildNumberOfIncidentsRaisedOrOpen,
} from './types/number-of-incidents-raised-and-open'
import {
    AverageTimeToResolveIncidentsResponse,
    buildAverageTimeInOpenIncidents,
} from './types/average-time-to-resolve-incidents'
import {buildNumberOfMonitoredAssetsInOpenInc} from './types/number-of-monitored-assets-in-open-incidents'
import {buildDeploymentSummary} from './types/build-deployment-summary-api'
import {VesselDeploymentStatus} from '../../vessel-management/contexts/type/deployment-status-types'
import {GuidType} from '../../../values/generic-type-defintions'

export function usePagedVesselsBeta(): UsePagedVesselsBetaResult {
    const {state, dispatch} = useContext(PagedVesselsBetaContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedVesselsBeta must be used within a PagedVesselsBetaContext')
    }
    const locations = useTypedSelector(locationMapSelector)
    const latestLocationThreatScores = useTypedSelector(latestLocationThreatScoreSelector)
    const threatMeasures = useTypedSelector(threatMeasuresSelector)
    const nodesForLocations = useTypedSelector(nodesForLocationsSelector)
    const sfmScores = useTypedSelector(sfmScoresSelector)
    const vesselFilter = useTypedSelector(vesselFilterSelector)
    const nodes = useTypedSelector(nodesSelector)
    const currentVesselBetaFilter = useTypedSelector(vesselsBetaFilterSelector)
    const allLocations = useTypedSelector(locationsSelector)

    const filteredLocationsIds = filteredVesselIds(allLocations, vesselFilter)

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        if (state.highlightedFilerValue && !displayFilterBar) {
            dispatch(ActionCreators.toggleHighlightedValue(false))
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    async function setActiveColumns(
        selectedColumnType: PossibleColumnsVesselBeta,
        selectedColumnNewValue: boolean,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestTablePageData())
        dispatch(ActionCreators.toggleHighlightedValue(false))
        ActionCreators.toggleSelectedColumns(
            selectedColumnType,
            selectedColumnNewValue,
            thunkDispatch,
        )

        const {updatedTableDetailsMap} = await getDataFromSelectedColumn(
            selectedColumnType,
            selectedColumnNewValue,
            currentVesselBetaFilter.analysisPeriod,
        )
        dispatch(ActionCreators.receivedRequestedTablePageData(updatedTableDetailsMap))
    }

    function toggleHighlightedValue(highlightedFilerValue: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleHighlightedValue(highlightedFilerValue))
        !state.showFilterBar && dispatch(ActionCreators.displayFilterBar(true))
    }

    async function loadDataFromUserPrefs(
        selectedColumns: PossibleColumnsVesselBeta[],
        analysisPeriod: TimestampFilterType,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        if (!selectedColumns || selectedColumns.length === 0) {
            dispatch(ActionCreators.receivedRequestedTablePageData(state.tableVesselsDataMap))
        }

        const promises = selectedColumns.map((columnType) => {
            return getDataFromSelectedColumn(columnType, true, analysisPeriod)
        })

        const allResults = await Promise.all(promises)

        const updatedTableDetailsMap = allResults.reduce(
            (acc, result) => new Map([...acc, ...result.updatedTableDetailsMap]),
            new Map(),
        )
        dispatch(ActionCreators.receivedRequestedTablePageData(updatedTableDetailsMap))
    }

    async function setAnalysisPeriod(
        analysisPeriod: TimestampFilterType,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const columnsToRefetch: PossibleColumnsVesselBeta[] = [
            PossibleColumnsVesselBeta.FrameworkProtection,
            PossibleColumnsVesselBeta.FrameworkMaintenance,
            PossibleColumnsVesselBeta.FrameworkBehaviour,
            PossibleColumnsVesselBeta.FrameworkSummaryScorecard,
            PossibleColumnsVesselBeta.TotalInventoryAllTypes,
            PossibleColumnsVesselBeta.UntrustedInventoryAllTypes,
            PossibleColumnsVesselBeta.TrustedInventoryAllTypes,
            PossibleColumnsVesselBeta.NewInventoryAllTypes,
            PossibleColumnsVesselBeta.AssetsAtRisk,
            PossibleColumnsVesselBeta.AssetsAtRiskHighValue,
            PossibleColumnsVesselBeta.FrameworkSummaryBenchmark,
            PossibleColumnsVesselBeta.FrameworkSummaryTarget,
            PossibleColumnsVesselBeta.FrameworkSummaryTrend,
            PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork,
            PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork,
            PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets,
            PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod,
            PossibleColumnsVesselBeta.NumberOfIncidentsRaised,
            PossibleColumnsVesselBeta.NumberOfIncidentsOpen,
            PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod,
        ]

        const columnsToReFetch = currentVesselBetaFilter.selectedColumns.filter((columnType) =>
            columnsToRefetch.includes(columnType),
        )

        dispatch(ActionCreators.requestTablePageData())

        let updatedTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel> =
            new Map()

        const promises = columnsToReFetch.map(async (columnType) => {
            const {updatedTableDetailsMap: columnMap} = await getDataFromSelectedColumn(
                columnType,
                true,
                analysisPeriod,
                updatedTableDetailsMap,
            )
            updatedTableDetailsMap = new Map([...updatedTableDetailsMap, ...columnMap])
        })

        await Promise.all(promises)

        const mergedTableDetailsMap = new Map([
            ...state.tableVesselsDataMap,
            ...updatedTableDetailsMap,
        ])

        dispatch(ActionCreators.receivedRequestedTablePageData(mergedTableDetailsMap))
        ActionCreators.setAnalysisPeriod(analysisPeriod, thunkDispatch)
    }

    function fetchWhenNewValueFalse(
        selectedColumnType: PossibleColumnsVesselBeta,
        updatedTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>,
    ) {
        updatedTableDetailsMap.delete(selectedColumnType)
    }

    function fetchDataWhenColumnFound(
        selectedColumnType: PossibleColumnsVesselBeta,
        updatedTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>,
        selectedColumnContent: unknown,
    ) {
        const updatedContent: VesselBetaTableDetailsModel = {
            selectedColumnName: selectedColumnType,
            selectedColumnContent: selectedColumnContent,
        }
        updatedTableDetailsMap.set(selectedColumnType, updatedContent)
    }

    function fetchDataWhenColumnNotFound(
        selectedColumnType: PossibleColumnsVesselBeta,
        updatedTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>,
        selectedColumnContent: unknown,
    ) {
        const updatedContent: VesselBetaTableDetailsModel = {
            selectedColumnName: selectedColumnType,
            selectedColumnContent: selectedColumnContent,
        }
        updatedTableDetailsMap.set(selectedColumnType, updatedContent)
    }

    async function getDataFromSelectedColumn(
        selectedColumnType: PossibleColumnsVesselBeta,
        selectedColumnNewValue: boolean,
        analysisPeriod: TimestampFilterType,
        newCleanMapToUpdate?: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>,
    ): Promise<{
        updatedTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>
    }> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return {updatedTableDetailsMap: new Map()}
        }
        let existingTableDetailsMap: Map<PossibleColumnsVesselBeta, VesselBetaTableDetailsModel>

        if (newCleanMapToUpdate) {
            existingTableDetailsMap = new Map(newCleanMapToUpdate)
        } else {
            existingTableDetailsMap = state.tableVesselsDataMap || new Map()
        }

        const updatedTableDetailsMap = new Map(existingTableDetailsMap)

        if (!selectedColumnNewValue) {
            fetchWhenNewValueFalse(selectedColumnType, updatedTableDetailsMap)
        } else {
            const selectedColumnContent = await columnContentFunctions(
                selectedColumnType,
                filteredLocationsIds,
                analysisPeriod,
            )
            if (existingTableDetailsMap.has(selectedColumnType)) {
                fetchDataWhenColumnFound(
                    selectedColumnType,
                    updatedTableDetailsMap,
                    selectedColumnContent,
                )
            } else {
                fetchDataWhenColumnNotFound(
                    selectedColumnType,
                    updatedTableDetailsMap,
                    selectedColumnContent,
                )
            }
        }
        return {updatedTableDetailsMap}
    }

    async function columnContentFunctions(
        selectedColumnType: PossibleColumnsVesselBeta,
        locationIds: string[],
        analysisPeriod: TimestampFilterType,
    ): Promise<unknown> {
        switch (selectedColumnType) {
            case PossibleColumnsVesselBeta.CurrentVesselScore:
                return buildCurrentVesselScoreModel(
                    locationIds,
                    locations,
                    latestLocationThreatScores,
                    threatMeasures,
                ) as CurrentVesselScoreResponse[]
            case PossibleColumnsVesselBeta.AssetsAtRisk:
            case PossibleColumnsVesselBeta.AssetsAtRiskHighValue:
                return buildAssetsAtRiskModel(
                    locationIds,
                    analysisPeriod,
                    nodesForLocations,
                    vesselFilter,
                    sfmScores,
                    threatMeasures,
                    nodes,
                ) as Map<LocationIdType, AssetThreatScoreOutputModel[]>
            case PossibleColumnsVesselBeta.FrameworkProtection:
            case PossibleColumnsVesselBeta.FrameworkMaintenance:
            case PossibleColumnsVesselBeta.FrameworkBehaviour:
            case PossibleColumnsVesselBeta.FrameworkSummaryScorecard:
                return (await buildFrameworkScoreCard(
                    locationIds,
                    analysisPeriod,
                )) as FrameworkScoreCardResponse[]
            case PossibleColumnsVesselBeta.TotalInventoryAllTypes:
                return (await buildTotalInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                )) as TotalInventoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.UntrustedInventoryAllTypes:
                return (await buildTotalInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                    'untrusted',
                )) as TotalInventoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.TrustedInventoryAllTypes:
                return (await buildTotalInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                    'trusted',
                )) as TotalInventoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.NewInventoryAllTypes:
                return (await buildTotalInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                    'new',
                )) as TotalInventoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.FrameworkSummaryBenchmark:
                return (await buildFrameworkSummaryBenchmark(
                    locationIds,
                    analysisPeriod,
                )) as FrameworkSummaryBenchmarkResponse[]
            case PossibleColumnsVesselBeta.OldestOpenIncident:
                return (await buildOldestOpenIncident(locationIds)) as OldestOpenIncidentResponse[]
            case PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork:
                return (await buildMissingInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                    'business',
                )) as MissingInvetoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork:
                return (await buildMissingInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                    'ot',
                )) as MissingInvetoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets:
                return (await buildMissingMonitoredAssetsInventoryAllTypes(
                    locationIds,
                    analysisPeriod,
                )) as MissingMonitoredAssetsResponse[]
            case PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod:
                return (await buildTotalInventoryPreviousPeriodAllTypes(
                    locationIds,
                    analysisPeriod,
                )) as TotalInventoryAllTypesResponse[]
            case PossibleColumnsVesselBeta.NumberOfDaysSinceLastData:
                return (await buildNumberOfDaysSinceLastData(
                    'all',
                )) as NumberOfDaysSinceLastDataResponse[]
            case PossibleColumnsVesselBeta.NumberOfDaysSinceLastNetworkData:
                return (await buildNumberOfDaysSinceLastData(
                    'network',
                )) as NumberOfDaysSinceLastDataResponse[]
            case PossibleColumnsVesselBeta.FrameworkSummaryTarget:
                return (await buildFrameworkSummaryTarget(
                    locationIds,
                    analysisPeriod,
                )) as FrameworkSummaryTargetResponse[]
            case PossibleColumnsVesselBeta.FrameworkSummaryTrend:
                return (await buildFrameworkSummaryTrends(
                    locationIds,
                    analysisPeriod,
                )) as FrameworkSummaryTrendResponse[]
            case PossibleColumnsVesselBeta.NumberOfIncidentsRaised:
                return (await buildNumberOfIncidentsRaisedOrOpen(
                    locationIds,
                    'incidents raised',
                    analysisPeriod,
                )) as NumberOfIncidentsResponse[]
            case PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod:
                return (await buildNumberOfIncidentsRaisedOrOpen(
                    locationIds,
                    'incidents raised prev per',
                    analysisPeriod,
                )) as NumberOfIncidentsResponse[]
            case PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents:
                return (await buildNumberOfIncidentsRaisedOrOpen(
                    locationIds,
                    'incidents current open',
                    analysisPeriod,
                )) as NumberOfIncidentsResponse[]
            case PossibleColumnsVesselBeta.NumberOfIncidentsOpen:
                return (await buildNumberOfIncidentsRaisedOrOpen(
                    locationIds,
                    'incidents open',
                    analysisPeriod,
                )) as NumberOfIncidentsResponse[]
            case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidentsAverageTime:
                return (await buildAverageTimeInOpenIncidents()) as AverageTimeToResolveIncidentsResponse[]
            case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents:
                return (await buildNumberOfMonitoredAssetsInOpenInc(
                    locationIds,
                )) as NumberOfIncidentsResponse[]
            case PossibleColumnsVesselBeta.DeploymentSummary:
                const locationsVessels: Record<GuidType, boolean> = {}
                for (const location of allLocations) {
                    locationsVessels[location.location] = location.vesselDashboard ?? false
                }
                return (await buildDeploymentSummary(locationsVessels)) as VesselDeploymentStatus
            default:
                return 'No data to display yet'
        }
    }

    const sortedVesselsIds = orderFunc(
        currentVesselBetaFilter.sortColumn,
        state.tableVesselsDataMap,
        filteredLocationsIds,
    )

    return {
        showFilterBar: state.showFilterBar,
        displayFilterBar,
        setActiveColumns,
        numberOfColumns: currentVesselBetaFilter.selectedColumns?.length ?? 0,
        highlightedFilerValue: state.highlightedFilerValue,
        toggleHighlightedValue,
        filteredLocationsIds: sortedVesselsIds,
        loadingFilteredDataState: state.loadingFilteredDataState,
        tableVesselsDataMap: state.tableVesselsDataMap,
        setAnalysisPeriod,
        loadDataFromUserPrefs,
    }
}
