import styled from 'styled-components'
import {ThreatValue} from '../../../../../../helpers/threatHelper'
import {getColorFromThreat} from '../../../../../../theme/helpers/node-title'

interface ValueProps {
    threatLevel: ThreatValue | undefined
}

export const Value = styled.span<ValueProps>`
    color: ${(props) => getColorFromThreat(props.theme, props.threatLevel)};
    line-height: 17px;
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
