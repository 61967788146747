import {FormattedAge} from './formatted-age.class'

function getFormattedMinutes(formattedAge: FormattedAge): string {
    return formattedAge.minutes === 0 ? '' : `${formattedAge.minutes}m`
}

function getFormattedWeeks(formattedAge: FormattedAge): string {
    return formattedAge.weeks === 0 ? '' : `${formattedAge.weeks}w`
}

function getFormattedDays(formattedAge: FormattedAge): string {
    return formattedAge.days === 0 ? '' : `${formattedAge.days}d`
}

function getFormattedHours(formattedAge: FormattedAge): string {
    return formattedAge.hours === 0 ? '' : `${formattedAge.hours}h`
}

export function formatAverageAgeIntoReadableTime(
    formattedAverageAge: FormattedAge | undefined,
): string {
    if (!formattedAverageAge) {
        return 'Unknown'
    }

    if (formattedAverageAge.weeks >= 1) {
        return formattedAverageAge.days >= 1
            ? `${getFormattedWeeks(formattedAverageAge)} ${getFormattedDays(
                  formattedAverageAge,
              )}`.trim()
            : `${getFormattedWeeks(formattedAverageAge)} ${getFormattedHours(
                  formattedAverageAge,
              )}`.trim()
    }

    if (formattedAverageAge.days >= 1) {
        return formattedAverageAge.hours >= 1
            ? `${getFormattedDays(formattedAverageAge)} ${getFormattedHours(
                  formattedAverageAge,
              )}`.trim()
            : `${getFormattedDays(formattedAverageAge)} ${getFormattedMinutes(
                  formattedAverageAge,
              )}`.trim()
    }

    if (formattedAverageAge.hours >= 1) {
        return `${getFormattedHours(formattedAverageAge)} ${getFormattedMinutes(
            formattedAverageAge,
        )}`.trim()
    }

    if (formattedAverageAge.minutes >= 1) {
        return `${getFormattedMinutes(formattedAverageAge)}`.trim()
    }

    return '0'
}
