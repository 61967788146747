import {TableDataCell, TitleText} from './_styled/table-data-cell.styled'
import {TableDataRow} from './_styled/table-data-row.styled'
import {SoftwareInventoryResponse} from '../../contexts/types/software-inventory-response'
import {getFormattedSoftwareInventoryData, getImageForSoftwareState} from '../data-helpers'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'

interface TableRowProps {
    software: SoftwareInventoryResponse
    index: number
    onClickFn?: () => void
}

export function TableRow({software, index, onClickFn}: TableRowProps): JSX.Element | null {
    const gridRow = index + 1
    const {modalIdForSoftwareDetails} = usePagedSoftwareInventory()
    const formattedSoftwareData = getFormattedSoftwareInventoryData(software)
    const activeSoftware = formattedSoftwareData.identity === modalIdForSoftwareDetails

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                id={`data-row_${formattedSoftwareData.identity}`}
                onClick={onClickFn}
                activeSoftware={activeSoftware}
            />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                onClick={onClickFn}
                id={`software-status-value_${formattedSoftwareData.identity}`}
            >
                <img src={getImageForSoftwareState(formattedSoftwareData.status)} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                onClick={onClickFn}
                id={`firstDetected-value_${formattedSoftwareData.identity}`}
            >
                {formattedSoftwareData.firstSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                onClick={onClickFn}
                id={`lastActive-value_${formattedSoftwareData.identity}`}
            >
                {formattedSoftwareData.lastSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                onClick={onClickFn}
                id={`vendor-value_${formattedSoftwareData.identity}`}
            >
                {formattedSoftwareData.companyName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                onClick={onClickFn}
                id={`productName-value_${formattedSoftwareData.identity}`}
            >
                {formattedSoftwareData.productName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                onClick={onClickFn}
                id={`totalNumberOfHostsPerItem-value_${formattedSoftwareData.identity}`}
                centered
            >
                <TitleText>{formattedSoftwareData.totalNumberOfHostsPerItem}</TitleText>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                onClick={onClickFn}
                id={`totalNumberOfVesselsPerItem-value_${formattedSoftwareData.identity}`}
                centered
            >
                <TitleText>{formattedSoftwareData.totalNumberOfVesselsPerItem}</TitleText>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={9}
                onClick={onClickFn}
                id={`newest_active_version-value_${formattedSoftwareData.identity}`}
                centered
            >
                {formattedSoftwareData.latestVersion}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={10}
                onClick={onClickFn}
                id={`oldest_active_version-value_${formattedSoftwareData.identity}`}
                centered
            >
                {formattedSoftwareData.oldestVersion}
            </TableDataCell>
        </>
    )
}
