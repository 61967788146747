import {formatDate} from '../../../../../../helpers/formatting'
import {CustomerReportsNotes} from '../../../../../../values/customer-reports'
import {NoteCard, NoteDetail, When, Who, Text} from './note.styled'
interface NoteProps {
    note: CustomerReportsNotes
    card?: boolean | undefined
}

function getLines(text: string | undefined | null): string[] {
    if (text == undefined || text == null) {
        return ['Unknown']
    }

    return text.split('\n')
}

export function Note({note, card}: NoteProps): JSX.Element {
    return (
        <NoteCard id={`${note.identity}-NoteCard`}>
            <NoteDetail card={card} id={`${note.identity}-NoteDetail`}>
                <When card={card} id={`${note.identity}-createdWhen`}>
                    {note?.createdWhen ? formatDate(note?.createdWhen) : 'Unknown'}
                </When>
                <Who card={card} id={`${note.identity}-createdBy`}>
                    {note?.createdBy ?? 'Unknown'}
                </Who>
            </NoteDetail>
            <Text card={card} id={`${note.identity}-Text`}>
                {getLines(note?.note).map((line, index) => (
                    <div key={index} id={`${note.identity}-line-${index}`}>
                        {line}
                    </div>
                ))}
            </Text>
        </NoteCard>
    )
}
