import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {enfocementPolicyReducer} from './state/reducer'
import {DEFAULT_ENFORCEMENT_POLICY_STATE} from './type/default-enforcement-policy-state'
import {EnforcementPolicyState} from './type/enforcement-policy-state'
import {EnforcementPolicyContext} from './enforcement-policy-context'

type EnforcementPolicyProviderProps = {
    children: ReactNode | ReactNode[]
}

export function EnforcementPolicyProvider({children}: EnforcementPolicyProviderProps): JSX.Element {
    const initialState: EnforcementPolicyState = cloneDeep(DEFAULT_ENFORCEMENT_POLICY_STATE)

    const [state, dispatch] = useReducer(enfocementPolicyReducer, initialState)

    return (
        <EnforcementPolicyContext.Provider value={{state, dispatch}}>
            {children}
        </EnforcementPolicyContext.Provider>
    )
}
