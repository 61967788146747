import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

function getTooltipPosition(width: number): string {
    if (width > 600) {
        return 'left: 190px; bottom: 30px;'
    }
    if (width > 500) {
        return 'left: 190px; bottom: 60px;'
    }
    return 'left: 80px; bottom: 80px;'
}

interface WarningMessageContainerProps {
    width: number
}
export const WarningMessageContainer = styled.div<WarningMessageContainerProps>`
    display: flex;
    position: absolute;
    ${(props) => getTooltipPosition(props.width)}
    width: 150px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    padding: ${spacing(1)};
    z-index: 1;
    pointer-events: none;
    white-space: break-spaces;
`

export const WarningMessageContainerTable = styled.div<WarningMessageContainerProps>`
    display: flex;
    position: absolute;
    left: 70px;
    bottom: ${spacing(1)};
    width: 150px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    padding: ${spacing(1)};
    z-index: 1;
    pointer-events: none;
    white-space: break-spaces;
`

interface IconContentProps {
    active?: boolean
}
export const IconContent = styled.div<IconContentProps>`
    height: 100%;
    display: flex;
    column-gap: ${spacing(1)};
    align-items: flex-start;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
    position: relative;
`
