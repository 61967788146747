import styled from 'styled-components'
import {Colors} from '../../../theme/colours'
import {spacing} from '../../../theme/spacing'

export const Table = styled.table`
    width: 100%;
    font-size: 14px;
`

export const Cell = styled.td`
    padding: ${spacing(3)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
`
export const Header = styled.th`
    padding: ${spacing(3)};
    border-bottom: 10px solid ${Colors.grey6};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
    text-align: center;
`

export const Row = styled.tr`
    background-color: ${Colors.white};
    border-top: 8px solid ${Colors.grey6};
`

export const CellDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ActionContent = styled.div`
    display: flex;
    gap: ${spacing(4)};
    justify-content: center;
    align-items: center;
`

export const SystemContent = styled.div`
    display: flex;
    gap: ${spacing(2)};
    justify-content: start;
    align-items: center;
`

export const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
`
