import {TableContent} from './table-content'
import {TableHeader} from './table-header'

interface OtherVesselsListProps {
    code: string
}

export function OtherVesselsListTable({code}: OtherVesselsListProps): JSX.Element {
    return (
        <>
            <TableHeader />
            <TableContent code={code} />
        </>
    )
}
