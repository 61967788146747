import styled from 'styled-components'
import {extraSmallFont, mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const NoDataContent = styled.div`
    padding: ${spacing(2)};
    height: 500px;
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
`

export const NoDataContainer = styled.div`
    align-items: center;
    background: ${(props) => props.theme.myVessels.cards.background};
    width: 420px;
    height: 595px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

export const NoDataBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${spacing(4)};
    row-gap: ${spacing(4)};
    align-items: center;
`
export const NoDataMessage = styled.h2`
    ${extraSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.incidents.dataRow.errorModal};
`
export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;

    width: 110px;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
export const BottomContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    margin: 0;
    padding: ${spacing(2)};
    z-index: 1;
`

export const ButtonText = styled.span`
    margin-left: ${spacing(1)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal}
`
