import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {addQueryParam, hasQueryParam, removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../../../store/routerSelectors'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {displayModal} from '../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {isNoteCreationSelector} from '../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {INCIDENT_DETAILS} from '../../../data-helpers'
import {ButtonText, IndicatorDot} from '../../shared/row-actions/common/button-text.styled'
import {Button} from '../../shared/row-actions/common/button.styled'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'
import {MessageForCard} from '../../table/event/message-card'
import {useState} from 'react'

interface ToggleNotesButtonProps {
    id: GuidType
    numberOfNewItems: number
}

export function ToggleDetailsButton({id, numberOfNewItems}: ToggleNotesButtonProps): JSX.Element {
    const queryString = useTypedSelector(queryStringObjectSelector)
    const incidentModalId = queryString?.incidentDetails as GuidType
    const {closeIncidentDetailsModal, displayIncidentDetailsModal} = usePagedIncidents()
    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)
    const dispatch = useDispatch()
    const [isHovering, setIsHovering] = useState(false)
    const {user} = useTypedSelector(currentUserSelector)
    const {updateLastViewed} = usePagedIncidents()

    const isExpanded = id === incidentModalId

    const displayDot = numberOfNewItems > 0
    function onClickWrapper() {
        const timeOutId = setTimeout(() => {
            const shouldUpdate = hasQueryParam(INCIDENT_DETAILS, id)
            if (shouldUpdate) {
                updateLastViewed(id, user)
            }
        }, 5000)
        if (noteCreationStatus) {
            dispatch(displayModal())
            return () => clearTimeout(timeOutId)
        } else if (isExpanded) {
            removeQueryParam(INCIDENT_DETAILS)
            closeIncidentDetailsModal()
            return () => clearTimeout(timeOutId)
        } else {
            dispatch(
                logDetailsOpen(
                    AuditLogPageType.INCIDENTS,
                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                    id,
                ),
            )
            addQueryParam(INCIDENT_DETAILS, id)
            displayIncidentDetailsModal(id)
            return () => clearTimeout(timeOutId)
        }
    }
    return (
        <Button onClick={onClickWrapper} id={`expand-details-button_${id}`}>
            <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            <ButtonText>
                Incident details
                {displayDot && (
                    <IndicatorDot
                        onMouseOver={() => setIsHovering(true)}
                        onMouseOut={() => setIsHovering(false)}
                        id={`indicator-dot-${numberOfNewItems}-${id}}`}
                    >
                        {numberOfNewItems}
                    </IndicatorDot>
                )}
            </ButtonText>
            {isHovering && <MessageForCard />}
        </Button>
    )
}
