import styled, {css} from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {mediumFont, mediumSmallFont} from '../../../theme/font-styles'
import BACKGROUND_IMG from '../background-img.svg'

export const DragAndDropWrapper = styled.div<DragAndDropBoxProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${spacing(3)};
    padding: ${spacing(5)} ${spacing(2)};
    align-items: center;
    height: 230px;
    border: 2px dashed ${(props) => props.theme.colors.border.active};
    border-radius: 2px;
    background-image: url(${BACKGROUND_IMG});
    background-size: cover;
    background-repeat: no-repeat;
    ${(props) =>
        props.isDragOver &&
        css`
            opacity: 0.5;
            background-image: none;
        `}
`
interface DragAndDropBoxProps {
    isDragOver: boolean
}
export const DragAndDropBox = styled.div`
    padding: ${spacing(2)};
    flex: 1;
`
export const SectionTitle = styled.span`
    ${mediumFont()}
    margin: 0;
    padding: 0;
`
export const ImageHeader = styled.img`
    height: 30px;
    width: 26px;
`
export const SizeWarning = styled.span`
    ${mediumSmallFont()}
    margin: 0;
    padding: 0;
    color: #a7b0be;
`
