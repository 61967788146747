import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {User} from '../../../../store/state/users/state'
import {useUserManagement} from '../../context/use-user-management'
import {TableHeader} from './table-header'
import {TableRow} from './table-row'
import {UserDetailsModal} from './user-details-modal/user-details-modal'
import * as Styled from './_styled/user-management-list.styled'

interface UserListTableProps {
    users: User[]
}

export function UserListTable({users}: UserListTableProps): JSX.Element {
    const {width} = useDimensions()
    const {activeUserId} = useUserManagement()
    const displayUserDetailsModal = activeUserId != null

    return (
        <div style={{minHeight: '200px'}}>
            <TableHeader />
            <Styled.ContentContainer width={width}>
                <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
                    <Styled.TableDataGrid width={width} showDetailsBtn={!displayUserDetailsModal}>
                        {users.map((user, index) => (
                            <TableRow key={user.user} user={user} index={index} />
                        ))}
                    </Styled.TableDataGrid>
                    {displayUserDetailsModal && <UserDetailsModal />}
                </div>
            </Styled.ContentContainer>
        </div>
    )
}
