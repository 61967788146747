import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const DetailsExpandedSection = styled.div`
    position: relative;
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(1)} ${spacing(4)};
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    background-color: ${(props) => props.theme.unknownAssets.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

export const PolicyNameWrapper = styled.div`
    padding: 0 ${spacing(3)};
    width: 100%;
`
