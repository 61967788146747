import {SortColumnType, TagsComponentSortType} from './contexts/types/tags-component-filter'
import {
    LocationTagsSummaryMapType,
    LocationTagsSummaryReduxState,
} from '../../../../store/state/location-tags-summary/state'
import {VesselTags} from '../../../../store/state/vessel-tags/state'
import {getGridLayout} from '../general/helpers'
import {GridLayout} from '../general/grid-layout.enum'

export type TableData = Record<
    Exclude<TagsComponentSortType, TagsComponentSortType.TAG_NAME>,
    number
>

export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.orderBy) {
        return false
    }
    switch (currentSortColumn.orderAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}

export function getVesselTagData(tagName: string, allTags: VesselTags[]): VesselTags | null {
    return allTags.find((tag) => tag.name === tagName) ?? null
}

function isLocationTagsSummaryMapType(value: unknown): value is LocationTagsSummaryMapType {
    if (!(typeof value === 'object') || !value) {
        return false
    }
    const keys = Object.keys(value)
    if (keys.length === 0) {
        return false
    }
    const keyType = typeof keys[0]
    const valueType = typeof Object.values(value)[0]

    return keyType === 'string' && valueType === 'number'
}

export function getTagInfo(data: LocationTagsSummaryReduxState, tagId: string): TableData | null {
    const retObj: TableData = {
        [TagsComponentSortType.AVG_SCORE]: 0,
        [TagsComponentSortType.MAX_SCORE]: 0,
        [TagsComponentSortType.INCIDENTS]: 0,
        [TagsComponentSortType.ASSET_DISCOVERY]: 0,
    }
    let foundData = false
    for (const key in data) {
        const castedKey = key as keyof LocationTagsSummaryReduxState
        const objData = data[castedKey]
        if (!isLocationTagsSummaryMapType(objData)) {
            continue
        }
        let tagData = objData[tagId]
        if (!tagData) {
            continue
        }
        tagData = Math.round(tagData)

        switch (castedKey) {
            case 'incidentResponsesCountsByLocationTags':
                retObj['incidents'] = tagData
                foundData = true
                break
            case 'unknownAssetCountsByLocationTags':
                retObj['assetDiscovery'] = tagData
                foundData = true
                break
            case 'maxLocationScoresByLocationTags':
                retObj['maxScore'] = tagData
                foundData = true
                break
            case 'averageLocationScoresByLocationTags':
                foundData = true
                retObj['avgScore'] = tagData
                break
        }
    }
    return foundData ? retObj : null
}

export function getWidth(width: number): string {
    const gridLayout = getGridLayout(width)
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_LARGE:
            return `auto;`
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '70px;'
        case GridLayout.TWO_COLUMNS_LARGE:
            return '100px;'
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '80px;'
        case GridLayout.ONE_COLUMN_SMALL:
            return '60px;'
        case GridLayout.THREE_COLUMNS_SMALL:
            return '60px;'
    }
    return `70px;`
}
