import {useRef} from 'react'
import {useTheme} from 'styled-components'
import {BoundsContext} from '../../../../components/bounds-context/bounds-context'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {FilterBar} from '../filter-bar/filter-bar'
import {IncidentsTableContent} from './incidents-table-content'
import {TableHeaderRow} from './table-header-row'
import {IncidentsTable, LHFilterBar, WideContentArea} from './_styled/incidents-table.styled'

export function IncidentTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const layoutRef = useRef(null)
    const {showFilterBar} = usePagedIncidents()

    return (
        <>
            {showFilterBar ? (
                <WideContentArea width={width}>
                    <LHFilterBar id="filter-bar-wrapper">
                        <FilterBar />
                    </LHFilterBar>
                    <IncidentsTable>
                        <ContentContainerMiddle
                            width={width}
                            backgroundColor={theme.incidents.dataArea.background}
                        >
                            <TableHeaderRow />
                        </ContentContainerMiddle>
                        <BoundsContext.Provider value={{ref: layoutRef}}>
                            <ContentContainerMiddle
                                ref={layoutRef}
                                width={width}
                                scrollable
                                backgroundColor={theme.incidents.dataArea.background}
                            >
                                <IncidentsTableContent />
                            </ContentContainerMiddle>
                        </BoundsContext.Provider>
                    </IncidentsTable>
                </WideContentArea>
            ) : (
                <>
                    <ContentContainerMiddle
                        width={width}
                        backgroundColor={theme.incidents.dataArea.background}
                    >
                        <TableHeaderRow />
                    </ContentContainerMiddle>
                    <BoundsContext.Provider value={{ref: layoutRef}}>
                        <ContentContainerMiddle
                            ref={layoutRef}
                            width={width}
                            scrollable
                            backgroundColor={theme.incidents.dataArea.background}
                        >
                            <IncidentsTableContent />
                        </ContentContainerMiddle>
                    </BoundsContext.Provider>
                </>
            )}
        </>
    )
}
