import {CardDataCell} from './data-cell'
import {useDispatch} from 'react-redux'
import {setReference} from '../../../store/state/extended-data-request/action-creators'
import {Input} from './_styled/input.styled'
import {useState} from 'react'

export function Reference(): JSX.Element {
    const dispatch = useDispatch()
    const [additionalReference, setAdditionalReference] = useState('')

    return (
        <CardDataCell gridArea="REFERENCE" label="Add an optional reference">
            <Input
                value={additionalReference}
                width={365}
                onChange={(e) => {
                    dispatch(setReference(e.target.value))
                    setAdditionalReference(e.target.value)
                }}
                id={`input-reference_${additionalReference}`}
            />
        </CardDataCell>
    )
}
