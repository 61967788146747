import * as styled from './styled/risk-score-bar.styled'
import {RiskScore} from './risk-score'
import {AxisTick} from './styled/axis-tick.styled'
import {GridLayout} from '../../../general/grid-layout.enum'
import {LowRiskColourBar} from './low-risk-colour-bar'
import {MediumRiskColourBar} from './medium-risk-colour-bar'
import {HighRiskColourBar} from './high-risk-colour-bar'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../../values/nodes/NodeData'

interface RiskScoreBarProps {
    score: number
    gridLayout: GridLayout
}

export function RiskScoreBar({score, gridLayout}: RiskScoreBarProps): JSX.Element {
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    return (
        <styled.RiskScoreBar gridLayout={gridLayout} id="RiskScoreBar">
            {threatMeasure && score < threatMeasure.lowerThreshold && (
                <LowRiskColourBar gridLayout={gridLayout} />
            )}
            {threatMeasure &&
                score >= threatMeasure.lowerThreshold &&
                score < threatMeasure.upperThreshold && (
                    <MediumRiskColourBar gridLayout={gridLayout} />
                )}
            {threatMeasure && score >= threatMeasure.upperThreshold && <HighRiskColourBar />}
            <RiskScore score={score} gridLayout={gridLayout} />
            <AxisTick gridLayout={gridLayout} value={0}>
                0
            </AxisTick>
            <AxisTick gridLayout={gridLayout} value={25}>
                25
            </AxisTick>
            <AxisTick gridLayout={gridLayout} value={50}>
                50
            </AxisTick>
            <AxisTick gridLayout={gridLayout} value={75}>
                75
            </AxisTick>
            <AxisTick gridLayout={gridLayout} value={100}>
                100
            </AxisTick>
        </styled.RiskScoreBar>
    )
}
