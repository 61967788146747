import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {LinkToGraphType} from './links/link-to-graph-type'
import {Links} from './links/links.styled'
import {WidgetWrapper} from './widget-wrapper'
import {Footer} from './footer/footer'
import {GraphContainer} from './graph/graph-container.styled'
import {Graph} from './graph/graph'
import {LAST_12H, LAST_24H, LAST_30D, LAST_7D, LAST_90D} from '../../../../../values/TimeRange'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {tagScoreDataReselector, widgetDataReselector} from '../reselect/widget-data.reselector'
import {NoData} from './no-data'

export function PopulatedData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const model = useTypedSelector(widgetDataReselector)
    const secondModel = useTypedSelector(tagScoreDataReselector)

    return (
        <WidgetWrapper gridLayout={gridLayout}>
            <Links id="links-to-graph">
                <LinkToGraphType
                    selected={model.timeRange === LAST_12H}
                    graphType="12h"
                    text="12h"
                />
                <LinkToGraphType
                    selected={model.timeRange === LAST_24H}
                    graphType="24h"
                    text="24h"
                />
                <LinkToGraphType selected={model.timeRange === LAST_7D} graphType="7d" text="7d" />
                <LinkToGraphType
                    selected={model.timeRange === LAST_30D}
                    graphType="30d"
                    text="30d"
                />
                <LinkToGraphType
                    selected={model.timeRange === LAST_90D}
                    graphType="90d"
                    text="90d"
                />
            </Links>
            {model.data.length === 0 ? (
                <NoData />
            ) : (
                <GraphContainer>
                    <Graph model={model} secondModel={secondModel} />
                </GraphContainer>
            )}
            <Footer model={model} />
        </WidgetWrapper>
    )
}
