import styled from 'styled-components'
import {MAX_SCREEN_WIDTH} from '../../../../theme/theme'
import {spacing} from '../../../../theme/spacing'

interface ContentContainerProps {
    width: number
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
    padding: 0 ${spacing(1)} ${spacing(2)};
`

interface TableDataGridProps {
    width: number
    showReducedGrid: boolean
}

export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: 0 200px 1fr 1fr 200px 100px 0;
    gap: ${spacing(2)};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
`
