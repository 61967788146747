import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`
export const TargetBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;
    gap: 5px;
`
export function getColor(targetType: string): string {
    switch (targetType) {
        case 'above':
            return '#7ABD3D;'
        case 'below':
            return '#BD3D45'
        default:
            return 'inherit'
    }
}
interface ScoreBarNoIssueProps {
    score: number
    targetType: string
}

export const ScoreBar = styled.div<ScoreBarNoIssueProps>`
    position: relative;
    height: 25px;
    width: ${(props) => (props.score / 100) * 130}px;
    background-color: ${(props) => getColor(props.targetType)};
    border-radius: 3px;
`
interface TippyWrapperProps {
    positionRightText: boolean
}
export const TippyWrapper = styled.div<TippyWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    ${(props) => (props.positionRightText ? 'right: 0' : 'left: 0')};
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    z-index: 100;
    width: 150px;
`
interface TippyTextColourProps {
    targetType: string
}
export const TippyTextColour = styled.div<TippyTextColourProps>`
    color: ${(props) => getColor(props.targetType)};
`
