import * as Styled from './incident-type-severity-section.styled'

export function IncidentTypeSeverityTitle(): JSX.Element {
    return (
        <Styled.ZoomLevel id="zoom-level">
            <Styled.TitleLabel>Incident type</Styled.TitleLabel>
            <Styled.TitleOption>Severity</Styled.TitleOption>
        </Styled.ZoomLevel>
    )
}
