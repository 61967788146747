import {ReactNode, useReducer} from 'react'
import {PagedUSBDevicesContext} from './paged-usb-devices-context'
import {pagedIncidentsReducer} from './state/reducer'
import {DEFAULT_PAGED_USB_DEVICES_STATE} from './types/default-paged-usb-devices-state'
import {PagedUSBDevicesState} from './types/paged-usb-devices-state'
import {cloneDeep} from 'lodash'

type PagedUSBDevicesProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedUSBDevicesProvider({
    children,
    pageSize,
}: PagedUSBDevicesProviderProps): JSX.Element {
    const initialState: PagedUSBDevicesState = cloneDeep(DEFAULT_PAGED_USB_DEVICES_STATE)
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedIncidentsReducer, initialState)

    return (
        <PagedUSBDevicesContext.Provider value={{state, dispatch}}>
            {children}
        </PagedUSBDevicesContext.Provider>
    )
}
