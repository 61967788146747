import * as Styled from './incident-recommendations.styled'
import {RecommendationOutputModel} from '../../../models/recommendation.model'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {IncidentsDetailType} from '../../../contexts/types/incidents-details-type'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
interface IncidentREcommendationsProps {
    recommendations: RecommendationOutputModel[]
}

export function IncidentRecommendations({
    recommendations,
}: IncidentREcommendationsProps): JSX.Element {
    const {detailExpanded} = useTypedSelector(incidentsFilterSelector)
    return (
        <Styled.Wrapper>
            <ExpandPanelButton headingRow={IncidentsDetailType.Recommendations} />
            {detailExpanded.recommendationsExpanded && (
                <Styled.TitleRowWrapper>
                    <Styled.RecommendationsList id="RecommendationsList">
                        {recommendations?.map((recommendation) => (
                            <Styled.RecommendationElement
                                key={recommendation.id}
                                id={`recommendation-${recommendation.id}`}
                            >
                                {recommendation.text}
                            </Styled.RecommendationElement>
                        ))}
                    </Styled.RecommendationsList>
                </Styled.TitleRowWrapper>
            )}
        </Styled.Wrapper>
    )
}
