import {isEmailValid} from '../../../../../../../utils/Utils'
import * as Styled from '../_styled/data-input.styled'
import {Check} from 'react-feather'

type EmailInputProps = {
    initialValue?: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    isChanged: boolean
}

export function EmailInput({
    initialValue,
    onChangeHandler,
    isChanged,
}: EmailInputProps): JSX.Element {
    const isValidEmail = isEmailValid(initialValue)
    const isSavable = isChanged && isValidEmail

    return (
        <>
            <Styled.Label id={`email-vessel-tagging`}>Email-address</Styled.Label>
            <Styled.Value id={`email-vessel-tagging-value`}>
                <Styled.inputNonText value={initialValue ?? ''} onInput={onChangeHandler} />
                {isSavable && (
                    <Check color="green" width={19} height={19} style={{paddingLeft: '2px'}} />
                )}
            </Styled.Value>
            <Styled.WarningMessage id={'emailInvalidError'} hidden={isValidEmail}>
                Email is invalid
            </Styled.WarningMessage>
        </>
    )
}
