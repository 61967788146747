import {TableDataCell, TableDataRow, TableDataRowBottom} from './_styled/table-row.styled'
import {ReportsActionsTable} from './report-actions-table/report-actions-table'
import {CustomerReports} from '../../../../values/customer-reports'
import {ReportsAttachmentsTable} from './report-attachments-table/reports-attachments-table'
import {ReportsDetailsTable} from './reports-details-table/reports-details-table'
import {StarRating} from '../shared/star-rating/star-rating'
import {BlankRow} from './_styled/blank-row.styled'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {getLocationDescription} from '../data-helpers'

interface TableRowProps {
    report: CustomerReports
    index: number
}
export function TableRow({report, index}: TableRowProps): JSX.Element {
    const {notesExpanded} = usePagedReports()
    const locationMap = useTypedSelector(locationMapSelector)
    const noteExpanded = notesExpanded.get(report.identity) === true
    const gridRow = index * 3 + 1
    return (
        <>
            <TableDataRow gridRow={gridRow} id={`data-row_${report.identity}`} />
            <TableDataRowBottom gridRow={gridRow + 1} expanded={noteExpanded} />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`reports-vessel_${report.identity}`}
            >
                {getLocationDescription(locationMap, report.location)}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`reports-report-type_${report.identity}`}
            >
                {report.reportType}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`reports-rating_${report.identity}`}
            >
                <StarRating rating={report.rating} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`reports-report-code-id_${report.identity}`}
            >
                {report.reportCode}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`reports-start-date_${report.identity}`}
            >
                {formatDateWithoutTime(report.periodStartDate)}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                id={`reports-end-date_${report.identity}`}
            >
                {formatDateWithoutTime(report.periodEndDate)}
            </TableDataCell>
            <ReportsActionsTable report={report} gridRow={gridRow} />
            <ReportsAttachmentsTable report={report} gridRow={gridRow} />
            <ReportsDetailsTable
                report={report}
                gridRow={gridRow + 1}
                detailsExpanded={noteExpanded}
            />
            <BlankRow gridRow={gridRow + 2} />
        </>
    )
}
