import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background-color: ${(props) => props.theme.myVessels.resetButton.background};
    color: ${(props) => props.theme.myVessels.resetButton.text};
    border-radius: 3px;
    margin: ${spacing(1)} auto ${spacing(3)} auto;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
