import {useDispatch} from 'react-redux'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    FilterBarType,
    SoftwareStatus,
} from '../../../../../store/state/software-inventory-filter/state'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {allSoftwareStatuses} from '../../data-helpers'
import {toggleAllSoftwareStatuses} from '../../../../../store/state/software-inventory-filter/action-creators'
import {AssetStatus} from './software-inventory-status-filter.styled'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {AppliedFilterIndicator} from '../../../../../components/page-filter/components/shared/applied-filter-indicator/applied-filter-indicator'
import {SoftwareStatusRow} from './software-inventory-status-row'
import {ViewAllSoftwareStatusRow} from './view-all-software-status-row'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

function determineViewAllState(
    allSoftwareStatuses: SoftwareStatus[],
    selected: SoftwareStatus[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    if (selected.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    }

    const numberOfSoftwareStatusType = allSoftwareStatuses.length

    return numberOfSoftwareStatusType === selected.length
        ? TriStateButtonState.FULLY_SELECTED
        : TriStateButtonState.PARTIAL_SELECTION
}

export function SoftwareInventoryStatusFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {filteredSoftwareStatus, filterExpanded} = useTypedSelector(
        softwareInventoryFilterSelector,
    )
    const numOfAppliedFilter = !filteredSoftwareStatus ? undefined : filteredSoftwareStatus.length

    const viewAll = determineViewAllState(allSoftwareStatuses, filteredSoftwareStatus)

    function setViewAllState(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleAllSoftwareStatuses(true))
            dispatch(
                logFilterByTypes(
                    'filterByStatus',
                    'View all',
                    true,
                    AuditLogPageType.SOFTWARE_INVENTORY,
                ),
            )
        } else if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleAllSoftwareStatuses(false))
            dispatch(
                logFilterByTypes(
                    'filterByStatus',
                    'View all',
                    false,
                    AuditLogPageType.SOFTWARE_INVENTORY,
                ),
            )
        }
    }

    const showFilteringIndicator =
        filterExpanded && !filterExpanded.softwareStatuses && numOfAppliedFilter != undefined

    const showClearButton = numOfAppliedFilter != 0

    return (
        <AssetStatus id="SoftwareInventoryStatusFilter">
            <ExpandFilterDetailsButton headingRow={FilterBarType.softwareInventoryStatus} />
            {showFilteringIndicator && (
                <AppliedFilterIndicator numOfAppliedFilter={numOfAppliedFilter} />
            )}
            {filterExpanded && filterExpanded.softwareStatuses && (
                <>
                    <ViewAllSoftwareStatusRow
                        state={viewAll}
                        setState={setViewAllState}
                        showClearButton={showClearButton}
                    />
                    <>
                        <SoftwareStatusRow statusLabel={SoftwareStatus.trusted} />
                        <SoftwareStatusRow statusLabel={SoftwareStatus.unknown} />
                        <SoftwareStatusRow statusLabel={SoftwareStatus.unwanted} />
                    </>
                </>
            )}
        </AssetStatus>
    )
}
