import {SeverityLevelSelectableWrapper, SeverityLevelSquare} from './_styled/severity-level.styled'
import {IncidentSeverityValue} from '../../../../../values/incident-response-values'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Button} from '../row-actions/common/button.styled'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {useEffect, useRef} from 'react'
import {SeverityLevelPopover} from './severity-level-popover'
import {getAlternativeIncidentSeverities} from '../../../data-helpers'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {SaveMessage} from '../../../contexts/types/save-note-result'

interface SeverityLevelSelectableProps {
    id: string
    incidentSeverity: IncidentSeverityValue
}

export function SeverityLevelSelectable({
    id,
    incidentSeverity,
}: SeverityLevelSelectableProps): JSX.Element {
    const {width} = useDimensions()
    const {value: dropdownOpen, setValue: setDropdownOpen} = useToggle()
    const {saveResultMessage} = usePagedIncidents()

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setDropdownOpen(false))

    useEffect(() => {
        if (
            saveResultMessage?.message === SaveMessage.SEVERITY_UPDATED &&
            saveResultMessage.identity === id
        ) {
            setDropdownOpen(false)
        }
    }, [id, saveResultMessage?.message, saveResultMessage?.identity, setDropdownOpen])

    return (
        <SeverityLevelSelectableWrapper width={width} ref={clickOutside}>
            <SeverityLevelSquare
                withBorder={false}
                severityLevel={incidentSeverity}
                id={`incident-severity-square_${incidentSeverity}-${id}`}
            >
                {incidentSeverity}
            </SeverityLevelSquare>
            <Button
                onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                id={`expand-details-button_${id}`}
            >
                <Icon glyph={dropdownOpen ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            </Button>

            {dropdownOpen && (
                <SeverityLevelPopover
                    id={id}
                    availableSeverities={getAlternativeIncidentSeverities(incidentSeverity)}
                />
            )}
        </SeverityLevelSelectableWrapper>
    )
}
