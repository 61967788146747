import {MouseEvent, useState} from 'react'
import {ButtonText} from './button-text.styled'
import {Button} from './button.styled'
import {GenerateReportPopup} from '../generate-report-popup/generate-report-popup'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {DisableButtonRecommendation} from '../disable-button-recom/disable-button-recom'
import {GenerateReportErrorPopup} from '../generate-report-error-popup/generate-report-error-popup'

type glyphType =
    | 'ArrowBack'
    | 'ArrowForward'
    | 'Dots'
    | 'Bell'
    | 'Cog'
    | 'Sun'
    | 'Moon'
    | 'Radar'
    | 'Medulla'
    | 'Search'
    | 'Arrow'
    | 'Reset'
    | 'Watch'
    | 'Unwatch'
    | 'Details'
    | 'Delete'
    | 'Locked'
    | 'Unlocked'
    | 'Warning'
    | 'Exclamation'
    | 'Pdf'
    | 'Excel'
    | 'Csv'
    | 'RatingStar'
    | 'FilterSortUp'
    | 'FilterSortDown'
    | 'DropdownUp'
    | 'DropdownDown'

interface GenerateReportButtonProps {
    createReport: () => void
    reportUrl: string | null
    showGenerateReportPopup: (show: boolean) => void
    reportDialogShown: boolean
    pageType: string
    isDisabled: boolean
    glyph?: glyphType
    btnText?: string
    errorText?: string
    onErrorClose?: () => void
    isErrorOpen?: boolean
}

export function GenerateReportButton({
    createReport,
    reportUrl,
    showGenerateReportPopup,
    reportDialogShown,
    pageType,
    isDisabled = false,
    glyph = 'Excel',
    btnText = 'Create Report',
    onErrorClose,
    errorText = '',
    isErrorOpen = false,
}: GenerateReportButtonProps): JSX.Element {
    const [viewRecommendation, setViewRecommendation] = useState(false)

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        createReport()
    }

    return (
        <div
            onMouseEnter={() => {
                if (isDisabled) {
                    setViewRecommendation(true)
                }
            }}
            onMouseLeave={() => {
                if (isDisabled) {
                    setViewRecommendation(false)
                }
            }}
            style={{position: 'relative'}}
        >
            <Button
                id={`${pageType}_generate-report-button`}
                onClick={(e) => refreshFunction(e)}
                disabled={isDisabled}
            >
                <Icon glyph={glyph} height={19} width={19} />
                <ButtonText id={`${pageType}_generate-report-button_text`}>{btnText}</ButtonText>
            </Button>
            {reportDialogShown && (
                <GenerateReportPopup
                    showGenerateReportPopup={showGenerateReportPopup}
                    reportUrl={reportUrl}
                    pageType={pageType}
                />
            )}
            {isErrorOpen && errorText && onErrorClose && (
                <GenerateReportErrorPopup onClose={onErrorClose} errorText={errorText} />
            )}
            {viewRecommendation && <DisableButtonRecommendation />}
        </div>
    )
}
