import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {VesselFiltersState, ZOOM_LEVEL_TYPE} from './types/my-vessel-filter-state'

export const myVesselsFiltersSelector = (state: AppState): VesselFiltersState =>
    state.myVesselsFilter

export const isLoadingMyVesselsFiltersSelector = (state: AppState): boolean =>
    state.myVesselsFilter.loadingState === LoadingState.NotPopulated ||
    state.myVesselsFilter.loadingState === LoadingState.RequestingData

export const myVesselsFiltersLoadingStateSelector = (state: AppState): LoadingState =>
    state.myVesselsFilter.loadingState

export const myVesselsZoomLevelSelector = (state: AppState): ZOOM_LEVEL_TYPE =>
    state.myVesselsFilter.zoomLevel
