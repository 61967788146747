import {GridLayout} from '../../general/grid-layout.enum'
import {Data} from '../../general/content-area/overview-data/data.styled'
import {Label} from '../../general/content-area/overview-data/label.styled'
import {Row} from '../../general/content-area/overview-data/row.styled'
import {TimeStamp} from '../../general/content-area/overview-data/time-stamp.styled'
import {Value} from '../../general/content-area/overview-data/value.styled'
import {WidgetOutputModel} from '../widget.model'
import {Level} from '../../../../../values/location-level-enum'

interface IncidentDataProps {
    gridLayout: GridLayout
    model: WidgetOutputModel | null
}

export function LatestUnknownAsset({gridLayout, model}: IncidentDataProps): JSX.Element | null {
    if (!model) {
        return null
    }

    return (
        <Data gridLayout={gridLayout} id="asset-discovery-data-grid">
            <Row gridLayout={gridLayout} id="row-first-detected-info">
                <Label gridLayout={gridLayout}>First Detected</Label>
                <Value id="value-first-detected">
                    {model.firstDetectedHumanReadable}
                    {model.firstDetectedTimeStamp !== 'N/A' && (
                        <TimeStamp gridLayout={gridLayout} id="timestamp-first-detected">
                            {model.firstDetectedTimeStamp}
                        </TimeStamp>
                    )}
                </Value>
            </Row>
            <Row
                gridLayout={gridLayout}
                deemphasize={model.level === Level.VESSEL}
                id="row-vessel-info"
            >
                <Label gridLayout={gridLayout}>Vessel</Label>
                <Value id="value-vessel">{model.vessel}</Value>
            </Row>
            <Row gridLayout={gridLayout} lastRow id="row-ipAddress-info">
                <Label gridLayout={gridLayout}>IP Address</Label>
                <Value id="value-ipAddress">{model.ipAddress}</Value>
            </Row>
        </Data>
    )
}
