import {ChangeEvent, MouseEvent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    setProcessingNewNoteAction,
    setSaveNewNoteAction,
} from '../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {newNoteToEditSelector} from '../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {GuidType} from '../../../../../values/generic-type-defintions'
import * as Styles from './add-note.styled'
import {CommentEntry} from './comment-entry'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {SaveMessage} from '../../../contexts/types/save-note-result'

interface AddNoteProps {
    incidentIdentity: GuidType
}

export function AddNote({incidentIdentity}: AddNoteProps): JSX.Element {
    const placeholderText = 'Type here...'
    const newNoteToEdit = useTypedSelector(newNoteToEditSelector)
    const [notePlaceholder, setNotePlaceholder] = useState<string>(placeholderText)
    const dispatch = useDispatch()
    const {saveNotesToIncident, saveResultMessage, setSaveMessage} = usePagedIncidents()

    const isSaveDisabled = newNoteToEdit?.length === 0

    const noteIsReviewed = saveResultMessage?.message === SaveMessage.NOTE_REVIEW && !isSaveDisabled
    useEffect(() => {
        if (saveResultMessage?.message === SaveMessage.NOTE_REVIEW && isSaveDisabled) {
            dispatch(setSaveNewNoteAction())
            setSaveMessage(undefined)
            setNotePlaceholder(placeholderText)
        }
    }, [dispatch, isSaveDisabled, saveResultMessage?.message, setSaveMessage])
    const noteBlurHandler = () => {
        if (isSaveDisabled) {
            setNotePlaceholder(placeholderText)
        }
    }

    const noteClickHandler = () => {
        setNotePlaceholder('')
    }

    const noteChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setProcessingNewNoteAction(incidentIdentity, e.target.value))
    }

    const onSaveHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        saveNotesToIncident(incidentIdentity, newNoteToEdit, dispatch, dispatch)
        setNotePlaceholder(placeholderText)
    }

    return (
        <Styles.AddNoteContainer noteIsReviewed={noteIsReviewed} id="add-note-container-wrapper">
            <Styles.AddNoteBorder isSaveDisabled={isSaveDisabled}>
                <CommentEntry
                    id={`new-note-entry_${incidentIdentity}`}
                    value={newNoteToEdit}
                    placeholder={notePlaceholder}
                    onClick={noteClickHandler}
                    onBlur={noteBlurHandler}
                    onChange={noteChangeHandler}
                />
            </Styles.AddNoteBorder>
            <Styles.SaveButton
                disabled={isSaveDisabled}
                id={`add-note_${incidentIdentity}`}
                value="Add"
                onClick={onSaveHandler}
            >
                Add note
            </Styles.SaveButton>
        </Styles.AddNoteContainer>
    )
}
