import {GridLayout} from './grid-layout.enum'

export function getGridLayout(width: number): GridLayout {
    if (width >= 1920) {
        return GridLayout.THREE_COLUMNS_LARGE
    } else if (width >= 1440) {
        return GridLayout.THREE_COLUMNS_MEDIUM
    } else if (width >= 1280) {
        return GridLayout.THREE_COLUMNS_SMALL
    } else if (width >= 1160) {
        return GridLayout.TWO_COLUMNS_LARGE
    } else if (width >= 980) {
        return GridLayout.TWO_COLUMNS_MEDIUM
    } else if (width >= 866) {
        return GridLayout.TWO_COLUMNS_SMALL
    } else if (width >= 570) {
        return GridLayout.ONE_COLUMN_LARGE
    } else if (width >= 470) {
        return GridLayout.ONE_COLUMN_MEDIUM
    }

    return GridLayout.ONE_COLUMN_SMALL
}
