import styled from 'styled-components'

export const Value = styled.div`
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    justify-content: flex-start;
`
