import {CardDataCell} from '../data-cell'
import {DataRangeDropdown} from './data-range-dropdown'

export function DataRange(): JSX.Element {
    return (
        <CardDataCell gridArea="DATA_RANGE" label="Date range of interest">
            <DataRangeDropdown />
        </CardDataCell>
    )
}
