import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './data-cell.styled'
import {LabelLock} from '../../../shared/tag-limit/label-lock'

interface DataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
    gridArea?: string
    width?: number
}
export function DataCell({label, children, gridArea, width}: DataCellProps): JSX.Element {
    const formattedGridArea = gridArea || label.toLocaleUpperCase().replace(/ /g, '-')
    const id = `user_management-${formattedGridArea.toLowerCase()}`

    return (
        <>
            <Styled.Label gridArea={formattedGridArea} width={width} id={`${id}-label`}>
                {label == 'Lock' ? <LabelLock /> : label}
            </Styled.Label>
            <Styled.Value gridArea={`${formattedGridArea}-VALUE`} id={`${id}-value`}>
                {children}
            </Styled.Value>
        </>
    )
}
