import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {CyberOwlTheme} from '../../../../../theme/theme'
interface TableDataRowProps {
    gridRow: number
    span?: number
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.reports.dataRow.text};
    background-color: ${(props) => props.theme.reports.dataRow.background};
    min-height: 64px;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.reports.dataArea.boxShadow};
`
interface TableDataRowBottomProps {
    expanded: boolean
    gridRow: number
}

export const TableDataRowBottom = styled.div<TableDataRowBottomProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.reports.dataRow.text};
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.reports.dataArea.boxShadow};

    background-color: ${(props) => props.theme.reports.dataRow.background};
    border-top: ${(props) =>
        props.expanded ? `1px solid ${props.theme.reports.dataRow.separator}` : 'none'};
`
interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean

    centered?: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean | undefined): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    justify-content: flex-start;
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold)};
`
