import {useState} from 'react'
import {SwitchToggle} from '../../../../../../../components/switch-toggle/switch-toggle'
import * as Styled from './enforcement-policy-detail-toggle.styled'
import {isEqual} from 'lodash'

interface EnforcementPolicyToggleProps {
    label: string
    enabled: boolean
    setChange: (value: boolean, isValueChanged: boolean) => void
    disabled?: boolean
    tooltip?: string
}

export function EnforcementPolicyToggle({
    label,
    enabled,
    setChange,
    disabled = false,
    tooltip,
}: EnforcementPolicyToggleProps): JSX.Element {
    const [onValue, setOnValue] = useState(enabled)
    return (
        <Styled.ToggleWrapper>
            <Styled.ToggleLabel>{label}</Styled.ToggleLabel>
            <SwitchToggle
                onValue={onValue}
                tooltip={tooltip}
                setToggleLeftValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    const isItChanged = !isEqual(enabled, false)
                    setOnValue(false)
                    setChange(false, isItChanged)
                }}
                setToggleRightValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    const isItChanged = !isEqual(enabled, true)
                    setOnValue(true)
                    setChange(true, isItChanged)
                }}
                id={`enforcement-pollicy-toggle-${label}`}
            />
        </Styled.ToggleWrapper>
    )
}
