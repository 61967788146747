import {useDispatch} from 'react-redux'
import {Button} from './save-filter-popup.styled'
import {setResultMessage} from '../../../../store/state/saved-filters/action-creators'

interface SCancelButtonProps {
    closePopup: (value: boolean) => void
}

export function CancelButton({closePopup}: SCancelButtonProps): JSX.Element {
    const dispatch = useDispatch()

    return (
        <Button
            id="close-save-filter-button"
            onClick={() => {
                closePopup(false)
                dispatch(setResultMessage(undefined))
            }}
        >
            Cancel
        </Button>
    )
}
