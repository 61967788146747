import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {useVesselManagement} from '../../../contexts/use-vessel-management'

interface DetailPanelHeaderProp {
    text: string
}

export function DetailPanelHeader({text}: DetailPanelHeaderProp): JSX.Element {
    const {closeVesselDetailModal} = useVesselManagement()

    return (
        <Styled.DetailPanelHeader>
            <Styled.Title>{text}</Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeVesselDetailModal()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
