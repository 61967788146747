import styled from 'styled-components'

interface TableHeaderCellProps {
    gridColumn: number
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
