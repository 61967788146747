import styled from 'styled-components'
import {Colors} from '../../../theme/colours'
import {spacing} from '../../../theme/spacing'
import Tippy from '@tippyjs/react'

export const Card = styled.div`
    background-color: white;
    padding: ${spacing(2)};
    width: 100%;
    border-radius: 10px;
    display: flex;
    gap: 1em;
    box-shadow: 0px 1px 2px 0px #00000034;
`

export const IconContainer = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
`

export const Content = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${Colors.grey5};
    overflow-y: hidden;
`

interface RowProps {
    columnGap?: string
}

export const Row = styled.div<RowProps>`
    display: flex;
    justify-content: space-between;
    column-gap: ${(props) => props.columnGap ?? '0'};
`

interface RowItemProps {
    truncateText?: boolean
    textAlign?: 'center' | 'left' | 'right' | 'justify' | 'initial' | 'inherit'
}
export const RowItem = styled.div<RowItemProps>`
    overflow: ${(props) => (props.truncateText ? 'hidden' : 'visible')};
    text-overflow: ${(props) => (props.truncateText ? 'ellipsis' : 'clip')};
    white-space: ${(props) => (props.truncateText ? 'nowrap' : 'normal')};
    text-align: ${(props) => props.textAlign ?? 'initial'};
    height: fit-content;
`

export const StyledTippy = styled(Tippy)`
    .tippy-content {
        padding: 5px !important;
    }

    .tippy-box {
        box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -4px rgba(0, 0, 0, 0.1);
    }
`
