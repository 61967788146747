import styled from 'styled-components'
import {WidgetType} from '../../widget-type'
import {GridLayout} from './grid-layout.enum'
import {getGridLayout} from './helpers'

interface WidgetHolderProps {
    widgetType: WidgetType
    width: number
    placeHolder?: boolean
}

function getBgColor(width: number): string {
    switch (getGridLayout(width)) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return 'rgba(0, 255,0, .1)'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return 'rgba(0,0, 255, .1)'
        default:
            return 'rgba(255, 0, 0, .1)'
    }
}
export const WidgetHolder = styled.div<WidgetHolderProps>`
    background-color: ${(props) => (props.placeHolder ? getBgColor(props.width) : 'inherit')};
    color: white;
    grid-area: ${(props) => props.widgetType};
`
