import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    cursor: pointer;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
`

export const ConnectionsWrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    gap: ${spacing(2)};
    justify-content: center;
`

export const NumberText = styled.div`
    justify-content: flex-end;
    align-self: center;
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const PurposeText = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    font-style: italic;
    color: ${(props) => props.theme.colors.text.default};
`
