import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {ReactNode, ReactNodeArray} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {useTagsComponent} from '../contexts/use-tags-component'
import {TagsFilter} from './filter-control/filter-component'
import {CogButton} from '../../general/header/cog-button/cog-button'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../store/state/users/state'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNodeArray
    noData?: boolean
}

export function WidgetWrapper({gridLayout, children}: WidgetWrapperProps): JSX.Element {
    const {showFilter, setShowFilter, searchedVesselTagTerm} = useTagsComponent()
    const filterApplied = searchedVesselTagTerm.length !== 0
    const {strictMode} = useTypedSelector(currentUserSelector)

    return (
        <Widget>
            <Header gridLayout={gridLayout} id="tags-widget-header">
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout} id="tags-widget-title">
                        Compare Fleets
                    </TitleText>
                    {strictMode !== StrictMode.Strict && (
                        <CogButton
                            filterApplied={filterApplied}
                            onClick={() => setShowFilter(!showFilter)}
                            widget="tags"
                        />
                    )}
                </HeaderContent>
            </Header>
            {showFilter && <TagsFilter />}
            {children}
        </Widget>
    )
}
