import {PagedIncidentsState} from '../types/paged-incidents-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {DEFAULT_PAGED_INCIDENTS_STATE} from '../types/default-paged-incidents-state'
import {isEqual, uniqBy} from 'lodash'
import {IncidentResponse} from '../types/incident-response'
import {GuidType} from '../../../../values/generic-type-defintions'
import {ProcessingStatus} from '../types/type-of-interfaces'
import {IncidentLinkType} from '../types/incident-link'
import {NetworkAssetData, SoftwareItemData, UsbItemData} from '../types/incident-linked-items'

function getNetworkAssetLabel(networkAsset: NetworkAssetData | undefined): string {
    if (!networkAsset) {
        return 'N/A'
    }

    if (networkAsset.name?.length > 0) {
        return networkAsset.name?.length > 10
            ? `${networkAsset.name.slice(0, 10)}...`
            : networkAsset.name
    } else if (networkAsset.mainIpAddress?.length > 0) {
        return networkAsset.mainIpAddress
    } else if (networkAsset.mainMacAddressVendor?.length > 0) {
        return `${networkAsset.mainMacAddressVendor.slice(
            0,
            7,
        )} (${networkAsset.mainMacAddress.slice(-8)})`
    } else {
        return networkAsset.mainMacAddress
    }
}

function getUsbItemLabel(usbItem: UsbItemData | undefined): string {
    if (!usbItem) {
        return 'N/A'
    }

    const vendorNamePart = usbItem.vendorName?.split(/[^0-9a-z]/gi)[0].slice(0, 8)
    const productNamePart = usbItem.productName
        ?.split(/\W/g)[0]
        .slice(0, 13 - vendorNamePart?.length)
    const idPart = `(${usbItem.deviceId?.slice(0, 3)})`
    return `${vendorNamePart} ${productNamePart}`?.length > 14
        ? `${vendorNamePart} ${productNamePart}...${idPart}`
        : `${vendorNamePart} ${productNamePart} ${idPart}`
}

function getSoftwareItemLabel(softwareItem: SoftwareItemData | undefined): string {
    if (!softwareItem) {
        return 'N/A'
    }

    const productNamePart =
        softwareItem.product?.length > 14
            ? `${softwareItem.product.slice(0, 14)}...`
            : softwareItem.product
    const companyNamePart = softwareItem.company
        ?.split(' ')[0]
        .slice(0, 19 - productNamePart?.length)

    return `${companyNamePart} ${productNamePart}`
}

export const pagedIncidentsReducer = produce(
    (draft: PagedIncidentsState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loading = LoadingState.RequestingData
                draft.dataIncidentsMap = undefined
                draft.totalNumberOfIncidents = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loading = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loading = LoadingState.Loaded
                draft.totalNumberOfIncidents = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages
                draft.totalNumberOfItemsByStatus = action.payload.totalNumberOfItemsByStatus
                draft.totalNumberOfItemsBySeverity = action.payload.totalNumberOfItemsBySeverity
                draft.isProcessingIncidentModal = ProcessingStatus.PROCESSED

                if (!draft.dataIncidentsMap) {
                    draft.dataIncidentsMap = new Map<number, IncidentResponse[]>()
                }

                if (
                    !draft.dataIncidentsMap.has(draft.selectedPage) ||
                    !isEqual(
                        draft.dataIncidentsMap.get(draft.selectedPage),
                        action.payload.incidents,
                    )
                ) {
                    draft.dataIncidentsMap.set(draft.selectedPage, action.payload.incidents)
                }
                break
            case ActionType.SET_FILTER:
                draft.loading = LoadingState.RequestingData
                draft.dataIncidentsMap = undefined
                draft.totalNumberOfIncidents = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.SET_SAVE_RESULT_MESSAGE:
                if (!isEqual(draft.saveResultMessage, action.payload)) {
                    draft.saveResultMessage = action.payload
                }
                break
            case ActionType.SET_EDITABLE_COMMENT:
                if (!draft.editableComments) {
                    draft.editableComments = new Map<GuidType, boolean>()
                }
                const existingState = draft.editableComments.get(action.payload) ?? false
                draft.editableComments.set(action.payload, existingState === false)
                break
            case ActionType.DISPLAY_INCIDENT_DETAILS_MODAL:
                draft.incidentModalId = action.payload
                draft.isProcessingIncidentModal = ProcessingStatus.PROCESSING
                break
            case ActionType.SWITCH_TO_CACHED_DATA_MODAL:
                draft.isProcessingIncidentModal = ProcessingStatus.PROCESSED
                break
            case ActionType.CLOSE_INCIDENT_DETAILS_MODAL:
                draft.incidentModalId = null
                draft.isProcessingIncidentModal = ProcessingStatus.NOT_PROCESSING
                break
            case ActionType.DISPLAY_FILTER_BAR:
                draft.displayFilterBar = action.payload
                break
            case ActionType.RECEIVED_GUEST_EMAILS:
                if (!isEqual(draft.guestEmails, action.payload)) {
                    draft.guestEmails = action.payload
                }
                break
            case ActionType.RECEIVED_REQUESTED_INCIDENT:
                if (!isEqual(draft.findIncident, action.payload)) {
                    draft.findIncident = action.payload
                }
                break
            case ActionType.REQUEST_LINKED_ITEMS:
                draft.isFetchingItems = true
                draft.linkedItemsData = DEFAULT_PAGED_INCIDENTS_STATE.linkedItemsData
                break
            case ActionType.RECEIVE_LINKED_ITEMS:
                if (!action.payload) {
                    draft.linkedItemsData = DEFAULT_PAGED_INCIDENTS_STATE.linkedItemsData
                    draft.monitorLinkedItems = DEFAULT_PAGED_INCIDENTS_STATE.monitorLinkedItems
                }
                if (action.payload.itemType === IncidentLinkType.NETWORK_ASSET) {
                    const networkData = action.payload.data as NetworkAssetData
                    draft.linkedItemsData.push({
                        incidentLinkIdentity: action.payload.incidentLinkIdentity,
                        identity: action.payload.data.identity,
                        itemType: IncidentLinkType.NETWORK_ASSET,
                        label: getNetworkAssetLabel(networkData),
                        linkUrl: `/network-assets?networkAssetDetails=${networkData.identity}`,
                        state: networkData.state,
                        lastSeen: networkData.lastSeen,
                        monitored:
                            draft.findIncident?.links.find(
                                (item) => item.itemIdentity === action.payload.data.identity,
                            )?.monitored ?? false,
                    })
                } else if (action.payload.itemType === IncidentLinkType.USB) {
                    const usbData = action.payload.data as UsbItemData
                    draft.linkedItemsData.push({
                        incidentLinkIdentity: action.payload.incidentLinkIdentity,
                        identity: usbData.identity,
                        itemType: IncidentLinkType.USB,
                        label: getUsbItemLabel(usbData),
                        linkUrl: `/usb-devices?usbDetails=${usbData.identity}`,
                        state: usbData.deviceStatus,
                        lastSeen: usbData.lastSeenTimestamp,
                        monitored:
                            draft.findIncident?.links.find(
                                (item) => item.itemIdentity === action.payload.data.identity,
                            )?.monitored ?? false,
                    })
                } else {
                    const softwareData = action.payload.data as SoftwareItemData
                    draft.linkedItemsData.push({
                        incidentLinkIdentity: action.payload.incidentLinkIdentity,
                        identity: action.payload.data.identity,
                        itemType: IncidentLinkType.SOFTWARE,
                        label: getSoftwareItemLabel(softwareData),
                        linkUrl: `/software?softwareDetails=${softwareData.identity}`,
                        state: softwareData.status,
                        lastSeen: softwareData.lastDetected.firstSeen,
                        monitored:
                            draft.findIncident?.links.find(
                                (item) => item.itemIdentity === action.payload.data.identity,
                            )?.monitored ?? false,
                    })
                }
                if (draft.linkedItemsData?.length > action.payload.num) {
                    draft.linkedItemsData = uniqBy(draft.linkedItemsData, 'identity')
                }
                if (draft.linkedItemsData?.length === action.payload.num) {
                    draft.isFetchingItems = false
                }
                break

            case ActionType.INITIALISE_LINKED_ITEMS_DATA:
                draft.linkedItemsData = DEFAULT_PAGED_INCIDENTS_STATE.linkedItemsData
                break

            case ActionType.OPEN_INCIDENT_MANAGEMENT_MODEL:
                draft.showIncidentManagementModal = action.payload
                break

            case ActionType.DISPLAY_CLOSE_MONITOR_INCIDENT_MODAL:
                draft.showCloseMonitoredIncidentModal = action.payload
                if (!action.payload) {
                    draft.monitorLinkedItems = []
                }
                draft.processingSaveCloseMonitor = ProcessingStatus.NOT_PROCESSING
                break

            case ActionType.SET_MONITORED_ITEM:
                if (action.payload.value) {
                    draft.monitorLinkedItems.push(action.payload.identity)
                } else {
                    draft.monitorLinkedItems = draft.monitorLinkedItems.filter(
                        (item) => item !== action.payload.identity,
                    )
                }
                break

            case ActionType.SET_SAVE_CLOSE_MONITOR_PROCESSING_STATUS:
                draft.processingSaveCloseMonitor = action.payload
                break

            case ActionType.RESET_INCIDENTS_RESPONSES:
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_INCIDENTS_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
