import styled from 'styled-components'

interface ContentWrapperProps {
    width: number
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
