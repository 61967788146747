import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {VesselInfoStyle} from './_styled/vessel-info.styled'
import {DataCellFixed} from '../data-cell/data-cell-fixed'
import {DataCellButtons} from '../data-cell/data-cell-buttons'
import {useVesselConfig} from '../../contexts/use-vessel-config'
import {Buttons} from './buttons'
import {SelectVesselModal} from './select-vessel-modal'
import {getGridType, showOneCol} from '../shared/grid-layout-helper'
import {Prompt} from 'react-router-dom'

export function VesselInfo(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridType(width)
    const {fixedValue, saveResultMessage, isChanged} = useVesselConfig()

    return (
        <>
            <Prompt
                when={isChanged}
                message="You have unsaved changes. Are you sure you want to leave?"
            />
            <VesselInfoStyle gridLayout={gridLayout}>
                <DataCellFixed label="GUID">{fixedValue.location}</DataCellFixed>
                <DataCellFixed label="Description">{fixedValue.description}</DataCellFixed>
                <DataCellFixed label="Code">{fixedValue.locationCode}</DataCellFixed>
                <DataCellButtons label={saveResultMessage || ''} gridArea={'BUTTON'}>
                    <Buttons />
                </DataCellButtons>
                {showOneCol(gridLayout) && (
                    <DataCellButtons label={''} gridArea={'COPYBUTTON'}>
                        <SelectVesselModal />
                    </DataCellButtons>
                )}
            </VesselInfoStyle>
        </>
    )
}
