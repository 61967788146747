export interface DonutPositon {
    lowPosition: string
    mediumPosition: string
    highPosition: string
    criticalPosition: string
}

export function getPositionOnDonut(
    lowIncidents: number,
    mediumIncidents: number,
    highIncidents: number,
    criticalIncidents: number,
): DonutPositon {
    const totalNrOfIncidents = lowIncidents + mediumIncidents + highIncidents + criticalIncidents

    const mediumPosition = (lowIncidents / totalNrOfIncidents) * 180
    const highPosition = mediumPosition + (mediumIncidents / totalNrOfIncidents) * 180
    const criticalPosition = highPosition + (highIncidents / totalNrOfIncidents) * 180
    return {
        lowPosition: '0deg',
        mediumPosition: mediumPosition !== 0 ? `${mediumPosition.toFixed(2)}deg` : '0deg',
        highPosition: highPosition !== 0 ? `${highPosition.toFixed(2)}deg` : '0deg',
        criticalPosition: criticalPosition !== 0 ? `${criticalPosition.toFixed(2)}deg` : '0deg',
    }
}
