import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface ScrollableContentProps {
    flexGrow?: boolean
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
    padding: ${spacing(6)} 0;
    overflow-y: auto;
    ${(props) => (props.flexGrow ? 'flex: 1;' : '')}
    width: 100%;
`
