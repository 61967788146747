import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {useDispatch} from 'react-redux'
import {useUserManagement} from '../../../../context/use-user-management'
import {fetchUsers} from '../../../../../../store/state/users/action-creators'

export function CloseButton(): JSX.Element {
    const {setDisplayAddNewUserModal} = useUserManagement()
    const dispatch = useDispatch()

    function closeModal(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setDisplayAddNewUserModal(false)
        dispatch(fetchUsers())
    }
    return (
        <Styled.Button id="close-button" onClick={closeModal}>
            Close
        </Styled.Button>
    )
}
