import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styled from './analysis-selection.styled'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {
    AnalysisType,
    BenchmarkTrendsType,
    FilterBarType,
} from '../../../../../store/state/metrics-filter-beta/state'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {PeriodSelectionFilter} from './period-selection-filter'
import {AnalysisTypeFilter} from './analysis-type-filter'
import * as RadioStyled from './radio-button.styled'
import {setBenchmarkTrendsType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {useDispatch} from 'react-redux'
export function AnalysisSelectionFilter(): JSX.Element {
    const {filterExpanded, analysisTypes, benchmarkType} =
        useTypedSelector(metricsBetaFilterSelector)
    const dispatch = useDispatch()

    return (
        <Styled.AnalysisPeriod id="AnalysisSelectionFilter">
            <ExpandFilterDetailsButton headingRow={FilterBarType.AnalysisSelection} />
            {filterExpanded && filterExpanded.periodExpanded && (
                <>
                    <PeriodSelectionFilter />
                    <AnalysisTypeFilter />
                    {analysisTypes === AnalysisType.TRENDS && (
                        <div style={{display: 'flex', gap: '5px', alignItems: 'baseline'}}>
                            <Styled.SubLabel>Benchmark type</Styled.SubLabel>
                            {availableBenchmarkTypes.map((item, index) => (
                                <RadioStyled.Container key={index}>
                                    <RadioStyled.Input
                                        type="radio"
                                        id={`${item}-radio`}
                                        value={item}
                                        checked={item === benchmarkType}
                                        onChange={() => dispatch(setBenchmarkTrendsType(item))}
                                    />
                                    <RadioStyled.Label htmlFor={`${item}-radio`}>
                                        {item}
                                    </RadioStyled.Label>
                                </RadioStyled.Container>
                            ))}
                        </div>
                    )}
                </>
            )}
        </Styled.AnalysisPeriod>
    )
}
const availableBenchmarkTypes: BenchmarkTrendsType[] = ['All customers', 'Whole fleet']
