import {CustomerReports} from '../../../../../values/customer-reports'
import {TableDataCell} from '../_styled/table-row.styled'
import * as Styles from '../../shared/report-actions/reports-actions.styled'
import {ToggleReportDetailsButton} from '../../shared/report-actions/toggle-reports-details-button'

interface ReportsActionsProps {
    report: CustomerReports
    gridRow: number
}
export function ReportsActionsTable({report, gridRow}: ReportsActionsProps): JSX.Element {
    return (
        <TableDataCell gridRow={gridRow} gridColumn={8} id={`reports-actions_${report.identity}`}>
            <Styles.ReportsActionsStyled>
                {report.notes && <ToggleReportDetailsButton id={report.identity} />}
            </Styles.ReportsActionsStyled>
        </TableDataCell>
    )
}
