import {useState} from 'react'
import * as Styled from './enable-toggle.styled'
import {SwitchToggle} from '../../../../components/switch-toggle/switch-toggle'
import {isEqual} from 'lodash'

interface EnableToggleProps {
    enabled: boolean
    setChange: (value: boolean, isValueChanged: boolean) => void
    id: string
    disabled?: boolean
    tooltip?: string
}

export function EnableToggle({
    enabled,
    setChange,
    disabled = false,
    tooltip,
    id,
}: EnableToggleProps): JSX.Element {
    const [onValue, setOnValue] = useState(enabled)
    return (
        <Styled.ToggleWrapper>
            <SwitchToggle
                id={id}
                onValue={onValue}
                tooltip={tooltip}
                setToggleLeftValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    const isItChanged = !isEqual(enabled, false)
                    setOnValue(false)
                    setChange(false, isItChanged)
                }}
                setToggleRightValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    const isItChanged = !isEqual(enabled, true)
                    setOnValue(true)
                    setChange(true, isItChanged)
                }}
            />
        </Styled.ToggleWrapper>
    )
}
