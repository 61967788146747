import styled from 'styled-components'

export const Card = styled.div`
    color: ${(props) => props.theme.modalDialog.dataRow.text};
    background-color: ${(props) => props.theme.modalDialog.dataRow.background};
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 17px;
`
