import {X} from 'react-feather'
import {Check} from 'react-feather'
import * as Styled from './notify-me-popup.styled'
import {useState} from 'react'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {useDispatch} from 'react-redux'
import {setChangeAlertPrefix} from '../../../../store/state/saved-filters/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'

interface AddCustomPrefixProps {
    model: NotifyMeOutputModel
}

export function AddCustomPrefix({model}: AddCustomPrefixProps): JSX.Element {
    const dispatch = useDispatch()
    const [addPrefix, serAddPrefix] = useState(false)
    function onPrefixChangeHandler(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault()
        dispatch(setChangeAlertPrefix(e.target.value))
    }
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const isChanged =
        newSubscription.subjectPrefix !== undefined && newSubscription.subjectPrefix.length !== 0

    return (
        <>
            {!addPrefix && !model.currentSubscription?.subjectPrefix ? (
                <>
                    <Styled.AddPrefix
                        onClick={() => serAddPrefix(!addPrefix)}
                        id="add-custom-email"
                    >
                        Add custom email prefix
                    </Styled.AddPrefix>
                    <Styled.PrefixDetail>
                        Choose an email prefix when receiving this alert - ex. Cyberowl-update
                    </Styled.PrefixDetail>
                </>
            ) : (
                <Styled.InputWrapper>
                    <Styled.InputField width={120}>
                        <Styled.Input
                            placeholder={model.subjectPrefix ?? 'Email prefix'}
                            value={newSubscription.subjectPrefix}
                            onChange={(e) => onPrefixChangeHandler(e)}
                            id="prefix-change-input"
                        />
                        {isChanged && (
                            <Styled.XIcon
                                onClick={() => dispatch(setChangeAlertPrefix(''))}
                                id="set-change-prefix"
                            >
                                <X height={15} width={15} />
                            </Styled.XIcon>
                        )}
                    </Styled.InputField>
                    {isChanged && (
                        <Check
                            color="green"
                            width={19}
                            height={19}
                            style={{position: 'absolute', right: '65px', paddingLeft: '2px'}}
                        />
                    )}
                </Styled.InputWrapper>
            )}
        </>
    )
}
