import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {narrowLayoutFn} from '../grid-helper'
interface IncidentTypeWrapperProps {
    width: number
}
export const IncidentTypeWrapper = styled.div<IncidentTypeWrapperProps>`
    display: flex;
    justify-content: ${(props) => (narrowLayoutFn(props.width) ? 'flex-start;' : 'flex-end;')};
    column-gap: ${spacing(1)};
    padding-right: ${spacing(2)};
`

export const IncidentTypeText = styled.span`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
`
