import * as CommonStyles from '../common/common.styled'
import * as Styles from './vessel-overview.styled'
import {ZoomLevel} from '../../common/zoom-level/zoom-level'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {locationsSelector} from '../../../../../../store/state/locations/selectors'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

export function VesselOverview(): JSX.Element {
    const totalVessels = useTypedSelector(locationsSelector)?.length || 0
    const totalConfiguredAssets = useTypedSelector(nodesSelector)?.size || 0

    return (
        <CommonStyles.Section>
            <Styles.SectionHeader>
                <Styles.SectionTitle>Vessels Overview</Styles.SectionTitle>
            </Styles.SectionHeader>
            <CommonStyles.SectionContent id={`${PageType.MY_VESSELS}_filter-bar_section-content`}>
                <Styles.DataRow>
                    <Styles.Label>Total Vessels</Styles.Label>
                    <Styles.Value id="overview-total-vessels">{totalVessels}</Styles.Value>
                </Styles.DataRow>
                <Styles.DataRow>
                    <Styles.Label>Total Configured Assets</Styles.Label>
                    <Styles.Value id="overview-total-assets">{totalConfiguredAssets}</Styles.Value>
                </Styles.DataRow>
                <Styles.DataRow lastRow>
                    <ZoomLevel inFilterBar />
                </Styles.DataRow>
            </CommonStyles.SectionContent>
        </CommonStyles.Section>
    )
}
