import {NodeDataMap} from '../../../values/nodes/NodeData'
import AppState from '../../types/app-state'
import {LatestNodeDetails, LocationNodeArrayMapType, NodesReduxState} from './state'

export const nodesStateSelector = (state: AppState): NodesReduxState => state.nodes
export const nodesFetchingSelector = (state: AppState): boolean => state.nodes.isFetchingNodes
export const nodesSelector = (state: AppState): NodeDataMap => state.nodes.nodes
export const lastestNodeDetailsSelector = (state: AppState): LatestNodeDetails | undefined =>
    state.nodes.latestNodeDetails

export const nodesForLocationsSelector = (state: AppState): LocationNodeArrayMapType =>
    state.nodes.nodesForLocations
