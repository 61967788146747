import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {LicensesPageProvider} from './contexts/licenses-page-provider'
import * as Styled from './licenses-page.styled'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {Header} from './components/header'
import {LicenseListTable} from './license-table/license-list-table'
import {useEffect} from 'react'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'

export function LicensesPage(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.LICENSES))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LicensesPageProvider>
            <LicensesPageContent />
        </LicensesPageProvider>
    )
}

function LicensesPageContent(): JSX.Element {
    const {width} = useDimensions()
    return (
        <FixedPageTemplate>
            <Styled.WideContentHeaderArea width={width}>
                <Header text="Customer packages" />
            </Styled.WideContentHeaderArea>
            <Styled.WideContentArea width={width} fullHeight={true}>
                <Styled.ScrollableContent flexGrow>
                    <LicenseListTable />
                </Styled.ScrollableContent>
            </Styled.WideContentArea>
        </FixedPageTemplate>
    )
}
