import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const NoDataMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: ${spacing(4)} 0;
    ${mediumSmallFont()}
`
