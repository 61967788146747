import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT_REDUCED_COLUMN = 600

interface AssetInfoAreaProps {
    width: number
}

function getGridTemplate(width: number): string {
    if (width < BREAK_POINT_REDUCED_COLUMN) {
        return '"VESSEL OS" "VESSEL-VALUE OS-VALUE" "ASSET-VALUE OS-BUILD" "ASSET-VALUE-VALUE  OS-BUILD-VALUE" "IP-ADDRESS OS-VERSION" "IP-ADDRESS-VALUE OS-VERSION-VALUE"'
    }
    return "'VESSEL VESSEL-VALUE OS OS-VALUE' 'ASSET-VALUE ASSET-VALUE-VALUE OS-BUILD OS-BUILD-VALUE' 'IP-ADDRESS IP-ADDRESS-VALUE OS-VERSION OS-VERSION-VALUE'"
}

export const AssetInfoArea = styled.div<AssetInfoAreaProps>`
    display: grid;
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(5)};
`
