import {getIncidentWidgetTypeDisplayName} from './get-incident-widget-type-display-name'
import * as Styled from './incidents-report-options.styled'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import {IncidentWidgetType} from '../contexts/types/incident-widget-type'

export function IncidentsReportOptions(): JSX.Element {
    const {displayIncidentWidgetOnDashboard, selectedIncidentWidget} = useIncidentsWidget()
    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        displayIncidentWidgetOnDashboard(event.target.value as IncidentWidgetType)
    }
    return (
        <Styled.Wrapper id="incidents-widgets-options-wrapper">
            <Styled.Label>Selected widget</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={selectedIncidentWidget}
                    id="incidents-widgets_select"
                >
                    <option
                        key="default-option"
                        value={IncidentWidgetType.LATEST_INCIDENT}
                        id="incidents-widgets_default-option"
                    >
                        {getIncidentWidgetTypeDisplayName(IncidentWidgetType.LATEST_INCIDENT)}
                    </option>
                    {allIncidentWidgetsTypes?.map((value) => (
                        <option key={value} value={value} id={`incidents-widgets_${value}-option`}>
                            {getIncidentWidgetTypeDisplayName(value)}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
const allIncidentWidgetsTypes: IncidentWidgetType[] = [
    IncidentWidgetType.CURRENT_STATUS,
    IncidentWidgetType.CURRENT_ASSIGNMENT,
    IncidentWidgetType.TYPES_INCIDENTS,
    IncidentWidgetType.SEVERITY_INCIDENTS,
    IncidentWidgetType.RAISED_INCIDENTS_TRENDS,
    IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE,
    IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE,
]
