import styled from 'styled-components'
import {extraSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {narrowestLayout} from '../../cards/grid-helper'

function getBackgroundColor(incidentStatus: IncidentStatus, theme: CyberOwlTheme): string {
    switch (incidentStatus) {
        case IncidentStatus.NEW:
            return theme.incidents.incidentStatus.new
        case IncidentStatus.OPEN:
            return theme.incidents.incidentStatus.open
        case IncidentStatus.CLOSED:
        case IncidentStatus.CLOSED_FALSE_POSITIVE:
        case IncidentStatus.CLOSED_MONITOR:
            return theme.incidents.incidentStatus.closed
        default:
            return theme.incidents.incidentStatus.draft
    }
}
interface Props {
    width: number
}
export const IncidentStatusWrapper = styled.div<Props>`
    position: relative;
    display: flex;
    justify-content: ${(props) =>
        props.width && narrowestLayout(props.width) ? 'flex-start' : 'center'};
    column-gap: ${spacing(2)};
`

interface SquareProps {
    incidentStatus: IncidentStatus
}

export const Square = styled.span<SquareProps>`
    color: white;
    background-color: ${(props) => getBackgroundColor(props.incidentStatus, props.theme)};
    height: ${spacing(5.5)};
    width: ${spacing(12)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const StatusAnnotation = styled.div`
    ${extraSmallFont()}
    font-style: italic;
`
