import {ScrollableContent} from './scrollable-content.styled'
import * as Styles from './no-data.styled'

export function NoData(): JSX.Element {
    return (
        <ScrollableContent flexGrow>
            <Styles.Container id="no-data-component">No Data</Styles.Container>
        </ScrollableContent>
    )
}
