import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'
import {getHorizontalPagePadding} from '../../theme/helpers/page-layout'

interface HeaderProps {
    width?: number
}

export const Header = styled.h2<HeaderProps>`
    font-size: 30px;
    line-height: 40.85px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: ${spacing(2)} auto 0 auto;
    color: black;

    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;

    padding: 0
        ${(props) =>
            props.width
                ? props.width < 340
                    ? `${spacing(2)}`
                    : getHorizontalPagePadding(props.width)
                : 0};
`
