import ActionType from './action-type'
import * as Actions from './actions'
import {IndicatorData} from '../../../values/IndicatorData'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'

const INDICATORS_REST_URL = '/api/v1/indicators/summary'

export function fetchIndicators(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api) => {
        dispatch(requestIndicatorsAction())

        api.getAuth(INDICATORS_REST_URL, (data) => {
            const indicators: IndicatorData[] = data as IndicatorData[]
            dispatch(receiveIndicatorsAction(indicators))
        })
    }
}

function requestIndicatorsAction(): Actions.RequestIndicatorsAction {
    return {
        type: ActionType.REQUEST_INDICATORS,
    }
}

function receiveIndicatorsAction(indicators: IndicatorData[]): Actions.ReceiveIndicatorsAction {
    return {
        type: ActionType.RECEIVE_INDICATORS,
        payload: indicators,
    }
}
