import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const NoDataMessage = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 200px;
    padding: ${spacing(4)} 0;
`
