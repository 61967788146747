import {usePagedNmeaInventory} from '../../contexts/use-paged-nmea-inventory'
import {DetailPanelHeader} from '../table-view/nmea-side-panel/header/detail-panel-header'
import {ActivitySection} from '../table-view/nmea-side-panel/insights-section/insights-section'
import {NmeaDetailedSection} from '../table-view/nmea-side-panel/nmea-detailed-section/nmea-detailed-section'
import {NoDataModal} from '../table-view/nmea-side-panel/no-data-modal'
import * as Styled from './_styled/nmea-inventory-expanded.styled'

export function NMEAModalExpanded(): JSX.Element {
    const {findNmeaDetailsForModal} = usePagedNmeaInventory()
    if (!findNmeaDetailsForModal) {
        return <NoDataModal />
    }
    return (
        <Styled.DetailsExpandedSection>
            <DetailPanelHeader />
            <Styled.ContentWrapper>
                <NmeaDetailedSection />
                <ActivitySection />
            </Styled.ContentWrapper>
        </Styled.DetailsExpandedSection>
    )
}
