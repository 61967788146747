import {useTheme} from 'styled-components'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../templates/fixed-page/content-container-middle.styled'
import {ContentWrapper, SectionsWrapper} from '../incidents-report-page.styled'
import {DataActions} from './data-actions/data-actions'
import {StatusOfIncidentsRaised} from './status-of-incidents-raised/status-of-incidents-raised'
import {ContentArea} from './status-of-incidents-raised/status-of-incidents-raised.styled'
import {IncidentsTypesAndSeverities} from './incidents-types-and-severities/incidents-types-and-severities'
import {NewOpenIncidentsDetails} from './new-open-incidents-details/new-open-incidents-details'
import {usePagedIncidentsReport} from '../contexts/use-paged-incidents-report'
import {DataLoading} from '../../../components/data-loading/data-loading'
import {useRef} from 'react'
import {BoundsContext} from '../../../components/bounds-context/bounds-context'
import {IncidentsResponsePerformance} from './incidents-response-performance/incidents-response-performance'

export function Content(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const {loadedIncidentsDetails} = usePagedIncidentsReport()
    const layoutRef = useRef(null)

    return (
        <ContentWrapper id="incidents-report-page_content-wrapper">
            <DataActions />
            <BoundsContext.Provider value={{ref: layoutRef}}>
                <ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.commonTableViewPage.dataArea.background}
                    scrollable
                    id="incidents-page-top-filter-section"
                >
                    <SectionsWrapper>
                        <ContentArea width={width}>
                            <StatusOfIncidentsRaised />
                        </ContentArea>
                        <ContentArea width={width}>
                            <IncidentsTypesAndSeverities />
                        </ContentArea>
                        <ContentArea width={width}>
                            <IncidentsResponsePerformance />
                        </ContentArea>
                        <ContentArea width={width}>
                            {loadedIncidentsDetails ? <NewOpenIncidentsDetails /> : <DataLoading />}
                        </ContentArea>
                    </SectionsWrapper>
                </ContentContainerMiddle>
            </BoundsContext.Provider>
        </ContentWrapper>
    )
}
