import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {DEFAULT_OT_SOURCE_MODAL_DETAILS} from '../../../../../contexts/type/ot-sources-modal-details'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {OTDataInput} from './data-input'
import * as Styled from './ot-sources-section.styled'
import {SharedButton} from './shared-button/shared-button'

interface OTSourcesSectionProps {
    locationId: GuidType
}

export function OTSourcesSection({locationId}: OTSourcesSectionProps): JSX.Element {
    const {displayValue, detailExpanded, openSetOTSourcesModal} = useVesselManagement()
    return (
        <Panel>
            <ExpandPanelButton headingRow={VesselManagementDetailType.OTSources} />
            {detailExpanded.otSourcesExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <OTDataInput
                            label="Syslog"
                            dataValue={displayValue.otSources?.syslog ?? []}
                            locationId={locationId}
                        />
                        <OTDataInput
                            label="NMEA"
                            dataValue={displayValue.otSources?.nmea ?? []}
                            locationId={locationId}
                        />
                        <div style={{textAlign: 'center', marginTop: '10px'}}>
                            <SharedButton
                                label="Manual Add"
                                onClickFn={() =>
                                    openSetOTSourcesModal({
                                        ...DEFAULT_OT_SOURCE_MODAL_DETAILS,
                                        locationId: locationId,
                                    })
                                }
                            />
                        </div>
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
