import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useRef, useState} from 'react'
import {X} from 'react-feather'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../../../hooks/useOnClickOutside'
import {vesselTagsSelector} from '../../../../../../../store/state/vessel-tags/selectors'
import * as Styles from './tag-search.styled'
import {VesselTag} from '../../../../../../../components/vessel-tag/vessel-tag'
import {findRecommendationFromEntryTag} from '../../../../../../../components/vessel-filters/data-helper'
import {usePolicyManagement} from '../../../../../contexts/policy-management/use-policy-management'

export function VesselTagSearch(): JSX.Element {
    const [searchTag, setSearchTag] = useState<string>('')
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const existingTags = useTypedSelector(vesselTagsSelector)
    const {
        assignPolicyDialogState,
        setSearchVesselNameTerm,
        setSearchVesselTagTerm,
        setSearchVesselTerm,
        setShowXButton,
    } = usePolicyManagement()

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchTag(e.currentTarget.value)
        setSearchVesselTerm(e.currentTarget.value)
        if (e.currentTarget.value.length === 0) {
            setShowXButton(false)
            setSearchVesselNameTerm(e.currentTarget.value)
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setSearchVesselNameTerm(assignPolicyDialogState.searchVesselTerm)
            setRecommendationOpen(false)
            setShowXButton(true)
        }
    }

    function onSearchClick() {
        setSearchVesselNameTerm(assignPolicyDialogState.searchVesselTerm)
        setRecommendationOpen(false)
        setShowXButton(true)
    }

    function onXClick() {
        setSearchVesselTerm('')
        setSearchVesselNameTerm('')
        setShowXButton(false)
    }

    function onDeleteClick(tagToDelete: string) {
        const newsearchTagList = assignPolicyDialogState.searchVesselTagTerm?.filter(
            (element) => element !== tagToDelete,
        )
        setSearchVesselTagTerm(newsearchTagList)
    }

    const filteredUniqueResult = findRecommendationFromEntryTag(existingTags, searchTag).filter(
        (element) =>
            element.name !==
            assignPolicyDialogState.searchVesselTagTerm.find(
                (inTheFilterElement) => inTheFilterElement === element.name,
            ),
    )

    const showRecomandations = recommendationOpen && filteredUniqueResult?.length !== 0

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setRecommendationOpen(false))

    return (
        <Styles.Container>
            <Styles.SearchInput
                id="tags-search"
                placeholder="Filter by tag, vessel name"
                autoComplete="off"
                value={assignPolicyDialogState.searchVesselTerm}
                onChange={onChangeFn}
                onKeyPress={onEnterClick}
            />
            {assignPolicyDialogState.showXButton && (
                <Styles.XIcon onClick={() => onXClick()}>
                    <X onClick={() => onXClick()} height={20} width={20} />
                </Styles.XIcon>
            )}
            <Styles.SearchIcon onClick={() => onSearchClick()}>
                <Icon glyph="Search" height={20} width={20} />
            </Styles.SearchIcon>
            <Styles.TagResultsWrapper ref={clickOutside}>
                {showRecomandations && (
                    <Styles.TagResultsPopUp>
                        {filteredUniqueResult?.map((tag, index) => (
                            <VesselTag
                                key={index}
                                tagName={tag.name}
                                onClickFn={() => {
                                    setSearchVesselTagTerm([
                                        ...assignPolicyDialogState.searchVesselTagTerm,
                                        tag.name,
                                    ]),
                                        setSearchVesselTerm('')
                                    setRecommendationOpen(false)
                                }}
                            />
                        ))}
                    </Styles.TagResultsPopUp>
                )}
                {assignPolicyDialogState.searchVesselTagTerm?.map((tag, index) => (
                    <VesselTag key={index} tagName={tag} onDeleteFn={() => onDeleteClick(tag)} />
                ))}
            </Styles.TagResultsWrapper>
        </Styles.Container>
    )
}
