import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'
import {SoftwareInventoryResponse} from './software-inventory-response'
import {SoftwareStatus} from '../../../../store/state/software-inventory-filter/state'

export type DataSoftwareInventoryMap = Map<number, SoftwareInventoryResponse[]>
export type EditableCommentsMap = Map<string, boolean>

export interface PagedSoftwareInventoryState {
    loadingDataState: LoadingState
    loadingFilterState: LoadingState
    dataSoftwareInventoryMap: DataSoftwareInventoryMap | undefined
    totalNumberOfSoftwareInventory: number | undefined
    totalNumberOfPages: number | undefined
    selectedPage: number
    pageSize: number
    showFilterBar: boolean
    modalIdForSoftwareDetail: GuidType | null
    isProcessingSoftwareDetailsModal: ProcessingStatus
    findSoftwareDetailForModal: SoftwareInventoryResponse | undefined
    showChangeSoftwareStatusModalDisplay: boolean
    selectedStatusType: SoftwareStatus | null
    softwareStatus: SoftwareStatus
    modalCheckState: {forCompany: boolean; forAllSoftware: boolean}
    reportUrl: string | null
    showGenerateReportPopup: boolean
}

export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export enum SoftwareDetailType {
    Details = 'Details',
    Insights = 'Insights',
    Actions = 'Actions',
}
