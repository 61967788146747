import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns, getReducedGridTemplateColumns} from '../grid-helper'
interface Props {
    width: number
    showDetailsBtn: boolean
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showDetailsBtn
            ? getGridTemplateColumns(props.width)
            : getReducedGridTemplateColumns(props.width)};
    column-gap: ${spacing(2)};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    ${(props) => (props.showDetailsBtn ? 'flex: 1;' : '')};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
    cursor: pointer;
`
