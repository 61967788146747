import {SeverityLevelSquare, TableCellWrapper} from './_styled/severity-level.styled'
import {IncidentSeverityValue} from '../../../../../values/incident-response-values'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {showCards} from '../../../data-helpers'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface SeverityLevelProps {
    incidentStatus: IncidentStatus
    severityLevel: IncidentSeverityValue
    gridRow: number
    id: GuidType
    onClickFn: () => void
}

export function SeverityLevel({
    incidentStatus,
    severityLevel,
    gridRow,
    id,
    onClickFn,
}: SeverityLevelProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <TableCellWrapper gridColumn={5} gridRow={gridRow} id={id} onClick={onClickFn}>
            <SeverityLevelSquare
                withBorder={incidentStatus === IncidentStatus.NEW && !showCards(width)}
                severityLevel={severityLevel}
            >
                {severityLevel}
            </SeverityLevelSquare>
        </TableCellWrapper>
    )
}
