import styled from 'styled-components'

export const Button = styled.button`
    position: relative;
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`
