import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'

export const LinkButtonsWrapper = styled.div``

export const LinkButton = styled.a`
    background: none;
    border: none;
    ${smallFont()}
    color: ${(props) => props.theme.systemPage.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        background-color: ${(props) => props.theme.systemPage.link.hoverBackground};
        cursor: pointer;
    }
`
