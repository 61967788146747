import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const DataRow = styled.div`
    display: grid;
    grid-template-columns: auto 130px;
    grid-template-areas: 'DETAILS DOWNLOAD';
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
interface DataCellProps {
    gridArea?: string
}

export const DataCell = styled.div<DataCellProps>`
    flex: 1;
    grid-area: ${(props) => props.gridArea};
    padding: ${spacing(1)} 0;
`

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    ${mediumSmallFont()};
`
export const ModalTitle = styled(Label)`
    text-align: center;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const DescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
`
export const DescriptionRow = styled.div`
    display: flex;
    justify-content: space-between;
`
export const WarningMessage = styled.h2`
    text-align: flex-start;
    ${smallFont()}
    color: ${(props) => props.theme.incidents.incidentStatus.new};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-bottom: ${spacing(1)};
    margin: 0;
`
export const DescriptionMessage = styled.h2`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: 0;
    ${mediumSmallFont()};
`
export const ModalLabel = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const NotesSection = styled.div`
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(2)};
`

export const CardDataRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, [col-start] 1fr);
    grid-template-areas: 'ASSETS ITEMS';
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    ${mediumSmallFont()};
    align-items: flex-start;
`

interface ItemListWrapper {
    gridArea: string
}

export const ItemListWrapper = styled.div<ItemListWrapper>`
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`
