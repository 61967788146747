import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'
import {CyberOwlTheme} from '../../../../theme/theme'
import {IncidentSeverityValue} from '../../../../values/incident-response-values'
import {narrowLayoutFn} from '../../incidents-report-page.styled'

interface ContentAreaProps {
    width: number
    narrowLayoutForOpenFilter: boolean
}

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(5)};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    color: ${(props) => props.theme.incidents.dataRow.text};
    border: 5px solid #fff;
`
export const SectionContent = styled.div<ContentAreaProps>`
    display: flex;
    ${(props) => !props.narrowLayoutForOpenFilter && 'justify-content: space-between'};
    flex-direction: ${(props) =>
        props.width < 1000 || props.narrowLayoutForOpenFilter ? 'column' : 'row'};

    gap: 10px;
`
interface ContainerProps {
    width: number
}

export const TypesContainer = styled.div<ContainerProps>`
    background-color: #fff;
    padding: ${(props) => (narrowLayoutFn(props.width) ? '' : '15px')};
    width: ${(props) => getsectionWidth(props.width)}px;
`
export const SeverityContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background-color: #fff;
    width: ${(props) => getsectionWidth(props.width)}px;
    padding: ${(props) => (narrowLayoutFn(props.width) ? '' : '15px')};
    justify-content: space-between;
    flex: 1;
`
export const SectionText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const ToolTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 4px 8px #0000004d;
    border-radius: 4px;
    padding: 15px;
    color: ${(props) => props.theme.colors.text.default};
    min-width: 150px;
`
export const ToolTipSubTitle = styled.div`
    font-size: 12px;
    line-height: 20px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const ToolTipTitle = styled.h4`
    font-size: 14px;
    line-height: 19px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
interface ToolTipRectangleProps {
    severityLevel: IncidentSeverityValue
}
export const ToolTipRectangle = styled.div<ToolTipRectangleProps>`
    width: 10px;
    height: 10px;
    background-color: ${(props) => getBackgroundColor(props.severityLevel, props.theme)};
`

export const SeverityCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${(props) => props.theme.incidents.dataRow.text};
    ${smallFont()};
`
interface FilterCardProps {
    severityLevel: IncidentSeverityValue
    severityScore: number
    width: number
}
export const ColouredSeverityBar = styled.div<FilterCardProps>`
    height: 10px;
    width: ${(props) => (props.severityScore / 100) * getSeverityWidth(props.width)}px;
    background-color: ${(props) => getBackgroundColor(props.severityLevel, props.theme)};
`
export const SeverityLink = styled.span`
    ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const SeverityCount = styled(SeverityLink)`
    width: 30px;
`
export const SeverityText = styled.h2`
    ${smallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    width: 100px;
`
export function getBackgroundColor(
    severityLevel: string | undefined,
    theme: CyberOwlTheme,
): string {
    switch (severityLevel) {
        case 'LOW':
            return `${theme.incidents.incidentSeverity.active.low}`
        case 'MEDIUM':
            return `${theme.incidents.incidentSeverity.active.medium}`
        case 'HIGH':
            return `${theme.incidents.incidentSeverity.active.high}`
        case 'CRITICAL':
            return `${theme.incidents.incidentSeverity.active.critical}`
        default:
            return `inherit`
    }
}
export function getsectionWidth(width: number): number {
    return narrowLayoutFn(width) ? 360 : 740
}
function getSeverityWidth(width: number): number {
    return narrowLayoutFn(width) ? 200 : 400
}

export const WrapperRightSide = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
`
