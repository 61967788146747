import LoadingState from '../../../values/loading-state-enum'
import {SelfMonitoringEngineRuleMap} from '../../../values/self-monitoring-rule'
import AppState from '../../types/app-state'

export const isLoadingSelfMonitoringEngineRulesSelector = (state: AppState): boolean =>
    state.selfMonitoringEngineRules.loadingRules === LoadingState.RequestingData ||
    state.selfMonitoringEngineRules.loadingRules === LoadingState.NotPopulated

export const selfMonitoringEngineRulesLoadingStateSelector = (state: AppState): LoadingState =>
    state.selfMonitoringEngineRules.loadingRules

export const selfMonitoringEngineRulesMapSelector = (
    state: AppState,
): SelfMonitoringEngineRuleMap => state.selfMonitoringEngineRules.data
