import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

interface DataActionsAreaProps {
    width: number
    noActiveControl?: boolean
}
export const DataActionsArea = styled.div<DataActionsAreaProps>`
    display: flex;
    flex-direction: ${(props) =>
        props.noActiveControl ? 'row' : props.width < 700 ? 'column' : 'row'};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`
export const Container = styled.div`
    display: flex;
    padding: ${spacing(4)} 0;
    background-color: ${(props) => props.theme.colors.background.page};
    justify-content: space-between;
`
