import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const VesselList = styled.div`
    background-color: ${(props) => props.theme.dashboard.vesselList.backgroundColor};
    flex: 1;
    padding: 0 ${spacing(6)};
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
`
