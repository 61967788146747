import {TableHeaderCell, TableHeaderContent} from './_styled/header-column.styled'
import QuestionCircle from '../../../../../@assets/icons/question-circle.svg'
import {useRef, useState} from 'react'
import {TrustDeviceRecommendation} from './trust-device-recommendation'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'

interface SortableColumnProps {
    gridColumn: number
}
export function TrustDeviceColumn({gridColumn}: SortableColumnProps): JSX.Element {
    const [viewRecommendation, setViewRecommendation] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setViewRecommendation(false)
    })

    return (
        <TableHeaderCell id="trustedColumn" gridColumn={gridColumn} ref={clickOutside}>
            <TableHeaderContent>
                Trust this device?
                <img
                    src={QuestionCircle}
                    style={{
                        position: 'absolute',
                        right: '-20px',
                        top: '-10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => setViewRecommendation(!viewRecommendation)}
                />
                {viewRecommendation && <TrustDeviceRecommendation />}
            </TableHeaderContent>
        </TableHeaderCell>
    )
}
