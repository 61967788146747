import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
interface ShowFilterBarProps {
    showFilterBar: boolean
    width: number
}
export const Section = styled.div<ShowFilterBarProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.showFilterBar ? '375px' : props.width > 540 ? '440px' : '300px')};
    ${mediumSmallFont()}
`
export const ScoreSection = styled.div`
    display: flex;
    column-gap: 15px;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`
export const Image = styled.img`
    width: 40px;
    height: 40px;
`

export const TitleWrapper = styled.div`
    display: flex;
    column-gap: 15px;
    padding: 20px 0;
    font-size: 20px;
    align-items: center;
`
interface ScoreProps {
    showFilterBar: boolean
}
export const Score = styled.div<ScoreProps>`
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1;
    position: relative;
    font-size: 40px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: rgba(57, 65, 135, 1);
    width: ${(props) => (props.showFilterBar ? '140px' : '180px')};
    ${(props) => !props.showFilterBar && 'padding-left:20px;'};
`

export const Text = styled.div<ScoreProps>`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    position: absolute;
    top: 25px;
    ${(props) => (props.showFilterBar ? 'left: 35px;' : 'right:0;')}
`
export const SubTitle = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const ScoreTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const SubTitleWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 90px;
    color: rgba(57, 65, 135, 1);
`
