import {Filter} from 'react-feather'
import * as Styled from './_styled/button.styled'

interface FilterBarButtonProps {
    onClick: () => void
    active?: boolean
}

export function FilterBarButton({onClick, active = false}: FilterBarButtonProps): JSX.Element {
    return (
        <Styled.Container onClick={onClick} id="filter-bar-button">
            <Styled.SearchIcon active={active}>
                <Filter height={16} width={16} />
            </Styled.SearchIcon>
            <Styled.TextWrapper active={active} id="filter-bar-button-text">
                <Styled.Text>Filter</Styled.Text>
            </Styled.TextWrapper>
        </Styled.Container>
    )
}
