import styled from 'styled-components'
import {getColor as getMetricTypeColor} from '../../../../../metrics-beta/components/metrics-summaries/score-bar.styled'
import {smallFont} from '../../../../../../theme/font-styles'

export const BenchmarkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`
export const ScoreSection = styled.div`
    display: flex;
    column-gap: 25px;
    justify-content: center;
    align-items: center;
    width: 130px;
    position: relative;
`
interface BenchmarkScoreProps {
    metricType: string
}
export const BenchmarkScore = styled.div<BenchmarkScoreProps>`
    position: relative;
    font-size: 30px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => getMetricTypeColor(props.metricType)};
`
export const PercentagWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: -18px;
    font-size: 20px;
`
interface TippyWrapperProps {
    positionRightText: boolean
}
export const TippyWrapper = styled.div<TippyWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    ${(props) => (props.positionRightText ? 'right: 0' : 'left: 0')};
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    z-index: 100;
    width: max-content;
`
