import {createSelector} from 'reselect'
import {queryStringObjectSelector} from '../../../../../store/routerSelectors'
import qs from 'qs'
import {
    etsTrend12HourDataSelector,
    etsTrend24HourDataSelector,
    etsTrend7DaysDataSelector,
    etsTrend30DaysDataSelector,
    etsTrend90DaysDataSelector,
} from '../../../../../store/state/ets-trend/selectors'
import {WidgetOutputModel, WidgetOutputTagScoreModel} from '../widget.model'
import {
    LAST_12H,
    LAST_24H,
    LAST_30D,
    LAST_7D,
    LAST_90D,
    TimeRange,
} from '../../../../../values/TimeRange'
import {EtsTrendScore} from '../../../../../values/ets-trend-score/EtsTrendScore'
import moment from 'moment'
import {
    locationTagScore12HourDataSelector,
    locationTagScore24HourDataSelector,
    locationTagScore30DaysDataSelector,
    locationTagScore7DaysDataSelector,
    locationTagScore90DaysDataSelector,
} from '../../../../../store/state/location-tag-score/selectors'
import {LocationTagScore} from '../../../../../values/location-tag-score'

function getGraphType(query: qs.ParsedQs | undefined): string {
    if (!query) {
        return '30d'
    }

    const value = query['graph']

    if (!value) {
        return '30d'
    }

    return (value as string).toLowerCase() ?? '30d'
}

function getTimeRange(graphType: string): TimeRange {
    switch (graphType) {
        case '12h':
            return LAST_12H
        case '24h':
            return LAST_24H
        case '7d':
            return LAST_7D
        case '90d':
            return LAST_90D
        case '30d':
        default:
            return LAST_30D
    }
}

export type EtsTrend = EtsTrendScore | LocationTagScore

function getData(
    timeRange: TimeRange,
    ets12HourTrend: EtsTrend[],
    ets24HourTrend: EtsTrend[],
    ets7DaysTrend: EtsTrend[],
    ets30DaysTrend: EtsTrend[],
    ets90DaysTrend: EtsTrend[],
): EtsTrend[] {
    switch (timeRange) {
        case LAST_12H:
            return ets12HourTrend
        case LAST_24H:
            return ets24HourTrend
        case LAST_7D:
            return ets7DaysTrend
        case LAST_90D:
            return ets90DaysTrend
        case LAST_30D:
        default:
            return ets30DaysTrend
    }
}

function getStartTime(timeRange: TimeRange, endTime: moment.Moment) {
    switch (timeRange) {
        case LAST_12H:
            return endTime.clone().subtract(12, 'hours')
        case LAST_24H:
            return endTime.clone().subtract(24, 'hours')
        case LAST_7D:
            return endTime.clone().subtract(7, 'days')
        case LAST_90D:
            return endTime.clone().subtract(90, 'days')
        case LAST_30D:
        default:
            return endTime.clone().subtract(30, 'days')
    }
}

export const widgetDataReselector = createSelector(
    etsTrend12HourDataSelector,
    etsTrend24HourDataSelector,
    etsTrend7DaysDataSelector,
    etsTrend30DaysDataSelector,
    etsTrend90DaysDataSelector,
    queryStringObjectSelector,
    (
        ets12HourTrend,
        ets24HourTrend,
        ets7DaysTrend,
        ets30DaysTrend,
        ets90DaysTrend,
        queryString,
    ): WidgetOutputModel => {
        const timeRange = getTimeRange(getGraphType(queryString))
        const data = getData(
            timeRange,
            ets12HourTrend,
            ets24HourTrend,
            ets7DaysTrend,
            ets30DaysTrend,
            ets90DaysTrend,
        )

        const endTime = moment()
        const startTime = getStartTime(timeRange, endTime)
        return {
            timeRange,
            data,
            startTime: startTime.valueOf(),
            endTime: endTime.valueOf(),
        } as WidgetOutputModel
    },
)

export const tagScoreDataReselector = createSelector(
    locationTagScore12HourDataSelector,
    locationTagScore24HourDataSelector,
    locationTagScore7DaysDataSelector,
    locationTagScore30DaysDataSelector,
    locationTagScore90DaysDataSelector,
    queryStringObjectSelector,
    (
        locationTagScore12HourTrend,
        locationTagScore24HourTrend,
        locationTagScore7DaysTrend,
        locationTagScore30DaysTrend,
        locationTagScore90DaysTrend,
        queryString,
    ): WidgetOutputTagScoreModel => {
        const timeRange = getTimeRange(getGraphType(queryString))
        const data = getData(
            timeRange,
            locationTagScore12HourTrend,
            locationTagScore24HourTrend,
            locationTagScore7DaysTrend,
            locationTagScore30DaysTrend,
            locationTagScore90DaysTrend,
        )

        const endTime = moment()
        const startTime = getStartTime(timeRange, endTime)
        return {
            timeRange,
            data,
            startTime: startTime.valueOf(),
            endTime: endTime.valueOf(),
        } as WidgetOutputTagScoreModel
    },
)
