import {Check} from 'react-feather'
import {DataCell} from './data-cell'
import {Input} from './data-cell.styled'

interface DataInputProps {
    label: string
    gridArea?: string
    initialValue: string | string[] | number
    setChange: (value: string) => void
    changed: boolean
}

export function DataInput({
    label,
    gridArea,
    initialValue,
    setChange,
    changed,
}: DataInputProps): JSX.Element {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setChange(e.target.value)
    }

    return (
        <DataCell label={label} gridArea={gridArea}>
            <Input value={initialValue} onChange={onChangeHandler} isChanged={changed} />
            {changed && <Check color={'green'} width={19} height={19} />}
        </DataCell>
    )
}
