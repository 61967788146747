import {Dispatch, useContext} from 'react'
import {PagedNmeaInventoryContext} from './paged-nmea-inventory-context'
import {usePagedNmeaInventoryResult} from './use-paged-nmea-inventory-output'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {warn} from '../../../helpers/logging'
import {REST} from '../../..'
import {SortColumnType} from './types/nmea-inventory-sort-type'
import LoadingState from '../../../values/loading-state-enum'
import {GuidType} from '../../../values/generic-type-defintions'
import {NmeaInventoryResponse} from './types/nmea-inventory-response'

const NMEA_INVENTORY_ENDPOINT = 'nmea/api/v1/nmea'

export function usePagedNmeaInventory(): usePagedNmeaInventoryResult {
    const {state, dispatch} = useContext(PagedNmeaInventoryContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedNmeaInventory must be used within a PagedNmeaInventoryContext')
    }

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())
        state.modalIdForNmeaDetails != null && dispatch(ActionCreators.closeNmeaDetailsModal())
        getData(dispatch, 0, state.pageSize, state.sortColumn)
    }

    function selectPage(requestedPage: number | undefined): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }

        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))
        getData(dispatch, offset, state.pageSize, state.sortColumn)
    }

    function setSortByColumn(sortColumn: SortColumnType): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        const offset = state.selectedPage * state.pageSize
        dispatch(ActionCreators.sortColumnAction(sortColumn))
        getData(dispatch, offset, state.pageSize, sortColumn)
    }

    function showNMEADetailModal(nmeaModalId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNmeaModalId(nmeaModalId))
    }

    function closeNmeaDetailsModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeNmeaDetailsModal())
    }

    const hasData = state.totalNumberOfNmeaInventory != undefined
    const dataNmeaInventory = hasData
        ? state.dataNmeaInventoryMap?.get(state.selectedPage || 0) ?? []
        : []
    const findNmeaDetailsForModal = dataNmeaInventory?.find((nmeaItem) => {
        const identity = `${nmeaItem?.talkerId}-${nmeaItem?.sentenceId}-${nmeaItem?.relayIp}`
        return identity === state.modalIdForNmeaDetails
    })
    return {
        loadingDataState: state.loadingDataState,
        pageSize: state.pageSize,
        totalNumberOfNmeaInventory: state.totalNumberOfNmeaInventory,
        selectedPage: state.selectedPage,
        totalNumberOfPages: state.totalNumberOfPages,
        dataNmeaInventory: dataNmeaInventory,
        refreshData: loadInitialPage,
        selectPage: hasData ? selectPage : null,
        isFilterLoading: state.loadingFilterState !== LoadingState.Loaded,
        sortColumn: state.sortColumn,
        setSortByColumn,
        modalIdForNmeaDetails: state.modalIdForNmeaDetails,
        showNMEADetailModal,
        findNmeaDetailsForModal: findNmeaDetailsForModal,
        closeNmeaDetailsModal,
    }
}

async function getData(
    dispatch: Dispatch<AllActions>,
    offset: number,
    count: number,
    sortColumn: SortColumnType,
): Promise<void> {
    try {
        const response = await REST.post(
            `${NMEA_INVENTORY_ENDPOINT}/find`,
            {
                orderBy: {column: sortColumn.orderBy, isAscending: sortColumn.orderAscending},
                pagination: {pageSize: count, pageOffset: offset},
                start: '-1y',
            },
            {
                timeout: 60000, // Increase the timeout
            },
        )

        dispatch(
            ActionCreators.receivedRequestedPageData(
                response.data.data ?? new Array<NmeaInventoryResponse>(),
                response.data.totalNumberOfItems ?? 0,
                response.data.totalNumberOfPages ?? 0,
            ),
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        dispatch(ActionCreators.receivedRequestedPageData(new Array<NmeaInventoryResponse>(), 0, 0))
    }
}
