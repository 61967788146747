import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {GridLayout} from '../../grid-layout.enum'

interface BottomSectionProps {
    gridLayout: GridLayout
}

function getLabelWidth(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.ONE_COLUMN_LARGE:
            return '90px'
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '80px'
        default:
            return '70px'
    }
}

export const BottomSection = styled.div<BottomSectionProps>`
    background-color: ${(props) =>
        props.theme.dashboard.vesselList.assetDetail.expanded.backgroundColor};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0px 1px 2px #00000033;
    font-size: 12px;
    line-height: 17px;
    padding: ${spacing(4)} ${spacing(3)} ${spacing(4)} ${spacing(3)};
    display: grid;
    grid-template-columns: ${(props) => getLabelWidth(props.gridLayout)} auto 120px;
    grid-template-rows: auto;
    row-gap: ${spacing(0.25)};
    margin-left: ${spacing(1)};
    margin-right: ${spacing(1)};
    align-items: center;
`
