import styled from 'styled-components'

interface TypeProps {
    width: number
    last?: boolean
}

export const Type = styled.div<TypeProps>`
    flex-grow: 1;
    padding-bottom: ${(props) => (props.width > 400 || props.last ? '0' : '5px')};
`
