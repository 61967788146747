import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {narrowLayoutFn} from '../grid-helper'

interface CardContainerProps {
    width: number
}

export const CardContainer = styled.div<CardContainerProps>`
    display: flex;
    flex-direction: ${(props) => (!narrowLayoutFn(props.width) ? 'row' : 'column')};
    gap: 3px;
    margin-top: ${spacing(1)};
    padding: 2px;
`
