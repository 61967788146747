import {Check} from 'react-feather'
import {AreaWrapper} from './tag-limit.styled'

import {TagSearch} from '../tag-search/tag-search'
import {DataCell} from '../../user-list-table/user-details-modal/data-input/data-cell'

interface TagFilterProps {
    label: string
    gridArea?: string
    changed: boolean
}

export function TagFilter({label, gridArea, changed}: TagFilterProps): JSX.Element {
    return (
        <DataCell label={label} gridArea={gridArea}>
            <AreaWrapper>
                <TagSearch />
            </AreaWrapper>
            {changed && <Check color={'green'} width={19} height={19} />}
        </DataCell>
    )
}
