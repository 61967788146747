import styled, {css, keyframes} from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Wrapper = styled.div`
    position: relative;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const FilterHeadingRow = styled.div`
    font-size: 16px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
export const SubHeadingRow = styled.div`
    position: absolute;
    top: -5px;
    left: 115px;
    font-size: 12px;
    font-style: italic;
    font-weight: ${(props) => props.theme.font.weight.normal};
`
export const SelectedColumnsWrapper = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    padding: 5px;
    overflow-x: hidden;
`
export const ColumnContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    gap: 3px;
`
const blinkAnimation = keyframes`
  0%, 100% {
    background-color: rgba(31, 136, 229, 0.1);
  }
  50% {
    background-color: inherit;
  }
`
interface SelectionProcessWrapperProps {
    highlightedFilerValue: boolean
}
export const SelectionProcessWrapper = styled.div<SelectionProcessWrapperProps>`
    animation: ${(props) =>
        props.highlightedFilerValue
            ? css`
                  ${blinkAnimation} 1s infinite
              `
            : 'none'};
`

export const SelectionIndicator = styled.div`
    position: relative;
    font-size: 12px;
    font-style: italic;
    font-weight: ${(props) => props.theme.font.weight.normal};
    cursor: pointer;
    padding: ${spacing(1)};
`

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.normal};
`
export const ColumnsResultsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(2)};
`

export const OpenColumnsRecommendationDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    gap: ${spacing(2)};
    padding: 3px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
`

export const TagResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 3px;
    background-color: #f1f6fb;
    border-radius: 4px;
    padding: 3px ${spacing(1)};
    height: 23px;
`

export const TagItem = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 280px;
`

export const XIcon = styled.div`
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
`
export const EmptyBox = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    overflow-x: hidden;
    color: white;
    height: 30px;
    cursor: pointer;
`
