import {StatusChangeOutputModel} from '../../../models/status-change.model'
import * as Styled from './incident-status-change-record.styles'
import STATUS_CHANGE_ICON from '../../../../../@assets/icons/status-updated.svg'
import {formatDate} from '../../../../../helpers/formatting'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {DisplayFormatType} from '../../../contexts/types/display-format-type'

interface IncidentStatusProps {
    statusRecord: StatusChangeOutputModel
    displayFormat: DisplayFormatType
}
export function IncidentStatusChangeRecord({
    statusRecord,
    displayFormat,
}: IncidentStatusProps): JSX.Element {
    function displayStatus(status: string): string {
        switch (status) {
            case 'DRAFT':
                return 'Draft'
            case 'NEW':
                return 'New'
            case 'OPEN':
                return 'Open'
            case 'CLOSED':
                return 'Closed - Resolved'
            case 'CLOSED_FALSE_POSITIVE':
                return 'Closed - No risk'
            case 'CLOSED_MONITOR':
                return 'Closed - Monitor'
            default:
                return status
        }
    }

    const isClosedResolved =
        statusRecord.newStatus === IncidentStatus.CLOSED ||
        statusRecord.newStatus === IncidentStatus.CLOSED_FALSE_POSITIVE ||
        statusRecord.newStatus === IncidentStatus.CLOSED_MONITOR

    const isAutoReopen = statusRecord.isAutoReopened

    return (
        <Styled.StatusChangeWrapper id={`incident-status-record-${statusRecord.id}-wrapper`}>
            <Styled.HeaderWrapper>
                <Styled.Icon src={STATUS_CHANGE_ICON} />
                <Styled.When id={`incident-status-record-when-${statusRecord.id}`}>
                    {formatDate(statusRecord?.when) ?? 'Unknown'}
                </Styled.When>
                <Styled.Who id={`incident-status-record-who-${statusRecord.id}`}>
                    {isAutoReopen ? 'Cyberowl Threat Analyst' : statusRecord.username}
                </Styled.Who>
                {!statusRecord.isViewed && <Styled.NewSquare>NEW</Styled.NewSquare>}
            </Styled.HeaderWrapper>
            <Styled.UpdateMessage displayColumn={displayFormat === 'modal' && isClosedResolved}>
                {isAutoReopen ? (
                    <Styled.UpdateMessageWrapper>
                        <div>Status update: It was </div>
                        <Styled.Status
                            status={statusRecord.newStatus}
                            style={{paddingRight: '5px', paddingLeft: '5px'}}
                            id={`incident-status-record-displayStatus-Reopened-${statusRecord.id}`}
                        >
                            {displayStatus('Reopened')}
                        </Styled.Status>
                        <div> automatically</div>
                    </Styled.UpdateMessageWrapper>
                ) : (
                    <>
                        <div>Status update: Incident was marked as:</div>
                        <Styled.Status
                            status={statusRecord.newStatus}
                            id={`incident-status-record-displayStatus-${statusRecord.newStatus}-${statusRecord.id}`}
                        >
                            {displayStatus(statusRecord.newStatus)}
                        </Styled.Status>
                    </>
                )}
            </Styled.UpdateMessage>
        </Styled.StatusChangeWrapper>
    )
}
