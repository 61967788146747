import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {formattedUSBDeviceDetailsCachedReselector} from '../../../reselector/formatted-software-details.cached-reselector'
import {getUIDataorHosts} from '../../data-helpers'
import * as Styled from './vessel-details.styled'
import {NodeData} from '../../../../../values/nodes/NodeData'
import {AssetVersion} from '../../../contexts/types/software-inventory-response'
import NEW_VESSEL_ICON from '../../../../../@assets/icons/new-vessel-icon.svg'
interface Props {
    locationId: LocationIdType
    associatedAsset: NodeData | undefined
    assetVersion: AssetVersion | undefined
    title: string
    footerValue: string | null
    footerPrefix: string
}

export function VesselDetails({
    locationId,
    associatedAsset,
    assetVersion,
    title,
    footerValue,
    footerPrefix,
}: Props): JSX.Element {
    const model = useTypedSelector((state) =>
        formattedUSBDeviceDetailsCachedReselector(state, locationId),
    )
    const givenAsset = model.hosts
        ?.filter((element) => element.identity === associatedAsset?.node)
        ?.map((givenAsset) => getUIDataorHosts(givenAsset, assetVersion))[0]

    return (
        <Styled.Cell id="software-inventory-details-modal_activity-content_VesselDetails">
            <Styled.HostsWrapper>
                <Styled.DataCellValue id="VesselDetails-title">{title}</Styled.DataCellValue>
            </Styled.HostsWrapper>
            <Styled.VesselDetails>
                <Styled.Details>
                    <Styled.DetailRow>
                        <Styled.TitleCell id={`givenAsset-name-${model.id}`}>
                            {givenAsset.hostName}
                        </Styled.TitleCell>
                        {footerValue && (
                            <Styled.RightHandCell>
                                {footerPrefix}
                                <Styled.AssetTimeStampValue
                                    id={`givenAsset-footerValue-${model.id}`}
                                >
                                    {footerValue}
                                </Styled.AssetTimeStampValue>
                            </Styled.RightHandCell>
                        )}
                    </Styled.DetailRow>
                </Styled.Details>
                <Styled.HostnameDetails>
                    <Styled.DetailRow>
                        <Styled.SubTitleCell id={`vessel-name-${model.id}`}>
                            <img src={NEW_VESSEL_ICON} style={{marginRight: '3px'}} />
                            {model.name}
                        </Styled.SubTitleCell>

                        <Styled.SubTitleCell id={`vessel-shipType-${model.id}`}>
                            {model.shipType}
                        </Styled.SubTitleCell>
                    </Styled.DetailRow>
                </Styled.HostnameDetails>
            </Styled.VesselDetails>
        </Styled.Cell>
    )
}
