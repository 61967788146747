import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'

interface LabelProps {
    gridArea: string
    width?: number
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    margin: 0;
    width: ${(props) => props.width ?? 60}px;
`

interface ValueProps {
    gridArea: string
    cardView?: boolean
    width?: number
}

export const Value = styled.div<ValueProps>`
    display: flex;
    grid-area: ${(props) => props.gridArea};
    max-width: ${(props) => (props.cardView ? '100%' : `${props.width}px`)};
`

export const Text = styled.h3`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    color: ${(props) => props.theme.colors.text.default};
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
