import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const DropdownWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
`
export const LogoImage = styled.img`
    border-radius: ${spacing(1)};
    width: 120px;
    height: 30px;
`
