import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

export const SwitchToggle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${(props) => props.theme.font.weight.semibold};
`

interface ToggleButtonProps {
    selectable?: boolean
}

export const ToggleButton = styled.div<ToggleButtonProps>`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`

type toggleProps = {
    disabled?: boolean
}
export const Wrapper = styled.div<toggleProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`

interface TitleProps {
    categoryLevel?: boolean
}

export const Title = styled.div<TitleProps>`
    ${mediumSmallFont()}
    font-weight: ${(props) =>
        props.categoryLevel ? props.theme.font.weight.semibold : props.theme.font.weight.normal};
`
