import {Link} from 'react-router-dom'
import styled from 'styled-components'

// zIndex here to bring in front of graph (which has a minus margin to bring up, but puts it technically over bottom of button)
export const ClickableLink = styled(Link)`
    width: 65px;
    padding: 10px 0;
    text-align: center;
    color: ${(props) => props.theme.dashboard.graphButtons.selectable.text};
    z-index: +1;
    &:focus,
    &:visited,
    &:link,
    &:active {
        color: ${(props) => props.theme.dashboard.graphButtons.selectable.text};
        text-decoration: none;
    }

    &:hover {
        color: ${(props) => props.theme.dashboard.graphButtons.hover.text};
        background-color: ${(props) => props.theme.dashboard.graphButtons.hover.background};
        text-decoration: none;
    }
`
