import {useRef} from 'react'

import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {BoundsContext} from '../../../../components/bounds-context/bounds-context'
import {IncidentsCardsContent} from './incidents-cards-content'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {FilterBar} from '../filter-bar/filter-bar'
import * as Styles from '../filter-bar/filter-bar.styled'
import {CloseFilterButton} from '../filter-bar/close-filter-button/close-filter-button'

export function IncidentCards(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar} = usePagedIncidents()
    const theme = useTheme()
    const layoutRef = useRef(null)
    if (showFilterBar) {
        return (
            <Styles.FilterBarCardsWrapper width={width}>
                <FilterBar />
                <Styles.BottomCloseFilterBar>
                    <CloseFilterButton text="Close Filter" />
                </Styles.BottomCloseFilterBar>
            </Styles.FilterBarCardsWrapper>
        )
    } else {
        return (
            <BoundsContext.Provider value={{ref: layoutRef}}>
                <ContentContainerMiddle
                    ref={layoutRef}
                    width={width}
                    scrollable
                    backgroundColor={theme.incidents.dataArea.background}
                >
                    <IncidentsCardsContent />
                </ContentContainerMiddle>
            </BoundsContext.Provider>
        )
    }
}
