import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import {StyledButton} from '../shared/button.styled'
import {getGridType, showOneColNarrow} from '../shared/grid-layout-helper'
import {OtherVesselsListCards} from './vessel-list-card/other-vessels-list-cards'
import {OtherVesselsListTable} from './vessel-list-table/other-vessels-list-table'
import * as Styled from './vessels-list-modal.styled'

interface ModalContentProps {
    code: string
}

export function ModalContent({code}: ModalContentProps): JSX.Element {
    const {width} = useDimensions()
    const {setValue: setModalOpen} = useToggle()
    return (
        <Styled.ModalInner>
            <Styled.ContentArea>
                <Styled.ModalHeader>
                    <StyledButton onClick={() => setModalOpen(false)}>Cancel</StyledButton>
                </Styled.ModalHeader>
                {showOneColNarrow(getGridType(width)) ? (
                    <OtherVesselsListCards code={code} />
                ) : (
                    <OtherVesselsListTable code={code} />
                )}
            </Styled.ContentArea>
        </Styled.ModalInner>
    )
}
