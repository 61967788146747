import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 4px 8px #0000004d;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
`
