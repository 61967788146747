import moment from 'moment'
import {LocationIdType} from '../../../store/state/locations/state'
import {spacing} from '../../../theme/spacing'
import {NodeDataMap} from '../../../values/nodes/NodeData'
import {
    FormattedHostDataForUSBDevice,
    USBDeviceModelOutput,
} from '../contexts/types/usb-device-model-output'
import {ListOfAssociatedAssets, USBDevicesResponse} from '../contexts/types/usb-devices-response'
import {
    DeviceStatus,
    SortColumnType,
    TimestampFilter,
    USBDeviceType,
    USBDevicesSortType,
    UsbInventoryFilterReduxState,
} from '../../../store/state/usb-inventory-filter/state'
import {UsbInventoryFilter} from '../../../values/user-preferences/usb-inventory-filter'
import {isEqual} from 'lodash'
import CIRCLE_CHECKED from '../../../@assets/icons/circle-checked.svg'
import CIRCLE_X from '../../../@assets/icons/circle-x.svg'
import CIRCLE_UNKNOWN from '../../../@assets/icons/circle-unknown.svg'
import {UsbDeviceDetailsOutputModel} from '../reselector/usb-device-details.model'
import {User} from '../../../store/state/users/state'
import {getUserDescription} from '../../../helpers/getUserDescription'
import {formatDate} from '../../../helpers/formatting'
import {getListOfVesselsForGivenAssets} from '../contexts/context-helper'
import {StringUtils} from '../../../utils/Utils'

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier ? width < 1260 : width < 1500
}
export function getGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(2)} 60px 160px 160px 160px 160px 120px auto 130px 200px ${spacing(2)}`
    }
    if (width > 1510) {
        return `${spacing(2)} 60px 150px 150px 150px 150px 120px auto 130px 190px ${spacing(2)}`
    }
    if (width > 1400) {
        return `${spacing(2)} 60px 130px 130px 130px 140px 120px auto 120px 180px ${spacing(2)}`
    }
    if (width > 1300) {
        return `${spacing(1)} 50px 130px 130px 130px 140px 110px auto 120px 170px ${spacing(1)}`
    }
    return `${spacing(1)} 50px 120px 120px 130px 130px 110px auto 120px 160px ${spacing(1)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1700) {
        return `${spacing(1)} 50px 130px 130px 130px 130px 110px 150px 120px 155px ${spacing(1)}`
    }
    if (width > 1600) {
        return `${spacing(1)} 50px 120px 110px 120px 130px 110px 140px 120px 140px ${spacing(1)}`
    }
    return `0 50px 120px 110px 100px 130px 90px 125px 110px 110px 0`
}

export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.orderBy) {
        return false
    }
    switch (currentSortColumn.orderAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}

export function showFilterBarOnScreen(width: number): boolean {
    return width > 1000
}
export function calculateNumberOfVesselsForGivenAssets(
    listOfAssociatedAssets: ListOfAssociatedAssets[] | undefined,
    nodes: NodeDataMap,
): number {
    if (!listOfAssociatedAssets) {
        return 0
    }

    const listOfLocationsIds: LocationIdType[] = listOfAssociatedAssets?.map(
        (element) => nodes?.get(element.identity)?.location ?? '',
    )

    const uniqueLocationIds = [...new Set(listOfLocationsIds)]
    return uniqueLocationIds.filter((element) => element !== '').length ?? 0
}

export function getFormattedUSBDeviceData(
    usbDevice: USBDevicesResponse,
    nodes: NodeDataMap,
): USBDeviceModelOutput {
    return {
        identity: usbDevice.identity,
        vendorName: usbDevice.vendorName ?? 'Unknown',
        productName: usbDevice.productName ?? 'Unknown',
        deviceIdentity: usbDevice.deviceId ?? 'Unknown',
        deviceType: formatDeviceType(usbDevice.deviceType),
        deviceStatus: usbDevice.deviceStatus ?? 'Unknown',
        firstSeenTimestamp: moment(usbDevice.firstSeenTimestamp).fromNow() ?? 'Unknown',
        lastSeenTimestamp: moment(usbDevice.lastSeenTimestamp).fromNow() ?? 'Unknown',
        volumeLabels:
            usbDevice?.volumes && usbDevice?.volumes.length > 0
                ? usbDevice?.volumes.map((volume) => volume.label).join(', ')
                : 'Not available',
        purpose: usbDevice.purpose,
        totalNumberOfHostsPerItem: usbDevice.assets?.length ?? 0,
        totalNumberOfVesselsPerItem: calculateNumberOfVesselsForGivenAssets(
            usbDevice.assets,
            nodes,
        ),
    }
}

export function getFormattedUSBDeviceModalData(
    usbDevice: USBDevicesResponse,
    nodes: NodeDataMap,
    users: User[],
    isInternalUser: boolean,
): UsbDeviceDetailsOutputModel {
    return {
        identity: usbDevice.identity,
        vendorIdentity: usbDevice.vendorIdentity,
        vendorName: usbDevice.vendorName ?? 'Not available',
        productIdentity: usbDevice.productIdentity,
        productName: usbDevice.productName ?? 'Not available',
        deviceId: usbDevice.deviceId ?? 'Not available',
        deviceType: formatDeviceType(usbDevice.deviceType),
        deviceStatus: usbDevice.deviceStatus
            ? usbDevice.deviceStatus === DeviceStatus.rejected
                ? 'Unwanted'
                : StringUtils.capitaliseString(usbDevice.deviceStatus)
            : 'Not available',
        firstSeenTimestamp: moment(usbDevice.firstSeenTimestamp).fromNow() ?? 'Not available',
        lastSeenTimestamp: moment(usbDevice.lastSeenTimestamp).fromNow() ?? 'Not available',
        totalNumberOfHostsPerItem: usbDevice.assets?.length ?? 0,
        totalNumberOfVesselsPerItem: calculateNumberOfVesselsForGivenAssets(
            usbDevice.assets,
            nodes,
        ),
        assets: usbDevice.assets,
        volumeLabels:
            usbDevice?.volumes && usbDevice?.volumes.length > 0
                ? usbDevice?.volumes.map((volume) => volume.label).join(', ')
                : 'Not available',
        volumeSerials:
            usbDevice?.volumes && usbDevice?.volumes.length > 0
                ? usbDevice?.volumes.map((volume) => volume.serialNumber).join(', ')
                : 'Not available',
        listOfVesselsForGivenAssets: getListOfVesselsForGivenAssets(usbDevice?.assets, nodes),
        purpose: usbDevice.purpose ?? 'Not available',
        purposeSubmittedBy: getFormattedSubmitUser(usbDevice, users, isInternalUser),
        purposeSubmittedWhen: usbDevice?.purposeSubmittedWhen
            ? formatDate(usbDevice?.purposeSubmittedWhen)
            : 'Not available',
        detectedByAgent: usbDevice.detectedByAgent,
    }
}

function getFormattedSubmitUser(
    usbDevice: USBDevicesResponse | undefined,
    users: User[],
    isInternalUser: boolean,
): string {
    if (!usbDevice) {
        return 'Not available'
    }
    if (usbDevice.detectedByAgent) {
        return 'Crew'
    } else if (!usbDevice.purposeSubmittedBy) {
        return 'Not available'
    }
    const userDescription = getUserDescription(
        users,
        usbDevice.purposeSubmittedBy,
        undefined,
        isInternalUser,
    )
    return userDescription === 'Unknown' ? 'Unknown user' : userDescription
}

export function getUIDataorHosts(
    givenAsset: FormattedHostDataForUSBDevice,
    listOfAssociatedAssets: ListOfAssociatedAssets[] | undefined,
): FormattedHostDataForUSBDevice {
    const findNodeDataFromVessel = listOfAssociatedAssets?.find(
        (element) => element.identity === givenAsset.identity,
    )
    if (!findNodeDataFromVessel || !listOfAssociatedAssets) {
        return {} as FormattedHostDataForUSBDevice
    }
    return {
        identity: givenAsset.identity,
        hostName: givenAsset?.hostName ?? 'Unknown',
        lastSeenTimestamp: moment(findNodeDataFromVessel?.lastSeenTimestamp).fromNow() ?? 'Unknown',
    }
}
export function formatDeviceType(deviceType: USBDeviceType | undefined): string {
    return deviceType
        ? `${deviceType.charAt(0).toUpperCase()}${deviceType?.slice(1)}`
        : 'Not available'
}

export const USB_DEVICES_DETAILS = 'usbDetails'

export class FormattedNewFilterModel {
    public readonly sortColumn: {orderBy: USBDevicesSortType; orderAscending: boolean}
    public readonly searchedFirstDetected: TimestampFilter | undefined
    public readonly searchedLastActive: TimestampFilter | undefined
    public readonly searchedVendor: string | undefined
    public readonly searchedProductName: string | undefined
    public readonly searchedType: USBDeviceType[] | undefined
    public readonly hasPurpose: boolean | undefined
    public readonly selectedUsbDeviceStatus: DeviceStatus[] | undefined

    public constructor(newFilter: UsbInventoryFilterReduxState | UsbInventoryFilter) {
        this.sortColumn = newFilter.sortColumn
        this.searchedFirstDetected =
            newFilter.searchedFirstDetected === 'All' ? undefined : newFilter.searchedFirstDetected
        this.searchedLastActive =
            newFilter.searchedLastActive === 'All' ? undefined : newFilter.searchedLastActive
        this.searchedVendor = newFilter.searchedVendor === '' ? undefined : newFilter.searchedVendor
        this.searchedProductName =
            newFilter.searchedProductName === '' ? undefined : newFilter.searchedProductName
        this.searchedType =
            newFilter.searchedType?.length === 2 ? undefined : newFilter.searchedType
        this.hasPurpose = newFilter.hasPurpose
        this.selectedUsbDeviceStatus = newFilter.selectedUsbDeviceStatus
    }
}

export function compareFilters(
    currentFilter: UsbInventoryFilter,
    newFilter: UsbInventoryFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)

    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}

export function getImageForUsbDevicesState(UsbDeviceStatus: DeviceStatus): string {
    switch (UsbDeviceStatus) {
        case DeviceStatus.trusted:
            return CIRCLE_CHECKED
        case DeviceStatus.unknown:
            return CIRCLE_UNKNOWN
        case DeviceStatus.rejected:
            return CIRCLE_X
        default:
            return ''
    }
}

export const allUsbDeviceStatus = [
    DeviceStatus.trusted,
    DeviceStatus.unknown,
    DeviceStatus.rejected,
]
