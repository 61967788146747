import {TagsComponentTablePopulated} from './tags-component-table-populated'
import {vesselTagsSelector} from '../../../../../../store/state/vessel-tags/selectors'
import {getTagInfo, TableData} from '../../data-helpers'
import {locationTagsSummarySelector} from '../../../../../../store/state/location-tags-summary/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {NoDataContent} from '../../../general/content-area/no-data/no-data-content'
import {useTagsComponent} from '../../contexts/use-tags-component'
import {TagsComponentSortType} from '../../contexts/types/tags-component-filter'

export function TagsComponentTableContent(): JSX.Element {
    const allTags = useTypedSelector(vesselTagsSelector)
    const vesselTagData = useTypedSelector(locationTagsSummarySelector)
    const {sortColumn, searchedVesselTagTerm} = useTagsComponent()
    const {orderBy, orderAscending} = sortColumn

    const filteredTags =
        searchedVesselTagTerm.length === 0
            ? allTags
            : searchedVesselTagTerm.flatMap((searchedTag) =>
                  allTags.filter((tags) => tags.name === searchedTag),
              )

    // tag name to info
    let tableData: Record<string, TableData> = {}
    for (const tagData of filteredTags) {
        if (!tableData[tagData.name]) {
            const dataInfo = getTagInfo(vesselTagData, tagData.identity)
            if (dataInfo) {
                tableData[tagData.name] = dataInfo
            }
        }
    }

    // sort the object given the users current sorting options
    tableData = Object.fromEntries(
        (() => {
            const result = Object.entries(tableData).sort(([k1, v1], [k2, v2]) => {
                if (orderBy === TagsComponentSortType.TAG_NAME) {
                    return k1.localeCompare(k2)
                }
                return v1[orderBy] - v2[orderBy]
            })
            return orderAscending ? result : result.reverse()
        })(),
    )

    if (Object.keys(tableData).length === 0) {
        return (
            <div style={{padding: '30px'}}>
                <NoDataContent text="No tag data as of" />
            </div>
        )
    }

    return <TagsComponentTablePopulated tableData={tableData} allTags={filteredTags} />
}
