import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {getFormattedSoftwareInventoryData} from '../../data-helpers'
import {DataCell} from '../shared/data-cell'
import * as Styled from './software-details.styled'

export function SoftwareDetailedSection(): JSX.Element {
    const {findSoftwareDetailsForModal} = usePagedSoftwareInventory()
    const formattedSoftwareDetails = getFormattedSoftwareInventoryData(findSoftwareDetailsForModal)

    const assetString =
        formattedSoftwareDetails.totalNumberOfHostsPerItem === 1 ? 'asset' : 'assets'
    const vesselString =
        formattedSoftwareDetails.totalNumberOfVesselsPerItem === 1 ? 'vessel' : 'vessels'
    const installCountMessage = `${formattedSoftwareDetails.totalNumberOfHostsPerItem} ${assetString} across ${formattedSoftwareDetails.totalNumberOfVesselsPerItem} ${vesselString}`

    return (
        <Styled.DetailsAreaWrapper id="SoftwareDetailedSection_DetailsAreaWrapper">
            <Styled.DetailsAreaContent id="SoftwareDetailedSection_DetailsAreaContent">
                <DataCell label="Company:">{formattedSoftwareDetails.companyName}</DataCell>
                <DataCell label="Product name:">{formattedSoftwareDetails.productName}</DataCell>
                <DataCell label="Install count:">{installCountMessage}</DataCell>
            </Styled.DetailsAreaContent>
        </Styled.DetailsAreaWrapper>
    )
}
