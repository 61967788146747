import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface Props {
    width: number
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: 0 120px 120px 0.8fr 1fr 1fr 0.5fr 0.5fr 120px 0;
    gap: ${spacing(2)};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.commonTableViewPage.dataArea.background};
`
