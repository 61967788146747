import {Check} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridType} from '../shared/grid-layout-helper'
import {DataCell} from './data-cell'
import {Input} from './_styled/data-cell.styled'

interface DataInputProps {
    label: string
    gridArea?: string
    initialValue: string | string[] | number
    type?: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    changed: boolean
}

export function DataInput({
    label,
    gridArea,
    initialValue,
    type,
    onChangeHandler,
    changed,
}: DataInputProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <DataCell label={label} gridArea={gridArea}>
            <Input
                value={initialValue}
                gridLayout={getGridType(width)}
                type={type}
                onChange={onChangeHandler}
                isChanged={changed}
            />
            {changed && <Check color={'green'} width={19} height={19} />}
        </DataCell>
    )
}
