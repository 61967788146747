import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea?: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

interface LabelProps {
    width: number
}

export const Label = styled.label<LabelProps>`
    ${mediumSmallFont}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    min-width: 80px;
    margin-right: 5px;
`
export const Children = styled.div`
    flex: 1;
`
