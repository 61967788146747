import {getTypeDisplayName} from '../../../../pages/unknown-assets-v2/components/filter-bar/properties-filter/period-filter/period-filter'
import {NetworkAssetsSortType} from '../../../../store/state/network-assets-filter/state'
import {NetworkAssetsFilterCriteria} from '../../../../values/user-preferences/network-assets-filter'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: NetworkAssetsFilterCriteria
    tooltip?: boolean
}

export function DetailFilterNetworkAssets({criteria, tooltip}: DetailFilterProps): JSX.Element {
    const behavioursMessage = (num: number) => {
        return num <= 1 ? `${num} behaviour selected` : `${num} behaviours selected`
    }
    return (
        <DetailsWrapper>
            {tooltip && (
                <Details id="orderBy">
                    • Order By:
                    {criteria.orderBy.column === NetworkAssetsSortType.AIMEE_TIMESTAMP
                        ? NetworkAssetsSortType.TIMESTAMP
                        : criteria.orderBy.column}
                    ({criteria.orderBy.isAscending ? 'Ascending' : 'Descending'})
                </Details>
            )}
            {criteria.states && (
                <Details id="states">• Asset Status: {criteria.states.toString()}</Details>
            )}
            {criteria.networks && (
                <Details id="networks">• Networks: {criteria.networks.toString()}</Details>
            )}
            {criteria.behaviours && (
                <Details id="behaviours">
                    • Behaviours: {behavioursMessage(criteria.behaviours.length)}
                </Details>
            )}
            {criteria.vlan && (
                <Details id="vlan">
                    • Vlan:
                    {criteria.vlan}
                </Details>
            )}
            {criteria.macAddress && (
                <Details id="macAddress">
                    • MacAddress:
                    {criteria.macAddress}
                </Details>
            )}
            {criteria.fromRelativeLastSeen && (
                <Details id="fromRelativeLastSeen">
                    • Last seen:
                    {getTypeDisplayName(criteria.fromRelativeLastSeen)}
                </Details>
            )}
        </DetailsWrapper>
    )
}
