import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Container = styled.div`
    padding: ${spacing(4)} 0;
    background-color: ${(props) => props.theme.colors.background.page};
    display: flex;
    justify-content: space-between;
    min-height: ${spacing(14)};
`
