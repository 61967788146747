import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'

interface FilterBarWrapperProps {
    width: number
}
export const FilterBarWrapper = styled.div<FilterBarWrapperProps>`
    min-width: 320px;
    width: ${(props) => (narrowFilterLayout(props.width) ? '388px' : 'fit-content')};
    display: flex;
    flex-direction: column;
    margin: ${spacing(12)} auto;
`

export const BottomCloseFilterBar = styled.div`
    margin: ${spacing(3)} auto 0 auto;
`
export function narrowFilterLayout(width: number): boolean {
    return width > 400
}
export function getHorizontalPagePadding(width: number): string {
    if (width > 1660) {
        return '0'
    }
    if (width > 1655) {
        return spacing(1)
    }
    if (width > 1650) {
        return spacing(2)
    }
    if (width > 1645) {
        return spacing(3)
    }
    if (width < 1025) {
        return spacing(3)
    }
    return spacing(4)
}

interface WideContentAreaProps {
    width: number
    fullHeight: boolean
}
export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    width: 100%;
    ${(props) => (props.fullHeight ? 'height: 100%;' : '')}

    padding: 0 ${(props) =>
        props.width < 340 ? `${spacing(2)}` : getHorizontalPagePadding(props.width)};
`
export const LHFilterBar = styled.div`
    padding: ${spacing(6)} 0;
    width: 388px;
    min-width: 320px;
    overflow-y: auto;
`
interface ContentWrapperProps {
    cardsView: boolean
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${(props) => (props.cardsView ? 'min-width: 300px' : 'justify-content: space-between')};
`
