import {DeviceStatus} from '../../../../../store/state/usb-inventory-filter/state'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {DetailsLayout} from '../_styled/panel-section.styled'
import * as Styled from './actions-section.styled'
import MAIL_ICON from '../../../../../@assets/icons/mail-icon.svg'
import {useDispatch} from 'react-redux'
import {openModal} from '../../../../../store/state/extended-data-request/action-creators'
import {InventoryType} from '../../../../../store/state/extended-data-request/state'
import {UsbItemData, getUsbItemName} from '../../../../../components/contact-soc-modal/helper'
import {isUserAllowed} from '../../../../../values/Role'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {rolesSelector} from '../../../../../store/state/roles/selectors'

export function ActionsContents(): JSX.Element {
    const {setDeviceStatus, displaySetPurposeModal, findUSBDeviceForModal, usbDetailsOutput} =
        usePagedUSBDevices()
    const dispatch = useDispatch()
    const userRoles = useTypedSelector(rolesSelector)
    const isAllowedUser = isUserAllowed(userRoles, [
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
    ])

    function onClickFn(status: DeviceStatus): void {
        if (!findUSBDeviceForModal) {
            return
        }
        setDeviceStatus(findUSBDeviceForModal, status)
    }

    const deviceName = getUsbItemName({
        identity: usbDetailsOutput.identity,
        vendorName: usbDetailsOutput.vendorName,
        productName: usbDetailsOutput.productName,
        deviceId: usbDetailsOutput.deviceId,
    } as UsbItemData)

    return (
        <DetailsLayout id="usb-devices-details-modal_panel_ActionsContents_DetailsLayout">
            <Styled.TextLink
                id={`change-status-to_${DeviceStatus.trusted}`}
                onClick={() => onClickFn(DeviceStatus.trusted)}
                disabled={findUSBDeviceForModal?.deviceStatus === DeviceStatus.trusted}
            >
                Change status to Trusted
            </Styled.TextLink>
            <Styled.TextLink
                id={`change-status-to_${DeviceStatus.rejected}`}
                onClick={() => onClickFn(DeviceStatus.rejected)}
                disabled={findUSBDeviceForModal?.deviceStatus === DeviceStatus.rejected}
            >
                Change status to Unwanted
            </Styled.TextLink>
            <Styled.TextLink
                id={`change-status-to_${DeviceStatus.unknown}`}
                onClick={() => onClickFn(DeviceStatus.unknown)}
                disabled={findUSBDeviceForModal?.deviceStatus === DeviceStatus.unknown}
            >
                Change status to Unknown
            </Styled.TextLink>
            {isAllowedUser && (
                <Styled.TextLink
                    onClick={() => displaySetPurposeModal(true)}
                    disabled={false}
                    id="set-purpose-for-device"
                >
                    Set purpose for the device
                </Styled.TextLink>
            )}

            <Styled.ContactSOCAreaWrapper id="ContactSOCAreaWrapper">
                <Styled.Label>
                    Get help with this asset:
                    <Styled.Link
                        onClick={() =>
                            dispatch(
                                openModal(
                                    InventoryType.USB,
                                    deviceName,
                                    usbDetailsOutput.listOfVesselsForGivenAssets,
                                ),
                            )
                        }
                        id="get-help-with-this-asset"
                    >
                        <img src={MAIL_ICON} style={{marginRight: '5px'}} />
                        Contact SOC
                    </Styled.Link>
                </Styled.Label>
            </Styled.ContactSOCAreaWrapper>
        </DetailsLayout>
    )
}
