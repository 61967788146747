import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DropdownWrapper, LogoImage} from './swagger-dropdown.styled'
import SwaggerLogo from '../../../../../@assets/images/swagger-logo.png'

export function SwaggerDropdown(): JSX.Element {
    const {width} = useDimensions()

    return (
        <DropdownWrapper>
            <LogoImage width={width} src={SwaggerLogo} />
        </DropdownWrapper>
    )
}
