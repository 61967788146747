import {CheckboxType} from './checkbox-type'
import * as Styles from './checkbox.styles'

interface Props {
    id: string
    checked: boolean
    onChange: (value: boolean) => void
    label: string
    isInactive?: boolean
    disabled?: boolean
    type?: CheckboxType
}
export function Checkbox({
    id,
    checked,
    onChange,
    label,
    isInactive,
    disabled,
    type = CheckboxType.DEFAULT,
}: Props): JSX.Element {
    return (
        <Styles.Label htmlFor={id} disabled={disabled && !checked}>
            <Styles.CheckboxInput
                type="checkbox"
                id={`${id}-input`}
                onChange={() => onChange(!checked)}
                checked={checked}
                checkboxType={type}
            />
            <Styles.StyledCheckbox isInactive={isInactive ?? false} />
            <Styles.Text id={`${id}-text`}>{label}</Styles.Text>
        </Styles.Label>
    )
}
