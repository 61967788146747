import * as Styled from '../generate-report-popup/generate-report-popup.styled'
import {ReportErrorDialogContent} from './popup-content-error'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../hooks/useOnClickOutside'

interface GenerateReportPopupProps {
    onClose: () => void
    errorText: string
}

export function GenerateReportErrorPopup({
    onClose,
    errorText,
}: GenerateReportPopupProps): JSX.Element {
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        onClose()
    })
    return (
        <Styled.AssignPolicyDialogWrapperCard ref={clickOutside} id={`generate-report-error-popup`}>
            <Styled.AssignPolicyDialogCard>
                <ReportErrorDialogContent onClose={onClose} errorText={errorText} />
            </Styled.AssignPolicyDialogCard>
        </Styled.AssignPolicyDialogWrapperCard>
    )
}
