import {PageType} from '../../../components/vessel-filters/data-helper'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {DEFAULT_VESSEL_FILTER_STATE} from './types/default-vessel-filter-state'
import {VesselFilterReduxState} from './types/vessel-filter-state'

export const vesselFilterSelector = (state: AppState): VesselFilterReduxState => state.vesselFilter

export const isLoadingVesselsFilterSelector = (state: AppState): boolean =>
    state.vesselFilter.loadingState === LoadingState.NotPopulated ||
    state.vesselFilter.loadingState === LoadingState.RequestingData

export const vesselsFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.vesselFilter.loadingState

export const pageTypeSelector = (state: AppState): PageType => state.vesselFilter.pageType

export const activeVesselFilterSelector = (state: AppState): boolean =>
    state.vesselFilter.locations !== DEFAULT_VESSEL_FILTER_STATE.locations ||
    state.vesselFilter.searchVesselNameTerm !== DEFAULT_VESSEL_FILTER_STATE.searchVesselNameTerm ||
    state.vesselFilter.searchVesselTagTerm !== DEFAULT_VESSEL_FILTER_STATE.searchVesselTagTerm
