import {Action} from 'redux'
import {useDispatch} from 'react-redux'
import {ThunkDispatch} from 'redux-thunk'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReduxDispatch = ThunkDispatch<any, any, Action>

export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>()
}
