import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {HeadlineValues} from '../components/headline-values'
import {ContentArea} from '../../general/content-area/content-area.styled'
import {VesselListTitle} from './vessel-list/_styled/vessel-list-title.styled'
import {ContentVesselsArea} from './content.styled'
import {VesselsList} from './vessel-list/vessels-list'
import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {DetailsButton} from '../../general/header/details-button/details-button'

export function PopulatedData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)

    return (
        <Widget>
            <div>
                <Header gridLayout={gridLayout} id="vessels-at-risk-widget-header">
                    <HeaderContent gridLayout={gridLayout}>
                        <TitleText gridLayout={gridLayout} id="vessels-at-risk-widget-title">
                            My Fleet
                        </TitleText>
                        <DetailsButton linkTo="/my-vessels" linkIdPrefix="vessels" />
                    </HeaderContent>
                </Header>

                <ContentArea gridLayout={gridLayout}>
                    <HeadlineValues gridLayout={gridLayout} />
                    <VesselListTitle gridLayout={gridLayout}>
                        Displaying - Vessels &amp; Risk Score
                    </VesselListTitle>
                </ContentArea>
            </div>
            <ContentVesselsArea gridLayout={gridLayout} id="ContentVesselsArea">
                <VesselsList />
            </ContentVesselsArea>
        </Widget>
    )
}
