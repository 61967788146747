import {Filter} from 'react-feather'
import {removeQueryParam} from '../../../../helpers/navigation'
import {NETWORK_ASSET_DETAILS} from '../../components/helpers/data-helpers'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import * as Styles from './filter-bar-button.styled'
import {fetchNetworkAssetsFilter} from '../../../../store/state/network-assets-filter/action-creators'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {savedNetworkAssetsFiltersSelector} from '../../../../store/state/saved-filters/selectors'

export function FilterBarButton(): JSX.Element {
    const {
        displayFilterBar,
        showFilterBar,
        activeFilter,
        closeNetworkAssetDetailsModal,
        isInterlinking,
    } = usePagedUnknownAssetsBeta()
    const dispatch = useDispatch()
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)

    return (
        <Styles.Container
            onClick={(e) => {
                e.preventDefault()
                displayFilterBar(!showFilterBar)
                removeQueryParam(NETWORK_ASSET_DETAILS)
                closeNetworkAssetDetailsModal()
                isInterlinking && dispatch(fetchNetworkAssetsFilter(savedFilters))
            }}
            id="filter-bar_button"
        >
            <Styles.SearchIcon active={activeFilter}>
                <Filter height={16} width={16} />
            </Styles.SearchIcon>
            <Styles.TextWrapper active={activeFilter}>
                <Styles.Text>Filter</Styles.Text>
            </Styles.TextWrapper>
        </Styles.Container>
    )
}
