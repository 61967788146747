import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {addQueryParam, removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../../../store/routerSelectors'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NETWORK_ASSET_DETAILS} from '../../helpers/data-helpers'
import {ButtonText} from '../../shared/row-actions/common/button-text.styled'
import {Button} from '../../shared/row-actions/common/button.styled'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'
import {fetchNetworkAssetsFilter} from '../../../../../store/state/network-assets-filter/action-creators'
import {savedNetworkAssetsFiltersSelector} from '../../../../../store/state/saved-filters/selectors'

interface ToggleNotesButtonProps {
    id: GuidType
}

export function ToggleDetailsButton({id}: ToggleNotesButtonProps): JSX.Element {
    const queryString = useTypedSelector(queryStringObjectSelector)
    const networkAssetModalId = queryString?.networkAssetDetails as GuidType
    const {
        displayNetworkAssetDetailsModal,
        closeNetworkAssetDetailsModal,
        displayFilterBar,
        isInterlinking,
    } = usePagedUnknownAssetsBeta()
    const isExpanded = id === networkAssetModalId
    const dispatch = useDispatch()
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)

    function onClickWrapper() {
        if (isExpanded) {
            removeQueryParam(NETWORK_ASSET_DETAILS)
            closeNetworkAssetDetailsModal()
            isInterlinking && dispatch(fetchNetworkAssetsFilter(savedFilters))
        } else {
            addQueryParam(NETWORK_ASSET_DETAILS, id)
            displayNetworkAssetDetailsModal(id)
            displayFilterBar(false)
            dispatch(
                logDetailsOpen(
                    AuditLogPageType.NETWORK_INVENTORY,
                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                    id,
                ),
            )
        }
    }
    return (
        <Button onClick={onClickWrapper} id={`expand-details-button_${id}`}>
            <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            <ButtonText>View details here</ButtonText>
        </Button>
    )
}
