import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {useOTLocations} from '../contexts/hooks/use-ot-locations'
import {Button} from '../../../components/generate-report-button/generate-report-button/button.styled'
import {ButtonText} from '../../../components/generate-report-button/generate-report-button/button-text.styled'

/**
 * Button component for triggering synchronization of locations and re-fetch
 * locations from ot-inventory
 */
export function RefreshButton(): JSX.Element {
    const {width} = useDimensions()
    const {refreshData} = useOTLocations()

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        refreshData()
    }

    return (
        <Button id="refresh-button" onClick={refreshFunction}>
            <Icon glyph="Reset" height={16} width={16} />
            {width > 400 && <ButtonText>{width > 550 ? 'Refresh Data' : 'Refresh'}</ButtonText>}
        </Button>
    )
}
