import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import * as Styled from './vessels-beta-page.styled'
import useTypedSelector from '../../hooks/use-typed-selector'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {FilterBar} from './components/filter-bar/filter-bar'
import {Content} from './components/content'
import {PagedVesselsBetaProvider} from './contexts/paged-vessels-beta-provider'
import {usePagedVesselsBeta} from './contexts/use-paged-vessels-beta'
import {DataLoading} from '../../components/data-loading/data-loading'
import {fetchUserPrefsIfExist} from '../../store/state/vessels-beta-filter/action-creators'
import {StrictMode} from '../../store/state/users/state'
import {isVesselBetaFilterLoadingStateSelector} from '../../store/state/vessels-beta-filter/selectors'

export function VesselsBeta(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.VESSELS_BETA))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PagedVesselsBetaProvider>
            <FixedPageTemplate>
                <VesselsBetaPage />
            </FixedPageTemplate>
        </PagedVesselsBetaProvider>
    )
}
function VesselsBetaPage(): JSX.Element {
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const {showFilterBar} = usePagedVesselsBeta()
    const loadingUserPrefs = useTypedSelector(isVesselBetaFilterLoadingStateSelector)

    useEffect(() => {
        dispatch(setPageType(PageType.VESSELS_BETA))
        dispatch(fetchUserPrefsIfExist(strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    return (
        <Styled.WideContentArea>
            {showFilterBar && (
                <Styled.LHFilterBar id="filter-abr-wrapper">
                    <FilterBar />
                </Styled.LHFilterBar>
            )}
            {loadingUserPrefs ? <DataLoading /> : <Content />}
        </Styled.WideContentArea>
    )
}
