import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumFont, mediumSmallFont} from '../../../../theme/font-styles'

export const BREAK_POINT = 550

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width >= BREAK_POINT ? '500px' : '350px')};
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
`

export const Settings = styled.div<FiltersProps>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => ((props.width || 0) > BREAK_POINT ? 'flex-start' : 'center')};
    padding: ${spacing(4)} ${spacing(4)} ${spacing(2)};
    row-gap: ${spacing(4)};
`

export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`
interface FiltersProps {
    width: number | undefined
}
export const Filters = styled.div<FiltersProps>`
    display: flex;
    flex-direction: column;
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
`

export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`

export const Panel = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(1)};
    align-items: center;
    ${mediumSmallFont()}
`
export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)} ${spacing(2)};
    margin: 0;
    width: 100%;
`

export const DataRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(1)};
`

export const DataCell = styled.div`
    display: flex;
    flex-direction: row;
`

export const DataCellKey = styled.div`
    width: 110px;
    padding: ${spacing(1)};
`

export const DataCellValue = styled.div`
    font-weight: bold;
    flex: 1;
    padding: ${spacing(1)};
`

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${spacing(4)};
`
