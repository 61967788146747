import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.background.default};
`

interface DropdownWrapper {
    changed: boolean
    cardView?: boolean
    disabled?: boolean
}

export const DropdownWrapper = styled.div<DropdownWrapper>`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.default};
    border-radius: ${spacing(1)};
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.background.default};

    &:hover,
    &:active,
    &:focus {
        border: 1px solid ${(props) => props.theme.colors.border.active};
    }

    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`

export const SearchInput = styled.div`
    display: inline-block;
    width: 300px;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    outline: none;
    align-items: center;
    padding: ${spacing(1)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    padding: 1px;
`

export const UserWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`

export const UserDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: ${spacing(1)};
    padding: ${spacing(2)};
    background: white;
    flex-wrap: wrap;
    z-index: 1;
    pointer-events: auto;
    max-height: 130px;
    overflow-y: auto;
`

export const ValueOption = styled.div`
    position: relative;
    cursor: pointer;
    ${mediumSmallFont()};
    padding: 3px;

    &:hover {
        background-color: rgba(206, 218, 236, 1);
    }
`
