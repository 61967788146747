import {IPAddressColumn} from './ip-address-column'
import {NICVendorColumn} from './nic-vendor-column'
import {SortableColumn} from './sortable-column'
import {TrustDeviceColumn} from './trust-device-column'
import {TemporaryColumn} from './temporary-column'
import {HostnameColumn} from './hostname-column'
import {NetworkAssetsSortType} from '../../../../../store/state/network-assets-filter/state'

export function TableHeader(): JSX.Element {
    return (
        <>
            <TemporaryColumn gridColumn={2} text="Status" />
            <SortableColumn
                gridColumn={3}
                text="First Detected"
                colName={NetworkAssetsSortType.TIMESTAMP}
            />
            <SortableColumn
                gridColumn={4}
                text="Vessel"
                colName={NetworkAssetsSortType.LOCATION_DESCRIPTION}
            />
            <SortableColumn gridColumn={5} text="Network" colName={NetworkAssetsSortType.NETWORK} />
            <NICVendorColumn gridColumn={6} />
            <IPAddressColumn gridColumn={7} />
            <HostnameColumn gridColumn={8} />
            <TrustDeviceColumn gridColumn={9} />
        </>
    )
}
