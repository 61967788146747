import LoadingState from '../../../../../values/loading-state-enum'
import {
    AllPolicyData,
    AssignPolicyDialogState,
    PolicyManagementState,
} from './policy-management-state'

export const DEFAULT_ASSIGN_POLICY_DIALOG_STATE: AssignPolicyDialogState = {
    selectedPolicyId: undefined,
    currentLocations: undefined,
    newLocations: undefined,
    deselectedLocations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchVesselTerm: '',
    showXButton: false,
}

export const DEFAULT_POLICY_MANAGEMENT_STATE: PolicyManagementState = {
    loadingDataState: LoadingState.NotPopulated,
    allPolicyData: new Array<AllPolicyData>(),
    selectedPolicyId: null,
    selectedPolicyType: null,
    showPolicyModal: false,
    dataToBeFetched: false,
    showAssignPolicyDialog: false,
    assignPolicyDialogState: DEFAULT_ASSIGN_POLICY_DIALOG_STATE,
    defaultPolicy: {
        identity: '',
        locationsOfDefaultPolicy: undefined,
    },
}
