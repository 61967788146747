import {VesselTags} from '../../../../../store/state/vessel-tags/state'

export function findRecommendationFromEntryTag(
    existingTags: VesselTags[] | undefined,
    searchTag: string,
): VesselTags[] {
    if (!existingTags || !searchTag || !searchTag?.trim()) {
        return []
    }
    return existingTags
        ?.filter((element) =>
            element.name?.trim().toLowerCase().includes(searchTag?.trim().toLowerCase()),
        )
        .sort((a, b) => a.name?.localeCompare(b.name))
}
