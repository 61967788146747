import styled from 'styled-components'

export type Props = {
    width: number
    outlineColour?: string
}

export const Circle = styled.div<Props>`
    display: grid;
    place-content: center;
    border: 0.4em solid
        ${(props) =>
            props.outlineColour ? props.outlineColour : props.theme.incidents.dataRow.text};
    border-radius: 50%;
    width: ${(props) => `${props.width}rem`};
    height: ${(props) => `${props.width}rem`};
    color: ${(props) => props.theme.incidents.dataRow.text};
`
