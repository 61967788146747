import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Panel = styled.div`
    width: 100%;
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`

export const DetailsLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
`
