import {useVesselManagement} from '../contexts/use-vessel-management'
import {DataPopulated} from './data-populated'
import {NoData} from './shared/no-data/no-data'

export function FilteredContents(): JSX.Element {
    const {filteredLocations} = useVesselManagement()
    const hasData = filteredLocations.length > 0

    return hasData ? <DataPopulated /> : <NoData message="There are no vessels found" />
}
