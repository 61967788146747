import {useState, useEffect, MouseEvent} from 'react'
import {TabHeader} from './common/header/tab-header'
import {addQueryParam, removeQueryParam} from '../../../../helpers/navigation'
import usedTypeSelector from '../../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {AlertsTab} from './alerts/alerts-tab'
import {IndicatorsTab} from './indicators/indicators-tab'
import {UniqueFlowsTab} from './unique-flows/unique-flows-tab'
import {useDispatch} from 'react-redux'
import {logAssetOverlayTabClick} from '../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../store/state/audit-log/state'
import {useLocation} from 'react-router-dom'

function getCategoryName(categoryValue: number): 'alerts' | 'indicators' | 'data-flows' {
    switch (categoryValue) {
        case 2:
            return 'indicators'
        case 3:
            return 'data-flows'
        case 1:
        default:
            return 'alerts'
    }
}

function getCategoryValue(categoryName: string): 1 | 2 | 3 {
    switch (categoryName) {
        case 'indicators':
            return 2
        case 'data-flows':
            return 3
        case 'alerts':
        default:
            return 1
    }
}

export function TabSection(): JSX.Element {
    const [activeTab, setActiveTab] = useState<number>(1)
    const queryObject = usedTypeSelector(queryStringObjectSelector)
    const dispatch = useDispatch()

    const category = queryObject?.category
    const assetId = queryObject?.assetDetails

    const pathname = useLocation().pathname
    const currentPage = (pathname: string): AuditLogPageType => {
        switch (true) {
            case pathname.startsWith('/my-vessels'):
                return AuditLogPageType.MY_VESSELS
            case pathname.startsWith('/incidents'):
                return AuditLogPageType.INCIDENTS
            case pathname.startsWith('/dashboard'):
                return AuditLogPageType.DASHBOARD
            default:
                return AuditLogPageType.MY_VESSELS
        }
    }

    useEffect(() => {
        if (category) {
            setActiveTab(getCategoryValue(category as string))
        }
    }, [category])

    const toggle = (event: MouseEvent, tab: number) => {
        if (activeTab !== tab) {
            removeQueryParam('category')
            addQueryParam('category', getCategoryName(tab))
            setActiveTab(tab)
            dispatch(
                logAssetOverlayTabClick(
                    currentPage(pathname),
                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                    assetId as string,
                    getCategoryName(tab),
                ),
            )
        }
        event.preventDefault()
    }

    return (
        <>
            <TabHeader activeTab={activeTab} toggle={toggle} />
            {activeTab === 1 && <AlertsTab />}
            {activeTab === 2 && <IndicatorsTab />}
            {activeTab === 3 && <UniqueFlowsTab />}
        </>
    )
}
