import {LinkText, TableDataCell} from './_styled/table-data-cell.styled'
import {TableDataRow} from './_styled/table-data-row.styled'
import {
    getFormattedNMEAeData,
    NmeaInventoryResponse,
} from '../../../contexts/types/nmea-inventory-response'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'
import {batch, useDispatch} from 'react-redux'
import {
    logDetailsOpen,
    logInterlinksClick,
} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {Link} from 'react-router-dom'
import {setSearchIpFilter} from '../../../../../store/state/network-assets-filter/action-creators'
import {setSearchVesselNameTerm} from '../../../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

interface TableRowProps {
    nmeaItem: NmeaInventoryResponse
    index: number
}

export function TableRow({nmeaItem, index}: TableRowProps): JSX.Element | null {
    const gridRow = index + 1
    const {showNMEADetailModal, modalIdForNmeaDetails} = usePagedNmeaInventory()
    const dispatch = useDispatch()
    const formattedNmeaItem = getFormattedNMEAeData(nmeaItem)
    const activeNMEAItem = formattedNmeaItem.identity === modalIdForNmeaDetails

    function onClickWrapper() {
        showNMEADetailModal(formattedNmeaItem.identity)
        dispatch(
            logDetailsOpen(
                AuditLogPageType.NMEA_INVENTORY,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                formattedNmeaItem.identity,
            ),
        )
    }

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                id={`data-row_${formattedNmeaItem.identity}`}
                selectable={true}
                activeItem={activeNMEAItem}
            />

            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`first-detected-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
            >
                {formattedNmeaItem.firstSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`last-active-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
            >
                {formattedNmeaItem.lastSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`vessel-name${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
            >
                {formattedNmeaItem.locationName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`nmea-talker-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
                noWrap
            >
                {formattedNmeaItem.talkerName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`nmea-sentence-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
                noWrap
            >
                {formattedNmeaItem.sentenceName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                id={`relay-ip-value_${formattedNmeaItem.identity}`}
                selectable
            >
                <Link
                    onClick={() => {
                        batch(() => {
                            dispatch(
                                setSearchVesselNameTerm(
                                    formattedNmeaItem.locationName,
                                    PageType.NETWORK_ASSETS,
                                ),
                            )
                            dispatch(setSearchIpFilter(formattedNmeaItem.relayIp))
                            dispatch(
                                logInterlinksClick(
                                    AuditLogPageType.NETWORK_INVENTORY,
                                    AuditLogAuditType.INTERLINK_CLICK,
                                    formattedNmeaItem.identity,
                                ),
                            )
                        })
                    }}
                    to={{
                        pathname: `/network-assets`,
                    }}
                    id="link-to-network-assets"
                >
                    <LinkText>{formattedNmeaItem.relayIp}</LinkText>
                </Link>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                id={`receiver-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
            >
                {formattedNmeaItem.receiver}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={9}
                id={`parameters-value_${formattedNmeaItem.identity}`}
                onClick={onClickWrapper}
                selectable
            >
                <LinkText>{formattedNmeaItem.numberOfFields}</LinkText>
            </TableDataCell>
        </>
    )
}
