import {ReactNode, ReactNodeArray} from 'react'
import {useSpring} from 'react-spring'
import {ContentWrapper} from './content-wrapper.styled'
import {Layer} from './layer.styled'

interface OverlayProps {
    children: ReactNode | ReactNodeArray
    closing: boolean
    onClick?: () => void
    z?: number
}

function Overlay({children, onClick, closing, z = 900}: OverlayProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const opacityAnimation: any = useSpring({
        opacity: closing ? 0 : 1,
        from: {opacity: closing ? 1 : 0},
    })
    return (
        <>
            <Layer style={opacityAnimation} z={z} />
            <ContentWrapper z={z + 1} onClick={() => onClick && onClick()}>
                {children}
            </ContentWrapper>
        </>
    )
}

export default Overlay
