import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {OTAsset, OTAssetStateReduxState} from './state'

export const OTAssetsLoadingStateSelector = (state: AppState): LoadingState =>
    state.OTAssets.loadingState

export const OTAssetsSelector = (state: AppState): OTAssetStateReduxState => state.OTAssets

export const OTAssetListSelector = (state: AppState): OTAsset[] => state.OTAssets.assets

export const OTAssetOpenedModalSelector = (state: AppState): boolean => state.OTAssets.openedModal

export const OTAssetSelector = (state: AppState): OTAsset | undefined =>
    state.OTAssets.selectedModalAsset

export const OTAssetDeleteLoadingStateSelector = (state: AppState): boolean =>
    state.OTAssets.deleteAssetIsLoading

export const OTAssetDeleteErrorSelector = (state: AppState): string =>
    state.OTAssets.deleteAssetError
