import * as CommonStyles from '../_styled/common.styled'
import * as Styles from '../_styled/vessel-filters.styled'
import {VesselTagSearch} from '../tags-search/tag-search'
import {SelectVesselsActionRow} from '../select-vessels-action-row'
import {VesselRow} from '../vessel-row'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../store/state/locations/state'
import {NoVesselMessage} from '../no-vessel-message'
import {filteredByTagsVesselIds} from '../../../../store/state/vessel-filter/reselector/filtered-by-tags-vessel-ids.reselector'
import {useDispatch} from 'react-redux'
import {toggleVessel} from '../../../../store/state/vessel-filter/action-creators'
import {
    pageTypeSelector,
    vesselFilterSelector,
} from '../../../../store/state/vessel-filter/selectors'
import {VesselOnlySearch} from '../tags-search/vessel-only-search'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../store/state/users/state'

function isChecked(id: LocationIdType, vesselsSelected: Set<LocationIdType> | undefined): boolean {
    return !vesselsSelected ? true : vesselsSelected.has(id)
}

interface VesselFilterProps {
    highlightedFilerValue: boolean
}

export function VesselFilter({highlightedFilerValue = false}: VesselFilterProps): JSX.Element {
    const dispatch = useDispatch()
    const locations = useTypedSelector(locationsSelector)
    const filterState = useTypedSelector(vesselFilterSelector)
    const vesselsSelected = filterState.locations
    const filteredVessels = filteredByTagsVesselIds(locations, filterState)
    const numOfAppliedFilter = !vesselsSelected ? undefined : vesselsSelected.size
    const showNoneButton = numOfAppliedFilter != 0
    const showAllButton = numOfAppliedFilter !== undefined
    const noVessel = filteredVessels.length === 0
    const pageType = useTypedSelector(pageTypeSelector)
    const {strictMode} = useTypedSelector(currentUserSelector)
    function setChecked(id: LocationIdType, checked: boolean): void {
        dispatch(toggleVessel(id, checked, pageType))
    }
    return (
        <CommonStyles.SectionContent>
            {!strictMode ? (
                <VesselTagSearch />
            ) : strictMode === StrictMode.Strict ? (
                <VesselOnlySearch />
            ) : (
                <VesselTagSearch />
            )}
            {!noVessel && (
                <SelectVesselsActionRow
                    showAllButton={showAllButton}
                    showNoneButton={showNoneButton}
                />
            )}
            <Styles.Vessels id="list-of-vessels-names">
                {noVessel && <NoVesselMessage />}
                {filteredVessels.map((id) => {
                    return (
                        <VesselRow
                            key={id}
                            locationId={id}
                            checked={isChecked(id, vesselsSelected)}
                            setChecked={setChecked}
                            highlightedFilerValue={
                                highlightedFilerValue && isChecked(id, vesselsSelected)
                            }
                        />
                    )
                })}
            </Styles.Vessels>
        </CommonStyles.SectionContent>
    )
}
