import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {IncidentsFilterReduxState} from './state'
import {LocationIdType} from '../locations/state'
import {TimeRange} from '../../../values/TimeRange'

export const incidentsFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.incidentsFilter.loadingState

export const incidentsFilterLoadingSelector = (state: AppState): boolean =>
    state.incidentsFilter.loadingState === LoadingState.RequestingData ||
    state.incidentsFilter.loadingState === LoadingState.NotPopulated

export const incidentsFilterSelector = (state: AppState): IncidentsFilterReduxState =>
    state.incidentsFilter

export const incidentsSearchIncidentNoSelector = (state: AppState): string | null =>
    state.incidentsFilter.searchIncidentNo
export const incidentFilterIncidentTypeSelector = (state: AppState): string | undefined =>
    state.incidentsFilter.selectedIncidentType
export const incidentsFilterCreatedToDataSelector = (state: AppState): string | null =>
    state.incidentsFilter.selectedCreatedToTimeRange

export const incidentsFilterCreatedFromRelativeSelector = (state: AppState): TimeRange | null =>
    state.incidentsFilter.createdFromRelative

export const incidentsFilterCreatedToRelativeSelector = (state: AppState): TimeRange | null =>
    state.incidentsFilter.createdToRelative

export const incidentsFilterUpdatedToRelativeSelector = (state: AppState): TimeRange | null =>
    state.incidentsFilter.updatedToRelative

export const incidentsFilterUpdatedFromRelativeSelector = (state: AppState): TimeRange | null =>
    state.incidentsFilter.updatedFromRelative

export const incidentsFilterCreatedFromDataSelector = (state: AppState): string | null =>
    state.incidentsFilter.selectedCreatedFromTimeRange
export const incidentsFilterUpdatedToDataSelector = (state: AppState): string | null =>
    state.incidentsFilter.selectedUpdatedToTimeRange
export const incidentsFilterUpdatedFromDataSelector = (state: AppState): string | null =>
    state.incidentsFilter.selectedUpdatedFromTimeRange
export const incidentsFilterVesselsSelector = (state: AppState): Set<LocationIdType> | undefined =>
    state.incidentsFilter.locations
export const incidentsFilterAssignedToSelector = (state: AppState): string | undefined =>
    state.incidentsFilter.assignedTo

export const incidentsFilterAssignedToVesselEmailSelector = (state: AppState): boolean | null =>
    state.incidentsFilter.assignedToVesselEmail

export const incidentsFilterGuestEmailToSelector = (state: AppState): string | undefined =>
    state.incidentsFilter.guestAssignedToEmail
export const incidentsFilterWatchIncidentsToSelector = (state: AppState): boolean | null =>
    state.incidentsFilter.watchedByCurrentUser
export const incidentsFilterUnseenIncidentsSelector = (state: AppState): boolean | null =>
    state.incidentsFilter.unseenIncidentResponses
export const incidentsSearchVesselTagTermSelector = (state: AppState): string[] | null =>
    state.incidentsFilter.searchVesselTagTerm

export const selectedFilterNameSelector = (state: AppState): string =>
    state.incidentsFilter.selectedFilterName

export const isActiveFilterSelector = (state: AppState): boolean =>
    state.incidentsFilter.selectedCreatedFromTimeRange != null ||
    state.incidentsFilter.selectedCreatedToTimeRange != null ||
    state.incidentsFilter.filterBySeverities != undefined ||
    (state.incidentsFilter.filterByStatus &&
        state.incidentsFilter.filterByStatus.length > 0 &&
        state.incidentsFilter.filterByStatus.length < 5) ||
    state.incidentsFilter.selectedIncidentType != undefined ||
    state.incidentsFilter.selectedUpdatedFromTimeRange != null ||
    state.incidentsFilter.selectedUpdatedToTimeRange != null ||
    state.incidentsFilter.assignedTo != undefined ||
    state.incidentsFilter.guestAssignedToEmail != undefined ||
    state.incidentsFilter.watchedByCurrentUser != null ||
    state.incidentsFilter.unseenIncidentResponses === true
