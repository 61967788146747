import * as Styled from './shared-button.styled'

interface SharedButtonProps {
    label: string
    onClickFn?: () => void
}

export function SharedButton({label, onClickFn}: SharedButtonProps): JSX.Element {
    return (
        <Styled.Button id={`${label}-button`} onClick={onClickFn}>
            <Styled.Text>{label}</Styled.Text>
        </Styled.Button>
    )
}
