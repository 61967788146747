import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {ReactNode, ReactNodeArray} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {isActiveLocation, Location} from '../../../../../store/state/locations/state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../../../../store/state/locations/selectors'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNodeArray
}

function getTitle(location: Location): string {
    if (isActiveLocation(location)) {
        return `${location.description} Risk Score`
    }

    return 'Fleet Risk Score'
}

export function WidgetWrapper({gridLayout, children}: WidgetWrapperProps): JSX.Element {
    const title = getTitle(useTypedSelector(activeLocationSelector))
    return (
        <Widget>
            <Header gridLayout={gridLayout} id="my-fleet-widget-header">
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout} id="my-fleet-widget-title">
                        {title}
                    </TitleText>
                </HeaderContent>
            </Header>
            {children}
        </Widget>
    )
}
