import * as Styled from './usb-details.styled'
import {DataCell} from '../shared/data-cell'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbModalDetailExpandedSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {UsbDevicesDetailType} from '../../../../../store/state/usb-inventory-filter/state'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'

export function UsbDetailsSection(): JSX.Element {
    const detailExpanded = useTypedSelector(usbModalDetailExpandedSelector)
    const {usbDetailsOutput} = usePagedUSBDevices()

    return (
        <Styled.DetailPanelSection id="usb-devices-details-modal_details-section_wrapper">
            <ExpandPanelButton headingRow={UsbDevicesDetailType.Details} />
            {detailExpanded?.detailsExpanded && (
                <Styled.DetailedAreaWrapper id="usb-devices-details-modal_details-section_section">
                    <Styled.DetailsAreaContent id="usb-devices-details-modal_details-section_content">
                        <DataCell label="Status:">{usbDetailsOutput.deviceStatus}</DataCell>
                        <DataCell label="First detected:">
                            {usbDetailsOutput.firstSeenTimestamp}
                        </DataCell>
                        <DataCell label="Last active:">
                            {usbDetailsOutput.lastSeenTimestamp}
                        </DataCell>
                        <DataCell label="ID:">{usbDetailsOutput.deviceId}</DataCell>
                        <DataCell label="Volume serials:">
                            {usbDetailsOutput.volumeSerials}
                        </DataCell>
                        <DataCell label="Activity counts:">
                            {usbDetailsOutput.totalNumberOfHostsPerItem} assets on{' '}
                            {usbDetailsOutput.totalNumberOfVesselsPerItem} vessel
                        </DataCell>
                    </Styled.DetailsAreaContent>
                </Styled.DetailedAreaWrapper>
            )}
        </Styled.DetailPanelSection>
    )
}
