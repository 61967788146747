import styled from 'styled-components'
import {mediumFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT = 600

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width >= BREAK_POINT ? '600px' : '500px')};
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    width: 100%;
    padding: ${spacing(2)};
    margin: 0;
`

export const Title = styled.h1`
    ${mediumFont()}
    text-align: center;
`

export const Subtitle = styled.div`
    ${smallFont()}
    padding: ${spacing(1)}
`

export const Highlighted = styled.span`
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${spacing(4)};
`
