import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getMarginBottom(gridLayout: GridLayout, lastRow = false): string {
    if (lastRow) {
        return '0'
    }

    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.THREE_COLUMNS_LARGE:
            return '10px'
        default:
            return '5px'
    }
}

interface RowProps {
    gridLayout: GridLayout
    deemphasize?: boolean
    lastRow?: boolean
}
export const Row = styled.div<RowProps>`
    margin-bottom: ${(props) => getMarginBottom(props.gridLayout, props.lastRow)};
    color: ${(props) =>
        props.deemphasize ? props.theme.colors.text.deemphasize : props.theme.colors.text.default};
    display: flex;
`
