import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Wrapper = styled.div`
    border-top: 2px solid ${(props) => props.theme.incidents.dataRow.separator};
`
interface HistoryProps {
    noHistory: boolean
}
export const TitleRowWrapper = styled.div<HistoryProps>`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)} ${spacing(2)} 0 0;
    ${(props) => (props.noHistory ? '' : 'height: 180px;')}
    overflow-y: auto;
    overflow-x: none;
`
