import styled from 'styled-components'
import {extraSmallFont, mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const CancelButton = styled.button`
    border: 1px solid ${(props) => props.theme.incidents.resetButton.background};
    background-color: white;
    color: ${(props) => props.theme.incidents.resetButton.background};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    padding: 0;
    margin-right: ${spacing(1)};
`

export const AddButton = styled.button`
    border: none;
    background-color: ${(props) => props.theme.incidents.resetButton.background};
    color: ${(props) => props.theme.incidents.resetButton.text};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    padding: 0;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    margin: ${spacing(1)};
    :disabled {
        opacity: 0.5;
        cursor: auto;
    }
`

export const EmailSectionWrapper = styled.div`
    background-color: ${(props) => props.theme.incidents.incidentStatusPopup.background};
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    width: 235px;
    min-height: 120px;
    border-radius: 2px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(1)};
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(3)};
`

export const EmailSectionTitle = styled.h2`
    margin: 0;
    padding: ${spacing(2)} ${spacing(2)} 0;
    ${extraSmallFont()};
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
`
export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    margin: 0 ${spacing(2)};
`
interface SearchInputProps {
    validEmail: boolean
    validBackground: boolean
}
export const SearchInput = styled.input<SearchInputProps>`
    ${extraSmallFont()};
    box-sizing: border-box;
    border: none;
    margin: 2px;
    padding: 4px;
    width: 90%;
    text-decoration: ${(props) => (props.validEmail ? 'none' : 'underline dotted red')};
    background-color: ${(props) =>
        props.validBackground ? props.theme.incidents.dataRow.editNoteBackground : 'none'};
`
export const CloseEmail = styled.img`
    width: 6px;
`
export const ErrorMessage = styled.h2`
    margin: 0;
    padding: 0 ${spacing(2)};
    ${extraSmallFont()};
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.text.error};
`
