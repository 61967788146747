import styled from 'styled-components'

const Heading = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Show = styled(Heading)``

export const Alerts = styled(Heading)`
    grid-column: 2 / span 2;
`
