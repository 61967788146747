import {useDispatch} from 'react-redux'
import {resetFilter} from '../../../../../store/state/my-vessels-filters/action-creators'
import {resetVesselFilter} from '../../../../../store/state/vessel-filter/action-creators'
import * as Styles from './reset-filter-button.styled'

export function ResetFilterButton(): JSX.Element {
    const dispatch = useDispatch()
    return (
        <Styles.Button
            id="reset-button"
            onClick={(e) => {
                e.preventDefault()
                dispatch(resetFilter())
                dispatch(resetVesselFilter())
            }}
        >
            Reset
        </Styles.Button>
    )
}
