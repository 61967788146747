import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    ${mediumSmallFont()};
`

export const ModalTitle = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding-top: ${spacing(1)};
    margin: 0;
`

export const DetailsExpandedSection = styled.div`
    position: relative;
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(1)} ${spacing(4)};
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    background-color: ${(props) => props.theme.unknownAssets.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

interface DetailsProps {
    width?: number
}

export const Details = styled.div<DetailsProps>`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: ${(props) =>
        props.width
            ? getGridTemplate(props.width)
            : '"NAME NAME-VALUE" "EMAIL EMAIL-VALUE" "TAG TAG-VALUE" "LOCK LOCK-VALUE"'};
    grid-row-gap: ${spacing(3)};
    grid-column-gap: ${spacing(1)};
    padding: ${spacing(2)} ${spacing(1)};
`

export const Roles = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: flex-start;
    padding: ${spacing(2)} ${spacing(1)};
`

export const Subscriptions = styled.div`
    justify-content: flex-start;
    padding: ${spacing(2)} ${spacing(1)};
`

export const DetailRow = styled.div`
    display: flex;
    padding: ${spacing(1)} 0;
    ${smallFont()}
`

interface ScrollableWrapperProp {
    noHistory: boolean
}
export const ScrollableWrapper = styled.div<ScrollableWrapperProp>`
    height: ${(props) => (props.noHistory ? '70px' : 'fit-content')};
    max-height: 200px;
    overflow-y: auto;
`

export const HistoryDetails = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'WHEN WHAT';
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(2)};
    padding: ${spacing(1)};
`

interface WhenProps {
    gridArea: string
}
function notesFont(): string {
    return `${smallFont()} letter-spacing: 0.24px;`
}

export const When = styled.div<WhenProps>`
    grid-area: ${(props) => props.gridArea};
    ${notesFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const What = styled.div<WhenProps>`
    grid-area: ${(props) => props.gridArea};
    ${notesFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`

function getGridTemplate(width: number): string {
    if (width < 600) {
        return '"NAME NAME-VALUE" "EMAIL EMAIL-VALUE" "TAG TAG-VALUE" "LOCK LOCK-VALUE"'
    }
    return '"NAME NAME-VALUE TAG TAG-VALUE" "EMAIL EMAIL-VALUE LOCK LOCK-VALUE"'
}
