import {ReactNode, ReactNodeArray} from 'react'
import {ThreatValue, ThreatValues} from '../../../../../helpers/threatHelper'
import {NodeValues} from '../../../../../values/nodes/NodeData'
import * as Styles from './border.styled'

interface BorderProps {
    threatLevel: ThreatValue
    nodeValue: NodeValues
    children: ReactNode | ReactNodeArray
}

export function Border({threatLevel, nodeValue, children}: BorderProps): JSX.Element {
    if (threatLevel === ThreatValues.MEDIUM || threatLevel === ThreatValues.HIGH) {
        return <Styles.WithBorder nodeValue={nodeValue}>{children}</Styles.WithBorder>
    }

    return <Styles.NoBorder>{children}</Styles.NoBorder>
}
