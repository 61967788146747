import {Check} from 'react-feather'
import {VesselDeploymentStatus} from '../../../../vessel-management/contexts/type/deployment-status-types'
import * as Styled from './deployment-summary-cell-styled'
import {GuidType} from '../../../../../values/generic-type-defintions'
import AlertHigh from '../../../../../@assets/icons/Alert high.svg'
import AlertMedium from '../../../../../@assets/icons/Alert medium.svg'
import {deploymentStatusBreakdown} from '../../../../vessel-management/components/vessel-list-table/vessel-details-modal/vessel-details/vessel-deployment-section/vessel-deployment-status-info'

interface DeploymentSummaryProps {
    vesselDeploymentStatusInfo: VesselDeploymentStatus
    locationId: GuidType
}

export function DeploymentSummaryCell({
    vesselDeploymentStatusInfo,
    locationId,
}: DeploymentSummaryProps): JSX.Element {
    const incus = getIncusStatus(vesselDeploymentStatusInfo, locationId)
    const agents = getAgents(vesselDeploymentStatusInfo, locationId)
    const traffic = getTraffic(vesselDeploymentStatusInfo, locationId)
    const dashboard = getDashboard(vesselDeploymentStatusInfo, locationId)
    return (
        <Styled.Row id={`deployment-summary-cell-${locationId}`}>
            <Styled.Column>{incus}</Styled.Column>
            <Styled.Column>{agents}</Styled.Column>
            <Styled.Column>{traffic}</Styled.Column>
            <Styled.Column>{dashboard}</Styled.Column>
        </Styled.Row>
    )
}
function getDashboard(
    vesselDeploymentStatus: VesselDeploymentStatus,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('dashboard', vesselDeploymentStatus, locationId)
    return (
        <>
            <Styled.Label id={`deployment-summary-Label-${locationId}-dashboard`}>
                Dashboard
            </Styled.Label>
            <Styled.StatusRow>{icon}</Styled.StatusRow>
        </>
    )
}

function getTraffic(
    vesselDeploymentStatus: VesselDeploymentStatus,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('traffic', vesselDeploymentStatus, locationId)
    return (
        <>
            <Styled.Label id={`deployment-summary-Label-${locationId}-traffic`}>
                Traffic
            </Styled.Label>
            <Styled.StatusRow>{icon}</Styled.StatusRow>
        </>
    )
}

function getAgents(
    vesselDeploymentStatus: VesselDeploymentStatus,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('agents', vesselDeploymentStatus, locationId)

    return (
        <>
            <Styled.Label id={`deployment-summary-Label-${locationId}-agents`}>Agents</Styled.Label>
            <Styled.StatusRow>{icon}</Styled.StatusRow>
        </>
    )
}

function getIncusStatus(
    vesselDeploymentStatus: VesselDeploymentStatus,
    locationId: GuidType,
): JSX.Element {
    const icon = getIcon('incus', vesselDeploymentStatus, locationId)

    return (
        <>
            <Styled.Label id={`deployment-summary-Label-${locationId}-incus`}>Incus</Styled.Label>
            <Styled.StatusRow>{icon}</Styled.StatusRow>
        </>
    )
}

function getIcon(
    type: deploymentStatusBreakdown,
    vesselDeploymentStatus: VesselDeploymentStatus,
    locationId: GuidType,
): JSX.Element | null {
    const vesselOperationalStatus = vesselDeploymentStatus?.operationalStatus?.[locationId] ?? null
    if (!vesselOperationalStatus) {
        return <Check color="green" />
    }
    switch (type) {
        case 'incus':
            const hasIncus = vesselOperationalStatus.operating
            if (hasIncus) {
                return <Check color="green" id={`deployment-summary-img-${locationId}-incus`} />
            }
            return (
                <img
                    src={AlertHigh}
                    title={'Incus not installed'}
                    width={25}
                    id={`deployment-summary-img-${locationId}-incus`}
                />
            )
        case 'agents':
            const agentsNeedUpdating = vesselOperationalStatus.upgradeAssets
            if (!agentsNeedUpdating || agentsNeedUpdating.length === 0) {
                return <Check color="green" id={`deployment-summary-img-${locationId}-agents`} />
            }
            return (
                <img
                    src={AlertMedium}
                    width={24}
                    id={`deployment-summary-img-${locationId}-agents`}
                />
            )
        case 'traffic':
            const traffic = vesselDeploymentStatus.trafficStatus?.[locationId]
            if (!traffic) {
                return <Check color="green" id={`deployment-summary-img-${locationId}-traffic`} />
            }
            return (
                <img
                    src={AlertHigh}
                    width={24}
                    id={`deployment-summary-img-${locationId}-traffic`}
                />
            )
        case 'dashboard':
            const hasDashboard = vesselDeploymentStatus.vesselDashboard[locationId]
            return hasDashboard ? (
                <Check color="green" id={`deployment-summary-img-${locationId}-dashboard`} />
            ) : (
                <img
                    src={AlertMedium}
                    width={24}
                    id={`deployment-summary-img-${locationId}-dashboard`}
                />
            )
        default:
            return null
    }
}
