import {RefreshButton} from '../common/refresh-button/refresh-button'
import * as styled from '../common/data-actions.styled'
import {usePagedUniqueFlows} from '../../../contexts/unique-flows/use-paged-unique-flows'

export function DataActions(): JSX.Element {
    const {loading, refreshData, totalNumberOfFlows} = usePagedUniqueFlows()

    return (
        <styled.DataActions>
            <RefreshButton
                id="unique_flows_refresh"
                loading={loading}
                totalNumberOfRecords={totalNumberOfFlows}
                refreshData={refreshData}
            />
        </styled.DataActions>
    )
}
