export enum ActionType {
    CHANGE_PAGE_SIZE_ACTION = '[PAGED ALERT CONTEXT] CHANGE PAGE SIZE ACTION',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED ALERT CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    SWITCH_TO_CACHED_PAGE_DATA_ACTION = '[PAGED ALERT CONTEXT] SWITCH TO CACHED PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED ALERT CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED ALERT CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    SET_ERROR_ACTION = '[PAGED ALERT CONTEXT] SET ERROR ACTION',
    CLEAR_DATE_FILTER = '[PAGED ALERT CONTEXT] CLEAR DATE FILTER',
    SET_DATE_FILTER = '[PAGED ALERT CONTEXT] SET DATE FILTER',
    DISPLAY_DATE_FILTER = '[PAGED ALERT CONTEXT] DISPLAY DATE FILTER',
}
