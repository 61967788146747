import {ThreatValue} from '../../../../../helpers/threatHelper'
import {LocationIdType} from '../../../../../store/state/locations/state'
import * as Styled from './current-vessel-score.styled'

interface CurrentVesselScoreProps {
    locationId: LocationIdType
    score: number
    threatLevel: ThreatValue
}
export function CurrentVesselScore({
    locationId,
    score,
    threatLevel,
}: CurrentVesselScoreProps): JSX.Element {
    return (
        <Styled.VesselThreatScore
            id={`threat-score-${locationId}`}
            score={score}
            threatLevel={threatLevel}
        >
            {score}
        </Styled.VesselThreatScore>
    )
}
