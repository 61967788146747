import {NoDataMessageGrid, NoDataMessage} from './no-data.styled'
import {TableDataRow} from '../_styled/table-row.styled'
import {TableDataCell} from '../_styled/table-data-cell.styled'

interface NoDataTableProps {
    text: string
}
export function NoDataTable({text}: NoDataTableProps): JSX.Element {
    return (
        <NoDataMessageGrid>
            <TableDataRow gridRow={1} />
            <TableDataCell gridRow={1} gridColumn={5} centered>
                <NoDataMessage id="no-data-component">{text}</NoDataMessage>
            </TableDataCell>
        </NoDataMessageGrid>
    )
}
