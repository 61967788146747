import {CardArea} from './_styled/card-area.styled'
import {IncidentCard} from './incident-card'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {NoData} from './no-data/no-data'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {useDispatch} from 'react-redux'
import {useRef} from 'react'
import {isNoteCreationSelector} from '../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {
    displayModal,
    setPendingNavigation,
} from '../../../../store/state/unsaved-incident-note-alert-modal/action-creators'

export function IncidentCardsPopulated(): JSX.Element {
    const {width} = useDimensions()

    const {dataIncidents, totalNumberOfFilteredIncidents} = usePagedIncidents()

    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)

    const dispatch = useDispatch()

    const clickOutside = useRef<HTMLDivElement>(null)
    const handleOutsideClick = (event: Event) => {
        if (noteCreationStatus) {
            dispatch(displayModal())
            // Store the intended navigation path
            const path = (event.target as HTMLElement).closest('a')?.getAttribute('href')
            if (path) {
                dispatch(setPendingNavigation(path))
            }
        }
    }
    useOnClickOutside(clickOutside, handleOutsideClick)

    if (!dataIncidents || totalNumberOfFilteredIncidents === 0) {
        return <NoData text="There are no incidents matching your filtering criteria" />
    }

    return (
        <CardArea width={width} ref={clickOutside} id="card-area-grid">
            {dataIncidents.map((incident) => (
                <IncidentCard key={incident.identity} incident={incident} />
            ))}
        </CardArea>
    )
}
