import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {TableHeader} from './table-header'
import * as Styled from './_styled/user-management-list.styled'
import {TableRow} from './table-row'
import {AddNewPolicyDetailsModal} from './policy-details-modal/add-new-policy-details-modal'
import {PolicyDetailsModal} from './policy-details-modal/policy-details-modal'
import {useMemo} from 'react'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {AllPolicyData} from '../../contexts/policy-management/type/policy-management-state'

interface PolicyListTableProps {
    policies: AllPolicyData[]
}

export function PolicyListTable({policies}: PolicyListTableProps): JSX.Element {
    const {width} = useDimensions()
    const {selectedPolicyId, showPolicyModal} = usePolicyManagement()
    const displayPolicyDetailsModal = useMemo(() => {
        return selectedPolicyId != null && showPolicyModal
    }, [selectedPolicyId, showPolicyModal])

    const displayAddNewPolicyDetailsModal = useMemo(() => {
        return !selectedPolicyId && showPolicyModal
    }, [selectedPolicyId, showPolicyModal])
    return (
        <div style={{minHeight: '200px'}}>
            <TableHeader />
            <Styled.ContentContainer width={width}>
                <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
                    <Styled.TableDataGrid
                        width={width}
                        showDetailsBtn={
                            !displayPolicyDetailsModal && !displayAddNewPolicyDetailsModal
                        }
                    >
                        {policies.map((policy, index) => (
                            <TableRow
                                key={policy.identity}
                                policy={policy}
                                index={index}
                                totalNumberOfPolicy={policies.length}
                            />
                        ))}
                    </Styled.TableDataGrid>
                    {displayPolicyDetailsModal && <PolicyDetailsModal />}
                    {displayAddNewPolicyDetailsModal && <AddNewPolicyDetailsModal />}
                </div>
            </Styled.ContentContainer>
        </div>
    )
}
