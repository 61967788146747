import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
interface FilterBarWrapperProps {
    width: number
}
export const FilterBarWrapper = styled.div<FilterBarWrapperProps>`
    background-color: ${(props) => props.theme.unknownAssets.dataArea.background};
    position: relative;
`
export const FilterBarStyled = styled.div`
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    min-height: 250px;
    box-shadow: ${(props) => props.theme.unknownAssets.filterBar.boxShadow};
`

interface SectionContentProps {
    isInactive: boolean
}

export const SectionContent = styled.div<SectionContentProps>`
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
    overflow-y: none;
    overflow-x: none;
    z-index: 0;
    border-top: ${(props) => `1px solid ${props.theme.colors.border.lowContrast}`};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`

export const Loading = styled.div`
    padding: 0;
    height: 400px;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background.default};
    box-shadow: ${(props) => props.theme.unknownAssets.filterBar.boxShadow};
`
interface FilterBarCardsWrapper {
    width: number
}
export const FilterBarCardsWrapper = styled.div<FilterBarCardsWrapper>`
    min-width: 320px;
    width: ${(props) => (props.width > 400 ? '388px' : 'fit-content')};
    display: flex;
    flex-direction: column;
    margin: ${spacing(2)} auto;
`
export const TopCloseFilterBar = styled.div`
    margin: 0 auto ${spacing(1)} auto;
`
export const BottomCloseFilterBar = styled.div`
    margin: ${spacing(1)} auto 0 auto;
`

export const SavedFilterContent = styled.div<SectionContentProps>`
    padding: ${spacing(1)} ${spacing(4)};
    margin: 0;
    width: 100%;
    overflow-y: none;
    overflow-x: none;
    z-index: 0;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.border.lowContrast}`};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`
