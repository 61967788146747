import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {
    BREAK_POINT_CARD_VIEW,
    SHOW_DETAILS_BREAK_POINT_CARD_VIEW,
} from '../../../vessel-management.styled'

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier
        ? width < BREAK_POINT_CARD_VIEW
        : width < SHOW_DETAILS_BREAK_POINT_CARD_VIEW
}

export function getGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(2)} 210px 150px auto 220px 220px 180px 180px 140px 140px ${spacing(2)}`
    }
    if (width > 1500) {
        return `${spacing(2)} 200px 150px auto 210px 210px 170px 170px 160px 160px ${spacing(2)}`
    }
    if (width > 1400) {
        return `${spacing(2)} 190px 145px auto 190px 190px 150px 150px 140px 140px ${spacing(2)}`
    }
    return `${spacing(1)} 190px 145px auto 170px 170px 130px 130px 120px 120px ${spacing(1)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1660) {
        return `${spacing(1)} 100px 145px 75px 160px 160px 125px 125px 130px 130px ${spacing(1)}`
    }
    if (width > 1565) {
        return `${spacing(1)} 100px 140px 75px 130px 130px 125px 125px 130px 130px ${spacing(1)}`
    }
    if (width > 1515) {
        return `${spacing(1)} 100px 115px 75px 115px 115px 120px 120px 115px 115px ${spacing(1)}`
    }
    return '0 135px 135px 110px 125px 125px 110px 110px 100px 100px 0'
}

interface TableHeaderGridProps {
    width: number
    showReducedGrid: boolean
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showReducedGrid
            ? getReducedGridTemplateColumns(props.width)
            : getGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    padding: 0 ${spacing(1)};
`

interface TableHeaderCellProps {
    gridColumn: number
    width: number
    centered?: boolean
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
`
