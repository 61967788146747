import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {getsectionWidth} from '../incidents-types-and-severities/incidents-types-and-severities.styled'

interface ContentAreaProps {
    width: number
    narrowLayoutForOpenFilter: boolean
}
export const ContentArea = styled.div<ContainerProps>`
    padding: 0 ${spacing(2)};
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(5)};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    color: ${(props) => props.theme.incidents.dataRow.text};
    border: ${spacing(1)} solid #fff;
`
export const SectionContent = styled.div<ContentAreaProps>`
    display: flex;
    ${(props) => !props.narrowLayoutForOpenFilter && 'justify-content: space-between'};
    flex-direction: ${(props) =>
        props.width < 1000 || props.narrowLayoutForOpenFilter ? 'column' : 'row'};
    gap: 10px;
`
interface ContainerProps {
    width: number
}

export const StatusFilterContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    background-color: #fff;
    padding: ${spacing(3)};
    width: ${(props) => getsectionWidth(props.width)}px;
`
export const SectionText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const WrapperOfStatusesCount = styled.div`
    display: flex;
    width: 100%;
    gap: ${spacing(6)};
`
