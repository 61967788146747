import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CardDataCell} from './card-data-cell'
import {ToggleButtonCell} from './details/toggle-button-cell'
import {ToggleDetailsButton} from './details/toggle-details-button'
import * as Styled from './_styled/policy-management-card.styled'
import {PolicyManagementDetails} from './_styled/policy-management-details.styled'
import moment from 'moment'
import {
    AllPolicyData,
    PolicyType,
} from '../../contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {PolicyDetailsExpanded} from './policy-details-expanded'
import {NumberOfVessels} from '../policy-list-table/_styled/user-management-table-data.styled'
import {AssignPolicyDialogCard} from '../shared/assign-policy-dialog/assign-policy-dialog-card'

interface PolicyListCardProps {
    policy: AllPolicyData
}

export function PolicyListCard({policy}: PolicyListCardProps): JSX.Element {
    const {width} = useDimensions()
    const {selectedPolicyId, showAssignPolicyDialog, assignPolicyDialogState} =
        usePolicyManagement()
    const isExpanded = policy.identity === selectedPolicyId

    return (
        <PolicyManagementDetails activePolicy={isExpanded}>
            <Styled.Card width={width}>
                <CardDataCell
                    gridArea="TYPE"
                    label="Policy type:"
                    policy={policy}
                    identifier="name"
                >
                    {policy.policyType}
                </CardDataCell>
                <CardDataCell
                    gridArea="NAME"
                    label="Policy name:"
                    policy={policy}
                    identifier="type"
                >
                    {policy.policyName}
                </CardDataCell>
                <CardDataCell
                    gridArea="VESSEL"
                    label="Vessels applied:"
                    policy={policy}
                    identifier="name"
                >
                    <div
                        onClick={() =>
                            policy.policyType !== PolicyType.INCIDENT &&
                            showAssignPolicyDialog(
                                policy.identity,
                                policy.locations,
                                policy.policyType,
                            )
                        }
                    >
                        <NumberOfVessels isActive={policy.policyType !== PolicyType.INCIDENT}>
                            {policy.locations?.length ?? 0}
                        </NumberOfVessels>
                    </div>
                    {policy.identity === assignPolicyDialogState.selectedPolicyId && (
                        <AssignPolicyDialogCard policy={policy} />
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="UPDATED"
                    label="Last updated:"
                    policy={policy}
                    identifier="last-updated"
                >
                    {moment(policy.lastUpdate).fromNow()}
                </CardDataCell>

                <ToggleButtonCell gridArea="VIEW-DETAIL">
                    <ToggleDetailsButton type={policy.policyType} id={policy.identity} />
                </ToggleButtonCell>
            </Styled.Card>
            {isExpanded && <PolicyDetailsExpanded />}
        </PolicyManagementDetails>
    )
}
