import {useState} from 'react'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {TargetType} from '../../../../metrics-beta/contexts/types/metrics-summary'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import * as Styled from './_styled/framework-summary-target.styled'
import {SectionText, StatusFilterContainer} from './widget-commonstyling.styled'
import {WidgetIconsSection} from './widget-icons-section'
import {
    findMetricCategory,
    getAnalysisPeriodDisplayName,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {StringUtils} from '../../../../../utils/Utils'

export function FrameworkSummaryTarget(): JSX.Element {
    const {metricsWidgetData} = useMetricsWidget()
    const targetProtection = metricsWidgetData?.get(MetricType.PROTECTION)?.target
    const targetMaintenance = metricsWidgetData?.get(MetricType.MAINTENANCE)?.target
    const targetBehaviour = metricsWidgetData?.get(MetricType.BEHAVIOUR)?.target

    return (
        <StatusFilterContainer>
            <SectionText>METRICS TARGET SUMMARY</SectionText>
            <Styled.ContentWrapper id="framework-summary-target-wrapper">
                <WidgetIconsSection />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <TargetBarColour
                        targetData={targetProtection}
                        metricType={MetricType.PROTECTION}
                    />
                    <TargetBarColour
                        targetData={targetMaintenance}
                        metricType={MetricType.MAINTENANCE}
                    />
                    <TargetBarColour
                        targetData={targetBehaviour}
                        metricType={MetricType.BEHAVIOUR}
                    />
                </div>
            </Styled.ContentWrapper>
        </StatusFilterContainer>
    )
}
function TargetBarColour({
    targetData,
    metricType,
}: {
    targetData: TargetType | undefined
    metricType: MetricType
}): JSX.Element {
    const totalVesselsAboveTarget = targetData?.aboveTarget ?? 0
    const totalVesselsBellowTarget = targetData?.belowTarget ?? 0

    const {locationSelectionNumber} = useMetricsWidget()
    const scoreOfVesselsAbove = Math.round(
        (totalVesselsAboveTarget / locationSelectionNumber) * 100,
    )
    const scoreOfVesselsBelow = Math.round(
        (totalVesselsBellowTarget / locationSelectionNumber) * 100,
    )

    return (
        <Styled.TargetBarWrapper>
            {scoreOfVesselsAbove != 0 && (
                <ScoreBar
                    score={scoreOfVesselsAbove}
                    targetType="above"
                    metricType={metricType}
                    vesselsNumber={totalVesselsAboveTarget}
                />
            )}
            {scoreOfVesselsBelow != 0 && (
                <ScoreBar
                    score={scoreOfVesselsBelow}
                    targetType="below"
                    metricType={metricType}
                    vesselsNumber={totalVesselsBellowTarget}
                />
            )}
        </Styled.TargetBarWrapper>
    )
}

interface ScoreBarProps {
    score: number
    targetType: 'above' | 'below'
    metricType: MetricType
    vesselsNumber: number
}
function ScoreBar({score, targetType, metricType, vesselsNumber}: ScoreBarProps): JSX.Element {
    const [visibleTippy, setVisibleTippy] = useState(false)

    return (
        <Styled.ScoreBar
            score={score}
            targetType={targetType}
            id={`target-score-bar-above-${metricType}-${score}`}
            onMouseEnter={() => {
                setVisibleTippy(true)
            }}
            onMouseLeave={() => {
                setVisibleTippy(false)
            }}
        >
            {visibleTippy && (
                <TippyContent
                    metricType={metricType}
                    score={score}
                    vesselsNumber={vesselsNumber}
                    targetType={targetType}
                />
            )}
        </Styled.ScoreBar>
    )
}
interface TippyContentProps {
    metricType: MetricType
    score: number
    vesselsNumber: number
    targetType: 'above' | 'below'
}

function TippyContent({
    metricType,
    score,
    targetType,
    vesselsNumber,
}: TippyContentProps): JSX.Element {
    const {selectedAnalysisPeriod} = useMetricsWidget()
    return (
        <Styled.TippyWrapper positionRightText={metricType === MetricType.BEHAVIOUR}>
            <div style={{display: 'flex', gap: '5px', fontWeight: 'bold'}}>
                <img
                    src={getMetricTypeIcon(findMetricCategory(metricType))}
                    id={`metricTypeIconTippy-${metricType}-${score}`}
                    width={20}
                    height={20}
                />
                {StringUtils.capitaliseString(metricType)}
            </div>
            <Styled.TippyTextColour targetType={targetType}>
                Vessels {targetType} target: {vesselsNumber}
            </Styled.TippyTextColour>
            <div> {getAnalysisPeriodDisplayName(selectedAnalysisPeriod)}</div>
        </Styled.TippyWrapper>
    )
}
