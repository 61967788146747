import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {
    CloseImage,
    Content,
    ContentText,
    ContentWrapper,
    Footer,
    Header,
    IconDetail,
    Logo,
    Section,
    SubTitle,
    Title,
} from '../_styled/incident-management.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import FolderWarning from '../../../../../@assets/icons/folder-warning.svg'
import VesselIcon from '../../../../../@assets/icons/vessel-white.svg'
import OwlLogo from '../../../../../@assets/icons/owl-logo.svg'
import PageIcon from '../../../../../@assets/icons/page-white.svg'
import Person from '../../../../../@assets/icons/Person.svg'
import {ContactLink} from '../../../../../components/shared/outline-button/outline-button.styled'

export function IncidentManagementModalDetail(): JSX.Element {
    const {width} = useDimensions()
    const {showIncidentManagementModal} = usePagedIncidents()
    return (
        <Section width={width}>
            <Header>
                <Logo>
                    <img id="image0_0_160" src={OwlLogo} />
                </Logo>
                <Title>Incident management</Title>
                <SubTitle>
                    Continuous monitoring of security events for your vessels and accurate triage
                    alerts
                </SubTitle>
                <CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        showIncidentManagementModal(false)
                    }}
                    id="close-details-icon"
                ></CloseImage>
            </Header>
            <Content>
                <ContentWrapper>
                    <IconDetail src={FolderWarning} />
                    <ContentText>
                        Easy and effective workflow for managing response to incidents
                    </ContentText>
                </ContentWrapper>
                <ContentWrapper>
                    <IconDetail src={VesselIcon} />
                    <ContentText>Assign actions to Medulla users and vessel Masters</ContentText>
                </ContentWrapper>
                <ContentWrapper>
                    <IconDetail src={PageIcon} />
                    <ContentText>Incident reports with clear recommend actions</ContentText>
                </ContentWrapper>
                <ContentWrapper>
                    <IconDetail src={Person} />
                    <ContentText>
                        Up to 2 hours of face-to-face security guidance to review incidents, metrics
                        or other bespoke queries
                    </ContentText>
                </ContentWrapper>
            </Content>
            <Footer>
                <ContactLink href="mailto:customersuccess@cyberowl.io" hoverColour={'#ffffff'}>
                    Contact us to enable
                </ContactLink>
            </Footer>
        </Section>
    )
}
