import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './filter-bar.styled'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {useVesselManagement} from '../../contexts/use-vessel-management'
import {ResetFilterButton} from '../../../../components/page-filter/components/shared/reset-filter/reset-filter-button'
import {resetFilter} from '../../../../store/state/vessel-management-filter/action-creators'
import {VesselDeploymentStatusFilter} from './vessel-depolyment-status-filter/vessel-depolyment-status-filter'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {displayFilterBar} = useVesselManagement()
    const isLoadingFilter = false

    if (isLoadingFilter) {
        return (
            <Styled.Loading>
                <LoadingSpinner />
            </Styled.Loading>
        )
    }
    return (
        <Styled.FilterBarWrapper width={width}>
            <div
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(false)
                    // showFilterEvent(false)
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styled.FilterBarStyled>
                <Styled.SectionHeader>
                    <Styled.SectionTitle>Filter</Styled.SectionTitle>
                </Styled.SectionHeader>
                <Styled.SectionContent>
                    <VesselDeploymentStatusFilter />
                </Styled.SectionContent>
                <VesselFilters pageType={PageType.VESSEL_MANAGEMENT} />
                <ResetFilterButton
                    resetFn={resetFilter}
                    currentPage={AuditLogPageType.VESSEL_MANAGEMENT}
                />
            </Styled.FilterBarStyled>
        </Styled.FilterBarWrapper>
    )
}
