import * as Styled from '../unknown-assets-header.styled'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {useEffect} from 'react'
import {usePagedUnknownAssetsBeta} from '../context/use-paged-unknown-assets'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import LoadingState from '../../../values/loading-state-enum'
import {FilterBarButton} from './filter-bar-button/filter-bar-button'
import {ContentContainerTop} from '../../../templates/fixed-page/content-container-top.styled'
import {queryStringObjectSelector} from '../../../store/routerSelectors'
import {GuidType} from '../../../values/generic-type-defintions'
import {useDispatch} from 'react-redux'
import {networkAssetsFilterSelector} from '../../../store/state/network-assets-filter/selectors'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../store/state/vessel-filter/selectors'
import {setVesselFilterPref} from '../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {activeLocationSelector} from '../../../store/state/locations/selectors'
import {isActiveLocation} from '../../../store/state/locations/state'
import {compareFilters} from '../components/data-helper'
import {
    DEFAULT_NETWORK_ASSETS_FILTER,
    NetworkAssetsFilter,
    NetworkAssetsFilterCriteria,
} from '../../../values/user-preferences/network-assets-filter'
import {setSelectedFilterName} from '../../../store/state/network-assets-filter/action-creators'
import {savedNetworkAssetsFiltersSelector} from '../../../store/state/saved-filters/selectors'
import {getFormattedNetworkAssetsFilter} from '../../../components/saved-search/components/shared/helper'
import {GenerateReportButton} from '../../../components/generate-report-button/generate-report-button/generate-report-button'
import {RefreshButtonNetworkAssets} from './refresh-button/refresh-button-network-assets'
import {currentUserSelector} from '../../../store/state/current-user/selectors'
import {StrictMode} from '../../../store/state/users/state'
import {createLocationSelectionNumberReselector} from '../../../store/state/vessel-filter/reselector/location-selection-number-reselector'
import AppState from '../../../store/types/app-state'

export function UnknownAssetsHeader(): JSX.Element {
    const dispatch = useDispatch()
    const {
        setFilter,
        networkAssetModalId,
        createReport,
        reportUrl,
        showGenerateReportPopup,
        reportDialogShown,
    } = usePagedUnknownAssetsBeta()

    const {
        searchedIpAddress,
        searchedMacDescription,
        states,
        networks,
        behaviours,
        searchedHostname,
        properties,
        orderBy,
        locations,
        searchVesselNameTerm,
        searchVesselTagTerm,
        selectedFilterName,
        fromRelativeLastSeen,
    } = useTypedSelector(networkAssetsFilterSelector)

    const newFilter = useTypedSelector(networkAssetsFilterSelector)
    const currentFilter = useTypedSelector(savedNetworkAssetsFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria

    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)

    const activeLocation = useTypedSelector(activeLocationSelector)
    const location = isActiveLocation(activeLocation) ? activeLocation.location : undefined

    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryId = queryString?.networkAssetDetails as GuidType

    const externalGuid =
        networkAssetModalId == null ? queryId != undefined : networkAssetModalId === queryId

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.NETWORK_ASSETS,
                ),
            )
        }
    }, [dispatch, locations, searchVesselNameTerm, searchVesselTagTerm, vesselFilterLoading])

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated && !externalGuid) {
            setFilter(
                orderBy,
                searchedIpAddress,
                searchedMacDescription,
                searchedHostname,
                properties,
                states,
                networks,
                behaviours,
                fromRelativeLastSeen,
                locations,
                searchVesselTagTerm,
                searchVesselNameTerm,
                location,
                externalGuid,
                queryId,
            )
        } else {
            compareFilters(
                currentFilter
                    ? (getFormattedNetworkAssetsFilter(
                          currentFilter as NetworkAssetsFilterCriteria,
                      ) as NetworkAssetsFilter)
                    : DEFAULT_NETWORK_ASSETS_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))

            setFilter(
                orderBy,
                searchedIpAddress,
                searchedMacDescription,
                searchedHostname,
                properties,
                states,
                networks,
                behaviours,
                fromRelativeLastSeen,
                vesselFilterLocations,
                vesselFilterTagTerm,
                vesselFilterNameTerm,
                location,
                externalGuid,
                queryId,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        vesselFilterLocations,
        vesselFilterTagTerm,
        vesselFilterNameTerm,
        orderBy,
        searchedIpAddress,
        searchedMacDescription,
        searchedHostname,
        properties,
        states,
        networks,
        behaviours,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        location,
        fromRelativeLastSeen,
    ])

    const {width} = useDimensions()
    const {strictMode} = useTypedSelector(currentUserSelector)
    const locationSelectionNumberReselector = createLocationSelectionNumberReselector(
        networkAssetsFilterSelector,
    )
    const state: AppState = useTypedSelector((state) => state)
    const selectedLocationNumber = locationSelectionNumberReselector(state)

    const moreThanOneVesselSelected = selectedLocationNumber > 1
    const isGenerateReportDisabled = strictMode === StrictMode.Strict && moreThanOneVesselSelected

    return (
        <ContentContainerTop width={width} id="UnknownAssetsHeader">
            <Styled.Container>
                <FilterBarButton />
                <div style={{display: 'flex'}}>
                    <GenerateReportButton
                        createReport={createReport}
                        reportUrl={reportUrl}
                        showGenerateReportPopup={showGenerateReportPopup}
                        reportDialogShown={reportDialogShown}
                        pageType={PageType.NETWORK_ASSETS}
                        isDisabled={isGenerateReportDisabled}
                    />
                    <RefreshButtonNetworkAssets />
                </div>
            </Styled.Container>
        </ContentContainerTop>
    )
}
