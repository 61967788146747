import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import LoadingState from '../../../../../values/loading-state-enum'
import {useUserManagement} from '../../../context/use-user-management'
import {UserDetailsReadOnly} from './user-details-read-only'
import {UserDetails} from './user-details'
import {ContentWrapper} from './_styled/panel-section.styled'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {isUserAllowed} from '../../../../../values/Role'

export function UserDetailsModal(): JSX.Element {
    const {width} = useDimensions()
    const {loadingDataState} = useUserManagement()
    const currentUser = useTypedSelector(currentUserSelector)
    const isAdminRole = isUserAllowed(currentUser.roles, [
        'CUSTOMER_ADMIN',
        'DEVELOPER',
        'THREAT_ANALYST',
    ])

    return (
        <ContentWrapper width={width}>
            {loadingDataState === LoadingState.Loaded ? (
                isAdminRole ? (
                    <UserDetails />
                ) : (
                    <UserDetailsReadOnly />
                )
            ) : (
                <DataLoading />
            )}
        </ContentWrapper>
    )
}
