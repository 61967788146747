import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {IncidentStatus} from '../../../contexts/types/incident-status'

function getBackgroundColor(incidentStatus: IncidentStatus, theme: CyberOwlTheme): string {
    switch (incidentStatus) {
        case IncidentStatus.NEW:
            return theme.incidents.incidentStatus.new
        case IncidentStatus.OPEN:
            return theme.incidents.incidentStatus.open
        case IncidentStatus.CLOSED:
        case IncidentStatus.CLOSED_FALSE_POSITIVE:
        case IncidentStatus.CLOSED_MONITOR:
            return theme.incidents.incidentStatus.closed
        default:
            return theme.incidents.incidentStatus.draft
    }
}
export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${spacing(1)};
    padding: 0 0 ${spacing(1)};
`
interface SquareProps {
    incidentStatus: IncidentStatus
    activeFilter: boolean
}

export const Square = styled.span<SquareProps>`
    color: white;
    background-color: ${(props) => getBackgroundColor(props.incidentStatus, props.theme)};
    height: ${spacing(4)};
    width: ${spacing(11)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    opacity: ${(props) => (props.activeFilter ? 1 : 0.5)};
`

export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const StatusesWrapper = styled.div`
    display: flex;
    column-gap: ${spacing(2)};
    justify-content: space-between;
`
