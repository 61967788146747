import * as Styled from './_styled/license-table-data.styled'
import {License, LicenseKeysTypes, LicenseKeyType} from '../../../store/state/config/state'
import {EnableToggle} from './toggle/enableToggle'
import {useLicenses} from '../contexts/use-licenses'
import {UseLicensesOutput} from '../contexts/UseLicensesOutput'

interface TableRowProps<T extends LicenseKeysTypes> {
    license: License<T> | undefined
    index: number
    title: string
    toggleChange: (value: boolean) => void
    lKey: LicenseKeyType<LicenseKeysTypes>
}

export function TableRow<T extends LicenseKeysTypes>({
    index,
    license,
    title,
    toggleChange,
    lKey,
}: TableRowProps<T>): JSX.Element {
    const gridRow = index + 1
    const context = useLicenses()
    const [endDateInput, maxVesselsInput] = getInput(lKey, context)

    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-license-row_${lKey}`} />
            <Styled.TableDataCell gridRow={gridRow} gridColumn={2} id={`license-row-type${lKey}`}>
                {
                    <EnableToggle
                        enabled={license?.value.enabled ?? false}
                        setChange={toggleChange}
                        id={`enable_toggle_${lKey}`}
                    />
                }
            </Styled.TableDataCell>
            <Styled.TableDataCell gridRow={gridRow} gridColumn={3} id={`license-row-title${lKey}`}>
                {title}
            </Styled.TableDataCell>
            {maxVesselsInput && (
                <Styled.TableDataCell
                    gridRow={gridRow}
                    gridColumn={4}
                    id={`license-row-inputVes${lKey}`}
                >
                    {maxVesselsInput}
                </Styled.TableDataCell>
            )}
            {endDateInput && (
                <Styled.TableDataCell
                    gridRow={gridRow}
                    gridColumn={5}
                    id={`license-row-inputDate${lKey}`}
                >
                    {endDateInput}
                </Styled.TableDataCell>
            )}
        </>
    )
}

function getInput(
    key: LicenseKeyType<LicenseKeysTypes>,
    context: UseLicensesOutput,
): [JSX.Element, JSX.Element | null] {
    function onDateChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let date: Date | null
        if (Number.isNaN(e.target.valueAsNumber)) {
            date = null
        } else {
            date = new Date(e.target.valueAsNumber)
        }
        switch (key) {
            case 'License:Incidents':
                context.setIncidentsPolicyEndDate(date)
                break
            case 'License:Metrics:OnboardEngagement':
                context.setOnboardEngagementEndDate(date)
                break
            case 'License:Enforcement:Networks':
                context.setNetworkEnforcementEndDate(date)
                break
            case 'License:Enforcement:Removables':
                context.setRemovableEnforcementEndDate(date)
                break
        }
    }

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const input = e.target.value.trim()
        let value: number | null = null
        if (input) {
            value = Number.parseInt(input)
            if (Number.isNaN(value)) {
                value = null
            }
        }
        switch (key) {
            case 'License:Enforcement:Removables':
                context.setRemovableEnforcementMaxVessels(value)
                break
            case 'License:Enforcement:Networks':
                context.setNetworkEnforcementMaxVessels(value)
                break
        }
    }

    function getMaxVesselsElement(): JSX.Element {
        let maxVessels = ''
        switch (key) {
            case 'License:Enforcement:Removables':
                maxVessels = context.removableEnforcementMaxVessels?.toString() ?? ''
                break
            case 'License:Enforcement:Networks':
                maxVessels = context.networkEnforcementMaxVessels?.toString() ?? ''
        }
        return (
            <Styled.Value id={`${key}-maxVessels-value`}>
                <Styled.inputNonText
                    placeholder="Number of vessels"
                    value={maxVessels}
                    type="text"
                    onInput={onInputChange}
                />
            </Styled.Value>
        )
    }

    function getEndDateElement(): JSX.Element {
        let str = ''
        switch (key) {
            case 'License:Incidents':
                str = context.incidentsPolicyEndDate?.toISOString().substr(0, 10) ?? ''
                break
            case 'License:Metrics:OnboardEngagement':
                str = context.onboardEngagementEndDate?.toISOString().substr(0, 10) ?? ''
                break
            case 'License:Enforcement:Removables':
                str = context.removableEnforcementEndDate?.toISOString().substr(0, 10) ?? ''
                break
            case 'License:Enforcement:Networks':
                str = context.networkEnforcementEndDate?.toISOString().substr(0, 10) ?? ''
                break
        }
        return (
            <Styled.Value id={`${key}-endDate-value`}>
                <Styled.inputNonText value={str} type="date" onChange={onDateChange} />
            </Styled.Value>
        )
    }

    switch (key) {
        case 'License:Enforcement:Networks':
        case 'License:Enforcement:Removables':
            return [getEndDateElement(), getMaxVesselsElement()]
        case 'License:Metrics:OnboardEngagement':
        case 'License:Incidents':
            return [getEndDateElement(), null]
    }
}
