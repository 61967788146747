import {ReactNode, useReducer} from 'react'
import {ModalUploadDocumentContext} from './modal-upload-document-context'
import {modalUploadDocumentReducer} from './state/reducer'
import {DEFAULT_MODAL_EDIT_ASSET_STATE} from './types/default-modal-upload-document-state'
import {
    ModalUploadDocumentState,
    UploadDocumentModalPage,
} from './types/modal-upload-document-state'

type ModalUploadDocumentProviderProps = {
    children: ReactNode | ReactNode[]
    requestedPage: UploadDocumentModalPage | null
    onUploadSuccess: () => void
}

export function ModalUploadDocumentProvider({
    children,
    requestedPage,
    onUploadSuccess,
}: ModalUploadDocumentProviderProps): JSX.Element {
    const initialState: ModalUploadDocumentState = {
        ...DEFAULT_MODAL_EDIT_ASSET_STATE,
        requestedPage,
    }

    const [state, dispatch] = useReducer(modalUploadDocumentReducer, initialState)

    return (
        <ModalUploadDocumentContext.Provider value={{state, dispatch, onUploadSuccess}}>
            {children}
        </ModalUploadDocumentContext.Provider>
    )
}
