import * as Styled from './_styled/self-monitoring-engine-table.styled'
import {selfMonitoringEngineRulesMapSelector} from '../../../../../store/state/self-monitoring-rules/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {getRuleDescription} from '../data-helpers'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'

interface TableRuleCellProps {
    event: SelfMonitoringEngineEvent
    gridRow: number
}
export function TableRuleCell({event, gridRow}: TableRuleCellProps): JSX.Element {
    const rules = useTypedSelector(selfMonitoringEngineRulesMapSelector)
    const nodes = useTypedSelector(nodesSelector)
    const locationMap = useTypedSelector(locationMapSelector)
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={2}
            id={`message-value_${event.identity}`}
        >
            {getRuleDescription(event, rules, nodes, locationMap)}
        </Styled.TableDataCell>
    )
}
