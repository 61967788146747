import {CardArea} from '../../common/cards/card-area.styled'
import {RequestErrorCard} from './request-error-card'

export function RequestError(): JSX.Element {
    return (
        <CardArea>
            <RequestErrorCard />
        </CardArea>
    )
}
