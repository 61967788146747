import {TimestampFilterType} from '../../../../../store/state/metrics-filter-beta/state'
import {getAnalysisPeriodDisplayName} from '../../../../metrics-beta/components/data-helper'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import * as Styled from './period-select.styled'
export function PeriodSelect(): JSX.Element {
    const {selectedAnalysisPeriod, setNewTimerange} = useMetricsWidget()
    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        setNewTimerange(event.target.value as TimestampFilterType)
    }
    return (
        <Styled.Input id="period-selector">
            <Styled.Label>Time Period</Styled.Label>
            <Styled.Select
                onChange={onChangeWrapper}
                value={selectedAnalysisPeriod}
                id="select-period-value"
            >
                <GetOptionsPerWidget />
            </Styled.Select>
        </Styled.Input>
    )
}
function GetOptionsPerWidget(): JSX.Element {
    return (
        <>
            <option
                key="latest"
                value={TimestampFilterType.LATEST}
                id="metric-widget_period-type_latest-option"
            >
                {getAnalysisPeriodDisplayName(TimestampFilterType.LATEST)}
            </option>
            {allPeriodTypeOther?.map((value) => (
                <option key={value} value={value} id={`metric-widget_period-type_${value}-option`}>
                    {getAnalysisPeriodDisplayName(value)}
                </option>
            ))}
            <option
                key="default-option"
                value={TimestampFilterType.LAST_30_DAYS}
                id={`metric-widget_period-type_default-option`}
            >
                {getAnalysisPeriodDisplayName(TimestampFilterType.LAST_30_DAYS)}
            </option>
        </>
    )
}
const allPeriodTypeOther = [TimestampFilterType.LAST_24_H, TimestampFilterType.LAST_7_DAYS]
