import {createSelector} from 'reselect'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {NodeDataMap} from '../../../../values/nodes/NodeData'
import {getLocationDescription, LocationMap} from '../../../../store/state/locations/state'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {overviewFetchingSelector} from '../../../../store/common-selectors/fetching-selector'
import {IpAddressType} from '../../../../values/generic-type-defintions'

export interface OverviewRecord {
    loading: boolean
    assetId: string
    assetCode: IpAddressType | undefined
    assetValueLevel: string | undefined
    location: string | undefined
}

function getDefaultRecord(): OverviewRecord {
    return {
        loading: true,
        assetId: '',
        assetCode: '',
        assetValueLevel: '',
        location: '',
    }
}
const overviewReselector = createSelector(
    queryStringObjectSelector,
    nodesSelector,
    locationMapSelector,
    overviewFetchingSelector,
    (
        queryString,
        nodes: NodeDataMap,
        locationMap: LocationMap,
        loading: boolean,
    ): OverviewRecord => {
        if (loading || !queryString?.assetDetails) {
            return getDefaultRecord()
        }

        const assetId = queryString.assetDetails
        const selectedNode = nodes.get(assetId as string)
        if (!selectedNode) {
            return {
                loading,
                assetId: '',
                assetCode: undefined,
                assetValueLevel: undefined,
                location: undefined,
            }
        }

        return {
            loading,
            assetId: assetId as string,
            assetCode: selectedNode.ipAddress,
            assetValueLevel: selectedNode.value.toLowerCase(),
            location: getLocationDescription(locationMap, selectedNode.location),
        }
    },
)

export default overviewReselector
