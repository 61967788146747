import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import * as Styled from './analysis-selection.styled'
import {
    convertPeriodTimestampForTrends,
    getAnalysisTypeDisplayName,
    isTrendsPeriodNeeded,
} from '../../data-helper'
import {AnalysisType} from '../../../../../store/state/metrics-filter-beta/state'
import {allAnalysisTypes} from '../../../contexts/types/metrics-response'
import {
    setAnalysisPeriod,
    setAnalysisType,
} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logAnalysisTypeOnFilter} from '../../../../../store/state/audit-log/action-creators'

export function AnalysisTypeFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {analysisTypes, selectedViewScreenType, selectedAnalysisPeriod} =
        useTypedSelector(metricsBetaFilterSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(
            logAnalysisTypeOnFilter(
                getAnalysisTypeDisplayName(event.target.value as AnalysisType),
                AuditLogPageType.METRICS,
            ),
        )
        const trendsPeriodNeeded = isTrendsPeriodNeeded(
            event.target.value as AnalysisType,
            selectedViewScreenType,
        )

        const getPeriodData = convertPeriodTimestampForTrends(
            trendsPeriodNeeded,
            selectedAnalysisPeriod,
        )
        if (getPeriodData !== selectedAnalysisPeriod) {
            dispatch(setAnalysisPeriod(getPeriodData, false))
            dispatch(setAnalysisType(event.target.value as AnalysisType))
        } else {
            dispatch(setAnalysisType(event.target.value as AnalysisType))
        }
    }

    return (
        <Styled.Wrapper
            highlightedFilerValue={highlightedFilerValue === 'Analysis type'}
            id={`${PageType.METRICS}_filter-bar_analysis-type-filter`}
        >
            <Styled.Label>Analysis Type:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={analysisTypes}
                    id={`${PageType.METRICS}_filter-bar_searched-analysis-type-value`}
                >
                    <option
                        key="default-option"
                        value={AnalysisType.SCORECARD}
                        id={`${PageType.METRICS}_filter-bar_analysis-type_default-option`}
                    >
                        {getAnalysisTypeDisplayName(AnalysisType.SCORECARD)}
                    </option>
                    {allAnalysisTypes?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.METRICS}_filter-bar_analysis-type_${value}-option`}
                        >
                            {getAnalysisTypeDisplayName(value)}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
