import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const IncidentTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 2px;
`
export const IncidentTypeText = styled.span`
    white-space: pre-wrap;
    ${mediumSmallFont()}
`
