import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {TableHeader} from './table-header'
import {TableHeaderGrid} from './table-header-grid.styled'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, modalIdForSoftwareDetails} = usePagedSoftwareInventory()

    return (
        <TableHeaderGrid
            width={width}
            showReducedGrid={showFilterBar || modalIdForSoftwareDetails !== null}
            id="TableHeaderGrid"
        >
            <TableHeader />
        </TableHeaderGrid>
    )
}
