export function IconProcessing(): JSX.Element {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.324 5.09896H16.3604C16.6825 5.09896 16.9915 5.22693 17.2193 5.4547C17.4471 5.68248 17.575 5.99142 17.575 6.31354V19.0667C17.575 19.3888 17.4471 19.6978 17.2193 19.9255C16.9915 20.1533 16.6825 20.2813 16.3604 20.2813H4.21458C3.89246 20.2813 3.58352 20.1533 3.35574 19.9255C3.12797 19.6978 3 19.3888 3 19.0667V6.31354C3 5.99142 3.12797 5.68248 3.35574 5.4547C3.58352 5.22693 3.89246 5.09896 4.21458 5.09896H7.25104C7.25104 4.29364 7.57095 3.5213 8.1404 2.95186C8.70984 2.38241 9.48215 2.0625 10.2875 2.0625C11.0928 2.0625 11.8652 2.38241 12.4346 2.95186C13.004 3.5213 13.324 4.29364 13.324 5.09896Z"
                stroke="#1F88E5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.3036 5.10827C10.136 5.10827 10 4.97232 10 4.80462C10 4.63692 10.136 4.50098 10.3036 4.50098"
                stroke="#1F88E5"
                stroke-width="1.5"
            />
            <path
                d="M10.3027 5.10827C10.4704 5.10827 10.6064 4.97232 10.6064 4.80462C10.6064 4.63692 10.4704 4.50098 10.3027 4.50098"
                stroke="#1F88E5"
                stroke-width="1.5"
            />
            <path
                d="M10.5 9.25C8.42969 9.25 6.75 10.9297 6.75 13C6.75 15.0703 8.42969 16.75 10.5 16.75C12.5703 16.75 14.25 15.0703 14.25 13C14.25 10.9297 12.5703 9.25 10.5 9.25Z"
                stroke="#1F88E5"
                stroke-miterlimit="10"
            />
            <path
                d="M10.5 10.5V13.3125H12.375"
                stroke="#1F88E5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
