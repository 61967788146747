import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'

export const ButtonText = styled.span`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()}
`

export const IndicatorDot = styled.div`
    ${smallFont()}
    position: absolute;
    justify-content: center;
    top: -8px;
    display: inline-block;
    width: 16px;
    margin-left: ${spacing(1)};
    height: 16px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-top: 2px;
    line-height: 11px;
    background-color: ${(props) => props.theme.navigationResponsive.active.background};
    border-radius: 50%;
    color: white;
`

export const IndicatorDotForTableRow = styled.div`
    ${smallFont()}
    display: flex;
    position: absolute;
    justify-content: center;
    left: 80px;
    top: 1px;
    width: 16px;
    height: 16px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding-top: 2px;
    line-height: 11px;
    background-color: ${(props) => props.theme.navigationResponsive.active.background};
    border-radius: 50%;
    color: white;
`
