import {useRef, DragEvent, ChangeEvent, useState} from 'react'
import * as Styled from './drag-and-drop-file.styled'
import UPLOAD_DOC from '../pdf-icon-upload.svg'
import {UploadButton} from './upload-button'
import {ProcessingStatus} from '../../../values/processing-save-state-enum'
import {useModaUploadDocument} from '../context/use-modal-upload-document'

export function NativeDragAndDrop(): JSX.Element {
    const {isProcessingSave, uploadDocument, setFileValue} = useModaUploadDocument()
    const [isDragOver, setIsDragOver] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const isSelectFileDisabled = isProcessingSave === ProcessingStatus.PROCESSED

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(false)
        const droppedFiles = Array.from(event.dataTransfer.files)
        if (droppedFiles.length > 0) {
            setFileValue(droppedFiles[0])
            uploadDocument(droppedFiles[0])
        }
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
    }

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(true)
    }

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(false)
    }

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (isSelectFileDisabled) {
            return
        }
        const selectedFiles = Array.from(event.target.files || [])
        if (selectedFiles.length > 0) {
            setFileValue(selectedFiles[0])
            uploadDocument(selectedFiles[0])
        }
    }

    const openFileBrowser = () => {
        if (!isSelectFileDisabled) {
            fileInputRef.current?.click()
        }
    }

    return (
        <Styled.DragAndDropWrapper
            isDragOver={isDragOver}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            aria-disabled={isSelectFileDisabled}
        >
            <GetImageHeader />
            <Styled.DragAndDropBox onClick={openFileBrowser}>
                <GetSectionTitle />
                <input
                    type="file"
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                />
            </Styled.DragAndDropBox>
            <UploadButton onClick={openFileBrowser} disabled={isSelectFileDisabled} />
            <Styled.SizeWarning>(Max. 10MB)</Styled.SizeWarning>
        </Styled.DragAndDropWrapper>
    )
}

function GetImageHeader(): JSX.Element {
    const {requestedPage} = useModaUploadDocument()
    if (requestedPage === 'metrics' || requestedPage === 'incidents') {
        return <Styled.ImageHeader src={UPLOAD_DOC} id="drag-and-drop-header_title-icon" />
    } else {
        return <div />
    }
}

function GetSectionTitle(): JSX.Element {
    const {requestedPage} = useModaUploadDocument()
    if (requestedPage === 'metrics' || requestedPage === 'incidents') {
        return <Styled.SectionTitle>Drag and drop PDF file to upload</Styled.SectionTitle>
    } else {
        return <div />
    }
}
