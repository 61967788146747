import styled from 'styled-components'
import {GridLayout} from '../../../general/grid-layout.enum'

function getFlexDirection(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.ONE_COLUMN_SMALL:
            return 'column'
        default:
            return 'row'
    }
}

function getJustifyContent(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.ONE_COLUMN_SMALL:
            return 'flex-start'
        default:
            return 'space-between'
    }
}

function getAlignItems(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.ONE_COLUMN_SMALL:
            return 'flex-start'
        default:
            return 'center'
    }
}

interface ContainerProps {
    gridLayout: GridLayout
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: ${(props) => getFlexDirection(props.gridLayout)};
    justify-content: ${(props) => getJustifyContent(props.gridLayout)};
    margin: 0 30px 20px 60px;
    font-size: 12px;
    line-height: 17px;
    align-items: ${(props) => getAlignItems(props.gridLayout)};
`
