import {CloseButton} from './close-button/close-button'
import * as Styles from './_styled/incident-details.styled'
import ERROR_IMG from '../../../../../@assets/images/owl-error.png'
export function NoDataModal(): JSX.Element {
    return (
        <Styles.Container>
            <Styles.NoDataContent>
                <Styles.NoDataBox>
                    <img src={ERROR_IMG} />
                    <Styles.NoDataMessage id="no-data-component">
                        Sorry, the link you are trying to access is not valid.
                    </Styles.NoDataMessage>
                    <CloseButton />
                </Styles.NoDataBox>
            </Styles.NoDataContent>
        </Styles.Container>
    )
}
