import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {largeFont, mediumFont} from '../../../../../theme/font-styles'

export const BREAK_POINT = 750

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: #ffffff;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width >= BREAK_POINT ? '900px' : '400px')};
    padding: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
`

export const Footer = styled.footer``

export const Title = styled.h1`
    ${largeFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`

export const IconDetail = styled.img``

export const SubTitle = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: ${spacing(3)};
`

export const Header = styled.header`
    color: #ffffff;
    background-color: #121212;
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
    position: relative;
`
export const Logo = styled.div`
    text-align: center;
    height: 100%;
    margin-bottom: ${spacing(5)};
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`

export const Content = styled.div`
    padding: ${spacing(4)};
    grid-template-columns: 1fr;
    grid-template: 'INFORMATION' 'DATA_RANGE' 'REQUIREMENTS' 'PURPOSE' 'REFERENCE';
    column-gap: ${spacing(8)};
    row-gap: ${spacing(2)};
`

export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`

export const ContentText = styled.span`
    margin-left: 20px;
`
export const ContentWrapper = styled.div`
    margin-bottom: ${spacing(4)};
`
