import {AssetPopover} from '../../../../../components/asset-popover/asset-popover'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {AssetThreatScoreOutputModel} from '../../../../my-vessels-v2/listing/reselectors/asset-threat-score.model'
import {PossibleColumnsVesselBeta} from '../../../contexts/types/paged-vessels-beta-state'
import {Border} from './border'
import * as Styled from './assets-at-risk-score.styled'

interface AssetThreatScoresGridProps {
    locationId: LocationIdType
    nodeList: AssetThreatScoreOutputModel[]
    selectedColumn: PossibleColumnsVesselBeta
}

export function AssetThreatScoresGrid({
    locationId,
    nodeList,
    selectedColumn,
}: AssetThreatScoresGridProps): JSX.Element | null {
    return (
        <Styled.AssetsGrid id={`assets-grid-${selectedColumn}-${locationId}`}>
            {nodeList &&
                nodeList?.map((assetItem) => (
                    <Styled.AssetsCell
                        key={`asset-threat-score-cell-${locationId}-${assetItem.id}-${selectedColumn}`}
                    >
                        <AssetPopover id={assetItem.id}>
                            <Border
                                threatLevel={assetItem.threatLevel}
                                nodeValue={assetItem.nodeValue}
                            >
                                <Styled.AssetSquare
                                    id={`asset-threat-score_${assetItem.id}`}
                                    nodeValue={assetItem.nodeValue}
                                    score={assetItem.score}
                                    threatLevel={assetItem.threatLevel}
                                >
                                    {assetItem.score}
                                </Styled.AssetSquare>
                            </Border>
                        </AssetPopover>
                    </Styled.AssetsCell>
                ))}
        </Styled.AssetsGrid>
    )
}
