import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {NoData} from '../shared/no-data/no-data'
import {MonaEngineModesTablePopulated} from './table/mona-engine-modes-table-populated'
import {MonaEngineModeCardsPopulated} from './cards/mona-engine-mode-cards-populated'
import {hasDataSelector} from '../../../../store/state/mona-engine-modes/selectors'

interface Props {
    environment: string
}

export function MonaEngineModesLoaded({environment}: Props): JSX.Element {
    const {width} = useDimensions()
    const showCards = width < 450
    if (!useTypedSelector(hasDataSelector)) {
        return <NoData message={`There are no mona engine modes for ${environment}`} />
    }
    if (showCards) {
        return <MonaEngineModeCardsPopulated />
    }
    return <MonaEngineModesTablePopulated />
}
