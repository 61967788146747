import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'
import {buildCombinedKey} from '../build_combined_key'
import {DataPageMap} from '../types/data-page-map'
import {EventDetail} from '../types/event-detail'
import {EventDetailsState} from '../types/event-details-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

function dataMapNeedsAddingOrUpdating(
    current: EventDetail | undefined,
    received: EventDetail,
): boolean {
    if (!received) {
        return false
    }

    if (!current) {
        return true
    }

    // if these don't match we're not comparing like to like
    if (current.guid != received.guid || current.timestamp != received.timestamp) {
        return false
    }

    return (
        current.node != received.node ||
        current.peerGroup != received.peerGroup ||
        current.indicator != received.indicator ||
        current.message != received.message ||
        current.type != received.type ||
        current.scope != received.scope
    )
}

function mutateEventDetailsIfNeeded(
    existingMap: DataPageMap | undefined,
    receivedData: EventDetail,
): DataPageMap | undefined {
    const combinedKey = buildCombinedKey(receivedData.timestamp, receivedData.guid)

    if (!dataMapNeedsAddingOrUpdating(existingMap?.get(combinedKey), receivedData)) {
        return existingMap
    }

    const newMap = new Map<GuidType, EventDetail>(existingMap || new Map<GuidType, EventDetail>())
    newMap.set(combinedKey, receivedData)
    return newMap
}

export function eventDetailsReducer(
    state: EventDetailsState,
    action: Actions.AllActions,
): EventDetailsState {
    switch (action.type) {
        case ActionType.REQUEST_EVENT_DETAIL_ACTION:
            return {
                ...state,
                loading: LoadingState.RequestingData,
            }

        case ActionType.RECEIVED_REQUESTED_EVENT_DETAIL_ACTION:
            return {
                ...state,
                loading: LoadingState.Loaded,
                eventDetails: mutateEventDetailsIfNeeded(state.eventDetails, action.payload),
            }

        /* istanbul ignore next */
        default:
            return state
    }
}
