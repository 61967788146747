import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbModalDetailExpandedSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {UsbDevicesDetailType} from '../../../../../store/state/usb-inventory-filter/state'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {ActionsContents} from './actions-contents'

export function ActionsSection(): JSX.Element {
    const detailExpanded = useTypedSelector(usbModalDetailExpandedSelector)

    return (
        <Panel>
            <ExpandPanelButton headingRow={UsbDevicesDetailType.Actions} />
            {detailExpanded?.actionsExpanded && <ActionsContents />}
        </Panel>
    )
}
