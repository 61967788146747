import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {ExternalRolesSelect} from './external-roles-select'
import {InternalRolesSelect} from './internal-roles-select'
import * as Styled from './roles-section.styled'

export function RolesSection(): JSX.Element {
    const {activeUserDetails, detailExpanded} = useUserManagement()

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.Roles} />
            {detailExpanded.rolesExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        {activeUserDetails?.internalUser ? (
                            <InternalRolesSelect />
                        ) : (
                            <ExternalRolesSelect />
                        )}
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
