import {useRef, useState} from 'react'
import {ChevronDown, ChevronUp} from 'react-feather'
import * as Styled from '../_styled/dropdown.styled'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../../../hooks/useOnClickOutside'
import {usersSelector} from '../../../../../../../store/state/users/selectors'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {showCards} from '../../../_styled/policy-management-list-header.styled'
import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {usePolicyManagement} from '../../../../../contexts/policy-management/use-policy-management'

interface UserSelectDropdownProps {
    initialValue: GuidType | null
    setChange: (userId: GuidType | null) => void
    changed: boolean
    disabled?: boolean
}

export function UserSelectDropdown({
    initialValue,
    setChange,
    changed,
    disabled,
}: UserSelectDropdownProps): JSX.Element {
    const [optionsVisible, setOptionsVisible] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    const users = useTypedSelector(usersSelector).filter(
        (user) => !user.internalUser && user.active && !user.deleted,
    )

    const {width} = useDimensions()
    const {selectedPolicyId} = usePolicyManagement()
    const isCardView = showCards(width, selectedPolicyId !== null)

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.DropdownWrapper
                onClick={() => setOptionsVisible(!optionsVisible)}
                changed={changed}
                cardView={isCardView}
                disabled={disabled}
            >
                <Styled.SearchInput id="filter-search">
                    {users.find((user) => user.user === initialValue)?.username ??
                        '- Select Medulla user -'}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {optionsVisible ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.DropdownWrapper>
            <Styled.UserWrapper ref={clickOutside}>
                {optionsVisible && (
                    <Styled.UserDropdown>
                        <Styled.ValueOption
                            onClick={() => {
                                setChange(null)
                                setOptionsVisible(false)
                            }}
                        >
                            (Unassigned)
                        </Styled.ValueOption>
                        {users.map((user, index) => (
                            <Styled.ValueOption
                                key={index}
                                onClick={() => {
                                    setChange(user.user)
                                    setOptionsVisible(false)
                                }}
                            >
                                {user.username}
                            </Styled.ValueOption>
                        ))}
                    </Styled.UserDropdown>
                )}
            </Styled.UserWrapper>
        </Styled.Container>
    )
}
