import * as Styled from './_styled/self-monitoring-card.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardTitle} from './card-title'
import {CardModeCell} from './card-mode-cell'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'

interface Props {
    monaEngineMode: MonaEngineMode
}

export function MonaEngineModeCard({monaEngineMode}: Props): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > 700
    return (
        <Styled.Card id={`card_${monaEngineMode.anomalyProcessingMode}`}>
            <CardTitle monaEngineMode={monaEngineMode} />
            <Styled.CardDataRow>
                <CardModeCell
                    monaEngineMode={monaEngineMode}
                    fixedWidthReducedText={fixedWidthReducedText}
                />
            </Styled.CardDataRow>
        </Styled.Card>
    )
}
