import {ContentWrapper} from './_styled/metrics-details.styled'
import {MetricsModalContent} from './metrics-modal-content'

export function MetricsDetailsModal(): JSX.Element {
    return (
        <ContentWrapper>
            <MetricsModalContent />
        </ContentWrapper>
    )
}
