import {DataLoaded} from './components/data-loaded'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isFetchinglocationTagsSummarySelector} from '../../../../store/state/location-tags-summary/selectors'
import {Loading} from './components/loading'
import {useEffect} from 'react'
import LoadingState from '../../../../values/loading-state-enum'
import {useTagsComponent} from './contexts/use-tags-component'
import {locationTagsSummary, QueryManager} from '../../../../websocket/Queries'
import {fetchLocationTagsSummary} from '../../../../store/state/location-tags-summary/action-creators'
import {useReduxDispatch} from '../../../../hooks/useReduxDispatch'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {vesselTagsSelector} from '../../../../store/state/vessel-tags/selectors'
import {getTagName} from '../../../../utils/Utils'
import {StrictMode} from '../../../../store/state/users/state'

type props = {
    queryManager: React.MutableRefObject<QueryManager>
}

export function TagsWidget({queryManager}: props): JSX.Element {
    const isLoading: boolean = useTypedSelector(isFetchinglocationTagsSummarySelector)
    const {fetchFilter, loadingFilterState} = useTagsComponent()
    const dispatch = useReduxDispatch()
    const {timeRange} = useTagsComponent()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const existingTags = useTypedSelector(vesselTagsSelector)

    useEffect(() => {
        if (loadingFilterState === LoadingState.NotPopulated) {
            fetchFilter(
                strictMode === StrictMode.DefaultVisibility
                    ? getTagName(existingTags, assignedTag)
                    : undefined,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFilter, loadingFilterState, assignedTag, strictMode])

    useEffect(() => {
        const currentQueryManager = queryManager.current
        currentQueryManager.save(locationTagsSummary, dispatch(fetchLocationTagsSummary(timeRange)))
        return () => {
            currentQueryManager.cancel(locationTagsSummary)
        }
    }, [dispatch, timeRange, queryManager])

    return isLoading ? <Loading /> : <DataLoaded />
}
