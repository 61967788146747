import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../theme/theme'
import {ThreatMeasure} from '../../../../values/ThreatMeasure'

export enum Size {
    MEDIUM,
    LARGE,
}
interface VesselThreatScoreProps {
    score: number
    size: Size
    threatMeasure: ThreatMeasure | undefined
}

function getColor(
    score: number,
    theme: CyberOwlTheme,
    threatMeasure: ThreatMeasure | undefined,
): string {
    if (threatMeasure && score >= threatMeasure.upperThreshold) {
        return theme.threatScores.score.high.text
    } else if (threatMeasure && score >= threatMeasure.lowerThreshold) {
        return theme.threatScores.score.medium.text
    }
    return theme.threatScores.score.low.text
}
function getBackgroundColor(
    score: number,
    theme: CyberOwlTheme,
    threatMeasure: ThreatMeasure | undefined,
): string {
    if (threatMeasure && score >= threatMeasure.upperThreshold) {
        return theme.threatScores.score.high.background
    } else if (threatMeasure && score >= threatMeasure.lowerThreshold) {
        return theme.threatScores.score.medium.background
    }
    return theme.threatScores.score.low.background
}

export const VesselThreatScore = styled.div<VesselThreatScoreProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.size === Size.MEDIUM ? '26px' : '40px')};
    height: ${(props) => (props.size === Size.MEDIUM ? '26px' : '40px')};
    color: ${(props) => getColor(props.score, props.theme, props.threatMeasure)};
    background-color: ${(props) =>
        getBackgroundColor(props.score, props.theme, props.threatMeasure)};
    line-height: ${(props) => (props.size === Size.MEDIUM ? '22px' : '27px')};
    font-size: ${(props) => (props.size === Size.MEDIUM ? '16px' : '20px')};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    border-radius: 4px;
`
