import {usePagedAlerts} from '../../../contexts/alerts/use-paged-alerts'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'

export function RecordSet(): JSX.Element | null {
    const {selectedPage, pageSize, totalNumberOfEvents} = usePagedAlerts()

    if (totalNumberOfEvents == undefined || selectedPage == undefined || pageSize == undefined) {
        return null
    }

    const startingAlert = selectedPage * pageSize + 1
    const endAlert =
        startingAlert + pageSize > totalNumberOfEvents
            ? totalNumberOfEvents
            : startingAlert + pageSize - 1

    return (
        <>
            {totalNumberOfEvents !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingAlert}
                    endIndicator={endAlert}
                    totalNumberOfItems={totalNumberOfEvents}
                />
            )}
        </>
    )
}
