import {IncidentSeverityValues} from '../../../../values/incident-response-values'
import {IncidentTrendsType} from '../../contexts/types/incidents-report-output'
import * as Styled from './incidents-types-and-severities.styled'
export interface CurrentScoreTooltipProps {
    active?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    label?: string
    data: IncidentTrendsType[]
}

export function CurrentTrendsTooltip({
    active,
    payload,
    data,
    label,
}: CurrentScoreTooltipProps): JSX.Element | null {
    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.label === label)
        if (!dataPoint) {
            return null
        }
        return (
            <Styled.ToolTipContainer id={`ToolTipContainer-${dataPoint?.label}`}>
                <Styled.ToolTipTitle>{dataPoint?.label}</Styled.ToolTipTitle>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle severityLevel={IncidentSeverityValues.LOW} />
                    <Styled.SeverityLink>
                        {dataPoint?.lowSeverityIncidentsCount}
                    </Styled.SeverityLink>
                    <Styled.ToolTipSubTitle>Low severity</Styled.ToolTipSubTitle>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle severityLevel={IncidentSeverityValues.MEDIUM} />
                    <Styled.SeverityLink>
                        {dataPoint?.mediumSeverityIncidentsCount}
                    </Styled.SeverityLink>
                    <Styled.ToolTipSubTitle>Med severity</Styled.ToolTipSubTitle>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle severityLevel={IncidentSeverityValues.HIGH} />
                    <Styled.SeverityLink>
                        {dataPoint?.highSeverityIncidentsCount}
                    </Styled.SeverityLink>
                    <Styled.ToolTipSubTitle>High severity</Styled.ToolTipSubTitle>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <Styled.ToolTipRectangle severityLevel={IncidentSeverityValues.CRITICAL} />
                    <Styled.SeverityLink>
                        {dataPoint?.criticalSeverityIncidentsCount}
                    </Styled.SeverityLink>
                    <Styled.ToolTipSubTitle>Critical severity</Styled.ToolTipSubTitle>
                </div>
            </Styled.ToolTipContainer>
        )
    }

    return null
}
