import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

const GRID_BREAKPOINT = 480

interface ContentProps {
    width: number
}

function getGridTemplate(width: number): string {
    if (width < 480) {
        return '"WHEN" "PROTOCOL" "HOST" "PORT" "PROXIMITY" "DIRECTION"'
    }
    return '"WHEN PROTOCOL" "HOST PORT" "PROXIMITY DIRECTION"'
}

export const Content = styled.div<ContentProps>`
    padding: ${spacing(4)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template: ${(props) => getGridTemplate(props.width)};
    column-gap: ${spacing(8)};
    row-gap: ${spacing(2)};
`

interface DataCellProps {
    gridArea: string
}

export const DataCell = styled.div<DataCellProps>`
    display: flex;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

interface LabelProps {
    width: number
}

function getLabelWidth(width: number): string {
    if ((width > 370 && width < GRID_BREAKPOINT) || width > 560) {
        return '110px'
    }

    return '70px'
}

export const Label = styled.label<LabelProps>`
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    font-weight: ${(props) => props.theme.font.weight.light};
    width: ${(props) => getLabelWidth(props.width)};
`

export const Value = styled.span`
    ${smallFont()}
`
