import moment from 'moment'
import {spacing} from '../../../theme/spacing'
import {
    FormattedHostDataForSoftwareInventory,
    SoftwareInventoryModelOutput,
    UNDEFINED_SOFTWARE_INVENTORY_MODEL_OUTPUT,
} from '../contexts/types/software-inventory-model-output'
import {
    AssetVersion,
    SoftwareInventoryResponse,
} from '../contexts/types/software-inventory-response'
import {
    SoftwareInventoryFilterReduxState,
    SoftwareInventorySortType,
    SoftwareStatus,
    SortColumnType,
    TimestampFilter,
} from '../../../store/state/software-inventory-filter/state'
import {SoftwareInventoryFilter} from '../../../values/user-preferences/software-inventory-filter'
import {isEqual} from 'lodash'
import CIRCLE_CHECKED from '../../../@assets/icons/circle-checked.svg'
import CIRCLE_UNKNOWN from '../../../@assets/icons/circle-unknown.svg'
import CIRCLE_X from '../../../@assets/icons/circle-x.svg'

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier ? width < 1260 : width < 1500
}
export function getGridTemplateColumns(width: number): string {
    if (width > 1660) {
        return `0 110px 160px 160px 220px 160px 170px 170px 170px 170px 0`
    }
    if (width > 1600) {
        return `0 100px 155px 155px 210px 160px 165px 170px 170px 170px 0`
    }
    if (width > 1550) {
        return `0 70px 155px 155px 205px 150px 160px 160px 160px 160px 0`
    }
    if (width > 1510) {
        return `0 70px 150px 150px 200px 150px 160px 160px 160px 160px 0`
    }
    if (width > 1440) {
        return `0 70px 130px 130px 200px 150px 140px 140px 160px 160px 0`
    }
    if (width > 1400) {
        return `0 70px 130px 130px 200px 150px 140px 140px 150px 150px 0`
    }
    if (width > 1330) {
        return `0 60px 120px 120px 180px 160px 130px 130px 140px 140px 0`
    }
    if (width > 1300) {
        return `0 60px 120px 120px 175px 160px 120px 120px 140px 140px 0`
    }
    return `0 50px 120px 115px 170px 160px 110px 110px 110px 110px 0`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1700) {
        return `${spacing(1)} 50px 120px 115px 170px 150px 130px 130px 115px 115px ${spacing(1)}`
    }
    if (width > 1600) {
        return `${spacing(1)} 50px 120px 115px 150px 130px 120px 130px 110px 110px ${spacing(1)}`
    }
    if (width > 1550) {
        return `${spacing(1)} 50px 120px 110px 150px 110px 120px 130px 110px 110px ${spacing(1)}`
    }
    return `${spacing(1)} 50px 120px 100px 120px 100px 100px 100px 110px 110px ${spacing(1)}`
}

export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.orderBy) {
        return false
    }
    switch (currentSortColumn.orderAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}

export function showFilterBarOnScreen(width: number): boolean {
    return width > 1000
}

export function isNull(companyName: string | null): boolean {
    return companyName === null || companyName === ' '
}

export function getFormattedSoftwareInventoryData(
    softwareInventory: SoftwareInventoryResponse | undefined,
): SoftwareInventoryModelOutput {
    if (softwareInventory === undefined) {
        return UNDEFINED_SOFTWARE_INVENTORY_MODEL_OUTPUT
    }
    let companyName = softwareInventory.company
    if (companyName == null || companyName === '-') {
        companyName = ' '
    }
    let product = softwareInventory.product
    if (product == null || product === '-') {
        product = ' '
    }
    return {
        identity: softwareInventory.identity,
        firstSeenTimestamp:
            moment(softwareInventory.firstDetected?.firstSeen).fromNow() ?? 'Unknown',
        lastSeenTimestamp: moment(softwareInventory.lastDetected?.firstSeen).fromNow() ?? 'Unknown',
        companyName: companyName,
        productName: product,
        totalNumberOfHostsPerItem: softwareInventory.assetCount ?? 0,
        totalNumberOfVesselsPerItem: softwareInventory.locationCount ?? 0,
        latestVersion: softwareInventory.latestVersion?.version ?? 'N/A',
        oldestVersion: softwareInventory.oldestVersion?.version ?? 'N/A',
        status: softwareInventory.status ?? SoftwareStatus.unknown,
        isPortable: softwareInventory.isPortable,
    }
}

export function getImageForSoftwareState(state: SoftwareStatus): string {
    switch (state) {
        case SoftwareStatus.trusted:
            return CIRCLE_CHECKED
        case SoftwareStatus.unknown:
            return CIRCLE_UNKNOWN
        case SoftwareStatus.unwanted:
            return CIRCLE_X
        default:
            return ''
    }
}

export function getUIDataorHosts(
    givenAsset: FormattedHostDataForSoftwareInventory,
    asset: AssetVersion | undefined,
): FormattedHostDataForSoftwareInventory {
    if (!asset) {
        return {} as FormattedHostDataForSoftwareInventory
    }
    return {
        identity: givenAsset.identity,
        hostName: givenAsset?.hostName ?? 'Unknown',
        lastSeenTimestamp: moment(asset?.firstSeen).fromNow() ?? 'Unknown',
    }
}

export const SOFTWARE_INVENTORY_DETAILS = 'softwareDetails'

export class FormattedNewFilterModel {
    public readonly sortColumn: {orderBy: SoftwareInventorySortType; orderAscending: boolean}
    public readonly searchedFirstDetected: TimestampFilter | undefined
    public readonly searchedLastActive: TimestampFilter | undefined
    public readonly searchedCompanyName: string | undefined
    public readonly searchedProductName: string | undefined
    public readonly filteredSoftwareStatus: SoftwareStatus[] | undefined

    public constructor(newFilter: SoftwareInventoryFilterReduxState | SoftwareInventoryFilter) {
        this.sortColumn = newFilter.sortColumn
        this.searchedFirstDetected =
            newFilter.searchedFirstDetected === 'All' ? undefined : newFilter.searchedFirstDetected
        this.searchedLastActive =
            newFilter.searchedLastActive === 'All' ? undefined : newFilter.searchedLastActive
        this.searchedCompanyName =
            newFilter.searchedCompanyName === '' ? undefined : newFilter.searchedCompanyName
        this.searchedProductName =
            newFilter.searchedProductName === '' ? undefined : newFilter.searchedProductName
        this.filteredSoftwareStatus = newFilter.filteredSoftwareStatus
    }
}

export function compareFilters(
    currentFilter: SoftwareInventoryFilter,
    newFilter: SoftwareInventoryFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)

    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}

export const allSoftwareStatuses = Object.values(SoftwareStatus)
