import styled from 'styled-components'
import {NodeValue, NodeValues} from '../../../../../values/nodes/NodeData'
import {CyberOwlTheme} from '../../../../../theme/theme'

function getBorderColor(value: NodeValue, theme: CyberOwlTheme): string {
    switch (value) {
        case NodeValues.HIGH:
            return theme.threatScores.score.lowScoreHighValue.background
        case NodeValues.MEDIUM:
            return theme.threatScores.score.lowScoreMediumValue.background
        default:
            return theme.threatScores.score.lowScoreLowValue.background
    }
}

interface WithBorderProps {
    nodeValue: NodeValue
}

const BorderBase = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const WithBorder = styled(BorderBase)<WithBorderProps>`
    border: 3px solid ${(props) => getBorderColor(props.nodeValue, props.theme)};
    border-radius: 6px;
    cursor: pointer;
`

export const NoBorder = styled(BorderBase)`
    border: none;
    padding: 3px;
    cursor: pointer;
`
