import styled from 'styled-components'
import {GridLayout} from '../grid-layout.enum'

interface TitleTextProps {
    gridLayout: GridLayout
}

function getFontSize(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '16px'
        default:
            return '20px'
    }
}

function getLineHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '22px'
        default:
            return '27px'
    }
}

export const TitleText = styled.h2<TitleTextProps>`
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
