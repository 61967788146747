import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../../theme/theme'
import {GridLayout} from '../../../../general/grid-layout.enum'

interface AxisTickProps {
    value: number
    gridLayout: GridLayout
}

function getTransform(value: number): number {
    return value === 0 || value === 100 ? value : 50
}

function getTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '18px'
        default:
            return '22px'
    }
}

function getFontWeight(gridLayout: GridLayout, theme: CyberOwlTheme): number {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return theme.font.weight.normal
        default:
            return theme.font.weight.semibold
    }
}

export const AxisTick = styled.span<AxisTickProps>`
    position: absolute;
    display: inline-block;
    top: ${(props) => getTop(props.gridLayout)};
    text-align: center;
    left: ${(props) => `${props.value}%`};
    color: ${(props) => props.theme.colors.text.default};
    transform: ${(props) => `translate(-${getTransform(props.value)}%, 0)`};
    font-size: 12px;
    line-height: 17px;
    font-weight: ${(props) => getFontWeight(props.gridLayout, props.theme)};
`
