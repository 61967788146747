import {useState} from 'react'
import CLOSE_ICON from '../../../../@assets/icons/close-assign-to-icon.svg'
import {Checkbox} from './checkbox'
import * as Styled from './policy-type-dropdown.styled'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {PolicyType} from '../../contexts/policy-management/type/policy-management-state'

interface PolicyTypeDropdownProps {
    setOpenPolicyType: (value: boolean) => void
}
export function PolicyTypeDropdown({
    setOpenPolicyType,
}: PolicyTypeDropdownProps): JSX.Element | null {
    const {showAddNewPolicyModalFn} = usePolicyManagement()
    const policyTypes = [PolicyType.INCIDENT, PolicyType.METRICS, PolicyType.ENFORCEMENT]

    const [selectedType, setSelectedType] = useState<PolicyType | string>('')

    function setChecked(type: PolicyType): void {
        setSelectedType(type)
    }

    return (
        <Styled.DropdownContentStyle>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    setOpenPolicyType(false)
                }}
                id="close-details-icon"
            />

            <Styled.Options>
                {policyTypes.map((type, index) => (
                    <Styled.Option key={index}>
                        <Checkbox
                            id={type}
                            checked={type === selectedType}
                            onChange={() => {
                                setChecked(type)
                            }}
                            label={type}
                        />
                    </Styled.Option>
                ))}
            </Styled.Options>
            <Styled.ButtonsWrapper>
                <Styled.CancelButton
                    onClick={() => {
                        setSelectedType('')
                        setOpenPolicyType(false)
                    }}
                >
                    Cancel
                </Styled.CancelButton>
                <Styled.AddButton
                    disabled={selectedType === ''}
                    onClick={() => {
                        showAddNewPolicyModalFn(selectedType as PolicyType)
                        setOpenPolicyType(false)
                    }}
                >
                    Add
                </Styled.AddButton>
            </Styled.ButtonsWrapper>
        </Styled.DropdownContentStyle>
    )
}
