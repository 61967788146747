import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'

export const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    position: relative;
    box-sizing: border-box;
    padding: 0;
`

export const Container = styled.div`
    width: auto;
    position: relative;
    height: 1.1875rem;
    flex: 1;
    input[type='radio'] + label::after {
        opacity: 0;
    }

    input[type='radio'] + label::before {
        opacity: 1;
    }

    input[type='radio']:checked + label::after {
        opacity: 1;
    }

    input[type='radio']:checked + label::before {
        opacity: 0;
    }

    input[type='radio']:focus + label::before {
        outline: ${(props) => props.theme.colors.link.primary} auto 0.3125rem;
    }
`
export const Label = styled.label`
    padding-left: 20px;
    margin-bottom: 0;
    height: 1.1875rem;
    line-height: 1.1875rem;
    ${smallFont()}
    margin-bottom: 0;
    font-style: italic;

    .disabled {
        color: ${(props) => props.theme.colors.link.disabled};
    }

    ::before,
    ::after {
        position: absolute;
        content: '';
        height: 15px;
        width: 15px;
    }

    ::before {
        height: 15px;
        width: 15px;
        border: 0.0625rem solid;
        border-radius: 100%;
        opacity: 1;
        left: 0rem;
    }

    ::after {
        position: absolute;
        left: 0;
        height: 15px;
        background-color: ${(props) => props.theme.colors.link.primary};
        border-radius: 100%;
        width: 15px;
        transition: opacity 0.2s ease-in-out;
    }
`
