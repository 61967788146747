import * as Styled from './_styled/benchmark-summary.styled'
import FLEET_COMPARISON_POSITIVE from '../../../../../@assets/icons/fleet-comparision-positive.svg'
import FLEET_COMPARISON_NEGATIVE from '../../../../../@assets/icons/fleet-comparision-negative.svg'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import {SectionText, StatusFilterContainer} from './widget-commonstyling.styled'
import {WidgetIconsSection} from './widget-icons-section'
import {BenchmarkType} from '../../../../metrics-beta/contexts/types/metrics-summary'
import {useState} from 'react'
import {
    findMetricCategory,
    getAnalysisPeriodDisplayName,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {StringUtils} from '../../../../../utils/Utils'

export function BenchmarkSummary(): JSX.Element {
    const {metricsWidgetData} = useMetricsWidget()
    const benchmarkProtection = metricsWidgetData?.get(MetricType.PROTECTION)?.benchmark
    const benchmarkMaintenance = metricsWidgetData?.get(MetricType.MAINTENANCE)?.benchmark
    const benchmarkBehaviour = metricsWidgetData?.get(MetricType.BEHAVIOUR)?.benchmark

    return (
        <StatusFilterContainer>
            <SectionText>METRICS BENCHMARK SUMMARY</SectionText>
            <Styled.BenchmarkWrapper id="framework-benchmark-wrapper">
                <WidgetIconsSection />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <BenchmarkScore
                        benchmarkData={benchmarkProtection}
                        metricType={MetricType.PROTECTION}
                    />
                    <BenchmarkScore
                        benchmarkData={benchmarkMaintenance}
                        metricType={MetricType.MAINTENANCE}
                    />
                    <BenchmarkScore
                        benchmarkData={benchmarkBehaviour}
                        metricType={MetricType.BEHAVIOUR}
                    />
                </div>
            </Styled.BenchmarkWrapper>
        </StatusFilterContainer>
    )
}
interface BenchmarkScoreProps {
    benchmarkData: BenchmarkType | undefined
    metricType: MetricType
}
function BenchmarkScore({benchmarkData, metricType}: BenchmarkScoreProps): JSX.Element {
    const [visibleTippy, setVisibleTippy] = useState(false)

    const selectedVesselsData = benchmarkData?.selectedVessels
    const selectedVesselsTotalAssetsOk = selectedVesselsData?.totalAssetsOk ?? 0
    const selectedVesselsTotalAssetsWithIssue = selectedVesselsData?.totalAssetsWithIssue ?? 0

    const selectedVesselScore = calculateScore(
        selectedVesselsTotalAssetsOk,
        selectedVesselsTotalAssetsWithIssue,
    )
    function getFleetComparisionImg(selectedPerformance: number): string {
        return selectedPerformance >= 0 ? FLEET_COMPARISON_POSITIVE : FLEET_COMPARISON_NEGATIVE
    }
    return (
        <Styled.ScoreSection
            onMouseEnter={() => {
                setVisibleTippy(true)
            }}
            onMouseLeave={() => {
                setVisibleTippy(false)
            }}
        >
            <Styled.BenchmarkScore metricType={metricType} id={`benchmarkScore-${metricType}`}>
                <div style={{display: 'flex'}} id={`protectionBenchmark-${selectedVesselScore}`}>
                    {selectedVesselScore}
                    <Styled.PercentagWrapper>%</Styled.PercentagWrapper>
                </div>
            </Styled.BenchmarkScore>

            <img
                src={getFleetComparisionImg(selectedVesselScore)}
                style={{width: '19px', height: '26px'}}
                id={`fleetComparisionImg-${metricType}`}
            />
            {visibleTippy && <TippyContent metricType={metricType} score={selectedVesselScore} />}
        </Styled.ScoreSection>
    )
}
function calculateScore(totalAssetsOk: number, totalAssetsWithIssue: number): number {
    const totalAssets = totalAssetsOk + totalAssetsWithIssue
    return totalAssets === 0 ? 0 : Math.round((totalAssetsOk / totalAssets) * 100)
}
interface TippyContentProps {
    metricType: MetricType
    score: number
}
function TippyContent({metricType, score}: TippyContentProps): JSX.Element {
    const {selectedAnalysisPeriod} = useMetricsWidget()
    return (
        <Styled.TippyWrapper positionRightText={metricType === MetricType.BEHAVIOUR}>
            <div style={{display: 'flex', gap: '5px', fontWeight: 'bold'}}>
                <img
                    src={getMetricTypeIcon(findMetricCategory(metricType))}
                    id={`metricTypeIconTippy-${metricType}-${score}`}
                    width={20}
                    height={20}
                />
                {StringUtils.capitaliseString(metricType)}
            </div>
            <div>Benchmark: {score}% of assets OK</div>
            <div> {getAnalysisPeriodDisplayName(selectedAnalysisPeriod)}</div>
        </Styled.TippyWrapper>
    )
}
