import {useContext} from 'react'
import {REST} from '../../..'
import {warn} from '../../../helpers/logging'
import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'
import {buildCombinedKey} from './build_combined_key'
import {EventDetailsContext} from './event-details-context'
import * as ActionCreators from './state/action-creators'
import {UseEventDetailsResult} from './use-event-details-output'

export function useEventDetails(timestamp: string, eventId: GuidType): UseEventDetailsResult {
    const {state, dispatch} = useContext(EventDetailsContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useEventDetails must be used within a EventDetailsContext')
    }

    function getEventDetail(timestamp: string, eventId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is undefined')
            return
        }

        dispatch(ActionCreators.requestEventDetail())
        REST.get(`/api/v1/events/indicator/${timestamp}/${eventId}`)
            .then((response) => {
                dispatch(ActionCreators.receiveEventDetail(response.data))
            })
            .catch((err) => {
                throw err
            })
    }

    if (
        state.loading === LoadingState.NotPopulated ||
        state.loading === LoadingState.RequestingData
    ) {
        return {
            loading: state.loading,
            eventDetail: undefined,
            getEventDetail,
        }
    }

    const combinedKey = buildCombinedKey(timestamp, eventId)
    if (!state.eventDetails || !state.eventDetails.has(combinedKey)) {
        return {
            loading: LoadingState.NotPopulated,
            eventDetail: undefined,
            getEventDetail,
        }
    }

    return {
        loading: state.loading,
        eventDetail: state.eventDetails?.get(combinedKey) ?? undefined,
        getEventDetail,
    }
}
