import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

export const AssetStatus = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const StatusRowWrapper = styled.div`
    display: flex;
    padding-left: ${spacing(2)};
`

export const TextRow = styled.div`
    padding-left: 3px;
    display: flex;
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`

export const ViewAllRowWrapper = styled.span`
    display: flex;
    justify-content: space-between;
`
