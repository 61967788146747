import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Label = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Children = styled.div`
    width: 100%;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    word-break: break-word;
`
