import {LocationIdType} from '../../../../../store/state/locations/state'
import {VesselDetails} from '../common/vessel-details/vessel-details'
import {AssetThreatScoresGrid} from '../common/level-3-and-4-asset-threat-scores-grid'
import * as Styles from '../common/level-2-and-3-cell.styled'

interface Props {
    id: LocationIdType
}

export function ZoomLevel3Cell({id}: Props): JSX.Element {
    return (
        <Styles.Cell id="ZoomLevel3Cell">
            <div>
                <VesselDetails id={id} />
            </div>
            <AssetThreatScoresGrid id={id} />
        </Styles.Cell>
    )
}
