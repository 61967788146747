import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface LabelProps {
    gridRow: number
}

export const Label = styled.label<LabelProps>`
    margin-right: ${spacing(2)};
    margin-bottom: 0;
    grid-column: 1;
    grid-row: ${(props) => props.gridRow};
`
