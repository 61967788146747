import {Panel} from '../_styled/panel-section.styled'
import {DurationDropdown} from '../duration-dropdown/duration-dropdown'
import {UserDropdown} from '../user-dropdown/user-dropdown'
import {IncidentPolicyDetailToggle} from '../incident-type-severity-section/incident-policy-detail-toggle'
import * as Styled from './reminder-section.styled'
import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'

export function ReminderSection(): JSX.Element {
    const {
        activeIncidentPolicyData,
        initialIncidentPolicyData,
        displayIncidentPolicyData,
        enableIncidentReminderFn,
        changeReminderDuration,
        changeEscalateToUser,
        enableIncidentReminder,
    } = useIncidentsPolicy()

    return (
        <Panel>
            <Styled.DetailsLayout>
                <Styled.Details style={{paddingTop: '15px'}}>
                    <IncidentPolicyDetailToggle
                        label="Incident reminders"
                        onValue={enableIncidentReminder}
                        setChange={enableIncidentReminderFn}
                    />
                </Styled.Details>
                <Styled.EditableArea disabled={!enableIncidentReminder}>
                    <Styled.Details>
                        <DurationDropdown
                            label="Send reminder if no update after"
                            initialValue={displayIncidentPolicyData.sendReminderAfterDuration}
                            setChange={changeReminderDuration}
                            changed={
                                activeIncidentPolicyData?.sendReminderAfterDuration !==
                                initialIncidentPolicyData.sendReminderAfterDuration
                            }
                        />
                    </Styled.Details>
                    <Styled.Details>
                        <UserDropdown
                            label="If still no update, escalate to"
                            initialValue={displayIncidentPolicyData.escalateToUser}
                            setChange={changeEscalateToUser}
                            changed={
                                activeIncidentPolicyData?.escalateToUser !==
                                initialIncidentPolicyData.escalateToUser
                            }
                        />
                    </Styled.Details>
                </Styled.EditableArea>
            </Styled.DetailsLayout>
        </Panel>
    )
}
