import {useDispatch} from 'react-redux'
import {removeQueryParam} from '../../../../../../helpers/navigation'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {displayModal} from '../../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import {isNoteCreationSelector} from '../../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {ButtonText} from './button-text.styled'
import {BottomContentWrapper, Button} from './button.styled'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {NETWORK_ASSET_DETAILS} from '../../../helpers/data-helpers'

export function CloseButton(): JSX.Element {
    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)
    const {closeNetworkAssetDetailsModal} = usePagedUnknownAssetsBeta()
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (noteCreationStatus) {
            dispatch(displayModal())
        } else {
            removeQueryParam(NETWORK_ASSET_DETAILS)
            closeNetworkAssetDetailsModal()
        }
    }
    return (
        <BottomContentWrapper>
            <Button id="close-button" onClick={onClickWrapper}>
                <ButtonText>Close</ButtonText>
            </Button>
        </BottomContentWrapper>
    )
}
