import threatHelper, {ThreatValue} from '../../../../helpers/threatHelper'
import {LatestLocationThreatScores} from '../../../../store/state/latest-location-threat-scores/state'
import {getLocationScore} from '../../../../store/state/locations/helpers'
import {Location, LocationIdType, LocationMap} from '../../../../store/state/locations/state'
import {ThreatMeasureMap} from '../../../../values/ThreatMeasure'
import {NodeValues} from '../../../../values/nodes/NodeData'

export interface CurrentVesselScoreResponse {
    locationId: LocationIdType
    score: number
    threatLevel: ThreatValue
}

export function buildCurrentVesselScoreModel(
    locationIds: LocationIdType[],
    locations: LocationMap,
    latestLocationThreatScores: LatestLocationThreatScores,
    threatMeasures: ThreatMeasureMap,
): CurrentVesselScoreResponse[] {
    return locationIds.map((locationId) => {
        const location: Location | undefined = locations.get(locationId)

        if (!location) {
            throw Error('Could not find location')
        }
        const score = getLocationScore(location.location, latestLocationThreatScores)
        return {
            locationId,
            score,
            threatLevel: threatHelper(score, threatMeasures, NodeValues.HIGH),
        }
    })
}
