import RiskScore from './risk-score/risk-score'
import {TitleBar} from './title-bar/title-bar'
import {GuidType} from '../../../../values/generic-type-defintions'
import {HeaderBar} from './header-bar.styled'

interface HeaderProps {
    assetId: GuidType
}

function Header({assetId}: HeaderProps): JSX.Element {
    return (
        <HeaderBar>
            <RiskScore assetId={assetId} />
            <TitleBar assetId={assetId} />
        </HeaderBar>
    )
}

export default Header
