import {useDispatch} from 'react-redux'
import {ClearButtonLink} from './clear-button.styled'
import {HeadingRowType} from '../../../../../../store/state/network-assets-filter/state'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'

interface ClearButtonProps {
    clearFn: (value: boolean) => void
    filterType: HeadingRowType
}

export function ClearButton({clearFn, filterType}: ClearButtonProps): JSX.Element {
    const dispatch = useDispatch()
    return (
        <ClearButtonLink
            onClick={() => {
                dispatch(clearFn(false))
            }}
            id={`${PageType.NETWORK_ASSETS}_filter-bar_clear-button_${filterType}`}
        >
            Clear
        </ClearButtonLink>
    )
}
