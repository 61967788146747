import styled from 'styled-components'

interface TopSectionProps {
    expanded: boolean
}

export const TopSection = styled.div<TopSectionProps>`
    background-color: ${(props) =>
        props.theme.dashboard.vesselList.assetDetail.default.backgroundColor};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.expanded ? 0 : '6px')};
    border-bottom-right-radius: ${(props) => (props.expanded ? 0 : '6px')};
    display: flex;
    margin-top: 10px;
    align-items: center;
    position: relative;
    box-shadow: 0px 1px 2px ${(props) => (props.expanded ? '#00000029' : '#00000033')};
    margin-left: 5px;
    margin-right: 5px;
`
