import {useTagsComponent} from '../../../contexts/use-tags-component'
import {SortColumnType, TagsComponentSortType} from '../../../contexts/types/tags-component-filter'
import {getNewSortByDirection} from '../../../data-helpers'
import {IconStyled, TableHeaderCell, TableHeaderText} from './table-header-cell.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: TagsComponentSortType
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const {sortColumn, setNewSortColumn} = useTagsComponent()

    function setSortBy(selectedColumn: TagsComponentSortType) {
        const newSortColumn: SortColumnType = {
            orderAscending: getNewSortByDirection(selectedColumn, sortColumn),
            orderBy: selectedColumn,
        }
        setNewSortColumn(newSortColumn)
    }

    return (
        <TableHeaderCell id={`sortable_${colName}`} gridColumn={gridColumn} selectable>
            <TableHeaderText
                onClick={() => setSortBy(colName)}
                active={sortColumn.orderBy === colName}
                centered={gridColumn === 2 ? false : true}
            >
                <div style={{textAlign: 'center'}}>{text}</div>
                <IconStyled arrowDec={sortColumn.orderBy === colName && !sortColumn.orderAscending}>
                    {<Icon width={12} height={17} glyph="Arrow" />}
                </IconStyled>
            </TableHeaderText>
        </TableHeaderCell>
    )
}
