import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {incidentsPolicyReducer} from './state/reducer'
import {DEFAULT_INCIDENTS_POLICY_STATE} from './type/default-incidents-policy-state'
import {IncidentsPolicyState} from './type/incidents-policy-state'
import {IncidentsPolicyContext} from './incidents-policy-context'

type IncidentsPolicyProviderProps = {
    children: ReactNode | ReactNode[]
}

export function IncidentsPolicyProvider({children}: IncidentsPolicyProviderProps): JSX.Element {
    const initialState: IncidentsPolicyState = cloneDeep(DEFAULT_INCIDENTS_POLICY_STATE)

    const [state, dispatch] = useReducer(incidentsPolicyReducer, initialState)

    return (
        <IncidentsPolicyContext.Provider value={{state, dispatch}}>
            {children}
        </IncidentsPolicyContext.Provider>
    )
}
