import {VesselTags} from '../../../../../../../store/state/vessel-tags/state'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import * as Styled from './network-settings-section.styled'
import {VesselTagging} from './vessel-tagging'

interface VesselTaggingSectionProps {
    locationId: GuidType
    vesselTags: VesselTags[]
}

export function VesselTaggingSection({
    locationId,
    vesselTags,
}: VesselTaggingSectionProps): JSX.Element {
    const {detailExpanded} = useVesselManagement()

    return (
        <Panel>
            <ExpandPanelButton headingRow={VesselManagementDetailType.VesselTagging} />
            {detailExpanded.vesselTaggingExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <VesselTagging vesselTags={vesselTags} locationIdentity={locationId} />
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
