import {SubmitArea} from '../submit-area/submit-area'
import * as Styled from './data-area.styled'

export function ChangeNotSaved(): JSX.Element {
    return (
        <>
            <Styled.Text>Save failed</Styled.Text>
            <Styled.Actions>
                <SubmitArea closeOnly />
            </Styled.Actions>
        </>
    )
}
