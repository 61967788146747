import LoadingState from '../../../../../values/loading-state-enum'
import {ContentPageType} from './content-type'
import {PolicyToCreateOrUpdate} from './metrics-policy'
import {MetricsSchema} from './metrics-policy-schema'
import {MetricsPolicyState} from './metrics-policy-state'
const emptyPolicyToUpdateOrCreate: PolicyToCreateOrUpdate = {
    isDefault: false,
    id: '',
    metricPolicies: undefined,
    name: '',
}

export const DEFAULT_METRICS_POLICY_STATE: MetricsPolicyState = {
    loadingRequestedMetricsDataState: LoadingState.NotPopulated,
    selectedContentPage: ContentPageType.PROTECTIVE_CONTROLS,
    metricsSchema: new Array<MetricsSchema>(),
    selectedPolicy: undefined,
    policyToCreateOrUpdate: emptyPolicyToUpdateOrCreate,
    isMetricsUpdated: false,
    discardChanges: false,
}
