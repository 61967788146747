import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {DisplayArea, DropdownWrapper} from './auth-token-display.styled'
import {SwaggerDropdown} from './swagger-dropdown/swagger-dropdown'
import {TokenArea} from './token-area/token-area'
import {SwaggerLinkArea} from './swagger-link-area/swagger-link-area'
import {LinkButtons} from './link-buttons/link-buttons'

export function AuthTokenDisplay(): JSX.Element {
    const {width} = useDimensions()
    const confluenceLink =
        'https://cyberowl.atlassian.net/wiki/spaces/SD/pages/3065446401/Useful+URLs'

    return (
        <DisplayArea width={width}>
            <TokenArea />
            <DropdownWrapper>
                <SwaggerDropdown />
                <SwaggerLinkArea />
                <div
                    style={{
                        padding: '3px',
                    }}
                >
                    <LinkButtons
                        id={'confluence-link'}
                        text={'Confluence Useful Links Page'}
                        link={confluenceLink}
                    />
                </div>
            </DropdownWrapper>
        </DisplayArea>
    )
}
