import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

import checkboxHighSVG from '../.././@assets/icons/checkmark-high.svg'
import checkboxMediumSVG from '../.././@assets/icons/checkmark-medium.svg'
import checkboxLowSVG from '../.././@assets/icons/checkmark-low.svg'
import checkboxWarningSVG from '../.././@assets/icons/checkmark-warning.svg'
import checkboxDangerSVG from '../.././@assets/icons/checkmark-danger.svg'
import checkboxSVG from '../.././@assets/icons/checkmark.svg'
import {CheckboxType} from './checkbox-type'
import {mediumSmallFont} from '../../theme/font-styles'

interface LabelProps {
    disabled?: boolean
}

export const Label = styled.label<LabelProps>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`

export const Text = styled.span`
    ${mediumSmallFont()}
    margin-left: ${spacing(2)};
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
`

interface StyledCheckboxProps {
    isInactive?: boolean
}

export const StyledCheckbox = styled.span<StyledCheckboxProps>`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    background: ${(props) => (props.isInactive ? props.theme.colors.background.fade : 'white')};
    border: 1px solid #ccc;
`

function getSvg(type?: CheckboxType): string {
    switch (type) {
        case CheckboxType.HIGH:
            return checkboxHighSVG
        case CheckboxType.MEDIUM:
            return checkboxMediumSVG
        case CheckboxType.LOW:
            return checkboxLowSVG
        case CheckboxType.WARNING:
            return checkboxWarningSVG
        case CheckboxType.DANGER:
            return checkboxDangerSVG
        default:
            return checkboxSVG
    }
}

function getHoverBorderColor(type?: CheckboxType): string {
    switch (type) {
        case CheckboxType.HIGH:
            return '#1f88e5'
        case CheckboxType.MEDIUM:
            return '#1f88e5'
        case CheckboxType.LOW:
            return '#1f88e5'
        case CheckboxType.WARNING:
            return '#1f88e5'
        case CheckboxType.DANGER:
            return '#1f88e5'
        default:
            return '#1f88e5'
    }
}

function getSelectedTickColor(type?: CheckboxType): string {
    switch (type) {
        case CheckboxType.LOW:
        case CheckboxType.WARNING:
            return '#424242'
        default:
            return 'white'
    }
}
interface Props {
    checkboxType?: CheckboxType
}

export const CheckboxInput = styled.input<Props>`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 19px;
    height: 19px;

    &:hover ~ ${StyledCheckbox} {
        border-color: ${(props) => getHoverBorderColor(props.checkboxType)};
    }

    &:checked + ${StyledCheckbox} {
        border: none;
        background-color: ${(props) => getSelectedTickColor(props.checkboxType)};
        content: url('${(props) => getSvg(props.checkboxType)}');
    }
`
