import {DataLoading} from '../../../../components/data-loading/data-loading'
import {SoftwareInventoryTablePopulated} from './software-inventory-table-populated'
import {NoData} from './no-data/no-data'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import LoadingState from '../../../../values/loading-state-enum'

export function SoftwareInventoryTableContent(): JSX.Element {
    const {loadingDataState, totalNumberOfFilteredSoftwareInventory} = usePagedSoftwareInventory()
    const showLoading =
        totalNumberOfFilteredSoftwareInventory == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData
    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfFilteredSoftwareInventory === 0) {
        return <NoData text="No software has been detected yet" />
    }
    return <SoftwareInventoryTablePopulated />
}
