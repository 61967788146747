import {VesselTag} from '../../../../../../../components/vessel-tag/vessel-tag'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../../../store/state/locations/selectors'
import {VesselTags} from '../../../../../../../store/state/vessel-tags/state'
import {TagsUtils} from '../../../../../../../utils/Utils'
import * as Styles from './_styled/checkbox.styles'

interface Props {
    id: string
    checked: boolean
    onChange: (value: boolean) => void
    tag: VesselTags
}
export function Checkbox({id, checked, onChange, tag}: Props): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const vesselCount = TagsUtils.getVesselCountForTag(tag, locations)
    return (
        <Styles.Label htmlFor={id}>
            <Styles.CheckboxInput
                type="checkbox"
                id={id}
                onChange={() => onChange(!checked)}
                checked={checked}
            />
            <Styles.StyledCheckbox />
            <VesselTag tagName={tag.name} />
            <span style={{paddingLeft: '5px'}}>({vesselCount})</span>
        </Styles.Label>
    )
}
