import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import * as Styled from './data-cell.styled'

interface DataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
}

export function DataCell({label, children}: DataCellProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <Styled.DataCell width={width} id={`ot-sources-DataCell_${label}`}>
            <Styled.Label id={`ot-sources-Label_${label}`}>{label}</Styled.Label>
            <Styled.Value id={`ot-sources-Value_${label}`}>{children}</Styled.Value>
        </Styled.DataCell>
    )
}
