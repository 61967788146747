import produce from 'immer'
import {OTSystems} from '../../location-inventory-page-context'
import type {Action} from './actions'
import ActionType from './action-type'
import LoadingState from '../../../../../values/loading-state-enum'

export const OTSystemsReducer = produce((draft: OTSystems['state'], action: Action) => {
    switch (action.type) {
        case ActionType.OT_SYSTEMS_REQUEST:
            draft.loadingState = LoadingState.RequestingData
            break
        case ActionType.SET_OT_SYSTEMS:
            draft.loadingState = LoadingState.Loaded
            draft.data = action.payload.data
            break
        case ActionType.OT_SYSTEMS_FAILURE:
            draft.loadingState = LoadingState.Errored
            break
        case ActionType.TOGGLE_DETAILS:
            const index = draft.expandedSystemNames.findIndex(
                (i) => i === action.payload.systemName,
            )

            if (index !== -1) {
                draft.expandedSystemNames.splice(index, 1)
            } else {
                draft.expandedSystemNames.push(action.payload.systemName)
            }

            break

        case ActionType.REPORT_CREATE:
            draft.reportUrl = action.payload
            break

        case ActionType.SET_SHOW_ASSIGN_GENERATE_REPORT_POPUP:
            draft.showGenerateReportPopup = action.payload
            break

        case ActionType.REPORT_CREATE_ERROR:
            draft.createReportError = action.payload.error
            draft.isErrorOpen = true
            break

        case ActionType.RESET_ERROR_STATE:
            draft.createReportError = ''
            draft.isErrorOpen = false
            break

        case ActionType.UPDATE_OT_SYSTEM:
            // placeholder
            for (const [k, v] of Object.entries(draft.data)) {
                if (
                    v.hardwareAssets.length &&
                    v.hardwareAssets[0].systemID === action.payload.data.systemID
                ) {
                    draft.data[k].assessments.unshift(action.payload.data)
                    // One systemID only has one matching systemName as key
                    break
                }
            }

            break

        default:
            break
    }
})
