import * as Styled from './_styled/on-board-engagement.styled'
import {
    OnboardEngagementConfig,
    OnboardEngagementConfigType,
} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'
import {useEffect, useState} from 'react'
import {SelectableCircle} from './selectable-circle'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {isEqual} from 'lodash'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../../../store/state/config/selectors'

interface OnBoardEngagementConfigItemProps {
    metricConfigId: GuidType
    configItem: OnboardEngagementConfig
    disabled?: boolean
}

export function OnBoardEngagementConfigItem({
    metricConfigId,
    configItem,
    disabled = false,
}: OnBoardEngagementConfigItemProps): JSX.Element {
    const formattedInitialValue =
        configItem.value?.toLowerCase() ?? configItem.default.toLowerCase()
    const initialOnBoardValue = formattedInitialValue as OnboardEngagementConfigType

    const fleetConfig = useTypedSelector(fleetConfigSelector)

    let tooltip = ''

    // can't use ! here due to nullish coalescing
    if (fleetConfig?.LicenseState?.['License:Metrics:OnboardEngagement']?.value.enabled === false) {
        disabled = true
        tooltip = 'Metrics policy not available for your account'
    }
    const [previewSelectedConfig, setPreviewSelectedConfig] =
        useState<OnboardEngagementConfigType | null>(null)
    const [selectedConfig, setSelectdConfig] =
        useState<OnboardEngagementConfigType>(initialOnBoardValue)
    const {changeOnboardValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()

    useEffect(() => {
        if (discardChanges && !isEqual(initialOnBoardValue, selectedConfig)) {
            setSelectdConfig(initialOnBoardValue)
            requestDiscardChanges(false)
        }
    }, [selectedConfig, discardChanges, initialOnBoardValue, requestDiscardChanges])

    function selectConfig(newRating: OnboardEngagementConfigType) {
        const isItChanged = !isEqual(initialOnBoardValue, newRating)
        setSelectdConfig(newRating)
        changeOnboardValue(metricConfigId, configItem.name, newRating, isItChanged)
        stopSelecting()
    }

    function stopSelecting(): void {
        if (previewSelectedConfig) {
            setPreviewSelectedConfig(null)
        }
    }

    function mouseOverOption(value: OnboardEngagementConfigType): void {
        setPreviewSelectedConfig(value)
    }

    const availablConfigOptions: OnboardEngagementConfigType[] = [
        'off',
        'on',
        'master',
        'crew',
        'max',
    ]

    return (
        <Styled.Wrapper disabled={disabled} title={tooltip}>
            <Styled.Label>{configItem.displayName}</Styled.Label>
            <Styled.IconWrapper>
                <Styled.CircleBox onMouseLeave={stopSelecting}>
                    <Styled.Line />
                    {availablConfigOptions.map((configOption) => (
                        <SelectableCircle
                            key={`button-${configOption}`}
                            configOption={configOption}
                            previewSelectedConfig={previewSelectedConfig}
                            mouseOverOption={mouseOverOption}
                            selectConfig={selectConfig}
                            selectedConfig={selectedConfig}
                            disabled={disabled}
                        />
                    ))}
                </Styled.CircleBox>
                <Styled.SubTitle positionOfText="off">Off</Styled.SubTitle>
                <Styled.SubTitle positionOfText="on">On</Styled.SubTitle>
                <Styled.SubTitle positionOfText="master">Master</Styled.SubTitle>
                <Styled.SubTitle positionOfText="crew">Crew</Styled.SubTitle>
                <Styled.SubTitle positionOfText="max">Max</Styled.SubTitle>
            </Styled.IconWrapper>
        </Styled.Wrapper>
    )
}
