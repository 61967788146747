import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'
import {getsectionWidth} from '../incidents-types-and-severities/incidents-types-and-severities.styled'

interface ContentAreaProps {
    width: number
    narrowLayoutForOpenFilter: boolean
}

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(5)};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    color: ${(props) => props.theme.incidents.dataRow.text};
    border: ${spacing(1)} solid #fff;
`
export const SectionContent = styled.div<ContentAreaProps>`
    display: flex;
    ${(props) => !props.narrowLayoutForOpenFilter && 'justify-content: space-between'};
    flex-direction: ${(props) =>
        props.width < 1000 || props.narrowLayoutForOpenFilter ? 'column' : 'row'};
    gap: 10px;
`
interface ContainerProps {
    width: number
}

export const StatusFilterContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    background-color: #fff;
    padding: ${spacing(3)};
    width: ${(props) => getsectionWidth(props.width)}px;
`
export const SectionText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const ToolTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 4px 8px #0000004d;
    border-radius: 4px;
    padding: 15px;
    color: ${(props) => props.theme.colors.text.default};
    min-width: 150px;
`
export const ToolTipSubTitle = styled.div`
    font-size: 12px;
    line-height: 20px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const ToolTipTitle = styled.h4`
    font-size: 14px;
    line-height: 19px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
interface ToolTipRectangleProps {
    parameterToDisplay: 'averageTimeToOpen' | 'averageTimeToClose'
}
export const ToolTipRectangle = styled.div<ToolTipRectangleProps>`
    width: 10px;
    height: 10px;
    background-color: ${(props) =>
        props.parameterToDisplay === 'averageTimeToOpen' ? '#77CDEB' : '#0F84AB'};
`
export const CountLink = styled.span`
    ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
