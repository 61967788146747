import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const SearchIcon = styled.div`
    position: absolute;
    right: ${spacing(1)};
    top: 0;
    padding-left: ${spacing(1)};
    color: ${(props) => props.theme.colors.link.primary};
    cursor: pointer;
    border-left: 1px solid ${(props) => props.theme.colors.border.active};
    line-height: 30px;
`

export const XIcon = styled.div`
    position: absolute;
    right: ${spacing(6)};
    top: 0;
    padding-left: ${spacing(1)};
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    line-height: 30px;
`

export const SearchInput = styled.input.attrs({type: 'text'})`
    width: 100%;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    padding: ${spacing(1)} ${spacing(6)} ${spacing(1)} ${spacing(1)};
    ${mediumSmallFont()}
    height: 30px;
    background-color: inherit;
`
