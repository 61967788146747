import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {addMultipleQueryParam} from '../../../../../../helpers/navigation'
import usedTypeSelector from '../../../../../../hooks/use-typed-selector'
import {indicatorsSelector} from '../../../../../../store/state/indicators/selectors'
import {Indicator} from '../../../../contexts/indicators/types/indicator'
import {Card} from '../../common/cards/card.styled'
import {IndicatorOutputModel} from '../indicator.model'
import {CardTitle} from './styled/card-title.styled'
import {DetailsButton} from '../table/styled/details-button.styled'
import {Details} from './styled/details.styled'
import {Label} from './styled/label.styled'
import {Type} from './styled/type.styled'
import {Types} from './styled/types.styled'
import {Value} from './styled/value.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {gfcFlagSelector} from '../../../../../../store/state/config/selectors'
import {useDispatch} from 'react-redux'
import {logButtonClickFor} from '../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../../store/state/audit-log/state'

interface IndicatorCardProps {
    event: Indicator
}
export function IndicatorCard({event}: IndicatorCardProps): JSX.Element {
    const gfcFlag = useTypedSelector(gfcFlagSelector)

    const model = new IndicatorOutputModel(
        event,
        usedTypeSelector(indicatorsSelector)?.get(event.indicator),
        gfcFlag,
    )

    const {width} = useDimensions()
    const dispatch = useDispatch()
    return (
        <Card>
            <CardTitle>
                <div id={`indicators_${model.id}_timestamp`}>{model.formattedTimeStamp}</div>
                <DetailsButton
                    id={`indicators_${model.id}_details`}
                    value={model.id}
                    onClick={() => {
                        addMultipleQueryParam(
                            'eventDetails',
                            model.id,
                            'eventTimestamp',
                            model.timeStamp,
                        )
                        dispatch(
                            logButtonClickFor(
                                `assetModalClickIndicatorDetails_${model.id}`,
                                AuditLogPageType.ASSET_MODAL,
                            ),
                        )
                    }}
                >
                    Details
                </DetailsButton>
            </CardTitle>
            <Details id={`indicators_${model.id}_title`}>{model.title}</Details>
            <Types width={width}>
                <Type width={width}>
                    <Label width={width}>Mitre Tactic:</Label>
                    <Value id={`indicators_${model.id}_tatic`}>{model.tatic}</Value>
                </Type>
                <Type width={width} last>
                    <Label width={width}>Mitre TTPS:</Label>
                    <Value id={`indicators_${model.id}_technique`}>{model.technique}</Value>
                </Type>
            </Types>
        </Card>
    )
}
