import {WidgetType} from '../../../widget-type'
import {CenteredContentArea} from '../../general/content-area/centered-content-area.styled'
import {LineOne, LineTwo} from './_styled/no-data-content.styled'

interface NoDataContentProps {
    type: WidgetType
}

export function NoDataContent({type}: NoDataContentProps): JSX.Element {
    const text = type === WidgetType.INCIDENT_RESPONSE ? 'Incidents' : 'Asset Discovery'
    return (
        <CenteredContentArea id={`${type}-data-content`}>
            <LineOne>{text} feature not enabled</LineOne>
            <LineTwo>Contact support for more information</LineTwo>
        </CenteredContentArea>
    )
}
