import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {SoftwareDetailType} from '../../../contexts/types/paged-software-inventory-state'
import {ActionsContents} from './actions-contents'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'

export function ActionsSection(): JSX.Element {
    const {detailExpanded} = useTypedSelector(softwareInventoryFilterSelector)

    return (
        <Panel>
            <ExpandPanelButton headingRow={SoftwareDetailType.Actions} />
            {detailExpanded.actionsExpanded && <ActionsContents />}
        </Panel>
    )
}
