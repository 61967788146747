import {useState} from 'react'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import * as Styled from './add-new-policy-area.styled'
import {PolicyTypeDropdown} from './policy-type-dropdown/policy-type-dropdown'
import {usePolicyManagement} from '../contexts/policy-management/use-policy-management'
import {useIncidentsPolicy} from '../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../contexts/metrics-policy/use-metrics-policy'
import {useEnforcementPolicy} from '../contexts/enforcement-policy/use-enforcement-policy'

export function AddNewPolicyArea(): JSX.Element {
    const {width} = useDimensions()
    const {closePolicyDetailModal} = usePolicyManagement()
    const {setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {setDefaultEnforcementPolicyData} = useEnforcementPolicy()
    const [openPolicyType, setOpenPolicyType] = useState(false)

    return (
        <Styled.ButtonWrapper width={width}>
            <Styled.Button
                onClick={() => {
                    setOpenPolicyType(true)
                    closePolicyDetailModal()
                    setDefaultIncidentPolicyData()
                    setDefaultMetricsPolicyData()
                    setDefaultEnforcementPolicyData()
                }}
            >
                + Add New Policy
            </Styled.Button>
            {openPolicyType && <PolicyTypeDropdown setOpenPolicyType={setOpenPolicyType} />}
        </Styled.ButtonWrapper>
    )
}
