import {Link} from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    &:focus,
    &:visited,
    &:link,
    &:active,
    &:hover {
        text-decoration: none;
    }
    color: ${(props) => props.theme.colors.link.primary};
`
