import * as Styled from './_styled/vessels-cards-populated.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {VesselsCard} from './vessels-card'
import {sortedLocationsSelector} from '../../selector/sorted-locations-selectors'

export function VesselsCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const sortedLocations = useTypedSelector(sortedLocationsSelector)

    return (
        <Styled.CardArea width={width}>
            {sortedLocations.map((location) => (
                <VesselsCard key={location.location} location={location} />
            ))}
        </Styled.CardArea>
    )
}
