import {LocationTagScore} from '../../../values/location-tag-score'
import AppState from '../../types/app-state'
import {LocationTagThreatScoreReduxState} from './state'

export const locationTagThreatScoreSelector = (state: AppState): LocationTagThreatScoreReduxState =>
    state.locationTagThreatScore

export const locationTagScore12HourDataSelector = (state: AppState): LocationTagScore[] =>
    state.locationTagThreatScore.trendLast12H

export const locationTagScore24HourDataSelector = (state: AppState): LocationTagScore[] =>
    state.locationTagThreatScore.trendLast24H

export const locationTagScore7DaysDataSelector = (state: AppState): LocationTagScore[] =>
    state.locationTagThreatScore.trendLast7D

export const locationTagScore30DaysDataSelector = (state: AppState): LocationTagScore[] =>
    state.locationTagThreatScore.trendLast30D

export const locationTagScore90DaysDataSelector = (state: AppState): LocationTagScore[] =>
    state.locationTagThreatScore.trendLast90D
