import {useEffect} from 'react'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {
    DEFAULT_VESSEL_CONFIG_MODAL,
    VesselConfigModal,
} from '../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../contexts/use-vessel-management'
import {vesselDetailsCachedReselector} from '../../../reselectors/vessel-details.cached-reselector'
import {NoDataModal} from '../../vessel-list-table/vessel-details-modal/no-data-modal'
import {CommsSettingsSection} from '../../vessel-list-table/vessel-details-modal/vessel-details/comms-settings-section/comms-settings-section'
import {NetworkSettingsSection} from '../../vessel-list-table/vessel-details-modal/vessel-details/network-settings-section/network-settings-section'
import {PolicySettingsSection} from '../../vessel-list-table/vessel-details-modal/vessel-details/policy-settings-section/policy-settings-section'
import {VesselTaggingSection} from '../../vessel-list-table/vessel-details-modal/vessel-details/vessel-tagging-section/vessel-tagging-section'
import {DetailsExpandedSection} from './vessel-management-detail-expanded.styled'
import {VesselDeploymentStatusInfo} from '../../vessel-list-table/vessel-details-modal/vessel-details/vessel-deployment-section/vessel-deployment-status-info'
import {usePolicyManagement} from '../../../../policy-management/contexts/policy-management/use-policy-management'
import {OTSourcesSection} from '../../vessel-list-table/vessel-details-modal/vessel-details/ot-sources-section/ot-sources-section'

interface VesselManagementDetailExpandedProps {
    locationId: GuidType
}

export function VesselManagementDetailExpanded({
    locationId,
}: VesselManagementDetailExpandedProps): JSX.Element {
    const {
        activeVesselId,
        setInitialValue,
        locationsInIsolationMode,
        setSelectedIncidentPolicy,
        setSelectedMetricsPolicy,
        setSelectedEnforcementPolicy,
    } = useVesselManagement()
    const {allPolicyData} = usePolicyManagement()

    const model = useTypedSelector((state) =>
        vesselDetailsCachedReselector(
            state,
            activeVesselId ?? '',
            locationsInIsolationMode,
            allPolicyData,
        ),
    )

    const initialVesselConfig = !model
        ? DEFAULT_VESSEL_CONFIG_MODAL
        : ({
              networkConfiguration: model.metadata.networkConfiguration,
              vesselDashboardAgentIds: model.metadata.vesselDashboardAgentIds,
              vesselEmail: model.vesselEmail,
              incidentPolicy: model.incidentPolicy,
              metricsPolicy: model.metricsPolicy,
              enforcementPolicy: model.enforcementPolicy,
              otSources: model.metadata.otSources,
          } as VesselConfigModal)

    useEffect(() => {
        setInitialValue(initialVesselConfig)
        setSelectedIncidentPolicy(initialVesselConfig.incidentPolicy.id)
        setSelectedMetricsPolicy(initialVesselConfig.metricsPolicy.id)
        setSelectedEnforcementPolicy(initialVesselConfig.enforcementPolicy.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVesselId])

    if (!model) {
        return <NoDataModal />
    }

    return (
        <DetailsExpandedSection>
            <VesselDeploymentStatusInfo locationId={locationId} />
            <NetworkSettingsSection locationId={locationId} />
            <OTSourcesSection locationId={model.id} />
            <CommsSettingsSection
                allAssets={model.nodesForLocation}
                vesselDashboard={model.vesselDashboard}
            />
            <PolicySettingsSection
                locationId={model.id}
                hasVesselDashboard={model.vesselDashboard}
            />
            <VesselTaggingSection locationId={model.id} vesselTags={model.vesselTags} />
        </DetailsExpandedSection>
    )
}
