import {createSelector} from 'reselect'
import {latestUnknownAssetLoadingSelector} from '../../../../../store/state/latest-unknown-asset/selectors'
import {locationsLoadingSelector} from '../../../../../store/state/locations/selectors'

export const loadingReselector = createSelector(
    locationsLoadingSelector,
    latestUnknownAssetLoadingSelector,
    (locationsLoading: boolean, latestUnknownAssetLoading: boolean): boolean => {
        return locationsLoading || latestUnknownAssetLoading
    },
)
