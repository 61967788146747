import styled from 'styled-components'
import {Colors} from '../../../theme/colours'

type AddButtonProps = {
    children?: JSX.Element | string
    onClick?: () => void
    height?: number
    width?: number
    disabled: boolean
}

const StyledButton = styled.button<Pick<AddButtonProps, 'disabled'>>`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    color: ${(prop) => (!prop.disabled ? Colors.blue2 : 'grey')};
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
`

const StyledSvg = styled.svg<Pick<AddButtonProps, 'disabled'>>`
    stroke: ${(prop) => (!prop.disabled ? '#1f88e5' : 'grey')};
`

export function AddButton({
    children,
    onClick,
    height,
    width,
    disabled = false,
}: AddButtonProps): JSX.Element {
    height = height ? height : 48
    width = width ? width : 48
    return (
        <StyledButton onClick={onClick} disabled={disabled} type="button">
            <StyledSvg
                disabled={disabled}
                width={width}
                height={height}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M39 6H9C7.34315 6 6 7.34315 6 9V39C6 40.6569 7.34315 42 9 42H39C40.6569 42 42 40.6569 42 39V9C42 7.34315 40.6569 6 39 6Z"
                    strokeWidth="4"
                    strokeLinejoin="round"
                />
                <path
                    d="M24 16V32M16 24H32"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </StyledSvg>
            {children}
        </StyledButton>
    )
}
