import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {AnalysisType} from '../../../../store/state/metrics-filter-beta/state'
import {MetricType} from '../../contexts/types/metrics-response'
import {BenchmarkFleetComparison} from './benchmark'
import {ScoreBar} from './score-bar'
import {Target} from './target'
import {Trends} from './trends'
import {WorstPerformingVessels} from './worst-performing-vessels'
interface Props {
    metricType: MetricType
}
export function MetricsFooter({metricType}: Props): JSX.Element {
    const {analysisTypes} = useTypedSelector(metricsBetaFilterSelector)
    switch (analysisTypes) {
        case AnalysisType.TRENDS:
            return <Trends metricType={metricType} />
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return <WorstPerformingVessels metricType={metricType} />
        case AnalysisType.BENCHMARK:
            return <BenchmarkFleetComparison metricType={metricType} />
        case AnalysisType.TARGET:
            return <Target metricType={metricType} />
        case AnalysisType.SCORECARD:
        default:
            return <ScoreBar metricType={metricType} />
    }
}
