import {EngineStatus} from '../../../../../values/engine_status/engine-status'
import {Section} from './section'

interface SectionsProps {
    engineStatuses: EngineStatus[] | undefined
}
export function Sections({engineStatuses}: SectionsProps): JSX.Element {
    return (
        <>
            {engineStatuses &&
                engineStatuses.map((engineStatus) => (
                    <Section key={engineStatus.engineName} engineStatus={engineStatus} />
                ))}
        </>
    )
}
