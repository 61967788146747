import {EngineStatus} from '../../../values/engine_status/engine-status'
import AppState from '../../types/app-state'
import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'

export const isLoadingSelector = (state: AppState): boolean =>
    state.engineStatus.loading === LoadingState.NotPopulated ||
    state.engineStatus.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState | undefined =>
    state.engineStatus.loading

export const dataSelector = (state: AppState): EngineStatus[] | undefined => state.engineStatus.data

export const lastUpdatedSelector = (state: AppState): Moment | null | undefined =>
    state.engineStatus.lastUpdate
