import moment from 'moment'
import * as Styled from './trends.styled'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import {MetricTrendsArrayForUIDisplay} from '../../contexts/types/metrics-summary'
import {BenchmarkTrendsType} from '../../../../store/state/metrics-filter-beta/state'
export interface CurrentScoreTooltipProps {
    active?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any
    label?: number
    data: MetricTrendsArrayForUIDisplay[]
    benchmarkType: BenchmarkTrendsType
    allCustomerPerformance?: number
    showAdditionalInfo?: boolean
}

export function CurrentScoreTooltip({
    active,
    payload,
    data,
    label,
    benchmarkType,
    allCustomerPerformance,
    showAdditionalInfo = true,
}: CurrentScoreTooltipProps): JSX.Element | null {
    if (active && payload && payload.length) {
        const dataPoint = data.find((element) => element.timestamp === label)
        return (
            <Styled.ToolTipContainer id={`ToolTipContainer-${dataPoint?.timestamp}`}>
                <Styled.ToolTipTitle>
                    <div>Assets OK:</div>
                    <div style={{paddingLeft: '5px'}}>
                        • selected vessels: {Math.round(dataPoint?.selectedVesselsPerformance ?? 0)}
                        %
                    </div>
                    {showAdditionalInfo && (
                        <div style={{paddingLeft: '5px'}}>
                            {benchmarkType === 'Whole fleet'
                                ? `• whole fleet: ${Math.round(dataPoint?.fleetPerformance ?? 0)}%`
                                : allCustomerPerformance
                                  ? `• all customers: ${allCustomerPerformance}%`
                                  : ''}
                        </div>
                    )}
                </Styled.ToolTipTitle>
                <Styled.ToolTipSubTitle>
                    {formatDateWithoutTime(moment(label))}
                </Styled.ToolTipSubTitle>
            </Styled.ToolTipContainer>
        )
    }

    return null
}
