interface Props {
    disabled?: boolean
}
export function ExcelIcon(props: Props): JSX.Element {
    return (
        <svg
            width="52"
            height="45"
            viewBox="0 0 52 45"
            fill={props.disabled ? '#A7B0BE' : '#1F88E5'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M34.854 8.176L32.894 6H17.4588C16.3452 6 15.9148 6.8256 15.9148 7.4704V13.2784H18.0796V8.6448C18.0796 8.3984 18.2876 8.1904 18.5276 8.1904H29.5724C29.8156 8.1904 29.9372 8.2336 29.9372 8.4336V16.1456H37.798C38.1068 16.1456 38.2268 16.3056 38.2268 16.5392V35.3712C38.2268 35.7648 38.0668 35.824 37.8268 35.824H18.5276C18.4086 35.8211 18.2954 35.7721 18.2118 35.6873C18.1282 35.6026 18.0808 35.4886 18.0796 35.3696V33.648H15.9292V36.36C15.9004 37.32 16.4124 38 17.4588 38H38.8956C40.0156 38 40.398 37.1888 40.398 36.4496V14.2992L39.838 13.6912L34.854 8.176ZM32.1372 8.432L32.7564 9.1264L36.91 13.6912L37.1388 13.968H32.894C32.574 13.968 32.3708 13.9152 32.286 13.808C32.2012 13.704 32.1516 13.536 32.1372 13.3072V8.432ZM30.3932 23.0672H37.7164V25.2016H30.3916L30.3932 23.0672ZM30.3932 18.8016H37.7164V20.9344H30.3916L30.3932 18.8016ZM30.3932 27.3344H37.7164V29.4688H30.3916L30.3932 27.3344ZM11.5996 15.0016V32.0688H28.3436V15.0016H11.5996ZM19.9724 24.928L18.9484 26.4928H19.9724V28.4H14.8252L18.5596 22.784L15.2508 17.7344H18.0156L19.974 20.672L21.9308 17.7344H24.694L21.3788 22.784L25.118 28.4H22.2492L19.9724 24.928Z" />
        </svg>
    )
}
