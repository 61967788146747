import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {removeQueryParam} from '../../../../../../helpers/navigation'

export function CancelButton(): JSX.Element {
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        removeQueryParam('editAsset')
    }
    return (
        <Styled.ButtonStyled id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.ButtonStyled>
    )
}
