import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {NMEAInventoryCardsContent} from './nmea-inventory-cards-content'

export function NMEAInventoryCards(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <ContentContainerMiddle
            width={width}
            scrollable
            backgroundColor={theme.commonTableViewPage.dataArea.background}
        >
            <NMEAInventoryCardsContent />
        </ContentContainerMiddle>
    )
}
