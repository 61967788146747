import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {
    IncidentSeverityValue,
    IncidentSeverityValues,
} from '../../../contexts/types/incident-severity-types'

function getSeverityLevelBackgroundColor(
    severityLevel: IncidentSeverityValue,
    theme: CyberOwlTheme,
): string {
    switch (severityLevel) {
        case IncidentSeverityValues.CRITICAL:
            return theme.incidents.incidentSeverity.active.critical
        case IncidentSeverityValues.HIGH:
            return theme.incidents.incidentSeverity.active.high
        case IncidentSeverityValues.MEDIUM:
            return theme.incidents.incidentSeverity.active.medium
        case IncidentSeverityValues.LOW:
            return theme.incidents.incidentSeverity.active.low
        default:
            return theme.incidents.incidentSeverity.active.low
    }
}
interface ContentWrapperProps {
    width: number
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > 400 ? 'row' : 'column')};
    align-items: center;
    justify-content: space-between;
    column-gap: ${spacing(1)};
    padding: ${spacing(1)} 0;
`

interface SeverityLevelSquareProps {
    severityLevel: IncidentSeverityValue
    activeFilter: boolean
}

export const SeverityLevelSquare = styled.span<SeverityLevelSquareProps>`
    color: white;
    background-color: ${(props) =>
        getSeverityLevelBackgroundColor(props.severityLevel, props.theme)};
    height: ${spacing(4)};
    width: ${spacing(12)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    position: relative;
    cursor: pointer;
    opacity: ${(props) => (props.activeFilter ? 1 : 0.5)};
`

export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const SeverityWrapper = styled.div`
    display: flex;
    column-gap: ${spacing(2)};
    justify-content: space-between;
`
export const SeverityLevelWarningIcon = styled.img`
    position: absolute;
    right: -7px;
    top: -12px;
    width: 18px;
    height: 16px;
`
