import LoadingState from '../../../../values/loading-state-enum'
import {PagedSoftwareInventoryState} from './paged-software-inventory-state'
import {SoftwareInventoryResponse} from './software-inventory-response'
import {SoftwareStatus} from '../../../../store/state/software-inventory-filter/state'

export const DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE: PagedSoftwareInventoryState = {
    loadingDataState: LoadingState.NotPopulated,
    loadingFilterState: LoadingState.NotPopulated,
    dataSoftwareInventoryMap: new Map<number, SoftwareInventoryResponse[]>(),
    totalNumberOfSoftwareInventory: undefined,
    totalNumberOfPages: undefined,
    selectedPage: 0,
    pageSize: 10,
    showFilterBar: false,
    modalIdForSoftwareDetail: null,
    findSoftwareDetailForModal: undefined,
    showChangeSoftwareStatusModalDisplay: false,
    selectedStatusType: null,
    softwareStatus: SoftwareStatus.unknown,
    modalCheckState: {forAllSoftware: false, forCompany: false},
    reportUrl: null,
    showGenerateReportPopup: false,
} as PagedSoftwareInventoryState
