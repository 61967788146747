import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {DEFAULT_PAGED_VESSELS_BETA_STATE} from './types/default-paged-vessels-beta-state'
import {pagedVesselsBetaReducer} from './state/reducer'
import {PagedVesselsBetaState} from './types/paged-vessels-beta-state'
import {PagedVesselsBetaContext} from './paged-vessels-beta-context'

type PagedVesselsBetaProviderProps = {
    children: ReactNode | ReactNode[]
}

export function PagedVesselsBetaProvider({children}: PagedVesselsBetaProviderProps): JSX.Element {
    const initialState: PagedVesselsBetaState = cloneDeep(DEFAULT_PAGED_VESSELS_BETA_STATE)

    const [state, dispatch] = useReducer(pagedVesselsBetaReducer, initialState)

    return (
        <PagedVesselsBetaContext.Provider value={{state, dispatch}}>
            {children}
        </PagedVesselsBetaContext.Provider>
    )
}
