import * as Styles from './usb-device-details-expanded.styled'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {USB_DEVICES_DETAILS} from '../../data-helpers'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {ActionsContents} from '../../usb-device-details-modal/actions/actions-contents'
import {ActivityContents} from '../../usb-device-details-modal/activity/activity-contents'
import {PurposeContents} from '../../usb-device-details-modal/activity/purpose-contents'

export function USBDeviceDetailsExpanded(): JSX.Element {
    const {findUSBDeviceForModal, setIdForUSBDetailsModal} = usePagedUSBDevices()

    return (
        <Styles.DetailsSection
            id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}`}
        >
            <Styles.DetailsContent
                id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}_DetailsContent-1`}
            >
                <Styles.ModalTitle
                    id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}_ModalTitle-1`}
                >
                    Activity
                </Styles.ModalTitle>
                {findUSBDeviceForModal && (
                    <Styles.DetailsLayout
                        id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}_DetailsLayout`}
                    >
                        <PurposeContents />
                        <ActivityContents />
                    </Styles.DetailsLayout>
                )}
            </Styles.DetailsContent>
            <Styles.DetailsContent
                id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}_DetailsContent-2`}
            >
                <Styles.ModalTitle
                    id={`usb-devices-details-card-extended_${findUSBDeviceForModal?.identity}_ModalTitle-2`}
                >
                    Actions
                </Styles.ModalTitle>
                {findUSBDeviceForModal && <ActionsContents />}
            </Styles.DetailsContent>
            <Styles.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    setIdForUSBDetailsModal(null)
                    removeQueryParam(USB_DEVICES_DETAILS)
                }}
                id="close-details-icon"
            />
        </Styles.DetailsSection>
    )
}
