import styled from 'styled-components'
import {animated} from 'react-spring'

export const ModalOverlay = styled(animated.span)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props) => props.theme.modalDialog.overlay};
    z-index: 999;
    backdrop-filter: blur(8px);
    opacity: 1;
`

export const ModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    min-height: 100vh;
    overflow: hidden;
`
