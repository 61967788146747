import AppState from '../types/app-state'

export const fetchingSelector2 = (state: AppState): boolean =>
    state.latestEventTimestamps.isFetchingLatestEventTimestamps ||
    state.nodes.isFetchingNodes ||
    state.sfmScores.fetching ||
    state.threatMeasures.isFetchingThreatMeasures ||
    state.watchedNodes.loading

export const overviewFetchingSelector = (state: AppState): boolean =>
    state.locations.isFetching || state.nodes.isFetchingNodes

export const fetchingSelector4 = (state: AppState): boolean =>
    state.locations.isFetching ||
    state.nodes.isFetchingNodes ||
    state.sfmScores.fetching ||
    state.threatMeasures.isFetchingThreatMeasures
