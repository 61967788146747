import produce from 'immer'
import {
    defaultLocationInventoryContextState,
    AssetFormOptions,
} from '../../location-inventory-page-context'
import type {Action} from './actions'
import ActionType from './action-type'
import LoadingState from '../../../../../values/loading-state-enum'
import _ from 'lodash'

export const AssetFormOptionsReducer = produce(
    (draft: AssetFormOptions['state'], action: Action) => {
        switch (action.type) {
            case ActionType.ASSET_OPTIONS_REQUEST:
                draft.formOptionsLoadingState = LoadingState.RequestingData
                // Reset the form options
                draft.formOptions = _.cloneDeep(
                    defaultLocationInventoryContextState.assetFormOptions.state.formOptions,
                )
                break
            case ActionType.SET_ASSET_OPTIONS:
                draft.formOptionsLoadingState = LoadingState.Loaded
                draft.formOptions = action.payload.data
                break
            case ActionType.ASSET_OPTIONS_FAILURE:
                draft.formOptionsLoadingState = LoadingState.Errored
                break
            case ActionType.CREATE_ASSET_OPTION_SUCCESS:
                draft.formSubmitLoadingState = LoadingState.Loaded
                draft.formOptions[action.payload.assetOptionKey].push(action.payload.data)
                break
            case ActionType.CREATE_ASSET_OPTION_REQUEST:
                draft.formSubmitLoadingState = LoadingState.RequestingData
                break
            case ActionType.SET_CREATE_ASSET_OPTION_ERROR:
                draft.formSubmitLoadingState = LoadingState.Errored
                draft.formSubmitError = action.payload.error
                break
            case ActionType.SET_CREATE_ASSET_OPTION_FORM_ERRORS:
                draft.formSubmitLoadingState = LoadingState.Errored
                draft.formFieldError = action.payload.formErrors
                break
            case ActionType.CREATE_ASSET_OPTION_RESET:
                const state = _.cloneDeep(
                    defaultLocationInventoryContextState.assetFormOptions.state,
                )
                state.formOptions = draft.formOptions
                Object.assign(draft, state)
                break
            default:
                break
        }
    },
)
