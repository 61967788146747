import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
`
export const Value = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
export const ReadOnlyValue = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const inputNonText = styled.input`
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`
