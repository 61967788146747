import {TableHeaderCell} from '../../common/table/table-header-cell.styled'

function TableHeader(): JSX.Element {
    return (
        <>
            <TableHeaderCell gridColumn={2}>First Detected</TableHeaderCell>
            <TableHeaderCell gridColumn={3}>
                Destination
                <br />
                Host
            </TableHeaderCell>
            <TableHeaderCell gridColumn={4}>
                Destination
                <br />
                Port
            </TableHeaderCell>
            <TableHeaderCell gridColumn={5}>
                Destination
                <br />
                Protocol
            </TableHeaderCell>
            <TableHeaderCell gridColumn={6}>Proximity</TableHeaderCell>
            <TableHeaderCell gridColumn={7}>Direction</TableHeaderCell>
        </>
    )
}

export default TableHeader
