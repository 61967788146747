import {MouseEvent} from 'react'
import {Container} from './container.styled'
import {LeftHorizontalBar} from './left-horizontal-bar.styled'
import {RightHorizontalBar} from './right-horizontal-bar.styled'
import {Tab} from './tab'

interface TabHeaderProps {
    activeTab: number
    toggle: (event: MouseEvent, tab: number) => void
}

export function TabHeader({activeTab, toggle}: TabHeaderProps): JSX.Element {
    return (
        <Container>
            <LeftHorizontalBar />
            <Tab activeTab={activeTab} index={1} toggle={toggle} isFirstTab>
                Alerts
            </Tab>
            <Tab activeTab={activeTab} index={2} toggle={toggle}>
                Indicators
            </Tab>
            <Tab activeTab={activeTab} index={3} toggle={toggle}>
                Data Flows
            </Tab>
            <RightHorizontalBar />
        </Container>
    )
}
