import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {fetchNetworkAssetsFilter} from '../../../../../../store/state/network-assets-filter/action-creators'
import {useDispatch} from 'react-redux'
import {savedNetworkAssetsFiltersSelector} from '../../../../../../store/state/saved-filters/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

export function CloseButton(): JSX.Element {
    const {closeEnableMonitoringModal, refreshData} = usePagedUnknownAssetsBeta()
    const dispatch = useDispatch()
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)

    function closeModal(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        closeEnableMonitoringModal()
        refreshData()
        dispatch(fetchNetworkAssetsFilter(savedFilters))
    }

    return (
        <Styled.Button id="close-button" onClick={closeModal}>
            Close
        </Styled.Button>
    )
}
