import {useState} from 'react'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {
    Actions,
    DataCell,
    DataCellKey,
    DataCellValue,
    DataRow,
    DetailsLayout,
    Header,
    Panel,
    Section,
    Title,
    InputDisplayText,
    InputEditableText,
    InputWrapper,
    SubDataCellValue,
} from './set-usb-purpose.styled'
import {SubmitArea} from './submit-area'
import PEN from '../../../../../../@assets/icons/pen.svg'
import {usePagedUSBDevices} from '../../../../contexts/use-paged-usb-devices'

export function SetUsbPurpose(): JSX.Element {
    const {width} = useDimensions()
    const {usbDetailsOutput, findUSBDeviceForModal} = usePagedUSBDevices()
    const [edit, setEdit] = useState(false)
    const [purpose, setPurpose] = useState(findUSBDeviceForModal?.purpose)

    return (
        <Section onClick={(e) => e.stopPropagation()} width={width} id="set-usb-purpose-section">
            <Header style={{display: 'flex'}} id="set-usb-purpose-Header">
                <Title id="set-usb-purpose-Title">Set purpose for this device</Title>
            </Header>
            <Panel id="set-usb-purpose-Panel">
                <DetailsLayout id="set-usb-purpose-DetailsLayout">
                    <DataRow id="set-usb-purpose-DataRow-1">
                        <DataCell id="set-usb-purpose-DataCell-1">
                            <DataCellKey id="set-usb-purpose-DataCellKey-1">Vendor:</DataCellKey>
                            <DataCellValue id="set-usb-purpose-DataCellValue-1">
                                {usbDetailsOutput.vendorName}
                            </DataCellValue>
                        </DataCell>
                        <DataCell id="set-usb-purpose-DataCell-2">
                            <DataCellKey id="set-usb-purpose-DataCellKey-2">
                                Product name:
                            </DataCellKey>
                            <DataCellValue id="set-usb-purpose-DataCellValue-2">
                                {usbDetailsOutput.productName}
                            </DataCellValue>
                        </DataCell>
                        <DataCell id="set-usb-purpose-DataCell-3">
                            <DataCellKey id="set-usb-purpose-DataCellKey-3">Type:</DataCellKey>
                            <DataCellValue id="set-usb-purpose-DataCellValue-3">
                                {usbDetailsOutput.deviceType}
                            </DataCellValue>
                        </DataCell>
                        <DataCell id="set-usb-purpose-DataCell-4">
                            <DataCellKey id="set-usb-purpose-DataCellKey-4">
                                Volume labels:
                            </DataCellKey>
                            <DataCellValue id="set-usb-purpose-DataCellValue-4">
                                {usbDetailsOutput.volumeLabels}
                            </DataCellValue>
                        </DataCell>
                    </DataRow>
                </DetailsLayout>
                <DetailsLayout>
                    <DataRow>
                        <DataCell id="set-usb-purpose-DataCell-5">
                            <DataCellKey id="set-usb-purpose-DataCellKey-5">
                                Purpose for the device:
                            </DataCellKey>
                            {findUSBDeviceForModal?.purpose ? (
                                <DataCellValue id="set-usb-purpose-DataCellValue-5">
                                    {edit ? (
                                        <InputWrapper id="set-usb-purpose-InputWrapper-1">
                                            <InputEditableText
                                                placeholder="Type purpose here"
                                                value={purpose}
                                                onChange={(e) => {
                                                    setPurpose(e.target.value)
                                                }}
                                                id="set-usb-purpose-InputEditableText-1"
                                            />
                                            <img
                                                src={PEN}
                                                onClick={() => setEdit(false)}
                                                id="set-usb-purpose-InputEditableImg-1"
                                            />
                                        </InputWrapper>
                                    ) : (
                                        <InputWrapper id="set-usb-purpose-InputWrapper-2">
                                            <InputDisplayText id="set-usb-purpose-InputEditableText-2">
                                                {purpose}
                                            </InputDisplayText>
                                            <img
                                                src={PEN}
                                                onClick={() => setEdit(true)}
                                                id="set-usb-purpose-InputEditableImg-2"
                                            />
                                        </InputWrapper>
                                    )}
                                    <SubDataCellValue id="set-usb-purpose-SubDataCellValue-1">
                                        Submitted by: {usbDetailsOutput.purposeSubmittedBy}
                                    </SubDataCellValue>
                                    <SubDataCellValue id="set-usb-purpose-SubDataCellValue-2">
                                        Updated on: {usbDetailsOutput.purposeSubmittedWhen}
                                    </SubDataCellValue>
                                </DataCellValue>
                            ) : (
                                <DataCellValue id="set-usb-purpose-DataCellValue-6">
                                    <InputWrapper id="set-usb-purpose-InputWrapper-3">
                                        <InputEditableText
                                            placeholder="Type purpose here"
                                            value={purpose}
                                            onChange={(e) => {
                                                setPurpose(e.target.value)
                                            }}
                                            id="set-usb-purpose-InputEditableText-3"
                                        />
                                    </InputWrapper>
                                </DataCellValue>
                            )}
                        </DataCell>
                    </DataRow>
                </DetailsLayout>
            </Panel>
            <Actions id="set-usb-purpose-Actions">
                <SubmitArea purpose={purpose} />
            </Actions>
        </Section>
    )
}
