import {useDispatch} from 'react-redux'
import {ClearButtonLink} from './clear-button.styled'

interface ClearButtonProps {
    clearFn: (value: boolean) => void
    id: string
}

export function ClearButton({clearFn, id}: ClearButtonProps): JSX.Element {
    const dispatch = useDispatch()
    return (
        <ClearButtonLink
            onClick={() => {
                dispatch(clearFn(false))
            }}
            id={id}
        >
            Clear
        </ClearButtonLink>
    )
}
