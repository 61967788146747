import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './_styled/data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/data-cell.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'

interface CardDataCellProps {
    label: string
    gridArea: string
    children?: ReactNode | ReactNodeArray
}
export function CardDataCell({label, children, gridArea}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `contact_soc-${label}`
    return (
        <Styled.CardDataCell
            gridArea={gridArea}
            titleOnSameLine={fixedWidthReducedText}
            id={`contact-soc-DataCell_${label}`}
        >
            <Styled.Label width={width} htmlFor={id} id={`contact-soc-Label_${label}`}>
                {label}
            </Styled.Label>
            <Styled.Children id={id}>{children}</Styled.Children>
        </Styled.CardDataCell>
    )
}
