import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {Location} from '../../../store/state/locations/state'
import {useVesselManagement} from '../contexts/use-vessel-management'
import {VesselListCards} from './vessel-list-cards/vessel-list-cards'
import {showCards} from './vessel-list-table/_styled/vessel-management-list-header.styled'
import {VesselListTable} from './vessel-list-table/vessel-list-table'

function compareRecord(a: Location, b: Location): number {
    return a.description.trim().localeCompare(b.description.trim())
}

export function DataPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {activeVesselId, filteredLocations, showFilterBar} = useVesselManagement()
    const showCardEarlier = activeVesselId !== null || showFilterBar

    return showCards(width, showCardEarlier) ? (
        <VesselListCards locations={filteredLocations.slice().sort(compareRecord)} />
    ) : (
        <VesselListTable locations={filteredLocations.slice().sort(compareRecord)} />
    )
}
