import {DataLoading} from '../../../../components/data-loading/data-loading'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {NoDataTable} from './no-data-table/no-data-table'
import {UnknownAssetsTablePopulated} from './unknown-assets-table-populated'

export function UnknownAssetsTableContent(): JSX.Element {
    const {loadingDataState, totalNumberOfUnknownAssets} = usePagedUnknownAssetsBeta()

    const showLoading =
        totalNumberOfUnknownAssets == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData

    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfUnknownAssets === 0) {
        return <NoDataTable text="No network assets found." />
    }
    return <UnknownAssetsTablePopulated />
}
