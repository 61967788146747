import * as Styled from './asset-details.styled'
import {NetworkAssetModalOutputModel} from '../../../../models/network-asset-modal.model'
import {DataCell} from '../shared/data-cell'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {networkAssetsModalDetailExpandedSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import {NetworkAssetsDetailType} from '../../../../../../store/state/network-assets-filter/state'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'

interface AssetDetailsProps {
    model: NetworkAssetModalOutputModel
}

export function AssetDetails({model}: AssetDetailsProps): JSX.Element {
    const detailExpanded = useTypedSelector(networkAssetsModalDetailExpandedSelector)

    return (
        <>
            <ExpandPanelButton headingRow={NetworkAssetsDetailType.AssetDetailsState} />

            {detailExpanded.detailsExpanded && (
                <Styled.AssetDetailsAreaWrapper id="network-asset-details-modal_asset-details_wrapper">
                    <Styled.AssetDetailsAreaContent id="network-asset-details-modal_asset-details_content">
                        <DataCell label="Network:">{model.network}</DataCell>
                        <DataCell label="Vendor:">{model.vendor}</DataCell>
                        <DataCell label="VLAN:">{model.vlan}</DataCell>
                        <DataCell label="MAC Address:">{model.macAddress}</DataCell>
                        <DataCell label="First detected:">{model.firstDetected}</DataCell>
                        <DataCell label="Last active:">{model.lastActive}</DataCell>
                    </Styled.AssetDetailsAreaContent>
                </Styled.AssetDetailsAreaWrapper>
            )}
        </>
    )
}
