import {DataLoading} from '../../../components/data-loading/data-loading'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../store/state/metrics-filter-beta/selectors'
import LoadingState from '../../../values/loading-state-enum'
import {usePagedMetricsBeta} from '../contexts/use-paged-metrics-beta'
import {ContentWrapper} from '../metrics-page.styled'
import {DataActions} from './data-actions/data-actions'
import {MetricsTableViewFormat} from './metrics-table/metrics-table-view-format'
import {MetricsSummaries} from './metrics-summaries/metrics-summaries'
import {BackArrowDiv} from './back-arrow/back-arrow-div'

export function Content(): JSX.Element {
    const {loadingFilteredDataState, previousPage} = usePagedMetricsBeta()
    const {selectedViewScreenType} = useTypedSelector(metricsBetaFilterSelector)
    return (
        <ContentWrapper id="metrics-page_content-wrapper">
            <DataActions />
            {selectedViewScreenType === 'metrics' && (
                <BackArrowDiv viewScreenLevel={previousPage ?? 'framework'} />
            )}
            {loadingFilteredDataState === LoadingState.Loaded ? (
                selectedViewScreenType === 'Table' ? (
                    <MetricsTableViewFormat />
                ) : (
                    <MetricsSummaries />
                )
            ) : (
                <DataLoading />
            )}
        </ContentWrapper>
    )
}
