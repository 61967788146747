import {Filter} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import * as Styles from './button.styled'
export function ShowFilterButton(): JSX.Element {
    const {setValue: showFilter} = useToggle()
    const {width} = useDimensions()

    return (
        <Styles.Button
            onClick={(e) => {
                e.preventDefault()
                showFilter(true)
            }}
            id="show-filter-button"
        >
            <Filter height={16} width={16} />
            {width > 400 && <Styles.Text>{width > 550 ? 'Change Filter' : 'Filter'}</Styles.Text>}
        </Styles.Button>
    )
}
