import styled from 'styled-components'

interface TopSectionProps {
    expanded: boolean
}
export const TopSection = styled.div<TopSectionProps>`
    padding: 15px;
    background-color: ${(props) =>
        props.theme.dashboard.vesselList.vesselDetail.default.backgroundColor};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.expanded ? 0 : '6px')};
    border-bottom-right-radius: ${(props) => (props.expanded ? 0 : '6px')};
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0px 1px 2px ${(props) => (props.expanded ? '#00000033' : '#00000034')};
`
