import * as Styled from '../../../system-page-content.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataLastUpdated} from '../last-updated/data-last-updated'
import {RefreshButton} from '../refresh-button/refresh-button'
import moment from 'moment'

interface HeaderProp {
    text: string
    refreshFunction: () => void
    lastUpdate?: moment.Moment | null | undefined
}
export function Header({text, refreshFunction, lastUpdate}: HeaderProp): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.Header width={width}>
            <Styled.PageTitle width={width}>{text}</Styled.PageTitle>
            <Styled.UpdatedTimeArea width={width}>
                <RefreshButton refreshFunction={refreshFunction} />
                {lastUpdate && <DataLastUpdated lastUpdate={lastUpdate} />}
            </Styled.UpdatedTimeArea>
        </Styled.Header>
    )
}
