import {VesselsTableContent} from './vessels-table-content'

import {VesselsTableHeader} from './vessels-table-header'

export function VesselsTable(): JSX.Element {
    return (
        <>
            <VesselsTableHeader />
            <VesselsTableContent />
        </>
    )
}
