import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {vesselTagsSelector} from '../../../../../../store/state/vessel-tags/selectors'
import {useUserManagement} from '../../../../context/use-user-management'
import {Panel} from '../_styled/panel-section.styled'

import {LockImage} from '../../../shared/tag-limit/tag-limit.styled'
import LOCKED from '../../../../../../@assets/icons/locked.svg'
import UNLOCKED from '../../../../../../@assets/icons/unlocked.svg'
import * as Styled from './details-section.styled'
import {StrictMode} from '../../../../../../store/state/users/state'
import {TagWrapper} from '../../_styled/user-management-table-data.styled'
import {getTagName} from '../../../../../../utils/Utils'
import {DataCellReadOnly} from '../data-input/data-cell-read-only'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {UserManagementDetailType} from '../../../../context/type/user-management-state'

export function DetailsSectionReadOnly(): JSX.Element {
    const {displayUserDetails, detailExpanded} = useUserManagement()
    const existingTags = useTypedSelector(vesselTagsSelector)

    const locked =
        displayUserDetails.assignedTag && displayUserDetails.strictMode === StrictMode.Strict
    const unlocked =
        displayUserDetails.assignedTag &&
        displayUserDetails.strictMode === StrictMode.DefaultVisibility

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.UserDetails} />
            {detailExpanded.detailsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <DataCellReadOnly label="Name">
                            {displayUserDetails.username}
                        </DataCellReadOnly>
                        <DataCellReadOnly label="Email">
                            {displayUserDetails.email}
                        </DataCellReadOnly>
                        <DataCellReadOnly label="Tag">
                            <TagWrapper>
                                {locked && <LockImage src={LOCKED} />}
                                {unlocked && <LockImage src={UNLOCKED} />}
                                {getTagName(existingTags, displayUserDetails.assignedTag) ?? 'N/A'}
                            </TagWrapper>
                        </DataCellReadOnly>
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
