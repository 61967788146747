import {IndicatorMap} from '../../../values/IndicatorData'
import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'

export const indicatorsSelector = (state: AppState): IndicatorMap => state.indicators.indicators

export const isFetchingIndicatorsSelector = (state: AppState): boolean =>
    state.indicators.indicatorsLoadingState === LoadingState.NotPopulated ||
    state.indicators.indicatorsLoadingState === LoadingState.RequestingData

export const indicatorsLoadingStateSelector = (state: AppState): LoadingState =>
    state.indicators.indicatorsLoadingState
