import {useIncidentsPolicy} from '../../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../../contexts/metrics-policy/use-metrics-policy'
import {useEnforcementPolicy} from '../../contexts/enforcement-policy/use-enforcement-policy'
import {
    AllPolicyData,
    PolicyType,
} from '../../contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {AssignPolicyDialog} from '../shared/assign-policy-dialog/assign-policy-dialog'
import * as Styled from './_styled/user-management-table-data.styled'
import moment from 'moment'

interface TableRowProps {
    policy: AllPolicyData
    index: number
    totalNumberOfPolicy: number
}

export function TableRow({policy, index, totalNumberOfPolicy}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const {
        selectedPolicyId,
        showPolicyDetailModal,
        closePolicyDetailModal,
        showAssignPolicyDialog,
        assignPolicyDialogState,
    } = usePolicyManagement()
    const {findIncidentsPolicyDetails, setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {findMetricsPolicyDetails, setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {findEnforcementPolicyDetails, setDefaultEnforcementPolicyData} = useEnforcementPolicy()
    const selectedPolicy = selectedPolicyId === policy.identity

    function onClickWrapper(e?: React.MouseEvent<HTMLDivElement>) {
        e?.stopPropagation()

        if (selectedPolicy) {
            closePolicyDetailModal()
            setDefaultIncidentPolicyData()
            setDefaultMetricsPolicyData()
            setDefaultEnforcementPolicyData()
        } else {
            showPolicyDetails(policy)
        }
    }

    function showPolicyDetails(policy: AllPolicyData) {
        const {policyType, identity} = policy
        showPolicyDetailModal(policyType, identity)

        switch (policyType) {
            case PolicyType.INCIDENT:
                findIncidentsPolicyDetails(identity)
                break
            case PolicyType.METRICS:
                findMetricsPolicyDetails(identity)
                break
            case PolicyType.ENFORCEMENT:
                findEnforcementPolicyDetails(identity)
                break
            default:
                break
        }
    }
    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-policy-management-row_${policy.identity}`}
                selectedPolicy={selectedPolicy}
                onClick={onClickWrapper}
            />
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`policy-management-type${policy.identity}`}
                onClick={onClickWrapper}
            >
                {policy.policyType}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`policy-management-name${policy.identity}`}
                onClick={onClickWrapper}
            >
                {policy.policyName}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`policy-management-vessels_${policy.identity}`}
                style={{position: 'relative', overflow: 'visible'}}
            >
                <div onClick={onClickWrapper}>
                    <Styled.NumberOfVessels
                        onClick={(e: React.MouseEvent<unknown>) => {
                            e.stopPropagation()
                            policy.policyType !== PolicyType.INCIDENT &&
                                showAssignPolicyDialog(
                                    policy.identity,
                                    policy.locations,
                                    policy.policyType,
                                )
                        }}
                        isActive={policy.policyType !== PolicyType.INCIDENT}
                    >
                        {policy.locations?.length ?? 0}
                    </Styled.NumberOfVessels>
                </div>
                {policy.identity === assignPolicyDialogState.selectedPolicyId && (
                    <AssignPolicyDialog
                        index={index}
                        totalNumberOfPolicy={totalNumberOfPolicy}
                        policy={policy}
                    />
                )}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`user-managements-updated_${policy.identity}`}
                onClick={onClickWrapper}
            >
                {moment(policy.lastUpdate).fromNow()}
            </Styled.TableDataCell>
        </>
    )
}
