import {Check} from 'react-feather'
import {X} from 'react-feather'
import {DataCell} from './data-cell'
import {Input, WarningMessage, Wrapper} from './data-cell.styled'

interface DataInputProps {
    label: string
    gridArea?: string
    initialValue: string | string[] | number
    setChange: (value: string) => void
    changed: boolean
    blocked?: boolean
}

export function DataInputEmail({
    label,
    gridArea,
    initialValue,
    setChange,
    changed,
    blocked,
}: DataInputProps): JSX.Element {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setChange(e.target.value)
    }
    const showChanged = changed && !blocked
    const showBlocked = changed && blocked

    return (
        <DataCell label={label} gridArea={gridArea}>
            <div>
                <Wrapper>
                    <Input value={initialValue} onChange={onChangeHandler} isChanged={changed} />
                    {showChanged && <Check color={'green'} width={19} height={19} />}
                    {showBlocked && <X color={'red'} width={19} height={19} />}
                </Wrapper>
                {showBlocked && (
                    <WarningMessage>This email is already used by an existing user</WarningMessage>
                )}
            </div>
        </DataCell>
    )
}
