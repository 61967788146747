import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface TableDataRowProps {
    gridRow: number
    span?: number
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.userManagement.dataRow.text};
    background-color: ${(props) => props.theme.userManagement.dataRow.background};
    height: 75px;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.userManagement.dataRow.dropShadow};
`
interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
    overFlowVisible?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    text-overflow: ellipsis;
    overflow: ${(props) => (props.overFlowVisible ? 'visible' : 'hidden')};
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`
