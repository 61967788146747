import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getMargin(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '10px 0 15px 0'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '10px 0 20px 0'
        default:
            return '12px 0 21px 0'
    }
}

interface DataProps {
    gridLayout: GridLayout
}

export const Data = styled.div<DataProps>`
    margin: ${(props) => getMargin(props.gridLayout)};
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.24px;
`
