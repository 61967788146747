import {AssetDetails} from '../../../../../components/asset-details/asset-details'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {filteredNodeIdsForLocationCachedReselector} from '../../reselectors/filtered-node-ids-for-location-cached-reselector'
import * as Styles from './asset-details-grid.styled'
interface Props {
    id: LocationIdType
}
export function AssetDetailsGrid({id}: Props): JSX.Element {
    const ids = useTypedSelector((state) => filteredNodeIdsForLocationCachedReselector(state, id))

    return (
        <Styles.Grid id={`assets-grid-${id}`}>
            {ids &&
                ids.map((assetId) => (
                    <Styles.Cell key={`asset-details-cell-${id}-${assetId}`}>
                        <AssetDetails id={assetId} />
                    </Styles.Cell>
                ))}
        </Styles.Grid>
    )
}
