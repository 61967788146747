import {useDispatch} from 'react-redux'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'

import {watchListSelector} from '../../../../../store/state/watched-nodes/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    addNodeToWatchList,
    deleteNodeFromWatchList,
} from '../../../../../store/state/watched-nodes/action-creators'
import {Button} from './button.styled'
import {Text} from './text.styled'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {Dispatch} from 'react'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {NodeData} from '../../../../../values/nodes/NodeData'

interface ClickableButtonProps {
    assetId: GuidType
}

export function watchAsset(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    node: NodeData | undefined,
): void {
    if (node) {
        dispatch(addNodeToWatchList(node.node))
    }
}

export function unwatchAsset(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    node: NodeData | undefined,
): void {
    if (node) {
        dispatch(deleteNodeFromWatchList(node.node))
    }
}

export function ClickableButton({assetId}: ClickableButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const isIncludedInWatchList = useTypedSelector(watchListSelector)?.includes(assetId)
    const action = isIncludedInWatchList ? 'Unwatch' : 'Watch'
    const asset = useTypedSelector(nodesSelector)?.get(assetId)
    return (
        <Button
            id="watch-asset-button"
            clickable
            color="link"
            onClick={() => {
                isIncludedInWatchList ? unwatchAsset(dispatch, asset) : watchAsset(dispatch, asset)
            }}
        >
            <Icon glyph={action} height={16} width={16} />
            <Text>{action}</Text>
        </Button>
    )
}
