import {USBDevicesResponse} from '../types/usb-devices-response'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {GuidType} from '../../../../values/generic-type-defintions'
import {DeviceStatus} from '../../../../store/state/usb-inventory-filter/state'
import {UsbDeviceDetailsOutputModel} from '../../reselector/usb-device-details.model'
import {TagAdaptor} from '../types/paged-usb-devices-state'

export function setSelectedPage(newSelectedPage: number): Actions.SetSelectedPageAction {
    return {type: ActionType.SET_SELECTED_PAGE, payload: newSelectedPage}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function displayUsbDetailsModal(UsbModalId: GuidType): Actions.DisplayUsbDetailsModalAction {
    return {
        type: ActionType.DISPLAY_USB_DETAILS_MODAL,
        payload: UsbModalId,
    }
}

export function setReportCreate(payload: string): Actions.ReportCreateAction {
    return {
        type: ActionType.REPORT_CREATE,
        payload,
    }
}

export function usbDeviceStatusSet(
    usbDevice: USBDevicesResponse,
    status: DeviceStatus,
): Actions.UsbDeviceStatusSet {
    return {
        type: ActionType.USB_DEVICE_STATE_CHANGE,
        payload: {
            status,
            usbDevice,
        },
    }
}

export function receivedRequestedPageData(
    usbDevices: USBDevicesResponse[],
    totalNumberOfItems: number,
    totalNumberOfPages: number,
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {
            usbDevices,
            totalNumberOfItems,
            totalNumberOfPages,
        },
    }
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function setIdUSBDetailsModal(
    modalIdentity: GuidType | null,
): Actions.SetIdUSBDetailsModalAction {
    return {
        type: ActionType.SET_ID_USB_DETAILS_MODAL,
        payload: modalIdentity,
    }
}

export function setFilter(payload: TagAdaptor): Actions.SetFilterAction {
    return {
        type: ActionType.SET_FILTER,
        payload,
    }
}

export function setFindUsbDetails(
    value: USBDevicesResponse | undefined,
): Actions.ReceivedRequestedUsbDetails {
    return {
        type: ActionType.RECEIVED_REQUESTED_USB_DETAILS,
        payload: value,
    }
}

export function setUsbDetailsOutput(
    value: UsbDeviceDetailsOutputModel,
): Actions.SetUsbDetailsOutputAction {
    return {
        type: ActionType.SET_USB_DETAILS_OUTPUT,
        payload: value,
    }
}

export function displaySetPurposeModal(value: boolean): Actions.DisplaySetPurposeModalAction {
    return {
        type: ActionType.DISPLAY_SET_PURPOSE_MODAL,
        payload: value,
    }
}

export function showGenerateReportPopup(show: boolean): Actions.ShowGenerateReportPopup {
    return {
        type: ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP,
        payload: show,
    }
}
