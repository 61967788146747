import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {userSubscriptionsSelector} from '../../../../../../store/state/saved-filters/selectors'
import {useUserManagement} from '../../../../context/use-user-management'
import {DataCellReadOnly} from '../data-input/data-cell-read-only'
import {SavedFilterAlertRowReadOnly} from './saved-filter-alert-row-read-only'
import * as Styled from './saved-filters-alerts.styled'

export function SavedFiltersAlertsContentReadOnly(): JSX.Element {
    const {displayUserDetails} = useUserManagement()
    const activeUserSubscriptions = useTypedSelector(userSubscriptionsSelector)
    const noSubscriptions = !activeUserSubscriptions || activeUserSubscriptions?.length === 0

    return (
        <Styled.DetailsLayout>
            <Styled.Details>
                <DataCellReadOnly
                    label="Email for Alerts"
                    gridArea="EMAIL"
                    width={{label: 100, input: 190}}
                >
                    {displayUserDetails.notificationEmail}
                </DataCellReadOnly>
            </Styled.Details>
            <Styled.ScrollableWrapper noData={noSubscriptions}>
                {noSubscriptions ? (
                    <Styled.NoSavedFiltersAlerts>
                        No Saved Filters Alerts
                    </Styled.NoSavedFiltersAlerts>
                ) : (
                    <>
                        {activeUserSubscriptions.map((data, index) => (
                            <SavedFilterAlertRowReadOnly key={index} subscriptionData={data} />
                        ))}
                    </>
                )}
            </Styled.ScrollableWrapper>
        </Styled.DetailsLayout>
    )
}
