import {useDispatch} from 'react-redux'
import {Button} from '../save-filter-popup/save-filter-popup.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'
import {createNewUserSubscription} from '../../../../store/state/saved-filters/action-creators'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {isEmailValid} from '../../../../utils/Utils'

interface Props {
    model: NotifyMeOutputModel
    text: string
}

export function CreateAlertButton({model, text}: Props): JSX.Element {
    const dispatch = useDispatch()
    const newSubscription = useTypedSelector(newSubscriptionSelector)

    const emailValidation =
        newSubscription.notificationEmail?.length == 0
            ? true
            : isEmailValid(newSubscription.notificationEmail)
    const hasSchedule = newSubscription.schedule !== undefined
    const isSavable = hasSchedule && emailValidation

    const onClickFn = () => {
        model.templateDefinitionId &&
            dispatch(
                createNewUserSubscription({
                    ...newSubscription,
                    notificationEmail:
                        newSubscription.notificationEmail === ''
                            ? undefined
                            : newSubscription.notificationEmail,
                    userId: model.userId,
                    searchDefinitionId: model.currentFilter.identity,
                    templateDefinitionId: model.templateDefinitionId,
                }),
            )
    }

    return (
        <Button id="create-or-update-alert-button" disabled={!isSavable} onClick={onClickFn}>
            {text}
        </Button>
    )
}
