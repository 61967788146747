import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const AssetDetailsAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
`

export const AssetDetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: ${getColumnTemplate()};
    grid-template-areas: ${getGridTemplate()};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`

export const AssetBehaviourAreaContent = styled.div`
    padding: ${spacing(2)} 0;
`

export const DescriptionText = styled.div`
    padding: 0 ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const BehaviourList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: ${spacing(1)};
`

function getGridTemplate(): string {
    return "'VLAN VLAN MAC-ADDRESS MAC-ADDRESS' 'FIRST-DETECTED FIRST-DETECTED LAST-ACTIVE LAST-ACTIVE'"
}

function getColumnTemplate(): string {
    return 'repeat(2,1fr);'
}
