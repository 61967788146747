import * as Styled from './behaviour-status.styled'
import {useState} from 'react'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {formatDateWithoutTime} from '../../../../../helpers/formatting'

interface BehaviourStatusProps {
    displayName: string
    cardView?: boolean
}

export function BehaviourStatus({displayName, cardView}: BehaviourStatusProps): JSX.Element {
    const [isHovering, setIsHovering] = useState(false)
    const {networkAssetBehaviourData} = usePagedUnknownAssetsBeta()

    const currentData = networkAssetBehaviourData?.find(
        (data) => data.behaviour.displayName === displayName,
    )
    const hoveringDate = isHovering && currentData !== undefined
    return (
        <div style={{position: 'relative'}}>
            <Styled.BehaviourStatusWrapper
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                popup={hoveringDate}
                id={`network-asset-details-modal_asset-insights_behaviour-status_${displayName}`}
            >
                {displayName}
            </Styled.BehaviourStatusWrapper>
            {hoveringDate && (
                <Styled.PopupContainer
                    cardView={cardView}
                    id={`network-asset-details-modal_asset-insights_last-detected_${displayName}-${currentData?.lastSeen}`}
                >
                    Last detected: {formatDateWithoutTime(currentData?.lastSeen)}
                </Styled.PopupContainer>
            )}
        </div>
    )
}
