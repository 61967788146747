import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {User} from '../../../../store/state/users/state'
import * as Styled from './_styled/card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/card-data-cell.styled'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    user: User
    identifier: string
}
export function CardDataCell({
    label,
    children,
    gridArea,
    user,
    identifier,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `user-management-${identifier}_${user.user}`
    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            <Styled.Label htmlFor={id}>{label}</Styled.Label>
            <Styled.Children id={id} gridArea={gridArea} width={width}>
                {children}
            </Styled.Children>
        </Styled.CardDataCell>
    )
}
