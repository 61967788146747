import moment from 'moment'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {getVesselForGivenAsset} from '../../../contexts/context-helper'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {VesselDetails} from './vessel-details'

interface ActivityContentsProps {
    cardView?: boolean
}

export function ActivityContents({cardView}: ActivityContentsProps): JSX.Element {
    const nodes = useTypedSelector(nodesSelector)
    const {findSoftwareDetailsForModal} = usePagedSoftwareInventory()

    const nodeFirstDetected = getVesselForGivenAsset(
        findSoftwareDetailsForModal?.firstDetected,
        nodes,
    )
    const firstDetectedFooterValue = findSoftwareDetailsForModal?.firstDetected?.firstSeen
        ? moment(findSoftwareDetailsForModal.firstDetected.firstSeen).fromNow()
        : null

    const nodeMostRecent = getVesselForGivenAsset(findSoftwareDetailsForModal?.lastDetected, nodes)
    const mostRecentUsedFooterValue = findSoftwareDetailsForModal?.lastDetected
        ? moment(findSoftwareDetailsForModal.lastDetected.firstSeen).fromNow()
        : null
    const nodesOldestVersion = getVesselForGivenAsset(
        findSoftwareDetailsForModal?.oldestVersion,
        nodes,
    )
    const oldestVersion = findSoftwareDetailsForModal?.oldestVersion?.version ?? null

    return (
        <div
            style={{
                width: cardView ? '100%' : '320px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
            }}
            id="software-inventory-details-modal_activity-content_wrapper"
        >
            {findSoftwareDetailsForModal && nodeFirstDetected && (
                <VesselDetails
                    locationId={nodeFirstDetected.location}
                    associatedAsset={nodeFirstDetected}
                    assetVersion={findSoftwareDetailsForModal?.firstDetected}
                    title="Asset where this software was first detected:"
                    footerPrefix="First detected:"
                    footerValue={firstDetectedFooterValue}
                />
            )}
            {findSoftwareDetailsForModal && nodeMostRecent && (
                <VesselDetails
                    locationId={nodeMostRecent.location}
                    associatedAsset={nodeMostRecent}
                    assetVersion={findSoftwareDetailsForModal?.lastDetected}
                    title="Asset where this software was most recently used:"
                    footerPrefix="Last active:"
                    footerValue={mostRecentUsedFooterValue}
                />
            )}
            {findSoftwareDetailsForModal && nodesOldestVersion && (
                <VesselDetails
                    locationId={nodesOldestVersion.location}
                    associatedAsset={nodesOldestVersion}
                    assetVersion={findSoftwareDetailsForModal?.oldestVersion}
                    title="Asset running the oldest version of this software:"
                    footerPrefix="Oldest Version:"
                    footerValue={oldestVersion}
                />
            )}
        </div>
    )
}
