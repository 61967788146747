import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const Text = styled.div`
    background: none;
    border: none;
    padding: 7px 13px;
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;
    min-width: 110px;
    text-align: center;
`
export const Actions = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`
