import {getFormattedNMEAeData} from '../../../../contexts/types/nmea-inventory-response'
import {usePagedNmeaInventory} from '../../../../contexts/use-paged-nmea-inventory'
import {DataCell} from '../data-cell/data-cell'
import * as Styled from './usb-details.styled'

export function NmeaDetailedSection(): JSX.Element | null {
    const {findNmeaDetailsForModal} = usePagedNmeaInventory()
    if (!findNmeaDetailsForModal) {
        return null
    }
    const formattedModalDetails = getFormattedNMEAeData(findNmeaDetailsForModal)
    return (
        <Styled.DetailsAreaWrapper id="nmea-details-modal_detailed-section_wrapper">
            <Styled.DetailsAreaContent id="nmea-details-modal_detailed-section_content">
                <DataCell label="NMEA Talker Code:">{formattedModalDetails?.talkerId}</DataCell>
                <DataCell label="Relay IP:">{formattedModalDetails?.relayIp}</DataCell>
                <DataCell label="Broadcast:">{formattedModalDetails?.receiver}</DataCell>
                <DataCell label="First detected:">
                    {formattedModalDetails?.firstSeenTimestamp}
                </DataCell>
                <DataCell label="Last active:">{formattedModalDetails?.lastSeenTimestamp}</DataCell>
            </Styled.DetailsAreaContent>
        </Styled.DetailsAreaWrapper>
    )
}
