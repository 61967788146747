import {removeQueryParam} from '../../../../../../helpers/navigation'
import * as Styled from './submit-area.styled'

export function CloseButton(): JSX.Element {
    function closeModal() {
        removeQueryParam('editAsset')
    }

    return (
        <Styled.ButtonStyled id="close-button" onClick={closeModal}>
            Close
        </Styled.ButtonStyled>
    )
}
