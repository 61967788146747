import {ReactNode, useReducer} from 'react'
import {PagedSoftwareInventoryContext} from './paged-software-inventory-context'
import {pagedSoftwareInventoryReducer} from './state/reducer'
import {DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE} from './types/default-paged-software-inventory-state'
import {PagedSoftwareInventoryState} from './types/paged-software-inventory-state'
import {cloneDeep} from 'lodash'

type PagedSoftwareInventoryProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedSoftwareInventoryProvider({
    children,
    pageSize,
}: PagedSoftwareInventoryProviderProps): JSX.Element {
    const initialState: PagedSoftwareInventoryState = cloneDeep(
        DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE,
    )
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedSoftwareInventoryReducer, initialState)

    return (
        <PagedSoftwareInventoryContext.Provider value={{state, dispatch}}>
            {children}
        </PagedSoftwareInventoryContext.Provider>
    )
}
