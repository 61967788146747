import {X} from 'react-feather'
import {Check} from 'react-feather'
import * as Styled from './notify-me-popup.styled'
import {useState} from 'react'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'
import {setChangeAlertEmail} from '../../../../store/state/saved-filters/action-creators'
import {useDispatch} from 'react-redux'
import {isEmailValid} from '../../../../utils/Utils'

interface AddCustomEmailProps {
    model: NotifyMeOutputModel
}

export function AddCustomEmail({model}: AddCustomEmailProps): JSX.Element {
    const dispatch = useDispatch()
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const [addEmail, setAddEmail] = useState(false)

    const [placeholder, setPlaceholder] = useState(
        model.currentSubscription?.notificationEmail ?? 'Add custom email',
    )
    const [emailValue, setEmailValue] = useState(
        model.currentSubscription?.notificationEmail ?? newSubscription.notificationEmail,
    )
    function onEmailChangeHandler(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault()
        setEmailValue(e.target.value)
        setPlaceholder(e.target.value === '' ? 'Add custom email' : e.target.value)
        dispatch(setChangeAlertEmail(e.target.value))
    }
    function onXClick(): void {
        setPlaceholder('Add custom email')
        setEmailValue('')
        dispatch(setChangeAlertEmail(''))
    }

    const showGuide = !model.currentSubscription?.notificationEmail
        ? true
        : model.currentSubscription?.notificationEmail === model.userEmail

    const isValidEmail =
        newSubscription.notificationEmail?.length !== 0 &&
        isEmailValid(newSubscription.notificationEmail)

    const hasValueTyped =
        newSubscription.notificationEmail !== undefined &&
        newSubscription.notificationEmail.length !== 0

    const showXIcon = placeholder !== 'Add custom email' || hasValueTyped

    const showCheckIcon = !model.currentSubscription?.notificationEmail
        ? newSubscription.notificationEmail !== undefined &&
          newSubscription.notificationEmail.length !== 0 &&
          isValidEmail
        : emailValue === ''
          ? true
          : model.currentSubscription?.notificationEmail !== emailValue && isValidEmail

    return (
        <>
            {!addEmail && showGuide ? (
                <>
                    <Styled.AddPrefix onClick={() => setAddEmail(!addEmail)} id="add-custom-email">
                        Add custom email
                    </Styled.AddPrefix>
                    <Styled.PrefixDetail id="notificationEmail">
                        By default, we will send alerts to {model.notificationEmail}
                    </Styled.PrefixDetail>
                </>
            ) : (
                <Styled.InputWrapper>
                    <Styled.InputField>
                        <Styled.Input
                            placeholder={placeholder}
                            value={emailValue}
                            onChange={(e) => onEmailChangeHandler(e)}
                            id="email-change-input"
                        />
                        {showXIcon && (
                            <Styled.XIcon onClick={() => onXClick()} id="x-click-button">
                                <X height={15} width={15} />
                            </Styled.XIcon>
                        )}
                    </Styled.InputField>
                    {showCheckIcon && (
                        <Check
                            color="green"
                            width={19}
                            height={19}
                            style={{position: 'absolute', right: '30px', paddingLeft: '2px'}}
                        />
                    )}
                </Styled.InputWrapper>
            )}
        </>
    )
}
