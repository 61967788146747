import {CloseMonitorIncident} from './close-monitor-incident'
import {Overlay} from './modal-overlay'

export function CloseMonitorIncidentModal(): JSX.Element {
    return (
        <Overlay>
            <CloseMonitorIncident />
        </Overlay>
    )
}
