import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {getBorder} from './incident-details.styled'
interface IncidentDetailsProps {
    incidentStatus: string
}
export const Card = styled.div<IncidentDetailsProps>`
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) => props.theme.incidents.dataRow.background};
    border-left: ${(props) => getBorder(props.incidentStatus, props.theme)};
    margin-bottom: ${spacing(2)};
    width: 100%;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${mediumSmallFont()}
`
