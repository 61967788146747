import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LatestLocationThreatScores} from '../../../../../store/state/latest-location-threat-scores/state'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {Location} from '../../../../../store/state/locations/state'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {latestLocationThreatScoreSelector} from '../../../../../store/state/latest-location-threat-scores/selectors'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {getLocationScore} from '../../../../../store/state/locations/helpers'
import {NodeValues} from '../../../../../values/nodes/NodeData'
import {threatMeasuresSelector} from '../../../../../store/state/threat-measures/selectors'
import {ThreatMeasure} from '../../../../../values/ThreatMeasure'

interface VesselsCountProps {
    gridLayout: GridLayout
}

function isAtRisk(
    locationId: GuidType,
    latestLocationScores: LatestLocationThreatScores,
    threatMeasure: ThreatMeasure | undefined,
): boolean {
    if (!threatMeasure) {
        return false
    }

    return getLocationScore(locationId, latestLocationScores) >= threatMeasure.lowerThreshold
}

export function VesselsAtRiskCount({gridLayout}: VesselsCountProps): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const latestLocationThreatScores = useTypedSelector(latestLocationThreatScoreSelector)
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)

    function atRiskCountReducer(count: number, location: Location): number {
        return isAtRisk(location.location, latestLocationThreatScores, threatMeasure)
            ? count + 1
            : count
    }

    return (
        <ValueForType
            type="Vessels at Risk"
            value={locations.reduce(atRiskCountReducer, 0)}
            gridLayout={gridLayout}
            id="vessels-at-risk-vessels-risk-count"
        />
    )
}
