import {ClearButtonLink} from './select-button.styled'

interface SelectButtonProps {
    clearFn: (value: boolean) => void
    label: string
    disabled: boolean
}

export function SelectButton({clearFn, label, disabled}: SelectButtonProps): JSX.Element {
    return (
        <ClearButtonLink
            onClick={() => {
                clearFn(false)
            }}
            disabled={disabled}
            id={`select-button_${label}`}
        >
            {label}
        </ClearButtonLink>
    )
}
