import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './_styled/card-data-cell.styled'
import {CustomerReports} from '../../../../values/customer-reports'
import {BREAK_POINT_CARD_REDUCED_TEXT} from '../data-helpers'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea?: string
    report: CustomerReports
    identifier: string
}
export function CardDataCell({
    label,
    children,
    gridArea,
    report,
    identifier,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `report-${identifier}_${report.identity}`
    return (
        <Styles.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            {label && (
                <Styles.Label width={width} htmlFor={id} id={`${label}-${id}`}>
                    {label}
                </Styles.Label>
            )}
            <Styles.Children id={`${children}-${id}`}>{children}</Styles.Children>
        </Styles.CardDataCell>
    )
}
