import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const Table = styled.div`
    display: flex;
    column-gap: 15px;
    justify-content: center;
    align-items: center;
`

export const VesselRow = styled.div`
    display: flex;
    gap: ${spacing(2)};
    padding-top: 20px;
    justify-content: space-between;
`

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) => props.theme.colors.text.default};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
`

export const VesselLabel = styled(Label)`
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const ScoreLabel = styled.div`
    display: flex;
    min-width: 175px;
    align-self: center;
    justify-content: flex-end;
    ${mediumSmallFont()}
`
export const Image = styled.img`
    width: 30px;
    height: 20px;
`
interface SectionProps {
    showFilterBar: boolean
    width: number
}
export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => getsectionWidth(props.showFilterBar, props.width)}px;
    ${mediumSmallFont()}
`
export const TitleWrapper = styled.div`
    display: flex;
    column-gap: 15px;
    padding: 20px 0;
    font-size: 20px;
    align-items: center;
`
function getsectionWidth(showFilterBar: boolean, width: number): number {
    return showFilterBar ? 350 : width > 540 ? 420 : 300
}
