import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './card-data-cell.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    identifier: string
    onClickFn?: () => void
}
export function CardDataCell({
    label,
    children,
    gridArea,
    identifier,
    onClickFn,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={false}>
            <Styled.Label width={width} htmlFor={identifier} id={`label-${identifier}`}>
                {label}
            </Styled.Label>
            <div style={{wordBreak: 'break-word'}} id={identifier} onClick={onClickFn}>
                {children}
            </div>
        </Styled.CardDataCell>
    )
}
