import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const FilterComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${spacing(1)};
    height: 50px;
    background-color: ${(props) => props.theme.colors.background.default};
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 0 15px;
    border: 1px solid #e2e5e9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`

export const FilterComponentWrapper = styled.div`
    position: relative;
`
export const Select = styled.select`
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 5px 0;
    ${smallFont()}
    border: 1px solid #1f88e5;
    border-radius: 5px;
    background: #f7f8f8;
    height: 31px;
`
export const Label = styled.label`
    ${smallFont()}
`
