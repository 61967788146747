import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {notesFont} from '../notes/incident-note.styled'

export const Icon = styled.img`
    height: 12px;
    width: 12px;
`

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StatusChangeWrapper = styled.div`
    padding-bottom: ${spacing(1)};
    padding-top: ${spacing(1)};
`

export const When = styled.div`
    ${notesFont()}
    padding-left: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const Who = styled.div`
    ${notesFont()}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    max-width: 150px;
`

export const NewSquare = styled.span`
    display: flex;
    background-color: ${(props) => props.theme.incidents.newRecordLabel.background};
    color: ${(props) => props.theme.incidents.newRecordLabel.text};
    height: 8px;
    width: ${spacing(5)};
    border-radius: 4px;
    margin-left: ${spacing(1)};
    justify-content: center;
    font-size: 6px;
    line-height: 8px;
`

export const UpdateMessage = styled.div`
    ${notesFont()};
    margin-top: ${spacing(1)};
    display: flex;
    flex-direction: row;
`

export const Status = styled.div`
    ${notesFont()}
    padding-left: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-style: italic;
`
