import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'

export const Vessels = styled.div`
    height: 205px;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
