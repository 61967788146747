import moment from 'moment'
import {StringUtils} from '../../../../../utils/Utils'
import {IncidentLinkType} from '../../../contexts/types/incident-link'
import {LinkedItemData} from '../../../contexts/types/incident-linked-items'
import {getImageForlinkedItemState} from '../../../data-helpers'
import * as Styled from './item-detail-popup.styled'

interface ItemDetailPopupProps {
    item: LinkedItemData
    lastRow: boolean
}

function getFormattedItemType(itemType: IncidentLinkType): string {
    switch (itemType) {
        case IncidentLinkType.NETWORK_ASSET:
            return 'Network Asset'
        case IncidentLinkType.USB:
            return 'USB'
        case IncidentLinkType.SOFTWARE:
            return 'Software'
    }
}

export function ItemDetailPopup({item, lastRow}: ItemDetailPopupProps): JSX.Element {
    return (
        <Styled.PopupContainer
            lastRow={lastRow}
            id={`ItemDetailPopup-${item.incidentLinkIdentity}`}
        >
            <Styled.TitleRow>
                <img src={getImageForlinkedItemState(item.state)} style={{width: '13px'}} />
                <Styled.Title id={`ItemDetailPopup-state-${item.incidentLinkIdentity}`}>
                    {StringUtils.capitaliseString(item.state)}
                </Styled.Title>
                <Styled.Title id={`ItemDetailPopup-itemType-${item.incidentLinkIdentity}`}>
                    {getFormattedItemType(item.itemType)}
                </Styled.Title>
            </Styled.TitleRow>
            <Styled.TimestampRow>
                Last seen:
                <Styled.Timestamp id={`ItemDetailPopup-lastSeen-${item.incidentLinkIdentity}`}>
                    {moment(item.lastSeen).fromNow()}
                </Styled.Timestamp>
            </Styled.TimestampRow>
        </Styled.PopupContainer>
    )
}
