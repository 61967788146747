import {ItemRow} from './item-row'
import {ItemListWrapper} from './items-list.styled'
import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'

export function WatchedIncidentItemsList(): JSX.Element {
    const {linkedItemsData, isFetchingItems} = usePagedIncidents()
    const watchedItemsData = linkedItemsData.filter((item) => item.monitored === true)
    const scrollable = watchedItemsData?.length > 3

    return (
        <>
            {isFetchingItems ? (
                <DataLoading size={40} />
            ) : (
                <ItemListWrapper scrollable={scrollable} id="watched-incident-ItemsList">
                    {watchedItemsData.map((item, index) => (
                        <ItemRow
                            key={index}
                            item={item}
                            lastRow={
                                scrollable
                                    ? index === linkedItemsData?.length - 1 ||
                                      index === linkedItemsData?.length - 2
                                    : false
                            }
                            link
                        />
                    ))}
                </ItemListWrapper>
            )}
        </>
    )
}
