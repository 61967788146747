import {Button} from './close-filter-button.styled'
import {XCircle} from 'react-feather'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
interface Props {
    text?: string
}
export function CloseFilterButton({text}: Props): JSX.Element {
    const {displayFilterBar} = usePagedIncidents()

    return (
        <Button onClick={() => displayFilterBar(false)} id="close-filter-button">
            <XCircle />
            {text}
        </Button>
    )
}
