import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentHistory} from '../contexts/types/incident-history'
import {IncidentSeverityValue} from '../contexts/types/incident-severity-types'

export interface SeverityChangeOutputModel {
    id: GuidType
    username: string
    oldSeverity: IncidentSeverityValue
    newSeverity: IncidentSeverityValue
    when: string
    isViewed: boolean
}

export function wasSeverityUpdated(record: IncidentHistory): boolean {
    return record.oldSeverity != null && record.newSeverity != null
}
