import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import {ScoreBar} from './score-bar'
import * as Styled from './_styled/summary-score-card.styled'
import {calculateScoreCardScore} from '../../../../metrics-beta/contexts/context-data-helper'
import {AssetStatusType} from '../../../../metrics-beta/contexts/types/metrics-summary'
import {SectionText, StatusFilterContainer} from './widget-commonstyling.styled'

export function SummaryScoreCard(): JSX.Element {
    const {metricsWidgetData} = useMetricsWidget()
    const assetStatusProtection = metricsWidgetData?.get(MetricType.PROTECTION)?.assetStatus
    const assetStatusMaintenance = metricsWidgetData?.get(MetricType.MAINTENANCE)?.assetStatus
    const assetStatusBehaviour = metricsWidgetData?.get(MetricType.BEHAVIOUR)?.assetStatus
    const scoreProtection = calculateScoreCardScore(
        assetStatusProtection ?? ({} as AssetStatusType),
    )
    const scoreMaintenance = calculateScoreCardScore(
        assetStatusMaintenance ?? ({} as AssetStatusType),
    )
    const scoreBehaviour = calculateScoreCardScore(assetStatusBehaviour ?? ({} as AssetStatusType))
    return (
        <StatusFilterContainer>
            <SectionText>METRICS SCORECARD SUMMARY</SectionText>
            <Styled.SummaryScoreCardWrapper id="summary-scoreCard-wrapper">
                <ScoreBar
                    displayGraph={assetStatusProtection !== undefined}
                    score={scoreProtection ?? 0}
                    metricType={MetricType.PROTECTION}
                />
                <ScoreBar
                    displayGraph={assetStatusMaintenance !== undefined}
                    score={scoreMaintenance ?? 0}
                    metricType={MetricType.MAINTENANCE}
                />
                <ScoreBar
                    displayGraph={assetStatusBehaviour !== undefined}
                    score={scoreBehaviour ?? 0}
                    metricType={MetricType.BEHAVIOUR}
                />
            </Styled.SummaryScoreCardWrapper>
        </StatusFilterContainer>
    )
}
