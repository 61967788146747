import * as Styled from './_styled/self-monitoring-cards-populated.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {MonaEngineModeCard} from './mona-engine-mode-card'
import {dataSelector} from '../../../../../store/state/mona-engine-modes/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'

export function MonaEngineModeCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const data = useTypedSelector(dataSelector)

    return (
        <Styled.CardArea width={width}>
            {data &&
                data.map((monaEngineMode) => (
                    <MonaEngineModeCard
                        key={`${monaEngineMode.anomalyProcessingMode}`}
                        monaEngineMode={monaEngineMode}
                    />
                ))}
        </Styled.CardArea>
    )
}
