import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)};
    padding-bottom: 0;
    margin: 0;
    width: 100%;
`

export const Details = styled.div`
    padding: ${spacing(1)} 0;
    margin: ${spacing(2)};
`

export const Label = styled.label`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: ${spacing(1)} 0;
`

interface ContainerProps {
    disabled?: boolean
}

export const Panel = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(1)};
    align-items: center;
    ${smallFont()}
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
