import {TableHeaderCell} from '../../common/table/table-header-cell.styled'

function TableHeader(): JSX.Element {
    return (
        <>
            <TableHeaderCell gridColumn={2}>Timestamp</TableHeaderCell>
            <TableHeaderCell gridColumn={3}>Threat Score</TableHeaderCell>
            <TableHeaderCell gridColumn={4}>Event Type</TableHeaderCell>
            <TableHeaderCell gridColumn={5}>Anomaly Type</TableHeaderCell>
            <TableHeaderCell gridColumn={6}>Details</TableHeaderCell>
        </>
    )
}

export default TableHeader
