import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'
import {narrowLayoutFn} from '../../incidents-report-page.styled'
import * as Styled from './incidents-count.styled'

interface IncidentsCountProps {
    incidentsNumber: number
    incidentStatus: IncidentStatus
}
export function IncidentsCount({
    incidentsNumber,
    incidentStatus,
}: IncidentsCountProps): JSX.Element {
    const {width} = useDimensions()
    const narrowLayout = narrowLayoutFn(width)

    return (
        <Styled.IncidentsWrapper
            width={width}
            incidentStatus={incidentStatus}
            id={`filter-card-incident-status-${incidentStatus}`}
        >
            <Styled.IncidentsCount id={`filter-card-status-${incidentStatus}-count`}>
                {incidentsNumber}
            </Styled.IncidentsCount>
            <Styled.IncidentsText id={`filter-card-status-${incidentStatus}-text`}>
                {narrowLayout ? incidentStatus : `${incidentStatus} INCIDENT(S)`}
            </Styled.IncidentsText>
        </Styled.IncidentsWrapper>
    )
}
