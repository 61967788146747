import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {smallFont} from '../../theme/font-styles'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'
import {getHorizontalPagePadding} from '../../theme/helpers/page-layout'

export const BREAK_POINT_REFRESH_SWITCH = 500
export const BREAK_POINT_CARD_VIEW = 800
export const SHOW_DETAILS_BREAK_POINT_CARD_VIEW = 1200

interface UserManagementPageStyleProps {
    width: number
}

export const Header = styled.div<UserManagementPageStyleProps>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'row' : 'column')};
`

export const PageTitle = styled.div<UserManagementPageStyleProps>`
    font-size: 20px;
    line-height: 27px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const UpdatedTimeArea = styled.div<UserManagementPageStyleProps>`
    display: flex;
    padding-top: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 0 : spacing(2))};
    flex-direction: ${(props) => (props.width > BREAK_POINT_REFRESH_SWITCH ? 'column' : 'row')};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`

export const ToggleButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${smallFont()}
    column-gap: ${spacing(1)};
`

export const HeaderAreaWrapper = styled.div<UserManagementPageStyleProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'row' : 'column-reverse')};
    align-items: ${(props) => (props.width > BREAK_POINT_CARD_VIEW ? 'center' : 'flex-start')};
    justify-content: center;
    column-gap: ${spacing(4)};
    row-gap: ${spacing(1)};
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: ${spacing(1)};
`

interface ScrollableContentProps {
    flexGrow?: boolean
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
    padding: ${spacing(3)} 0;
    overflow-y: auto;
    ${(props) => (props.flexGrow ? 'flex: 1;' : '')}
    width: 100%;
`

interface WideContentAreaProps {
    width: number
    fullHeight: boolean
}

export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    padding: 0 ${(props) => getHorizontalPagePadding(props.width)};
    margin: 0 auto;
    width: 100%;
    ${(props) => (props.fullHeight ? 'height: 100%;' : '')}
`

interface WideContentHeaderAreaProps {
    width: number
}

export const WideContentHeaderArea = styled.div<WideContentHeaderAreaProps>`
    display: flex;
    justify-content: space-between;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    padding: 10px ${(props) => getHorizontalPagePadding(props.width)};
    margin-left: ${(props) => (props.width > MAX_SCREEN_WIDTH ? (props.width - 1640) / 2 : 0)}px;
`
