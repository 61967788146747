import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {FrameworkScoreCardResponse} from '../../../contexts/types/framework-score-card-api'
import {ScoreBar} from './score-bar'
import * as Styled from './summary-score-card.styled'

interface SummaryScoreCardProps {
    sumaryContentScore: FrameworkScoreCardResponse
}
export function SummaryScoreCard({sumaryContentScore}: SummaryScoreCardProps): JSX.Element {
    return (
        <Styled.SummaryScoreCardWrapper
            id={`summary-scoreCard-wrapper-${sumaryContentScore.locationId}`}
        >
            <ScoreBar
                displayGraph={sumaryContentScore.protectionScore !== undefined}
                score={sumaryContentScore.protectionScore ?? 0}
                metricType={MetricType.PROTECTION}
                identifier={sumaryContentScore.locationId}
                summaryView={true}
            />
            <ScoreBar
                displayGraph={sumaryContentScore.maintenanceScore !== undefined}
                score={sumaryContentScore.maintenanceScore ?? 0}
                metricType={MetricType.MAINTENANCE}
                identifier={sumaryContentScore.locationId}
                summaryView={true}
            />
            <ScoreBar
                displayGraph={sumaryContentScore.behaviourScore !== undefined}
                score={sumaryContentScore.behaviourScore ?? 0}
                metricType={MetricType.BEHAVIOUR}
                identifier={sumaryContentScore.locationId}
                summaryView={true}
            />
        </Styled.SummaryScoreCardWrapper>
    )
}
