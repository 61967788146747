import {FormattedVesselConfig} from './formatted-vessel-config'
import {VesselConfigState} from './vessel-config-state'

export const DEFAULT_VESSEL_CONFIG_STATE: VesselConfigState = {
    fixedValue: {location: '', description: '', locationCode: '', status: 'NOT_READY'},
    initialData: getEmptyData(),
    data: getEmptyData(),
    displayData: getEmptyData(),
    isChanged: false,
    saveResultMessage: undefined,
}

function getEmptyData(): FormattedVesselConfig {
    return {
        type: '',
        customerLocationCode: '',
        businessNetworks: [''],
        otNetworks: [''],
        crewNetworks: [''],
        gatewayIp: '',
        gatewayMac: '',
        incusIp: '',
        incusMac: '',
        sensorIp: '',
        sensorMac: '',
        otDevices: [''],
        otCrossings: [''],
        businessDevices: [''],
        businessCrossings: [''],
        emailHosts: [''],
        hostLogExceptions: [''],
        networkLogExceptions: [''],
        usbHosts: [''],
        usbDevices: [''],
        adminLogons: [''],
        remoteAccess: [''],
        encryptionRatio: '',
        monitoringCoverage: '',
        maxOSAge: '',
        maxScore: 0,
    }
}
