import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../theme/font-styles'
import {spacing} from '../../../theme/spacing'

export const FileBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 2px;
    width: 500px;
    padding: ${spacing(2)};
`
const Message = styled.span`
    ${smallFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`
export const SuccessMessage = styled(Message)`
    color: ${(props) => props.theme.colors.text.success};
`

export const ErrorMessage = styled(Message)`
    color: ${(props) => props.theme.colors.text.error};
`
export const CloseModalMessage = styled(Message)`
    font-style: italic;
    color: ${(props) => props.theme.colors.text.default};
`
export const FileImage = styled.img`
    height: 25px;
    width: 21px;
`
export const SubTitle = styled.span`
    ${smallFont()}
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
`
export const Button = styled.button`
    border: none;
    background: none;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.hover.textColor};
    background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    border: 1px solid;
    border-radius: 5px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: ${spacing(2)};
    &:hover {
        color: ${(props) => props.theme.refreshButton.default.textColor};
        background-color: white;
    }
    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`

export const ButtonText = styled.span`
    font-weight: ${(props) => props.theme.font.weight.normal};
    ${mediumSmallFont()}
`
