import ActionType from './action-type'
import * as Actions from './actions'
import {Api} from '../../../api/Api'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {NetworkAnomalyMatrixApiRecord} from '../../../values/anomalies/network-anomaly-matrix-api-record'

export const NODES_REST_URL = '/api/v1/mona/anomaly-matrix'

export function fetchNetworkAnomalyMatrix(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestRecordsAction())

        api.getAuth(NODES_REST_URL, (data) => {
            dispatch(receiveRecordsAction(data as NetworkAnomalyMatrixApiRecord[]))
        })
    }
}

function requestRecordsAction(): Actions.RequestDataAction {
    return {
        type: ActionType.REQUEST_DATA,
    }
}

function receiveRecordsAction(records: NetworkAnomalyMatrixApiRecord[]): Actions.ReceiveDataAction {
    return {
        type: ActionType.RECEIVE_DATA,
        payload: records,
    }
}
