import {useEffect} from 'react'
import {CardArea} from '../../common/cards/card-area.styled'
import {UniqueFlowCard} from './unique-flow-card'
import LoadingState from '../../../../../../values/loading-state-enum'
import {usePagedUniqueFlows} from '../../../../contexts/unique-flows/use-paged-unique-flows'
import {RequestError} from './request-error'
import {LoadingData} from '../../common/cards/loading-data'
import {NoData} from './no-data'

export function UniqueFlowsCards(): JSX.Element {
    const {loading, refreshData, totalNumberOfFlows, dataPage} = usePagedUniqueFlows()

    useEffect(() => {
        if (loading === LoadingState.NotPopulated) {
            refreshData()
        }
    }, [loading, refreshData])

    if (loading === LoadingState.Errored) {
        return <RequestError />
    }

    const showLoading =
        totalNumberOfFlows == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData

    if (showLoading || !dataPage) {
        return <LoadingData />
    }

    if (totalNumberOfFlows === 0) {
        return <NoData />
    }

    return (
        <CardArea>
            {dataPage.map((uniqueFlow) => (
                <UniqueFlowCard key={uniqueFlow.uniqueFlow} uniqueFlow={uniqueFlow} />
            ))}
        </CardArea>
    )
}
