import {useEffect, useState} from 'react'
import * as Styled from './dropdown.styled'
import {isEqual} from 'lodash'
import {useEnforcementPolicy} from '../../../../../contexts/enforcement-policy/use-enforcement-policy'
import {policyForMasterOverrideEnum} from '../../../../../contexts/enforcement-policy/type/enforcement-policy'

export type DropdownProps = {
    disabled?: boolean
}

export function PolicyForMasterOverrideDropDown({disabled}: DropdownProps): JSX.Element {
    const {
        selectedEnforcementPolicy,
        changePolicyForMasterOverride,
        discardChanges,
        requestDiscardChanges,
    } = useEnforcementPolicy()
    const initialDropDownValue =
        selectedEnforcementPolicy?.usbProtection.policyForMasterOverride ??
        policyForMasterOverrideEnum.NEVER
    const [dropDownValue, setDropDownValue] =
        useState<policyForMasterOverrideEnum>(initialDropDownValue)

    useEffect(() => {
        if (discardChanges && !isEqual(initialDropDownValue, dropDownValue)) {
            setDropDownValue(initialDropDownValue)
            requestDiscardChanges(false)
        }
    }, [dropDownValue, discardChanges, initialDropDownValue, requestDiscardChanges])

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        if (disabled) {
            return
        }
        const newValue = event.target.value as policyForMasterOverrideEnum
        const isItChanged = !isEqual(initialDropDownValue, newValue)
        setDropDownValue(newValue)
        changePolicyForMasterOverride(newValue, isItChanged)
    }

    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Label>Master Override</Styled.Label>
            <Styled.Input>
                <Styled.Select onChange={onChangeWrapper} value={dropDownValue}>
                    {Object.entries(policyForMasterOverrideEnum).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value
                                .replace(/_/g, ' ')
                                .toLowerCase()
                                .replace(/^\w/, (c) => c.toUpperCase())}
                        </option>
                    ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
