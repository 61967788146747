import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {FixedPageTemplate} from '../../../templates/fixed-page/fixed-page-template'
import * as Styles from './my-vessels.styled'
import {FilterBar} from './components/filter/filter-bar'
import {isLoadingReSelector} from './reselectors/is-loading-reselector'
import {LoadingData} from './components/loading-data'
import {LoadedData} from './components/loaded-data'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {ToggleProvider} from '../../../contexts/toggle/toggle-provider'
import {useToggle} from '../../../contexts/toggle/use-toggle'
import {ScrollablePageTemplate} from '../../../templates/scrollable-page/scrollable-page-template'
import {showFilterBarOnScreen} from './helpers'
import {CloseFilterButton} from './components/filter/close-filter-button'
import {fetchMyVesselsFilter} from '../../../store/state/my-vessels-filters/action-creators'
import {setPageType} from '../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {currentUserSelector} from '../../../store/state/current-user/selectors'
import {StrictMode} from '../../../store/state/users/state'
import {logPageAccess} from '../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../store/state/audit-log/state'

interface LocationState {
    from: string
}

function isFromDeepLinking(state: LocationState): boolean {
    if (state == null) {
        return false
    }

    return state.from === 'deep-linking'
}

export function MyVessels(): JSX.Element {
    const routerLocation = useLocation<LocationState>()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isFromDeepLinking(routerLocation.state)) {
            dispatch(logPageAccess(AuditLogPageType.MY_VESSELS))
        } else {
            history.replaceState({}, document.title)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ToggleProvider>
            <MyVesselPage />
        </ToggleProvider>
    )
}

function MyVesselPage(): JSX.Element {
    const dispatch = useDispatch()
    const {width} = useDimensions()
    const {value: showFilter, setValue: setShowFilter} = useToggle()
    const showFilterBar = showFilterBarOnScreen(width)
    const loading = useTypedSelector(isLoadingReSelector)
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)

    useEffect(() => {
        dispatch(setPageType(PageType.MY_VESSELS))
        dispatch(fetchMyVesselsFilter(strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    useEffect(() => {
        if (showFilter) {
            if (showFilterBar) {
                setShowFilter(false)
            }
        }
    }, [setShowFilter, showFilter, showFilterBar])

    if (!showFilterBar && showFilter) {
        return (
            <ScrollablePageTemplate onClick={() => setShowFilter(false)}>
                <Styles.FilterBarWrapper
                    onClick={(e) => e.stopPropagation()}
                    id="filter-abr-wrapper"
                >
                    <Styles.TopCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.TopCloseFilterBar>
                    <FilterBar />
                    <Styles.BottomCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.BottomCloseFilterBar>
                </Styles.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Styles.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styles.LHFilterBar id="filter-abr-wrapper">
                            <FilterBar />
                        </Styles.LHFilterBar>
                    )}

                    {loading ? <LoadingData /> : <LoadedData />}
                </Styles.WideContentArea>
            </FixedPageTemplate>
        )
    }
}
