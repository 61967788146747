import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {showOneCol, showTwoCol, showTwoColWide} from '../shared/grid-layout-helper'
import {GridLayout} from '../shared/grid-layout.enum'

interface DataAreaStyleProps {
    gridLayout: GridLayout
    addMargin: boolean
}

export const DataAreaStyle = styled.div<DataAreaStyleProps>`
    ${(props) =>
        showTwoCol(props.gridLayout)
            ? 'display: flex; justify-content: space-between;'
            : 'justify-content: flex-start;'}
    column-gap: ${spacing(10)};
    margin: ${(props) => (props.addMargin ? spacing(2) : 0)} 0;
    padding: ${(props) =>
        showTwoColWide(props.gridLayout)
            ? spacing(8)
            : showOneCol(props.gridLayout)
              ? spacing(4)
              : spacing(6)};
    padding-top: ${(props) => (showTwoColWide(props.gridLayout) ? spacing(4) : spacing(2))};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
    color: ${(props) => props.theme.colors.text.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
`

export const Divider = styled.div`
    margin: 6.5px 0;
    border-top: 1px solid ${(props) => props.theme.navigationResponsive.dataArea.miniTextColor};
`
