import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {sortedLocationsSelector} from '../../selector/sorted-locations-selectors'
import {TableRow} from './vessels-table-row'
import {ContentContainer} from './_styled/table-populated.styled'

export function VesselsTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const sortedLocations = useTypedSelector(sortedLocationsSelector)
    return (
        <ContentContainer width={width}>
            {sortedLocations.map((location, index) => (
                <TableRow key={location.location} location={location} index={index} />
            ))}
        </ContentContainer>
    )
}
