import {RefreshButton} from '../../../components/refresh-button/refresh-button'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../../templates/fixed-page/content-container-top.styled'
import {usePagedNmeaInventory} from '../contexts/use-paged-nmea-inventory'
import {Container} from './data-actions.styled'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const {refreshData} = usePagedNmeaInventory()

    return (
        <ContentContainerTop width={width} id="NmeaInventoryHeader">
            <Container>
                <RefreshButton
                    longText="Refresh NMEA Talkers"
                    onClick={refreshData}
                    pageType={PageType.NMEA_INVENTORY}
                />
            </Container>
        </ContentContainerTop>
    )
}
