import {SwitchToggle} from '../../../../../../../components/switch-toggle/switch-toggle'
import * as Styled from './incident-type-severity-section.styled'

interface IncidentPolicyDetailToggleProps {
    label: string
    onValue: boolean
    setChange: (value: boolean) => void
    disabled?: boolean
}

export function IncidentPolicyDetailToggle({
    label,
    onValue,
    setChange,
    disabled = false,
}: IncidentPolicyDetailToggleProps): JSX.Element {
    return (
        <Styled.ToggleButton id="zoom-level">
            {label}
            <SwitchToggle
                onValue={onValue}
                setToggleLeftValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    setChange(false)
                }}
                setToggleRightValue={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    setChange(true)
                }}
                id="incident-policy-detail"
            />
        </Styled.ToggleButton>
    )
}
