import {RecordSetForPagination} from '../../../../components/paging/basic/record-set-for-pagination'
import {usePagedNmeaInventory} from '../../contexts/use-paged-nmea-inventory'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfNmeaInventory, selectedPage, pageSize} = usePagedNmeaInventory()

    if (
        totalNumberOfNmeaInventory == undefined ||
        selectedPage == undefined ||
        pageSize == undefined
    ) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfNmeaInventory
            ? totalNumberOfNmeaInventory
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfNmeaInventory !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfNmeaInventory}
                />
            )}
        </>
    )
}
