import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../../../store/state/email-recipient-management/selectors'
import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import * as Styled from './subscriptions-section.styled'

export function SubscriptionsReadOnly(): JSX.Element {
    const {activeUserDetails, detailExpanded} = useUserManagement()
    const subscriptionData = useTypedSelector(emailRecipientDataSelector)
    const initialSubscriptionValue = subscriptionData.some(
        (data) => data.user === activeUserDetails?.user && data.notificationType === 'NEW_INCIDENT',
    )

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.Subscriptions} />
            {detailExpanded.subscriptionsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <Styled.DetailRow>
                            {initialSubscriptionValue ? 'Incidents' : 'None'}
                        </Styled.DetailRow>
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
