import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface TooltipContainerProps {
    lastRow: boolean
}

export const TooltipContainer = styled.div<TooltipContainerProps>`
    display: inline-block;
    position: absolute;
    ${(props) => (props.lastRow ? `bottom: ${spacing(1)};` : `top: ${spacing(5)};`)}
    max-width: 180px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    padding: ${spacing(1)};
    z-index: 100;
    pointer-events: none;
    overflow-x: hidden;
    overflow-y: auto;
`

export const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Details = styled.div`
    display: inline-block;
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
    text-transform: capitalize;
`
