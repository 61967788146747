import {Location} from '../../../../../../../store/state/locations/state'
import {VesselTags} from '../../../../../../../store/state/vessel-tags/state'
import {AssignPolicyDialogState} from '../../../../../contexts/policy-management/type/policy-management-state'

function compareRecord(a: Location, b: Location): number {
    return a.description.localeCompare(b.description)
}

export function filterVesselTags(
    vesselTag: VesselTags[] | undefined,
    searchTerm: string[] | undefined,
): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

export function filterVesselName(vesselName: string, searchTerm: string | undefined): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return vesselName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

export function filteredByTagsVesselIds(
    locations: Location[],
    filter: AssignPolicyDialogState,
): string[] {
    return locations
        .filter((location) => filterVesselTags(location.tags, filter.searchVesselTagTerm))
        .filter((location) => filterVesselName(location.description, filter.searchVesselNameTerm))
        .sort(compareRecord)
        .map((location) => location.location)
}
