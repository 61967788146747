import {GuidType} from '../../../../../values/generic-type-defintions'
import {AssetDetails} from './asset-details'

interface Props {
    nodeIds: GuidType[] | undefined
}

export function AssetDetailList({nodeIds}: Props): JSX.Element {
    return (
        <>
            {nodeIds &&
                nodeIds.map((nodeId) => {
                    return (
                        <AssetDetails key={nodeId} assetId={nodeId} where="dashboard-fleetlist" />
                    )
                })}
        </>
    )
}
