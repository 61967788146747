import {ToggleLeft, ToggleRight} from 'react-feather'
import * as Styled from './_styled/on-off-toggle.styled'
import {useEffect, useState} from 'react'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {isEqual} from 'lodash'

interface OnOffToggleConfigProps {
    enabled: boolean
    label: string
    categoryLevel?: boolean
    metricConfigId: GuidType
    disabled?: boolean
}

export function OnOffToggleConfig({
    metricConfigId,
    enabled,
    label,
    categoryLevel,
    disabled = false,
}: OnOffToggleConfigProps): JSX.Element {
    const [onValue, setOnValue] = useState(enabled)
    const {changeMetricEnabledValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()
    useEffect(() => {
        if (discardChanges && !isEqual(enabled, onValue)) {
            setOnValue(enabled)
            requestDiscardChanges(false)
        }
    }, [onValue, discardChanges, enabled, requestDiscardChanges])

    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Title categoryLevel={categoryLevel}>{label}</Styled.Title>
            <Styled.SwitchToggle>
                <Styled.ToggleButton selectable>
                    {onValue ? (
                        <ToggleRight
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault()
                                    return
                                }
                                const isItChanged = !isEqual(enabled, false)
                                setOnValue(false)
                                changeMetricEnabledValue(metricConfigId, false, isItChanged)
                            }}
                            fill="#1f88e5"
                            color="#f7f7f7"
                            width={27}
                            height={27}
                        />
                    ) : (
                        <ToggleLeft
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault()
                                    return
                                }
                                const isItChanged = !isEqual(enabled, true)
                                setOnValue(true)
                                changeMetricEnabledValue(metricConfigId, true, isItChanged)
                            }}
                            fill="#c9c9c9"
                            color="#f7f7f7"
                            width={27}
                            height={27}
                        />
                    )}
                </Styled.ToggleButton>
            </Styled.SwitchToggle>
        </Styled.Wrapper>
    )
}
