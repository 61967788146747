import styled from 'styled-components'
import {GridLayout} from '../../general/grid-layout.enum'

interface IconValueWrapperProps {
    gridLayout: GridLayout
}

function getMarginLeft(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '0'
        default:
            return '-27px'
    }
}

export const ImgValueWrapper = styled.div<IconValueWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: ${(props) => getMarginLeft(props.gridLayout)};
`
