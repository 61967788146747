import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {BREAK_POINT} from '../enable-monitoring.styled'

interface DataCellProps {
    width: number
}

export const DataCell = styled.div<DataCellProps>`
    ${(props) => (props.width >= BREAK_POINT ? 'display: flex;' : '')}
`

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
    width: 140px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    display: flex;
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const Input = styled.input`
    width: 240px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`

export const IconWrapper = styled.div`
    padding-top: ${spacing(1)};
`

export const InputWrapper = styled.div`
    display: flex;
`
export const WarningMessage = styled.div`
    background: none;
    border: none;
    min-height: 20px;
    padding: 0 ${spacing(1)} ${spacing(1)};
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.text.warning};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
