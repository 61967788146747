import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Period = styled.div`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    display: flex;
    align-items: center;
`
export const LinkText = styled.div`
    cursor: pointer;
    color: ${(props) => props.theme.colors.link.primary};
    padding-left: 3px;
    padding-right: 3px;
`
