export enum ActionType {
    TOGGLE_SHOW_ASSET_UPLOAD_MODAL = '[OT ASSET UPLOAD MODAL] TOGGLE SHOW ASSET UPLOAD MODAL',
    SET_FILE = '[OT ASSET UPLOAD MODAL] SET FILE',
    SET_UPLOADING_STATUS = '[OT ASSET UPLOAD MODAL] SET UPLOADING STATUS',
    SET_SERVER_MESSAGE = '[OT ASSET UPLOAD MODAL] SET SERVER MESSAGE',

    ADD_FILE = '[OT ASSET UPLOAD MODAL] ADD FILE',
    SET_FILES = '[OT ASSET UPLOAD MODAL] SET FILES',

    SET_FILE_UPLOAD_ERROR = '[OT ASSET UPLOAD MODAL] SET FILE UPLOAD ERROR',
    SET_FILE_UPLOAD_SUCCESS = '[OT ASSET UPLOAD MODAL] SET FILE UPLOAD SUCCESS',

    FILES_REQUEST = '[OT ASSET UPLOAD MODAL] REQUEST',
    FILES_REQUEST_FAILURE = '[OT ASSET UPLOAD MODAL] REQUEST ERROR',
    FILES_REQUEST_SUCCESS = '[OT ASSET UPLOAD MODAL] REQUEST SUCCESS',
}
