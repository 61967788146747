import {WrapperOfStatusesCount} from './status-of-incidents-raised.styled'
import * as Styled from './assignments-count.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import UNASSIGNED from '../../../../@assets/icons/assign-to-unassigned.svg'
import ASSIGNED_TO_PERSON from '../../../../@assets/icons/assigned-to-person.svg'
import ASSIGNED_TO_COLLEAGUES from '../../../../@assets/icons/assign-to-colleagues.svg'
import ASSIGNED_TO_VESSELS from '../../../../@assets/icons/new-vessel-icon.svg'
import {IncidentAssignmentsCounts} from '../../contexts/types/incidents-report-output'

export function TotalNumberOfIncidentsByAssignments({
    assignementsCounts,
}: {
    assignementsCounts: IncidentAssignmentsCounts
}): JSX.Element {
    const {width} = useDimensions()

    return (
        <WrapperOfStatusesCount>
            <Styled.AssignmentsWrapper width={width}>
                <Styled.ImgCountWrapper>
                    <img src={UNASSIGNED} alt="Unassigned" width={26} height={26} />
                    <Styled.AssignmentCount>{assignementsCounts.unassigned}</Styled.AssignmentCount>
                </Styled.ImgCountWrapper>
                <Styled.AssignmentText>Unassigned</Styled.AssignmentText>
            </Styled.AssignmentsWrapper>
            <Styled.AssignmentsWrapper width={width}>
                <Styled.ImgCountWrapper>
                    <img src={ASSIGNED_TO_PERSON} alt="Assigned to you" width={26} height={26} />
                    <Styled.AssignmentCount>
                        {assignementsCounts.assignedToCurrentUser}
                    </Styled.AssignmentCount>
                </Styled.ImgCountWrapper>
                <Styled.AssignmentText>Assigned to you</Styled.AssignmentText>
            </Styled.AssignmentsWrapper>
            <Styled.AssignmentsWrapper width={width}>
                <Styled.ImgCountWrapper>
                    <img
                        src={ASSIGNED_TO_COLLEAGUES}
                        alt="Assigned to colleagues"
                        width={32}
                        height={32}
                    />
                    <Styled.AssignmentCount>
                        {assignementsCounts.assignedToColleagues}
                    </Styled.AssignmentCount>
                </Styled.ImgCountWrapper>
                <Styled.AssignmentText>Assigned to colleagues</Styled.AssignmentText>
            </Styled.AssignmentsWrapper>
            <Styled.AssignmentsWrapper width={width}>
                <Styled.ImgCountWrapper>
                    <img
                        src={ASSIGNED_TO_VESSELS}
                        alt="Assigned to vessels"
                        width={26}
                        height={26}
                    />
                    <Styled.AssignmentCount>
                        {assignementsCounts.assignedToVessel}
                    </Styled.AssignmentCount>
                </Styled.ImgCountWrapper>
                <Styled.AssignmentText>Assigned to vessels</Styled.AssignmentText>
            </Styled.AssignmentsWrapper>
        </WrapperOfStatusesCount>
    )
}
