import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {ClearButton} from '../../../../../components/page-filter/components/shared/clear-button/clear-button'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {ToggleAllUsbDeviceStatus} from '../../../../../store/state/usb-inventory-filter/action-creators'
import {ViewAllRowWrapper} from './usb-device-status-filter.styled'

interface Props {
    state: TriStateButtonState
    setState: (value: TriStateButtonState) => void
    showClearButton: boolean
}

export function ViewAllUsbDeviceStatusRow({state, setState, showClearButton}: Props): JSX.Element {
    return (
        <ViewAllRowWrapper>
            <TriStateButton
                id={`${PageType.USB_DEVICES}_filter-bar_view-all`}
                label="View All"
                state={state}
                onClick={setState}
            />
            {showClearButton && (
                <ClearButton
                    clearFn={ToggleAllUsbDeviceStatus}
                    id={`${PageType.USB_DEVICES}_filter-bar_clear-view-all`}
                />
            )}
        </ViewAllRowWrapper>
    )
}
