import {usePagedUniqueFlows} from '../../../contexts/unique-flows/use-paged-unique-flows'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'

export function RecordSet(): JSX.Element | null {
    const {selectedPage, pageSize, totalNumberOfFlows} = usePagedUniqueFlows()

    if (totalNumberOfFlows == undefined || selectedPage == undefined || pageSize == undefined) {
        return null
    }

    const startingAlert = selectedPage * pageSize + 1
    const endAlert =
        startingAlert + pageSize > totalNumberOfFlows
            ? totalNumberOfFlows
            : startingAlert + pageSize - 1

    return (
        <>
            {totalNumberOfFlows !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingAlert}
                    endIndicator={endAlert}
                    totalNumberOfItems={totalNumberOfFlows}
                />
            )}
        </>
    )
}
