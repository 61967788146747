import * as Styled from './data-cell.styled'
import {ReactNode, ReactNodeArray} from 'react'

interface DataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
    tooltip?: string
}

export function DataCell({label, children, tooltip}: DataCellProps): JSX.Element {
    return (
        <>
            <Styled.Label id={`data-cell_label_${label}`} title={tooltip}>
                {label}
            </Styled.Label>
            <Styled.Children id={`data-cell_children_${children}`}>{children}</Styled.Children>
        </>
    )
}
