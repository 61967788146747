import styled from 'styled-components'

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`

export const IconWrapper = styled.div`
    width: 19px;
    height: 19px;
`
const Base = styled.div`
    font-size: 16px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const FilterHeadingRow = styled(Base)`
    grid-column: 1 / span 1;
`
export const HeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
