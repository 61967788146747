import styled from 'styled-components'
import {WidgetType} from '../../widget-type'
import {GridLayout} from './grid-layout.enum'
import {getGridLayout} from './helpers'

const THREE_COLUMN_LAYOUT = `"${WidgetType.MY_FLEET} ${WidgetType.INCIDENT_RESPONSE} ${WidgetType.VESSELS_AT_RISK}" "${WidgetType.RISK_SCORE_OVER_TIME} ${WidgetType.RISK_SCORE_OVER_TIME} ${WidgetType.VESSELS_AT_RISK}" "${WidgetType.ASSET_DISCOVERY} ${WidgetType.METRICS} ${WidgetType.TAGS_TABLE}"`
const TWO_COLUMN_LAYOUT = `"${WidgetType.MY_FLEET} ${WidgetType.VESSELS_AT_RISK}" "${WidgetType.INCIDENT_RESPONSE} ${WidgetType.VESSELS_AT_RISK}" "${WidgetType.RISK_SCORE_OVER_TIME} ${WidgetType.RISK_SCORE_OVER_TIME}" "${WidgetType.ASSET_DISCOVERY} ${WidgetType.METRICS}" "${WidgetType.TAGS_TABLE} ${WidgetType.TAGS_TABLE}"`
const ONE_COLUMN_LAYOUT = `"${WidgetType.MY_FLEET}" "${WidgetType.INCIDENT_RESPONSE}" "${WidgetType.VESSELS_AT_RISK}" "${WidgetType.VESSELS_AT_RISK}" "${WidgetType.RISK_SCORE_OVER_TIME}" "${WidgetType.ASSET_DISCOVERY}" "${WidgetType.METRICS}" "${WidgetType.TAGS_TABLE}"`

function getGridTemplate(width: number): string {
    switch (getGridLayout(width)) {
        case GridLayout.THREE_COLUMNS_LARGE:
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_SMALL:
            return THREE_COLUMN_LAYOUT
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_SMALL:
            return TWO_COLUMN_LAYOUT
        default:
            return ONE_COLUMN_LAYOUT
    }
}

function getColumnWidth(width: number): string {
    switch (getGridLayout(width)) {
        case GridLayout.THREE_COLUMNS_LARGE:
        case GridLayout.TWO_COLUMNS_LARGE:
        case GridLayout.ONE_COLUMN_LARGE:
            return '536px'
        case GridLayout.THREE_COLUMNS_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '452px'
        default:
            return '404px'
    }
}

function getColumnHeight(width: number): string {
    switch (getGridLayout(width)) {
        case GridLayout.THREE_COLUMNS_LARGE:
            return '316px 316px 316px'
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '259px 259px 259px'
        case GridLayout.THREE_COLUMNS_SMALL:
            return '225px 225px 225px'
        case GridLayout.TWO_COLUMNS_LARGE:
            return '316px 316px 316px 316px 316px'
        case GridLayout.TWO_COLUMNS_MEDIUM:
            return '259px 259px 259px 259px 259px'
        case GridLayout.TWO_COLUMNS_SMALL:
            return '225px 225px 225px 225px 225px'
        case GridLayout.ONE_COLUMN_LARGE:
            return '316px 316px 316px 316px 316px 316px 316px'
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '259px 259px 259px 259px 259px 259px 259px'
        case GridLayout.ONE_COLUMN_SMALL:
        default:
            return '225px 225px 225px 225px 225px 225px 225px'
    }
}

interface WidgetGridProps {
    width: number
}

export const WidgetGrid = styled.div<WidgetGridProps>`
    margin-top: 16px;
    margin-bottom: 16px;
    display: grid;
    min-width: max-content;
    max-width: max-content;
    grid-template: ${(props) => getGridTemplate(props.width)};
    grid-auto-columns: ${(props) => getColumnWidth(props.width)};
    grid-template-rows: ${(props) => getColumnHeight(props.width)};
    column-gap: 16px;
    row-gap: 16px;
`
