import {useDispatch} from 'react-redux'
import {
    TimestampFilterType,
    allPeriodTypeOther,
    getTypeDisplayName,
} from '../../../contexts/types/timestamp-filter'
import {usePagedVesselsBeta} from '../../../contexts/use-paged-vessels-beta'
import * as Styled from './analysis-selection.styled'
import {logAnalysisPeriod} from '../../../../../store/state/audit-log/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    analysisPeriodVesselsBetaFilterSelector,
    selectedColumnsVesselsBetaFilterSelector,
} from '../../../../../store/state/vessels-beta-filter/selectors'

export function AnalysisSelectionFilter(): JSX.Element {
    const {setAnalysisPeriod} = usePagedVesselsBeta()
    const dispatch = useDispatch()
    const analysisPeriod = useTypedSelector(analysisPeriodVesselsBetaFilterSelector)
    const selectedColumns = useTypedSelector(selectedColumnsVesselsBetaFilterSelector)

    function onChangeWrapperCommon(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        setAnalysisPeriod(event.target.value as TimestampFilterType, dispatch)
        dispatch(logAnalysisPeriod(event.target.value as TimestampFilterType, selectedColumns))
    }
    return (
        <Styled.Wrapper>
            <Styled.FilterHeadingRow>Analysis Selection</Styled.FilterHeadingRow>
            <Styled.AnalysisPeriod>
                <Styled.Label>Analysis Period</Styled.Label>
                <Styled.Input>
                    <Styled.Select onChange={onChangeWrapperCommon} value={analysisPeriod}>
                        {allPeriodTypeOther?.map((value) => (
                            <option key={value} value={value}>
                                {getTypeDisplayName(value)}
                            </option>
                        ))}
                        <option key="default-option" value="30d">
                            {getTypeDisplayName('30d')}
                        </option>
                    </Styled.Select>
                </Styled.Input>
            </Styled.AnalysisPeriod>
        </Styled.Wrapper>
    )
}
