import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CustomerReports} from '../../../../values/customer-reports'
import {ReportCard} from './report-card'
import * as Styles from './_styled/reports-cards-populated.styled'
interface ReportsCardsPopulatedProps {
    customerReports: CustomerReports[] | undefined
}
export function ReportsCardsPopulated({customerReports}: ReportsCardsPopulatedProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styles.CardArea width={width} id="card-data-grid-style">
            {customerReports &&
                customerReports.map((report) => (
                    <ReportCard key={report.identity} report={report} />
                ))}
        </Styles.CardArea>
    )
}
