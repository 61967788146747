import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {useIncidentsPolicy} from '../../../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../../../contexts/metrics-policy/use-metrics-policy'
import {useEnforcementPolicy} from '../../../contexts/enforcement-policy/use-enforcement-policy'

interface DetailPanelHeaderProp {
    text: string
}

export function DetailPanelHeader({text}: DetailPanelHeaderProp): JSX.Element {
    const {closePolicyDetailModal} = usePolicyManagement()
    const {setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {setDefaultEnforcementPolicyData} = useEnforcementPolicy()

    return (
        <Styled.DetailPanelHeader>
            <Styled.Title>{text}</Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closePolicyDetailModal()
                    setDefaultIncidentPolicyData()
                    setDefaultMetricsPolicyData()
                    setDefaultEnforcementPolicyData()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
