import styled from 'styled-components'

export const Button = styled.button`
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 3px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
    &:active {
        color: ${(props) => props.theme.refreshButton.active.textColor};
        background-color: ${(props) => props.theme.refreshButton.active.backgroundColor};
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
`
