import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsResponsePerformanceWidget} from '../../../../incidents-report/components/incidents-response-performance/incidents-response-performance-widget'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import * as Styled from './widget-commonstyling.styled'

export function IncidentsPerformanceWidget(): JSX.Element {
    const {loadedIncidentsByAvgPerformance, incidentsByAvgPerformance} = useIncidentsWidget()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>INCIDENT RESPONSE PERFORMANCE</Styled.SectionText>
            {loadedIncidentsByAvgPerformance ? (
                <IncidentsResponsePerformanceWidget
                    incidentsByAvgPerformance={incidentsByAvgPerformance}
                />
            ) : (
                <DataLoading />
            )}
        </Styled.StatusFilterContainer>
    )
}
