import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setFilteredIncidentStatus} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {narrowLayoutFn} from '../../cards/grid-helper'
import {FilterCard, FilterCount, FilterText} from './filter-card.styled'
import {avaliableStatuses} from '../../filter-bar/incident-status/incident-status-filter'
import {remove} from 'lodash'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'

export function OpenIncidentsFilter(): JSX.Element {
    const {openIncidents} = usePagedIncidents()
    const {width} = useDimensions()

    const dispatch = useDispatch()
    let {filterByStatus} = useTypedSelector(incidentsFilterSelector)
    const narrowLayout = narrowLayoutFn(width)

    function onClickWrapper() {
        if (!filterByStatus || filterByStatus.length === avaliableStatuses.flat().length) {
            filterByStatus = []
        }
        filterByStatus = [...filterByStatus]
        if (filterByStatus?.includes(IncidentStatus.OPEN)) {
            remove(filterByStatus, (value) => [IncidentStatus.OPEN].includes(value))
            dispatch(setFilteredIncidentStatus(filterByStatus))
            dispatch(logFilterByTypes('filterByStatus', 'OPEN', false, AuditLogPageType.INCIDENTS))
        } else {
            dispatch(setFilteredIncidentStatus([IncidentStatus.OPEN, ...filterByStatus]))
            dispatch(logFilterByTypes('filterByStatus', 'OPEN', false, AuditLogPageType.INCIDENTS))
        }
    }
    return (
        <FilterCard
            width={width}
            incidentStatus={IncidentStatus.OPEN}
            onClick={onClickWrapper}
            activeFilter={
                filterByStatus?.includes(IncidentStatus.OPEN) ||
                filterByStatus?.length === 0 ||
                filterByStatus == undefined
            }
            id="filter-card-incident-status-open"
        >
            <FilterCount id="filter-card-status-open-count">{openIncidents}</FilterCount>
            <FilterText id="filter-card-status-open-text">
                {narrowLayout ? 'OPEN' : 'OPEN INCIDENT(S)'}
            </FilterText>
        </FilterCard>
    )
}
