import {VesselDetailsOutputModel} from '../../../reselectors/vessel-details.model'
import * as Styles from './full-width-details-panel.styled'
import {VesselTag} from '../../../../../../components/vessel-tag/vessel-tag'

interface Props {
    model: VesselDetailsOutputModel
    fixedWidthContent: boolean
}

export function FullWidthDetailsPanel({model, fixedWidthContent}: Props): JSX.Element {
    return (
        <>
            <Styles.DetailRow fixedWidthContent={fixedWidthContent}>
                <Styles.TitleCell
                    id={`vessel-name-${model.id}`}
                    fixedWidthContent={fixedWidthContent}
                >
                    {model.name}
                </Styles.TitleCell>
                <Styles.RightHandCell
                    id={`vessel-imo-${model.id}`}
                    fixedWidthContent={fixedWidthContent}
                >
                    IMO {model.imoNumber}
                </Styles.RightHandCell>
            </Styles.DetailRow>
            <Styles.DetailRow fixedWidthContent={fixedWidthContent}>
                <Styles.AssetCountCell
                    id={`vessel-assets-${model.id}`}
                    fixedWidthContent={fixedWidthContent}
                >
                    {model.numberOfAssets}
                </Styles.AssetCountCell>
                <Styles.RightHandCell
                    id={`vessel-type-${model.id}`}
                    fixedWidthContent={fixedWidthContent}
                >
                    {model.shipType}
                </Styles.RightHandCell>
            </Styles.DetailRow>
            <Styles.VesselTaggingRow>
                <Styles.VesselTagsCell id={`vessel-tags-${model.id}`}>
                    {model.vesselTags?.map((tag, index) => (
                        <VesselTag key={index} tagName={tag.name} showHover={true} />
                    ))}
                </Styles.VesselTagsCell>
            </Styles.VesselTaggingRow>
        </>
    )
}
