import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../../../../templates/fixed-page/content-container-top.styled'
import {ResetFilterButton} from '../reset-filter/reset-filter-button'
import {Container} from './select.styled'
import {SortOrderDropDown} from './sort-order-drop-down'

export function BottomFilterSection(): JSX.Element {
    const {width} = useDimensions()
    return (
        <ContentContainerTop width={width} id="incidents-page-Bottom-Filter-Section">
            <Container width={width}>
                <SortOrderDropDown />
                <ResetFilterButton />
            </Container>
        </ContentContainerTop>
    )
}
