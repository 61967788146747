import styled from 'styled-components'
import {getColor as getMetricTypeColor} from '../../../../metrics-beta/components/metrics-summaries/score-bar.styled'
import {spacing} from '../../../../../theme/spacing'

export const ScoreBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
`
interface ColorWrapperProps {
    metricType: string
}
export const ColorWrapper = styled.div<ColorWrapperProps>`
    color: ${(props) => getMetricTypeColor(props.metricType)};
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 35px;
`
interface MetricIconProps {
    summaryView?: boolean
}
export const MetricIcon = styled.img<MetricIconProps>`
    width: ${(props) => (props.summaryView ? '20px' : '30px')};
    height: ${(props) => (props.summaryView ? '20px' : '30px')};
`
export const Score = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: ${spacing(1)};
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const ScoreTextWrapper = styled.div`
    position: absolute;
    top: -3px;
    right: -10px;
`
export const ScoreBar = styled.div`
    height: 10px;
    width: 150px;
    background-color: #f29dac;
    border-radius: 4px;
`
interface ScoreBarNoIssueProps {
    score: number
    metricType: string
}

export const ScoreBarNoIssue = styled.div<ScoreBarNoIssueProps>`
    position: relative;
    height: 10px;
    width: ${(props) => (getScoreDisplay(props.score) / 100) * 150}px;
    background-color: ${(props) => getMetricTypeColor(props.metricType)};
    border-radius: 4px 0 0 4px;
`
function getScoreDisplay(score: number): number {
    return score === 0 ? 2 : score
}
