import {Dispatch, useContext} from 'react'
import * as ActionCreators from '../state/vessels/action-creators'
import {Action} from '../state/vessels/actions'
import {REST} from '../../../..'
import {UseOTLocationsResult} from './use-ot-locations-result'
import {VesselListContext} from '../vessel-list-context'

const OT_INVENTORY_BASE_ENDPOINT = `/ot-inventory/api/v1`

export function useOTLocations(): UseOTLocationsResult {
    const {state, dispatch} = useContext(VesselListContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useOTLocations must be used within a OTLocationsContext')
    }
    const {createReportError, isErrorOpen} = state
    const d = dispatch

    function createReport(): void {
        d(ActionCreators.resetErrorState())
        REST.post(`${OT_INVENTORY_BASE_ENDPOINT}/reports/otInventory`)
            .then((response) => {
                const {identity} = response.data
                const reportUrl = `/reports?reportDetails=${identity}`
                d(ActionCreators.setReportCreate(reportUrl))
                d(ActionCreators.setShowGenerateReportPopup(true))
            })
            .catch((error: Error) => {
                d(
                    ActionCreators.reportCreateError(
                        'An error occurred while creating OT Assessment Report.',
                    ),
                )
                // eslint-disable-next-line no-console
                console.error(error)
            })
    }

    return {
        dispatch,
        refreshData: () => getData(dispatch),
        data: state.data,
        filteredData: state.filteredData,
        filter: state.filter,
        loadingState: state.loadingState,
        reportUrl: state.reportUrl,
        reportDialogShown: state.showGenerateReportPopup,
        showGenerateReportPopup: (show: boolean) =>
            d(ActionCreators.setShowGenerateReportPopup(show)),
        createReport,
        createReportError,
        resetCreateReportError: () => d(ActionCreators.resetErrorState()),
        isErrorOpen,
    }
}

function getData(dispatch: Dispatch<Action>): void {
    dispatch(ActionCreators.requestOTLocations())
    REST.get(`${OT_INVENTORY_BASE_ENDPOINT}/locations`, {retry: 3, retryDelay: 1000})
        .then((response) => {
            dispatch(ActionCreators.setOTLocations(response.data))
        })
        .catch(() => {
            dispatch(ActionCreators.failFetchOTLocations())
        })
}
