import {useState, MouseEvent} from 'react'
import {ContentPageType} from '../../../../../contexts/metrics-policy/type/content-type'
import {ControlsMaintenanceContent} from '../controls-maintenance-content'
import {UserBehavioursContent} from '../user-behaviours-content'
import {ProtectiveControlsContent} from '../protective-controls-content'
import {TabHeader} from './tab-header'

export function TabSection(): JSX.Element {
    const [activeTab, setActiveTab] = useState<ContentPageType>(ContentPageType.PROTECTIVE_CONTROLS)

    const toggle = (event: MouseEvent, tab: ContentPageType) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
        event.preventDefault()
    }

    return (
        <>
            <TabHeader activeTab={activeTab} toggle={toggle} />
            <GetContentPageType selectedContentPage={activeTab} />
        </>
    )
}
interface GetContentPageTypeProps {
    selectedContentPage: ContentPageType
}
function GetContentPageType({selectedContentPage}: GetContentPageTypeProps): JSX.Element {
    switch (selectedContentPage) {
        case ContentPageType.CONTROLS_MAINTENANCE:
            return <ControlsMaintenanceContent />
        case ContentPageType.USER_BEHAVIOUR:
            return <UserBehavioursContent />
        case ContentPageType.PROTECTIVE_CONTROLS:
        default:
            return <ProtectiveControlsContent />
    }
}
