import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

interface ButtonProps {
    isChanged: boolean
}

export const Button = styled.button<ButtonProps>`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 6px;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    ${(props) => (props.isChanged ? '' : 'pointer-events: none; opacity: 0.5;')};
    width: 120px;
`

export const Text = styled.span`
    ${mediumSmallFont()}
`
