import * as Style from './header.styled'

interface HeaderProps {
    text: string
    width?: number
}

export function Header({text, width}: HeaderProps): JSX.Element {
    return <Style.Header width={width}>{text}</Style.Header>
}
