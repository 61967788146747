import * as Styled from './_styled/vessels-card.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardTitle} from './card-title'
import {CardCodeCell} from './card-code-cell'
import {Location} from '../../../../../store/state/locations/state'
import {Link} from 'react-router-dom'
import {getFormattedUrl} from '../../helper'

interface VesselsCardProps {
    location: Location
}

export function VesselsCard({location}: VesselsCardProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > 350
    return (
        <Link to={getFormattedUrl(location.code)}>
            <Styled.Card id={`location-card_${location.location}`}>
                <CardTitle location={location} />
                <Styled.CardDataRow>
                    <CardCodeCell
                        location={location}
                        fixedWidthReducedText={fixedWidthReducedText}
                    />
                </Styled.CardDataRow>
            </Styled.Card>
        </Link>
    )
}
