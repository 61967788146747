import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {VesselsBetaFilterReduxState} from './state'
import {SortColumnType} from '../../../pages/vessels-beta/contexts/types/sort-column'
import {TimestampFilterType} from '../../../pages/vessels-beta/contexts/types/timestamp-filter'
import {PossibleColumnsVesselBeta} from '../../../pages/vessels-beta/contexts/types/paged-vessels-beta-state'

export const isVesselBetaFilterLoadingStateSelector = (state: AppState): boolean =>
    state.vesselsBetaFilter.loadingState !== LoadingState.Loaded

export const vesselsBetaFilterSelector = (state: AppState): VesselsBetaFilterReduxState =>
    state.vesselsBetaFilter

export const sortColumnVesselsBetaFilterSelector = (state: AppState): SortColumnType =>
    state.vesselsBetaFilter.sortColumn

export const analysisPeriodVesselsBetaFilterSelector = (state: AppState): TimestampFilterType =>
    state.vesselsBetaFilter.analysisPeriod

export const selectedColumnsVesselsBetaFilterSelector = (
    state: AppState,
): PossibleColumnsVesselBeta[] => state.vesselsBetaFilter.selectedColumns
