import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Wrapper = styled.div`
    display: grid;
    row-gap: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const AnalysisPeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${spacing(2)} 0;
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    background-color: inherit;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`

export const FilterHeadingRow = styled.div`
    grid-column: 1 / span 1;
    font-size: 16px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
