import {StrictMode} from '../../../../../store/state/users/state'
import {DataCell} from '../../user-list-table/user-details-modal/data-input/data-cell'

import {RadioButton} from './radio-button/radio-button'
import {AreaWrapper} from './tag-limit.styled'

interface TagFilterProps {
    label: string
    gridArea?: string
}

export function TagLimit({label, gridArea}: TagFilterProps): JSX.Element {
    return (
        <DataCell label={label} gridArea={gridArea}>
            <AreaWrapper>
                <RadioButton label={StrictMode.Strict} />
                <RadioButton label={StrictMode.DefaultVisibility} />
            </AreaWrapper>
        </DataCell>
    )
}
