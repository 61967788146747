import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentHistory} from '../contexts/types/incident-history'

export interface AssignmentChangeOutputModel {
    id: GuidType
    username: string
    oldAssignedToUser: GuidType
    newAssignedToUser: GuidType
    oldGuestAssignedToEmail: string
    newGuestAssignedToEmail: string
    oldAssignedToVesselEmail: boolean
    newAssignedToVesselEmail: boolean
    when: string
    isViewed: boolean
}

export function wasAssignmentUpdated(record: IncidentHistory): boolean {
    return (
        record.oldAssignedToUser !== record.newAssignedToUser ||
        record.oldGuestAssignedToEmail !== record.newGuestAssignedToEmail ||
        record.oldAssignedToVesselEmail !== record.newAssignedToVesselEmail
    )
}
