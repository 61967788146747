import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
    popup?: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: ${(props) => (props.inset ? spacing(2) : 0)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold || false)};
    cursor: pointer;
`

export const TitleText = styled.a`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const StatusAnnotation = styled.div`
    font-style: italic;
`
