import styled from 'styled-components'

interface TableDataRowProps {
    gridRow: number
    span?: number
    activeSoftware?: boolean
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.usbDevices.dataRow.text};
    background-color: ${(props) => props.theme.usbDevices.dataRow.background};
    cursor: pointer;
    min-height: 64px;
    box-shadow: ${(props) =>
        `1px 1px 6px -2px ${props.theme.commonTableViewPage.dataRow.boxShadow}`};
    ${(props) =>
        props.activeSoftware
            ? `border: 3px solid ${props.theme.softwareInventory.dataRow.selectedRow}`
            : ''};
`
