import {ColourBar} from './styled/colour-bar.styled'
import {useTheme} from 'styled-components'
import {GridLayout} from '../../../general/grid-layout.enum'
import {getOpacity} from './helpers'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../../values/nodes/NodeData'

interface LowRiskColourBarProps {
    gridLayout: GridLayout
}

export function MediumRiskColourBar({gridLayout}: LowRiskColourBarProps): JSX.Element {
    const theme = useTheme()
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    const upperThreshold = threatMeasure?.upperThreshold || 67
    return (
        <>
            <ColourBar
                start={0}
                end={upperThreshold}
                colour={theme.threatScores.score.medium.background}
                id={`medium-colour-bar-${upperThreshold}`}
            />
            <ColourBar
                start={upperThreshold}
                end={100 - upperThreshold}
                colour={theme.threatScores.score.high.background}
                opacity={getOpacity(gridLayout)}
                id={`medium-colour-bar-${100 - upperThreshold}`}
            />
        </>
    )
}
