import BAR_CHART from '../../../../@assets/icons/bar-chart.svg'

import * as Styled from './worst-performing-vessels.styled'
import {MetricType} from '../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {
    WorstPerformingLocationsArray,
    WorstPerformingLocationsForUIDisplay,
} from '../../contexts/types/metrics-summary'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {getLocationDescription} from '../../../../store/state/locations/state'
import {NoData} from '../no-data/no-data'
import LoadingState from '../../../../values/loading-state-enum'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'

interface WorstPerformingVesselsProps {
    metricType: MetricType
}

export function WorstPerformingVessels({metricType}: WorstPerformingVesselsProps): JSX.Element {
    const {dataSummariesMetricsMap, showFilterBar, loadingFilteredDataState} = usePagedMetricsBeta()
    const {width} = useDimensions()

    const allLocationMap = useTypedSelector(locationMapSelector)
    const locationsArray = dataSummariesMetricsMap?.get(metricType)?.worstPerformingLocations

    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-worst-performing-vessels-section-${metricType}`}
            >
                <TitleWrapper icon={BAR_CHART} title="Worst Performing Vessels" id={metricType} />
                <DataLoading />
            </Styled.Section>
        )
    }
    if (!locationsArray) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-worst-performing-vessels-section-${metricType}`}
            >
                <TitleWrapper icon={BAR_CHART} title="Worst Performing Vessels" id={metricType} />
                <NoData text="No Assets are found" />
            </Styled.Section>
        )
    }
    function getLocationArrayForUIDisplay(
        locationsArray: WorstPerformingLocationsArray[],
    ): WorstPerformingLocationsForUIDisplay[] {
        if (!locationsArray) {
            return new Array<WorstPerformingLocationsForUIDisplay>()
        }
        return locationsArray
            ?.map((locationItem) => ({
                ...locationItem,
                score: isNaN(locationItem.count / locationItem.total)
                    ? 0
                    : Math.round(
                          ((locationItem.total - locationItem.count) / locationItem.total) * 100,
                      ),
                locationName: getLocationDescription(allLocationMap, locationItem.location),
            }))
            .sort(
                (a, b) =>
                    b.count - a.count ||
                    a.locationName
                        .toLocaleLowerCase()
                        .localeCompare(b.locationName.toLocaleLowerCase()),
            )
            .sort((a, b) => a.score - b.score)
            .slice(0, 3)
    }
    const formattedLocationsArray = getLocationArrayForUIDisplay(locationsArray)
    const numOfWorstVessels =
        formattedLocationsArray.length >= 3 ? 3 : formattedLocationsArray.length

    const rows = []
    for (let i = 0; i < numOfWorstVessels; i++) {
        const text = formattedLocationsArray[i].total === 1 ? 'asset is ok' : 'assets are ok'
        rows.push(
            <Styled.VesselRow
                key={i}
                id={`footer-worst-performing-vessels-vesssels-rows-${metricType}`}
            >
                <Styled.VesselLabel
                    id={`footer-worst-performing-vessels-vessel-label-${metricType}`}
                >
                    {formattedLocationsArray[i].locationName}
                </Styled.VesselLabel>
                <Styled.ScoreLabel
                    id={`footer-worst-performing-vessels-score-label-${metricType}`}
                >{`${formattedLocationsArray[i].score}% (${
                    formattedLocationsArray[i].total - formattedLocationsArray[i].count
                } of ${formattedLocationsArray[i].total} ${text})`}</Styled.ScoreLabel>
            </Styled.VesselRow>,
        )
    }

    return (
        <Styled.Section
            showFilterBar={showFilterBar}
            width={width}
            id={`footer-worst-performing-vessels-section-${metricType}`}
        >
            <TitleWrapper icon={BAR_CHART} title="Worst Performing Vessels" id={metricType} />
            {rows}
        </Styled.Section>
    )
}
interface TitleWrapperProps {
    icon: string
    title: string
    id: string
}

function TitleWrapper({icon, title, id}: TitleWrapperProps): JSX.Element {
    return (
        <Styled.TitleWrapper id={`footer-worst-performing-vessels-title-${id}`}>
            <Styled.Image src={icon} id={`footer-worst-performing-vessels-icon-${id}`} />
            {title}
        </Styled.TitleWrapper>
    )
}
