import {TableHeaderCell, TableHeaderText} from './table-header-cell.styled'

interface TemporaryColumnProps {
    text: string
    gridColumn: number
    centered?: boolean
}
export function TemporaryColumn({text, gridColumn, centered}: TemporaryColumnProps): JSX.Element {
    return (
        <TableHeaderCell id={`column_${text}`} gridColumn={gridColumn}>
            <TableHeaderText centered={centered} id={`text-${text}`}>
                {text}
            </TableHeaderText>
        </TableHeaderCell>
    )
}
