import {Wrapper} from './assigned-to-row.styles'

interface AssignedToProps {
    assignedToName: string
}

export function AssignedTo({assignedToName}: AssignedToProps): JSX.Element {
    const assignedToDsiplay = assignedToName != undefined ? assignedToName : 'Unassigned'
    return <Wrapper id={`assigned-to-${assignedToDsiplay}`}>{assignedToDsiplay}</Wrapper>
}
