import * as Styled from './data-cell.styled'
import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

interface DataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
    gridArea: string
}
export function DataCell({label, children, gridArea}: DataCellProps): JSX.Element {
    const {width} = useDimensions()
    const labelOnSameLine = width >= 700

    return (
        <Styled.DataCell gridArea={gridArea} labelOnSameLine={labelOnSameLine} id="data-cell">
            <Styled.Label id={`data-cell-label_${label}`}>{label}</Styled.Label>
            <Styled.Children id={`data-cell-children_${children}`}>{children}</Styled.Children>
        </Styled.DataCell>
    )
}
