import {createSelector} from 'reselect'
import {isFetchingEtsSelector} from '../../../../../store/state/ets-value/selectors'
import {
    isFetching24HourTrendDataSelector,
    isFetching7DaysTrendDataSelector,
} from '../../../../../store/state/ets-trend/selectors'

export const loadingReselector = createSelector(
    isFetchingEtsSelector,
    isFetching24HourTrendDataSelector,
    isFetching7DaysTrendDataSelector,
    (etsValueLoading, ets24HourTrendLoading, ets7DaysTrendLoading): boolean => {
        return etsValueLoading || ets24HourTrendLoading || ets7DaysTrendLoading
    },
)
