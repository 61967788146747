import styled, {keyframes, css} from 'styled-components'
import {ToastType, ToastPosition} from './types'

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const StyledToast = styled.div<{type: ToastType}>`
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    max-width: 400px;
    margin-bottom: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    animation: ${slideIn} 0.3s ease-out;
    background-color: ${(props) => {
        switch (props.type) {
            case 'success':
                return '#2ecc71'
            case 'warning':
                return '#f39c12'
            case 'error':
                return '#e74c3c'
            default:
                return '#3498db'
        }
    }};
    color: white;
`

export const ToastMessage = styled.p`
    margin: 0;
    font-size: 14px;
`

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    color: white;
`

export const ToastContainer = styled.div<{position: ToastPosition}>`
    position: fixed;
    display: flex;
    z-index: 9999;

    ${(props) => {
        switch (props.position) {
            case 'top-left':
                return css`
                    top: 20px;
                    left: 20px;
                    flex-direction: column;
                `
            case 'top-right':
                return css`
                    top: 20px;
                    right: 20px;
                    flex-direction: column;
                `
            case 'top-center':
                return css`
                    top: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    flex-direction: column;
                `
            case 'bottom-left':
                return css`
                    bottom: 20px;
                    left: 20px;
                    flex-direction: column-reverse;
                `
            case 'bottom-right':
                return css`
                    bottom: 20px;
                    right: 20px;
                    flex-direction: column-reverse;
                `
            case 'bottom-center':
                return css`
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    flex-direction: column-reverse;
                `
        }
    }}
`
