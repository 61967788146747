import {ThreatValue} from '../../../../helpers/threatHelper'
import {LocationIdType} from '../../../../store/state/locations/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {SoftwareStatus} from '../../../../store/state/software-inventory-filter/state'

export interface SoftwareInventoryModelOutput {
    identity: GuidType
    firstSeenTimestamp: string
    lastSeenTimestamp: string
    companyName: string | null
    productName: string
    totalNumberOfHostsPerItem: number
    totalNumberOfVesselsPerItem: number
    latestVersion: string | undefined
    oldestVersion: string | undefined
    status: SoftwareStatus
    isPortable: boolean
}

export const UNDEFINED_SOFTWARE_INVENTORY_MODEL_OUTPUT: SoftwareInventoryModelOutput = {
    identity: 'Unknown',
    firstSeenTimestamp: 'Unknown',
    lastSeenTimestamp: 'Unknown',
    companyName: null,
    productName: 'Unknown',
    totalNumberOfHostsPerItem: 0,
    totalNumberOfVesselsPerItem: 0,
    latestVersion: 'N/A',
    oldestVersion: 'N/A',
    status: SoftwareStatus.unknown,
    isPortable: false,
} as SoftwareInventoryModelOutput

export interface FormattedLocationDataForSoftwareInventory {
    id: LocationIdType
    name: string
    shipType: string
    numberOfAssets: string
    imoNumber: string
    score: number
    threatLevel: ThreatValue
    hosts: FormattedHostDataForSoftwareInventory[]
}
export interface FormattedHostDataForSoftwareInventory {
    identity: GuidType
    hostName: string
    lastSeenTimestamp: string
}
