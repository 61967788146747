import {useEffect} from 'react'
import {FilterBarButton} from '../../../../components/filter-button/filter-button'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {incidentsReportFilterSelector} from '../../../../store/state/incidents-report-filter/selectors'
import {activeFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {savedIncidentsReportFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {setVesselFilterPref} from '../../../../store/state/vessel-filter/action-creators'
import {
    activeVesselFilterSelector,
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../store/state/vessel-filter/selectors'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {ContentWrapper} from './data-actions.styled'
import {SearchedPeriod} from './searched-period/searched-period'
import {
    DEFAULT_INCIDENTS_REPORT_FILTER,
    IncidentsReportFilter,
} from '../../../../store/state/incidents-report-filter/state'
import {setSelectedFilterName} from '../../../../store/state/incidents-report-filter/action-creators'
import {useDispatch} from 'react-redux'
import {getFormattedIncidentsReportFilter} from '../../../../components/saved-search/components/shared/helper'
import {compareFilters, findIfDefaultFilterIsChanged} from '../../contexts/data-helper'

export function FilterBarButtonWrapper(): JSX.Element {
    const {displayFilterBar, showFilterBar, refreshData, setFilter} = usePagedIncidentsReport()
    const {width} = useDimensions()
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)
    const activeFilter = useTypedSelector(activeFilterSelector)

    const dispatch = useDispatch()

    const {
        fromDate,
        toDate,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        selectedFilterName,
    } = useTypedSelector(incidentsReportFilterSelector)
    const newFilter = useTypedSelector(incidentsReportFilterSelector)
    const currentFilter = useTypedSelector(savedIncidentsReportFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria
    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)
    const isNotPopulated = vesselFilterLoading === LoadingState.NotPopulated

    useEffect(() => {
        if (isNotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.INCIDENTS_REPORT,
                ),
            )
        }
    }, [dispatch, searchVesselNameTerm, searchVesselTagTerm, locations, vesselFilterLoading])

    useEffect(() => {
        const filterLocations = isNotPopulated ? locations : vesselFilterLocations
        const filterTagTerm = isNotPopulated ? searchVesselTagTerm : vesselFilterTagTerm
        const filterNameTerm = isNotPopulated ? searchVesselNameTerm : vesselFilterNameTerm

        // Compare the current filter with the default state
        const isDefaultFilter = findIfDefaultFilterIsChanged(
            DEFAULT_INCIDENTS_REPORT_FILTER,
            filterLocations,
            fromDate,
            toDate,
            filterTagTerm,
            filterNameTerm,
        )
        if (isDefaultFilter) {
            refreshData()
        } else {
            compareFilters(
                currentFilter
                    ? getFormattedIncidentsReportFilter(currentFilter as IncidentsReportFilter)
                    : DEFAULT_INCIDENTS_REPORT_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))
            setFilter(fromDate, toDate, filterLocations, filterTagTerm, filterNameTerm)
        }
    }, [
        vesselFilterLocations,
        vesselFilterTagTerm,
        vesselFilterNameTerm,
        vesselFilterLoading,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        fromDate,
        toDate,
    ])

    return (
        <ContentWrapper width={width} id="FilterBarButtonWrapper">
            <FilterBarButton
                onClick={() => {
                    displayFilterBar(!showFilterBar)
                }}
                active={activeFilter || activeVesselFilter}
            />
            <SearchedPeriod />
        </ContentWrapper>
    )
}
