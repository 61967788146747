import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Box = styled.div`
    margin: ${spacing(4)} 0 ${spacing(4)};
    white-space: pre-line;
`

export const Header = styled.h2`
    color: black;
    font-size: 18px;
    line-height: 25px;
`
