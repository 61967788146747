import * as Styled from './assign-policy-dialog.styled'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {AllPolicyData} from '../../../contexts/policy-management/type/policy-management-state'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {DialogContent} from './dialog-content'

interface AssignPolicyDialogProps {
    policy: AllPolicyData
}

export function AssignPolicyDialogCard({policy}: AssignPolicyDialogProps): JSX.Element {
    const {closeAssignPolicyDialog} = usePolicyManagement()
    const {width} = useDimensions()

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        closeAssignPolicyDialog()
    })

    return (
        <Styled.AssignPolicyDialogWrapperCard ref={clickOutside}>
            <Styled.ArrowUpCard width={width} />
            <Styled.AssignPolicyDialogCard width={width}>
                <DialogContent policy={policy} />
            </Styled.AssignPolicyDialogCard>
            <Styled.ArrowUpInnerCard width={width} />
        </Styled.AssignPolicyDialogWrapperCard>
    )
}
