import {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {getUserDescription} from '../../../../../helpers/getUserDescription'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {
    setSelectedAssigngToGuest,
    setSelectedAssigngToUser,
    setVesselEmail,
} from '../../../../../store/state/incidents-filter/action-creators'
import {
    incidentsFilterAssignedToSelector,
    incidentsFilterAssignedToVesselEmailSelector,
    incidentsFilterGuestEmailToSelector,
} from '../../../../../store/state/incidents-filter/selectors'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'

import * as Styles from './assigned-to-filter.styled'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function AssignedToFilter(): JSX.Element | null {
    const {getAssignedGuestEmailsFn, guestEmails} = usePagedIncidents()
    const assignedTo = useTypedSelector(incidentsFilterAssignedToSelector)
    const guestAssignedTo = useTypedSelector(incidentsFilterGuestEmailToSelector)
    const vesselEmailAssignedTo = useTypedSelector(incidentsFilterAssignedToVesselEmailSelector)
    const users = useTypedSelector(usersSelector).filter(
        (user) => !user.internalUser && user.active && !user.deleted,
    )
    const [meOption, setMeOption] = useState(false)
    const currentUser = useTypedSelector(currentUserSelector)
    const assignedToName = assignedTo && getUserDescription(users, assignedTo, undefined)

    const [optionsVisible, setOptionsVisible] = useState(false)

    let vesselEmailAssignedToName
    if (typeof vesselEmailAssignedTo === 'boolean') {
        vesselEmailAssignedToName = 'Assigned to vessel email'
    }

    const assignedToValue = assignedToName || guestAssignedTo || vesselEmailAssignedToName
    const [optionValue, setOptionValue] = useState(assignedToValue)
    useEffect(() => {
        setOptionValue(assignedToValue)
    }, [assignedToValue])

    const [openMedullaUsers, setOpenMedullaUsers] = useState(false)
    const [openGuestUsers, setOpenGuestUsers] = useState(false)
    const dispatch = useDispatch()

    function onChangeUserAssign(value: string): void {
        if (guestAssignedTo != undefined) {
            dispatch(setSelectedAssigngToGuest(''))
        }
        if (vesselEmailAssignedTo !== null) {
            dispatch(setVesselEmail(null))
        }
        dispatch(
            logFilterByTypes(
                'filterByAssignedTo-Medulla User',
                `${value ? value : 'All'}`,
                true,
                AuditLogPageType.INCIDENTS,
            ),
        )

        dispatch(setSelectedAssigngToUser(value))
        setOpenMedullaUsers(false)
        meOption && setMeOption(false)
    }

    function onChangeVesselEmail(value: boolean | null): void {
        if (assignedTo != undefined) {
            dispatch(setSelectedAssigngToUser(''))
        }
        if (guestAssignedTo != undefined) {
            dispatch(setSelectedAssigngToGuest(''))
        }
        dispatch(setVesselEmail(value))
        setOpenMedullaUsers(false)
        meOption && setMeOption(false)
    }

    function onChangeGuestAssign(value: string): void {
        if (assignedTo != undefined) {
            dispatch(setSelectedAssigngToUser(''))
        }
        if (vesselEmailAssignedTo !== null) {
            dispatch(setVesselEmail(null))
        }
        dispatch(
            logFilterByTypes(
                'filterByAssignedTo-Guest email',
                `${value ? value : 'All'}`,
                true,
                AuditLogPageType.INCIDENTS,
            ),
        )
        dispatch(setSelectedAssigngToGuest(value))
        setOpenGuestUsers(false)
        meOption && setMeOption(false)
    }
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
        openMedullaUsers && setOpenMedullaUsers(false)
        openGuestUsers && setOpenGuestUsers(false)
    })
    const resetValue = !assignedTo && !guestAssignedTo && !vesselEmailAssignedTo
    let display: unknown = '- All -'
    if (!resetValue) {
        if (meOption) {
            display = currentUser.username
        } else {
            display = optionValue
        }
    }
    return (
        <Styles.AssignedToWrapper ref={clickOutside} id="assignedToFilter-wrapper">
            <Styles.Label>Assigned to:</Styles.Label>
            <Styles.Input>
                <Styles.WrapperOption
                    onClick={() => setOptionsVisible(true)}
                    id="assignedToFilter-wrapper-option"
                >
                    {display as string}
                </Styles.WrapperOption>

                {optionsVisible && (
                    <Styles.OptionsVisible>
                        <Styles.Option
                            key="default-option"
                            onClick={() => {
                                onChangeUserAssign('')
                                onChangeGuestAssign('')
                                setOptionValue('- All -')
                                setOptionsVisible(false)
                            }}
                            id="assignedToFilter-default-option"
                        >
                            - All -
                        </Styles.Option>
                        <Styles.Option
                            key="medulla-option"
                            onClick={() => {
                                setOpenMedullaUsers(true)
                                setOptionsVisible(false)
                            }}
                            id="assignedToFilter-medulla-option"
                        >
                            Choose Medulla user
                        </Styles.Option>

                        <Styles.Option
                            key="me-option"
                            onClick={() => {
                                setMeOption(true)
                                onChangeUserAssign(currentUser.user)
                                setOptionValue(currentUser.username)
                                setOptionsVisible(false)
                            }}
                            id="assignedToFilter-me-option"
                        >
                            Assigned to me
                        </Styles.Option>

                        <Styles.Option
                            key="guest-option"
                            onClick={() => {
                                getAssignedGuestEmailsFn()
                                setOpenGuestUsers(true)
                                setOptionsVisible(false)
                            }}
                            id="assignedToFilter-guest-option"
                        >
                            Choose guest user
                        </Styles.Option>

                        <Styles.Option
                            key="vessel-email"
                            onClick={() => {
                                onChangeUserAssign('')
                                onChangeGuestAssign('')
                                onChangeVesselEmail(true)
                                setOptionsVisible(false)
                            }}
                            id="assignedToFilter-vessel-option"
                        >
                            Assigned to vessel email
                        </Styles.Option>
                    </Styles.OptionsVisible>
                )}

                {openMedullaUsers && (
                    <Styles.ValueOptions>
                        {users?.map((user) => (
                            <Styles.ValueOption
                                key={user.user}
                                onClick={() => {
                                    onChangeUserAssign(user.user)
                                    setOptionValue(user.username)
                                }}
                                id={`openMedullaUsers-${user.user}`}
                            >
                                {user.username}
                            </Styles.ValueOption>
                        ))}
                    </Styles.ValueOptions>
                )}
                {openGuestUsers && (
                    <Styles.ValueOptions>
                        {guestEmails?.map((guestEmail) => (
                            <Styles.ValueOption
                                key={guestEmail.guestEmail}
                                onClick={() => {
                                    onChangeGuestAssign(guestEmail.guestEmail)
                                    setOptionValue(guestEmail.guestEmail)
                                }}
                                id={`openGuestUsers-${guestEmail.guestEmail}`}
                            >
                                {guestEmail.guestEmail}
                            </Styles.ValueOption>
                        ))}
                    </Styles.ValueOptions>
                )}
            </Styles.Input>
        </Styles.AssignedToWrapper>
    )
}
