import {LocationMap} from '../../../../store/state/locations/state'
import {NodeDataMap} from '../../../../values/nodes/NodeData'
import {SelfMonitoringEngineRuleMap} from '../../../../values/self-monitoring-rule'
import {SelfMonitoringEngineEvent} from '../../../../values/self-monitoring-event'
import {GuidType} from '../../../../values/generic-type-defintions'

export function getLocationDescription(
    event: SelfMonitoringEngineEvent,
    locations: LocationMap,
    nodes: NodeDataMap,
): string {
    return (
        (isEventLocationEmpty(event)
            ? getLocationFromAsset(event, locations, nodes)
            : getLocationFromEvent(event.location, locations)) || 'N/A'
    )
}

function isEventLocationEmpty(event: SelfMonitoringEngineEvent): boolean {
    return event?.location == null
}

function getLocationFromEvent(locationId: GuidType, locations: LocationMap): string {
    return locations?.get(locationId)?.description || 'Unknown'
}

function getLocationFromAsset(
    event: SelfMonitoringEngineEvent,
    locations: LocationMap,
    nodes: NodeDataMap,
): string | undefined {
    const assetLocationId = nodes?.get(event?.node)?.location
    return assetLocationId ? locations?.get(assetLocationId)?.description : undefined
}

export function getRuleDescription(
    event: SelfMonitoringEngineEvent,
    rules: SelfMonitoringEngineRuleMap,
    nodes: NodeDataMap,
    locations: LocationMap,
): string {
    const ruleMessageTemplate = rules?.get(event?.rule)?.logMessageTemplate
    const aliasDescription = nodes?.get(event?.node)?.alias
    const locationDescription = getLocationDescription(event, locations, nodes)

    if (!ruleMessageTemplate || ruleMessageTemplate?.length === 0) {
        return aliasDescription && aliasDescription?.length > 0
            ? `No rule to display for ${aliasDescription}`
            : 'No rule to display'
    }

    return ruleMessageTemplate
        .replace('<asset>', aliasDescription || 'Unknown')
        .replace('<location>', locationDescription || 'Unknown')
}
