import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../../@assets/icons/close-panel.svg'
import {useUserManagement} from '../../../../context/use-user-management'

export function DetailPanelHeader(): JSX.Element {
    const {closeUserDetailModal, activeUserDetails} = useUserManagement()

    return (
        <Styled.DetailPanelHeader>
            <Styled.Title>{activeUserDetails.username}</Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeUserDetailModal()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
