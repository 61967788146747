import {animated} from 'react-spring'
import styled from 'styled-components'

export const Box = styled(animated.div)`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;
    width: 3rem;
`
