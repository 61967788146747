import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerBottom} from '../../../../templates/fixed-page/content-container-bottom.styled'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {ActionBar} from '../action-bar/action-bar'
import {Pagination} from '../footer/paging/pagination'
import {RecordSet} from '../footer/record-set/record-set'
import {TableHeader} from './header/table-header'
import {ReportsTableContent} from './reports-table-content'
import {ReportsTable} from './_styled/table-populated.styled'
import {Footer} from '../../../../components/table-view-components/footer.styled'

export function ReportsTableFormat(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <ReportsTable>
            <ActionBar />
            <ContentContainerMiddle width={width}>
                <TableHeader />
            </ContentContainerMiddle>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.reports.dataArea.background}
            >
                <ReportsTableContent />
            </ContentContainerMiddle>
            <ContentContainerBottom width={width}>
                <Footer width={width} id="footer-row">
                    <Pagination />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </ReportsTable>
    )
}
