import CommonEvent from '../../../../../values/common-event/common-event'
import {formatDate, normaliseScore} from '../../../../../helpers/formatting'
import threatHelper, {ThreatValue} from '../../../../../helpers/threatHelper'
import {NodeData, NodeValues} from '../../../../../values/nodes/NodeData'
import {ThreatMeasureMap} from '../../../../../values/ThreatMeasure'
import {IpAddressType} from '../../../../../values/generic-type-defintions'
import CommonEventMonaDetails from '../../../../../values/common-event/common-event-mona-details'
import DetailStringFormatterFactory from './matrix-detail-formatter/detail-string-formatter-factory'
import NetworkAnomalyMatrixRecordMap from '../../../../../values/anomalies/network-anomaly-matrix-record-map'

function getEventTypeDescription(type: string): string {
    switch (type) {
        case 'MONA_EVENT':
            return 'Inference'
        case 'GANDALF_EVENT':
            return 'Detection'
        case 'SELF_MONITORING_EVENT':
            return 'System'
        case 'INCIDENT_RESPONSE_NEW_EVENT':
        case 'INCIDENT_RESPONSE_CLOSED_EVENT':
        case 'INCIDENT_RESPONSE_OPEN_EVENT':
            return 'Incident'
        default:
            return 'Unknown'
    }
}

function getEngineTypeDescription(type: string): string {
    switch (type) {
        case 'SFM':
            return 'System'
        case 'PEERING':
            return 'Peer'
        case 'MONA':
            return 'Network'
        case 'MANUAL':
            return 'N/A'
        case 'GANDALF':
            return 'Signature'
        case 'SELF_MONITORING':
            return 'Availability'
        default:
            return 'Unknown'
    }
}

function isMessageForServer(
    node: NodeData | undefined,
    sourceIpAddress: IpAddressType,
    destinationIpAddress: IpAddressType,
): boolean | null {
    if (node == undefined) {
        return null
    }

    if (node.ipAddress === destinationIpAddress) {
        return true
    }

    if (node.ipAddress === sourceIpAddress) {
        return false
    }

    return null
}

function getFormattedDetail(
    commonEvent: CommonEvent,
    networkAnomalyMatrixRecords: NetworkAnomalyMatrixRecordMap | null,
    node: NodeData | undefined,
): string | null | undefined {
    if (!commonEvent.matrix || !networkAnomalyMatrixRecords) {
        return commonEvent.details
    }

    const matrixRecord = networkAnomalyMatrixRecords?.get(commonEvent.matrix)
    if (!matrixRecord) {
        // eslint-disable-next-line no-console
        console.error(
            `Can not find matrix record for matrix id: ${commonEvent.matrix} (Common Event ID: ${commonEvent.identity})`,
        )
        return commonEvent.details
    }

    const monaDetails = commonEvent as CommonEventMonaDetails
    const messageForServer = isMessageForServer(
        node, //s.get(commonEvent.node),
        monaDetails.sourceIpAddress,
        monaDetails.destinationIpAddress,
    )
    if (messageForServer == null) {
        // eslint-disable-next-line no-console
        console.error(
            `Can not determine if message is for server: Common Event ID: ${commonEvent.identity}`,
        )
        return commonEvent.details
    }

    const isPublic = monaDetails.destinationType
        ? monaDetails.destinationType.toLowerCase() !== 'private'
        : false

    const details = DetailStringFormatterFactory.build(messageForServer, isPublic).format(
        monaDetails,
        matrixRecord,
    )
    return details
}

export class AssetOutputModel {
    public readonly formattedTimeStamp: string
    public readonly score: number
    public readonly threatLevel: ThreatValue
    public readonly eventType: string
    public readonly engineType: string
    public readonly details: string | null | undefined

    public constructor(
        commonEvent: CommonEvent,
        threatMeasures: ThreatMeasureMap,
        networkAnomalyMatrixRecords: NetworkAnomalyMatrixRecordMap | null,
        node: NodeData | undefined,
    ) {
        this.score = normaliseScore(commonEvent.score)
        this.formattedTimeStamp = formatDate(commonEvent.timestamp)
        this.threatLevel = threatHelper(this.score, threatMeasures, NodeValues.HIGH)
        this.eventType = getEventTypeDescription(commonEvent.eventType)
        this.engineType = getEngineTypeDescription(commonEvent.engineType)
        this.details = getFormattedDetail(commonEvent, networkAnomalyMatrixRecords, node)
    }
}
