import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {useVesselManagement} from '../../contexts/use-vessel-management'
import * as Styled from './_styled/vessel-management-list-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    const {activeVesselId, showFilterBar} = useVesselManagement()
    const displayVesselDetailsModal = activeVesselId != null

    return (
        <Styled.TableHeaderGrid
            width={width}
            showReducedGrid={displayVesselDetailsModal || showFilterBar}
        >
            <Styled.TableHeaderCell gridColumn={2} width={width}>
                Vessel
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={3} width={width}>
                Deployment status
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={4} width={width}>
                Tags
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={5} width={width}>
                Network settings
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={6} width={width}>
                Comms settings
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={7} width={width}>
                Incident policy
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={8} width={width}>
                Onboard engagement
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={9} width={width}>
                Network enforcement
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={10} width={width}>
                Removable enforcement
            </Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
