import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {getGridTemplateColumns, getReducedGridTemplateColumns} from '../../data-helpers'
interface Props {
    width: number
    showReducedGrid: boolean
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showReducedGrid
            ? getReducedGridTemplateColumns(props.width)
            : getGridTemplateColumns(props.width)};
    gap: ${spacing(2)};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${(props) => props.theme.softwareInventory.dataArea.background};
`
