import {getLines} from '../../../incidents-v3/data-helpers'
import {SimpleButton} from '../../../../components/filter-button/simple-button'
import * as Styled from './new-open-incidents-details.styled'
import {useEffect, useState} from 'react'
import {buildIncidentNotesMap} from '../../contexts/types/incidents-notes-map.builder'
import {IncidentHistoryOutputModel} from '../../../incidents-v3/models/history-record.model'
import {formatDate} from '../../../../helpers/formatting'
import {GuidType} from '../../../../values/generic-type-defintions'

interface LatestNotesProps {
    incidentHistory: IncidentHistoryOutputModel[]
    incidentId: GuidType
}
export function LatestNotes({incidentHistory, incidentId}: LatestNotesProps): JSX.Element {
    const [indexNoteToDisplay, setIndexNoteToDisplay] = useState(0)
    const incidentNotesMap = buildIncidentNotesMap(incidentHistory)

    const lastNote = incidentNotesMap.get(indexNoteToDisplay)

    let who = lastNote?.createdBy ?? 'Unknown'
    if (lastNote?.isAddedOnVessel) {
        who = 'Note added on vessel'
    }
    const serviceAccount = who === 'Basal Ganglia Service Account User'

    useEffect(() => {
        setIndexNoteToDisplay(0)
    }, [incidentId])

    return (
        <>
            <Styled.SectionText>Notes</Styled.SectionText>
            {!lastNote ? (
                <Styled.NoEvents>No notes to display</Styled.NoEvents>
            ) : (
                <Styled.Note id={`incident-report-lattest-note-wrapper`}>
                    <Styled.NoteDetail id={`incident-note-detail`}>
                        <SimpleButton
                            label="&lt;"
                            onClick={() => {
                                setIndexNoteToDisplay(indexNoteToDisplay - 1)
                            }}
                            disabled={indexNoteToDisplay === 0}
                        />
                        <SimpleButton
                            onClick={() => {
                                setIndexNoteToDisplay(indexNoteToDisplay + 1)
                            }}
                            label="&gt;"
                            disabled={incidentNotesMap.size - indexNoteToDisplay === 1}
                        />
                        <Styled.When id={`incident-note-detail-when`}>
                            {formatDate(lastNote?.updatedWhen) ?? 'Unknown'}
                        </Styled.When>
                        <Styled.Who id={`incident-note-detail-who`}>
                            {serviceAccount ? 'Cyberowl Threat Analyst' : who}
                        </Styled.Who>
                    </Styled.NoteDetail>

                    <Styled.Text id={`incident-note-detail-text`}>
                        {getLines(lastNote?.text).map((line, index) => (
                            <div key={index}>{line}</div>
                        ))}
                    </Styled.Text>
                </Styled.Note>
            )}
        </>
    )
}
