import {useState, useEffect} from 'react'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../../store/state/email-recipient-management/selectors'
import {useUserManagement} from '../../../context/use-user-management'
import {ExternalRolesSelect} from '../../user-list-table/user-details-modal/roles/external-roles-select'
import {InternalRolesSelect} from '../../user-list-table/user-details-modal/roles/internal-roles-select'
import {SubmitArea} from '../../user-list-table/user-details-modal/submit-area/submit-area'
import * as Styled from './user-details-extended.styled'
import {TagFilter} from '../../shared/tag-limit/tag-filter'
import {TagLimit} from '../../shared/tag-limit/tag-limit'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataInput} from '../../user-list-table/user-details-modal/data-input/data-input'
import {SavedFiltersAlertsContent} from '../../user-list-table/user-details-modal/saved-filters-alerts/saved-filters-alerts-content'
import {UserManagementDetailType} from '../../../context/type/user-management-state'
import {findUserSubscriptions} from '../../../../../store/state/saved-filters/action-creators'
import {useDispatch} from 'react-redux'
import {HistorySectionContent} from '../../user-list-table/user-details-modal/history/history-section-content'

export function UserDetails(): JSX.Element | null {
    const {width} = useDimensions()
    const {
        activeUserDetails,
        displayUserDetails,
        initialUserDetails,
        changeUserName,
        changeUserEmail,
        setSubscription,
    } = useUserManagement()
    const dispatch = useDispatch()

    const subscriptionData = useTypedSelector(emailRecipientDataSelector)
    const initialSubscriptionValue = subscriptionData.some(
        (data) => data.user === activeUserDetails?.user && data.notificationType === 'NEW_INCIDENT',
    )

    const [checked, setChecked] = useState(false)
    const emailId =
        subscriptionData.find(
            (data) =>
                data.user === activeUserDetails?.user && data.notificationType === 'NEW_INCIDENT',
        )?.identity ?? ''

    useEffect(() => {
        setChecked(initialSubscriptionValue)
    }, [initialUserDetails, initialSubscriptionValue])

    useEffect(() => {
        dispatch(findUserSubscriptions(activeUserDetails.user))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUserDetails.user])

    const onChangeWrapper = () => {
        setChecked(!checked)
        setSubscription({
            initialSubscriptionStatus: initialSubscriptionValue,
            newStatus: !checked,
            userId: activeUserDetails.user,
            emailId: emailId,
        })
    }

    return (
        <>
            <Styled.ModalTitle>{UserManagementDetailType.UserDetails}</Styled.ModalTitle>
            <Styled.Details width={width}>
                <DataInput
                    label="Name"
                    initialValue={displayUserDetails.username}
                    setChange={changeUserName}
                    changed={activeUserDetails.username !== initialUserDetails.username}
                />
                <DataInput
                    label="Email"
                    initialValue={displayUserDetails.email}
                    setChange={changeUserEmail}
                    changed={activeUserDetails.email !== initialUserDetails.email}
                />
                <TagFilter
                    label="Tag"
                    changed={activeUserDetails.assignedTag !== initialUserDetails.assignedTag}
                />
                <TagLimit label="Lock" />
            </Styled.Details>
            <Styled.ModalTitle>{UserManagementDetailType.Roles}</Styled.ModalTitle>
            <Styled.Roles>
                {activeUserDetails?.internalUser ? (
                    <InternalRolesSelect />
                ) : (
                    <ExternalRolesSelect />
                )}
            </Styled.Roles>
            {!activeUserDetails?.internalUser && (
                <>
                    <Styled.ModalTitle>{UserManagementDetailType.Subscriptions}</Styled.ModalTitle>
                    <Styled.Subscriptions>
                        <Styled.DetailRow>
                            <Checkbox
                                id={'user-management-subscriptions'}
                                checked={checked}
                                onChange={onChangeWrapper}
                                label={'Incidents'}
                            />
                        </Styled.DetailRow>
                    </Styled.Subscriptions>
                </>
            )}
            <Styled.ModalTitle style={{paddingBottom: '5px'}}>
                {UserManagementDetailType.SavedFiltersAlerts}
            </Styled.ModalTitle>
            <SavedFiltersAlertsContent />
            <SubmitArea />
            <Styled.ModalTitle style={{paddingBottom: '5px'}}>
                {UserManagementDetailType.History}
            </Styled.ModalTitle>
            <HistorySectionContent />
        </>
    )
}
