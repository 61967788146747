import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './_styled/card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/card-data-cell.styled'
import {AllPolicyData} from '../../contexts/policy-management/type/policy-management-state'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    policy: AllPolicyData
    identifier: string
}
export function CardDataCell({
    label,
    children,
    gridArea,
    policy,
    identifier,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `policy-management-${identifier}_${policy.identity}`
    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            <Styled.Label htmlFor={id}>{label}</Styled.Label>
            <Styled.Children
                id={id}
                gridArea={gridArea}
                width={width}
                style={{position: 'relative', overflow: 'visible'}}
            >
                {children}
            </Styled.Children>
        </Styled.CardDataCell>
    )
}
