import {useDispatch} from 'react-redux'
import {
    closeModal,
    reviewNote,
    setPendingNavigation,
} from '../../../../../store/state/unsaved-incident-note-alert-modal/action-creators'
import * as Styled from './alert-modal.styled'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {SaveMessage} from '../../../contexts/types/save-note-result'
import {Overlay} from '../../../../../modals/shared-components/modal-overlay'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {pendingNavigationSelector} from '../../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {useHistory} from 'react-router'

export function AlertModal(): JSX.Element {
    const dispatch = useDispatch()
    const {setSaveMessage} = usePagedIncidents()
    const history = useHistory()
    const pendingNavigation = useTypedSelector(pendingNavigationSelector)
    function cancelChanges() {
        dispatch(closeModal())
        setSaveMessage(undefined)
        if (pendingNavigation) {
            history.push(pendingNavigation)
            dispatch(setPendingNavigation(null))
        }
    }
    function reviewChanges() {
        setSaveMessage(SaveMessage.NOTE_REVIEW)
        dispatch(reviewNote())
        if (pendingNavigation) {
            dispatch(setPendingNavigation(null))
        }
    }
    return (
        <Overlay>
            <Styled.AlertWrapper onClick={(e) => e.stopPropagation()} id="AlertWrapper">
                <Styled.Title>Discard changes?</Styled.Title>
                <Styled.Message id="AlertModal-Message">
                    Some of the changes you have made to the notes will not be saved if you select
                    discard.
                </Styled.Message>
                <Styled.ButtonsArea>
                    <Styled.ButtonStyled id="cancel-changes-button" onClick={cancelChanges}>
                        Discard
                    </Styled.ButtonStyled>
                    <Styled.ButtonStyled id="review-button" onClick={reviewChanges}>
                        Cancel & Review
                    </Styled.ButtonStyled>
                </Styled.ButtonsArea>
            </Styled.AlertWrapper>
        </Overlay>
    )
}
