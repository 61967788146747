import styled from 'styled-components'

interface InputProps {
    width?: number
}

export const Input = styled.input<InputProps>`
    width: ${(props) => props.width ?? 220}px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`
