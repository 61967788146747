import {DetailsSection} from './details/details-section'
import {RolesSection} from './roles/roles-section'
import {DetailPanelHeader} from './header/detail-panel-header'
import {SubmitArea} from './submit-area/submit-area'
import {SubscriptionsSection} from './subscriptions/subscriptions-section'
import * as Styled from './_styled/user-details.styled'
import {useUserManagement} from '../../../context/use-user-management'
import {NoDataModal} from './no-data-modal'
import {HistorySection} from './history/history-section'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {fetchAuditLog} from '../../../../../store/state/audit-log/action-creators'
import {SavedFiltersAlerts} from './saved-filters-alerts/saved-filters-alerts'
import {findUserSubscriptions} from '../../../../../store/state/saved-filters/action-creators'

export function UserDetails(): JSX.Element | null {
    const {activeUserDetails} = useUserManagement()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAuditLog(activeUserDetails.user))
        dispatch(findUserSubscriptions(activeUserDetails.user))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUserDetails.user])

    if (!activeUserDetails) {
        return <NoDataModal />
    }

    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader />
            <DetailsSection />
            <RolesSection />
            {!activeUserDetails.internalUser && <SubscriptionsSection />}
            <SavedFiltersAlerts />
            <SubmitArea />
            <HistorySection />
        </Styled.Container>
    )
}
