import styled from 'styled-components'

import {MAX_SCREEN_WIDTH} from '../../../../../theme/theme'

interface WideContentAreaProps {
    width: number
}
export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    overflow: hidden;
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    width: 100%;
`
export const IncidentsTable = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`
export const LHFilterBar = styled.div`
    overflow-y: auto;
    overflow-x: none;
    width: 390px;
`
