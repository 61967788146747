import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`
export const LeftHorizontalBar = styled.span`
    border-bottom: solid 1px ${(props) => props.theme.colors.border.lowContrast};
`
export const RightHorizontalBar = styled.span`
    flex-grow: 1;
    border-bottom: solid 1px ${(props) => props.theme.colors.border.lowContrast};
`

export const SelectedTab = styled.div`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    text-align: center;
    align-items: center;
    padding: 10px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.border.lowContrast};
    color: ${(props) => props.theme.modalDialog.tabs.selected.text};
    background-color: ${(props) => props.theme.modalDialog.tabs.selected.background};
`
interface NotSelectedTabProps {
    isChanged: boolean
    firstTab?: boolean
}

export const NotSelectedTab = styled.span<NotSelectedTabProps>`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    text-align: center;
    align-items: center;
    padding: 10px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.border.lowContrast};
    color: ${(props) => props.theme.modalDialog.tabs.notSelected.text};
    background-color: ${(props) => props.theme.modalDialog.tabs.notSelected.background};
    ${(props) => (props.isChanged ? 'pointer-events: none; opacity: 0.5;' : 'cursor:pointer;')};
`
