import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import {ExternalRole} from '../../../../../../values/Role'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './roles-section.styled'

export function ExternalRolesSelect(): JSX.Element {
    const externalRoles = ['CUSTOMER_ADMIN', 'USER'] as ExternalRole[]
    const {displayUserDetails, assignCurrentUserRole, deassignCurrentUserRole} = useUserManagement()

    return (
        <>
            {externalRoles.map((role, index) => (
                <Styled.DetailRow key={index}>
                    <Checkbox
                        id={`user-management-role_${role}`}
                        checked={displayUserDetails.roles.includes(role)}
                        onChange={() =>
                            displayUserDetails.roles.includes(role)
                                ? deassignCurrentUserRole(role)
                                : assignCurrentUserRole(role)
                        }
                        label={role}
                    />
                </Styled.DetailRow>
            ))}
        </>
    )
}
