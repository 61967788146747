import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../store/state/locations/state'
import {usePagedVesselsBeta} from '../../contexts/use-paged-vessels-beta'
import {Location} from '../../../../store/state/locations/state'
import {VesselBetaTableDetailsModel} from '../../contexts/types/vessel-beta-table-details-model'
import {PossibleColumnsVesselBeta} from '../../contexts/types/paged-vessels-beta-state'
import {AssetThreatScoreOutputModel} from '../../../my-vessels-v2/listing/reselectors/asset-threat-score.model'
import {NodeValues} from '../../../../values/nodes/NodeData'
import {MetricType} from '../../../metrics-beta/contexts/types/metrics-response'
import {FrameworkSummaryTrendResponse} from '../../contexts/types/framework-summary-trends-api'
import {FrameworkSummaryTrend} from './table-components/framework-summary-trend'
import {CurrentVesselScoreResponse} from '../../contexts/types/current-vessel-score-model-api'
import {CurrentVesselScore} from './table-components/current-vessel-score'
import {FrameworkScoreCardResponse} from '../../contexts/types/framework-score-card-api'
import {ScoreBar} from './table-components/score-bar'
import {SummaryScoreCard} from './table-components/summary-score-card'
import {TotalInventoryAllTypesResponse} from '../../contexts/types/total-inventory-all-types-api'
import {InventoryItems} from './table-components/inventory-items'
import {FrameworkSummaryBenchmarkResponse} from '../../contexts/types/framework-summary-benchmark-api'
import {BenchmarkSummary} from './table-components/benchmark-summary'
import {OldestOpenIncidentResponse} from '../../contexts/types/oldest-open-incident-api'
import {OldestIncident} from './table-components/oldest-open-incident'
import * as Styled from './_styled/table-data.styled'
import {MissingInvetoryAllTypesResponse} from '../../contexts/types/missing-inventory-all-types-api'
import {MissingNetworkItems} from './table-components/missing-network-items'
import {MissingMonitoredAssetsResponse} from '../../contexts/types/missing-monitored-assets-inventory-api'
import {NumberOfDaysSinceLastDataResponse} from '../../contexts/types/number-of-days-since-last-data-api'
import {NumberOfDaysItems} from './table-components/number-of-days-since-last-data'
import {AssetThreatScoresGrid} from './table-components/assets-at-risk-score'
import {selectedColumnsVesselsBetaFilterSelector} from '../../../../store/state/vessels-beta-filter/selectors'
import {FrameworkSummaryTargetResponse} from '../../contexts/types/framework-summary-target-api'
import {FrameworkSummaryTarget} from './table-components/framework-summary-target'
import {NumberOfIncidentsResponse} from '../../contexts/types/number-of-incidents-raised-and-open'
import {IncidentsNumber} from './table-components/incidents-numbers'
import {AverageTimeToResolveIncidentsResponse} from '../../contexts/types/average-time-to-resolve-incidents'
import {TotalAverageTime} from './table-components/total-average-time-to-resolve-incidents'
import {VesselDeploymentStatus} from '../../../vessel-management/contexts/type/deployment-status-types'
import {DeploymentSummaryCell} from './table-components/deployment-summary-cell'
interface TableRowProps {
    locationId: LocationIdType
    index: number
}

export function TableRow({index, locationId}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const {tableVesselsDataMap} = usePagedVesselsBeta()
    const selectedColumns = useTypedSelector(selectedColumnsVesselsBetaFilterSelector)

    const locations = useTypedSelector(locationMapSelector)
    const location: Location | undefined = locations?.get(locationId)

    const findValueToDisplayForGivenColumn = (
        selectedColumn: PossibleColumnsVesselBeta,
    ): JSX.Element | null => {
        const valuesToDisplay =
            tableVesselsDataMap?.get(selectedColumn) ?? ({} as VesselBetaTableDetailsModel)
        const nodeListForAssetsAtRisk = valuesToDisplay.selectedColumnContent as Map<
            LocationIdType,
            AssetThreatScoreOutputModel[]
        >
        switch (selectedColumn) {
            case PossibleColumnsVesselBeta.CurrentVesselScore:
                const currentVesselOutput =
                    valuesToDisplay.selectedColumnContent as CurrentVesselScoreResponse[]

                const foundOutputLocation = currentVesselOutput?.find((outputLocationId) => {
                    return outputLocationId.locationId === locationId
                })

                if (foundOutputLocation) {
                    return (
                        <CurrentVesselScore
                            locationId={foundOutputLocation.locationId}
                            score={foundOutputLocation.score}
                            threatLevel={foundOutputLocation.threatLevel}
                        />
                    )
                }

                return null
            case PossibleColumnsVesselBeta.AssetsAtRisk:
                const findAssetsAtRisk = nodeListForAssetsAtRisk?.get(locationId)
                if (findAssetsAtRisk) {
                    return (
                        <AssetThreatScoresGrid
                            locationId={locationId}
                            nodeList={findAssetsAtRisk}
                            selectedColumn={selectedColumn}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.AssetsAtRiskHighValue:
                const findNodeListForAssetsAtRisk = nodeListForAssetsAtRisk?.get(locationId)
                if (findNodeListForAssetsAtRisk) {
                    return (
                        <AssetThreatScoresGrid
                            locationId={locationId}
                            nodeList={findNodeListForAssetsAtRisk?.filter(
                                (highValueAssets) => highValueAssets.nodeValue === NodeValues.HIGH,
                            )}
                            selectedColumn={selectedColumn}
                        />
                    )
                }
                return null

            case PossibleColumnsVesselBeta.FrameworkProtection:
                const protectionScorecardContent =
                    valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
                const findProtectionFrameworkData = protectionScorecardContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findProtectionFrameworkData) {
                    return (
                        <ScoreBar
                            score={findProtectionFrameworkData.protectionScore ?? 0}
                            metricType={MetricType.PROTECTION}
                            displayGraph={findProtectionFrameworkData.protectionScore !== undefined}
                            identifier={findProtectionFrameworkData.locationId}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.FrameworkMaintenance:
                const maintenanceScorecardContent =
                    valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
                const findMaintenanceFrameworkData = maintenanceScorecardContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findMaintenanceFrameworkData) {
                    return (
                        <ScoreBar
                            score={findMaintenanceFrameworkData.maintenanceScore ?? 0}
                            metricType={MetricType.MAINTENANCE}
                            displayGraph={
                                findMaintenanceFrameworkData.maintenanceScore !== undefined
                            }
                            identifier={findMaintenanceFrameworkData.locationId}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.FrameworkBehaviour:
                const behaviourScorecardContent =
                    valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
                const findbehaviourFrameworkData = behaviourScorecardContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findbehaviourFrameworkData) {
                    return (
                        <ScoreBar
                            score={findbehaviourFrameworkData.behaviourScore ?? 0}
                            metricType={MetricType.BEHAVIOUR}
                            displayGraph={findbehaviourFrameworkData.behaviourScore !== undefined}
                            identifier={findbehaviourFrameworkData.locationId}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.FrameworkSummaryScorecard:
                const summaryScorecardContent =
                    valuesToDisplay.selectedColumnContent as FrameworkScoreCardResponse[]
                const findFrameworkData = summaryScorecardContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findFrameworkData ? (
                    <SummaryScoreCard sumaryContentScore={findFrameworkData} />
                ) : null
            case PossibleColumnsVesselBeta.TotalInventoryAllTypes:
                const totalInventoryAllTypesContent =
                    valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
                const findTotalInventoryAllTypesData = totalInventoryAllTypesContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findTotalInventoryAllTypesData ? (
                    <InventoryItems
                        totalAssets={findTotalInventoryAllTypesData.totalAssets}
                        totalUsbDevices={findTotalInventoryAllTypesData.totalUsbDevices}
                        totalSoftware={findTotalInventoryAllTypesData.totalSoftware}
                        locationId={locationId}
                        stateOfItems="total"
                    />
                ) : null
            case PossibleColumnsVesselBeta.UntrustedInventoryAllTypes:
                const totalUntrustedInventoryAllTypesContent =
                    valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
                const findTotalUntrustedInventoryAllTypesData =
                    totalUntrustedInventoryAllTypesContent?.find(
                        (summary) => summary.locationId === locationId,
                    )
                return findTotalUntrustedInventoryAllTypesData ? (
                    <InventoryItems
                        totalAssets={findTotalUntrustedInventoryAllTypesData.totalAssets}
                        totalUsbDevices={findTotalUntrustedInventoryAllTypesData.totalUsbDevices}
                        totalSoftware={findTotalUntrustedInventoryAllTypesData.totalSoftware}
                        locationId={locationId}
                        stateOfItems="untrusted"
                    />
                ) : null
            case PossibleColumnsVesselBeta.TrustedInventoryAllTypes:
                const totalTrustedInventoryAllTypesContent =
                    valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
                const findTotalTrustedInventoryAllTypesData =
                    totalTrustedInventoryAllTypesContent?.find(
                        (summary) => summary.locationId === locationId,
                    )
                return findTotalTrustedInventoryAllTypesData ? (
                    <InventoryItems
                        totalAssets={findTotalTrustedInventoryAllTypesData.totalAssets}
                        totalUsbDevices={findTotalTrustedInventoryAllTypesData.totalUsbDevices}
                        totalSoftware={findTotalTrustedInventoryAllTypesData.totalSoftware}
                        locationId={locationId}
                        stateOfItems="trusted"
                    />
                ) : null
            case PossibleColumnsVesselBeta.NewInventoryAllTypes:
                const newInventoryAllTypesContent =
                    valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
                const findNewInventoryAllTypesData = newInventoryAllTypesContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findNewInventoryAllTypesData ? (
                    <InventoryItems
                        totalAssets={findNewInventoryAllTypesData.totalAssets}
                        totalUsbDevices={findNewInventoryAllTypesData.totalUsbDevices}
                        totalSoftware={findNewInventoryAllTypesData.totalSoftware}
                        locationId={locationId}
                        stateOfItems="total"
                    />
                ) : null
            case PossibleColumnsVesselBeta.FrameworkSummaryBenchmark:
                const behaviourSummaryContent =
                    valuesToDisplay.selectedColumnContent as FrameworkSummaryBenchmarkResponse[]
                const findbehaviourSummaryData = behaviourSummaryContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findbehaviourSummaryData) {
                    return <BenchmarkSummary findbehaviourSummaryData={findbehaviourSummaryData} />
                }
                return null
            case PossibleColumnsVesselBeta.OldestOpenIncident:
                const oldestIncidentContent =
                    valuesToDisplay.selectedColumnContent as OldestOpenIncidentResponse[]
                const findoldestIncidentData = oldestIncidentContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findoldestIncidentData) {
                    return <OldestIncident findoldestIncidentData={findoldestIncidentData} />
                }
                return null
            case PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork:
                const missingAssetsBussinessContent =
                    valuesToDisplay.selectedColumnContent as MissingInvetoryAllTypesResponse[]
                const findmissingAssetsBussinessData = missingAssetsBussinessContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findmissingAssetsBussinessData ? (
                    <MissingNetworkItems
                        totalMissingAssets={findmissingAssetsBussinessData.totalMissingAssets}
                        identifier={findmissingAssetsBussinessData.locationId}
                    />
                ) : null
            case PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork:
                const missingAssetsOtContent =
                    valuesToDisplay.selectedColumnContent as MissingInvetoryAllTypesResponse[]
                const findmissingAssetsOtData = missingAssetsOtContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findmissingAssetsOtData ? (
                    <MissingNetworkItems
                        totalMissingAssets={findmissingAssetsOtData.totalMissingAssets}
                        identifier={findmissingAssetsOtData.locationId}
                    />
                ) : null
            case PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets:
                const missingMonitoredAssetsContent =
                    valuesToDisplay.selectedColumnContent as MissingMonitoredAssetsResponse[]
                const findmissingMonitoredAssetsData = missingMonitoredAssetsContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findmissingMonitoredAssetsData ? (
                    <MissingNetworkItems
                        totalMissingAssets={
                            findmissingMonitoredAssetsData.totalMissingMonitoredAssets
                        }
                        identifier={findmissingMonitoredAssetsData.locationId}
                    />
                ) : null
            case PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod:
                const newInventoryPrevPeriodAllTypesContent =
                    valuesToDisplay.selectedColumnContent as TotalInventoryAllTypesResponse[]
                const findNewInventoryPrePeriodAllTypesData =
                    newInventoryPrevPeriodAllTypesContent?.find(
                        (summary) => summary.locationId === locationId,
                    )
                return findNewInventoryPrePeriodAllTypesData ? (
                    <InventoryItems
                        totalAssets={findNewInventoryPrePeriodAllTypesData.totalAssets}
                        totalUsbDevices={findNewInventoryPrePeriodAllTypesData.totalUsbDevices}
                        totalSoftware={findNewInventoryPrePeriodAllTypesData.totalSoftware}
                        locationId={locationId}
                        stateOfItems="new"
                    />
                ) : null
            case PossibleColumnsVesselBeta.NumberOfDaysSinceLastData:
            case PossibleColumnsVesselBeta.NumberOfDaysSinceLastNetworkData:
                const numberOfDaysSinceLastData =
                    valuesToDisplay.selectedColumnContent as NumberOfDaysSinceLastDataResponse[]
                const findnumberOfDaysSinceLastData = numberOfDaysSinceLastData?.find(
                    (summary) => summary.locationId === locationId,
                )
                return findnumberOfDaysSinceLastData ? (
                    <NumberOfDaysItems
                        lastSeenTimestamp={findnumberOfDaysSinceLastData.lastSeenTimestamp}
                        identifier={findnumberOfDaysSinceLastData.locationId}
                    />
                ) : null
            case PossibleColumnsVesselBeta.FrameworkSummaryTrend:
                const trendsSummaryContent =
                    valuesToDisplay.selectedColumnContent as FrameworkSummaryTrendResponse[]
                const findTrendSummaryData = trendsSummaryContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findTrendSummaryData) {
                    return <FrameworkSummaryTrend findTrendSummaryData={findTrendSummaryData} />
                }
                return null
            case PossibleColumnsVesselBeta.FrameworkSummaryTarget:
                const targetSummaryContent =
                    valuesToDisplay.selectedColumnContent as FrameworkSummaryTargetResponse[]
                const findTargetSummaryData = targetSummaryContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findTargetSummaryData) {
                    return <FrameworkSummaryTarget findTargetSummaryData={findTargetSummaryData} />
                }
                return null
            case PossibleColumnsVesselBeta.NumberOfIncidentsRaised:
                const incidentsRaisedContent =
                    valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
                const findIncidentRaisedData = incidentsRaisedContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findIncidentRaisedData) {
                    return (
                        <IncidentsNumber
                            findIncidentData={findIncidentRaisedData}
                            columnName={PossibleColumnsVesselBeta.NumberOfIncidentsRaised}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod:
                const incidentsRaisedPrevPerContent =
                    valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
                const findIncidentRaisedPrevPerData = incidentsRaisedPrevPerContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findIncidentRaisedPrevPerData) {
                    return (
                        <IncidentsNumber
                            findIncidentData={findIncidentRaisedPrevPerData}
                            columnName={PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents:
                const incidentsCurrentOpenContent =
                    valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
                const findIncidentCurrentOpenData = incidentsCurrentOpenContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findIncidentCurrentOpenData) {
                    return (
                        <IncidentsNumber
                            findIncidentData={findIncidentCurrentOpenData}
                            columnName={PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.NumberOfIncidentsOpen:
                const incidentsOpenContent =
                    valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
                const findIncidentOpenData = incidentsOpenContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findIncidentOpenData) {
                    return (
                        <IncidentsNumber
                            findIncidentData={findIncidentOpenData}
                            columnName={PossibleColumnsVesselBeta.NumberOfIncidentsOpen}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidentsAverageTime:
                const averageTimeToResolveIncidentsContent =
                    valuesToDisplay.selectedColumnContent as AverageTimeToResolveIncidentsResponse[]
                const findAverageTimeData = averageTimeToResolveIncidentsContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findAverageTimeData) {
                    return (
                        <TotalAverageTime
                            averageResolveTime={findAverageTimeData.averageResolveTime}
                            identifier={findAverageTimeData.locationId}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents:
                const nodesCountInOpenIncContent =
                    valuesToDisplay.selectedColumnContent as NumberOfIncidentsResponse[]
                const findNodesCountInOpenIncData = nodesCountInOpenIncContent?.find(
                    (summary) => summary.locationId === locationId,
                )
                if (findNodesCountInOpenIncData) {
                    return (
                        <IncidentsNumber
                            findIncidentData={findNodesCountInOpenIncData}
                            columnName={PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents}
                        />
                    )
                }
                return null
            case PossibleColumnsVesselBeta.DeploymentSummary:
                const deploymentSummaryContent =
                    valuesToDisplay.selectedColumnContent as VesselDeploymentStatus
                const findVesselOperationalStatus =
                    deploymentSummaryContent?.operationalStatus?.[locationId]
                if (findVesselOperationalStatus) {
                    return (
                        <DeploymentSummaryCell
                            vesselDeploymentStatusInfo={deploymentSummaryContent}
                            locationId={locationId}
                        />
                    )
                }
                return null
            default:
                return <p>{valuesToDisplay?.selectedColumnContent as string}</p>
        }
    }
    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-vessels-beta-row_${locationId}`} />
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`vessels-beta-name-${locationId}`}
            >
                {location?.description ?? 'Unknown'}
            </Styled.TableDataCell>
            {selectedColumns?.map((selectedColumn, index) => (
                <Styled.TableDataCell
                    key={selectedColumn}
                    id={`vessels-beta-${selectedColumn}-${locationId}`}
                    gridRow={gridRow}
                    gridColumn={3 + index}
                    overFlowVisible={
                        selectedColumn === PossibleColumnsVesselBeta.OldestOpenIncident
                    }
                >
                    {findValueToDisplayForGivenColumn(selectedColumn)}
                </Styled.TableDataCell>
            ))}
        </>
    )
}
