import CommonEvent from '../../../../../values/common-event/common-event'
import LoadingState from '../../../../../values/loading-state-enum'
import {PagedAlertsState} from '../types/paged-alerts-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function pagedAlertsReducer(
    state: PagedAlertsState,
    action: Actions.AllActions,
): PagedAlertsState {
    switch (action.type) {
        case ActionType.CHANGE_PAGE_SIZE_ACTION:
            return {
                ...state,
                pageSize: action.payload,
                dataPages: undefined,
                loading: LoadingState.RequestingData,
                totalNumberOfEvents: undefined,
                totalNumberOfPages: undefined,
                error: '',
                selectedPage: 0,
            }
        case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
            return {
                ...state,
                dataPages: undefined,
                loading: LoadingState.RequestingData,
                totalNumberOfEvents: undefined,
                totalNumberOfPages: undefined,
                error: '',
                selectedPage: 0,
            }
        case ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION:
            if (
                state.selectedPage === action.payload &&
                state.loading === LoadingState.Loaded &&
                state.error === ''
            ) {
                return state
            }

            return {
                ...state,
                loading: LoadingState.Loaded,
                error: '',
                selectedPage: action.payload,
            }
        case ActionType.REQUEST_PAGE_DATA_ACTION:
            return {
                ...state,
                selectedPage: action.payload,
                loading: LoadingState.RequestingData,
                error: '',
            }

        case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
            const dataPages = new Map<number, CommonEvent[]>(
                state.dataPages || new Map<number, CommonEvent[]>(),
            )
            if (state.selectedPage != undefined) {
                dataPages.set(state.selectedPage, action.payload.data)
            }
            const totalNumberOfEvents = action.payload.totalRecords
            const totalNumberOfPages = Math.ceil(totalNumberOfEvents / state.pageSize)

            return {
                ...state,
                loading: LoadingState.Loaded,
                dataPages,
                totalNumberOfEvents,
                totalNumberOfPages,
                error: '',
            }

        case ActionType.SET_ERROR_ACTION:
            if (state.loading === LoadingState.Errored && state.error === action.payload) {
                return state
            }

            return {
                ...state,
                error: state.error,
                loading: LoadingState.Errored,
            }

        case ActionType.CLEAR_DATE_FILTER:
            if (state.fromDate == null && state.toDate == null) {
                return state
            }

            return {
                ...state,
                dataPages: undefined,
                loading: LoadingState.RequestingData,
                totalNumberOfEvents: undefined,
                totalNumberOfPages: undefined,
                error: '',
                selectedPage: 0,
                fromDate: undefined,
                toDate: undefined,
            }

        case ActionType.SET_DATE_FILTER:
            if (
                state.fromDate === action.payload.fromDate &&
                state.toDate === action.payload.toDate
            ) {
                return state
            }

            return {
                ...state,
                dataPages: undefined,
                loading: LoadingState.RequestingData,
                totalNumberOfEvents: undefined,
                totalNumberOfPages: undefined,
                error: '',
                selectedPage: 0,
                fromDate: action.payload.fromDate,
                toDate: action.payload.toDate,
            }
        case ActionType.DISPLAY_DATE_FILTER:
            if (state.showDateFilter === action.payload) {
                return state
            }

            return {
                ...state,
                showDateFilter: action.payload,
            }

        /* istanbul ignore next */
        default:
            return state
    }
}
