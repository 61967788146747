import * as Styled from './generate-report-popup.styled'
import {ReportDialogContent} from './popup-content'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../hooks/useOnClickOutside'

interface GenerateReportPopupProps {
    showGenerateReportPopup: (show: boolean) => void
    reportUrl: string | null
    pageType: string
}

export function GenerateReportPopup({
    showGenerateReportPopup,
    reportUrl,
    pageType,
}: GenerateReportPopupProps): JSX.Element {
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        showGenerateReportPopup(false)
    })
    return (
        <Styled.AssignPolicyDialogWrapperCard
            ref={clickOutside}
            id={`${pageType}_generate-report-popup`}
        >
            <Styled.AssignPolicyDialogCard>
                <ReportDialogContent
                    reportUrl={reportUrl}
                    pageType={pageType}
                    showGenerateReportPopup={showGenerateReportPopup}
                />
            </Styled.AssignPolicyDialogCard>
        </Styled.AssignPolicyDialogWrapperCard>
    )
}
