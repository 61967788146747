import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {vesselTagsForLocationsSelector} from '../../../../../store/state/vessel-tags/selectors'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NetworkAssetModalOutputModel} from '../../../models/network-asset-modal.model'
import {AssetDetails} from './asset-details/asset-details'
import {NoDataModal} from './no-data-modal'
import * as Styled from './_styled/network-asset-details.styled'
import {AssetInsights} from './asset-insights/asset-insights'
import {AssetActions} from './asset-actions/asset-actions'
import {DetailPanelHeader} from './header/detail-panel-header'
import {VesselStatus} from './vessel-status/vessel-status'
import {
    NetworkAssetMonitoringState,
    NetworkAssetState,
} from '../../../context/types/isolated-assets-type'

export function NetworkAssetDetails(): JSX.Element | null {
    const {findNetworkAsset, isolatedAssetsStatus, currentAssetData} = usePagedUnknownAssetsBeta()
    const locationMap = useTypedSelector(locationMapSelector)
    const vesselTags = useTypedSelector(vesselTagsForLocationsSelector)

    if (!findNetworkAsset) {
        return <NoDataModal />
    }

    const model = new NetworkAssetModalOutputModel(
        findNetworkAsset,
        locationMap,
        isolatedAssetsStatus,
        vesselTags,
        currentAssetData,
    )

    const isAllowed =
        model.monitoringState === NetworkAssetMonitoringState.UNKNOWN &&
        model.state !== NetworkAssetState.BLOCKED
    return (
        <Styled.Container
            onClick={(e) => e.stopPropagation()}
            id="network-asset-details-modal_container"
        >
            <DetailPanelHeader assetName={model.hostname} />
            <VesselStatus model={model} />
            <Styled.DetailPanelSection id="network-asset-details-modal_panel-section-1">
                <AssetDetails model={model} />
            </Styled.DetailPanelSection>
            <Styled.DetailPanelSection id="network-asset-details-modal_panel-section-2">
                <AssetInsights />
            </Styled.DetailPanelSection>
            <Styled.DetailPanelSection id="network-asset-details-modal_panel-section-3">
                <AssetActions
                    locationCode={model.locationCode}
                    locationId={findNetworkAsset.location}
                    deviceName={model.deviceName}
                    isAllowed={isAllowed}
                />
            </Styled.DetailPanelSection>
        </Styled.Container>
    )
}
