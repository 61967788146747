export type ValidationResult = 'valid_subnet' | 'invalid_subnet'
export function isValidCidr(cidr: string[]): ValidationResult | null {
    if (!cidr || cidr?.filter((e) => e.trim().length !== 0).map((e) => e.trim()).length === 0) {
        return null
    }
    return cidr.filter((element) => isValidCidrItem(element) !== 'Subnet is ok').length !== 0
        ? 'invalid_subnet'
        : 'valid_subnet'
}
const isValidCidrItem = (cidr: string) => {
    const [addr, mask] = cidr.split('/') // split on / to separate address and mask

    const octs = addr.split('.') // split address part in to octets
    if (octs.length !== 4) {
        return 'Invalid subnet - address should have 4 octets'
    }
    if (
        (Number(octs[0]) !== 10 &&
            Number(octs[0]) !== 172 &&
            Number(octs[0]) !== 192 &&
            !octs[0]) ||
        !octs[0]?.match(/^[0-9]+$/)
    ) {
        return 'Invalid subnet - 1st octet should be 10, 172 or 192'
    }
    if (!octs[1] || !octs[1]?.match(/^[0-9]+$/) || Number(octs[1]) < 0 || Number(octs[1]) > 256) {
        return 'Invalid subnet - 2nd octet should be 0 to 256'
    }
    if (!octs[2] || !octs[2]?.match(/^[0-9]+$/) || Number(octs[2]) < 0 || Number(octs[2]) > 256) {
        return 'Invalid subnet - 3rd octet should be 0 to 256'
    }
    if (!octs[3] || !octs[3]?.match(/^[0-9]+$/) || Number(octs[3]) < 0 || Number(octs[3]) > 256) {
        return 'Invalid subnet - 4th octet should be 0 to 256'
    }
    // calculate integer value of IP
    if (Number(octs[0]) >= 128) {
        octs[0] = (Number(octs[0]) - 128).toString()
        //ignore the MSB because JS can't handle uint32
    }

    if (!mask || !mask?.match(/^[0-9]+$/)) {
        return 'Invalid subnet - mask should contain only numbers'
    }
    if (Number(mask) < 1 || Number(mask) > 32 || !mask) {
        return 'Invalid subnet - mask should be /1 to /32'
    }

    const intaddr =
        (parseInt(octs[0], 10) << 24) +
        (parseInt(octs[1], 10) << 16) +
        (parseInt(octs[2], 10) << 8) +
        parseInt(octs[3], 10)

    // check whether the address contains any digits within the mask region
    const intmask = 2 ** (32 - Number(mask)) - 1

    if ((intaddr & intmask) != 0) {
        return 'Invalid subnet - address does not conform to mask'
    }
    return 'Subnet is ok'
}
