import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {sortedLocationsSelector} from '../../../../listing/selector/sorted-locations-selectors'
import {OtherVesselsListCard} from './other-vessels-list-card'
import {CardArea} from './_styled/vessels-cards-populated.styled'

interface OtherVesselsListCardsProps {
    code: string
}

export function OtherVesselsListCards({code}: OtherVesselsListCardsProps): JSX.Element {
    const sortedLocations = useTypedSelector(sortedLocationsSelector)
    const currentLocation = sortedLocations.filter((location) => location.code === code)[0]
    return (
        <CardArea>
            {sortedLocations
                .filter((location) => location !== currentLocation)
                .map((location) => (
                    <OtherVesselsListCard
                        key={location.location}
                        location={location}
                        currentType={currentLocation.type}
                        currentCustomerLocationCode={currentLocation.customerLocationCode}
                    />
                ))}
        </CardArea>
    )
}
