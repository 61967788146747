import {RouteComponentProps} from 'react-router-dom'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {ScrollablePageTemplate} from '../../../templates/scrollable-page/scrollable-page-template'
import * as Styled from './vessel-configuration.styled'
import {DataPopulated} from './components/data-populated/data-populated'
import {doesLocationExistReselector} from './reselector/does-location-exist-reselector'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {VesselNotFound} from './components/vessel-not-found/vessel-not-found'
import {ToggleProvider} from '../../../contexts/toggle/toggle-provider'
import {VesselConfigProvider} from './contexts/vessel-config-provider'
import {Header} from './components/header/header'
import {MAX_SCREEN_WIDTH} from '../../../theme/theme'

const BREAK_POINT = 620

export function VesselConfiguration({match}: RouteComponentProps<{location: string}>): JSX.Element {
    const {width} = useDimensions()
    const locationCode = match.params.location
    const doesLocationExist = useTypedSelector((state) =>
        doesLocationExistReselector(state, locationCode),
    )
    return (
        <ScrollablePageTemplate>
            <VesselConfigProvider locationCode={locationCode}>
                <Styled.Configuration>
                    <Styled.Layout
                        addMargin={width > MAX_SCREEN_WIDTH}
                        reducePadding={width <= BREAK_POINT}
                    >
                        {doesLocationExist ? (
                            <ToggleProvider>
                                <Header />
                                <DataPopulated />
                            </ToggleProvider>
                        ) : (
                            <VesselNotFound />
                        )}
                    </Styled.Layout>
                </Styled.Configuration>
            </VesselConfigProvider>
        </ScrollablePageTemplate>
    )
}
