import * as Styled from './incident-severity-change-record.styles'
import SEVERITY_CHANGE_ICON from '../../../../../@assets/icons/assigned-to-black.svg'
import {formatDate} from '../../../../../helpers/formatting'
import {AssignmentChangeOutputModel} from '../../../models/assignment-change.model'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usersSelector} from '../../../../../store/state/users/selectors'

interface IncidentAssignmentProps {
    assignmentRecord: AssignmentChangeOutputModel
}
export function IncidentAssignmentChangeRecord({
    assignmentRecord,
}: IncidentAssignmentProps): JSX.Element {
    const allUsers = useTypedSelector(usersSelector)
    const oldAssigned = assignmentRecord.oldAssignedToVesselEmail
        ? 'Vessel'
        : allUsers.find((user) => user.user === assignmentRecord.oldAssignedToUser)?.username ??
          assignmentRecord.oldGuestAssignedToEmail ??
          'Unassigned'

    const newAssigned = assignmentRecord.newAssignedToVesselEmail
        ? 'Vessel'
        : allUsers.find((user) => user.user === assignmentRecord.newAssignedToUser)?.username ??
          assignmentRecord.newGuestAssignedToEmail ??
          'Unassigned'

    return (
        <Styled.StatusChangeWrapper
            id={`incident-assignment-record-${assignmentRecord.id}-wrapper`}
        >
            <Styled.HeaderWrapper>
                <img src={SEVERITY_CHANGE_ICON} style={{width: '13px', height: '15px'}}></img>
                <Styled.When id={`incident-assignment-record-when-${assignmentRecord.id}`}>
                    {formatDate(assignmentRecord?.when) ?? 'Unknown'}
                </Styled.When>
                <Styled.Who id={`incident-assignment-record-who-${assignmentRecord.id}`}>
                    {assignmentRecord.username}
                </Styled.Who>
                {!assignmentRecord.isViewed && <Styled.NewSquare>NEW</Styled.NewSquare>}
            </Styled.HeaderWrapper>
            <Styled.UpdateMessage>
                <div id={`incident-assignment-record-newAssigned-${assignmentRecord.id}`}>
                    Assignment changed: From {oldAssigned} to {newAssigned}
                </div>
            </Styled.UpdateMessage>
        </Styled.StatusChangeWrapper>
    )
}
