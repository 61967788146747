// Assuming the styled components file is in the same directory
import * as Style from './vessel-card.styled'
import VESSEL from '../../../@assets/icons/ot-inventory-vessel.svg'
import {OTLocation} from '../contexts/types/ot-inventory-response'
import type {DefaultTheme} from 'styled-components'

/**
 * Component for a card containing details of an OTLocation.
 */
export function VesselCard({vessel}: {vessel: OTLocation}) {
    return (
        <Style.Card id={`vessel-entry-${vessel?.customerCode}`}>
            <Style.IconContainer>
                <img src={VESSEL} alt="Vessel" />
            </Style.IconContainer>
            <Style.Content>
                <Style.Row columnGap="10px">
                    <Style.StyledTippy
                        className="tippy-vessel-card"
                        content={vessel.name}
                        placement="bottom-start"
                        arrow
                        // A hack to satisfy typescript cause styled component override tippy's 'theme' prop
                        // and im not sure how to solve this.
                        theme={'light-border' as unknown as DefaultTheme}
                    >
                        <Style.RowItem
                            id={`vessel-description-${vessel.customerCode}`}
                            truncateText
                        >
                            {vessel.name}
                        </Style.RowItem>
                    </Style.StyledTippy>
                    <Style.StyledTippy
                        content={`IMO ${vessel.customerCode}`}
                        placement="bottom-start"
                        arrow
                        theme={'light-border' as unknown as DefaultTheme}
                    >
                        <Style.RowItem
                            textAlign="right"
                            id={`vessel-customerCode-${vessel.customerCode}`}
                            truncateText
                        >
                            IMO {vessel.customerCode}
                        </Style.RowItem>
                    </Style.StyledTippy>
                </Style.Row>
                <Style.Row>
                    <Style.RowItem id={`vessel-asset-total-${vessel.customerCode}`}>
                        {vessel.hardwareAssetCount === 1
                            ? `${vessel.hardwareAssetCount} Asset`
                            : `${vessel.hardwareAssetCount} Assets`}
                    </Style.RowItem>

                    <Style.StyledTippy
                        content={vessel.type}
                        placement="bottom-start"
                        arrow
                        theme={'light-border' as unknown as DefaultTheme}
                    >
                        <Style.RowItem id={`vessel-type-${vessel.customerCode}`} truncateText>
                            {vessel.type}
                        </Style.RowItem>
                    </Style.StyledTippy>
                </Style.Row>
            </Style.Content>
        </Style.Card>
    )
}
