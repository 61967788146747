import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const NoteCard = styled.div`
    padding-top: ${spacing(2)};
    padding-bottom: ${spacing(2)};
`
interface NoteDetailProps {
    card: boolean | undefined
}
export const NoteDetail = styled.div<NoteDetailProps>`
    display: flex;
    flex-direction: ${(props) => (props.card ? 'row' : 'column')};
`
interface WhenProps {
    card: boolean | undefined
}
function notesFont(): string {
    return `${mediumSmallFont()} letter-spacing: 0.24px;`
}

export const When = styled.div<WhenProps>`
    ${notesFont()}
    font-weight: ${(props) =>
        props.card ? props.theme.font.weight.semibold : props.theme.font.weight.extrabold};
    min-width: ${(props) => (props.card ? '130px' : 'auto')};
    padding-right: ${(props) => (props.card ? spacing(2) : 'auto')};
`
interface WhoProps {
    card: boolean | undefined
}

export const Who = styled.div<WhoProps>`
    ${notesFont()}
    font-weight: ${(props) =>
        props.card ? props.theme.font.weight.normal : props.theme.font.weight.semibold};
`
interface TextProps {
    card: boolean | undefined
}

export const Text = styled.div<TextProps>`
    ${notesFont()}
    font-weight: ${(props) =>
        props.card ? props.theme.font.weight.normal : props.theme.font.weight.semibold};
    margin: ${spacing(1)} 0 0 0;
    line-height: ${(props) => (props.card ? '20px' : '17px')};
    word-break: break-word;
`
