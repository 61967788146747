import {GuidType} from '../../../../../values/generic-type-defintions'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import * as Styled from './edit-message.styled'
interface EditButtonProps {
    noteIdentityToEdit: GuidType
}
export function EditButton({noteIdentityToEdit}: EditButtonProps): JSX.Element {
    const {toggleNoteEdit} = usePagedIncidents()

    function handleClick() {
        toggleNoteEdit(noteIdentityToEdit)
    }
    return (
        <Styled.EditButton
            onClick={handleClick}
            id={`incident-note-edit-button-${noteIdentityToEdit}`}
        >
            Edit message
        </Styled.EditButton>
    )
}
