import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {narrowLayoutFn} from '../grid-helper'
interface TitleRowProps {
    width: number
}
export const TitleRow = styled.div<TitleRowProps>`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    column-gap: ${spacing(2)};
    padding: ${spacing(1)} 0 ${spacing(1)} 0;
    border-bottom: 1px solid ${(props) => props.theme.reports.dataRow.separator};
`

function getGridTemplate(width: number): string {
    if (narrowLayoutFn(width)) {
        return '"INCIDENT-TYPE INCIDENT-TYPE INCIDENT-NUMBER INCIDENT-NUMBER VESSEL VESSEL""TITLE TITLE TITLE TITLE TITLE TITLE"'
    }
    return '"INCIDENT-NUMBER INCIDENT-NUMBER TITLE TITLE VESSEL VESSEL"'
}
