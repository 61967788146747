import {LoadingSpinner} from '../../../../components/loading/loading'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {reportsFilterLoadingSelector} from '../../../../store/state/reports-filter/selectors'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {ComplianceRating} from './compliance-rating/compliance-rating'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {ReportType} from './report-type/report-type'
import {ResetFilterButton} from './reset-filter/reset-filter-button'
import {TimePeriodFrom} from './time-period/time-period-from'
import {TimePeriodTo} from './time-period/time-period-to'
import {WholeFleetReports} from './whole-fleet-reports/whole-fleet-reports'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const isLoadingFilter = useTypedSelector(reportsFilterLoadingSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    if (isLoadingFilter) {
        return (
            <Styles.Loading>
                <LoadingSpinner />
            </Styles.Loading>
        )
    }

    return (
        <Styles.FilterBarStyled width={width} id={`${PageType.REPORTS}_filter-bar`}>
            <FilterBarHeader />
            <Styles.SavedFilterContent isInactive={isInactive}>
                <SavedSearch savedFilterPageType={SavedFilterPageType.REPORTS} />
            </Styles.SavedFilterContent>
            <Styles.SectionContent isInactive={isInactive}>
                <ReportType />
                <ComplianceRating />
                <TimePeriodFrom />
                <TimePeriodTo />
                <WholeFleetReports />
            </Styles.SectionContent>
            <VesselFilters pageType={PageType.REPORTS} />
            <ResetFilterButton />
        </Styles.FilterBarStyled>
    )
}
