import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${spacing(3)};
    row-gap: ${spacing(4)};
`

export const Cell = styled.div`
    width: 382px;
`
