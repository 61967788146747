export class FormattedAge {
    public readonly weeks: number
    public readonly days: number
    public readonly hours: number
    public readonly minutes: number

    private static getWeeksFromMilliSeconds(milliseconds: number): number {
        return milliseconds / (1000 * 60 * 60 * 24 * 7)
    }

    private static getWeekRemainderInDays(weeks: number, absoluteWeeks: number): number {
        return (weeks - absoluteWeeks) * 7
    }

    private static getHoursRemainder(days: number, absoluteDays: number): number {
        return (days - absoluteDays) * 24
    }

    constructor(milliseconds: number) {
        const precisionWeeks = FormattedAge.getWeeksFromMilliSeconds(milliseconds)
        this.weeks = Math.floor(precisionWeeks)

        const precisionDays = FormattedAge.getWeekRemainderInDays(precisionWeeks, this.weeks)
        this.days = Math.floor(precisionDays)

        const precisionHours = FormattedAge.getHoursRemainder(precisionDays, this.days)
        this.hours = Math.floor(precisionHours)

        this.minutes = Math.floor((precisionHours - this.hours) * 60)
    }
}
