import {ReferenceArea} from 'recharts'
import {CyberOwlTheme} from '../../../../../../theme/theme'

// use like a custom component, but must be a function as recharts will render data returned by a function, not a custom component
export function amberRiskBackground(
    theme: CyberOwlTheme,
    lowerThreshold: number,
    upperThreshold: number,
): JSX.Element {
    return (
        <ReferenceArea
            y1={lowerThreshold}
            y2={upperThreshold}
            fill={theme.threatScores.score.medium.background}
            fillOpacity={0.3}
        />
    )
}
