import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellIncusMac(): JSX.Element {
    const {data, initialData, displayData, changeIncusMac} = useVesselConfig()
    const changed = data.incusMac !== initialData.incusMac
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeIncusMac(e.target.value)
    }
    return (
        <DataInput
            label="Incus Mac"
            initialValue={displayData.incusMac}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
