import {
    ConnectionsWrapper,
    PurposeText,
    TableDataCell,
    NumberText,
} from './_styled/table-data-cell.styled'
import {TableDataRow} from './_styled/table-data-row.styled'
import {USBDevicesResponse} from '../../contexts/types/usb-devices-response'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {getFormattedUSBDeviceData, getImageForUsbDevicesState} from '../data-helpers'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../store/state/audit-log/state'
import NEW_VESSEL_ICON from '../../../../@assets/icons/new-vessel-icon.svg'
import ASSOCIATED_ASSET_ICON from '../../../../@assets/icons/associated-asset-icon.svg'

interface TableRowProps {
    usbDevice: USBDevicesResponse
    index: number
    onClickFn?: () => void
}

export function TableRow({usbDevice, index, onClickFn}: TableRowProps): JSX.Element | null {
    const gridRow = index + 1
    const {setIdForUSBDetailsModal, modalIdForUSBDetails, displayUsbModal} = usePagedUSBDevices()
    const nodes = useTypedSelector(nodesSelector)
    const formattedUSDeviceData = getFormattedUSBDeviceData(usbDevice, nodes)
    const activeSoftware = formattedUSDeviceData.identity === modalIdForUSBDetails
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (onClickFn) {
            onClickFn()
        }
        setIdForUSBDetailsModal(formattedUSDeviceData.identity)
        displayUsbModal(formattedUSDeviceData.identity)
        dispatch(
            logDetailsOpen(
                AuditLogPageType.USB_INVENTORY,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                formattedUSDeviceData.identity,
            ),
        )
    }

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                id={`data-row_${formattedUSDeviceData.identity}`}
                activeSoftware={activeSoftware}
                onClick={onClickWrapper}
            />
            <TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                onClick={onClickWrapper}
                id={`status-value_${formattedUSDeviceData.identity}`}
                centered
            >
                <img src={getImageForUsbDevicesState(formattedUSDeviceData.deviceStatus)} />
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                onClick={onClickWrapper}
                id={`first-detected-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.firstSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                onClick={onClickWrapper}
                id={`last-active-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.lastSeenTimestamp}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                onClick={onClickWrapper}
                id={`vendor-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.vendorName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                onClick={onClickWrapper}
                id={`product-name-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.productName}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={7}
                onClick={onClickWrapper}
                id={`type-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.deviceType}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={8}
                onClick={onClickWrapper}
                id={`volume-labels-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.volumeLabels}
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={9}
                onClick={onClickWrapper}
                id={`connections-value_${formattedUSDeviceData.identity}`}
            >
                <ConnectionsWrapper>
                    <NumberText>
                        {formattedUSDeviceData.totalNumberOfVesselsPerItem}
                        <img src={NEW_VESSEL_ICON} style={{marginLeft: '5px'}} />
                    </NumberText>
                    <NumberText>
                        {formattedUSDeviceData.totalNumberOfHostsPerItem}
                        <img src={ASSOCIATED_ASSET_ICON} width={19} style={{marginLeft: '5px'}} />
                    </NumberText>
                </ConnectionsWrapper>
            </TableDataCell>
            <TableDataCell
                gridRow={gridRow}
                gridColumn={10}
                onClick={onClickWrapper}
                id={`purpose-value_${formattedUSDeviceData.identity}`}
            >
                {formattedUSDeviceData.purpose ? (
                    <PurposeText>{`"${formattedUSDeviceData.purpose}"`}</PurposeText>
                ) : (
                    <PurposeText>No purpose set</PurposeText>
                )}
            </TableDataCell>
        </>
    )
}
