import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface ContainerProps {
    disabled: boolean
}

export const Wrapper = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)} 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    min-height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    padding: 3px;
`
export const Text = styled.div`
    ${mediumSmallFont()}
    word-break: break-word;
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-align: justify;
    display: flex;
    flex-direction: column;
`
export const Arrows = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 14px;
    font-size: 13px;
    line-height: 23px;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.background};
`
interface ArrowProps {
    disabled: boolean
}
export const UpArrow = styled.span<ArrowProps>`
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.icon};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`

export const DownArrow = styled.span<ArrowProps>`
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.arrow.icon};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`
export const SortOrderFakeInput = styled.div`
    display: flex;
    justify-content: space-between;
    height: 30px;
    min-width: 50px;
    border-radius: 3px;
    overflow: hidden;
    padding: 0;
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.sortOrder.border};
`
interface SortValueProps {
    disabled: boolean
}
export const SortValue = styled.div<SortValueProps>`
    display: flex;
    align-items: center;
    padding: ${spacing(1)};
    margin: 0;
    font-size: 13px;
    line-height: 23px;
    font-weight: ${(props) =>
        props.disabled ? props.theme.font.weight.semibold : props.theme.font.weight.bold};
    color: ${(props) => props.theme.myVessels.filterBar.sortOrder.text};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`
