import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardArea} from '../_styled/unknown-assets-cards.styled'
import {NoDataCardStyle, NoDataMessage} from './no-data.styled'

interface NoDataCardProps {
    text: string
}
export function NoDataCard({text}: NoDataCardProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <CardArea width={width}>
            <NoDataCardStyle>
                <NoDataMessage id="no-data-component">{text}</NoDataMessage>
            </NoDataCardStyle>
        </CardArea>
    )
}
