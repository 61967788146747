import {ReactNode, ReactNodeArray, useReducer} from 'react'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {getFixedValue, getFormattedVesselConfig} from './helper'
import {currentLocationReselector} from '../reselector/current-location-reselector'
import {VesselConfigContext} from './vessel-config-context'
import {vesselConfigReducer} from './state/reducer'
import {VesselConfigState} from './types/vessel-config-state'

type VesselConfigProviderProps = {
    children: ReactNode | ReactNodeArray
    locationCode: string
}
export function VesselConfigProvider({
    children,
    locationCode,
}: VesselConfigProviderProps): JSX.Element {
    const currentLocation = useTypedSelector((state) =>
        currentLocationReselector(state, locationCode),
    )

    const initialState: VesselConfigState = {
        fixedValue: getFixedValue(currentLocation),
        initialData: getFormattedVesselConfig(currentLocation),
        data: getFormattedVesselConfig(currentLocation),
        displayData: getFormattedVesselConfig(currentLocation),
        isChanged: false,
        saveResultMessage: undefined,
    }

    const [state, dispatch] = useReducer(vesselConfigReducer, initialState)

    return (
        <VesselConfigContext.Provider value={{state, dispatch}}>
            {children}
        </VesselConfigContext.Provider>
    )
}
