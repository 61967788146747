import {UniqueFlowsPaging} from './unique-flows-paging'
import {DataActions} from './data-actions'
import {RecordSet} from './record-set'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {UniqueFlowsTable} from './table/unique-flows-table'
import {UniqueFlowsCards} from './cards/unique-flows-cards'
import {Footer} from '../../../../../components/table-view-components/footer.styled'

export function UniqueFlowsTab(): JSX.Element {
    const {width} = useDimensions()
    const BREAKPOINT = 660
    return (
        <>
            <DataActions />
            {width >= BREAKPOINT && <UniqueFlowsTable />}
            {width < BREAKPOINT && <UniqueFlowsCards />}
            <Footer width={width}>
                <UniqueFlowsPaging />
                <RecordSet />
            </Footer>
        </>
    )
}
