import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './metrics-scorecard.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {StringUtils} from '../../../../utils/Utils'
import {MetricsFooter} from './metrics-footer'
import {getMetricTypeIcon} from '../data-helper'
import {MetricType} from '../../contexts/types/metrics-response'
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {
    setSelectedViewScreen,
    filterMainFrameworkCard,
} from '../../../../store/state/metrics-filter-beta/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import LoadingState from '../../../../values/loading-state-enum'
import {useSpring} from 'react-spring'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {FormattedMetricData} from '../../../../store/state/metric-types/state'
interface MetricsScorecardCardProps {
    metricType: FormattedMetricData
}
export function MetricsScorecardCard({metricType}: MetricsScorecardCardProps): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {showFilterBar, loadingFilteredDataState, changePreviousPage} = usePagedMetricsBeta()
    const dataLoaded = loadingFilteredDataState === LoadingState.Loaded
    const {metricTypes} = useTypedSelector(metricsBetaFilterSelector)

    // Function to handle metric card click
    const onFrameworkClick = useCallback(
        (metricType: MetricType) => {
            changePreviousPage('metrics', metricTypes)
            dispatch(setSelectedViewScreen('Table'))
            dispatch(filterMainFrameworkCard([metricType]))
        },
        [changePreviousPage, dispatch],
    )

    const fadeStyles = useSpring({
        opacity: dataLoaded ? 1 : 0,
        from: {opacity: dataLoaded ? 0 : 1},
    })

    return (
        <Styled.AnimatedDiv style={fadeStyles} id={`scorecard-card-${metricType}-wrapper`}>
            <Styled.HeaderSection
                width={width}
                showFilterBar={showFilterBar}
                onClick={() => onFrameworkClick(metricType.name)}
                id={`scorecard-card-${metricType}-header`}
            >
                <Styled.TitleWrapper>
                    <Styled.Image
                        src={getMetricTypeIcon(metricType.metricGroup)}
                        id={`scorecard-card-${metricType}-image`}
                    />
                    <Styled.Title id={`scorecard-card-${metricType}-title`}>
                        {metricType.displayName ??
                            StringUtils.capitaliseString(
                                metricType.name.replace(/([A-Z])/g, ' $1'),
                            )}
                    </Styled.Title>
                </Styled.TitleWrapper>
                <Styled.Message id={`scorecard-card-${metricType}-message`}>
                    <MetricsFooter metricType={metricType.name} />
                </Styled.Message>
            </Styled.HeaderSection>
        </Styled.AnimatedDiv>
    )
}
