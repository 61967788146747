import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const AddNoteBorder = styled.div`
    border: 1px dotted ${(props) => props.theme.colors.link.primary};
    padding: ${spacing(1)};
    display: flex;
`
interface TextAreaWrapperProps {
    minHeight: string
}
export const TextAreaWrapper = styled.div<TextAreaWrapperProps>`
    min-height: ${(props) => props.minHeight};
    ${mediumSmallFont()}
    flex: 1;
`
interface TextAreaProps {
    height: string
}
export const TextArea = styled.textarea<TextAreaProps>`
    border: 0;
    outline: none;
    width: 100%;
    height: ${(props) => props.height};
`
export const ButtonWrapper = styled.div`
    margin-top: ${spacing(2)};
    margin-bottom: ${spacing(4)};
`
export const SaveButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    text-transform: uppercase;
    :disabled {
        opacity: 0.5;
    }
`
export const IconWrapper = styled.div`
    margin-right: ${spacing(2)};
    margin-top: ${spacing(-0.75)};
`
