import {BasicPaging} from '../../../../../../components/paging/basic/basic-paging'
import {
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'

export function MetricsPaging(): JSX.Element {
    const {width} = useDimensions()
    const {selectedPage, totalNumberOfPages, selectPage, pageSize} = usePagedMetricsBeta()

    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }

    return (
        <BasicPaging
            selectedPage={selectedPage}
            totalNumberOfPages={totalNumberOfPages}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, totalNumberOfPages)}
        />
    )
}
