import {Location, MetaData, ReportConfiguration} from '../../../../store/state/locations/state'
import {FormattedVesselConfig} from './types/formatted-vessel-config'
import {FixedValueState} from './types/vessel-config-state'

export const getFormattedVesselConfig = (
    currentLocation: Location | undefined,
): FormattedVesselConfig => {
    return {
        type: currentLocation?.type || '',
        customerLocationCode: currentLocation?.customerLocationCode || '',
        businessNetworks: currentLocation?.metaData?.network?.BusinessNetworks || [''],
        otNetworks: currentLocation?.metaData?.network?.OTNetworks || [''],
        crewNetworks: currentLocation?.metaData?.network?.CrewNetworks || [''],
        gatewayIp: currentLocation?.metaData?.network?.Gateway?.IP || '',
        gatewayMac: currentLocation?.metaData?.network?.Gateway?.MAC || '',
        incusIp: currentLocation?.metaData?.deployment?.Incus?.IP || '',
        incusMac: currentLocation?.metaData?.deployment?.Incus?.MAC || '',
        sensorIp: currentLocation?.metaData?.deployment?.Sensor?.IP || '',
        sensorMac: currentLocation?.metaData?.deployment?.Sensor?.MAC || '',
        otDevices: currentLocation?.reportConfiguration?.allow?.OTDevices || [''],
        otCrossings: currentLocation?.reportConfiguration?.allow?.OTCrossings || [''],
        businessDevices: currentLocation?.reportConfiguration?.allow?.BusinessDevices || [''],
        businessCrossings: currentLocation?.reportConfiguration?.allow?.BusinessCrossings || [''],
        emailHosts: currentLocation?.reportConfiguration?.allow?.Email || [''],
        hostLogExceptions: currentLocation?.reportConfiguration?.allow?.HostLogExceptions || [''],
        networkLogExceptions: currentLocation?.reportConfiguration?.allow?.NetworkLogExceptions || [
            '',
        ],
        usbHosts: currentLocation?.reportConfiguration?.allow?.USBHosts || [''],
        usbDevices: currentLocation?.reportConfiguration?.allow?.USBDevices || [''],
        adminLogons: currentLocation?.reportConfiguration?.allow?.AdminLogons || [''],
        remoteAccess: currentLocation?.reportConfiguration?.allow?.RemoteAccess || [''],
        encryptionRatio: currentLocation?.reportConfiguration?.target?.EncryptionRatio || '',
        monitoringCoverage: currentLocation?.reportConfiguration?.target?.MonitoringCoverage || '',
        maxOSAge: currentLocation?.reportConfiguration?.target?.MaxOSAge || '',
        maxScore: currentLocation?.reportConfiguration?.target?.MaxScore,
        vesselDashboardAgentIds: currentLocation?.metaData?.vesselDashboardAgentIds,
    }
}

export const getMetaData = (formattedData: FormattedVesselConfig): MetaData => {
    return {
        imo:
            formattedData.customerLocationCode === ''
                ? undefined
                : formattedData.customerLocationCode,
        network: {
            BusinessNetworks:
                formattedData.businessNetworks.toString() === ''
                    ? undefined
                    : formattedData.businessNetworks,
            OTNetworks:
                formattedData.otNetworks.toString() === '' ? undefined : formattedData.otNetworks,
            CrewNetworks:
                formattedData.crewNetworks.toString() === ''
                    ? undefined
                    : formattedData.crewNetworks,
            Gateway: {
                IP: formattedData.gatewayIp === '' ? undefined : formattedData.gatewayIp,
                MAC: formattedData.gatewayMac === '' ? undefined : formattedData.gatewayMac,
            },
        },
        deployment: {
            Incus: {
                IP: formattedData.incusIp === '' ? undefined : formattedData.incusIp,
                MAC: formattedData.incusMac === '' ? undefined : formattedData.incusMac,
            },
            Sensor: {
                IP: formattedData.sensorIp === '' ? undefined : formattedData.sensorIp,
                MAC: formattedData.sensorMac === '' ? undefined : formattedData.sensorMac,
            },
        },
        vesselDashboardAgentIds: formattedData.vesselDashboardAgentIds,
    }
}

export const getReportConfiguration = (
    formattedData: FormattedVesselConfig,
): ReportConfiguration => {
    return {
        allow: {
            OTDevices:
                formattedData.otDevices.toString() === '' ? undefined : formattedData.otDevices,
            OTCrossings:
                formattedData.otCrossings.toString() === '' ? undefined : formattedData.otCrossings,
            BusinessDevices:
                formattedData.businessDevices.toString() === ''
                    ? undefined
                    : formattedData.businessDevices,
            BusinessCrossings:
                formattedData.businessCrossings.toString() === ''
                    ? undefined
                    : formattedData.businessCrossings,
            Email:
                formattedData.emailHosts.toString() === '' ? undefined : formattedData.emailHosts,
            HostLogExceptions:
                formattedData.hostLogExceptions.toString() === ''
                    ? undefined
                    : formattedData.hostLogExceptions,
            NetworkLogExceptions:
                formattedData.networkLogExceptions.toString() === ''
                    ? undefined
                    : formattedData.networkLogExceptions,
            USBHosts: formattedData.usbHosts.toString() === '' ? undefined : formattedData.usbHosts,
            USBDevices:
                formattedData.usbDevices.toString() === ''
                    ? undefined
                    : formattedData.toString() === ''
                      ? undefined
                      : formattedData.usbDevices,
            AdminLogons:
                formattedData.adminLogons.toString() === '' ? undefined : formattedData.adminLogons,
            RemoteAccess:
                formattedData.remoteAccess.toString() === ''
                    ? undefined
                    : formattedData.remoteAccess,
        },
        target: {
            EncryptionRatio:
                formattedData.encryptionRatio === '' ? undefined : formattedData.encryptionRatio,
            MonitoringCoverage:
                formattedData.monitoringCoverage === ''
                    ? undefined
                    : formattedData.monitoringCoverage,
            MaxOSAge: formattedData.maxOSAge === '' ? undefined : formattedData.maxOSAge,
            MaxScore: formattedData.maxScore === 0 ? undefined : formattedData.maxScore,
        },
    }
}

export const getLocationData = (
    fixedValue: FixedValueState,
    formattedData: FormattedVesselConfig,
): Location => {
    return {
        location: fixedValue.location,
        code: fixedValue.locationCode,
        description: fixedValue.description,
        type: formattedData.type,
        customerLocationCode: formattedData.customerLocationCode,
        metaData: getMetaData(formattedData),
        reportConfiguration: getReportConfiguration(formattedData),
        status: fixedValue.status,
    }
}

export const getFixedValue = (currentLocation: Location | undefined): FixedValueState => {
    return {
        location: currentLocation ? currentLocation.location : '',
        description: currentLocation ? currentLocation.description : '',
        locationCode: currentLocation ? currentLocation.code : '',
        status: currentLocation ? currentLocation.status : 'NOT_READY',
    }
}

export const getCopyedConfig = (
    currentType: string | undefined,
    currentCustomerLocationCode: string | undefined,
    newLocation: Location,
): FormattedVesselConfig => {
    return {
        type: currentType || '',
        customerLocationCode: currentCustomerLocationCode || '',
        businessNetworks: newLocation.metaData?.network?.BusinessNetworks || [''],
        otNetworks: newLocation.metaData?.network?.OTNetworks || [''],
        crewNetworks: newLocation.metaData?.network?.CrewNetworks || [''],
        gatewayIp: newLocation.metaData?.network?.Gateway?.IP || '',
        gatewayMac: newLocation.metaData?.network?.Gateway?.MAC || '',
        incusIp: newLocation.metaData?.deployment?.Incus?.IP || '',
        incusMac: newLocation.metaData?.deployment?.Incus?.MAC || '',
        sensorIp: newLocation.metaData?.deployment?.Sensor?.IP || '',
        sensorMac: newLocation.metaData?.deployment?.Sensor?.MAC || '',
        otDevices: newLocation.reportConfiguration?.allow?.OTDevices || [''],
        otCrossings: newLocation.reportConfiguration?.allow?.OTCrossings || [''],
        businessDevices: newLocation.reportConfiguration?.allow?.BusinessDevices || [''],
        businessCrossings: newLocation.reportConfiguration?.allow?.BusinessCrossings || [''],
        emailHosts: newLocation.reportConfiguration?.allow?.Email || [''],
        hostLogExceptions: newLocation.reportConfiguration?.allow?.HostLogExceptions || [''],
        networkLogExceptions: newLocation.reportConfiguration?.allow?.NetworkLogExceptions || [''],
        usbHosts: newLocation.reportConfiguration?.allow?.USBHosts || [''],
        usbDevices: newLocation.reportConfiguration?.allow?.USBDevices || [''],
        adminLogons: newLocation.reportConfiguration?.allow?.AdminLogons || [''],
        remoteAccess: newLocation.reportConfiguration?.allow?.RemoteAccess || [''],
        encryptionRatio: newLocation.reportConfiguration?.target?.EncryptionRatio || '',
        monitoringCoverage: newLocation.reportConfiguration?.target?.MonitoringCoverage || '',
        maxOSAge: newLocation.reportConfiguration?.target?.MaxOSAge || '',
        maxScore: newLocation.reportConfiguration?.target?.MaxScore,
        vesselDashboardAgentIds: newLocation.metaData?.vesselDashboardAgentIds,
    }
}
