import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {StatusRowWrapper} from './metrics-type-filter.styled'
import {toggleMetricType} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {MetricType} from '../../../contexts/types/metrics-response'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {allMainMetricsTypeSelector} from '../../../../../store/state/metric-types/selectors'
import {FormattedMetricData} from '../../../../../store/state/metric-types/state'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface Props {
    subMetric: FormattedMetricData
}
export function MetricsTypeRow({subMetric}: Props): JSX.Element {
    const dispatch = useDispatch()
    const {metricTypes} = useTypedSelector(metricsBetaFilterSelector)
    const allMainMetricsType = useTypedSelector(allMainMetricsTypeSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()

    function isChecked(givenValue: MetricType): boolean {
        try {
            return !metricTypes ? true : metricTypes.includes(givenValue)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return true
        }
    }

    function setChecked(currentSelectedMetricType: MetricType, newValue: boolean): void {
        dispatch(toggleMetricType(allMainMetricsType, currentSelectedMetricType, newValue))
        dispatch(
            logFilterByTypes(
                'Metric types',
                `Metric value: ${currentSelectedMetricType}`,
                newValue,
                AuditLogPageType.METRICS,
            ),
        )
    }

    return (
        <StatusRowWrapper
            highlightedFilerValue={
                highlightedFilerValue === 'Metrics selection' && isChecked(subMetric.name)
            }
        >
            <Checkbox
                id={`metrics-filter-${subMetric.name}`}
                label={subMetric.displayName}
                checked={isChecked(subMetric.name)}
                onChange={(newValue) => setChecked(subMetric.name, newValue)}
            />
        </StatusRowWrapper>
    )
}
