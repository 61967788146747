import {Line} from 'recharts'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {LocationTagScore} from '../../../../../../values/location-tag-score'

export function secondDataLine(theme: CyberOwlTheme, data: LocationTagScore[]): JSX.Element {
    return (
        <Line
            isAnimationActive={false}
            type="linear"
            strokeWidth={2}
            connectNulls={true}
            data={data}
            dataKey="tagScore"
            name="Tag score"
            stroke={theme.colors.graph.secondary}
        />
    )
}
