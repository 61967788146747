import styled from 'styled-components'

export const BottomSection = styled.div`
    background-color: ${(props) =>
        props.theme.dashboard.vesselList.assetDetail.expanded.backgroundColor};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0px 1px 2px #00000034;
    font-size: 12px;
    line-height: 17px;
    padding: 14px 47px 11px 70px;
    display: flex;
    justify-content: space-between;
`
