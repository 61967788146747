import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {User} from './state'

export const usersLoadingSelector = (state: AppState): boolean =>
    state.users.loadingState === LoadingState.NotPopulated ||
    state.users.loadingState === LoadingState.RequestingData

export const usersLoadingStateSelector = (state: AppState): LoadingState => state.users.loadingState

export const usersSelector = (state: AppState): User[] => state.users.users

export const usersEmailsSelector = (state: AppState): string[] =>
    state.users.users.map((user) => user.email)

export const hasDataSelector = (state: AppState): boolean => state.users.users.length !== 0
