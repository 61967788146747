import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styled from './_styled/saved-filter-row.styled'
import {useDispatch} from 'react-redux'
import {useState} from 'react'
import {
    SavedFilterPageType,
    SavedIncidentsFilter,
    SavedIncidentsReportFilter,
    SavedMetricsBetaFilter,
    SavedNetworkAssetsFilter,
    SavedReportsFilter,
    SavedSoftwareInventoryFilter,
    SavedUsbInventoryFilter,
    SearchType,
} from '../../../store/state/saved-filters/state'
import {deleteSavedFilter} from '../../../store/state/saved-filters/action-creators'
import {logSavedFilterUsage} from '../../../store/state/audit-log/action-creators'
import {AuditLogAuditType} from '../../../store/state/audit-log/state'
import {getAuditPageType} from './shared/helper'
import {DetailTooltip} from './save-filter-popup/detail-tooltip'

interface Props {
    filter:
        | SavedIncidentsFilter
        | SavedNetworkAssetsFilter
        | SavedUsbInventoryFilter
        | SavedSoftwareInventoryFilter
        | SavedMetricsBetaFilter
        | SavedReportsFilter
        | SavedIncidentsReportFilter
    onClickFn?: () => void
    lastRow: boolean
    pageType: SavedFilterPageType
}

export function SavedFilterRow({filter, onClickFn, lastRow, pageType}: Props): JSX.Element {
    const isDeletable = filter.searchType === SearchType.INDIVIDUAL
    const dispatch = useDispatch()
    const [isHovering, setIsHovering] = useState(false)

    return (
        <Styled.SavedFilterWrapper
            onClick={onClickFn}
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
            isHovering={isHovering}
            id={`saved-filter-wrapper_${pageType}_${filter.name}`}
        >
            <Styled.FilterName>{filter.name}</Styled.FilterName>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Styled.DeleteButton
                    isDeletable={isDeletable}
                    onClick={() => {
                        dispatch(deleteSavedFilter(filter.identity))
                        dispatch(
                            logSavedFilterUsage(
                                getAuditPageType(pageType),
                                AuditLogAuditType.DELETE_SAVED_FILTER,
                            ),
                        )
                    }}
                    id={`delete-saved-filter_${filter.name}`}
                >
                    Delete
                    <Icon glyph="Delete" width={13} />
                </Styled.DeleteButton>
            </div>
            {isHovering && (
                <DetailTooltip pageType={pageType} criteria={filter.criteria} lastRow={lastRow} />
            )}
        </Styled.SavedFilterWrapper>
    )
}
