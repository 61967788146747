export enum ActionType {
    REQUEST_PAGE_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_DETAILS_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED INCIDENTS_DETAILS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED INCIDENTS_COUNTS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED INCIDENTS_ASSIGNMENTS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED INCIDENTS_TRENDS DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED AVG_PERFORMANCE DATA ACTION',
    RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION = '[PAGED INCIDENTS REPORT CONTEXT] RECEIVED REQUESTED VESSELS_PERFORMANCE DATA ACTION',
    DISPLAY_FILTER_BAR = '[PAGED INCIDENTS REPORT CONTEXT] DISPLAY FILTER BAR',
    SET_FILTER = '[PAGED INCIDENTS REPORT CONTEXT] SET FILTER',
    SET_HIGHLIGHTED_FILTER_VALUE = '[PAGED INCIDENTS REPORT CONTEXT] SET HIGHLIGHTED FILTER VALUE',
    SET_ACTIVE_SAVED_FILTER_DROPDOWN = '[PAGED INCIDENTS REPORT CONTEXT] SET ACTIVE SAVED FILTER DROPDOWN',
    DISPLAY_INCIDENT_DETAILS_EXPANDED = '[PAGED INCIDENTS REPORT CONTEXT] DISPLAY INCIDENT DETAILS EXPANDED',
    REPORT_CREATE = '[PAGED INCIDENTS REPORT CONTEXT] REPORT CREATE',
    SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[PAGED INCIDENTS REPORT CONTEXT] show report popup',
}
