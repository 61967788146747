import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {SoftwareInventoryCardsContent} from './software-inventory-cards-content'
export function SoftwareInventoryCards(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <ContentContainerMiddle
            width={width}
            scrollable
            backgroundColor={theme.softwareInventory.dataArea.background}
        >
            <SoftwareInventoryCardsContent />
        </ContentContainerMiddle>
    )
}
