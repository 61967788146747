import {ListItemWrapper} from './swagger-list-item.styled'
import {LinkButtons} from '../link-buttons/link-buttons'

interface SwaggerListItemProps {
    id: string
    appName: string
    swaggerLink: string | undefined
}

export function SwaggerListItem({id, appName, swaggerLink}: SwaggerListItemProps): JSX.Element {
    return (
        <ListItemWrapper>
            <LinkButtons id={id} text={appName} link={swaggerLink} />
        </ListItemWrapper>
    )
}
