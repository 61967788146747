import * as Styled from './asset-insights.styled'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {BehaviourStatus} from '../../../shared/behaviour-status/behaviour-status'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {NetworkAssetsDetailType} from '../../../../../../store/state/network-assets-filter/state'
import {networkAssetsModalDetailExpandedSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'

export function AssetInsights(): JSX.Element {
    const {networkAssetBehaviourData} = usePagedUnknownAssetsBeta()
    const detailExpanded = useTypedSelector(networkAssetsModalDetailExpandedSelector)

    const behaviourNameToMap = networkAssetBehaviourData?.map(
        (behaviour) => behaviour.behaviour.displayName,
    )

    const behaviorsToDisplay = behaviourNameToMap.length !== 0
    return (
        <>
            <ExpandPanelButton headingRow={NetworkAssetsDetailType.AssetInsightsState} />

            {detailExpanded.insightsExpanded && (
                <Styled.AssetBehaviourAreaWrapper id="network-asset-details-modal_asset-insights_wrapper">
                    <Styled.DescriptionText>Detected behaviours summary</Styled.DescriptionText>
                    <Styled.BehaviourList id="network-asset-details-modal_asset-insights_list">
                        {behaviorsToDisplay ? (
                            <>
                                {behaviourNameToMap?.map((name, index) => (
                                    <BehaviourStatus key={index} displayName={name} />
                                ))}
                            </>
                        ) : (
                            <div
                                style={{fontStyle: 'italic', fontSize: '14px'}}
                                id="no-behaviour-detected"
                            >
                                No behaviour detected on this asset
                            </div>
                        )}
                    </Styled.BehaviourList>
                </Styled.AssetBehaviourAreaWrapper>
            )}
        </>
    )
}
