import {useDispatch} from 'react-redux'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import * as Styles from './save-button.styled'
import {MouseEvent} from 'react'
import {usePolicyManagement} from '../../../../../../policy-management/contexts/policy-management/use-policy-management'

interface SaveButtonProps {
    locationId: GuidType
}

export function SaveButton({locationId}: SaveButtonProps): JSX.Element {
    const {
        saveUpdatedConfig,
        getPolicyLicenses,
        isValidChange,
        selectedMetricsPolicy,
        selectedEnforcementPolicy,
        initialValue,
    } = useVesselManagement()
    const dispatch = useDispatch()
    const {
        assignOneVesselToMetricsPolicy,
        unassignOneVesselFromMetricsPolicy,
        assignOneVesselToEnforcementPolicy,
        unassignOneVesselFromEnforcementPolicy,
    } = usePolicyManagement()

    const onSaveHandler = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const formattedLocations =
            !initialValue.metricsPolicy.locations?.filter((location) => location !== locationId) ||
            initialValue.metricsPolicy.locations?.filter((location) => location !== locationId)
                .length === 0
                ? undefined
                : initialValue.metricsPolicy.locations?.filter(
                      (location) => location !== locationId,
                  )

        selectedMetricsPolicy
            ? assignOneVesselToMetricsPolicy(locationId, selectedMetricsPolicy)
            : unassignOneVesselFromMetricsPolicy(initialValue.metricsPolicy.id!, formattedLocations)
        selectedEnforcementPolicy
            ? assignOneVesselToEnforcementPolicy(locationId, selectedEnforcementPolicy)
            : unassignOneVesselFromEnforcementPolicy(
                  initialValue.enforcementPolicy.id!,
                  formattedLocations,
              )
        await getPolicyLicenses()
        saveUpdatedConfig(locationId, dispatch)
    }

    return (
        <Styles.ButtonWrapper>
            <Styles.Button
                id="save-button-vessel-management"
                onClick={onSaveHandler}
                isChanged={isValidChange}
            >
                Save
            </Styles.Button>
        </Styles.ButtonWrapper>
    )
}
