import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'

export function AddButton(): JSX.Element | null {
    const {saveMonitoringChanges, findNetworkAsset, updateAssetData} = usePagedUnknownAssetsBeta()
    const blocked = updateAssetData.hostname === undefined || updateAssetData.hostname.length === 0

    function onClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        saveMonitoringChanges(findNetworkAsset?.identity ?? '', updateAssetData)
    }

    return (
        <Styled.Button disabled={blocked} id="add-button" onClick={onClick}>
            Add
        </Styled.Button>
    )
}
