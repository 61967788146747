import * as Styled from './items-list.styled'
import NETWORK_ASSET from '../../../../../@assets/icons/network-asset.svg'
import USB from '../../../../../@assets/icons/usb.svg'
import SOFTWARE from '../../../../../@assets/icons/software.svg'
import {IncidentLinkType} from '../../../contexts/types/incident-link'
import {Link} from 'react-router-dom'
import {LinkedItemData} from '../../../contexts/types/incident-linked-items'
import {getImageForlinkedItemState} from '../../../data-helpers'
import {ItemDetailPopup} from '../item-detail-popup/item-detail-popup'
import {useState} from 'react'

interface ItemRowProps {
    item: LinkedItemData
    lastRow: boolean
    link?: boolean
}

export function ItemRow({item, link, lastRow}: ItemRowProps): JSX.Element {
    const [isHovering, setIsHovering] = useState(false)

    const icon = (() => {
        switch (item.itemType) {
            case IncidentLinkType.NETWORK_ASSET:
                return NETWORK_ASSET
            case IncidentLinkType.USB:
                return USB
            case IncidentLinkType.SOFTWARE:
                return SOFTWARE
        }
    })()

    return (
        <>
            {link && item.linkUrl ? (
                <Link to={item.linkUrl} id={`link-${item.incidentLinkIdentity}`}>
                    <Styled.ItemRow watched={item.monitored}>
                        <img src={icon} /> {item.label}
                        <img
                            src={getImageForlinkedItemState(item.state)}
                            style={{width: '12px', marginLeft: '3px'}}
                            onMouseOver={() => setIsHovering(true)}
                            onMouseOut={() => setIsHovering(false)}
                            id={`item-row-${item.incidentLinkIdentity}`}
                        />
                        {isHovering && <ItemDetailPopup item={item} lastRow={lastRow} />}
                    </Styled.ItemRow>
                </Link>
            ) : (
                <Styled.ItemRow
                    watched={item.monitored}
                    id={`item-row-monitored-${item.incidentLinkIdentity}`}
                >
                    <img src={icon} /> {item.label}
                    <img
                        src={getImageForlinkedItemState(item.state)}
                        style={{width: '12px', marginLeft: '3px'}}
                        id={`item-row-monitored-${item.incidentLinkIdentity}-icon`}
                    />
                </Styled.ItemRow>
            )}
        </>
    )
}
