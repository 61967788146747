import LoadingState from '../../../../values/loading-state-enum'
import {PagedUnknownAssetsBetaState, ProcessingStatus} from '../types/paged-unknown-assets-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {DEFAULT_PAGED_UNKNOWN_ASSETS_BETA_STATE} from '../types/default-paged-unknown-assets-state'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import {isEqual} from 'lodash'
import produce from 'immer'
import {Anomaly} from '../types/formatted-anomaly'
import {
    AssignedMacAddressType,
    DEFAULT_ASSET_UPDATE_DATA,
    UpdateAssignedMacAddressType,
} from '../types/asset-data-type'

function getUpdateAssignedMacAddresses(data: AssignedMacAddressType): UpdateAssignedMacAddressType {
    const assignedMacAddressesArray = [] as string[]
    data.ipAddresses?.map(
        (ipAddress) => ipAddress.ip && assignedMacAddressesArray.push(ipAddress.ip),
    )
    return {ipAddresses: assignedMacAddressesArray, mac: data.mac}
}

export const pagedUnknownAssetsBetaReducer = produce(
    (draft: PagedUnknownAssetsBetaState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.dataUnknownAssets = undefined
                draft.totalNumberOfUnknownAssets = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.selectedPage = action.payload
                break
            case ActionType.SET_FILTER:
                draft.selectedPage = 0
                break
            case ActionType.RECEIVE_ALL_NETWORK_BEHAVIOURS:
                if (!isEqual(draft.allNetworkBehaviours, action.payload)) {
                    draft.allNetworkBehaviours = action.payload
                }
                break
            case ActionType.RECEIVE_NETWORK_BEHAVIOURS_FOR_ASSET:
                draft.networkAssetBehaviourData = action.payload.map((data) => data)
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.selectedPage = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.totalNumberOfUnknownAssets = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages
                draft.isProcessingnetworkAssetModal = ProcessingStatus.PROCESSED

                if (!draft.dataUnknownAssets) {
                    draft.dataUnknownAssets = new Map<number, Anomaly[]>()
                }
                if (
                    !draft.dataUnknownAssets.has(draft.selectedPage) ||
                    !isEqual(draft.dataUnknownAssets.get(draft.selectedPage), action.payload.items)
                ) {
                    draft.dataUnknownAssets.set(draft.selectedPage, action.payload.items)
                }
                break

            case ActionType.RECEIVE_ISOLATED_ASSETS_STATUS:
                draft.isolatedAssetsStatus = action.payload
                break

            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break

            case ActionType.RECEIVED_REQUESTED_NETWORK_ASSETS:
                if (!isEqual(draft.findNetworkAsset, action.payload)) {
                    draft.findNetworkAsset = action.payload
                }
                break
            case ActionType.DISPLAY_NETWORK_ASSET_DETAILS_MODAL:
                draft.networkAssetModalId = action.payload
                draft.isProcessingnetworkAssetModal = ProcessingStatus.PROCESSING
                break
            case ActionType.SWITCH_TO_CACHED_DATA_MODAL:
                draft.isProcessingnetworkAssetModal = ProcessingStatus.PROCESSED
                break
            case ActionType.CLOSE_NETWORK_ASSET_DETAILS_MODAL:
                draft.networkAssetModalId = null
                draft.isProcessingnetworkAssetModal = ProcessingStatus.NOT_PROCESSING
                break

            case ActionType.DISPLAY_ENABLE_MONITORING_MODAL:
                draft.showEnableMonitoringModal = action.payload
                break
            case ActionType.CLOSE_ENABLE_MONITORING_MODAL:
                draft.updateAssetData = DEFAULT_ASSET_UPDATE_DATA
                draft.displayedHostname = undefined
                break
            case ActionType.RECEIVE_REQUESTED_ASSET_DATA:
                draft.assetData = action.payload
                draft.updateAssetData.agentId = action.payload.agentIdentifier?.agentId
                draft.updateAssetData.alias = action.payload.alias
                const updateAssignedMacAddresses = [] as UpdateAssignedMacAddressType[]
                action.payload.assignedMacAddresses?.map((e) =>
                    updateAssignedMacAddresses.push(getUpdateAssignedMacAddresses(e)),
                )
                draft.updateAssetData.assignedMacAddresses = updateAssignedMacAddresses
                draft.updateAssetData.hostname = action.payload.hostnameIdentifier?.hostname
                draft.updateAssetData.location = action.payload.location
                draft.updateAssetData.mainIpAddress = action.payload.mainIpAddress?.ip
                draft.updateAssetData.reportConfig = action.payload.reportConfig
                const unassignedIpAddresses = [] as string[]
                action.payload.unassignedIpAddresses?.map(
                    (unassignedIpAddress) =>
                        unassignedIpAddress.ip &&
                        unassignedIpAddresses.push(unassignedIpAddress.ip),
                )
                draft.updateAssetData.unassignedIpAddresses = unassignedIpAddresses
                draft.updateAssetData.value = action.payload.value
                break
            case ActionType.SET_CURRENT_LOCATION_CODE:
                draft.updateAssetData.locationCode = action.payload
                break
            case ActionType.SET_HOSTNAME_DATA:
                draft.displayedHostname = action.payload
                break
            case ActionType.SET_CHANGE_ASSET_VALUE:
                draft.updateAssetData.value = action.payload
                break
            case ActionType.SET_CHANGE_HOSTNAME:
                draft.displayedHostname = action.payload
                draft.updateAssetData.hostname =
                    action.payload.trim().length === 0 ? undefined : action.payload.trim()
                break

            case ActionType.SET_SAVE_PROCESSING_STATUS:
                draft.isProcessingSave = action.payload
                break
            case ActionType.SET_IS_INTERLINKING:
                draft.isInterlinking = action.payload
                break
            case ActionType.TOGGLE_TRUST:
                draft.loadingToggleState = action.payload.loading
                    ? {id: action.payload.id, loading: LoadingState.Loaded}
                    : {id: action.payload.id, loading: LoadingState.RequestingData}
                break
            case ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN:
                draft.isInactive = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_UNKNOWN_ASSETS_BETA_STATE
                break
            case ActionType.REPORT_CREATE:
                draft.reportUrl = action.payload
                break
            case ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP:
                draft.showGenerateReportPopup = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
