import {PopulatedData} from './populated-data'
import {useEffect} from 'react'
import {useMetricsWidget} from '../contexts/use-metrics-component'
import {Loading} from './loading'

export function DataLoaded({location}: {location?: string}): JSX.Element {
    const {
        setDataForMetricsWidgetOnDashboard,
        selectedAnalysisPeriod,
        selectedMetricWidget,
        loadedDataState,
    } = useMetricsWidget()

    useEffect(() => {
        setDataForMetricsWidgetOnDashboard(selectedAnalysisPeriod, selectedMetricWidget, location)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAnalysisPeriod, selectedMetricWidget, location])

    return loadedDataState ? <PopulatedData /> : <Loading />
}
