import {useMemo} from 'react'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {NmeaInventorySortType} from '../../../contexts/types/nmea-inventory-sort-type'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'
import {SortableColumn} from './sortable-column'
import * as Styled from './table-header-row.styled'
import {TableHeaderCell} from './table-header-row.styled'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()
    const {modalIdForNmeaDetails} = usePagedNmeaInventory()

    const displayNMEADetailsModal = useMemo(() => {
        return modalIdForNmeaDetails != null
    }, [modalIdForNmeaDetails])

    return (
        <Styled.TableHeaderGrid
            width={width}
            id="TableHeaderGrid"
            reducedGrid={displayNMEADetailsModal}
        >
            <SortableColumn
                gridColumn={2}
                text="First Detected"
                colName={NmeaInventorySortType.FIRST_SEEN_TIMESTAMP}
            />
            <SortableColumn
                gridColumn={3}
                text="Last Active"
                colName={NmeaInventorySortType.LAST_SEEN_TIMESTAMP}
            />
            <SortableColumn
                gridColumn={4}
                text="Vessel"
                colName={NmeaInventorySortType.VESSEL_NAME}
            />
            <SortableColumn
                gridColumn={5}
                text="NMEA Talker"
                colName={NmeaInventorySortType.NMEA_TALKER}
            />
            <SortableColumn
                gridColumn={6}
                text="NMEA Sentence"
                colName={NmeaInventorySortType.NMEA_SENTENCE}
            />
            <TableHeaderCell gridColumn={7}>Relay IP</TableHeaderCell>
            <TableHeaderCell gridColumn={8}>Receiver</TableHeaderCell>
            <TableHeaderCell gridColumn={9}>Parameters</TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
