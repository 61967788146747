import {Check} from 'react-feather'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridType} from '../shared/grid-layout-helper'
import {DataCell} from './data-cell'
import {TextArea} from './_styled/data-cell.styled'

interface DataTextAreaProps {
    label: string
    gridArea?: string
    initialValue: string[]
    onChangeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    changed: boolean
}

export function DataTextArea({
    label,
    gridArea,
    initialValue,
    onChangeHandler,
    changed,
}: DataTextAreaProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <DataCell label={label} gridArea={gridArea}>
            <TextArea
                value={initialValue.join('\n')}
                gridLayout={getGridType(width)}
                rows={initialValue.length}
                onChange={onChangeHandler}
                isChanged={changed}
            />
            {changed && <Check color={'green'} width={19} height={19} />}
        </DataCell>
    )
}
