import styled from 'styled-components'

interface DataLoadingProps {
    forceHeight?: boolean
}

export const DataLoading = styled.div<DataLoadingProps>`
    display: flex;
    ${(props) => (props.forceHeight ? 'height: 100%' : 'flex: 1')};
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.modalDialog.dataArea.background};
`
