import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {WarningMessageContainer} from './warning-message.styled'

export function WarningMessageCard(): JSX.Element {
    const {width} = useDimensions()

    return (
        <WarningMessageContainer width={width}>
            Vessel does not support incident automation
        </WarningMessageContainer>
    )
}
