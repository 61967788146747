import {Overlay} from '../../pages/unknown-assets-v2/components/enable-monitoring-modal/components/modal-overlay'
import {ContactSOC} from './components/contact-soc'

export function ContactSocModal(): JSX.Element {
    return (
        <Overlay>
            <ContactSOC />
        </Overlay>
    )
}
