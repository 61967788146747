import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {hasLocationsSelector} from '../../../../../store/state/locations/selectors'
import {NoDataMessageStyle} from '../_styled/no-data-message.styled'
import {VesselsTablePopulated} from './vessels-table-populated'

export function VesselsTableLoaded(): JSX.Element {
    if (!useTypedSelector(hasLocationsSelector)) {
        return <NoDataMessageStyle>There are no vessels</NoDataMessageStyle>
    }
    return <VesselsTablePopulated />
}
