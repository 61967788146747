import styled from 'styled-components'

interface SwitchToggleProps {
    align: 'flex-start' | 'center' | 'flex-end'
}
export const SwitchToggle = styled.div<SwitchToggleProps>`
    display: flex;
    justify-content: ${(props) => props.align};
    align-items: center;
    ${(props) => props.theme.font.weight.semibold};
`

interface ToggleButtonProps {
    selectable?: boolean
}
export const ToggleButton = styled.div<ToggleButtonProps>`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
