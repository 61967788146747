import styled from 'styled-components'

interface LegendItemProps {
    last?: boolean
}
export const LegendItem = styled.div<LegendItemProps>`
    display: flex;
    align-items: center;
    margin-right: ${(props) => (props.last ? 0 : '30px')};
`
