import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'

export function CancelButton(): JSX.Element {
    const {setDisplayAddNewUserModal} = useUserManagement()

    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setDisplayAddNewUserModal(false)
    }
    return (
        <Styled.Button id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Styled.Button>
    )
}
