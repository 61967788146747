import {Location} from '../../../../../../store/state/locations/state'
import {TableDataCell} from './_styled/table-content.styled'

interface TableNameCellProps {
    location: Location
    gridRow: number
    isHovering: boolean
}

export function TableNameCell({location, gridRow, isHovering}: TableNameCellProps): JSX.Element {
    return (
        <TableDataCell
            gridRow={gridRow}
            gridColumn={2}
            isHovering={isHovering}
            id={`modal_location-description_${location.location}`}
        >
            {location.description}
        </TableDataCell>
    )
}
