import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const FilterComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: ${(props) => props.theme.colors.background.default};
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 0 15px;
    border: 1px solid #e2e5e9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`

export const FilterComponentWrapper = styled.div`
    position: relative;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    ${smallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
export const Label = styled.label`
    ${smallFont()}
    margin-bottom: ${spacing(1)};
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #6d7c92;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`
