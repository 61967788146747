import styled from 'styled-components'

interface TableLabelProps {
    first?: boolean
    last?: boolean
}
export const TableLabel = styled.th<TableLabelProps>`
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding-right: 10px;
    padding-top: ${(props) => (props.first ? 0 : '7.5px')};
    padding-bottom: ${(props) => (props.last ? 0 : '7.5px')};
`
