import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {WidgetType} from '../../widget-type'
import {BottomContentSection} from '../general/content-area/bottom-content-section'
import {ContentArea} from '../general/content-area/content-area.styled'
import {Title} from '../general/content-area/title.styled'
import {getGridLayout} from '../general/helpers'
import {DisabledDataWidgetWrapper} from './components/disabled-data-widget-wrapper'
import {DisabledDataHeadlineValues} from './components/disabled-headline-values'
import {NoDataContent} from './components/no-data-content'
import {MessageSection} from './components/_styled/no-data-content.styled'

interface DisabledDataWidgetProps {
    type: WidgetType
}

export function DisabledDataWidget({type}: DisabledDataWidgetProps): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const titleText =
        type === WidgetType.INCIDENT_RESPONSE ? 'Incident Response' : 'Asset Discovery'
    const subTitleText =
        type === WidgetType.INCIDENT_RESPONSE ? 'Incident Overview' : 'Recently Discovered Assets'

    return (
        <DisabledDataWidgetWrapper gridLayout={gridLayout} titleText={titleText}>
            <ContentArea gridLayout={gridLayout}>
                <MessageSection>
                    <NoDataContent type={type} />
                </MessageSection>
                <BottomContentSection>
                    <Title gridLayout={gridLayout}>{subTitleText}</Title>
                    <DisabledDataHeadlineValues gridLayout={gridLayout} type={type} />
                </BottomContentSection>
            </ContentArea>
        </DisabledDataWidgetWrapper>
    )
}
