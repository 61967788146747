import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellMonitoringCoverage(): JSX.Element {
    const {data, initialData, displayData, changeMonitoringCoverage} = useVesselConfig()
    const changed = data.monitoringCoverage != initialData.monitoringCoverage
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeMonitoringCoverage(e.target.value)
    }
    return (
        <DataInput
            label="Monitoring Coverage (%)"
            gridArea="MONITORING-COVERAGE"
            initialValue={displayData.monitoringCoverage}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
