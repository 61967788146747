import {createContext, Dispatch} from 'react'
import {DEFAULT_MODAL_EDIT_ASSET_STATE} from './types/default-modal-upload-document-state'
import {ModalUploadDocumentState} from './types/modal-upload-document-state'
import {AllActions} from './state/actions'

export const ModalUploadDocumentContext = createContext<{
    state: ModalUploadDocumentState
    dispatch: Dispatch<AllActions> | undefined
    onUploadSuccess: () => void
}>({state: DEFAULT_MODAL_EDIT_ASSET_STATE, dispatch: undefined, onUploadSuccess: () => {}})
