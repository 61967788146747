import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(2)};
    padding: 0 ${spacing(3)} ${spacing(2)};
    margin: 0;
    width: 100%;
`

export const DetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: 150px auto;
    gap: ${spacing(1)};
`
export const Panel = styled.div`
    width: 100%;
    padding-bottom: ${spacing(2)};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`
