import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import * as Styled from './_styled/license-table-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    return (
        <Styled.TableHeaderGrid width={width} showReducedGrid={false}>
            <Styled.TableHeaderCell gridColumn={2} width={width}>
                Enabled
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={3} width={width}>
                Licenses
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={4} width={width}>
                Max vessels
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={5} width={width}>
                End date
            </Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
