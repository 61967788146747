import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './expand-panel-button.styled'
import {
    DetailExpandedType,
    IncidentsDetailType,
} from '../../../contexts/types/incidents-details-type'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useDispatch} from 'react-redux'
import {toggleDetailExpanded} from '../../../../../store/state/incidents-filter/action-creators'

interface ExpandPanelButtonProps {
    headingRow: IncidentsDetailType
}

export function ExpandPanelButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    const {detailExpanded} = useTypedSelector(incidentsFilterSelector)
    const dispatch = useDispatch()
    const isExpanded = getCurrentDetailExpanded(headingRow, detailExpanded)

    function setNewDetailExpanded() {
        const newDetailExpanded: DetailExpandedType = getNewDetailExpanded(
            headingRow,
            detailExpanded,
        )
        dispatch(toggleDetailExpanded(newDetailExpanded))
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow id={`heading-row-${headingRow}`}>
                {headingRow}
            </Styles.DetailHeadingRow>
            <Styles.Button
                onClick={() => setNewDetailExpanded()}
                id={`expand-panel-button-${headingRow}`}
            >
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewDetailExpanded = (
    toggledDetail: IncidentsDetailType,
    currentDetailExpanded: DetailExpandedType,
): DetailExpandedType => {
    switch (toggledDetail) {
        case IncidentsDetailType.History:
            return {
                ...currentDetailExpanded,
                historyExpanded: !currentDetailExpanded.historyExpanded,
            }
        case IncidentsDetailType.Recommendations:
            return {
                ...currentDetailExpanded,
                recommendationsExpanded: !currentDetailExpanded.recommendationsExpanded,
            }
        default:
            return currentDetailExpanded
    }
}

const getCurrentDetailExpanded = (
    toggledDetail: IncidentsDetailType,
    currentDetailExpanded: DetailExpandedType,
): boolean => {
    switch (toggledDetail) {
        case IncidentsDetailType.History:
            return currentDetailExpanded.historyExpanded
        case IncidentsDetailType.Recommendations:
            return currentDetailExpanded.recommendationsExpanded
        default:
            return false
    }
}
