import {User} from '../../../../store/state/users/state'
import {Role} from '../../../../values/Role'
import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'

export interface UserManagementState {
    loadingDataState: LoadingState
    activeUserId: GuidType | null
    initialUserDetails: User
    userDetails: User
    displayUserDetails: User
    subscriptionStatus: {
        initialSubscriptionStatus: boolean
        newStatus: boolean
        userId: string
        emailId: string
    }
    isSubscriptionChanged: boolean
    isChanged: boolean
    isRoleChanged: boolean
    saveResultMessage: 'Save failed' | 'Saved successfully' | undefined
    disableUserResultMessage:
        | 'Disable user failed'
        | 'Disabled successfully'
        | 'Enable user failed'
        | 'Enabled successfully'
        | undefined
    displayAddNewUserModal: boolean
    isSaveProcessing: ProcessingStatus
    newUserData: NewUserData
    viewAsExternal: boolean
    detailExpanded: DetailExpandedType
    currentSelectedAlertId: GuidType | undefined
}

export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}

export enum StrictModeLabel {
    LIMIT = 'Strictly limit access to this tag',
    DEFAULT = 'Default visibility to this tag',
}

export interface NewUserData {
    username: string
    email: string
    external: boolean
    roles: Role[]
    subscription: boolean
}

export interface DetailExpandedType {
    detailsExpanded: boolean
    rolesExpanded: boolean
    subscriptionsExpanded: boolean
    savedFiltersAlertsExpanded: boolean
    historyExpanded: boolean
}

export enum UserManagementDetailType {
    UserDetails = 'Details',
    Roles = 'Roles',
    Subscriptions = 'Subscriptions',
    SavedFiltersAlerts = 'Saved Filters Alerts',
    History = 'History',
}
