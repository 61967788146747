import {FilterIndicator} from './applied-filter-indicator.styled'

interface AppliedFilterIndicatorProps {
    numOfAppliedFilter: number | undefined
    searchedLocationDescription?: string | undefined
}

export function AppliedFilterIndicator({
    numOfAppliedFilter,
    searchedLocationDescription,
}: AppliedFilterIndicatorProps): JSX.Element {
    const filterSelectedMessage =
        numOfAppliedFilter && numOfAppliedFilter <= 1
            ? `${numOfAppliedFilter} filter applied`
            : `${numOfAppliedFilter} filters applied`
    const vesselSearchedMessage = 'vessel searched'

    const indicatorMessage = searchedLocationDescription
        ? vesselSearchedMessage
        : filterSelectedMessage

    return (
        <FilterIndicator id={`filter-bar_applied-filter-indicator-${indicatorMessage}`}>
            {indicatorMessage}
        </FilterIndicator>
    )
}
