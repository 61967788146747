import {createSelector} from 'reselect'
import {locationsLoadingSelector} from '../../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../../store/state/nodes/selectors'
import {fetchingSfmScoresSelector} from '../../../../store/state/sfm-scores/selectors'
import {threatMeasuresFetchingSelector} from '../../../../store/state/threat-measures/selectors'
import {fetchingLatestLocationThreatScoreSelector} from '../../../../store/state/latest-location-threat-scores/selectors'
import {isLoadingMyVesselsFiltersSelector} from '../../../../store/state/my-vessels-filters/selectors'
import {isLoadingVesselsFilterSelector} from '../../../../store/state/vessel-filter/selectors'

export const isLoadingReSelector = createSelector(
    locationsLoadingSelector,
    nodesFetchingSelector,
    fetchingSfmScoresSelector,
    threatMeasuresFetchingSelector,
    fetchingLatestLocationThreatScoreSelector,
    isLoadingMyVesselsFiltersSelector,
    isLoadingVesselsFilterSelector,
    (
        locationsLoading,
        nodesLoading,
        sfmScoreLoading,
        threatMeasuresLoading,
        locationThreatScoreLoading,
        filterSettingsLoading,
        loadingVesselsFilter,
    ): boolean => {
        return (
            locationsLoading === true ||
            nodesLoading === true ||
            sfmScoreLoading === true ||
            threatMeasuresLoading === true ||
            locationThreatScoreLoading === true ||
            filterSettingsLoading === true ||
            loadingVesselsFilter === true
        )
    },
)
