import styled from 'styled-components'

export const NoDataMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
