import * as Styles from './_styled/vessel-filters.styled'
import {SectionContent, SectionTitle} from '../assign-policy-dialog.styled'
import {VesselTagSearch} from './tags-search/tag-search'
import {VesselRow} from './vessel-row'
import {NoVesselMessage} from './no-vessel-message'
import {VesselOnlySearch} from './tags-search/vessel-only-search'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../../store/state/locations/selectors'
import {LocationIdType} from '../../../../../../store/state/locations/state'
import {currentUserSelector} from '../../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../../store/state/users/state'
import {usePolicyManagement} from '../../../../contexts/policy-management/use-policy-management'
import {filteredByTagsVesselIds} from './reselector/filtered-by-tags-vessel-ids.reselector'
import {vesselFilterSelector} from '../../../../../../store/state/vessel-filter/selectors'
import {useEffect} from 'react'

function isChecked(id: LocationIdType, vesselsSelected: Set<LocationIdType> | undefined): boolean {
    return !vesselsSelected ? true : vesselsSelected.has(id)
}

export function VesselFilter(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const defaultTag = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const {toggleVessel, assignPolicyDialogState, setSearchVesselTagTerm} = usePolicyManagement()
    const vessels = locations.map((location) => location.location)
    const vesselsSelected = assignPolicyDialogState.newLocations
    const filteredVessels = filteredByTagsVesselIds(locations, assignPolicyDialogState)
    const noVessel = filteredVessels.length === 0
    const {strictMode} = useTypedSelector(currentUserSelector)

    useEffect(() => {
        if (defaultTag.length > 0) {
            setSearchVesselTagTerm([...defaultTag, ...assignPolicyDialogState.searchVesselTagTerm])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function setChecked(id: LocationIdType, checked: boolean): void {
        toggleVessel(vessels, id, checked)
    }

    return (
        <SectionContent>
            <SectionTitle>Select vessels to apply policy to:</SectionTitle>
            {!strictMode ? (
                <VesselTagSearch />
            ) : strictMode === StrictMode.Strict ? (
                <VesselOnlySearch />
            ) : (
                <VesselTagSearch />
            )}
            <Styles.Note>
                Note: Policy is applied to the vessel and will not be updated if the vessel tag is
                changed. This will replace any existing policy on the vessel. Greyed out vessels are
                not selectable because they are either already selected or assigned to another
                policy.
            </Styles.Note>
            <Styles.Vessels>
                {noVessel && <NoVesselMessage />}
                {filteredVessels.map((id) => {
                    return (
                        <VesselRow
                            key={id}
                            locationId={id}
                            checked={isChecked(id, vesselsSelected)}
                            setChecked={setChecked}
                        />
                    )
                })}
            </Styles.Vessels>
        </SectionContent>
    )
}
