import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import * as Styles from './expand-filter-details-button.styled'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'
import {
    FilterBarType,
    FilterExpandedType,
} from '../../../../../../store/state/incidents-report-filter/state'
import {incidentsReportFilterSelector} from '../../../../../../store/state/incidents-report-filter/selectors'
import {toggleFilterExpanded} from '../../../../../../store/state/incidents-report-filter/action-creators'

interface ExpandFilterDetailsButtonProps {
    headingRow: FilterBarType
}

export function ExpandFilterDetailsButton({
    headingRow,
}: ExpandFilterDetailsButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const {filterExpanded} = useTypedSelector(incidentsReportFilterSelector)
    const isExpanded = getCurrentFilterExpanded(headingRow, filterExpanded)
    function setNewFilterExpanded() {
        const newFilterExpanded: FilterExpandedType = getNewFilterExpanded(
            headingRow,
            filterExpanded,
        )
        dispatch(toggleFilterExpanded(newFilterExpanded))
    }

    return (
        <Styles.HeadingWrapper>
            <Styles.FilterHeadingRow
                id={`${PageType.INCIDENTS_REPORT}_filter-bar_expand-heading-row-${headingRow}`}
            >
                {headingRow}
            </Styles.FilterHeadingRow>
            <Styles.Button
                onClick={() => setNewFilterExpanded()}
                id={`${PageType.INCIDENTS_REPORT}_filter-bar_expand-button-${headingRow}`}
            >
                <Styles.IconWrapper>
                    <Icon
                        glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'}
                        height={15}
                        width={15}
                    />
                </Styles.IconWrapper>
            </Styles.Button>
        </Styles.HeadingWrapper>
    )
}

const getNewFilterExpanded = (
    toggledFilter: FilterBarType,
    currentFilterExpanded: FilterExpandedType,
): FilterExpandedType => {
    switch (toggledFilter) {
        case FilterBarType.PeriodSelection:
            return {
                ...currentFilterExpanded,
                periodExpanded: !currentFilterExpanded.periodExpanded,
            }
        case FilterBarType.Vessels:
            return {
                ...currentFilterExpanded,
                vesselExpanded: !currentFilterExpanded.vesselExpanded,
            }
        default:
            return currentFilterExpanded
    }
}

const getCurrentFilterExpanded = (
    toggledFilter: FilterBarType,
    currentFilterExpanded: FilterExpandedType,
): boolean => {
    switch (toggledFilter) {
        case FilterBarType.PeriodSelection:
            return currentFilterExpanded.periodExpanded
        case FilterBarType.Vessels:
            return currentFilterExpanded.vesselExpanded
        default:
            return false
    }
}
