import SEVERITY_LOW from '../../../../../../../@assets/icons/severity-low.svg'
import SEVERITY_MEDIUM from '../../../../../../../@assets/icons/severity-medium.svg'
import SEVERITY_HIGH from '../../../../../../../@assets/icons/severity-high.svg'
import SEVERITY_CRITICAL from '../../../../../../../@assets/icons/severity-critical.svg'
import * as Styled from './incident-type-severity-section.styled'
import {IncidentSeverityValues} from '../../../../../../../values/incident-response-values'
import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'

interface Props {
    incidentTypeName: string
    severity: IncidentSeverityValues
    selected: boolean
    disabled?: boolean
}

export function IncidentTypeSeverity({
    incidentTypeName,
    severity,
    selected,
    disabled,
}: Props): JSX.Element {
    const {selectSeverity} = useIncidentsPolicy()

    const severityIcon = (): string => {
        switch (severity) {
            case IncidentSeverityValues.LOW:
                return SEVERITY_LOW
            case IncidentSeverityValues.MEDIUM:
                return SEVERITY_MEDIUM
            case IncidentSeverityValues.HIGH:
                return SEVERITY_HIGH
            case IncidentSeverityValues.CRITICAL:
                return SEVERITY_CRITICAL
        }
    }
    return (
        <Styled.Option selected={selected}>
            <img
                src={severityIcon()}
                onClick={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    selectSeverity(incidentTypeName, severity, !selected)
                }}
                style={{verticalAlign: 'bottom'}}
            />
        </Styled.Option>
    )
}
