import LoadingState from '../../../values/loading-state-enum'
import AppState from '../../types/app-state'
import {User} from '../users/state'
import {EmailRecipientData} from './types/email-recipient-data'

export const emailRecipientDataSelector = (state: AppState): EmailRecipientData[] =>
    state.emailRecipientData.emailRecipientData
export const hasDataSelector = (state: AppState): boolean =>
    state.emailRecipientData.emailRecipientData.length !== 0
export const isFetchingSelector = (state: AppState): boolean =>
    state.emailRecipientData.isFetching === LoadingState.NotPopulated ||
    state.emailRecipientData.isFetching === LoadingState.RequestingData
export const loadingSelector = (state: AppState): LoadingState =>
    state.emailRecipientData.isFetching

export const listOfExistingRecipientSelector = (state: AppState): string[] =>
    getListOfExistingEmails(
        listOfEmailRecipient(state.emailRecipientData.emailRecipientData),
        internalUsersEmails(state.users.users, state.emailRecipientData.emailRecipientData),
    )

function getListOfExistingEmails(
    listOfEmailrecipients: string[],
    listOfInternalEmails: string[],
): string[] {
    return listOfEmailrecipients.concat(listOfInternalEmails)
}
function listOfEmailRecipient(emailRecipientData: EmailRecipientData[]): string[] {
    return emailRecipientData.filter((email) => email.email != undefined).map((item) => item.email)
}
function internalUsersEmails(users: User[], emailRecipientData: EmailRecipientData[]): string[] {
    return users
        .filter((user) => user.internalUser)
        .filter((internalUser) =>
            emailRecipientData.map((item) => item.user).includes(internalUser.user),
        )
        .map((internalUser) => internalUser.email)
}
