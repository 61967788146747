import {uniq} from 'lodash'
import {IncidentsFilter} from '../../../../values/user-preferences/incidents-filter'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'
import {IncidentStatus} from '../../../../pages/incidents-v3/contexts/types/incident-status'
import {getUserDescription} from '../../../../helpers/getUserDescription'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usersSelector} from '../../../../store/state/users/selectors'

function getFormattedStatus(status: IncidentStatus): string | undefined {
    switch (status) {
        case IncidentStatus.NEW:
            return 'New'
        case IncidentStatus.OPEN:
            return 'Open'
        case IncidentStatus.CLOSED:
        case IncidentStatus.CLOSED_FALSE_POSITIVE:
        case IncidentStatus.CLOSED_MONITOR:
            return 'Closed'
        case IncidentStatus.DRAFT:
            return 'Draft'
        default:
            return undefined
    }
}

interface DetailFilterProps {
    criteria: IncidentsFilter
    tooltip?: boolean
}

export function DetailFilterIncidents({criteria, tooltip}: DetailFilterProps): JSX.Element {
    const users = useTypedSelector(usersSelector).filter(
        (user) => !user.internalUser && user.active && !user.deleted,
    )

    const formattedStatus =
        criteria.filterByStatus &&
        uniq(criteria.filterByStatus.map((status) => getFormattedStatus(status)))

    const formattedAssignedTo =
        criteria.assignedTo && getUserDescription(users, criteria.assignedTo, undefined)

    return (
        <DetailsWrapper>
            {formattedStatus && (
                <Details id="formattedStatus">• Status: {formattedStatus.toString()}</Details>
            )}
            {criteria.selectedIncidentType && (
                <Details id="selectedIncidentType">
                    • Incident Type: {criteria.selectedIncidentType.toLocaleLowerCase()}
                </Details>
            )}
            {criteria.assignedTo && (
                <Details id="formattedAssignedTo">• Assigned to: {formattedAssignedTo}</Details>
            )}
            {criteria.filterBySeverities && (
                <Details id="filterBySeverities">
                    • Severity: {criteria.filterBySeverities.toString().toLocaleLowerCase()}
                </Details>
            )}
            {tooltip && (
                <Details id="orderIncidentsBy">
                    • Order By: {criteria.orderIncidentsBy.column} (
                    {criteria.orderIncidentsBy.direction.toLocaleLowerCase()})
                </Details>
            )}
            {criteria.createdToRelative && (
                <Details id="createdToRelative">
                    • Raised on: Before {criteria.createdToRelative.toString()}
                </Details>
            )}
            {criteria.createdFromRelative && (
                <Details id="createdFromRelative">
                    • Raised on: Within {criteria.createdFromRelative.toString()}
                </Details>
            )}
            {criteria.updatedToRelative && (
                <Details id="updatedToRelative">
                    • Updated on: Before {criteria.updatedToRelative.toString()}
                </Details>
            )}
            {criteria.updatedFromRelative && (
                <Details id="updatedFromRelative">
                    • Updated on: Within {criteria.updatedFromRelative.toString()}
                </Details>
            )}
            {criteria.unseenIncidentResponses == true && (
                <Details id="unseenIncidentResponses">• Incidents with new items </Details>
            )}
            {criteria.watchedByCurrentUser && (
                <Details id="watchedByCurrentUser">• Watched incidents</Details>
            )}
        </DetailsWrapper>
    )
}
