import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {DetailsButton} from '../../general/header/details-button/details-button'
import {ReactNode, useRef, useState} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {HeaderNewIndicator} from '../../general/header/new-indicator/new-indicator'
import {Level} from '../../../../../values/location-level-enum'
import {CogButton} from '../../general/header/cog-button/cog-button'
import {IncidentsReportOptions} from './incidents-report-options'
import {PeriodSelect} from './period-select'
import {FilterComponent, FilterComponentWrapper} from './incidents-report-options.styled'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNode[]
    level: Level
    noData?: boolean
}

export function WidgetWrapper({
    gridLayout,
    children,
    level,
    noData = false,
}: WidgetWrapperProps): JSX.Element {
    const {disabledPeriodWidget, hasNewIncidents} = useIncidentsWidget()
    const [openIncidentsWidgetDropDown, setOpenIncidentsWidgetDropDown] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        openIncidentsWidgetDropDown && setOpenIncidentsWidgetDropDown(false)
    })

    return (
        <Widget>
            <Header gridLayout={gridLayout} id="incident-response-widget-header" ref={clickOutside}>
                {hasNewIncidents && <HeaderNewIndicator gridLayout={gridLayout} />}
                <HeaderContent gridLayout={gridLayout} reducedLeftPadding={hasNewIncidents}>
                    <TitleText gridLayout={gridLayout} id="incident-response-widget-title">
                        {level !== Level.VESSEL ? 'Incidents' : 'Incident Response'}
                    </TitleText>
                    {!noData && <DetailsButton linkTo="/incidents" linkIdPrefix="incidents" />}
                    <CogButton
                        filterApplied={openIncidentsWidgetDropDown}
                        onClick={() => setOpenIncidentsWidgetDropDown(!openIncidentsWidgetDropDown)}
                        widget="incidents"
                    />
                </HeaderContent>
            </Header>
            {openIncidentsWidgetDropDown && (
                <FilterComponentWrapper>
                    <FilterComponent ref={clickOutside}>
                        <IncidentsReportOptions />
                        {!disabledPeriodWidget && <PeriodSelect />}
                    </FilterComponent>
                </FilterComponentWrapper>
            )}
            {children}
        </Widget>
    )
}
