import {Overlay} from './modal-overlay'
import {IncidentManagementModalDetail} from './incident-management-modal-detail'

export function IncidentManagementModal(): JSX.Element {
    return (
        <Overlay>
            <IncidentManagementModalDetail />
        </Overlay>
    )
}
