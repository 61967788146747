import {isEqual} from 'lodash'
import {VesselTags} from '../../../store/state/vessel-tags/state'

export function showReducedWidthVersion(width: number): boolean {
    return width <= 320
}

export function showFilterBarOnScreen(width: number): boolean {
    return width > 790
}

export function findRecommendationFromEntryTag(
    existingTags: VesselTags[] | undefined,
    searchTag: string,
): VesselTags[] {
    if (!existingTags || !searchTag || !searchTag?.trim()) {
        return []
    }
    return existingTags
        ?.filter((element) =>
            element.name?.trim().toLowerCase().includes(searchTag?.trim().toLowerCase()),
        )
        .sort((a, b) => a.name?.localeCompare(b.name))
        .slice(0, 5)
}

export function formattedValue(value: string[] | undefined): string[] {
    return value ? value : ['']
}

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export function isEmailValid(email: string | undefined | null): boolean {
    if (email == undefined || email == null || email.length == 0) {
        return true
    }
    return email !== undefined && validEmailRegex.test(email)
}

export function isEmailChanged(
    emailAddress: string | undefined,
    emailUpdate: string | undefined | null,
): boolean {
    if (emailUpdate === undefined && emailAddress !== undefined) {
        return false
    }
    if (emailAddress === undefined && emailUpdate?.length === 0) {
        return false
    }
    return !isEqual(emailAddress, emailUpdate)
}
