import ActionType from './action-type'
import * as Actions from './actions'
import * as uuid from 'uuid'
import {ThunkAction} from 'redux-thunk'
import {TimeRange} from '../../../values/TimeRange'
import {ets, QueryCancellation} from '../../../websocket/Queries'
import {EtsTrendScore} from '../../../values/ets-trend-score/EtsTrendScore'
import {Api, QuerySpecification} from '../../../api/Api'
import AppState from '../../types/app-state'
import {LocationIdType} from '../locations/state'

export interface RawEts {
    timestamp: number
    score: number
}

interface RawEtsList {
    items: RawEts[]
}

const QUERY_PATH = 'enterpriseThreatScore'
const QUERY_PATH_LOCATION = 'locationThreatScore'
const QUERY_SCHEDULE = '10s'

export function fetchTrend(
    timeRange: TimeRange,
    location?: string | null,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api) => {
        dispatch(requestTrendAction(timeRange))

        const query: QuerySpecification = location
            ? etsQueryLocation(timeRange, location)
            : etsQuery(timeRange)

        api.newQuery(query, (data) => {
            const scores: EtsTrendScore[] = convertScores(data)
            dispatch(receiveTrendAction(timeRange, scores))
        })

        return () => {
            setTimeout(() => {
                api.cancelQuery({localId: query.localId})
            }, 5000)
        }
    }
}

export function requestTrendAction(timeRange: TimeRange): Actions.RequestTrendAction {
    return {
        type: ActionType.REQUEST_TREND,
        payload: timeRange,
    }
}

export function receiveTrendAction(
    timeRange: TimeRange,
    scores: EtsTrendScore[],
): Actions.ReceiveTrendAction {
    return {
        type: ActionType.RECEIVE_TREND,
        payload: {
            timeRange: timeRange,
            scores: scores,
        },
    }
}

function etsQuery(timeRange: TimeRange): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${ets}-${timeRange}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
        params: {
            fromRelative: `now() - ${timeRange}`,
        },
    }
}

function etsQueryLocation(timeRange: TimeRange, location: LocationIdType): QuerySpecification {
    return {
        path: QUERY_PATH_LOCATION,
        localId: `${ets}-${timeRange}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
        params: {
            location,
            fromRelative: `now() - ${timeRange}`,
        },
    }
}

function convertScores(data: RawEtsList): EtsTrendScore[] {
    const scores: RawEts[] = data.items
    return scores.map((value) => {
        return {
            time: value.timestamp,
            score: value.score,
        }
    })
}
