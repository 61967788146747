import {NoData} from './no-data'
import {PopulatedData} from './populated-data'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'

export function DataLoaded(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)

    return locations?.length === 0 ? <NoData /> : <PopulatedData />
}
