import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfFilteredIncidents, selectedPage, pageSize} = usePagedIncidents()

    if (
        totalNumberOfFilteredIncidents == undefined ||
        selectedPage == undefined ||
        pageSize == undefined
    ) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfFilteredIncidents
            ? totalNumberOfFilteredIncidents
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfFilteredIncidents !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfFilteredIncidents}
                />
            )}
        </>
    )
}
