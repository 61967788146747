import {DefaultTheme} from 'styled-components'
import {OTAsset} from '../../../store/state/ot-assets/state'
import {StyledTippy} from './vessel-card.styled'

interface RenderProp {
    asset: OTAsset
}

interface ConfigItem {
    label: string
    render: (prop: RenderProp) => JSX.Element
}

type Config = ConfigItem[]

export const systemDetailsConfig: Config = [
    {
        label: 'Asset name',
        render: ({asset}) => (
            <StyledTippy
                content={asset.name}
                placement="bottom-start"
                arrow
                theme={'light-border' as unknown as DefaultTheme}
            >
                <span>{asset.name}</span>
            </StyledTippy>
        ),
    },
    {
        label: 'Type',
        render: ({asset}) => (
            <StyledTippy
                content={asset.assetTypeName}
                placement="bottom-start"
                arrow
                theme={'light-border' as unknown as DefaultTheme}
            >
                <span>{asset.assetTypeName}</span>
            </StyledTippy>
        ),
    },
    {
        label: 'Brand',
        render: ({asset}) => (
            <StyledTippy
                content={asset.vendor}
                placement="bottom-start"
                arrow
                theme={'light-border' as unknown as DefaultTheme}
            >
                <span>{asset.vendor}</span>
            </StyledTippy>
        ),
    },
    {
        label: 'System software',
        render: ({asset}) => (
            <StyledTippy
                content={asset.softwareName}
                placement="bottom-start"
                arrow
                theme={'light-border' as unknown as DefaultTheme}
            >
                <span>{asset.softwareName}</span>
            </StyledTippy>
        ),
    },
    {
        label: 'Physical interface',
        render: ({asset}) => {
            const pi = asset.physicalInterfaces.map((p) => p.name).join(', ')

            return (
                <StyledTippy
                    content={pi}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <span>{pi}</span>
                </StyledTippy>
            )
        },
    },
    {
        label: 'Communication protocol',
        render: ({asset}) => (
            <StyledTippy
                content={asset.communicationProtocolName}
                placement="bottom-start"
                arrow
                theme={'light-border' as unknown as DefaultTheme}
            >
                <span>{asset.communicationProtocolName}</span>
            </StyledTippy>
        ),
    },
]
