import {useDispatch} from 'react-redux'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {resetFilter} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {resetVesselFilter} from '../../../../../store/state/vessel-filter/action-creators'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {INCIDENT_DETAILS} from '../../../data-helpers'
import * as Styles from './reset-filter-button.styled'
import {logButtonClickFor} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function ResetFilterButton(): JSX.Element {
    const dispatch = useDispatch()
    const {closeIncidentDetailsModal} = usePagedIncidents()
    const {filterByStatus, filterBySeverities} = useTypedSelector(incidentsFilterSelector)
    const activeReset = filterByStatus != undefined || filterBySeverities != undefined

    return (
        <>
            {activeReset && (
                <Styles.Button
                    id="reset-button-incidents"
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(logButtonClickFor('Resset filter', AuditLogPageType.INCIDENTS))
                        dispatch(resetFilter())
                        dispatch(resetVesselFilter())
                        removeQueryParam(INCIDENT_DETAILS)
                        closeIncidentDetailsModal()
                    }}
                >
                    Reset
                </Styles.Button>
            )}
        </>
    )
}
