import {DataCell} from '../data-cell/data-cell'
import {TextLink} from './host-log-collection.styled'

export function HostLogCollection(): JSX.Element {
    return (
        <DataCell label="Host log collection">
            <TextLink
                href={`${process.env.PUBLIC_URL}/install-host-agent.pdf`}
                download={'install-host-agent.pdf'}
                id="enable-monitoring-TextLink"
            >
                Download instructions to install host agent
            </TextLink>
        </DataCell>
    )
}
