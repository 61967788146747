import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {
    MetricsResponseData,
    MetricsResponseForTrends,
} from '../../../metrics-beta/contexts/types/metrics-response'
import {TimestampFilterType} from './timestamp-filter'
import {METRICS_ENDPOINT} from './vessels-beta-endpoints'
export interface FrameworkSummaryTrendResponse {
    locationId: string
    protectionTrend: number | undefined
    behaviourTrend: number | undefined
    maintenanceTrend: number | undefined
}
function convertTimestampForTrends(analysisPeriod: TimestampFilterType): string {
    switch (analysisPeriod) {
        case '1d':
            return '14d'
        case '7d':
            return '71d'
        case '30d':
        default:
            return '365d'
    }
}

export async function buildFrameworkSummaryTrends(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<FrameworkSummaryTrendResponse[]> {
    try {
        const response = await REST.post(`${METRICS_ENDPOINT}/metrics/tables`, {
            locations: locationIds,
            period: convertTimestampForTrends(analysisPeriod),
            analysisTypes: ['trends'],
            aggregationLevel: 'framework',
        })

        const receivedData = new Map(Object.entries(response.data.tables))?.get(
            'trends',
        ) as MetricsResponseData
        const result = (receivedData?.data as MetricsResponseForTrends[]).reduce(
            (
                accumulator: {
                    [key: string]: {
                        locationId: string
                        protectionTrend: number | undefined
                        behaviourTrend: number | undefined
                        maintenanceTrend: number | undefined
                    }
                },
                current: MetricsResponseForTrends,
            ) => {
                const locationId = current.location

                if (!accumulator[locationId]) {
                    accumulator[locationId] = {
                        locationId,
                        protectionTrend: undefined,
                        behaviourTrend: undefined,
                        maintenanceTrend: undefined,
                    }
                }

                if (current.framework === 'protection') {
                    accumulator[locationId].protectionTrend = current.performance
                } else if (current.framework === 'behaviour') {
                    accumulator[locationId].behaviourTrend = current.performance
                } else if (current.framework === 'maintenance') {
                    accumulator[locationId].maintenanceTrend = current.performance
                }

                return accumulator
            },
            {},
        )
        const resultArray = Object.values(result)
        return resultArray
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<FrameworkSummaryTrendResponse>()
    }
}
