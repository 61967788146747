import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {InventoryType, ProcessingStatus} from './state'
import {GuidType} from '../../../values/generic-type-defintions'

const REPORTS_ENDPOINT = '/api/v1/customerReports/extended'

export const openModal = (
    inventory: InventoryType,
    title: string,
    locations: GuidType[],
): Actions.OpenModalAction => ({
    type: ActionType.OPEN_MODAL,
    payload: {inventory, title, locations},
})

export const closeModal = (): Actions.CloseModalAction => ({
    type: ActionType.CLOSE_MODAL,
})

export const setLocation = (location: string | null): Actions.SetLocationAction => ({
    type: ActionType.SET_LOCATION,
    payload: location,
})

export const setDataRange = (range: string): Actions.SetDataRangeAction => ({
    type: ActionType.SET_DATA_RANGE,
    payload: range,
})

export const setRequirements = (
    requirement: string,
    value: boolean,
): Actions.SetRequirementsAction => ({
    type: ActionType.SET_REQUIREMENTS,
    payload: {requirement, value},
})

export const setAdditionalRequirement = (
    requirement: string,
    value: boolean,
): Actions.SetAdditionalRequirementAction => ({
    type: ActionType.SET_ADDITIONAL_REQUIREMENT,
    payload: {requirement, value},
})

export const setPurpose = (purpose: string): Actions.SetPurposeAction => ({
    type: ActionType.SET_PURPOSE,
    payload: purpose,
})

export const setReference = (reference: string): Actions.SetReferenceAction => ({
    type: ActionType.SET_REFERENCE,
    payload: reference,
})

export function setSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetSaveProcessingStatusAction {
    return {
        type: ActionType.SET_SAVE_PROCESSING_STATUS,
        payload: value,
    }
}

export function submitDataRequestFn(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, getState) => {
        const currentState = getState().extendedDataRequest

        const formattedRequirements =
            currentState.isAdditionalRequirementExist &&
            currentState.additionalRequirement &&
            currentState.additionalRequirement.length > 0
                ? currentState.data.requirements
                    ? [...currentState.data.requirements, currentState.additionalRequirement]
                    : [currentState.additionalRequirement]
                : currentState.data.requirements

        dispatch(setSaveProcessingStatus(ProcessingStatus.PROCESSING))

        REST.post(REPORTS_ENDPOINT, {
            ...currentState.data,
            dateRange: getTimeRange(currentState.data.dateRange),
            requirements: formattedRequirements,
            reference: currentState.data.reference,
        })
            .then(() => {
                dispatch(setSaveProcessingStatus(ProcessingStatus.PROCESSED))
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                dispatch(setSaveProcessingStatus(ProcessingStatus.FAILED))
            })
    }
}

function getTimeRange(dataRange: string): string {
    switch (dataRange) {
        case 'Last 24 hours':
            return 'now() - 24h'
        case 'Last 7 days':
            return 'now() - 7d'
        case 'Last 30 days':
            return 'now() - 30d'
        default:
            return 'now() - 24h'
    }
}
