import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {HistorySectionContent} from './history-section-content'

export function HistorySection(): JSX.Element {
    const {detailExpanded} = useUserManagement()

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.History} />
            {detailExpanded.historyExpanded && <HistorySectionContent />}
        </Panel>
    )
}
