import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../../@assets/icons/close-panel.svg'
import {usePagedNmeaInventory} from '../../../../contexts/use-paged-nmea-inventory'

export function DetailPanelHeader(): JSX.Element {
    const {findNmeaDetailsForModal, closeNmeaDetailsModal} = usePagedNmeaInventory()

    return (
        <Styled.DetailPanelHeader id="detail-panel-header">
            <Styled.Title id="detail-panel-header_locationName">
                {findNmeaDetailsForModal?.locationName}
            </Styled.Title>
            <Styled.Title id="detail-panel-header_talkerName">
                {findNmeaDetailsForModal?.talkerName}
            </Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeNmeaDetailsModal()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
