import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerBottom} from '../../../../templates/fixed-page/content-container-bottom.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {MetricsPaging} from './footer/paging/metrics-paging'
import {RecordSet} from './footer/record-set/record-set'
import {MetricsCards} from './metrics-card-format/metrics-cards'
import {MetricsTable} from './metrics-table-format/metrics-table'
import {showCards} from './metrics-table-format/_styled/metrics-table.styled'
import {Footer} from '../../../../components/table-view-components/footer.styled'

export function MetricsTableViewFormat(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar} = usePagedMetricsBeta()

    return (
        <>
            {showCards(width, showFilterBar) ? <MetricsCards /> : <MetricsTable />}
            <ContentContainerBottom width={width}>
                <Footer width={width} id="footer-row">
                    <MetricsPaging />
                    <RecordSet />
                </Footer>
            </ContentContainerBottom>
        </>
    )
}
