import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../@assets/icons/close-panel.svg'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {SOFTWARE_INVENTORY_DETAILS, getFormattedSoftwareInventoryData} from '../../data-helpers'

export function DetailPanelHeader(): JSX.Element {
    const {closeSoftwareDetailsModal, findSoftwareDetailsForModal} = usePagedSoftwareInventory()
    const formattedSoftwareDetails = getFormattedSoftwareInventoryData(findSoftwareDetailsForModal)

    return (
        <Styled.DetailPanelHeader id="detail-panel-header">
            <Styled.Title id="detail-panel-header_title">
                {formattedSoftwareDetails.productName}
            </Styled.Title>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeSoftwareDetailsModal()
                    removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
                }}
                id="detail-panel-header_close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
