import {LoadingSpinner} from '../../../../../../components/loading/loading'
import {Actions} from '../close-monitor-incident.styled'
import * as Styled from './data-area.styled'
export function SaveInProgress(): JSX.Element {
    return (
        <Actions>
            <LoadingSpinner size={60} />
            <Styled.Text>Saving...</Styled.Text>
        </Actions>
    )
}
