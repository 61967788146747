import styled from 'styled-components'
export const InventoryItemsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`

export const InventoryItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`
interface InventoryItemWrapperProps {
    stateOfItems: 'total' | 'trusted' | 'untrusted' | 'new'
}
export const IconInventory = styled.img<InventoryItemWrapperProps>`
    filter: ${(props) =>
        props.stateOfItems === 'untrusted'
            ? 'invert(46%) sepia(91%) saturate(3722%) hue-rotate(325deg) brightness(98%) contrast(95%)'
            : props.stateOfItems === 'trusted'
              ? 'invert(69%) sepia(71%) saturate(600%) hue-rotate(63deg) brightness(90%) contrast(100%)'
              : 'none'};
    width: 20px;
    height: 20px;
`
interface NumberInventoryProps {
    newItems: boolean
}
export const NumberInventory = styled.p<NumberInventoryProps>`
    color: ${(props) => (props.newItems ? 'inherit' : props.theme.colors.link.primary)};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`
