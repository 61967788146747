import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)};
    padding-bottom: 0;
    margin: 0;
    width: 100%;
`

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 ${spacing(1)} ${spacing(2)};
`

export const Column = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`

export const Label = styled.label`
    flex-grow: 1;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    width: 100px;
    padding: 0;
    margin: 0;
    ${mediumSmallFont()};
`

export const StatusRow = styled.div`
    display: flex;
    width: 100%;
    padding: ${spacing(1)};
    align-items: center;
    ${mediumSmallFont()};
    gap: ${spacing(1)};
`
