import {useState} from 'react'
import {formatDate} from '../../../../../helpers/formatting'
import {DateTimeEventOutputModel} from '../../../models/date-time-event.model'
import {IndicatorDotForTableRow} from '../../shared/row-actions/common/button-text.styled'
import {MessageForTable} from './message-table'
import {When} from './when.styled'
import {Who} from './who.styled'

interface EventProps {
    data: DateTimeEventOutputModel
    numberOfNewItems: number
}

export function UpdatedEvent({data, numberOfNewItems}: EventProps): JSX.Element | null {
    const [isHovering, setIsHovering] = useState(false)
    const displayDot = numberOfNewItems > 0

    if (!data) {
        return null
    }

    return (
        <>
            <When style={{position: 'relative'}}>
                {formatDate(data.when)}
                {displayDot && (
                    <IndicatorDotForTableRow
                        onMouseOver={() => setIsHovering(true)}
                        onMouseOut={() => setIsHovering(false)}
                    >
                        {numberOfNewItems}
                    </IndicatorDotForTableRow>
                )}
                {isHovering && <MessageForTable />}
            </When>
            <Who>{data.who}</Who>
        </>
    )
}
