import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../theme/font-styles'
import {spacing} from '../../theme/spacing'
import {getColor, getBackgroundColor} from '../../theme/helpers/risk-score'
import {NodeValue, NodeValues} from '../../values/nodes/NodeData'
import {
    getColorFromNodeValue,
    getBackgroundColorFromNodeValue,
} from '../../theme/helpers/node-title'
import {ThreatValue} from '../../helpers/threatHelper'

export const Container = styled.aside`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
`

export const Header = styled.header`
    display: flex;
    justify-content: flex-start;
`

interface RiskScoreProps {
    score: number
    value: NodeValues
    threatLevel: ThreatValue
}

export const RiskScore = styled.div<RiskScoreProps>`
    font-size: 18px;
    line-height: 24px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 54px;
    background-color: ${(props) =>
        getBackgroundColor(props.theme, props.score, props.value, props.threatLevel)};
    color: ${(props) => getColor(props.theme, props.score, props.value, props.threatLevel)};
`

interface TitlesProps {
    value: NodeValue
}
export const Titles = styled.div<TitlesProps>`
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    border-left: 1px solid white;
    padding-left: ${spacing(3)};
    padding-right: ${spacing(3)};
    color: ${(props) => getColorFromNodeValue(props.value, props.theme)};
    background-color: ${(props) => getBackgroundColorFromNodeValue(props.value, props.theme)};
`
export const Names = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`

export const AssetName = styled.h2`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

interface AssetValueProps {
    value: NodeValue
}

export const AssetValue = styled.span<AssetValueProps>`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    margin: 0 0 0 ${spacing(5)};
    padding: 0;
    text-transform: uppercase;
    text-align: center;
`

export const VesselName = styled.h3`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Details = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: ${spacing(5)};
    row-gap: ${spacing(1)};
    margin: ${spacing(3)};
`

export const Label = styled.label`
    ${smallFont()}
    font-weight:${(props) => props.theme.font.weight.semibold};
    margin: 0;
    padding: 0;
`

export const Value = styled.span`
    ${smallFont()}
    font-weight:${(props) => props.theme.font.weight.bold};
    margin: 0;
    padding: 0;
`

export const Actions = styled.footer`
    border-top: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
`

export const DetailText = styled.span`
    margin-left: ${spacing(1)};
`
