import * as Styled from './data-cell.styled'
import {ReactNode, ReactNodeArray} from 'react'

interface DataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
}
export function DataCell({label, children}: DataCellProps): JSX.Element {
    return (
        <>
            <Styled.Label id={`label-${label}`}>{label}</Styled.Label>
            <Styled.Children id={`${children}`}>{children}</Styled.Children>
        </>
    )
}
