import * as Styled from './vessel-tag.styled'
import {X} from 'react-feather'

interface Props {
    tagName: string
    onClickFn?: () => void
    onDeleteFn?: () => void
}

export function VesselTag({tagName, onClickFn, onDeleteFn}: Props): JSX.Element {
    return (
        <Styled.TagResult
            cursorPointer={onClickFn != undefined}
            onClick={onClickFn}
            id={`tag-result-${tagName}-fleet-widget`}
        >
            <Styled.TagItem id={`tag-name-${tagName}-fleet-widget`}>{tagName}</Styled.TagItem>
            {onDeleteFn && (
                <Styled.XIcon onClick={onDeleteFn} id="delete-tag-button-fleet-widget">
                    <X
                        onClick={onDeleteFn}
                        height={12}
                        width={12}
                        id="delete-tag-icon-fleet-widget"
                    />
                </Styled.XIcon>
            )}
        </Styled.TagResult>
    )
}
