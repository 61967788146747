import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {narrowLayoutFn} from '../grid-helper'
interface DataRowProps {
    width: number
}
export const DataRow = styled.div<DataRowProps>`
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
`

function getGridTemplate(width: number): string {
    if (narrowLayoutFn(width)) {
        return '"STATUS STATUS SEVERITY SEVERITY ASSIGNED ASSIGNED" "RAISED RAISED RAISED UPDATED UPDATED UPDATED"'
    }
    return '"INCIDENT-TYPE STATUS SEVERITY ASSIGNED RAISED UPDATED"'
}
