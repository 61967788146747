import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {IncidentAttachmentData} from '../../../contexts/types/incident-attachment-data'
import {Button, ButtonText} from './download-report-button.styled'
import {useDispatch} from 'react-redux'
import {logDownloadFile} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, DownloadFileType} from '../../../../../store/state/audit-log/state'

interface DownloadReportButtonProps {
    id: string
    attachment: IncidentAttachmentData | null
    page: AuditLogPageType
    downloadIncidentResponse: (identity: string, attachment: IncidentAttachmentData) => void
}

export function DownloadReportButton({
    id,
    attachment,
    page,
    downloadIncidentResponse,
}: DownloadReportButtonProps): JSX.Element | null {
    const dispatch = useDispatch()
    const onDownload = (identity: string, attachment: IncidentAttachmentData): void => {
        downloadIncidentResponse(identity, attachment)
    }

    if (!attachment) {
        return null
    }

    function onClickWrapper() {
        if (attachment) {
            onDownload(id, attachment)
            dispatch(logDownloadFile(page, id, DownloadFileType.PDF))
        }
    }

    return (
        <Button id={`download-attachment_${id}`} onClick={onClickWrapper}>
            <ButtonText>Download Report</ButtonText>
            <Icon glyph="Pdf" height={17} width={17} />
        </Button>
    )
}
