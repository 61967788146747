import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Area,
    AreaChart,
    ReferenceArea,
} from 'recharts'
import {CyberOwlTheme} from '../../../../theme/theme'
import * as Styled from './trends.styled'
import {useTheme} from 'styled-components'
import {MetricType} from '../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {CurrentScoreTooltip} from './current-score-tooltip'
import {
    AllCustomerBenchmarksData,
    MetricTrendsArray,
    MetricTrendsArrayForUIDisplay,
} from '../../contexts/types/metrics-summary'
import TRENDS from '../../../../@assets/icons/trends-metrics.svg'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {NoData} from '../no-data/no-data'
import LoadingState from '../../../../values/loading-state-enum'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {getColor} from './score-bar.styled'
import {TimestampFilterType} from '../../../../store/state/metrics-filter-beta/state'
import {convertPeriodTimestampForTrends} from '../data-helper'

function formatTick(value: number) {
    return `${value}%`
}

function yAxis(theme: CyberOwlTheme, id: string): JSX.Element {
    const ticks = [0, 25, 50, 75, 100]
    return (
        <YAxis
            type="number"
            domain={[0, 100]}
            ticks={ticks}
            tickLine={true}
            fontSize="14px"
            color={theme.colors.text.default}
            fontWeight={theme.font.weight.semibold}
            tickFormatter={formatTick}
            id={`y-axis-${id}`}
        />
    )
}
interface TrendsProps {
    metricType: MetricType
}
export function Trends({metricType}: TrendsProps): JSX.Element {
    const theme = useTheme()
    const {width} = useDimensions()

    const {
        dataSummariesMetricsMap,
        showFilterBar,
        loadingFilteredDataState,
        allCustomerBenchmarkResponse,
        wholeFleetBenchmarkMap,
    } = usePagedMetricsBeta()
    const {selectedViewScreenType, selectedAnalysisPeriod, benchmarkType} =
        useTypedSelector(metricsBetaFilterSelector)

    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-trends-section-${metricType}`}
            >
                <TitleWrapper
                    scorecardView={selectedViewScreenType === 'metrics'}
                    id={metricType}
                />
                <DataLoading />
            </Styled.Section>
        )
    }
    const trendsArray = dataSummariesMetricsMap?.get(metricType)?.trends
    const wholeFleetBenchmarkArray = wholeFleetBenchmarkMap?.get(metricType)?.trends
    const findAllCustomerBenchmarkMetric = allCustomerBenchmarkResponse?.find(
        (item) => item.metricType === metricType,
    )
    if (!trendsArray) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-trends-section-${metricType}`}
            >
                <TitleWrapper
                    scorecardView={selectedViewScreenType === 'metrics'}
                    id={metricType}
                />
                <NoData text="No Assets are found" />
            </Styled.Section>
        )
    }
    // Determine start and end date based on the analysis period
    const endDate = new Date()
    const startDate = new Date(endDate)
    const getPeriodData = convertPeriodTimestampForTrends(true, selectedAnalysisPeriod)
    switch (getPeriodData) {
        case '365d':
            startDate.setFullYear(endDate.getFullYear() - 1)
            break
        case '71d':
            startDate.setDate(endDate.getDate() - 71)
            break
        case '14d':
            startDate.setDate(endDate.getDate() - 14)
            break
        case '2h':
            startDate.setHours(endDate.getHours() - 2)
            break
        default:
            startDate.setFullYear(endDate.getFullYear() - 1)
    }

    const formattedData = getTrendsArrayForUIDisplay(
        trendsArray,
        wholeFleetBenchmarkArray,
        findAllCustomerBenchmarkMetric,
    )
    // Adjust x2 if it's the same as x1
    const singleDataPoint =
        formattedData[0]?.timestamp === formattedData[formattedData.length - 1]?.timestamp
    const x2 = singleDataPoint
        ? findX2PositionFromPeriod(formattedData[0]?.timestamp, getPeriodData)
        : formattedData[formattedData.length - 1]?.timestamp
    const yValue = formattedData[0]?.selectedVesselsPerformance

    return (
        <Styled.Section
            showFilterBar={showFilterBar}
            width={width}
            id={`footer-trends-section-${metricType}`}
        >
            <TitleWrapper scorecardView={selectedViewScreenType === 'metrics'} id={metricType} />
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <Styled.Title>Assets with status OK</Styled.Title>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Styled.LegendText>
                        <Styled.ColouredLegend />
                        Your vessels
                    </Styled.LegendText>
                    <Styled.LegendText>
                        <Styled.ColouredLegend benchmarkType={benchmarkType} />
                        {benchmarkType === 'Whole fleet' ? 'Your whole fleet' : 'All customers'}
                    </Styled.LegendText>
                </div>
            </div>
            <ResponsiveContainer height={180}>
                <AreaChart margin={{top: 15, right: 20, bottom: -10}} data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" fill="#f0f0f0" id="CartesianGrid" />
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        tick={false}
                        domain={[startDate.getTime(), endDate.getTime()]}
                        id={`x-axis-${metricType}`}
                    />

                    {yAxis(theme, metricType)}
                    <Tooltip
                        content={
                            <CurrentScoreTooltip
                                data={formattedData}
                                benchmarkType={benchmarkType}
                                allCustomerPerformance={findAllCustomerBenchmarkMetric?.benchmark}
                            />
                        }
                    />
                    <ReferenceArea
                        x1={formattedData[0]?.timestamp}
                        x2={x2}
                        fill="#ffffff"
                        fillOpacity={1}
                        id="area-background"
                        strokeWidth={5}
                    />
                    {!singleDataPoint ? (
                        <>
                            <Area
                                id="area-chart-selectedVesselsPerformance"
                                type="monotone"
                                dataKey="selectedVesselsPerformance"
                                fill={getColor(metricType)}
                                stroke={theme.colors.graph.primary}
                                strokeWidth={3}
                                dot={{
                                    stroke: theme.colors.graph.primary,
                                    strokeWidth: 3,
                                    fill: '#ffffff',
                                }}
                                fillOpacity={0.5}
                                isAnimationActive={false}
                            />
                            {benchmarkType === 'Whole fleet' && (
                                <Area
                                    id="area-chart-fleet-performance"
                                    type="monotone"
                                    dataKey="fleetPerformance"
                                    fill={getColor(metricType)}
                                    stroke="#F34663"
                                    dot={{
                                        stroke: '#F34663',
                                        strokeWidth: 1,
                                        fill: '#ffffff',
                                    }}
                                    fillOpacity={0}
                                    isAnimationActive={false}
                                />
                            )}
                            {benchmarkType === 'All customers' && (
                                <Area
                                    id="area-chart-allCustomerBenchmarkPerformance"
                                    type="monotone"
                                    dataKey="allCustomerBenchmarkPerformance"
                                    fill={getColor(metricType)}
                                    stroke="#F34663"
                                    strokeDasharray={4}
                                    fillOpacity={0}
                                    isAnimationActive={false}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <ReferenceArea
                                x1={formattedData[0]?.timestamp}
                                x2={x2}
                                y1={yValue}
                                fill="#ffffff"
                                fillOpacity={1}
                                id="one-point-background-white"
                                strokeWidth={5}
                            />
                            <ReferenceArea
                                x1={formattedData[0]?.timestamp}
                                x2={x2}
                                y1={yValue}
                                y2={0}
                                fill={getColor(metricType)}
                                fillOpacity={1}
                                id="one-point-background-colour"
                            />
                            <Area
                                id="area-chart"
                                type="monotone"
                                dataKey="selectedVesselsPerformance"
                                fill={getColor(metricType)}
                                stroke={theme.colors.graph.primary}
                                strokeWidth={3}
                                dot={{
                                    stroke: theme.colors.graph.primary,
                                    strokeWidth: 3,
                                    fill: '#ffffff',
                                }}
                                fillOpacity={0.5}
                                connectNulls={false}
                            />
                            {benchmarkType === 'Whole fleet' && (
                                <Area
                                    id="area-chart-fleet-performance"
                                    type="monotone"
                                    dataKey="fleetPerformance"
                                    fill={getColor(metricType)}
                                    stroke="#F34663"
                                    dot={{
                                        stroke: '#F34663',
                                        strokeWidth: 1,
                                        fill: '#ffffff',
                                    }}
                                    fillOpacity={0}
                                    isAnimationActive={false}
                                />
                            )}
                            {benchmarkType === 'All customers' && (
                                <Area
                                    id="area-chart-allCustomerBenchmarkPerformance"
                                    type="monotone"
                                    dataKey="allCustomerBenchmarkPerformance"
                                    fill={getColor(metricType)}
                                    stroke="#F34663"
                                    strokeDasharray={4}
                                    fillOpacity={0}
                                    isAnimationActive={false}
                                />
                            )}
                        </>
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </Styled.Section>
    )
}
interface TitleWrapperProps {
    scorecardView: boolean
    id: string
}

function TitleWrapper({scorecardView, id}: TitleWrapperProps): JSX.Element {
    const {benchmarkType} = useTypedSelector(metricsBetaFilterSelector)
    return (
        <Styled.TitleWrapper scorecardView={scorecardView} id={`footer-trends-title-${id}`}>
            <img src={TRENDS} id={`footer-trends-icon-${id}`} />
            Trends - <Styled.SubTitle>{benchmarkType}</Styled.SubTitle>
        </Styled.TitleWrapper>
    )
}

function findX2PositionFromPeriod(timestamp: number, periodForTrend: TimestampFilterType): number {
    switch (periodForTrend) {
        case '365d':
            return timestamp + 86400000 //add 1 day
        case '71d':
            return timestamp + 15400000
        case '14d':
            return timestamp + 7200000 //add 2h
        case '2h':
            return timestamp + 60000
        default:
            return timestamp + 60000
    }
}
function getTrendsArrayForUIDisplay(
    trendsArray: MetricTrendsArray[] | undefined,
    wholeFleetBenchmarkArray: MetricTrendsArray[] | undefined,
    findAllCustomerBenchmarkMetric?: AllCustomerBenchmarksData,
): MetricTrendsArrayForUIDisplay[] {
    if (!trendsArray) {
        return new Array<MetricTrendsArrayForUIDisplay>()
    }
    return trendsArray.map((trendItem) => {
        const trendTimestamp = new Date(trendItem?.timestamp).getTime()
        const tolerance = 60000 // 1 minute tolerance

        const fleetPerformanceItem = wholeFleetBenchmarkArray?.find((fleetItem) => {
            const fleetTimestamp = new Date(fleetItem?.timestamp).getTime()
            return Math.abs(fleetTimestamp - trendTimestamp) < tolerance
        })

        const totalAssetsOkScore = isNaN(
            trendItem.totalAssetsOk / (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue),
        )
            ? 0
            : Math.round(
                  (trendItem.totalAssetsOk /
                      (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue)) *
                      100,
              )

        const fleetPerformanceScore = fleetPerformanceItem
            ? isNaN(
                  fleetPerformanceItem.totalAssetsOk /
                      (fleetPerformanceItem.totalAssetsOk +
                          fleetPerformanceItem.totalAssetsWithIssue),
              )
                ? 0
                : Math.round(
                      (fleetPerformanceItem.totalAssetsOk /
                          (fleetPerformanceItem.totalAssetsOk +
                              fleetPerformanceItem.totalAssetsWithIssue)) *
                          100,
                  )
            : 0
        const allCustomerBenchmarkPerformance = findAllCustomerBenchmarkMetric
            ? findAllCustomerBenchmarkMetric.benchmark
            : undefined
        return {
            timestamp: new Date(trendItem?.timestamp).getTime(),
            selectedVesselsPerformance: totalAssetsOkScore,
            fleetPerformance: fleetPerformanceScore,
            allCustomerBenchmarkPerformance: allCustomerBenchmarkPerformance,
        }
    })
}
