import styled from 'styled-components'

export const Title = styled.h4`
    flex: 1;
    margin: 0 14px;
    line-height: 19px;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 0;
`
