import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSelectedReportType} from '../../../../../store/state/reports-filter/action-creators'
import {reportsFilterSelector} from '../../../../../store/state/reports-filter/selectors'
import {usePagedReports} from '../../../contexts/use-paged-reports'
import * as Styles from './report-type.styled'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'

export function ReportType(): JSX.Element {
    const dispatch = useDispatch()
    const {allReportTypes} = usePagedReports()
    const {selectedReportType} = useTypedSelector(reportsFilterSelector)

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(
            logFilterByTypes(
                'SelectFromTimePeriod',
                `currentReportType: ${getReportType(selectedReportType)}, newReportType: ${getReportType(event.target.value)}`,
                true,
                AuditLogPageType.REPORTS,
            ),
        )
        dispatch(setSelectedReportType(event.target.value))
    }

    return (
        <Styles.Reports id={`${PageType.REPORTS}_filter-bar_ReportType-filter`}>
            <Styles.Label>Report Type:</Styles.Label>
            <div>
                <Styles.Input>
                    <Styles.Select
                        onChange={onChangeWrapper}
                        value={selectedReportType}
                        id={`${PageType.REPORTS}_filter-bar_selectedReportType-value`}
                    >
                        <option
                            key="default-option"
                            value=""
                            id={`${PageType.REPORTS}_filter-bar_selectedReportType_default-option`}
                        >
                            - All -
                        </option>
                        {allReportTypes?.map((value) => (
                            <option
                                key={value}
                                value={value}
                                id={`${PageType.REPORTS}_filter-bar_selectedReportType_${value}-option`}
                            >
                                {value}
                            </option>
                        ))}
                    </Styles.Select>
                </Styles.Input>
            </div>
        </Styles.Reports>
    )
}
function getReportType(reportType: string | undefined): string {
    if (reportType == undefined) {
        return 'Unknown'
    }

    return reportType === '' ? 'All' : reportType
}
