import styled from 'styled-components'

interface MessageContainerForTableProps {
    width: number
}

export const MessageContainerForTable = styled.div<MessageContainerForTableProps>`
    display: flex;
    position: absolute;
    justify-content: center;
    left: 20px;
    top: 20px;
    width: 80px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    padding: 3px;
    z-index: 1;
    pointer-events: none;
`

export const MessageContainerForCard = styled.div<MessageContainerForTableProps>`
    display: flex;
    position: absolute;
    justify-content: center;
    left: 145px;
    width: 80px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.incidents.filterBar.savedFilters.detailBackground};
    padding: 3px;
    z-index: 1;
    pointer-events: none;
`
