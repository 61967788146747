import {DataLoading} from '../../../../components/data-loading/data-loading'
import {USBDevicesTablePopulated} from './usb-devices-table-populated'
import {NoData} from './no-data/no-data'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import LoadingState from '../../../../values/loading-state-enum'

export function USBDevicesTableContent(): JSX.Element {
    const {loadingDataState, totalNumberOfFilteredUSBDevices} = usePagedUSBDevices()

    const showLoading =
        totalNumberOfFilteredUSBDevices == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData

    if (showLoading) {
        return <DataLoading />
    }
    if (totalNumberOfFilteredUSBDevices === 0) {
        return <NoData text="There are no USBDevices matching your filtering criteria" />
    }
    return <USBDevicesTablePopulated />
}
