import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const SwaggerLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.background.fade};
    padding: ${spacing(1)};
    gap: 3px;
`
