import {Location} from '../../../../../../store/state/locations/state'
import {TableDataCell} from './_styled/table-content.styled'

interface TableCodeCellProps {
    location: Location
    gridRow: number
    isHovering: boolean
}
export function TableCodeCell({location, gridRow, isHovering}: TableCodeCellProps): JSX.Element {
    return (
        <TableDataCell
            gridRow={gridRow}
            gridColumn={3}
            isHovering={isHovering}
            id={`modal_location-code_${location.location}`}
        >
            {location.code}
        </TableDataCell>
    )
}
