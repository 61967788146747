import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import {Role, notSelectableRole} from '../../../../../../values/Role'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './roles-section.styled'

export function InternalRolesSelect(): JSX.Element {
    const internalRoles = [
        'APP',
        'GATEWAY',
        'NEBULA',
        'BEACON',
        'VESSEL',
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
        'USER',
    ] as Role[]

    const {displayUserDetails, assignCurrentUserRole, deassignCurrentUserRole} = useUserManagement()

    const isNotSelectable = (label: string) => {
        return notSelectableRole.indexOf(label) !== -1
    }

    return (
        <>
            {internalRoles.map((role, index) => (
                <Styled.DetailRow key={index}>
                    <Checkbox
                        id={`user-management-role_${role}`}
                        checked={displayUserDetails.roles.includes(role)}
                        onChange={() =>
                            displayUserDetails.roles.includes(role)
                                ? deassignCurrentUserRole(role)
                                : assignCurrentUserRole(role)
                        }
                        label={role}
                        disabled={isNotSelectable(role)}
                    />
                </Styled.DetailRow>
            ))}
        </>
    )
}
