import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useToggle} from '../../../../../contexts/toggle/use-toggle'
import {useVesselConfig} from '../../contexts/use-vessel-config'
import {getGridType} from '../shared/grid-layout-helper'
import {VesselsListModal} from '../vessels-list-modal/vessels-list-modal'
import {CopyConfigButton} from './_styled/copy-config-area.styled'

export function SelectVesselModal(): JSX.Element {
    const {width} = useDimensions()
    const {fixedValue} = useVesselConfig()
    const {value: modalOpen, setValue: setModalOpen} = useToggle()
    return (
        <>
            <CopyConfigButton gridLayout={getGridType(width)} onClick={() => setModalOpen(true)}>
                Copy config from other vessel
            </CopyConfigButton>
            {modalOpen && <VesselsListModal code={fixedValue.locationCode} />}
        </>
    )
}
