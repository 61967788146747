import {GuidType} from '../../../../../values/generic-type-defintions'

export interface IncidentPolicyPlaybooks {
    identity: GuidType
    incidentResponsePolicyIdentity: GuidType
    incidentResponseRecommendationPlaybookTypeIdentity: GuidType
    fileIdentity: GuidType
}
export interface IncidentPolicyPlaybooksTypes {
    identity: GuidType
    displayName: string
    defaultFileId: GuidType
}

export interface FormattedIncidentsPolicyPlaybooksForUI {
    identity: GuidType
    incidentResponsePolicyIdentity: GuidType
    incidentResponseRecommendationPlaybookTypeIdentity: GuidType
    displayName: string
    defaultFileId: GuidType
    fileIdentity: GuidType | null
}

export function getFormattedIncidentsPolicyPlaybooksForUI(
    incidentPolicyPlaybooks: IncidentPolicyPlaybooks[],
    incidentPolicyPlaybooksTypes: IncidentPolicyPlaybooksTypes[],
    incidentResponsePolicyIdentity: GuidType,
): FormattedIncidentsPolicyPlaybooksForUI[] {
    if (incidentPolicyPlaybooksTypes.length === 0) {
        return new Array<FormattedIncidentsPolicyPlaybooksForUI>()
    }
    return incidentPolicyPlaybooksTypes.map((typePlaybook) => {
        const findMatchigType = incidentPolicyPlaybooks.find((elementPolicy) => {
            return (
                elementPolicy.incidentResponseRecommendationPlaybookTypeIdentity ===
                typePlaybook.identity
            )
        })
        if (findMatchigType) {
            return {
                identity: findMatchigType.identity,
                incidentResponsePolicyIdentity: findMatchigType.incidentResponsePolicyIdentity,
                incidentResponseRecommendationPlaybookTypeIdentity:
                    findMatchigType.incidentResponseRecommendationPlaybookTypeIdentity,
                displayName: typePlaybook.displayName,
                defaultFileId: typePlaybook.defaultFileId,
                fileIdentity: findMatchigType.fileIdentity,
            }
        } else {
            return {
                identity: typePlaybook.identity,
                incidentResponsePolicyIdentity: incidentResponsePolicyIdentity,
                incidentResponseRecommendationPlaybookTypeIdentity: typePlaybook.identity,
                displayName: typePlaybook.displayName,
                defaultFileId: typePlaybook.defaultFileId,
                fileIdentity: null,
            }
        }
    })
}
