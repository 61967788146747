import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const Options = styled.ul`
    list-style-type: none;
    padding: ${spacing(3)};
    margin: 0;
    width: 194px;
    cursor: default;
`

export const Option = styled.li`
    padding: ${spacing(1)} 0;
    margin: 0;
    font: 13px;
    line-height: 23px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
