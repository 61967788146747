import {User} from '../store/state/users/state'
import {GuidType} from '../values/generic-type-defintions'

const PROTECTED_NAME = 'CyberOwl TA'
const UNKNOWN_USER = 'Unknown'

export function getUserDescription(
    users: User[],
    userId: GuidType,
    userEmail: string | undefined,
    isInternalUser = false,
): string {
    const userToOutput = users?.find((value) => value.user === userId)
    if (!userToOutput) {
        if (userEmail != undefined) {
            return userEmail
        }
        return !isInternalUser ? PROTECTED_NAME : UNKNOWN_USER
    }

    if (isInternalUser) {
        return userToOutput.username
    }

    return userToOutput.internalUser === true ? PROTECTED_NAME : userToOutput.username
}

export function isServiceAccount(
    users: User[],
    userId: GuidType,
    isInternalUser: boolean,
    reason: string | null,
): boolean {
    const userToOutput = users?.find((value) => value.user === userId)
    if (isInternalUser) {
        return userToOutput?.username === 'Basal Ganglia Service Account User'
    } else {
        return !userToOutput && reason !== null
    }
}
