import * as Styled from './insights-section.styled'
import {DataCell} from '../data-cell/data-cell'
import {usePagedNmeaInventory} from '../../../../contexts/use-paged-nmea-inventory'

export function ParameterContent(): JSX.Element | null {
    const {findNmeaDetailsForModal} = usePagedNmeaInventory()
    if (!findNmeaDetailsForModal) {
        return null
    }
    const parametersSet = findNmeaDetailsForModal?.parameters
    const sortedParameters = Object.entries(parametersSet)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value !== null)
        .sort(([a], [b]) => {
            const numA = parseInt(a.replace('Parameter ', ''), 10)
            const numB = parseInt(b.replace('Parameter ', ''), 10)
            return numA - numB
        })

    return (
        <Styled.DetailsAreaContent id="nmea-details-modal_panel_Insights_DetailsAreaContent">
            <DataCell label="Sentence description:">
                {findNmeaDetailsForModal?.sentenceName}
            </DataCell>
            <DataCell label="Sentence code:">{findNmeaDetailsForModal?.sentenceId}</DataCell>
            {sortedParameters?.map(([key, value]) => (
                <DataCell key={key} label={`${key}:`} tooltip={key}>
                    {value}
                </DataCell>
            ))}
        </Styled.DetailsAreaContent>
    )
}
