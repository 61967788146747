import styled from 'styled-components'
import {getColor as getMetricTypeColor} from '../../../../metrics-beta/components/metrics-summaries/score-bar.styled'

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`
export const ScoreSection = styled.div`
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
`
interface TrendScoreProps {
    metricType: string
}
export const TrendScore = styled.div<TrendScoreProps>`
    position: relative;
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => getMetricTypeColor(props.metricType)};
`
export const PercentagWrapper = styled.div`
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 12px;
`
export const ImgComparison = styled.img<TrendScoreProps>`
    width: 19px;
    height: 10px;

    filter: ${(props) =>
        props.metricType === 'protection'
            ? 'invert(18%) sepia(99%) saturate(2889%) hue-rotate(228deg) brightness(86%) contrast(101%)'
            : props.metricType === 'maintenance'
              ? 'invert(10%) sepia(97%) saturate(6207%) hue-rotate(270deg) brightness(92%) contrast(96%)'
              : props.metricType === 'behaviour'
                ? 'invert(56%) sepia(78%) saturate(853%) hue-rotate(156deg) brightness(90%) contrast(91%)'
                : ''};
`
