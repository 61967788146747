import {ReactNode, useReducer} from 'react'
import {EventDetailsContext} from './event-details-context'
import {eventDetailsReducer} from './state/reducer'
import {DEFAULT_EVENT_DETAILS_STATE} from './types/default-event-details-state'
import {EventDetailsState} from './types/event-details-state'

type EventDetailsProviderProps = {
    children: ReactNode | ReactNode[]
}

export function EventDetailsProvider({children}: EventDetailsProviderProps): JSX.Element {
    const initialState: EventDetailsState = DEFAULT_EVENT_DETAILS_STATE

    const [state, dispatch] = useReducer(eventDetailsReducer, initialState)

    return (
        <EventDetailsContext.Provider value={{state, dispatch}}>
            {children}
        </EventDetailsContext.Provider>
    )
}
