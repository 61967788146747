import {Check} from 'react-feather'
import * as Styled from './editable-input.styled'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../../../store/state/saved-filters/selectors'
import {isEmailValid} from '../../../../../../utils/Utils'
import {setChangeAlertEmail} from '../../../../../../store/state/saved-filters/action-creators'

interface DataInputProps {
    initialValue: string | undefined
}

export function EmailInput({initialValue}: DataInputProps): JSX.Element {
    const dispatch = useDispatch()
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        dispatch(setChangeAlertEmail(e.target.value))
    }
    const isValidEmail =
        newSubscription.notificationEmail?.length !== 0 &&
        isEmailValid(newSubscription.notificationEmail)
    const isChanged = newSubscription.notificationEmail !== undefined && isValidEmail

    return (
        <Styled.InputWrapper>
            <Styled.Input
                defaultValue={initialValue}
                value={newSubscription.notificationEmail}
                onChange={(e) => onChangeHandler(e)}
            />
            {isChanged && <Check color={'green'} width={15} height={15} />}
        </Styled.InputWrapper>
    )
}
