import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/vessel-management-card.styled'
import AlertWhite from '../../../../@assets/icons/alert-high-white.svg'
import AlertBlue from '../../../../@assets/icons/alert-high-blue.svg'
import {PolicyManagementDetails} from './_styled/vessel-management-details.styled'
import {vesselDetailsCachedReselector} from '../../reselectors/vessel-details.cached-reselector'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {useState} from 'react'
import {WarningMessageCard} from '../shared/warning-message-card'
import {IconContent} from '../shared/warning-message.styled'
import {ToggleButtonCell} from './details/toggle-button-cell'
import {ToggleDetailsButton} from './details/toggle-details-button'
import {useVesselManagement} from '../../contexts/use-vessel-management'
import {VesselManagementDetailExpanded} from './vessel-management-detail-expanded/vessel-management-detail-expanded'
import {Location} from '../../../../store/state/locations/state'
import {getDeploymentStatus, getMergedLocation} from '../shared/vessel-status'
import {usePolicyManagement} from '../../../policy-management/contexts/policy-management/use-policy-management'
import {fleetConfigSelector} from '../../../../store/state/config/selectors'
import {useEnforcementPolicy} from '../../../policy-management/contexts/enforcement-policy/use-enforcement-policy'
import {getDateAwareLicense, LicenseSummeryFleetConfigData} from '../lincense-utils'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {getLicenseSummeryInfo} from '../shared/license/licnese'
import Tippy from '@tippyjs/react'

interface VesselListCardProps {
    location: Location
}

export function VesselListCard({location}: VesselListCardProps): JSX.Element {
    const {width} = useDimensions()
    const {
        activeVesselId,
        locationsInIsolationMode,
        vesselDeploymentStatusInfo,
        policyLicenseSummery,
    } = useVesselManagement()
    const {allPolicyData} = usePolicyManagement()
    const locationId = location.location
    const model = useTypedSelector((state) =>
        vesselDetailsCachedReselector(state, locationId, locationsInIsolationMode, allPolicyData),
    )
    const [isHovering, setIsHovering] = useState(false)
    const isExpanded = locationId === activeVesselId
    const deploymentStatus = getDeploymentStatus(
        getMergedLocation(vesselDeploymentStatusInfo, locationId),
    )

    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const {allPolicies} = useEnforcementPolicy()
    let licenseSummeryDateAware: LicenseSummeryFleetConfigData | undefined

    if (policyLicenseSummery && fleetConfig.LicenseState) {
        licenseSummeryDateAware = getDateAwareLicense(
            policyLicenseSummery,
            fleetConfig.LicenseState,
        )
    }

    // if there is no config, then either the fleet config is still loading or the policy summery endpoint is
    if (!licenseSummeryDateAware) {
        return <DataLoading />
    }

    const [incidentIcon, incidentToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Incidents'],
        model.incidentPolicy,
        allPolicies,
    )
    const [enforcementNetworkIcon, enforcementNetworkToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Enforcement:Networks'],
        model.enforcementPolicy,
        allPolicies,
    )
    const [enforcementRemoveablesIcon, enforcementRemoveablesToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Enforcement:Removables'],
        model.enforcementPolicy,
        allPolicies,
    )
    const [onboardEngagementIcon, onboardEngagementToolTip] = getLicenseSummeryInfo(
        licenseSummeryDateAware['License:Metrics:OnboardEngagement'],
        model.metricsPolicy,
        allPolicies,
    )

    return (
        <PolicyManagementDetails activePolicy={isExpanded}>
            <Styled.Card width={width}>
                <CardDataCell
                    gridArea="NAME"
                    label="Vessel:"
                    locationId={locationId}
                    identifier="name"
                >
                    {model.name}
                </CardDataCell>
                <CardDataCell
                    gridArea="TAGS"
                    label="Tags:"
                    locationId={locationId}
                    identifier="tags"
                >
                    {model.vesselTags?.map((tag) => tag.name).join(', ')}
                </CardDataCell>
                <CardDataCell
                    gridArea="NETWORK"
                    label="Network settings:"
                    locationId={locationId}
                    identifier="network-settings"
                >
                    {model.metadata?.networkConfiguration?.BusinessNetworks && (
                        <Styled.Settings style={{marginBottom: '3px'}}>
                            Business:
                            {model.metadata?.networkConfiguration?.BusinessNetworks.toString()}
                        </Styled.Settings>
                    )}
                    {model.metadata?.networkConfiguration?.OTNetworks && (
                        <Styled.Settings style={{marginBottom: '3px'}}>
                            OT: {model.metadata?.networkConfiguration?.OTNetworks.toString()}
                        </Styled.Settings>
                    )}
                    {model.metadata?.networkConfiguration?.CrewNetworks && (
                        <Styled.Settings>
                            Crew: {model.metadata?.networkConfiguration?.CrewNetworks.toString()}
                        </Styled.Settings>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="COMMS"
                    label="Comm settings:"
                    locationId={locationId}
                    identifier="comms-settings"
                >
                    {model.dashboardPC !== undefined && (
                        <Styled.Settings style={{marginBottom: '3px'}}>
                            Dashboard PC: {model.dashboardPC}
                        </Styled.Settings>
                    )}
                    {model.vesselEmail && (
                        <Styled.Settings>Email: {model.vesselEmail}</Styled.Settings>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="INCIDENT-POLICY"
                    label="Incident policy:"
                    locationId={locationId}
                    identifier="incident-policy"
                    centered
                >
                    {incidentToolTip ? (
                        <IconContent>
                            <Tippy
                                content={incidentToolTip}
                                key={`vessel-management-onboardEngagement_card_tooltip_${locationId}`}
                            >
                                <img src={incidentIcon} />
                            </Tippy>
                            Default
                            {!model.vesselDashboard && (
                                <img
                                    src={isHovering ? AlertBlue : AlertWhite}
                                    width={16}
                                    onMouseOver={() => setIsHovering(true)}
                                    onMouseOut={() => setIsHovering(false)}
                                />
                            )}
                        </IconContent>
                    ) : (
                        <IconContent>
                            Default
                            {!model.vesselDashboard && (
                                <img
                                    src={isHovering ? AlertBlue : AlertWhite}
                                    width={16}
                                    onMouseOver={() => setIsHovering(true)}
                                    onMouseOut={() => setIsHovering(false)}
                                />
                            )}
                        </IconContent>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="METRICS-POLICY"
                    label="Onboard engagement:"
                    locationId={locationId}
                    identifier="metrics-policy"
                    centered
                >
                    {onboardEngagementToolTip ? (
                        <>
                            <Tippy
                                content={onboardEngagementToolTip}
                                key={`vessel-management-onboardEngagement_card_tooltip_${locationId}`}
                            >
                                <img src={onboardEngagementIcon} />
                            </Tippy>
                            {model.metricsPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={onboardEngagementIcon} />
                            {model.metricsPolicy.name}
                        </>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="ENFORCEMENT-REMOVABLE-POLICY"
                    label="Removable enforcement:"
                    locationId={locationId}
                    identifier="inforcement-removable-policy"
                    centered
                >
                    {enforcementRemoveablesToolTip ? (
                        <>
                            <Tippy
                                content={enforcementRemoveablesToolTip}
                                key={`vessel-management-removable_card_tooltip_${locationId}`}
                            >
                                <img src={enforcementRemoveablesIcon} />
                            </Tippy>
                            {model.enforcementPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={enforcementRemoveablesIcon} />
                            {model.enforcementPolicy.name}
                        </>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="ENFORCEMENT-NETWORK-POLICY"
                    label="Network enforcement:"
                    locationId={locationId}
                    identifier="inforcement-network-policy"
                    centered
                >
                    {enforcementNetworkToolTip ? (
                        <>
                            <Tippy
                                content={enforcementNetworkToolTip}
                                key={`vessel-management-enforcement_card_tooltip_${locationId}`}
                            >
                                <img src={enforcementNetworkIcon} />
                            </Tippy>
                            {model.enforcementPolicy.name}
                        </>
                    ) : (
                        <>
                            <img src={enforcementNetworkIcon} />
                            {model.enforcementPolicy.name}
                        </>
                    )}
                </CardDataCell>
                <CardDataCell
                    gridArea="DEPLOYMENT"
                    label="Deployment:"
                    locationId={locationId}
                    identifier="deployment"
                >
                    {deploymentStatus}
                </CardDataCell>
                <ToggleButtonCell gridArea="VIEW-DETAIL">
                    <ToggleDetailsButton id={locationId} />
                </ToggleButtonCell>
                {isHovering && <WarningMessageCard />}
            </Styled.Card>
            {isExpanded && <VesselManagementDetailExpanded locationId={locationId} />}
        </PolicyManagementDetails>
    )
}
