import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import * as Styled from './_styled/policy-management-list-header.styled'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    const {selectedPolicyId, showPolicyModal} = usePolicyManagement()
    return (
        <Styled.TableHeaderGrid
            width={width}
            showDetailsBtn={selectedPolicyId == null && !showPolicyModal}
        >
            <Styled.TableHeaderCell gridColumn={2} width={width}>
                Policy type
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={3} width={width}>
                Policy name
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={4} width={width}>
                Vessels applied
            </Styled.TableHeaderCell>
            <Styled.TableHeaderCell gridColumn={5} width={width}>
                Last updated
            </Styled.TableHeaderCell>
        </Styled.TableHeaderGrid>
    )
}
