import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface DataCellProps {
    gridArea: string
    labelOnSameLine?: boolean
}
export const DataCell = styled.div<DataCellProps>`
    ${(props) => (props.labelOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

export const Label = styled.label`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    align-items: center;
    min-width: 100px;
    margin: 0;
`
export const Children = styled.div`
    width: 100%;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
`
