import {SourceDestinationOt} from '../../../../../../../store/state/locations/state'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import * as Styled from '../_styled/data-input.styled'

interface DataInputProps {
    label: string
    dataValue: SourceDestinationOt[]
    locationId: GuidType
}

export function OTDataInput({label, dataValue, locationId}: DataInputProps): JSX.Element {
    const {openSetOTSourcesModal} = useVesselManagement()

    const formattedDisplayValue = dataValue?.map(
        (entry) => `${entry?.source} - ${entry?.destination}`,
    )
    const formattedRowsNumber =
        formattedDisplayValue?.length === 0 ? 1 : formattedDisplayValue?.length

    return (
        <>
            <Styled.Label id={`${label}-ot-source-label`}>{label}</Styled.Label>
            <Styled.Value id={`${label}-ot-source-value`}>
                <Styled.Input
                    onClick={() =>
                        openSetOTSourcesModal({
                            locationId: locationId,
                            entry: 'existing',
                            sourceType: label.toLowerCase(),
                            sourceData: dataValue,
                        })
                    }
                    defaultValue={formattedDisplayValue?.join('\n')}
                    rows={formattedRowsNumber}
                    readOnly
                />
            </Styled.Value>
        </>
    )
}
