import {Link} from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    min-width: 110px;
    padding: 7px 13px;
    &:focus,
    &:visited,
    &:link,
    &:active,
    &:hover {
        text-decoration: none;
        color: ${(props) => props.theme.colors.link.primary};
    }
    &:hover {
        background-color: rgba(31, 136, 229, 0.1);
    }
    color: ${(props) => props.theme.colors.link.primary};
`
