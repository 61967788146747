import {getUserDescription, isServiceAccount} from '../../../helpers/getUserDescription'
import threatHelper from '../../../helpers/threatHelper'
import {LatestEventTimestampMap} from '../../../store/state/latest-event-timestamps/state'
import {
    getLocationDescription,
    getLocationEmail,
    isOnboardDashboard,
    LocationMap,
} from '../../../store/state/locations/state'
import {SFMScoreMap} from '../../../store/state/sfm-scores/state'
import {User} from '../../../store/state/users/state'
import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentSeverityValue} from '../../../values/incident-response-values'
import {getNodeLatestEvent, NodeDataMap} from '../../../values/nodes/NodeData'
import {Role} from '../../../values/Role'
import {ThreatMeasureMap} from '../../../values/ThreatMeasure'
import {IncidentAssociatedAssets} from '../contexts/types/incident-associated-assets'
import {IncidentAttachmentData} from '../contexts/types/incident-attachment-data'
import {IncidentHistory} from '../contexts/types/incident-history'
import {IncidentNotes} from '../contexts/types/incident-notes'
import {IncidentRecommendations} from '../contexts/types/incident-recommenations'
import {IncidentResponse} from '../contexts/types/incident-response'
import {IncidentStatus} from '../contexts/types/incident-status'
import {AssetOutputModel} from './asset.model'
import {AssignmentChangeOutputModel, wasAssignmentUpdated} from './assignment-change.model'
import {DateTimeEventOutputModel} from './date-time-event.model'
import {IncidentHistoryOutputModel} from './history-record.model'
import {NoteOutputModel} from './note.model'
import {RecommendationOutputModel} from './recommendation.model'
import {SeverityChangeOutputModel, wasSeverityUpdated} from './severity-change.model'
import {StatusChangeOutputModel, wasStatusUpdated} from './status-change.model'

export class IncidentModalOutputModel {
    public readonly id: GuidType
    public readonly status: IncidentStatus
    public readonly number: string
    public readonly title: string
    public readonly severity: IncidentSeverityValue
    public readonly type: string
    public readonly vessel: string
    public readonly assetsInvolved: AssetOutputModel[]
    public readonly raised: DateTimeEventOutputModel
    public readonly updated: DateTimeEventOutputModel
    public readonly attachment: IncidentAttachmentData | null
    public readonly history: IncidentHistoryOutputModel[]
    public readonly recommendations: RecommendationOutputModel[]
    public readonly description: string
    public readonly warning: string
    public readonly assignedBy: GuidType
    public readonly assignedTo: GuidType
    public readonly assignedToName: string
    public readonly guestAssignedToEmail: string
    public readonly numberOfNewItems: number
    public readonly assignedToVesselEmail: boolean
    public readonly vesselEmail: string | null
    public readonly vesselOnboardDashboard: boolean
    public readonly hasWatchedItems: boolean
    public readonly allItemsMonitored: boolean

    public constructor(
        response: IncidentResponse,
        locationMap: LocationMap,
        userRoles: Role[],
        users: User[],
        sfmScores: SFMScoreMap,
        nodes: NodeDataMap,
        latestEventTimestamps: LatestEventTimestampMap,
        threatMeasures: ThreatMeasureMap,
    ) {
        const isInternalUser = this.checkIfIsInternalUser(userRoles)

        this.id = response.identity
        this.status = response.currentStatus
        this.number = response.incidentCode
        this.title = response.title
        this.severity = response.severity
        this.type = response.type
        this.vessel = getLocationDescription(locationMap, response.location)
        this.description = response.description
        this.warning = response.warning
        this.assignedToVesselEmail = response.assignedToVesselEmail
        this.updated = {
            when: response.updatedState.when,
            who: getUserDescription(users, response.updatedState.who, undefined, isInternalUser),
        }
        this.vesselEmail = getLocationEmail(locationMap, response.location)
        this.vesselOnboardDashboard = isOnboardDashboard(locationMap, response.location)
        this.raised = {
            when: response.createdState.when,
            who: getUserDescription(users, response.createdState.who, undefined, isInternalUser),
        }

        this.assetsInvolved = this.getAssetsInvolved(
            response.identity,
            response.associatedAssets,
            sfmScores,
            nodes,
            latestEventTimestamps,
            threatMeasures,
            locationMap,
        )

        this.attachment = response.attachment
        this.history = this.parseHistory(
            this.parseNotes(response.notes, users, isInternalUser),
            this.parseStatusChanges(response.history, users, isInternalUser),
            this.parseSeverityChanges(response.history, users, isInternalUser),
            this.parseAssignmentChanges(response.history, users, isInternalUser),
        )
        this.recommendations = this.parseRecommendations(response.recommendations)

        this.assignedBy = response.assignedByWho
        this.assignedTo = response.assignedToWho
        this.assignedToName = response.assignedToWhoName
        this.guestAssignedToEmail = response.guestAssignedToEmail
        this.numberOfNewItems = response.numberOfNewItems
        this.hasWatchedItems = response.links?.some((e) => e.monitored)
        this.allItemsMonitored =
            response.links?.length > 0 && response.links?.every((e) => e.monitored)
    }

    private parseNotes(
        notes: IncidentNotes[],
        users: User[],
        isInternalUser: boolean,
    ): NoteOutputModel[] {
        return (
            notes?.map((note) => {
                return {
                    id: note.identity,
                    createdBy: getUserDescription(
                        users,
                        note.createdBy,
                        note.createdByEmail,
                        isInternalUser,
                    ),
                    isAddedOnVessel: note.isAddedOnVessel,
                    userId: note.createdBy,
                    createdWhen: note.createdWhen,
                    updatedWhen: note.updatedWhen,
                    text: note.note,
                    edited: note.updatedWhen !== note.createdWhen,
                    isViewed: note.isViewed,
                } as NoteOutputModel
            }) ?? []
        )
    }

    private parseStatusChanges(
        statusHistory: IncidentHistory[],
        users: User[],
        isInternalUser: boolean,
    ): StatusChangeOutputModel[] {
        return (
            statusHistory
                ?.filter((record) => wasStatusUpdated(record))
                ?.map((record) => {
                    return {
                        id: record.identity,
                        username: getUserDescription(
                            users,
                            record.state.who,
                            undefined,
                            isInternalUser,
                        ),
                        oldStatus: record.oldStatus,
                        newStatus: record.newStatus,
                        when: record.state.when,
                        isViewed: record.isViewed,
                        isAutoReopened:
                            record.oldStatus === 'CLOSED_MONITOR' &&
                            record.newStatus === 'OPEN' &&
                            isServiceAccount(
                                users,
                                record.state.who,
                                isInternalUser,
                                record.reason,
                            ),
                    } as StatusChangeOutputModel
                }) ?? []
        )
    }

    private parseSeverityChanges(
        severityHistory: IncidentHistory[],
        users: User[],
        isInternalUser: boolean,
    ): SeverityChangeOutputModel[] {
        return (
            severityHistory
                ?.filter((record) => wasSeverityUpdated(record))
                ?.map((record) => {
                    return {
                        id: record.identity,
                        username: getUserDescription(
                            users,
                            record.state.who,
                            undefined,
                            isInternalUser,
                        ),
                        oldSeverity: record.oldSeverity,
                        newSeverity: record.newSeverity,
                        when: record.state.when,
                        isViewed: record.isViewed,
                    } as SeverityChangeOutputModel
                }) ?? []
        )
    }

    private parseAssignmentChanges(
        assignmentHistory: IncidentHistory[],
        users: User[],
        isInternalUser: boolean,
    ): AssignmentChangeOutputModel[] {
        return (
            assignmentHistory?.filter(wasAssignmentUpdated)?.map((record) => {
                return {
                    id: record.identity,
                    username: getUserDescription(
                        users,
                        record.state.who,
                        undefined,
                        isInternalUser,
                    ),
                    oldAssignedToUser: record.oldAssignedToUser,
                    newAssignedToUser: record.newAssignedToUser,
                    oldGuestAssignedToEmail: record.oldGuestAssignedToEmail,
                    newGuestAssignedToEmail: record.newGuestAssignedToEmail,
                    oldAssignedToVesselEmail: record.oldAssignedToVesselEmail,
                    newAssignedToVesselEmail: record.newAssignedToVesselEmail,
                    when: record.state.when,
                    isViewed: record.isViewed,
                } as AssignmentChangeOutputModel
            }) ?? []
        )
    }

    private parseHistory(
        notes: NoteOutputModel[],
        statusHistory: StatusChangeOutputModel[],
        severityHistory: SeverityChangeOutputModel[],
        assignmentHistory: AssignmentChangeOutputModel[],
    ): IncidentHistoryOutputModel[] {
        const result = notes.map((note) => {
            return {
                id: note.id,
                noteRecord: note,
                when: note.updatedWhen,
            } as IncidentHistoryOutputModel
        })

        statusHistory.map((record) => {
            result.push({
                id: record.id,
                statusChangeRecord: record,
                when: record.when,
            } as IncidentHistoryOutputModel)
        })

        severityHistory.map((record) => {
            result.push({
                id: record.id,
                severityChangeRecord: record,
                when: record.when,
            } as IncidentHistoryOutputModel)
        })

        assignmentHistory.map((record) => {
            result.push({
                id: record.id,
                assignmentChangeRecord: record,
                when: record.when,
            } as IncidentHistoryOutputModel)
        })
        return result
    }

    private parseRecommendations(
        recommendations: IncidentRecommendations[],
    ): RecommendationOutputModel[] {
        return (
            recommendations?.map((recommendation) => {
                return {
                    id: recommendation.identity,
                    text: recommendation.recommendation,
                } as RecommendationOutputModel
            }) ?? []
        )
    }

    private getAssetsInvolved(
        identity: string,
        associatedAssets: IncidentAssociatedAssets[],
        sfmScores: SFMScoreMap,
        nodes: NodeDataMap,
        latestEventTimestamps: LatestEventTimestampMap,
        threatMeasures: ThreatMeasureMap,
        locationMap: LocationMap,
    ): AssetOutputModel[] {
        if (!associatedAssets) {
            return []
        }

        return associatedAssets.map((asset) => {
            const sfmScore = sfmScores && sfmScores?.get(asset.node)
            const nodeData = nodes && nodes?.get(asset.node)
            return {
                incidentId: identity,
                assetId: nodeData?.node,
                threatScore: Math.round(sfmScore || 0),
                lastEvent: getNodeLatestEvent(asset.node, latestEventTimestamps),
                threatLevel:
                    sfmScore && nodeData
                        ? threatHelper(sfmScore || 0, threatMeasures, nodeData.value)
                        : undefined,
                location: nodeData
                    ? getLocationDescription(locationMap, nodeData.location)
                    : 'Unknown',
                value: nodeData?.value,
                alias: nodeData?.alias,
                ipAddress: nodeData?.ipAddress,
            } as AssetOutputModel
        })
    }

    private checkIfIsInternalUser(roles: Role[]): boolean {
        const INTERNAL_ROLES = ['DEVELOPER', 'THREAT_ANALYST']
        return roles && roles.some((role) => INTERNAL_ROLES.includes(role))
    }
}
