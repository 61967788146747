import LoadingState from '../../../../values/loading-state-enum'
import {VesselDeploymentStatus} from './deployment-status-types'
import {DEFAULT_OT_SOURCE_MODAL_DETAILS} from './ot-sources-modal-details'
import {DEFAULT_VESSEL_CONFIG_MODAL, VesselManagementState} from './vessel-management-state'

export const DEFAULT_VESSEL_MANAGEMENT_STATE: VesselManagementState = {
    activeVesselId: null,
    initialValue: DEFAULT_VESSEL_CONFIG_MODAL,
    displayValue: DEFAULT_VESSEL_CONFIG_MODAL,
    updatedValue: DEFAULT_VESSEL_CONFIG_MODAL,
    isValidBusinessNetworkChange: false,
    isValidOTNetworkChange: false,
    isValidCrewNetworkChange: false,
    isOnboardAgentIdChanged: false,
    isIncidentPolicyChanged: false,
    isMetricsPolicyChanged: false,
    isEnforcementPolicyChanged: false,
    isValidEmailChange: false,
    isValidChange: false,
    detailExpanded: {
        networkSettingsExpanded: true,
        commsSettingsExpanded: true,
        policySettingsExpanded: true,
        vesselTaggingExpanded: true,
        otSourcesExpanded: true,
    },
    locationsInIsolationMode: undefined,
    hasActiveControl: false,
    selectedIncidentPolicy: undefined,
    selectedMetricsPolicy: undefined,
    selectedEnforcementPolicy: undefined,
    operationalStatus: undefined,
    trafficStatus: undefined,
    showOTSourceModalDisplay: false,
    showFilterBar: false,
    filteredLocations: [],
    deploymentOperationalLoadingState: LoadingState.NotPopulated,
    deploymentTrafficLoadingState: LoadingState.NotPopulated,
    vesselDeploymentLoadingState: LoadingState.NotPopulated,
    vesselDeploymentStatus: {} as VesselDeploymentStatus,
    oTSourceModalDetails: DEFAULT_OT_SOURCE_MODAL_DETAILS,
    policyLicenseSummery: undefined,
}
