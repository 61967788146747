import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {smallFont} from '../../../../../../theme/font-styles'

export const SavedFilterWrapper = styled.div`
    position: relative;
    width: 100%;
    align-items: center;
    border-radius: ${spacing(1)};
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    padding: ${spacing(1)};
    margin-bottom: ${spacing(1)};
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.background.fade};
`

export const NameRow = styled.div`
    display: flex;
    justify-content: space-between;
`

export const FilterName = styled.div`
    display: inline-block;
    justify-content: flex-start;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Details = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export const AlertDetails = styled.div`
    padding: ${spacing(1)};
`

export const DetailRow = styled.div`
    display: flex;
    justify-content: flex-start;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    line-height: 20px;
    width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

interface TextButtonProps {
    disabled?: boolean
}

export const TextButton = styled.div<TextButtonProps>`
    color: ${(props) => props.theme.colors.link.primary};
    padding: 3px;
    text-decoration: underline;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`

interface DeleteProps {
    isDeletable: boolean
}

export const DeleteButton = styled.button<DeleteProps>`
    display: flex;
    justify-content: center;
    gap: ${spacing(1)};
    ${smallFont()}
    border: none;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.background.fade};
    color: ${(props) => props.theme.colors.link.primary};
    &:hover {
        background-color: rgba(206, 218, 236, 1);
    }
    cursor: ${(props) => (props.isDeletable ? 'pointer' : 'default')};
    ${(props) => (props.isDeletable ? '' : 'pointer-events: none; opacity: 0.3;')};
`
