import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellVesselType(): JSX.Element {
    const {data, initialData, displayData, changeType} = useVesselConfig()
    const changed = data.type !== initialData.type
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeType(e.target.value)
    }
    return (
        <DataInput
            label="Vessel Type"
            initialValue={displayData.type}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
