import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {useDispatch} from 'react-redux'
import {submitDataRequestFn} from '../../../../store/state/extended-data-request/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isSubmitAllowedSelector} from '../../../../store/state/extended-data-request/selectors'

export function AddButton(): JSX.Element | null {
    const isSubmitAllowed = useTypedSelector(isSubmitAllowedSelector)
    const dispatch = useDispatch()

    function onClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        dispatch(submitDataRequestFn())
    }

    return (
        <Styled.Button disabled={!isSubmitAllowed} id="add-button" onClick={onClick}>
            Submit
        </Styled.Button>
    )
}
