import {GridLayout} from '../../grid-layout.enum'
import {ColouredLead} from './coloured-lead.styled'
import {Container} from './container.styled'
import {ContentArea} from './content-area.styled'
import {Text} from './text.styled'

interface HeaderNewIndicatorProps {
    gridLayout: GridLayout
}

export function HeaderNewIndicator({gridLayout}: HeaderNewIndicatorProps): JSX.Element {
    return (
        <Container gridLayout={gridLayout} id="HeaderNewIndicator">
            <ColouredLead />
            <ContentArea>
                <Text gridLayout={gridLayout}>New</Text>
            </ContentArea>
        </Container>
    )
}
