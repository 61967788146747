import styled from 'styled-components'

interface ClearButtonLinkProps {
    disabled: boolean
}

export const ClearButtonLink = styled.div<ClearButtonLinkProps>`
    color: ${(props) =>
        props.disabled ? props.theme.colors.link.disabled : props.theme.colors.link.primary};
    text-decoration: underline;
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: 0;
    ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
    &:hover {
        outline: 0;
        cursor: pointer;
    }
`
