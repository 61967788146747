import styled from 'styled-components'
import {GridLayout} from '../../grid-layout.enum'

function getMarginTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '5px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '10px'
        default:
            return '9px'
    }
}

function getJustifyContent(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return 'space-between'
        default:
            return 'space-around'
    }
}

interface ContentProps {
    gridLayout: GridLayout
}

export const Content = styled.div<ContentProps>`
    display: flex;
    justify-content: ${(props) => getJustifyContent(props.gridLayout)};
    align-items: center;
    margin-top: ${(props) => getMarginTop(props.gridLayout)};
`
