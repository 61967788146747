import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {vesselManagementReducer} from './state/reducer'
import {VesselManagementContext} from './vessel-management-context'
import {VesselManagementState} from './type/vessel-management-state'
import {DEFAULT_VESSEL_MANAGEMENT_STATE} from './type/default-vessel-management-state'

type VesselManagementProviderProps = {
    children: ReactNode | ReactNode[]
    hasActiveControl: boolean
}

export function VesselManagementProvider({
    children,
    hasActiveControl,
}: VesselManagementProviderProps): JSX.Element {
    const initialState: VesselManagementState = cloneDeep(DEFAULT_VESSEL_MANAGEMENT_STATE)
    initialState.hasActiveControl = hasActiveControl

    const [state, dispatch] = useReducer(vesselManagementReducer, initialState)

    return (
        <VesselManagementContext.Provider value={{state, dispatch}}>
            {children}
        </VesselManagementContext.Provider>
    )
}
