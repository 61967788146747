import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import * as Styled from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {AnalysisSelectionFilter} from './analysis-selection-filter/analysis-selection-filter'
import {ResetFilterButton} from '../../../../components/page-filter/components/shared/reset-filter/reset-filter-button'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {incidentsReportFilterLoadingSelector} from '../../../../store/state/incidents-report-filter/selectors'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {resetFilter} from '../../../../store/state/incidents-report-filter/action-creators'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {
        showFilterBar,
        displayFilterBar,
        isInactive,
        displayHighlightedFilterValue,
        highlightedFilerValue,
    } = usePagedIncidentsReport()

    if (useTypedSelector(incidentsReportFilterLoadingSelector)) {
        return (
            <Styled.Loading>
                <LoadingSpinner />
            </Styled.Loading>
        )
    }

    return (
        <Styled.FilterBarWrapper width={width} id={`${PageType.INCIDENTS_REPORT}_filter-bar`}>
            <div
                id={`${PageType.INCIDENTS_REPORT}_filter-bar_close-icon`}
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(!showFilterBar)
                    displayHighlightedFilterValue('None')
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styled.FilterBarStyled>
                <FilterBarHeader title="Incidents Report Filters" />
                <Styled.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.INCIDENTS_REPORT} />
                </Styled.SavedFilterContent>

                <Styled.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.INCIDENTS_REPORT}_filter-bar_section-content-1`}
                >
                    <AnalysisSelectionFilter />
                </Styled.SectionContent>

                <VesselFilters
                    pageType={PageType.INCIDENTS_REPORT}
                    highlightedFilerValueForMetrics={highlightedFilerValue === 'Vessels'}
                />
                <ResetFilterButton
                    resetFn={resetFilter}
                    currentPage={AuditLogPageType.INCIDENTS_REPORT}
                />
            </Styled.FilterBarStyled>
        </Styled.FilterBarWrapper>
    )
}
