import styled from 'styled-components'

export const ErrorBox = styled.div`
    height: 100%;
    font-size: 24px;
    font-weight: 600;
    color: red;
    text-align: center;
    margin-top: 60px;
`

export const FlexPanelContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
