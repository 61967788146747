import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const Networks = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

const Base = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const VesselsHeadingRow = styled(Base)`
    grid-column: 1 / span 1;
`

export const NetworkRowWrapper = styled.div`
    padding-left: ${spacing(2)};
`
export const ViewAllRowWrapper = styled.span`
    display: flex;
    justify-content: space-between;
`
