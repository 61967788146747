import {REST} from '../../../..'
import {AIMEE_ENDPOINT} from './vessels-beta-endpoints'

export interface NumberOfDaysSinceLastDataResponse {
    locationId: string
    lastSeenTimestamp: string
}
export async function buildNumberOfDaysSinceLastData(
    criteria: 'all' | 'network',
): Promise<NumberOfDaysSinceLastDataResponse[]> {
    try {
        const lastSeenTimestampType = criteria === 'network' ? 'packetbeat' : 'all'

        const response = await REST.post(
            `${AIMEE_ENDPOINT}/assets/assetsLastSeenLocationSummary?lastSeenTimestampType=${lastSeenTimestampType}`,
        )
        const resultArray = response.data as Array<{
            location: string
            lastSeenTimestamp: string
        }>
        return resultArray.map((oneLocation) => {
            return {
                locationId: oneLocation.location,
                lastSeenTimestamp: oneLocation.lastSeenTimestamp,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<NumberOfDaysSinceLastDataResponse>()
    }
}
