import {useEffect, useState} from 'react'
import * as Styled from './_styled/targeted-assets-volume.styled'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {isEqual} from 'lodash'

interface TargetAssetsProps {
    metricConfigId: GuidType
    targetAssets: number
    disabled?: boolean
}

export function TargetedAssetsVolume({
    metricConfigId,
    targetAssets,
    disabled = false,
}: TargetAssetsProps): JSX.Element {
    const [selectedTarget, setTargetAssets] = useState<number>(targetAssets)
    const [hover, setHover] = useState(false)
    const {changeTargetedAssets, discardChanges, requestDiscardChanges} = useMetricsPolicy()

    useEffect(() => {
        if (discardChanges && !isEqual(targetAssets, selectedTarget)) {
            setTargetAssets(targetAssets)
            requestDiscardChanges(false)
        }
    }, [selectedTarget, discardChanges, targetAssets, requestDiscardChanges])

    function handleVolumeChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (disabled) {
            e.preventDefault()
            return
        }
        const newValue = parseFloat(e.target.value)
        const isItChanged = !isEqual(targetAssets, e.target.value)
        if (!isNaN(newValue)) {
            setTargetAssets(newValue)
            changeTargetedAssets(metricConfigId, newValue, isItChanged)
        }
    }

    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Label>Target % assets</Styled.Label>
            <Styled.VolumeSliderContainer>
                <Styled.VolumeSliderTrack
                    type="range"
                    min={0}
                    max={100}
                    value={selectedTarget}
                    onChange={handleVolumeChange}
                    volume={selectedTarget}
                    onMouseOver={(e) => {
                        if (disabled) {
                            e.preventDefault()
                            return
                        }
                        setHover(true)
                    }}
                    onMouseLeave={(e) => {
                        if (disabled) {
                            e.preventDefault()
                            return
                        }
                        setHover(false)
                    }}
                    seeking={hover}
                />
                <Styled.SubValue volume={selectedTarget}>{selectedTarget}%</Styled.SubValue>
            </Styled.VolumeSliderContainer>
        </Styled.Wrapper>
    )
}
