import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './product-name-filter.styled'
import {useDispatch} from 'react-redux'
import {setSearchedProductName} from '../../../../../store/state/usb-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbInventoryFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function ProductNameFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {searchedProductName} = useTypedSelector(usbInventoryFilterSelector)

    return (
        <Styles.Wrapper id={`${PageType.USB_DEVICES}_filter-bar_product-name-filter`}>
            <Styles.Label>Product Name:</Styles.Label>
            <Styles.Container>
                <Styles.SearchInput
                    id={`${PageType.USB_DEVICES}_filter-bar_searched-product-name-value`}
                    type="text"
                    placeholder=" Search product name "
                    value={searchedProductName}
                    onChange={(e) => {
                        dispatch(setSearchedProductName(e.target.value))
                        dispatch(
                            logFilterByTypes(
                                'filterByProductName',
                                e.target.value,
                                true,
                                AuditLogPageType.USB_INVENTORY,
                            ),
                        )
                    }}
                />
                <Styles.SearchIcon>
                    <Icon glyph="Search" height={15} width={15} />
                </Styles.SearchIcon>
            </Styles.Container>
        </Styles.Wrapper>
    )
}
