import {Button} from './button.styled'
import {Text} from './text.styled'

export function WatchlistUpdating(): JSX.Element {
    return (
        <Button>
            <Text>Updating...</Text>
        </Button>
    )
}
