import styled from 'styled-components'
import checkboxSVG from '../../../../@assets/icons/checkmark.svg'
import {spacing} from '../../../../theme/spacing'

export const Wrapper = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    column-gap: ${spacing(2)};
    align-items: center;
    padding: 0;
    margin: 0;
`

export const Text = styled.span`
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: ${(props) => props.theme.font.weight.normal};
    font-size: 10px;
`

export const StyledCheckbox = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    background: white;
    border: 1px solid #ccc;
`

export const CheckboxInput = styled.input`
    opacity: 0;
    z-index: 1;
    height: 19px;
    &:hover ~ ${StyledCheckbox} {
        border-color: #1f88e5;
    }

    &:checked + ${StyledCheckbox} {
        border: none;
        background-color: white;
        content: url('${checkboxSVG}');
    }
`
