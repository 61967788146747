import {formatDate} from '../../../../../../helpers/formatting'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {auditLogDataSelector} from '../../../../../../store/state/audit-log/selectors'
import * as Styled from './history-section.styled'

export function HistorySectionContent(): JSX.Element {
    const auditLogData = useTypedSelector(auditLogDataSelector)
    const noHistory = !auditLogData || auditLogData?.length === 0

    return (
        <Styled.DetailsLayout>
            <Styled.ScrollableWrapper noHistory={noHistory}>
                {noHistory ? (
                    <Styled.NoHistory>No History</Styled.NoHistory>
                ) : (
                    <>
                        {auditLogData.map((data) => (
                            <Styled.Details key={data.identity}>
                                <Styled.When gridArea={'WHEN'}>
                                    {formatDate(data.createdAt)}
                                </Styled.When>
                                <Styled.What gridArea={'WHAT'}>{data.message}</Styled.What>
                            </Styled.Details>
                        ))}
                    </>
                )}
            </Styled.ScrollableWrapper>
        </Styled.DetailsLayout>
    )
}
