import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {TimestampFilterType, formatTimestampCriteria} from './timestamp-filter'
import {
    NETWORK_ASSET_AGGREGATION_ENDPOINT,
    SOFTWARE_SUMMARY_ENDPOINT,
    USB_SUMMARY_ENDPOINT,
} from './vessels-beta-endpoints'

export interface TotalInventoryAllTypesResponse {
    locationId: string
    totalAssets: number | undefined
    totalUsbDevices: number | undefined
    totalSoftware: number | undefined
}
export async function buildTotalInventoryAllTypes(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
    state?: 'untrusted' | 'trusted' | 'new',
): Promise<TotalInventoryAllTypesResponse[]> {
    const stateCriteriaAssets =
        state === 'untrusted' ? ['blocked'] : state === 'trusted' ? ['trusted'] : undefined
    const stateCriteriaUsb =
        state === 'untrusted' ? ['rejected'] : state === 'trusted' ? ['trusted'] : undefined
    const stateCriteriaSoftware =
        state === 'untrusted' ? ['unwanted'] : state === 'trusted' ? ['trusted'] : undefined

    try {
        const totalAssetsPromise = await REST.post(
            `${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                states: stateCriteriaAssets,
                fromRelativeLastSeen:
                    state === 'new' ? undefined : formatTimestampCriteria(analysisPeriod),
                fromRelativeFirstSeen:
                    state === 'new' ? formatTimestampCriteria(analysisPeriod) : undefined,
            },
        )
        const totalUsbDevicesPromise = await REST.post(`${USB_SUMMARY_ENDPOINT}/find/summary`, {
            locations: locationIds,
            deviceStatuses: stateCriteriaUsb,
            fromRelativeLastSeen:
                state === 'new' ? undefined : formatTimestampCriteria(analysisPeriod),
            fromRelativeFirstSeen:
                state === 'new' ? formatTimestampCriteria(analysisPeriod) : undefined,
        })
        const totalSoftwarePromise = await REST.post(`${SOFTWARE_SUMMARY_ENDPOINT}/find/summary`, {
            locations: locationIds,
            statuses: stateCriteriaSoftware,
            fromRelativeLastSeen:
                state === 'new' ? undefined : formatTimestampCriteria(analysisPeriod),
            fromRelativeFirstSeen:
                state === 'new' ? formatTimestampCriteria(analysisPeriod) : undefined,
        })
        const [totalAssetsResponse, totalUsbDevicesResponse, totalSoftwareResponse] =
            await Promise.all([totalAssetsPromise, totalUsbDevicesPromise, totalSoftwarePromise])

        const result = locationIds.map((locationId) => {
            const findTotalAssets: {location: string; totalAssets: number} =
                totalAssetsResponse.data.find(
                    (itemToFetch: {location: string; totalAssets: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalUsbDevices: {location: string; totalUsbDevices: number} =
                totalUsbDevicesResponse.data.find(
                    (itemToFetch: {location: string; totalAssets: number}) =>
                        itemToFetch.location === locationId,
                )
            const findTotalSoftware: {location: string; totalSoftware: number} =
                totalSoftwareResponse.data.find(
                    (itemToFetch: {location: string; totalSoftware: number}) =>
                        itemToFetch.location === locationId,
                )
            return {
                locationId: locationId,
                totalAssets: findTotalAssets?.totalAssets ?? undefined,
                totalUsbDevices: findTotalUsbDevices?.totalUsbDevices ?? undefined,
                totalSoftware: findTotalSoftware?.totalSoftware ?? undefined,
            }
        })
        return result.filter(
            (onlyValuesItems) =>
                !(
                    onlyValuesItems.totalAssets == undefined &&
                    onlyValuesItems.totalUsbDevices == undefined &&
                    onlyValuesItems.totalSoftware == undefined
                ),
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<TotalInventoryAllTypesResponse>()
    }
}
