import * as Styled from './_styled/policy-available-to-crew.styled'
import PDF_ICON from '../../../../../../modals/upload-document/pdf-icon-upload.svg'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {addQueryParam} from '../../../../../../helpers/navigation'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {useDispatch} from 'react-redux'
import {logDownloadPolicyFile} from '../../../../../../store/state/audit-log/action-creators'
import {DownloadFileType} from '../../../../../../store/state/audit-log/state'
interface PolicyAvailableToCrewProps {
    metricPolicyId: GuidType
    crewPolicyFileId?: GuidType
    disabled?: boolean
}
export function PolicyAvailableToCrew({
    crewPolicyFileId,
    metricPolicyId,
    disabled,
}: PolicyAvailableToCrewProps): JSX.Element {
    const {downloadCrewFile, removeCrewFile} = useMetricsPolicy()
    const dispatch = useDispatch()

    const textToDisplay = crewPolicyFileId ? 'Remove file' : 'Upload custom PDF'
    function onTextClick() {
        if (!crewPolicyFileId) {
            addQueryParam('uploadDocument', metricPolicyId)
        } else {
            removeCrewFile(metricPolicyId)
        }
    }
    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Label>Policy available to crew</Styled.Label>
            <Styled.FileWrapper>
                {crewPolicyFileId && (
                    <img
                        src={PDF_ICON}
                        onClick={() => {
                            downloadCrewFile(crewPolicyFileId)
                            dispatch(
                                logDownloadPolicyFile(
                                    crewPolicyFileId,
                                    DownloadFileType.PDF,
                                    metricPolicyId,
                                    'Metric policy',
                                ),
                            )
                        }}
                        style={{cursor: 'pointer'}}
                    />
                )}
                <Styled.TitleText onClick={onTextClick}>{textToDisplay}</Styled.TitleText>
            </Styled.FileWrapper>
        </Styled.Wrapper>
    )
}
