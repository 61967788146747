import styled from 'styled-components'

interface ColourSquareProps {
    background: string
}

export const ColourSquare = styled.span<ColourSquareProps>`
    display: inline-block;
    height: 13px;
    width: 13px;
    background-color: ${(props) => props.background};
    margin-right: 10px;
`
