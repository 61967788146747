import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {ReactNode, useRef, useState} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {CogButton} from '../../general/header/cog-button/cog-button'
import {PeriodSelect} from './period-select'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {MetricWidgetOptions} from './metrics-widget-options'
import {FilterComponent, FilterComponentWrapper} from './_styled/metrics-widget-options.styled'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNode[]
}

export function WidgetWrapper({gridLayout, children}: WidgetWrapperProps): JSX.Element {
    const [openMetricsWidgetDropDown, setOpenMetricsWidgetDropDown] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)

    useOnClickOutside(clickOutside, () => {
        openMetricsWidgetDropDown && setOpenMetricsWidgetDropDown(false)
    })

    return (
        <Widget>
            <Header gridLayout={gridLayout} id="metrics-widget-header" ref={clickOutside}>
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout} id="metrics-widget-title">
                        Metrics
                    </TitleText>
                    <CogButton
                        filterApplied={openMetricsWidgetDropDown}
                        onClick={() => setOpenMetricsWidgetDropDown(!openMetricsWidgetDropDown)}
                        widget="metrics"
                    />
                </HeaderContent>
            </Header>
            {openMetricsWidgetDropDown && (
                <FilterComponentWrapper>
                    <FilterComponent ref={clickOutside}>
                        <MetricWidgetOptions />
                        <PeriodSelect />
                    </FilterComponent>
                </FilterComponentWrapper>
            )}
            {children}
        </Widget>
    )
}
