import {NumBerOfDaysDisplay} from './number-of-days-since-last-data.styled'

interface NumberOfDaysItemsProps {
    averageResolveTime: string
    identifier: string
}
export function TotalAverageTime({
    averageResolveTime,
    identifier,
}: NumberOfDaysItemsProps): JSX.Element {
    return (
        <NumBerOfDaysDisplay
            id={`average-time-display-${identifier}-${getFormattedDuration(averageResolveTime)}`}
        >
            {getFormattedDuration(averageResolveTime)}
        </NumBerOfDaysDisplay>
    )
}
export function getFormattedDuration(duration: string): string | null {
    const matchDays = duration.match(/^P(\d+)D/)
    const matchHours = duration.match(/T(\d+)H/)
    const matchMinutes = duration.match(/(\d+)M/)
    const matchSeconds = duration.match(/(\d+)S/)

    let days = matchDays ? parseInt(matchDays[1], 10) : 0
    let hours = matchHours ? parseInt(matchHours[1], 10) : 0
    let minutes = matchMinutes ? parseInt(matchMinutes[1], 10) : 0
    const seconds = matchSeconds ? parseInt(matchSeconds[1], 10) : 0

    // Convert hours to days if greater than 24
    if (hours >= 24) {
        const additionalDays = Math.floor(hours / 24)
        days += additionalDays
        hours = hours % 24
    }

    // Convert minutes to hours if greater than 60
    if (minutes >= 60) {
        const additionalHours = Math.floor(minutes / 60)
        hours += additionalHours
        minutes = minutes % 60
    }
    const parts: string[] = []

    if (days > 0) {
        parts.push(days > 1 ? `${days} days` : `${days} day`)
    }

    if (hours > 0) {
        parts.push(hours > 1 ? `${hours} hours` : `${hours} hour`)
    }

    if (minutes > 0) {
        parts.push(minutes > 1 ? `${minutes} minutes` : `${minutes} minute`)
    }
    if (seconds > 0 && minutes < 1 && hours < 1 && days < 1) {
        parts.push(seconds > 1 ? `${seconds} seconds` : `${seconds} second`)
    }

    return parts.join(' ').trim()
}
