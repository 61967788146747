import {Check} from 'react-feather'
import {ChangeEvent, useState} from 'react'
import {NodeValue, NodeValues} from '../../../../../../values/nodes/NodeData'
import {DataCell} from '../data-cell/data-cell'
import {IconWrapper, Select} from './business-value.styled'
import {useModalEditAsset} from '../../../../contexts/edit-asset/use-modal-edit-asset'

export function SelectBusinessValue(): JSX.Element {
    const {currentAssetValue, setNewAssetValue} = useModalEditAsset()
    const [displayedValue, setDisplayedValue] = useState(currentAssetValue)

    const changedValue = displayedValue !== currentAssetValue

    const selectableFields: NodeValues[] = []
    selectableFields.push(NodeValues.HIGH)
    selectableFields.push(NodeValues.MEDIUM)
    selectableFields.push(NodeValues.LOW)

    function businessValueChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        setDisplayedValue(e.target.value as NodeValue)
        setNewAssetValue(e.target.value as NodeValue)
    }

    return (
        <DataCell label="Business Value">
            <Select
                onChange={businessValueChangeHandler}
                value={displayedValue}
                id="business-value-Select"
            >
                {!currentAssetValue && (
                    <option key="default-option" id="business-value-Select-default-option">
                        Select Value
                    </option>
                )}
                {selectableFields?.map((value) => (
                    <option key={value} value={value} id={`business-value-Select-${value}-option`}>
                        {value.toUpperCase()}
                    </option>
                ))}
            </Select>
            <IconWrapper>
                {changedValue && <Check color={'green'} width={19} height={19} />}
            </IconWrapper>
        </DataCell>
    )
}
