import {useDispatch} from 'react-redux'
import * as Styles from '../incident-type/incident-type.styled'
import {useRef, useState} from 'react'
import {
    setSelectedCreatedRelativeFromRange,
    setSelectedCreatedRelativeToRange,
} from '../../../../../store/state/incidents-filter/action-creators'
import {TimeRange} from '../../../../../values/TimeRange'
import {
    incidentsFilterCreatedFromRelativeSelector,
    incidentsFilterCreatedToRelativeSelector,
} from '../../../../../store/state/incidents-filter/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {
    RelativeTimestampType,
    TimestampType,
    allAvailableRaisedTimestampOptions,
    getKeyFromTimestamp,
} from '../../../contexts/types/incident-timestamp'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function RaisedOn(): JSX.Element {
    const dispatch = useDispatch()

    const selectedCreatedFromRelative = useTypedSelector(incidentsFilterCreatedFromRelativeSelector)
    const selectedCreatedToRelative = useTypedSelector(incidentsFilterCreatedToRelativeSelector)
    const selectedOption =
        selectedCreatedFromRelative !== null
            ? {
                  type: RelativeTimestampType.RELATIVE_FROM,
                  value: selectedCreatedFromRelative,
              }
            : {type: RelativeTimestampType.RELATIVE_TO, value: selectedCreatedToRelative}

    const [optionsVisible, setOptionsVisible] = useState(false)

    function onChangeWrapper(value: TimestampType | null): void {
        if (value === null) {
            dispatch(setSelectedCreatedRelativeToRange(null))
            dispatch(setSelectedCreatedRelativeFromRange(null))
        } else {
            const timeRange = TimeRange.fromName(value.value)

            if (value.type === RelativeTimestampType.RELATIVE_TO) {
                dispatch(setSelectedCreatedRelativeToRange(timeRange))
                dispatch(setSelectedCreatedRelativeFromRange(null))
            } else {
                dispatch(setSelectedCreatedRelativeFromRange(timeRange))
                dispatch(setSelectedCreatedRelativeToRange(null))
            }
            dispatch(
                logFilterByTypes(
                    'filterByRaisedOnPeriod',
                    `${timeRange}`,
                    true,
                    AuditLogPageType.INCIDENTS,
                ),
            )
        }
    }

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    return (
        <Styles.Wrapper ref={clickOutside} id="raisedOn-filter">
            <Styles.Label>Raised on:</Styles.Label>
            <Styles.Input>
                <Styles.WrapperOption
                    onClick={() => setOptionsVisible(true)}
                    id="raisedOn-filter-wrapper-option"
                >
                    {getKeyFromTimestamp(
                        'created',
                        selectedOption.type,
                        selectedOption.value?.name ?? null,
                    ) ?? '- All -'}
                </Styles.WrapperOption>
                {optionsVisible && (
                    <Styles.ValueOptions>
                        <Styles.ValueOption
                            key="default-option"
                            onClick={() => {
                                onChangeWrapper(null)
                                setOptionsVisible(false)
                            }}
                            id="raisedOn-filter-default-option"
                        >
                            - All -
                        </Styles.ValueOption>
                        {allAvailableRaisedTimestampOptions.map((value, index) => (
                            <Styles.ValueOption
                                key={index}
                                onClick={() => {
                                    onChangeWrapper(value)
                                    setOptionsVisible(false)
                                }}
                                id={`raisedOn-filter-${value.key}-option`}
                            >
                                {value.key}
                            </Styles.ValueOption>
                        ))}
                    </Styles.ValueOptions>
                )}
            </Styles.Input>
        </Styles.Wrapper>
    )
}
