import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {extraLargeFont, mediumSmallFont} from '../../../../theme/font-styles'

export const TitleWrapper = styled.div`
    display: flex;
    column-gap: 15px;
    padding: 20px 0;
    font-size: 20px;
`
interface ShowFilterBarProps {
    showFilterBar: boolean
    width: number
}
export const ScoreBar = styled.div<ShowFilterBarProps>`
    height: 30px;
    width: ${(props) => getScorebarWidth(props.showFilterBar, props.width)}px;
    background-color: #f29dac;
    border-radius: 4px;
`
export function getColor(metricType: string): string {
    switch (metricType) {
        case 'protection':
            return '#192CDB'
        case 'malwareDetection':
            return '#131D8D'
        case 'osSupported':
            return '#2836DC'
        case 'priviligedAccounts':
            return '#5359D8'
        case 'removableControls':
            return '#787CDD'
        case 'securityTools':
            return '#1C29C1'
        case 'maintenance':
            return '#6E19DB'
        case 'malwareDefinition':
            return '#884FE3'
        case 'osSecurityPatches':
            return '#9860E6'
        case 'assetOnline':
            return '#723CD5'
        case 'behaviour':
            return '#19ACDB'
        case 'usbStorage':
            return '#50A9AB'
        case 'mobileDevices':
            return '#7DD9DB'
        case 'hotspotMode':
            return '#418C8D'
        case 'wirelessConnection':
            return '#3A7F80'
        case 'adminLogon':
            return '#58BABC'
        case 'portableSoftware':
            return '#51A4A6'
        case 'accessControls':
            return '#2E4B97'
        default:
            return 'inherit'
    }
}
interface ScoreBarNoIssueProps {
    score: number
    metricType: string
    showFilterBar: boolean
    width: number
}

export const ScoreBarNoIssue = styled.div<ScoreBarNoIssueProps>`
    position: relative;
    height: 30px;
    width: ${(props) => (props.score / 100) * getScorebarWidth(props.showFilterBar, props.width)}px;
    background-color: ${(props) => getColor(props.metricType)};
    border-radius: 4px 0 0 4px;
`

export const Label = styled.div<ShowFilterBarProps>`
    display: flex;
    justify-content: space-between;
    width: ${(props) => getScorebarWidth(props.showFilterBar, props.width)}px;
    padding-top: 10px;
`
interface AssetsOkMessageProps {
    metricType: string
}
export const AssetsOkMessage = styled.div<AssetsOkMessageProps>`
    color: ${(props) => getColor(props.metricType)};
`
interface SectionProps {
    showFilterBar: boolean
    width: number
}
export const ScoreSection = styled.div<SectionProps>`
    width: ${(props) => getsectionWidth(props.showFilterBar, props.width)}px;
`
export const Score = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacing(1)};
    ${extraLargeFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding: 10px 0;
`
export const ScoreTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Text = styled.div`
    ${mediumSmallFont()};
    padding-left: 10px;
    font-weight: ${(props) => props.theme.font.weight.normal};
`
interface ColorWrapperProps {
    metricType: string
}
export const ColorWrapper = styled.div<ColorWrapperProps>`
    color: ${(props) => getColor(props.metricType)};
`
function getScorebarWidth(showFilterBar: boolean, width: number): number {
    return showFilterBar ? 350 : width > 540 ? 420 : 300
}
function getsectionWidth(showFilterBar: boolean, width: number): number {
    return showFilterBar ? 350 : width > 540 ? 420 : 300
}
