import {useDispatch} from 'react-redux'
import {Button} from './save-filter-popup.styled'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {
    saveNewIncidentsFilter,
    saveNewMetricsBetaFilter,
    saveNewNetworkAssetFilter,
    saveNewSoftwareInventoryFilter,
    saveNewUsbInventoryFilter,
    saveNewReportsFilter,
    saveNewIncidentsReportFilter,
} from '../../../../store/state/saved-filters/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {pageTypeSelector} from '../../../../store/state/saved-filters/selectors'
import {logSavedFilterUsage} from '../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType} from '../../../../store/state/audit-log/state'
import {getAuditPageType} from '../shared/helper'

interface Props {
    disabled: boolean
    newFilterName: string
    closePopup: (value: boolean) => void
}

export function SaveButton({disabled, newFilterName, closePopup}: Props): JSX.Element {
    const dispatch = useDispatch()
    const pageType = useTypedSelector(pageTypeSelector)

    const onClickFn = (pageType: SavedFilterPageType) => {
        switch (pageType) {
            case SavedFilterPageType.INCIDENTS:
                dispatch(
                    saveNewIncidentsFilter(
                        newFilterName,
                        SavedFilterPageType.INCIDENTS,
                        closePopup,
                    ),
                )
                break
            case SavedFilterPageType.NETWORK_ASSETS:
                dispatch(
                    saveNewNetworkAssetFilter(
                        newFilterName,
                        SavedFilterPageType.NETWORK_ASSETS,
                        closePopup,
                    ),
                )
                break
            case SavedFilterPageType.USB_INVENTORY:
                dispatch(
                    saveNewUsbInventoryFilter(
                        newFilterName,
                        SavedFilterPageType.USB_INVENTORY,
                        closePopup,
                    ),
                )
                break
            case SavedFilterPageType.SOFTWARE_INVENTORY:
                dispatch(
                    saveNewSoftwareInventoryFilter(
                        newFilterName,
                        SavedFilterPageType.SOFTWARE_INVENTORY,
                        closePopup,
                    ),
                )
                break
            case SavedFilterPageType.METRICS:
                dispatch(
                    saveNewMetricsBetaFilter(
                        newFilterName,
                        SavedFilterPageType.METRICS,
                        closePopup,
                    ),
                )
                break
            case SavedFilterPageType.REPORTS:
                dispatch(
                    saveNewReportsFilter(newFilterName, SavedFilterPageType.REPORTS, closePopup),
                )
                break
            case SavedFilterPageType.INCIDENTS_REPORT:
                dispatch(
                    saveNewIncidentsReportFilter(
                        newFilterName,
                        SavedFilterPageType.INCIDENTS_REPORT,
                        closePopup,
                    ),
                )
                break
        }
        dispatch(logSavedFilterUsage(getAuditPageType(pageType), AuditLogAuditType.SAVE_NEW_FILTER))
    }

    return (
        <Button id="save-new-filter-button" disabled={disabled} onClick={() => onClickFn(pageType)}>
            Save
        </Button>
    )
}
