import {useEffect, useRef, useState} from 'react'
import * as Styled from './saved-search.styled'
import {FilterSearch} from './components/filter-search'
import BOOKMARK from '../../@assets/icons/bookmark.svg'
import BOOKMARK_FILLED from '../../@assets/icons/bookmark-filled.svg'
import NOTIFY_ME from '../../@assets/icons/notify-me-empty.svg'
import {useDispatch} from 'react-redux'
import {
    resetAlertChanges,
    setCurrentAppliedSavedFilter,
    setInactiveComponent,
    setResultMessage,
} from '../../store/state/saved-filters/action-creators'
import useTypedSelector from '../../hooks/use-typed-selector'
import {SaveFilterPopup} from './components/save-filter-popup/save-filter-popup'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {networkAssetsFilterSelector} from '../../store/state/network-assets-filter/selectors'
import {usbInventoryFilterSelector} from '../../store/state/usb-inventory-filter/selectors'
import {softwareInventoryFilterSelector} from '../../store/state/software-inventory-filter/selectors'
import {NotifyMePopup} from './components/notify-me-popup/notify-me-popup'
import {notifyMeReselector} from './reselector/notify-me.reselector'
import {incidentsFilterSelector} from '../../store/state/incidents-filter/selectors'
import {IncidentsFilterCriteria} from '../../values/user-preferences/incidents-filter'
import {isSubscriptionUnavailable} from './components/shared/helper'
import {VesselTagsPopup} from './components/notify-me-popup/message-popup'
import {metricsBetaFilterSelector} from '../../store/state/metrics-filter-beta/selectors'
import {reportsFilterSelector} from '../../store/state/reports-filter/selectors'
import NOTIFY_ME_FILLED from '../../@assets/icons/notify-me-filled.svg'
import {incidentsReportFilterSelector} from '../../store/state/incidents-report-filter/selectors'

interface SavedSearchProp {
    savedFilterPageType: SavedFilterPageType
}

export function SavedSearch({savedFilterPageType}: SavedSearchProp): JSX.Element {
    const clickOutside = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [saveFilterPopupOpen, setSaveFilterPopupOpen] = useState(false)
    const [notifyMePopupOpen, setNotifyMePopupOpen] = useState(false)
    const [isHovering, setIsHovering] = useState(false)

    const incidentsSelectedFilterName = useTypedSelector(incidentsFilterSelector).selectedFilterName
    const networkAssetsSelectedFilterName = useTypedSelector(
        networkAssetsFilterSelector,
    ).selectedFilterName
    const usbDeviceSelectedFilterName = useTypedSelector(
        usbInventoryFilterSelector,
    ).selectedFilterName
    const softwareInventorySelectedFilterName = useTypedSelector(
        softwareInventoryFilterSelector,
    ).selectedFilterName
    const metricsBetaSelectedFilterName =
        useTypedSelector(metricsBetaFilterSelector).selectedFilterName
    const reportsSelectedFilterName = useTypedSelector(reportsFilterSelector).selectedFilterName
    const incidentsReportsSelectedFilterName = useTypedSelector(
        incidentsReportFilterSelector,
    ).selectedFilterName

    const model = useTypedSelector(notifyMeReselector)

    function getAllSavedFilterName(pageType: SavedFilterPageType): string {
        switch (pageType) {
            case SavedFilterPageType.INCIDENTS:
                return incidentsSelectedFilterName
            case SavedFilterPageType.NETWORK_ASSETS:
                return networkAssetsSelectedFilterName
            case SavedFilterPageType.USB_INVENTORY:
                return usbDeviceSelectedFilterName
            case SavedFilterPageType.SOFTWARE_INVENTORY:
                return softwareInventorySelectedFilterName
            case SavedFilterPageType.METRICS:
                return metricsBetaSelectedFilterName
            case SavedFilterPageType.REPORTS:
                return reportsSelectedFilterName
            case SavedFilterPageType.INCIDENTS_REPORT:
                return incidentsReportsSelectedFilterName
            default:
                return ''
        }
    }

    const selectedFilterName = getAllSavedFilterName(savedFilterPageType)
    const subscriptionUnavailable =
        savedFilterPageType === SavedFilterPageType.INCIDENTS
            ? isSubscriptionUnavailable(model?.currentFilter.criteria as IncidentsFilterCriteria)
            : false

    const unsaved = selectedFilterName === 'Unsaved filter'
    const notSelected = selectedFilterName === 'Saved filters'
    const subscribed = model?.currentSubscription !== undefined

    useEffect(() => {
        dispatch(setInactiveComponent(saveFilterPopupOpen))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveFilterPopupOpen])

    useEffect(() => {
        dispatch(setInactiveComponent(notifyMePopupOpen))
        if (!notifyMePopupOpen) {
            dispatch(resetAlertChanges())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifyMePopupOpen])

    useEffect(() => {
        unsaved && dispatch(setCurrentAppliedSavedFilter(undefined))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unsaved])

    return (
        <Styled.PopupWrapper>
            <Styled.Wrapper ref={clickOutside}>
                <FilterSearch
                    selectedFilterName={selectedFilterName}
                    pageType={savedFilterPageType}
                />
                <Styled.Image
                    src={unsaved || notSelected ? BOOKMARK : BOOKMARK_FILLED}
                    disabled={!unsaved}
                    onClick={() => {
                        setNotifyMePopupOpen(false)
                        setSaveFilterPopupOpen(!saveFilterPopupOpen)
                        dispatch(setResultMessage(undefined))
                    }}
                    id="notify-popup-action"
                />
                {subscriptionUnavailable ? (
                    <>
                        <Styled.ImageForNotSubscribe
                            src={NOTIFY_ME}
                            onMouseOver={() => setIsHovering(true)}
                            onMouseOut={() => setIsHovering(false)}
                            id="subscription-action-hover"
                        />
                        {isHovering && <VesselTagsPopup />}
                    </>
                ) : savedFilterPageType !== SavedFilterPageType.METRICS &&
                  savedFilterPageType !== SavedFilterPageType.REPORTS ? (
                    <Styled.Image
                        src={subscribed ? NOTIFY_ME_FILLED : NOTIFY_ME}
                        disabled={!model || unsaved}
                        onClick={() => {
                            setSaveFilterPopupOpen(false)
                            setNotifyMePopupOpen(!notifyMePopupOpen)
                        }}
                        id="subscription-action-click"
                    />
                ) : (
                    <></>
                )}
            </Styled.Wrapper>
            {saveFilterPopupOpen && <SaveFilterPopup closePopup={setSaveFilterPopupOpen} />}
            {notifyMePopupOpen && model && (
                <NotifyMePopup closePopup={setNotifyMePopupOpen} model={model} />
            )}
        </Styled.PopupWrapper>
    )
}
