import {default as mitreAttackJson} from '../@assets/json/enterprise-attack-details.json'
import {default as mitreAttackJsonGfc} from '../@assets/json/enterprise-attack-details-gfc.json'
import {MitreAttackJson, MitreObject} from '../values/mitre-object/data'
import {IndicatorData} from '../values/IndicatorData'

function getMitreObjects(gfcFlag: boolean): MitreObject[] {
    return gfcFlag
        ? (mitreAttackJsonGfc as MitreAttackJson)?.objects ?? []
        : (mitreAttackJson as MitreAttackJson)?.objects ?? []
}

function isOfType(object: MitreObject, type: string): boolean {
    return object.type === type
}

function matchByCode(object: MitreObject, code: string): boolean {
    return object.external_references.some((ref) => ref.external_id === code)
}

export const getTacticFromCode = (
    code: string | undefined,
    gfcFlag: boolean,
): string | null | undefined => {
    if (code == undefined) {
        return null
    }

    try {
        return getMitreObjects(gfcFlag)
            .filter((object) => isOfType(object, 'x-mitre-tactic'))
            .find((object) => matchByCode(object, code))?.name
    } catch {
        // eslint-disable-next-line no-console
        console.error(`could not find tactic with code ${code}`)
        return null
    }
}

export const getTechniqueFromCode = (
    code: string | undefined,
    gfcFlag: boolean,
): MitreObject | null | undefined => {
    if (code == undefined) {
        return null
    }

    try {
        return getMitreObjects(gfcFlag)
            .filter((object) => isOfType(object, 'attack-pattern'))
            .find((object) => matchByCode(object, code))
    } catch {
        // eslint-disable-next-line no-console
        console.error(`could not find tactic with code ${code}`)
        return null
    }
}

export const formatTechnique = (
    indicator: IndicatorData | undefined,
    mitreAttackTech: MitreObject | null | undefined,
): string | null => {
    if (!indicator || !mitreAttackTech) {
        return null
    }

    try {
        return `${indicator.mitreTtps} - ${mitreAttackTech?.name}`
    } catch {
        return null
    }
}
