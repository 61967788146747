import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
interface FilterBarWrapperProps {
    width: number
}
export const FilterBarWrapper = styled.div<FilterBarWrapperProps>`
    background-color: ${(props) => props.theme.unknownAssets.dataArea.background};
    position: relative;
`
export const FilterBarStyled = styled.div`
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    min-height: 250px;
    box-shadow: ${(props) => props.theme.unknownAssets.filterBar.boxShadow};
`
export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(3)};
    margin: 0;
    width: 100%;
`
export const SectionTitle = styled.h2`
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
`
export const SectionContent = styled.div`
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
    overflow-y: none;
    overflow-x: none;
    z-index: 0;
    border-top: ${(props) => `1px solid ${props.theme.colors.border.lowContrast}`};
    background-color: ${(props) => props.theme.colors.background.default};
`

interface FilterBarCardsWrapper {
    width: number
}
export const FilterBarCardsWrapper = styled.div<FilterBarCardsWrapper>`
    min-width: 320px;
    width: ${(props) => (props.width > 400 ? '388px' : 'fit-content')};
    display: flex;
    flex-direction: column;
    margin: ${spacing(2)} auto;
`
export const TopCloseFilterBar = styled.div`
    margin: 0 auto ${spacing(1)} auto;
`
export const BottomCloseFilterBar = styled.div`
    margin: ${spacing(1)} auto 0 auto;
`
