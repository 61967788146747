import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import {formatAverageAgeIntoReadableTime} from './data-helper/format-average-age-into-readable-time'
import {FormattedAge} from './data-helper/formatted-age.class'
interface Props {
    gridLayout: GridLayout
}
export function AverageAgeIncidents({gridLayout}: Props): JSX.Element {
    const {averageIncdientsAge} = useIncidentsWidget()

    const formattedAverageAge = formatAverageAgeIntoReadableTime(
        !averageIncdientsAge ? undefined : new FormattedAge(averageIncdientsAge),
    )

    return (
        <ValueForType
            type="Avg. Age"
            value={formattedAverageAge}
            gridLayout={gridLayout}
            id="incident-response-headline-values-avg-age"
        />
    )
}
