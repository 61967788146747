import {ReactNode, useReducer} from 'react'
import {PagedIncidentsContext} from './paged-incidents-context'
import {pagedIncidentsReducer} from './state/reducer'
import {DEFAULT_PAGED_INCIDENTS_STATE} from './types/default-paged-incidents-state'
import {PagedIncidentsState} from './types/paged-incidents-state'

type PagedIncidentsProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedIncidentsProvider({
    children,
    pageSize,
}: PagedIncidentsProviderProps): JSX.Element {
    const initialState: PagedIncidentsState = DEFAULT_PAGED_INCIDENTS_STATE
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedIncidentsReducer, initialState)

    return (
        <PagedIncidentsContext.Provider value={{state, dispatch}}>
            {children}
        </PagedIncidentsContext.Provider>
    )
}
