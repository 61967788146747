import styled from 'styled-components'
import {GridLayout} from '../../general/grid-layout.enum'

interface ImageProps {
    gridLayout: GridLayout
}

function getMarginRight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '5px'
        default:
            return '10px'
    }
}

export const Image = styled.img<ImageProps>`
    margin-right: ${(props) => getMarginRight(props.gridLayout)};
`
