import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {PolicyType} from '../../../../../../policy-management/contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../../../../../policy-management/contexts/policy-management/use-policy-management'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelNoButton} from '../expand-panel-button/expand-panel-no-button'
import {SaveButton} from '../save-button/save-button'
import {PolicySelect} from './policy-select'
import * as Styled from './policy-settings-section.styled'

interface NetworkSettingsSectionProps {
    locationId: GuidType
    hasVesselDashboard: boolean
}

export function PolicySettingsSection({
    locationId,
    hasVesselDashboard,
}: NetworkSettingsSectionProps): JSX.Element {
    const {detailExpanded} = useVesselManagement()
    const {allPolicyData} = usePolicyManagement()

    return (
        <Panel>
            <ExpandPanelNoButton headingRow={VesselManagementDetailType.PolicySettings} />
            {detailExpanded.networkSettingsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <PolicySelect
                            label={'Metrics'}
                            active={true}
                            allPolicyData={allPolicyData.filter(
                                (policy) => policy.policyType === PolicyType.METRICS,
                            )}
                        />
                        <PolicySelect
                            label={'Incidents'}
                            active={false}
                            noVesselDashboard={!hasVesselDashboard}
                            allPolicyData={allPolicyData.filter(
                                (policy) => policy.policyType === PolicyType.INCIDENT,
                            )}
                        />
                        <PolicySelect
                            label={'Enforcement'}
                            active={true}
                            allPolicyData={allPolicyData.filter(
                                (policy) => policy.policyType === PolicyType.ENFORCEMENT,
                            )}
                        />
                        <SaveButton locationId={locationId} />
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
