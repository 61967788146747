import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface CogImageProps {
    filterApplied: boolean
}

export const CogImage = styled.img`
    width: ${spacing(4)};
    height: ${spacing(4)};
    cursor: pointer;
`

export const SettingsIconCell = styled.div<CogImageProps>`
    white-space: nowrap;
    padding: 3px;
    border-radius: 3px;
    ${(props) => props.filterApplied && `background-color: ${props.theme.colors.link.primary};`}
`
