import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {IncidentSeverityValue} from '../../../../../../values/incident-response-values'

export const DonutWrapper = styled.div`
    height: 50px;
    width: 100px;
    background-color: white;
`
export const DonutIndicator = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`
export const DonutBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    overflow: hidden;
`
function getBackgroundColor(
    severityLevel: string | undefined,
    theme: CyberOwlTheme,
    activeFilter: boolean,
): string {
    switch (severityLevel) {
        case 'LOW':
            return activeFilter
                ? `${theme.incidents.incidentSeverity.active.low}`
                : `${theme.incidents.incidentSeverity.opacity.low}`
        case 'MEDIUM':
            return activeFilter
                ? `${theme.incidents.incidentSeverity.active.medium}`
                : `${theme.incidents.incidentSeverity.opacity.medium}`
        case 'HIGH':
            return activeFilter
                ? `${theme.incidents.incidentSeverity.active.high}`
                : `${theme.incidents.incidentSeverity.opacity.high}`

        case 'CRITICAL':
            return activeFilter
                ? `${theme.incidents.incidentSeverity.active.critical}`
                : `${theme.incidents.incidentSeverity.opacity.critical}`
        default:
            return `inherit`
    }
}
interface DonutLayerProps {
    severityLevel: IncidentSeverityValue
    positionOnDonut: string
    activeFilter: boolean
    firstElement?: boolean
}
export const DonutLayer = styled.div<DonutLayerProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: bottom center;
    background-color: ${(props) =>
        getBackgroundColor(props.severityLevel, props.theme, props.activeFilter)};
    transform: rotate(${(props) => props.positionOnDonut});
    border-bottom: ${(props) => (props.firstElement ? 'none;' : '2px solid white;')}
    cursor: pointer;
`
export const DonutCenter = styled.div`
    position: absolute;
    left: 30%;
    width: 40%;
    top: 62%;
    height: 40%;
    color: white;
    background-color: white;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    transform: none;
`

interface FilterCardProps {
    severityLevel: IncidentSeverityValue
    activeFilter: boolean
}
export const FilterCard = styled.div<FilterCardProps>`
    display: flex;
    flex-direction: row;
    color: ${(props) => props.theme.incidents.dataRow.text};
    ${smallFont()};
    cursor: pointer;
    border-left: 8px solid
        ${(props) => getBackgroundColor(props.severityLevel, props.theme, props.activeFilter)};
`
export const FilterCount = styled.a`
    padding-left: ${spacing(1)};
    ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-style: underline;
`
export const FilterText = styled.h2`
    padding-left: ${spacing(1)};
    ${smallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const ValuesWrapper = styled.div`
    height: 80px;
    width: 260px;
`
