import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {AuthTokenDisplay} from './components/auth-token-display/auth-token-display'
import {useEffect} from 'react'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {ContentContainerTop} from '../../templates/fixed-page/content-container-top.styled'
import {useDispatch} from 'react-redux'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'

export default function SwaggerLinksPage(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.SWAGGER))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FixedPageTemplate>
            <ContentContainerTop width={width}>
                <AuthTokenDisplay />
            </ContentContainerTop>
        </FixedPageTemplate>
    )
}
