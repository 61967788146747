import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSortByColumn} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {SORT_ORDER} from '../../../../../values/sort-order'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {
    getFormattedIncidentsSortType,
    getIncidentsSortType,
    getNewSortByDirection,
    INCIDENT_DETAILS,
} from '../../../data-helpers'
import {Select} from './select.styled'
import {IncidentResponseSortType} from '../../../contexts/types/type-of-interfaces'

export function SortOrderDropDown(): JSX.Element {
    const {width} = useDimensions()
    const ascending = width >= 400 ? 'Ascending' : 'asc'
    const descending = width >= 400 ? 'Descending' : 'desc'
    const dispatch = useDispatch()
    const {orderIncidentsBy} = useTypedSelector(incidentsFilterSelector)
    const {closeIncidentDetailsModal} = usePagedIncidents()

    const values: [string, string][] = []
    values.push(['+status', `Status (${ascending})`])
    values.push(['-status', `Status (${descending})`])
    values.push(['+incidentNumber', `Incident Number (${ascending})`])
    values.push(['-incidentNumber', `Incident Number (${descending})`])
    values.push(['+title', `Title (${ascending})`])
    values.push(['-title', `Title (${descending})`])
    values.push(['+incidentType', `Incident Type (${ascending})`])
    values.push(['-incidentType', `Incident Type (${descending})`])
    values.push(['+severity', `Severity (${ascending})`])
    values.push(['-severity', `Severity (${descending})`])
    values.push(['+vessel', `Vessel (${ascending})`])
    values.push(['-vessel', `Vessel (${descending})`])
    values.push(['+raised', `Raised (${ascending})`])
    values.push(['-raised', `Raised (${descending})`])
    values.push(['+updated', `Updated (${ascending})`])
    values.push(['-updated', `Updated (${descending})`])

    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        const formattedColumn = getIncidentsSortType(e.currentTarget.value.substring(1))
        dispatch(
            setSortByColumn(
                formattedColumn,
                getNewSortByDirection(formattedColumn, orderIncidentsBy),
            ),
        )
        removeQueryParam(INCIDENT_DETAILS)
        closeIncidentDetailsModal()
    }

    function getLabel(orderIncidentsBy: {
        column: IncidentResponseSortType
        direction: SORT_ORDER
    }): string {
        if (orderIncidentsBy.direction === SORT_ORDER.DESCENDING) {
            return `-${getFormattedIncidentsSortType(orderIncidentsBy.column)}`
        } else {
            return `+${getFormattedIncidentsSortType(orderIncidentsBy.column)}`
        }
    }
    return (
        <Select
            id={`sortable_${orderIncidentsBy.column}`}
            onChange={onChangeWrapper}
            value={getLabel(orderIncidentsBy)}
        >
            {values.map((item, key) => (
                <option key={key} value={item[0]} id={item[0]}>
                    {item[1]}
                </option>
            ))}
        </Select>
    )
}
