import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CopyConfigAreaLayout} from './_styled/copy-config-area.styled'
import {SelectVesselModal} from './select-vessel-modal'
import {getGridType} from '../shared/grid-layout-helper'

export function CopyConfigArea(): JSX.Element {
    const {width} = useDimensions()
    return (
        <CopyConfigAreaLayout gridLayout={getGridType(width)}>
            <SelectVesselModal />
        </CopyConfigAreaLayout>
    )
}
