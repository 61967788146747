import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../theme/theme'

export const Configuration = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.75rem;
    row-gap: ${spacing(2)};
`

interface LayoutProps {
    addMargin: boolean
    reducePadding: boolean
}

export const Layout = styled.div<LayoutProps>`
    margin: ${(props) => (props.addMargin ? spacing(2) : 0)} 0;
    padding: ${(props) => (props.reducePadding ? spacing(4) : spacing(8))};
    padding-top: ${(props) => (props.reducePadding ? spacing(2) : spacing(4))};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
    color: ${(props) => props.theme.colors.text.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
`
