import {useEffect} from 'react'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import LoadingState from '../../../../values/loading-state-enum'
import {Loading} from './components/loading'
import {PopulatedData} from './components/populated-data'
import {useFleetScoreOverTime} from './contexts/use-fleet-score-over-time'
import {loadingReselector} from './reselect/loading.reselector'
import {locationTagThreatScore, QueryManager} from '../../../../websocket/Queries'
import {fetchLocationTagThreatScore} from '../../../../store/state/location-tag-score/action-creators'
import {LAST_12H, LAST_24H, LAST_30D, LAST_7D, LAST_90D} from '../../../../values/TimeRange'
import {useReduxDispatch} from '../../../../hooks/useReduxDispatch'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {vesselTagsSelector} from '../../../../store/state/vessel-tags/selectors'
import {StrictMode} from '../../../../store/state/users/state'

interface Props {
    queryManager: React.MutableRefObject<QueryManager>
}

export function FleetScoreOverTimeWidget({queryManager}: Props): JSX.Element {
    const {fetchFilter, loadingFilterState, searchedVesselTagTerm} = useFleetScoreOverTime()
    const dispatch = useReduxDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const existingTags = useTypedSelector(vesselTagsSelector)

    useEffect(() => {
        if (loadingFilterState === LoadingState.NotPopulated) {
            fetchFilter(
                strictMode === StrictMode.DefaultVisibility
                    ? existingTags.find((tag) => tag.identity === assignedTag)
                    : undefined,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchFilter, loadingFilterState, assignedTag, strictMode])

    useEffect(() => {
        const currentQueryManager = queryManager.current
        if (searchedVesselTagTerm) {
            currentQueryManager.save(
                'ets-trend-12h',
                dispatch(fetchLocationTagThreatScore(LAST_12H, searchedVesselTagTerm?.identity)),
            )
            currentQueryManager.save(
                'ets-trend-24h',
                dispatch(fetchLocationTagThreatScore(LAST_24H, searchedVesselTagTerm.identity)),
            )
            currentQueryManager.save(
                'ets-trend-7d',
                dispatch(fetchLocationTagThreatScore(LAST_7D, searchedVesselTagTerm.identity)),
            )
            currentQueryManager.save(
                'ets-trend-30d',
                dispatch(fetchLocationTagThreatScore(LAST_30D, searchedVesselTagTerm.identity)),
            )
            currentQueryManager.save(
                'ets-trend-90d',
                dispatch(fetchLocationTagThreatScore(LAST_90D, searchedVesselTagTerm.identity)),
            )
        }
        return () => {
            currentQueryManager.cancel(locationTagThreatScore)
        }
    }, [dispatch, queryManager, searchedVesselTagTerm])

    return useTypedSelector(loadingReselector) ? <Loading /> : <PopulatedData />
}
