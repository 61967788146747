import {ReportsTableColumn} from './reports-table-column'

export function TableHeaderRow(): JSX.Element {
    return (
        <>
            <ReportsTableColumn gridColumn={2} text="Vessel" colName="vessel" />
            <ReportsTableColumn gridColumn={3} text="Report Type" colName="reportType" />
            <ReportsTableColumn gridColumn={5} text="Report ID" colName="reportID" />
            <ReportsTableColumn gridColumn={6} text="Period Start Date" colName="periodStartDate" />
            <ReportsTableColumn gridColumn={7} text="Period End Date" colName="periodEndDate" />
            <ReportsTableColumn gridColumn={8} text="Actions" colName="actions" />
        </>
    )
}
