import {ButtonsArea} from './chnage-software-status.styled'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import {useDispatch} from 'react-redux'
import {fetchSoftwareInventoryFilter} from '../../../../store/state/software-inventory-filter/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {savedSoftwareInventoryFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {MouseEvent} from 'react'
import {SoftwareStatus} from '../../../../store/state/software-inventory-filter/state'
import {Button} from '../../../../components/refresh-button/button.styled'

interface SubmitAreaProps {
    closeOnly?: boolean
}

export function SubmitArea({closeOnly}: SubmitAreaProps): JSX.Element {
    return (
        <ButtonsArea>
            {closeOnly ? (
                <CloseButton />
            ) : (
                <>
                    <CancelButton />
                    <ContinueButton />
                </>
            )}
        </ButtonsArea>
    )
}

export function CloseButton(): JSX.Element {
    const {closeChangeSoftwareStatusModal, refreshData} = usePagedSoftwareInventory()
    const dispatch = useDispatch()
    const savedFilters = useTypedSelector(savedSoftwareInventoryFiltersSelector)
    function closeModal(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        closeChangeSoftwareStatusModal()
        refreshData()
        dispatch(fetchSoftwareInventoryFilter(savedFilters))
    }
    return (
        <Button id="close-button" onClick={closeModal}>
            Close
        </Button>
    )
}

export function CancelButton(): JSX.Element {
    const {closeChangeSoftwareStatusModal} = usePagedSoftwareInventory()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        closeChangeSoftwareStatusModal()
    }
    return (
        <Button id="cancel-changes-button" onClick={cancelChanges}>
            Cancel
        </Button>
    )
}

export function ContinueButton(): JSX.Element | null {
    const {
        setSoftwareStatusDetails,
        findSoftwareDetailsForModal,
        selectedStatusType,
        closeChangeSoftwareStatusModal,
        checkState,
    } = usePagedSoftwareInventory()
    function onClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        setSoftwareStatusDetails(
            {
                status: selectedStatusType ?? SoftwareStatus.unknown,
                existingSoftware: checkState.forAllSoftware,
                setForCompany: checkState.forCompany,
            },
            findSoftwareDetailsForModal!.identity,
        ).then(() => closeChangeSoftwareStatusModal())
    }
    return (
        <Button id="continue-button" onClick={onClick}>
            Continue
        </Button>
    )
}
