import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import {Role} from '../../../../../../values/Role'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './roles-section.styled'

export function InternalRolesSelect(): JSX.Element {
    const internalRoles = [
        'APP',
        'GATEWAY',
        'NEBULA',
        'BEACON',
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
        'USER',
    ] as Role[]
    const {newUserData, setNewUserRole} = useUserManagement()

    return (
        <>
            {internalRoles.map((role, index) => (
                <Styled.DetailRow key={index}>
                    <Checkbox
                        id={`user-management-role_${role}`}
                        checked={newUserData.roles.includes(role)}
                        onChange={() => setNewUserRole(role, !newUserData.roles.includes(role))}
                        label={role}
                    />
                </Styled.DetailRow>
            ))}
        </>
    )
}
