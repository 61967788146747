import * as Style from './asset-creation-form.styled'
import {StringUtils} from '../../../utils/Utils'
import ERROR_ICON from '../../../@assets/icons/ot-inventory-form-error.svg'

export function FormError({error}: {error: string}) {
    // Don't render if there is no error
    if (!StringUtils.validString(error)) {
        return null
    }

    return (
        <Style.ErrorMessage>
            <img src={ERROR_ICON} />
            <div>{error}</div>
        </Style.ErrorMessage>
    )
}
