import {useRef, useState} from 'react'
import {VesselTag} from './vessel-tag'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../../../hooks/useOnClickOutside'
import {vesselTagsSelector} from '../../../../../../../store/state/vessel-tags/selectors'
import {useTagsComponent} from '../../../contexts/use-tags-component'
import {findRecommendationFromEntryTag} from '../../helper'
import {Checkbox} from './checkbox'
import * as Styled from './_styled/tag-search.styled'
import {ChevronUp, ChevronDown, MoreHorizontal} from 'react-feather'

export function TagSearch(): JSX.Element {
    const [searchTag, setSearchTag] = useState<string>('')
    const {searchedVesselTagTerm, setSearchVesselTerm, toggleVesselTag} = useTagsComponent()
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const [inputOpen, setInputOpen] = useState(false)
    const existingTags = useTypedSelector(vesselTagsSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchTag(e.currentTarget.innerText)
        setSearchVesselTerm(e.currentTarget.innerText)
    }

    function onDeleteClick(tagToDelete: string) {
        toggleVesselTag(existingTags, tagToDelete, !searchedVesselTagTerm.includes(tagToDelete))
    }

    const filteredUniqueResult =
        searchTag.length === 0
            ? existingTags
            : findRecommendationFromEntryTag(existingTags, searchTag)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setRecommendationOpen(false)
        setInputOpen(false)
    })

    const showInputDiv = searchedVesselTagTerm.length === 0 || inputOpen
    const showEllipsis = searchedVesselTagTerm.length >= 3 && !inputOpen

    return (
        <Styled.Container
            ref={clickOutside}
            onClick={() => {
                if (!inputOpen) {
                    setRecommendationOpen(true)
                    setInputOpen(true)
                }
            }}
            id="tags-search-wrapper-tags-widget"
        >
            <Styled.TagFilterWrapper inputOpen={inputOpen}>
                <Styled.Wrapper contentEditable={false}>
                    {searchedVesselTagTerm?.map((tag, index) => (
                        <VesselTag
                            key={index}
                            tagName={tag}
                            onDeleteFn={() => onDeleteClick(tag)}
                        />
                    ))}
                    {showInputDiv && (
                        <Styled.SearchInput
                            contentEditable
                            id="tags-search-input-tags-widget"
                            placeholder="Select tags or search..."
                            onInput={onChangeFn}
                        />
                    )}
                </Styled.Wrapper>
                <Styled.EllipsisIcon>
                    {showEllipsis && <MoreHorizontal height={16} width={16} />}
                </Styled.EllipsisIcon>
                <Styled.SearchIcon>
                    {inputOpen ? (
                        <ChevronUp
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(false)
                                setInputOpen(false)
                            }}
                            id="chevrop-up-tags-widget"
                        />
                    ) : (
                        <ChevronDown
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(true)
                                setInputOpen(true)
                            }}
                            id="chevrop-down-tags-widget"
                        />
                    )}
                </Styled.SearchIcon>
            </Styled.TagFilterWrapper>
            <Styled.TagResultsWrapper ref={clickOutside}>
                {recommendationOpen &&
                    (filteredUniqueResult?.length !== 0 ? (
                        <Styled.TagResultsDropdown id="TagResultsDropdown-tags-widget">
                            {filteredUniqueResult?.map((tag, index) => (
                                <Checkbox
                                    key={index}
                                    id={`filter-${tag.identity}`}
                                    checked={searchedVesselTagTerm.includes(tag.name)}
                                    onChange={() => {
                                        toggleVesselTag(
                                            existingTags,
                                            tag.name,
                                            !searchedVesselTagTerm.includes(tag.name),
                                        )
                                    }}
                                    tag={tag}
                                />
                            ))}
                        </Styled.TagResultsDropdown>
                    ) : (
                        <Styled.TagResultsDropdown
                            style={{textAlign: 'center'}}
                            id="TagResultsDropdown-tags-widget"
                        >
                            - No Tags Found -
                        </Styled.TagResultsDropdown>
                    ))}
            </Styled.TagResultsWrapper>
        </Styled.Container>
    )
}
