import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {useUserManagement} from '../../../context/use-user-management'
import {ButtonText} from '../../shared/row-actions/common/button-text.styled'
import {Button} from '../../shared/row-actions/common/button.styled'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'

interface ToggleDetailsButtonProps {
    id: GuidType
}

export function ToggleDetailsButton({id}: ToggleDetailsButtonProps): JSX.Element {
    const {showUserDetailModal, closeUserDetailModal, activeUserId} = useUserManagement()
    const isExpanded = id === activeUserId
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (isExpanded) {
            closeUserDetailModal()
        } else {
            showUserDetailModal(id)
            dispatch(
                logDetailsOpen(AuditLogPageType.USERS, AuditLogAuditType.DETAIL_PANEL_ACCESS, id),
            )
        }
    }

    return (
        <Button onClick={onClickWrapper} id={`view-details-button_${id}`}>
            <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            <ButtonText>See details here</ButtonText>
        </Button>
    )
}
