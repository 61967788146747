import {DetailPanelHeader} from './header/detail-panel-header'
import * as Styled from './_styled/user-details.styled'
import {useUserManagement} from '../../../context/use-user-management'
import {NoDataModal} from './no-data-modal'
import {DetailsSectionReadOnly} from './details/details-section-read-only'
import {RolesReadOnly} from './roles/roles-read-only'
import {SubscriptionsReadOnly} from './subscriptions/subscriptions-read-only'
import {HistorySection} from './history/history-section'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {fetchAuditLog} from '../../../../../store/state/audit-log/action-creators'
import {SavedFiltersAlertsReadOnly} from './saved-filters-alerts/saved-filters-alerts-read-only'

export function UserDetailsReadOnly(): JSX.Element | null {
    const {activeUserDetails} = useUserManagement()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAuditLog(activeUserDetails.user))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUserDetails.user])

    if (!activeUserDetails) {
        return <NoDataModal />
    }

    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader />
            <DetailsSectionReadOnly />
            <RolesReadOnly />
            {!activeUserDetails.internalUser && <SubscriptionsReadOnly />}
            <SavedFiltersAlertsReadOnly />
            <HistorySection />
        </Styled.Container>
    )
}
