import * as CommonStyles from '../_styled/common.styled'
import {SortOrder} from '../sort-order/sort-order'
import {SortDirection, SortField} from '../sort-order/values'
import {PageType} from '../../data-helper'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {vesselFilterSelector} from '../../../../store/state/vessel-filter/selectors'
import {onVesselSort} from '../../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'

interface SectionHeaderProps {
    sectionTitle: string
    groupName: string
    pageType: PageType
}

export function SectionHeader({
    sectionTitle,
    groupName,
    pageType,
}: SectionHeaderProps): JSX.Element {
    const dispatch = useDispatch()
    const filterState = useTypedSelector(vesselFilterSelector)
    const formattedGroupName = `${groupName.substring(0, groupName.indexOf('-'))}SortOrder`
    const sortOrder = filterState.vesselSort
        ? filterState.vesselSort
        : DEFAULT_VESSEL_FILTER_STATE.vesselSort
    const sortableFields: SortField[] = []
    sortableFields.push({key: 'vessel-name', value: 'Vessel Name'})
    sortableFields.push({key: 'vessel-threat-score', value: 'Threat Score'})

    function sortOrderVariableChanged(key: string): void {
        dispatch(onVesselSort(key, sortOrder.direction, PageType.MY_VESSELS))
    }

    function sortOrderDirectionChanged(direction: SortDirection): void {
        dispatch(onVesselSort(sortOrder.field, direction, PageType.MY_VESSELS))
    }
    return (
        <CommonStyles.SectionHeader id="vessels-filter-header">
            <CommonStyles.SectionTitle id="vessels-filter-sectionTitle">
                {sectionTitle}
            </CommonStyles.SectionTitle>
            {pageType === PageType.MY_VESSELS && (
                <SortOrder
                    id={groupName}
                    sortableFields={sortableFields}
                    sortOrder={sortOrder}
                    groupName={formattedGroupName}
                    sortOrderVariableChanged={sortOrderVariableChanged}
                    sortOrderDirectionChanged={sortOrderDirectionChanged}
                />
            )}
        </CommonStyles.SectionHeader>
    )
}
