import {Container} from './container.styled'
import {Scrollable} from './scrollable.styled'
import {Content2} from './content.styled'
import {Title} from './title.styled'

function prettifyJson(rawLog: string | null | undefined): string {
    if (!rawLog) {
        return ''
    }

    try {
        return JSON.stringify(JSON.parse(rawLog), null, 2)
    } catch {
        // eslint-disable-next-line no-console
        console.error('raw log data was not valid JSON so prettier did not work')

        return rawLog
    }
}

interface LogDataProps {
    logData: string | null | undefined
}

export function LogData({logData}: LogDataProps): JSX.Element {
    return (
        <Container>
            <Title>Log Data</Title>
            <Scrollable>
                <Content2>{prettifyJson(logData)}</Content2>
            </Scrollable>
        </Container>
    )
}
