import CommonEvent from '../../../../../../values/common-event/common-event'
import usedTypeSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {AssetOutputModel} from '../asset.model'
import {ThreatScore} from '../threat-score/threat-score'
import {CardTitle} from './styled/card-title.styled'
import {Card} from '../../common/cards/card.styled'
import {Details} from './styled/details.styled'
import {Types} from './styled/types.styled'
import {Type} from './styled/type.styled'
import {Label} from './styled/label.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {recordsSelector} from '../../../../../../store/state/network-anomaly-matrix/selectors'

interface AlertCardProps {
    commonEvent: CommonEvent
}

export function AlertCard({commonEvent}: AlertCardProps): JSX.Element {
    const threatMeasure = usedTypeSelector(threatMeasuresSelector)
    const node = usedTypeSelector(nodesSelector).get(commonEvent.node)
    const networkAnomalyMatrixMap = usedTypeSelector(recordsSelector)

    const model = new AssetOutputModel(commonEvent, threatMeasure, networkAnomalyMatrixMap, node)
    const {width} = useDimensions()

    return (
        <Card>
            <CardTitle>
                <span id={`alerts_${commonEvent.identity}_timestamp`}>
                    {model.formattedTimeStamp}
                </span>
                <ThreatScore
                    id={`alerts_${commonEvent.identity}_threatscore`}
                    score={model.score}
                    threatLevel={model.threatLevel}
                    roundedCorners={false}
                />
            </CardTitle>
            <Types width={width}>
                <Type width={width}>
                    <Label width={width}>Event:</Label>
                    <span id={`alerts_${commonEvent.identity}_event-type`}>{model.eventType}</span>
                </Type>
                <Type width={width} last>
                    <Label width={width}>Engine:</Label>
                    <span id={`alerts_${commonEvent.identity}_engine-type`}>
                        {model.engineType}
                    </span>
                </Type>
            </Types>
            <Details id={`alerts_${commonEvent.identity}_detail`}>{model.details}</Details>
        </Card>
    )
}
