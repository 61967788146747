import {useRef, useState} from 'react'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {vesselTagsSelector} from '../../../../../store/state/vessel-tags/selectors'
import * as Styled from './tag-search.styled'
import {ChevronDown, ChevronUp} from 'react-feather'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {VesselTag} from './vessel-tag'
import {findRecommendationFromEntryTag} from '../../../../../components/vessel-filters/data-helper'
import {useUserManagement} from '../../../context/use-user-management'
import {GuidType} from '../../../../../values/generic-type-defintions'

export function TagSearch(): JSX.Element {
    const [searchTag, setSearchTag] = useState<string>('')
    const {setAssignedTagId, activeUserDetails} = useUserManagement()
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const [inputOpen, setInputOpen] = useState(false)
    const existingTags = useTypedSelector(vesselTagsSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchTag(e.currentTarget.innerText)
    }

    function onDeleteClick() {
        setAssignedTagId(undefined)
        setSearchTag('')
    }

    const filteredUniqueResult =
        searchTag.length === 0
            ? existingTags
            : findRecommendationFromEntryTag(existingTags, searchTag)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setRecommendationOpen(false)
        setInputOpen(false)
    })

    function getTagName(tagId: GuidType | undefined): string {
        return existingTags.find((tags) => tags.identity === tagId)?.name ?? 'N/A'
    }

    return (
        <Styled.Container
            ref={clickOutside}
            onClick={() => {
                if (!inputOpen) {
                    setRecommendationOpen(true)
                    setInputOpen(true)
                }
            }}
        >
            <Styled.TagFilterWrapper inputOpen={inputOpen}>
                <Styled.Wrapper contentEditable={false}>
                    {activeUserDetails.assignedTag ? (
                        <VesselTag
                            tagName={getTagName(activeUserDetails.assignedTag)}
                            onDeleteFn={onDeleteClick}
                        />
                    ) : (
                        <Styled.SearchInput
                            contentEditable
                            id="tags-search"
                            placeholder="Select tag or search..."
                            onInput={onChangeFn}
                            onFocus={() => setRecommendationOpen(true)}
                        />
                    )}
                </Styled.Wrapper>
                <Styled.SearchIcon>
                    {inputOpen ? (
                        <ChevronUp
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(false)
                                setInputOpen(false)
                            }}
                        />
                    ) : (
                        <ChevronDown
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(true)
                                setInputOpen(true)
                            }}
                        />
                    )}
                </Styled.SearchIcon>
            </Styled.TagFilterWrapper>
            <Styled.TagResultsWrapper ref={clickOutside}>
                {recommendationOpen &&
                    (filteredUniqueResult?.length !== 0 ? (
                        <Styled.TagResultsDropdown>
                            {filteredUniqueResult?.map((tag) => (
                                <VesselTag
                                    key={tag.identity}
                                    tagName={tag.name}
                                    onClickFn={() => {
                                        setAssignedTagId(tag.identity)
                                        setRecommendationOpen(false)
                                        setInputOpen(false)
                                    }}
                                />
                            ))}
                        </Styled.TagResultsDropdown>
                    ) : (
                        <Styled.TagResultsDropdown style={{textAlign: 'center'}}>
                            - No Tags Found -
                        </Styled.TagResultsDropdown>
                    ))}
            </Styled.TagResultsWrapper>
        </Styled.Container>
    )
}
