import {createSelector} from 'reselect'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../../store/state/threat-measures/selectors'
import {NodeValues} from '../../../../../values/nodes/NodeData'
import {getScoreForNodes} from '../../../../../values/nodes/reselectors/helpers'

export const countAssetAtRiskReselector = createSelector(
    nodesSelector,
    sfmScoresSelector,
    threatMeasuresSelector,
    (nodes, sfmScores, threatMeasures): number => {
        const threatMeasure = threatMeasures.get(NodeValues.HIGH)

        let myAssetsAtRisk = 0
        nodes.forEach((node) => {
            if (
                threatMeasure &&
                getScoreForNodes(node, sfmScores) >= threatMeasure.lowerThreshold
            ) {
                myAssetsAtRisk++
            }
        })
        return myAssetsAtRisk
    },
)
