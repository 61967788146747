import styled from 'styled-components'
import {smallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'

export const NoVesselMessage = styled.div`
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.reports.dataRow.text};
    background-color: ${(props) => props.theme.vesselFilter.dataRow.background};
    margin: ${spacing(4)} 0;
    ${smallFont()}
`
