import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {useVesselConfig} from '../../contexts/use-vessel-config'
import {StyledButton} from '../shared/button.styled'
import {getGridType} from '../shared/grid-layout-helper'
import {ButtonWrapper} from './_styled/vessel-info.styled'

export function Buttons(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {fixedValue, data, saveResultMessage, isChanged, saveConfigChanges, resetChanges} =
        useVesselConfig()
    return (
        <ButtonWrapper gridLayout={getGridType(width)}>
            <StyledButton
                disabled={!isChanged && !saveResultMessage}
                onClick={() => saveConfigChanges(fixedValue, data, dispatch)}
            >
                Save
            </StyledButton>
            <StyledButton
                disabled={!isChanged && !saveResultMessage}
                onClick={() => resetChanges()}
            >
                Reset
            </StyledButton>
            <Link to="/vessels">
                <StyledButton>Back to List</StyledButton>
            </Link>
        </ButtonWrapper>
    )
}
