import {Location, LocationIdType} from '../../locations/state'
import {VesselTags} from '../../vessel-tags/state'
import {VesselFilter} from '../types/vessel-filter-state'

function filterVessel(location: Location, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(location.location)
}

export function filterVesselTags(
    vesselTag: VesselTags[] | undefined,
    searchTerm: string[],
): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

export function filterVesselName(vesselName: string, searchTerm: string): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return vesselName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

function compareRecord(a: Location, b: Location): number {
    return a.description.localeCompare(b.description)
}

export function filteredVesselIds(locations: Location[], filter: VesselFilter): string[] {
    return locations
        .filter((location) => filterVessel(location, filter.locations))
        .filter((location) => filterVesselTags(location.tags, filter.searchVesselTagTerm))
        .filter((location) => filterVesselName(location.description, filter.searchVesselNameTerm))
        .sort(compareRecord)
        .map((location) => location.location)
}

export function filteredVessels(
    locations: Location[],
    selectedLocations: Set<LocationIdType> | undefined,
    searchVesselNameTerm: string,
    searchVesselTagTerm: string[],
): Location[] {
    return locations
        .filter((location) => filterVessel(location, selectedLocations))
        .filter((location) => filterVesselTags(location.tags, searchVesselTagTerm))
        .filter((location) => filterVesselName(location.description, searchVesselNameTerm))
        .sort(compareRecord)
}
