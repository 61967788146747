import {DetailsSection} from '../../policy-list-table/policy-details-modal/incident-policy-details/details-section/details-section'
import {DetailPanelHeader} from '../../shared/header/detail-panel-header'
import {ReminderSection} from '../../policy-list-table/policy-details-modal/incident-policy-details/reminder-section/reminder-section'
import {AddNewButton} from '../../policy-list-table/policy-details-modal/incident-policy-details/save-button/add-new-button'
import {DetailsExpandedSection} from './incident-policy-detail-expanded.styled'

export function AddNewIncindentPolicyDetailExpanded(): JSX.Element {
    return (
        <DetailsExpandedSection>
            <DetailPanelHeader text="Add new incident policy" />
            <DetailsSection />
            <ReminderSection />
            <AddNewButton />
        </DetailsExpandedSection>
    )
}
