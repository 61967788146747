import {GridLayout} from '../../general/grid-layout.enum'
import {Content} from '../../general/content-area/headline-values/content.styled'
import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {WidgetType} from '../../../widget-type'

interface DisabledDataHeadlineValuesProps {
    gridLayout: GridLayout
    type: WidgetType
}
export function DisabledDataHeadlineValues({
    gridLayout,
    type,
}: DisabledDataHeadlineValuesProps): JSX.Element | null {
    return (
        <Content gridLayout={gridLayout}>
            {type === WidgetType.INCIDENT_RESPONSE ? (
                <>
                    <ValueForType
                        type="New"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="incident-response-headline-values-new"
                    />
                    <ValueForType
                        type="Open"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="incident-response-headline-values-open"
                    />
                    <ValueForType
                        type="Avg. Age"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="incident-response-headline-values-avg-age"
                    />
                </>
            ) : (
                <>
                    <ValueForType
                        type="24h"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="asset-discovery-24h"
                    />
                    <ValueForType
                        type="7d"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="asset-discovery-7d"
                    />
                    <ValueForType
                        type="30d"
                        value={'?'}
                        gridLayout={gridLayout}
                        id="asset-discovery-30d"
                    />
                </>
            )}
        </Content>
    )
}
