import styled from 'styled-components'
import {getHorizontalPagePadding} from '../../../theme/helpers/page-layout'
import {spacing} from '../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../theme/theme'
interface WideContentAreaProps {
    width: number
    fullHeight: boolean
}

export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    padding: 0 ${(props) => getHorizontalPagePadding(props.width)};
    margin: 0 auto;
    width: 100%;
    ${(props) => (props.fullHeight ? 'height: 100%;' : '')}
`
export const LHFilterBar = styled.div`
    padding: ${spacing(6)} 0;
    width: 388px;
    overflow-y: auto;
`

export const FilterBarWrapper = styled.div`
    min-width: 320px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin: ${spacing(12)} auto;
`

export const TopCloseFilterBar = styled.div`
    margin: 0 auto ${spacing(3)} auto;
`

export const BottomCloseFilterBar = styled.div`
    margin: ${spacing(3)} auto 0 auto;
`
