import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LinkIdPrefix} from '../../../../../../values/link-id-prefix'
import {StyledLink} from './styled-link.styled'
import {Text} from './text.styled'

interface DetailsButtonProps {
    linkIdPrefix: LinkIdPrefix
    linkTo: string
    onClick?: (() => void) | null
}

export function DetailsButton({
    linkTo,
    linkIdPrefix,
    onClick = null,
}: DetailsButtonProps): JSX.Element {
    function onClickWrapper() {
        if (onClick != null) {
            onClick()
        }
    }

    return (
        <StyledLink to={linkTo} id={`${linkIdPrefix}_details_link`} onClick={onClickWrapper}>
            <Icon glyph="Details" height={19} width={19} />
            <Text>Details</Text>
        </StyledLink>
    )
}
