import styled from 'styled-components'

interface ButtonProps {
    clickable?: boolean
}
export const Button = styled.button<ButtonProps>`
    border: none;
    background: none;
    color: white;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: ${(props) => (props.clickable ? 'pointer' : 'wait')};
    height: 100%;
    padding: 0 20px;
    width: 125px;
`
