import {PolicyType} from '../../../contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {AddNewEnforcementPolicyDetails} from './enforcement-policy-details/add-new-enforcement-policy-details'
import {AddNewIncidentPolicyDetails} from './incident-policy-details/add-new-incident-policy-details'
import {AddNewMetricsPolicyDetails} from './metrics-policy-details/add-new-metrics-policy-details'

export function AddNewPolicyDetailsModal(): JSX.Element {
    const {selectedPolicyType} = usePolicyManagement()
    switch (selectedPolicyType) {
        case PolicyType.INCIDENT:
            return <AddNewIncidentPolicyDetails />
        case PolicyType.METRICS:
            return <AddNewMetricsPolicyDetails />
        case PolicyType.ENFORCEMENT:
            return <AddNewEnforcementPolicyDetails />
        default:
            return <div />
    }
}
