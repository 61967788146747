import styled from 'styled-components'
import {extraSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
export const EditButton = styled.button`
    position: absolute;
    top: -5px;
    right: 0;
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    background-color: rgba(255, 255, 255, 1);
    border-radius: 2px;
    width: 70px;
    ${extraSmallFont()}
    padding: 0;
    overflow: hidden;
`

interface AddNoteBorderProps {
    isSaveDisabled: boolean
}
export const AddNoteBorder = styled.div<AddNoteBorderProps>`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    display: flex;
    flex: 1;
    width: 100%;
    opacity: ${(props) => (props.isSaveDisabled ? 0.5 : 1)};
`
export const SaveButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    height: 21px;
    ${extraSmallFont()}
    :disabled {
        opacity: 0.5;
    }
    :hover:not([disabled]) {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
export const AddNoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;
    padding-top: 5px;
`
export const ButtonWrapper = styled.div`
    display: flex;
    column-gap: ${spacing(2)};
`
export const CancelButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    ${extraSmallFont()}
    :disabled {
        opacity: 0.5;
    }
`
