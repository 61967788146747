import {MouseEvent} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styles from './button.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {narrowFilterLayout} from '../data-helpers'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {batch, useDispatch} from 'react-redux'
import {fetchLocations} from '../../../../store/state/locations/action-creators'
import {fetchCurrentUser} from '../../../../store/state/current-user/action-creators'
import {fetchUsers} from '../../../../store/state/users/action-creators'
import {fetchReportsFilter} from '../../../../store/state/reports-filter/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {savedReportsFiltersSelector} from '../../../../store/state/saved-filters/selectors'

export function RefreshButton(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {refreshData} = usePagedReports()
    const savedFilters = useTypedSelector(savedReportsFiltersSelector)

    function refreshFunction(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        refreshData()
        dispatch(fetchReportsFilter(savedFilters))
        batch(() => {
            dispatch(fetchLocations())
            dispatch(fetchCurrentUser())
            dispatch(fetchUsers())
        })
    }

    return (
        <Styles.Button
            id="refresh-button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => refreshFunction(e)}
        >
            <Icon glyph="Reset" height={16} width={16} />
            <Styles.Text id="refresh-text">
                {narrowFilterLayout(width) ? 'Refresh Data' : 'Refresh'}
            </Styles.Text>
        </Styles.Button>
    )
}
