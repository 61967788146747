import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {CardCodeCell} from '../../../../listing/components/cards/card-code-cell'
import {CardTitle} from '../../../../listing/components/cards/card-title'
import {Location} from '../../../../../../store/state/locations/state'
import * as Styled from './_styled/vessels-card.styled'
import {useToggle} from '../../../../../../contexts/toggle/use-toggle'
import {useVesselConfig} from '../../../contexts/use-vessel-config'
import {getCopyedConfig} from '../../../contexts/helper'

interface VesselsCardProps {
    location: Location
    currentType: string | undefined
    currentCustomerLocationCode: string | undefined
}

export function OtherVesselsListCard({
    location,
    currentType,
    currentCustomerLocationCode,
}: VesselsCardProps): JSX.Element {
    const {width} = useDimensions()
    const {bulkAssignConfig} = useVesselConfig()
    const {setValue: setModalOpen} = useToggle()
    const fixedWidthReducedText = width > 350
    const onClickWrapper = () => {
        bulkAssignConfig(getCopyedConfig(currentType, currentCustomerLocationCode, location))
        setModalOpen(false)
    }
    return (
        <Styled.Card onClick={onClickWrapper} id={`modal_location-card_${location.location}`}>
            <CardTitle location={location} />
            <Styled.CardDataRow>
                <CardCodeCell location={location} fixedWidthReducedText={fixedWidthReducedText} />
            </Styled.CardDataRow>
        </Styled.Card>
    )
}
