import {GuidType} from '../../../../values/generic-type-defintions'

export type OperationalStatus = Record<
    GuidType,
    {
        operating: boolean | null
        upgradeAssets:
            | {
                  identity: GuidType
                  alias?: string
              }[]
            | null
    }
>

export type TrafficStatus = Record<GuidType, string[]>

export type VesselDeploymentStatus = {
    operationalStatus: OperationalStatus | undefined
    trafficStatus: TrafficStatus | undefined
    vesselDashboard: Record<GuidType, boolean>
}

export type PayloadUnion = {
    [DeploymentType.Operational]: OperationalStatus
    [DeploymentType.Traffic]: TrafficStatus
}
export enum DeploymentType {
    Operational,
    Traffic,
}
