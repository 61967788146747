import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import * as Styled from './table-header-row.styled'
import {
    getNewSortByDirection,
    NmeaInventorySortType,
    SortColumnType,
} from '../../../contexts/types/nmea-inventory-sort-type'
import {usePagedNmeaInventory} from '../../../contexts/use-paged-nmea-inventory'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: NmeaInventorySortType
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const {isFilterLoading, sortColumn, setSortByColumn, closeNmeaDetailsModal} =
        usePagedNmeaInventory()

    function setSortBy(selectedColumn: NmeaInventorySortType) {
        const newSortColumn: SortColumnType = {
            orderAscending: getNewSortByDirection(selectedColumn, sortColumn),
            orderBy: selectedColumn,
        }
        closeNmeaDetailsModal()
        setSortByColumn(newSortColumn)
    }

    return (
        <Styled.TableHeaderCell
            id={`sortable-column_${colName}`}
            gridColumn={gridColumn}
            selectable
        >
            <Styled.TableHeaderText
                onClick={() => setSortBy(colName)}
                active={!isFilterLoading && sortColumn.orderBy === colName}
                id={`text_${colName}`}
            >
                {text}
                <Styled.IconStyled
                    arrowDec={sortColumn.orderBy === colName && !sortColumn.orderAscending}
                    id={`arrow-icon_${colName}`}
                >
                    {!isFilterLoading && <Icon width={12} height={17} glyph="Arrow" />}
                </Styled.IconStyled>
            </Styled.TableHeaderText>
        </Styled.TableHeaderCell>
    )
}
