import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {toggleAllNetworks} from '../../../../../store/state/network-assets-filter/action-creators'
import {HeadingRowType} from '../../../../../store/state/network-assets-filter/state'
import {ClearButton} from '../shared/clear-button/clear-button'
import {ViewAllRowWrapper} from './network-filter.styled'
interface Props {
    state: TriStateButtonState
    setState: (value: TriStateButtonState) => void
    showClearButton: boolean
}

export function ViewAllNetworkRow({state, setState, showClearButton}: Props): JSX.Element {
    return (
        <ViewAllRowWrapper>
            <TriStateButton
                id={`${PageType.NETWORK_ASSETS}_filter-bar_view-all_network`}
                label="View All"
                state={state}
                onClick={setState}
            />
            {showClearButton && (
                <ClearButton
                    clearFn={toggleAllNetworks}
                    filterType={HeadingRowType.FilterBarNetworks}
                />
            )}
        </ViewAllRowWrapper>
    )
}
