import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

export const ButtonWrapper = styled.div`
    display: flex;
    gap: ${spacing(3)};
    padding: ${spacing(1)};
`

export const ActionRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing(1)};
`

export const NumOfSelectedVesselMessage = styled.div`
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(1)};
`
