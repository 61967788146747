import styled from 'styled-components'
import {GridLayout} from '../grid-layout.enum'

interface HeaderProps {
    gridLayout: GridLayout
}

function getHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '38px'
        default:
            return '52px'
    }
}

export const Header = styled.header<HeaderProps>`
    display: flex;
    height: ${(props) => getHeight(props.gridLayout)};
    justify-content: flex-start;
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`
