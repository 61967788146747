import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'

import * as Styled from './status-of-incidents-raised.styled'
import {TotalNumberOfIncidentsByAssignments} from './total-number-of-incidents-by-assignments'
import {TotalNumberOfIncidentsByStatus} from './total-number-of-incidents-by-status'

export function StatusOfIncidentsRaised(): JSX.Element {
    const {width} = useDimensions()
    const {
        loadedIncidentsCounts,
        loadedIncidentsAssignments,
        showFilterBar,
        assignementsCounts,
        totalNumberOfItemsByStatus,
    } = usePagedIncidentsReport()
    const narrowLayoutForOpenFilter = showFilterBar === true

    return (
        <Styled.Section>
            <Styled.SectionText>Status of incidents raised</Styled.SectionText>

            <Styled.SectionContent
                width={width}
                narrowLayoutForOpenFilter={narrowLayoutForOpenFilter}
            >
                <Styled.StatusFilterContainer
                    width={width}
                    id="incidents-report-page-status-section-status-count-container"
                >
                    <Styled.SectionText>
                        CURRENT STATUS OF INCIDENTS RAISED IN REPORT PERIOD
                    </Styled.SectionText>
                    {loadedIncidentsCounts ? (
                        <TotalNumberOfIncidentsByStatus
                            totalNumberOfItemsByStatus={totalNumberOfItemsByStatus}
                        />
                    ) : (
                        <DataLoading />
                    )}
                </Styled.StatusFilterContainer>
                <Styled.StatusFilterContainer
                    width={width}
                    id="incidents-report-page-status-section-assignment-count-container"
                >
                    <Styled.SectionText>
                        CURRENT ASSIGNMENT OF NEW/OPEN INCIDENTS RAISED IN REPORT PERIOD
                    </Styled.SectionText>
                    {loadedIncidentsAssignments ? (
                        <TotalNumberOfIncidentsByAssignments
                            assignementsCounts={assignementsCounts}
                        />
                    ) : (
                        <DataLoading />
                    )}
                </Styled.StatusFilterContainer>
            </Styled.SectionContent>
        </Styled.Section>
    )
}
