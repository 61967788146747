import {GridLayout} from '../../general/grid-layout.enum'
import {Data} from '../../general/content-area/overview-data/data.styled'
import {Label} from '../../general/content-area/overview-data/label.styled'
import {Row} from '../../general/content-area/overview-data/row.styled'
import {TimeStamp} from '../../general/content-area/overview-data/time-stamp.styled'
import {Value} from '../../general/content-area/overview-data/value.styled'
import {NoValue} from '../../general/content-area/overview-data/no-value.styled'
import {WidgetOutputModel} from '../widget.model'
import {formatDate} from '../../../../../helpers/formatting'
import {Level} from '../../../../../values/location-level-enum'

interface IncidentDataProps {
    gridLayout: GridLayout
    model: WidgetOutputModel
}

export function IncidentData({gridLayout, model}: IncidentDataProps): JSX.Element {
    return (
        <Data gridLayout={gridLayout} id="incident-data-grid">
            <Row gridLayout={gridLayout} id="row-date-updated-info">
                <Label gridLayout={gridLayout}>Date Updated</Label>
                <Value id="value-date-updated">
                    {model.dateUpdated ? model.dateUpdated.fromNow() : 'Unknown'}
                    <TimeStamp gridLayout={gridLayout} id="timestamp-date-updated">
                        {model.dateUpdated && formatDate(model.dateUpdated)}
                    </TimeStamp>
                </Value>
            </Row>
            <Row
                gridLayout={gridLayout}
                deemphasize={model.level === Level.VESSEL}
                id="row-vessel-info"
            >
                <Label gridLayout={gridLayout}>Vessel</Label>
                <Value id="value-vessel-name">{model.vessel}</Value>
            </Row>
            <Row gridLayout={gridLayout} lastRow id="row-asset-info">
                <Label gridLayout={gridLayout}>Asset</Label>
                {model.asset && <Value id="value-asset-name">{model.asset}</Value>}
                {!model.asset && <NoValue>No Assets</NoValue>}
            </Row>
        </Data>
    )
}
