import {useEffect} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../../../templates/fixed-page/content-container-top.styled'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import {Container} from './container.styled'
import {FilterBarButton} from './filter-bar-button/filter-bar-button'
import {RefreshButtonUSB} from './refresh/refresh-button-usb'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../store/state/vessel-filter/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {useDispatch} from 'react-redux'
import {setVesselFilterPref} from '../../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {usbInventoryFilterSelector} from '../../../../store/state/usb-inventory-filter/selectors'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {GuidType} from '../../../../values/generic-type-defintions'
import {
    DEFAULT_USB_INVENTORY_FILTER,
    UsbInventoryFilter,
    UsbInventoryFilterCriteria,
} from '../../../../values/user-preferences/usb-inventory-filter'
import {setSelectedFilterName} from '../../../../store/state/usb-inventory-filter/action-creators'
import {savedUsbInventoryFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {compareFilters} from '../data-helpers'
import {getFormattedUsbInventoryFilter} from '../../../../components/saved-search/components/shared/helper'
import {GenerateReportButton} from '../../../../components/generate-report-button/generate-report-button/generate-report-button'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../store/state/users/state'
import {createLocationSelectionNumberReselector} from '../../../../store/state/vessel-filter/reselector/location-selection-number-reselector'
import AppState from '../../../../store/types/app-state'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {
        setFilter,
        modalIdForUSBDetails,
        createReport,
        reportUrl,
        showGenerateReportPopup,
        reportDialogShown,
    } = usePagedUSBDevices()

    const {
        searchedProductName,
        searchedType,
        hasPurpose,
        searchedVendor,
        sortColumn,
        searchedFirstDetected,
        searchedLastActive,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        selectedFilterName,
        selectedUsbDeviceStatus,
    } = useTypedSelector(usbInventoryFilterSelector)

    const newFilter = useTypedSelector(usbInventoryFilterSelector)
    const currentFilter = useTypedSelector(savedUsbInventoryFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria

    const queryString = useTypedSelector(queryStringObjectSelector)
    const queryId = queryString?.usbDetails as GuidType
    const externalGuid = modalIdForUSBDetails == null && queryId != undefined

    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.USB_DEVICES,
                ),
            )
        }
    }, [dispatch, searchVesselNameTerm, searchVesselTagTerm, locations, vesselFilterLoading])

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            setFilter(
                selectedUsbDeviceStatus,
                searchedFirstDetected,
                searchedLastActive,
                searchedVendor,
                searchedProductName,
                searchedType,
                hasPurpose,
                sortColumn,
                locations,
                searchVesselTagTerm,
                searchVesselNameTerm,
                externalGuid,
                queryId,
            )
        } else {
            compareFilters(
                currentFilter
                    ? (getFormattedUsbInventoryFilter(
                          currentFilter as UsbInventoryFilterCriteria,
                      ) as UsbInventoryFilter)
                    : DEFAULT_USB_INVENTORY_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))
            setFilter(
                selectedUsbDeviceStatus,
                searchedFirstDetected,
                searchedLastActive,
                searchedVendor,
                searchedProductName,
                searchedType,
                hasPurpose,
                sortColumn,
                vesselFilterLocations,
                vesselFilterTagTerm,
                vesselFilterNameTerm,
                externalGuid,
                queryId,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedUsbDeviceStatus,
        searchedFirstDetected,
        searchedLastActive,
        searchedVendor,
        searchedProductName,
        searchedType,
        hasPurpose,
        locations,
        sortColumn,
        searchVesselTagTerm,
        searchVesselNameTerm,
        vesselFilterLocations,
        vesselFilterTagTerm,
        vesselFilterNameTerm,
    ])
    const {strictMode} = useTypedSelector(currentUserSelector)

    const locationSelectionNumberReselector = createLocationSelectionNumberReselector(
        usbInventoryFilterSelector,
    )
    const state: AppState = useTypedSelector((state) => state)
    const selectedLocationNumber = locationSelectionNumberReselector(state)

    const moreThanOneVesselSelected = selectedLocationNumber > 1

    const isGenerateReportDisabled = strictMode === StrictMode.Strict && moreThanOneVesselSelected

    return (
        <ContentContainerTop width={width}>
            <Container id={`${PageType.USB_DEVICES}_data-actions-row`}>
                <FilterBarButton />
                <div style={{display: 'flex'}}>
                    <GenerateReportButton
                        createReport={createReport}
                        reportUrl={reportUrl}
                        showGenerateReportPopup={showGenerateReportPopup}
                        reportDialogShown={reportDialogShown}
                        pageType={PageType.USB_DEVICES}
                        isDisabled={isGenerateReportDisabled}
                    />
                    <RefreshButtonUSB />
                </div>
            </Container>
        </ContentContainerTop>
    )
}
