import styled from 'styled-components'
import {getBackgroundColorFromNode} from '../../../../../theme/helpers/node-title'
import {NodeData} from '../../../../../values/nodes/NodeData'

interface BoxProps {
    node: NodeData | undefined
}

export const Box = styled.div<BoxProps>`
    margin-left: 1px;
    flex: 1;
    align-items: 'center';
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 1.3125rem;
    line-height: 1.75rem;
    letter-spacing: 0.02rem;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: ${(props) => getBackgroundColorFromNode(props.node, props.theme)};
    justify-content: space-between;
    height: 100%;
`
