import {TextareaHTMLAttributes, useEffect, useRef, useState} from 'react'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {TextConfig} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'
import * as Styled from './_styled/text-config.styled'
import {isEqual} from 'lodash'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'

interface TextConfigItemProps {
    metricConfigId: GuidType
    configItem: TextConfig
    disabled?: boolean
}

export function TextConfigItem({
    metricConfigId,
    configItem,
    disabled = false,
}: TextConfigItemProps): JSX.Element {
    const initialTextValue = configItem.value ?? configItem.default
    const [textConfigValue, setTextValue] = useState(initialTextValue)
    const {changeTextConfigValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()

    useEffect(() => {
        if (discardChanges && !isEqual(initialTextValue, textConfigValue)) {
            setTextValue(initialTextValue)
            requestDiscardChanges(false)
        }
    }, [textConfigValue, discardChanges, initialTextValue, requestDiscardChanges])

    function onChangeWrapper(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        event.preventDefault()
        if (disabled) {
            return
        }
        const isItChanged = !isEqual(initialTextValue, event.target.value)
        setTextValue(event.target.value)
        changeTextConfigValue(metricConfigId, configItem.name, event.target.value, isItChanged)
    }

    return (
        <Styled.Wrapper id={metricConfigId} disabled={disabled}>
            <Styled.Label>{configItem.displayName ?? configItem.name}</Styled.Label>
            <Styled.Input>
                {configItem.enabled ? (
                    <EditableTextConfig
                        id={`text-config-entry_${metricConfigId}`}
                        value={textConfigValue}
                        onChange={onChangeWrapper}
                        disabled={disabled}
                    />
                ) : (
                    <Styled.Text>
                        {getLines(initialTextValue).map((line, index) => (
                            <div key={index}>{line}</div>
                        ))}
                    </Styled.Text>
                )}
            </Styled.Input>
        </Styled.Wrapper>
    )
}

function getLines(text: string | undefined | null): string[] {
    if (text == undefined) {
        return ['Unknown']
    }
    return text.split('\n')
}

function EditableTextConfig(props: TextareaHTMLAttributes<HTMLTextAreaElement>): JSX.Element {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        textAreaRef.current!.style.height = '0px'
        const scrollHeight = textAreaRef.current!.scrollHeight
        textAreaRef.current!.style.height = scrollHeight + 'px'
    }, [props.value])

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.disabled) {
            event.preventDefault()
            return
        }
        if (props.onChange) {
            props.onChange(event)
        }
    }
    return (
        <Styled.TextAreaWrapper>
            <Styled.TextArea {...props} ref={textAreaRef} rows={1} onChange={onChangeHandler}>
                {props.value}
            </Styled.TextArea>
        </Styled.TextAreaWrapper>
    )
}
