import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {narrowestLayout} from '../../cards/grid-helper'

interface Props {
    width: number
}

export const Options = styled.ul<Props>`
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: default;
    float: ${(props) => (props.width && narrowestLayout(props.width) ? 'left' : 'right')};
`

export const Option = styled.li`
    padding: ${spacing(1)} 0;
`

export const DropdownContentStyle = styled.div<Props>`
    background-color: ${(props) => props.theme.incidents.incidentStatusPopup.background};
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    margin: 0;
    border-radius: 2px;
    padding-right: ${(props) =>
        props.width && narrowestLayout(props.width) ? `${spacing(5)}` : `${spacing(7)}`};
    padding-left: ${(props) =>
        props.width && narrowestLayout(props.width) ? `${spacing(2)}` : '0'};
    width: ${(props) => (props.width && narrowestLayout(props.width) ? '105px;' : '125px;')};
    max-width: ${(props) =>
        props.width && narrowestLayout(props.width) ? 'calc(100% + 10px)' : '100%'};
    font-weight: ${(props) => props.theme.font.weight.normal};
    display: block;
    position: absolute;
    right: ${(props) => (props.width && narrowestLayout(props.width) ? '' : 'calc(50% - 50px)')};
    left: ${(props) => (props.width && narrowestLayout(props.width) ? '-10px' : '')};
    top: 32px;
    z-index: 1;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        cursor: default;
    }
`

export const Label = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
`

export const StatusButtonInput = styled.input`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 0;
    height: 0;
`
