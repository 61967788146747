import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import * as Styles from './reports.styled'
import {FilterBar} from './components/filter-bar/filter-bar'
import {Content} from './content'
import {showFilterBarOnScreen} from './components/data-helpers'
import {ToggleProvider} from '../../contexts/toggle/toggle-provider'
import {useToggle} from '../../contexts/toggle/use-toggle'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {CloseFilterButton} from './components/filter-bar/close-filter-button'
import {PagedReportsProvider} from './contexts/paged-reports-provider'
import LoadingState from '../../values/loading-state-enum'
import {usersLoadingStateSelector} from '../../store/state/users/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {fetchReportsFilter} from '../../store/state/reports-filter/action-creators'
import {reportsFilterLoadingStateSelector} from '../../store/state/reports-filter/selectors'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {StrictMode} from '../../store/state/users/state'
import {savedReportsFiltersSelector} from '../../store/state/saved-filters/selectors'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import {setSavedFilterPageType} from '../../store/state/saved-filters/action-creators'

export function Reports(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.REPORTS))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)
    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <ToggleProvider>
            <PagedReportsProvider pageSize={10}>
                <ReportsPage />
            </PagedReportsProvider>
        </ToggleProvider>
    )
}

function ReportsPage(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const {value: showFilter, setValue: setShowFilter} = useToggle()
    const showFilterBar = showFilterBarOnScreen(width)
    const reportsFilterLoadingState = useTypedSelector(reportsFilterLoadingStateSelector)
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedReportsFiltersSelector)

    useEffect(() => {
        dispatch(setPageType(PageType.REPORTS))
        dispatch(setSavedFilterPageType(SavedFilterPageType.REPORTS))
        dispatch(fetchReportsFilter(savedFilters, strictMode === StrictMode.DefaultVisibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedTag, dispatch, strictMode])

    useEffect(() => {
        if (showFilter) {
            if (showFilterBar) {
                setShowFilter(false)
            }
        }
    }, [setShowFilter, showFilter, showFilterBar])

    if (!showFilterBar && showFilter) {
        return (
            <ScrollablePageTemplate onClick={() => setShowFilter(false)}>
                <Styles.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-bar_wrapper"
                >
                    <Styles.TopCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.TopCloseFilterBar>
                    <FilterBar />
                    <Styles.BottomCloseFilterBar>
                        <CloseFilterButton />
                    </Styles.BottomCloseFilterBar>
                </Styles.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <FixedPageTemplate>
                <Styles.WideContentArea width={width} fullHeight={!showFilterBar}>
                    {showFilterBar && (
                        <Styles.LHFilterBar id="filter-bar_wrapper">
                            <FilterBar />
                        </Styles.LHFilterBar>
                    )}
                    {reportsFilterLoadingState === LoadingState.Loaded && <Content />}
                </Styles.WideContentArea>
            </FixedPageTemplate>
        )
    }
}
