import styled from 'styled-components'

export const SwitchToggle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${(props) => props.theme.font.weight.semibold};
`

interface ToggleButtonProps {
    selectable?: boolean
}
export const ToggleButton = styled.div<ToggleButtonProps>`
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
