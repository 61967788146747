import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import * as Styled from './_styled/card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/card-data-cell.styled'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    anomaly: FormattedAnomaly
    identifier: string
}
export function CardDataCell({
    label,
    children,
    gridArea,
    anomaly,
    identifier,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `network-assets-${identifier}_${anomaly.identity}`
    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            <Styled.Label htmlFor={id} id={`${label}-${id}`}>
                {label}
            </Styled.Label>
            <div id={`${children}-${id}`}>{children}</div>
        </Styled.CardDataCell>
    )
}
