import {getFormattedDuration} from '../../../data-helpers'
import * as Styled from './_styled/incident-details.styled'

interface ReopenDurationMessageProps {
    incidentReopenDuration: string
}

export function IncidentDetailsReopenDurationMessage({
    incidentReopenDuration,
}: ReopenDurationMessageProps): JSX.Element {
    return (
        <Styled.IncidentDetailsMessage id="incident-details-reopen-duration-message">
            <Styled.MessageTitle>Monitoring closed incident</Styled.MessageTitle>
            <Styled.Subtitle>
                This incident is in{' '}
                <Styled.Highlighted style={{color: 'green'}}>
                    CLOSED - Monitored{' '}
                </Styled.Highlighted>
                status: the incident will
                <Styled.Highlighted>
                    {' '}
                    reopen if any new activity occurs
                    <span style={{fontStyle: 'italic'}}>
                        {' '}
                        within {getFormattedDuration(incidentReopenDuration)}{' '}
                    </span>
                    on your watched items
                </Styled.Highlighted>{' '}
                below
            </Styled.Subtitle>
        </Styled.IncidentDetailsMessage>
    )
}
