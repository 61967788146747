import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {TimestampFilterType, formatTimestampCriteria} from './timestamp-filter'
import {NETWORK_ASSET_AGGREGATION_ENDPOINT} from './vessels-beta-endpoints'
export interface MissingInvetoryAllTypesResponse {
    locationId: string
    totalMissingAssets: number | undefined
}
export async function buildMissingInventoryAllTypes(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
    criteria: 'business' | 'ot',
): Promise<MissingInvetoryAllTypesResponse[]> {
    try {
        const totalAssetsPromise = await REST.post(
            `${NETWORK_ASSET_AGGREGATION_ENDPOINT}/find/summary`,
            {
                locations: locationIds,
                networks: [criteria],
                toRelativeLastSeen: formatTimestampCriteria(analysisPeriod),
            },
        )

        const findTotalAssets: {location: string; totalAssets: number}[] =
            totalAssetsPromise?.data as Array<{
                location: string
                totalAssets: number
            }>

        return findTotalAssets?.map((itemToFetch) => {
            return {
                locationId: itemToFetch.location,
                totalMissingAssets: itemToFetch?.totalAssets,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<MissingInvetoryAllTypesResponse>()
    }
}
