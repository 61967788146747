import styled from 'styled-components'
import {smallFont} from '../../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../../theme/spacing'

export const Container = styled.div`
    position: absolute;
    top: 34px;
    width: 260px;
    /* margin-bottom: ${spacing(2)}; */
    background-color: ${(props) => props.theme.colors.background.default};
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    padding: 1px;
`

export const EllipsisIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
`

export const SearchInput = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: ${spacing(1)};
    ${smallFont()}
    :empty:before {
        content: attr(placeholder);
        color: ${(props) => props.theme.colors.text.default};
    }
`

export const TagResultsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(2)};
`
export const TagResultsDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    gap: ${spacing(2)};
    padding: 3px;
    background: white;
    max-height: 140px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
`
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    border-radius: ${spacing(1)};
    gap: 3px;
    padding: 1px;
`

interface BigWrapperProps {
    inputOpen: boolean
}
export const TagFilterWrapper = styled.div<BigWrapperProps>`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    ${(props) => !props.inputOpen && 'overflow: hidden; height: 30px;'}
`
