import {ZoomLevel} from '../common/zoom-level/zoom-level'
import {ShowFilterButton} from './show-filter-button'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {showFilterBarOnScreen} from '../../helpers'
import * as Styles from './action-bar.styled'
import {RefreshButton} from './refresh-button'

export function ActionBar(): JSX.Element {
    const {width} = useDimensions()
    const showFilterBar = showFilterBarOnScreen(width)

    if (showFilterBar) {
        return (
            <Styles.Container rightAlign>
                <RefreshButton />
            </Styles.Container>
        )
    }

    return (
        <Styles.Container>
            <ZoomLevel />
            <Styles.Buttons>
                <ShowFilterButton />
                <RefreshButton />
            </Styles.Buttons>
        </Styles.Container>
    )
}
