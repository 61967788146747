import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    ${mediumSmallFont()};
`

export const ModalTitle = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding-top: ${spacing(1)};
    margin: 0;
`

export const DetailsSection = styled.div`
    position: relative;
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(2)};
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    background-color: ${(props) => props.theme.unknownAssets.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

export const ActionsTextLink = styled.a`
    ${mediumSmallFont()}
    padding: ${spacing(2)};
    color: ${(props) => props.theme.colors.link.primary};
`

export const ActionLabel = styled.label`
    display: flex;
    justify-content: flex-start;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    margin: 0;
`
