import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentHistory} from '../contexts/types/incident-history'
import {IncidentStatus} from '../contexts/types/incident-status'

export interface StatusChangeOutputModel {
    id: GuidType
    username: string
    oldStatus: IncidentStatus
    newStatus: IncidentStatus
    when: string
    isViewed: boolean
    isAutoReopened: boolean
}

export function wasStatusUpdated(record: IncidentHistory): boolean {
    return record.oldStatus != null && record.newStatus != null
}
