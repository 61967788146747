import * as Styled from './incident-history.styled'
import {GuidType} from '../../../../../values/generic-type-defintions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentsFilterSelector} from '../../../../../store/state/incidents-filter/selectors'
import {getSortedIncidentHistory} from '../../../data-helpers'
import {FilterDropDown} from '../filter-drop-down/filter-drop-down'
import {AddNote} from '../add-note/add-note'
import {IncidentHistoryOutputModel} from '../../../models/history-record.model'
import {IncidentHistoryRecord} from './incident-history-record'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {IncidentsDetailType} from '../../../contexts/types/incidents-details-type'
interface IncidentHistoryProps {
    history: IncidentHistoryOutputModel[]
    incidentIdentity: GuidType
    displayFormat: 'modal' | 'card'
}

export function IncidentHistory({
    history,
    incidentIdentity,
    displayFormat,
}: IncidentHistoryProps): JSX.Element {
    const {sortByNotes, detailExpanded} = useTypedSelector(incidentsFilterSelector)
    const sortedHistory = getSortedIncidentHistory(history, sortByNotes)

    return (
        <Styled.Wrapper id="incident-history-wrapper">
            <ExpandPanelButton headingRow={IncidentsDetailType.History} />
            {detailExpanded.historyExpanded && (
                <>
                    <FilterDropDown />
                    <Styled.TitleRowWrapper
                        noHistory={sortedHistory.length < 2}
                        id="incident-history-TitleRowWrapper"
                    >
                        {sortedHistory.map((record) => {
                            return (
                                <IncidentHistoryRecord
                                    key={record.id}
                                    record={record}
                                    incidentIdentity={incidentIdentity}
                                    displayFormat={displayFormat}
                                />
                            )
                        })}
                        <AddNote incidentIdentity={incidentIdentity} />
                    </Styled.TitleRowWrapper>
                </>
            )}
        </Styled.Wrapper>
    )
}
