import {useEffect} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {vesselsFilterLoadingStateSelector} from '../../../../store/state/vessel-filter/selectors'
import {vesselsBetaFilterSelector} from '../../../../store/state/vessels-beta-filter/selectors'
import {ContentContainerTop} from '../../../../templates/fixed-page/content-container-top.styled'
import LoadingState from '../../../../values/loading-state-enum'
import {Container} from './data-actions.styled'
import {FilterBarButtonWrapper} from './filter-bar-button-wrapper'
import {useDispatch} from 'react-redux'
import {setVesselFilterPref} from '../../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {usePagedVesselsBeta} from '../../contexts/use-paged-vessels-beta'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const {selectedColumns, analysisPeriod, locations, searchVesselTagTerm, searchVesselNameTerm} =
        useTypedSelector(vesselsBetaFilterSelector)

    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)
    const isNotPopulated = vesselFilterLoading === LoadingState.NotPopulated
    const dispatch = useDispatch()
    const {loadDataFromUserPrefs} = usePagedVesselsBeta()

    useEffect(() => {
        if (isNotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.VESSELS_BETA,
                ),
            )
        }
    }, [dispatch, searchVesselNameTerm, searchVesselTagTerm, locations, vesselFilterLoading])

    useEffect(() => {
        loadDataFromUserPrefs(selectedColumns, analysisPeriod)
    }, [])

    return (
        <ContentContainerTop width={width}>
            <Container id="data-actions-container">
                <FilterBarButtonWrapper />
            </Container>
        </ContentContainerTop>
    )
}
