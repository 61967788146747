import {useRef, DragEvent, ChangeEvent, useState} from 'react'
import * as Styled from './drag-and-drop-file.styled'
import {useFileUpload} from './use-file-upload'
import {ProcessingStatus} from '../../../../../values/processing-save-state-enum'
import {Button} from '../../../../../components/base/button'
import {ExcelIcon} from '../../excel-icon'

// spreadhseet mime and csv
const acceptedTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
]

export function NativeDragAndDrop(): JSX.Element {
    const {uploadingStatus, uploadFile, setFileValue, setServerMessage} = useFileUpload()
    const [isDragOver, setIsDragOver] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const isSelectFileDisabled = uploadingStatus === ProcessingStatus.PROCESSING

    const validateFileTypeAndSize = (file: File): boolean => {
        if (!acceptedTypes.includes(file.type)) {
            setServerMessage({
                type: 'error',
                message:
                    "The file format isn't supported. Only xls, xlsx, and csv files are allowed.",
            })
            return false
        }
        if (file.size > 10485760) {
            setServerMessage({
                type: 'error',
                message: 'File size exceeds limit. Please try again',
            })
            return false
        }
        return true
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (isSelectFileDisabled) {
            return
        }
        setIsDragOver(false)
        const droppedFiles = Array.from(event.dataTransfer.files)

        if (droppedFiles.length > 0) {
            const file = droppedFiles[0]
            if (!validateFileTypeAndSize(file)) {
                return
            }
            setFileValue(file)
            uploadFile(file)
        }
    }

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(true)
    }

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setIsDragOver(false)
    }

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(event.target.files || [])
        if (selectedFiles.length > 0) {
            const file = selectedFiles[0]
            if (!validateFileTypeAndSize(file)) {
                return
            }
            setFileValue(file)
            uploadFile(file)
        }
    }

    const openFileBrowser = () => {
        if (!isSelectFileDisabled) {
            fileInputRef.current?.click()
        }
    }

    // This is required to make selecting the same file again work
    const clearFileInput = () => {
        setFileValue(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    return (
        <Styled.DragAndDropWrapper
            isDragOver={isDragOver}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            aria-disabled={isSelectFileDisabled}
        >
            <ExcelIcon disabled={isSelectFileDisabled} />
            <Styled.DragAndDropBox>
                <Styled.SectionTitle aria-disabled={isSelectFileDisabled}>
                    Drag and drop file to upload
                </Styled.SectionTitle>
                <Styled.SectionDivider>or</Styled.SectionDivider>
                <input
                    type="file"
                    onChange={handleFileSelect}
                    onClick={clearFileInput}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                    disabled={isSelectFileDisabled}
                    accept=".xlsx,.xls,.csv"
                />
            </Styled.DragAndDropBox>

            <div>
                <Button onClick={openFileBrowser} size="lg" disabled={isSelectFileDisabled}>
                    Select file to upload
                </Button>
                <Styled.SizeWarning>(Max. 10MB)</Styled.SizeWarning>
            </div>
        </Styled.DragAndDropWrapper>
    )
}
