import {
    findMetricCategory,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import {FrameworkSummaryTargetResponse} from '../../../contexts/types/framework-summary-target-api'
import * as Styled from './framework-summary-target.styled'
interface FrameworkSummaryTargetProps {
    findTargetSummaryData: FrameworkSummaryTargetResponse
}
export function FrameworkSummaryTarget({
    findTargetSummaryData,
}: FrameworkSummaryTargetProps): JSX.Element {
    function getTargetPerformanceComparision(selectedPerformance: number | undefined): string {
        return !selectedPerformance ? '' : selectedPerformance >= 0 ? 'green' : 'red'
    }
    return (
        <Styled.ContentWrapper
            id={`framework-summary-target-wrapper-${findTargetSummaryData.locationId}`}
        >
            <Styled.TargetBarWrapper>
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.PROTECTION))}
                    style={{width: '30px', height: '30px'}}
                    id={`metricTypeIcon-protection-${findTargetSummaryData.locationId}-${findTargetSummaryData.protectionTargetPerformance}`}
                />
                <Styled.TargetBar
                    colourBar={getTargetPerformanceComparision(
                        findTargetSummaryData.protectionTargetPerformance,
                    )}
                    id={`colourBar-protection-${findTargetSummaryData.locationId}-${findTargetSummaryData.protectionTargetPerformance}`}
                />
            </Styled.TargetBarWrapper>
            <Styled.TargetBarWrapper>
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.MAINTENANCE))}
                    style={{width: '30px', height: '30px'}}
                    id={`metricTypeIcon-maintenance-${findTargetSummaryData.locationId}-${findTargetSummaryData.maintenanceTargetPerformance}`}
                />
                <Styled.TargetBar
                    colourBar={getTargetPerformanceComparision(
                        findTargetSummaryData.maintenanceTargetPerformance,
                    )}
                    id={`colourBar-maintenance-${findTargetSummaryData.locationId}-${findTargetSummaryData.maintenanceTargetPerformance}`}
                />
            </Styled.TargetBarWrapper>
            <Styled.TargetBarWrapper>
                <img
                    src={getMetricTypeIcon(findMetricCategory(MetricType.BEHAVIOUR))}
                    style={{width: '30px', height: '30px'}}
                    id={`metricTypeIcon-behaviour-${findTargetSummaryData.locationId}-${findTargetSummaryData.behaviourTargetPerformance}`}
                />
                <Styled.TargetBar
                    colourBar={getTargetPerformanceComparision(
                        findTargetSummaryData.behaviourTargetPerformance,
                    )}
                    id={`colourBar-behaviour-${findTargetSummaryData.locationId}-${findTargetSummaryData.behaviourTargetPerformance}`}
                />
            </Styled.TargetBarWrapper>
        </Styled.ContentWrapper>
    )
}
