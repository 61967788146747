import styled from 'styled-components'
import {smallFont, mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'
import {OnboardEngagementConfigType} from '../../../../../contexts/metrics-policy/type/metrics-policy-schema'
import {CyberOwlTheme} from '../../../../../../../theme/theme'

interface ContainerProps {
    disabled?: boolean
}

export const Wrapper = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)} 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`
export const Line = styled.span`
    height: 5px;
    width: 170px;
    background-color: #1f88e5;
`

interface SubTitleProps {
    positionOfText: OnboardEngagementConfigType
}

export const SubTitle = styled.div<SubTitleProps>`
    position: absolute;
    top: 10px;
    ${smallFont()}
    left: ${(props) => props.positionOfText !== 'max' && getPositionOfText(props.positionOfText)};
    right: ${(props) => props.positionOfText === 'max' && '-2px'};
`

function getPositionOfText(positionOfText: OnboardEngagementConfigType) {
    return positionOfText === 'off'
        ? 0
        : positionOfText === 'on'
          ? '38px'
          : positionOfText === 'master'
            ? '68px'
            : '110px'
}

export const CircleBox = styled.div`
    display: flex;
    position: relative;
`

interface RatingButtonProps {
    selected: boolean
    isHovering: boolean
}

function getBackgroundColor(theme: CyberOwlTheme, selected: boolean, isHovering: boolean): string {
    if (!selected) {
        return theme.reports.starRating.unSelected.background
    }
    return isHovering
        ? theme.reports.starRating.hover.background
        : theme.reports.starRating.selected.background
}

export const RatingButton = styled.button<RatingButtonProps>`
    padding: 0;
    cursor: pointer;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin-right: ${spacing(2)};

    background-color: ${(props) =>
        getBackgroundColor(props.theme, props.selected, props.isHovering)};

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-left: 0.5px;
    }
`
