import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {WidgetWrapper} from './widget-wrapper'
import {NoDataContent} from '../../general/content-area/no-data/no-data-content'
import {Level} from '../../../../../values/location-level-enum'

interface NoDataProps {
    level: Level
}

export function NoData({level}: NoDataProps): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)

    return (
        <WidgetWrapper level={level} gridLayout={gridLayout} noData={true}>
            <NoDataContent text="No incidents as of" />
        </WidgetWrapper>
    )
}
