import styled from 'styled-components'

export const Loading = styled.div`
    padding: 0;
    height: 400px;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.myVessels.filterBar.background};
    box-shadow: ${(props) => props.theme.myVessels.filterBar.boxShadow};
`

export const FilterBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    background-color: ${(props) => props.theme.myVessels.filterBar.background};
    box-shadow: ${(props) => props.theme.myVessels.filterBar.boxShadow};
`
