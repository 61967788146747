import {MouseEvent, ReactNode, ReactNodeArray} from 'react'
import {NotSelectedTab, SelectedTab} from './tab-section.styled'
import {ContentPageType} from '../../../../../contexts/metrics-policy/type/content-type'
import {useMetricsPolicy} from '../../../../../contexts/metrics-policy/use-metrics-policy'

interface TabProps {
    activeTab: ContentPageType
    index: ContentPageType
    children: ReactNode | ReactNodeArray
    toggle: (event: MouseEvent, tab: ContentPageType) => void
}
export function Tab({activeTab, index, children, toggle}: TabProps): JSX.Element {
    const {isMetricsUpdated} = useMetricsPolicy()
    if (activeTab === index) {
        return <SelectedTab>{children}</SelectedTab>
    }

    return (
        <NotSelectedTab onClick={(e: MouseEvent) => toggle(e, index)} isChanged={isMetricsUpdated}>
            {children}
        </NotSelectedTab>
    )
}
