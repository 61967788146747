import {IncidentTypeText, IncidentTypeWrapper} from './incident-type.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {incidentTypesSelector} from '../../../../../store/state/incident-types/selectors'
import {getMatchingIcon, getTypeDisplayName} from '../../../contexts/types/incident-type'
import {getIncidentTypeIcon} from '../../../data-helpers'

interface IncidentTypeProps {
    incidentType: string
}

export function IncidentType({incidentType}: IncidentTypeProps): JSX.Element {
    const incidentTypes = useTypedSelector(incidentTypesSelector)
    const anomalousBehaviour = incidentType === 'ANOMALOUS_BEHAVIOUR'
    const maliciousFiles = incidentType === 'POTENTIALLY_MALICIOUS_FILE'
    return (
        <IncidentTypeWrapper>
            <img
                src={getIncidentTypeIcon(getMatchingIcon(incidentTypes, incidentType))}
                height={16}
                width={16}
            />
            {anomalousBehaviour ? (
                <>
                    <IncidentTypeText>Anomalous</IncidentTypeText>
                    <IncidentTypeText>Behaviour</IncidentTypeText>
                </>
            ) : maliciousFiles ? (
                <>
                    <IncidentTypeText>Potentially</IncidentTypeText>
                    <IncidentTypeText>Malicious File</IncidentTypeText>
                </>
            ) : (
                <IncidentTypeText>
                    {getTypeDisplayName(incidentTypes, incidentType)}
                </IncidentTypeText>
            )}
        </IncidentTypeWrapper>
    )
}
