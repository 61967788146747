import {CloseButton} from './close-button'
import * as Styled from './data-area.styled'

export function ChangeSaved(): JSX.Element {
    return (
        <>
            <Styled.Text>Saved successfully</Styled.Text>
            <Styled.Actions>
                <CloseButton />
            </Styled.Actions>
        </>
    )
}
