import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../store/state/email-recipient-management/selectors'
import {StrictMode, User} from '../../../../store/state/users/state'
import {useUserManagement} from '../../context/use-user-management'
import {CardDataCell} from './card-data-cell'
import {ToggleButtonCell} from './details/toggle-button-cell'
import {ToggleDetailsButton} from './details/toggle-details-button'
import {UserDetailsExpanded} from './user-details-expanded/user-details-expanded'
import * as Styled from './_styled/user-management-card.styled'
import {UserManagementDetails} from './_styled/user-management-details.styled'
import {vesselTagsSelector} from '../../../../store/state/vessel-tags/selectors'
import {LockImage} from '../shared/tag-limit/tag-limit.styled'
import LOCKED from '../../../../@assets/icons/locked.svg'
import UNLOCKED from '../../../../@assets/icons/unlocked.svg'
import {getTagName} from '../../../../utils/Utils'

interface UserListCardsProps {
    user: User
}

export function UserListCard({user}: UserListCardsProps): JSX.Element {
    const {width} = useDimensions()
    const subscriptionData = useTypedSelector(emailRecipientDataSelector)
    const {activeUserId} = useUserManagement()
    const expandUserDetails = activeUserId === user.user
    const existingTags = useTypedSelector(vesselTagsSelector)

    const locked = user.assignedTag && user.strictMode === StrictMode.Strict
    const unlocked = user.assignedTag && user.strictMode === StrictMode.DefaultVisibility

    return (
        <UserManagementDetails activeUser={expandUserDetails}>
            <Styled.Card width={width}>
                <Styled.CardTitleData gridArea="NAME">
                    <div id={`user-management-name_${user.user}`}>{user.username}</div>
                </Styled.CardTitleData>
                <CardDataCell gridArea="EMAIL" label="Email:" user={user} identifier="email">
                    {user.email}
                </CardDataCell>
                <CardDataCell gridArea="ROLES" label="Role:" user={user} identifier="role">
                    {user.roles.toString()}
                </CardDataCell>
                <CardDataCell gridArea="TAG" label="Tag:" user={user} identifier="tag">
                    <Styled.TagWrapper>
                        {locked && <LockImage src={LOCKED} />}
                        {unlocked && <LockImage src={UNLOCKED} />}
                        {getTagName(existingTags, user.assignedTag) ?? 'N/A'}
                    </Styled.TagWrapper>
                </CardDataCell>
                <CardDataCell
                    gridArea="SUBSCRIPTIONS"
                    label="Subscriptions:"
                    user={user}
                    identifier="subscriptions"
                >
                    {subscriptionData.find(
                        (data) =>
                            data.user === user.user && data.notificationType === 'NEW_INCIDENT',
                    )
                        ? 'Incidents'
                        : 'None'}
                </CardDataCell>
                <ToggleButtonCell gridArea="VIEW-DETAIL">
                    <ToggleDetailsButton id={user.user} />
                </ToggleButtonCell>
            </Styled.Card>
            {expandUserDetails && <UserDetailsExpanded />}
        </UserManagementDetails>
    )
}
