import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

function getColumnTemplate(width: number): string {
    if (width < 700) {
        return 'repeat(2, [col-start] 1fr);'
    }
    return 'repeat(3, [col-start] 1fr);'
}
export const Card = styled.div`
    color: ${(props) => props.theme.softwareInventory.dataRow.text};
    background-color: ${(props) => props.theme.softwareInventory.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    ${mediumSmallFont()};
`
interface CardAreaProps {
    width: number
}
export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getTitleGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`
interface CardCustomerTitleDataProps {
    gridArea: string
}
export const CardCustomerTitleData = styled.h2<CardCustomerTitleDataProps>`
    display: flex;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`
interface CardProductTitleDataProps {
    gridArea: string
}
export const CardProductTitleData = styled.h2<CardProductTitleDataProps>`
    display: flex;
    margin: 0px;
    align-items: flex-start;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`

function getGridTemplate(width: number): string {
    if (width < 700) {
        return "'WHEN LAST-ACTIVE' 'NEWEST-VERSION OLDEST-VERSION' 'NUMBER-HOSTS NUMBER-VESSELS' 'EMPTY DOWNLOAD'"
    }
    return "'WHEN NEWEST-VERSION NUMBER-HOSTS' 'LAST-ACTIVE OLDEST-VERSION NUMBER-VESSELS' 'EMPTY EMPTY DOWNLOAD'"
}

function getTitleGridTemplate(width: number): string {
    if (width < 700) {
        return "'COMPANY-TITLE PRODUCT-TITLE'"
    }
    return "'COMPANY-TITLE EMPTY-TITLE PRODUCT-TITLE'"
}

export const LinkText = styled.a`
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const AttachmentButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`
