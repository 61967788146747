import {Location, LocationIdType, Network, OTSource} from '../../../../store/state/locations/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {LocationInIsolationMode} from './location-in-isolation.mode'
import {OperationalStatus, TrafficStatus, VesselDeploymentStatus} from './deployment-status-types'
import LoadingState from '../../../../values/loading-state-enum'
import {OTSourceModalDetails} from './ot-sources-modal-details'
import {LicenseSummery} from './license-types'

export interface VesselConfigModal {
    networkConfiguration: Network | undefined
    vesselDashboardAgentIds: string[] | undefined
    vesselEmail: string | null | undefined
    incidentPolicy: {id: GuidType | undefined; locations: LocationIdType[] | undefined}
    metricsPolicy: {id: GuidType | undefined; locations: LocationIdType[] | undefined}
    enforcementPolicy: {id: GuidType | undefined; locations: LocationIdType[] | undefined}
    otSources: OTSource | undefined
}

export const DEFAULT_VESSEL_CONFIG_MODAL = {
    networkConfiguration: undefined,
    vesselDashboardAgentIds: undefined,
    vesselEmail: undefined,
    incidentPolicy: {id: undefined, locations: undefined},
    metricsPolicy: {id: undefined, locations: undefined},
    enforcementPolicy: {id: undefined, locations: undefined},
    otSources: undefined,
} as VesselConfigModal

export interface VesselManagementState {
    activeVesselId: GuidType | null
    initialValue: VesselConfigModal
    displayValue: VesselConfigModal
    updatedValue: VesselConfigModal
    isValidBusinessNetworkChange: boolean
    isValidOTNetworkChange: boolean
    isValidCrewNetworkChange: boolean
    isOnboardAgentIdChanged: boolean
    isIncidentPolicyChanged: boolean
    isMetricsPolicyChanged: boolean
    isEnforcementPolicyChanged: boolean
    isValidEmailChange: boolean
    isValidChange: boolean
    detailExpanded: DetailExpandedType
    locationsInIsolationMode: LocationInIsolationMode[] | undefined
    hasActiveControl: boolean
    selectedIncidentPolicy: GuidType | undefined
    selectedMetricsPolicy: GuidType | undefined
    selectedEnforcementPolicy: GuidType | undefined
    trafficStatus: TrafficStatus | undefined
    operationalStatus: OperationalStatus | undefined
    showOTSourceModalDisplay: boolean
    oTSourceModalDetails: OTSourceModalDetails
    showFilterBar: boolean
    filteredLocations: Location[]
    deploymentOperationalLoadingState: LoadingState
    deploymentTrafficLoadingState: LoadingState
    vesselDeploymentLoadingState: LoadingState
    vesselDeploymentStatus: VesselDeploymentStatus
    policyLicenseSummery?: LicenseSummery
}

export interface DetailExpandedType {
    networkSettingsExpanded: boolean
    otSourcesExpanded: boolean
    commsSettingsExpanded: boolean
    policySettingsExpanded: boolean
    vesselTaggingExpanded: boolean
}

export enum VesselManagementDetailType {
    NetworkSettings = 'Network Settings',
    OTSources = 'OT Sources',
    CommsSettings = 'Comms Settings',
    PolicySettings = 'Policy Settings',
    VesselTagging = 'Vessel Tagging',
    DeploymentStatus = 'Deployment status',
}
