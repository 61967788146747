import styled from 'styled-components'
import checkboxSVG from '../../../../../../../../@assets/icons/checkmark.svg'
import {spacing} from '../../../../../../../../theme/spacing'

export const Label = styled.label`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: ${spacing(1)};
`

export const StyledCheckbox = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    background: white;
    border: 1px solid #ccc;
`

export const CheckboxInput = styled.input`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 19px;
    height: 19px;
    margin-right: 5px;
    &:hover ~ ${StyledCheckbox} {
        border-color: #1f88e5;
    }
    &:checked + ${StyledCheckbox} {
        border: none;
        background-color: white;
        content: url('${checkboxSVG}');
    }
`
