import styled from 'styled-components'
import {smallFont, extraSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'
import {IncidentStatus} from '../../../incidents-v3/contexts/types/incident-status'
import {getIncidentsStatusColour} from '../status-of-incidents-raised/incidents-count.styled'

interface SquareProps {
    incidentStatus: IncidentStatus
}

export const Square = styled.span<SquareProps>`
    color: white;
    background-color: ${(props) => getIncidentsStatusColour(props.incidentStatus, props.theme)};
    height: ${spacing(5.5)};
    width: ${spacing(12)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const StatusAnnotation = styled.div`
    ${extraSmallFont()}
    font-style: italic;
`
