import {ItemRow} from './item-row'
import {ItemListWrapper, NoItemsText} from './items-list.styled'
import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'

export function LinkedIncidentItemsList(): JSX.Element {
    const {linkedItemsData, isFetchingItems, findIncident} = usePagedIncidents()

    if (findIncident?.links?.length === 0) {
        return <NoItemsText>No items</NoItemsText>
    }
    const notWatchedItemsData = linkedItemsData.filter((item) => item.monitored === false)
    const scrollable = notWatchedItemsData?.length > 3

    return (
        <>
            {isFetchingItems ? (
                <DataLoading size={40} />
            ) : (
                <ItemListWrapper scrollable={scrollable} id="allItems-monitored-wrapper">
                    {notWatchedItemsData.map((item, index) => (
                        <ItemRow
                            key={index}
                            item={item}
                            lastRow={
                                scrollable
                                    ? index === linkedItemsData?.length - 1 ||
                                      index === linkedItemsData?.length - 2
                                    : false
                            }
                            link
                        />
                    ))}
                </ItemListWrapper>
            )}
        </>
    )
}
