import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {VesselListCard} from './vessel-list-card'
import * as Styled from './_styled/vessel-management-cards.styled'
import {Location} from '../../../../store/state/locations/state'

interface VesselListCardsProps {
    locations: Location[]
}

export function VesselListCards({locations}: VesselListCardsProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.CardArea width={width}>
            {locations.map((location, index) => (
                <VesselListCard key={index} location={location} />
            ))}
        </Styled.CardArea>
    )
}
