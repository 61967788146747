import styled from 'styled-components'

export const Scrollable = styled.div`
    background: ${(props) => props.theme.modalDialog.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 10px 20px 20px 20px;
    padding: 10px 15px 0 15px;
`
