import {ActionType} from './action-type'
import * as Actions from './actions'
import {HighlightedFilerValueType} from '../types/highlighted-filter-type'
import {
    IncidentAssignmentsCounts,
    IncidentAvgPerformanceType,
    IncidentCountsType,
    IncidentTrendsType,
    IncidentVesselsPerformanceType,
} from '../types/incidents-report-output'
import {IncidentResponse} from '../../../incidents-v3/contexts/types/incident-response'
import {GuidType} from '../../../../values/generic-type-defintions'

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION}
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function setFilter(): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER}
}

export function setHighlightedFilterValue(
    value: HighlightedFilerValueType,
): Actions.SetHighlightedFilterValueAction {
    return {
        type: ActionType.SET_HIGHLIGHTED_FILTER_VALUE,
        payload: value,
    }
}

export function setSavedFilterDropdown(value: boolean): Actions.SetActiveSavedFilterDropdownAction {
    return {
        type: ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN,
        payload: value,
    }
}

export function receivedRequestedDetailsData(
    incidentsDetails: IncidentResponse[],
): Actions.ReceivedRequestedDetailsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_DETAILS_DATA_ACTION,
        payload: incidentsDetails,
    }
}

export function receivedRequestedCountsData(
    incidentsCounts: IncidentCountsType,
): Actions.ReceivedRequestedCountsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_COUNTS_DATA_ACTION,
        payload: incidentsCounts,
    }
}
export function receivedRequestedAssignmentsData(
    assignementsCounts: IncidentAssignmentsCounts,
): Actions.ReceivedRequestedAssignmentsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_ASSIGNMENTS_DATA_ACTION,
        payload: assignementsCounts,
    }
}

export function receivedRequestedTrendsData(
    incidentsTrends: IncidentTrendsType[],
): Actions.ReceivedRequestedTrendsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_TRENDS_DATA_ACTION,
        payload: incidentsTrends,
    }
}

export function receivedRequestedAvgPerformanceData(
    incidentsAvgPerformance: IncidentAvgPerformanceType[],
): Actions.ReceivedRequestedAvgPerformanceDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_AVG_PERFORMANCE_DATA_ACTION,
        payload: incidentsAvgPerformance,
    }
}

export function displayIncidentDetailsExpandedAction(
    incidentDetailExpandedId: GuidType,
): Actions.DisplayIncidentDetailsExpandedAction {
    return {
        type: ActionType.DISPLAY_INCIDENT_DETAILS_EXPANDED,
        payload: incidentDetailExpandedId,
    }
}

export function receivedRequestedVesselsPerformanceData(
    incidentsVesselsPerformance: IncidentVesselsPerformanceType,
): Actions.ReceivedRequestedVesselsPerformanceDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_INCIDENTS_VESSELS_PERFORMANCE_DATA_ACTION,
        payload: incidentsVesselsPerformance,
    }
}

export function setReportCreate(payload: string): Actions.ReportCreateAction {
    return {
        type: ActionType.REPORT_CREATE,
        payload,
    }
}
export function showGenerateReportPopup(show: boolean): Actions.ShowGenerateReportPopup {
    return {
        type: ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP,
        payload: show,
    }
}
