import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {filteredNodeIdsForLocationCachedReselector} from '../../reselectors/filtered-node-ids-for-location-cached-reselector'
import {AssetThreatScore} from './asset-threat-score'
import * as Styles from './level-3-and-4-asset-threat-scores-grid.styled'

interface Props {
    id: LocationIdType
}

export function AssetThreatScoresGrid({id}: Props): JSX.Element | null {
    const ids = useTypedSelector((state) => filteredNodeIdsForLocationCachedReselector(state, id))

    return (
        <Styles.AssetsGrid id={`assets-threat-score-grid-${id}`}>
            {ids &&
                ids.map((assetId) => (
                    <Styles.AssetsCell key={`asset-threat-score-cell-${id}-${assetId}`}>
                        <AssetThreatScore id={assetId} showScore />
                    </Styles.AssetsCell>
                ))}
        </Styles.AssetsGrid>
    )
}
