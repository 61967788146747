import {CustomerReports} from '../../../../../values/customer-reports'
import * as Styles from '../../shared/report-actions/reports-actions.styled'
import {ToggleReportDetailsButton} from '../../shared/report-actions/toggle-reports-details-button'
import {CardDataCell} from '../card-data-cell'

interface ReportsActionsProps {
    report: CustomerReports
}
export function ReportsActionsCard({report}: ReportsActionsProps): JSX.Element {
    return (
        <CardDataCell gridArea="REPORT-NOTES" label="" report={report} identifier="report-notes">
            <Styles.ReportsActionsStyled>
                {report.notes && <ToggleReportDetailsButton id={report.identity} />}
            </Styles.ReportsActionsStyled>
        </CardDataCell>
    )
}
