import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {
    SavedIncidentsFilter,
    SavedFilterPageType,
    SavedNetworkAssetsFilter,
    SavedUsbInventoryFilter,
    SavedSoftwareInventoryFilter,
    SearchType,
    AllSavedFilterType,
    SavedFilterSubscription,
    TemplateDefinitions,
    UpdatedSubscriptionValue,
    SavedMetricsBetaFilter,
    SavedReportsFilter,
    SavedIncidentsReportFilter,
} from './state'

export const savedFiltersLoadingStateSelector = (state: AppState): LoadingState =>
    state.savedFilters.loadingState

export const savedFiltersLoadingSelector = (state: AppState): boolean =>
    state.savedFilters.loadingState === LoadingState.RequestingData ||
    state.savedFilters.loadingState === LoadingState.NotPopulated

export const pageTypeSelector = (state: AppState): SavedFilterPageType =>
    state.savedFilters.pageType

export const savedFiltersNameArraySelector = (state: AppState): string[] => {
    const filters = {
        [SavedFilterPageType.INCIDENTS]: state.savedFilters.savedIncidentsFilter,
        [SavedFilterPageType.NETWORK_ASSETS]: state.savedFilters.savedNetworkAssetsFilter,
        [SavedFilterPageType.USB_INVENTORY]: state.savedFilters.savedUsbInventoryFilter,
        [SavedFilterPageType.SOFTWARE_INVENTORY]: state.savedFilters.savedSoftwareInventoryFilter,
        [SavedFilterPageType.METRICS]: state.savedFilters.savedMetricsBetaFilter,
        [SavedFilterPageType.REPORTS]: state.savedFilters.savedReportsFilter,
        [SavedFilterPageType.INCIDENTS_REPORT]: state.savedFilters.savedIncidentsReportFilter,
    }

    return filters[state.savedFilters.pageType]?.map((filter) => filter.name) ?? []
}
export const resultMessageSelector = (state: AppState): string | undefined =>
    state.savedFilters.resultMessage

export const isSaveFilterPopupOpenSelector = (state: AppState): boolean =>
    state.savedFilters.isSaveFilterPopupOpen

export const savedIncidentsFiltersSelector = (state: AppState): SavedIncidentsFilter[] =>
    state.savedFilters.savedIncidentsFilter

export const savedMetricsBetaFiltersSelector = (state: AppState): SavedMetricsBetaFilter[] =>
    state.savedFilters.savedMetricsBetaFilter

export const savedNetworkAssetsFiltersSelector = (state: AppState): SavedNetworkAssetsFilter[] =>
    state.savedFilters.savedNetworkAssetsFilter

export const savedUsbInventoryFiltersSelector = (state: AppState): SavedUsbInventoryFilter[] =>
    state.savedFilters.savedUsbInventoryFilter

export const savedSoftwareInventoryFiltersSelector = (
    state: AppState,
): SavedSoftwareInventoryFilter[] => state.savedFilters.savedSoftwareInventoryFilter

export const savedReportsFiltersSelector = (state: AppState): SavedReportsFilter[] =>
    state.savedFilters.savedReportsFilter

export const savedIncidentsReportFiltersSelector = (
    state: AppState,
): SavedIncidentsReportFilter[] => state.savedFilters.savedIncidentsReportFilter

const getFilteredAndSortedFilters = <T extends {searchType: SearchType}>(
    filters: T[],
): {[key in SearchType]: T[]} => {
    return {
        [SearchType.SYSTEM]: filters.filter((e) => e.searchType === SearchType.SYSTEM),
        [SearchType.SHARED]: filters.filter((e) => e.searchType === SearchType.SHARED),
        [SearchType.INDIVIDUAL]: filters.filter((e) => e.searchType === SearchType.INDIVIDUAL),
    }
}

const createSortedFiltersSelector =
    <T extends {searchType: SearchType}>(baseSelector: (state: AppState) => T[]) =>
    (state: AppState): T[] => {
        const filteredAndSorted = getFilteredAndSortedFilters(baseSelector(state))
        return [
            ...filteredAndSorted[SearchType.SYSTEM],
            ...filteredAndSorted[SearchType.SHARED],
            ...filteredAndSorted[SearchType.INDIVIDUAL],
        ]
    }

export const sortedSavedIncidentsFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedIncidentsFilter,
)

export const sortedSavedMetricsBetaFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedMetricsBetaFilter,
)

export const sortedSavedNetworkAssetsFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedNetworkAssetsFilter,
)

export const sortedSavedUsbInventoryFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedUsbInventoryFilter,
)

export const sortedSavedSoftwareInventoryFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedSoftwareInventoryFilter,
)

export const sortedSavedReportsFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedReportsFilter,
)

export const sortedSavedIncidentsReportFiltersSelector = createSortedFiltersSelector(
    (state: AppState) => state.savedFilters.savedIncidentsReportFilter,
)

export const isInactiveSelector = (state: AppState): boolean => state.savedFilters.isInactive

export const isNotifyMePopupOpenSelector = (state: AppState): boolean =>
    state.savedFilters.isNotifyMePopupopen

export const currentAppliedSavedFilterSelector = (
    state: AppState,
): AllSavedFilterType | undefined => state.savedFilters.currentAppliedSavedFilter

export const allUserSubscriptionsSelector = (
    state: AppState,
): SavedFilterSubscription[] | undefined => state.savedFilters.allUserSubscriptions

export const userSubscriptionsSelector = (state: AppState): SavedFilterSubscription[] | undefined =>
    state.savedFilters.userSubscriptions

export const newSubscriptionSelector = (state: AppState): UpdatedSubscriptionValue =>
    state.savedFilters.newSubscription

export const templateDefinitionsSelector = (state: AppState): TemplateDefinitions[] | undefined =>
    state.savedFilters.templateDefinitions
