import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {FirstDetectedFilter} from './timestamp-filter/first-seen-filter'
import {LastActiveFilter} from './timestamp-filter/last-active-filter'
import {ProductNameFilter} from './product-name-filter/product-name-filter'
import {ResetFilterButton} from './reset-filter/reset-filter-button'
import {CompanyNameFilter} from './company-name-filter/company-name-filter'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {softwareInventoryFilterLoadingSelector} from '../../../../store/state/software-inventory-filter/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {SoftwareInventoryStatusFilter} from './software-inventory-status-filter/software-inventory-status-filter'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {displayFilterBar} = usePagedSoftwareInventory()
    const isLoadingFilter = useTypedSelector(softwareInventoryFilterLoadingSelector)
    const isInactive = useTypedSelector(isInactiveSelector)

    if (isLoadingFilter) {
        return (
            <Styles.Loading>
                <LoadingSpinner />
            </Styles.Loading>
        )
    }
    return (
        <Styles.FilterBarWrapper width={width} id={`${PageType.SOFTWARE_INVENTORY}_filter-bar`}>
            <div
                id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_close-icon`}
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(false)
                }}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styles.FilterBarStyled>
                <FilterBarHeader />
                <Styles.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.SOFTWARE_INVENTORY} />
                </Styles.SavedFilterContent>
                <Styles.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_section-content-1`}
                >
                    <FirstDetectedFilter />
                    <LastActiveFilter />
                    <CompanyNameFilter />
                    <ProductNameFilter />
                </Styles.SectionContent>
                <Styles.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.SOFTWARE_INVENTORY}_filter-bar_section-content-2`}
                >
                    <SoftwareInventoryStatusFilter />
                </Styles.SectionContent>
                <VesselFilters pageType={PageType.SOFTWARE_INVENTORY} />
                <ResetFilterButton />
            </Styles.FilterBarStyled>
        </Styles.FilterBarWrapper>
    )
}
