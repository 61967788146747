import {GridLayout} from '../../general/grid-layout.enum'
import {Content} from '../../general/content-area/headline-values/content.styled'
import {NewIncidents} from './new-incidents'
import {OpenIncidents} from './open-incidents'
import {AverageAgeIncidents} from './average-age-incidents'

interface HeadlineValuesProps {
    gridLayout: GridLayout
}
export function HeadlineValues({gridLayout}: HeadlineValuesProps): JSX.Element {
    return (
        <Content gridLayout={gridLayout} id="incident-response-HeadlineValues">
            <NewIncidents gridLayout={gridLayout} />
            <OpenIncidents gridLayout={gridLayout} />
            <AverageAgeIncidents gridLayout={gridLayout} />
        </Content>
    )
}
