import type {OTAsset} from '../../../store/state/ot-assets/state'
import {assetTableConfig} from './asset-table.config'
import * as AssetTableStyle from './asset-table.styled'
import * as Style from './asset-table-expanded-row.styled'
import {StyledTippy} from './vessel-card.styled'
import type {DefaultTheme} from 'styled-components'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'

export function AssetTableExpandedRow({otAsset}: {otAsset: OTAsset}) {
    // Comma separated list of physical interfaces
    const physicalInterfaces = otAsset.physicalInterfaces.map((item) => item.name).join(', ')

    const {width} = useDimensions()
    const isGridDisplay = width > 765

    const customFields = otAsset.customFields?.map((customField) => {
        return (
            <Style.CustomFieldWrapper
                key={otAsset.id + '-custom-field-' + customField.name}
                isGridDisplay={isGridDisplay}
            >
                <Style.CustomFieldLabel>{customField.name}:</Style.CustomFieldLabel>
                <Style.CustomFieldValue>{customField.value}</Style.CustomFieldValue>
            </Style.CustomFieldWrapper>
        )
    })

    const customFieldsDisplay =
        otAsset.customFields.length === 0 ? null : (
            <Style.CustomFieldsDisplay>
                <hr />
                <Style.CustomFieldsHeader>Additional Fields</Style.CustomFieldsHeader>
                <Style.CustomFieldList isGridDisplay={isGridDisplay}>
                    {customFields}
                </Style.CustomFieldList>
            </Style.CustomFieldsDisplay>
        )

    return (
        <AssetTableStyle.RowDetail>
            <AssetTableStyle.Cell colSpan={assetTableConfig.length}>
                <Style.Content>
                    <Style.Data>
                        <Style.Label>Manufacturer/Brand:</Style.Label>
                        <StyledTippy
                            content={otAsset.vendor}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.vendor}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Physical interface(s):</Style.Label>

                        <StyledTippy
                            content={physicalInterfaces}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{physicalInterfaces}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Version and patch version of system software:</Style.Label>

                        <StyledTippy
                            content={otAsset.softwareVersion}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.softwareVersion}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Model:</Style.Label>

                        <StyledTippy
                            content={otAsset.model}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.model}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Name/Type of system software:</Style.Label>

                        <StyledTippy
                            content={otAsset.softwareName}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.softwareName}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Supported communication protocol:</Style.Label>

                        <StyledTippy
                            content={otAsset.communicationProtocolName}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.communicationProtocolName}</Style.Value>
                        </StyledTippy>
                    </Style.Data>

                    <Style.Data>
                        <Style.Label>Description:</Style.Label>

                        <StyledTippy
                            content={otAsset.description}
                            placement="bottom-start"
                            arrow
                            theme={'light-border' as unknown as DefaultTheme}
                        >
                            <Style.Value>{otAsset.description}</Style.Value>
                        </StyledTippy>
                    </Style.Data>
                </Style.Content>
                {customFieldsDisplay}
            </AssetTableStyle.Cell>
        </AssetTableStyle.RowDetail>
    )
}
