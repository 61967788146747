import {useEffect, useRef, useState} from 'react'
import * as Styled from './_styled/filter-search.styled'
import {ChevronDown, ChevronUp} from 'react-feather'
import {useOnClickOutside} from '../../../hooks/useOnClickOutside'
import {useDispatch} from 'react-redux'
import {setVesselFilterPref} from '../../../store/state/vessel-filter/action-creators'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    sortedSavedIncidentsFiltersSelector,
    sortedSavedMetricsBetaFiltersSelector,
    sortedSavedNetworkAssetsFiltersSelector,
    sortedSavedReportsFiltersSelector,
    sortedSavedIncidentsReportFiltersSelector,
    sortedSavedSoftwareInventoryFiltersSelector,
    sortedSavedUsbInventoryFiltersSelector,
} from '../../../store/state/saved-filters/selectors'
import {
    SavedFilterPageType,
    SavedIncidentsFilter,
    SavedIncidentsReportFilter,
    SavedMetricsBetaFilter,
    SavedNetworkAssetsFilter,
    SavedReportsFilter,
    SavedSoftwareInventoryFilter,
    SavedUsbInventoryFilter,
} from '../../../store/state/saved-filters/state'
import * as IncidentsAction from '../../../store/state/incidents-filter/action-creators'
import * as NetworkAssetsAction from '../../../store/state/network-assets-filter/action-creators'
import * as UsbInventoryAction from '../../../store/state/usb-inventory-filter/action-creators'
import * as SoftwareInventoryAction from '../../../store/state/software-inventory-filter/action-creators'
import * as MetricsBetaAction from '../../../store/state/metrics-filter-beta/action-creators'
import * as ReportsAction from '../../../store/state/reports-filter/action-creators'
import * as IncidentsReportAction from '../../../store/state/incidents-report-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {pageTypeSelector, vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {SavedFilterRow} from './saved-filter-row'
import {
    setCurrentAppliedSavedFilter,
    setInactiveComponent,
} from '../../../store/state/saved-filters/action-creators'
import {
    NetworkAssetsFilter,
    NetworkAssetsFilterCriteria,
} from '../../../values/user-preferences/network-assets-filter'
import {
    UsbInventoryFilter,
    UsbInventoryFilterCriteria,
} from '../../../values/user-preferences/usb-inventory-filter'
import {
    SoftwareInventoryFilter,
    SoftwareInventoryFilterCriteria,
} from '../../../values/user-preferences/software-inventory-filter'
import {logSavedFilterUsage} from '../../../store/state/audit-log/action-creators'
import {AuditLogAuditType} from '../../../store/state/audit-log/state'
import {
    getAuditPageType,
    getFormattedIncidentsFilter,
    getFormattedIncidentsReportFilter,
    getFormattedMetricsBetaFilter,
    getFormattedNetworkAssetsFilter,
    getFormattedReportsFilter,
    getFormattedSoftwareInventoryFilter,
    getFormattedUsbInventoryFilter,
} from './shared/helper'
import {
    IncidentsFilter,
    IncidentsFilterCriteria,
} from '../../../values/user-preferences/incidents-filter'
import {incidentsFilterSelector} from '../../../store/state/incidents-filter/selectors'
import {networkAssetsModalDetailExpandedSelector} from '../../../store/state/network-assets-filter/selectors'
import {usbModalDetailExpandedSelector} from '../../../store/state/usb-inventory-filter/selectors'
import {softwareModalDetailExpandedSelector} from '../../../store/state/software-inventory-filter/selectors'
import {MetricsBetaFilter} from '../../../store/state/metrics-filter-beta/state'
import {ReportsFilter, ReportsFilterCriteria} from '../../../values/user-preferences/reports-filter'
import {IncidentsReportFilter} from '../../../store/state/incidents-report-filter/state'

interface FilterSearchProp {
    selectedFilterName: string
    pageType: SavedFilterPageType
}
export function FilterSearch({selectedFilterName, pageType}: FilterSearchProp): JSX.Element {
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const dispatch = useDispatch()
    const savedIncidentsFilters = useTypedSelector(sortedSavedIncidentsFiltersSelector)
    const savedNetworkAssetsFilters = useTypedSelector(sortedSavedNetworkAssetsFiltersSelector)
    const savedUsbInventoryFilters = useTypedSelector(sortedSavedUsbInventoryFiltersSelector)
    const savedSoftwareInventoryFilters = useTypedSelector(
        sortedSavedSoftwareInventoryFiltersSelector,
    )
    const savedMetricsBetaFilters = useTypedSelector(sortedSavedMetricsBetaFiltersSelector)
    const savedReportsFilters = useTypedSelector(sortedSavedReportsFiltersSelector)
    const savedIncidentsReportFilters = useTypedSelector(sortedSavedIncidentsReportFiltersSelector)

    const currentVesselFilter = useTypedSelector(vesselFilterSelector)
    const pageTypeVesselFilter = useTypedSelector(pageTypeSelector)
    const {sortByNotes, detailExpanded} = useTypedSelector(incidentsFilterSelector)
    const networkAssetsModalDetailExpanded = useTypedSelector(
        networkAssetsModalDetailExpandedSelector,
    )
    const usbModalDetailExpanded = useTypedSelector(usbModalDetailExpandedSelector)
    const softwareModalDetailExpanded = useTypedSelector(softwareModalDetailExpandedSelector)
    function getSavedFilters(
        pageType: SavedFilterPageType,
    ):
        | SavedIncidentsFilter[]
        | SavedNetworkAssetsFilter[]
        | SavedUsbInventoryFilter[]
        | SavedSoftwareInventoryFilter[]
        | SavedMetricsBetaFilter[]
        | SavedIncidentsReportFilter[]
        | SavedReportsFilter[] {
        switch (pageType) {
            case SavedFilterPageType.INCIDENTS:
                return savedIncidentsFilters
            case SavedFilterPageType.NETWORK_ASSETS:
                return savedNetworkAssetsFilters
            case SavedFilterPageType.USB_INVENTORY:
                return savedUsbInventoryFilters
            case SavedFilterPageType.SOFTWARE_INVENTORY:
                return savedSoftwareInventoryFilters
            case SavedFilterPageType.METRICS:
                return savedMetricsBetaFilters
            case SavedFilterPageType.REPORTS:
                return savedReportsFilters
            case SavedFilterPageType.INCIDENTS_REPORT:
                return savedIncidentsReportFilters
            default:
                return savedNetworkAssetsFilters
        }
    }
    const savedFilters = getSavedFilters(pageType)

    useEffect(() => {
        dispatch(setInactiveComponent(recommendationOpen))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendationOpen])

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setRecommendationOpen(false)
    })

    const onClickWrapper = (
        filter:
            | SavedIncidentsFilter
            | SavedNetworkAssetsFilter
            | SavedUsbInventoryFilter
            | SavedSoftwareInventoryFilter
            | SavedMetricsBetaFilter
            | SavedReportsFilter
            | SavedIncidentsReportFilter,
    ) => {
        if (pageType === SavedFilterPageType.INCIDENTS) {
            dispatch(IncidentsAction.setSelectedFilterName(filter.name))
            dispatch(
                IncidentsAction.bulkAssignSavedFilter(
                    getFormattedIncidentsFilter(
                        filter.criteria as IncidentsFilterCriteria,
                    ) as IncidentsFilter,
                    filter.name,
                    sortByNotes,
                    detailExpanded,
                    true,
                    filter as SavedIncidentsFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.NETWORK_ASSETS) {
            dispatch(NetworkAssetsAction.setSelectedFilterName(filter.name))
            dispatch(
                NetworkAssetsAction.bulkAssignSavedFilter(
                    getFormattedNetworkAssetsFilter(
                        filter.criteria as NetworkAssetsFilterCriteria,
                    ) as NetworkAssetsFilter,
                    filter.name,
                    networkAssetsModalDetailExpanded,
                    true,
                    filter as SavedNetworkAssetsFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.USB_INVENTORY) {
            dispatch(UsbInventoryAction.setSelectedFilterName(filter.name))
            dispatch(
                UsbInventoryAction.bulkAssignSavedFilter(
                    getFormattedUsbInventoryFilter(
                        filter.criteria as UsbInventoryFilterCriteria,
                    ) as UsbInventoryFilter,
                    filter.name,
                    usbModalDetailExpanded,
                    true,
                    filter as SavedUsbInventoryFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.SOFTWARE_INVENTORY) {
            dispatch(SoftwareInventoryAction.setSelectedFilterName(filter.name))
            dispatch(
                SoftwareInventoryAction.bulkAssignSavedFilter(
                    getFormattedSoftwareInventoryFilter(
                        filter.criteria as SoftwareInventoryFilterCriteria,
                    ) as SoftwareInventoryFilter,
                    filter.name,
                    softwareModalDetailExpanded,
                    true,
                    filter as SavedSoftwareInventoryFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.METRICS) {
            dispatch(MetricsBetaAction.setSelectedFilterName(filter.name))
            dispatch(
                MetricsBetaAction.bulkAssignSavedFilter(
                    getFormattedMetricsBetaFilter(filter.criteria as MetricsBetaFilter),
                    filter.name,
                    true,
                    filter as SavedMetricsBetaFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.REPORTS) {
            dispatch(ReportsAction.setSelectedFilterName(filter.name))
            dispatch(
                ReportsAction.bulkAssignSavedFilter(
                    getFormattedReportsFilter(
                        filter.criteria as ReportsFilterCriteria,
                    ) as ReportsFilter,
                    filter.name,
                    true,
                    filter as SavedReportsFilter,
                ),
            )
        } else if (pageType === SavedFilterPageType.INCIDENTS_REPORT) {
            dispatch(IncidentsReportAction.setSelectedFilterName(filter.name))
            dispatch(
                IncidentsReportAction.bulkAssignSavedFilter(
                    getFormattedIncidentsReportFilter(
                        filter.criteria as IncidentsReportFilter,
                    ) as IncidentsReportFilter,
                    filter.name,
                    true,
                    filter as SavedIncidentsReportFilter,
                ),
            )
        }
        dispatch(
            logSavedFilterUsage(getAuditPageType(pageType), AuditLogAuditType.SELECT_SAVED_FILTER),
        )
        dispatch(
            setVesselFilterPref(
                {
                    ...DEFAULT_VESSEL_FILTER_STATE,
                    locations: currentVesselFilter.locations,
                    searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                    searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                },
                pageTypeVesselFilter,
            ),
        )
        setRecommendationOpen(false)
        dispatch(setCurrentAppliedSavedFilter(filter))
    }

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.FilterSearchWrapper
                onClick={() => {
                    setRecommendationOpen(!recommendationOpen)
                }}
                id={`recommendation-section_${pageType}`}
            >
                <Styled.SearchInput id="selected-filter-name">
                    {selectedFilterName}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {recommendationOpen ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.FilterSearchWrapper>
            <Styled.SavedFiltersWrapper ref={clickOutside}>
                {recommendationOpen &&
                    (savedFilters?.length !== 0 ? (
                        <Styled.SavedFiltersDropdown>
                            {savedFilters?.map((filter, index) => (
                                <SavedFilterRow
                                    key={index}
                                    filter={filter}
                                    onClickFn={() => onClickWrapper(filter)}
                                    lastRow={getLastRow(index, savedFilters.length)}
                                    pageType={pageType}
                                />
                            ))}
                        </Styled.SavedFiltersDropdown>
                    ) : (
                        <Styled.NoSavedFiltersMessage id="no-saved-filters">
                            - No Saved Filters Found -
                        </Styled.NoSavedFiltersMessage>
                    ))}
            </Styled.SavedFiltersWrapper>
        </Styled.Container>
    )
}
function getLastRow(index: number, searchLength: number): boolean {
    if (index === 0) {
        return false
    } else if (index === searchLength - 1 || index === searchLength - 2) {
        return true
    } else {
        return false
    }
}
