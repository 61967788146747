import styled from 'styled-components'
import {smallFont} from '../../../theme/font-styles'
import {spacing} from '../../../theme/spacing'

export const RecommendationContainer = styled.span`
    display: flex;
    width: 232px;
    position: absolute;
    overflow: visible;
    top: 0;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(2)};
    background-color: rgba(194, 226, 255, 1);
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-align: center;
    padding: 2px;
    z-index: 10;
`
