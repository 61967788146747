import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {BREAK_POINT} from '../../vessels.styled'

interface HeaderStyleProps {
    width: number
}

export const Header = styled.div<HeaderStyleProps>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.width > BREAK_POINT ? 'row' : 'column')};
`
export const PageTitle = styled.div<HeaderStyleProps>`
    font-size: 20px;
    line-height: 27px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const RefreshArea = styled.div<HeaderStyleProps>`
    display: flex;
    padding-top: ${(props) => (props.width > BREAK_POINT ? 0 : spacing(2))};
    flex-direction: ${(props) => (props.width > BREAK_POINT ? 'column' : 'row')};
    justify-content: space-between;
    row-gap: ${spacing(2)};
`
