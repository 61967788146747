import {VesselDetails} from '../vessel-list/vessel-details/vessel-details'
import {VesselList} from '../vessel-list/_styled/vessel-list.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../../store/state/locations/selectors'
import {latestLocationThreatScoreSelector} from '../../../../../../store/state/latest-location-threat-scores/selectors'
import {getLocationScore} from '../../../../../../store/state/locations/helpers'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {LatestLocationThreatScores} from '../../../../../../store/state/latest-location-threat-scores/state'
import {Location} from '../../../../../../store/state/locations/state'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'
interface SortableRecord {
    locationId: GuidType
    locationScore: number
    locationDescription: string
    locationTags: VesselTags[]
}

function populateSortableRecord(
    location: Location,
    latestLocationThreatScores: LatestLocationThreatScores,
): SortableRecord {
    return {
        locationId: location.location,
        locationScore: getLocationScore(location.location, latestLocationThreatScores),
        locationDescription: location.description,
        locationTags: location.tags ?? [],
    }
}

function compareRecords(a: SortableRecord, b: SortableRecord): number {
    return (
        b.locationScore - a.locationScore ||
        a.locationDescription.localeCompare(b.locationDescription)
    )
}

export function VesselsList(): JSX.Element {
    const locations = useTypedSelector(locationsSelector)
    const latestLocationThreatScores = useTypedSelector(latestLocationThreatScoreSelector)

    return (
        <VesselList>
            {locations
                .map((location) => populateSortableRecord(location, latestLocationThreatScores))
                .sort(compareRecords)
                .map((location, index) => (
                    <VesselDetails
                        key={location.locationId}
                        locationId={location.locationId}
                        last={index === locations.length - 1}
                    />
                ))}
        </VesselList>
    )
}
