import styled from 'styled-components'
import {getColor as getMetricTypeColor} from '../../../../metrics-beta/components/metrics-summaries/score-bar.styled'

export const BenchmarkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`
export const ScoreSection = styled.div`
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
`
interface BenchmarkScoreProps {
    metricType: string
}
export const BenchmarkScore = styled.div<BenchmarkScoreProps>`
    position: relative;
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    color: ${(props) => getMetricTypeColor(props.metricType)};
`
export const PercentagWrapper = styled.div`
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 12px;
`
