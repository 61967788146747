import {TriStateButton} from '../../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import * as Styles from './view-all-row.styled'

interface Props {
    state: TriStateButtonState
    viewAllChanged: (newState: TriStateButtonState) => void
}

function determineNewState(currentState: TriStateButtonState): TriStateButtonState {
    return currentState === TriStateButtonState.FULLY_SELECTED
        ? TriStateButtonState.NOT_SELECTED
        : TriStateButtonState.FULLY_SELECTED
}

export function ViewAllRow({state, viewAllChanged}: Props): JSX.Element {
    return (
        <>
            <Styles.ViewAllCell>
                <TriStateButton
                    id="asset-filter-viewall"
                    label="View All"
                    state={state}
                    onClick={() => viewAllChanged(determineNewState(state))}
                />
            </Styles.ViewAllCell>
            <Styles.AlertHeading>Lower</Styles.AlertHeading>
            <Styles.AlertHeading>Upper</Styles.AlertHeading>
        </>
    )
}
