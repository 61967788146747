import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDispatch} from 'react-redux'
import {removeQueryParam} from '../../../../../helpers/navigation'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {setSortByColumn} from '../../../../../store/state/incidents-filter/action-creators'
import {
    incidentsFilterLoadingSelector,
    incidentsFilterSelector,
} from '../../../../../store/state/incidents-filter/selectors'
import {SORT_ORDER} from '../../../../../values/sort-order'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {
    getFormattedIncidentsSortType,
    getIncidentsSortType,
    getNewSortByDirection,
    INCIDENT_DETAILS,
} from '../../../data-helpers'
import {IconStyled, TableHeaderCell, TableHeaderText} from './table-header-cell.styled'

interface SortableColumnProps {
    gridColumn: number
    text: string
    colName: string
}

export function SortableColumn({gridColumn, text, colName}: SortableColumnProps): JSX.Element {
    const dispatch = useDispatch()
    const {orderIncidentsBy} = useTypedSelector(incidentsFilterSelector)
    const isLoading = useTypedSelector(incidentsFilterLoadingSelector)
    const {closeIncidentDetailsModal} = usePagedIncidents()

    function setSortBy(selectedColumn: string) {
        const formattedColumn = getIncidentsSortType(selectedColumn)
        dispatch(
            setSortByColumn(
                formattedColumn,
                getNewSortByDirection(formattedColumn, orderIncidentsBy),
            ),
        )
        removeQueryParam(INCIDENT_DETAILS)
        closeIncidentDetailsModal()
    }

    return (
        <TableHeaderCell id={`sortable-column_${colName}`} gridColumn={gridColumn} selectable>
            <TableHeaderText
                onClick={() => setSortBy(colName)}
                active={
                    !isLoading &&
                    getFormattedIncidentsSortType(orderIncidentsBy.column) === colName &&
                    orderIncidentsBy.direction !== SORT_ORDER.NONE
                }
                id={`text_${colName}`}
            >
                {text}
                <IconStyled
                    arrowDec={
                        getFormattedIncidentsSortType(orderIncidentsBy.column) === colName &&
                        orderIncidentsBy.direction === SORT_ORDER.DESCENDING
                    }
                    id={`arrow-icon_${colName}`}
                >
                    {!isLoading && <Icon width={12} height={17} glyph="Arrow" />}
                </IconStyled>
            </TableHeaderText>
        </TableHeaderCell>
    )
}
