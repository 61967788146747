import _ from 'lodash'
import {
    Deployment,
    Location,
    Network,
    ReportConfiguration,
} from '../../../../../../store/state/locations/state'

export function isEmptyMetaData(
    network: Network | undefined,
    deployment: Deployment | undefined,
): boolean {
    const isEmptyNetwork =
        _.isEmpty(network) || (_.isObject(network) && _.every(_.values(network), isEmptyMetaData))
    const isEmptyDeployment =
        _.isEmpty(deployment) ||
        (_.isObject(deployment) && _.every(_.values(deployment), isEmptyMetaData))

    return isEmptyNetwork && isEmptyDeployment
}

export function isEmptyReportConfig(reportConfig: ReportConfiguration | undefined): boolean {
    return (
        _.isEmpty(reportConfig) ||
        (_.isObject(reportConfig) && _.every(_.values(reportConfig), isEmptyReportConfig))
    )
}

export function isEmptyConfig(location: Location): boolean {
    return (
        isEmptyMetaData(location.metaData?.network, location.metaData?.deployment) &&
        isEmptyReportConfig(location.reportConfiguration)
    )
}
