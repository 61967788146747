import {UseMetricsWidgetOutput} from './use-metrics-component-output'
import {Dispatch, useContext} from 'react'
import {MetricsWidgetContext} from './metrics-widget-context'
import {warn} from '../../../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {TimestampFilterType} from '../../../../../store/state/metrics-filter-beta/state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    activeLocationSelector,
    locationsSelector,
} from '../../../../../store/state/locations/selectors'
import {
    LocationIdType,
    isActiveLocation,
    Location,
} from '../../../../../store/state/locations/state'
import LoadingState from '../../../../../values/loading-state-enum'
import {MetricsWidgetType} from './types/metrics-widget-type'
import {AllActions} from './state/actions'
import {REST} from '../../../../..'
import {METRICS_ENDPOINT} from '../../../../vessels-beta/contexts/types/vessels-beta-endpoints'
import {filteredVesselIds} from '../../../../metrics-beta/contexts/reselector/location-selection-number-reselector'
import {
    MetricSummary,
    MetricSummmaryMap,
} from '../../../../metrics-beta/contexts/types/metrics-summary'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {vesselTagsSelector} from '../../../../../store/state/vessel-tags/selectors'
import {getTagName} from '../../../../../utils/Utils'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'

export function useMetricsWidget(): UseMetricsWidgetOutput {
    const {state, dispatch} = useContext(MetricsWidgetContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useMetricsWidget must be used within a MetricsWidgetContext')
    }
    const allLocations = useTypedSelector(locationsSelector)

    const activeLocation = useTypedSelector(activeLocationSelector)
    const location = isActiveLocation(activeLocation) ? activeLocation.location : null

    const {assignedTag} = useTypedSelector(currentUserSelector)
    const existingTags = useTypedSelector(vesselTagsSelector)
    const assignedTagName = getTagName(existingTags, assignedTag)

    function setNewTimerange(timeRange: TimestampFilterType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setTimeRange(timeRange, dispatch, state.metricsWidgetFilter)
    }

    function fetchFilter(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.fetchUserPrefsIfExistForDashboard(dispatch, location, assignedTagName)
    }

    function setShowFilter(show = false): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showFilter(show))
    }

    function displayMetricsWidgetOnDashboard(metricWidget: MetricsWidgetType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setMetricWidget(metricWidget, dispatch, state.metricsWidgetFilter)
    }
    function setDataForMetricsWidgetOnDashboard(
        timeRange: TimestampFilterType,
        metricWidget: MetricsWidgetType,
        locations?: LocationIdType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setFilter())
        const formattedLocation = locations ? new Set([locations]) : undefined
        getDataForDashboard(
            dispatch,
            timeRange,
            formattedLocation,
            state.metricsWidgetFilter.searchVesselTagTerm,
            allLocations,
            metricWidget,
        )
    }

    return {
        loadedFilterState: state.loadingFilterState === LoadingState.Loaded,
        loadingFilterState: state.loadingFilterState,
        loadedDataState: state.loadingDataState === LoadingState.Loaded,
        fetchFilter,
        showFilter: state.showFilter,
        setShowFilter,
        setNewTimerange,
        displayMetricsWidgetOnDashboard,
        setDataForMetricsWidgetOnDashboard,
        selectedAnalysisPeriod: state.metricsWidgetFilter.selectedAnalysisPeriod,
        selectedMetricWidget: state.metricsWidgetFilter.selectedMetricWidget,
        metricsWidgetData: state.metricsWidgetData,
        locationSelectionNumber: state.locationSelectionNumber,
    }
}
async function getDataForDashboard(
    dispatch: Dispatch<AllActions>,
    analysisPeriod: TimestampFilterType,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    allLocations: Location[],
    metricWidget: MetricsWidgetType,
): Promise<void> {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm ?? [],
        '',
    )
    try {
        const responsePromise = await REST.post(
            `${METRICS_ENDPOINT}/metrics/summaries`,
            {
                locations: filteredVessels,
                period: analysisPeriod,
                analysisTypes: [metricWidget],
                aggregationLevel: 'framework',
            },
            {
                timeout: 60000, // Increase the timeout
            },
        )

        const entries = new Map(Object.entries(responsePromise.data.summaries)) as MetricSummmaryMap

        dispatch(ActionCreators.receivedRequestedWidgetData(entries, filteredVessels.length ?? 0))
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        dispatch(
            ActionCreators.receivedRequestedWidgetData(new Map<MetricType, MetricSummary>(), 0),
        )
    }
}
