import {useContext} from 'react'
import {AddButton} from './add-button'
import {AssetTable} from './asset-table'
import {LocationInventoryPageContext} from '../contexts/location-inventory-page-context'
import {CreateOTAssetReq, OTAsset} from '../../../store/state/ot-assets/state'
import PageActionType from '../contexts/state/page/action-type'

interface Props {
    handleFormSubmit: (req: CreateOTAssetReq) => void
    handleFormDismiss: () => void
}

export function AssetsLevelTab({handleFormSubmit, handleFormDismiss}: Props) {
    const {page} = useContext(LocationInventoryPageContext)
    const handleAddButtonClick = () => {
        page.dispatch?.({
            type: PageActionType.TOGGLE_SHOW_ASSET_CREATION_FORM,
            payload: {showForm: true},
        })
    }
    return (
        <>
            <AddButton disabled={page.state.isDisabledAddButton} onClick={handleAddButtonClick}>
                Add asset
            </AddButton>
            <AssetTable
                keyGenerator={(item: OTAsset, type) => `${item.id}-${type}`}
                onSubmit={handleFormSubmit}
                onDismiss={handleFormDismiss}
            />
        </>
    )
}
