import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'

interface ContainerProps {
    width: number
}

export const Container = styled.div<ContainerProps>`
    padding: ${(props) => (props.width < 600 ? `${spacing(4)} ${spacing(2)}` : spacing(4))};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
    display: flex;
    justify-content: space-between;
`
