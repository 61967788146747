import * as Styled from './upload-processing.styled'

interface ButtonProps {
    onClick: () => void
    disabled?: boolean
}

export function UploadButton({onClick, disabled}: ButtonProps): JSX.Element {
    return (
        <Styled.Button id="upload-button" onClick={onClick} disabled={disabled}>
            <Styled.ButtonText>Select file to upload</Styled.ButtonText>
        </Styled.Button>
    )
}
