import {GridLayout} from '../../grid-layout.enum'
import {Label} from './label.styled'
import {OverviewValue} from './overview-value.styled'
import {Value} from './value.styled'

interface ValueForTypeProps {
    type: string
    value: string | number
    gridLayout: GridLayout
    id: string
}

export function ValueForType({type, value, gridLayout, id}: ValueForTypeProps): JSX.Element {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return (
                <OverviewValue gridLayout={gridLayout}>
                    <Label gridLayout={gridLayout} id={`${id}-label`}>
                        {type}
                    </Label>
                    <Value gridLayout={gridLayout} id={`${id}-value`}>
                        {value}
                    </Value>
                </OverviewValue>
            )
        default:
            return (
                <OverviewValue gridLayout={gridLayout}>
                    <Value gridLayout={gridLayout} id={`${id}-value`}>
                        {value}
                    </Value>
                    <Label gridLayout={gridLayout} id={`${id}-label`}>
                        {type}
                    </Label>
                </OverviewValue>
            )
    }
}
