import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../../templates/fixed-page/content-container-middle.styled'
import {TableActionRow} from '../metrics-table-format/metrics-table-action-row'
import {MetricsCardsContent} from './metrics-cards-content'

export function MetricsCards(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()

    return (
        <>
            <TableActionRow />
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.softwareInventory.dataArea.background}
            >
                <MetricsCardsContent />
            </ContentContainerMiddle>
        </>
    )
}
