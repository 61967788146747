import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {MetricsDetailType} from '../../../../contexts/types/modal-types'
import * as Styled from './activity-section.styled'
import {DataCell} from '../shared/data-cell'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'

export function ActivitySection(): JSX.Element {
    const {modalDetailsExpanded, isProcessingMetricDetailsModal, findMetricDetailsForModal} =
        usePagedMetricsBeta()
    return (
        <Panel>
            <ExpandPanelButton headingRow={MetricsDetailType.Insights} />
            {modalDetailsExpanded.insightsExpanded &&
                (!isProcessingMetricDetailsModal ? (
                    <DataLoading />
                ) : (
                    <Styled.DetailedAreaWrapper>
                        <Styled.DetailsAreaContent>
                            <DataCell label="Difference from target:">
                                {findMetricDetailsForModal?.changeOverTrendPeriod}
                            </DataCell>
                            <DataCell label="Target for this metric:">
                                {findMetricDetailsForModal?.targetForThisMetric}
                            </DataCell>
                            <DataCell label="Assets to fix to meet target:">
                                {findMetricDetailsForModal?.assetsToFix}
                            </DataCell>
                        </Styled.DetailsAreaContent>
                    </Styled.DetailedAreaWrapper>
                ))}
        </Panel>
    )
}
