import {Banner, Button, FlexRowItem, Text} from './_styled/banner.styled'
import QuestionCircle from '../../../../@assets/icons/question-circle.svg'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'

export type BannerProps = {
    cardView: boolean
}

export function BannerElement({cardView}: BannerProps): JSX.Element {
    const {showIncidentManagementModal} = usePagedIncidents()
    return (
        <Banner isCard={cardView}>
            <FlexRowItem>
                <img src={QuestionCircle} style={{height: '24px', marginRight: '10px'}} />
                The Incident management feature is not enabled for your account.
            </FlexRowItem>
            <FlexRowItem>
                <Button isCard={cardView} onClick={() => showIncidentManagementModal(true)}>
                    <Text>Learn More</Text>
                </Button>
            </FlexRowItem>
        </Banner>
    )
}
