import * as Styled from './user-details-extended.styled'
import {UserManagementDetailType} from '../../../context/type/user-management-state'
import {HistorySectionContent} from '../../user-list-table/user-details-modal/history/history-section-content'
import {SavedFiltersAlertsContentReadOnly} from '../../user-list-table/user-details-modal/saved-filters-alerts/saved-filters-alerts-content-read-only'

export function UserDetailsReadOnly(): JSX.Element | null {
    return (
        <>
            <Styled.ModalTitle style={{paddingBottom: '5px'}}>
                {UserManagementDetailType.SavedFiltersAlerts}
            </Styled.ModalTitle>
            <SavedFiltersAlertsContentReadOnly />
            <Styled.ModalTitle style={{paddingBottom: '5px'}}>
                {UserManagementDetailType.History}
            </Styled.ModalTitle>
            <HistorySectionContent />
        </>
    )
}
