import {useDispatch} from 'react-redux'
import {Button} from '../save-filter-popup/save-filter-popup.styled'
import {deleteUserSubscription} from '../../../../store/state/saved-filters/action-creators'
import {GuidType} from '../../../../values/generic-type-defintions'

interface Props {
    disabled: boolean
    subscriptionId: GuidType
    closePopup: (value: boolean) => void
}

export function DeleteAlertButton({disabled, subscriptionId}: Props): JSX.Element {
    const dispatch = useDispatch()

    const onClickFn = () => {
        dispatch(deleteUserSubscription(subscriptionId))
    }

    return (
        <Button id="delete-alert-button" disabled={disabled} onClick={() => onClickFn()}>
            Delete Alert
        </Button>
    )
}
