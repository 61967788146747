import {ColumnTitle} from '../data-cell/_styled/data-cell.styled'
import {VesselMetadataStyle} from './vessel-metadata.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataCellVesselType} from '../input-data-cell/data-cell-vessel-type'
import {DataCellCustomerLocationCode} from '../input-data-cell/data-cell-customer-location-code'
import {DataCellGatewayIp} from '../input-data-cell/data-cell-gateway-ip'
import {DataCellGatewayMac} from '../input-data-cell/data-cell-gateway-mac'
import {DataCellIncusIp} from '../input-data-cell/data-cell-incus-ip'
import {DataCellIncusMac} from '../input-data-cell/data-cell-incus-mac'
import {DataCellSensorIp} from '../input-data-cell/data-cell-sensor-ip'
import {DataCellSensorMac} from '../input-data-cell/data-cell-sensor-mac'
import {DataCellBusinessNetworks} from '../input-data-cell/data-cell-business-networks'
import {DataCellOtNetworks} from '../input-data-cell/data-cell-ot-networks'
import {DataCellCrewNetworks} from '../input-data-cell/data-cell-crew-networks'
import {getGridType} from '../shared/grid-layout-helper'

export function VesselMetadata(): JSX.Element {
    const {width} = useDimensions()
    return (
        <div>
            <ColumnTitle>Vessel Metadata</ColumnTitle>
            <VesselMetadataStyle gridLayout={getGridType(width)}>
                <DataCellVesselType />
                <DataCellCustomerLocationCode />
                <DataCellBusinessNetworks />
                <DataCellOtNetworks />
                <DataCellCrewNetworks />
                <DataCellGatewayIp />
                <DataCellGatewayMac />
                <DataCellIncusIp />
                <DataCellIncusMac />
                <DataCellSensorIp />
                <DataCellSensorMac />
            </VesselMetadataStyle>
        </div>
    )
}
