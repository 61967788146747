import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

export function getGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(2)} 210px 150px auto 220px 220px 180px 180px 180px ${spacing(2)}`
    }
    if (width > 1500) {
        return `${spacing(2)} 200px 150px auto 210px 210px 170px 170px 170px ${spacing(2)}`
    }
    if (width > 1400) {
        return `${spacing(2)} 190px 145px auto 190px 190px 150px 150px 150px ${spacing(2)}`
    }
    return `${spacing(1)} 190px 145px auto 170px 170px 130px 130px 130px ${spacing(1)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1660) {
        return `${spacing(1)} 140px 145px 170px 160px 160px 125px 125px 140px ${spacing(1)}`
    }
    if (width > 1565) {
        return `${spacing(1)} 140px 140px 130px 130px 130px 125px 125px 140px ${spacing(1)}`
    }
    if (width > 1515) {
        return `${spacing(1)} 140px 135px 120px 125px 125px 120px 120px 125px ${spacing(1)}`
    }
    return '0 135px 135px 110px 125px 125px 110px 110px 110px 0'
}

interface TableHeaderGridProps {
    width: number
    showReducedGrid: boolean
}

export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: 0 200px 1fr 1fr 200px 100px 0;
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
`

interface TableHeaderCellProps {
    gridColumn: number
    width: number
    centered?: boolean
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
`
