import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface NmeaInventoryDetailsProps {
    activeNmea: boolean
}

export const NmeaInventoryDetails = styled.div<NmeaInventoryDetailsProps>`
    color: ${(props) => props.theme.commonTableViewPage.dataRow.text};
    overflow: auto;
    ${(props) =>
        props.activeNmea
            ? `border: 3px solid ${props.theme.commonTableViewPage.dataRow.selectedRow}`
            : ''};
`

function getColumnTemplate(width: number): string {
    if (width < 700) {
        return 'repeat(2, [col-start] 1fr);'
    }
    return 'repeat(3, [col-start] 1fr);'
}

export const Card = styled.div`
    color: ${(props) => props.theme.commonTableViewPage.dataRow.text};
    background-color: ${(props) => props.theme.commonTableViewPage.dataRow.background};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    ${mediumSmallFont()};
`

interface CardAreaProps {
    width: number
}

export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getTitleGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`

interface CardCustomerTitleDataProps {
    gridArea: string
}

export const CardTalkerTitleData = styled.h2<CardCustomerTitleDataProps>`
    display: flex;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`

interface CardProductTitleDataProps {
    gridArea: string
}

export const CardSentenceTitleData = styled.h2<CardProductTitleDataProps>`
    display: flex;
    margin: 0px;
    align-items: flex-start;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`

function getGridTemplate(width: number): string {
    if (width < 700) {
        return "'WHEN LAST-ACTIVE' 'RELAY-IP RECEIVER' 'LOCATION-NAME PARAMETERS'"
    }
    return "'WHEN RELAY-IP LOCATION-NAME' 'LAST-ACTIVE RECEIVER PARAMETERS'"
}

function getTitleGridTemplate(width: number): string {
    if (width < 700) {
        return "'TALKER-TITLE SENTENCE-TITLE'"
    }
    return "'TALKER-TITLE EMPTY-TITLE SENTENCE-TITLE'"
}

export const LinkText = styled.a`
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const AttachmentButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 0;
    margin: 0;
`
