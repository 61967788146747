import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../theme/font-styles'
import {narrowLayoutFn} from '../../incidents-report-page.styled'
import {notesFont} from '../../../incidents-v3/components/shared/notes/incident-note.styled'

interface ContentAreaProps {
    width: number
}

export const Section = styled.div<ContentAreaProps>`
    display: flex;
    flex-direction: column;
    gap: ${spacing(5)};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    color: ${(props) => props.theme.incidents.dataRow.text};
    border: 5px solid #fff;
`

interface ContainerProps {
    width: number
}

export const WidgetContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: ${(props) => (narrowLayoutFn(props.width) ? '' : '15px')};
    overflow-x: auto;
`
export const SectionText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
interface LinkTextProps {
    active: boolean
}
export const LinkText = styled.div<LinkTextProps>`
    cursor: pointer;
    ${(props) => props.active && 'pointer-events:none'};
    color: ${(props) =>
        props.active ? props.theme.refreshButton.hover.textColor : props.theme.colors.link.primary};
    background-color: ${(props) => props.active && props.theme.refreshButton.hover.backgroundColor};
    min-width: 130px;
    height: 40px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
export const IncidentDetailsContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    padding: 15px;
`
export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing(10)};
`
export const SectionContent = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${(props) => props.width < 1000 && 'min-height: 150px'};
    overflow-x: auto;
`

export const SummaryGrid = styled.div`
    display: grid;
    grid-template-columns: 150px auto;
    gap: ${spacing(1)};
    padding: 10px;
`
export const WrapperSectionContent = styled.div<ContainerProps>`
    display: flex;
    flex-direction: ${(props) => (props.width < 1000 ? 'column' : 'row')};
    gap: 10px;
`
export const TimelineWrapper = styled.div`
    display: flex;
    position: relative;
`
interface LineProps {
    lastItem: boolean
}
export const Line = styled.span<LineProps>`
    position: absolute;
    height: 1px;
    width: ${(props) => !props.lastItem && '96px'};
    background-color: #000;
    top: 14px;
    left: 24px;
`
interface SubTitleProps {
    index: number
}
export const SubTitle = styled.div<SubTitleProps>`
    position: absolute;
    bottom: -105px;
    ${smallFont()}
    width: 80px;
    white-space: pre-wrap;
    word-wrap: break-word;
    left: ${(props) => props.index * 120}px;
`

export const CircleBox = styled.div`
    display: flex;
    position: relative;
`

export const NoEvents = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    font-style: italic;
`
export const AssignedToCell = styled.div`
    ${smallFont()}
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const Note = styled.div`
    padding-top: ${spacing(1)};
    padding-bottom: ${spacing(1)};
`
export const NoteDetail = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing(2)};
`
export const When = styled.div`
    ${notesFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const Who = styled.div`
    ${notesFont()}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Text = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    word-break: break-word;
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(2)};
    text-align: justify;
    display: flex;
    flex-direction: column;
`
