import styled from 'styled-components'
import {GridLayout} from '../grid-layout.enum'

interface HeaderContentProps {
    gridLayout: GridLayout
    reducedLeftPadding?: boolean
}

function getPadding(gridLayout: GridLayout, reducedLeftPadding = false): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return reducedLeftPadding ? '20px' : '20px'
        default:
            return reducedLeftPadding ? '20px' : '30px'
    }
}

export const HeaderContent = styled.div<HeaderContentProps>`
    flex-grow: 1;
    padding-left: ${(props) => getPadding(props.gridLayout, props.reducedLeftPadding)};
    padding-right: ${(props) => getPadding(props.gridLayout, false)};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
