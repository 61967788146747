import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {getGridTemplateColumns} from './email-management-header.styled'

interface ContentContainerProps {
    width: number
    backgroundColor?: ColorType
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
`

interface TableDataGridProps {
    width: number
}
export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    gap: ${spacing(2)};
    flex: 1;
    background-color: ${(props) => props.theme.systemPage.dataArea.background};
`
