import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './modal-overlay.styled'

interface OverlayProps {
    children: ReactNode | ReactNodeArray
}

export function Overlay({children}: OverlayProps): JSX.Element {
    return (
        <>
            <Styled.ModalOverlay />
            <Styled.ModalWrapper>{children}</Styled.ModalWrapper>
        </>
    )
}
