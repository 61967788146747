import {ContentPageType} from '../../../../contexts/metrics-policy/type/content-type'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {MetricElement} from './metric-elements'

export function ControlsMaintenanceContent(): JSX.Element {
    const {formattedMetrics, isDefaultPolicy} = useMetricsPolicy()

    return (
        <div style={{padding: '0 10px'}}>
            {formattedMetrics
                .filter(
                    (findProtectionSchema) =>
                        findProtectionSchema.metricGroup === ContentPageType.CONTROLS_MAINTENANCE,
                )
                .map((item) => (
                    <MetricElement
                        key={item.metricConfigId}
                        metricSchema={item}
                        defaultPolicy={isDefaultPolicy}
                    />
                ))}
        </div>
    )
}
