/* eslint-disable @typescript-eslint/no-explicit-any */
import {forwardRef, ReactNode, ReactNodeArray, useContext, useState} from 'react'
import {
    BoundsContext,
    BoundsContextProps,
} from '../../../../components/bounds-context/bounds-context'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import {SortOrderDialog} from './sort-order-dialog'
import {SortField} from './values'

interface Props {
    children: ReactNode | ReactNodeArray
    sortKey: string
    sortableFields: SortField[]
    groupName: string
    sortOrderVariableChanged: (key: string) => void
}

export function SortOrderPopover({
    children,
    sortKey,
    sortableFields,
    groupName,
    sortOrderVariableChanged,
}: Props): JSX.Element {
    const {ref: layoutRef} = useContext(BoundsContext) as BoundsContextProps

    const [visible, setVisible] = useState(false)

    function onClickWrapper() {
        setVisible(!visible)
    }

    function onClickOutside() {
        setVisible(false)
    }

    function optionSelected(key: string): void {
        setVisible(false)
        sortOrderVariableChanged(key)
    }

    // eslint-disable-next-line react/display-name
    const ForwardRefChildren = forwardRef((_props, ref: any) => {
        return (
            <div ref={ref} onClick={onClickWrapper}>
                {children}
            </div>
        )
    })

    // note: need to wrap tippy up in a div to work with keyboard navigation, likes a new parent node apparently
    // see https://github.com/atomiks/tippyjs-react/issues/131
    return (
        <div>
            <Tippy
                visible={visible}
                onClickOutside={onClickOutside}
                zIndex={10}
                arrow={true}
                theme="light-border"
                interactive={true}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {boundary: layoutRef?.current},
                        },
                    ],
                }}
                content={
                    <SortOrderDialog
                        sortKey={sortKey}
                        sortableFields={sortableFields}
                        groupName={groupName}
                        optionSelected={optionSelected}
                    />
                }
                placement="right-start"
            >
                <ForwardRefChildren />
            </Tippy>
        </div>
    )
}
