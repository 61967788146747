import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const ClearButtonLink = styled.div`
    ${mediumSmallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    text-decoration: underline;
    font-weight: ${(props) => props.theme.font.weight.normal};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    &:hover {
        outline: 0;
        cursor: pointer;
    }
`
