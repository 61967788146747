import {Check} from 'react-feather'
import * as Styled from './data-input.styled'

interface DataInputProps {
    label: string
    initialValue: string | string[] | number | undefined
    setChange: (value: string) => void
    changed: boolean
    disabled?: boolean
}

export function DataInput({label, initialValue, setChange, changed}: DataInputProps): JSX.Element {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setChange(e.target.value)
    }

    return (
        <>
            <Styled.Label id={label}>{label}:</Styled.Label>
            <Styled.Value id={`${label}-value`}>
                <Styled.inputNonText value={initialValue ?? ''} onInput={onChangeHandler} />
                {changed && (
                    <Check color={'green'} width={19} height={19} style={{paddingLeft: '2px'}} />
                )}
            </Styled.Value>
        </>
    )
}
