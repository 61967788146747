import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {DEFAULT_METRICS_BETA_FILTER, MetricsBetaFilterReduxState} from './state'

export const metricsBetaFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.metricsBetaFilter.loadingState

export const metricsBetaFilterLoadingSelector = (state: AppState): boolean =>
    state.metricsBetaFilter.loadingState === LoadingState.RequestingData ||
    state.metricsBetaFilter.loadingState === LoadingState.NotPopulated

export const metricsBetaFilterSelector = (state: AppState): MetricsBetaFilterReduxState =>
    state.metricsBetaFilter

export const selectedFilterNameSelector = (state: AppState): string =>
    state.metricsBetaFilter.selectedFilterName

export const activeFilterSelector = (state: AppState): boolean =>
    state.metricsBetaFilter.metricTypes !== undefined ||
    state.metricsBetaFilter.selectedAnalysisPeriod !==
        DEFAULT_METRICS_BETA_FILTER.selectedAnalysisPeriod ||
    state.metricsBetaFilter.onlyAssetsWithIssues === false
