import {getUserDescription} from '../../../helpers/getUserDescription'
import {
    getLocationDescription,
    LocationMap,
    Location,
    getLocationEmail,
    isOnboardDashboard,
} from '../../../store/state/locations/state'
import {User} from '../../../store/state/users/state'
import {formatVesseltagsForUI, VesselTagsAPI} from '../../../store/state/vessel-tags/helper'
import {LocationTagArrayMapType, VesselTags} from '../../../store/state/vessel-tags/state'
import {GuidType} from '../../../values/generic-type-defintions'
import {IncidentSeverityValue} from '../../../values/incident-response-values'
import {Role} from '../../../values/Role'
import {IncidentAttachmentData} from '../contexts/types/incident-attachment-data'
import {IncidentNotes} from '../contexts/types/incident-notes'
import {IncidentRecommendations} from '../contexts/types/incident-recommenations'
import {IncidentResponse} from '../contexts/types/incident-response'
import {IncidentStatus} from '../contexts/types/incident-status'
import {DateTimeEventOutputModel} from './date-time-event.model'
import {NoteOutputModel} from './note.model'
import {RecommendationOutputModel} from './recommendation.model'

export function parseNotes(
    notes: IncidentNotes[],
    users: User[],
    isInternalUser: boolean,
): NoteOutputModel[] {
    return notes.map((note) => {
        return {
            id: note.identity,
            createdBy: getUserDescription(
                users,
                note.createdBy,
                note.createdByEmail,
                isInternalUser,
            ),
            userId: note.createdBy,
            createdWhen: note.createdWhen,
            updatedWhen: note.updatedWhen,
            text: note.note,
            edited: note.updatedWhen !== note.createdWhen,
        } as NoteOutputModel
    })
}

export function parseRecommendations(
    recommendations: IncidentRecommendations[],
): RecommendationOutputModel[] {
    return recommendations.map((recommendation) => {
        return {
            id: recommendation.identity,
            text: recommendation.recommendation,
        } as RecommendationOutputModel
    })
}

export class IncidentOutputModel {
    public readonly id: GuidType
    public readonly status: IncidentStatus
    public readonly number: string
    public readonly title: string
    public readonly severity: IncidentSeverityValue
    public readonly type: string
    public readonly vessel: string
    public readonly raised: DateTimeEventOutputModel
    public readonly updated: DateTimeEventOutputModel
    public readonly attachment: IncidentAttachmentData | null
    public readonly notes: NoteOutputModel[]
    public readonly recommendations: RecommendationOutputModel[]
    public readonly description: string
    public readonly warning: string
    public readonly assignedBy: GuidType
    public readonly assignedTo: GuidType
    public readonly assignedToName: string
    public readonly guestAssignedToEmail: string
    public readonly numberOfNewItems: number
    public readonly vesselTags: VesselTags[]
    public readonly vesselEmail: string | null
    public readonly assignedToVesselEmail: boolean
    public readonly vesselOnboardDashboard: boolean

    public constructor(
        response: IncidentResponse,
        locationMap: LocationMap,
        userRoles: Role[],
        users: User[],
        vesselTagsForLocations: LocationTagArrayMapType,
    ) {
        const isInternalUser = checkIfIsInternalUser(userRoles)
        const location: Location | undefined = locationMap.get(response.location)
        if (!location) {
            throw Error('Could not find location')
        }

        this.id = response.identity
        this.status = response.currentStatus
        this.number = response.incidentCode
        this.title = response.title
        this.severity = response.severity
        this.type = response.type
        this.vessel = getLocationDescription(locationMap, response.location)
        this.description = response.description
        this.warning = response.warning
        this.assignedBy = response.assignedByWho
        this.assignedTo = response.assignedToWho
        this.assignedToName = response.assignedToWhoName
        this.guestAssignedToEmail = response.guestAssignedToEmail
        this.numberOfNewItems = response.numberOfNewItems
        this.vesselEmail = getLocationEmail(locationMap, response.location)
        this.assignedToVesselEmail = response.assignedToVesselEmail
        this.updated = {
            when: response.updatedState.when,
            who: getUserDescription(users, response.updatedState.who, undefined, isInternalUser),
        }

        this.raised = {
            when: response.createdState.when,
            who: getUserDescription(users, response.createdState.who, undefined, isInternalUser),
        }

        this.attachment = response.attachment
        this.notes = parseNotes(response.notes, users, isInternalUser)
        this.recommendations = parseRecommendations(response.recommendations)
        this.vesselTags = sortedTagsForLocation(vesselTagsForLocations.get(location.location))
        this.vesselOnboardDashboard = isOnboardDashboard(locationMap, response.location)
    }
}
export const checkIfIsInternalUser = (roles: Role[]): boolean => {
    const INTERNAL_ROLES = ['DEVELOPER', 'THREAT_ANALYST']

    return roles && roles.some((role) => INTERNAL_ROLES.includes(role))
}

function sortedTagsForLocation(vesselTags: VesselTagsAPI[] | undefined): VesselTags[] {
    if (!vesselTags) {
        return []
    }
    return [...vesselTags]
        ?.map((element) => formatVesseltagsForUI(element))
        .sort((a: VesselTags, b: VesselTags) => a.name?.localeCompare(b.name))
}
