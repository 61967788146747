import {StringUtils} from '../../../../../utils/Utils'
import {MetricsResponseForTrends} from '../../../contexts/types/metrics-response'
import {getFormatedPercentNumber, getMetricTypeIcon, getTime} from '../../data-helper'
import {TableDataCell, TableDataRow} from './_styled/metrics-table-format.styled'

interface TableRowProps {
    metrics: MetricsResponseForTrends
    index: number
    onClickFn: () => void
    activeRow: boolean
}

export function TableRowTrends({
    metrics,
    index,
    onClickFn,
    activeRow,
}: TableRowProps): JSX.Element | null {
    const gridRow = index + 1
    const cells = [
        metrics.locationName,
        metrics.policyName,
        StringUtils.capitaliseString(metrics.framework),
        metrics.formattedMetricForUI.displayName ??
            StringUtils.capitaliseString(metrics.metric.replace(/([A-Z])/g, ' $1')),
        getTime(metrics.window),
        getFormatedPercentNumber(metrics.performance),
    ]

    return (
        <>
            <TableDataRow
                gridRow={gridRow}
                id={`data-row_${metrics.location}-${index}`}
                activeRow={activeRow}
            />
            {cells.map((cell, i) => (
                <TableDataCell
                    key={i}
                    gridRow={gridRow}
                    gridColumn={i + 2}
                    id={`dataCell_${metrics.location}-${i}`}
                    centered={i === 4 || i === 5}
                    onClick={onClickFn}
                >
                    {i === 2 && (
                        <img
                            src={getMetricTypeIcon(metrics.framework)}
                            style={{width: '35px', height: '35px', marginRight: '5px'}}
                        />
                    )}
                    {cell}
                </TableDataCell>
            ))}
        </>
    )
}
