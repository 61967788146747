import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styles from './action-bar.styled'
import {RefreshButton} from './refresh-button'
import {compareFilters, showFilterBarOnScreen} from '../data-helpers'
import {ShowFilterButton} from './show-filter-button'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {useEffect} from 'react'
import {reportsFilterSelector} from '../../../../store/state/reports-filter/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../store/state/vessel-filter/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {useDispatch} from 'react-redux'
import {setVesselFilterPref} from '../../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {GuidType} from '../../../../values/generic-type-defintions'
import {savedReportsFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {
    DEFAULT_REPORTS_FILTER,
    ReportsFilter,
    ReportsFilterCriteria,
} from '../../../../values/user-preferences/reports-filter'
import {setSelectedFilterName} from '../../../../store/state/reports-filter/action-creators'
import {getFormattedReportsFilter} from '../../../../components/saved-search/components/shared/helper'

export function ActionBar(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const showFilterBar = showFilterBarOnScreen(width)
    const {getFilteredData, setNoteExpanded} = usePagedReports()
    const {
        selectedReportType,
        selectedRating,
        selectedFromTimeRange,
        selectedToTimeRange,
        includeWholeFleetReports,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        selectedFilterName,
    } = useTypedSelector(reportsFilterSelector)

    const newFilter = useTypedSelector(reportsFilterSelector)
    const currentFilter = useTypedSelector(savedReportsFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria

    const vesselFilter = useTypedSelector(vesselFilterSelector)
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)
    const queryString = useTypedSelector(queryStringObjectSelector) ?? null
    const queryId = (queryString?.reportDetails as GuidType | undefined) ?? null

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.REPORTS,
                ),
            )
        }
    }, [dispatch, locations, searchVesselNameTerm, searchVesselTagTerm, vesselFilterLoading])

    useEffect(() => {
        if (vesselFilterLoading === LoadingState.NotPopulated) {
            getFilteredData(
                selectedReportType,
                selectedRating,
                selectedFromTimeRange,
                selectedToTimeRange,
                includeWholeFleetReports,
                {
                    ...DEFAULT_VESSEL_FILTER_STATE,
                    locations,
                    searchVesselTagTerm,
                    searchVesselNameTerm,
                },
                queryId,
            )
        } else {
            compareFilters(
                currentFilter
                    ? (getFormattedReportsFilter(
                          currentFilter as ReportsFilterCriteria,
                      ) as ReportsFilter)
                    : DEFAULT_REPORTS_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))

            getFilteredData(
                selectedReportType,
                selectedRating,
                selectedFromTimeRange,
                selectedToTimeRange,
                includeWholeFleetReports,
                vesselFilter,
                queryId,
            )
        }
        if (queryId) {
            setNoteExpanded(queryId, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        locations,
        selectedRating,
        selectedReportType,
        selectedFromTimeRange,
        selectedToTimeRange,
        includeWholeFleetReports,
        vesselFilter,
        queryId,
    ])

    return (
        <Styles.Container rightAlign={showFilterBar} width={width}>
            {!showFilterBar && <ShowFilterButton />}
            <RefreshButton />
        </Styles.Container>
    )
}
