import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {NoData} from '../shared/no-data/no-data'
import {SelfMonitoringEngineTablePopulated} from './table/self-monitoring-table-populated'
import {SelfMonitoringEngineCardsPopulated} from './cards/self-monitoring-cards-populated'
import {selfMonitoringEngineEventsSelector} from '../../../../store/state/self-monitoring-events/selectors'

interface SelfMonitoringEngineLoadedProps {
    environment: string
}

export function SelfMonitoringEngineLoaded({
    environment,
}: SelfMonitoringEngineLoadedProps): JSX.Element {
    const {width} = useDimensions()
    const showCards = width < 1240
    const selfMonitoringEngineEvents = useTypedSelector(selfMonitoringEngineEventsSelector)
    if (selfMonitoringEngineEvents?.length === 0) {
        return <NoData message={`There are no self-monitoring engine events for ${environment}`} />
    }
    if (showCards) {
        return (
            <SelfMonitoringEngineCardsPopulated
                selfMonitoringEngineEvents={selfMonitoringEngineEvents}
            />
        )
    }
    return (
        <SelfMonitoringEngineTablePopulated
            selfMonitoringEngineEvents={selfMonitoringEngineEvents}
        />
    )
}
