import produce from 'immer'
import {ModalUploadDocumentState} from '../types/modal-upload-document-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'

export const modalUploadDocumentReducer = produce(
    (draft: ModalUploadDocumentState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_FILE_VALUE:
                draft.file = action.payload
                break
            case ActionType.SET_IS_PROCESSING_STATUS:
                draft.isProcessingSave = action.payload
                break
            case ActionType.SET_ERROR_MESSAGE:
                draft.errorMessage = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
