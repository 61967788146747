import {formatDate} from '../../../helpers/formatting'
import {TableLabel} from './table-label.styled'
import {TableValue} from './table-value.styled'

import * as styled from './left-hand-panel.styled'
import {TableRow} from './table-row.styled'
import {EventDetailOutputModel} from './event-detail.model'

interface LeftHandPanelProps {
    eventDetails: EventDetailOutputModel
}

export function LeftHandPanel({eventDetails}: LeftHandPanelProps): JSX.Element {
    return (
        <styled.LeftHandPanel>
            <table>
                <tbody>
                    {eventDetails.name && (
                        <TableRow>
                            <TableLabel first>Name</TableLabel>
                            <TableValue first>{eventDetails.name}</TableValue>
                        </TableRow>
                    )}
                    {eventDetails.indicator && (
                        <TableRow>
                            <TableLabel>Indicator ID</TableLabel>
                            <TableValue>{eventDetails.indicator}</TableValue>
                        </TableRow>
                    )}
                    {eventDetails.timestamp && (
                        <TableRow>
                            <TableLabel>Time Stamp</TableLabel>
                            <TableValue>{formatDate(eventDetails.timestamp)}</TableValue>
                        </TableRow>
                    )}
                    {eventDetails.id && (
                        <TableRow>
                            <TableLabel last>ID</TableLabel>
                            <TableValue last>{eventDetails.id}</TableValue>
                        </TableRow>
                    )}
                </tbody>
            </table>
        </styled.LeftHandPanel>
    )
}
