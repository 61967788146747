import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'

export const Button = styled.button`
    background: none;
    border: none;
    padding: 7px 13px;
    font: ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    min-width: 110px;
    display: flex;
    justify-content: flex-start;
    &:hover {
        background-color: rgba(31, 136, 229, 0.1);
    }
`

export const Processing = styled.div`
    background: none;
    border: none;
    padding: 7px 13px;
    font: ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;
    min-width: 110px;
    text-align: center;
`

export const Text = styled.span`
    margin-left: 12px;
`
