import {TableHeaderRow} from './table-header-row'
import {MouseEvent} from 'react'
import {usePagedAlerts} from '../../../../contexts/alerts/use-paged-alerts'
import {NoDataMessageGrid} from './styled/no-data-table-row.styled'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {TableErrorCell} from '../../common/table/table-error-cell.styled'
import {ErrorMessage} from '../../common/table/error-message.styled'
import {RetryButton} from '../../common/retry-button.styled'

export function RequestError(): JSX.Element {
    const {refreshData} = usePagedAlerts()

    function onClickHandler(e: MouseEvent) {
        refreshData()
        e.preventDefault()
    }

    return (
        <>
            <TableHeaderRow />
            <NoDataMessageGrid>
                <TableDataRow gridRow={1} />
                <TableErrorCell gridRow={1} gridColumn={3} centered>
                    <ErrorMessage id="alerts_error_message">
                        Sorry, an error occured whilst requesting alert data
                    </ErrorMessage>
                    <RetryButton id="alerts_error_retry" onClick={onClickHandler}>
                        Retry
                    </RetryButton>
                </TableErrorCell>
            </NoDataMessageGrid>
        </>
    )
}
