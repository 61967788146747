import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const StyledButton = styled.button`
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    color: ${(props) => props.theme.colors.link.primary};
    border-radius: 2px;
    background: none;
    padding: ${spacing(1)} ${spacing(2)};
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    &:hover {
        color: ${(props) => props.theme.vessels.button.hoverTextColor};
        background-color: ${(props) => props.theme.vessels.button.hoverBackgroundColor};
    }
    :disabled {
        opacity: 0.5;
    }
`
