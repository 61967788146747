import {useState} from 'react'
import * as Styled from './save-filter-popup.styled'
import {useDispatch} from 'react-redux'
import {SaveButton} from './save-button'
import {CancelButton} from './cancel-button'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    resultMessageSelector,
    savedFiltersNameArraySelector,
} from '../../../../store/state/saved-filters/selectors'
import {ResultMessageType} from '../../../../store/state/saved-filters/state'
import {setResultMessage} from '../../../../store/state/saved-filters/action-creators'

interface SaveFilterPopupProps {
    closePopup: (value: boolean) => void
}

export function SaveFilterPopup({closePopup}: SaveFilterPopupProps): JSX.Element {
    const [newFilterName, setNewFilterName] = useState('')
    const resultMessage = useTypedSelector(resultMessageSelector)
    const existingFiltersName = useTypedSelector(savedFiltersNameArraySelector)
    const notSavable = resultMessage !== undefined || newFilterName === ''
    const isSaved = resultMessage === ResultMessageType.SUCCESS
    const dispatch = useDispatch()

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        setNewFilterName(e.target.value)
        if (existingFiltersName.includes(e.target.value)) {
            dispatch(setResultMessage(ResultMessageType.NOT_SAVABLE))
        } else {
            dispatch(setResultMessage(undefined))
        }
    }

    return (
        <Styled.SavedFiltersWrapper>
            <Styled.SavedFiltersDropdown>
                <Styled.Title>Save filter as:</Styled.Title>
                <Styled.Input
                    placeholder="New Filter Name"
                    value={newFilterName}
                    onChange={onChangeHandler}
                    isChanged={false}
                    id="input-filed-for-new-saved-filter"
                />
                <Styled.ResultMessage isSaved={isSaved} id="resultMessage">
                    {resultMessage}
                </Styled.ResultMessage>
                <Styled.ButtonsArea>
                    <SaveButton
                        disabled={notSavable}
                        newFilterName={newFilterName}
                        closePopup={closePopup}
                    />
                    <CancelButton closePopup={closePopup} />
                </Styled.ButtonsArea>
            </Styled.SavedFiltersDropdown>
        </Styled.SavedFiltersWrapper>
    )
}
