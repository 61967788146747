import * as Styled from './incident-severity-change-record.styles'
import SEVERITY_CHANGE_ICON from '../../../../../@assets/icons/severity-updated.svg'
import {formatDate} from '../../../../../helpers/formatting'
import {SeverityChangeOutputModel} from '../../../models/severity-change.model'

interface IncidentSeverityProps {
    severityRecord: SeverityChangeOutputModel
}
export function IncidentSeverityChangeRecord({severityRecord}: IncidentSeverityProps): JSX.Element {
    function displaySeverity(severity: string): string {
        switch (severity) {
            case 'LOW':
                return 'Low'
            case 'MEDIUM':
                return 'Medium'
            case 'HIGH':
                return 'High'
            case 'CRITICAL':
                return 'Critical'
            default:
                return severity
        }
    }

    return (
        <Styled.StatusChangeWrapper id={`incident-severity-record-${severityRecord.id}-wrapper`}>
            <Styled.HeaderWrapper>
                <Styled.Icon src={SEVERITY_CHANGE_ICON}></Styled.Icon>
                <Styled.When id={`incident-severity-record-when-${severityRecord.id}`}>
                    {formatDate(severityRecord?.when) ?? 'Unknown'}
                </Styled.When>
                <Styled.Who id={`incident-severity-record-who-${severityRecord.id}`}>
                    {severityRecord.username}
                </Styled.Who>
                {!severityRecord.isViewed && <Styled.NewSquare>NEW</Styled.NewSquare>}
            </Styled.HeaderWrapper>
            <Styled.UpdateMessage>
                <div>Severity update: Severity was marked as:</div>
                <Styled.Status id={`incident-severity-record-displaySeverity-${severityRecord.id}`}>
                    {displaySeverity(severityRecord.newSeverity)}
                </Styled.Status>
            </Styled.UpdateMessage>
        </Styled.StatusChangeWrapper>
    )
}
