import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {ProcessingStatus} from '../../values/processing-save-state-enum'
import {Overlay} from '../shared-components/modal-overlay'
import CLOSE_ICON from '../../@assets/icons/close-assign-to-icon.svg'
import UPLOAD_DOC from './upload-dcoument.svg'
import * as Styled from './upload-document-modal.styled'
import {removeQueryParam} from '../../helpers/navigation'
import {UploadSucceded} from './components/upload-succeded'
import {UploadError} from './components/upload-error'
import {UploadInProgress} from './components/upload-in-progress'
import {NativeDragAndDrop} from './components/native-drag-and-drop'
import {ModalUploadDocumentProvider} from './context/modal-upload-document-provider'
import {UploadDocumentModalPage} from './context/types/modal-upload-document-state'
import {useModaUploadDocument} from './context/use-modal-upload-document'

interface UploadDocumentModalProps {
    requestedPage: UploadDocumentModalPage | null
    onUploadSuccess: () => void
}
export function UploadDocumentModal({
    requestedPage,
    onUploadSuccess,
}: UploadDocumentModalProps): JSX.Element {
    return (
        <ModalUploadDocumentProvider
            requestedPage={requestedPage}
            onUploadSuccess={onUploadSuccess}
        >
            <Overlay>
                <UploadDocumentContent />
            </Overlay>
        </ModalUploadDocumentProvider>
    )
}

function UploadDocumentContent(): JSX.Element {
    const {isProcessingSave, setIsProcessingValue} = useModaUploadDocument()

    const {width} = useDimensions()
    return (
        <Styled.Section onClick={(e) => e.stopPropagation()} width={width} id="edit-asset-section">
            <Styled.Header id="upload-document-Header">
                <GetSectionTitle />
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        setIsProcessingValue(ProcessingStatus.NOT_PROCESSING)
                        removeQueryParam('uploadDocument')
                    }}
                    id="detail-panel-header_close-details-icon"
                />
            </Styled.Header>
            <Styled.DragAndDropSection width={width} id="upload-document-DragAndDropSection">
                <GetSectionSubTitle />
                <NativeDragAndDrop />
            </Styled.DragAndDropSection>
            <Styled.ProcessingDocumentSection id="upload-document-Actions">
                {isProcessingSave === ProcessingStatus.PROCESSING && <UploadInProgress />}
                {isProcessingSave === ProcessingStatus.PROCESSED && <UploadSucceded />}
                {isProcessingSave === ProcessingStatus.FAILED && <UploadError />}
            </Styled.ProcessingDocumentSection>
        </Styled.Section>
    )
}
function GetSectionTitle(): JSX.Element {
    const {requestedPage} = useModaUploadDocument()
    if (requestedPage === 'metrics' || requestedPage === 'incidents') {
        return (
            <Styled.Title id="upload-document-Title">
                <Styled.TitleImage src={UPLOAD_DOC} id="detail-panel-header_title-icon" />
                Upload custom PDF
            </Styled.Title>
        )
    } else {
        return <div />
    }
}

function GetSectionSubTitle(): JSX.Element {
    const {requestedPage} = useModaUploadDocument()
    if (requestedPage === 'metrics' || requestedPage === 'incidents') {
        return (
            <Styled.SubTitle>
                Import your own document to be displayed on the vessel
            </Styled.SubTitle>
        )
    } else {
        return <div />
    }
}
