import createCachedSelector from 're-reselect'
import {normaliseScore} from '../../../helpers/formatting'
import {latestLocationThreatScoreSelector} from '../../../store/state/latest-location-threat-scores/selectors'
import {getLocationScore} from '../../../store/state/locations/helpers'
import {locationMapSelector} from '../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import {nodesForLocationsSelector} from '../../../store/state/nodes/selectors'
import AppState from '../../../store/types/app-state'
import {NodeData} from '../../../values/nodes/NodeData'
import {
    FormattedHostDataForSoftwareInventory,
    FormattedLocationDataForSoftwareInventory,
} from '../contexts/types/software-inventory-model-output'

function formatNumberOfAssets(numberOfAssets: number): string {
    return numberOfAssets === 1 ? '1 asset' : `${numberOfAssets} assets`
}
function formatHost(element: NodeData): FormattedHostDataForSoftwareInventory {
    return {
        identity: element.node,
        hostName: element.alias,
        lastSeenTimestamp: element.updatedAt,
    }
}
function getFormattedHostList(
    nodes: NodeData[] | undefined,
): FormattedHostDataForSoftwareInventory[] {
    let listOfFormattedHosts: FormattedHostDataForSoftwareInventory[] = []
    if (!nodes) {
        return listOfFormattedHosts
    }
    listOfFormattedHosts = nodes.map((element) => formatHost(element))
    return listOfFormattedHosts
}

export const formattedUSBDeviceDetailsCachedReselector = createCachedSelector(
    [
        locationMapSelector,
        nodesForLocationsSelector,
        latestLocationThreatScoreSelector,
        (_state: AppState, locationId: LocationIdType) => locationId,
    ],
    (locations, nodesForLocations, latestLocationThreatScores, locationId) => {
        const location: Location | undefined = locations.get(locationId)
        if (!location) {
            throw Error('Could not find location')
        }
        const score = getLocationScore(location.location, latestLocationThreatScores)
        return {
            id: locationId,
            name: location?.description ?? 'Unknown',
            shipType: location?.type ?? 'Unknown',
            imoNumber: location?.customerLocationCode ?? 'Unknown',
            numberOfAssets: formatNumberOfAssets(
                nodesForLocations.get(location.location)?.length || 0,
            ),
            score: normaliseScore(score),
            hosts: getFormattedHostList(nodesForLocations.get(location.location)),
        } as FormattedLocationDataForSoftwareInventory
    },
)((_state, locationId) => locationId)
