import styled from 'styled-components'

interface NetworkAssetDetailsProps {
    activeNetworkAsset: boolean
}

export const NetworkAssetDetails = styled.div<NetworkAssetDetailsProps>`
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    ${(props) =>
        props.activeNetworkAsset
            ? `border: 3px solid ${props.theme.unknownAssets.dataRow.selectedRow}`
            : ''};
`
