import {ContentWrapper} from './_styled/usb-details-modal.styled'
import {USBDeviceDetails} from './usb-device-details'

export function USBDeviceDetailsModal(): JSX.Element {
    return (
        <ContentWrapper id="usb-devices-details-modal_wrapper">
            <USBDeviceDetails />
        </ContentWrapper>
    )
}
