import {ContentWrapper, ScrollableContent} from '../vessels-beta-page.styled'
import {DataActions} from './data-actions/data-actions'
import {TableHeader} from './vessels-table/table-header'
import * as Styled from './vessels-table/_styled/table-list.styled'
import {TableRow} from './vessels-table/table-row'
import {usePagedVesselsBeta} from '../contexts/use-paged-vessels-beta'
import {BoundsContext} from '../../../components/bounds-context/bounds-context'
import {useRef} from 'react'
import LoadingState from '../../../values/loading-state-enum'
import {DataLoading} from '../../../components/data-loading/data-loading'

export function Content(): JSX.Element {
    const {numberOfColumns, filteredLocationsIds, loadingFilteredDataState} = usePagedVesselsBeta()
    const noData = filteredLocationsIds?.length === 0
    const layoutRef = useRef(null)
    const isLoadingData = loadingFilteredDataState !== LoadingState.Loaded

    return (
        <ContentWrapper id="vessels-beta-page_content-wrapper">
            <DataActions />
            <TableHeader />
            <BoundsContext.Provider value={{ref: layoutRef}}>
                <ScrollableContent>
                    {noData ? (
                        <Styled.ContentContainer>No Data</Styled.ContentContainer>
                    ) : isLoadingData ? (
                        <DataLoading />
                    ) : (
                        <Styled.ContentContainer>
                            <Styled.TableDataGrid
                                numberOfColumns={numberOfColumns}
                                id="table-data-grid-style"
                            >
                                {filteredLocationsIds?.map((locationId, index) => (
                                    <TableRow
                                        key={locationId}
                                        locationId={locationId}
                                        index={index}
                                    />
                                ))}
                            </Styled.TableDataGrid>
                        </Styled.ContentContainer>
                    )}
                </ScrollableContent>
            </BoundsContext.Provider>
        </ContentWrapper>
    )
}
