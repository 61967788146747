import * as Styled from './generate-report-popup.styled'
import CLOSE_ICON from '../../../@assets/icons/close-panel.svg'
interface ReportDialogContentProps {
    reportUrl: string | null
    pageType: string
    showGenerateReportPopup: (show: boolean) => void
}

export function ReportDialogContent({
    reportUrl,
    pageType,
    showGenerateReportPopup,
}: ReportDialogContentProps): JSX.Element {
    return (
        <Styled.TitleRow id={`${pageType}_generate-report-popup_title-row`}>
            <Styled.CloseImage
                src={CLOSE_ICON}
                id="close-popup-icon"
                onClick={() => showGenerateReportPopup(false)}
            />
            <Styled.Title>
                The report will be available in the{' '}
                <a href={`${reportUrl}`} id={`${pageType}_generate-report-popup_redirect-link`}>
                    Reports page
                </a>
            </Styled.Title>
        </Styled.TitleRow>
    )
}
