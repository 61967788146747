export enum GridLayout {
    THREE_COLUMNS_LARGE = 'THREE_COLUMNS_LARGE',
    THREE_COLUMNS_MEDIUM = 'THREE_COLUMNS_MEDIUM',
    THREE_COLUMNS_SMALL = 'THREE_COLUMNS_SMALL',
    TWO_COLUMNS_LARGE = 'TWO_COLUMNS_LARGE',
    TWO_COLUMNS_MEDIUM = 'TWO_COLUMNS_MEDIUM',
    TWO_COLUMNS_SMALL = 'TWO_COLUMNS_SMALL',
    ONE_COLUMN_LARGE = 'ONE_COLUMN_LARGE',
    ONE_COLUMN_MEDIUM = 'ONE_COLUMN_MEDIUM',
    ONE_COLUMN_SMALL = 'ONE_COLUMN_SMALL',
}
