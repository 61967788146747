import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Container = styled.div`
    display: flex;
    height: 25px;
`
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
`
export const Label = styled.label`
    ${mediumSmallFont()}
`
export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) => props.theme.colors.link.primary};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-left: 0;
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const SearchInput = styled.input`
    ${mediumSmallFont()}
    width: 170px;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)} 0 0 ${spacing(1)};
    margin: 0;
    padding: 4px;
    height: 25px;
    background-color: inherit;
`
