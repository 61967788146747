import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'

interface ContainerProps {
    disabled?: boolean
}

export const Wrapper = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 190px;
    background-color: inherit;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.bold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
