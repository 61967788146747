import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {setSearchAssetTerm} from '../../../../../../../store/state/my-vessels-filters/action-creators'
import {myVesselsFiltersSelector} from '../../../../../../../store/state/my-vessels-filters/selectors'
import * as Styles from './asset-search.styled'
import {logFilterByTypes} from '../../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../../../store/state/audit-log/state'

export function AssetSearch(): JSX.Element {
    const searchTerm = useTypedSelector(myVesselsFiltersSelector).searchAssetTerm
    const dispatch = useDispatch()

    function searchTermChanged(searchTerm: string): void {
        dispatch(setSearchAssetTerm(searchTerm))
        if (searchTerm) {
            dispatch(
                logFilterByTypes(
                    'searchAssetsEvent',
                    `${searchTerm}`,
                    true,
                    AuditLogPageType.MY_VESSELS,
                ),
            )
        }
    }

    return (
        <Styles.Container>
            <Styles.SearchInput
                id="asset-search"
                placeholder=" - Search Asset - "
                value={searchTerm}
                onChange={(e) => searchTermChanged(e.currentTarget.value)}
            />
            <Styles.SearchIcon glyph="Search" height={20} width={20} />
        </Styles.Container>
    )
}
