import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.link.primary};
    padding: 3px;
    margin: 0;
    border: 1px solid ${(props) => props.theme.colors.link.primary};
    border-radius: 3px;
    :disabled {
        cursor: default;
        opacity: 0.5;
    }
`
export const ButtonText = styled.span`
    padding-right: ${spacing(1)};
    ${smallFont()}
`
