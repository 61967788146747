import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Panel = styled.div`
    background-color: #f7f7f7;
    border-radius: 5px;
    padding: 30px 60px;
    margin-bottom: ${spacing(2)};
    width: 100%;
`
