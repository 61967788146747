import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    isLoadingSelector,
    loadingStateSelector,
} from '../../../../store/state/mona-engine-modes/selectors'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import {MonaEngineModesLoaded} from './engine-modes-loaded'
import {Header} from '../shared/header/header'
import {useDispatch} from 'react-redux'
import {fetchMonaEngineModes} from '../../../../store/state/mona-engine-modes/action-creators'
import {lastUpdatedSelector} from '../../../../store/state/mona-engine-modes/selectors'
import {configTenantDisplayNameSelector} from '../../../../store/state/config/selectors'
import {useEffect} from 'react'
import LoadingState from '../../../../values/loading-state-enum'
import {fetchLocations} from '../../../../store/state/locations/action-creators'

export function MonaEngineModes(): JSX.Element {
    const tenantDisplayName = useTypedSelector(configTenantDisplayNameSelector)
    const title: string = tenantDisplayName ? tenantDisplayName : 'Medulla'

    const isLoading = useTypedSelector(isLoadingSelector)
    const loadingState = useTypedSelector(loadingStateSelector)
    const dispatch = useDispatch()
    const lastUpdate = useTypedSelector(lastUpdatedSelector)
    function refresh() {
        dispatch(fetchLocations())
        dispatch(fetchMonaEngineModes())
    }

    useEffect(() => {
        if (loadingState === LoadingState.NotPopulated) {
            dispatch(fetchMonaEngineModes())
        }
    }, [dispatch, loadingState])

    return (
        <>
            <Header
                text={`Mona engine mode for ${title}`}
                refreshFunction={refresh}
                lastUpdate={lastUpdate}
            />
            {isLoading ? <DataLoading /> : <MonaEngineModesLoaded environment={title} />}
        </>
    )
}
