import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const TitleText = styled.h1`
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin: 0;
`

export const Content = styled.div`
    padding-inline: ${spacing(7)};
    padding-block: ${spacing(3)};
`

export const SubtitleText = styled.h2`
    font-style: italic;
    font-weight: ${(props) => props.theme.font.weight.light};
    font-size: 14px;
`

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-inline: ${spacing(8)};
    padding-block-end: ${spacing(4)};
`

export const CloseRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const InstructionTitleText = styled.p`
    font-size: 14px;
    background-color: #f7f8f8;
    margin-block-end: 0;
    padding-block-end: ${spacing(4)};
    padding-inline: ${spacing(8)};
    padding-block-start: ${spacing(2)};
`

export const InstructionBodyText = styled.p`
    font-size: 12px;
    font-style: italic;
    background-color: #f7f8f8;
    padding-block-end: ${spacing(4)};
    padding-inline: ${spacing(8)};
    padding-block-start: ${spacing(2)};
`

export const SubmissionError = styled.div`
    text-align: center;
    padding: ${spacing(2)};
    color: #f34663;
    font-size: 14px;
`

export const ModalDialog = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 40px;
    margin-inline: 25px;
    overflow-y: auto;
    width: 875px;
    max-height: 80vh;
    border-radius: 6px;
    height: 100%;
`

export const Logo = styled.div`
    text-align: center;
    height: 100%;
    margin-bottom: ${spacing(5)};
`

export const QuestionRow = styled.div`
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-auto-rows: 50px;
    column-gap: 20px;
`

export const QuestionText = styled.div`
    font-size: 14px;
`

export const QuestionFieldset = styled.fieldset`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${spacing(4)};
`

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TopSection = styled.div`
    height: 45%;
`

export const QuestionOptionLabel = styled.label`
    margin: auto;
`

export const MiddleSection = styled.div`
    max-height: 30vh;
    overflow-y: scroll;
    background-color: #f7f8f8;
`

export const QuestionsWrapper = styled.div`
    padding: ${spacing(3)} ${spacing(8)};
`

export const BottomSection = styled.div`
    padding-block-start: ${spacing(4)};
    height: 10%;
`
