import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {userSubscriptionsSelector} from '../../../../../../store/state/saved-filters/selectors'
import {useUserManagement} from '../../../../context/use-user-management'
import {DataInput} from '../data-input/data-input'
import {SavedFilterAlertRow} from './saved-filter-alert-row'
import * as Styled from './saved-filters-alerts.styled'

export function SavedFiltersAlertsContent(): JSX.Element {
    const {initialUserDetails, activeUserDetails, displayUserDetails, changeNotificationEmail} =
        useUserManagement()
    const activeUserSubscriptions = useTypedSelector(userSubscriptionsSelector)
    const noSubscriptions = !activeUserSubscriptions || activeUserSubscriptions?.length === 0

    return (
        <Styled.DetailsLayout>
            <Styled.Details>
                <DataInput
                    label="Email for Alerts"
                    gridArea="EMAIL"
                    initialValue={displayUserDetails.notificationEmail ?? ''}
                    setChange={changeNotificationEmail}
                    changed={
                        activeUserDetails.notificationEmail !== initialUserDetails.notificationEmail
                    }
                    width={{label: 100, input: 190}}
                />
            </Styled.Details>
            <Styled.ScrollableWrapper noData={noSubscriptions}>
                {noSubscriptions ? (
                    <Styled.NoSavedFiltersAlerts>
                        No Saved Filters Alerts
                    </Styled.NoSavedFiltersAlerts>
                ) : (
                    <>
                        {activeUserSubscriptions.map((data, index) => (
                            <SavedFilterAlertRow key={index} subscriptionData={data} />
                        ))}
                    </>
                )}
            </Styled.ScrollableWrapper>
        </Styled.DetailsLayout>
    )
}
