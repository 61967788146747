import {useEffect, useState} from 'react'
import {DropdownConfig} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'
import * as Styled from './_styled/drop-down-config.styled'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {isEqual} from 'lodash'

interface DropDownConfigProps {
    metricConfigId: GuidType
    configItem: DropdownConfig
    disabled?: boolean
}

export function DropDownConfig({
    metricConfigId,
    configItem,
    disabled = false,
}: DropDownConfigProps): JSX.Element {
    const initialDropDownValue = configItem.value ?? configItem.default
    const [dropDownValue, setDropDownValue] = useState(initialDropDownValue)
    const {changeDropDownValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()

    useEffect(() => {
        if (discardChanges && !isEqual(initialDropDownValue, dropDownValue)) {
            setDropDownValue(initialDropDownValue)
            requestDiscardChanges(false)
        }
    }, [dropDownValue, discardChanges, initialDropDownValue, requestDiscardChanges])

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        if (disabled) {
            return
        }
        const isItChanged = !isEqual(initialDropDownValue, event.target.value)
        setDropDownValue(event.target.value)
        changeDropDownValue(metricConfigId, configItem.name, event.target.value, isItChanged)
    }

    const findDefaultValue = Object.entries(configItem?.options).find(
        ([key]) => key === configItem.default,
    )
    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Label>{configItem.displayName ?? configItem.name}</Styled.Label>
            <Styled.Input>
                <Styled.Select onChange={onChangeWrapper} value={dropDownValue}>
                    <option key="default-option" value={configItem.default}>
                        {(findDefaultValue && findDefaultValue[1]) ?? 'NA'}
                    </option>
                    {Object.entries(configItem.options)
                        ?.filter(([key]) => key !== configItem?.default)
                        .map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
