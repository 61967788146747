import styled from 'styled-components'
import {mediumLargeFont, mediumSmallFont, smallFont} from '../../../../theme/font-styles'
import {BenchmarkTrendsType} from '../../../../store/state/metrics-filter-beta/state'
interface SectionProps {
    showFilterBar: boolean
    width: number
}
export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.showFilterBar ? '380px' : props.width > 540 ? '430px' : '300px')};
`
interface TitleWrapperProps {
    scorecardView: boolean
}
export const TitleWrapper = styled.div<TitleWrapperProps>`
    display: flex;
    column-gap: 15px;
    ${(props) => (props.scorecardView ? 'padding: 5px 0' : 'padding: 20px 0')};
    ${mediumLargeFont()}
    align-items: center;
`
export const Title = styled.div`
    margin-left: 25px;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const ToolTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 4px 8px #0000004d;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
`
export const ToolTipSubTitle = styled.div`
    padding: 0;
    margin: 0 15px 10px 15px;
    font-size: 12px;
    line-height: 20px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const ToolTipTitle = styled.h4`
    padding: 0;
    margin: 15px 15px 5px 15px;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`
export const SubTitle = styled.div`
    font-style: italic;
`
interface ColouredLegendProps {
    benchmarkType?: BenchmarkTrendsType
}
export const ColouredLegend = styled.div<ColouredLegendProps>`
    width: 35px;
    border: none;
    ${(props) =>
        props.benchmarkType === 'All customers'
            ? 'border-top: 2px dashed #F34663'
            : props.benchmarkType === 'Whole fleet'
              ? 'border-top: 2px solid #F34663'
              : 'border-top: 2px solid black'};
`
export const LegendText = styled.div`
    ${smallFont()}
    display: flex;
    align-items: center;
    gap: 5px;
`
