import {LoadingSpinner} from '../../../../../components/loading/loading'
import * as styled from './data-loading.styled'
interface DataLoadingProps {
    forceHeight?: boolean
}

export const DataLoading = ({forceHeight}: DataLoadingProps): JSX.Element => (
    <styled.DataLoading forceHeight={forceHeight}>
        <LoadingSpinner size={100} />
    </styled.DataLoading>
)
