import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {SoftwareInventoryModelOutput} from '../../contexts/types/software-inventory-model-output'
import * as Styled from './_styled/card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/card-data-cell.styled'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    formattedUSDeviceData: SoftwareInventoryModelOutput
    identifier: string
    onClickFn?: () => void
}
export function CardDataCell({
    label,
    children,
    gridArea,
    formattedUSDeviceData,
    identifier,
    onClickFn,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `formattedSoftrwareData-${identifier}_${formattedUSDeviceData.identity}`
    return (
        <Styled.CardDataCell gridArea={gridArea} titleOnSameLine={fixedWidthReducedText}>
            <Styled.Label width={width} htmlFor={id} id={`${label}-${id}`}>
                {label}
            </Styled.Label>
            <div style={{wordBreak: 'break-word'}} id={`${children}-${id}`} onClick={onClickFn}>
                {children}
            </div>
        </Styled.CardDataCell>
    )
}
