import * as Styled from './_styled/self-monitoring-cards-populated.styled'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {SelfMonitoringEngineCard} from './self-monitoring-card'

interface SelfMonitoringEngineCardsProps {
    selfMonitoringEngineEvents: SelfMonitoringEngineEvent[]
}

export function SelfMonitoringEngineCardsPopulated({
    selfMonitoringEngineEvents,
}: SelfMonitoringEngineCardsProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.CardArea width={width}>
            {selfMonitoringEngineEvents.map((selfMonitoringEngineEvent) => (
                <SelfMonitoringEngineCard
                    key={`${selfMonitoringEngineEvent.identity}`}
                    selfMonitoringEngineEvent={selfMonitoringEngineEvent}
                />
            ))}
        </Styled.CardArea>
    )
}
