import {LockImage, LabelWrapper} from './tag-limit.styled'
import LOCKED from '../../../../../@assets/icons/locked.svg'
import UNLOCKED from '../../../../../@assets/icons/unlocked.svg'

export function LabelLock(): JSX.Element {
    return (
        <LabelWrapper>
            <LockImage src={LOCKED} />
            <LockImage src={UNLOCKED} />
        </LabelWrapper>
    )
}
