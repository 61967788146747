import styled from 'styled-components'

interface ExpandButtonProps {
    id: string
    onClick: () => void
    isExpanded: boolean
    disabled?: boolean
}

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
    color: #1f88e5;

    fill: red;
`

const StyledSvg = styled.svg<{isDisabled: boolean; isExpanded: boolean}>`
    fill: ${(prop) => (!prop.isDisabled ? '#1f88e5' : 'grey')};
    transition: transform 0.2s ease-in-out;
    transform: ${({isExpanded}) => (!isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export function ExpandButton({id, onClick, isExpanded, disabled = false}: ExpandButtonProps) {
    return (
        <StyledButton onClick={onClick} id={id} disabled={disabled}>
            <StyledSvg
                isExpanded={isExpanded}
                isDisabled={disabled}
                width="15"
                height="15"
                viewBox="0 0 17 17"
            >
                <g>
                    <path
                        data-name="Path 4490"
                        d="M13.072 10.241a.724.724 0 000-1.072L9.035 5.422a.857.857 0 00-1.122-.024L3.935 9.09a.722.722 0 000 1.072.857.857 0 001.152 0L8.5 7.029l3.421 3.216a.857.857 0 001.151-.004z"
                    />
                    <path
                        data-name="Path 4491"
                        d="M17 8.5A8.5 8.5 0 108.5 17 8.5 8.5 0 0017 8.5zM3.416 13.584A7.19 7.19 0 1113.584 3.416 7.19 7.19 0 113.416 13.584z"
                    />
                </g>
            </StyledSvg>
        </StyledButton>
    )
}
