import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {LocationIdType} from '../../../../../store/state/locations/state'
import {filteredNodeIdsForLocationCachedReselector} from '../../reselectors/filtered-node-ids-for-location-cached-reselector'
import {AssetThreatScore} from '../common/asset-threat-score'
import * as Styles from './assets-grid.styled'

interface Props {
    id: LocationIdType
}

export function AssetsGrid({id}: Props): JSX.Element {
    const ids = useTypedSelector((state) => filteredNodeIdsForLocationCachedReselector(state, id))
    return (
        <Styles.AssetsGrid id={`assets-grid-${id}`}>
            {ids &&
                ids.map((assetId) => (
                    <Styles.AssetsCell key={`asset-threat-score-cell-${id}-${assetId}`}>
                        <AssetThreatScore id={assetId} />
                    </Styles.AssetsCell>
                ))}
        </Styles.AssetsGrid>
    )
}
