import {ReferenceArea} from 'recharts'
import {CyberOwlTheme} from '../../../../../../theme/theme'

// use like a custom component, but must be a function as recharts will render data returned by a function, not a custom component
export function redRiskBackground(theme: CyberOwlTheme, upperThreshold: number): JSX.Element {
    return (
        <ReferenceArea
            y1={upperThreshold}
            y2={100}
            fill={theme.threatScores.score.high.background}
            fillOpacity={0.3}
        />
    )
}
