import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {User} from '../../../../store/state/users/state'
import {UserListCard} from './user-list-card'
import * as Styled from './_styled/user-management-cards.styled'

interface UserListCardsProps {
    users: User[]
}

export function UserListCards({users}: UserListCardsProps): JSX.Element {
    const {width} = useDimensions()

    return (
        <Styled.CardArea width={width}>
            {users.map((user) => (
                <UserListCard key={user.user} user={user} />
            ))}
        </Styled.CardArea>
    )
}
