import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../theme/theme'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: ${(props) => (props.inset ? '10px' : 0)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    font-size: 12px;
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold || false)};
`
