import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {extraSmallFont, mediumSmallFont, smallFont} from '../../../../theme/font-styles'

export const NotifyMePopupWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;
`

export const ArrowUp = styled.div`
    position: absolute;
    right: 5px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 15px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
`

export const ArrowUpInner = styled.div`
    position: absolute;
    right: 5px;
    top: -12px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 15px solid white;
    z-index: 1;
`

export const NotifyMePopup = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 300px;
    right: -5px;
    top: 10px;
    max-height: 500px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(3)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    gap: ${spacing(2)};
    margin-top: -${spacing(2)};
`

export const TitleRow = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: ${spacing(1)};
    border-bottom: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
`

export const Title = styled.div`
    ${mediumSmallFont()}
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(2)};
    top: ${spacing(2)};
`

export const CurrentFilterDetails = styled.div`
    display: flex;
    justify-content: center;
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(2)} 0 ${spacing(1)};
`

export const Schedule = styled.div`
    display: flex;
    justify-content: center;
    padding: ${spacing(2)} 0 ${spacing(1)};
`
export const Select = styled.select`
    text-align-last: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    background: #f7f8f8;
    height: 25px;
    width: 100px;
    &:focus {
        outline: 0;
    }
`

export const AddPrefix = styled.div`
    display: flex;
    justify-content: center;
    ${smallFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(2)} 0 ${spacing(1)};
`

export const PrefixDetail = styled.div`
    align-self: center;
    display: flex;
    text-align: center;
    width: 180px;
    ${extraSmallFont()};
    color: ${(props) => props.theme.colors.text.default};
    font-style: italic;
    font-weight: ${(props) => props.theme.font.weight.light};
`

export const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${spacing(1)};
`

interface InputFieldProps {
    width?: number
}

export const InputField = styled.div<InputFieldProps>`
    display: flex;
    align-self: center;
    width: ${(props) => props.width ?? 200}px;
    height: 30px;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    border-bottom: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    padding: ${spacing(1)};
`

export const Input = styled.input`
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 180px;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    ${smallFont()};
    &:focus {
        outline: 0;
    }
`

export const XIcon = styled.div`
    padding-left: ${spacing(1)};
    color: ${(props) => props.theme.colors.text.default};
    cursor: pointer;
`

export const ButtonsArea = styled.div`
    display: flex;
    justify-content: center;
    column-gap: ${spacing(4)};
    padding-top: ${spacing(2)};
`

export const ButtonStyled = styled.button`
    ${smallFont()};
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    min-width: 110px;
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`

export const Button = styled(ButtonStyled)`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    min-width: 110px;
`

interface ResultMessageProps {
    isSaved: boolean
}

export const ResultMessage = styled.div<ResultMessageProps>`
    text-align: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) =>
        props.isSaved ? props.theme.colors.text.success : props.theme.colors.text.error};
`

export const Text = styled.div`
    ${smallFont()};
    margin-right: 2px;
`
