import {XCircle} from 'react-feather'
import {LoadingSpinner} from '../../../../components/loading/loading'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {incidentsFilterLoadingSelector} from '../../../../store/state/incidents-filter/selectors'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {AssignedToFilter} from './assigned-to-filter/assigned-to-filter'
import * as Styles from './filter-bar.styled'
import {FilterBarHeader} from './header/filter-bar-header'
import {IncidentSeverityFilter} from './incident-severity/incident-severity-filter'
import {IncidentStatusFilter} from './incident-status/incident-status-filter'
import {IncidentType} from './incident-type/incident-type'
import {ResetFilterButton} from './reset-filter/reset-filter-button'
import {RaisedOn} from './timestamp-filter/raised-on-filter'
import {UpdatedOn} from './timestamp-filter/updated-on-filter'
import {WatchedIncidents} from './watched-incidents-filter/watched-incidents'
import {SavedSearch} from '../../../../components/saved-search/saved-search'
import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {isInactiveSelector} from '../../../../store/state/saved-filters/selectors'
import {NewNoteFilter} from './new-note-filter/new-note-filter'

export function FilterBar(): JSX.Element {
    const {width} = useDimensions()
    const {displayFilterBar} = usePagedIncidents()
    const isInactive = useTypedSelector(isInactiveSelector)

    if (useTypedSelector(incidentsFilterLoadingSelector)) {
        return (
            <Styles.Loading>
                <LoadingSpinner />
            </Styles.Loading>
        )
    }

    return (
        <Styles.FilterBarWrapper width={width} id={`${PageType.INCIDENTS}_filter-bar`}>
            <div
                style={{position: 'absolute', right: 0, cursor: 'pointer'}}
                onClick={() => {
                    displayFilterBar(false)
                }}
                id={`${PageType.INCIDENTS}_filter-bar_close-icon`}
            >
                <XCircle color="white" fill="blue" />
            </div>
            <Styles.FilterBarStyled>
                <FilterBarHeader />
                <Styles.SavedFilterContent isInactive={isInactive}>
                    <SavedSearch savedFilterPageType={SavedFilterPageType.INCIDENTS} />
                </Styles.SavedFilterContent>
                <Styles.SectionContent
                    isInactive={isInactive}
                    id={`${PageType.INCIDENTS}_filter-bar_section-content`}
                >
                    <IncidentStatusFilter />
                    <IncidentType />
                    <AssignedToFilter />
                    <IncidentSeverityFilter />
                    <RaisedOn />
                    <UpdatedOn />
                    <NewNoteFilter />
                    <WatchedIncidents />
                </Styles.SectionContent>
                <VesselFilters pageType={PageType.INCIDENTS} />
                <ResetFilterButton />
            </Styles.FilterBarStyled>
        </Styles.FilterBarWrapper>
    )
}
