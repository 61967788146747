import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styled from './analysis-selection.styled'
import {ExpandFilterDetailsButton} from '../shared/expand-filter-details-button/expand-filter-details-button'
import {PeriodSelectionFilter} from './period-selection-filter'
import {incidentsReportFilterSelector} from '../../../../../store/state/incidents-report-filter/selectors'
import {FilterBarType} from '../../../../../store/state/incidents-report-filter/state'

export function AnalysisSelectionFilter(): JSX.Element {
    const {filterExpanded} = useTypedSelector(incidentsReportFilterSelector)

    return (
        <Styled.AnalysisPeriod id="AnalysisSelectionFilter">
            <ExpandFilterDetailsButton headingRow={FilterBarType.PeriodSelection} />
            {filterExpanded && filterExpanded.periodExpanded && <PeriodSelectionFilter />}
        </Styled.AnalysisPeriod>
    )
}
