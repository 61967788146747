import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)};
    padding-bottom: 0;
    margin: 0;
    width: 100%;
`

export const Details = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'NAME NAME-VALUE' 'EMAIL EMAIL-VALUE' 'TAG TAG-VALUE' 'LOCK LOCK-VALUE';
    grid-row-gap: ${spacing(3)};
    grid-column-gap: ${spacing(1)};
    padding: ${spacing(2)} ${spacing(1)};
`
