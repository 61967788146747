import {LoadingSpinner} from '../../../../components/loading/loading'
import LoadingState from '../../../../values/loading-state-enum'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {IsolationOperation} from '../../context/types/isolated-assets-type'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {showTrustToggleNoAc, toggleOnNoAc} from '../helpers/data-helpers'
import {SwitchToggle} from '../switch-toggle/switch-toggle'
import {LoadingSpinnerWrapper} from '../table/_styled/trust-device-cell.styled'
import {CardDataCell} from './card-data-cell'

interface TrustDeviceCellCardProps {
    anomaly: FormattedAnomaly
}

export function TrustDeviceCellCardNoAc({anomaly}: TrustDeviceCellCardProps): JSX.Element {
    const {actionTrustedDevice, loadingToggleState} = usePagedUnknownAssetsBeta()
    return (
        <CardDataCell
            gridArea="TRUST-DEVICE"
            label={showTrustToggleNoAc(anomaly.state) ? 'Trust this device?' : ''}
            anomaly={anomaly}
            identifier="trust-device"
        >
            {loadingToggleState.id === anomaly.identity &&
            loadingToggleState.loading === LoadingState.RequestingData ? (
                <LoadingSpinnerWrapper>
                    <LoadingSpinner size={25} />
                </LoadingSpinnerWrapper>
            ) : (
                <div style={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
                    {showTrustToggleNoAc(anomaly.state) && (
                        <>
                            <SwitchToggle
                                onValue={toggleOnNoAc(anomaly.state)}
                                setToggleLeftValue={() => {
                                    actionTrustedDevice(anomaly.identity, IsolationOperation.BLOCK)
                                }}
                                setToggleRightValue={() => {
                                    actionTrustedDevice(anomaly.identity, IsolationOperation.TRUST)
                                }}
                                identifier={anomaly.identity}
                            />
                            Trust
                        </>
                    )}
                </div>
            )}
        </CardDataCell>
    )
}
