import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {
    DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE,
    DetailExpandedType,
    SoftwareInventoryFilterReduxState,
} from './state'

export const softwareInventoryFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.softwareInventoryFilter.loadingState

export const softwareInventoryFilterLoadingSelector = (state: AppState): boolean =>
    state.softwareInventoryFilter.loadingState === LoadingState.RequestingData ||
    state.softwareInventoryFilter.loadingState === LoadingState.NotPopulated

export const softwareInventoryFilterSelector = (
    state: AppState,
): SoftwareInventoryFilterReduxState => state.softwareInventoryFilter

export const isActiveFilterSelector = (state: AppState): boolean =>
    state.softwareInventoryFilter.searchedFirstDetected !==
        DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedFirstDetected ||
    state.softwareInventoryFilter.searchedLastActive !==
        DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedLastActive ||
    state.softwareInventoryFilter.searchedCompanyName !==
        DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedCompanyName ||
    state.softwareInventoryFilter.searchedProductName !==
        DEFAULT_SOFTWARE_INVENTORY_FILTER_STATE.searchedProductName

export const softwareModalDetailExpandedSelector = (state: AppState): DetailExpandedType =>
    state.softwareInventoryFilter.detailExpanded
