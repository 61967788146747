import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import TableRow from './table-row'
import {usePagedIndicators} from '../../../../contexts/indicators/use-paged-indicators'
import LoadingState from '../../../../../../values/loading-state-enum'
import {TableDataGrid} from './styled/table-data-grid.styled'
import {TableHeaderRow} from './table-header-row'
import {LoadingData} from './loading-data'
import {RequestError} from './request-error'
import {NoData} from './no-data'
import {indicatorsLoadingStateSelector} from '../../../../../../store/state/indicators/selectors'
import {fetchIndicators} from '../../../../../../store/state/indicators/action-creators'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'

export function IndicatorsTable(): JSX.Element {
    const dispatch = useDispatch()
    const indicatorLoadingState = useTypedSelector(indicatorsLoadingStateSelector)
    const {refreshData, loading, totalNumberOfIndicators, dataPage} = usePagedIndicators()

    useEffect(() => {
        if (loading === LoadingState.NotPopulated) {
            refreshData()
        }
    }, [loading, refreshData])

    useEffect(() => {
        if (indicatorLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchIndicators())
        }
    }, [dispatch, indicatorLoadingState])

    if (loading === LoadingState.Errored) {
        return <RequestError />
    }

    const showLoading =
        totalNumberOfIndicators == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData ||
        indicatorLoadingState === LoadingState.NotPopulated ||
        indicatorLoadingState === LoadingState.RequestingData

    if (showLoading || !dataPage) {
        return <LoadingData />
    }

    if (totalNumberOfIndicators === 0) {
        return <NoData />
    }

    return (
        <>
            <TableHeaderRow />
            <TableDataGrid>
                {dataPage.map((event, index) => (
                    <TableRow key={`${event.guid}`} event={event} index={index} />
                ))}
            </TableDataGrid>
        </>
    )
}
