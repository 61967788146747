import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {GuidType} from '../../../../values/generic-type-defintions'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {NoDataComponent} from '../../incidents-report-page.styled'
import {IncidentsDetailsExpanded} from './incidents-details-expanded'
import * as Styled from './new-open-incidents-details.styled'

export function NewOpenIncidentsDetails(): JSX.Element {
    const {width} = useDimensions()
    const {listOfIncidentsCodes, displayIncidentDetailsExpanded, incidentDetailExpandedId} =
        usePagedIncidentsReport()
    function onClickFunction(incidentIdentity: GuidType) {
        displayIncidentDetailsExpanded(incidentIdentity)
    }
    return (
        <Styled.Section width={width}>
            <Styled.SectionText>New/Open incidents</Styled.SectionText>
            <Styled.WidgetContainer
                width={width}
                id="incidents-page-top-filter-section-status-fiter-container"
            >
                {!listOfIncidentsCodes.length ? (
                    <NoDataComponent>No data to display</NoDataComponent>
                ) : (
                    listOfIncidentsCodes?.map((incident) => {
                        return (
                            <Styled.LinkText
                                key={incident.incidentIdentity}
                                onClick={() => onClickFunction(incident.incidentIdentity)}
                                active={incidentDetailExpandedId === incident.incidentIdentity}
                            >
                                <span title={incident.incidentCode}>{incident.incidentCode}</span>
                            </Styled.LinkText>
                        )
                    })
                )}
            </Styled.WidgetContainer>
            <IncidentsDetailsExpanded />
        </Styled.Section>
    )
}
