import * as Styled from './_styled/self-monitoring-card.styled'
import {selfMonitoringEngineRulesMapSelector} from '../../../../../store/state/self-monitoring-rules/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {getRuleDescription} from '../data-helpers'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'

interface CardTitleProps {
    event: SelfMonitoringEngineEvent
}
export function CardTitle({event}: CardTitleProps): JSX.Element {
    const rules = useTypedSelector(selfMonitoringEngineRulesMapSelector)
    const nodes = useTypedSelector(nodesSelector)
    const locationMap = useTypedSelector(locationMapSelector)
    return (
        <Styled.CardTitleRow>
            <Styled.CardTitleData id={`message-value_${event.identity}`}>
                {getRuleDescription(event, rules, nodes, locationMap)}
            </Styled.CardTitleData>
        </Styled.CardTitleRow>
    )
}
