import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {
    BREAK_POINT_CARD_VIEW,
    BREAK_POINT_REFRESH_SWITCH,
    SHOW_DETAILS_BREAK_POINT_CARD_VIEW,
} from '../../../user-management.styled'

export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier
        ? width < BREAK_POINT_CARD_VIEW
        : width < SHOW_DETAILS_BREAK_POINT_CARD_VIEW
}

export function getGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(2)}  250px auto 300px 200px 200px 170px  ${spacing(2)}`
    }
    if (width > 1500) {
        return `${spacing(2)}  240px auto 250px 190px 170px 160px  ${spacing(2)}`
    }
    if (width > 1400) {
        return `${spacing(2)}  220px auto 240px 180px 160px 150px  ${spacing(2)}`
    }
    if (width > 1300) {
        return `${spacing(2)}  210px auto 230px 170px 150px 140px  ${spacing(2)}`
    }
    return `${spacing(2)}  200px auto 220px 160px 140px 130px  ${spacing(2)}`
}

export function getReducedGridTemplateColumns(width: number): string {
    if (width > 1600) {
        return `${spacing(2)}  250px 250px 250px 170px 170px 0  ${spacing(2)}`
    }
    if (width > 1500) {
        return `${spacing(2)}  230px 230px 240px 155px 155px 0  ${spacing(2)}`
    }
    if (width > 1400) {
        return `${spacing(2)}  220px 220px 220px 120px 120px 0  ${spacing(2)}`
    }
    return `${spacing(2)}  210px 210px 200px 110px 110px 0  ${spacing(2)}`
}

interface TableHeaderGridProps {
    width: number
    showDetailsBtn: boolean
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showDetailsBtn
            ? getGridTemplateColumns(props.width)
            : getReducedGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
`

interface TableHeaderCellProps {
    gridColumn: number
    width: number
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.width > BREAK_POINT_REFRESH_SWITCH ? 'space-between' : 'center'};
`

export const TableHeaderEmailCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: space-between;
`
