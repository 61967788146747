import * as Styled from './_styled/table.styled'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'
import {getLocationDescription} from '../../../../../store/state/locations/state'

interface TableLocationCellProps {
    monaEngineMode: MonaEngineMode
    gridRow: number
}

export function TableLocationCell({monaEngineMode, gridRow}: TableLocationCellProps): JSX.Element {
    const locations = useTypedSelector(locationMapSelector)
    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={2}
            id={`location-value_${monaEngineMode.anomalyProcessingMode}`}
        >
            {getLocationDescription(locations, monaEngineMode.location)}
        </Styled.TableDataCell>
    )
}
