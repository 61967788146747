import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {metricsPolicyReducer} from './state/reducer'
import {DEFAULT_METRICS_POLICY_STATE} from './type/default-metrics-policy-state'
import {MetricsPolicyState} from './type/metrics-policy-state'
import {MetricsPolicyContext} from './metrics-policy-context'

type MetricsPolicyProviderProps = {
    children: ReactNode | ReactNode[]
}

export function MetricsPolicyProvider({children}: MetricsPolicyProviderProps): JSX.Element {
    const initialState: MetricsPolicyState = cloneDeep(DEFAULT_METRICS_POLICY_STATE)

    const [state, dispatch] = useReducer(metricsPolicyReducer, initialState)

    return (
        <MetricsPolicyContext.Provider value={{state, dispatch}}>
            {children}
        </MetricsPolicyContext.Provider>
    )
}
