import {createSelector} from 'reselect'
import {
    isFetching12HourTrendDataSelector,
    isFetching24HourTrendDataSelector,
    isFetching30DaysTrendDataSelector,
    isFetching7DaysTrendDataSelector,
    isFetching90DaysTrendDataSelector,
} from '../../../../../store/state/ets-trend/selectors'

export const loadingReselector = createSelector(
    isFetching12HourTrendDataSelector,
    isFetching24HourTrendDataSelector,
    isFetching7DaysTrendDataSelector,
    isFetching30DaysTrendDataSelector,
    isFetching90DaysTrendDataSelector,
    (
        ets12HourTrendLoading,
        ets24HourTrendLoading,
        ets7DaysTrendLoading,
        ets30DaysTrendLoading,
        ets90DaysTrendLoading,
    ): boolean => {
        return (
            ets12HourTrendLoading ||
            ets24HourTrendLoading ||
            ets7DaysTrendLoading ||
            ets30DaysTrendLoading ||
            ets90DaysTrendLoading
        )
    },
)
