import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {
    IncidentSeverityValue,
    IncidentSeverityValues,
} from '../../../../../../values/incident-response-values'
import {narrowestLayout} from '../../../cards/grid-helper'

export function getSeverityLevelBackgroundColor(
    severityLevel: IncidentSeverityValue,
    theme: CyberOwlTheme,
): string {
    switch (severityLevel) {
        case IncidentSeverityValues.CRITICAL:
            return theme.incidents.incidentSeverity.active.critical
        case IncidentSeverityValues.HIGH:
            return theme.incidents.incidentSeverity.active.high
        case IncidentSeverityValues.MEDIUM:
            return theme.incidents.incidentSeverity.active.medium
        case IncidentSeverityValues.LOW:
            return theme.incidents.incidentSeverity.active.low
        default:
            return theme.incidents.incidentSeverity.active.low
    }
}

interface SeverityLevelSelectableWrapperProps {
    width: number
}

export const SeverityLevelSelectableWrapper = styled.div<SeverityLevelSelectableWrapperProps>`
    position: relative;
    display: flex;
    justify-content: ${(props) =>
        props.width && narrowestLayout(props.width) ? 'flex-start' : 'center'};
    column-gap: ${spacing(2)};
`

interface SeverityLevelSquareProps {
    severityLevel: IncidentSeverityValue
    withBorder: boolean
}

export const SeverityLevelSquare = styled.span<SeverityLevelSquareProps>`
    color: white;
    background-color: ${(props) =>
        getSeverityLevelBackgroundColor(props.severityLevel, props.theme)};
    height: ${spacing(5.5)};
    width: ${spacing(12)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => (props.withBorder ? '2px solid white;' : 'none')};
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    position: relative;
`

interface TableCellWrapperProps {
    gridRow: number
    gridColumn: number
}

export const TableCellWrapper = styled.div<TableCellWrapperProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
`
