import _ from 'lodash'
import {Location} from '../../../../store/state/locations/state'
import AppState from '../../../../store/types/app-state'

function compareRecord(a: Location, b: Location): number {
    return a.description.localeCompare(b.description)
}

export const sortedLocationsSelector = (state: AppState): Location[] =>
    _.clone(state.locations.locations).sort(compareRecord)
