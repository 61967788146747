import {useContext} from 'react'
import {ModalUploadDocumentContext} from './modal-upload-document-context'
import * as ActionCreators from './state/action-creators'
import {UseModalUploadDocumentResult} from './use-modal-upload-document-output'
import {warn} from '../../../helpers/logging'
import {ProcessingStatus} from '../../../values/processing-save-state-enum'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../store/routerSelectors'
import {REST} from '../../..'
import {useIncidentsPolicy} from '../../../pages/policy-management/contexts/incidents-policy/use-incidents-policy'

const METRICS_UPLOAD_ENDPOINT = '/metrics/api/v1/crewMetricFiles'
const INCIDENTS_UPLOAD_ENDPOINT = '/api/v1/incidentResponses/recommendationPlaybooks'

export function useModaUploadDocument(): UseModalUploadDocumentResult {
    const {state, dispatch, onUploadSuccess} = useContext(ModalUploadDocumentContext)
    if (state == undefined || dispatch == undefined || onUploadSuccess == undefined) {
        throw new Error('useModaUploadDocument must be used within a ModalUploadDocumentContext')
    }

    const queryString = useTypedSelector(queryStringObjectSelector)
    const {activeIncidentPolicyData} = useIncidentsPolicy()

    function setFileValue(value: File | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setFileValue(value))

        if (!value) {
            dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.NOT_PROCESSING))
        }
    }
    function setIsProcessingValue(value: ProcessingStatus): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveProcessingStatus(value))

        if (!value) {
            dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.NOT_PROCESSING))
        }
    }
    async function uploadDocument(file: File): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSING))
        if (state.requestedPage === 'metrics') {
            const metricPolicyId = queryString ? (queryString.uploadDocument as string) : null

            const formData = new FormData()
            formData.append('file', file, file.name)
            await REST.put(`${METRICS_UPLOAD_ENDPOINT}/${metricPolicyId}`, formData)
                .then(() => {
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSED))
                    onUploadSuccess()
                })
                .catch((error) => {
                    dispatch(ActionCreators.setErrorMessageValue(`Error uploading file: ${error}`))
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.FAILED))
                })
        } else if (state.requestedPage === 'incidents') {
            const incidentResponsePlaybookTypeGuid = queryString
                ? (queryString.uploadDocument as string)
                : null

            const formData = new FormData()
            formData.append('file', file, file.name)
            formData.append('incidentResponsePolicyGuid', activeIncidentPolicyData.identity)
            formData.append(
                'incidentResponsePlaybookTypeGuid',
                incidentResponsePlaybookTypeGuid as string,
            )
            await REST.put(INCIDENTS_UPLOAD_ENDPOINT, formData)
                .then(() => {
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSED))
                    onUploadSuccess()
                })
                .catch((error) => {
                    dispatch(
                        ActionCreators.setErrorMessageValue(
                            `Error uploading file: ${error}. Please try again`,
                        ),
                    )
                    dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.FAILED))
                })
        }
    }
    return {
        requestedPage: state.requestedPage,
        isProcessingSave: state.isProcessingSave,
        file: state.file,
        fileName: state.file?.name ?? '',
        fileSize: formatFileSize(state.file?.size ?? 0),
        errorMessage: state.errorMessage,
        setFileValue,
        setIsProcessingValue,
        uploadDocument,
    }
}

// Utility function to format file size
function formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let unitIndex = 0
    let formattedSize = size

    while (formattedSize >= 1024 && unitIndex < units.length - 1) {
        formattedSize /= 1024
        unitIndex += 1
    }

    return `${formattedSize.toFixed(1)} ${units[unitIndex]}`
}
