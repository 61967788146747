import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {ReactNode, ReactNodeArray} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {CogButton} from '../../general/header/cog-button/cog-button'
import {useFleetScoreOverTime} from '../contexts/use-fleet-score-over-time'
import {TagsFilter} from './tag-filter/tag-filter'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import {StrictMode} from '../../../../../store/state/users/state'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNodeArray
}

export function WidgetWrapper({gridLayout, children}: WidgetWrapperProps): JSX.Element {
    const {showFilter, setShowFilter, searchedVesselTagTerm} = useFleetScoreOverTime()
    const {strictMode} = useTypedSelector(currentUserSelector)

    return (
        <Widget>
            <Header gridLayout={gridLayout} id="fleet-score-over-time-widget-header">
                <HeaderContent gridLayout={gridLayout}>
                    <TitleText gridLayout={gridLayout} id="fleet-score-over-time-widget-title">
                        Fleet Timeline
                    </TitleText>
                    {strictMode !== StrictMode.Strict && (
                        <CogButton
                            filterApplied={searchedVesselTagTerm !== undefined}
                            onClick={() => setShowFilter(!showFilter)}
                            widget="fleet-score"
                        />
                    )}
                </HeaderContent>
            </Header>
            {showFilter && <TagsFilter />}
            {children}
        </Widget>
    )
}
