import {animated} from 'react-spring'
import styled from 'styled-components'

export const Value = styled(animated.div)`
    color: white;
    font-weight: 700;
    font-size: 1.3125rem;
    line-height: 1.75rem;
    letter-spacing: 0.02em;
`
