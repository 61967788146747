import {ReactNode, useReducer} from 'react'
import {PagedNmeaInventoryContext} from './paged-nmea-inventory-context'
import {pagedNmeaInventoryReducer} from './state/reducer'
import {DEFAULT_PAGED_NMEA_INVENTORY_STATE} from './types/default-paged-nmea-inventory-state'
import {PagedNmeaInventoryState} from './types/paged-nmea-inventory-state'
import {cloneDeep} from 'lodash'

type PagedNmeaInventoryProviderProps = {
    children: ReactNode | ReactNode[]
    pageSize: number
}

export function PagedNmeaInventoryProvider({
    children,
    pageSize,
}: PagedNmeaInventoryProviderProps): JSX.Element {
    const initialState: PagedNmeaInventoryState = cloneDeep(DEFAULT_PAGED_NMEA_INVENTORY_STATE)
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedNmeaInventoryReducer, initialState)

    return (
        <PagedNmeaInventoryContext.Provider value={{state, dispatch}}>
            {children}
        </PagedNmeaInventoryContext.Provider>
    )
}
