import {MouseEvent, ReactNode, ReactNodeArray} from 'react'
import {NotSelectedTab} from './not-selected-tab.styled'
import {SelectedTab} from './selected-tab.styled'

interface TabProps {
    activeTab: number
    index: number
    children: ReactNode | ReactNodeArray
    toggle: (event: MouseEvent, tab: number) => void
    isFirstTab?: boolean
}
export function Tab({
    activeTab,
    index,
    children,
    toggle,
    isFirstTab = false,
}: TabProps): JSX.Element {
    if (activeTab === index) {
        return <SelectedTab firstTab={isFirstTab}>{children}</SelectedTab>
    }

    return (
        <NotSelectedTab onClick={(e: MouseEvent) => toggle(e, index)} firstTab={isFirstTab}>
            {children}
        </NotSelectedTab>
    )
}
