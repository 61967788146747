import * as Styled from './_styled/self-monitoring-card.styled'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import moment from 'moment'

interface CardTimestampCellProps {
    event: SelfMonitoringEngineEvent
    fixedWidthReducedText: boolean
}

export function CardTimestampCell({
    event,
    fixedWidthReducedText,
}: CardTimestampCellProps): JSX.Element {
    const timeStamp = moment(event.timestamp)

    return (
        <Styled.CardDataCell titleOnSameLine={fixedWidthReducedText}>
            <Styled.ReducedText fixedWidth={fixedWidthReducedText}>Timestamp:</Styled.ReducedText>
            {fixedWidthReducedText ? (
                <Styled.TimestampValue id={`timestamp-value_${event.identity}`}>
                    {timeStamp.fromNow()}
                    <Styled.TimestampSpan>
                        {timeStamp.format('(DD MMM YYYY - HH:mm)')}
                    </Styled.TimestampSpan>
                </Styled.TimestampValue>
            ) : (
                <>
                    <div id={`timestamp-value_${event.identity}`}>{timeStamp.fromNow()}</div>
                    <div>{timeStamp.format('DD MMM YYYY - HH:mm')}</div>
                </>
            )}
        </Styled.CardDataCell>
    )
}
