import styled from 'styled-components'
import {Colors} from '../../theme/colours'
import {spacing} from '../../theme/spacing'

export const Dialog = styled.dialog`
    min-width: 370px;
    max-width: 400px;
    border: 2px solid ${Colors.grey6};
    &::backdrop {
        background: hsl(0 0% 0% / 50%);
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
`

export const TopDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(3)};
    margin-bottom: ${spacing(5)};
`

export const ActionDiv = styled.div`
    display: flex;
    gap: ${spacing(2)};
    justify-content: space-around;
`

export const ErrorDiv = styled.div`
    font-size: 14px;
    padding: ${spacing(2)};
    border-radius: 5px;
    background-color: rgba(185, 28, 28, 0.65);
    color: white;
`

export const BottomDiv = styled.div`
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ConfirmBtn = styled.button`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 4px;
    padding: 6px 12px;
    display: flex;
    gap: ${spacing(1)};
`

export const CancelBtn = styled.button`
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 6px 12px;
    border-color: ${(props) => props.theme.refreshButton.active.borderColor};

    :disabled {
        background-color: ${Colors.grey1};
    }
`

export const Title = styled.h2`
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    margin: 0;
`

export const Message = styled.span`
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
`
