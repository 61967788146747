import styled from 'styled-components'
import {TableDataCell} from './table-data-cell.styled'

interface TableErrorCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
}

export const TableErrorCell = styled(TableDataCell)<TableErrorCellProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
`
