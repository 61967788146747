import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface CardAreaProps {
    width: number
}

export const CardArea = styled.div<CardAreaProps>`
    flex: 1;
    display: grid;
    gap: ${spacing(2)};
    padding: ${(props) => (props.width < 600 ? `${spacing(2)}` : `${spacing(2)} ${spacing(4)}`)};
    background-color: ${(props) => props.theme.unknownAssets.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
