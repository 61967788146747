import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const AssetDetailsAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
`

export const AssetDetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    gap: ${spacing(1)};
`
