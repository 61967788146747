import {IncidentTypeText, IncidentTypeWrapper} from './incident-type.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {incidentTypesSelector} from '../../../../../../store/state/incident-types/selectors'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {getMatchingIcon, getTypeDisplayName} from '../../../../contexts/types/incident-type'
import {getIncidentTypeIcon} from '../../../../data-helpers'

interface IncidentTypeProps {
    incidentType: string
    id: GuidType
}

export function IncidentType({incidentType, id}: IncidentTypeProps): JSX.Element {
    const incidentTypes = useTypedSelector(incidentTypesSelector)
    return (
        <IncidentTypeWrapper id={id} gridArea="INCIDENT-TYPE">
            <img
                src={getIncidentTypeIcon(getMatchingIcon(incidentTypes, incidentType))}
                height={16}
                width={16}
            />
            <IncidentTypeText>{getTypeDisplayName(incidentTypes, incidentType)}</IncidentTypeText>
        </IncidentTypeWrapper>
    )
}
