import {usePagedIncidents} from '../../../../contexts/use-paged-incidents'
import {MouseEvent} from 'react'
import {Button} from './button.styled'

export function CancelButton(): JSX.Element {
    const {showCloseMonitorIncidentModal} = usePagedIncidents()
    function cancelChanges(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        showCloseMonitorIncidentModal(false)
    }
    return (
        <Button id="cancel-changes-button" onClick={cancelChanges}>
            <div>Cancel</div>
            <div>Leave incident in current state</div>
        </Button>
    )
}
