import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'

import {Api, QuerySpecification} from '../../../api/Api'
import {QueryCancellation} from '../../../websocket/Queries'
import AppState from '../../types/app-state'
import {LocationTagsSummaryReduxState} from './state'
import {TIME_RANGE_VALUES} from '../../../values/TimeRangeValues'

const QUERY_PATH = 'locationTagsSummary'
const QUERY_SCHEDULE = '15s'

function locationTagsSummaryQuery(timeRange: TIME_RANGE_VALUES): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: QUERY_PATH,
        schedule: QUERY_SCHEDULE,
        params: {duration: timeRange},
    }
}

export function fetchLocationTagsSummary(
    timeRange: TIME_RANGE_VALUES,
): ThunkAction<QueryCancellation, AppState, Api, Actions.Action> {
    return (dispatch, _, api) => {
        dispatch(requestLocationTagsSummary(timeRange))

        const query: QuerySpecification = locationTagsSummaryQuery(timeRange)

        api.newQuery(query, (data) => {
            dispatch(receiveLocationTagsSummary(data))
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

export function requestLocationTagsSummary(
    timeRange: TIME_RANGE_VALUES,
): Actions.RequestLocationTagsSummary {
    return {
        type: ActionType.REQUEST_LOCATION_TAGS_SUMMARY,
        payload: timeRange,
    }
}

export function receiveLocationTagsSummary(
    data: LocationTagsSummaryReduxState,
): Actions.ReceiveLocationTagsSummary {
    return {
        type: ActionType.RECEIVE_LOCATION_TAGS_SUMMARY,
        payload: data,
    }
}
