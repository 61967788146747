import * as Styles from './_styled/software-details.styled'
import {DetailPanelHeader} from './header/detail-panel-header'
import {ActionsSection} from './actions/actions-section'
import {SoftwareDetailedSection} from './software-details/software-detailed'
import {SoftwareDetailsSection} from './software-details/software-details'
import {ActivitySection} from './activity/activity-section'

export function SoftwareDetails(): JSX.Element {
    return (
        <Styles.Container
            onClick={(e) => e.stopPropagation()}
            id="software-details-modal_container"
        >
            <DetailPanelHeader />
            <SoftwareDetailedSection />
            <SoftwareDetailsSection />
            <ActivitySection />
            <ActionsSection />
        </Styles.Container>
    )
}
