import * as Styled from './enforcement-policy-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {DetailsSection} from './details-section/details-section'
import {ContentWrapper} from '../_styled/policy-details-modal.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {PolicyName} from './policy-name/policy-name'
import {ButtonsArea} from './buttons-area/buttons-area'

export function AddNewEnforcementPolicyDetails(): JSX.Element | null {
    const {width} = useDimensions()

    return (
        <ContentWrapper width={width}>
            <Styled.Container onClick={(e) => e.stopPropagation()}>
                <DetailPanelHeader text="Add new enforcement policy" />
                <Styled.PolicyNameWrapper>
                    <PolicyName />
                    <ButtonsArea policyToCreate />
                </Styled.PolicyNameWrapper>
                <DetailsSection />
            </Styled.Container>
        </ContentWrapper>
    )
}
