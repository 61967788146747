import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const TitleText = styled.h1`
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin: 0;
`

export const Content = styled.div`
    padding-inline: 8px;
    padding-block: 8px;
`

export const TitleRow = styled.div`
    padding-inline: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const CloseRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const GrayDivider = styled.hr`
    border: none;
    border-bottom: 1px solid #e2e5e9;
    margin-inline: ${spacing(3)};
`

export const UploadWarningText = styled.div`
    font-style: italic;
    font-size: 14px;
    text-align: center;
    margin-top: ${spacing(1)};
    color: black;
`

export const TemplateText = styled.div`
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: black;
`

export const DragAndDropWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const FileListTitle = styled.div`
    display: flex;
    align-content: center;
    gap: ${spacing(1)};
`

export const FileListWrapper = styled.div`
    margin-top: ${spacing(6)};
    margin-inline: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    overflow-y: hidden;
`

export const FileListItemsWrapper = styled.div`
    display: flex;
    gap: ${spacing(2)};
    flex-direction: column;
    margin-top: ${spacing(2)};
    max-height: 500px;
    overflow-y: auto;
`

export const FileItem = styled.div`
    display: flex;
    align-items: center;
    border: 3px solid #e2e5e9;
    color: black;
    padding-block: ${spacing(3)};
    padding-inline: ${spacing(4)};
    gap: ${spacing(1)};
`

export const FileItemElement = styled.div<{flexGrow?: number; textAlign?: string; color?: string}>`
    font-size: 14px;
    flex-grow: ${(props) => props.flexGrow ?? 1};
    text-align: ${(props) => props.textAlign ?? 'left'};
    color: ${(props) => props.color ?? 'black'};
`
export const UploadError = styled.div<{status: 'error' | 'success'}>`
    text-align: center;
    padding: ${spacing(4)};
    color: ${(props) => (props.status === 'error' ? '#F34663' : '#0E7A0E')};
    font-size: 14px;
`

export const ModalDialog = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 40px;
    margin-inline: 25px;
    overflow-y: auto;
    width: 875px;
    max-height: 80vh;
    border-radius: 6px;
`
