import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../store/state/email-recipient-management/selectors'
import {TableHeader} from './email-management-header'
import {TableRow} from './table-row'
import * as Styled from './_styled/email-management-list.styled'

export function EmailManagementList(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const data = useTypedSelector(emailRecipientDataSelector)
    return (
        <div style={{minHeight: '200px'}}>
            <Styled.ContentContainer
                width={width}
                backgroundColor={theme.systemPage.dataArea.background}
            >
                <TableHeader />
            </Styled.ContentContainer>
            <Styled.ContentContainer width={width}>
                <Styled.TableDataGrid width={width}>
                    {data.map((emailRecipient, index) => (
                        <TableRow
                            key={emailRecipient.identity}
                            emailRecipient={emailRecipient}
                            index={index}
                        />
                    ))}
                </Styled.TableDataGrid>
            </Styled.ContentContainer>
        </div>
    )
}
