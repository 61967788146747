import styled from 'styled-components'
import {getColor as getMetricTypeColor} from '../../../../../metrics-beta/components/metrics-summaries/score-bar.styled'
import {spacing} from '../../../../../../theme/spacing'
import {smallFont} from '../../../../../../theme/font-styles'

export const ScoreBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 45px;
    justify-content: space-around;
`
interface ColorWrapperProps {
    metricType: string
}
export const ColorWrapper = styled.div<ColorWrapperProps>`
    color: ${(props) => getMetricTypeColor(props.metricType)};
    display: flex;
    justify-content: space-between;
    gap: ${spacing(2)};
    width: 35px;
`

export const MetricIcon = styled.img`
    width: 35px;
    height: 35px;
`
export const Score = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const ScoreTextWrapper = styled.div`
    position: absolute;
    top: 0;
    right: -15px;
`
export const ScoreBar = styled.div`
    height: 20px;
    width: 300px;
    background-color: #f29dac;
    border-radius: 5px;
    position: relative;
`
interface ScoreBarNoIssueProps {
    score: number
    metricType: string
}

export const ScoreBarNoIssue = styled.div<ScoreBarNoIssueProps>`
    height: 20px;
    width: ${(props) => (getScoreDisplay(props.score) / 100) * 300}px;
    background-color: ${(props) => getMetricTypeColor(props.metricType)};
    border-radius: 5px 0 0 5px;
`
function getScoreDisplay(score: number): number {
    return score === 0 ? 2 : score
}
interface TippyWrapperProps {
    positionOfText: boolean
}
export const TippyWrapper = styled.div<TippyWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    ${(props) => (props.positionOfText ? 'bottom: 5px' : 'top: 5px')};
    left: 20px;
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    z-index: 100;
`
