import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)} 0;
`

export const Image = styled.input.attrs({type: 'image'})`
    margin-left: ${spacing(2)};
    width: 17px;
    height: 19px;
    cursor: pointer;
    pointer-events: auto;
    :disabled {
        cursor: default;
        opacity: 0.5;
    }
`

export const ImageForNotSubscribe = styled.input.attrs({type: 'image'})`
    margin-left: ${spacing(2)};
    width: 17px;
    height: 19px;
    opacity: 0.5;
`

export const PopupWrapper = styled.div``
