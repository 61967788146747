export enum ActionType {
    CHANGE_PAGE_SIZE_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] CHANGE PAGE SIZE ACTION',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    SWITCH_TO_CACHED_PAGE_DATA_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] SWITCH TO CACHED PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    SET_ERROR_ACTION = '[PAGED UNIQUE FLOWS CONTEXT] SET ERROR ACTION',
    CLEAR_DATE_FILTER = '[PAGED UNIQUE FLOWS CONTEXT] CLEAR DATE FILTER',
    SET_DATE_FILTER = '[PAGED UNIQUE FLOWS CONTEXT] SET DATE FILTER',
}
