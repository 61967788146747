import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {ZOOM_LEVEL} from '../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {Button} from './button'
import * as Styles from './zoom-level.styled'

interface Props {
    inFilterBar?: boolean
}

export function ZoomLevel({inFilterBar = false}: Props): JSX.Element {
    const {width} = useDimensions()
    const shortText = !inFilterBar && width < 480
    const twoLine = !inFilterBar && width < 320
    return (
        <Styles.ZoomLevel twoLine={twoLine} inFilterBar={inFilterBar} id="zoom-level">
            <Styles.Label>{shortText ? 'Zoom' : 'Vessel Zoom Level'}</Styles.Label>
            <Styles.Options>
                <Styles.LeftMostOption>
                    <Button zoomLevel={ZOOM_LEVEL.ONE} />
                </Styles.LeftMostOption>
                <Styles.MiddleOption>
                    <Button zoomLevel={ZOOM_LEVEL.TWO} />
                </Styles.MiddleOption>
                <Styles.MiddleOption>
                    <Button zoomLevel={ZOOM_LEVEL.THREE} />
                </Styles.MiddleOption>
                <Styles.RightMostOption>
                    <Button zoomLevel={ZOOM_LEVEL.FOUR} />
                </Styles.RightMostOption>
            </Styles.Options>
        </Styles.ZoomLevel>
    )
}
