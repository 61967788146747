import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {
    getMaximumNumberOfPagesToShow,
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {SpacingBlank} from '../../../../../components/paging/basic/styling/spacing-blank.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedIndicators} from '../../../contexts/indicators/use-paged-indicators'

function IndicatorPagination(): JSX.Element {
    const {selectedPage, totalNumberOfPages, selectPage, pageSize} = usePagedIndicators()
    const {width} = useDimensions()

    if (
        selectPage == null ||
        selectedPage == undefined ||
        totalNumberOfPages == undefined ||
        pageSize == undefined
    ) {
        return <SpacingBlank />
    }
    const maximumPagesToShow = getMaximumNumberOfPagesToShow(totalNumberOfPages)
    return (
        <BasicPaging
            selectedPage={selectedPage}
            totalNumberOfPages={maximumPagesToShow}
            selectPage={selectPage}
            pagesToShow={getPagesToShow(width)}
            pageSize={pageSize}
            showButtons={getPagingButtonsToShow(width, maximumPagesToShow)}
        />
    )
}

export default IndicatorPagination
