import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Select = styled.select`
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 5px 0;
    ${smallFont()}
    border: 1px solid #1f88e5;
    border-radius: 5px;
    background: #f7f8f8;
    height: 31px;
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export const Label = styled.label`
    ${smallFont()}
    margin-bottom: ${spacing(1)};
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    color: #6d7c92;
`
