import {FilterIndicator} from './applied-filter-indicator.styled'

interface AppliedPropertiesFilterIndicatorProps {
    searchedProperties?: string | undefined
}

export function AppliedPropertiesFilterIndicator({
    searchedProperties,
}: AppliedPropertiesFilterIndicatorProps): JSX.Element {
    return <FilterIndicator>Showing result for {searchedProperties}</FilterIndicator>
}
