import {ThreatValue, ThreatValues} from '../../helpers/threatHelper'
import {NodeData, NodeValue, NodeValues} from '../../values/nodes/NodeData'
import {CyberOwlTheme} from '../theme'

export function getColorFromNode(node: NodeData | undefined, theme: CyberOwlTheme): string {
    if (!node) {
        return theme.threatScores.title.unknown.text
    }

    return getColorFromNodeValue(node.value, theme)
}

export function getColorFromNodeValue(nodeValue: NodeValue, theme: CyberOwlTheme): string {
    switch (nodeValue) {
        case NodeValues.HIGH:
            return theme.threatScores.title.high.text

        case NodeValues.MEDIUM:
            return theme.threatScores.title.medium.text
        case NodeValues.LOW:
        default:
            return theme.threatScores.title.low.text
    }
}

export function getBackgroundColorFromNode(
    node: NodeData | undefined,
    theme: CyberOwlTheme,
): string {
    if (!node) {
        return theme.threatScores.title.unknown.background
    }

    return getBackgroundColorFromNodeValue(node.value, theme)
}

export function getBackgroundColorFromNodeValue(
    nodeValue: NodeValue,
    theme: CyberOwlTheme,
): string {
    switch (nodeValue) {
        case NodeValues.HIGH:
            return theme.threatScores.title.high.background
        case NodeValues.MEDIUM:
            return theme.threatScores.title.medium.background
        case NodeValues.LOW:
        default:
            return theme.threatScores.title.low.background
    }
}

export function getColorFromThreat(
    theme: CyberOwlTheme,
    threatLevel: ThreatValue | undefined,
): string {
    if (threatLevel == undefined) {
        return theme.threatScores.title.unknown.background
    }

    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.text
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.text
        case ThreatValues.LOW:
        default:
            return theme.threatScores.score.lowScoreHighValue.text
    }
}

export function getBackGroundColorFromThreat(
    theme: CyberOwlTheme,
    threatLevel: ThreatValue | undefined,
): string {
    if (threatLevel == undefined) {
        return theme.threatScores.title.unknown.background
    }

    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.background
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.background
        case ThreatValues.LOW:
        default:
            return theme.threatScores.score.lowScoreHighValue.background
    }
}
