import {batch, useDispatch} from 'react-redux'
import * as Styles from './reset-filter-button.styled'
import {resetVesselFilter} from '../../../../../store/state/vessel-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isInactiveSelector} from '../../../../../store/state/saved-filters/selectors'
import {logButtonClickFor} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface ResetFilterButtonProps {
    resetFn: () => void
    currentPage: AuditLogPageType
}

export function ResetFilterButton({resetFn, currentPage}: ResetFilterButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const isInactive = useTypedSelector(isInactiveSelector)

    return (
        <Styles.ButtonWrapper isInactive={isInactive}>
            <Styles.Button
                id="reset-button"
                onClick={(e) => {
                    e.preventDefault()
                    batch(() => {
                        dispatch(resetFn())
                        dispatch(resetVesselFilter())
                        dispatch(logButtonClickFor('Resset filter', currentPage))
                    })
                }}
            >
                Reset
            </Styles.Button>
        </Styles.ButtonWrapper>
    )
}
