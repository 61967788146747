import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {X} from 'react-feather'
import * as Styles from './tag-search.styled'
import {useDispatch} from 'react-redux'
import {usePolicyManagement} from '../../../../../contexts/policy-management/use-policy-management'

export function VesselOnlySearch(): JSX.Element {
    const dispatch = useDispatch()
    const {assignPolicyDialogState, setSearchVesselNameTerm, setSearchVesselTerm, setShowXButton} =
        usePolicyManagement()

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        dispatch(setSearchVesselTerm(e.currentTarget.value))
        if (e.currentTarget.value.length === 0) {
            setShowXButton(false)
            setSearchVesselNameTerm(e.currentTarget.value)
        }
    }

    function onEnterClick(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setSearchVesselNameTerm(assignPolicyDialogState.searchVesselTerm)
            setShowXButton(true)
        }
    }

    function onSearchClick() {
        setSearchVesselNameTerm(assignPolicyDialogState.searchVesselTerm)
        setShowXButton(true)
    }

    function onXClick() {
        setSearchVesselTerm('')
        setSearchVesselNameTerm('')
        setShowXButton(false)
    }

    return (
        <Styles.Container>
            <Styles.SearchInput
                id="vessel-search"
                placeholder="Filter by vessel name"
                autoComplete="off"
                value={assignPolicyDialogState.searchVesselTerm}
                onChange={onChangeFn}
                onKeyPress={onEnterClick}
            />
            {assignPolicyDialogState.showXButton && (
                <Styles.XIcon onClick={() => onXClick()}>
                    <X onClick={() => onXClick()} height={20} width={20} />
                </Styles.XIcon>
            )}
            <Styles.SearchIcon onClick={() => onSearchClick()}>
                <Icon glyph="Search" height={20} width={20} />
            </Styles.SearchIcon>
        </Styles.Container>
    )
}
