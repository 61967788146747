import {CloseButton} from './close-button/close-button'
import * as Styles from './_styled/network-asset-details.styled'
import ERROR_IMG from '../../../../../@assets/images/owl-error.png'
export function NoDataModal(): JSX.Element {
    return (
        <Styles.NoDataContainer>
            <Styles.NoDataContent>
                <Styles.NoDataBox>
                    <img src={ERROR_IMG} />
                    <Styles.NoDataMessage>
                        Sorry, the link you are trying to access is not valid.
                    </Styles.NoDataMessage>
                </Styles.NoDataBox>
            </Styles.NoDataContent>
            <CloseButton />
        </Styles.NoDataContainer>
    )
}
