import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellSensorIp(): JSX.Element {
    const {data, initialData, displayData, changeSensorIp} = useVesselConfig()
    const changed = data.sensorIp !== initialData.sensorIp
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeSensorIp(e.target.value)
    }
    return (
        <DataInput
            label="Sensor IP"
            initialValue={displayData.sensorIp}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
