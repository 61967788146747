import styled from 'styled-components'

export const DisabledButton = styled.div`
    cursor: wait;
    color: ${(props) => props.theme.refreshButton.disabled.textColor};
    border-radius: 4px;
    border: 3px solid ${(props) => props.theme.refreshButton.disabled.borderColor};
    background: none;
    background-color: ${(props) => props.theme.refreshButton.disabled.backgroundColor};
    padding: 4px 8px;
`
