import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {Button} from './button.styled'
import {ButtonText} from './button-text.styled'

interface RefreshButtonProps {
    longText: string
    onClick: () => void
    pageType: string
}

export function RefreshButton({longText, onClick, pageType}: RefreshButtonProps): JSX.Element {
    const {width} = useDimensions()
    const text = width >= 600 ? longText : width >= 350 ? 'Refresh' : ''
    return (
        <Button id={`${pageType}_refresh-button`} onClick={onClick}>
            <Icon glyph="Reset" height={16} width={16} />
            <ButtonText>{text}</ButtonText>
        </Button>
    )
}
