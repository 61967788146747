import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ProcessingStatus} from '../../../context/type/user-management-state'
import {useUserManagement} from '../../../context/use-user-management'
import {DetailHeadingRow} from '../../user-list-table/user-details-modal/_styled/panel-section.styled'
import {DataInput} from './input-area/data-input'
import * as Styled from './add-new-user.styled'
import {ChangeNotSaved} from './data-area/change-not-saved'
import {ChangeSaved} from './data-area/change-saved'
import {SaveInProgress} from './data-area/save-in-progress'
import {SubmitArea} from './submit-area/submit-area'
import {ExternalRolesSelect} from './roles/external-roles-select'
import {InternalRolesSelect} from './roles/internal-roles-select'
import {currentUserSelector} from '../../../../../store/state/current-user/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usersEmailsSelector} from '../../../../../store/state/users/selectors'
import {DataInputEmail} from './input-area/data-input-email'

export function AddNewUser(): JSX.Element {
    const {width} = useDimensions()
    const {isSaveProcessing, newUserData, setNewUserName, setNewUserEmail, setNewUserExternal} =
        useUserManagement()
    const {internalUser} = useTypedSelector(currentUserSelector)
    const usersEmails = useTypedSelector(usersEmailsSelector)

    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        const value = e.target.value === 'external' ? true : false
        setNewUserExternal(value)
    }

    return (
        <Styled.Section onClick={(e) => e.stopPropagation()} width={width}>
            <Styled.Header>
                <Styled.Title>Add new user</Styled.Title>
            </Styled.Header>
            {isSaveProcessing === ProcessingStatus.PROCESSING && <SaveInProgress />}
            {isSaveProcessing === ProcessingStatus.PROCESSED && <ChangeSaved />}
            {isSaveProcessing === ProcessingStatus.FAILED && <ChangeNotSaved />}
            {isSaveProcessing === ProcessingStatus.NOT_PROCESSING && (
                <>
                    <Styled.DetailPanelSection>
                        <DetailHeadingRow>Details</DetailHeadingRow>
                        <Styled.Details>
                            <DataInput
                                label="Name"
                                initialValue={newUserData.username}
                                setChange={setNewUserName}
                                changed={newUserData.username.length !== 0}
                            />
                            <DataInputEmail
                                label="Email"
                                initialValue={newUserData.email}
                                setChange={setNewUserEmail}
                                changed={newUserData.email.length !== 0}
                                blocked={usersEmails.includes(newUserData.email)}
                            />
                        </Styled.Details>
                    </Styled.DetailPanelSection>
                    <Styled.DetailPanelSection>
                        <Styled.RoleHeaderWrapper>
                            <DetailHeadingRow>Roles</DetailHeadingRow>
                            {internalUser && (
                                <Styled.Select onChange={onChangeWrapper} defaultValue={'external'}>
                                    <option value={'external'}>External User</option>
                                    <option value={'internal'}>Internal User</option>
                                </Styled.Select>
                            )}
                        </Styled.RoleHeaderWrapper>
                        <Styled.RoleDetails>
                            {newUserData.external ? (
                                <ExternalRolesSelect />
                            ) : (
                                <InternalRolesSelect />
                            )}
                        </Styled.RoleDetails>
                    </Styled.DetailPanelSection>
                    <Styled.DetailPanelSection>
                        <SubmitArea blocked={usersEmails.includes(newUserData.email)} />
                    </Styled.DetailPanelSection>
                </>
            )}
        </Styled.Section>
    )
}
