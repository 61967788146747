import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const CurrentAppliedVessels = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${spacing(1)} ${spacing(2)};
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: auto;
`

export const CurrentVessel = styled.div`
    ${mediumSmallFont()};
`
