import * as Styled from './message-popup.styled'

export function VesselTagsPopup(): JSX.Element {
    return (
        <Styled.PopupContainer id="vessels-tags-popup">
            <Styled.Message>Alert is unavailable when filtering by:</Styled.Message>
            <Styled.List>- Raised on</Styled.List>
            <Styled.List>- Updated on</Styled.List>
            <Styled.List>- My watched incidents</Styled.List>
        </Styled.PopupContainer>
    )
}
