import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {ReportsFilterReduxState} from './state'

export const reportsFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.reportsFilter.loadingState

export const reportsFilterLoadingSelector = (state: AppState): boolean =>
    state.reportsFilter.loadingState === LoadingState.RequestingData ||
    state.reportsFilter.loadingState === LoadingState.NotPopulated

export const reportsFilterSelector = (state: AppState): ReportsFilterReduxState =>
    state.reportsFilter
