import {GridLayout} from '../../../general/grid-layout.enum'
import {RiskScoreTick} from './styled/risk-score-tick.styled'
import {RiskScoreValue} from './styled/risk-score-value.styled'

interface RiskScoreSquareProps {
    gridLayout: GridLayout
    score: number
}

export function RiskScore({gridLayout, score}: RiskScoreSquareProps): JSX.Element {
    return (
        <>
            <RiskScoreTick score={score} id="riskScore-Tick" />
            <RiskScoreValue score={score} gridLayout={gridLayout} id="riskScore-Value">
                {score}
            </RiskScoreValue>
        </>
    )
}
