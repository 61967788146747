import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {Overlay} from './components/modal-overlay'
import {SelectOTSourceType} from './components/ot-source-type/ot-source-type'
import {DestinationInput} from './components/source-input/destiantion-input'
import {SourceInput} from './components/source-input/source-input'
import {SubmitArea} from './components/submit-area/submit-area'
import * as Styled from './ot-sources-modal.styled'

export function OTSourceModalDisplay(): JSX.Element {
    const {width} = useDimensions()

    return (
        <Overlay>
            <Styled.Section
                onClick={(e) => e.stopPropagation()}
                width={width}
                id="ot-source-section"
            >
                <Styled.Header id="ot-source-Header">
                    <Styled.Title id="ot-source-Title">OT Sources</Styled.Title>
                </Styled.Header>

                <>
                    <Styled.Settings width={width} id="ot-source-Settings">
                        <Styled.Filters width={width} id="ot-source-Filters">
                            <SelectOTSourceType />
                            <SourceInput />
                            <DestinationInput />
                        </Styled.Filters>
                    </Styled.Settings>
                    <Styled.Actions id="ot-source-Actions">
                        <SubmitArea />
                    </Styled.Actions>
                </>
            </Styled.Section>
        </Overlay>
    )
}
