import styled from 'styled-components'

export const DetailIconText = styled.span`
    margin-left: 12px;
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 17px;
`

export const DetailIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.link.primary};
    opacity: 0.5;
`
