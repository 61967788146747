import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../../../store/state/config/selectors'
import {getFormattedDuration} from '../../../data-helpers'
import * as Styled from './close-monitor-incident.styled'

export function ReopenDurationMessage(): JSX.Element {
    const {incidentReopenDuration} = useTypedSelector(fleetConfigSelector)

    if (incidentReopenDuration) {
        return (
            <Styled.Subtitle>
                By selecting{' '}
                <Styled.Highlighted style={{color: 'green'}}>CLOSED - Monitored</Styled.Highlighted>
                , you can
                <Styled.Highlighted> watch linked items</Styled.Highlighted> and will trigger the{' '}
                <Styled.Highlighted>
                    {' '}
                    reopening of this incident if further activity occurs{' '}
                    <span style={{fontStyle: 'italic'}}>
                        within {getFormattedDuration(incidentReopenDuration)}{' '}
                    </span>
                    on those linked items.
                </Styled.Highlighted>
            </Styled.Subtitle>
        )
    } else {
        return (
            <Styled.Subtitle>
                By selecting
                <Styled.Highlighted style={{color: 'green'}}>
                    {' '}
                    CLOSED - Monitored
                </Styled.Highlighted>
                , you can watch linked items and will trigger the reopening of this incident if
                further activity occurs on those linked items.
            </Styled.Subtitle>
        )
    }
}
