import {OTAssessmentRes, OTSystemMap} from '../../types/ot-types'
import ActionType from './action-type'
import * as Actions from './actions'

export function requestOTSystems(): Actions.RequestOTSystems {
    return {
        type: ActionType.OT_SYSTEMS_REQUEST,
    }
}

export function setOTSystems(data: OTSystemMap): Actions.SetOTSystems {
    return {
        type: ActionType.SET_OT_SYSTEMS,
        payload: {
            data,
        },
    }
}

export function failFetchOTSystems(): Actions.FailFetchOTSystems {
    return {
        type: ActionType.OT_SYSTEMS_FAILURE,
    }
}

export function toggleOTSystemDetails(systemName: string): Actions.ToggleOTSystemDetails {
    return {
        type: ActionType.TOGGLE_DETAILS,
        payload: {
            systemName,
        },
    }
}

export function setShowGenerateReportPopup(show: boolean): Actions.SetShowGenerateReportPopup {
    return {
        type: ActionType.SET_SHOW_ASSIGN_GENERATE_REPORT_POPUP,
        payload: show,
    }
}

export function setReportCreate(payload: string): Actions.ReportCreateAction {
    return {
        type: ActionType.REPORT_CREATE,
        payload,
    }
}

export function reportCreateError(error: string): Actions.ReportCreateError {
    return {
        type: ActionType.REPORT_CREATE_ERROR,
        payload: {
            error,
        },
    }
}

export function resetErrorState(): Actions.ResetErrorState {
    return {
        type: ActionType.RESET_ERROR_STATE,
    }
}

export function updateOTSystem(data: OTAssessmentRes): Actions.UpdateOTSystem {
    return {
        type: ActionType.UPDATE_OT_SYSTEM,
        payload: {
            data,
        },
    }
}
