import {useContext} from 'react'
import * as ActionCreators from './state/action-creators'
import {UseUserManagementResult} from './use-user-management-output'
import {UserManagementContext} from './user-management-context'
import {warn} from '../../../helpers/logging'
import {GuidType} from '../../../values/generic-type-defintions'
import {REST} from '../../..'
import {StrictMode, User} from '../../../store/state/users/state'
import {Action} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {Api} from '../../../api/Api'
import AppState from '../../../store/types/app-state'
import {
    addNewEmailRecipient,
    deleteEmailRecipient,
} from '../../../store/state/email-recipient-management/action-creators'
import {fetchUsers} from '../../../store/state/users/action-creators'
import {Role} from '../../../values/Role'
import {DetailExpandedType, ProcessingStatus} from './type/user-management-state'
import {fetchCurrentUser} from '../../../store/state/current-user/action-creators'
import {DEFAULT_USER_MANAGEMENT_STATE} from './type/default-user-management-state'
import {isEqual} from 'lodash'
import {fetchAllLocationTagsByLocation} from '../../../store/state/vessel-tags/action-creators'
import {fetchLocations} from '../../../store/state/locations/action-creators'

const USERS_ENDPOINT = 'api/v1/users'

export function useUserManagement(): UseUserManagementResult {
    const {state, dispatch} = useContext(UserManagementContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useUserManagement must be used within a userManagementContext')
    }

    function showUserDetailModal(userId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        findUserDetails(userId)
        dispatch(ActionCreators.showUserDetailModal(userId))
    }

    function closeUserDetailModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeUserDetailModal())
    }

    function findUserDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(`${USERS_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(ActionCreators.receivedRequestedUserDetails(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function changeUserName(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeUserName(value))
    }

    function changeUserEmail(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeUserEmail(value))
    }

    function changeNotificationEmail(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeNotificationEmail(value))
    }

    function assignCurrentUserRole(role: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.assignUserRole(role))
    }

    function deassignCurrentUserRole(role: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.deassignUserRole(role))
    }

    function setSubscription(value: {
        initialSubscriptionStatus: boolean
        newStatus: boolean
        userId: string
        emailId: string
    }): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.SetSubscription(value))
    }

    function setAssignedTagId(value: GuidType | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setAssignedTagId(value))
    }

    function setTagStrictMode(value: StrictMode | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setTagStrictMode(value))
    }

    function updateOpenUserDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(`${USERS_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(ActionCreators.receivedRequestedUserDetails(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function saveChanges(
        userDetails: User,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const rolesToBeAdded = userDetails.roles.filter(
            (role) => !state.initialUserDetails.roles.includes(role),
        )
        const rolesToBeDeleted = state.initialUserDetails.roles.filter(
            (role) => !userDetails.roles.includes(role),
        )
        rolesToBeAdded.length > 0 &&
            rolesToBeAdded.map((role) =>
                REST.post(`${USERS_ENDPOINT}/${userDetails.user}/roles/${role}`),
            )
        rolesToBeDeleted.length > 0 &&
            rolesToBeDeleted.map((role) =>
                REST.delete(`${USERS_ENDPOINT}/${userDetails.user}/roles/${role}`),
            )
        if (state.isSubscriptionChanged) {
            state.subscriptionStatus.initialSubscriptionStatus
                ? thunkDispatch(deleteEmailRecipient(state.subscriptionStatus.emailId))
                : thunkDispatch(addNewEmailRecipient(undefined, userDetails.user))
        }

        REST.put(`${USERS_ENDPOINT}/${userDetails.user}`, {
            username: userDetails.username,
            email: userDetails.email,
            emailNotificationEnabled: userDetails.emailNotificationEnabled,
            external: !userDetails.internalUser,
            active: userDetails.active,
            deleted: userDetails.deleted,
            assignedTag: userDetails.assignedTag,
            strictMode: userDetails.strictMode,
            notificationEmail: userDetails.notificationEmail,
        })
            .then(() => {
                dispatch(ActionCreators.changesSaved())
                setTimeout(() => dispatch(ActionCreators.setSaveMessage(undefined)), 3000)
                updateOpenUserDetails(userDetails.user)
                thunkDispatch(fetchUsers())
                thunkDispatch(fetchCurrentUser())
                thunkDispatch(fetchAllLocationTagsByLocation())
                thunkDispatch(fetchLocations())
            })
            .catch(() => {
                dispatch(ActionCreators.setSaveMessage('Save failed'))
            })
    }

    function disableUser(initialUserDetails: User): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.put(`${USERS_ENDPOINT}/${initialUserDetails.user}`, {
            ...initialUserDetails,
            active: false,
        })
            .then(() => {
                dispatch(ActionCreators.userDisabled())
                setTimeout(() => dispatch(ActionCreators.setDisableUserMessage(undefined)), 3000)
                updateOpenUserDetails(initialUserDetails.user)
                setTimeout(() => updateOpenUserDetails(initialUserDetails.user), 3000)
            })
            .catch(() => {
                dispatch(ActionCreators.setDisableUserMessage('Disable user failed'))
                setTimeout(() => dispatch(ActionCreators.setDisableUserMessage(undefined)), 3000)
            })
    }

    function enableUser(initialUserDetails: User): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.put(`${USERS_ENDPOINT}/${initialUserDetails.user}`, {
            ...initialUserDetails,
            active: true,
        })
            .then(() => {
                dispatch(ActionCreators.userEnabled())
                setTimeout(() => dispatch(ActionCreators.setDisableUserMessage(undefined)), 3000)
                updateOpenUserDetails(initialUserDetails.user)
            })
            .catch(() => {
                dispatch(ActionCreators.setDisableUserMessage('Enable user failed'))
                setTimeout(() => dispatch(ActionCreators.setDisableUserMessage(undefined)), 3000)
            })
    }

    function setDisplayAddNewUserModal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayAddNewUserModal(value))
        if (!value) {
            dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.NOT_PROCESSING))
        }
    }

    function setNewUserName(username: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNewUserNameAction(username))
    }
    function setNewUserEmail(email: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNewUserEmailAction(email))
    }
    function setNewUserRole(role: Role, value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNewUserRoleAction(role, value))
    }
    function setNewUserSubscription(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNewUserSubscriptionAction(value))
    }
    function setNewUserExternal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setNewUserExternalAction(value))
    }

    function addNewUser(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSING))
        REST.post(USERS_ENDPOINT, {
            username: state.newUserData.username,
            email: state.newUserData.email,
            emailNotificationEnabled: true,
            external: state.newUserData.external,
            active: true,
            deleted: false,
        })
            .then((response) => {
                state.newUserData.roles.map((role) =>
                    REST.post(`${USERS_ENDPOINT}/${response.data.user}/roles/${role}`),
                )
            })
            .then(() => {
                dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.PROCESSED))
                dispatch(ActionCreators.newUserAdded())
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                dispatch(ActionCreators.setSaveProcessingStatus(ProcessingStatus.FAILED))
            })
    }

    function setViewAsExternal(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.viewAsExternal(value))
    }

    function toggleDetailExpanded(detailExpanded: DetailExpandedType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleDetailExpanded(detailExpanded))
    }

    function toggleAlertDetailEdit(currentAlertId: GuidType | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleAlertDetailEdit(currentAlertId))
    }

    const isUserDataChanged = !isEqual(state.initialUserDetails, state.userDetails)

    return {
        loadingDataState: state.loadingDataState,
        showUserDetailModal,
        activeUserId: state.activeUserId,
        closeUserDetailModal,
        activeUserDetails: state.userDetails,
        initialUserDetails: state.initialUserDetails,
        displayUserDetails: state.displayUserDetails,
        subscriptionStatus: state.subscriptionStatus,
        changeUserName,
        changeUserEmail,
        changeNotificationEmail,
        isChanged: state.isChanged,
        isRoleChanged: state.isRoleChanged,
        setSubscription,
        isSubscriptionChanged: state.isSubscriptionChanged,
        setAssignedTagId,
        assignedTagId: state.userDetails.assignedTag,
        setTagStrictMode,
        tagStrictMode: state.userDetails.strictMode,
        assignCurrentUserRole,
        deassignCurrentUserRole,
        saveChanges,
        disableUser,
        enableUser,
        saveResultMessage: state.saveResultMessage,
        disableUserResultMessage: state.disableUserResultMessage,
        setDisplayAddNewUserModal,
        displayAddNewUserModal: state.displayAddNewUserModal,
        isSaveProcessing: state.isSaveProcessing,
        addNewUser,
        newUserData: state.newUserData,
        setNewUserName,
        setNewUserEmail,
        setNewUserRole,
        setNewUserSubscription,
        setNewUserExternal,
        setViewAsExternal,
        externalView: state.viewAsExternal,
        toggleDetailExpanded,
        detailExpanded: state.detailExpanded
            ? state.detailExpanded
            : DEFAULT_USER_MANAGEMENT_STATE.detailExpanded,
        isUserDataChanged: isUserDataChanged,
        currentSelectedAlertId: state.currentSelectedAlertId,
        toggleAlertDetailEdit,
    }
}
