import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import * as Styled from '../../vessel-management/components/header.styled'

interface HeaderProp {
    text: string
}

export function Header({text}: HeaderProp): JSX.Element {
    const {width} = useDimensions()
    return (
        <Styled.Header width={width}>
            <Styled.PageTitle width={width}>{text}</Styled.PageTitle>
        </Styled.Header>
    )
}
