import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'
import {Button} from './button.styled'
import {ButtonText} from './button-text.styled'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {PolicyType} from '../../../contexts/policy-management/type/policy-management-state'
import {useIncidentsPolicy} from '../../../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../../../contexts/metrics-policy/use-metrics-policy'
import {useEnforcementPolicy} from '../../../contexts/enforcement-policy/use-enforcement-policy'

interface ToggleDetailsButtonProps {
    type: PolicyType
    id: GuidType
}

export function ToggleDetailsButton({type, id}: ToggleDetailsButtonProps): JSX.Element {
    const {showPolicyDetailModal, closePolicyDetailModal, selectedPolicyId} = usePolicyManagement()
    const {findIncidentsPolicyDetails, setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {findMetricsPolicyDetails, setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {findEnforcementPolicyDetails, setDefaultEnforcementPolicyData} = useEnforcementPolicy()
    const isExpanded = id === selectedPolicyId
    const dispatch = useDispatch()

    function onClickWrapper() {
        if (isExpanded) {
            closePolicyDetailModal()
            setDefaultIncidentPolicyData()
            setDefaultMetricsPolicyData()
            setDefaultEnforcementPolicyData()
        } else {
            showPolicyDetails(type, id)
        }
    }

    function showPolicyDetails(type: PolicyType, id: GuidType) {
        showPolicyDetailModal(type, id)
        dispatch(logDetailsOpen(AuditLogPageType.USERS, AuditLogAuditType.DETAIL_PANEL_ACCESS, id))
        switch (type) {
            case PolicyType.INCIDENT:
                findIncidentsPolicyDetails(id)
                break
            case PolicyType.METRICS:
                findMetricsPolicyDetails(id)
                break
            case PolicyType.ENFORCEMENT:
                findEnforcementPolicyDetails(id)
                break
            default:
                break
        }
    }

    return (
        <Button onClick={onClickWrapper} id={`view-details-button_${id}`}>
            <Icon glyph={isExpanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
            <ButtonText>See details here</ButtonText>
        </Button>
    )
}
