import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    padding: 0 ${spacing(3)};
    margin: 0;
    width: 100%;
`

export const Details = styled.div`
    justify-content: flex-start;
    padding: ${spacing(2)} ${spacing(1)};
`

export const DetailRow = styled.div`
    display: flex;
    padding: 5px 0;
    ${smallFont()}
`
