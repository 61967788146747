import styled from 'styled-components'
export const IncidentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    overflow: visible;
`
export const IncidentAgeDisplay = styled.p`
    color: ${(props) => props.theme.colors.text.default};
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`
