import styled from 'styled-components'
import {Colors} from '../../../theme/colours'
import {spacing} from '../../../theme/spacing'

export const Content = styled.div`
    display: grid;
    grid-template-columns: ${spacing(8)} repeat(6, 1fr) ${spacing(8)};
`

export const GridHeaderCell = styled.div`
    background-color: ${Colors.white};
    padding: 0 ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.light};
    margin-bottom: ${spacing(1)};
`
export const GridRowCell = styled.div`
    background-color: ${Colors.white};
    padding: 0 ${spacing(2)};
    margin-bottom: ${spacing(2)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const RowCell = styled.td`
    padding: ${spacing(1)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
