import {GuidType} from '../../../../../values/generic-type-defintions'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function toggleModalOpen(systemID: GuidType): Actions.ToggleModal {
    return {
        type: ActionType.TOGGLE_MODAL,
        payload: {
            systemID,
            isOpen: true,
        },
    }
}

export function toggleModalClose(): Actions.ToggleModal {
    return {
        type: ActionType.TOGGLE_MODAL,
        payload: {
            isOpen: false,
        },
    }
}

export function submitAssessmentSuccess(): Actions.SubmitAssessmentSuccess {
    return {
        type: ActionType.SUBMIT_SUCCESS,
    }
}

export function submitAssessment(): Actions.SubmitAssessment {
    return {
        type: ActionType.SUBMIT,
    }
}

export function submitAssessmentError(error: string): Actions.SubmitAssessmentError {
    return {
        type: ActionType.SUBMIT_ERROR,
        payload: {
            error,
        },
    }
}
