import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {UnknownAssetsCard} from './unknown-assets-card'
import * as Styled from './_styled/unknown-assets-cards.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {NoDataCard} from './no-data-card/no-data-card'
import {
    activeLocationSelector,
    locationMapSelector,
} from '../../../../store/state/locations/selectors'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {setFormattedAnomalies} from '../../context/helpers'

export function UnknownAssetsCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const activeLocation = useTypedSelector(activeLocationSelector)
    const locationMap = useTypedSelector(locationMapSelector)

    const {dataUnknownAssets, isolatedAssetsStatus} = usePagedUnknownAssetsBeta()
    const formattedAnomalies: FormattedAnomaly[] = setFormattedAnomalies(
        dataUnknownAssets,
        activeLocation,
        locationMap,
        isolatedAssetsStatus,
    )
    if (formattedAnomalies.length == 0) {
        return <NoDataCard text="No results match your chosen options." />
    }

    return (
        <Styled.CardArea width={width} id="card-data-grid-style">
            {formattedAnomalies.map((anomaly) => (
                <UnknownAssetsCard key={anomaly.identity} anomaly={anomaly} />
            ))}
        </Styled.CardArea>
    )
}
