import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'

interface Props {
    gridLayout: GridLayout
}
export function OpenIncidents({gridLayout}: Props): JSX.Element {
    const {openIncidents} = useIncidentsWidget()

    return (
        <ValueForType
            type="Open"
            value={openIncidents}
            gridLayout={gridLayout}
            id="incident-response-headline-values-open"
        />
    )
}
