import {useSpring} from 'react-spring'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {nodesStateSelector} from '../../../../../store/state/nodes/selectors'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {Box} from './box.styled'
import {Value} from './value.styled'
import {WatchListButton} from '../watchlist-button/watchlist-button'
import {getColorFromNode} from '../../../../../theme/helpers/node-title'
import {useTheme} from 'styled-components'

interface TitleBarProps {
    assetId: GuidType
}

export function TitleBar({assetId}: TitleBarProps): JSX.Element {
    const theme = useTheme()
    const nodesState = useTypedSelector(nodesStateSelector)
    const node = !nodesState.isFetchingNodes ? nodesState?.nodes?.get(assetId) : undefined

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueProps: any = useSpring({color: getColorFromNode(node, theme)})

    return (
        <Box node={node}>
            <Value id="asset_alias" style={valueProps}>
                {node?.alias || 'Unknown'}
            </Value>
            <WatchListButton assetId={assetId} />
        </Box>
    )
}
