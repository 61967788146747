import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {formatDate} from '../../../../../../helpers/formatting'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {UniqueFlow} from '../../../../../../values/unique-flow'
import {usePagedUniqueFlows} from '../../../../contexts/unique-flows/use-paged-unique-flows'
import {Card} from '../../common/cards/card.styled'
import {getDirectionValue, getProximityValue} from '../data-helpers'
import * as Styled from './_styled/card.styled'

interface UniqueFlowCardProps {
    uniqueFlow: UniqueFlow
}

export function UniqueFlowCard({uniqueFlow}: UniqueFlowCardProps): JSX.Element {
    const {width} = useDimensions()
    const node = useTypedSelector(nodesSelector)?.get(uniqueFlow.identifier.knownDestination)
    const {assetId} = usePagedUniqueFlows()

    return (
        <Card>
            <Styled.Content width={width}>
                <Styled.DataCell gridArea="WHEN">
                    <Styled.Label width={width}>First seen:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_timestamp}`}>
                        {formatDate(uniqueFlow.uniqueFlowTimestamp)}
                    </Styled.Value>
                </Styled.DataCell>
                <Styled.DataCell gridArea="PROTOCOL">
                    <Styled.Label width={width}>Destination Protocol:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_transport}`}>
                        {uniqueFlow.identifier?.transport?.toUpperCase() || 'Unknown'}
                    </Styled.Value>
                </Styled.DataCell>
                <Styled.DataCell gridArea="HOST">
                    <Styled.Label width={width}>Destination Host:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_node}`}>
                        {node?.alias || 'Unknown'}
                    </Styled.Value>
                </Styled.DataCell>
                <Styled.DataCell gridArea="PORT">
                    <Styled.Label width={width}>Destination Port:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_port}`}>
                        {uniqueFlow.identifier.destinationPort}
                    </Styled.Value>
                </Styled.DataCell>
                <Styled.DataCell gridArea="PROXIMITY">
                    <Styled.Label width={width}>Proximity:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_proximity}`}>
                        {getProximityValue(uniqueFlow.identifier)}
                    </Styled.Value>
                </Styled.DataCell>
                <Styled.DataCell gridArea="DIRECTION">
                    <Styled.Label width={width}>Direction:</Styled.Label>
                    <Styled.Value id={`unique_flows_${uniqueFlow.identifier}_direction}`}>
                        {getDirectionValue(assetId, uniqueFlow.identifier)}
                    </Styled.Value>
                </Styled.DataCell>
            </Styled.Content>
        </Card>
    )
}
