import styled from 'styled-components'
import BACKGROUND_IMG from '../background-img.svg'
import {spacing} from '../../../../../theme/spacing'

export const DragAndDropWrapper = styled.div<DragAndDropBoxProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${spacing(2)};
    padding: ${spacing(5)} ${spacing(2)};
    align-items: center;
    height: 300px;
    width: 300px;
    border: 2px dashed ${(props) => props.theme.colors.border.active};
    border-radius: 2px;
    background-image: url(${BACKGROUND_IMG});
    background-size: cover;
    background-repeat: no-repeat;

    &[aria-disabled='true'] {
        background-color: #f7f8f8;
    }
`
interface DragAndDropBoxProps {
    isDragOver: boolean
}
export const DragAndDropBox = styled.div`
    padding: ${spacing(2)};
`
export const SectionTitle = styled.div`
    color: black;
    font-size: 14px;

    &[aria-disabled='true'] {
        color: #6d7c92;
    }
`
export const SectionDivider = styled.div`
    margin-top: ${spacing(1)};
    font-style: italic;
    text-align: center;
`
export const ImageHeader = styled.img`
    height: 30px;
    width: 26px;
`
export const SizeWarning = styled.div`
    text-align: center;
    font-size: 14px;
    color: #a7b0be;
`
