import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Container = styled.div`
    width: 220px;
    background-color: ${(props) => props.theme.colors.background.default};
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    padding: 1px;
`

export const SearchInput = styled.div`
    width: 100%;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.normal};
    ${smallFont()}
    :empty:before {
        content: attr(placeholder);
        color: ${(props) => props.theme.colors.text.default};
    }
`

export const TagResultsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(2)};
`
export const TagResultsDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 140px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: 3px;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
`

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: none;
    border-radius: ${spacing(1)};
    gap: 3px;
    padding: 1px;
`

interface BigWrapperProps {
    inputOpen: boolean
}
export const TagFilterWrapper = styled.div<BigWrapperProps>`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: 3px;
    ${(props) => !props.inputOpen && 'overflow: hidden; height: 30px;'}
`

export const CloseIcon = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    color: ${(props) => props.theme.colors.border.lowContrast};
    background: ${(props) => props.theme.colors.background.default};
    cursor: pointer;
    width: 20px;
    border: 0;
    &:hover {
        color: ${(props) => props.theme.colors.border.active};
    }
    :disabled {
        opacity: 0;
        cursor: default;
    }
`
