import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {
    MetricsResponseData,
    MetricsResponseForBenchmark,
} from '../../../metrics-beta/contexts/types/metrics-response'
import {TimestampFilterType} from './timestamp-filter'
import {METRICS_ENDPOINT} from './vessels-beta-endpoints'
export interface FrameworkSummaryBenchmarkResponse {
    locationId: string
    protectionBenchmark: number | undefined
    protectionPerformance: number
    behaviourBenchmark: number | undefined
    behaviourPerformance: number
    maintenanceBenchmark: number | undefined
    maintenancePerformance: number
}
export async function buildFrameworkSummaryBenchmark(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<FrameworkSummaryBenchmarkResponse[]> {
    try {
        const response = await REST.post(`${METRICS_ENDPOINT}/metrics/tables`, {
            locations: locationIds,
            period: analysisPeriod,
            analysisTypes: ['benchmark'],
            aggregationLevel: 'framework',
        })

        const receivedData = new Map(Object.entries(response.data.tables))?.get(
            'benchmark',
        ) as MetricsResponseData

        const result = (receivedData?.data as MetricsResponseForBenchmark[]).reduce(
            (
                accumulator: {
                    [key: string]: {
                        locationId: string
                        protectionBenchmark: number | undefined
                        protectionPerformance: number
                        behaviourBenchmark: number | undefined
                        behaviourPerformance: number
                        maintenanceBenchmark: number | undefined
                        maintenancePerformance: number
                    }
                },
                current: MetricsResponseForBenchmark,
            ) => {
                const locationId = current.location

                if (!accumulator[locationId]) {
                    accumulator[locationId] = {
                        locationId,
                        protectionBenchmark: undefined,
                        protectionPerformance: 0,
                        behaviourBenchmark: undefined,
                        behaviourPerformance: 0,
                        maintenanceBenchmark: undefined,
                        maintenancePerformance: 0,
                    }
                }

                if (current.framework === 'protection') {
                    accumulator[locationId].protectionBenchmark = current.benchmark
                    accumulator[locationId].protectionPerformance = current.performance
                } else if (current.framework === 'behaviour') {
                    accumulator[locationId].behaviourBenchmark = current.benchmark
                    accumulator[locationId].behaviourPerformance = current.performance
                } else if (current.framework === 'maintenance') {
                    accumulator[locationId].maintenanceBenchmark = current.benchmark
                    accumulator[locationId].maintenancePerformance = current.performance
                }

                return accumulator
            },
            {},
        )
        const resultArray = Object.values(result)
        return resultArray
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<FrameworkSummaryBenchmarkResponse>()
    }
}
