import {GuidType} from '../../../../values/generic-type-defintions'

export interface SaveNoteResult {
    message: SaveMessage | undefined
    identity?: GuidType | undefined
}
export enum SaveMessage {
    FAILED = 'Operation failed',
    SUCCESFULLY = 'Operation performed successfully',
    NOTE_ADDED = 'Note added successfully',
    NOTE_UPDATED = 'Note updated successfully',
    NOTE_IN_PROGRESS = 'New note in progress',
    NOTE_REVIEW = 'Review note',
    STATUS_UPDATED = 'Status updated succesfully',
    SEVERITY_UPDATED = 'Severity updated succesfully',
    ASSIGNED = 'Incident was assigned successfully',
    NOT_ASSIGNED = 'Incident was not assigned successfully',
    UNASSINGED = 'Incident was unassigned successfully',
    NOT_UNASSINGED = 'Incident was not unassigned successfully',
    UPDATED_LAST_VIEWED = 'Updated last viewed incident timestamp',
    UPDATED_LAST_VIEWED_FAILED = 'Failed to update last viewed incident timestamp',
}
