import styled from 'styled-components'

export const DetailsButton = styled.button`
    border: none;
    background: none;
    color: #007bff;
    font-size: 12px;
    border-radius: 2px;
    &:hover {
        background-color: #007bff;
        color: white;
    }
`
