import {VesselTags} from '../../../../../store/state/vessel-tags/state'
import {VesselTag} from '../../../../../components/vessel-tag/vessel-tag'
import {PopupContainer} from './vessel-tags-popup.styled'

interface VesselTagsPopupProps {
    tags: VesselTags[]
}

export function VesselTagsPopup({tags}: VesselTagsPopupProps): JSX.Element {
    return (
        <PopupContainer>
            {tags.map((tag, index) => (
                <div style={{paddingBottom: '3px'}} key={index}>
                    <VesselTag tagName={tag.name} />
                </div>
            ))}
        </PopupContainer>
    )
}
