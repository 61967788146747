import {RecommendationContainer} from './_styled/header-column.styled'

export function TrustDeviceRecommendation(): JSX.Element {
    return (
        <RecommendationContainer>
            Trusting a device will allow your detected assets to communicate with other managed
            devices.
        </RecommendationContainer>
    )
}
