import * as Styled from './enable-monitoring.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {useEffect} from 'react'
import {SubmitArea} from './submit-area/submit-area'
import {SelectBusinessValue} from './business-value/business-value'
import {HostnameInput} from './hostname/hostname'
import {HostLogCollection} from './host-log-collection/host-log-collection'
import {SaveInProgress} from './data-area/save-in-progress'
import {ProcessingStatus} from '../../../context/types/paged-unknown-assets-state'
import {ChangeSaved} from './data-area/change-saved'
import {ChangeNotSaved} from './data-area/change-not-saved'

export function EnableMonitoring(): JSX.Element {
    const {isProcessingSave, getAssetData, setHostnameData, findNetworkAsset} =
        usePagedUnknownAssetsBeta()
    const {width} = useDimensions()

    useEffect(() => {
        getAssetData(findNetworkAsset?.identity ?? '')
        setHostnameData(findNetworkAsset?.name ?? '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Styled.Section
            onClick={(e) => e.stopPropagation()}
            width={width}
            id="enable-monitoring-section"
        >
            <Styled.Header id="enable-monitoring-Header">
                <Styled.Title id="enable-monitoring-Title">Enable Monitoring</Styled.Title>
            </Styled.Header>
            {isProcessingSave === ProcessingStatus.PROCESSING && <SaveInProgress />}
            {isProcessingSave === ProcessingStatus.PROCESSED && <ChangeSaved />}
            {isProcessingSave === ProcessingStatus.FAILED && <ChangeNotSaved />}
            {isProcessingSave === ProcessingStatus.NOT_PROCESSING && (
                <>
                    <Styled.Settings width={width} id="enable-monitoring-Settings">
                        <Styled.Filters width={width} id="enable-monitoring-Filters">
                            <SelectBusinessValue />
                            <HostnameInput />
                            <HostLogCollection />
                        </Styled.Filters>
                    </Styled.Settings>
                    <Styled.Actions id="enable-monitoring-Actions">
                        <SubmitArea />
                    </Styled.Actions>
                </>
            )}
        </Styled.Section>
    )
}
