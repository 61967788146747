import {ChangeEvent} from 'react'
import {DataCell} from '../data-cell/data-cell'
import {Select} from './business-value.styled'
import {useVesselManagement} from '../../../../contexts/use-vessel-management'

export function SelectOTSourceType(): JSX.Element {
    const {oTSourceModalDetails, changeOTSourceType} = useVesselManagement()
    const selectableFields: string[] = ['syslog', 'nmea']

    function otSourceTypeChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
        e.preventDefault()
        changeOTSourceType(e.target.value)
    }

    return (
        <DataCell label="Type">
            <Select
                onChange={otSourceTypeChangeHandler}
                value={oTSourceModalDetails.sourceType}
                id="business-value-Select"
                disabled={oTSourceModalDetails.entry === 'existing'}
            >
                <option key="default-option" id="ot-source-type-default-option" value="">
                    Select Value
                </option>
                {selectableFields?.map((value) => (
                    <option key={value} value={value} id={`ot-source-type-${value}-option`}>
                        {value.toUpperCase()}
                    </option>
                ))}
            </Select>
        </DataCell>
    )
}
