import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {authTokenSelector} from '../../../../../store/state/auth/selectors'
import {
    Header,
    TokenAreaWrapper,
    AuthTokenValue,
    IconText,
    AuthTokenValueArea,
    CopyButton,
} from './token-area.styled'
import {Copy, Plus} from 'react-feather'

export function TokenArea(): JSX.Element {
    const authToken = useTypedSelector(authTokenSelector)
    const {width} = useDimensions()
    return (
        <TokenAreaWrapper>
            <Header>Auth Token</Header>
            <AuthTokenValueArea width={width}>
                <AuthTokenValue width={width}>{authToken}</AuthTokenValue>
                <CopyButton
                    id="auth-token-copy-button"
                    onClick={() => {
                        authToken && navigator.clipboard.writeText(authToken)
                    }}
                >
                    <Copy width={19} height={19} />
                    <IconText>{'Copy'}</IconText>
                </CopyButton>
                <CopyButton
                    id="auth-token-copy-button"
                    onClick={() => {
                        authToken && navigator.clipboard.writeText('Bearer ' + authToken)
                    }}
                >
                    <Plus width={19} height={19} />
                    <IconText>{'Bearer'}</IconText>
                </CopyButton>
            </AuthTokenValueArea>
        </TokenAreaWrapper>
    )
}
