import {ReactNode, useReducer} from 'react'
import {cloneDeep} from 'lodash'
import {policyManagementReducer} from './state/reducer'
import {DEFAULT_POLICY_MANAGEMENT_STATE} from './type/default-policy-management-state'
import {PolicyManagementState} from './type/policy-management-state'
import {PolicyManagementContext} from './policy-management-context'

type PolicyManagementProviderProps = {
    children: ReactNode | ReactNode[]
}

export function PolicyManagementProvider({children}: PolicyManagementProviderProps): JSX.Element {
    const initialState: PolicyManagementState = cloneDeep(DEFAULT_POLICY_MANAGEMENT_STATE)

    const [state, dispatch] = useReducer(policyManagementReducer, initialState)

    return (
        <PolicyManagementContext.Provider value={{state, dispatch}}>
            {children}
        </PolicyManagementContext.Provider>
    )
}
