import * as Styled from './assign-policy-dialog.styled'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {AllPolicyData} from '../../../contexts/policy-management/type/policy-management-state'
import {useRef} from 'react'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {DialogContent} from './dialog-content'

interface AssignPolicyDialogProps {
    index: number
    totalNumberOfPolicy: number
    policy: AllPolicyData
}

export function AssignPolicyDialog({
    index,
    totalNumberOfPolicy,
    policy,
}: AssignPolicyDialogProps): JSX.Element {
    const {closeAssignPolicyDialog} = usePolicyManagement()
    const isBottom = totalNumberOfPolicy > 7 && index > totalNumberOfPolicy / 2

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        closeAssignPolicyDialog()
    })

    return (
        <Styled.AssignPolicyDialogWrapper ref={clickOutside}>
            <Styled.ArrowUp />
            <Styled.AssignPolicyDialog isBottom={isBottom}>
                <DialogContent policy={policy} />
            </Styled.AssignPolicyDialog>
            <Styled.ArrowUpInner />
        </Styled.AssignPolicyDialogWrapper>
    )
}
