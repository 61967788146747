import {
    formatTechnique,
    getTacticFromCode,
    getTechniqueFromCode,
} from '../../../helpers/indicatorHelper'
import {IndicatorData, IndicatorMap} from '../../../values/IndicatorData'
import {MitreObject} from '../../../values/mitre-object/data'
import {EventDetail} from '../contexts/types/event-detail'

function getIndicator(
    indicatorCode: string | undefined,
    indicators: IndicatorMap,
): IndicatorData | null | undefined {
    if (indicatorCode == undefined) {
        return null
    }

    try {
        return indicators.get(indicatorCode)
    } catch {
        // eslint-disable-next-line no-console
        console.error(` could not find indicator with code ${indicatorCode}`)
        return null
    }
}

function getPlatforms(mitreAttackTech: MitreObject | null | undefined): string | null | undefined {
    try {
        return mitreAttackTech?.x_mitre_platforms?.join(', ')
    } catch {
        return null
    }
}

function getPermissionsRequired(
    mitreAttackTech: MitreObject | null | undefined,
): string | null | undefined {
    try {
        return mitreAttackTech?.x_mitre_permissions_required?.join(', ')
    } catch {
        return null
    }
}

function getEffectivePermissions(
    mitreAttackTech: MitreObject | null | undefined,
): string | null | undefined {
    try {
        return mitreAttackTech?.x_mitre_effective_permissions?.join(', ')
    } catch {
        return null
    }
}

export class EventDetailOutputModel {
    public readonly name: string | undefined
    public readonly indicator: string | undefined
    public readonly timestamp: string | null | undefined
    public readonly tactic: string | null | undefined
    public readonly technique: string | undefined | null
    public readonly platform: string | null | undefined
    public readonly permissionsRequired: string | null | undefined
    public readonly effectivePermissions: string | null | undefined
    public readonly logData: string | null | undefined
    public readonly id: string | undefined

    public constructor(
        eventDetail: EventDetail | undefined,
        indicators: IndicatorMap,
        gfcFlag: boolean,
    ) {
        this.timestamp = eventDetail?.timestamp
        this.logData = eventDetail?.message

        const indicator = getIndicator(eventDetail?.indicator, indicators)

        if (!indicator) {
            return
        }

        const mitreAttackTech = gfcFlag ? null : getTechniqueFromCode(indicator.mitreTtps, gfcFlag)

        this.name = indicator.title
        this.indicator = indicator.indicatorCode
        this.tactic = gfcFlag ? null : getTacticFromCode(indicator.mitreStep, gfcFlag)
        this.technique = gfcFlag ? null : formatTechnique(indicator, mitreAttackTech)
        this.platform = getPlatforms(mitreAttackTech)
        this.permissionsRequired = getPermissionsRequired(mitreAttackTech)
        this.effectivePermissions = getEffectivePermissions(mitreAttackTech)
        this.id = eventDetail?.guid
    }
}
