import produce from 'immer'
import {Action} from './actions'
import {ActionType} from './action-type'
import {AssetUploadModal} from '../../location-inventory-page-context'
import {AssetBulkFileStatus} from '../../types/ot-types'
import LoadingState from '../../../../../values/loading-state-enum'

export const AssetUploadModalReducer = produce(
    (draft: AssetUploadModal['state'], action: Action) => {
        switch (action.type) {
            case ActionType.TOGGLE_SHOW_ASSET_UPLOAD_MODAL:
                draft.isOpen = action.payload.isOpen
                break
            case ActionType.SET_FILE:
                draft.file = action.payload.file
                break
            case ActionType.SET_UPLOADING_STATUS:
                draft.uploadingStatus = action.payload.status
                break
            case ActionType.SET_SERVER_MESSAGE:
                draft.serverMessage = action.payload
                break
            case ActionType.ADD_FILE:
                draft.files.unshift(action.payload.file)
                break
            case ActionType.SET_FILES:
                draft.files = action.payload.files
                draft.fetchLoadingState = LoadingState.Loaded
                break
            case ActionType.SET_FILE_UPLOAD_ERROR: {
                const fileIndex = draft.files.findIndex(
                    (file) => file.status === AssetBulkFileStatus.UPLOADING,
                )
                if (fileIndex !== -1) {
                    draft.files[fileIndex].status = AssetBulkFileStatus.UPLOAD_ERROR
                }
                break
            }

            case ActionType.SET_FILE_UPLOAD_SUCCESS: {
                const fileIndex = draft.files.findIndex(
                    (file) => file.status === AssetBulkFileStatus.UPLOADING,
                )
                if (fileIndex !== -1) {
                    draft.files[fileIndex].status = AssetBulkFileStatus.UPLOADED
                }
                break
            }
            case ActionType.FILES_REQUEST: {
                draft.fetchLoadingState = LoadingState.RequestingData
                break
            }
            case ActionType.FILES_REQUEST_FAILURE: {
                draft.fetchLoadingState = LoadingState.Errored
                break
            }
            case ActionType.FILES_REQUEST_SUCCESS: {
                draft.fetchLoadingState = LoadingState.Loaded
                draft.files = action.payload.files
                break
            }
            default:
                break
        }
    },
)
