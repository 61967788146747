import * as Styles from './checkbox.styles'

interface Props {
    id: string
    checked: boolean
    onChange: (value: boolean) => void
    label: string
}
export function Checkbox({id, checked, onChange, label}: Props): JSX.Element {
    return (
        <Styles.Wrapper htmlFor={id}>
            <Styles.CheckboxInput
                type="checkbox"
                id={id}
                onChange={() => onChange(!checked)}
                checked={checked}
            />
            <Styles.StyledCheckbox />
            <Styles.Text>{label}</Styles.Text>
        </Styles.Wrapper>
    )
}
