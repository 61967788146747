import * as Styles from './expand-panel-button.styled'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'

interface ExpandPanelButtonProps {
    headingRow: VesselManagementDetailType
}

export function ExpandPanelNoButton({headingRow}: ExpandPanelButtonProps): JSX.Element {
    return (
        <Styles.HeadingWrapper>
            <Styles.DetailHeadingRow>{headingRow}</Styles.DetailHeadingRow>
        </Styles.HeadingWrapper>
    )
}
