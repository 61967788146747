import {EventDetailOutputModel} from './event-detail.model'
import * as styled from './right-hand-panel.styled'
import {TableHeader} from './table-header.styled'
import {TableLabel} from './table-label.styled'
import {TableValue} from './table-value.styled'

interface RightHandPanelProps {
    eventDetails: EventDetailOutputModel
}

export function RightHandPanel({eventDetails}: RightHandPanelProps): JSX.Element {
    if (!eventDetails.technique) {
        return <styled.RightHandPanel></styled.RightHandPanel>
    }

    return (
        <styled.RightHandPanel>
            <table>
                <tbody>
                    <tr>
                        <TableHeader colSpan={2}>MITRE ATT&CK&#8482;</TableHeader>
                    </tr>
                    {eventDetails.tactic && (
                        <tr>
                            <TableLabel>Tactic</TableLabel>
                            <TableValue>{eventDetails.tactic}</TableValue>
                        </tr>
                    )}
                    {eventDetails.technique && (
                        <tr>
                            <TableLabel>Technique</TableLabel>
                            <TableValue>{eventDetails.technique}</TableValue>
                        </tr>
                    )}
                    {eventDetails.platform && (
                        <tr>
                            <TableLabel>Platform:</TableLabel>
                            <TableValue>{eventDetails.platform}</TableValue>
                        </tr>
                    )}
                    {eventDetails.permissionsRequired && (
                        <tr>
                            <TableLabel>Permission Required:</TableLabel>
                            <TableValue>{eventDetails.permissionsRequired}</TableValue>
                        </tr>
                    )}
                    {eventDetails.effectivePermissions && (
                        <tr>
                            <TableLabel>Effective Permissions:</TableLabel>
                            <TableValue>{eventDetails.effectivePermissions}</TableValue>
                        </tr>
                    )}
                </tbody>
            </table>
        </styled.RightHandPanel>
    )
}
