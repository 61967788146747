import styled from 'styled-components'
import {getSeverityLevelBackgroundColor} from '../../../incidents-v3/components/shared/severity-level/_styled/severity-level.styled'
import {IncidentSeverityValue} from '../../../../values/incident-response-values'
import {smallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

interface SquareProps {
    incidentSeverity: IncidentSeverityValue
}

export const Square = styled.span<SquareProps>`
    color: white;
    background-color: ${(props) =>
        getSeverityLevelBackgroundColor(props.incidentSeverity, props.theme)};
    height: ${spacing(6)};
    width: ${spacing(12)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
