import {removeQueryParam} from '../../../../../helpers/navigation'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {SOFTWARE_INVENTORY_DETAILS} from '../../data-helpers'
import {useDispatch} from 'react-redux'
import {fetchSoftwareInventoryFilter} from '../../../../../store/state/software-inventory-filter/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {savedSoftwareInventoryFiltersSelector} from '../../../../../store/state/saved-filters/selectors'
import {RefreshButton} from '../../../../../components/refresh-button/refresh-button'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function RefreshButtonSoftwareInventory(): JSX.Element {
    const dispatch = useDispatch()
    const {refreshData} = usePagedSoftwareInventory()
    const savedFilters = useTypedSelector(savedSoftwareInventoryFiltersSelector)
    function onClickFn() {
        removeQueryParam(SOFTWARE_INVENTORY_DETAILS)
        refreshData()
        dispatch(fetchSoftwareInventoryFilter(savedFilters))
    }

    return (
        <RefreshButton
            longText="Refresh Software"
            onClick={onClickFn}
            pageType={PageType.SOFTWARE_INVENTORY}
        />
    )
}
