import {Location, LocationIdType} from '../../../../store/state/locations/state'
import {VesselFilterReduxState} from '../../../../store/state/vessel-filter/types/vessel-filter-state'
import {VesselTags} from '../../../../store/state/vessel-tags/state'

function filterVessel(location: Location, locations: Set<LocationIdType> | undefined): boolean {
    return !locations ? true : locations.has(location.location)
}

function filterVesselTags(vesselTag: VesselTags[] | undefined, searchTerm: string[]): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return searchTerm.every((searchTag) =>
        vesselTag?.map((element) => element.name).includes(searchTag),
    )
}

function filterVesselName(vesselName: string, searchTerm: string): boolean {
    if (!searchTerm || searchTerm?.length === 0) {
        return true
    }
    return vesselName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
}

export function filteredVesselIds(locations: Location[], filter: VesselFilterReduxState): string[] {
    return locations
        .filter((location) => filterVessel(location, filter.locations))
        .filter((location) => filterVesselTags(location.tags, filter.searchVesselTagTerm))
        .filter((location) => filterVesselName(location.description, filter.searchVesselNameTerm))
        .map((location) => location.location)
}
