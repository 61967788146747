import {getVesselTagData, TableData} from '../../data-helpers'
import {TableDataGrid} from './_styled/table-data-grid.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'
import {TableRow} from './table-row'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../../store/state/locations/selectors'
import {TagsUtils} from '../../../../../../utils/Utils'

type TagsComponentDataProps = {
    tableData: Record<string, TableData>
    allTags: VesselTags[]
}

export function TagsComponentTablePopulated({
    tableData,
    allTags,
}: TagsComponentDataProps): JSX.Element {
    const {width} = useDimensions()
    const locations = useTypedSelector(locationsSelector)
    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
            <TableDataGrid width={width} showReducedGrid={false}>
                {(() => {
                    const retArr: JSX.Element[] = []
                    let i = 0
                    for (const tagName in tableData) {
                        const tagData = tableData[tagName]
                        const tagInfo = getVesselTagData(tagName, allTags)
                        if (!tagInfo) {
                            continue
                        }
                        const tagCount = TagsUtils.getVesselCountForTag(tagInfo, locations)
                        retArr.push(
                            <TableRow
                                key={`${tagInfo.identity}`}
                                index={i}
                                tableDataRow={{...tagData, ...tagInfo}}
                                vesselCount={tagCount}
                            />,
                        )
                        i++
                    }
                    return retArr
                })()}
            </TableDataGrid>
        </div>
    )
}
