import {UniqueFlowIdentifier} from '../../../../../values/unique-flow'

function isPublic(value: string | undefined): boolean {
    if (value == undefined) {
        return false
    }

    return value.toUpperCase() === 'PUBLIC'
}

export function getProximityValue(identifier: UniqueFlowIdentifier): string | null {
    return identifier && (isPublic(identifier.destination) || isPublic(identifier.source))
        ? 'internet'
        : null
}

export function getDirectionValue(
    assetId: string | undefined,
    identifier: UniqueFlowIdentifier,
): string | null {
    if (identifier.knownDestination === identifier.knownSource) {
        return assetId === identifier.knownDestination ? 'loop' : null
    }

    if (assetId === identifier.knownDestination) {
        return 'inbound'
    }

    if (assetId === identifier.knownDestination) {
        return 'outbound'
    }

    return null
}
