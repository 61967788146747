import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useRef, useState} from 'react'
import * as Styles from './behaviours-search.styled'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {useOnClickOutside} from '../../../../../../hooks/useOnClickOutside'
import {NetworkAssetBehaviour} from '../../../../context/types/network-asset-behaviour'
import {isInactiveSelector} from '../../../../../../store/state/saved-filters/selectors'
import {selectBehaviour} from '../../../../../../store/state/network-assets-filter/action-creators'
import {BehaviourBlock} from './behaviour-block'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {networkAssetsFilterSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import {PageType} from '../../../../../../components/vessel-filters/data-helper'
import {logFilterByTypes} from '../../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../../store/state/audit-log/state'

function findRecommendationFromEntryBehaviour(
    existingBehaviours: NetworkAssetBehaviour[] | undefined,
    searchBehaviour: string,
): NetworkAssetBehaviour[] {
    if (!existingBehaviours || !searchBehaviour || !searchBehaviour?.trim()) {
        return []
    }
    return existingBehaviours
        ?.filter((element) =>
            element.displayName
                ?.trim()
                .toLowerCase()
                .includes(searchBehaviour?.trim().toLowerCase()),
        )
        .sort((a, b) => a.displayName?.localeCompare(b.displayName))
}

function getDisplayedBehaviourName(
    allNetworkBehaviours: NetworkAssetBehaviour[],
    searchBehaviourId: string,
): string {
    return (
        allNetworkBehaviours.find((behaviour) => behaviour.identity === searchBehaviourId)
            ?.displayName ?? 'N/A'
    )
}

export function BehavioursSearch(): JSX.Element {
    const dispatch = useDispatch()
    const [searchBehaviour, setSearchBehaviour] = useState<string>('')
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const {allNetworkBehaviours, allBehavioursId} = usePagedUnknownAssetsBeta()
    const isInactive = useTypedSelector(isInactiveSelector)
    const {behaviours} = useTypedSelector(networkAssetsFilterSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchBehaviour(e.target.value)
    }

    function onDeleteClick(tagToDelete: GuidType) {
        dispatch(selectBehaviour(allBehavioursId, tagToDelete, false))
    }

    const filteredUniqueResult = findRecommendationFromEntryBehaviour(
        allNetworkBehaviours,
        searchBehaviour,
    ).filter(
        (element) =>
            element.identity !==
            behaviours?.find((inTheFilterElement) => inTheFilterElement === element.identity),
    )

    const showRecomandations = recommendationOpen && filteredUniqueResult?.length !== 0

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => setRecommendationOpen(false))

    return (
        <Styles.Container isInactive={isInactive}>
            <Styles.SearchInput
                id={`${PageType.NETWORK_ASSETS}_filter-bar_behaviours-search`}
                placeholder="Filter by behaviours"
                autoComplete="off"
                value={searchBehaviour}
                onChange={onChangeFn}
            />
            <Styles.SearchIcon>
                <Icon glyph="Search" height={20} width={20} />
            </Styles.SearchIcon>
            <Styles.TagResultsWrapper ref={clickOutside} id="list-tagResultsWrapper">
                {showRecomandations && (
                    <Styles.TagResultsPopUp id="showRecomandations-tagResultsPopUp">
                        {filteredUniqueResult?.map((behaviour, index) => (
                            <BehaviourBlock
                                key={index}
                                behaviourName={behaviour.displayName}
                                onClickFn={() => {
                                    dispatch(
                                        selectBehaviour(allBehavioursId, behaviour.identity, true),
                                    )
                                    setSearchBehaviour('')
                                    setRecommendationOpen(false)
                                    dispatch(
                                        logFilterByTypes(
                                            'SearchedBehaviour',
                                            `${behaviour.displayName}`,
                                            true,
                                            AuditLogPageType.NETWORK_INVENTORY,
                                        ),
                                    )
                                }}
                            />
                        ))}
                    </Styles.TagResultsPopUp>
                )}
                {behaviours?.map((behaviour, index) => (
                    <BehaviourBlock
                        key={index}
                        behaviourName={getDisplayedBehaviourName(allNetworkBehaviours, behaviour)}
                        onDeleteFn={() => onDeleteClick(behaviour)}
                    />
                ))}
            </Styles.TagResultsWrapper>
        </Styles.Container>
    )
}
