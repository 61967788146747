import {Overlay} from './modal-overlay'
import {ModalContent} from './modal-content'

interface VesselsListModalProps {
    code: string
}

export function VesselsListModal({code}: VesselsListModalProps): JSX.Element {
    return (
        <Overlay>
            <ModalContent code={code} />
        </Overlay>
    )
}
