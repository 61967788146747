import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../../../templates/fixed-page/content-container-top.styled'
import {Container} from './data-actions.styled'
import {FilterBarButtonWrapper} from './filter-bar-button-wrapper'
import {RighHandButtonsArea} from './right-hand-buttons-area'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const {showFilterBar} = usePagedIncidentsReport()
    const narrowLayoutForOpenFilter = showFilterBar === true
    return (
        <ContentContainerTop
            width={width}
            backgroundColor={theme.commonTableViewPage.dataArea.background}
        >
            <Container
                id="data-actions-container"
                narrowLayoutForOpenFilter={narrowLayoutForOpenFilter}
            >
                <FilterBarButtonWrapper />
                <RighHandButtonsArea />
            </Container>
        </ContentContainerTop>
    )
}
