import {DataLoading} from '../../../../../../components/data-loading/data-loading'
import {MetricsDetailType} from '../../../../contexts/types/modal-types'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {DataCell} from '../shared/data-cell'
import * as Styled from './metrics-details.styled'

export function MetricsDetailsSection(): JSX.Element {
    const {modalDetailsExpanded, isProcessingMetricDetailsModal, findMetricDetailsForModal} =
        usePagedMetricsBeta()
    return (
        <Styled.DetailPanelSection>
            <ExpandPanelButton headingRow={MetricsDetailType.Details} />
            {modalDetailsExpanded.detailsExpanded &&
                (!isProcessingMetricDetailsModal ? (
                    <DataLoading />
                ) : (
                    <Styled.DetailedAreaWrapper>
                        <Styled.DetailsAreaContent>
                            <DataCell label="Number of assets:">
                                {findMetricDetailsForModal?.numberOfAssets}
                            </DataCell>
                            <DataCell label="Assets with issues:">
                                {`${findMetricDetailsForModal?.assetsWithIssues} (${findMetricDetailsForModal?.scoreOfOkAssets}% ok)`}
                            </DataCell>
                            <DataCell label="Applied policy:">
                                {findMetricDetailsForModal?.appliedPolicyName}
                            </DataCell>
                            <DataCell label="Vessel within target?:">
                                {findMetricDetailsForModal?.vesselWithinTarget}
                            </DataCell>
                        </Styled.DetailsAreaContent>
                    </Styled.DetailedAreaWrapper>
                ))}
        </Styled.DetailPanelSection>
    )
}
