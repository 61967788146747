import * as Styled from './_styled/self-monitoring-card.styled'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

import {CardTitle} from './card-title'
import {CardLocationCell} from './card-location-cell'
import {CardTimestampCell} from './card-timestamp-cell'

interface SelfMonitoringEngineCardProps {
    selfMonitoringEngineEvent: SelfMonitoringEngineEvent
}

export function SelfMonitoringEngineCard({
    selfMonitoringEngineEvent,
}: SelfMonitoringEngineCardProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > 700
    return (
        <Styled.Card id={`card_${selfMonitoringEngineEvent.identity}`}>
            <CardTitle event={selfMonitoringEngineEvent} />
            <Styled.CardDataRow>
                <CardTimestampCell
                    event={selfMonitoringEngineEvent}
                    fixedWidthReducedText={fixedWidthReducedText}
                />
                <CardLocationCell
                    event={selfMonitoringEngineEvent}
                    fixedWidthReducedText={fixedWidthReducedText}
                />
            </Styled.CardDataRow>
        </Styled.Card>
    )
}
