import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 3px;
    padding: ${spacing(0.5)} ${spacing(2)};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: pointer;
    margin-right: ${spacing(2)};
`
interface ButtonWrapperProps {
    width: number
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
    position: relative;
    padding-top: ${spacing(3)};
`
