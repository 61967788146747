import {SimpleButton} from '../../../../components/filter-button/simple-button'
import {GenerateReportButton} from '../../../../components/generate-report-button/generate-report-button/generate-report-button'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {incidentsReportFilterSelector} from '../../../../store/state/incidents-report-filter/selectors'
import {StrictMode} from '../../../../store/state/users/state'
import {createLocationSelectionNumberReselector} from '../../../../store/state/vessel-filter/reselector/location-selection-number-reselector'
import AppState from '../../../../store/types/app-state'
import {ViewScreenLevel} from '../../../incidents-v3/components/data-actions/view-screen-level/view-screen-level'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'

export function RighHandButtonsArea(): JSX.Element {
    const {refreshData, createReport, reportUrl, showGenerateReportPopup, reportDialogShown} =
        usePagedIncidentsReport()
    const {strictMode} = useTypedSelector(currentUserSelector)

    const locationSelectionNumberReselector = createLocationSelectionNumberReselector(
        incidentsReportFilterSelector,
    )
    const state: AppState = useTypedSelector((state) => state)
    const selectedLocationNumber = locationSelectionNumberReselector(state)

    const moreThanOneVesselSelected = selectedLocationNumber > 1

    const isGenerateReportDisabled = strictMode === StrictMode.Strict && moreThanOneVesselSelected

    return (
        <div style={{display: 'flex', padding: '0 10px', gap: '10px'}}>
            <ViewScreenLevel landingOption={'Report'} />
            <GenerateReportButton
                createReport={createReport}
                reportUrl={reportUrl}
                showGenerateReportPopup={showGenerateReportPopup}
                reportDialogShown={reportDialogShown}
                pageType={PageType.INCIDENTS_REPORT}
                isDisabled={isGenerateReportDisabled}
                glyph="Pdf"
            />
            <SimpleButton onClick={refreshData} label="Refresh stats" />
        </div>
    )
}
