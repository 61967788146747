import * as Styled from './_styled/table.styled'
import {TableModeCell} from './table-mode-cell'
import {TableLocationCell} from './table-location-cell'
import {MonaEngineMode} from '../../../../../values/mona_engine_mode'

interface TableRowProps {
    monaEngineMode: MonaEngineMode
    index: number
}

export function TableRow({monaEngineMode, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-sme-row_${monaEngineMode.anomalyProcessingMode}`}
            />
            <TableLocationCell monaEngineMode={monaEngineMode} gridRow={gridRow} />
            <TableModeCell monaEngineMode={monaEngineMode} gridRow={gridRow} />
        </>
    )
}
