import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

interface ContentAreaProps {
    narrowLayoutForOpenFilter: boolean
}
export const Container = styled.div<ContentAreaProps>`
    padding: ${spacing(4)} 0;
    display: flex;
    justify-content: ${(props) =>
        props.narrowLayoutForOpenFilter ? 'flex-start' : 'space-between'};
    height: 70px;
`
interface ContentWrapperProps {
    width: number
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    display: flex;
    ${(props) =>
        props.width < 1500
            ? 'flex-direction:column; align items:flex-start;'
            : 'align-items: center;'}
    justify-content: space-between;
    padding: 0 ${spacing(2)};
    gap: ${spacing(2)};
`
export const Label = styled.label`
    font-size: 16px;
    line-height: 23px;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
`
export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.bold};
    ${mediumSmallFont()}
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
