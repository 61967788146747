import {useState} from 'react'
import {CardDataCell} from './data-cell'
import {RadioButton} from './radio-button/radio-button'
import {RequirementsRadioButton} from './radio-button/requirements-radio-button'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {extendedDataRequestStateSelector} from '../../../store/state/extended-data-request/selectors'
import {Input} from './_styled/input.styled'
import {useDispatch} from 'react-redux'
import {setAdditionalRequirement} from '../../../store/state/extended-data-request/action-creators'

export function Requirements(): JSX.Element {
    const {data, additionalRequirement} = useTypedSelector(extendedDataRequestStateSelector)
    const dispatch = useDispatch()
    const [showAdditionalInput, setShowAdditionalInput] = useState(false)

    return (
        <CardDataCell gridArea="REQUIREMENTS" label="What do you want to know?">
            <RequirementsRadioButton
                label="Timeline of activity for the device"
                value={data.requirements}
            />
            <RequirementsRadioButton
                label="Additional information about the device"
                value={data.requirements}
            />
            <RadioButton
                label="Something else / specify request"
                isChecked={showAdditionalInput}
                onClick={() => {
                    setShowAdditionalInput(!showAdditionalInput)
                    dispatch(
                        setAdditionalRequirement(additionalRequirement ?? '', !showAdditionalInput),
                    )
                }}
            />
            {showAdditionalInput && (
                <Input
                    value={additionalRequirement}
                    width={365}
                    onChange={(e) => {
                        dispatch(setAdditionalRequirement(e.target.value, showAdditionalInput))
                    }}
                    id={`input-for_${additionalRequirement}`}
                />
            )}
        </CardDataCell>
    )
}
