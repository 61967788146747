import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

export const Properties = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

interface ContainerProps {
    isInactive?: boolean
}

export const Container = styled.div<ContainerProps>`
    width: 100%;
    margin-bottom: ${spacing(2)};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
`

export const Label = styled.div`
    ${smallFont()};
    margin-bottom: ${spacing(1)};
`

export const FilterWrapper = styled.div`
    padding: ${spacing(1)} 0;
`
