import {createSelector} from 'reselect'
import {locationsLoadingSelector} from '../../../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../../../store/state/nodes/selectors'
import {isLoadingSelfMonitoringEngineEventsSelector} from '../../../../../store/state/self-monitoring-events/selectors'
import {isLoadingSelfMonitoringEngineRulesSelector} from '../../../../../store/state/self-monitoring-rules/selectors'

export const isLoadingReselector = createSelector(
    isLoadingSelfMonitoringEngineEventsSelector,
    isLoadingSelfMonitoringEngineRulesSelector,
    locationsLoadingSelector,
    nodesFetchingSelector,
    (loadingEvents, loadingRules, loadingLocations, loadingNodes): boolean => {
        return loadingEvents || loadingRules || loadingLocations || loadingNodes
    },
)
