import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {
    DEFAULT_USB_INVENTORY_FILTER_STATE,
    DetailExpandedType,
    UsbInventoryFilterReduxState,
} from './state'
import {isEqual} from 'lodash'

export const usbInventoryFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.usbInventoryFilter.loadingState

export const usbInventoryFilterLoadingSelector = (state: AppState): boolean =>
    state.usbInventoryFilter.loadingState === LoadingState.RequestingData ||
    state.usbInventoryFilter.loadingState === LoadingState.NotPopulated

export const usbInventoryFilterSelector = (state: AppState): UsbInventoryFilterReduxState =>
    state.usbInventoryFilter

export const isActiveFilterSelector = (state: AppState): boolean =>
    state.usbInventoryFilter.searchedFirstDetected !==
        DEFAULT_USB_INVENTORY_FILTER_STATE.searchedFirstDetected ||
    state.usbInventoryFilter.searchedLastActive !==
        DEFAULT_USB_INVENTORY_FILTER_STATE.searchedLastActive ||
    state.usbInventoryFilter.searchedVendor !== DEFAULT_USB_INVENTORY_FILTER_STATE.searchedVendor ||
    state.usbInventoryFilter.searchedProductName !==
        DEFAULT_USB_INVENTORY_FILTER_STATE.searchedProductName ||
    !isEqual(
        [...state.usbInventoryFilter.searchedType].sort(),
        [...DEFAULT_USB_INVENTORY_FILTER_STATE.searchedType].sort(),
    ) ||
    state.usbInventoryFilter.selectedUsbDeviceStatus !== undefined ||
    state.usbInventoryFilter.hasPurpose !== undefined

export const selectedFilterNameSelector = (state: AppState): string =>
    state.usbInventoryFilter.selectedFilterName

export const usbModalDetailExpandedSelector = (state: AppState): DetailExpandedType =>
    state.usbInventoryFilter.detailExpanded
