import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../../@assets/icons/close-assign-to-icon.svg'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {removeQueryParam} from '../../../../../../helpers/navigation'
import {NETWORK_ASSET_DETAILS} from '../../../helpers/data-helpers'
import {fetchNetworkAssetsFilter} from '../../../../../../store/state/network-assets-filter/action-creators'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {savedNetworkAssetsFiltersSelector} from '../../../../../../store/state/saved-filters/selectors'
import {StringUtils} from '../../../../../../utils/Utils'

interface DetailPanelHeaderProps {
    assetName: string
}
export function DetailPanelHeader({assetName}: DetailPanelHeaderProps): JSX.Element {
    const {closeNetworkAssetDetailsModal, isInterlinking} = usePagedUnknownAssetsBeta()
    const dispatch = useDispatch()
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)

    return (
        <Styled.DetailPanelHeader>
            <div id="detail-panel-header_asset-name">{StringUtils.capitaliseString(assetName)}</div>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    removeQueryParam(NETWORK_ASSET_DETAILS)
                    closeNetworkAssetDetailsModal()
                    isInterlinking && dispatch(fetchNetworkAssetsFilter(savedFilters))
                }}
                id="detail-panel-header_close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
