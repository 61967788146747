import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {useDispatch} from 'react-redux'
import {fetchUsbInventoryFilter} from '../../../../../store/state/usb-inventory-filter/action-creators'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {USB_DEVICES_DETAILS} from '../../data-helpers'
import {savedUsbInventoryFiltersSelector} from '../../../../../store/state/saved-filters/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {RefreshButton} from '../../../../../components/refresh-button/refresh-button'

export function RefreshButtonUSB(): JSX.Element {
    const dispatch = useDispatch()
    const {refreshData, setIdForUSBDetailsModal} = usePagedUSBDevices()
    const savedFilters = useTypedSelector(savedUsbInventoryFiltersSelector)
    function onClickFn() {
        removeQueryParam(USB_DEVICES_DETAILS)
        setIdForUSBDetailsModal(null)
        refreshData()
        dispatch(fetchUsbInventoryFilter(savedFilters))
    }
    return (
        <RefreshButton
            longText="Refresh USB Devices"
            onClick={onClickFn}
            pageType={PageType.USB_DEVICES}
        />
    )
}
