import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../../theme/spacing'

export const Button = styled.button`
    border: none;
    background-color: ${(props) => props.theme.reports.resetButton.background};
    color: ${(props) => props.theme.reports.resetButton.text};
    border-radius: 6px;
    padding: ${spacing(1)} ${spacing(2)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const Text = styled.span`
    ${mediumSmallFont()}
`
