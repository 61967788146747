import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Select = styled.select`
    background-color: white;
    ${mediumSmallFont()}
    height: 30px;
`
interface ContainerProps {
    width: number
}

export const Container = styled.div<ContainerProps>`
    padding: ${(props) => (props.width < 600 ? `${spacing(2)}` : `${spacing(2)} ${spacing(4)}`)};
    background-color: ${(props) => props.theme.incidents.dataArea.background};
    display: flex;
    justify-content: space-between;
`
