import {usePagedSoftwareInventory} from '../../contexts/use-paged-software-inventory'
import {
    Actions,
    DataCell,
    DataCellKey,
    DataCellValue,
    DataRow,
    DetailsLayout,
    Header,
    Panel,
    Section,
    Title,
} from './chnage-software-status.styled'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import CIRCLE_UNKNOWN from '../../../../@assets/icons/circle-unknown.svg'
import {getFormattedSoftwareInventoryData, isNull} from '../data-helpers'
import {SubmitArea} from './submit-area'
import {Checkbox} from '../../../../components/form-elements/checkbox'
import {CheckboxType} from '../../../../components/form-elements/checkbox-type'

export function ChangeSoftwareStatus(): JSX.Element {
    enum CHECK_TYPE {
        ALL_SOFTWARE,
        COMPANY,
    }
    const {selectedStatusType, findSoftwareDetailsForModal, setModalSelections, checkState} =
        usePagedSoftwareInventory()

    const formattedSoftwareDetails = getFormattedSoftwareInventoryData(findSoftwareDetailsForModal)
    const {width} = useDimensions()

    function checkBox(checked: boolean, type: CHECK_TYPE): void {
        const copy = {...checkState}
        switch (type) {
            case CHECK_TYPE.ALL_SOFTWARE:
                copy.forAllSoftware = checked
                break
            case CHECK_TYPE.COMPANY:
                copy.forCompany = checked
                break
        }
        setModalSelections(copy.forCompany, copy.forAllSoftware)
    }
    return (
        <Section onClick={(e) => e.stopPropagation()} width={width}>
            <Header style={{display: 'flex'}}>
                <img src={CIRCLE_UNKNOWN} style={{marginRight: '10px'}} />
                <Title>Changing state to {selectedStatusType} for software item:</Title>
            </Header>
            <Panel>
                <DetailsLayout>
                    <DataRow>
                        <DataCell>
                            <DataCellKey>Company:</DataCellKey>
                            <DataCellValue>{formattedSoftwareDetails.companyName}</DataCellValue>
                        </DataCell>
                        <DataCell>
                            <DataCellKey>Product name:</DataCellKey>
                            <DataCellValue>{formattedSoftwareDetails.productName}</DataCellValue>
                        </DataCell>
                    </DataRow>
                </DetailsLayout>
                {!isNull(formattedSoftwareDetails?.companyName) && (
                    <div style={{padding: '0 27px 10px'}}>
                        <Checkbox
                            id="ChangeForAll"
                            label="Also change state for all existing software by this company"
                            type={CheckboxType.DANGER}
                            checked={checkState.forAllSoftware}
                            onChange={(checked) => {
                                checkBox(checked, CHECK_TYPE.ALL_SOFTWARE)
                            }}
                        />
                        <div style={{paddingTop: '15px'}}>
                            <Checkbox
                                id="changeForNewSoftware"
                                label="Set default state for any new software by this company"
                                type={CheckboxType.DANGER}
                                checked={checkState.forCompany}
                                onChange={(checked) => {
                                    checkBox(checked, CHECK_TYPE.COMPANY)
                                }}
                            />
                        </div>
                    </div>
                )}
            </Panel>
            <Actions>
                <SubmitArea />
            </Actions>
        </Section>
    )
}
