import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {NMEAInventoryCard} from './nmea-inventory-card'
import {CardArea} from './_styled/nmea-inventory-cards.styled'
import {usePagedNmeaInventory} from '../../contexts/use-paged-nmea-inventory'

export function NMEAInventoryCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataNmeaInventory} = usePagedNmeaInventory()

    return (
        <CardArea width={width} id="card-data-grid-style">
            {dataNmeaInventory?.map((nmeaItem) => {
                return <NMEAInventoryCard key={nmeaItem.location} nmeaItem={nmeaItem} />
            })}
        </CardArea>
    )
}
