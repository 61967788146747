import {ReportConfigStyle} from './report-config.styled'
import {ColumnTitle} from '../data-cell/_styled/data-cell.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {DataCellOtDevices} from '../input-data-cell/data-cell-ot-devices'
import {DataCellOtCrossings} from '../input-data-cell/data-cell-ot-crossings'
import {DataCellBusinessCrossings} from '../input-data-cell/data-cell-business-crossings'
import {DataCellBusinessDevices} from '../input-data-cell/data-cell-business-devices'
import {DataCellEmailHosts} from '../input-data-cell/data-cell-email-hosts'
import {DataCellHostLogExceptions} from '../input-data-cell/data-cell-host-log-exceptions'
import {DataCellNetworkLogExceptions} from '../input-data-cell/data-cell-network-log-exceptions'
import {DataCellUsbHosts} from '../input-data-cell/data-cell-usb-hosts'
import {DataCellUsbDevices} from '../input-data-cell/data-cell-usb-devices'
import {DataCellAdminLogons} from '../input-data-cell/data-cell-admin-logons'
import {DataCellRemoteAccess} from '../input-data-cell/data-cell-remote-access'
import {DataCellEncryptionRatio} from '../input-data-cell/data-cell-encryption-ratio'
import {DataCellMonitoringCoverage} from '../input-data-cell/data-cell-monitoring-coverage'
import {DataCellMaxOSAge} from '../input-data-cell/data-cell-max-os-age'
import {DataCellMaxScore} from '../input-data-cell/data-cell-max-score'
import {getGridType} from '../shared/grid-layout-helper'

export function ReportConfig(): JSX.Element {
    const {width} = useDimensions()
    return (
        <div>
            <ColumnTitle>Report Config</ColumnTitle>
            <ReportConfigStyle gridLayout={getGridType(width)}>
                <DataCellOtDevices />
                <DataCellOtCrossings />
                <DataCellBusinessDevices />
                <DataCellBusinessCrossings />
                <DataCellEmailHosts />
                <DataCellHostLogExceptions />
                <DataCellNetworkLogExceptions />
                <DataCellUsbHosts />
                <DataCellUsbDevices />
                <DataCellAdminLogons />
                <DataCellRemoteAccess />
                <DataCellEncryptionRatio />
                <DataCellMonitoringCoverage />
                <DataCellMaxOSAge />
                <DataCellMaxScore />
            </ReportConfigStyle>
        </div>
    )
}
