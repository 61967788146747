import * as Styled from './_styled/table-populated.styled'
import {TableRow} from './self-monitoring-engine-table'
import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {TableHeader} from './self-monitoring-table-header'
import {dataSelector} from '../../../../../store/state/mona-engine-modes/selectors'
import useTypedSelector from '../../../../../hooks/use-typed-selector'

export function MonaEngineModesTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const data = useTypedSelector(dataSelector)

    return (
        <>
            <Styled.ContentContainer
                width={width}
                backgroundColor={theme.systemPage.dataArea.background}
            >
                <TableHeader />
            </Styled.ContentContainer>
            <Styled.ContentContainer width={width}>
                <Styled.TableDataGrid>
                    {data &&
                        data.map((monaEngineMode, index) => (
                            <TableRow
                                key={`${monaEngineMode.anomalyProcessingMode}`}
                                monaEngineMode={monaEngineMode}
                                index={index}
                            />
                        ))}
                </Styled.TableDataGrid>
            </Styled.ContentContainer>
        </>
    )
}
