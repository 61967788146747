import styled from 'styled-components'

type RemoveButtonProps = {
    id: string
    onClick?: () => void
    disabled?: boolean
}

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
`

const StyledPath = styled.path<{isDisabled: boolean}>`
    fill: ${(prop) => (!prop.isDisabled ? '#1f88e5' : 'grey')};
`

export function RemoveButton({id, onClick, disabled = false}: RemoveButtonProps): JSX.Element {
    return (
        <StyledButton onClick={onClick} id={id} disabled={disabled} type="button">
            <svg width="15" height="15" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <StyledPath
                    isDisabled={disabled}
                    d="M1 18V3H0V1H5V0H11V1H16V3H15V18H1ZM3 16H13V3H3V16ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                />
            </svg>
        </StyledButton>
    )
}
