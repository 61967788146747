export enum MetricsDetailType {
    Details = 'Details',
    Insights = 'Insights',
    Assets = 'Assets affected',
}
export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}
export interface DetailExpandedType {
    detailsExpanded: boolean
    insightsExpanded: boolean
    assetsExpanded: boolean
}
