import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellMaxScore(): JSX.Element {
    const {data, initialData, changeMaxScore} = useVesselConfig()
    const initialValue = data.maxScore ? data.maxScore : ''
    const changed = data.maxScore != initialData.maxScore
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeMaxScore(Number(e.target.value))
    }
    return (
        <DataInput
            label="Max Score (1-100)"
            gridArea="MAX-SCORE"
            initialValue={initialValue}
            type="number"
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
