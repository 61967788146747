import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

function narrowLayout(width: number): boolean {
    return width < 1000
}
function getGridTemplate(width: number): string {
    if (narrowLayout(width)) {
        return '"REPORT-TYPE REPORT-ID" "PERIOD-FROM PERIOD-TO" "REPORT-NOTES DOCUMENT-TYPE"'
    }
    return "'REPORT-TYPE REPORT-TYPE PERIOD-FROM PERIOD-FROM DOCUMENT-TYPE' 'REPORT-ID REPORT-ID PERIOD-TO PERIOD-TO REPORT-NOTES'"
}

function getColumnTemplate(width: number): string {
    if (narrowLayout(width)) {
        return 'repeat(2,1fr);'
    }
    return 'repeat(5, 1fr);'
}
export const Card = styled.div`
    color: ${(props) => props.theme.reports.dataRow.text};
    background-color: ${(props) => props.theme.reports.dataRow.background};
    margin-bottom: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.reports.dataArea.boxShadow};
    ${mediumSmallFont()};
`
interface CardAreaProps {
    width: number
}
export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'TITLE TITLE TITLE TITLE STARS';
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`
interface CardTitleProps {
    gridArea: string
}
export const CardLocationTitleData = styled.h2<CardTitleProps>`
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
    grid-area: ${(props) => props.gridArea};
`
export const CardRatingTitleData = styled.div<CardTitleProps>`
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    width: 80px;
    grid-area: ${(props) => props.gridArea};
    ${mediumSmallFont()}
`
