import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.background.default};
`

export const FilterSearchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    cursor: pointer;
`

export const SearchInput = styled.div`
    display: inline-block;
    width: 200px;
    color: ${(props) => props.theme.colors.text.default};
    border: none;
    outline: none;
    align-items: center;
    padding: ${spacing(1)};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 1;
    flex-grow: 1;
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    padding: 1px;
`

export const SavedFiltersWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`

export const SavedFiltersDropdown = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 500px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(2)};
    padding-bottom: ${spacing(1)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    pointer-events: auto;
`

export const NoSavedFiltersMessage = styled(SavedFiltersDropdown)`
    ${mediumSmallFont()}
    text-align: center;
    padding-bottom: ${spacing(2)};
`
