import styled from 'styled-components'

interface UserManagementDetailsProps {
    activeUser: boolean
}

export const UserManagementDetails = styled.div<UserManagementDetailsProps>`
    color: ${(props) => props.theme.userManagement.dataRow.text};
    ${(props) =>
        props.activeUser
            ? `border: 3px solid ${props.theme.userManagement.dataRow.selectedRow}`
            : ''};
`
