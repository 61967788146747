import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {currentUserSelector} from '../../../../../../store/state/current-user/selectors'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './submit-area.styled'

export function SubmitArea(): JSX.Element {
    const dispatch = useDispatch()
    const currentUser = useTypedSelector(currentUserSelector)
    const {
        isChanged,
        isRoleChanged,
        isSubscriptionChanged,
        saveChanges,
        activeUserDetails,
        disableUser,
        enableUser,
        saveResultMessage,
        disableUserResultMessage,
        initialUserDetails,
    } = useUserManagement()

    const isDetailChanged = isChanged || isRoleChanged || isSubscriptionChanged
    const buttonText = !activeUserDetails.active ? 'Enable User' : 'Disable User'
    return (
        <Styled.SubmitArea>
            <Styled.SaveChangesSection>
                <Styled.SaveButton
                    disabled={!isDetailChanged && !saveResultMessage}
                    id="save-user-detail-changes"
                    value="Save"
                    onClick={() => saveChanges(activeUserDetails, dispatch)}
                >
                    Save Changes
                </Styled.SaveButton>
                <Styled.MessageArea saved={saveResultMessage === 'Saved successfully'}>
                    {saveResultMessage}
                </Styled.MessageArea>
            </Styled.SaveChangesSection>
            <Styled.SaveChangesSection>
                <Styled.SaveButton
                    disabled={currentUser.user === activeUserDetails.user}
                    id="disable-user"
                    value="Save"
                    onClick={() =>
                        !activeUserDetails.active
                            ? enableUser(initialUserDetails)
                            : disableUser(initialUserDetails)
                    }
                >
                    {buttonText}
                </Styled.SaveButton>
                <Styled.MessageArea
                    saved={
                        disableUserResultMessage === 'Disabled successfully' ||
                        disableUserResultMessage === 'Enabled successfully'
                    }
                >
                    {disableUserResultMessage}
                </Styled.MessageArea>
            </Styled.SaveChangesSection>
        </Styled.SubmitArea>
    )
}
