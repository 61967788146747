/**
 * The types of action available for OT Locations Redux store
 */
enum ActionType {
    REQUEST_LOCATIONS = '[OT LOCATIONS] REQUEST',
    SET_LOCATIONS = '[OT LOCATIONS] SET',
    FAIL_FETCH_LOCATIONS = '[OT LOCATIONS] FETCH ERROR',
    SET_FILTER = '[OT LOCATIONS] SET FILTER',
    SET_SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[OT LOCATIONS] SET SHOW REPORT POPUP',
    REPORT_CREATE = '[OT LOCATIONS] REPORT CREATE',
    REPORT_CREATE_ERROR = '[OT LOCATIONS] REPORT CREATE ERROR',
    RESET_ERROR_STATE = '[OT LOCATIONS] RESET ERROR STATE',
}

export default ActionType
