import * as Styled from './incident-type-severity-section.styled'
import {IncidentType} from '../../../../../../incidents-v3/contexts/types/incident-type'
import {IncidentSeverityValues} from '../../../../../../../values/incident-response-values'
import {IncidentTypeSeverity} from './incident-type-severity'
import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'

interface Props {
    incidentType: IncidentType
    isDefaultPol?: boolean
}

export function IncidentTypeSeveritySection({incidentType, isDefaultPol}: Props): JSX.Element {
    const {formattedIncidentPolicyTypes} = useIncidentsPolicy()
    const currentType = formattedIncidentPolicyTypes.find(
        (type) => type.incidentResponseType === incidentType.name,
    )

    return (
        <>
            <Styled.ZoomLevel id="zoom-level">
                <Styled.Label>{incidentType.displayName}</Styled.Label>
                <Styled.Options>
                    <IncidentTypeSeverity
                        incidentTypeName={incidentType.name}
                        severity={IncidentSeverityValues.LOW}
                        disabled={isDefaultPol}
                        selected={
                            (!isDefaultPol &&
                                currentType?.incidentResponseSeverities.includes(
                                    IncidentSeverityValues.LOW,
                                )) ??
                            false
                        }
                    />
                    <IncidentTypeSeverity
                        incidentTypeName={incidentType.name}
                        severity={IncidentSeverityValues.MEDIUM}
                        disabled={isDefaultPol}
                        selected={
                            (!isDefaultPol &&
                                currentType?.incidentResponseSeverities.includes(
                                    IncidentSeverityValues.MEDIUM,
                                )) ??
                            false
                        }
                    />
                    <IncidentTypeSeverity
                        incidentTypeName={incidentType.name}
                        severity={IncidentSeverityValues.HIGH}
                        disabled={isDefaultPol}
                        selected={
                            (!isDefaultPol &&
                                currentType?.incidentResponseSeverities.includes(
                                    IncidentSeverityValues.HIGH,
                                )) ??
                            false
                        }
                    />
                    <IncidentTypeSeverity
                        incidentTypeName={incidentType.name}
                        severity={IncidentSeverityValues.CRITICAL}
                        disabled={isDefaultPol}
                        selected={
                            (!isDefaultPol &&
                                currentType?.incidentResponseSeverities.includes(
                                    IncidentSeverityValues.CRITICAL,
                                )) ??
                            false
                        }
                    />
                </Styled.Options>
            </Styled.ZoomLevel>
        </>
    )
}
