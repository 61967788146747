import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/nmea-inventory-card.styled'
import {
    getFormattedNMEAeData,
    NmeaInventoryResponse,
} from '../../contexts/types/nmea-inventory-response'
import {usePagedNmeaInventory} from '../../contexts/use-paged-nmea-inventory'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
import {NMEAModalExpanded} from './nmea-inventory-detail-expanded '

interface NMEAInventoryCardProps {
    nmeaItem: NmeaInventoryResponse
    onClickFn?: () => void
}

export function NMEAInventoryCard({nmeaItem}: NMEAInventoryCardProps): JSX.Element {
    const {width} = useDimensions()
    const {showNMEADetailModal, modalIdForNmeaDetails} = usePagedNmeaInventory()
    const dispatch = useDispatch()

    const formattedNmeaItem = getFormattedNMEAeData(nmeaItem)
    const activeNMEAItem = formattedNmeaItem.identity === modalIdForNmeaDetails

    function onClickWrapper() {
        showNMEADetailModal(formattedNmeaItem.identity)
        dispatch(
            logDetailsOpen(
                AuditLogPageType.NMEA_INVENTORY,
                AuditLogAuditType.DETAIL_PANEL_ACCESS,
                formattedNmeaItem.identity,
            ),
        )
    }

    return (
        <Styled.NmeaInventoryDetails
            id={`NmeaInventoryDetails${formattedNmeaItem.identity}`}
            activeNmea={activeNMEAItem}
        >
            <Styled.Card
                id={`data-card_${formattedNmeaItem.identity}`}
                onClick={() => onClickWrapper()}
            >
                <Styled.CardAreaTitle width={width}>
                    <Styled.CardTalkerTitleData
                        gridArea="TALKER-TITLE"
                        id={`nmeaItem-CardVendorTitleData_${formattedNmeaItem.identity}`}
                    >
                        <div id={`nmeaItem-talkerName_${formattedNmeaItem.identity}`}>
                            {formattedNmeaItem.talkerName}
                        </div>
                    </Styled.CardTalkerTitleData>
                    <Styled.CardSentenceTitleData
                        gridArea="SENTENCE-TITLE"
                        id={`nmeaItem-CardProductTitleData_${formattedNmeaItem.identity}`}
                    >
                        <div id={`nmeaItem-sentenceName_${formattedNmeaItem.identity}`}>
                            {formattedNmeaItem.sentenceName}
                        </div>
                    </Styled.CardSentenceTitleData>
                </Styled.CardAreaTitle>
                <Styled.CardAreaContent
                    width={width}
                    id={`usb-devices-CardAreaContent_${formattedNmeaItem.identity}`}
                >
                    <CardDataCell
                        gridArea="WHEN"
                        label="First Detected:"
                        identity={formattedNmeaItem.identity}
                        identifier="first_detected"
                    >
                        {formattedNmeaItem.firstSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="LAST-ACTIVE"
                        label="Last Active:"
                        identity={formattedNmeaItem.identity}
                        identifier="last-active"
                    >
                        {formattedNmeaItem.lastSeenTimestamp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="RELAY-IP"
                        label="Relay IP:"
                        identity={formattedNmeaItem.identity}
                        identifier="relay_ip"
                    >
                        {formattedNmeaItem.relayIp}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="RECEIVER"
                        label="Receiver:"
                        identity={formattedNmeaItem.identity}
                        identifier="receiver"
                    >
                        {formattedNmeaItem.receiver}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="LOCATION-NAME"
                        label="Vessel:"
                        identity={formattedNmeaItem.identity}
                        identifier="vessel_name"
                    >
                        {formattedNmeaItem.locationName}
                    </CardDataCell>
                    <CardDataCell
                        gridArea="PARAMETERS"
                        label="Parameters:"
                        identity={formattedNmeaItem.identity}
                        identifier="parameters"
                    >
                        {formattedNmeaItem.numberOfFields}
                    </CardDataCell>
                </Styled.CardAreaContent>
            </Styled.Card>
            {activeNMEAItem && <NMEAModalExpanded />}
        </Styled.NmeaInventoryDetails>
    )
}
