import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const BREAK_POINT_CARD_REDUCED_TEXT = 500

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

interface CardCardItemsListCellProps {
    titleOnSameLine?: boolean
}

export const CardItemsListCell = styled.div<CardCardItemsListCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
`

interface LabelProps {
    width: number
}

export const Label = styled.label<LabelProps>`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    padding-right: ${spacing(1)};
    width: 110px;
    margin: 0;
`
