import {ContentWrapper} from '../_styled/policy-details-modal.styled'
import {useDimensions} from '../../../../../../contexts/dimensions/use-dimensions'
import * as Styled from './_styled/metrics-policy-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {PolicyName} from './policy-name/policy-name'
import {TabSection} from './tab-section/tab-section'
import {ButtonsArea} from './buttons-area/buttons-area'
export function AddNewMetricsPolicyDetails(): JSX.Element {
    const {width} = useDimensions()
    return (
        <ContentWrapper width={width}>
            <Styled.Container onClick={(e) => e.stopPropagation()}>
                <DetailPanelHeader text="Add new metrics policy" />
                <Styled.PolicyNameWrapper>
                    <PolicyName />
                    <ButtonsArea policyToCreate={true} />
                </Styled.PolicyNameWrapper>
                <Styled.ContentWrapper>
                    <TabSection />
                </Styled.ContentWrapper>
            </Styled.Container>
        </ContentWrapper>
    )
}
