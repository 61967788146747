import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {smallFont} from '../../../../../../../theme/font-styles'

export const Card = styled.div`
    color: ${(props) => props.theme.vessels.dataRow.text};
    background-color: ${(props) => props.theme.vessels.dataRow.background};
    margin-bottom: ${spacing(2)};
    width: 100%;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.vessels.dataRow.dropShadow};
    ${smallFont()}
    padding: ${spacing(2)};
    cursor: pointer;
    &:hover {
        color: ${(props) => props.theme.vessels.modal.hoverTextColor};
        background-color: ${(props) => props.theme.vessels.modal.hoverBackgroundColor};
    }
`
export const CardTitleRow = styled.div`
    display: flex;
    align-items: center;
`
export const CardTitleData = styled.h2`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 17px;
    flex: 1;
`
export const CardDataRow = styled.div`
    display: flex;
`
interface CardDataCellProps {
    titleOnSameLine?: boolean
}

export const CardDataCell = styled.div<CardDataCellProps>`
    flex: 1;
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    padding-right: ${spacing(1)};
`
interface ReducedTextProps {
    fixedWidth: boolean
}

export const ReducedText = styled.div<ReducedTextProps>`
    font-size: 12px;
    font-weight: ${(props) => props.theme.font.weight.light};
    line-height: 16px;
    padding-right: ${spacing(2)};
`
