import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {TableHeader} from './table-header'
import * as Styled from './_styled/license-table-list.styled'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {fleetConfigSelector} from '../../../store/state/config/selectors'
import {License, LicenseKeysTypes, LicenseKeyType} from '../../../store/state/config/state'
import {TableRow} from './table-row'
import {useLicenses} from '../contexts/use-licenses'

import {useDispatch} from 'react-redux'
import {
    fetchEnforcementNetworksLicense,
    fetchEnforcementRemovablesLicense,
    fetchIncidentLicense,
    fetchMetricsOnboardEngagementLicense,
} from '../../../store/state/config/action-creators'
import {SimpleButton} from '../../../components/filter-button/simple-button'
import {UseLicensesOutput} from '../contexts/UseLicensesOutput'

export function LicenseListTable(): JSX.Element {
    const {width} = useDimensions()
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const context = useLicenses()

    const dispatch = useDispatch()

    async function doSave(): Promise<void> {
        await context.saveLicenses()
        dispatch(fetchMetricsOnboardEngagementLicense())
        dispatch(fetchIncidentLicense())
        dispatch(fetchEnforcementNetworksLicense())
        dispatch(fetchEnforcementRemovablesLicense())
    }

    const licenses =
        fleetConfig.LicenseState ??
        ({
            'License:Metrics:OnboardEngagement': {
                key: 'License:Metrics:OnboardEngagement',
            },
            'License:Enforcement:Removables': {
                key: 'License:Enforcement:Removables',
            },
            'License:Enforcement:Networks': {
                key: 'License:Enforcement:Networks',
            },
            'License:Incidents': {
                key: 'License:Incidents',
            },
        } as Partial<License<LicenseKeysTypes>>)

    return (
        <>
            <TableHeader />
            <Styled.ContentContainer width={width}>
                <Styled.TableDataGrid width={width} showReducedGrid={false}>
                    {Object.entries(licenses)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([key, license], index) => {
                            const toggleChange = getToggleChange(key, context)
                            if (!toggleChange) {
                                return
                            }
                            return (
                                <TableRow
                                    key={key}
                                    lKey={key as LicenseKeyType<LicenseKeysTypes>}
                                    license={license}
                                    index={index}
                                    title={mapKey(key as LicenseKeyType<LicenseKeysTypes>)!}
                                    toggleChange={toggleChange}
                                />
                            )
                        })}
                </Styled.TableDataGrid>
                <div style={{textAlign: 'center'}}>
                    <SimpleButton label="Save" onClick={doSave} />
                </div>
            </Styled.ContentContainer>
        </>
    )
}

function getToggleChange(
    key: string,
    context: UseLicensesOutput,
): ((payload: boolean) => void) | null {
    switch (key) {
        case 'License:Enforcement:Networks':
            return context.toggleNetworkEnforcement
        case 'License:Enforcement:Removables':
            return context.toggleRemovableEnforcement
        case 'License:Incidents':
            return context.toggleIncidentsPolicy
        case 'License:Metrics:OnboardEngagement':
            return context.toggleOnboardEngagement
        default:
            return null
    }
}

function mapKey(type: LicenseKeyType<LicenseKeysTypes>): string | null {
    switch (type) {
        case 'License:Enforcement:Networks':
            return 'Network enforcement'
        case 'License:Enforcement:Removables':
            return 'Removable enforcement'
        case 'License:Incidents':
            return 'Incident policy'
        case 'License:Metrics:OnboardEngagement':
            return 'Onboard Engagement'
        default:
            return null
    }
}
