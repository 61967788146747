import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {TableHeader} from './header/table-header'
import {TableHeaderGrid} from './header/table-header-grid.styled'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()

    const {incidentModalId, showFilterBar} = usePagedIncidents()

    return (
        <TableHeaderGrid
            width={width}
            hideLastCol={!incidentModalId && !showFilterBar}
            id="TableHeaderGrid"
        >
            <TableHeader />
        </TableHeaderGrid>
    )
}
