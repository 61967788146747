import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useRef, useState} from 'react'
import * as Styled from './vessel-tag.styled'
import {useOnClickOutside} from '../../hooks/useOnClickOutside'

interface Props {
    tagName: string
    onClickFn?: () => void
    onDeleteFn?: () => void
    showHover?: boolean
    maxWidth?: string
}

export function VesselTag({
    tagName,
    onClickFn,
    onDeleteFn,
    showHover,
    maxWidth,
}: Props): JSX.Element {
    const [hoverOn, setHoverOn] = useState<boolean>(false)

    function mouseOverRow(): void {
        if (showHover || onDeleteFn) {
            setHoverOn(true)
        }
    }

    function mouseLeaveRow(): void {
        if (showHover || onDeleteFn) {
            setHoverOn(false)
        }
    }

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setHoverOn(false)
    })

    return (
        <Styled.TagResult
            onMouseEnter={() => mouseOverRow()}
            onMouseLeave={() => mouseLeaveRow()}
            cursorPointer={onClickFn != undefined}
            onClick={onClickFn ? onClickFn : () => setHoverOn(!hoverOn)}
            ref={clickOutside}
            id={`tag-result_${tagName}`}
        >
            <Styled.TagItem maxWidth={maxWidth} id={`tag-name_${tagName}`}>
                {tagName}
            </Styled.TagItem>
            {hoverOn && (
                <Styled.TagExpandedView>
                    <Styled.TagExpandedViewTagName id={`tag-hover_${tagName}`}>
                        {tagName}
                    </Styled.TagExpandedViewTagName>
                    {onDeleteFn && (
                        <Styled.DeleteIcon onClick={onDeleteFn} id={`clear-filter_${tagName}`}>
                            Clear filter
                            <Icon glyph="Delete" width={12} height={12} />
                        </Styled.DeleteIcon>
                    )}
                </Styled.TagExpandedView>
            )}
        </Styled.TagResult>
    )
}
