import styled from 'styled-components'

export const ExpandButton = styled.button`
    width: 10px;
    margin-right: 19px;
    margin-left: 19px;
    border: none;
    background: none;
    padding: 0;
`
