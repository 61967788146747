import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {Header} from './header/header'
import {VesselsCards} from './cards/vessels-cards'
import {VesselsTable} from './table/vessels-table'

export function VesselsList(): JSX.Element {
    const {width} = useDimensions()
    const showCards = width < 500
    return (
        <>
            <Header />
            {showCards ? <VesselsCards /> : <VesselsTable />}
        </>
    )
}
