import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {EmailInput} from './email-input'

type VesselEmailSettingProps = {
    emailAddress: string | undefined
}
export function VesselEmailSetting({emailAddress}: VesselEmailSettingProps): JSX.Element {
    const {changeVesselEmail, initialValue} = useVesselManagement()

    function emailChangeHandler(e: React.ChangeEvent<HTMLInputElement>): void {
        const newEmail = e.target.value
        changeVesselEmail(newEmail)
    }

    function isChanged(
        emailAddress: string | undefined,
        emailUpdate: string | undefined | null,
    ): boolean {
        if (emailAddress === undefined && emailUpdate?.length === 0) {
            return false
        }
        return emailAddress !== emailUpdate
    }

    const emailChanged = isChanged(emailAddress, initialValue.vesselEmail)

    return (
        <EmailInput
            isChanged={emailChanged}
            onChangeHandler={emailChangeHandler}
            initialValue={emailAddress}
        />
    )
}
