import {GuidType} from '../../../../values/generic-type-defintions'
import {NodeValue} from '../../../../values/nodes/NodeData'
import {NetworkAssetMonitoringState} from './isolated-assets-type'

export interface AssignedMacAddressType {
    ipAddresses: [
        {
            ip: string | undefined
            lastSeenTimestamp: string | undefined
        },
    ]
    lastSeenTimestamp: string | undefined
    mac: string | undefined
}

export interface UpdateAssignedMacAddressType {
    ipAddresses: string[] | undefined
    mac: string | undefined
}

export interface AssetDataType {
    agentIdentifier: {
        agentId: GuidType | undefined
        lastSeenTimestamp: string | undefined
    }
    alias: string | undefined
    archivedTimestamp: string | undefined
    archivedTo: string | undefined
    assignedMacAddresses: AssignedMacAddressType[]
    autoDiscovered: true | undefined
    blockStatus: string | undefined
    description: string | undefined
    hasAgent: true | undefined
    hostnameIdentifier: {
        hostname: string | undefined
        lastSeenTimestamp: string | undefined
    }
    identity: GuidType | undefined
    location: GuidType | undefined
    mainIpAddress: {
        ip: string | undefined
        lastSeenTimestamp: string | undefined
    }
    mergedTimestamp: string | undefined
    mergedTo: string | undefined
    packetbeatLastSeenTimestamp: string | undefined
    reportConfig: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additionalProp1: any | undefined
    }
    state: NetworkAssetMonitoringState | undefined
    type: string | undefined
    unassignedIpAddresses: [
        {
            ip: string | undefined
            lastSeenTimestamp: string | undefined
        },
    ]
    value: NodeValue | undefined
    winlogbeatLastSeenTimestamp: string | undefined
}

export interface AssetDataUpdateType {
    agentId: GuidType | undefined
    alias: string | undefined
    assignedMacAddresses: UpdateAssignedMacAddressType[]
    hostname: string | undefined
    location: GuidType | undefined
    locationCode: string | undefined
    mainIpAddress: string | undefined
    reportConfig: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additionalProp1: any | undefined
    }
    unassignedIpAddresses: string[] | undefined
    value: NodeValue | undefined
}

export const DEFAULT_ASSET_DATA: AssetDataType = {
    agentIdentifier: {
        agentId: undefined,
        lastSeenTimestamp: undefined,
    },
    alias: undefined,
    archivedTimestamp: undefined,
    archivedTo: undefined,
    assignedMacAddresses: [
        {
            ipAddresses: [
                {
                    ip: undefined,
                    lastSeenTimestamp: undefined,
                },
            ],
            lastSeenTimestamp: undefined,
            mac: undefined,
        },
    ],
    autoDiscovered: undefined,
    blockStatus: undefined,
    description: undefined,
    hasAgent: undefined,
    hostnameIdentifier: {
        hostname: undefined,
        lastSeenTimestamp: undefined,
    },
    identity: undefined,
    location: undefined,
    mainIpAddress: {
        ip: undefined,
        lastSeenTimestamp: undefined,
    },
    mergedTimestamp: undefined,
    mergedTo: undefined,
    packetbeatLastSeenTimestamp: undefined,
    reportConfig: {
        additionalProp1: undefined,
    },
    state: undefined,
    type: undefined,
    unassignedIpAddresses: [
        {
            ip: undefined,
            lastSeenTimestamp: undefined,
        },
    ],
    value: undefined,
    winlogbeatLastSeenTimestamp: undefined,
}

export const DEFAULT_ASSET_UPDATE_DATA: AssetDataUpdateType = {
    agentId: undefined,
    alias: undefined,
    assignedMacAddresses: [
        {
            ipAddresses: undefined,
            mac: undefined,
        },
    ],
    hostname: undefined,
    location: undefined,
    locationCode: undefined,
    mainIpAddress: undefined,
    reportConfig: {
        additionalProp1: undefined,
    },
    unassignedIpAddresses: undefined,
    value: undefined,
}
