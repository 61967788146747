import {format} from 'date-fns'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {activeLocationSelector} from '../../../../../../store/state/locations/selectors'
import {isActiveLocation} from '../../../../../../store/state/locations/state'
import {CenteredContentArea} from '../centered-content-area.styled'
import {LineOne} from './line-one.styled'
import {LineTwo} from './line-two.styled'
import {VesselName} from './vessel-name.styled'

interface NoDataContentProps {
    text: string
}

export function NoDataContent({text}: NoDataContentProps): JSX.Element {
    const activeLocation = useTypedSelector(activeLocationSelector)
    return (
        <CenteredContentArea>
            {isActiveLocation(activeLocation) && (
                <VesselName>{activeLocation.description}</VesselName>
            )}
            <LineOne>{text}</LineOne>
            <LineTwo>{format(new Date(), 'dd/MM/yyy HH:mm')}</LineTwo>
        </CenteredContentArea>
    )
}
