import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {ThreatMeasure} from '../../../../../../values/ThreatMeasure'

interface RiskScoreIndicatorProps {
    score: number
    threatMeasure: ThreatMeasure
}

function getBackgroundColor(
    score: number,
    theme: CyberOwlTheme,
    threatMeasure: ThreatMeasure,
): string {
    if (score >= threatMeasure.upperThreshold) {
        return theme.threatScores.score.high.background
    } else if (score >= threatMeasure.lowerThreshold) {
        return theme.threatScores.score.medium.background
    }
    return 'inherit'
}

export const RiskScoreIndicator = styled.span<RiskScoreIndicatorProps>`
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${(props) =>
        getBackgroundColor(props.score, props.theme, props.threatMeasure)};
    left: -7.5px;
    top: -7.5px;
    border: solid 1px ${(props) => props.theme.dashboard.vesselList.backgroundColor};
`
