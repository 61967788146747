import * as Styled from './_styled/self-monitoring-engine-table.styled'
import {SelfMonitoringEngineEvent} from '../../../../../values/self-monitoring-event'
import moment from 'moment'

interface TableTimestampCellProps {
    event: SelfMonitoringEngineEvent
    gridRow: number
}

export function TableTimestampCell({event, gridRow}: TableTimestampCellProps): JSX.Element {
    const timeStamp = moment(event.timestamp)

    return (
        <Styled.TableDataCell
            gridRow={gridRow}
            gridColumn={3}
            id={`timestamp-value_${event.identity}`}
        >
            <Styled.TimestampValue>
                {timeStamp.fromNow()}
                <Styled.TableDataCellSpan>
                    {timeStamp.format('(DD MMM YYYY - HH:mm)')}
                </Styled.TableDataCellSpan>
            </Styled.TimestampValue>
        </Styled.TableDataCell>
    )
}
