import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const AlertWrapper = styled.div`
    height: 137px;
    width: 578px;
    background: ${(props) => props.theme.modalDialog.background};
    border-radius: 3px;
    border: 1px solid black;
    padding: ${spacing(2)};
`
export const Title = styled.h1`
    ${smallFont()};
    margin: 0;
    padding-top: ${spacing(2)};
`
export const Message = styled.h2`
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(4)} ${spacing(2)} ${spacing(2)} 0;
    margin: 0;
`

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: ${spacing(2)};
    column-gap: ${spacing(2)};
`
export const ButtonStyled = styled.button`
    ${smallFont()};
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    min-width: 110px;
    :disabled {
        opacity: 0.5;
    }
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
