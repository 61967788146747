import {ButtonsArea} from './set-usb-purpose.styled'
import {usePagedUSBDevices} from '../../../../contexts/use-paged-usb-devices'
import {MouseEvent} from 'react'
import {Button} from '../../../../../../components/refresh-button/button.styled'

interface SubmitAreaProps {
    purpose: string | undefined
}

export function SubmitArea({purpose}: SubmitAreaProps): JSX.Element {
    const {submitUsbPurpose, usbDetailsOutput, displaySetPurposeModal, findUSBDeviceForModal} =
        usePagedUSBDevices()
    const isDisabled =
        !purpose || purpose.trim().length === 0 || purpose.trim() === findUSBDeviceForModal?.purpose
    function onCancelClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        displaySetPurposeModal(false)
    }
    function onSaveClick(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        !isDisabled &&
            submitUsbPurpose(usbDetailsOutput.identity, purpose).then(() =>
                displaySetPurposeModal(false),
            )
    }
    return (
        <ButtonsArea id="set-usb-purpose-ButtonsArea">
            <Button id="cancel-button" onClick={onCancelClick}>
                Cancel
            </Button>
            <Button id="save-button" disabled={isDisabled} onClick={onSaveClick}>
                Save
            </Button>
        </ButtonsArea>
    )
}
