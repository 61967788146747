import produce from 'immer'
import type {AssetList} from '../../location-inventory-page-context'
import ActionType from './action-type'
import type {Action} from './actions'

export const assetListReducer = produce((draft: AssetList['state'], action: Action) => {
    switch (action.type) {
        case ActionType.TOGGLE_ASSET_ROW: {
            // Add row to expanded list
            if (action.payload.isExpanded) {
                const isAlreadyExpanded = draft.expandedAssetId.find(
                    (id) => id === action.payload.assetId,
                )

                if (!isAlreadyExpanded) {
                    draft.expandedAssetId.push(action.payload.assetId)
                }
            }
            // Remove row from expanded list
            else {
                const index = draft.expandedAssetId.findIndex((id) => id === action.payload.assetId)

                // Item is currently expanded
                if (index !== -1) {
                    draft.expandedAssetId.splice(index, 1)
                }
            }

            break
        }

        default:
            break
    }
})
