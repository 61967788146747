import styled from 'styled-components'
import {mediumFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT = 550

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width >= BREAK_POINT ? '400px' : '350px')};
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lowContrast};
`

export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`

export const Details = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'NAME NAME-VALUE' 'EMAIL EMAIL-VALUE' 'TAGS TAGS-VALUE';
    grid-row-gap: ${spacing(3)};
    grid-column-gap: ${spacing(1)};
    padding: ${spacing(2)} ${spacing(1)};
`

export const DetailPanelSection = styled.section`
    width: 100%;
    padding: ${spacing(3)};
`
export const DetailRow = styled.div`
    display: flex;
    padding: 5px 0;
    ${smallFont()}
`

export const RoleHeaderWrapper = styled.div`
    display: flex;
    column-gap: ${spacing(5)};
`

export const Select = styled.select`
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 3px 0;
    ${smallFont()}
    border-radius: 3px;
    background: ${(props) => props.theme.colors.background.lowContrast};
    height: 25px;
`

export const RoleDetails = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'NAME NAME-VALUE' 'EMAIL EMAIL-VALUE' 'TAGS TAGS-VALUE';
    grid-row-gap: ${spacing(2)};
    grid-column-gap: ${spacing(4)};
    padding: ${spacing(3)} ${spacing(1)};
`
