import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

interface SectionProps {
    isInactive?: boolean
}

export const Section = styled.section<SectionProps>`
    width: 100%;
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${(props) => (props.isInactive ? 'pointer-events: none;' : '')}
`

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(4)};
    margin: 0;
    border-top: 1px solid ${(props) => props.theme.vesselFilter.filterBar.titleSeparator};
    width: 100%;
`

export const SectionContent = styled.div`
    ${mediumSmallFont()}
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
`

export const SectionTitle = styled.h3`
    ${mediumSmallFont()}
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
`
