import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {smallFont} from '../../../../theme/font-styles'

export const SavedFilterWrapper = styled.div`
    position: relative;
    width: 100%;
    align-items: center;
    border-radius: ${spacing(1)};
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    padding: ${spacing(1)};
    margin-bottom: ${spacing(1)};
    cursor: pointer;
`

export const FilterName = styled.div`
    display: flex;
    justify-content: flex-start;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

interface DeleteProps {
    isDeletable: boolean
}

export const Delete = styled.div<DeleteProps>`
    display: flex;
    justify-content: flex-end;
    gap: ${spacing(1)};
    ${smallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    cursor: ${(props) => (props.isDeletable ? 'pointer' : 'default')};
    ${(props) => (!props.isDeletable ? 'pointer-events: none; opacity: 0.3;' : '')};
`
export const SavedFiltersWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;
`

export const SavedFiltersDropdown = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 500px;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    padding: ${spacing(3)};
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    gap: ${spacing(2)};
    margin-top: -${spacing(2)};
`

export const Title = styled.div`
    ${smallFont()}
`

interface InputProps {
    isChanged: boolean
}

export const Input = styled.input<InputProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    padding: ${spacing(1)};
    ${smallFont()}
    height: 30px;
`
export const ButtonsArea = styled.div`
    display: flex;
    justify-content: center;
    column-gap: ${spacing(4)};
    padding-top: ${spacing(2)};
`

export const ButtonStyled = styled.button`
    ${smallFont()};
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 6px;
    padding: ${spacing(1)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    min-width: 110px;
    :disabled {
        opacity: 0.5;
        cursor: default;
    }
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`

export const Button = styled(ButtonStyled)`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    justify-content: center;
    text-transform: uppercase;
    min-width: 110px;
`

interface ResultMessageProps {
    isSaved: boolean
}

export const ResultMessage = styled.div<ResultMessageProps>`
    text-align: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: ${(props) =>
        props.isSaved ? props.theme.colors.text.success : props.theme.colors.text.error};
`
