import {useDispatch} from 'react-redux'
import {Checkbox} from '../../../components/form-elements/checkbox'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../store/state/locations/selectors'
import {LocationIdType} from '../../../store/state/locations/state'
import {numberOfNodesForLocationCachedReselector} from '../../../store/state/nodes/re-reselectors/number-of-nodes-for-location-cached-reselector'
import {isInactiveSelector} from '../../../store/state/saved-filters/selectors'
import {pageTypeSelector} from '../../../store/state/vessel-filter/selectors'
import {PageType} from '../data-helper'
import * as Styles from './_styled/vessel-row.styled'
import {logFilterByTypes} from '../../../store/state/audit-log/action-creators'

interface Props {
    locationId: LocationIdType
    checked: boolean
    setChecked: (locationId: LocationIdType, checked: boolean) => void
    highlightedFilerValue: boolean
}
export function VesselRow({
    locationId,
    checked,
    setChecked,
    highlightedFilerValue,
}: Props): JSX.Element {
    const location = useTypedSelector(locationMapSelector).get(locationId)
    const assetCount = useTypedSelector((state) =>
        numberOfNodesForLocationCachedReselector(state, locationId),
    )
    const pageType = useTypedSelector(pageTypeSelector)
    const isInactive = useTypedSelector(isInactiveSelector)
    const dispatch = useDispatch()
    return (
        <Styles.TableDataRow highlightedFilerValue={highlightedFilerValue}>
            <Checkbox
                id={`vessel-filter-${locationId}`}
                checked={checked}
                onChange={() => {
                    setChecked(locationId, !checked)
                    dispatch(
                        logFilterByTypes(
                            'Vessels ids',
                            `Vessel value: ${locationId}`,
                            !checked,
                            pageType,
                        ),
                    )
                }}
                label={location?.description || 'Unknown'}
                isInactive={isInactive}
            />

            {(pageType === PageType.MY_VESSELS || pageType === PageType.VESSELS_BETA) && (
                <Styles.Assets id="assets-count">
                    {assetCount <= 1 ? `${assetCount} asset` : `${assetCount} assets`}
                </Styles.Assets>
            )}
        </Styles.TableDataRow>
    )
}
