import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

import {Icon} from '@cyberowlteam/cyberowl-ui-components'

export const SearchInput = styled.input.attrs({type: 'text'})`
    width: 100%;
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.search.border};
    padding: ${spacing(2)} ${spacing(2)} ${spacing(2)} ${spacing(2)};
    border-radius: 5px;
`

export const VesselContentArea = styled.div`
    margin: 0 60px;
`

export const VesselSearchContentArea = styled.div`
    position: relative;
    width: 100%;
    max-width: 382px;
    margin-bottom: ${spacing(2)};
`

export const VesselListContentArea = styled.div`
    display: grid;
    row-gap: 40px;
    column-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

export const NoVesselContent = styled.div`
    background-color: #cccccc;
    padding: 0.5em;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    grid-column: 1 / -1;
    margin-top: 4rem;
`

export const SearchIcon = styled(Icon)`
    right: ${spacing(2)};
    top: ${spacing(2)};
    position: absolute;
    color: blue;
`

export const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing(4)};
`

export const PageTitle = styled.h2`
    font-size: 30px;
    line-height: 40.85px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.bold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    color: black;
`

export const ButtonWrapper = styled.div`
    display: flex;
`
