import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellSensorMac(): JSX.Element {
    const {data, initialData, displayData, changeSensorMac} = useVesselConfig()
    const changed = data.sensorMac !== initialData.sensorMac
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeSensorMac(e.target.value)
    }
    return (
        <DataInput
            label="Sensor MAC"
            initialValue={displayData.sensorMac}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
