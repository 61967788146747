import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'

export const TitleWrapper = styled.div`
    display: flex;
    column-gap: 15px;
    padding: 20px 0;
    font-size: 20px;
`

export function getColor(targetType: string): string {
    switch (targetType) {
        case 'above':
            return '#7ABD3D;'
        case 'below':
            return '#BD3D45'
        default:
            return 'inherit'
    }
}
interface ScoreBarNoIssueProps {
    score: number
    targetType: string
    showFilterBar: boolean
    width: number
}

export const ScoreBar = styled.div<ScoreBarNoIssueProps>`
    position: relative;
    height: 28px;
    width: ${(props) => (props.score / 100) * getScorebarWidth(props.showFilterBar, props.width)}px;
    background-color: ${(props) => getColor(props.targetType)};
    border-radius: 3px;
`

interface SectionProps {
    showFilterBar: boolean
    width: number
}

export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => getsectionWidth(props.showFilterBar, props.width)}px;
    ${mediumSmallFont()}
`
export const SubTitle = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
`
export const TargetSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-left: 10px;
`
export const ScoreBarWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 20px;
`
export const Text = styled.div`
    ${mediumSmallFont()};
    padding-left: 10px;
    font-weight: ${(props) => props.theme.font.weight.normal};
`

function getScorebarWidth(showFilterBar: boolean, width: number): number {
    return showFilterBar ? 150 : width > 540 ? 240 : 100
}
function getsectionWidth(showFilterBar: boolean, width: number): number {
    return showFilterBar ? 350 : width > 540 ? 440 : 300
}
