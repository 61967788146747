import * as Styled from './detail-panel-header.styled'
import CLOSE_ICON from '../../../../../../@assets/icons/close-panel.svg'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {findMetricCategory, getMetricTypeIcon} from '../../../data-helper'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'

export function DetailPanelHeader(): JSX.Element {
    const {
        closeMetricsDetailsModal,
        isProcessingMetricDetailsModal,
        findMetricDetailsForModal,
        idForMetricDetailsModal,
    } = usePagedMetricsBeta()
    if (!isProcessingMetricDetailsModal) {
        return (
            <Styled.DetailPanelHeader>
                <DataLoading />
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        closeMetricsDetailsModal()
                    }}
                    id="close-details-icon"
                />
            </Styled.DetailPanelHeader>
        )
    }
    return (
        <Styled.DetailPanelHeader>
            <Styled.TitleWrapper>
                {idForMetricDetailsModal?.metric && (
                    <img
                        src={getMetricTypeIcon(findMetricCategory(idForMetricDetailsModal?.metric))}
                        style={{width: '35px', height: '35px'}}
                    />
                )}
                <Styled.Title>{findMetricDetailsForModal?.metricName}</Styled.Title>
                <Styled.VesselTitle>{findMetricDetailsForModal?.locationName}</Styled.VesselTitle>
            </Styled.TitleWrapper>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    closeMetricsDetailsModal()
                }}
                id="close-details-icon"
            />
        </Styled.DetailPanelHeader>
    )
}
