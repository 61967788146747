import {TableHeaderRow} from './table-header-row'
import {MouseEvent} from 'react'
import {NoDataMessageGrid} from './_styled/no-data-table-row.styled'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {TableErrorCell} from '../../common/table/table-error-cell.styled'
import {ErrorMessage} from '../../common/table/error-message.styled'
import {RetryButton} from '../../common/retry-button.styled'
import {usePagedUniqueFlows} from '../../../../contexts/unique-flows/use-paged-unique-flows'

export function RequestError(): JSX.Element {
    const {refreshData} = usePagedUniqueFlows()

    function onClickHandler(e: MouseEvent) {
        refreshData()
        e.preventDefault()
    }

    return (
        <>
            <TableHeaderRow />
            <NoDataMessageGrid>
                <TableDataRow gridRow={1} />
                <TableErrorCell gridRow={1} gridColumn={3} centered>
                    <ErrorMessage id="unique_flows_error_message">
                        Sorry, an error occured whilst requesting unique flow data
                    </ErrorMessage>
                    <RetryButton id="unique_flows_error_retry" onClick={onClickHandler}>
                        Retry
                    </RetryButton>
                </TableErrorCell>
            </NoDataMessageGrid>
        </>
    )
}
