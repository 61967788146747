import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {notesFont} from '../notes/incident-note.styled'

export const Wrapper = styled.div`
    border-top: 2px solid ${(props) => props.theme.incidents.dataRow.separator};
`
export const RecommendationsTitle = styled.h2`
    margin: 0;
    padding: ${spacing(2)};
    font-size: 16px;
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const RecommendationsList = styled.ul`
    padding-inline-start: 0;
`

export const RecommendationElement = styled.li`
    ${notesFont()};
    word-break: break-word;
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: ${spacing(1)} 0 0 0;
    line-height: 14px;
    text-align: justify;
`

export const TitleRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${spacing(2)} 0 ${spacing(5)};
`
