export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED USB DEVICES CONTEXT] SET SELECTED PAGE',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED USB DEVICES CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED USB DEVICES CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED USB DEVICES CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    RESET_USB_DEVICES_RESPONSES = '[PAGED USB DEVICES CONTEXT] RESET USB_DEVICES RESPONSES',
    DISPLAY_FILTER_BAR = '[PAGED USB DEVICES CONTEXT] DISPLAY FILTER BAR',
    SET_ID_USB_DETAILS_MODAL = '[PAGED USB DEVICES CONTEXT] SET ID USB DETAILS MODAL',
    SET_FILTER = '[PAGED USB DEVICES CONTEXT] SET FILTER',
    DISPLAY_USB_DETAILS_MODAL = '[PAGED USB DEVICES CONTEXT] DISPLAY USB DETAILS MODAL',
    USB_DEVICE_STATE_CHANGE = '[PAGED USB DEVICES CONTEXT] STATE CHANGED',
    RECEIVED_REQUESTED_USB_DETAILS = '[PAGED USB DEVICES CONTEXT] RECEIVED REQUESTED USB DETAILS',
    SET_USB_DETAILS_OUTPUT = '[PAGED USB DEVICES CONTEXT] SET USB DETAILS OUTPUT',
    DISPLAY_SET_PURPOSE_MODAL = '[PAGED USB DEVICES CONTEXT] DISPLAY SET PURPOSE MODAL',
    REPORT_CREATE = '[PAGED USB DEVICES CONTEXT] REPORT CREATE',
    SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[PAGED USB DEVICES CONTEXT] show report popup',
}
