import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../theme/theme'
import {getHorizontalPagePadding, narrowFilterLayout} from './components/data-helpers'

interface WideContentAreaProps {
    width: number
    fullHeight: boolean
}
export const WideContentArea = styled.div<WideContentAreaProps>`
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: ${spacing(4)};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    margin: 0 auto;
    width: 100%;
    ${(props) => (props.fullHeight ? 'height: 100%;' : '')}

    padding: 0 ${(props) =>
        props.width < 340 ? `${spacing(2)}` : getHorizontalPagePadding(props.width)};
`
export const LHFilterBar = styled.div`
    padding: ${spacing(6)} 0;
    width: 388px;
    min-width: 320px;
    overflow-y: auto;
`
export const ScrollableContent = styled.div`
    padding: 0;
    overflow-y: auto;
`
export const Content = styled.div`
    flex: 1;
`
interface FilterBarWrapperProps {
    width: number
}
export const FilterBarWrapper = styled.div<FilterBarWrapperProps>`
    min-width: 320px;
    width: ${(props) => (narrowFilterLayout(props.width) ? '388px' : 'fit-content')};
    display: flex;
    flex-direction: column;
    margin: ${spacing(12)} auto;
`

export const TopCloseFilterBar = styled.div`
    margin: 0 auto ${spacing(3)} auto;
`

export const BottomCloseFilterBar = styled.div`
    margin: ${spacing(3)} auto 0 auto;
`
