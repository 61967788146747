import {useRef, useState} from 'react'
import * as Styled from './view-controls.styled'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {PossibleColumnsVesselBeta} from '../../../contexts/types/paged-vessels-beta-state'
import {usePagedVesselsBeta} from '../../../contexts/use-paged-vessels-beta'
import {X} from 'react-feather'
import {getDisplayColumnName} from '../../../contexts/context-data-helper'
import {useDispatch} from 'react-redux'
import {logSelectedColumns} from '../../../../../store/state/audit-log/action-creators'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {selectedColumnsVesselsBetaFilterSelector} from '../../../../../store/state/vessels-beta-filter/selectors'

export function ViewControlsFilter(): JSX.Element {
    const [openColumnsRecommendation, setOpenColumnsRecommendation] = useState(false)
    const dispatch = useDispatch()
    const allPossibleValues: PossibleColumnsVesselBeta[] = [
        PossibleColumnsVesselBeta.NewInventoryAllTypes,
        PossibleColumnsVesselBeta.TotalInventoryAllTypes,
        PossibleColumnsVesselBeta.UntrustedInventoryAllTypes,
        PossibleColumnsVesselBeta.TrustedInventoryAllTypes,
        PossibleColumnsVesselBeta.NewInventoryAllTypesPreviousPeriod,
        PossibleColumnsVesselBeta.MissingNetworkDevicesBusinessNetwork,
        PossibleColumnsVesselBeta.MissingNetworkDevicesOTNetwork,
        PossibleColumnsVesselBeta.MissingNetworkDevicesMonitoredAssets,
        PossibleColumnsVesselBeta.NumberOfIncidentsRaised,
        PossibleColumnsVesselBeta.NumberOfCurrentOpenIncidents,
        PossibleColumnsVesselBeta.NumberOfIncidentsOpen,
        // PossibleColumnsVesselBeta.IncidentsBySeverityHigh,
        PossibleColumnsVesselBeta.IncidentsRaisedPreviousPeriod,
        // PossibleColumnsVesselBeta.IncidentsRaisedComparedToWholeFleet,
        // PossibleColumnsVesselBeta.NumberOfIncidentsAssignedToVessel,
        PossibleColumnsVesselBeta.FrameworkProtection,
        PossibleColumnsVesselBeta.FrameworkMaintenance,
        PossibleColumnsVesselBeta.FrameworkBehaviour,
        // PossibleColumnsVesselBeta.AssetsWithWorstMetrics,
        PossibleColumnsVesselBeta.FrameworkSummaryScorecard,
        PossibleColumnsVesselBeta.FrameworkSummaryTarget,
        PossibleColumnsVesselBeta.FrameworkSummaryBenchmark,
        PossibleColumnsVesselBeta.FrameworkSummaryTrend,
        PossibleColumnsVesselBeta.AssetsAtRisk,
        PossibleColumnsVesselBeta.AssetsAtRiskHighValue,
        PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidents,
        PossibleColumnsVesselBeta.MonitoredAssetsInOpenIncidentsAverageTime,
        PossibleColumnsVesselBeta.OldestOpenIncident,
        PossibleColumnsVesselBeta.CurrentVesselScore,
        PossibleColumnsVesselBeta.NumberOfDaysSinceLastData,
        PossibleColumnsVesselBeta.NumberOfDaysSinceLastNetworkData,
        PossibleColumnsVesselBeta.DeploymentSummary,
    ]
    const {setActiveColumns, highlightedFilerValue} = usePagedVesselsBeta()
    const selectedColumns = useTypedSelector(selectedColumnsVesselsBetaFilterSelector)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setOpenColumnsRecommendation(false)
    })

    function onDeleteClick(columnToDelete: PossibleColumnsVesselBeta) {
        setActiveColumns(columnToDelete, !selectedColumns.includes(columnToDelete), dispatch)
        dispatch(logSelectedColumns(columnToDelete, false))
    }

    const selectedColumnsToDisplay = selectedColumns?.length !== 0
    const showSelectionIndicator = !selectedColumnsToDisplay || !openColumnsRecommendation
    return (
        <Styled.Wrapper>
            <Styled.FilterHeadingRow>View Controls</Styled.FilterHeadingRow>
            <Styled.SubHeadingRow>Beta version</Styled.SubHeadingRow>
            <Styled.SelectionProcessWrapper
                ref={clickOutside}
                onClick={() => {
                    !openColumnsRecommendation && setOpenColumnsRecommendation(true)
                }}
                highlightedFilerValue={highlightedFilerValue && !selectedColumnsToDisplay}
            >
                {selectedColumnsToDisplay && (
                    <Styled.SelectedColumnsWrapper>
                        <Styled.ColumnContentWrapper>
                            {selectedColumns?.map((selectedColumn) => (
                                <SelectedColumns
                                    key={selectedColumn}
                                    columnName={selectedColumn}
                                    onDeleteFn={() => onDeleteClick(selectedColumn)}
                                />
                            ))}
                        </Styled.ColumnContentWrapper>
                    </Styled.SelectedColumnsWrapper>
                )}
                {showSelectionIndicator && (
                    <>
                        {!selectedColumnsToDisplay && <Styled.EmptyBox />}
                        <Styled.SelectionIndicator id="column-search">
                            Choose up to 5 columns to be displayed
                        </Styled.SelectionIndicator>
                    </>
                )}
                <Styled.ColumnsResultsWrapper>
                    {openColumnsRecommendation && (
                        <Styled.OpenColumnsRecommendationDropdown>
                            {allPossibleValues?.map((columnName) => (
                                <ColumnTypeRow
                                    columnName={columnName}
                                    key={columnName}
                                    allPossibleValues={allPossibleValues}
                                />
                            ))}
                        </Styled.OpenColumnsRecommendationDropdown>
                    )}
                </Styled.ColumnsResultsWrapper>
            </Styled.SelectionProcessWrapper>
        </Styled.Wrapper>
    )
}
interface ColumnTypeRowProps {
    columnName: PossibleColumnsVesselBeta
    allPossibleValues: PossibleColumnsVesselBeta[]
}
function ColumnTypeRow({columnName}: ColumnTypeRowProps): JSX.Element {
    const {setActiveColumns} = usePagedVesselsBeta()
    const dispatch = useDispatch()
    const selectedColumns = useTypedSelector(selectedColumnsVesselsBetaFilterSelector)

    function isChecked(givenValue: PossibleColumnsVesselBeta): boolean {
        try {
            return selectedColumns?.includes(givenValue) ?? false
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return false
        }
    }

    function setChecked(
        currentSelectedColumnType: PossibleColumnsVesselBeta,
        newValue: boolean,
    ): void {
        setActiveColumns(currentSelectedColumnType, newValue, dispatch)
        dispatch(logSelectedColumns(currentSelectedColumnType, true))
    }

    const isInactiveSelection = (selectedColumns?.length ?? 0) > 4

    return (
        <Styled.CheckboxWrapper>
            <Checkbox
                id={`vessels-beta-filter-${columnName}`}
                label={getDisplayColumnName(columnName)}
                checked={isChecked(columnName)}
                onChange={(newValue) => setChecked(columnName, newValue)}
                disabled={isInactiveSelection}
            />
        </Styled.CheckboxWrapper>
    )
}
interface SelectedColumnsProps {
    columnName: PossibleColumnsVesselBeta
    onDeleteFn?: () => void
}

function SelectedColumns({columnName, onDeleteFn}: SelectedColumnsProps): JSX.Element {
    return (
        <Styled.TagResult>
            <Styled.TagItem>{getDisplayColumnName(columnName)}</Styled.TagItem>
            <Styled.XIcon onClick={onDeleteFn}>
                <X onClick={onDeleteFn} height={12} width={12} />
            </Styled.XIcon>
        </Styled.TagResult>
    )
}
