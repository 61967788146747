import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../../theme/font-styles'

export const Label = styled.label`
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    padding-top: 3px;
    margin: 0;
`
export const Value = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const ReadOnlyValue = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const inputNonText = styled.input`
    ${mediumSmallFont()};
    width: 100%;
    height: 31px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`
export const Input = styled.textarea`
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
`
export const WarningMessage = styled.div`
    color: ${(props) => props.theme.colors.text.default};
    background-color: #fcd5db;
    ${smallFont()};
`

export const CcEmailWrapper = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    background-color: ${(props) => props.theme.incidents.dataRow.editNoteBackground};
    ${mediumSmallFont()}
    padding: 2px 3px;
    border-radius: 2px;
    margin: 3px;
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
`

export const CcEmail = styled.div`
    width: fit-content;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
`
