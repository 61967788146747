import {Action} from './state/vessels/actions'
import {DEFAULT_OT_LOCATIONS_STATE} from './types/ot-inventory-response'
import {OTLocationsState} from './types/ot-locations-state'
import {Dispatch, createContext} from 'react'

interface vesselListContext {
    state: OTLocationsState
    dispatch: Dispatch<Action> | undefined
}

export const VesselListContext = createContext<vesselListContext>({
    state: DEFAULT_OT_LOCATIONS_STATE,
    dispatch: undefined,
})
