import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {removeQueryParam} from '../../../../helpers/navigation'
import {NETWORK_ASSET_DETAILS} from '../../components/helpers/data-helpers'
import {fetchNetworkAssetsFilter} from '../../../../store/state/network-assets-filter/action-creators'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {savedNetworkAssetsFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {RefreshButton} from '../../../../components/refresh-button/refresh-button'
import {PageType} from '../../../../components/vessel-filters/data-helper'

export function RefreshButtonNetworkAssets(): JSX.Element {
    const dispatch = useDispatch()
    const {refreshData} = usePagedUnknownAssetsBeta()
    const savedFilters = useTypedSelector(savedNetworkAssetsFiltersSelector)

    function refreshFunction() {
        refreshData()
        removeQueryParam(NETWORK_ASSET_DETAILS)
        dispatch(fetchNetworkAssetsFilter(savedFilters))
    }

    return (
        <RefreshButton
            longText="Refresh Assets"
            onClick={refreshFunction}
            pageType={PageType.NETWORK_ASSETS}
        />
    )
}
