import {REST} from '../../../..'
import {INCIDENTS_ENDPOINT} from './vessels-beta-endpoints'
export interface AverageTimeToResolveIncidentsResponse {
    locationId: string
    averageResolveTime: string
}
export async function buildAverageTimeInOpenIncidents(): Promise<
    AverageTimeToResolveIncidentsResponse[]
> {
    try {
        const response = await REST.get(`${INCIDENTS_ENDPOINT}/averageResolveTime/summary`)

        const resultArray = response?.data as Array<{
            location: string
            averageResolveTime: string
        }>
        return resultArray?.map((oneLocation) => {
            return {
                locationId: oneLocation?.location,
                averageResolveTime: oneLocation?.averageResolveTime,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<AverageTimeToResolveIncidentsResponse>()
    }
}
