import {useState} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {addNewEmailRecipient} from '../../../../../store/state/email-recipient-management/action-creators'
import {usersSelector} from '../../../../../store/state/users/selectors'
import CLOSE_ICON from '../../../../../@assets/icons/close-assign-to-icon.svg'
import {Checkbox} from './checkbox'
import * as Styled from './medulla-users-drop-down.styled'
import {emailRecipientDataSelector} from '../../../../../store/state/email-recipient-management/selectors'
interface MedullaUsersDropDownProps {
    setOpenMedullaUsers: (value: boolean) => void
}
export function MedullaUsersDropDown({
    setOpenMedullaUsers,
}: MedullaUsersDropDownProps): JSX.Element | null {
    const externalUsers = useTypedSelector(usersSelector).filter(
        (user) => !user.internalUser && user.active && !user.deleted,
    )
    const data = useTypedSelector(emailRecipientDataSelector).map((recipient) => recipient.user)
    const filteredUsers = externalUsers.filter(
        (user) => user.user !== data.find((userId) => userId === user.user),
    )
    const [selectedUser, setSelectedUser] = useState('')
    const dispatch = useDispatch()
    function setChecked(user: string): void {
        setSelectedUser(user)
    }

    const noUsersToDisplay = filteredUsers.length === 0
    return (
        <Styled.DropdownContentStyle>
            <Styled.CloseImage
                src={CLOSE_ICON}
                onClick={() => {
                    setOpenMedullaUsers(false)
                }}
                id="close-details-icon"
            />

            <Styled.Options>
                {filteredUsers.map((user) => (
                    <Styled.Option key={user.user}>
                        <Checkbox
                            id={user.user}
                            checked={user.user === selectedUser}
                            onChange={() => {
                                setChecked(user.user)
                            }}
                            label={user.username}
                        />
                    </Styled.Option>
                ))}
                {noUsersToDisplay && (
                    <Styled.ErrorMessage>No more users to select</Styled.ErrorMessage>
                )}
            </Styled.Options>
            <Styled.ButtonsWrapper>
                <Styled.CancelButton
                    onClick={() => {
                        setSelectedUser('')
                        setOpenMedullaUsers(false)
                    }}
                >
                    Cancel
                </Styled.CancelButton>
                <Styled.AddButton
                    disabled={selectedUser === ''}
                    onClick={() => {
                        dispatch(addNewEmailRecipient(undefined, selectedUser))
                        setOpenMedullaUsers(false)
                    }}
                >
                    Add
                </Styled.AddButton>
            </Styled.ButtonsWrapper>
        </Styled.DropdownContentStyle>
    )
}
