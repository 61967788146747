import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../theme/theme'

interface VesselsStyleProps {
    width: number
}
export const BREAK_POINT = 600

export const Vessels = styled.div<VesselsStyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.75rem;
    row-gap: ${spacing(2)};
`
export const Layout = styled.div<VesselsStyleProps>`
    margin: ${(props) => (props.width > MAX_SCREEN_WIDTH ? spacing(2) : 0)} 0;
    padding: ${(props) => (props.width > BREAK_POINT ? spacing(8) : spacing(4))};
    padding-top: ${(props) => (props.width > BREAK_POINT ? spacing(4) : spacing(2))};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
    color: ${(props) => props.theme.colors.text.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
`
