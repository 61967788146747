import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

function getColumnTemplate(width: number): string {
    if (width < 700) {
        return 'repeat(2, [col-start] 1fr);'
    }
    return 'repeat(3, [col-start] 1fr);'
}
export const Card = styled.div`
    color: ${(props) => props.theme.usbDevices.dataRow.text};
    background-color: ${(props) => props.theme.usbDevices.dataRow.background};
    box-shadow: ${(props) =>
        `1px 1px 6px -2px ${props.theme.commonTableViewPage.dataRow.boxShadow}`};
`
interface CardAreaProps {
    width: number
}
export const CardAreaContent = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
`
export const CardAreaTitle = styled.div<CardAreaProps>`
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) => getTitleGridTemplate(props.width)};
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(2)} 0 ${spacing(2)};
`
interface CardVendorTitleDataProps {
    gridArea: string
}
export const CardVendorTitleData = styled.h2<CardVendorTitleDataProps>`
    display: flex;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    margin: 0;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`
interface CardProductTitleDataProps {
    gridArea: string
}
export const CardProductTitleData = styled.h2<CardProductTitleDataProps>`
    display: flex;
    margin: 0px;
    align-items: center;
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 21px;
    grid-area: ${(props) => props.gridArea};
    word-break: break-word;
`

function getTitleGridTemplate(width: number): string {
    if (width < 700) {
        return "'VENDOR-TITLE PRODUCT-TITLE'"
    }
    return "'VENDOR-TITLE EMPTY PRODUCT-TITLE'"
}

function getGridTemplate(width: number): string {
    if (width < 700) {
        return '"WHEN LAST-ACTIVE" "TYPE VOLUME-LABELS" "PURPOSE CONNECTIONS"'
    }
    return "'WHEN TYPE PURPOSE' 'LAST-ACTIVE VOLUME-LABELS CONNECTIONS'"
}

export const LinkText = styled.a`
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

interface UsbInventoryDetailsProps {
    activeUsb: boolean
}

export const UsbInventoryDetails = styled.div<UsbInventoryDetailsProps>`
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    overflow: auto;
    ${(props) =>
        props.activeUsb
            ? `border: 3px solid ${props.theme.softwareInventory.dataRow.selectedRow}`
            : ''};
`

export const ConnectionsWrapper = styled.div`
    display: flex;
    flex-basis: 100%;
    gap: ${spacing(2)};
    justify-content: center;
`

export const NumberText = styled.div`
    justify-content: flex-end;
    align-self: center;
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const PurposeText = styled.div`
    align-self: center;
    font-style: italic;
    color: ${(props) => props.theme.colors.text.default};
`
