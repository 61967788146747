import {TableDataCell} from '../table-content/_styled/table-data-cell.styled'
import {TableDataRow} from '../table-content/_styled/table-data-row.styled'
import * as Styled from './no-data.styled'

interface NoDataTableProps {
    text: string
}

export function NoData({text}: NoDataTableProps): JSX.Element {
    return (
        <Styled.NoDataMessageGrid>
            <TableDataRow gridRow={1} selectable={false} />
            <TableDataCell gridRow={1} gridColumn={5} centered selectable={false}>
                <Styled.NoDataMessage id="no-data-component">{text}</Styled.NoDataMessage>
            </TableDataCell>
        </Styled.NoDataMessageGrid>
    )
}
