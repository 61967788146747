import * as Styled from './usb-details.styled'
import {DataCell} from '../shared/data-cell'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'

export function UsbDetailedSection(): JSX.Element {
    const {usbDetailsOutput} = usePagedUSBDevices()

    return (
        <Styled.DetailsAreaWrapper id="usb-devices-details-modal_detailed-section_wrapper">
            <Styled.DetailsAreaContent id="usb-devices-details-modal_detailed-section_content">
                <DataCell label="Vendor:">{usbDetailsOutput.vendorName}</DataCell>
                <DataCell label="Product name:">{usbDetailsOutput.productName}</DataCell>
                <DataCell label="Type:">{usbDetailsOutput.deviceType}</DataCell>
                <DataCell label="Volume labels:">{usbDetailsOutput.volumeLabels}</DataCell>
            </Styled.DetailsAreaContent>
        </Styled.DetailsAreaWrapper>
    )
}
