import React from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Filter} from 'react-feather'
import {SortableColumn} from './sortable-column'
import {
    TableHeaderContent,
    Input,
    SearchIconStyle,
    InputFieldWrapper,
} from './_styled/header-column.styled'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {NetworkAssetsSortType} from '../../../../../store/state/network-assets-filter/state'

interface FilterableColumnProps {
    text: string
    colName: NetworkAssetsSortType
    gridColumn: number
    searchValue?: string
    searchFunc(event: React.ChangeEvent<HTMLInputElement>): void
}

export function FilterableColumn({
    text,
    colName,
    searchValue = '',
    searchFunc,
    gridColumn,
}: FilterableColumnProps): JSX.Element {
    const {showFilterBar} = usePagedUnknownAssetsBeta()
    return (
        <SortableColumn gridColumn={gridColumn} text={text} colName={colName}>
            <TableHeaderContent>
                <InputFieldWrapper>
                    <Input
                        type="text"
                        placeholder="- All - "
                        value={searchValue}
                        onChange={searchFunc}
                        showReducedGrid={showFilterBar}
                        id={`${colName}-input-search`}
                    />
                    <SearchIconStyle id={`${colName}-icon`}>
                        {showFilterBar &&
                            (searchValue.length ? (
                                <Filter height={15} width={15} color="#1f88e5" />
                            ) : (
                                <Icon glyph="Search" height={15} width={15} />
                            ))}
                    </SearchIconStyle>
                </InputFieldWrapper>
                {!showFilterBar &&
                    (!searchValue.length ? (
                        <Filter height={17} width={17} />
                    ) : (
                        <Filter height={17} width={17} color="#1f88e5" />
                    ))}
            </TableHeaderContent>
        </SortableColumn>
    )
}
