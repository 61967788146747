import moment from 'moment'
import {GuidType} from '../../../../values/generic-type-defintions'

export interface NmeaInventoryResponse {
    location: GuidType
    locationName: string
    talkerId: string
    talkerName: string
    sentenceId: string
    sentenceName: string
    relayIp: string
    receiver: string
    firstSeenTimestamp: string
    lastSeenTimestamp: string
    parameters: Record<string, string | number>
}

export const mockNmeaInventoryResponse: NmeaInventoryResponse = {
    location: '729f65ea-d493-4ea1-a3ba-33117e318037',
    locationName: 'INT-DEV-002',
    talkerId: 'GP',
    talkerName: 'Weather Instruments',
    sentenceId: 'GGA',
    sentenceName: 'Wind Speed and Angle',
    relayIp: '192.168.2.90',
    receiver: '224.0.1.200:44001',
    firstSeenTimestamp: '2023-10-24T09:16:09.063503Z',
    lastSeenTimestamp: '2023-10-24T11:36:35.642811Z',
    parameters: {
        'Time of fix (UTC)': 123519,
        Latitude: 4807.038,
        'North/South': 'N',
        Longitude: 1131,
        'West/East': 'E',
        'Fix quality indicator': 1,
        'Number of satellites being tracked': 8,
        'Horizontal dilution of precision (HDOP)': 0.9,
        'Altitude above mean sea level': 545.4,
        'Altitude Unit': 'M',
        'Height of geoid above WGS84 ellipsoid': 46.9,
        'Height Unit': 'M',
    },
}

function outputNumberOfFields(parameters: Record<string, string | number>): number {
    if (typeof parameters !== 'object' || !parameters) {
        return 0
    }
    return Object.keys(parameters).length
}

interface NmeaInventoryResponseOutput extends NmeaInventoryResponse {
    identity: string
    numberOfFields: number
}

export function getFormattedNMEAeData(
    nmeaItem: NmeaInventoryResponse,
): NmeaInventoryResponseOutput {
    return {
        identity: `${nmeaItem?.talkerId}-${nmeaItem?.sentenceId}-${nmeaItem?.relayIp}`,
        location: nmeaItem?.location,
        locationName: nmeaItem?.locationName,
        talkerId: nmeaItem?.talkerId,
        talkerName: nmeaItem?.talkerName,
        sentenceId: nmeaItem?.sentenceId,
        sentenceName: nmeaItem?.sentenceName,
        relayIp: nmeaItem?.relayIp,
        receiver: nmeaItem?.receiver,
        firstSeenTimestamp: moment(nmeaItem?.firstSeenTimestamp).fromNow() ?? 'Unknown',
        lastSeenTimestamp: moment(nmeaItem?.lastSeenTimestamp).fromNow() ?? 'Unknown',
        parameters: nmeaItem?.parameters,
        numberOfFields: outputNumberOfFields(nmeaItem?.parameters),
    }
}
