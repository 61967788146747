import {DiscardChangesButton} from '../discard-changes-button/discard-changes-button'
import {SaveButton} from '../save-button/save-button'
import * as Styled from './buttons-area.styled'
interface ButtonsAreaProps {
    policyToCreate?: boolean
}
export function ButtonsArea({policyToCreate}: ButtonsAreaProps): JSX.Element {
    return (
        <Styled.ButtonsWrapper>
            <DiscardChangesButton />
            <SaveButton policyToCreate={policyToCreate} />
        </Styled.ButtonsWrapper>
    )
}
