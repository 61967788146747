import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    padding-top: 3px;
    margin: 0;
    width: 60px;
`

interface ValueProps {
    gridArea: string
}

export const Value = styled.div<ValueProps>`
    display: flex;
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

interface InputProps {
    isChanged: boolean
}

export const Input = styled.input<InputProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-right: ${(props) => (props.isChanged ? 0 : `${spacing(4)}`)};
`

export const Wrapper = styled.div`
    display: flex;
`

export const WarningMessage = styled.div`
    ${smallFont()}
    color: ${(props) => props.theme.colors.text.warning};
    font-weight: ${(props) => props.theme.font.weight.normal};
`
