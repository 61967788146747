import createCachedSelector from 're-reselect'
import {normaliseScore} from '../../../../helpers/formatting'
import threatHelper from '../../../../helpers/threatHelper'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../store/state/threat-measures/selectors'
import AppState from '../../../../store/types/app-state'
import {GuidType} from '../../../../values/generic-type-defintions'
import {getScoreForNodes} from '../../../../values/nodes/reselectors/helpers'
import {AssetThreatScoreOutputModel} from './asset-threat-score.model'

export const assetThreatScoreCachedReselector = createCachedSelector(
    [
        nodesSelector,
        sfmScoresSelector,
        threatMeasuresSelector,
        (_state: AppState, assetId: GuidType) => assetId,
    ],
    (nodes, sfmScores, threatMeasures, assetId) => {
        const node = nodes.get(assetId)
        if (!node) {
            throw Error('Could not find node')
        }

        const score = getScoreForNodes(node, sfmScores)
        return {
            id: assetId,
            nodeValue: node.value,
            score: normaliseScore(score),
            threatLevel: threatHelper(score, threatMeasures, node.value),
        } as AssetThreatScoreOutputModel
    },
)((_state, assetId) => assetId)
