import * as Styled from './score-bar.styled'
import ASSET_STATUS from '../../../../@assets/icons/asset-status-metrics.svg'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {MetricType} from '../../contexts/types/metrics-response'
import {NoData} from '../no-data/no-data'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import LoadingState from '../../../../values/loading-state-enum'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {calculateScoreCardScore} from '../../contexts/context-data-helper'

interface ScoreBarProps {
    metricType: MetricType
}

export function ScoreBar({metricType}: ScoreBarProps): JSX.Element {
    const {width} = useDimensions()
    const {dataSummariesMetricsMap, showFilterBar, loadingFilteredDataState} = usePagedMetricsBeta()
    const assetStatus = dataSummariesMetricsMap?.get(metricType)?.assetStatus
    const totalAssetsOk = assetStatus?.totalAssetsOk ?? 0
    const totalAssetsWithIssue = assetStatus?.totalAssetsWithIssue ?? 0
    const totalNumberOfAssets = totalAssetsOk + totalAssetsWithIssue

    const noAssets = !assetStatus || totalNumberOfAssets === 0
    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return (
            <Styled.ScoreSection
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-score-bar-section-${metricType}`}
            >
                <TitleWrapper icon={ASSET_STATUS} title="Scorecard" identifier={metricType} />
                <DataLoading />
            </Styled.ScoreSection>
        )
    }
    if (noAssets) {
        return (
            <Styled.ScoreSection
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-score-bar-section-${metricType}`}
            >
                <TitleWrapper icon={ASSET_STATUS} title="Scorecard" identifier={metricType} />
                <NoData text="No Assets are found" />
            </Styled.ScoreSection>
        )
    }

    const assetsOkMessage = getAssetsMessage(totalAssetsOk, 'asset is ok', 'assets are ok')
    const assetsNotOkMessage = getAssetsMessage(
        totalAssetsWithIssue,
        'asset with issues',
        'assets with issues',
    )
    const score = calculateScoreCardScore(assetStatus)
    return (
        <div>
            <TitleWrapper icon={ASSET_STATUS} title="Scorecard" identifier={metricType} />
            <Styled.ColorWrapper metricType={metricType}>
                <Styled.ScoreSection
                    showFilterBar={showFilterBar}
                    width={width}
                    id={`footer-score-bar-section-${metricType}`}
                >
                    <Styled.Score id={`footer-score-number-${metricType}`}>
                        {score}
                        <Styled.ScoreTextWrapper>
                            <div style={{fontSize: '24px'}}>%</div>
                            <Styled.Text id={`footer-score-text-${metricType}`}>
                                of assets are ok
                            </Styled.Text>
                        </Styled.ScoreTextWrapper>
                    </Styled.Score>
                    <Styled.ScoreBar showFilterBar={showFilterBar} width={width}>
                        <Styled.ScoreBarNoIssue
                            score={score}
                            metricType={metricType}
                            showFilterBar={showFilterBar}
                            width={width}
                            id={`footer-score-bar-no-issues-${metricType}`}
                        />
                    </Styled.ScoreBar>
                    <Styled.Label showFilterBar={showFilterBar} width={width}>
                        <Styled.AssetsOkMessage
                            metricType={metricType}
                            id={`footer-score-bar-ok-message-${metricType}`}
                        >
                            {assetsOkMessage}
                        </Styled.AssetsOkMessage>
                        <div
                            style={{color: '#F29DAC'}}
                            id={`footer-score-bar-not-ok-message-${metricType}`}
                        >
                            {assetsNotOkMessage}
                        </div>
                    </Styled.Label>
                </Styled.ScoreSection>
            </Styled.ColorWrapper>
        </div>
    )
}
interface TitleWrapperProps {
    icon: string
    title: string
    identifier: string
}

function TitleWrapper({icon, title, identifier}: TitleWrapperProps): JSX.Element {
    return (
        <Styled.TitleWrapper id={`footer-score-bar-title-${identifier}`}>
            <img src={icon} id={`footer-score-bar-icon-${identifier}`} />
            {title}
        </Styled.TitleWrapper>
    )
}

function getAssetsMessage(count: number, singular: string, plural: string) {
    if (count === 1) {
        return `1 ${singular}`
    }
    return `${count} ${plural}`
}
