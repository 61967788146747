import * as Styled from './_styled/table.styled'
import {TableCodeCell} from './table-code-cell'
import {TableNameCell} from './table-name-cell'
import {Location} from '../../../../../store/state/locations/state'
import {Link} from 'react-router-dom'
import {getFormattedUrl} from '../../helper'
import {useState} from 'react'

interface TableRowProps {
    location: Location
    index: number
}

export function TableRow({location, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const [hoverOn, setHoverOn] = useState<boolean>(false)
    function mouseOverRow(): void {
        setHoverOn(true)
    }
    function mouseLeaveRow(): void {
        setHoverOn(false)
    }
    return (
        <Link to={getFormattedUrl(location.code)}>
            <Styled.TableDataGrid
                onMouseEnter={() => mouseOverRow()}
                onMouseLeave={() => mouseLeaveRow()}
            >
                <Styled.TableDataRow
                    gridRow={gridRow}
                    id={`location-row_${location.location}`}
                    isHovering={hoverOn}
                />
                <TableNameCell location={location} gridRow={gridRow} isHovering={hoverOn} />
                <TableCodeCell location={location} gridRow={gridRow} isHovering={hoverOn} />
            </Styled.TableDataGrid>
        </Link>
    )
}
