import {IncidentsReportFilter} from '../../../../store/state/incidents-report-filter/state'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: IncidentsReportFilter
    tooltip?: boolean
}

export function DetailFilterIncidentsReport({criteria}: DetailFilterProps): JSX.Element {
    return (
        <DetailsWrapper>
            {criteria.fromDate && <Details>• From: {criteria.fromDate}</Details>}
            {criteria.toDate && <Details>• To: {criteria.toDate}</Details>}
        </DetailsWrapper>
    )
}
