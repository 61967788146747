import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {CloseButton} from './close-button/close-button'
import * as Styles from './_styled/network-asset-details.styled'

export function LoadingDataModal(): JSX.Element {
    return (
        <Styles.NoDataContainer>
            <Styles.NoDataContent>
                <Styles.NoDataBox>
                    <DataLoading />
                </Styles.NoDataBox>
            </Styles.NoDataContent>
            <CloseButton />
        </Styles.NoDataContainer>
    )
}
