import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const PrimaryButton = styled.button`
    text-align: left;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 2px solid #1f88e5;
    background: none;
    padding: 4px 8px;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
    &:active {
        color: ${(props) => props.theme.refreshButton.active.textColor};
        background-color: ${(props) => props.theme.refreshButton.active.backgroundColor};
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
    ${mediumSmallFont()}
`
