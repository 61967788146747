import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {generateGridTemplateColumns} from './table-header.styled'

export const ContentContainer = styled.div`
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
    padding: 0 ${spacing(1)} ${spacing(2)};
`

interface TableDataGridProps {
    numberOfColumns: number
}
export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: ${(props) => generateGridTemplateColumns(props.numberOfColumns)};
    gap: ${spacing(2)};
    flex: 1;
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
`
