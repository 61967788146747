import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {TotalNumberOfIncidentsByAssignments} from '../../../../incidents-report/components/status-of-incidents-raised/total-number-of-incidents-by-assignments'
import {useIncidentsWidget} from '../contexts/use-incidents-widget'
import * as Styled from './widget-commonstyling.styled'

export function CurrentAssignmentWidget(): JSX.Element {
    const {loadedIncidentsAssignments, assignementsCounts} = useIncidentsWidget()
    return (
        <Styled.StatusFilterContainer id="assignment-section-assignment-count-container">
            <Styled.SectionText>CURRENT ASSIGNMENT OF NEW/OPEN INCIDENTS</Styled.SectionText>
            {loadedIncidentsAssignments ? (
                <TotalNumberOfIncidentsByAssignments assignementsCounts={assignementsCounts} />
            ) : (
                <DataLoading />
            )}
        </Styled.StatusFilterContainer>
    )
}
