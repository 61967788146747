import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {TableHeader} from './table-header'
import {TableHeaderGrid} from './_styled/table-header-grid.styled'

export function TableHeaderRow(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, networkAssetModalId} = usePagedUnknownAssetsBeta()

    return (
        <TableHeaderGrid
            width={width}
            betaPage={true}
            showReducedGrid={networkAssetModalId !== null || showFilterBar}
            id="TableHeaderGrid"
        >
            <TableHeader />
        </TableHeaderGrid>
    )
}
