import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataTextArea} from '../data-cell/data-text-area'

export function DataCellOtNetworks(): JSX.Element {
    const {data, initialData, displayData, changeOtNetworks} = useVesselConfig()
    const changed = data.otNetworks?.toString() !== initialData.otNetworks?.toString()
    const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        e.preventDefault()
        changeOtNetworks(e.target.value.split('\n'))
    }
    return (
        <DataTextArea
            label="OT Networks"
            initialValue={displayData.otNetworks}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
