import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import {DataInput} from '../data-cell/data-input'

export function HostnameInput(): JSX.Element {
    const {displayedHostname, currentHostname, updateAssetData, changeHostname} =
        usePagedUnknownAssetsBeta()
    const changed = currentHostname
        ? displayedHostname !== currentHostname
        : displayedHostname !== ''
    const blocked = updateAssetData.hostname === undefined || updateAssetData.hostname.length === 0

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeHostname(e.target.value)
    }

    return (
        <DataInput
            label="Hostname"
            initialValue={displayedHostname ?? ''}
            onChangeHandler={onChangeHandler}
            changed={changed}
            blocked={blocked}
        />
    )
}
