import AssetOverview from './asset-overview/asset-overview'
import {TabSection} from './tabs/tab-section'
import {PagedAlertsProvider} from '../contexts/alerts/paged-alerts-provider'
import {PagedIndicatorsProvider} from '../contexts/indicators/paged-indicators-provider'
import {GuidType} from '../../../values/generic-type-defintions'
import {FilterForm} from './filter-form/filter-form'
import {PagedUniqueFlowsProvider} from '../contexts/unique-flows/paged-unique-flows-provider'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {queryStringObjectSelector} from '../../../store/routerSelectors'
import {usePagedAlerts} from '../contexts/alerts/use-paged-alerts'
import {Overlay} from '../../shared-components/modal-overlay'

interface ModalContentProps {
    assetId: GuidType
}

export function ModalContent({assetId}: ModalContentProps): JSX.Element {
    const queryString = useTypedSelector(queryStringObjectSelector)
    const fromDate = queryString ? (queryString.fromDate as string | undefined) : undefined
    const toDate = queryString ? (queryString.toDate as string | undefined) : undefined

    return (
        <PagedAlertsProvider assetId={assetId} fromDate={fromDate} toDate={toDate} pageSize={10}>
            <PagedIndicatorsProvider
                assetId={assetId}
                fromDate={fromDate}
                toDate={toDate}
                pageSize={10}
            >
                <PagedUniqueFlowsProvider
                    assetId={assetId}
                    fromDate={fromDate}
                    toDate={toDate}
                    pageSize={10}
                >
                    <AssetOverview />
                    <TabSection />
                    <Modals />
                </PagedUniqueFlowsProvider>
            </PagedIndicatorsProvider>
        </PagedAlertsProvider>
    )
}

function Modals(): JSX.Element {
    const {showDateFilter} = usePagedAlerts()

    return (
        <>
            {showDateFilter && (
                <Overlay>
                    <FilterForm />
                </Overlay>
            )}
        </>
    )
}
