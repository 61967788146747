export enum ActionType {
    TOGGLE_INCIDENTS_LICENSE = '[LICENSE CONTEXT] TOGGLE INCIDENTS LICENSE',
    INCIDENT_POLICY_END_DATE = '[LICENSE CONTEXT] SET INCIDENT END DATE',
    TOGGLE_ONBOARD_ENGAGEMENT = '[LICENSE CONTEXT] TOGGLE ONBOARD_ENGAGEMENT',
    SET_ONBOARD_ENGAGEMENT_END_DATE = '[LICENSE CONTEXT] SET_ONBOARD_ENGAGEMENT_END_DATE',
    TOGGLE_NETWORK_ENFORCEMENT = '[LICENSE CONTEXT] TOGGLE_NETWORK_ENFORCEMENT',
    SET_NETWORK_ENFORCEMENT_MAX_VESSELS = '[LICENSE CONTEXT] SET_NETWORK_ENFORCEMENT_MAX_VESSELS',
    SET_NETWORK_ENFORCEMENT_END_DATE = '[LICENSE CONTEXT] SET_NETWORK_ENFORCEMENT_END_DATE',
    TOGGLE_REMOVABLE_ENFORCEMENT = '[LICENSE CONTEXT] TOGGLE_REMOVABLE_ENFORCEMENT',
    SET_REMOVABLE_ENFORCEMENT_MAX_VESSELS = '[LICENSE CONTEXT] SET_REMOVABLE_ENFORCEMENT_MAX_VESSELS',
    SET_REMOVABLE_ENFORCEMENT_END_DATE = '[LICENSE CONTEXT] SET_REMOVABLE_ENFORCEMENT_END_DATE',
    SAVE_BUTTON_ENABLED_STATE = '[LICENSE CONTEXT] SET SAVE BUTTON STATE',
}
