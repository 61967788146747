export function IconCancelled(): JSX.Element {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1639_9278)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 17.808C3.80558 17.808 0 13.8215 0 8.90398C0 3.98645 3.80558 0 8.5 0C13.1944 0 17 3.98645 17 8.90398C17 13.8215 13.1944 17.808 8.5 17.808ZM8.5 15.582C12.0208 15.582 14.875 12.5921 14.875 8.90398C14.875 5.21583 12.0208 2.22599 8.5 2.22599C4.97918 2.22599 2.125 5.21583 2.125 8.90398C2.125 12.5921 4.97918 15.582 8.5 15.582ZM8.5 10.478L6.0638 13.03L4.5612 11.456L6.9974 8.90398L4.5612 6.35199L6.0638 4.77798L8.5 7.32996L10.9362 4.77798L12.4388 6.35199L10.0026 8.90398L12.4388 11.456L10.9362 13.03L8.5 10.478Z"
                    fill="#F34663"
                />
            </g>
            <defs>
                <clipPath id="clip0_1639_9278">
                    <rect width="17" height="17.808" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
