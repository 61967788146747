import {useDispatch} from 'react-redux'
import {TriStateButton} from '../../../../../components/form-elements/tri-state-button/tri-state-button'
import {TriStateButtonState} from '../../../../../components/form-elements/tri-state-button/tri-state-button-state'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {toggleWholeFleetReports} from '../../../../../store/state/reports-filter/action-creators'
import {reportsFilterSelector} from '../../../../../store/state/reports-filter/selectors'

import * as Styles from './whole-fleet-reports.styled'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'

export function WholeFleetReports(): JSX.Element {
    const dispatch = useDispatch()
    const {includeWholeFleetReports} = useTypedSelector(reportsFilterSelector)
    function determineNewState(currentState: TriStateButtonState): TriStateButtonState {
        return currentState === TriStateButtonState.FULLY_SELECTED
            ? TriStateButtonState.NOT_SELECTED
            : TriStateButtonState.FULLY_SELECTED
    }
    const viewAll = determineViewAll(includeWholeFleetReports)
    function viewAllChanged(newState: TriStateButtonState): void {
        if (newState === TriStateButtonState.NOT_SELECTED) {
            dispatch(toggleWholeFleetReports(false))
            dispatch(
                logFilterByTypes(
                    'toggleWholeFleetReports',
                    'View all',
                    false,
                    AuditLogPageType.REPORTS,
                ),
            )
        } else if (newState === TriStateButtonState.FULLY_SELECTED) {
            dispatch(toggleWholeFleetReports(true))
            dispatch(
                logFilterByTypes(
                    'toggleWholeFleetReports',
                    'View all',
                    true,
                    AuditLogPageType.REPORTS,
                ),
            )
        }
    }
    return (
        <Styles.Wrapper id={`${PageType.REPORTS}_filter-bar_WholeFleetReports-filter`}>
            <Styles.Label>Whole Fleet Reports:</Styles.Label>
            <TriStateButton
                id="whole-reports-view-all"
                label=""
                state={viewAll}
                onClick={() => viewAllChanged(determineNewState(viewAll))}
            />
        </Styles.Wrapper>
    )
}
function determineViewAll(includeWholeFleetReports: boolean): TriStateButtonState {
    if (includeWholeFleetReports) {
        return TriStateButtonState.FULLY_SELECTED
    }

    return TriStateButtonState.NOT_SELECTED
}
