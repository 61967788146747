import {ReactNode, ReactNodeArray} from 'react'
import * as Styled from './_styled/data-cell.styled.ts'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {BREAK_POINT_REDUCED_COLUMN} from './_styled/asset-info-area.styled'

interface CardDataCellProps {
    label: string
    children: ReactNode | ReactNodeArray
    gridArea: string
}
export function DataCell({label, children, gridArea}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const inSameLine = width > BREAK_POINT_REDUCED_COLUMN
    const id = `asset-overview-${gridArea.toLowerCase()}`

    return (
        <>
            <Styled.Label gridArea={gridArea} id={`${id}-label`}>
                {label}
            </Styled.Label>
            <Styled.Value gridArea={`${gridArea}-VALUE`} inSameLine={inSameLine} id={`${id}-value`}>
                {children}
            </Styled.Value>
        </>
    )
}
