export function IconUploaded(): JSX.Element {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.78125 12.0312L8.59375 16.8438L18.2188 6.53125"
                stroke="#0E7A0E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
