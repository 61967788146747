import styled from 'styled-components'

export const Widget = styled.section`
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 1px 2px ${(props) => props.theme.colors.boxShadow.highContrast};
`
