import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Label = styled.label`
    ${mediumSmallFont()}
    margin-bottom: 0;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    min-width: 110px;
`

export const Text = styled.span`
    margin-left: 4px;
`

export const NewSquare = styled.span`
    display: flex;
    background-color: ${(props) => props.theme.incidents.newRecordLabel.background};
    color: ${(props) => props.theme.incidents.newRecordLabel.text};
    height: 15px;
    width: ${spacing(5)};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    line-height: 12px;
`
