import styled from 'styled-components'
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`
export const TargetBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`
interface TargetBarProps {
    colourBar: string
}
export const TargetBar = styled.div<TargetBarProps>`
    width: 50px;
    height: 12px;
    border-radius: 3px;
    background-color: ${(props) =>
        props.colourBar === 'green' ? '#7ABD3D' : props.colourBar === 'red' ? '#BD3D45' : ''};
`
