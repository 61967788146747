import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {usePolicyManagement} from '../contexts/policy-management/use-policy-management'
import {PolicyListCards} from './policy-list-card/policy-list-cards'
import {showCards} from './policy-list-table/_styled/policy-management-list-header.styled'
import {PolicyListTable} from './policy-list-table/policy-list-table'

export function DataPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {selectedPolicyId, allPolicyData, showPolicyModal} = usePolicyManagement()
    const showCardEarlier = selectedPolicyId !== null || showPolicyModal

    return showCards(width, showCardEarlier) ? (
        <PolicyListCards policies={allPolicyData} />
    ) : (
        <PolicyListTable policies={allPolicyData} />
    )
}
