import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'

export function RecordSet(): JSX.Element | null {
    const {totalNumberOfFilteredUSBDevices, selectedPage, pageSize} = usePagedUSBDevices()

    if (
        totalNumberOfFilteredUSBDevices == undefined ||
        selectedPage == undefined ||
        pageSize == undefined
    ) {
        return null
    }

    const startingIndicator = selectedPage * pageSize + 1
    const endIndicator =
        startingIndicator + pageSize > totalNumberOfFilteredUSBDevices
            ? totalNumberOfFilteredUSBDevices
            : startingIndicator + pageSize - 1

    return (
        <>
            {totalNumberOfFilteredUSBDevices !== 0 && (
                <RecordSetForPagination
                    startingIndicator={startingIndicator}
                    endIndicator={endIndicator}
                    totalNumberOfItems={totalNumberOfFilteredUSBDevices}
                />
            )}
        </>
    )
}
