import * as Styled from './card.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {CardArea} from './card-area.styled'
export function EmptyCard(): JSX.Element {
    const {width} = useDimensions()
    return (
        <CardArea width={width}>
            <Styled.EmptyCard width={width}>
                <Styled.Title>No Engine</Styled.Title>
            </Styled.EmptyCard>
        </CardArea>
    )
}
