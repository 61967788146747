import styled from 'styled-components'
import {showOneCol} from '../../shared/grid-layout-helper'
import {GridLayout} from '../../shared/grid-layout.enum'

interface HeaderStyleProps {
    gridLayout: GridLayout
}

export const HeaderStyle = styled.div<HeaderStyleProps>`
    ${(props) =>
        showOneCol(props.gridLayout) ? '' : 'display: flex; justify-content: space-between;'}
`
