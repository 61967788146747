import {LocationIdType} from '../../../../store/state/locations/state'
import {GuidType} from '../../../../values/generic-type-defintions'
import LoadingState from '../../../../values/loading-state-enum'
import {AssetDataType, AssetDataUpdateType} from './asset-data-type'
import {Anomaly} from './formatted-anomaly'
import {IsolatedAssetsType} from './isolated-assets-type'
import {NetworkAssetBehaviour} from './network-asset-behaviour'
import {NetworkAssetBehaviourData} from './network-asset-behaviour-data'

export type DataUnknownAssetsMap = Map<number, Anomaly[]>

export interface PagedUnknownAssetsBetaState {
    loadingDataState: LoadingState
    loadingFilterState: LoadingState
    loadingToggleState: {id: GuidType | undefined; loading: LoadingState}
    dataUnknownAssets: DataUnknownAssetsMap | undefined
    totalNumberOfUnknownAssets: number | undefined
    pageSize: number
    selectedPage: number
    totalNumberOfPages: number | undefined
    activeLocationId: LocationIdType | undefined
    isolatedAssetsStatus: IsolatedAssetsType[] | undefined
    acEnabled: boolean
    showFilterBar: boolean
    networkAssetModalId: GuidType | null
    isProcessingnetworkAssetModal: ProcessingStatus
    findNetworkAsset: Anomaly | undefined
    showEnableMonitoringModal: boolean
    assetData: AssetDataType
    updateAssetData: AssetDataUpdateType
    displayedHostname: string | undefined
    isProcessingSave: ProcessingStatus
    allNetworkBehaviours: NetworkAssetBehaviour[]
    networkAssetBehaviourData: NetworkAssetBehaviourData[]
    isInterlinking: boolean
    isInactive: boolean
    reportUrl: string | null
    showGenerateReportPopup: boolean
}

export enum ProcessingStatus {
    NOT_PROCESSING = 'NOT_PROCESSING',
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
}
