import {useState} from 'react'
import {IncidentOutputModel} from '../../models/incident.model'
import {VesselTagsPopup} from './vessel-tags-popup/vessel-tags-popup'
import {TableDataCell} from './_styled/table-data-cell.styled'

interface VesselDataCellProps {
    index: number
    model: IncidentOutputModel
    onClickFn: () => void
}

export function VesselDataCell({index, model, onClickFn}: VesselDataCellProps): JSX.Element {
    const gridRow = index * 3 + 1
    const [isHovering, setIsHovering] = useState(false)
    const showPopup = model.vesselTags.length > 0 && isHovering

    return (
        <TableDataCell
            gridRow={gridRow}
            gridColumn={7}
            id={`vessel-value_${model.id}`}
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
            popup={showPopup}
            onClick={onClickFn}
        >
            {model.vessel}
            {showPopup && <VesselTagsPopup tags={model.vesselTags} />}
        </TableDataCell>
    )
}
