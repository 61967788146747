import {NmeaInventoryResponse} from '../types/nmea-inventory-response'
import {ActionType} from './action-type'
import * as Actions from './actions'
import {GuidType} from '../../../../values/generic-type-defintions'
import {SortColumnType} from '../types/nmea-inventory-sort-type'

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function sortColumnAction(sortColumn: SortColumnType): Actions.SetSortColumnAction {
    return {type: ActionType.SET_SORT_COLUMN_ACTION, payload: sortColumn}
}

export function receivedRequestedPageData(
    data: NmeaInventoryResponse[],
    totalNumberOfItems: number,
    totalNumberOfPages: number,
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION,
        payload: {
            data,
            totalNumberOfItems,
            totalNumberOfPages,
        },
    }
}

export function setNmeaModalId(nmeaModalId: GuidType): Actions.DisplayNmeaDetailsModalAction {
    return {
        type: ActionType.DISPLAY_NMEA_DETAILS_MODAL,
        payload: nmeaModalId,
    }
}

export function closeNmeaDetailsModal(): Actions.CloseNmeaDetailsModalAction {
    return {
        type: ActionType.CLOSE_NMEA_DETAILS_MODAL,
    }
}

export function setFindNmeaDetails(
    findSoftwareDetails: NmeaInventoryResponse | undefined,
): Actions.ReceivedRequestedNmeaDetails {
    return {
        type: ActionType.RECEIVED_REQUESTED_NMEA_DETAILS,
        payload: findSoftwareDetails,
    }
}
