import {Check} from 'react-feather'
import * as Styled from '../data-input/data-input.styled'
import {DurationSelectDropdown} from './duration-select-dropdown'

interface DurationDropdownProps {
    label: string
    initialValue: string | null
    setChange: (value: number | null) => void
    changed: boolean
    disabled?: boolean
}

export function DurationDropdown({
    label,
    initialValue,
    setChange,
    changed,
    disabled,
}: DurationDropdownProps): JSX.Element {
    return (
        <>
            <Styled.Label id={`${label}-vessel-tagging`}>{label}:</Styled.Label>
            <Styled.Value id={`${label}-vessel-tagging-value`}>
                <DurationSelectDropdown
                    initialValue={initialValue}
                    setChange={setChange}
                    changed={changed}
                    disabled={disabled}
                />
                {changed && (
                    <Check color={'green'} width={19} height={19} style={{paddingLeft: '2px'}} />
                )}
            </Styled.Value>
        </>
    )
}
