import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {
    isInternalUserSelector,
    currentUserSelector,
} from '../../../store/state/current-user/selectors'
import {rolesSelector} from '../../../store/state/roles/selectors'
import {usersSelector} from '../../../store/state/users/selectors'
import {isUserAllowed} from '../../../values/Role'
import {UserListTable} from './user-list-table/user-list-table'
import {UserListCards} from './user-list-card/user-list-cards'
import {showCards} from './user-list-table/_styled/user-management-list-header.styled'
import {useUserManagement} from '../context/use-user-management'
import {User} from '../../../store/state/users/state'

function compareRecord(a: User, b: User): number {
    return a.username.trim().localeCompare(b.username.trim())
}

export function DataPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {activeUserId, externalView} = useUserManagement()
    const users = useTypedSelector(usersSelector)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const currentUserRole = useTypedSelector(rolesSelector)
    const currentUser = useTypedSelector(currentUserSelector)

    const filteredUserData = isInternalUser
        ? users
        : isUserAllowed(currentUserRole, ['CUSTOMER_ADMIN'])
          ? users.filter((user) => !user.internalUser)
          : users.filter((user) => user.user === currentUser.user)
    const filteredExternalUserData = filteredUserData.filter((user) => !user.internalUser)

    return showCards(width, activeUserId !== null) ? (
        <UserListCards
            users={
                externalView
                    ? filteredExternalUserData.sort(compareRecord)
                    : filteredUserData.sort(compareRecord)
            }
        />
    ) : (
        <UserListTable
            users={
                externalView
                    ? filteredExternalUserData.sort(compareRecord)
                    : filteredUserData.sort(compareRecord)
            }
        />
    )
}
