import {Dispatch} from 'react'
import {REST} from '../../../../..'
import {updateVesselConfig} from '../../../../../store/state/locations/action-creators'
import {UpdateVesselConfigAction} from '../../../../../store/state/locations/actions'
import {getLocationData, getMetaData, getReportConfiguration} from '../helper'
import {FormattedVesselConfig} from '../types/formatted-vessel-config'
import {FixedValueState} from '../types/vessel-config-state'
import {ActionType} from './action-type'
import * as Actions from './actions'

const LOCATIONS_URL = '/api/v1/nodes/locations'

export function setChangeType(value: string): Actions.SetChangeTypeAction {
    return {type: ActionType.SET_CHANGE_TYPE, payload: value}
}
export function setChangeCustomerLocationCode(
    value: string,
): Actions.SetChangeCustomerLocationCodeAction {
    return {type: ActionType.SET_CHANGE_CUSTOMER_LOCATION_CODE, payload: value}
}
export function setChangeBusinessNetworks(
    value: string[],
): Actions.SetChangeBusinessNetworksAction {
    return {type: ActionType.SET_CHANGE_BUSINESS_NETWORKS, payload: value}
}
export function setChangeOtNetworks(value: string[]): Actions.SetChangeOtNetworksAction {
    return {type: ActionType.SET_CHANGE_OT_NETWORKS, payload: value}
}
export function setChangeCrewNetworks(value: string[]): Actions.SetChangeCrewNetworksAction {
    return {type: ActionType.SET_CHANGE_CREW_NETWORKS, payload: value}
}
export function setChangeGatewayIp(value: string): Actions.SetChangeGatewayIpAction {
    return {type: ActionType.SET_CHANGE_GATEWAY_IP, payload: value}
}
export function setChangeGatewayMac(value: string): Actions.SetChangeGatewayMacAction {
    return {type: ActionType.SET_CHANGE_GATEWAY_MAC, payload: value}
}
export function setChangeIncusIp(value: string): Actions.SetChangeIncusIpAction {
    return {type: ActionType.SET_CHANGE_INCUS_IP, payload: value}
}
export function setChangeIncusMac(value: string): Actions.SetChangeIncusMacAction {
    return {type: ActionType.SET_CHANGE_INCUS_MAC, payload: value}
}
export function setChangeSensorIp(value: string): Actions.SetChangeSensorIpAction {
    return {type: ActionType.SET_CHANGE_SENSOR_IP, payload: value}
}
export function setChangeSensorMac(value: string): Actions.SetChangeSensorMacAction {
    return {type: ActionType.SET_CHANGE_SENSOR_MAC, payload: value}
}
export function setChangeOtDevices(value: string[]): Actions.SetChangeOtDevicesAction {
    return {type: ActionType.SET_CHANGE_OT_DEVICES, payload: value}
}
export function setChangeOtCrossings(value: string[]): Actions.SetChangeOtCrossingsAction {
    return {type: ActionType.SET_CHANGE_OT_CROSSINGS, payload: value}
}
export function setChangeBusinessDevices(value: string[]): Actions.SetChangeBusinessDevicesAction {
    return {type: ActionType.SET_CHANGE_BUSINESS_DEVICES, payload: value}
}
export function setChangeBusinessCrossings(
    value: string[],
): Actions.SetChangeBusinessCrossingsAction {
    return {type: ActionType.SET_CHANGE_BUSINESS_CROSSINGS, payload: value}
}
export function setChangeEmailHosts(value: string[]): Actions.SetChangeEmailHostsAction {
    return {type: ActionType.SET_CHANGE_EMAIL_HOSTS, payload: value}
}
export function setChangeHostLogExceptions(
    value: string[],
): Actions.SetChangeHostLogExceptionsAction {
    return {type: ActionType.SET_CHANGE_HOST_LOG_EXCEPTIONS, payload: value}
}
export function setChangeNetworkLogExceptions(
    value: string[],
): Actions.SetChangeNetworkLogExceptionsAction {
    return {type: ActionType.SET_CHANGE_NETWORK_LOG_EXCEPTIONS, payload: value}
}
export function setChangeUsbHosts(value: string[]): Actions.SetChangeUsbHostsAction {
    return {type: ActionType.SET_CHANGE_USB_HOSTS, payload: value}
}
export function setChangeUsbDevices(value: string[]): Actions.SetChangeUsbDevicesAction {
    return {type: ActionType.SET_CHANGE_USB_DEVICES, payload: value}
}
export function setChangeAdminLogons(value: string[]): Actions.SetChangeAdminLogonsAction {
    return {type: ActionType.SET_CHANGE_ADMIN_LOGONS, payload: value}
}
export function setChangeRemoteAccess(value: string[]): Actions.SetChangeRemoteAccessAction {
    return {type: ActionType.SET_CHANGE_REMOTE_ACCESS, payload: value}
}
export function setChangeEncryptionRatio(value: string): Actions.SetChangeEncryptionRatioAction {
    return {type: ActionType.SET_CHANGE_ENCRYPTION_RATIO, payload: value}
}
export function setChangeMonitoringCoverage(
    value: string,
): Actions.SetChangeMonitoringCoverageAction {
    return {type: ActionType.SET_CHANGE_MONITORING_COVERAGE, payload: value}
}
export function setChangeMaxOSAge(value: string): Actions.SetChangeMaxOSAgeAction {
    return {type: ActionType.SET_CHANGE_MAX_OS_AGE, payload: value}
}
export function setChangeMaxScore(value: number): Actions.SetChangeMaxScoreAction {
    return {type: ActionType.SET_CHANGE_MAX_SCORE, payload: value}
}
function changesSaved(): Actions.ChangesSavedAction {
    return {
        type: ActionType.CHANGES_SAVED,
    }
}
export function saveConfig(
    dispatch: Dispatch<Actions.AllActions>,
    fixedValue: FixedValueState,
    value: FormattedVesselConfig,
    reduxDispatch: Dispatch<UpdateVesselConfigAction>,
): void {
    const data = getLocationData(fixedValue, value)
    REST.put(`${LOCATIONS_URL}/${fixedValue.location}`, {...data})
        .then(() => {
            dispatch(changesSaved())
            reduxDispatch(
                updateVesselConfig(
                    fixedValue.location,
                    value.type,
                    value.customerLocationCode,
                    getMetaData(value),
                    getReportConfiguration(value),
                ),
            )
            setTimeout(() => dispatch(setSaveMessage(undefined)), 3000)
        })
        .catch(() => {
            dispatch(setSaveMessage('Save failed'))
        })
}
export function setSaveMessage(
    message: 'Save failed' | 'Saved successfully' | undefined,
): Actions.SetSaveResultMessageAction {
    return {type: ActionType.SET_SAVE_RESULT_MESSAGE, payload: message}
}
export function resetChanges(): Actions.ResetChangesAction {
    return {type: ActionType.RESET_CHANGES}
}
export function bulkAssignConfig(data: FormattedVesselConfig): Actions.BulkAssignConfigAction {
    return {
        type: ActionType.BULK_ASSIGN_CONFIG,
        payload: data,
    }
}
