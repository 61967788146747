import {useVesselManagement} from '../../../../contexts/use-vessel-management'
import {DataCell} from '../data-cell/data-cell'
import {DataInput} from '../data-cell/data-input'

export function SourceInput(): JSX.Element {
    const {oTSourceModalDetails} = useVesselManagement()

    const hasData = oTSourceModalDetails.sourceData.length !== 0

    return (
        <DataCell label="Source">
            {hasData ? (
                <>
                    {oTSourceModalDetails.sourceData?.map((entry, index) => {
                        const [inputTypeIP, inputTypePort] = entry.source.split(':')
                        return (
                            <DataInput
                                key={`data-input-${entry.source} - ${entry.destination}`}
                                ipValue={inputTypeIP}
                                portValue={inputTypePort}
                                index={index}
                                label="source"
                            />
                        )
                    })}
                </>
            ) : (
                <DataInput ipValue="" portValue="" label="source" />
            )}
        </DataCell>
    )
}
