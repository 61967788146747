import {Line} from 'recharts'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {EtsTrendScore} from '../../../../../../values/ets-trend-score/EtsTrendScore'

// use like a custom component, but must be a function as recharts will render data returned by a function, not a custom component
export function dataLine(theme: CyberOwlTheme, data: EtsTrendScore[]): JSX.Element {
    return (
        <Line
            isAnimationActive={false}
            type="linear"
            strokeWidth={2}
            connectNulls={true}
            data={data}
            dataKey="score"
            name="Fleet Risk Score"
            stroke={theme.colors.graph.primary}
        />
    )
}
