import styled from 'styled-components'

export const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid;
    border-radius: 5px;
    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`
