import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {DEFAULT_PAGED_VESSELS_BETA_STATE} from '../types/default-paged-vessels-beta-state'
import {PagedVesselsBetaState, PossibleColumnsVesselBeta} from '../types/paged-vessels-beta-state'
import LoadingState from '../../../../values/loading-state-enum'
import {VesselBetaTableDetailsModel} from '../types/vessel-beta-table-details-model'

export const pagedVesselsBetaReducer = produce(
    (draft: PagedVesselsBetaState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break
            case ActionType.TOGGLE_HIGHLIGHTED_FILTER_VALUE:
                draft.highlightedFilerValue = action.payload
                break
            case ActionType.REQUEST_TABLE_PAGE_DATA_ACTION:
                draft.loadingFilteredDataState = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_REQUESTED_TABLE_PAGE_DATA_ACTION:
                draft.loadingFilteredDataState = LoadingState.Loaded
                if (!draft.tableVesselsDataMap) {
                    draft.tableVesselsDataMap = new Map<
                        PossibleColumnsVesselBeta,
                        VesselBetaTableDetailsModel
                    >()
                }
                draft.tableVesselsDataMap = action.payload
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_VESSELS_BETA_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
