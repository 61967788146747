import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'
import {getBackgroundColor} from '../../../../../../theme/helpers/risk-score'
import {NodeValues} from '../../../../../../values/nodes/NodeData'
import {ThreatValues} from '../../../../../../helpers/threatHelper'

export const NoAssetsText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    font-style: italic;
    text-align: center;
`
export const AssetsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
    padding: ${spacing(2)} ${spacing(3)};
`
export const AssetSection = styled.div`
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    padding: ${spacing(2)};
    min-height: 70px;
    display: flex;
    flex-direction: column;
`

export const AssetSquare = styled.span`
    color: ${(props) => props.theme.incidents.incidentAsset.text};
    background-color: ${(props) =>
        getBackgroundColor(props.theme, 20, NodeValues.MEDIUM, ThreatValues.MEDIUM)};
    height: ${spacing(6)};
    width: ${spacing(24)};
    padding: ${spacing(1)};
    margin: ${spacing(1)} 0;
    border-radius: ${spacing(1)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 'none';
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: center;
`
export const AssetWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
interface XTickProps {
    index: number
    lastItem: boolean
    oneTick: boolean
}
export const XTick = styled.div<XTickProps>`
    position: absolute;
    font-size: 12px;
    ${(props) => getTickPosition(props.index, props.oneTick, props.lastItem)};
    z-index: 10;
`
interface TippyWrapperProps {
    isLastElement: boolean
    xCoordinate: number
    yCoordinate: number
}
export const TippyWrapper = styled.div<TippyWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    width: max-content;
    top: ${(props) => props.yCoordinate}px;
    ${(props) =>
        props.isLastElement ? `right: -${props.xCoordinate}px` : `left: ${props.xCoordinate}px`};
`

export const ChartContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`
function getTickPosition(index: number, oneTick: boolean, lastItem: boolean) {
    if (oneTick) {
        return 'left: 150px'
    } else if (index === 0) {
        return 'left: 0'
    } else if (lastItem) {
        return 'right: 0'
    } else {
        return 'left: 20%'
    }
}
export const AssetsText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    text-align: center;
`
export const TimestampText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
`
export const DetailsAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacing(2)} 0;
`

export const DetailsAreaContent = styled.div`
    display: grid;
    grid-template-columns: 70px auto;
    gap: ${spacing(1)};
`
export const DetailedAreaWrapper = styled(DetailsAreaWrapper)`
    border-bottom: none;
`
