import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const DetailsLayout = styled.div`
    padding: ${spacing(2)};
    margin: 0;
    width: 100%;
`

interface ScrollableWrapperProp {
    noData: boolean
}

export const ScrollableWrapper = styled.div<ScrollableWrapperProp>`
    height: ${(props) => (props.noData ? '70px' : 'fit-content')};
    max-height: 300px;
    overflow-y: auto;
    padding-top: 10px;
`

export const Details = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'EMAIL EMAIL-VALUE';
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(2)};
    padding: ${spacing(1)};
`

export const NoSavedFiltersAlerts = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${spacing(4)};
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.text.default};
`
