import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const Label = styled.h2`
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    ${mediumSmallFont()};
`
export const ModalTitle = styled(Label)`
    text-align: flex-start;
    font-weight: ${(props) => props.theme.font.weight.bold};
    padding: ${spacing(1)};
    margin: 0;
`
export const DetailsSection = styled.div`
    position: relative;
    border-top: 1px solid ${(props) => props.theme.incidents.dataRow.separator};
    padding: ${spacing(2)};
`

export const DetailsContent = styled.div`
    overflow-y: auto;
    overflow-x: none;
    flex: 1;
    z-index: 0;
    margin-bottom: ${spacing(2)};
`

export const CloseImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`

export const DetailsLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(2)};
    padding: 0 ${spacing(3)} ${spacing(2)};
    margin: 0;
    width: 100%;
`
