import {useEffect, useState} from 'react'
import {NetworkDataInput} from './data-input'
import {isEqual} from 'lodash'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {ValidationResult, isValidCidr} from '../../../../../reselectors/network-data-validation'
import {formattedValue} from '../../../../../reselectors/helper'

interface VesselNetworksCrewNetworksProps {
    crewNetworks: string[] | undefined
    locationIdentity: GuidType
}
export function VesselNetworksCrewNetworks({
    crewNetworks,
    locationIdentity,
}: VesselNetworksCrewNetworksProps): JSX.Element {
    const [resultValidation, setResultValidation] = useState<ValidationResult | null>(null)
    const {changeCrewNetworks, initialValue} = useVesselManagement()

    useEffect(() => {
        setResultValidation(null)
    }, [locationIdentity])

    useEffect(() => {
        if (
            crewNetworks &&
            (resultValidation === 'valid_subnet' ||
                (crewNetworks[0].length === 0 && resultValidation !== 'invalid_subnet'))
        ) {
            changeCrewNetworks(crewNetworks)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crewNetworks, resultValidation])

    function crewNetworksOnChangeHandler(e: React.ChangeEvent<HTMLTextAreaElement>): void {
        e.preventDefault()
        changeCrewNetworks(e.target.value.split('\n'))
        setResultValidation(isValidCidr(e.target.value.split('\n')))
    }

    const isChanged =
        !isEqual(crewNetworks, initialValue.networkConfiguration?.CrewNetworks) &&
        resultValidation !== 'invalid_subnet'

    return (
        <NetworkDataInput
            label="Crew Network"
            initialValue={formattedValue(crewNetworks)}
            onChangeHandler={crewNetworksOnChangeHandler}
            isValidCidr={resultValidation}
            isChanged={isChanged}
        />
    )
}
