import useTypedSelector from '../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../store/state/email-recipient-management/selectors'
import {StrictMode, User} from '../../../../store/state/users/state'
import {useUserManagement} from '../../context/use-user-management'
import {ViewDetailsButton} from './details/view-details-button'
import * as Styled from './_styled/user-management-table-data.styled'
import {useDispatch} from 'react-redux'
import {logDetailsOpen} from '../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../store/state/audit-log/state'
import {vesselTagsSelector} from '../../../../store/state/vessel-tags/selectors'
import {GuidType} from '../../../../values/generic-type-defintions'
import LOCKED from '../../../../@assets/icons/locked.svg'
import UNLOCKED from '../../../../@assets/icons/unlocked.svg'
import {LockImage} from '../shared/tag-limit/tag-limit.styled'

interface TableRowProps {
    user: User
    index: number
}

export function TableRow({user, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const subscriptionData = useTypedSelector(emailRecipientDataSelector)
    const {activeUserId, showUserDetailModal, closeUserDetailModal} = useUserManagement()
    const selectedUser = activeUserId === user.user
    const dispatch = useDispatch()
    const existingTags = useTypedSelector(vesselTagsSelector)

    function getTagName(tagId: GuidType | undefined): string | undefined {
        return existingTags.find((tags) => tags.identity === tagId)?.name
    }

    const locked = user.assignedTag && user.strictMode === StrictMode.Strict
    const unlocked = user.assignedTag && user.strictMode === StrictMode.DefaultVisibility

    function onClickWrapper() {
        if (selectedUser) {
            closeUserDetailModal()
        } else {
            showUserDetailModal(user.user)
            dispatch(
                logDetailsOpen(
                    AuditLogPageType.USERS,
                    AuditLogAuditType.DETAIL_PANEL_ACCESS,
                    user.user,
                ),
            )
        }
    }

    return (
        <>
            <Styled.TableDataRow
                gridRow={gridRow}
                id={`data-user-management-row_${user.user}`}
                selectedUser={selectedUser}
            />
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={2}
                id={`user-management-name_${user.user}`}
                onClick={onClickWrapper}
            >
                {user.username}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={3}
                id={`user-management-email_${user.user}`}
                onClick={onClickWrapper}
            >
                {user.email}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={4}
                id={`user-management-role_${user.user}`}
                onClick={onClickWrapper}
            >
                {user.roles.toString()}
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={5}
                id={`user-management-tag_${user.user}`}
                onClick={onClickWrapper}
            >
                <Styled.TagWrapper>
                    {locked && <LockImage src={LOCKED} />}
                    {unlocked && <LockImage src={UNLOCKED} />}
                    {getTagName(user.assignedTag) ?? 'N/A'}
                </Styled.TagWrapper>
            </Styled.TableDataCell>
            <Styled.TableDataCell
                gridRow={gridRow}
                gridColumn={6}
                id={`user-management-subscriptions_${user.user}`}
                onClick={onClickWrapper}
            >
                {subscriptionData.find(
                    (data) => data.user === user.user && data.notificationType === 'NEW_INCIDENT',
                )
                    ? 'Incidents'
                    : 'None'}
            </Styled.TableDataCell>
            {activeUserId == null && (
                <Styled.TableDataCell
                    gridRow={gridRow}
                    gridColumn={7}
                    id={`user-management-detail_${user.user}`}
                    onClick={onClickWrapper}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    <ViewDetailsButton />
                </Styled.TableDataCell>
            )}
        </>
    )
}
