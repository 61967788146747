import styled from 'styled-components'
import {spacing} from '../../../theme/spacing'

export const Value = styled.div`
    flex: 1;
    font-weight: bold;
    word-wrap: break-word;
    white-space: pre-wrap;
    height: fit-content;
    width: 45%;
`

export const Label = styled.label`
    flex: 1;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 45%;
`

export const Data = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${spacing(4)};
    align-items: start;
    max-width: 500px;
`

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: ${spacing(5)};
    justify-content: space-between;
`

export const DataValue = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
`

export const CustomFieldList = styled.dl<IsGridDisplayProp>`
    gap: ${spacing(4)} ${spacing(8)};
    justify-content: space-between;
    display: ${(props) => (props.isGridDisplay ? 'grid' : 'block')};
    grid-template-columns: max-content 1fr;
`

export const CustomFieldsDisplay = styled.div`
    margin-block-start: ${spacing(4)};
`

export const CustomFieldLabel = styled.dt``

export const CustomFieldValue = styled.dd`
    font-weight: bold;
`

export const CustomFieldsHeader = styled.h4`
    margin-block-start: ${spacing(4)};
`

interface IsGridDisplayProp {
    isGridDisplay: boolean
}

export const CustomFieldWrapper = styled.div<IsGridDisplayProp>`
    display: ${(props) => (props.isGridDisplay ? 'contents' : 'block')};
    margin-block-end: ${(props) => (props.isGridDisplay ? '0' : spacing(4))};
`
