import * as Styled from './submit-area.styled'
import {MouseEvent} from 'react'
import {closeModal} from '../../../../store/state/extended-data-request/action-creators'
import {useDispatch} from 'react-redux'

export function CloseButton(): JSX.Element {
    const dispatch = useDispatch()

    function closeFn(e: MouseEvent<HTMLButtonElement> | undefined) {
        e && e.preventDefault()
        dispatch(closeModal())
    }

    return (
        <Styled.Button id="close-button" onClick={closeFn}>
            Close
        </Styled.Button>
    )
}
