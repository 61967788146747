import {ReactNode, useReducer} from 'react'
import {userManagementReducer} from './state/reducer'
import {DEFAULT_USER_MANAGEMENT_STATE} from './type/default-user-management-state'
import {UserManagementState} from './type/user-management-state'
import {UserManagementContext} from './user-management-context'

type UserManagementProviderProps = {
    children: ReactNode | ReactNode[]
}

export function UserManagementProvider({children}: UserManagementProviderProps): JSX.Element {
    const initialState: UserManagementState = DEFAULT_USER_MANAGEMENT_STATE

    const [state, dispatch] = useReducer(userManagementReducer, initialState)

    return (
        <UserManagementContext.Provider value={{state, dispatch}}>
            {children}
        </UserManagementContext.Provider>
    )
}
