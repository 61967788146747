/* 
    matrixRecord.anySource 
    false = The machines have never spoken before and
    true = The machines have spoken before

    matrixRecord.anyDestination 
    false = No one speaks to the server on that port 
    true Other clients speak to the server on that port
    
    matrixRecord.anyPort 
    false = The client doesn't talk to anyone else on that port. 
    true = The client speaks to other servers on that port
    */

import NetworkAnomalyMatrixRecord from '../../../../../../values/anomalies/network-anomaly-matrix-record'

const buildCombinedKey = (matrixRecord: NetworkAnomalyMatrixRecord): string =>
    `${matrixRecord.anyPort}_${matrixRecord.anySource}_${matrixRecord.anyDestination}`

export default buildCombinedKey
