import {PagedUSBDevicesState} from '../types/paged-usb-devices-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {DEFAULT_PAGED_USB_DEVICES_STATE} from '../types/default-paged-usb-devices-state'
import {isEqual} from 'lodash'
import {USBDevicesResponse} from '../types/usb-devices-response'

export const pagedIncidentsReducer = produce(
    (draft: PagedUSBDevicesState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.dataUSBDevicesMap = undefined
                draft.totalNumberOfUSBDevices = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.USB_DEVICE_STATE_CHANGE:
                const selectedPage = draft.selectedPage
                const device: USBDevicesResponse[] | undefined =
                    draft.dataUSBDevicesMap?.get(selectedPage)
                if (device) {
                    const foundDevice = device.find(
                        (d) => d.identity === action.payload.usbDevice.identity,
                    )
                    if (foundDevice) {
                        foundDevice.deviceStatus = action.payload.status
                    }
                }
                break
            case ActionType.DISPLAY_USB_DETAILS_MODAL:
                draft.modalIdForUSBDetails = action.payload
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loadingDataState = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.totalNumberOfUSBDevices = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages

                if (!draft.dataUSBDevicesMap) {
                    draft.dataUSBDevicesMap = new Map<number, USBDevicesResponse[]>()
                }
                if (
                    !draft.dataUSBDevicesMap.has(draft.selectedPage) ||
                    !isEqual(
                        draft.dataUSBDevicesMap.get(draft.selectedPage),
                        action.payload.usbDevices,
                    )
                ) {
                    draft.dataUSBDevicesMap.set(draft.selectedPage, action.payload.usbDevices)
                }
                break

            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                draft.modalIdForUSBDetails = null
                break

            case ActionType.SET_ID_USB_DETAILS_MODAL:
                draft.showFilterBar = false
                draft.modalIdForUSBDetails = action.payload
                break

            case ActionType.SET_FILTER:
                draft.vesselFilter = action.payload
                draft.selectedPage = 0
                break

            case ActionType.RECEIVED_REQUESTED_USB_DETAILS:
                if (!isEqual(draft.findUsbDetailForModal, action.payload)) {
                    draft.findUsbDetailForModal = action.payload
                }
                break

            case ActionType.SET_USB_DETAILS_OUTPUT:
                if (!isEqual(draft.usbDetailsOutput, action.payload)) {
                    draft.usbDetailsOutput = action.payload
                }
                break

            case ActionType.DISPLAY_SET_PURPOSE_MODAL:
                draft.showSetPurposeModal = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_USB_DEVICES_STATE
                break
            case ActionType.REPORT_CREATE:
                draft.reportUrl = action.payload
                break
            case ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP:
                draft.showGenerateReportPopup = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
