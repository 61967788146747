import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {TimestampFilterType} from './timestamp-filter'
import {INCIDENTS_ENDPOINT} from './vessels-beta-endpoints'
export interface NumberOfIncidentsResponse {
    locationId: string
    incidentsCount: number
}
export async function buildNumberOfIncidentsRaisedOrOpen(
    locationIds: LocationIdType[],
    criteriaForFiler:
        | 'incidents raised'
        | 'incidents raised prev per'
        | 'incidents current open'
        | 'incidents open',
    analysisPeriod: TimestampFilterType,
): Promise<NumberOfIncidentsResponse[]> {
    try {
        const criteriaForStatus =
            criteriaForFiler === 'incidents open' || criteriaForFiler === 'incidents current open'
                ? ['OPEN']
                : undefined
        const criteriaForTimestamp =
            criteriaForFiler === 'incidents raised' || criteriaForFiler === 'incidents open'
                ? getRelativeTimeRange(analysisPeriod)
                : undefined

        const {fromRelativeFirstSeen, toRelativeFirstSeen} =
            getFormatedPreiousPeriodTimestamp(analysisPeriod)

        const response = await REST.post(`${INCIDENTS_ENDPOINT}/find/summary`, {
            locations: locationIds,
            statuses: criteriaForStatus,
            createdFromRelative:
                criteriaForFiler === 'incidents raised prev per'
                    ? fromRelativeFirstSeen
                    : criteriaForTimestamp,
            createdAtToRelative:
                criteriaForFiler === 'incidents raised prev per' ? toRelativeFirstSeen : undefined,
        })
        const findIncidentsForLocation: {location: string; count: number}[] =
            response?.data as Array<{
                location: string
                count: number
            }>
        return findIncidentsForLocation?.map((oneLocation) => {
            return {
                locationId: oneLocation?.location,
                incidentsCount: oneLocation?.count,
            }
        })
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<NumberOfIncidentsResponse>()
    }
}
function getRelativeTimeRange(analysisPeriod: TimestampFilterType): string {
    switch (analysisPeriod) {
        case '1d':
            return 'now() - 24h'
        case '7d':
            return 'now() - 7d'
        case '30d':
            return 'now() - 30d'
        default:
            return 'now() - 30d'
    }
}
function getFormatedPreiousPeriodTimestamp(types: TimestampFilterType): {
    fromRelativeFirstSeen: string
    toRelativeFirstSeen: string
} {
    switch (types) {
        case '1d':
            return {
                fromRelativeFirstSeen: 'now() - 48h',
                toRelativeFirstSeen: 'now() - 24h',
            }
        case '7d':
            return {
                fromRelativeFirstSeen: 'now() - 14d',
                toRelativeFirstSeen: 'now() - 7d',
            }
        case '30d':
        default:
            return {
                fromRelativeFirstSeen: 'now() - 60d',
                toRelativeFirstSeen: 'now() - 30d',
            }
    }
}
