import CommonEvent from '../../../../../../values/common-event/common-event'
import {TableDataRow} from '../../common/table/table-data-row.styled'
import {TableDataCell} from '../../common/table/table-data-cell.styled'
import {ThreatScore} from '../threat-score/threat-score'
import usedTypeSelector from '../../../../../../hooks/use-typed-selector'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import {AssetOutputModel} from '../asset.model'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {recordsSelector} from '../../../../../../store/state/network-anomaly-matrix/selectors'

interface TableRowProps {
    commonEvent: CommonEvent
    index: number
}

function TableRow({commonEvent, index}: TableRowProps): JSX.Element {
    const gridRow = index + 1
    const threatMeasures = usedTypeSelector(threatMeasuresSelector)
    const node = usedTypeSelector(nodesSelector)?.get(commonEvent.node)
    const networkAnomalyMatrixMap = usedTypeSelector(recordsSelector)

    const model = new AssetOutputModel(commonEvent, threatMeasures, networkAnomalyMatrixMap, node)

    return (
        <>
            <TableDataRow gridRow={gridRow} />
            <TableDataCell
                id={`alerts_${commonEvent.identity}_timestamp`}
                gridRow={gridRow}
                gridColumn={2}
            >
                {model.formattedTimeStamp}
            </TableDataCell>
            <TableDataCell gridRow={gridRow} gridColumn={3} inset>
                <ThreatScore
                    id={`alerts_${commonEvent.identity}_threatscore`}
                    score={model.score}
                    threatLevel={model.threatLevel}
                />
            </TableDataCell>
            <TableDataCell
                id={`alerts_${commonEvent.identity}_event-type`}
                gridRow={gridRow}
                gridColumn={4}
                extrabold
            >
                {model.eventType}
            </TableDataCell>
            <TableDataCell
                id={`alerts_${commonEvent.identity}_engine-type`}
                gridRow={gridRow}
                gridColumn={5}
            >
                {model.engineType}
            </TableDataCell>
            <TableDataCell
                id={`alerts_${commonEvent.identity}_detail`}
                gridRow={gridRow}
                gridColumn={6}
            >
                {model.details}
            </TableDataCell>
        </>
    )
}

export default TableRow
