import * as Styled from './filter-component.styled'
import {PeriodSelect} from './period-select'
import {TagSelect} from './tag-filter/tag-select'

export function TagsFilter(): JSX.Element {
    return (
        <Styled.FilterComponentWrapper>
            <Styled.FilterComponent>
                <TagSelect />
                <PeriodSelect />
            </Styled.FilterComponent>
        </Styled.FilterComponentWrapper>
    )
}
