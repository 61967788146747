import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

function getGridTemplate(): string {
    return "'VESSEL-STATUS VESSEL-STATUS VESSEL-TAG VESSEL-TAG'"
}

function getColumnTemplate(): string {
    return 'repeat(1,1fr);'
}

export const VesselStatusAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    border-bottom: ${(props) => `1px solid ${props.theme.myVessels.filterBar.titleSeparator}`};
`

export const VesselStatusAreaContent = styled.div`
    display: grid;
    grid-template-columns: ${getColumnTemplate()};
    grid-template-areas: ${getGridTemplate()};
    gap: ${spacing(2)};
    padding: ${spacing(2)} 0;
`

interface TagItemsWrapperProps {
    gridArea: string
    width: number
}

export const TagItemsWrapper = styled.div<TagItemsWrapperProps>`
    display: flex;
    flex-direction: ${(props) => (props.width > 550 ? 'row' : 'column')};
    gap: ${spacing(1)};
    padding: ${spacing(2)};
    grid-area: ${(props) => props.gridArea};
`

export const TagItems = styled.div`
    display: flex;
    column-gap: 3px;
    padding: 2px;
    background-color: rgba(31, 136, 229, 0.24);
`

export const TagItem = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    max-width: 114px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
