import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ButtonText} from './button-text.styled'
import {Button} from './button.styled'
import {MouseEvent} from 'react'

interface RefreshButtonProps {
    refreshFunction: (e: MouseEvent<HTMLButtonElement>) => void
}

export function RefreshButton({refreshFunction}: RefreshButtonProps): JSX.Element {
    const {width} = useDimensions()
    const shrinkWidth = width > 320
    return (
        <Button id="refresh-button" onClick={refreshFunction}>
            <Icon glyph="Reset" height={16} width={16} />
            <ButtonText>{shrinkWidth ? 'Refresh Data' : 'Refresh'}</ButtonText>
        </Button>
    )
}
