import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const DetailsLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
`

export const Label = styled.label`
    display: flex;
    justify-content: space-between;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    margin: 0;
`
interface TextLinkProps {
    disabled?: boolean
}

export const TextLink = styled.a<TextLinkProps>`
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    margin-right: 5px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    ${(props) => (props.disabled ? 'pointer-events: none; opacity: 0.3;' : '')};
`
export const Link = styled.a`
    color: ${(props) => props.theme.colors.link.primary};
    margin-right: 5px;
`

export const ContactSOCAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(2)};
`
