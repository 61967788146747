import {MouseEvent} from 'react'
import {Tab} from './tab'
import {ContentPageType} from '../../../../../contexts/metrics-policy/type/content-type'
import * as Styled from './tab-section.styled'
interface TabHeaderProps {
    activeTab: ContentPageType
    toggle: (event: MouseEvent, tab: ContentPageType) => void
}

export function TabHeader({activeTab, toggle}: TabHeaderProps): JSX.Element {
    return (
        <Styled.Container>
            <Tab activeTab={activeTab} index={ContentPageType.PROTECTIVE_CONTROLS} toggle={toggle}>
                Protective Controls
            </Tab>
            <Tab activeTab={activeTab} index={ContentPageType.CONTROLS_MAINTENANCE} toggle={toggle}>
                Controls Maintenance
            </Tab>
            <Tab activeTab={activeTab} index={ContentPageType.USER_BEHAVIOUR} toggle={toggle}>
                User Behaviour
            </Tab>
        </Styled.Container>
    )
}
