export enum ActionType {
    SHOW_VESSEL_DETAIL_MODAL = '[VESSEL MANAGEMENT CONTEXT] SHOW VESSEL DETAIL MODAL',
    CLOSE_VESSEL_DETAIL_MODAL = '[VESSEL MANAGEMENT CONTEXT] CLOSE VESSEL DETAIL MODAL',
    SET_INITIAL_VALUE = '[VESSEL MANAGEMENT CONTEXT] SET INITIAL VALUE',
    CHANGE_BUSINESS_NETWORKS = '[VESSEL MANAGEMENT CONTEXT] CHANGE BUSINESS_NETWORKS',
    CHANGE_VESSEL_EMAIL = '[VESSEL MANAGEMENT CONTEXT] CHANGE VESSEL_EMAIL',
    CHANGE_OT_NETWORKS = '[VESSEL MANAGEMENT CONTEXT] CHANGE OT_NETWORKS',
    CHANGE_CREW_NETWORKS = '[VESSEL MANAGEMENT CONTEXT] CHANGE CREW_NETWORKS',
    CHANGE_DASHBOARD_AGENT_IDS = '[VESSEL MANAGEMENT CONTEXT] CHANGE DASHBOARD AGENT IDS',
    SET_VALID_CHANGE = '[VESSEL MANAGEMENT CONTEXT] SET VALID CHANGE',
    TOGGLE_DETAIL_EXPANDED = '[VESSEL MANAGEMENT CONTEXT] TOGGLE DETAIL EXPANDED',
    RECEIVE_LOCATIONS_CONTROL = '[VESSEL MANAGEMENT CONTEXT] RECEIVE LOCATIONS CONTROL',
    SET_SELECTED_METRICS_POLICY = '[VESSEL MANAGEMENT CONTEXT] SET SELECTED METRICS POLICY',
    SET_SELECTED_INCIDENT_POLICY = '[VESSEL MANAGEMENT CONTEXT] SET SELECTED INCIDENT POLICY',
    SET_SELECTED_ENFORCEMENT_POLICY = '[VESSEL MANAGEMENT CONTEXT] SET SELECTED ENFORCEMENT POLICY',
    OPEN_SET_OT_SOURCES_MODAL = '[VESSEL MANAGEMENT CONTEXT] OPEN SET OT SOURCES MODAL',
    CHANGE_OT_SOURCES_TYPE = '[VESSEL MANAGEMENT CONTEXT] CHANGE OT SOURCES TYPE',
    CHANGE_OT_SOURCES_IP = '[VESSEL MANAGEMENT CONTEXT] CHANGE OT SOURCES IP',
    CHANGE_OT_SOURCES_PORT = '[VESSEL MANAGEMENT CONTEXT] CHANGE OT SOURCES PORT',
    UPDATE_OT_DISPLAY_AFTER_SAVE = '[VESSEL MANAGEMENT CONTEXT] UPDATE OT DISPLAY',
    CLOSE_SET_OT_SOURCES_MODAL = '[VESSEL MANAGEMENT CONTEXT] CLOSE SET OT SOURCES MODAL',
    REQUEST_VESSEL_DEPLOYMENT_STATUS = '[VESSEL DEPLOYMENT STATUS CONTEXT] REQUEST VESSEL DEPLOYMENT STATUS',
    GET_VESSEL_DEPLOYMENT_STATUS_OPERATIONAL = '[VESSEL DEPLOYMENT STATUS CONTEXT] GET VESSEL DEPLOYMENT STATUS OPERATIONAL',
    GET_VESSEL_DEPLOYMENT_STATUS_TRAFFIC = '[VESSEL DEPLOYMENT STATUS CONTEXT] GET VESSEL DEPLOYMENT STATUS TRAFFIC',
    SET_VESSEL_DEPLOYMENT_STATUS = '[VESSEL DEPLOYMENT STATUS CONTEXT] VESSEL DEPLOYMENT STATUS',
    DISPLAY_FILTER_BAR = '[VESSEL DEPLOYMENT STATUS CONTEXT] DISPLAY FILTER BAR',
    SET_FILTER = '[VESSEL MANAGEMENT CONTEXT] SET FILTER',
    POLICY_LICENSE_SUMMERY = '[VESSEL MANAGEMENT CONTEXT] RECEIVE POLICY LICENSE SUMMERY',
}
