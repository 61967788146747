import useTypedSelector from '../../../../../hooks/use-typed-selector'
import * as Styled from './analysis-selection.styled'
import {usePagedIncidentsReport} from '../../../contexts/use-paged-incidents-report'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {incidentsReportFilterSelector} from '../../../../../store/state/incidents-report-filter/selectors'
import {setDateFilter} from '../../../../../store/state/incidents-report-filter/action-creators'
import {useDispatch} from 'react-redux'

export function PeriodSelectionFilter(): JSX.Element {
    const {fromDate, toDate} = useTypedSelector(incidentsReportFilterSelector)
    const {highlightedFilerValue} = usePagedIncidentsReport()
    const dispatch = useDispatch()
    const startDate = fromDate != null ? new Date(fromDate) : null

    const endDate = toDate != null ? new Date(toDate) : null

    const handleChange = (dates: [Date | null, Date | null]) => {
        const formatDateToISOString = (date: Date | null) => {
            return date ? date.toISOString() : null
        }
        const fromDateStr = formatDateToISOString(dates[0])
        const toDateStr = formatDateToISOString(dates[1])
        dispatch(setDateFilter(fromDateStr, toDateStr))
    }

    return (
        <Styled.Wrapper
            highlightedFilerValue={highlightedFilerValue === 'Period'}
            id={`${PageType.INCIDENTS_REPORT}_filter-bar_period-type-filter`}
        >
            <Styled.Label>Report Period:</Styled.Label>
            <Styled.Input>
                <Styled.DateEntry
                    id="dateRange"
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleChange}
                    selectsRange
                    dateFormat="MMM d, yyyy"
                    isClearable
                    placeholderText="Start date - End date"
                />
            </Styled.Input>
        </Styled.Wrapper>
    )
}
