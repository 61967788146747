import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'

export const CardArea = styled.div`
    flex: 1;
    padding-top: ${spacing(2)};
    background-color: ${(props) => props.theme.vessels.dataArea.background};
    overflow-x: hidden;
    overflow-y: auto;
`
