import {useDispatch} from 'react-redux'
import {Check} from 'react-feather'
import {NotificationScheduleType} from '../../../../store/state/saved-filters/state'
import * as Styled from './notify-me-popup.styled'
import {setChangeAlertSchedule} from '../../../../store/state/saved-filters/action-creators'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'
import {isEqual} from 'lodash'

interface SetScheduleProps {
    schedule: NotificationScheduleType | undefined
}

export function SetSchedule({schedule}: SetScheduleProps): JSX.Element {
    const dispatch = useDispatch()
    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        dispatch(setChangeAlertSchedule(e.target.value as NotificationScheduleType))
    }
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const isChanged = newSubscription.schedule && !isEqual(newSubscription.schedule, schedule)

    return (
        <Styled.InputWrapper>
            <Styled.Schedule>
                <Styled.Select
                    onChange={onChangeWrapper}
                    defaultValue={schedule}
                    id={`select-${schedule}`}
                >
                    {!schedule && (
                        <option value={undefined} selected disabled id="default-value">
                            - Schedule -
                        </option>
                    )}
                    <option value={NotificationScheduleType.DAILY} id="daily-value">
                        Daily
                    </option>
                    <option value={NotificationScheduleType.WEEKLY} id="weekly-value">
                        Weekly
                    </option>
                    <option value={NotificationScheduleType.MONTHLY} id="monthly-value">
                        Monthly
                    </option>
                    <option value={NotificationScheduleType.NEVER} id="never-value">
                        Never
                    </option>
                </Styled.Select>
            </Styled.Schedule>
            {isChanged && (
                <Check
                    color="green"
                    width={19}
                    height={19}
                    style={{position: 'absolute', right: '75px', paddingLeft: '2px'}}
                />
            )}
        </Styled.InputWrapper>
    )
}
