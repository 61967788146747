import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

export const TitleRow = styled.div`
    display: flex;
    justify-content: start;
    column-gap: ${spacing(20)};
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(4)};
`
