import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {smallFont} from '../../../../../../theme/font-styles'

export const InputWrapper = styled.div`
    display: flex;
    margin-left: ${spacing(1)};
    width: 100%;
`

export const Input = styled.input`
    flex: 1;
    height: 19px;
    ${smallFont()}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
`

export const Select = styled.select`
    flex: 1;
    text-align-last: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    background: #f7f8f8;
    height: 19px;
    &:focus {
        outline: 0;
    }
`
