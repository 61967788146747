import {useState, useRef} from 'react'
import {ChevronUp, ChevronDown} from 'react-feather'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import * as Styled from '../_styled/dropdown.styled'
import {setDataRange} from '../../../../store/state/extended-data-request/action-creators'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {requestedDataSelector} from '../../../../store/state/extended-data-request/selectors'

export function DataRangeDropdown(): JSX.Element {
    const dispatch = useDispatch()
    const data = useTypedSelector(requestedDataSelector)
    const [optionsVisible, setOptionsVisible] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    const allDataRangeOption: string[] = ['Last 24 hours', 'Last 7 days', 'Last 30 days']

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.FilterSearchWrapper
                onClick={() => setOptionsVisible(!optionsVisible)}
                id="data-range-dropdpwn"
            >
                <Styled.SearchInput id="filter-search">
                    {data.dateRange ?? 'Last 24 hours'}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {optionsVisible ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.FilterSearchWrapper>
            <Styled.SavedFiltersWrapper ref={clickOutside} id="data-range-SavedFiltersWrapper">
                {optionsVisible && (
                    <Styled.PeriodDropdown>
                        {allDataRangeOption.map((value, index) => (
                            <Styled.ValueOption
                                key={index}
                                onClick={() => {
                                    dispatch(setDataRange(value))
                                    setOptionsVisible(false)
                                }}
                                id={`set-data-range_${value}`}
                            >
                                {value}
                            </Styled.ValueOption>
                        ))}
                    </Styled.PeriodDropdown>
                )}
            </Styled.SavedFiltersWrapper>
        </Styled.Container>
    )
}
