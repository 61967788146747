import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT_CARD_REDUCED_TEXT = 500

function getValueWidth(width: number): string {
    if (width > 1100) {
        return '380px'
    }
    if (width > 1000) {
        return '350px'
    }
    if (width > 900) {
        return '320px'
    }
    if (width > 800) {
        return '270px'
    }
    return '380px'
}

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
`

export const Label = styled.label`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    padding-right: ${spacing(1)};
    width: 90px;
    margin: 0;
`

interface ChildrenProps {
    gridArea: string
    width: number
}

export const Children = styled.div<ChildrenProps>`
    width: ${(props) => getValueWidth(props.width)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
