import {useRef, useState} from 'react'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {vesselTagsSelector} from '../../../../../../store/state/vessel-tags/selectors'
import {useFleetScoreOverTime} from '../../contexts/use-fleet-score-over-time'
import * as Styled from './tag-search.styled'
import {ChevronDown, ChevronUp} from 'react-feather'
import {findRecommendationFromEntryTag} from '../../../tags-component/components/helper'
import {useOnClickOutside} from '../../../../../../hooks/useOnClickOutside'
import {VesselTag} from './vessel-tag'

export function TagSearch(): JSX.Element {
    const [searchTag, setSearchTag] = useState<string>('')
    const {searchedVesselTagTerm, setSearchVesselTagTerm} = useFleetScoreOverTime()
    const [recommendationOpen, setRecommendationOpen] = useState(false)
    const [inputOpen, setInputOpen] = useState(false)
    const existingTags = useTypedSelector(vesselTagsSelector)

    function onChangeFn(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        setRecommendationOpen(true)
        setSearchTag(e.currentTarget.innerText)
    }

    function onDeleteClick() {
        setSearchVesselTagTerm(undefined)
        setSearchTag('')
    }

    const filteredUniqueResult =
        searchTag.length === 0
            ? existingTags
            : findRecommendationFromEntryTag(existingTags, searchTag)

    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        setRecommendationOpen(false)
        setInputOpen(false)
    })

    return (
        <Styled.Container
            ref={clickOutside}
            onClick={() => {
                if (!inputOpen) {
                    setRecommendationOpen(true)
                    setInputOpen(true)
                }
            }}
            id="tag-search-wrapper-fleet-widget"
        >
            <Styled.TagFilterWrapper inputOpen={inputOpen}>
                <Styled.Wrapper contentEditable={false}>
                    {searchedVesselTagTerm ? (
                        <VesselTag
                            tagName={searchedVesselTagTerm.name}
                            onDeleteFn={onDeleteClick}
                        />
                    ) : (
                        <Styled.SearchInput
                            contentEditable
                            id="tags-search-input-fleet-widget"
                            placeholder="Select tag or search..."
                            onInput={onChangeFn}
                            onFocus={() => setRecommendationOpen(true)}
                        />
                    )}
                </Styled.Wrapper>
                <Styled.SearchIcon>
                    {inputOpen ? (
                        <ChevronUp
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(false)
                                setInputOpen(false)
                            }}
                            id="chevrop-up-fleet-widget"
                        />
                    ) : (
                        <ChevronDown
                            height={13}
                            width={13}
                            onClick={() => {
                                setRecommendationOpen(true)
                                setInputOpen(true)
                            }}
                            id="chevrop-down-fleet-widget"
                        />
                    )}
                </Styled.SearchIcon>
            </Styled.TagFilterWrapper>
            <Styled.TagResultsWrapper ref={clickOutside}>
                {recommendationOpen &&
                    (filteredUniqueResult?.length !== 0 ? (
                        <Styled.TagResultsDropdown id="TagResultsDropdown-fleet-widget">
                            {filteredUniqueResult?.map((tag) => (
                                <VesselTag
                                    key={tag.identity}
                                    tagName={tag.name}
                                    onClickFn={() => {
                                        setSearchVesselTagTerm(tag)
                                        setRecommendationOpen(false)
                                        setInputOpen(false)
                                    }}
                                />
                            ))}
                        </Styled.TagResultsDropdown>
                    ) : (
                        <Styled.TagResultsDropdown
                            style={{textAlign: 'center'}}
                            id="TagResultsDropdown-fleet-widget"
                        >
                            - No Tags Found -
                        </Styled.TagResultsDropdown>
                    ))}
            </Styled.TagResultsWrapper>
        </Styled.Container>
    )
}
