enum ActionType {
    OT_SYSTEMS_REQUEST = '[OT SYSTEMS] REQUEST',
    SET_OT_SYSTEMS = '[OT SYSTEMS] SET',
    OT_SYSTEMS_FAILURE = '[OT SYSTEMS] FAILURE',
    TOGGLE_DETAILS = '[OT SYSTEMS] TOGGLE DETAILS',
    SET_SHOW_ASSIGN_GENERATE_REPORT_POPUP = '[OT SYSTEMS] SET SHOW REPORT GROUP',
    REPORT_CREATE = '[OT SYSTEMS] REPORT CREATE',
    REPORT_CREATE_ERROR = '[OT SYSTEMS] REPORT CREATE ERROR',
    RESET_ERROR_STATE = '[OT SYSTEMS] RESET ERROR STATE',
    UPDATE_OT_SYSTEM = '[OT SYSTEMS] UPDATE',
}

export default ActionType
