import {DataLoading} from '../../../../components/data-loading/data-loading'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedNmeaInventory} from '../../contexts/use-paged-nmea-inventory'
import {NoData} from './no-data/no-data'
import {NMEAInventoryTablePopulated} from './table-content/nmea-table-populated'

export function NMEAInventoryTableContent(): JSX.Element {
    const {loadingDataState, totalNumberOfNmeaInventory} = usePagedNmeaInventory()
    const noData = totalNumberOfNmeaInventory === 0
    const showLoading =
        totalNumberOfNmeaInventory == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData

    if (showLoading) {
        return <DataLoading />
    }
    if (noData) {
        return <NoData text="Ask SOC for help configuring NMEA collection" />
    }
    return <NMEAInventoryTablePopulated />
}
