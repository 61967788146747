import styled from 'styled-components'
import {spacing} from '../../theme/spacing'

export const Label = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
`

export const Text = styled.span`
    margin-left: ${spacing(3)};
    vertical-align: middle;
`

export const StyledRadioButton = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: white;
    border: 1px solid #ccc;
`

export const RadioButtonInput = styled.input`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    width: 19px;
    height: 19px;
    &:hover ~ ${StyledRadioButton} {
        border-color: #1f88e5;
    }

    &:checked + ${StyledRadioButton} {
        background: #1f88e5;
        border: 1px solid #1f88e5;
    }
`
