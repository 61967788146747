import createCachedSelector from 're-reselect'
import threatHelper, {ThreatValue} from '../../../../helpers/threatHelper'
import {nodesSelector} from '../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../store/state/threat-measures/selectors'
import AppState from '../../../../store/types/app-state'
import {NodeValue} from '../../../../values/nodes/NodeData'
import {getScoreForNodes} from '../../../../values/nodes/reselectors/helpers'

export const assetThreatValueCountCachedReselector = createCachedSelector(
    [
        nodesSelector,
        sfmScoresSelector,
        threatMeasuresSelector,
        (_state: AppState, props: {nodeValue: NodeValue; threatValue: ThreatValue}) => props,
    ],
    (nodes, sfmScores, threatMeasures, props) => {
        let count = 0
        nodes.forEach((node) => {
            if (node.value === props.nodeValue) {
                const score = getScoreForNodes(node, sfmScores)
                const threatMeasure = threatHelper(score, threatMeasures, node.value)
                if (threatMeasure === props.threatValue) {
                    count++
                }
            }
        })
        return count
    },
)((_state, props) => `${props.nodeValue}-${props.threatValue}`)
