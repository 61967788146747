import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'

interface TableDataRowProps {
    gridRow: number
    span?: number
    selectedPolicy?: boolean
    selectedUser?: boolean
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.userManagement.dataRow.text};
    background-color: ${(props) => props.theme.userManagement.dataRow.background};
    min-height: 52px;
    box-shadow: 1px 1px 6px -2px ${(props) => props.theme.userManagement.dataRow.dropShadow};
    ${(props) =>
        props.selectedPolicy
            ? `border: 3px solid ${props.theme.userManagement.dataRow.selectedRow}`
            : ''};
    cursor: pointer;
`
interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: ${(props) => (props.inset ? spacing(2) : 0)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold || false)};
    cursor: pointer;
`

export const TagWrapper = styled.div`
    display: flex;
    column-gap: 3px;
`

interface NumberOfVesselsProps {
    isActive?: boolean
}

export const NumberOfVessels = styled.span<NumberOfVesselsProps>`
    cursor: default;
    ${(props) =>
        props.isActive &&
        `color: ${props.theme.colors.link.primary}; font-weight: ${props.theme.font.weight.semibold}; cursor: pointer;
        &:hover {
        background-color: rgba(31, 136, 229, 0.1);
    }`}
`
