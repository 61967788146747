import {useMemo} from 'react'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {
    allMainMetricsTypeSelector,
    totalNumberOfFrameworkFiltersSelector,
} from '../../../../../store/state/metric-types/selectors'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {
    findMetricCategory,
    getAnalysisTypeDisplayName,
    getAnalysisPeriodDisplayName,
} from '../../data-helper'
import {Period} from './searched-period.styled'
import locationSelectionNumberReselector from '../../../contexts/reselector/location-selection-number-reselector'
import {ClickableHeaderFilter} from './clickable-header-filter'

export function SearchedPeriod(): JSX.Element {
    const {selectedAnalysisPeriod, metricTypes, analysisTypes} =
        useTypedSelector(metricsBetaFilterSelector)

    const totalNumberOfFrameworkFilters = useTypedSelector(totalNumberOfFrameworkFiltersSelector)
    const allMetricsType = useTypedSelector(allMainMetricsTypeSelector)

    const displayPeriod = useMemo(() => {
        return getAnalysisPeriodDisplayName(selectedAnalysisPeriod)
    }, [selectedAnalysisPeriod])

    const mainMetricsSelectionNumber = useMemo(() => {
        if (!metricTypes) {
            return totalNumberOfFrameworkFilters
        }
        return [...new Set(metricTypes.map((metric) => findMetricCategory(metric)))].length ?? 0
    }, [metricTypes, totalNumberOfFrameworkFilters])

    const subMetricsSelectionNumber = useMemo(() => {
        if (!metricTypes) {
            return allMetricsType.length ?? 0
        }
        return metricTypes?.length ?? 0
    }, [metricTypes, allMetricsType])

    const locationSelectionNumber = useTypedSelector(locationSelectionNumberReselector)

    return (
        <Period id="clickable-header-filter-wrapper">
            <ClickableHeaderFilter name="Framework" value={mainMetricsSelectionNumber} />
            {'>'}
            <ClickableHeaderFilter name="Metrics selection" value={subMetricsSelectionNumber} />
            {'>'}
            <ClickableHeaderFilter name="Analysis period" value={displayPeriod} />
            {'>'}
            <ClickableHeaderFilter
                name="Analysis type"
                value={getAnalysisTypeDisplayName(analysisTypes)}
            />
            {'>'}
            <ClickableHeaderFilter name="Vessels" value={locationSelectionNumber} />
        </Period>
    )
}
