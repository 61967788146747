import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {ContentArea} from '../../general/content-area/content-area.styled'
import {LinkedTitle, Title} from '../../general/content-area/title.styled'
import {HeadlineValues} from './headline-values'
import {WidgetWrapper} from './widget-wrapper'
import {TopContentSection} from '../../general/content-area/top-content-section.styled'
import {BottomContentSection} from '../../general/content-area/bottom-content-section'
import {LatestUnknownAsset} from './Latest-unknown-asset'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {latestUnknownAssetDataReselector} from '../reselect/unknown-asset-data.reselector'
import {Link} from 'react-router-dom'
import {logInterlinksClick} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType, AuditLogAuditType} from '../../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'

export function PopulatedData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const model = useTypedSelector(latestUnknownAssetDataReselector)
    const dispatch = useDispatch()

    return (
        <WidgetWrapper gridLayout={gridLayout}>
            <ContentArea gridLayout={gridLayout}>
                <TopContentSection>
                    <Link
                        to={{
                            pathname: `/network-assets`,
                            search: `?networkAssetDetails=${model?.identity}`,
                            state: model?.identity,
                        }}
                        onClick={() =>
                            model?.identity &&
                            dispatch(
                                logInterlinksClick(
                                    AuditLogPageType.NETWORK_INVENTORY,
                                    AuditLogAuditType.INTERLINK_CLICK,
                                    model.identity,
                                ),
                            )
                        }
                        id="link-to-network-assets"
                    >
                        <LinkedTitle gridLayout={gridLayout}>Latest Network Asset</LinkedTitle>
                    </Link>
                    <LatestUnknownAsset model={model} gridLayout={gridLayout} />
                </TopContentSection>
                <BottomContentSection id="asset-discovery-bottom-section-title">
                    <Title gridLayout={gridLayout}>Recently Discovered Assets</Title>
                    <HeadlineValues model={model} gridLayout={gridLayout} />
                </BottomContentSection>
            </ContentArea>
        </WidgetWrapper>
    )
}
