import styled from 'styled-components'

interface PolicyManagementDetailsProps {
    activePolicy: boolean
}

export const PolicyManagementDetails = styled.div<PolicyManagementDetailsProps>`
    color: ${(props) => props.theme.userManagement.dataRow.text};
    ${(props) =>
        props.activePolicy
            ? `border: 3px solid ${props.theme.userManagement.dataRow.selectedRow}`
            : ''};
`
