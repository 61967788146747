import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const AssetBehaviourAreaWrapper = styled.div`
    padding: ${spacing(2)};
    padding-left: ${spacing(3)};
`

export const DescriptionText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const SinceLastResetText = styled.div`
    padding: ${spacing(2)};
    font-weight: ${(props) => props.theme.font.weight.normal};
    font-style: italic;
`

export const BehaviourList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(1)};
    padding: ${spacing(1)} 0 ${spacing(1)};
`
