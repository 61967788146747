import {shallowEqual} from 'react-redux'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import overviewReselector from '../tabs/overview-reselector'
import {Overview} from './_styled/overview.styled'
import {FilterStatus} from './filter-status'
import {AssetInfo} from './asset-info'
import {EditAssetButton} from '../edit-asset-modal/components/edit-asset-button/edit-asset-button'
import isUserAllowedToEditAssetReselector from '../../../../components/asset-details/re-reselectors/is-user-allowed-to-edit-asset-reselector'

function AssetOverview(): JSX.Element {
    const overview = useTypedSelector(overviewReselector, shallowEqual)
    const isAllowedToEdit = useTypedSelector(isUserAllowedToEditAssetReselector)

    return (
        <Overview>
            {!overview.loading && <AssetInfo overview={overview} />}
            <div>
                <FilterStatus />
                {isAllowedToEdit && <EditAssetButton assetIdCard={overview.assetId} />}
            </div>
        </Overview>
    )
}
export default AssetOverview
