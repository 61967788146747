import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import {USBDeviceCard} from './usb-device-card'
import {CardArea} from './_styled/usb-devices-cards.styled'
import {addQueryParam} from '../../../../helpers/navigation'
import {USB_DEVICES_DETAILS} from '../data-helpers'

export function USBDevicesCardsPopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataUSBDevices} = usePagedUSBDevices()

    return (
        <CardArea width={width} id="card-data-grid-style">
            {dataUSBDevices?.map((usbDevice) => (
                <USBDeviceCard
                    key={usbDevice.identity}
                    usbDevice={usbDevice}
                    onClickFn={() => {
                        addQueryParam(USB_DEVICES_DETAILS, usbDevice.identity)
                    }}
                />
            ))}
        </CardArea>
    )
}
