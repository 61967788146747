import {ThunkAction} from 'redux-thunk'
import {LocationIdType} from '../locations/state'
import ActionType from './action-type'
import * as Actions from './actions'
import {DEFAULT_MY_VESSELS_FILTERS_STATE} from './types/default-my-vessel-filter-state'
import {
    SORT_DIRECTION_TYPE,
    VesselFiltersState,
    ZOOM_LEVEL,
    ZOOM_LEVEL_TYPE,
} from './types/my-vessel-filter-state'
import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {MyVesselsFilter} from '../../../values/user-preferences/my-vessels-filter'
import {isEqual} from 'lodash'
import {StringUtils} from '../../../utils/Utils'
import {VesselFilterReduxState} from '../vessel-filter/types/vessel-filter-state'
import {DEFAULT_VESSEL_FILTER_STATE} from '../vessel-filter/types/default-vessel-filter-state'

const USER_PREFS_URL = '/api/v1/userPreferences/my-vessels-filter'

function buildLocations(
    allLocations: LocationIdType[],
    currentSelectedLocations: Set<LocationIdType> | undefined,
    location: LocationIdType | LocationIdType[],
    newValue: boolean,
): string | LocationIdType[] {
    const newLocations = new Set<LocationIdType>(
        currentSelectedLocations != undefined ? currentSelectedLocations : allLocations,
    )
    if (StringUtils.validString(location)) {
        if (newValue) {
            newLocations.add(location)
        } else {
            newLocations.delete(location)
        }
    } else if (Array.isArray(location)) {
        if (newValue) {
            location.forEach((e) => newLocations.add(e))
        } else {
            location.forEach((e) => newLocations.delete(e))
        }
    }

    if (newLocations.size === 0) {
        return 'all-deselected'
    }

    if (newLocations.size === allLocations?.length) {
        return 'all-selected'
    }

    return Array.from(newLocations)
}

function populateLocations(
    locations: string | Array<LocationIdType>,
): Set<LocationIdType> | undefined {
    try {
        if (!locations || isEqual(locations, {}) || locations === 'all-selected') {
            return undefined
        }

        if (locations === 'all-deselected') {
            return new Set<LocationIdType>()
        }

        return new Set<LocationIdType>(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

function populateLocationsForFilterCriteria(
    locations: Set<LocationIdType> | undefined,
): Array<LocationIdType> | undefined {
    try {
        if (!locations) {
            return undefined
        }
        return Array.from(locations)
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error({err, locations})
        return undefined
    }
}

export const fetchMyVesselsFilter = (
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            dispatch(
                                receiveFilter({
                                    ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                                    ...response.data.value,
                                    locations: populateLocations(response.data.value?.locations),
                                    searchVesselTagTerm: res.data.value.default_tag,
                                }),
                            )
                            REST.put(USER_PREFS_URL, {
                                ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                                ...response.data.value,
                                locations: populateLocations(response.data.value?.locations),
                                searchVesselTagTerm: res.data.value.default_tag,
                            })
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                receiveFilter({
                                    ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                                    ...response.data.value,
                                    locations: populateLocations(response.data.value?.locations),
                                }),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            receiveFilter({
                                ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                                searchVesselTagTerm: res.data.value.default_tag,
                            }),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    dispatch(
                        receiveFilter({
                            ...DEFAULT_MY_VESSELS_FILTERS_STATE,
                            ...response.data.value,
                            locations: populateLocations(response.data.value?.locations),
                        }),
                    )
                },
                () => {
                    dispatch(receiveFilter(DEFAULT_MY_VESSELS_FILTERS_STATE))
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

function receiveFilter(payload: VesselFiltersState): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload,
    }
}

export const toggleAllAssetsFilter = (
    payload: boolean,
): ThunkAction<void, AppState, Api, Actions.SetBooleanValue> => {
    return (dispatch, getState) => {
        const currentFilter = getState().myVesselsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_ALL_ASSETS,
            payload,
        })

        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs({...currentFilter, ...toggleAllAssets(payload)}, currentVesselFilter),
        )
    }
}

export const toggleValueThreshold = (
    value: Actions.Value,
    bool: boolean,
    threshold: 'high' | 'medium' | 'low',
): ThunkAction<void, AppState, Api, Actions.ToggleValueThresholdAction> => {
    return (dispatch, getState) => {
        const currentFilter = getState().myVesselsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_VALUE_THRESHOLD,
            payload: {
                value,
                bool,
                threshold,
            },
        })

        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(
                {
                    ...currentFilter,
                    ...{
                        [value]: {
                            ...currentFilter[value],
                            [threshold]: bool,
                        },
                    },
                },
                currentVesselFilter,
            ),
        )
    }
}

export const onAssetSort = (
    field: string,
    direction: SORT_DIRECTION_TYPE,
): ThunkAction<void, AppState, Api, Actions.SetSortField> => {
    return (dispatch, getState) => {
        const currentFilter = getState().myVesselsFilter
        const currentVesselFilter = getState().vesselFilter
        const assetSort = {
            field,
            direction,
        }
        dispatch({
            type: ActionType.ASSET_SORT,
            payload: assetSort,
        })
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs({...currentFilter, assetSort}, currentVesselFilter),
        )
    }
}

export const deepLinkVessel = (
    allLocations: Array<LocationIdType>,
    location: LocationIdType,
): ThunkAction<void, AppState, Api, Actions.DeepLinkVessel> => {
    return (dispatch, getState) => {
        const currentFilter = getState().myVesselsFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.DEEP_LINK_VESSEL,
            payload: location,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs(currentFilter, currentVesselFilter),
            zoomLevel: ZOOM_LEVEL.FOUR,
            locations: buildLocations(allLocations, new Set<LocationIdType>(), location, true),
        })
    }
}

export const setSearchAssetTerm = (searchTerm: string): Actions.SetSearchAssetTerm => {
    return {
        type: ActionType.SET_SEARCH_ASSET_TERM,
        payload: searchTerm,
    }
}

export const setZoomLevel = (
    zoomLevel: ZOOM_LEVEL_TYPE,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().myVesselsFilter
        const currentVesselFilter = getState().vesselFilter

        dispatch({
            type: ActionType.CHANGE_ZOOM_LEVEL,
            payload: zoomLevel,
        })
        REST.put(USER_PREFS_URL, {
            ...buildFilterPrefs({...currentFilter, zoomLevel}, currentVesselFilter),
        })
    }
}

export const resetFilter = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch({
            type: ActionType.RESET_FILTER,
        })
        REST.put(
            USER_PREFS_URL,
            buildFilterPrefs(DEFAULT_MY_VESSELS_FILTERS_STATE, DEFAULT_VESSEL_FILTER_STATE),
        )
    }
}

function buildFilterPrefs(
    state: VesselFiltersState,
    vesselFilterState: VesselFilterReduxState,
): MyVesselsFilter {
    return {
        zoomLevel: state.zoomLevel,
        vesselSort: vesselFilterState.vesselSort,
        assetSort: state.assetSort,
        HIGH: state.HIGH,
        MEDIUM: state.MEDIUM,
        LOW: state.LOW,
        locations: populateLocationsForFilterCriteria(vesselFilterState.locations),
        searchVesselTagTerm: vesselFilterState.searchVesselTagTerm,
        searchVesselNameTerm: vesselFilterState.searchVesselNameTerm,
    }
}

function toggleAllAssets(selected: boolean) {
    return {
        HIGH: {
            low: selected,
            medium: selected,
            high: selected,
        },
        MEDIUM: {
            low: selected,
            medium: selected,
            high: selected,
        },
        LOW: {
            low: selected,
            medium: selected,
            high: selected,
        },
    }
}
