import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import MiscDocument from '../../../../../@assets/images/icon-misc-document.png'
import {DownloadFileType} from '../../../../../store/state/audit-log/state'
interface DownloadIconProps {
    contentType: DownloadFileType | null
}

export function DownloadIcon({contentType}: DownloadIconProps): JSX.Element | null {
    switch (contentType) {
        case DownloadFileType.EXCEL:
            return <Icon glyph="Excel" height={19} width={19} />
        case DownloadFileType.CSV:
            return <Icon glyph="Excel" height={19} width={19} />
        case DownloadFileType.PDF:
            return <Icon glyph="Pdf" height={19} width={19} />
        case DownloadFileType.MISC:
            return <img src={MiscDocument} height={19} width={17} />
        default:
            return null
    }
}
