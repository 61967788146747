import LoadingState from '../../../../values/loading-state-enum'
import {PagedNmeaInventoryState} from './paged-nmea-inventory-state'
import {NmeaInventoryResponse} from './nmea-inventory-response'
import {NmeaInventorySortType} from './nmea-inventory-sort-type'

export const DEFAULT_PAGED_NMEA_INVENTORY_STATE: PagedNmeaInventoryState = {
    loadingDataState: LoadingState.NotPopulated,
    loadingFilterState: LoadingState.NotPopulated,
    dataNmeaInventoryMap: new Map<number, NmeaInventoryResponse[]>(),
    totalNumberOfNmeaInventory: undefined,
    totalNumberOfPages: undefined,
    sortColumn: {
        orderBy: NmeaInventorySortType.FIRST_SEEN_TIMESTAMP,
        orderAscending: false,
    },
    selectedPage: 0,
    pageSize: 10,
    modalIdForNmeaDetails: null,
} as PagedNmeaInventoryState
