import styled from 'styled-components'
import {GridLayout} from '../../../general/grid-layout.enum'

function getMarginTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_LARGE:
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.ONE_COLUMN_SMALL:
            return '5px'
        default:
            return '0'
    }
}

interface LegendItemsProps {
    gridLayout: GridLayout
}

export const LegendItems = styled.div<LegendItemsProps>`
    display: flex;
    align-items: center;
    margin-top: ${(props) => getMarginTop(props.gridLayout)};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
