import {ActionType} from './action-type'
import * as Actions from './actions'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {
    EnforcementPolicy,
    policyForMasterOverrideEnum,
    policyForNewDevicesEnum,
} from '../type/enforcement-policy'

export function requestEnforcementPolicyData(): Actions.RequestPolicyDataAction {
    return {type: ActionType.REQUEST_POLICY_DATA_ACTION}
}

export function receivedRequestedEnforcementPolicyData(
    enforcementPolicyIdentity: GuidType,
    receivedPolicy: EnforcementPolicy,
): Actions.ReceivedRequestedPolicyDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION,
        payload: {enforcementPolicyIdentity, receivedPolicy},
    }
}

export function receivedAllEnforcementPolicyData(
    receivedPolicies: EnforcementPolicy[],
): Actions.AllPolicyDataAction {
    return {
        type: ActionType.RECEIVED_ALL_POLICY_DATA_ACTION,
        payload: receivedPolicies,
    }
}

export function setChangePolicyName(newName: string): Actions.SetChangePolicyNameAction {
    return {type: ActionType.SET_CHANGE_POLICY_NAME, payload: newName}
}

export function changeNetworkProtection(value: boolean): Actions.ChangeNetworkProtectionAction {
    return {type: ActionType.CHANGE_NETWORK_PROTECTION, payload: value}
}

export function changeUsbAvProtection(value: boolean): Actions.ChangeUsbAvProtectionAction {
    return {type: ActionType.CHANGE_USB_AV_PROTECTION, payload: value}
}

export function changeRemovablesEnforcement(
    value: boolean,
): Actions.ChangeRemovablesProtectionAction {
    return {type: ActionType.CHANGE_REMOVABLES_PROTECTION, payload: value}
}

export function changePolicyForNewDevices(
    value: policyForNewDevicesEnum,
): Actions.ChangePolicyForNewDevicesAction {
    return {type: ActionType.CHANGE_POLICY_FOR_NEW_DEVICES, payload: value}
}

export function changeMasterOverride(
    value: policyForMasterOverrideEnum,
): Actions.ChangePolicyForMasterOverrideAction {
    return {type: ActionType.CHANGE_POLICY_FOR_MASTER_OVERRIDE, payload: value}
}

export function changeBlockAllUserForOfflineAssets(
    value: boolean,
): Actions.ChangeBlockAllUserForOfflineAssetsAction {
    return {
        type: ActionType.CHANGE_BLOCK_ALL_USB_FOR_OFFLINE_ASSETS,
        payload: value,
    }
}

export function changeAllowAnyOnMaster(value: boolean): Actions.ChangeAllowAnyOnMasterAction {
    return {
        type: ActionType.CHANGE_ALLOW_ANY_ON_MASTER,
        payload: value,
    }
}

export function setDefaultPolicyData(): Actions.SetDefaultPolicyDataAction {
    return {type: ActionType.SET_DEFAULT_POLICY_DATA}
}

export function requestDiscardChanges(discardChanges: boolean): Actions.SetDiscardChangesAction {
    return {type: ActionType.SET_DISCARD_CHANGES, payload: discardChanges}
}

export function setPolicyAfterUpdate(
    receivedPolicy: EnforcementPolicy,
): Actions.SetPolicyToUpdateAction {
    return {
        type: ActionType.SET_POLICY_AFTER_UPDATE,
        payload: {receivedPolicy},
    }
}
