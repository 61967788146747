import * as Styles from './_styled/incident-details.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {DownloadReportButton} from '../../shared/download/download-report-button'
import {formatDateWithoutTime} from '../../../../../helpers/formatting'
import {IncidentAssetsList} from './assets-list'
import {IncidentRecommendations} from '../../shared/incident-recommendations/incident-recommendations'
import {latestTimestampSelector} from '../../../../../store/state/latest-event-timestamps/selectors'
import {locationMapSelector} from '../../../../../store/state/locations/selectors'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import {rolesSelector} from '../../../../../store/state/roles/selectors'
import {sfmScoresSelector} from '../../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../../store/state/threat-measures/selectors'
import {usersSelector} from '../../../../../store/state/users/selectors'
import {IncidentModalOutputModel} from '../../../models/incident-modal.model'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import {IncidentDetailsHeader} from './incident-details-header'
import {IncidentHistory} from '../../shared/incident-history/incident-history'
import {LinkedIncidentItemsList} from '../../shared/item-list/linked-items-list'
import {WatchedIncidentItemsList} from '../../shared/item-list/watched-items-list'
import {useEffect} from 'react'
import {IncidentStatus} from '../../../contexts/types/incident-status'
import {IncidentDetailsReopenDurationMessage} from './incident-details-reopen-duration-message'
import {fleetConfigSelector} from '../../../../../store/state/config/selectors'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function IncidentDetails(): JSX.Element | null {
    const locationMap = useTypedSelector(locationMapSelector)
    const userRoles = useTypedSelector(rolesSelector)
    const users = useTypedSelector(usersSelector)
    const nodes = useTypedSelector(nodesSelector)
    const threatMeasures = useTypedSelector(threatMeasuresSelector)
    const sfmScores = useTypedSelector(sfmScoresSelector)
    const latestTimestamps = useTypedSelector(latestTimestampSelector)
    const {findIncident, getLinkedItemsData, initialiseLinkedItemsData, downloadIncidentResponse} =
        usePagedIncidents()
    const {incidentReopenDuration} = useTypedSelector(fleetConfigSelector)

    useEffect(() => {
        initialiseLinkedItemsData()
        if (findIncident && findIncident.links?.length > 0) {
            getLinkedItemsData(findIncident.links)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findIncident?.identity, findIncident?.currentStatus])

    if (!findIncident) {
        return null
    }

    const model = new IncidentModalOutputModel(
        findIncident,
        locationMap,
        userRoles,
        users,
        sfmScores,
        nodes,
        latestTimestamps,
        threatMeasures,
    )

    if (!model) {
        return null
    }

    const showReopenDurationMessage =
        model.status === IncidentStatus.CLOSED_MONITOR && incidentReopenDuration !== undefined

    return (
        <Styles.Container
            onClick={(e) => e.stopPropagation()}
            id="incident-details-modal-container"
        >
            <IncidentDetailsHeader model={model} />
            {showReopenDurationMessage && (
                <IncidentDetailsReopenDurationMessage
                    incidentReopenDuration={incidentReopenDuration}
                />
            )}
            <Styles.IncidentDetailsContent>
                <Styles.DescriptionRow>
                    <Styles.ModalTitle>Details</Styles.ModalTitle>
                    <DownloadReportButton
                        id={model.id}
                        attachment={model.attachment}
                        page={AuditLogPageType.INCIDENTS}
                        downloadIncidentResponse={downloadIncidentResponse}
                    />
                </Styles.DescriptionRow>

                <Styles.WarningMessage id="warning-message">{model.warning}</Styles.WarningMessage>
                <Styles.DescriptionMessage id="description-message">
                    {model.description}
                </Styles.DescriptionMessage>

                <Styles.AssetsSection>
                    <Styles.AssetsRow centered>
                        <Styles.ModalLabel>Assets affected:</Styles.ModalLabel>
                        <IncidentAssetsList assets={model.assetsInvolved} />
                    </Styles.AssetsRow>
                    {model.hasWatchedItems && (
                        <Styles.AssetsRow>
                            <Styles.ModalLabel>Watched items:</Styles.ModalLabel>
                            <WatchedIncidentItemsList />
                        </Styles.AssetsRow>
                    )}
                    {!model.allItemsMonitored && (
                        <Styles.AssetsRow>
                            <Styles.ModalLabel>Linked items:</Styles.ModalLabel>
                            <LinkedIncidentItemsList />
                        </Styles.AssetsRow>
                    )}
                </Styles.AssetsSection>

                <Styles.TimeSection>
                    <Styles.DescriptionRow>
                        <Styles.ModalLabel>Last updated on:</Styles.ModalLabel>
                        <Styles.EventModal id="event-updated-modal">
                            {formatDateWithoutTime(model.updated.when)}
                        </Styles.EventModal>
                    </Styles.DescriptionRow>
                    <Styles.DescriptionRow>
                        <Styles.ModalLabel>Created on:</Styles.ModalLabel>
                        <Styles.EventModal id="event-raised-modal">
                            {formatDateWithoutTime(model.raised.when)}
                        </Styles.EventModal>
                    </Styles.DescriptionRow>
                </Styles.TimeSection>

                <IncidentHistory
                    history={model.history}
                    incidentIdentity={model.id}
                    displayFormat="modal"
                />

                {model.recommendations?.length !== 0 && (
                    <IncidentRecommendations recommendations={model.recommendations} />
                )}
            </Styles.IncidentDetailsContent>
        </Styles.Container>
    )
}
