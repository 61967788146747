import {NodeData} from '../../../../../../../values/nodes/NodeData'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelNoButton} from '../expand-panel-button/expand-panel-no-button'
import * as Styled from './comms-settings-section.styled'
import {ConfigurationOnboardDashboardAssets} from './configuration-asset-select'
import {VesselEmailSetting} from './vessel-email-setting'

interface CommsSettingsSectionProps {
    allAssets: NodeData[]
    vesselDashboard: boolean
}

export function CommsSettingsSection({
    allAssets,
    vesselDashboard,
}: CommsSettingsSectionProps): JSX.Element {
    const {displayValue, detailExpanded} = useVesselManagement()

    return (
        <Panel>
            <ExpandPanelNoButton headingRow={VesselManagementDetailType.CommsSettings} />
            {detailExpanded.networkSettingsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <ConfigurationOnboardDashboardAssets
                            vesselDashboardAgentIds={
                                displayValue.vesselDashboardAgentIds ?? undefined
                            }
                            allAssets={allAssets}
                            active={vesselDashboard}
                        />
                        <VesselEmailSetting emailAddress={displayValue.vesselEmail ?? undefined} />
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
