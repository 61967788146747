import {Input, InputWrapper} from './data-cell.styled'
import {useRef, useState} from 'react'
import {useVesselManagement} from '../../../../contexts/use-vessel-management'
import {debounce} from 'lodash'

interface DataInputProps {
    ipValue: string
    portValue: string
    label: 'source' | 'destination'
    index?: number
}

export function DataInput({ipValue, portValue, label, index}: DataInputProps): JSX.Element {
    const [displayIPValue, setDisplayIPValue] = useState(ipValue)
    const [displayPortValue, setDisplayPortValue] = useState(portValue)

    const {changeOTSourceIp, changeOTSourcePort} = useVesselManagement()

    const debounceChangeIp = useRef(
        debounce((newIpValue: string) => {
            changeOTSourceIp(newIpValue, label, index)
        }, 0),
    ).current

    const debounceChangePort = useRef(
        debounce((newPortValue: string) => {
            changeOTSourcePort(newPortValue, label, index)
        }, 0),
    ).current

    const handleIpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setDisplayIPValue(newValue)
    }

    const handlePortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setDisplayPortValue(newValue)
    }

    const handleIpBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        debounceChangeIp(displayIPValue)
    }

    const handlePortBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        debounceChangePort(displayPortValue)
    }

    return (
        <InputWrapper id={`ot-source-InputWrapper_${index}`}>
            <Input
                id={`ot-source-inputTypeIP_${index}`}
                value={displayIPValue}
                onChange={handleIpChange}
                onBlur={handleIpBlur}
                placeholder="Src IP"
            />
            <Input
                id={`ot-source-inputTypePort_${index}`}
                value={displayPortValue}
                onChange={handlePortChange}
                onBlur={handlePortBlur}
                placeholder="Src Port"
            />
        </InputWrapper>
    )
}
