import {createSelector} from 'reselect'
import qs from 'qs'

import {getNodeLatestEventOrNA} from '../../../../../values/nodes/NodeData'
import {latestTimestampSelector} from '../../../../../store/state/latest-event-timestamps/selectors'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const queryString = (state: any): any => state.router.location.search

const latestEventTimeStampSelector = createSelector(
    queryString,
    latestTimestampSelector,
    (queryStringState, latestEventTimestamps) => {
        const {assetDetails} = qs.parse(queryStringState, {ignoreQueryPrefix: true})

        return getNodeLatestEventOrNA(assetDetails as string, latestEventTimestamps)
    },
)

export default latestEventTimeStampSelector
