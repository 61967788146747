import {
    PagedSoftwareInventoryState,
    ProcessingStatus,
} from '../types/paged-software-inventory-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {SoftwareInventoryResponse} from '../types/software-inventory-response'
import {DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE} from '../types/default-paged-software-inventory-state'

export const pagedSoftwareInventoryReducer = produce(
    (draft: PagedSoftwareInventoryState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_SELECTED_PAGE:
                draft.selectedPage = action.payload
                break
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.dataSoftwareInventoryMap = undefined
                draft.totalNumberOfSoftwareInventory = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loadingDataState = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.totalNumberOfSoftwareInventory = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages

                if (!draft.dataSoftwareInventoryMap) {
                    draft.dataSoftwareInventoryMap = new Map<number, SoftwareInventoryResponse[]>()
                }
                if (
                    !draft.dataSoftwareInventoryMap.has(draft.selectedPage) ||
                    !isEqual(
                        draft.dataSoftwareInventoryMap.get(draft.selectedPage),
                        action.payload.data,
                    )
                ) {
                    draft.dataSoftwareInventoryMap.set(draft.selectedPage, action.payload.data)
                }
                break
            case ActionType.RECEIVED_REQUESTED_SOFTWARE_DETAILS:
                if (!isEqual(draft.findSoftwareDetailForModal, action.payload)) {
                    draft.findSoftwareDetailForModal = action.payload
                }
                break

            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break

            case ActionType.DISPLAY_SOFTWARE_DETAILS_MODAL:
                draft.modalIdForSoftwareDetail = action.payload
                draft.isProcessingSoftwareDetailsModal = ProcessingStatus.PROCESSING
                break
            case ActionType.SWITCH_TO_CACHED_DATA_MODAL:
                draft.isProcessingSoftwareDetailsModal = ProcessingStatus.PROCESSED
                break
            case ActionType.CLOSE_SOFTWARE_DETAILS_MODAL:
                draft.modalIdForSoftwareDetail = null
                draft.isProcessingSoftwareDetailsModal = ProcessingStatus.NOT_PROCESSING
                break
            case ActionType.SET_FILTER:
                draft.selectedPage = 0
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_SOFTWARE_INVENTORY_STATE
                break
            case ActionType.DISPLAY_CHANGE_SOFTWARE_STATUS_MODAL:
                draft.showChangeSoftwareStatusModalDisplay = action.payload.state
                draft.selectedStatusType = action.payload.status
                break
            case ActionType.CLOSE_CHANGE_SOFTWARE_STATUS_MODAL:
                draft.selectedStatusType = null
                draft.showChangeSoftwareStatusModalDisplay = false
                break
            case ActionType.SET_SOFTWARE_STATUS:
                draft.findSoftwareDetailForModal!.status = action.payload
                break
            case ActionType.SET_SOFTWARE_MODAL_OPTIONS:
                draft.modalCheckState.forCompany = action.payload.forCompany
                draft.modalCheckState.forAllSoftware = action.payload.forAllSoftware
                break
            case ActionType.SHOW_ASSIGN_GENERATE_REPORT_POPUP:
                draft.showGenerateReportPopup = action.payload
                break
            case ActionType.REPORT_CREATE:
                draft.reportUrl = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
