import * as Styled from './target.styled'
import TARGET from '../../../../@assets/icons/target-metrics.svg'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {MetricType} from '../../contexts/types/metrics-response'
import {NoData} from '../no-data/no-data'
import {DataLoading} from '../../../../components/data-loading/data-loading'
import LoadingState from '../../../../values/loading-state-enum'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import locationSelectionNumberReselector from '../../contexts/reselector/location-selection-number-reselector'

interface TargetProps {
    metricType: MetricType
}

export function Target({metricType}: TargetProps): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, loadingFilteredDataState, dataSummariesMetricsMap} = usePagedMetricsBeta()
    const targetData = dataSummariesMetricsMap?.get(metricType)?.target
    const totalVesselsAboveTarget = targetData?.aboveTarget ?? 0
    const totalVesselsBellowTarget = targetData?.belowTarget ?? 0

    const locationSelectionNumber = useTypedSelector(locationSelectionNumberReselector)

    const scoreOfVesselsAbove = Math.round(
        (totalVesselsAboveTarget / locationSelectionNumber) * 100,
    )
    const scoreOfVesselsBelow = Math.round(
        (totalVesselsBellowTarget / locationSelectionNumber) * 100,
    )
    const aboveTarget = getVesselsMessage(totalVesselsAboveTarget, 'vessel', 'vessels')
    const belowTarget = getVesselsMessage(totalVesselsBellowTarget, 'vessel', 'vessels')

    if (loadingFilteredDataState === LoadingState.RequestingData) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-target-section-${metricType}`}
            >
                <TitleWrapper icon={TARGET} title="Target" identifier={metricType} />
                <DataLoading />
            </Styled.Section>
        )
    }
    if (!targetData) {
        return (
            <Styled.Section
                showFilterBar={showFilterBar}
                width={width}
                id={`footer-target-section-${metricType}`}
            >
                <TitleWrapper icon={TARGET} title="Target" identifier={metricType} />
                <NoData text="No Assets are found" />
            </Styled.Section>
        )
    }

    return (
        <Styled.Section
            showFilterBar={showFilterBar}
            width={width}
            id={`footer-target-section-${metricType}`}
        >
            <TitleWrapper icon={TARGET} title="Target" identifier={metricType} />
            <Styled.TargetSection>
                <Styled.ScoreBarWrapper id={`footer-target-score-bar-wrapper-${metricType}`}>
                    <Styled.SubTitle>Above target</Styled.SubTitle>
                    <Styled.ScoreBar
                        score={scoreOfVesselsAbove}
                        targetType="above"
                        showFilterBar={showFilterBar}
                        width={width}
                        id={`footer-target-score-bar-${metricType}-${scoreOfVesselsAbove}`}
                    />
                </Styled.ScoreBarWrapper>
                <Styled.Text id={`footer-target-above-target-${metricType}`}>
                    {aboveTarget}
                </Styled.Text>
            </Styled.TargetSection>
            <Styled.TargetSection>
                <Styled.ScoreBarWrapper>
                    <Styled.SubTitle>Below target</Styled.SubTitle>
                    <Styled.ScoreBar
                        score={scoreOfVesselsBelow}
                        targetType="below"
                        showFilterBar={showFilterBar}
                        width={width}
                        id={`footer-target-score-bar-${metricType}-${scoreOfVesselsBelow}`}
                    />
                </Styled.ScoreBarWrapper>
                <Styled.Text id={`footer-target-below-target-${metricType}`}>
                    {belowTarget}
                </Styled.Text>
            </Styled.TargetSection>
        </Styled.Section>
    )
}
interface TitleWrapperProps {
    icon: string
    title: string
    identifier: string
}

function TitleWrapper({icon, title, identifier}: TitleWrapperProps): JSX.Element {
    return (
        <Styled.TitleWrapper id={`footer-target-title-${identifier}`}>
            <img src={icon} id={`footer-target-icon-${identifier}`} />
            {title}
        </Styled.TitleWrapper>
    )
}
function getVesselsMessage(count: number, singular: string, plural: string) {
    if (count === 1) {
        return `1 ${singular}`
    }
    return `${count} ${plural}`
}
