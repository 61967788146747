import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
interface TableNotesRowProps {
    gridRow: number
}

export const TableNotesRow = styled.div<TableNotesRowProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 2;
    grid-column-end: -2;
`
export const DetailsSection = styled.div`
    margin-top: ${spacing(2)};
    margin-bottom: ${spacing(2)};
`
