import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface CardProps {
    width: number
    acEnabled?: boolean
}

function getColumnTemplate(width: number): string {
    if (width < 700) {
        return 'repeat(2, [col-start] 1fr);'
    }
    return 'repeat(3, [col-start] 1fr);'
}
export const Card = styled.div<CardProps>`
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    background-color: ${(props) => props.theme.unknownAssets.dataRow.background};
    display: grid;
    grid-template-columns: ${(props) => getColumnTemplate(props.width)};
    grid-template-areas: ${(props) =>
        props.acEnabled ? getBetaGridTemplate(props.width) : getGridTemplate(props.width)};
    gap: ${spacing(2)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    padding: ${spacing(2)};
    ${mediumSmallFont()};
`
interface CardAssetTitleDataProps {
    gridArea: string
}
export const CardAssetTitleData = styled.h2<CardAssetTitleDataProps>`
    display: flex;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin: 0;
    grid-area: ${(props) => props.gridArea};
`
interface CardLocationTitleDataProps {
    gridArea: string
}
export const CardLocationTitleData = styled.h2<CardLocationTitleDataProps>`
    display: flex;
    margin: 0px;
    align-items: center;
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    grid-area: ${(props) => props.gridArea};
`
function getBetaGridTemplate(width: number): string {
    if (width < 700) {
        return '"ASSET-TITLE LOCATION-TITLE" "WHEN UPDATED-BY" "IP-ADDRESS HOSTNAME" "NETWORK EMPTY" "VIEW-DETAIL TRUST-DEVICE"'
    }
    return '"ASSET-TITLE EMPTY-COLUMN-TITLE LOCATION-TITLE" "WHEN IP-ADDRESS NETWORK" "UPDATED-BY HOSTNAME EMPTY"  "VIEW-DETAIL EMPTY-LOW-COLUMN TRUST-DEVICE"'
}

function getGridTemplate(width: number): string {
    if (width < 700) {
        return '"ASSET-TITLE LOCATION-TITLE" "WHEN IP-ADDRESS" "NETWORK HOSTNAME" "VIEW-DETAIL EMPTY"'
    }
    return "'ASSET-TITLE EMPTY-COLUMN-TITLE LOCATION-TITLE' 'WHEN IP-ADDRESS NETWORK'  'HOSTNAME EMPTY EMPTY' 'VIEW-DETAIL EMPTY EMPTY'"
}
