import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface BlankRowProps {
    gridRow: number
}
export const BlankRow = styled.div<BlankRowProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 1;
    grid-column-end: -1;
    height: ${spacing(2)};
`
