import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../theme/spacing'
interface ButtonProps {
    isChanged: boolean
}

export const Button = styled.button<ButtonProps>`
    border: none;
    background: none;
    color: ${(props) => props.theme.reports.actionButton.text};
    border: 1px solid;
    border-radius: 6px;
    background-color: ${(props) => props.theme.reports.actionButton.background};
    padding: ${spacing(1)} ${spacing(2)};
    ${(props) => (props.isChanged ? '' : 'pointer-events: none; opacity: 0.5;')};
`

export const Text = styled.span`
    ${mediumSmallFont()}
`
