import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {CreateAlertButton} from './create-alert-button'
import {DeleteAlertButton} from './delete-alert-button'
import * as Styled from './notify-me-popup.styled'
import {UpdateAlertButton} from './update-alert-button'

interface ButtonAreaProps {
    model: NotifyMeOutputModel
    closePopup: (value: boolean) => void
}

export function ButtonArea({model, closePopup}: ButtonAreaProps): JSX.Element {
    return (
        <>
            {model.currentSubscription ? (
                <Styled.ButtonsArea>
                    <UpdateAlertButton model={model} text="Update alert" />
                    <DeleteAlertButton
                        disabled={false}
                        subscriptionId={model.currentSubscription.identity}
                        closePopup={closePopup}
                    />
                </Styled.ButtonsArea>
            ) : (
                <Styled.ButtonsArea>
                    <CreateAlertButton model={model} text="Create alert" />
                </Styled.ButtonsArea>
            )}
        </>
    )
}
