import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usersLoadingSelector} from '../../../../store/state/users/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {usePagedReports} from '../../contexts/use-paged-reports'
import {LoadingData} from '../../loading-data'

import {ReportsCardsLoaded} from './reports-cards-loaded'
export function ReportsCardsContent(): JSX.Element {
    const {loadingDataState, totalNumberOfReports} = usePagedReports()
    const usersLoading = useTypedSelector(usersLoadingSelector)

    const showLoading =
        totalNumberOfReports == undefined ||
        loadingDataState === LoadingState.NotPopulated ||
        loadingDataState === LoadingState.RequestingData ||
        usersLoading

    if (showLoading) {
        return <LoadingData />
    }
    return <ReportsCardsLoaded />
}
