import * as Styled from './_styled/metrics-policy-details.styled'
import {DetailPanelHeader} from '../../../shared/header/detail-panel-header'
import {PolicyName} from './policy-name/policy-name'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {TabSection} from './tab-section/tab-section'
import {NoDataModal} from '../no-data-modal'
import {ButtonsArea} from './buttons-area/buttons-area'

export function MetricsPolicyDetails(): JSX.Element {
    const {selectedMetricPolicyId} = useMetricsPolicy()
    if (!selectedMetricPolicyId) {
        return <NoDataModal />
    }
    return (
        <Styled.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader text="Metrics policy" />
            <Styled.PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea />
            </Styled.PolicyNameWrapper>
            <Styled.ContentWrapper>
                <TabSection />
            </Styled.ContentWrapper>
        </Styled.Container>
    )
}
