import {LocationIdType} from '../../../../../store/state/locations/state'
import {VesselDetails} from '../common/vessel-details/vessel-details'
import * as Styled from './cell.styled'
interface Props {
    id: LocationIdType
}

export function ZoomLevel1Cell({id}: Props): JSX.Element {
    return (
        <Styled.Cell id="ZoomLevel1Cell">
            <div>
                <VesselDetails id={id} />
            </div>
        </Styled.Cell>
    )
}
