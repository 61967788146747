import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

interface ContainerProps {
    isInactive?: boolean
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    width: 100%;
    margin-bottom: ${spacing(2)};
    background-color: ${(props) =>
        props.isInactive
            ? props.theme.colors.background.fade
            : props.theme.colors.background.default};
    ${mediumSmallFont()}
`

export const SearchIcon = styled.div`
    position: absolute;
    right: ${spacing(1)};
    top: 0;
    padding-left: ${spacing(1)};
    color: ${(props) => props.theme.colors.link.primary};
    cursor: pointer;
    border-left: 1px solid ${(props) => props.theme.colors.border.active};
    line-height: 30px;
`

export const XIcon = styled.div`
    position: absolute;
    right: ${spacing(6)};
    top: 0;
    padding-left: ${spacing(1)};
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
    line-height: 30px;
`

export const SearchInput = styled.input.attrs({type: 'text'})`
    width: 100%;
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    padding: ${spacing(1)} ${spacing(6)} ${spacing(1)} ${spacing(1)};
    height: 30px;
    background-color: inherit;
`
export const TagResultsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing(2)};
    padding: 3px;
`
export const TagResultsPopUp = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.incidents.filterBar.titleSeparator};
    border-radius: 3px;
    flex-wrap: wrap;
    gap: ${spacing(2)};
    padding: 3px;
    background: white;
    overflow: hidden;
    z-index: 1;
`
