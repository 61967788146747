import * as Styled from './tag-filter.styled'
import {TagSearch} from './tag-search'

export function TagsFilter(): JSX.Element {
    return (
        <Styled.FilterComponentWrapper>
            <Styled.FilterComponent>
                <Styled.Label>Selected Tag</Styled.Label>
                <TagSearch />
            </Styled.FilterComponent>
        </Styled.FilterComponentWrapper>
    )
}
