import {ReactNode, useReducer} from 'react'
import {LicenseState} from './type/licenses-state'
import {DEFAULT_LICENSE_STATE} from './type/default-licenses-state'
import {licenseReducer} from './state/reducer'
import {LicensesContext} from './licenses-context'
import {fleetConfigSelector} from '../../../store/state/config/selectors'
import useTypedSelector from '../../../hooks/use-typed-selector'

type LicensesPageProviderProps = {
    children: ReactNode | ReactNode[]
}

export function LicensesPageProvider({children}: LicensesPageProviderProps): JSX.Element {
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const licenseStates: Omit<LicenseState, 'saveButtonEnabled'> = {
        incidentsPolicyEnabled:
            fleetConfig.LicenseState?.['License:Incidents']?.value.enabled ??
            DEFAULT_LICENSE_STATE.incidentsPolicyEnabled,

        incidentsPolicyEndDate:
            fleetConfig.LicenseState?.['License:Incidents']?.value.endTimestamp ??
            DEFAULT_LICENSE_STATE.incidentsPolicyEndDate,

        networkEnforcementEnabled:
            fleetConfig.LicenseState?.['License:Enforcement:Networks']?.value.enabled ??
            DEFAULT_LICENSE_STATE.networkEnforcementEnabled,

        networkEnforcementEndDate:
            fleetConfig.LicenseState?.['License:Enforcement:Networks']?.value.endTimestamp ??
            DEFAULT_LICENSE_STATE.networkEnforcementEndDate,

        networkEnforcementMaxVessels:
            fleetConfig.LicenseState?.['License:Enforcement:Networks']?.value.numberOfLicenses ??
            DEFAULT_LICENSE_STATE.networkEnforcementMaxVessels,

        onboardEngagementEnabled:
            fleetConfig.LicenseState?.['License:Metrics:OnboardEngagement']?.value.enabled ??
            DEFAULT_LICENSE_STATE.onboardEngagementEnabled,

        onboardEngagementEndDate:
            fleetConfig.LicenseState?.['License:Metrics:OnboardEngagement']?.value.endTimestamp ??
            DEFAULT_LICENSE_STATE.onboardEngagementEndDate,

        removableEnforcementEnabled:
            fleetConfig.LicenseState?.['License:Enforcement:Removables']?.value.enabled ??
            DEFAULT_LICENSE_STATE.removableEnforcementEnabled,

        removableEnforcementEndDate:
            fleetConfig.LicenseState?.['License:Enforcement:Removables']?.value.endTimestamp ??
            DEFAULT_LICENSE_STATE.removableEnforcementEndDate,

        removableEnforcementMaxVessels:
            fleetConfig.LicenseState?.['License:Enforcement:Removables']?.value.numberOfLicenses ??
            DEFAULT_LICENSE_STATE.removableEnforcementMaxVessels,
    }
    const initialState: LicenseState = {
        ...DEFAULT_LICENSE_STATE,
        ...licenseStates,
    }
    const [state, dispatch] = useReducer(licenseReducer, initialState)
    return <LicensesContext.Provider value={{state, dispatch}}>{children}</LicensesContext.Provider>
}
