import {UniqueFlow} from '../../../../../values/unique-flow'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function changePageSize(newPageSize: number): Actions.ChangePageSizeAction {
    return {type: ActionType.CHANGE_PAGE_SIZE_ACTION, payload: newPageSize}
}

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function switchToCachedPageData(requestedPage: number): Actions.SwitchToCachedPagedAction {
    return {type: ActionType.SWITCH_TO_CACHED_PAGE_DATA_ACTION, payload: requestedPage}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function receivedRequestedPageData(
    data: UniqueFlow[],
    totalRecords: number,
): Actions.ReceivedRequestedPageDataAction {
    return {type: ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION, payload: {data, totalRecords}}
}

export function setError(error: string): Actions.SetErrorAction {
    return {type: ActionType.SET_ERROR_ACTION, payload: error}
}

export function clearDateFilter(): Actions.ClearDateFilterAction {
    return {type: ActionType.CLEAR_DATE_FILTER}
}

export function setDateFilter(
    fromDate: string | undefined,
    toDate: string | undefined,
): Actions.SetDateFilterAction {
    return {type: ActionType.SET_DATE_FILTER, payload: {fromDate, toDate}}
}
