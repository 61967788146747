import * as Styled from './upload-processing.styled'
import PDF_UPLOAD from '../pdf-icon-upload.svg'
import {AlertCircle, Trash2} from 'react-feather'
import {useModaUploadDocument} from '../context/use-modal-upload-document'

export function UploadError(): JSX.Element {
    const {fileName, fileSize, errorMessage, setFileValue} = useModaUploadDocument()
    return (
        <>
            <Styled.FileBox>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.FileImage src={PDF_UPLOAD} />
                    <Styled.SubTitle>{`${fileName} (${fileSize})`}</Styled.SubTitle>
                    <Trash2
                        color="blue"
                        width={19}
                        height={19}
                        style={{cursor: 'pointer'}}
                        onClick={() => setFileValue(null)}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.SubTitle>Upload error</Styled.SubTitle>
                    <AlertCircle color="red" width={19} height={19} />
                </div>
            </Styled.FileBox>
            <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
        </>
    )
}
