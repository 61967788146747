import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {locationMapSelector} from '../../../../../../store/state/locations/selectors'
import {getLocationDescription} from '../../../../../../store/state/locations/state'
import * as Styled from './current-applied-vessels.style'
import {SectionContent, SectionTitle} from '../assign-policy-dialog.styled'
import {usePolicyManagement} from '../../../../contexts/policy-management/use-policy-management'

export function CurrentAppliedVessels(): JSX.Element {
    const allLocations = useTypedSelector(locationMapSelector)
    const {assignPolicyDialogState} = usePolicyManagement()
    const locations = assignPolicyDialogState.currentLocations
    return (
        <SectionContent>
            <SectionTitle> Currently assigned vessels:</SectionTitle>
            <Styled.CurrentAppliedVessels>
                {locations
                    ?.filter((location) => allLocations.has(location))
                    .map((location, index) => (
                        <Styled.CurrentVessel key={index}>
                            • {getLocationDescription(allLocations, location)}
                        </Styled.CurrentVessel>
                    ))}
            </Styled.CurrentAppliedVessels>
        </SectionContent>
    )
}
