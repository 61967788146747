import styled from 'styled-components'
import {mediumFont, mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'
import DatePicker from 'react-datepicker'

export const Filter = styled.section`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(10px 10px 30px ${(props) => props.theme.modalDialog.dropShadow});
`

export const Header = styled.header`
    color: white;
    background-color: #303030;
    width: 100%;
    padding: ${spacing(1)} ${spacing(2)};
    margin: 0;
`

export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: 0;
`

export const Settings = styled.div`
    padding: ${spacing(8)};
`

export const DateRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: ${spacing(2)} 0;
    align-items: center;
`

export const Label = styled.label`
    margin: 0 ${spacing(2)} 0 0;
    padding: 0;
    width: 70px;
    text-align: right;
`

export const Actions = styled.div`
    border-top: 1px solid grey;
    padding: ${spacing(2)} ${spacing(2)};
    margin: 0;
`

export const ClearButtonWrapper = styled.span`
    margin-right: ${spacing(4)};
`

export const CancelButtonWrapper = styled.span`
    margin-right: ${spacing(8)};
`

export const PrimaryButton = styled.button`
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 4px 8px;
    color: ${(props) => props.theme.refreshButton.hover.textColor};
    background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};

    &:hover {
    }

    &:active {
        color: ${(props) => props.theme.refreshButton.active.textColor};
        background-color: ${(props) => props.theme.refreshButton.active.backgroundColor};
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
    ${mediumSmallFont()}
`

export const SecondaryButton = styled.button`
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 4px 8px;
    border-color: ${(props) => props.theme.refreshButton.active.borderColor};

    &:hover {
    }
    &:active {
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
    ${mediumSmallFont()}
`

export const DateEntry = styled(DatePicker)`
    ${mediumSmallFont()}
    width: 200px;
    padding-left: ${spacing(1)};
`
