import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {SortColumnType} from '../../../../../../store/state/metrics-filter-beta/state'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export function getGridTemplateColumns(showReducedColumnNo: boolean): string {
    return showReducedColumnNo
        ? `0 200px 200px 170px 130px 150px 120px 120px 0`
        : `0 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0`
}
export function showCards(width: number, showCardsEarlier: boolean): boolean {
    return !showCardsEarlier ? width < 1000 : width < 1300
}
interface Props {
    showReducedColumnNo: boolean
}
export const TableHeaderGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.showReducedColumnNo)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    padding: ${spacing(2)};
    background-color: ${(props) => props.theme.softwareInventory.dataArea.background};
`
export const getNewSortByDirection = (
    newlySelectedColumn: string,
    currentSortColumn: SortColumnType,
): boolean => {
    if (newlySelectedColumn !== currentSortColumn.column) {
        return false
    }
    switch (currentSortColumn.isAscending) {
        case true:
            return false
        case false:
            return true
        default:
            return false
    }
}
interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transition:
        transform 0.2s,
        color 0.2s;
    transition-timing-function: ease-in-out;
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`
interface TableHeaderTextProps {
    active?: boolean
    centered?: boolean
}
export const TableHeaderText = styled.div<TableHeaderTextProps>`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: center;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
    ${(props) => (props.centered ? 'justify-content: center;' : '')}
`
export const Text = styled.div`
    word-break: break-word;
    text-align: center;
    display: flex;
    flex-direction: column;
`
