import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {MAX_SCREEN_WIDTH} from '../../../../../theme/theme'
import {
    getGridTemplateColumns,
    getReducedGridTemplateColumns,
} from './user-management-list-header.styled'

interface ContentContainerProps {
    width: number
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
    padding: 0 ${spacing(1)} ${spacing(2)};
`

interface TableDataGridProps {
    width: number
    showDetailsBtn: boolean
}
export const TableDataGrid = styled.div<TableDataGridProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.showDetailsBtn
            ? getGridTemplateColumns(props.width)
            : getReducedGridTemplateColumns(props.width)};
    gap: ${spacing(2)};
    flex: 1;
    background-color: ${(props) => props.theme.userManagement.dataArea.background};
`
