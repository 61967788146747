import {SortableColumn} from './sortable-column'
import {TableHeaderCell} from './table-header-cell.styled'

export function TableHeader(): JSX.Element {
    return (
        <>
            <SortableColumn gridColumn={2} text="Status" colName="status" />
            <SortableColumn gridColumn={3} text="Incident No." colName="incidentNumber" />
            <SortableColumn gridColumn={4} text="Title" colName="title" />
            <SortableColumn gridColumn={5} text="Severity" colName="severity" />
            <SortableColumn gridColumn={6} text="Incident Type" colName="incidentType" />
            <SortableColumn gridColumn={7} text="Vessel" colName="vessel" />
            <SortableColumn gridColumn={8} text="Raised" colName="raised" />
            <SortableColumn gridColumn={9} text="Assigned to" colName="assignedTo" />
            <SortableColumn gridColumn={10} text="Updated" colName="updated" />
            <TableHeaderCell gridColumn={11}></TableHeaderCell>
        </>
    )
}
