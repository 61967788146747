import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {sortedLocationsSelector} from '../../../../listing/selector/sorted-locations-selectors'
import {isEmptyConfig} from './helper'
import {TableRow} from './vessels-table-row'
import {ContentContainer} from './_styled/table-content.styled'

interface TableContentProps {
    code: string
}

export function TableContent({code}: TableContentProps): JSX.Element {
    const sortedLocations = useTypedSelector(sortedLocationsSelector)
    const currentLocation = sortedLocations.filter((location) => location.code === code)[0]

    return (
        <ContentContainer>
            {sortedLocations
                .filter((location) => location !== currentLocation && !isEmptyConfig(location))
                .map((location, index) => (
                    <TableRow
                        key={location.location}
                        location={location}
                        index={index}
                        currentType={currentLocation.type}
                        currentCustomerLocationCode={currentLocation.customerLocationCode}
                    />
                ))}
        </ContentContainer>
    )
}
