import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface Props {
    width: number
    reducedGrid: boolean
}

export const TableHeaderGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.reducedGrid
            ? '0 120px 120px 0.8fr 1fr 1fr 0.5fr 0.5fr 120px 0 420px'
            : '0 120px 120px 0.8fr 1fr 1fr 0.5fr 0.5fr 120px 0'};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    padding: 0 ${spacing(2)} ${spacing(2)} ${spacing(4)};
    background-color: ${(props) => props.theme.commonTableViewPage.dataArea.background};
`

interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
}

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`

interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`

interface TableHeaderTextProps {
    active?: boolean
}

export const TableHeaderText = styled.div<TableHeaderTextProps>`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: center;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
`
