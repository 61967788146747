import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {softwareInventoryFilterSelector} from '../../../../../store/state/software-inventory-filter/selectors'
import {SoftwareDetailType} from '../../../contexts/types/paged-software-inventory-state'
import {usePagedSoftwareInventory} from '../../../contexts/use-paged-software-inventory'
import {getFormattedSoftwareInventoryData} from '../../data-helpers'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {DataCell} from '../shared/data-cell'
import * as Styled from './software-details.styled'

export function SoftwareDetailsSection(): JSX.Element {
    const {findSoftwareDetailsForModal} = usePagedSoftwareInventory()
    const formattedSoftwareDetails = getFormattedSoftwareInventoryData(findSoftwareDetailsForModal)
    const {detailExpanded} = useTypedSelector(softwareInventoryFilterSelector)
    return (
        <Styled.DetailPanelSection id="SoftwareDetailsSection_Section">
            <ExpandPanelButton headingRow={SoftwareDetailType.Details} />
            {detailExpanded.detailsExpanded && (
                <Styled.DetailedAreaWrapper>
                    <Styled.DetailsAreaContent id="SoftwareDetailsSection_Content">
                        <DataCell label="Newest version:">
                            {formattedSoftwareDetails.latestVersion}
                        </DataCell>
                        <DataCell label="Oldest version:">
                            {formattedSoftwareDetails.oldestVersion}
                        </DataCell>
                        <DataCell label="First detected:">
                            {formattedSoftwareDetails.firstSeenTimestamp}
                        </DataCell>
                        <DataCell label="Last active:">
                            {formattedSoftwareDetails.lastSeenTimestamp}
                        </DataCell>
                        <DataCell label="Is Portable:">
                            {formattedSoftwareDetails.isPortable ? 'true' : 'false'}
                        </DataCell>
                    </Styled.DetailsAreaContent>
                </Styled.DetailedAreaWrapper>
            )}
        </Styled.DetailPanelSection>
    )
}
