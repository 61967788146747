import styled from 'styled-components'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../../theme/theme'

interface ContentContainerProps {
    width: number
    backgroundColor?: ColorType
}

export const ContentContainer = styled.div<ContentContainerProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${MAX_SCREEN_WIDTH}px;
    width: 100%;
    align-self: center;
`
