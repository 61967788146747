import {usePagedUnknownAssetsBeta} from '../../../context/use-paged-unknown-assets'
import {LoadingDataModal} from './loading-data-modal'
import {NetworkAssetDetails} from './network-asset-details'
import {ContentWrapper} from './_styled/content-wrapper.styled'

export function NetworkAssetDetailsModal(): JSX.Element {
    const {isProcessingModal} = usePagedUnknownAssetsBeta()

    if (isProcessingModal) {
        return <LoadingDataModal />
    }

    return (
        <ContentWrapper id="network-asset-details-modal_wrapper">
            <NetworkAssetDetails />
        </ContentWrapper>
    )
}
