import styled from 'styled-components'
import {ThreatValue, ThreatValues} from '../../../../../../helpers/threatHelper'
import {spacing} from '../../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../../theme/theme'

interface ScoreProps {
    theme: CyberOwlTheme
    score: number
    threatLevel: ThreatValue
}

function getCardBackGroundColor(theme: CyberOwlTheme, threatLevel: ThreatValue): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.myVessels.cards.highRiskBackground

        case ThreatValues.MEDIUM:
            return theme.myVessels.cards.mediumRiskBackground

        default:
            return theme.myVessels.cards.lowRiskBackground
    }
}

function getCardColor(theme: CyberOwlTheme, threatLevel: ThreatValue): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.myVessels.cards.highRiskText
        case ThreatValues.MEDIUM:
            return theme.myVessels.cards.mediumRiskText

        default:
            return theme.myVessels.cards.lowRiskText
    }
}

interface VesselDetailsProps {
    threatLevel: ThreatValue
}

export const VesselDetails = styled.div<VesselDetailsProps>`
    display: flex;
    align-items: center;
    background-color: ${(props) => getCardBackGroundColor(props.theme, props.threatLevel)};
    color: ${(props) => getCardColor(props.theme, props.threatLevel)};
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 1px 2px ${(props) => props.theme.myVessels.cards.boxShadow};
`

function getVesselThreatScoreBackGroundColor(
    theme: CyberOwlTheme,
    threatLevel: ThreatValue,
): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.background
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.background

        default:
            return theme.threatScores.score.low.background
    }
}

function getVesselThreatScoreColor(theme: CyberOwlTheme, threatLevel: ThreatValue): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.text
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.text

        default:
            return theme.threatScores.score.low.text
    }
}

export const VesselThreatScore = styled.div<ScoreProps>`
    border-radius: 3px;
    margin: ${spacing(3)} ${spacing(2)} ${spacing(3)} ${spacing(3)};
    height: 40px;
    width: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.4;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    color: ${(props) => getVesselThreatScoreColor(props.theme, props.threatLevel)};
    background-color: ${(props) =>
        getVesselThreatScoreBackGroundColor(props.theme, props.threatLevel)};
`

export const Details = styled.div`
    display: flex;
    padding: ${spacing(2)};
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    min-width: 0;
`
