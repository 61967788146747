import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

interface TagResultProps {
    cursorPointer?: boolean
}
export const TagResult = styled.div<TagResultProps>`
    position: relative;
    display: flex;
    column-gap: ${spacing(1)};
    border-radius: ${spacing(1)};
    background-color: rgba(232, 220, 200, 0.5);
    padding: 3px ${spacing(1)};
`

interface TagItemProps {
    maxWidth?: string
}
export const TagItem = styled.div<TagItemProps>`
    font-weight: ${(props) => props.theme.font.weight.semibold};
    max-width: ${(props) => props.maxWidth ?? '300px'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
export const TagExpandedView = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    background: white;
    padding: 3px;
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    border-radius: ${spacing(1)};
    min-width: 100px;
    z-index: 5;
`
export const TagExpandedViewTagName = styled.div`
    text-align: center;
    font-weight: ${(props) => props.theme.font.weight.normal};
`

export const DeleteIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: red;
    cursor: pointer;
`
