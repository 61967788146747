import {GuidType} from '../../../../../../../values/generic-type-defintions'
import {VesselManagementDetailType} from '../../../../../contexts/type/vessel-management-state'
import {useVesselManagement} from '../../../../../contexts/use-vessel-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import * as Styled from './network-settings-section.styled'
import {VesselNetworksBusinessNetworks} from './vessel-networks-business-networks'
import {VesselNetworksCrewNetworks} from './vessel-networks-crew-networks'
import {VesselNetworksOTNetworks} from './vessel-networks-ot-networks'

interface NetworkSettingsSectionProps {
    locationId: GuidType
}

export function NetworkSettingsSection({locationId}: NetworkSettingsSectionProps): JSX.Element {
    const {displayValue, detailExpanded} = useVesselManagement()

    return (
        <Panel>
            <ExpandPanelButton headingRow={VesselManagementDetailType.NetworkSettings} />
            {detailExpanded.networkSettingsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <VesselNetworksBusinessNetworks
                            businessNetworks={displayValue.networkConfiguration?.BusinessNetworks}
                            locationIdentity={locationId}
                        />
                        <VesselNetworksOTNetworks
                            otNetworks={displayValue.networkConfiguration?.OTNetworks}
                            locationIdentity={locationId}
                        />
                        <VesselNetworksCrewNetworks
                            crewNetworks={displayValue.networkConfiguration?.CrewNetworks}
                            locationIdentity={locationId}
                        />
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
