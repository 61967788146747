import {UnknownAssetsCardsPopulated} from './unknown-assets-cards-populated'
import {NoDataCard} from './no-data-card/no-data-card'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'

export function UnknownAssetsCardsLoaded(): JSX.Element {
    const {totalNumberOfUnknownAssets} = usePagedUnknownAssetsBeta()
    return totalNumberOfUnknownAssets && totalNumberOfUnknownAssets > 0 ? (
        <UnknownAssetsCardsPopulated />
    ) : (
        <NoDataCard text="No network assets found." />
    )
}
