import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {IncidentSeverityValues} from '../../../../values/incident-response-values'
import {TotalNumberOfItemsBySeverity} from '../../../incidents-v3/contexts/types/type-of-interfaces'
import * as Styled from './incidents-types-and-severities.styled'

export function IncidentsSeveritiesWidget({
    totalNumberOfItemsBySeverity,
}: {
    totalNumberOfItemsBySeverity: TotalNumberOfItemsBySeverity
}): JSX.Element {
    const {width} = useDimensions()
    const totalIncidentsScore =
        totalNumberOfItemsBySeverity.LOW +
            totalNumberOfItemsBySeverity.MEDIUM +
            totalNumberOfItemsBySeverity.HIGH +
            totalNumberOfItemsBySeverity.CRITICAL ?? 0
    return (
        <>
            <Styled.SeverityCard id="Severity-card-severity-lowIncidents">
                <Styled.SeverityText>Low severity</Styled.SeverityText>
                <Styled.SeverityCount id="lowIncidents-count">
                    {totalNumberOfItemsBySeverity.LOW ?? 0}
                </Styled.SeverityCount>
                <Styled.ColouredSeverityBar
                    severityLevel={IncidentSeverityValues.LOW}
                    severityScore={calculateScoreCard(
                        totalNumberOfItemsBySeverity.LOW,
                        totalIncidentsScore,
                    )}
                    width={width}
                />
            </Styled.SeverityCard>
            <Styled.SeverityCard id="filter-card-severity-mediumIncidents">
                <Styled.SeverityText>Medium severity</Styled.SeverityText>
                <Styled.SeverityCount id="mediumIncidents-count">
                    {totalNumberOfItemsBySeverity.MEDIUM ?? 0}
                </Styled.SeverityCount>
                <Styled.ColouredSeverityBar
                    severityLevel={IncidentSeverityValues.MEDIUM}
                    severityScore={calculateScoreCard(
                        totalNumberOfItemsBySeverity.MEDIUM,
                        totalIncidentsScore,
                    )}
                    width={width}
                />
            </Styled.SeverityCard>
            <Styled.SeverityCard id="Severity-card-severity-highIncidents">
                <Styled.SeverityText>High severity</Styled.SeverityText>
                <Styled.SeverityCount id="highIncidents-count">
                    {totalNumberOfItemsBySeverity.HIGH ?? 0}
                </Styled.SeverityCount>
                <Styled.ColouredSeverityBar
                    severityLevel={IncidentSeverityValues.HIGH}
                    severityScore={calculateScoreCard(
                        totalNumberOfItemsBySeverity.HIGH,
                        totalIncidentsScore,
                    )}
                    width={width}
                />
            </Styled.SeverityCard>
            <Styled.SeverityCard id="Severity-card-severity-criticalIncidents">
                <Styled.SeverityText>Critical severity</Styled.SeverityText>
                <Styled.SeverityCount id="criticalIncidents-count">
                    {totalNumberOfItemsBySeverity.CRITICAL ?? 0}
                </Styled.SeverityCount>
                <Styled.ColouredSeverityBar
                    severityLevel={IncidentSeverityValues.CRITICAL}
                    severityScore={calculateScoreCard(
                        totalNumberOfItemsBySeverity.CRITICAL,
                        totalIncidentsScore,
                    )}
                    width={width}
                />
            </Styled.SeverityCard>
        </>
    )
}
function calculateScoreCard(severityCount: number, totalIncidents: number): number {
    return totalIncidents === 0 ? 0 : Math.round((severityCount / totalIncidents) * 100)
}
