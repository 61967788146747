import {AssetOutputModel} from '../../../models/asset.model'
import {IncidentAsset} from './incident-asset'
import {AssetsListWrapper, NoAssetsText} from './_styled/assets-list.styled'
import _ from 'lodash'

interface Props {
    assets: AssetOutputModel[]
}

export function IncidentAssetsList({assets}: Props): JSX.Element {
    const filteredArray = _.remove(assets, (asset) => !!asset.assetId)
    if (filteredArray?.length === 0) {
        return <NoAssetsText>No assets</NoAssetsText>
    }
    return (
        <AssetsListWrapper id="assets-list-wrapper">
            {filteredArray?.map((asset, index) => (
                <IncidentAsset asset={asset} key={`${asset.incidentId}-${index}`} />
            ))}
        </AssetsListWrapper>
    )
}
