import {TableHeaderCell} from '../../common/table/table-header-cell.styled'

function TableHeader(): JSX.Element {
    return (
        <>
            <TableHeaderCell gridColumn={2}>Timestamp</TableHeaderCell>
            <TableHeaderCell gridColumn={3}>Indicator</TableHeaderCell>
            <TableHeaderCell gridColumn={4}>Mitre Tactic</TableHeaderCell>
            <TableHeaderCell gridColumn={5}>Mitre TTPS</TableHeaderCell>
            <TableHeaderCell gridColumn={6}>Action</TableHeaderCell>
        </>
    )
}

export default TableHeader
