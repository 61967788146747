import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../../theme/theme'
import {GridLayout} from '../../../../general/grid-layout.enum'

interface RiskScoreValueProps {
    gridLayout: GridLayout
    score: number
}
function getMarginTop(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '-29px'
        default:
            return '-41px'
    }
}

function getFontSize(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '20px'
        default:
            return '30px;'
    }
}

function getLineHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return '27px'
        default:
            return '41px;'
    }
}
function getFontWeight(gridLayout: GridLayout, theme: CyberOwlTheme): number {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return theme.font.weight.extrabold
        default:
            return theme.font.weight.semibold
    }
}
function getTransform(value: number): number {
    return value === 0 || value === 100 ? value : 50
}

export const RiskScoreValue = styled.div<RiskScoreValueProps>`
    position: absolute;
    margin-top: ${(props) => getMarginTop(props.gridLayout)};
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    font-weight: ${(props) => getFontWeight(props.gridLayout, props.theme)};
    left: ${(props) => `${props.score}%`};
    transform: ${(props) => `translate(-${getTransform(props.score)}%, 0)`};
`
