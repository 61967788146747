import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellIncusIp(): JSX.Element {
    const {data, initialData, displayData, changeIncusIp} = useVesselConfig()
    const changed = data.incusIp !== initialData.incusIp
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeIncusIp(e.target.value)
    }
    return (
        <DataInput
            label="Incus IP"
            initialValue={displayData.incusIp}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
