import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {mediumSmallFont, smallFont} from '../../../../../../theme/font-styles'

export const DetailsLayout = styled.div`
    padding: ${spacing(3)};
    margin: 0;
    width: 100%;
`

interface ScrollableWrapperProp {
    noHistory: boolean
}

export const ScrollableWrapper = styled.div<ScrollableWrapperProp>`
    height: ${(props) => (props.noHistory ? '70px' : 'fit-content')};
    max-height: 220px;
    overflow-y: auto;
`

export const Details = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-areas: 'WHEN WHAT';
    grid-row-gap: ${spacing(1)};
    grid-column-gap: ${spacing(2)};
    padding: ${spacing(1)};
`

export const NoHistory = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${spacing(4)};
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.text.default};
`

interface WhenProps {
    gridArea: string
}
function notesFont(): string {
    return `${smallFont()} letter-spacing: 0.24px;`
}

export const When = styled.div<WhenProps>`
    grid-area: ${(props) => props.gridArea};
    ${notesFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const What = styled.div<WhenProps>`
    grid-area: ${(props) => props.gridArea};
    ${notesFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`
