import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'
import {BREAK_POINT_REFRESH_SWITCH} from '../../../system-page-content.styled'

export function getGridTemplateColumns(width: number): string {
    if (width > BREAK_POINT_REFRESH_SWITCH) {
        return '0 auto 150px 150px 0'
    }
    return '0 auto 80px 50px 0'
}
interface TableHeaderGridProps {
    width: number
}
export const TableHeaderGrid = styled.div<TableHeaderGridProps>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    grid-template-rows: 48px;
    column-gap: ${spacing(2)};
    background-color: ${(props) => props.theme.systemPage.dataArea.background};
`

interface TableHeaderCellProps {
    gridColumn: number
    width: number
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.width > BREAK_POINT_REFRESH_SWITCH ? 'space-between' : 'center'};
`

export const TableHeaderEmailCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    display: flex;
    align-items: center;
    justify-content: space-between;
`
