import createCachedSelector from 're-reselect'
import {locationsSelector} from '../../../../store/state/locations/selectors'
import AppState from '../../../../store/types/app-state'

export const doesLocationExistReselector = createCachedSelector(
    [locationsSelector, (_state: AppState, locationCode: string) => locationCode],
    (locations, locationCode) => {
        return (
            locations?.find((location) => location.code.toLocaleLowerCase() === locationCode) !=
            undefined
        )
    },
)((_state, locationCode) => locationCode)
