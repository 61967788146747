import {ButtonsArea} from '../../policy-list-table/policy-details-modal/metrics-policy-details/buttons-area/buttons-area'
import {PolicyName} from '../../policy-list-table/policy-details-modal/metrics-policy-details/policy-name/policy-name'
import {TabSection} from '../../policy-list-table/policy-details-modal/metrics-policy-details/tab-section/tab-section'
import {DetailPanelHeader} from '../../shared/header/detail-panel-header'
import * as Styled from './add-new-metrics-policy-expanded.styled'

export function AddNewMetricsPolicyDetailExpanded(): JSX.Element {
    return (
        <Styled.DetailsExpandedSection>
            <DetailPanelHeader text="Add new metrics policy" />
            <Styled.PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea policyToCreate={true} />
            </Styled.PolicyNameWrapper>
            <Styled.ContentWrapper>
                <TabSection />
            </Styled.ContentWrapper>
        </Styled.DetailsExpandedSection>
    )
}
