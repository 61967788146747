import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {getGridTemplateColumns} from './metrics-table.styled'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

interface Props {
    showReducedColumnNo: boolean
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.showReducedColumnNo)};
    gap: ${spacing(2)};
    padding: ${spacing(2)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    background-color: ${(props) => props.theme.softwareInventory.dataArea.background};
`
interface TableDataRowProps {
    gridRow: number
    span?: number
    activeRow: boolean
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.softwareInventory.dataRow.text};
    background-color: ${(props) => props.theme.softwareInventory.dataRow.background};
    min-height: 64px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border: ${(props) =>
        props.activeRow && `3px solid  ${props.theme.incidents.dataRow.selectedRow}`};
`
interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    cursor: pointer;
`
