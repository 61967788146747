import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {GridLayout} from '../../grid-layout.enum'

interface ValueProps {
    gridLayout: GridLayout
}

function getFontSize(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '30px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '22px'
        default:
            return '30px'
    }
}

function getLineHeight(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '41px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '27px'
        default:
            return '41px'
    }
}

function getLineSpacing(gridLayout: GridLayout): string {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '0.6px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '0.4px'
        default:
            return '0.6px'
    }
}

function getFontWeight(gridLayout: GridLayout, theme: CyberOwlTheme): number {
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return theme.font.weight.semibold
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return theme.font.weight.extrabold
        default:
            return theme.font.weight.semibold
    }
}

export const Value = styled.span<ValueProps>`
    font-size: ${(props) => getFontSize(props.gridLayout)};
    line-height: ${(props) => getLineHeight(props.gridLayout)};
    letter-spacing: ${(props) => getLineSpacing(props.gridLayout)};
    font-weight: ${(props) => getFontWeight(props.gridLayout, props.theme)};
`
