import {DataLoading} from '../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {IncidentsSeveritiesWidget} from './incidents-severities-widget'
import {IncidentsTrendsWidget} from './incidents-trends-widget'
import * as Styled from './incidents-types-and-severities.styled'
import {IncidentsTypesWidget} from './incidents-types-widget'

export function IncidentsTypesAndSeverities(): JSX.Element {
    const {width} = useDimensions()
    const {
        loadedIncidentsCounts,
        loadedIncidentsTrends,
        showFilterBar,
        totalNumberOfItemsByTypes,
        totalNumberOfItemsBySeverity,
        incidentsTrendsBySeverity,
    } = usePagedIncidentsReport()
    const narrowLayoutForOpenFilter = showFilterBar === true
    return (
        <Styled.Section>
            <Styled.SectionText>Incident types and severities</Styled.SectionText>
            <Styled.SectionContent
                width={width}
                narrowLayoutForOpenFilter={narrowLayoutForOpenFilter}
            >
                <Styled.TypesContainer
                    width={width}
                    id="incidents-report-page-IncidentsTypes-section-container"
                >
                    <Styled.SectionText>
                        TYPES OF INCIDENTS RAISED IN REPORT PERIOD
                    </Styled.SectionText>
                    <IncidentsTypesWidget totalNumberOfItemsByTypes={totalNumberOfItemsByTypes} />
                </Styled.TypesContainer>
                <Styled.WrapperRightSide>
                    <Styled.SeverityContainer
                        width={width}
                        id="incidents-report-page-IncidentsSeverities-section-container"
                    >
                        <Styled.SectionText>
                            SEVERITY OF INCIDENTS RAISED IN REPORT PERIOD
                        </Styled.SectionText>
                        {loadedIncidentsCounts ? (
                            <IncidentsSeveritiesWidget
                                totalNumberOfItemsBySeverity={totalNumberOfItemsBySeverity}
                            />
                        ) : (
                            <DataLoading />
                        )}
                    </Styled.SeverityContainer>
                    <Styled.SeverityContainer
                        width={width}
                        id="incidents-report-page-IncidentsRaisedTrends-section-container"
                    >
                        <Styled.SectionText>RAISED INCIDENTS TREND</Styled.SectionText>
                        {loadedIncidentsTrends ? (
                            <IncidentsTrendsWidget
                                incidentsTrendsBySeverity={incidentsTrendsBySeverity}
                            />
                        ) : (
                            <DataLoading />
                        )}
                    </Styled.SeverityContainer>
                </Styled.WrapperRightSide>
            </Styled.SectionContent>
        </Styled.Section>
    )
}
