import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

export const DetailCell = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    min-width: 0;
`

export const Title = styled(DetailCell)`
    font-size: 16px;
    line-height: 24px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const AssetCount = styled(DetailCell)`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const AdditionalInfo = styled.div`
    margin-top: ${spacing(2)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const VesselTaggingRow = styled.div`
    display: flex;
`
export const VesselTaggsCell = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    gap: 5px;
    flex-grow: 1;
    min-width: 0;
`
export const SettingsIconCell = styled.div`
    white-space: nowrap;
`
interface VesselTagProps {
    gridArea: string
}

export const VesselTag = styled.div<VesselTagProps>`
    display: flex;
    font-size: 12px;
    grid-area: ${(props) => props.gridArea};
`
