import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`
interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`
interface TableHeaderTextProps {
    active?: boolean
}
export const TableHeaderText = styled.div<TableHeaderTextProps>`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: center;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
`
