import {Overlay} from './modal-overlay'
import {SetUsbPurpose} from './set-usb-purpose'

export function SetUsbPurposeModal(): JSX.Element {
    return (
        <Overlay>
            <SetUsbPurpose />
        </Overlay>
    )
}
