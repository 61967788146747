import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import {NodeValue, NodeValues} from '../../../../../values/nodes/NodeData'
import {Cell} from './asset-value-row.styled'
import {toggleValueThreshold} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {Checkbox} from '../../../../../components/form-elements/checkbox'
import {CheckboxType} from '../../../../../components/form-elements/checkbox-type'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface Props {
    nodeValue: NodeValue
}

function getLabelText(value: NodeValue): string {
    switch (value) {
        case NodeValues.HIGH:
            return 'High'
        case NodeValues.MEDIUM:
            return 'Medium'
        case NodeValues.LOW:
            return 'Low'
        default:
            return 'Unknown'
    }
}

function getType(value: NodeValue): CheckboxType {
    switch (value) {
        case NodeValues.HIGH:
            return CheckboxType.HIGH
        case NodeValues.MEDIUM:
            return CheckboxType.MEDIUM
        case NodeValues.LOW:
            return CheckboxType.LOW
        default:
            return CheckboxType.DEFAULT
    }
}

export function AssetValueRow({nodeValue}: Props): JSX.Element {
    const {assetValues} = useTypedSelector(metricsBetaFilterSelector)
    const dispatch = useDispatch()

    function isChecked(givenValue: NodeValue): boolean {
        try {
            return !assetValues ? true : assetValues.includes(givenValue)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error({error})
            return true
        }
    }

    function setChecked(currentSelectedAsset: NodeValue, newValue: boolean): void {
        dispatch(toggleValueThreshold(currentSelectedAsset, newValue))
        dispatch(
            logFilterByTypes(
                'Asset types',
                `Asset value: ${currentSelectedAsset}`,
                newValue,
                AuditLogPageType.METRICS,
            ),
        )
    }

    return (
        <Cell>
            <Checkbox
                id={`asset-filter-${nodeValue}-nominal`}
                label={`${getLabelText(nodeValue)} Value Assets`}
                type={getType(nodeValue)}
                checked={isChecked(nodeValue)}
                onChange={(newValue) => setChecked(nodeValue, newValue)}
            />
        </Cell>
    )
}
