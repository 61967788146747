import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const StatusFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(5)};
    justify-content: space-between;
    background-color: #fff;
`
export const SectionText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const TypesFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
`
export const WidgetIconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
