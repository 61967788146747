import {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {useOnClickOutside} from '../../../../../hooks/useOnClickOutside'
import {incidentTypesSelector} from '../../../../../store/state/incident-types/selectors'
import {setSelectedIncidentType} from '../../../../../store/state/incidents-filter/action-creators'
import {incidentFilterIncidentTypeSelector} from '../../../../../store/state/incidents-filter/selectors'
import {getTypeDisplayName} from '../../../contexts/types/incident-type'
import * as Styles from './incident-type.styled'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {getReportType} from '../../../../../utils/Utils'

export function IncidentType(): JSX.Element | null {
    const dispatch = useDispatch()

    const incidentTypes = useTypedSelector(incidentTypesSelector)
    const incidentFilterIncidentType = useTypedSelector(incidentFilterIncidentTypeSelector)
    const [optionsVisible, setOptionsVisible] = useState(false)

    function onChangeWrapper(value: string): void {
        dispatch(setSelectedIncidentType(value))
        dispatch(
            logFilterByTypes(
                'incidentType',
                getReportType(value),
                true,
                AuditLogPageType.INCIDENTS,
            ),
        )
    }
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    if (incidentTypes?.length === 0) {
        return null
    }

    return (
        <Styles.Wrapper ref={clickOutside} id="incidentType-filter-wrapper">
            <Styles.Label>Incident Type:</Styles.Label>
            <Styles.Input>
                <Styles.WrapperOption onClick={() => setOptionsVisible(true)} id="filter-option">
                    {!incidentFilterIncidentType
                        ? '- All -'
                        : getTypeDisplayName(incidentTypes, incidentFilterIncidentType)}
                </Styles.WrapperOption>
                {optionsVisible && (
                    <Styles.ValueOptions>
                        <Styles.ValueOption
                            key="default-option"
                            onClick={() => {
                                onChangeWrapper('')
                                setOptionsVisible(false)
                            }}
                            id="incidentType-filter-default-option"
                        >
                            - All -
                        </Styles.ValueOption>
                        {incidentTypes?.map((value, index) => (
                            <Styles.ValueOption
                                key={index}
                                onClick={() => {
                                    onChangeWrapper(value.name)
                                    setOptionsVisible(false)
                                }}
                                id={`incidentType-filter-${value.name}-option`}
                            >
                                {value.displayName}
                            </Styles.ValueOption>
                        ))}
                    </Styles.ValueOptions>
                )}
            </Styles.Input>
        </Styles.Wrapper>
    )
}
