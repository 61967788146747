import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface ItemRowProps {
    watched: boolean
}

export const ItemRow = styled.div<ItemRowProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${mediumSmallFont()};
    color: ${(props) => props.theme.colors.text.default};
    margin-bottom: 5px;
    gap: 5px;
    cursor: pointer;
    position: relative;
    ${(props) =>
        props.watched &&
        `text-decoration: underline; font-weight: ${props.theme.font.weight.semibold};`}
`

export const NoItemsText = styled.div`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    font-style: italic;
`

interface ItemListWrapperProps {
    scrollable: boolean
}

export const ItemListWrapper = styled.div<ItemListWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    ${(props) =>
        props.scrollable &&
        'height: fit-content; max-height: 68px; overflow-x: hidden; overflow-y: auto;'}
`
