import {GuidType} from '../../../../../values/generic-type-defintions'
import {MetricsPolicy} from '../type/metrics-policy'
import {MetricsSchema, OnboardEngagementConfigType} from '../type/metrics-policy-schema'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function requestMetricsPolicyData(): Actions.RequestMetricsDataAction {
    return {type: ActionType.REQUEST_POLICY_DATA_ACTION}
}

export function receivedRequestedMetricsPolicyData(
    metricPolicyIdentity: GuidType,
    receivedPolicy: MetricsPolicy,
): Actions.ReceivedRequestedMetricsDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_POLICY_DATA_ACTION,
        payload: {metricPolicyIdentity, receivedPolicy},
    }
}

export function receivedMetricsSchema(
    receivedMetricsSchema: MetricsSchema[],
): Actions.ReceivedMetricsSchemaAction {
    return {
        type: ActionType.RECEIVED_METRICS_SCHEMA_ACTION,
        payload: {receivedMetricsSchema},
    }
}

export function setChangePolicyName(
    newName: string,
    isValueChanged: boolean,
): Actions.SetChangePolicyNameAction {
    return {type: ActionType.SET_CHANGE_POLICY_NAME, payload: {newName, isValueChanged}}
}
export function setDefaultPolicyData(): Actions.SetDefaultPolicyValuesAction {
    return {type: ActionType.SET_DEFAULT_POLICY_VALUES}
}

export function setChangeTargetedAssetsValue(
    metricConfigId: GuidType,
    targetAssets: number,
    isValueChanged: boolean,
): Actions.SetChangeTargetedAssetsValueAction {
    return {
        type: ActionType.SET_CHANGE_TARGETED_ASSETS_VALUE,
        payload: {metricConfigId, targetAssets, isValueChanged},
    }
}

export function setChangeEnabledValue(
    metricConfigId: GuidType,
    enabled: boolean,
    isValueChanged: boolean,
): Actions.SetChangeEnabledValueAction {
    return {
        type: ActionType.SET_CHANGE_METRIC_ENABLED_VALUE,
        payload: {metricConfigId, enabled, isValueChanged},
    }
}

export function setChangeEnabledSubMetricValue(
    metricConfigId: GuidType,
    configName: string,
    enabled: boolean,
    isValueChanged: boolean,
): Actions.SetChangeEnabledSubMetricValueAction {
    return {
        type: ActionType.SET_CHANGE_SUBMETRIC_ENABLED_VALUE,
        payload: {metricConfigId, configName, enabled, isValueChanged},
    }
}

export function setChangeDropDownValue(
    metricConfigId: GuidType,
    configName: string,
    selectedValue: string,
    isValueChanged: boolean,
): Actions.SetChangeDropDownValueAction {
    return {
        type: ActionType.SET_CHANGE_DROP_DOWN_CONFIG_VALUE,
        payload: {metricConfigId, configName, selectedValue, isValueChanged},
    }
}
export function setChangeOnboardValue(
    metricConfigId: GuidType,
    configName: string,
    selectedValue: OnboardEngagementConfigType,
    isValueChanged: boolean,
): Actions.SetChangeOnboardValueAction {
    return {
        type: ActionType.SET_CHANGE_ONBOARD_CONFIG_VALUE,
        payload: {metricConfigId, configName, selectedValue, isValueChanged},
    }
}
export function setChangeNumericValue(
    metricConfigId: GuidType,
    configName: string,
    selectedValue: number,
    isValueChanged: boolean,
): Actions.SetChangeNumericValueAction {
    return {
        type: ActionType.SET_CHANGE_NUMERIC_CONFIG_VALUE,
        payload: {metricConfigId, configName, selectedValue, isValueChanged},
    }
}
export function setChangeTextValue(
    metricConfigId: GuidType,
    configName: string,
    selectedValue: string,
    isValueChanged: boolean,
): Actions.SetChangeTextValueAction {
    return {
        type: ActionType.SET_CHANGE_TEXT_CONFIG_VALUE,
        payload: {metricConfigId, configName, selectedValue, isValueChanged},
    }
}

export function requestDiscardChanges(discardChanges: boolean): Actions.SetDiscardChangesAction {
    return {type: ActionType.SET_DISCARD_CHANGES, payload: discardChanges}
}

export function setPolicyAfterUpdate(
    receivedPolicy: MetricsPolicy,
): Actions.SetPolicyToUpdateAction {
    return {
        type: ActionType.SET_POLICY_AFTER_UPDATE,
        payload: {receivedPolicy},
    }
}
