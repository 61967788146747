import {
    findMetricCategory,
    getMetricTypeIcon,
} from '../../../../metrics-beta/components/data-helper'
import {MetricType} from '../../../../metrics-beta/contexts/types/metrics-response'
import * as Styled from './score-bar.styled'

interface ScoreBarProps {
    score: number
    metricType: MetricType
    displayGraph: boolean
    identifier: string
    summaryView?: boolean
}
export function ScoreBar({
    score,
    metricType,
    displayGraph,
    identifier,
    summaryView,
}: ScoreBarProps): JSX.Element {
    return (
        <>
            {displayGraph && (
                <Styled.ScoreBarWrapper
                    id={`scoreBar-Wrapper-${metricType}-${score}-${identifier}-${identifier}`}
                >
                    <Styled.ColorWrapper
                        metricType={metricType}
                        id={`color-Wrapper-${metricType}-${score}-${identifier}`}
                    >
                        <Styled.MetricIcon
                            src={getMetricTypeIcon(findMetricCategory(metricType))}
                            summaryView={summaryView}
                            id={`metricTypeIcon-${metricType}-${score}-${identifier}`}
                        />
                        <Styled.Score id={`score-${metricType}-${score}-${identifier}`}>
                            {score}
                            <Styled.ScoreTextWrapper>
                                <div style={{fontSize: '12px'}}>%</div>
                            </Styled.ScoreTextWrapper>
                        </Styled.Score>
                    </Styled.ColorWrapper>
                    <Styled.ScoreBar>
                        <Styled.ScoreBarNoIssue
                            score={score}
                            metricType={metricType}
                            id={`score-bar-no-issues-${metricType}-${score}-${identifier}`}
                        />
                    </Styled.ScoreBar>
                </Styled.ScoreBarWrapper>
            )}
        </>
    )
}
