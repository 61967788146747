import {AnalysisType, TimestampFilterType} from '../../../store/state/metrics-filter-beta/state'

import {
    AssetStatusType,
    BenchmarkType,
    MetricSummary,
    TargetType,
    MetricTrendsArray,
    WorstPerformingLocationsArray,
} from './types/metrics-summary'

// Define a helper function to calculate the score based on AnalysisType
export function calculateScoreForCards(value: MetricSummary, analysisTypes: AnalysisType): number {
    switch (analysisTypes) {
        case AnalysisType.SCORECARD:
            return calculateScoreCardScore(value.assetStatus)
        case AnalysisType.BENCHMARK:
            return calculateBechmarkScore(value.benchmark)
        case AnalysisType.TARGET:
            return calculateTargetScore(value.target)
        case AnalysisType.TRENDS:
            return calculateTrendsScore(value.trends)
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return calculateWorstVesselScore(value.worstPerformingLocations)
        default:
            return 0
    }
}

export function calculateScoreCardScore(assetStatus: AssetStatusType): number {
    const totalAssetsOk = assetStatus?.totalAssetsOk ?? 0
    const totalAssetsWithIssue = assetStatus?.totalAssetsWithIssue ?? 0
    const totalNumberOfAssets = totalAssetsOk + totalAssetsWithIssue
    return totalNumberOfAssets === 0 ? 0 : Math.round((totalAssetsOk / totalNumberOfAssets) * 100)
}

export function calculateBechmarkScore(benchmarkData: BenchmarkType): number {
    const selectedVesselsData = benchmarkData?.selectedVessels
    const selectedVesselsTotalAssetsOk = selectedVesselsData?.totalAssetsOk ?? 0
    const selectedVesselsTotalAssetsWithIssue = selectedVesselsData?.totalAssetsWithIssue ?? 0
    const totalAssets = selectedVesselsTotalAssetsOk + selectedVesselsTotalAssetsWithIssue
    return totalAssets === 0 ? 0 : Math.round((selectedVesselsTotalAssetsOk / totalAssets) * 100)
}

export function calculateTargetScore(benchmarkData: TargetType): number {
    const totalVesselsBellowTarget = benchmarkData?.belowTarget ?? 0
    return totalVesselsBellowTarget === 0 ? 0 : -totalVesselsBellowTarget
}

export function calculateWorstVesselScore(locationsArray: WorstPerformingLocationsArray[]): number {
    if (!locationsArray) {
        return 0
    }
    const scores: number[] = locationsArray.map((locationItem) => {
        return isNaN(locationItem.count / locationItem.total)
            ? 0
            : Math.round(((locationItem.total - locationItem.count) / locationItem.total) * 100)
    })

    return Math.min(...scores)
}
export function calculateTrendsScore(trendsArray: MetricTrendsArray[]): number {
    if (!trendsArray) {
        return 0
    }
    const lastItem = trendsArray[trendsArray.length - 1]
    const score =
        (lastItem.totalAssetsOk / (lastItem.totalAssetsOk + lastItem.totalAssetsWithIssue)) * 100

    return isNaN(score) ? 0 : Math.round(score)
}
// Function to get start and end date based on the period
export function getStartAndEndDate(period: TimestampFilterType) {
    const endDate = new Date()
    const startDate = new Date(endDate)

    switch (period) {
        case 'latest':
            break
        case '2h':
            startDate.setHours(endDate.getHours() - 2)
            break
        case '1d':
            startDate.setHours(endDate.getHours() - 24)
            break
        case '7d':
            startDate.setDate(endDate.getDate() - 7)
            break
        case '30d':
            startDate.setDate(endDate.getDate() - 30)
            break
        case '14d':
            startDate.setDate(endDate.getDate() - 14)
            break
        case '71d':
            startDate.setDate(endDate.getDate() - 71)
            break
        case '365d':
            startDate.setFullYear(endDate.getFullYear() - 1)
            break
        default:
            break
    }

    return {startDate, endDate}
}
