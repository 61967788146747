export enum ActionType {
    REQUEST_POLICY_DATA_ACTION = '[METRICS POLICY CONTEXT] REQUEST POLICY DATA ACTION',
    RECEIVED_REQUESTED_POLICY_DATA_ACTION = '[METRICS POLICY CONTEXT] RECEIVED REQUESTED POLICY DATA ACTION',
    RECEIVED_METRICS_SCHEMA_ACTION = '[METRICS POLICY CONTEXT] RECEIVED METRICS SCHEMA ACTION',
    SET_CHANGE_POLICY_NAME = '[METRICS POLICY CONTEXT] SET CHANGE POLICY NAME',
    SET_DEFAULT_POLICY_VALUES = '[METRICS POLICY CONTEXT] SET DEFAULT POLICY VALUES',
    SET_CHANGE_TARGETED_ASSETS_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE TARGETED ASSETS VALUE',
    SET_CHANGE_METRIC_ENABLED_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE METRIC ENABLED VALUE',
    SET_CHANGE_SUBMETRIC_ENABLED_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE SUB METRIC ENABLED VALUE',
    SET_CHANGE_DROP_DOWN_CONFIG_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE DROP DOWN CONFIG VALUE',
    SET_CHANGE_ONBOARD_CONFIG_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE ONBOARD CONFIG VALUE',
    SET_CHANGE_TEXT_CONFIG_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE TEXT CONFIG VALUE',
    SET_CHANGE_NUMERIC_CONFIG_VALUE = '[METRICS POLICY CONTEXT] SET CHANGE NUMERIC CONFIG VALUE',
    SET_DISCARD_CHANGES = '[METRICS POLICY CONTEXT] SET DISCARD CHANGES',
    SET_POLICY_AFTER_UPDATE = '[METRICS POLICY CONTEXT] SET POLICY AFTER UPDATE',
}
