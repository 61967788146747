import * as Styled from './submit-area.styled'
import {CancelButton} from './cancel-button'
import {AddButton} from './add-button'
import {CloseButton} from './close-button'

interface SubmitAreaProps {
    closeOnly?: boolean
    blocked?: boolean
}

export function SubmitArea({closeOnly, blocked}: SubmitAreaProps): JSX.Element {
    return (
        <Styled.ButtonsArea>
            {closeOnly ? (
                <CloseButton />
            ) : (
                <>
                    <CancelButton />
                    <AddButton blocked={blocked} />
                </>
            )}
        </Styled.ButtonsArea>
    )
}
