import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {WidgetWrapper} from './widget-wrapper'
import {NoDataContent} from '../../general/content-area/no-data/no-data-content'

export function NoData(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    return (
        <WidgetWrapper gridLayout={gridLayout} noData={true}>
            <NoDataContent text="No network assets as of" />
        </WidgetWrapper>
    )
}
