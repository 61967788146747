export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED INCIDENTS CONTEXT] SET SELECTED PAGE',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED INCIDENTS CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED INCIDENTS CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_PAGE_DATA_ACTION = '[PAGED INCIDENTS CONTEXT] RECEIVED REQUESTED PAGE DATA ACTION',
    RESET_INCIDENTS_RESPONSES = '[PAGED INCIDENTS CONTEXT] RESET INCIDENTS RESPONSES',
    SET_FILTER = '[PAGED INCIDENTS CONTEXT] SET FILTER',
    UPDATE_INCIDENT_STATUS = '[PAGED INCIDENTS CONTEXT] UPDATE_INCIDENT_STATUS',
    SET_SAVE_RESULT_MESSAGE = '[PAGED INCIDENTS CONTEXT] SET SAVE RESULT MESSAGE',
    SET_EDITABLE_COMMENT = '[PAGED INCIDENTS CONTEXT] SET EDITABLE COMMENT',
    DISPLAY_INCIDENT_DETAILS_MODAL = '[PAGED INCIDENTS CONTEXT] DISPLAY INCIDENT DETAILS MODAL',
    SWITCH_TO_CACHED_DATA_MODAL = '[PAGED INCIDENTS CONTEXT] SWITCH TO CACHED DATA MODAL',
    CLOSE_INCIDENT_DETAILS_MODAL = '[PAGED INCIDENTS CONTEXT] CLOSE INCIDENT DETAILS MODAL',
    DISPLAY_FILTER_BAR = '[PAGED INCIDENTS CONTEXT] DISPLAY FILTER BAR',
    RECEIVED_GUEST_EMAILS = '[PAGED INCIDENTS CONTEXT] RECEIVED GUEST EMAILS',
    RECEIVED_REQUESTED_INCIDENT = '[PAGED INCIDENTS CONTEXT] RECEIVED REQUESTED INCIDENT',
    REQUEST_LINKED_ITEMS = '[CORE/ITEMS] REQUEST LINKED ITEMS',
    RECEIVE_LINKED_ITEMS = '[CORE/ITEMS] RECEIVE LINKED ITEMS',
    INITIALISE_LINKED_ITEMS_DATA = '[CORE/ITEMS] INITIALISE LINKED ITEMS DATA',
    DISPLAY_CLOSE_MONITOR_INCIDENT_MODAL = '[CORE/ITEMS] DISPLAY CLOSE MONITOR INCIDENT MODAL',
    SET_MONITORED_ITEM = '[PAGED INCIDENTS CONTEXT] SET MONITORED ITEM',
    SET_SAVE_CLOSE_MONITOR_PROCESSING_STATUS = '[PAGED INCIDENTS CONTEXT] SET CLOSE MONITOR SAVE PROCESSING STATUS',
    OPEN_INCIDENT_MANAGEMENT_MODEL = '[PAGED INCIDENTS CONTEXT] SET OPEN INCIDENT MANAGEMENT MODEL',
}
