import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {ThreatValue, ThreatValues} from '../../../../../helpers/threatHelper'

function getVesselThreatScoreBackGroundColor(
    theme: CyberOwlTheme,
    threatLevel: ThreatValue,
): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.background
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.background

        default:
            return theme.threatScores.score.low.background
    }
}

function getVesselThreatScoreColor(theme: CyberOwlTheme, threatLevel: ThreatValue): string {
    switch (threatLevel) {
        case ThreatValues.HIGH:
            return theme.threatScores.score.high.text
        case ThreatValues.MEDIUM:
            return theme.threatScores.score.medium.text

        default:
            return theme.threatScores.score.low.text
    }
}
interface ScoreProps {
    theme: CyberOwlTheme
    score: number
    threatLevel: ThreatValue
}
export const VesselThreatScore = styled.div<ScoreProps>`
    border-radius: 3px;
    height: 40px;
    width: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.4;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    color: ${(props) => getVesselThreatScoreColor(props.theme, props.threatLevel)};
    background-color: ${(props) =>
        getVesselThreatScoreBackGroundColor(props.theme, props.threatLevel)};
`
