import {CardArea} from './card-area.styled'
import {DataLoading} from '../data-loading'

export function LoadingData(): JSX.Element {
    return (
        <CardArea>
            <DataLoading forceHeight />
        </CardArea>
    )
}
