import * as Styles from './_styled/metrics-details.styled'
import {DetailPanelHeader} from './header/detail-panel-header'
import {MetricsDetailsSection} from './metrics-details/metrics-details'
import {ActivitySection} from './activity/activity-section'
import {AssetsAffectedSection} from './assets-afected/assets-affected'

export function MetricsModalContent(): JSX.Element {
    return (
        <Styles.Container onClick={(e) => e.stopPropagation()}>
            <DetailPanelHeader />
            <MetricsDetailsSection />
            <ActivitySection />
            <AssetsAffectedSection />
        </Styles.Container>
    )
}
