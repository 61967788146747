import TableHeader from './table-header'
import {TableHeaderGrid} from './_styled/table-header-grid.styled'

export function TableHeaderRow(): JSX.Element {
    return (
        <TableHeaderGrid>
            <TableHeader />
        </TableHeaderGrid>
    )
}
