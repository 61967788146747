import {FilterBarButton} from '../../../../components/filter-button/filter-button'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {activeVesselFilterSelector} from '../../../../store/state/vessel-filter/selectors'
import {usePagedVesselsBeta} from '../../contexts/use-paged-vessels-beta'

import {ContentWrapper} from './data-actions.styled'

export function FilterBarButtonWrapper(): JSX.Element {
    const {width} = useDimensions()
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)
    const {showFilterBar, displayFilterBar} = usePagedVesselsBeta()

    return (
        <ContentWrapper width={width} id="FilterBarButtonWrapper">
            <FilterBarButton
                onClick={() => displayFilterBar(!showFilterBar)}
                //to be fixed when filter is ready
                active={activeVesselFilter}
            />
        </ContentWrapper>
    )
}
