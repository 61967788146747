import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {getGridTemplateColumns} from '../_styled/table-data-grid.styled'

interface Props {
    width: number
    showReducedGrid: boolean
}
export const TableHeaderGrid = styled.div<Props>`
    display: grid;
    grid-template-rows: 40px;
    column-gap: ${spacing(2)};
    padding: 0 ${spacing(1)};
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
`
