import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    height: 25px;
    width: 25px;
    background-color: ${(props) => props.theme.myVessels.zoomLevel.unselected.background};
    &:hover {
        background-color: ${(props) => props.theme.myVessels.zoomLevel.unselected.hoverBackground};
    }
`

export const Selected = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.myVessels.zoomLevel.selected.text};
    background-color: ${(props) => props.theme.myVessels.zoomLevel.selected.background};
    height: 25px;
    width: 25px;
`

export const Text = styled.span`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    letter-spacing: 0.28px;
`
