import * as ActionCreators from './state/action-creators'
import {VesselConfigContext} from './vessel-config-context'
import {UseVesselConfigResult} from './use-vessel-config-result'
import {Dispatch, useContext} from 'react'
import {FormattedVesselConfig} from './types/formatted-vessel-config'
import {UpdateVesselConfigAction} from '../../../../store/state/locations/actions'
import {FixedValueState} from './types/vessel-config-state'

export function useVesselConfig(): UseVesselConfigResult {
    const {state, dispatch} = useContext(VesselConfigContext)

    if (state == undefined || dispatch == undefined) {
        throw new Error('useVesselConfig must be used within a VesselConfigContext')
    }

    function changeType(value: string): void {
        dispatch(ActionCreators.setChangeType(value))
    }
    function changeCustomerLocationCode(value: string): void {
        dispatch(ActionCreators.setChangeCustomerLocationCode(value))
    }
    function changeBusinessNetworks(value: string[]): void {
        dispatch(ActionCreators.setChangeBusinessNetworks(value))
    }
    function changeOtNetworks(value: string[]): void {
        dispatch(ActionCreators.setChangeOtNetworks(value))
    }
    function changeCrewNetworks(value: string[]): void {
        dispatch(ActionCreators.setChangeCrewNetworks(value))
    }
    function changeGatewayIp(value: string): void {
        dispatch(ActionCreators.setChangeGatewayIp(value))
    }
    function changeGatewayMac(value: string): void {
        dispatch(ActionCreators.setChangeGatewayMac(value))
    }
    function changeIncusIp(value: string): void {
        dispatch(ActionCreators.setChangeIncusIp(value))
    }
    function changeIncusMac(value: string): void {
        dispatch(ActionCreators.setChangeIncusMac(value))
    }
    function changeSensorIp(value: string): void {
        dispatch(ActionCreators.setChangeSensorIp(value))
    }
    function changeSensorMac(value: string): void {
        dispatch(ActionCreators.setChangeSensorMac(value))
    }
    function changeOtDevices(value: string[]): void {
        dispatch(ActionCreators.setChangeOtDevices(value))
    }
    function changeOtCrossings(value: string[]): void {
        dispatch(ActionCreators.setChangeOtCrossings(value))
    }
    function changeBusinessDevices(value: string[]): void {
        dispatch(ActionCreators.setChangeBusinessDevices(value))
    }
    function changeBusinessCrossings(value: string[]): void {
        dispatch(ActionCreators.setChangeBusinessCrossings(value))
    }
    function changeEmailHosts(value: string[]): void {
        dispatch(ActionCreators.setChangeEmailHosts(value))
    }
    function changeHostLogExceptions(value: string[]): void {
        dispatch(ActionCreators.setChangeHostLogExceptions(value))
    }
    function changeNetworkLogExceptions(value: string[]): void {
        dispatch(ActionCreators.setChangeNetworkLogExceptions(value))
    }
    function changeUsbHosts(value: string[]): void {
        dispatch(ActionCreators.setChangeUsbHosts(value))
    }
    function changeUsbDevices(value: string[]): void {
        dispatch(ActionCreators.setChangeUsbDevices(value))
    }
    function changeAdminLogons(value: string[]): void {
        dispatch(ActionCreators.setChangeAdminLogons(value))
    }
    function changeRemoteAccess(value: string[]): void {
        dispatch(ActionCreators.setChangeRemoteAccess(value))
    }
    function changeEncryptionRatio(value: string): void {
        dispatch(ActionCreators.setChangeEncryptionRatio(value))
    }
    function changeMonitoringCoverage(value: string): void {
        dispatch(ActionCreators.setChangeMonitoringCoverage(value))
    }
    function changeMaxOSAge(value: string): void {
        dispatch(ActionCreators.setChangeMaxOSAge(value))
    }
    function changeMaxScore(value: number): void {
        dispatch(ActionCreators.setChangeMaxScore(value))
    }
    function saveConfigChanges(
        fixedValue: FixedValueState,
        value: FormattedVesselConfig,
        reduxDispatch: Dispatch<UpdateVesselConfigAction>,
    ): void {
        ActionCreators.saveConfig(dispatch, fixedValue, value, reduxDispatch)
    }
    function resetChanges(): void {
        dispatch(ActionCreators.resetChanges())
    }
    function bulkAssignConfig(value: FormattedVesselConfig): void {
        dispatch(ActionCreators.bulkAssignConfig(value))
    }

    return {
        fixedValue: state.fixedValue,
        data: state.data,
        initialData: state.initialData,
        displayData: state.displayData,
        isChanged: state.isChanged,
        saveResultMessage: state.saveResultMessage,
        changeType,
        changeCustomerLocationCode,
        changeBusinessNetworks,
        changeOtNetworks,
        changeCrewNetworks,
        changeGatewayIp,
        changeGatewayMac,
        changeIncusIp,
        changeIncusMac,
        changeSensorIp,
        changeSensorMac,
        changeOtDevices,
        changeOtCrossings,
        changeBusinessDevices,
        changeBusinessCrossings,
        changeEmailHosts,
        changeHostLogExceptions,
        changeNetworkLogExceptions,
        changeUsbHosts,
        changeUsbDevices,
        changeAdminLogons,
        changeRemoteAccess,
        changeEncryptionRatio,
        changeMonitoringCoverage,
        changeMaxOSAge,
        changeMaxScore,
        saveConfigChanges,
        resetChanges,
        bulkAssignConfig,
    }
}
