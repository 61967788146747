import {useRef} from 'react'
import {TableDataGrid} from './_styled/table-data-grid.styled'
import TableRow from './table-row'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usePagedIncidents} from '../../contexts/use-paged-incidents'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {NoData} from './no-data/no-data'
import {IncidentDetailsModal} from './incident-details-modal/incident-details-modal'
import {useOnClickOutside} from '../../../../hooks/useOnClickOutside'
import {isNoteCreationSelector} from '../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {useDispatch} from 'react-redux'
import {
    displayModal,
    setPendingNavigation,
} from '../../../../store/state/unsaved-incident-note-alert-modal/action-creators'

export function IncidentTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataIncidents, totalNumberOfFilteredIncidents, incidentModalId, showFilterBar} =
        usePagedIncidents()

    const displayIncidentModal = incidentModalId != null
    const noteCreationStatus = useTypedSelector(isNoteCreationSelector)
    const dispatch = useDispatch()

    const clickOutside = useRef<HTMLDivElement>(null)
    const handleOutsideClick = (event: Event) => {
        if (noteCreationStatus) {
            dispatch(displayModal())
            // Store the intended navigation path
            const path = (event.target as HTMLElement).closest('a')?.getAttribute('href')
            if (path) {
                dispatch(setPendingNavigation(path))
            }
        }
    }
    useOnClickOutside(clickOutside, handleOutsideClick)

    if (!dataIncidents || totalNumberOfFilteredIncidents === 0) {
        return <NoData text="There are no incidents matching your filtering criteria" />
    }
    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}} ref={clickOutside}>
            <TableDataGrid
                width={width}
                showDetailsBtn={!displayIncidentModal && !showFilterBar}
                id="table-data-grid-style"
            >
                {dataIncidents?.map((incident, index) => (
                    <TableRow
                        key={`${incident.identity}`}
                        incident={incident}
                        index={index}
                        showDetailsBtn={!displayIncidentModal}
                    />
                ))}
            </TableDataGrid>
            {displayIncidentModal && <IncidentDetailsModal />}
        </div>
    )
}
