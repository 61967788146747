import useTypedSelector from '../../../../../hooks/use-typed-selector'

import {ValueForType} from '../../general/content-area/headline-values/value-for-type'
import {GridLayout} from '../../general/grid-layout.enum'
import {countAssetAtRiskReselector} from '../reselect/count-asset-at-risk.reselector'
interface VesselsCountProps {
    gridLayout: GridLayout
}

export function AssetsAtRiskCount({gridLayout}: VesselsCountProps): JSX.Element {
    const myAssetsAtRisk = useTypedSelector(countAssetAtRiskReselector)
    return (
        <ValueForType
            type="Assets at Risk"
            value={myAssetsAtRisk}
            gridLayout={gridLayout}
            id="vessels-at-risk-assets-risk-count"
        />
    )
}
