import produce from 'immer'
import {Action} from './actions'
import {ActionType} from './action-type'
import {SystemAssessmentModal} from '../../location-inventory-page-context'
import LoadingState from '../../../../../values/loading-state-enum'

export const SystemAssessmentModalReducer = produce(
    (draft: SystemAssessmentModal['state'], action: Action) => {
        switch (action.type) {
            case ActionType.TOGGLE_MODAL:
                draft.isOpen = action.payload.isOpen
                draft.systemID = action.payload.systemID
                draft.submissionState = LoadingState.NotPopulated
                break
            case ActionType.SUBMIT_ERROR:
                draft.error = action.payload.error
                draft.submissionState = LoadingState.Errored
                break
            case ActionType.SUBMIT_SUCCESS:
                draft.submissionState = LoadingState.Loaded
                break
            case ActionType.SUBMIT:
                draft.submissionState = LoadingState.RequestingData
                break
            default:
                break
        }
    },
)
