import {IncidentsReportFilter} from '../../../../store/state/incidents-report-filter/state'
import {MetricsBetaFilter} from '../../../../store/state/metrics-filter-beta/state'
import {
    AllSavedFilterCriteriaType,
    SavedFilterPageType,
} from '../../../../store/state/saved-filters/state'
import {IncidentsFilterCriteria} from '../../../../values/user-preferences/incidents-filter'
import {NetworkAssetsFilterCriteria} from '../../../../values/user-preferences/network-assets-filter'
import {ReportsFilterCriteria} from '../../../../values/user-preferences/reports-filter'
import {SoftwareInventoryFilterCriteria} from '../../../../values/user-preferences/software-inventory-filter'
import {UsbInventoryFilterCriteria} from '../../../../values/user-preferences/usb-inventory-filter'
import {TooltipContainer} from '../_styled/detail-tooltip.styled'
import {DetailFilterIncidents} from '../shared/detail-filter-incidents'
import {DetailFilterIncidentsReport} from '../shared/detail-filter-incidents-report'
import {DetailFilterMetricsBeta} from '../shared/detail-filter-metrics-beta'
import {DetailFilterNetworkAssets} from '../shared/detail-filter-network-assets'
import {DetailFilterReports} from '../shared/detail-filter-reports'
import {DetailFilterSoftwareInventory} from '../shared/detail-filter-software-inventory'
import {DetailFilterUsbInventory} from '../shared/detail-filter-usb-inventory'
import {
    getFormattedIncidentsFilter,
    getFormattedIncidentsReportFilter,
    getFormattedMetricsBetaFilter,
    getFormattedReportsFilter,
    getFormattedSoftwareInventoryFilter,
    getFormattedUsbInventoryFilter,
} from '../shared/helper'

interface DetailTooltipProps {
    pageType: SavedFilterPageType
    criteria: AllSavedFilterCriteriaType
    lastRow: boolean
}

export function DetailTooltip({pageType, criteria, lastRow}: DetailTooltipProps): JSX.Element {
    switch (pageType) {
        case SavedFilterPageType.INCIDENTS:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterIncidents
                        criteria={getFormattedIncidentsFilter(criteria as IncidentsFilterCriteria)}
                        tooltip
                    />
                </TooltipContainer>
            )

        case SavedFilterPageType.METRICS:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterMetricsBeta
                        criteria={getFormattedMetricsBetaFilter(criteria as MetricsBetaFilter)}
                        tooltip
                    />
                </TooltipContainer>
            )
        case SavedFilterPageType.NETWORK_ASSETS:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterNetworkAssets
                        criteria={criteria as NetworkAssetsFilterCriteria}
                        tooltip
                    />
                </TooltipContainer>
            )
        case SavedFilterPageType.USB_INVENTORY:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterUsbInventory
                        criteria={getFormattedUsbInventoryFilter(
                            criteria as UsbInventoryFilterCriteria,
                        )}
                        tooltip
                    />
                </TooltipContainer>
            )
        case SavedFilterPageType.SOFTWARE_INVENTORY:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterSoftwareInventory
                        criteria={getFormattedSoftwareInventoryFilter(
                            criteria as SoftwareInventoryFilterCriteria,
                        )}
                        tooltip
                    />
                </TooltipContainer>
            )
        case SavedFilterPageType.REPORTS:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterReports
                        criteria={getFormattedReportsFilter(criteria as ReportsFilterCriteria)}
                        tooltip
                    />
                </TooltipContainer>
            )
        case SavedFilterPageType.INCIDENTS_REPORT:
            return (
                <TooltipContainer lastRow={lastRow}>
                    <DetailFilterIncidentsReport
                        criteria={getFormattedIncidentsReportFilter(
                            criteria as IncidentsReportFilter,
                        )}
                        tooltip
                    />
                </TooltipContainer>
            )
    }
}
