import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'
import {spacing} from '../../../../../../theme/spacing'

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    selectable: boolean
    centered?: boolean
    noWrap?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.noWrap ? 'wrap' : 'nowrap')};
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${(props) => (props.selectable ? 'cursor: pointer' : '')};
    ${mediumSmallFont()}
`

export const LinkText = styled.span`
    padding-left: ${spacing(1)};
    ${mediumSmallFont()};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
