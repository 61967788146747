import {useEffect, useState} from 'react'
import {Checkbox} from '../../../../../../components/form-elements/checkbox'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {emailRecipientDataSelector} from '../../../../../../store/state/email-recipient-management/selectors'
import {useUserManagement} from '../../../../context/use-user-management'
import * as Styled from './subscriptions-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {Panel} from '../_styled/panel-section.styled'

export function SubscriptionsSection(): JSX.Element {
    const {activeUserDetails, setSubscription, initialUserDetails, detailExpanded} =
        useUserManagement()
    const subscriptionData = useTypedSelector(emailRecipientDataSelector)
    const initialSubscriptionValue = subscriptionData.some(
        (data) => data.user === activeUserDetails?.user && data.notificationType === 'NEW_INCIDENT',
    )
    const emailId =
        subscriptionData.find(
            (data) =>
                data.user === activeUserDetails?.user && data.notificationType === 'NEW_INCIDENT',
        )?.identity ?? ''

    const [checked, setChecked] = useState(false)
    useEffect(() => {
        setChecked(initialSubscriptionValue)
    }, [initialUserDetails, initialSubscriptionValue])

    const onChangeWrapper = () => {
        setChecked(!checked)
        setSubscription({
            initialSubscriptionStatus: initialSubscriptionValue,
            newStatus: !checked,
            userId: activeUserDetails.user,
            emailId: emailId,
        })
    }

    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.Subscriptions} />
            {detailExpanded.subscriptionsExpanded && (
                <Styled.DetailsLayout>
                    <Styled.Details>
                        <Styled.DetailRow>
                            <Checkbox
                                id={'user-management-subscriptions'}
                                checked={checked}
                                onChange={onChangeWrapper}
                                label={'Incidents'}
                            />
                        </Styled.DetailRow>
                    </Styled.Details>
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
