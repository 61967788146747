import {useDispatch} from 'react-redux'
import {Check} from 'react-feather'
import * as Styled from './editable-input.styled'
import {isEqual} from 'lodash'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../../../store/state/saved-filters/selectors'
import {NotificationScheduleType} from '../../../../../../store/state/saved-filters/state'
import {setChangeAlertSchedule} from '../../../../../../store/state/saved-filters/action-creators'

interface SetScheduleProps {
    schedule: NotificationScheduleType | undefined
}

export function SetSchedule({schedule}: SetScheduleProps): JSX.Element {
    const dispatch = useDispatch()
    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        dispatch(setChangeAlertSchedule(e.target.value as NotificationScheduleType))
    }
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const isChanged = newSubscription.schedule && !isEqual(newSubscription.schedule, schedule)

    return (
        <Styled.InputWrapper>
            <Styled.Select onChange={onChangeWrapper} defaultValue={schedule}>
                {!schedule && (
                    <option value={undefined} selected disabled>
                        - Schedule -
                    </option>
                )}
                <option value={NotificationScheduleType.DAILY}>Daily</option>
                <option value={NotificationScheduleType.WEEKLY}>Weekly</option>
                <option value={NotificationScheduleType.MONTHLY}>Monthly</option>
                <option value={NotificationScheduleType.NEVER}>Never</option>
            </Styled.Select>
            {isChanged && <Check color="green" width={15} height={15} />}
        </Styled.InputWrapper>
    )
}
