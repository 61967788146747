import {ReactNode, ReactNodeArray} from 'react'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './_styled/card-data-cell.styled'
import {BREAK_POINT_CARD_REDUCED_TEXT} from './_styled/card-data-cell.styled'
import {GuidType} from '../../../../values/generic-type-defintions'

interface CardDataCellProps {
    label: string
    children?: ReactNode | ReactNodeArray
    gridArea: string
    locationId: GuidType
    identifier: string
    centered?: boolean
}
export function CardDataCell({
    label,
    children,
    gridArea,
    locationId,
    identifier,
    centered,
}: CardDataCellProps): JSX.Element {
    const {width} = useDimensions()
    const fixedWidthReducedText = width > BREAK_POINT_CARD_REDUCED_TEXT
    const id = `vessel-management-${identifier}_${locationId}`

    return (
        <Styled.CardDataCell
            gridArea={gridArea}
            titleOnSameLine={fixedWidthReducedText}
            centered={centered}
        >
            <Styled.Label htmlFor={id}>{label}</Styled.Label>
            <Styled.Children id={id} gridArea={gridArea} width={width}>
                {children}
            </Styled.Children>
        </Styled.CardDataCell>
    )
}
