import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import * as Styled from './activity-section.styled'
import {ActivityContents} from './activity-contents'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {usbModalDetailExpandedSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {UsbDevicesDetailType} from '../../../../../store/state/usb-inventory-filter/state'
import {PurposeContents} from './purpose-contents'

export function ActivitySection(): JSX.Element {
    const detailExpanded = useTypedSelector(usbModalDetailExpandedSelector)

    return (
        <Panel>
            <ExpandPanelButton headingRow={UsbDevicesDetailType.Insights} />
            {detailExpanded?.insightsExpanded && (
                <Styled.DetailsLayout id="usb-devices-details-modal_panel_ActivitySection_DetailsLayout">
                    <PurposeContents />
                    <ActivityContents />
                </Styled.DetailsLayout>
            )}
        </Panel>
    )
}
