import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const Select = styled.select`
    text-align-last: center;
    padding: 3px ${spacing(4)} 3px ${spacing(6)};
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    font-size: 12px;
    border-radius: 3px;
    width: 240px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const IconWrapper = styled.div`
    padding-top: ${spacing(1)};
`
