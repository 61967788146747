import styled from 'styled-components'

interface ColourBarProps {
    start: number
    end: number
    colour: string
    opacity?: number
}

export const ColourBar = styled.span<ColourBarProps>`
    position: absolute;
    left: ${(props) => `${props.start}%`};
    width: ${(props) => `${props.end}%`};
    height: 10px;
    background-color: ${(props) => props.colour};
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`
