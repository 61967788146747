import {useContext, useEffect} from 'react'
import {GenerateReportButton} from '../../../components/generate-report-button/generate-report-button/generate-report-button'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {useOTSystemsContext} from '../contexts/hooks/use-ot-systems'
import {SystemTable} from './system-table'
import {FlexRow} from './tab-section/tab-section.styled'
import {ButtonText} from './../../../components/generate-report-button/generate-report-button/button-text.styled'
import {Button} from './../../../components/generate-report-button/generate-report-button/button.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {LocationInventoryPageContext} from '../contexts/location-inventory-page-context'
import {SystemAssessmentModal} from './system-assessment-modal'

export function SystemsLevelTab() {
    const {systemAssessmentModal} = useContext(LocationInventoryPageContext)
    const {
        createReport,
        reportUrl,
        showGenerateReportPopup,
        reportDialogShown,
        createReportError,
        resetCreateReportError,
        otSystems: {
            state: {data, isErrorOpen},
        },
    } = useOTSystemsContext()

    useEffect(() => resetCreateReportError(), [])

    const btnText = 'Download Assessment Report'
    const glyph = 'Pdf'

    let hasAssessment = false
    for (const {assessments} of Object.values(data)) {
        if (assessments.length) {
            hasAssessment = true
            break
        }
    }

    let button: JSX.Element

    if (hasAssessment) {
        button = (
            <GenerateReportButton
                createReport={createReport}
                reportUrl={reportUrl}
                showGenerateReportPopup={showGenerateReportPopup}
                reportDialogShown={reportDialogShown}
                pageType={PageType.OT_INVENTORY}
                isDisabled={false}
                glyph={glyph}
                btnText={btnText}
                onErrorClose={resetCreateReportError}
                errorText={createReportError}
                isErrorOpen={isErrorOpen}
            />
        )
    } else {
        button = (
            <Button id={`disabled_generate-report-button`} disabled>
                <Icon glyph={glyph} height={19} width={19} />
                <ButtonText id={`disabled_generate-report-button_text`}>{btnText}</ButtonText>
            </Button>
        )
    }

    return (
        <>
            {systemAssessmentModal.state.isOpen && <SystemAssessmentModal />}
            <FlexRow>{button}</FlexRow>
            <SystemTable />
        </>
    )
}
