import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const Label = styled.label`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    margin: 0;
`
export const Children = styled.div`
    width: 100%;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    word-break: break-word;
`
