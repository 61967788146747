import {AssetOutputModel} from '../../../models/asset.model'
import {AssetPopover} from '../../../../../components/asset-popover/asset-popover'
import {AssetSquare} from './_styled/assets-list.styled'

interface IncidentAssetProps {
    asset: AssetOutputModel
}

export function IncidentAsset({asset}: IncidentAssetProps): JSX.Element {
    return (
        <AssetPopover id={asset.assetId}>
            <AssetSquare asset={asset} key={asset.assetId} id={asset.alias}>
                {asset.alias}
            </AssetSquare>
        </AssetPopover>
    )
}
