import styled from 'styled-components'
import {smallFont} from '../../../../../../theme/font-styles'

interface CellProps {
    fixedWidthContent: boolean
}

export const DetailRow = styled.div<CellProps>`
    display: flex;
    justify-content: ${(props) => (props.fixedWidthContent ? 'flex-start' : 'space-between')};
    align-items: center;
`

export const DetailCell = styled.div<CellProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${(props) => (props.fixedWidthContent ? 'width: 250px;' : 'flex-grow: 1; min-width: 0;')}
`

export const TitleCell = styled(DetailCell)`
    font-size: 16px;
    line-height: 24px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const AssetCountCell = styled(DetailCell)`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.extrabold};
`

export const RightHandCell = styled.div<CellProps>`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: ${(props) => (props.fixedWidthContent ? 'left' : 'right')};
    white-space: nowrap;
`

export const VesselTaggingRow = styled.div`
    display: flex;
`
export const VesselTagsCell = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    gap: 5px;
    flex-grow: 1;
    min-width: 0;
`
