import styled from 'styled-components'
import {extraSmallFont, mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

interface Props {
    width: number
}

export const DropdownContentStyle = styled.div<Props>`
    background-color: ${(props) => props.theme.incidents.incidentStatusPopup.background};
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    margin: 0;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    max-width: 300px;
    min-height: 160px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline: 0;
        cursor: default;
    }
`

export const SearchInputWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: ${spacing(2)} ${spacing(1)} ${spacing(1)};
    z-index: 1;
`

export const UserSearchInput = styled.button`
    position: relative;
    font-size: 10px;
    width: 120px;
    height: 20px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    background-color: ${(props) => props.theme.refreshButton.default.backgroundColor};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)};
    padding: 0;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
`

export const Options = styled.ul<Props>`
    width: 100%;
    padding-inline-start: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    cursor: default;
    float: left;
`

export const Option = styled.li`
    padding: ${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(4)};
    flex-direction: row;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
`

export const Label = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    color: black;
`

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const CancelButton = styled.button`
    border: 1px solid ${(props) => props.theme.incidents.resetButton.background};
    background-color: white;
    color: ${(props) => props.theme.incidents.resetButton.background};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    padding: 0;
    margin-right: ${spacing(1)};
`

export const AssignButton = styled.button`
    border: none;
    background-color: ${(props) => props.theme.incidents.resetButton.background};
    color: ${(props) => props.theme.incidents.resetButton.text};
    border-radius: 5px;
    height: 25px;
    width: 70px;
    padding: 0;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    font-size: 10px;
    align-self: center;
    margin: ${spacing(1)};
    :disabled {
        opacity: 0.5;
        cursor: auto;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    height: 0;
    width: 0;
    background-color: white;
    border: none;
    right: 0;
    top: -12px;
    color: ${(props) => props.theme.incidents.resetButton.background};
`

export const CloseImage = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: -8px;
`
export const GuestUserWrapper = styled.div`
    background-color: ${(props) => props.theme.incidents.incidentStatusPopup.background};
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    width: 200px;
    min-height: 120px;
    border-radius: 2px;
    font-weight: ${(props) => props.theme.font.weight.normal};
    padding: ${spacing(1)};
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(3)};
`
export const GuestUserRow = styled.div`
    border: 1px solid ${(props) => props.theme.incidents.incidentStatusPopup.border};
    ${extraSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
    margin: ${spacing(2)} ${spacing(2)} ${spacing(1)};
    padding: ${spacing(1)};
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.text.default};
`
export const GuestUserLink = styled.span`
    ${extraSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-end;
`
export const GuestSectionTitle = styled.h2`
    margin: 0;
    padding: ${spacing(2)} ${spacing(2)} 0;
    ${extraSmallFont()};
    text-align: start;
    font-weight: ${(props) => props.theme.font.weight.normal};
`
export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    margin: 0 ${spacing(2)};
`
interface SearchInputProps {
    validEmail: boolean
    validBackground: boolean
}
export const SearchInput = styled.input<SearchInputProps>`
    ${extraSmallFont()};
    box-sizing: border-box;
    border: none;
    margin: 2px;
    padding: 4px;
    width: 80%;
    text-decoration: ${(props) => (props.validEmail ? 'none' : 'underline dotted red')};
    background-color: ${(props) =>
        props.validBackground ? props.theme.incidents.dataRow.editNoteBackground : 'none'};
`
export const CloseGuestEmail = styled.img`
    width: 6px;
`
export const EmailResults = styled.div`
    background-color: ${(props) => props.theme.incidents.dataRow.editNoteBackground};
    ${extraSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    overflow: hidden;
    text-overflow: ellipsis;
`
export const EmailResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    justify-content: center;
    padding: 3px;
    margin: 0 ${spacing(2)};
    cursor: pointer;
`
