import styled from 'styled-components'

export const Input = styled.a`
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    border: 1px solid black;
    border-radius: 2px;
    padding: 2px;
    overflow-x: hidden;
    &:focus,
    &:active {
        border: 2px solid black;
        outline: none;
    }
    &:link,
    &:visited {
        color: #000;
    }
`

export const Panel = styled.a`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
`

export const DisabledPanel = styled.a`
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(118, 118, 118, 0.3);
    border-radius: 2px;
    padding: 2px;
    color: rgb(170, 170, 170);
    pointer-events: none;
`

export const Label = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
    background-color: #f1f6fb;
    margin: 4px;
    padding: 0px 4px 0px 4px;
    border-radius: 4px;
    color: sky;
`

export const Placeholder = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #a7b0be;
`

export const Box = styled.div`
    position: relative;
    color: #000;
    font-weight: 400;
    flex: 1;
`
export const CheckboxContainer = styled.div`
    position: absolute;
    border: 1px solid #a7b0be;
    border-radius: 0px, 0px, 5px, 5px;
    background-color: #fff;
    width: 100%;
    padding: 9px 15px 15px 15px;
    z-index: 1;
`
export const OptionContainer = styled.div`
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    gap: 10px;
    margin-top: 10px;
`

export const Option = styled.a`
    display: block;
    padding: 5px 12px;
    cursor: pointer;
    border-radius: 4px;
    white-space: pre-wrap;
    &:hover,
    &:focus,
    &:active {
        background-color: #f1f6fb;
        outline: none;
    }
    &:link,
    &:visited {
        color: #000;
    }
`

export const SearchInput = styled.input`
    width: 100%;
    border: 1px solid black;
    padding: 3px;
    border-radius: 2px;
    &:focus {
        outline: none;
        border: 2px solid black;
    }
`
