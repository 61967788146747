import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface ContentWrapperProps {
    width: number
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
    display: flex;
    ${(props) =>
        props.width < 700
            ? 'flex-direction:column; align items:flex-start;'
            : 'align-items: center;'}
    justify-content: space-between;
    padding: 0 ${spacing(2)};
    gap: ${spacing(2)};
`
export const Label = styled.label`
    font-size: 16px;
    line-height: 23px;
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 130px;
`
export const WrapperOption = styled.div`
    ${mediumSmallFont()}
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
`
export const ValueOptions = styled.div`
    ${mediumSmallFont()}
    position: absolute;
    background-color: ${(props) => props.theme.incidents.dataRow.background};
    padding: 3px ${spacing(1)};
    width: 130px;
    z-index: 100;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
`
export const ValueOption = styled.div`
    text-align-last: center;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    padding: 2px;
    cursor: pointer;
`
