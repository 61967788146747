import type {
    AssetOption,
    AssetOptionKey,
    AssetOptions,
    CreateOTAssetOptionReq,
} from '../../location-inventory-page-context'
import ActionType from './action-type'
import * as Actions from './actions'

export function RequestAssetOptions(): Actions.RequestAssetOptions {
    return {
        type: ActionType.ASSET_OPTIONS_REQUEST,
    }
}

export function SetAssetOptions(data: AssetOptions): Actions.SetAssetOptions {
    return {
        type: ActionType.SET_ASSET_OPTIONS,
        payload: {
            data: data,
        },
    }
}

export function failFetchAssetOptions(): Actions.FailFetchAssetOptions {
    return {
        type: ActionType.ASSET_OPTIONS_FAILURE,
    }
}

export function AddAssetOption(
    data: AssetOption,
    assetOptionKey: AssetOptionKey,
): Actions.AddAssetOption {
    return {
        type: ActionType.CREATE_ASSET_OPTION_SUCCESS,
        payload: {
            data,
            assetOptionKey,
        },
    }
}

export function CreateAssetOption(data: CreateOTAssetOptionReq): Actions.CreateAssetOption {
    return {
        type: ActionType.CREATE_ASSET_OPTION_REQUEST,
        payload: {
            data,
        },
    }
}

export function SetCreateAssetOptionError(error: string): Actions.SetCreateAssetOptionError {
    return {
        type: ActionType.SET_CREATE_ASSET_OPTION_ERROR,
        payload: {
            error,
        },
    }
}

export function SetCreateAssetOptionFormErrors(
    formErrors: CreateOTAssetOptionReq,
): Actions.SetCreateAssetOptionFormErrors {
    return {
        type: ActionType.SET_CREATE_ASSET_OPTION_FORM_ERRORS,
        payload: {
            formErrors,
        },
    }
}

export function ResetCreateAssetOptionForm(): Actions.ResetCreateAssetOptionForm {
    return {
        type: ActionType.CREATE_ASSET_OPTION_RESET,
    }
}
