import {DetailPanelHeader} from './header/detail-panel-header'
import {ActivitySection} from './activity/activity-section'
import {ActionsSection} from './actions/actions-section'
import {UsbDetailedSection} from './usb-details/usb-detailed-section'
import {Container} from './_styled/usb-details-modal.styled'
import {UsbDetailsSection} from './usb-details/usb-details'

export function USBDeviceDetails(): JSX.Element {
    return (
        <Container onClick={(e) => e.stopPropagation()} id="usb-devices-details-modal_container">
            <DetailPanelHeader />
            <UsbDetailedSection />
            <UsbDetailsSection />
            <ActivitySection />
            <ActionsSection />
        </Container>
    )
}
