import * as Styled from './upload-processing.styled'
import PDF_UPLOAD from '../pdf-icon-upload.svg'
import {Check} from 'react-feather'
import {useModaUploadDocument} from '../context/use-modal-upload-document'

export function UploadSucceded(): JSX.Element {
    const {fileName, fileSize} = useModaUploadDocument()
    return (
        <>
            <Styled.FileBox>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.FileImage src={PDF_UPLOAD} />
                    <Styled.SubTitle>{`${fileName} (${fileSize})`}</Styled.SubTitle>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Styled.SubTitle>Uploaded</Styled.SubTitle>
                    <Check color="green" width={19} height={19} />
                </div>
            </Styled.FileBox>
            <Styled.SuccessMessage>
                Your document has been successfully uploaded and will now be displayed on the
                onboard dashboard!
            </Styled.SuccessMessage>
            <Styled.CloseModalMessage>
                No more action needed. You can now close this window
            </Styled.CloseModalMessage>
        </>
    )
}
