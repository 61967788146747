import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const SectionWrapper = styled.div`
    display: grid;
    grid-template-columns: fit-content(auto);
    column-gap: ${spacing(2)};
    row-gap: ${spacing(2)};
    align-items: center;
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
const Heading = styled.div`
    font-size: 14px;
    letter-spacing: 0.32px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const SubTitle = styled(Heading)``
