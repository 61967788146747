import {Card} from '../_styled/usb-device-card.styled'
import {NoDataMessage} from './no-data-message.styled'

interface NoDataCardProps {
    text: string
}
export function NoDataCard({text}: NoDataCardProps): JSX.Element {
    return (
        <Card>
            <NoDataMessage id="no-data-component">{text}</NoDataMessage>
        </Card>
    )
}
