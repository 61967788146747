import {ReactNode, ReactNodeArray} from 'react'
import {CardDataCell} from '../_styled/card-data-cell.styled'

interface ToggleButtonCellProps {
    children?: ReactNode | ReactNodeArray
    gridArea: string
}
export function ToggleButtonCell({children, gridArea}: ToggleButtonCellProps): JSX.Element {
    return (
        <CardDataCell gridArea={gridArea}>
            <div>{children}</div>
        </CardDataCell>
    )
}
