import {REST} from '../../../..'
import {LocationIdType} from '../../../../store/state/locations/state'
import {MetricType} from '../../../metrics-beta/contexts/types/metrics-response'
import {MetricSummmaryMap} from '../../../metrics-beta/contexts/types/metrics-summary'
import {TimestampFilterType} from './timestamp-filter'
import {METRICS_ENDPOINT} from './vessels-beta-endpoints'

export interface FrameworkScoreCardResponse {
    locationId: string
    protectionScore: number | undefined
    behaviourScore: number | undefined
    maintenanceScore: number | undefined
}

export async function buildFrameworkScoreCard(
    locationIds: LocationIdType[],
    analysisPeriod: TimestampFilterType,
): Promise<FrameworkScoreCardResponse[]> {
    try {
        const response = await REST.post(`${METRICS_ENDPOINT}/metrics/summaries`, {
            locations: locationIds,
            period: analysisPeriod,
            aggregationLevel: 'framework',
            analysisTypes: ['worstPerformingLocations'],
        })
        const entries = new Map(Object.entries(response.data.summaries)) as MetricSummmaryMap

        const result = locationIds.map((locationId) => {
            const protectionEntry = entries
                .get(MetricType.PROTECTION)
                ?.worstPerformingLocations.find((entry) => entry.location === locationId)
            const behaviourEntry = entries
                .get(MetricType.BEHAVIOUR)
                ?.worstPerformingLocations.find((entry) => entry.location === locationId)
            const maintenanceEntry = entries
                .get(MetricType.MAINTENANCE)
                ?.worstPerformingLocations.find((entry) => entry.location === locationId)
            const protectionScore = protectionEntry
                ? isNaN(protectionEntry?.count / protectionEntry?.total)
                    ? 0
                    : Math.round(
                          ((protectionEntry?.total - protectionEntry?.count) /
                              protectionEntry?.total) *
                              100,
                      )
                : undefined
            const behaviourScore = behaviourEntry
                ? isNaN(behaviourEntry?.count / behaviourEntry?.total)
                    ? 0
                    : Math.round(
                          ((behaviourEntry?.total - behaviourEntry?.count) /
                              behaviourEntry?.total) *
                              100,
                      )
                : undefined
            const maintenanceScore = maintenanceEntry
                ? isNaN(maintenanceEntry?.count / maintenanceEntry?.total)
                    ? 0
                    : Math.round(
                          ((maintenanceEntry?.total - maintenanceEntry?.count) /
                              maintenanceEntry?.total) *
                              100,
                      )
                : undefined
            return {
                locationId,
                protectionScore: protectionScore,
                behaviourScore: behaviourScore,
                maintenanceScore: maintenanceScore,
            }
        })

        return result.filter(
            (onlyValuesItems) =>
                !(
                    onlyValuesItems.protectionScore == undefined &&
                    onlyValuesItems.behaviourScore == undefined &&
                    onlyValuesItems.maintenanceScore == undefined
                ),
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return new Array<FrameworkScoreCardResponse>()
    }
}
