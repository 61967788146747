import LoadingState from '../../../../../values/loading-state-enum'
import {IncidentResponsePolicy} from './incident-response-policy'
import {IncidentsPolicyState} from './incidents-policy-state'

export const emptyIncidentPolicyDetail = {
    identity: '',
    name: '',
    defaultPolicy: false,
    enabledForIncidentManagement: true,
    updatedWhen: '',
    types: [],
    otherwiseAssignToUser: null,
    reassignToUser: null,
    sendReminderAfterDuration: null,
    sendEscalationReminderAfterDuration: null,
    escalateToUser: null,
    ccVesselEmailsTo: [],
    recommendationPlaybooks: [],
} as IncidentResponsePolicy

export const DEFAULT_INCIDENTS_POLICY_STATE: IncidentsPolicyState = {
    loadingRequestedIncidentsDataState: LoadingState.NotPopulated,
    incidentResponsePolicyData: [] as IncidentResponsePolicy[],
    activeIncidentPolicyData: emptyIncidentPolicyDetail,
    initialIncidentPolicyDetails: emptyIncidentPolicyDetail,
    displayIncidentPolicyDetails: emptyIncidentPolicyDetail,
    formattedIncidentPolicyTypes: [],
    initialEnableIncidentReminder: true,
    enableIncidentReminder: true,
    incidentPolicyPlaybooksTypes: [],
}
