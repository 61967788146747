import * as Styles from './rating-button.styled'
import {StarIcon} from './star-icon'
import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {reportsFilterSelector} from '../../../../../store/state/reports-filter/selectors'
import {setSelectedRating} from '../../../../../store/state/reports-filter/action-creators'
import {logFilterByTypes} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

interface RatingButtonProps {
    percentageThreshold: number
    previewSelectedRating: number | null
    mouseOverStar: (value: number) => void
    stopSelecting: () => void
}

function isButtonSelected(percentageThreshold: number, selectedRating: number): boolean {
    return percentageThreshold <= selectedRating
}
export function RatingButton({
    percentageThreshold,
    previewSelectedRating,
    mouseOverStar,
    stopSelecting,
}: RatingButtonProps): JSX.Element {
    const dispatch = useDispatch()
    const {selectedRating} = useTypedSelector(reportsFilterSelector)

    const shownSelectedRating = previewSelectedRating || selectedRating
    const buttonIsSelected = isButtonSelected(percentageThreshold, shownSelectedRating)
    function selectRating(newRating: number) {
        dispatch(setSelectedRating(newRating))
        stopSelecting()
        dispatch(
            logFilterByTypes(
                'SelectComplianceRating',
                `${newRating}`,
                true,
                AuditLogPageType.REPORTS,
            ),
        )
    }

    return (
        <Styles.RatingButton
            id={`rating-button-${percentageThreshold}`}
            onMouseEnter={() => mouseOverStar(percentageThreshold)}
            onClick={() => {
                selectRating(percentageThreshold)
            }}
            selected={buttonIsSelected}
            isHovering={previewSelectedRating != null}
        >
            <StarIcon
                key={percentageThreshold}
                selected={buttonIsSelected}
                isHovering={previewSelectedRating != null}
            />
        </Styles.RatingButton>
    )
}
