import styled from 'styled-components'
import {ThreatValue} from '../../../../../helpers/threatHelper'
import {getColor, getBackgroundColor} from '../../../../../theme/helpers/risk-score'
import {NodeValues} from '../../../../../values/nodes/NodeData'
interface Props {
    score: number
    nodeValue: NodeValues
    threatLevel: ThreatValue
}

export const Square = styled.div<Props>`
    margin: 2px;
    border-radius: 3px;
    font-size: 18px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: center;
    height: 100%;
    width: 100%;
    min-height: 20px;
    min-width: 20px;
    color: ${(props) => getColor(props.theme, props.score, props.nodeValue, props.threatLevel)};
    background-color: ${(props) =>
        getBackgroundColor(props.theme, props.score, props.nodeValue, props.threatLevel)};
`
