import {USBDevicesResponse} from './usb-devices-response'
import {PagedUSBDevicesState} from './paged-usb-devices-state'

export const DEFAULT_PAGED_USB_DEVICES_STATE: PagedUSBDevicesState = {
    dataUSBDevicesMap: new Map<number, USBDevicesResponse[]>(),
    totalNumberOfUSBDevices: undefined,
    totalNumberOfPages: undefined,
    selectedPage: 0,
    pageSize: 10,
    showFilterBar: false,
    modalIdForUSBDetails: null,
    findUsbDetailForModal: undefined,
    usbDetailsOutput: {},
    showSetPurposeModal: false,
    reportUrl: null,
    showGenerateReportPopup: false,
} as PagedUSBDevicesState
