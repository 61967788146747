import {TableHeaderRow} from './table-header-row'
import {DataLoading} from '../../common/data-loading'

export function LoadingData(): JSX.Element {
    return (
        <>
            <TableHeaderRow />
            <DataLoading />
        </>
    )
}
