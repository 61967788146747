import {StringUtils} from '../../../../../utils/Utils'
import {MetricsResponseForAssetStatus} from '../../../contexts/types/metrics-response'
import {getMetricTypeIcon, getTime} from '../../data-helper'
import {CardDataCell} from './card-data-cell'
import * as Styled from './metrics-cards.styled'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {MetricsDetailsExpanded} from './metrics-details-expanded/metrics-details-expanded'

interface MetricsCardProps {
    metrics: MetricsResponseForAssetStatus
    onClickFn: () => void
}

export function MetricsCardAssetStatus({metrics, onClickFn}: MetricsCardProps): JSX.Element {
    const cardAreaData = [
        {
            label: 'Framework:',
            identifier: `metrics-framework_type-${metrics.metric}`,
            value: StringUtils.capitaliseString(metrics.framework),
        },
        {
            label: 'Metric:',
            identifier: `metrics-metric_type-${metrics.metric}`,
            value:
                metrics.formattedMetricForUI.displayName ??
                StringUtils.capitaliseString(metrics.metric.replace(/([A-Z])/g, ' $1')),
        },
        {
            label: 'Number of issues:',
            identifier: `metrics-control_issue_at_any_point-${metrics.metric}`,
            value: metrics.timeWithControlIssue === 0 ? 'No' : 'Yes',
        },
        {
            label: 'Total time with issues:',
            identifier: `metrics-time_with_control_issue-${metrics.metric}`,
            value: getTime(metrics.timeWithControlIssue),
        },
    ]
    const {idForMetricDetailsModal} = usePagedMetricsBeta()

    const detailExtended =
        idForMetricDetailsModal?.metric === metrics.metric &&
        idForMetricDetailsModal?.locationId === metrics.location

    return (
        <Styled.MetricsDetails>
            <Styled.Card onClick={onClickFn} id={`metrics-card-${metrics.metric}`}>
                <Styled.CardAreaTitle>
                    <Styled.CardCustomerTitleData gridArea="VESSEL-NAME">
                        <div id={`metrics-vessel_name-${metrics.metric}`}>
                            {metrics.locationName}
                        </div>
                    </Styled.CardCustomerTitleData>
                    <Styled.CardProductTitleData gridArea="POLICY-NAME">
                        <div id={`metrics-policy_name-${metrics.metric}`}>{metrics.policyName}</div>
                    </Styled.CardProductTitleData>
                    <Styled.CardProductTitleData gridArea="ANALYSIS-NAME">
                        <div id={`metrics-asset_name-${metrics.metric}`}>{metrics.assetName}</div>
                    </Styled.CardProductTitleData>
                </Styled.CardAreaTitle>
                <Styled.CardAreaContent>
                    {cardAreaData.map((data, index) => (
                        <CardDataCell
                            key={index}
                            gridArea={data.label}
                            label={data.label}
                            identifier={data.identifier}
                        >
                            {data.label === 'Framework:' && (
                                <img
                                    src={getMetricTypeIcon(metrics.framework)}
                                    style={{width: '35px', height: '35px', marginRight: '5px'}}
                                />
                            )}
                            {data.value}
                        </CardDataCell>
                    ))}
                </Styled.CardAreaContent>
            </Styled.Card>
            {detailExtended && <MetricsDetailsExpanded />}
        </Styled.MetricsDetails>
    )
}
