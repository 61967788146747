/* eslint-disable @typescript-eslint/no-unused-vars */
import {SortDirection, SortField} from './values'
import {SortOrderPopover} from './sort-order-popover'
import * as Styles from './sort-order.styled'
import {SortValue} from '../../../../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'

function getSortValue(sortableFields: SortField[], sortKey: string): string | undefined {
    return sortableFields.find((field) => field.key === sortKey)?.value
}
interface Props {
    id: string
    sortOrder: SortValue
    sortableFields: SortField[]
    groupName: string
    sortOrderVariableChanged: (key: string) => void
    sortOrderDirectionChanged: (direction: SortDirection) => void
}

export function SortOrder({
    id,
    sortOrder,
    sortableFields,
    groupName,
    sortOrderVariableChanged,
    sortOrderDirectionChanged,
}: Props): JSX.Element {
    const sortValue = getSortValue(sortableFields, sortOrder.field)

    return (
        <Styles.Container>
            <SortOrderPopover
                sortKey={sortOrder.field}
                sortableFields={sortableFields}
                groupName={groupName}
                sortOrderVariableChanged={sortOrderVariableChanged}
            >
                <Styles.SortOrderFakeInput>
                    <Styles.SortValue id={`${id}-${sortValue}`}>{sortValue}</Styles.SortValue>
                    <Styles.Arrows>
                        <Styles.UpArrow />
                        <Styles.DownArrow />
                    </Styles.Arrows>
                </Styles.SortOrderFakeInput>
            </SortOrderPopover>
            <div
                id={`${id}-${sortOrder.direction}`}
                onClick={() => {
                    sortOrderDirectionChanged(
                        sortOrder.direction === SortDirection.ASC
                            ? SortDirection.DESC
                            : SortDirection.ASC,
                    )
                }}
            >
                <Styles.ArrowIcon
                    width={12}
                    height={17}
                    glyph="Arrow"
                    direction={sortOrder.direction}
                />
            </div>
        </Styles.Container>
    )
}
