import styled from 'styled-components'
import {smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const TOKEN_AREA_REAK_POINT = 430

export const TokenAreaWrapper = styled.div``

export const Header = styled.h2`
    color: ${(props) => props.theme.colors.text.default};
    font-size: 14px;
    line-height: 21px;
    margin: 0;
`

export const AuthTokenValueArea = styled.div<AuthTokenValueProps>`
    display: flex;
    flex: 1;
`

interface AuthTokenValueProps {
    width: number
}

export const AuthTokenValue = styled.div<AuthTokenValueProps>`
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    color: ${(props) => props.theme.systemPage.dataRow.text};
    background-color: ${(props) => props.theme.systemPage.dataRow.background};
    padding: ${spacing(1)};
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    ${smallFont()};
`

export const CopyButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    ${smallFont()}
    color: ${(props) => props.theme.systemPage.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;

    &:hover {
        background-color: ${(props) => props.theme.systemPage.link.hoverBackground};
        cursor: pointer;
    }
`

export const IconText = styled.div`
    margin-left: ${spacing(1)};
`
