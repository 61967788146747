import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../../theme/font-styles'

export const ViewAllCell = styled.div`
    padding: 0;
    margin: 0;
    ${mediumSmallFont()}
    letter-spacing: 0.24px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`

export const AlertHeading = styled.h4`
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.myVessels.filterBar.headingHighlight};
    ${mediumSmallFont()}
    line-height: 30px;
    letter-spacing: 0.24px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
