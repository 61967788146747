import {Divider} from '../data-populated/data-populated.styled'
import {CopyConfigArea} from './copy-config-area'
import {VesselInfo} from './vessel-info'
import {HeaderStyle} from './_styled/header.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridType, showTwoCol} from '../shared/grid-layout-helper'

export function Header(): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridType(width)
    return (
        <>
            <HeaderStyle gridLayout={gridLayout}>
                <VesselInfo />
                {showTwoCol(gridLayout) && <CopyConfigArea />}
            </HeaderStyle>
            <Divider />
        </>
    )
}
