import {ReactNode, useReducer} from 'react'
import {GuidType} from '../../../../values/generic-type-defintions'
import {PagedAlertsContext} from './paged-alerts-context'
import {pagedAlertsReducer} from './state/reducer'
import {DEFAULT_PAGED_ALERT_STATE} from './types/default-paged-alerts-state'
import {PagedAlertsState} from './types/paged-alerts-state'

type PagedAlertsProviderProps = {
    children: ReactNode | ReactNode[]
    assetId: GuidType
    fromDate: string | undefined
    toDate: string | undefined
    pageSize: number
}

export function PagedAlertsProvider({
    children,
    assetId,
    fromDate,
    toDate,
    pageSize,
}: PagedAlertsProviderProps): JSX.Element {
    const initialState: PagedAlertsState = DEFAULT_PAGED_ALERT_STATE
    initialState.assetId = assetId
    initialState.fromDate = fromDate
    initialState.toDate = toDate
    initialState.pageSize = pageSize

    const [state, dispatch] = useReducer(pagedAlertsReducer, initialState)

    return (
        <PagedAlertsContext.Provider value={{state, dispatch}}>
            {children}
        </PagedAlertsContext.Provider>
    )
}
