import {TableHeaderRow} from './table-header-row'
import {TableHeaderGrid} from './table-header-grid.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function TableHeader(): JSX.Element {
    const {width} = useDimensions()
    return (
        <TableHeaderGrid width={width} id="TableHeaderGrid">
            <TableHeaderRow />
        </TableHeaderGrid>
    )
}
