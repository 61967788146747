import {ReactNode} from 'react'
import * as Styled from './_styled/data-cell-fixed.styled'

interface DataCellFixedProps {
    label: string
    gridArea?: string
    children: ReactNode | ReactNode[]
}

export function DataCellButtons({label, children, gridArea}: DataCellFixedProps): JSX.Element {
    const formattedGridArea = gridArea || label.toLocaleUpperCase().replace(/ /g, '-')

    return (
        <>
            <Styled.MessageArea
                saved={label === 'Saved successfully'}
                gridArea={formattedGridArea}
                id={'vessel-configuration_buttons-message'}
            >
                {label}
            </Styled.MessageArea>
            <Styled.Value
                gridArea={`${formattedGridArea}-VALUE`}
                id={'vessel-configuration_buttons'}
            >
                {children}
            </Styled.Value>
        </>
    )
}
