import * as Styled from './_styled/vessels-card.styled'
import {Location} from '../../../../../store/state/locations/state'

interface CardCodeCellProps {
    location: Location
    fixedWidthReducedText: boolean
}

export function CardCodeCell({location, fixedWidthReducedText}: CardCodeCellProps): JSX.Element {
    return (
        <Styled.CardDataCell titleOnSameLine={fixedWidthReducedText}>
            <Styled.ReducedText fixedWidth={fixedWidthReducedText}>Code:</Styled.ReducedText>
            <div id={`location-code_${location.location}`}>{location.code}</div>
        </Styled.CardDataCell>
    )
}
