import styled from 'styled-components'
import {spacing} from '../../theme/spacing'
import {mediumFont, mediumSmallFont} from '../../theme/font-styles'
import {Colors} from '../../theme/colours'

export const BREAK_POINT = 550

interface SectionProps {
    width: number
}

export const Section = styled.section<SectionProps>`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width >= BREAK_POINT ? '700px' : '350px')};
    min-height: 500px;
`

export const Header = styled.header`
    color: ${(props) => props.theme.modalDialog.text};
    background-color: ${(props) => props.theme.modalDialog.background};
    width: 100%;
    padding: ${spacing(2)};
    position: relative;
`
export const CloseImage = styled.img`
    height: 16px;
    width: 16px;
    cursor: pointer;
    position: absolute;
    right: ${spacing(1)};
    top: ${spacing(1)};
`
interface DragAndDropSectionProps {
    width: number
}
export const DragAndDropSection = styled.div<DragAndDropSectionProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${spacing(4)};
    row-gap: ${spacing(4)};
    flex: 1;
`

export const Title = styled.h1`
    ${mediumFont()}
    margin: 0;
    padding: ${spacing(2)};
    border-bottom: 1px solid ${Colors.grey2};
`

export const ProcessingDocumentSection = styled.div`
    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)};
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${spacing(4)};
`
export const TitleImage = styled.img`
    height: 24px;
    width: 24px;
    margin-right: ${spacing(4)};
`
export const SubTitle = styled.h2`
    ${mediumSmallFont()}
    margin: 0;
    padding: 0;
    text-align: center;
`
