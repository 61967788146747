import styled from 'styled-components'
import {spacing} from '../../../../../../../theme/spacing'
import {getGridLayout} from '../../../../general/helpers'
import {GridLayout} from '../../../../general/grid-layout.enum'

interface Props {
    width: number
    showReducedGrid: boolean
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: ${(props) => getGridTemplateColumns(props.width)};
    row-gap: ${spacing(1)};
    column-gap: ${spacing(2)};
    padding: ${spacing(1)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
`

export function getGridTemplateColumns(width: number): string {
    const gridLayout = getGridLayout(width)
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_LARGE:
            return '0 130px 100px 100px 110px;'
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '0 115px 85px 85px 85px;'
        case GridLayout.THREE_COLUMNS_SMALL:
            return '0 110px 75px 75px 75px;'
        case GridLayout.TWO_COLUMNS_LARGE:
            return '0 301px 237px 258px auto;'
        case GridLayout.TWO_COLUMNS_MEDIUM:
            return '0 311px 177px 198px auto;'
        case GridLayout.TWO_COLUMNS_SMALL:
            return '0 281px 157px 168px auto;'
        case GridLayout.ONE_COLUMN_LARGE:
            return '0 150px 105px 105px 105px;'
        case GridLayout.ONE_COLUMN_MEDIUM:
            return '0 115px 85px 85px 85px;'
        case GridLayout.ONE_COLUMN_SMALL:
            return '0 110px 75px 75px 75px;'
    }
}
