import {useState, useEffect, useRef} from 'react'
import * as Style from './multiselect-checkbox.styled'
import Chevron from '../../../@assets/icons/chevron.svg'
import {DropdownOption} from './dropdown'

type MultiselectCheckboxProps = {
    options: DropdownOption[]
    selectedOptions: DropdownOption[]
    onChange: (option: DropdownOption) => void
    placeholder: string
    required?: boolean
}

export function MultiselectCheckbox(props: MultiselectCheckboxProps): JSX.Element {
    const [showOptions, setShowOptions] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')

    const boxElem = useRef<HTMLDivElement>(null)
    const selectedElem = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        const close = (event: MouseEvent) => {
            if (!boxElem.current) {
                return
            }

            if (!boxElem.current.contains(event.target as Node)) {
                setShowOptions(false)
            }
        }

        window.addEventListener('click', close, true)

        return () => {
            window.removeEventListener('click', close)
        }
    }, [])

    useEffect(() => {
        if (showOptions) {
            return
        }
    }, [props.selectedOptions, showOptions])

    // Escape regex special characters and create a regex for search filter
    const filterRegex = new RegExp(searchFilter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i')

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value)
    }

    const handleOptionClick = (event: React.MouseEvent) => {
        event.preventDefault()
        setShowOptions(!showOptions)
    }

    let renderOptions = props.options
        .filter((option) => filterRegex.test(option.label))
        .map((option) => (
            <Style.Checkbox key={option.value}>
                <input
                    id={'physical-interfaces-' + option.value}
                    type="checkbox"
                    checked={props.selectedOptions.some((x) => x.label === option.label)}
                    onChange={() => props.onChange(option)}
                />
                <Style.CheckboxLabel htmlFor={'physical-interfaces-' + option.value}>
                    {option.label}
                </Style.CheckboxLabel>
            </Style.Checkbox>
        ))

    if (renderOptions.length === 0) {
        renderOptions = [<Style.Option key="no-option">No options</Style.Option>]
    }

    let selectedOptions: JSX.Element
    if (props.selectedOptions.length > 0) {
        selectedOptions = (
            <Style.Label>{props.selectedOptions.map((x) => x.label).join(', ')}</Style.Label>
        )
    } else {
        selectedOptions = <Style.Placeholder>{props.placeholder}</Style.Placeholder>
    }

    return (
        <Style.Box ref={boxElem}>
            <Style.Panel ref={selectedElem} onClick={handleOptionClick} href="#">
                {selectedOptions}
                <img src={Chevron} alt="arrow down" />
            </Style.Panel>
            {showOptions && (
                <Style.CheckboxContainer>
                    <Style.SearchInput
                        placeholder="Search"
                        value={searchFilter}
                        onChange={handleSearchChange}
                    />
                    <Style.OptionContainer>{renderOptions}</Style.OptionContainer>
                </Style.CheckboxContainer>
            )}
        </Style.Box>
    )
}
