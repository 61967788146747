import {useDispatch} from 'react-redux'
import {TIME_RANGE_VALUES} from '../../../../../../values/TimeRangeValues'
import {useTagsComponent} from '../../contexts/use-tags-component'
import * as Styled from './filter-component.styled'

export function PeriodSelect(): JSX.Element {
    const {timeRange, setNewTimerange} = useTagsComponent()
    const dispatch = useDispatch()

    function onChangeWrapper(e: React.ChangeEvent<HTMLSelectElement>): void {
        e.preventDefault()
        const value = e.target.value as keyof typeof dispatch
        setNewTimerange(TIME_RANGE_VALUES[value])
    }
    return (
        <Styled.Input id="period-selector">
            <Styled.Label>Time Period</Styled.Label>
            <Styled.Select
                onChange={onChangeWrapper}
                defaultValue={timeRange}
                id="select-period-value"
            >
                <option value="PT24H" id="PT24H">
                    1 day
                </option>
                <option value="PT168H" id="PT168H">
                    7 days
                </option>
                <option value="PT720H" id="PT720H">
                    30 days
                </option>
                <option value="PT2160H" id="PT2160H">
                    90 days
                </option>
            </Styled.Select>
        </Styled.Input>
    )
}
