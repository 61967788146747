import * as Styled from '../generate-report-popup/generate-report-popup.styled'
import CLOSE_ICON from '../../../@assets/icons/close-panel.svg'

interface ReportDialogContentProps {
    onClose: () => void
    errorText: string
}

export function ReportErrorDialogContent({
    onClose,
    errorText,
}: ReportDialogContentProps): JSX.Element {
    return (
        <Styled.TitleRow id={`generate-report-popup-error-title`}>
            <Styled.CloseImage src={CLOSE_ICON} id="close-popup-icon" onClick={onClose} />
            <Styled.ErrorTitle>{errorText}</Styled.ErrorTitle>
        </Styled.TitleRow>
    )
}
