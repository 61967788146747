import {IndicatorsTable} from './table/indicators-table'
import IndicatorPagination from './indicators-pagination'

import {DataActions} from './data-actions'
import {RecordSet} from './record-set'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {IndicatorCards} from './cards/indicator-cards'
import {Footer} from '../../../../../components/table-view-components/footer.styled'

export function IndicatorsTab(): JSX.Element {
    const {width} = useDimensions()
    return (
        <>
            <DataActions />
            {width >= 1024 && <IndicatorsTable />}
            {width < 1024 && <IndicatorCards />}
            <Footer width={width}>
                <IndicatorPagination />
                <RecordSet />
            </Footer>
        </>
    )
}
