import styled from 'styled-components'

export const VesselTagsCell = styled.div`
    display: flex;
    background-color: white;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 15px;
    flex: 1;
    gap: 5px;
    flex-grow: 1;
    min-width: 0;
`
