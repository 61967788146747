import {REST} from '../../../..'
import {GuidType} from '../../../../values/generic-type-defintions'
import {
    DeploymentType,
    OperationalStatus,
    TrafficStatus,
    VesselDeploymentStatus,
} from '../../../vessel-management/contexts/type/deployment-status-types'
import {
    OPERATING_URL,
    SELF_MONITOR_WARNING_URL,
} from '../../../vessel-management/contexts/use-vessel-management'

export async function buildDeploymentSummary(
    locationsVessels: Record<GuidType, boolean>,
): Promise<VesselDeploymentStatus> {
    try {
        const vesselDeploymentInfo: VesselDeploymentStatus = {
            operationalStatus: undefined,
            trafficStatus: undefined,
            vesselDashboard: locationsVessels,
        }
        const stringKeys = Object.values(DeploymentType).filter(
            (value) => typeof value === 'number',
        ) as DeploymentType[]
        for (const type of stringKeys) {
            switch (type) {
                case DeploymentType.Operational:
                    await REST.get(OPERATING_URL).then((response) => {
                        vesselDeploymentInfo.operationalStatus = response.data as OperationalStatus
                    })
                    break
                case DeploymentType.Traffic:
                    await REST.get(SELF_MONITOR_WARNING_URL).then((response) => {
                        vesselDeploymentInfo.trafficStatus = response.data as TrafficStatus
                    })
                    break
            }
        }
        return vesselDeploymentInfo
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        return {} as VesselDeploymentStatus
    }
}
