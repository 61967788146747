export function IconRejected(): JSX.Element {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.65 11.05H9.35V12.75H7.65V11.05ZM7.65 4.25H9.35V9.35H7.65V4.25ZM8.4915 0C3.7995 0 0 3.808 0 8.5C0 13.192 3.7995 17 8.4915 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.4915 0ZM8.5 15.3C4.743 15.3 1.7 12.257 1.7 8.5C1.7 4.743 4.743 1.7 8.5 1.7C12.257 1.7 15.3 4.743 15.3 8.5C15.3 12.257 12.257 15.3 8.5 15.3Z"
                fill="#F34663"
            />
        </svg>
    )
}
