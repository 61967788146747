import * as Styles from './heading-row.styled'

export function HeadingRow(): JSX.Element {
    return (
        <>
            <Styles.Show>Show:</Styles.Show>
            <Styles.Alerts>Alerts</Styles.Alerts>
        </>
    )
}
