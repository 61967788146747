import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {usePagedIncidents} from '../../../contexts/use-paged-incidents'
import * as Styled from './close-monitor-incident.styled'
import {ButtonsArea} from './close-monitor-incident.styled'
import {ItemsListOnModal} from './items-list'
import {ProcessingStatus} from '../../../contexts/types/type-of-interfaces'
import {SaveInProgress} from './data-area/save-in-progress'
import {ChangeSaved} from './data-area/change-saved'
import {ChangeNotSaved} from './data-area/change-not-saved'
import {SaveButton} from './data-area/save-button'
import {CancelButton} from './data-area/cancel-button'
import {ReopenDurationMessage} from './reopen-duration-message'

export function CloseMonitorIncident(): JSX.Element {
    const {width} = useDimensions()
    const {processingSaveCloseMonitor} = usePagedIncidents()

    return (
        <Styled.Section
            onClick={(e) => e.stopPropagation()}
            width={width}
            id="CloseMonitorIncident"
        >
            <Styled.Header>
                <Styled.Title id="CloseMonitorIncident-title">
                    Close Incident - Monitor
                </Styled.Title>
                <ReopenDurationMessage />
                <Styled.Subtitle style={{fontWeight: 'bold'}} id="CloseMonitorIncident-sub-title">
                    Select which linked item you wish to watch and trigger reopening of the incident
                    from below:
                </Styled.Subtitle>
            </Styled.Header>

            {processingSaveCloseMonitor === ProcessingStatus.PROCESSING && <SaveInProgress />}
            {processingSaveCloseMonitor === ProcessingStatus.PROCESSED && <ChangeSaved />}
            {processingSaveCloseMonitor === ProcessingStatus.FAILED && <ChangeNotSaved />}
            {processingSaveCloseMonitor === ProcessingStatus.NOT_PROCESSING && (
                <>
                    <ItemsListOnModal />
                    <Styled.Actions>
                        <ButtonsArea id="CloseMonitorIncident-ButtonsArea">
                            <CancelButton />
                            <SaveButton />
                        </ButtonsArea>
                    </Styled.Actions>
                </>
            )}
        </Styled.Section>
    )
}
