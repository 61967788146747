import {PagedNmeaInventoryState} from '../types/paged-nmea-inventory-state'
import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {NmeaInventoryResponse} from '../types/nmea-inventory-response'
import {DEFAULT_PAGED_NMEA_INVENTORY_STATE} from '../types/default-paged-nmea-inventory-state'

export const pagedNmeaInventoryReducer = produce(
    (draft: PagedNmeaInventoryState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.RequestingData
                draft.dataNmeaInventoryMap = undefined
                draft.totalNumberOfNmeaInventory = undefined
                draft.totalNumberOfPages = undefined
                draft.selectedPage = 0
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.selectedPage = action.payload
                draft.loadingDataState = LoadingState.RequestingData
                break
            case ActionType.SET_SORT_COLUMN_ACTION:
                draft.sortColumn = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_PAGE_DATA_ACTION:
                draft.loadingDataState = LoadingState.Loaded
                draft.loadingFilterState = LoadingState.Loaded
                draft.totalNumberOfNmeaInventory = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages

                if (!draft.dataNmeaInventoryMap) {
                    draft.dataNmeaInventoryMap = new Map<number, NmeaInventoryResponse[]>()
                }
                if (
                    !draft.dataNmeaInventoryMap.has(draft.selectedPage) ||
                    !isEqual(
                        draft.dataNmeaInventoryMap.get(draft.selectedPage),
                        action.payload.data,
                    )
                ) {
                    draft.dataNmeaInventoryMap.set(draft.selectedPage, action.payload.data)
                }
                break
            case ActionType.DISPLAY_NMEA_DETAILS_MODAL:
                draft.modalIdForNmeaDetails = action.payload
                break
            case ActionType.CLOSE_NMEA_DETAILS_MODAL:
                draft.modalIdForNmeaDetails = null
                break

            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_NMEA_INVENTORY_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
