import styled from 'styled-components'
import {CyberOwlTheme} from '../../../../../theme/theme'
import {IncidentStatus} from '../../../contexts/types/incident-status'

interface TableDataRowTopProps {
    incidentStatus?: IncidentStatus
    gridRow: number
    activeIncident?: boolean
}

function getBorderLeft(status: IncidentStatus | undefined, theme: CyberOwlTheme): string {
    switch (status) {
        case 'NEW':
            return `thick solid ${theme.incidents.dataRow.statusBar.new}`
        case 'OPEN':
            return `thick solid ${theme.incidents.dataRow.statusBar.open}`
        case 'CLOSED':
        case 'CLOSED_FALSE_POSITIVE':
        case 'CLOSED_MONITOR':
            return `thick solid ${theme.incidents.dataRow.statusBar.closed}`
        case 'DRAFT':
            return `thick solid ${theme.incidents.dataRow.statusBar.draft}`
        default:
            return `thick solid inherit`
    }
}

export const TableDataRowTop = styled.div<TableDataRowTopProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.incidents.dataRow.text};
    background-color: ${(props) =>
        props.incidentStatus === 'NEW'
            ? props.theme.incidents.dataRow.newBackground
            : props.theme.incidents.dataRow.background};
    border-top: ${(props) =>
        props.activeIncident ? `3px solid  ${props.theme.incidents.dataRow.selectedRow};` : ''};
    border-right: ${(props) =>
        props.activeIncident ? `3px solid  ${props.theme.incidents.dataRow.selectedRow};` : ''};
    border-bottom: ${(props) =>
        props.activeIncident ? `3px solid  ${props.theme.incidents.dataRow.selectedRow};` : ''};
    border-left: ${(props) => getBorderLeft(props.incidentStatus, props.theme)};
    height: 64px;
`
