import {useEnforcementPolicy} from '../../../contexts/enforcement-policy/use-enforcement-policy'
import {useIncidentsPolicy} from '../../../contexts/incidents-policy/use-incidents-policy'
import {useMetricsPolicy} from '../../../contexts/metrics-policy/use-metrics-policy'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {BottomContentWrapper, Button, ButtonText} from './_styled/button.styled'

export function CloseButton(): JSX.Element {
    const {closePolicyDetailModal} = usePolicyManagement()
    const {setDefaultIncidentPolicyData} = useIncidentsPolicy()
    const {setDefaultMetricsPolicyData} = useMetricsPolicy()
    const {setDefaultEnforcementPolicyData} = useEnforcementPolicy()

    function onClickWrapper() {
        closePolicyDetailModal()
        setDefaultIncidentPolicyData()
        setDefaultMetricsPolicyData()
        setDefaultEnforcementPolicyData()
    }

    return (
        <BottomContentWrapper>
            <Button id="close-button" onClick={onClickWrapper}>
                <ButtonText>Close</ButtonText>
            </Button>
        </BottomContentWrapper>
    )
}
