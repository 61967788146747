import styled from 'styled-components'
import {mediumLargeFont, mediumSmallFont} from '../../../../../theme/font-styles'

type BannerProps = {
    isCard: boolean
}

export const Banner = styled.div<BannerProps>`
    background-color: #303030;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 121px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: white;
    column-gap: 40px;
    padding-left: 7rem;
    border-radius: ${(props) => (props.isCard ? '0px' : '20px')};
`

export const FlexRowItem = styled.span``

export const Button = styled.button<BannerProps>`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    ${(props) => (props.isCard ? mediumSmallFont() : mediumLargeFont())}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.normal};
    min-width: 110px;
`

export const Text = styled.span`
    margin-left: 5px;
`
