import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {CyberOwlTheme} from '../../../../../theme/theme'

interface RatingButtonProps {
    selected: boolean
    isHovering: boolean
}
function getBackgroundColor(theme: CyberOwlTheme, selected: boolean, isHovering: boolean): string {
    if (!selected) {
        return theme.reports.starRating.unSelected.background
    }
    return isHovering
        ? theme.reports.starRating.hover.background
        : theme.reports.starRating.selected.background
}
export const RatingButton = styled.button<RatingButtonProps>`
    padding: 0;
    cursor: pointer;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    margin-right: ${spacing(2)};
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    border-radius: 3px;
    width: 27px;
    height: 27px;
    background-color: ${(props) =>
        getBackgroundColor(props.theme, props.selected, props.isHovering)};
    &:last-child {
        margin-right: 0;
    }
    svg {
        margin-left: 0.5px;
    }
`
