import styled from 'styled-components'

export const Title = styled.h2`
    border-bottom: 1px solid ${(props) => props.theme.modalDialog.divider};
    padding: 10px 15px;
    margin: 0;
    font-size: 21px;
    line-height: 28px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`
