import styled from 'styled-components'

const StyledSpinner = styled.svg`
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    animation: spin 1s linear infinite;
`

const StyledPath = styled.path`
    opacity: 0.75;
`

const StyledCircle = styled.circle`
    opacity: 0.25;
`

interface Props {
    color?: string
    height?: string
    width?: string
}

export function Spinner(props: Props) {
    return (
        <StyledSpinner
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            color={props.color ?? 'white'}
            height={props.height ?? '20'}
            width={props.width ?? '20'}
        >
            <StyledCircle
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
            ></StyledCircle>
            <StyledPath
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></StyledPath>
        </StyledSpinner>
    )
}
