import * as Styled from './asset-actions.styled'
import {usePagedUnknownAssetsBeta} from '../../../../context/use-paged-unknown-assets'
import CIRCLE_MONITORED from '../../../../../../@assets/icons/circle-monitored.svg'
import MAIL_ICON from '../../../../../../@assets/icons/mail-icon.svg'
import {useDispatch} from 'react-redux'
import {openModal} from '../../../../../../store/state/extended-data-request/action-creators'
import {InventoryType} from '../../../../../../store/state/extended-data-request/state'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {networkAssetsModalDetailExpandedSelector} from '../../../../../../store/state/network-assets-filter/selectors'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {NetworkAssetsDetailType} from '../../../../../../store/state/network-assets-filter/state'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'

interface AssetActionsProps {
    locationCode: string
    locationId: GuidType
    deviceName: string
    isAllowed: boolean
}

export function AssetActions({
    locationCode,
    locationId,
    deviceName,
    isAllowed,
}: AssetActionsProps): JSX.Element {
    const dispatch = useDispatch()
    const {displayEnableMonitoringModal, setCurrentLocationCode} = usePagedUnknownAssetsBeta()
    const detailExpanded = useTypedSelector(networkAssetsModalDetailExpandedSelector)

    const onClickWrapper = (): void => {
        displayEnableMonitoringModal(true)
        setCurrentLocationCode(locationCode)
    }

    return (
        <>
            <ExpandPanelButton headingRow={NetworkAssetsDetailType.AssetActionsState} />
            {detailExpanded.actionsExpanded && (
                <Styled.AssetMonitoringAreaWrapper id="network-asset-details-modal_AssetActions_wrapper">
                    {isAllowed && (
                        <Styled.Label id="network-asset-details-modal_AssetActions_label">
                            Monitor this asset:
                            <Styled.TextLink
                                onClick={() => onClickWrapper()}
                                id="network-asset-details-modal_AssetActions_text"
                            >
                                <img src={CIRCLE_MONITORED} style={{marginRight: '5px'}} />
                                Enable monitoring
                            </Styled.TextLink>
                        </Styled.Label>
                    )}

                    <Styled.Label>
                        Get help with this asset:
                        <Styled.TextLink
                            onClick={() =>
                                dispatch(openModal(InventoryType.NETWORK, deviceName, [locationId]))
                            }
                            id="get-help-contact-soc"
                        >
                            <img src={MAIL_ICON} style={{marginRight: '5px'}} />
                            Contact SOC
                        </Styled.TextLink>
                    </Styled.Label>
                </Styled.AssetMonitoringAreaWrapper>
            )}
        </>
    )
}
