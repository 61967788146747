import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../templates/fixed-page/content-container-middle.styled'
import {TableHeaderRow} from './header/table-header-row'
import {USBDevicesTableContent} from './usb-devices-table-content'

export function USBDevicesTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    return (
        <>
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.usbDevices.dataArea.background}
            >
                <TableHeaderRow />
            </ContentContainerMiddle>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.usbDevices.dataArea.background}
            >
                <USBDevicesTableContent />
            </ContentContainerMiddle>
        </>
    )
}
