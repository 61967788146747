import {SavedFilterPageType} from '../../../../store/state/saved-filters/state'
import {IncidentsFilterCriteria} from '../../../../values/user-preferences/incidents-filter'
import {NetworkAssetsFilter} from '../../../../values/user-preferences/network-assets-filter'
import {SoftwareInventoryFilterCriteria} from '../../../../values/user-preferences/software-inventory-filter'
import {UsbInventoryFilterCriteria} from '../../../../values/user-preferences/usb-inventory-filter'
import {NotifyMeOutputModel} from '../../reselector/notify-me-detail.model'
import {DetailFilterIncidents} from '../shared/detail-filter-incidents'
import {DetailFilterNetworkAssets} from '../shared/detail-filter-network-assets'
import {DetailFilterSoftwareInventory} from '../shared/detail-filter-software-inventory'
import {DetailFilterUsbInventory} from '../shared/detail-filter-usb-inventory'
import {
    getFormattedIncidentsFilter,
    getFormattedSoftwareInventoryFilter,
    getFormattedUsbInventoryFilter,
} from '../shared/helper'
import * as Styled from './notify-me-popup.styled'

interface FilterDetailsProps {
    model: NotifyMeOutputModel
}

export function FilterDetails({model}: FilterDetailsProps): JSX.Element {
    return (
        <Styled.CurrentFilterDetails>
            {model?.pageType === SavedFilterPageType.NETWORK_ASSETS ? (
                <DetailFilterNetworkAssets
                    criteria={model?.currentFilter.criteria as NetworkAssetsFilter}
                />
            ) : model?.pageType === SavedFilterPageType.USB_INVENTORY ? (
                <DetailFilterUsbInventory
                    criteria={getFormattedUsbInventoryFilter(
                        model?.currentFilter.criteria as UsbInventoryFilterCriteria,
                    )}
                />
            ) : model?.pageType === SavedFilterPageType.SOFTWARE_INVENTORY ? (
                <DetailFilterSoftwareInventory
                    criteria={getFormattedSoftwareInventoryFilter(
                        model?.currentFilter.criteria as SoftwareInventoryFilterCriteria,
                    )}
                />
            ) : model?.pageType === SavedFilterPageType.INCIDENTS ? (
                <DetailFilterIncidents
                    criteria={getFormattedIncidentsFilter(
                        model?.currentFilter.criteria as IncidentsFilterCriteria,
                    )}
                />
            ) : /* ) : model?.pageType === SavedFilterPageType.REPORTS ? (
                     <DetailFilterReports
                         criteria={getFormattedReportsFilter(
                             model?.currentFilter.criteria as ReportsFilterCriteria,
                         )}
                     />*/
            null}
        </Styled.CurrentFilterDetails>
    )
}
