import * as Actions from './actions'
import {
    setNetworkEnforcementEndDateAction,
    setNetworkEnforcementMaxVesselsAction,
    setRemovableEnforcementEndDateAction,
    setRemovableEnforcementMaxVesselsAction,
    toggleRemovableEnforcementAction,
} from './actions'
import {ActionType} from './action-type'

export function toggleIncidentsPolicy(payload: boolean): Actions.ToggleIncidentsPolicyAction {
    return {
        type: ActionType.TOGGLE_INCIDENTS_LICENSE,
        payload,
    }
}

export function setIncidentsPolicyEndDate(
    payload: Date | null,
): Actions.setIncidentsPolicyEndDateAction {
    return {
        type: ActionType.INCIDENT_POLICY_END_DATE,
        payload,
    }
}

export function setSaveButtonState(payload: boolean): Actions.setSaveButtonStateAction {
    return {
        type: ActionType.SAVE_BUTTON_ENABLED_STATE,
        payload,
    }
}

export function toggleOnboardEngagement(payload: boolean): Actions.toggleOnboardEngagementAction {
    return {
        type: ActionType.TOGGLE_ONBOARD_ENGAGEMENT,
        payload,
    }
}

export function setOnboardEngagementEndDate(
    payload: Date | null,
): Actions.setOnboardEngagementEndDateAction {
    return {
        type: ActionType.SET_ONBOARD_ENGAGEMENT_END_DATE,
        payload,
    }
}

export function toggleNetworkEnforcement(payload: boolean): Actions.toggleNetworkEnforcementAction {
    return {
        type: ActionType.TOGGLE_NETWORK_ENFORCEMENT,
        payload,
    }
}

export function setNetworkEnforcementMaxVessels(
    payload: number | null,
): setNetworkEnforcementMaxVesselsAction {
    return {
        type: ActionType.SET_NETWORK_ENFORCEMENT_MAX_VESSELS,
        payload,
    }
}

export function setNetworkEnforcementEndDate(
    payload: Date | null,
): setNetworkEnforcementEndDateAction {
    return {
        type: ActionType.SET_NETWORK_ENFORCEMENT_END_DATE,
        payload,
    }
}

export function toggleRemovableEnforcement(payload: boolean): toggleRemovableEnforcementAction {
    return {
        type: ActionType.TOGGLE_REMOVABLE_ENFORCEMENT,
        payload,
    }
}

export function setRemovableEnforcementMaxVessels(
    payload: number | null,
): setRemovableEnforcementMaxVesselsAction {
    return {
        type: ActionType.SET_REMOVABLE_ENFORCEMENT_MAX_VESSELS,
        payload,
    }
}

export function setRemovableEnforcementEndDate(
    payload: Date | null,
): setRemovableEnforcementEndDateAction {
    return {
        type: ActionType.SET_REMOVABLE_ENFORCEMENT_END_DATE,
        payload,
    }
}
