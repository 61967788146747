import {useContext} from 'react'
import * as ActionCreators from './state/action-creators'
import {warn} from '../../../helpers/logging'
import {ConfigInputType} from './type/config-input-type'
import {DetailExpandedType, VesselConfigModal} from './type/vessel-management-state'
import {
    saveUpdatedOTSources,
    saveUpdatedVesselConfig,
} from '../../../store/state/locations/action-creators'
import {ThunkDispatch} from 'redux-thunk'
import AppState from '../../../store/types/app-state'
import {Action} from 'redux'
import {Api} from '../../../api/Api'
import {GuidType} from '../../../values/generic-type-defintions'
import {UseVesselManagementOutput} from './use-vessel-management-output'
import {VesselManagementContext} from './vessel-management-context'
import {DEFAULT_VESSEL_MANAGEMENT_STATE} from './type/default-vessel-management-state'
import {IsolationMode} from './type/location-in-isolation.mode'
import {REST} from '../../..'
import type {VesselDeploymentStatus} from './type/deployment-status-types'
import {DeploymentType} from './type/deployment-status-types'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {filteredVessels} from '../../../store/state/vessel-filter/reselector/filtered-vessel-ids.reselector'
import {getMergedLocation} from '../components/shared/vessel-status'
import {LocationIdType} from '../../../store/state/locations/state'
import LoadingState from '../../../values/loading-state-enum'
import {OTSourceModalDetails} from './type/ot-sources-modal-details'
import {LicenseSummery} from './type/license-types'

export const LOCATIONS_URL = 'active-control/api/v1/locations'
export const OPERATING_URL = 'aimee/api/v1/locations/operating'
export const SELF_MONITOR_WARNING_URL = '/api/v1/selfMonitoring/warnings'
export const LICENSE_SUMMERY_URL = 'active-control/api/v1/policies/license/summary'

export function useVesselManagement(): UseVesselManagementOutput {
    const {state, dispatch} = useContext(VesselManagementContext)
    const allLocations = useTypedSelector(locationsSelector)

    if (state == undefined || dispatch == undefined) {
        throw new Error('useVesselManagement must be used within a VesselManagementContext')
    }
    function showVesselDetailModal(locationId: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showVesselDetailModal(locationId))
    }

    async function getPolicyLicenses(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const {data} = await REST.get<LicenseSummery>(LICENSE_SUMMERY_URL)
        dispatch(ActionCreators.getPolicyLicenseSummery(data))
    }

    function getDeploymentStatus(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const stringKeys = Object.values(DeploymentType).filter(
            (value) => typeof value === 'number',
        ) as DeploymentType[]

        dispatch(ActionCreators.requestVesselDeploymentStatus())

        for (const type of stringKeys) {
            switch (type) {
                case DeploymentType.Operational:
                    REST.get(OPERATING_URL).then((response) => {
                        dispatch(ActionCreators.getVesselDeploymentStatus(response.data, type))
                    })
                    break
                case DeploymentType.Traffic:
                    REST.get(SELF_MONITOR_WARNING_URL).then((response) => {
                        dispatch(ActionCreators.getVesselDeploymentStatus(response.data, type))
                    })
                    break
            }
        }
    }

    function setDeploymentStatus(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        const locationsVessels: Record<GuidType, boolean> = {}
        for (const location of allLocations) {
            locationsVessels[location.location] = location.vesselDashboard ?? false
        }
        const vesselDeploymentStatus: VesselDeploymentStatus = {
            operationalStatus: state.operationalStatus,
            trafficStatus: state.trafficStatus,
            vesselDashboard: locationsVessels,
        }
        dispatch(ActionCreators.setVesselDeploymentStatus(vesselDeploymentStatus))
    }

    function closeVesselDetailModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeVesselDetailModal())
    }

    function setInitialValue(payload: VesselConfigModal): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setInitialValue(payload))
    }

    function changeBusinessNetworks(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeBusinessNetworks(value))
    }

    function changeOtNetworks(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeOtNetworks(value))
    }

    function changeCrewNetworks(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeCrewNetworks(value))
    }

    function changeDashboardAgentIds(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeDashboardAgentIds(value))
    }

    function changeVesselEmail(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeVesselEmail(value))
    }

    function setValidChange(payload: {inputType: ConfigInputType; value: boolean}): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setValidChange(payload))
    }

    function setSelectedIncidentPolicy(incidentPolicyId: GuidType | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSelectedIncidentPolicy(incidentPolicyId))
    }

    function setSelectedMetricsPolicy(metricsPolicyId: GuidType | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSelectedMetricsPolicy(metricsPolicyId))
    }

    function setSelectedEnforcementPolicy(enforcementPolicyId: GuidType | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSelectedEnforcementPolicy(enforcementPolicyId))
    }

    function saveUpdatedConfig(
        locationId: GuidType,
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        thunkDispatch(saveUpdatedVesselConfig(locationId, state.updatedValue))
    }

    function toggleDetailExpanded(detailExpanded: DetailExpandedType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleDetailExpanded(detailExpanded))
    }

    function getLocationsInIsolationMode(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.get(LOCATIONS_URL)
            .then((response) => {
                dispatch(ActionCreators.receivedLocationsControl(response.data))
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.error(err))
    }

    function changeIsolationModeOfVessel(locationIdentity: GuidType, mode: IsolationMode): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        REST.patch(`${LOCATIONS_URL}/${locationIdentity}/mode/${mode}`)
            .then(() => getLocationsInIsolationMode())
            // eslint-disable-next-line no-console
            .catch((err) => console.error(err))
    }

    function openSetOTSourcesModal(oTSourceModalDetails: OTSourceModalDetails): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displaySetOTSourcesModal(oTSourceModalDetails))
    }

    function closeSetOTSourcesModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeSetOTSourcesModal())
    }

    function changeOTSourceType(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeOTSourcesType(value))
    }

    function changeOTSourceIp(
        newIpValue: string,
        label: 'source' | 'destination',
        index?: number,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeOTSourcesIp(newIpValue, label, index))
    }

    function changeOTSourcePort(
        newPortValue: string,
        label: 'source' | 'destination',
        index?: number,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeOTSourcesPort(newPortValue, label, index))
    }

    function saveUpdatedOTSourcesOnLocation(
        thunkDispatch: ThunkDispatch<AppState, Api, Action>,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        thunkDispatch(saveUpdatedOTSources(state.oTSourceModalDetails))
        dispatch(ActionCreators.updateOTSourcesDisplay())
        dispatch(ActionCreators.closeSetOTSourcesModal())
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    const isAllValid =
        state.isValidBusinessNetworkChange &&
        state.isValidOTNetworkChange &&
        state.isValidCrewNetworkChange &&
        state.isOnboardAgentIdChanged &&
        state.isIncidentPolicyChanged &&
        state.isMetricsPolicyChanged &&
        state.isEnforcementPolicyChanged &&
        state.isValidEmailChange

    function setFilter(
        hasDeploymentIssues: boolean | undefined,
        selectedLocations: Set<LocationIdType> | undefined,
        searchVesselNameTerm: string,
        searchVesselTagTerm: string[],
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const vesselFilteredLocations = filteredVessels(
            allLocations,
            selectedLocations,
            searchVesselNameTerm,
            searchVesselTagTerm,
        )
        const mergedLocations = vesselFilteredLocations?.map((location) =>
            getMergedLocation(state.vesselDeploymentStatus, location.location),
        )

        const filteredLocationIdsWithIssues = mergedLocations
            .filter((location) =>
                hasDeploymentIssues
                    ? location.trafficStatus != null ||
                      location.upgradeAssets != null ||
                      location.operating === false ||
                      location.vesselDashboard === false
                    : location.trafficStatus === null &&
                      location.upgradeAssets === null &&
                      location.operating !== false &&
                      location.vesselDashboard !== false,
            )
            .map((location) => location.location)

        const filteredLocations =
            hasDeploymentIssues === undefined
                ? vesselFilteredLocations
                : vesselFilteredLocations.filter((location) =>
                      filteredLocationIdsWithIssues.includes(location.location),
                  )

        dispatch(ActionCreators.setFilter(filteredLocations))
    }

    return {
        showVesselDetailModal,
        activeVesselId: state.activeVesselId,
        closeVesselDetailModal,
        initialValue: state.initialValue,
        displayValue: state.displayValue,
        updatedValue: state.updatedValue,
        setInitialValue,
        changeBusinessNetworks,
        changeOtNetworks,
        changeCrewNetworks,
        changeDashboardAgentIds,
        changeVesselEmail,
        isValidChange: isAllValid && state.isValidChange,
        setValidChange,
        setSelectedIncidentPolicy,
        setSelectedMetricsPolicy,
        setSelectedEnforcementPolicy,
        selectedIncidentPolicy: state.selectedIncidentPolicy,
        selectedMetricsPolicy: state.selectedMetricsPolicy,
        selectedEnforcementPolicy: state.selectedEnforcementPolicy,
        saveUpdatedConfig,
        toggleDetailExpanded,
        detailExpanded: state.detailExpanded
            ? state.detailExpanded
            : DEFAULT_VESSEL_MANAGEMENT_STATE.detailExpanded,
        getLocationsInIsolationMode,
        changeIsolationModeOfVessel,
        locationsInIsolationMode: state.locationsInIsolationMode,
        hasActiveControl: state.hasActiveControl,
        getDeploymentStatus,
        showOTSourceModalDisplay: state.showOTSourceModalDisplay,
        oTSourceModalDetails: state.oTSourceModalDetails,
        openSetOTSourcesModal,
        closeSetOTSourcesModal,
        setDeploymentStatus,
        vesselDeploymentStatusInfo: state.vesselDeploymentStatus,
        displayFilterBar,
        setFilter,
        showFilterBar: state.showFilterBar,
        filteredLocations: state.filteredLocations,
        isGetDeploymentStatusLoading:
            state.deploymentOperationalLoadingState !== LoadingState.Loaded ||
            state.deploymentTrafficLoadingState !== LoadingState.Loaded,
        isVesselDeploymentStatusLoading: state.vesselDeploymentLoadingState !== LoadingState.Loaded,
        changeOTSourceType,
        changeOTSourceIp,
        changeOTSourcePort,
        saveUpdatedOTSourcesOnLocation,
        getPolicyLicenses,
        policyLicenseSummery: state.policyLicenseSummery,
    }
}
