import {useEffect} from 'react'
import {CardArea} from '../../common/cards/card-area.styled'
import {AlertCard} from './alert-card'
import LoadingState from '../../../../../../values/loading-state-enum'
import {usePagedAlerts} from '../../../../contexts/alerts/use-paged-alerts'

import {RequestError} from './request-error'
import {LoadingData} from '../../common/cards/loading-data'
import {NoData} from './no-data'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {isloadingSelector} from '../../../../../../store/state/network-anomaly-matrix/selectors'

export function AlertCards(): JSX.Element {
    const {loading, refreshData, totalNumberOfEvents, dataPage} = usePagedAlerts()
    const matrixIsLoading = useTypedSelector(isloadingSelector)

    useEffect(() => {
        if (loading === LoadingState.NotPopulated) {
            refreshData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    if (loading === LoadingState.Errored) {
        return <RequestError />
    }

    const showLoading =
        totalNumberOfEvents == undefined ||
        loading === LoadingState.NotPopulated ||
        loading === LoadingState.RequestingData ||
        matrixIsLoading

    if (showLoading || !dataPage) {
        return <LoadingData />
    }

    if (totalNumberOfEvents === 0) {
        return <NoData />
    }

    return (
        <CardArea>
            {dataPage.map((commonEvent) => (
                <AlertCard key={commonEvent.identity} commonEvent={commonEvent} />
            ))}
        </CardArea>
    )
}
