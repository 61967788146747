import styled from 'styled-components'
import {narrowLayoutFn} from '../grid-helper'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
interface Props {
    gridArea: string
    width?: number
}

export const IncidentNumber = styled.h2<Props>`
    ${(props) =>
        props.width && narrowLayoutFn(props.width)
            ? 'text-align:center;'
            : 'text-align: flex-start;'};
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    grid-area: ${(props) => props.gridArea};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    margin: 0;
`

export const VesselCell = styled.div<Props>`
    font-size: 14px;
    font-weight: ${(props) => props.theme.font.weight.semibold};
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    grid-area: ${(props) => props.gridArea};
    ${(props) =>
        props.width && narrowLayoutFn(props.width)
            ? ''
            : 'display: flex; justify-content:flex-end;'};
`

export const VesselCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const IncidentTitleCell = styled.div<Props>`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    color: ${(props) => props.theme.colors.link.primary};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    grid-area: ${(props) => props.gridArea};
    display: flex;
    justify-content: center;
`
export const SubTitle = styled.div`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
`
