import {createSelector} from 'reselect'
import {locationsLoadingSelector} from '../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../store/state/nodes/selectors'
import {isVesselManagementFilterLoadingStateSelector} from '../../../store/state/vessel-management-filter/selectors'

export const isLocationsLoadingReSelector = createSelector(
    locationsLoadingSelector,
    nodesFetchingSelector,
    isVesselManagementFilterLoadingStateSelector,

    (locationsLoading, nodesLoading, filterLoading): boolean => {
        return locationsLoading === true || nodesLoading === true || filterLoading === true
    },
)
