import AppState from '../../types/app-state'
import {Moment} from 'moment'
import LoadingState from '../../../values/loading-state-enum'
import {MonaEngineMode} from '../../../values/mona_engine_mode'

export const isLoadingSelector = (state: AppState): boolean =>
    state.monaEngineModes.loading === LoadingState.NotPopulated ||
    state.monaEngineModes.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState => state.monaEngineModes.loading

export const hasDataSelector = (state: AppState): boolean =>
    state.monaEngineModes.data?.length > 0 || false

export const dataSelector = (state: AppState): MonaEngineMode[] => state.monaEngineModes.data

export const lastUpdatedSelector = (state: AppState): Moment | null =>
    state.monaEngineModes.lastUpdate
