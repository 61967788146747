import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../general/helpers'
import {WidgetOutputModel} from '../widget.model'
import {WidgetWrapper} from './widget-wrapper'
import {ContentArea} from '../../general/content-area/content-area.styled'
import {Title} from '../../general/content-area/title.styled'
import {HeadlineValues} from './headline-values'
import {RiskScoreBar} from './risk-score-bar/risk-score-bar'
import {TopContentSection} from '../../general/content-area/top-content-section.styled'
import {BottomContentSection} from '../../general/content-area/bottom-content-section'

interface PopulatedDataProps {
    model: WidgetOutputModel
}

export function PopulatedData({model}: PopulatedDataProps): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    return (
        <WidgetWrapper gridLayout={gridLayout}>
            <ContentArea gridLayout={gridLayout}>
                <TopContentSection>
                    <RiskScoreBar score={model.score} gridLayout={gridLayout} />
                </TopContentSection>
                <BottomContentSection>
                    <Title gridLayout={gridLayout} id="my-fleet-bottom-section-title">
                        Risk Score Trends
                    </Title>
                    <HeadlineValues model={model} gridLayout={gridLayout} />
                </BottomContentSection>
            </ContentArea>
        </WidgetWrapper>
    )
}
