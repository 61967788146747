import {useRef, useState} from 'react'
import {ChevronDown, ChevronUp} from 'react-feather'
import * as Styled from '../_styled/dropdown.styled'
import {useOnClickOutside} from '../../../../../../../hooks/useOnClickOutside'
import {showCards} from '../../../_styled/policy-management-list-header.styled'
import {useDimensions} from '../../../../../../../contexts/dimensions/use-dimensions'
import {reverseFormattedReminderDuration} from '../../../../../contexts/data-helper'
import {usePolicyManagement} from '../../../../../contexts/policy-management/use-policy-management'

interface DurationSelectDropdownProps {
    initialValue: string | null
    setChange: (value: number | null) => void
    changed: boolean
    disabled?: boolean
}

export function DurationSelectDropdown({
    initialValue,
    setChange,
    changed,
    disabled,
}: DurationSelectDropdownProps): JSX.Element {
    const [optionsVisible, setOptionsVisible] = useState(false)
    const clickOutside = useRef<HTMLDivElement>(null)
    useOnClickOutside(clickOutside, () => {
        optionsVisible && setOptionsVisible(false)
    })

    const days: number[] = [...Array(14).keys()].map((num) => num + 1)

    const {width} = useDimensions()
    const {selectedPolicyId} = usePolicyManagement()
    const isCardView = showCards(width, selectedPolicyId !== null)

    function getFormattedDuration(value: number | undefined): string | undefined {
        if (!value) {
            return undefined
        }
        const suffix = value > 1 ? 'days' : 'day'
        return `${value} ${suffix}`
    }

    return (
        <Styled.Container ref={clickOutside}>
            <Styled.DropdownWrapper
                onClick={(e) => {
                    if (disabled) {
                        e.preventDefault()
                        return
                    }
                    setOptionsVisible(!optionsVisible)
                }}
                disabled={disabled}
                changed={changed}
                cardView={isCardView}
            >
                <Styled.SearchInput id="filter-search">
                    {getFormattedDuration(
                        days.find((day) => day === reverseFormattedReminderDuration(initialValue)),
                    ) ?? '- Select period -'}
                </Styled.SearchInput>
                <Styled.SearchIcon>
                    {optionsVisible ? (
                        <ChevronUp height={13} width={13} />
                    ) : (
                        <ChevronDown height={13} width={13} />
                    )}
                </Styled.SearchIcon>
            </Styled.DropdownWrapper>
            <Styled.UserWrapper ref={clickOutside}>
                {optionsVisible && (
                    <Styled.UserDropdown>
                        <Styled.ValueOption
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault()
                                    return
                                }
                                setChange(null)
                                setOptionsVisible(false)
                            }}
                        >
                            (Unselect)
                        </Styled.ValueOption>
                        {days.map((day, index) => (
                            <Styled.ValueOption
                                key={index}
                                onClick={(e) => {
                                    if (disabled) {
                                        e.preventDefault()
                                        return
                                    }
                                    setChange(day)
                                    setOptionsVisible(false)
                                }}
                            >
                                {getFormattedDuration(day)}
                            </Styled.ValueOption>
                        ))}
                    </Styled.UserDropdown>
                )}
            </Styled.UserWrapper>
        </Styled.Container>
    )
}
