import {meanBy} from 'lodash'
import {createSelector} from 'reselect'
import {normaliseScore} from '../../../../../helpers/formatting'
import {etsDataSelector} from '../../../../../store/state/ets-value/selectors'
import {EtsData} from '../../../../../store/state/ets-value/state'
import {
    etsTrend24HourDataSelector,
    etsTrend7DaysDataSelector,
} from '../../../../../store/state/ets-trend/selectors'
import {EtsTrendScore} from '../../../../../values/ets-trend-score/EtsTrendScore'
import {getDifference} from '../../../../../values/ets-trend-score/helpers'
import {TrendDirection, WidgetOutputModel} from '../widget.model'
import {loadingReselector} from './loading.reselector'

function noData(
    etsValue: EtsData,
    ets24HourTrend: EtsTrendScore[],
    ets7DaysTrend: EtsTrendScore[],
): boolean {
    const no24HourTrendData =
        ets24HourTrend == undefined || ets24HourTrend == null || ets24HourTrend.length === 0
    const no7DaysTrendData =
        ets7DaysTrend == undefined || ets7DaysTrend == null || ets7DaysTrend.length === 0

    return etsValue?.score === 0 && no24HourTrendData && no7DaysTrendData
}

export const widgetDataReselector = createSelector(
    loadingReselector,
    etsDataSelector,
    etsTrend24HourDataSelector,
    etsTrend7DaysDataSelector,
    (isLoading, etsValue, ets24HourTrend, ets7DaysTrend): WidgetOutputModel | null => {
        if (isLoading || noData(etsValue, ets24HourTrend, ets7DaysTrend)) {
            return null
        }

        return {
            score: Math.round(normaliseScore(etsValue.score)),
            trend: {
                hours24: {
                    score: getDifference(ets24HourTrend),
                    direction: TrendDirection.Same,
                },
                days7: {
                    score: getDifference(ets7DaysTrend),
                    direction: TrendDirection.Same,
                },
                days7Average:
                    ets7DaysTrend && ets7DaysTrend.length > 0
                        ? Math.round(meanBy(ets7DaysTrend, 'score'))
                        : 0,
            },
        } as WidgetOutputModel
    },
)
