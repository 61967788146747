import {PagedReportsState} from './paged-reports-state'
import LoadingState from '../../../../values/loading-state-enum'
import {CustomerReports} from '../../../../values/customer-reports'
import {GuidType} from '../../../../values/generic-type-defintions'
import {ReportsFilter} from '../../../../values/user-preferences/reports-filter'

export const DEFAULT_REPORTS_FILTERS: ReportsFilter = {
    selectedRating: 101,
    selectedFromTimeRange: '',
    selectedToTimeRange: '',
    selectedReportType: '',
    includeWholeFleetReports: true,
    locations: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}

export const DEFAULT_PAGED_REPORTS_STATE: PagedReportsState = {
    loadingDataState: LoadingState.NotPopulated,
    loadingFilterState: LoadingState.NotPopulated,
    dataReportsMap: new Map<number, CustomerReports[]>(),
    earliestPeriodEndDate: '',
    latestPeriodEndDate: '',
    reportsFilter: DEFAULT_REPORTS_FILTERS,
    totalNumberOfReports: undefined,
    totalNumberOfPages: undefined,
    pageSize: 10,
    selectedPage: 0,
    notesExpanded: new Map<GuidType, boolean>(),
    allReportTypes: undefined,
} as PagedReportsState
