import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const Label = styled.label`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    padding: 0;
    margin: 0;
    ${mediumSmallFont()};
`

export const StatusRow = styled.div`
    display: flex;
    align-items: center;
    ${mediumSmallFont()};
`
