import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const AssetsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing(2)};
    margin: ${spacing(2)};
`

export const AssetsCell = styled.div`
    height: 36px;
    width: 36px;
`
