import {cloneDeep} from 'lodash'
import {ReactNode, useReducer} from 'react'
import {OTLocationsReducer} from './state/vessels/reducer'
import {DEFAULT_OT_LOCATIONS_STATE} from './types/ot-inventory-response'
import {VesselListContext} from './vessel-list-context'

interface VesselListProviderProps {
    children: ReactNode | ReactNode[]
}

export function VesselListProvider({children}: VesselListProviderProps) {
    const initialState = cloneDeep(DEFAULT_OT_LOCATIONS_STATE)
    const [state, dispatch] = useReducer(OTLocationsReducer, initialState)

    return (
        <VesselListContext.Provider value={{state, dispatch}}>
            {children}
        </VesselListContext.Provider>
    )
}
