import {useDispatch} from 'react-redux'
import {Check} from 'react-feather'
import * as Styled from './notify-me-popup.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {newSubscriptionSelector} from '../../../../store/state/saved-filters/selectors'
import {SwitchToggle} from '../../../switch-toggle/switch-toggle'
import {setNotificationOnChange} from '../../../../store/state/saved-filters/action-creators'
import {useState} from 'react'

interface SetNotificationOnChangeProps {
    notifyOnChange: boolean
}

export function SetNotificationOnChange({
    notifyOnChange,
}: SetNotificationOnChangeProps): JSX.Element {
    const dispatch = useDispatch()
    const [notification, setNotification] = useState(notifyOnChange)
    const newSubscription = useTypedSelector(newSubscriptionSelector)
    const isChanged =
        newSubscription.notifyOnChange !== undefined &&
        newSubscription.notifyOnChange !== notifyOnChange

    return (
        <Styled.InputWrapper>
            <Styled.Text>Notify if seen on the new asset</Styled.Text>
            <SwitchToggle
                onValue={notification}
                setToggleLeftValue={() => {
                    dispatch(setNotificationOnChange(false))
                    setNotification(false)
                }}
                setToggleRightValue={() => {
                    dispatch(setNotificationOnChange(true))
                    setNotification(true)
                }}
                id="set-notification"
            />
            {isChanged && (
                <Check
                    color="green"
                    width={19}
                    height={19}
                    style={{position: 'absolute', right: '30px', paddingLeft: '2px'}}
                />
            )}
        </Styled.InputWrapper>
    )
}
