import {useTheme} from 'styled-components'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {ContentContainerMiddle} from '../../../../../templates/fixed-page/content-container-middle.styled'
import {TableHeaderRow} from './table-header'
import {MetricsTableContent} from './metrics-table-content'
import {TableActionRow} from './metrics-table-action-row'

export function MetricsTable(): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    return (
        <>
            <TableActionRow />
            <ContentContainerMiddle
                width={width}
                backgroundColor={theme.softwareInventory.dataArea.background}
            >
                <TableHeaderRow />
            </ContentContainerMiddle>
            <ContentContainerMiddle
                width={width}
                scrollable
                backgroundColor={theme.softwareInventory.dataArea.background}
            >
                <MetricsTableContent />
            </ContentContainerMiddle>
        </>
    )
}
