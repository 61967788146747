import {PageType} from '../../../../../components/vessel-filters/data-helper'
import * as Styles from './filter-bar-header.styled'

export function FilterBarHeader(): JSX.Element {
    return (
        <Styles.SectionHeader id={`${PageType.USB_DEVICES}_filter-bar_header`}>
            <Styles.SectionTitle>Filters</Styles.SectionTitle>
        </Styles.SectionHeader>
    )
}
