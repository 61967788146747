import styled from 'styled-components'
import {smallFont} from '../../../../../../../../theme/font-styles'
import {spacing} from '../../../../../../../../theme/spacing'

interface TagResultProps {
    cursorPointer?: boolean
}
export const TagResult = styled.div<TagResultProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 3px;
    border-radius: ${spacing(1)};
    background-color: rgba(31, 136, 229, 0.24);
    padding: 3px ${spacing(1)};
    max-width: 105px;
    height: 23px;
`

interface TagItemProps {
    maxWidth?: string
}
export const TagItem = styled.div<TagItemProps>`
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.semibold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
export const TagExpandedView = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    background: white;
    padding: 3px;
    border: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    border-radius: ${spacing(1)};
    min-width: 100px;
    z-index: 5;
`
export const TagExpandedViewTagName = styled.div`
    text-align: center;
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.normal};
`

export const XIcon = styled.div`
    color: ${(props) => props.theme.incidents.filterBar.search.text};
    cursor: pointer;
`
