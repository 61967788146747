import {createSelector} from 'reselect'
import {selfMonitoringEngineEventsLoadingStateSelector} from '../../../../../store/state/self-monitoring-events/selectors'
import {selfMonitoringEngineRulesLoadingStateSelector} from '../../../../../store/state/self-monitoring-rules/selectors'
import LoadingState from '../../../../../values/loading-state-enum'

export const isSMEDataNotPopulatedReselector = createSelector(
    selfMonitoringEngineEventsLoadingStateSelector,
    selfMonitoringEngineRulesLoadingStateSelector,

    (eventsLoadingState: LoadingState, rulesLoadingState: LoadingState): boolean => {
        return (
            eventsLoadingState === LoadingState.NotPopulated ||
            rulesLoadingState === LoadingState.NotPopulated
        )
    },
)
