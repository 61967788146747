import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {smallFont} from '../../../../theme/font-styles'

export const PopupContainer = styled.div`
    display: inline-block;
    position: absolute;
    top: 90px;
    right: 15px;
    margin-top: ${spacing(1)};
    z-index: 1;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    background-color: ${(props) => props.theme.colors.background.lowContrast};
    padding: ${spacing(1)};
    pointer-events: none;
    ${smallFont()};
    color: ${(props) => props.theme.colors.text.error};
`

export const Message = styled.div`
    ${smallFont()};
`

export const List = styled.div`
    padding-left: ${spacing(1)};
`
