import {LinkButtonsWrapper, LinkButton} from './link-buttons.styled'
import {ArrowUpRight} from 'react-feather'

interface LinkButtonsProps {
    id: string
    text: string
    link: string | undefined
}
export function LinkButtons({id, text, link}: LinkButtonsProps): JSX.Element {
    return (
        <LinkButtonsWrapper>
            <LinkButton id={id} rel="noopener,noreferrer" target="_blank" href={link}>
                {text} <ArrowUpRight size={10} />
            </LinkButton>
        </LinkButtonsWrapper>
    )
}
