import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import LoadingState from '../../../../../values/loading-state-enum'
import {IncidentPolicyDetails} from './incident-policy-details/incident-policy-details'
import {ContentWrapper} from './_styled/policy-details-modal.styled'
import {useIncidentsPolicy} from '../../../contexts/incidents-policy/use-incidents-policy'
import {usePolicyManagement} from '../../../contexts/policy-management/use-policy-management'
import {PolicyType} from '../../../contexts/policy-management/type/policy-management-state'
import {MetricsPolicyDetails} from './metrics-policy-details/metrics-policy-details'
import {useMetricsPolicy} from '../../../contexts/metrics-policy/use-metrics-policy'
import {EnforcementPolicyDetails} from './enforcement-policy-details/enforcement-policy-details'
import {useEnforcementPolicy} from '../../../contexts/enforcement-policy/use-enforcement-policy'

export function PolicyDetailsModal(): JSX.Element {
    const {width} = useDimensions()
    const {loadingRequestedIncidentsDataState} = useIncidentsPolicy()
    const {loadingRequestedMetricsDataState} = useMetricsPolicy()
    const {loadingRequestedEnforcementDataState} = useEnforcementPolicy()
    const {selectedPolicyType} = usePolicyManagement()

    function dataIsLoaded() {
        if (selectedPolicyType === PolicyType.INCIDENT) {
            return loadingRequestedIncidentsDataState === LoadingState.Loaded
        } else if (selectedPolicyType === PolicyType.METRICS) {
            return loadingRequestedMetricsDataState === LoadingState.Loaded
        } else if (selectedPolicyType === PolicyType.ENFORCEMENT) {
            return loadingRequestedEnforcementDataState === LoadingState.Loaded
        } else {
            return false
        }
    }
    const isLoaded = dataIsLoaded()
    return (
        <ContentWrapper width={width}>
            {isLoaded ? <GetPolicyModal activePolicyType={selectedPolicyType} /> : <DataLoading />}
        </ContentWrapper>
    )
}
interface Props {
    activePolicyType: PolicyType | null
}
function GetPolicyModal({activePolicyType}: Props): JSX.Element {
    switch (activePolicyType) {
        case PolicyType.INCIDENT:
            return <IncidentPolicyDetails />
        case PolicyType.METRICS:
            return <MetricsPolicyDetails />
        case PolicyType.ENFORCEMENT:
            return <EnforcementPolicyDetails />
        default:
            return <div />
    }
}
