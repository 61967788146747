import {TableHeaderCell, TableHeaderContent} from './_styled/header-column.styled'
interface TemporaryColumnProps {
    text?: string
    gridColumn: number
}
export function TemporaryColumn({text, gridColumn}: TemporaryColumnProps): JSX.Element {
    return (
        <TableHeaderCell id={`column_${text}`} gridColumn={gridColumn}>
            <TableHeaderContent>{text}</TableHeaderContent>
        </TableHeaderCell>
    )
}
