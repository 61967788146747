import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {DetailExpandedType, NetworkAssetsFilterReduxState} from './state'

export const networkAssetsFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.networkAssetsFilter.loadingState

export const networkAssetsFilterLoadingSelector = (state: AppState): boolean =>
    state.networkAssetsFilter.loadingState === LoadingState.RequestingData ||
    state.networkAssetsFilter.loadingState === LoadingState.NotPopulated

export const networkAssetsFilterSelector = (state: AppState): NetworkAssetsFilterReduxState =>
    state.networkAssetsFilter

export const selectedFilterNameSelector = (state: AppState): string =>
    state.networkAssetsFilter.selectedFilterName
export const networkAssetsModalDetailExpandedSelector = (state: AppState): DetailExpandedType =>
    state.networkAssetsFilter.detailExpanded
