import CircleXIcon from '../../../@assets/icons/circle-x-blue.svg'
import styled from 'styled-components'
import {Colors} from '../../../theme/colours'

type CloseButtonProps = {
    onClick: () => void
}

const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    color: ${Colors.blue2};
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
`

export function CloseButton({onClick}: CloseButtonProps): JSX.Element {
    return (
        <StyledButton onClick={onClick}>
            <img width={16} height={16} src={CircleXIcon} alt="Close" />
        </StyledButton>
    )
}
