import styled from 'styled-components'

interface SoftwareInventoryDetailsProps {
    activeSoftware: boolean
}

export const SoftwareInventoryDetails = styled.div<SoftwareInventoryDetailsProps>`
    color: ${(props) => props.theme.unknownAssets.dataRow.text};
    overflow: auto;
    ${(props) =>
        props.activeSoftware
            ? `border: 3px solid ${props.theme.softwareInventory.dataRow.selectedRow}`
            : ''};
`
