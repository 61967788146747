import createCachedSelector from 're-reselect'
import {latestLocationThreatScoreSelector} from '../../../../../../../../store/state/latest-location-threat-scores/selectors'
import {getLocationScore} from '../../../../../../../../store/state/locations/helpers'
import {locationMapSelector} from '../../../../../../../../store/state/locations/selectors'
import {Location, LocationIdType} from '../../../../../../../../store/state/locations/state'
import {nodesForLocationsSelector} from '../../../../../../../../store/state/nodes/selectors'
import {
    VesselTagsAPI,
    formatVesseltagsForUI,
} from '../../../../../../../../store/state/vessel-tags/helper'
import {vesselTagsForLocationsSelector} from '../../../../../../../../store/state/vessel-tags/selectors'
import {VesselTags} from '../../../../../../../../store/state/vessel-tags/state'
import AppState from '../../../../../../../../store/types/app-state'
import {VesselDetailsOutputModel} from './vessel-details.model'

function formatVesselMetadata(location: Location): string {
    if (!location || !location.customerLocationCode) {
        return 'IMO Unknown'
    }

    return `IMO ${location.customerLocationCode}`
}

function sortedTagsForLocation(vesselTags: VesselTagsAPI[] | undefined): VesselTags[] {
    if (!vesselTags) {
        return []
    }
    return [...vesselTags]
        ?.map((element) => formatVesseltagsForUI(element))
        .sort((a: VesselTags, b: VesselTags) => a.name?.localeCompare(b.name))
}

export const vesselDetailsCachedReselector = createCachedSelector(
    [
        locationMapSelector,
        nodesForLocationsSelector,
        latestLocationThreatScoreSelector,
        vesselTagsForLocationsSelector,
        (_state: AppState, locationId: LocationIdType) => locationId,
    ],
    (
        locations,
        nodesForLocations,
        latestLocationThreatScores,
        vesselTagsForLocations,
        locationId,
    ) => {
        const location: Location | undefined = locations.get(locationId)
        if (!location) {
            throw Error('Could not find location')
        }
        const score = getLocationScore(location.location, latestLocationThreatScores)

        return {
            id: locationId,
            name: location.description ?? 'Unknown',
            shipType: location.type ?? 'Unknown',
            imoNumber: formatVesselMetadata(location),
            numberOfAssets: nodesForLocations.get(location.location)?.length || 0,
            score: score,
            vesselTags: sortedTagsForLocation(vesselTagsForLocations.get(location.location)),
        } as VesselDetailsOutputModel
    },
)((_state, locationId) => locationId)
