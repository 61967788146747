import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'
import {smallFont} from '../../../../../../theme/font-styles'
import {CyberOwlTheme} from '../../../../../../theme/theme'

interface TableDataRowProps {
    gridRow: number
    span?: number
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.systemPage.dataRow.text};
    background-color: ${(props) => props.theme.systemPage.dataRow.background};
    min-height: 64px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    extrabold?: boolean
    inset?: boolean
    centered?: boolean
}

function getFontWeight(theme: CyberOwlTheme, extraBold: boolean): number {
    return extraBold ? theme.font.weight.extrabold : theme.font.weight.normal
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: ${(props) => (props.inset ? spacing(2) : 0)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => getFontWeight(props.theme, props.extrabold || false)};
`
export const TimestampValue = styled.div`
    display: flex;
    justify-content: flex-start;
`
export const TableDataCellSpan = styled.div`
    margin-left: ${spacing(1)};
`
interface BlankRowProps {
    gridRow: number
}
export const BlankRow = styled.div<BlankRowProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column-start: 1;
    grid-column-end: -1;
    height: ${spacing(2)};
`
