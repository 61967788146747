import styled from 'styled-components'

import {spacing} from '../../../../../theme/spacing'

export const PopupContainer = styled.div`
    display: inline-block;
    position: fixed;
    margin-top: ${spacing(1)};
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.background.default};
    padding: 3px 3px 0 3px;
    pointer-events: none;
`
