import styled from 'styled-components'
import {spacing} from '../../../../../../theme/spacing'

export const Section = styled.section`
    width: 100%;
`

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(3)};
    margin: 0;
    border-top: 1px solid ${(props) => props.theme.myVessels.filterBar.titleSeparator};
    width: 100%;
`

export const SectionContent = styled.div`
    padding: ${spacing(3)} ${spacing(4)};
    margin: 0;
    width: 100%;
`

export const SectionTitle = styled.h3`
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.42px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    flex-flow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
`
