import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'

interface RecordSetProps {
    width: number
}
export const RecordSet = styled.div<RecordSetProps>`
    margin-top: ${(props) => (props.width <= 600 ? spacing(2) : 0)};
    ${smallFont()}
    color: ${(props) => props.theme.colors.text.default};
`
export const Value = styled.span`
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
