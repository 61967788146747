import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const NoDataLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NoDataMessage = styled.div`
    display: flex;
    justify-content: center;
    padding: ${spacing(10)} 0;
    ${mediumSmallFont()};
`

export const BackButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 7px 13px;
    ${smallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-transform: uppercase;
    &:hover {
        background-color: rgba(31, 136, 229, 0.1);
    }
`
