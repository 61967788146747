import useTypedSelector from '../../../../hooks/use-typed-selector'
import {myVesselsZoomLevelSelector} from '../../../../store/state/my-vessels-filters/selectors'
import {
    ZOOM_LEVEL,
    ZOOM_LEVEL_TYPE,
} from '../../../../store/state/my-vessels-filters/types/my-vessel-filter-state'
import {filteredVesselIds} from '../reselectors/filtered-vessel-ids.reselector'
import {ZoomLevelGrid as StyledGrid} from '../components/common/grid.styled'
import {LocationIdType} from '../../../../store/state/locations/state'
import {ZoomLevel1Cell} from './zoom-level-1/cell'
import {ZoomLevel2Cell} from './zoom-level-2/cell'
import {ZoomLevel4Cell} from './zoom-level-4/cell'
import {ZoomLevel3Cell} from './zoom-level-3/cell'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {queryStringObjectSelector} from '../../../../store/routerSelectors'
import {EditAssetModal} from '../../../../modals/asset-details/components/edit-asset-modal/edit-asset-modal'

export function ZoomLevelGrid(): JSX.Element {
    const zoomLevel = useTypedSelector(myVesselsZoomLevelSelector) as ZOOM_LEVEL_TYPE
    const ids = useTypedSelector(filteredVesselIds)
    const queryString = useTypedSelector(queryStringObjectSelector)
    const displayAssetDetailsModal = queryString?.hasOwnProperty('editAsset')

    const {width} = useDimensions()

    return (
        <>
            <StyledGrid zoomLevel4={zoomLevel === ZOOM_LEVEL.FOUR} width={width}>
                {ids.map((id) => getZoomLevelCell(zoomLevel, id))}
            </StyledGrid>
            {displayAssetDetailsModal && <EditAssetModal />}
        </>
    )
}

function getZoomLevelCell(zoomLevel: ZOOM_LEVEL, id: LocationIdType): JSX.Element {
    switch (zoomLevel) {
        case '4':
            return <ZoomLevel4Cell key={id} id={id} />

        case '3':
            return <ZoomLevel3Cell key={id} id={id} />

        case '2':
            return <ZoomLevel2Cell key={id} id={id} />

        case '1':
        default:
            return <ZoomLevel1Cell key={id} id={id} />
    }
}
