import {batch, useDispatch} from 'react-redux'
import * as Styled from './inventory-items.styled'
import {Link} from 'react-router-dom'
import {toggleVesselForVesselBetaInterlinking} from '../../../../../store/state/vessel-filter/action-creators'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {logInterlinksClickForVesselsBeta} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'
import ASSETS from '../../../icons/assets_icon.svg'
import USB_DEVICES from '../../../icons/usbDevices_icon.svg'
import SOFTWARE from '../../../icons/software_icon.svg'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {analysisPeriodVesselsBetaFilterSelector} from '../../../../../store/state/vessels-beta-filter/selectors'

interface InventoryItemsProps {
    totalAssets: number | undefined
    totalUsbDevices: number | undefined
    totalSoftware: number | undefined
    locationId: string
    stateOfItems: 'total' | 'trusted' | 'untrusted' | 'new'
}

export function InventoryItems({
    totalAssets,
    totalUsbDevices,
    totalSoftware,
    locationId,
    stateOfItems,
}: InventoryItemsProps): JSX.Element {
    const newItems = stateOfItems === 'new'
    function getItemsDisplay(itemsNumber: number, newItems: boolean) {
        return newItems && itemsNumber > 0 ? `+${itemsNumber}` : itemsNumber
    }
    const dispatch = useDispatch()
    const analysisPeriod = useTypedSelector(analysisPeriodVesselsBetaFilterSelector)
    return (
        <Styled.InventoryItemsWrapper>
            {totalAssets && (
                <Styled.InventoryItemWrapper
                    id={`inventory-item-wrapper-totalAssets-${locationId}-${totalAssets}`}
                >
                    <Styled.NumberInventory
                        newItems={newItems}
                        id={`inventory-item-totalAssets-newItems-${locationId}-${totalAssets}`}
                    >
                        {newItems ? (
                            <>{getItemsDisplay(totalAssets, newItems)}</>
                        ) : (
                            <Link
                                onClick={() =>
                                    batch(() => {
                                        dispatch(
                                            toggleVesselForVesselBetaInterlinking(
                                                locationId,
                                                PageType.NETWORK_ASSETS,
                                                analysisPeriod,
                                            ),
                                        )
                                        dispatch(
                                            logInterlinksClickForVesselsBeta(
                                                AuditLogPageType.NETWORK_INVENTORY,
                                                locationId,
                                                `${stateOfItems} inventory`,
                                            ),
                                        )
                                    })
                                }
                                to="/network-assets"
                                style={{color: '#1f88e5'}}
                                id={`inventory-item-link-${locationId}-${totalAssets}-${newItems}`}
                            >
                                {getItemsDisplay(totalAssets, newItems)}
                            </Link>
                        )}
                    </Styled.NumberInventory>
                    <Styled.IconInventory
                        stateOfItems={stateOfItems}
                        src={ASSETS}
                        id={`inventory-item-icon-${locationId}-totalAssets-${stateOfItems}`}
                    />
                </Styled.InventoryItemWrapper>
            )}
            {totalUsbDevices && (
                <Styled.InventoryItemWrapper
                    id={`inventory-item-wrapper-totalUsbDevices-${locationId}-${totalUsbDevices}`}
                >
                    <Styled.NumberInventory
                        newItems={newItems}
                        id={`inventory-item-totalUsbDevices-newItems-${locationId}-${totalUsbDevices}`}
                    >
                        {newItems ? (
                            <>{getItemsDisplay(totalUsbDevices, newItems)}</>
                        ) : (
                            <Link
                                onClick={() =>
                                    batch(() => {
                                        dispatch(
                                            toggleVesselForVesselBetaInterlinking(
                                                locationId,
                                                PageType.USB_DEVICES,
                                                analysisPeriod,
                                            ),
                                        )
                                        dispatch(
                                            logInterlinksClickForVesselsBeta(
                                                AuditLogPageType.USB_INVENTORY,
                                                locationId,
                                                `${stateOfItems} inventory`,
                                            ),
                                        )
                                    })
                                }
                                to="/usb-devices"
                                style={{color: '#1f88e5'}}
                                id={`inventory-item-link-${locationId}-${totalUsbDevices}-${newItems}`}
                            >
                                {getItemsDisplay(totalUsbDevices, newItems)}
                            </Link>
                        )}
                    </Styled.NumberInventory>
                    <Styled.IconInventory
                        stateOfItems={stateOfItems}
                        src={USB_DEVICES}
                        id={`inventory-item-icon-${locationId}-totalUsbDevices-${stateOfItems}`}
                    />
                </Styled.InventoryItemWrapper>
            )}
            {totalSoftware && (
                <Styled.InventoryItemWrapper
                    id={`inventory-item-wrapper-totalSoftware-${locationId}-${totalSoftware}`}
                >
                    <Styled.NumberInventory
                        newItems={newItems}
                        id={`inventory-item-totalSoftware-newItems-${locationId}-${totalSoftware}`}
                    >
                        {newItems ? (
                            <>{getItemsDisplay(totalSoftware, newItems)}</>
                        ) : (
                            <Link
                                onClick={() =>
                                    batch(() => {
                                        dispatch(
                                            toggleVesselForVesselBetaInterlinking(
                                                locationId,
                                                PageType.SOFTWARE_INVENTORY,
                                                analysisPeriod,
                                            ),
                                        )
                                        dispatch(
                                            logInterlinksClickForVesselsBeta(
                                                AuditLogPageType.SOFTWARE_INVENTORY,
                                                locationId,
                                                `${stateOfItems} inventory`,
                                            ),
                                        )
                                    })
                                }
                                to="/software"
                                style={{color: '#1f88e5'}}
                                id={`inventory-item-link-${locationId}-${totalSoftware}-${newItems}`}
                            >
                                {getItemsDisplay(totalSoftware, newItems)}
                            </Link>
                        )}
                    </Styled.NumberInventory>
                    <Styled.IconInventory
                        stateOfItems={stateOfItems}
                        src={SOFTWARE}
                        id={`inventory-item-icon-${locationId}-totalSoftware-${stateOfItems}`}
                    />
                </Styled.InventoryItemWrapper>
            )}
        </Styled.InventoryItemsWrapper>
    )
}
