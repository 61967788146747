import {VesselDetailsOutputModel} from '../../../reselectors/vessel-details.model'
import * as Styles from './reduced-width-details-panel.styled'
import {VesselTag} from '../../../../../../components/vessel-tag/vessel-tag'

interface Props {
    model: VesselDetailsOutputModel
}

export function ReducedWidthDetailsPanel({model}: Props): JSX.Element {
    return (
        <>
            <Styles.Title id={`vessel-name-${model.id}`}>{model.name}</Styles.Title>
            <Styles.AssetCount id={`vessel-assets-${model.id}`}>
                {model.numberOfAssets}
            </Styles.AssetCount>
            <Styles.AdditionalInfo>
                <div id={`vessel-type-${model.id}`}>{model.shipType}</div>
                <div id={`vessel-imo-${model.id}`}>IMO {model.imoNumber}</div>
            </Styles.AdditionalInfo>
            <Styles.VesselTaggingRow>
                <Styles.VesselTaggsCell id={`vessel-tags-${model.id}`}>
                    {model.vesselTags?.map((tag, index) => (
                        <VesselTag key={index} tagName={tag.name} showHover={true} />
                    ))}
                </Styles.VesselTaggsCell>
            </Styles.VesselTaggingRow>
        </>
    )
}
