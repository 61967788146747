import {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {DataLoading} from '../../components/data-loading/data-loading'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../hooks/use-typed-selector'
import {configTenantDisplayNameSelector} from '../../store/state/config/selectors'
import {fetchEmailRecipients} from '../../store/state/email-recipient-management/action-creators'
import {loadingSelector} from '../../store/state/email-recipient-management/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'
import {
    hasDataSelector,
    usersLoadingSelector,
    usersLoadingStateSelector,
} from '../../store/state/users/selectors'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import LoadingState from '../../values/loading-state-enum'
import {DataPopulated} from './components/data-populated'
import {Header} from './components/header'
import {NoData} from './components/shared/no-data/no-data'
import {UserManagementProvider} from './context/user-management-provider'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../store/state/audit-log/state'
import {AddNewUserModal} from './components/add-new-user-modal/add-new-user-modal'
import {useUserManagement} from './context/use-user-management'
import * as Styled from './user-management.styled'

export default function UserManagement(): JSX.Element {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.USERS))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <UserManagementProvider>
            <UserManagementPage />
        </UserManagementProvider>
    )
}

function UserManagementPage(): JSX.Element {
    const {width} = useDimensions()
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)
    const isLoading = useTypedSelector(usersLoadingSelector)
    const emailRecipientLoadingState = useTypedSelector(loadingSelector)
    const hasData = useTypedSelector(hasDataSelector)
    const dispatch = useDispatch()
    const tenantDisplayName = useTypedSelector(configTenantDisplayNameSelector)
    const title: string = tenantDisplayName ? tenantDisplayName : 'Medulla'

    const {displayAddNewUserModal} = useUserManagement()

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
        if (emailRecipientLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchEmailRecipients())
        }
    }, [dispatch, emailRecipientLoadingState, usersLoadingState])
    const layoutRef = useRef(null)

    return (
        <FixedPageTemplate>
            <Styled.WideContentHeaderArea width={width}>
                <Header text={`User Management for ${title}`} />
            </Styled.WideContentHeaderArea>
            <Styled.WideContentArea width={width} fullHeight={true}>
                <Styled.ScrollableContent ref={layoutRef} flexGrow>
                    {isLoading ? (
                        <DataLoading />
                    ) : hasData ? (
                        <DataPopulated />
                    ) : (
                        <NoData message={`There are no users for ${title}`} />
                    )}
                </Styled.ScrollableContent>
            </Styled.WideContentArea>
            {displayAddNewUserModal && <AddNewUserModal />}
        </FixedPageTemplate>
    )
}
