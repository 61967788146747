import {PolicyType} from '../../contexts/policy-management/type/policy-management-state'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {AddNewEnforcementPolicyDetailExpanded} from './enforcement-policy-detail-expanded/add-new-enforcement-policy-detail-expanded'
import {AddNewIncindentPolicyDetailExpanded} from './incident-policy-detail-expanded/add-new-incident-policy-detail-expanded'
import {AddNewMetricsPolicyDetailExpanded} from './metrics-policy-detail-expanded/add-new-metrics-policy-detail-expanded'

export function AddNewPolicyDetailsExpanded(): JSX.Element {
    const {selectedPolicyType} = usePolicyManagement()

    switch (selectedPolicyType) {
        case PolicyType.INCIDENT:
            return <AddNewIncindentPolicyDetailExpanded />
        case PolicyType.METRICS:
            return <AddNewMetricsPolicyDetailExpanded />
        case PolicyType.ENFORCEMENT:
            return <AddNewEnforcementPolicyDetailExpanded />
        default:
            return <div />
    }
}
