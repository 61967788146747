import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

interface LabelProps {
    gridArea: string
}

export const Label = styled.label<LabelProps>`
    grid-area: ${(props) => props.gridArea};
    color: ${(props) => props.theme.colors.text.default};
    font-size: 12px;
    line-height: 17px;
    margin: 0;
`
interface ValueProps {
    gridArea: string
    inSameLine: boolean
}

export const Value = styled.div<ValueProps>`
    grid-area: ${(props) => props.gridArea};
    text-transform: capitalize;
    color: ${(props) => props.theme.colors.text.default};
    font-size: 12px;
    line-height: 17px;
    font-weight: ${(props) => props.theme.font.weight.extrabold};
    margin-right: ${(props) => (props.inSameLine ? `${spacing(8)}` : `${spacing(3)}`)};
`
