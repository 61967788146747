import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {useTheme} from 'styled-components'
import {CurrentTrendsTooltip} from './current-incidents-trends-tooltip'
import {IncidentTrendsType} from '../../contexts/types/incidents-report-output'

export function IncidentsTrendsWidget({
    incidentsTrendsBySeverity,
}: {
    incidentsTrendsBySeverity: IncidentTrendsType[]
}): JSX.Element {
    const theme = useTheme()

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight={150}>
            <BarChart
                height={150}
                data={incidentsTrendsBySeverity}
                margin={{
                    top: 0,
                    right: 0,
                    left: -10,
                    bottom: -10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" fontSize="14px" />
                <YAxis
                    fontSize="14px"
                    label={<Label value="INCIDENTS" angle={-90} position="center" dx={-10} />}
                />
                <Tooltip content={<CurrentTrendsTooltip data={incidentsTrendsBySeverity} />} />
                <Bar
                    dataKey="lowSeverityIncidentsCount"
                    fill={theme.incidents.incidentSeverity.active.low}
                    name="Low severity"
                />
                <Bar
                    dataKey="mediumSeverityIncidentsCount"
                    fill={theme.incidents.incidentSeverity.active.medium}
                    name="Medium severity"
                />
                <Bar
                    dataKey="highSeverityIncidentsCount"
                    fill={theme.incidents.incidentSeverity.active.high}
                    name="High severity"
                />
                <Bar
                    dataKey="criticalSeverityIncidentsCount"
                    fill={theme.incidents.incidentSeverity.active.critical}
                    name="Critical severity"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
