import {DetailsSection} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/details-section/details-section'
import {
    DetailsExpandedSection,
    PolicyNameWrapper,
} from './enforcement-policy-detail-expanded.styled'
import {PolicyName} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/policy-name/policy-name'
import {ButtonsArea} from '../../policy-list-table/policy-details-modal/enforcement-policy-details/buttons-area/buttons-area'
import {useEnforcementPolicy} from '../../../contexts/enforcement-policy/use-enforcement-policy'
import {NoDataModal} from '../../policy-list-table/policy-details-modal/no-data-modal'

export function EnforcementPolicyDetailExpanded(): JSX.Element {
    const {selectedEnforcementPolicyId} = useEnforcementPolicy()
    if (!selectedEnforcementPolicyId) {
        return <NoDataModal />
    }

    return (
        <DetailsExpandedSection>
            <PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea />
            </PolicyNameWrapper>
            <DetailsSection />
        </DetailsExpandedSection>
    )
}
