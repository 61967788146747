import styled from 'styled-components'
import {mediumFont, mediumSmallFont} from '../../../../theme/font-styles'
import {spacing} from '../../../../theme/spacing'
import {narrowLayoutFn} from '../../incidents-report-page.styled'

interface Props {
    width: number
}
export const AssignmentsWrapper = styled.div<Props>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${spacing(1)};
    ${(props) => (narrowLayoutFn(props.width) ? '' : 'width: 160px')};
    color: ${(props) => props.theme.incidents.dataRow.text};
`

export const AssignmentCount = styled.a`
    padding-left: ${spacing(1)};
    ${mediumFont()};
    color: ${(props) => props.theme.colors.link.primary};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    pointer-events: none;
`
export const AssignmentText = styled.h2`
    ${mediumSmallFont()};
    margin: 0;
    color: ${(props) => props.theme.colors.text.default};
    font-weight: ${(props) => props.theme.font.weight.semibold};
    text-align: center;
`
export const ImgCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
