import {CyberOwlTheme} from '../theme'
import {NodeValues} from '../../values/nodes/NodeData'
import {ThreatValue, ThreatValues} from '../../helpers/threatHelper'

export function getBackgroundColor(
    theme: CyberOwlTheme,
    score: number | undefined,
    value: NodeValues | undefined,
    threatLevel: ThreatValue,
): string {
    if (score == undefined) {
        return theme.threatScores.score.unknown.background
    }

    if (threatLevel === ThreatValues.HIGH) {
        return theme.threatScores.score.high.background
    }

    if (threatLevel === ThreatValues.MEDIUM) {
        return theme.threatScores.score.medium.background
    }

    switch (value) {
        case NodeValues.HIGH:
            return theme.threatScores.score.lowScoreHighValue.background
        case NodeValues.MEDIUM:
            return theme.threatScores.score.lowScoreMediumValue.background
        case NodeValues.LOW:
        default:
            return theme.threatScores.score.lowScoreLowValue.background
    }
}

export function getColor(
    theme: CyberOwlTheme,
    score: number | undefined,
    value: NodeValues | undefined,
    threatLevel: ThreatValue,
): string {
    if (score == undefined) {
        return theme.threatScores.score.unknown.text
    }

    if (threatLevel === ThreatValues.HIGH) {
        return theme.threatScores.score.high.text
    }

    if (threatLevel === ThreatValues.MEDIUM) {
        return theme.threatScores.score.medium.text
    }

    switch (value) {
        case NodeValues.HIGH:
            return theme.threatScores.score.lowScoreHighValue.text
        case NodeValues.MEDIUM:
            return theme.threatScores.score.lowScoreMediumValue.text
        case NodeValues.LOW:
        default:
            return theme.threatScores.score.lowScoreLowValue.text
    }
}
