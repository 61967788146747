import {ActionType} from './action-type'
import {DEFAULT_METRICS_FILTERS, MetricsWidgetFilter} from '../types/metrics-widget-filter'
import {REST} from '../../../../../../index'
import * as Actions from './actions'
import {TimestampFilterType} from '../../../../../../store/state/metrics-filter-beta/state'
import {formattedLocationsBlockSet} from '../../../../../../utils/Utils'
import {MetricsWidgetType} from '../types/metrics-widget-type'
import {Dispatch} from 'react'
import {MetricSummmaryMap} from '../../../../../metrics-beta/contexts/types/metrics-summary'

const USER_PREFS_URL = '/api/v1/userPreferences/metrics_widget'

export const fetchUserPrefsIfExistForDashboard = async (
    dispatch: Dispatch<Actions.AllActions>,
    location: string | null,
    assignedTagName?: string,
): Promise<void> => {
    dispatch(requestFilter())

    try {
        const response = await REST.get(USER_PREFS_URL)
        const outputResult: MetricsWidgetFilter = {
            locations: formattedLocationsBlockSet([location]),
            searchVesselTagTerm: assignedTagName ? [assignedTagName] : [],
            selectedAnalysisPeriod: response.data.value.selectedAnalysisPeriod ?? '30d',
            selectedMetricWidget:
                response.data.value.selectedMetricWidget ?? MetricsWidgetType.SCORECARD_SUMMARY,
        }
        dispatch(receivePrefsFilter(outputResult))
    } catch (error) {
        REST.put(USER_PREFS_URL, DEFAULT_METRICS_FILTERS)
        dispatch(receivePrefsFilter(DEFAULT_METRICS_FILTERS))
    }
}

function receivePrefsFilter(
    receivedMetricsWidgetFiler: MetricsWidgetFilter,
): Actions.ReceivePrefsFilterAction {
    return {
        type: ActionType.RECEIVE_PREFS_FILTER,
        payload: receivedMetricsWidgetFiler,
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function showFilter(show = false): Actions.ShowFilterAction {
    return {
        type: ActionType.SHOW_FILTER,
        payload: show,
    }
}

export function setTimeRange(
    selectedAnalysisPeriod: TimestampFilterType,
    dispatch: Dispatch<Actions.RequestTimeRange>,
    currentFilter: MetricsWidgetFilter,
): void {
    dispatch({
        type: ActionType.SET_TIME_RANGE,
        payload: selectedAnalysisPeriod,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, selectedAnalysisPeriod: selectedAnalysisPeriod})
}

export function setMetricWidget(
    selectedMetricWidget: MetricsWidgetType,
    dispatch: Dispatch<Actions.SetMetricWidgetType>,
    currentFilter: MetricsWidgetFilter,
): void {
    dispatch({
        type: ActionType.SET_METRICS_WIDGET,
        payload: selectedMetricWidget,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, selectedMetricWidget: selectedMetricWidget})
}

export function setFilter(): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER}
}

export function receivedRequestedWidgetData(
    metricsWidgetData: MetricSummmaryMap | undefined,
    locationSelectionNumber: number,
): Actions.ReceivedRequestedWidgetDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_WIDGET_DATA,
        payload: {metricsWidgetData, locationSelectionNumber},
    }
}
