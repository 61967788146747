import {isLoadingSelector} from '../../../store/state/watched-nodes/selectors'
import usedTypeSelector from '../../../hooks/use-typed-selector'

import {GuidType} from '../../../values/generic-type-defintions'
import {WatchlistUpdating} from './watchlist-updating'
import {ClickableButton} from './clickable-button'
import {useState} from 'react'

interface WatchListButtonProps {
    assetId: GuidType
}

export function WatchListButton({assetId}: WatchListButtonProps): JSX.Element | null {
    const [hasLoaded, setHasLoaded] = useState(false)
    const isLoading = usedTypeSelector(isLoadingSelector)

    if (isLoading) {
        if (!hasLoaded) {
            return null
        }

        return <WatchlistUpdating />
    }

    if (!hasLoaded) {
        setHasLoaded(true)
    }

    return <ClickableButton assetId={assetId} />
}
