import {ToggleLeft, ToggleRight} from 'react-feather'
import * as Styled from './_styled/on-off-toggle.styled'
import {useEffect, useState} from 'react'
import {useMetricsPolicy} from '../../../../contexts/metrics-policy/use-metrics-policy'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {isEqual} from 'lodash'
import {OnOffConfig} from '../../../../contexts/metrics-policy/type/metrics-policy-schema'

interface OnOffToggleConfigProps {
    metricConfigId: GuidType
    configItem: OnOffConfig
    disabled?: boolean
}

export function OnOffSubMetricToggleConfig({
    metricConfigId,
    configItem,
    disabled = false,
}: OnOffToggleConfigProps): JSX.Element {
    const initialOnOffValue = !disabled && (configItem.value ?? configItem.default)
    const [onValue, setOnValue] = useState(initialOnOffValue)
    const {changeSubMetricEnabledValue, discardChanges, requestDiscardChanges} = useMetricsPolicy()
    useEffect(() => {
        if (discardChanges && !isEqual(initialOnOffValue, onValue)) {
            setOnValue(initialOnOffValue)
            requestDiscardChanges(false)
        }
    }, [onValue, discardChanges, initialOnOffValue, requestDiscardChanges])

    return (
        <Styled.Wrapper disabled={disabled}>
            <Styled.Title>{configItem.displayName ?? configItem.name}</Styled.Title>
            <Styled.SwitchToggle>
                <Styled.ToggleButton selectable>
                    {onValue ? (
                        <ToggleRight
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault()
                                    return
                                }
                                const isItChanged = !isEqual(initialOnOffValue, false)
                                setOnValue(false)
                                changeSubMetricEnabledValue(
                                    metricConfigId,
                                    configItem.name,
                                    false,
                                    isItChanged,
                                )
                            }}
                            fill="#1f88e5"
                            color="#f7f7f7"
                            width={27}
                            height={27}
                        />
                    ) : (
                        <ToggleLeft
                            onClick={(e) => {
                                if (disabled) {
                                    e.preventDefault()
                                    return
                                }
                                const isItChanged = !isEqual(initialOnOffValue, true)
                                setOnValue(true)
                                changeSubMetricEnabledValue(
                                    metricConfigId,
                                    configItem.name,
                                    true,
                                    isItChanged,
                                )
                            }}
                            fill="#c9c9c9"
                            color="#f7f7f7"
                            width={27}
                            height={27}
                        />
                    )}
                </Styled.ToggleButton>
            </Styled.SwitchToggle>
        </Styled.Wrapper>
    )
}
