import {TableDataGrid} from './_styled/table-data-grid.styled'
import {usePagedUSBDevices} from '../../contexts/use-paged-usb-devices'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {TableRow} from './table-row'
import {USBDeviceDetailsModal} from '../usb-device-details-modal/usb-device-details-modal'
import {addQueryParam} from '../../../../helpers/navigation'
import {USB_DEVICES_DETAILS} from '../data-helpers'

export function USBDevicesTablePopulated(): JSX.Element {
    const {width} = useDimensions()
    const {dataUSBDevices, showFilterBar, modalIdForUSBDetails} = usePagedUSBDevices()

    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flex: 1}}>
            <TableDataGrid
                width={width}
                showReducedGrid={showFilterBar || modalIdForUSBDetails !== null}
                id="table-data-grid-style"
            >
                {dataUSBDevices?.map((usbDevice, index) => (
                    <TableRow
                        key={`${usbDevice.identity}`}
                        usbDevice={usbDevice}
                        index={index}
                        onClickFn={() => {
                            addQueryParam(USB_DEVICES_DETAILS, usbDevice.identity)
                        }}
                    />
                ))}
            </TableDataGrid>
            {modalIdForUSBDetails !== null && <USBDeviceDetailsModal />}
        </div>
    )
}
