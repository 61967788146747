export enum ActionType {
    SET_CHANGE_TYPE = '[VESSEL CONFIG CONTEXT] SET CHANGE VESSEL TYPE',
    SET_CHANGE_CUSTOMER_LOCATION_CODE = '[VESSEL CONFIG CONTEXT] SET CHANGE CUSTOMER LOCATION CODE',
    SET_CHANGE_BUSINESS_NETWORKS = '[VESSEL CONFIG CONTEXT] SET CHANGE BUSINESS_NETWORKS',
    SET_CHANGE_OT_NETWORKS = '[VESSEL CONFIG CONTEXT] SET CHANGE OT_NETWORKS',
    SET_CHANGE_CREW_NETWORKS = '[VESSEL CONFIG CONTEXT] SET CHANGE CREW_NETWORKS',
    SET_CHANGE_GATEWAY_IP = '[VESSEL CONFIG CONTEXT] SET CHANGE GATEWAY IP',
    SET_CHANGE_GATEWAY_MAC = '[VESSEL CONFIG CONTEXT] SET CHANGE GATEWAY MAC',
    SET_CHANGE_INCUS_IP = '[VESSEL CONFIG CONTEXT] SET CHANGE INCUS IP',
    SET_CHANGE_INCUS_MAC = '[VESSEL CONFIG CONTEXT] SET CHANGE INCUS MAC',
    SET_CHANGE_SENSOR_IP = '[VESSEL CONFIG CONTEXT] SET CHANGE SENSOR IP',
    SET_CHANGE_SENSOR_MAC = '[VESSEL CONFIG CONTEXT] SET CHANGE SENSOR MAC',
    SET_CHANGE_OT_DEVICES = '[VESSEL CONFIG CONTEXT] SET CHANGE OT DEVICES',
    SET_CHANGE_OT_CROSSINGS = '[VESSEL CONFIG CONTEXT] SET CHANGE OT CROSSINGS',
    SET_CHANGE_BUSINESS_DEVICES = '[VESSEL CONFIG CONTEXT] SET CHANGE BUSINESS DEVICES',
    SET_CHANGE_BUSINESS_CROSSINGS = '[VESSEL CONFIG CONTEXT] SET CHANGE BUSINESS CROSSINGS',
    SET_CHANGE_EMAIL_HOSTS = '[VESSEL CONFIG CONTEXT] SET CHANGE EMAIL HOSTS',
    SET_CHANGE_HOST_LOG_EXCEPTIONS = '[VESSEL CONFIG CONTEXT] SET CHANGE HOST LOG EXCEPTIONS',
    SET_CHANGE_NETWORK_LOG_EXCEPTIONS = '[VESSEL CONFIG CONTEXT] SET CHANGE NETWORK LOG EXCEPTIONS',
    SET_CHANGE_USB_HOSTS = '[VESSEL CONFIG CONTEXT] SET CHANGE USB HOSTS',
    SET_CHANGE_USB_DEVICES = '[VESSEL CONFIG CONTEXT] SET CHANGE USB DEVICES',
    SET_CHANGE_ADMIN_LOGONS = '[VESSEL CONFIG CONTEXT] SET CHANGE ADMIN LOGONS',
    SET_CHANGE_REMOTE_ACCESS = '[VESSEL CONFIG CONTEXT] SET CHANGE REMOTE ACCESS',
    SET_CHANGE_ENCRYPTION_RATIO = '[VESSEL CONFIG CONTEXT] SET CHANGE ENCRYPTION RATIO',
    SET_CHANGE_MONITORING_COVERAGE = '[VESSEL CONFIG CONTEXT] SET CHANGE MONITORING COVERAGE',
    SET_CHANGE_MAX_OS_AGE = '[VESSEL CONFIG CONTEXT] SET CHANGE MAX OS AGE',
    SET_CHANGE_MAX_SCORE = '[VESSEL CONFIG CONTEXT] SET CHANGE MAX SCORE',
    CHANGES_SAVED = '[VESSEL CONFIG CONTEXT] CHANGES SAVED',
    SET_SAVE_RESULT_MESSAGE = '[VESSEL CONFIG CONTEXT] SET SAVE RESULT MESSAGE',
    RESET_CHANGES = '[VESSEL CONFIG CONTEXT] RESET CHANGES',
    BULK_ASSIGN_CONFIG = '[VESSEL CONFIG CONTEXT] BULK ASSIGN CONFIG',
}
