import styled from 'styled-components'
import {mediumSmallFont, smallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const FilterContent = styled.div`
    display: flex;
    align-items: center;
`
export const Actions = styled.div`
    display: flex;
    flex-direction: column;
`

export const PrimaryButton = styled.button`
    text-align: left;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 3px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 4px 8px;
    &:hover {
        color: ${(props) => props.theme.refreshButton.hover.textColor};
        background-color: ${(props) => props.theme.refreshButton.hover.backgroundColor};
    }
    &:active {
        color: ${(props) => props.theme.refreshButton.active.textColor};
        background-color: ${(props) => props.theme.refreshButton.active.backgroundColor};
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
    ${mediumSmallFont()}
`

export const SecondaryButton = styled.button`
    text-align: left;
    color: ${(props) => props.theme.refreshButton.default.textColor};
    border-radius: 4px;
    border: 3px solid ${(props) => props.theme.refreshButton.default.borderColor};
    background: none;
    padding: 4px 8px;
    &:hover {
        border-color: ${(props) => props.theme.refreshButton.active.borderColor};
    }
    &:active {
        border-color: #${(props) => props.theme.refreshButton.active.borderColor};
    }
    ${mediumSmallFont()}
`

export const FilterDetails = styled.div`
    display: flex;
    flex-direction: column;
    ${smallFont()}
    margin-right: ${spacing(4)};
`

export const Title = styled.h3`
    ${mediumSmallFont()}
    padding: 0;
    margin: 0 0 ${spacing(1)} 0;
`

export const DetailRow = styled.div`
    margin: ${spacing(0.5)} 0;
`

export const Label = styled.label`
    font-size: 10px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    width: 30px;
`
