import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {AuditEntry} from './state'

export const isLoadingSelector = (state: AppState): boolean =>
    state.engineStatus.loading === LoadingState.NotPopulated ||
    state.engineStatus.loading === LoadingState.RequestingData

export const loadingStateSelector = (state: AppState): LoadingState | undefined =>
    state.engineStatus.loading

export const auditLogDataSelector = (state: AppState): AuditEntry[] | undefined =>
    state.auditLog.data
