import {isEqual} from 'lodash'
import {
    NetworkAssetsFilterReduxState,
    NetworkAssetsSortType,
} from '../../../store/state/network-assets-filter/state'
import {NetworkAssetsFilter} from '../../../values/user-preferences/network-assets-filter'
import {NetworkAssetState} from '../context/types/isolated-assets-type'
import {NetworkType} from '../context/types/network-type'
import {GuidType} from '../../../values/generic-type-defintions'

export class FormattedNewFilterModel {
    public readonly orderBy: {column: NetworkAssetsSortType; isAscending: boolean}
    public readonly states: NetworkAssetState[] | undefined
    public readonly networks: NetworkType[] | undefined
    public readonly behaviours: GuidType[] | undefined
    public readonly properties: string | undefined
    public readonly fromRelativeLastSeen: string | undefined

    public constructor(newFilter: NetworkAssetsFilterReduxState | NetworkAssetsFilter) {
        this.orderBy =
            newFilter.orderBy.column === NetworkAssetsSortType.AIMEE_TIMESTAMP
                ? {
                      column: NetworkAssetsSortType.TIMESTAMP,
                      isAscending: newFilter.orderBy.isAscending,
                  }
                : newFilter.orderBy
        this.states = newFilter.states
        this.networks = newFilter.networks
        this.behaviours = newFilter.behaviours
        this.properties = newFilter.properties
        this.fromRelativeLastSeen = newFilter.fromRelativeLastSeen
    }
}

export function compareFilters(
    currentFilter: NetworkAssetsFilter,
    newFilter: NetworkAssetsFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel({
        ...currentFilter,
        properties: currentFilter.vlan ?? currentFilter.macAddress,
    })

    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}
