import {useMetricsPolicy} from '../../../contexts/metrics-policy/use-metrics-policy'
import {ButtonsArea} from '../../policy-list-table/policy-details-modal/metrics-policy-details/buttons-area/buttons-area'
import {PolicyName} from '../../policy-list-table/policy-details-modal/metrics-policy-details/policy-name/policy-name'
import {TabSection} from '../../policy-list-table/policy-details-modal/metrics-policy-details/tab-section/tab-section'
import {NoDataModal} from '../../policy-list-table/policy-details-modal/no-data-modal'
import * as Styled from './add-new-metrics-policy-expanded.styled'
export function MetricsPolicyDetailExpanded(): JSX.Element {
    const {selectedMetricPolicyId} = useMetricsPolicy()
    if (!selectedMetricPolicyId) {
        return <NoDataModal />
    }
    return (
        <Styled.DetailsExpandedSection>
            <Styled.PolicyNameWrapper>
                <PolicyName />
                <ButtonsArea />
            </Styled.PolicyNameWrapper>
            <Styled.ContentWrapper>
                <TabSection />
            </Styled.ContentWrapper>
        </Styled.DetailsExpandedSection>
    )
}
