import * as Styled from './saved-filter-alert-row.styled'
import {AlertDetails} from './alert-details'
import {SavedFilterSubscription} from '../../../../../../store/state/saved-filters/state'

interface Props {
    subscriptionData: SavedFilterSubscription
}

export function SavedFilterAlertRowReadOnly({subscriptionData}: Props): JSX.Element {
    return (
        <Styled.SavedFilterWrapper>
            <Styled.NameRow>
                <Styled.FilterName>{subscriptionData.searchDefinition.name}</Styled.FilterName>
            </Styled.NameRow>
            <Styled.Details>
                <AlertDetails subscriptionData={subscriptionData} />
            </Styled.Details>
        </Styled.SavedFilterWrapper>
    )
}
