import {GridLayout} from '../../../general/grid-layout.enum'

export function getOpacity(gridLayout: GridLayout): number {
    switch (gridLayout) {
        case GridLayout.THREE_COLUMNS_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.ONE_COLUMN_SMALL:
            return 1
        default:
            return 0.3
    }
}
