import produce from 'immer'
import {UserManagementState} from '../type/user-management-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import {DEFAULT_USER_MANAGEMENT_STATE} from '../type/default-user-management-state'
import {isEqual} from 'lodash'
import LoadingState from '../../../../values/loading-state-enum'
import {StrictMode} from '../../../../store/state/users/state'

export const userManagementReducer = produce(
    (draft: UserManagementState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SHOW_USER_DETAIL_MODAL:
                draft.activeUserId = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_USER_DETAILS:
                draft.userDetails = action.payload
                draft.initialUserDetails = action.payload
                draft.displayUserDetails = action.payload
                draft.loadingDataState = LoadingState.Loaded
                break

            case ActionType.CLOSE_USER_DETAIL_MODAL:
                draft.activeUserId = DEFAULT_USER_MANAGEMENT_STATE.activeUserId
                draft.initialUserDetails = DEFAULT_USER_MANAGEMENT_STATE.initialUserDetails
                draft.userDetails = DEFAULT_USER_MANAGEMENT_STATE.userDetails
                draft.displayUserDetails = DEFAULT_USER_MANAGEMENT_STATE.displayUserDetails
                draft.subscriptionStatus = DEFAULT_USER_MANAGEMENT_STATE.subscriptionStatus
                draft.isSubscriptionChanged = DEFAULT_USER_MANAGEMENT_STATE.isSubscriptionChanged
                draft.isChanged = DEFAULT_USER_MANAGEMENT_STATE.isChanged
                draft.isRoleChanged = DEFAULT_USER_MANAGEMENT_STATE.isRoleChanged
                draft.saveResultMessage = DEFAULT_USER_MANAGEMENT_STATE.saveResultMessage
                draft.disableUserResultMessage =
                    DEFAULT_USER_MANAGEMENT_STATE.disableUserResultMessage
                draft.isSaveProcessing = DEFAULT_USER_MANAGEMENT_STATE.isSaveProcessing
                draft.currentSelectedAlertId = DEFAULT_USER_MANAGEMENT_STATE.currentSelectedAlertId
                break
            case ActionType.SET_CHANGE_USER_NAME:
                draft.displayUserDetails.username = action.payload
                draft.userDetails.username = action.payload.trim()
                draft.isChanged = !isEqual(
                    draft.initialUserDetails.username,
                    draft.userDetails.username,
                )
                break
            case ActionType.SET_CHANGE_USER_EMAIL:
                draft.displayUserDetails.email = action.payload
                draft.userDetails.email = action.payload.trim()
                draft.isChanged = !isEqual(draft.initialUserDetails.email, draft.userDetails.email)
                break
            case ActionType.SET_CHANGE_NOTIFICATION_EMAIL:
                draft.displayUserDetails.notificationEmail =
                    action.payload.length === 0 ? undefined : action.payload
                draft.userDetails.notificationEmail =
                    action.payload.trim().length === 0 ? undefined : action.payload.trim()
                draft.isChanged = !isEqual(
                    draft.initialUserDetails.notificationEmail,
                    draft.userDetails.notificationEmail,
                )
                break
            case ActionType.SET_ASSIGNED_TAG_ID:
                draft.displayUserDetails.assignedTag = action.payload
                draft.userDetails.assignedTag = action.payload
                if (!action.payload) {
                    draft.displayUserDetails.strictMode = undefined
                    draft.userDetails.strictMode = undefined
                } else {
                    draft.displayUserDetails.strictMode = StrictMode.DefaultVisibility
                    draft.userDetails.strictMode = StrictMode.DefaultVisibility
                }
                draft.isChanged = !isEqual(
                    draft.initialUserDetails.assignedTag,
                    draft.userDetails.assignedTag,
                )
                break
            case ActionType.SET_TAG_STRICT_MODE:
                draft.displayUserDetails.strictMode = action.payload
                draft.userDetails.strictMode = action.payload
                draft.isChanged =
                    !isEqual(draft.initialUserDetails.strictMode, draft.userDetails.strictMode) ||
                    !isEqual(draft.initialUserDetails.assignedTag, draft.userDetails.assignedTag)
                break
            case ActionType.ASSIGN_USER_ROLE:
                draft.displayUserDetails.roles.push(action.payload)
                draft.userDetails.roles.push(action.payload)
                draft.isRoleChanged = !isEqual(
                    draft.userDetails.roles.sort(),
                    draft.initialUserDetails.roles.sort(),
                )
                break
            case ActionType.DEASSIGN_USER_ROLE:
                draft.displayUserDetails.roles = draft.displayUserDetails.roles.filter(
                    (role) => role !== action.payload,
                )
                draft.userDetails.roles = draft.userDetails.roles.filter(
                    (role) => role !== action.payload,
                )
                draft.isRoleChanged = !isEqual(
                    draft.userDetails.roles.sort(),
                    draft.initialUserDetails.roles.sort(),
                )
                break
            case ActionType.SET_SUBSCRIPTION:
                draft.subscriptionStatus.initialSubscriptionStatus =
                    action.payload.initialSubscriptionStatus
                draft.subscriptionStatus.newStatus = action.payload.newStatus
                draft.isSubscriptionChanged =
                    action.payload.initialSubscriptionStatus !== action.payload.newStatus
                draft.subscriptionStatus.userId = action.payload.userId
                draft.subscriptionStatus.emailId = action.payload.emailId
                break
            case ActionType.CHANGES_SAVED:
                draft.initialUserDetails = draft.userDetails
                draft.displayUserDetails = draft.initialUserDetails
                draft.subscriptionStatus = DEFAULT_USER_MANAGEMENT_STATE.subscriptionStatus
                draft.isChanged = false
                draft.isRoleChanged = false
                draft.isSubscriptionChanged = false
                draft.saveResultMessage = 'Saved successfully'
                break
            case ActionType.USER_DISABLED:
                draft.initialUserDetails = draft.userDetails
                draft.displayUserDetails = draft.initialUserDetails
                draft.isChanged = false
                draft.isRoleChanged = false
                draft.isSubscriptionChanged = false
                draft.disableUserResultMessage = 'Disabled successfully'
                break
            case ActionType.USER_ENABLED:
                draft.initialUserDetails = draft.userDetails
                draft.displayUserDetails = draft.initialUserDetails
                draft.isChanged = false
                draft.isRoleChanged = false
                draft.isSubscriptionChanged = false
                draft.disableUserResultMessage = 'Enabled successfully'
                break
            case ActionType.SET_SAVE_RESULT_MESSAGE:
                draft.saveResultMessage = action.payload
                break
            case ActionType.SET_DISABLE_USER_RESULT_MESSAGE:
                draft.disableUserResultMessage = action.payload
                break
            case ActionType.DISPLAY_ADD_NEW_USER_MODAL:
                draft.displayAddNewUserModal = action.payload
                if (!action.payload) {
                    draft.newUserData = DEFAULT_USER_MANAGEMENT_STATE.newUserData
                }
                break
            case ActionType.SET_NEW_USER_NAME:
                draft.newUserData.username = action.payload
                break
            case ActionType.SET_NEW_USER_EMAIL:
                draft.newUserData.email = action.payload
                break
            case ActionType.SET_NEW_USER_ROLES:
                action.payload.value
                    ? draft.newUserData.roles.push(action.payload.role)
                    : (draft.newUserData.roles = draft.newUserData.roles.filter(
                          (role) => role !== action.payload.role,
                      ))
                break
            case ActionType.SET_NEW_USER_SUBSCRIPTION:
                draft.newUserData.subscription = action.payload
                break
            case ActionType.SET_NEW_USER_EXTERNAL:
                draft.newUserData.external = action.payload
                break
            case ActionType.NEW_USER_ADDED:
                draft.newUserData = DEFAULT_USER_MANAGEMENT_STATE.newUserData
                break
            case ActionType.SET_SAVE_PROCESSING_STATUS:
                draft.isSaveProcessing = action.payload
                break
            case ActionType.VIEW_AS_EXTERNAL:
                draft.viewAsExternal = action.payload
                draft.activeUserId = null
                break
            case ActionType.TOGGLE_DETAIL_EXPANDED:
                if (!isEqual(draft.detailExpanded, action.payload)) {
                    draft.detailExpanded = action.payload
                }
                break
            case ActionType.TOGGLE_ALERT_DETAIL_EDIT:
                draft.currentSelectedAlertId = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_USER_MANAGEMENT_STATE
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
