import {useVesselConfig} from '../../contexts/use-vessel-config'
import {DataInput} from '../data-cell/data-input'

export function DataCellUsbDevices(): JSX.Element {
    const {data, initialData, displayData, changeUsbDevices} = useVesselConfig()
    const changed = data.usbDevices?.toString() !== initialData.usbDevices?.toString()
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault()
        changeUsbDevices(e.target.value.split(','))
    }
    return (
        <DataInput
            label="USB Devices"
            initialValue={displayData.usbDevices}
            onChangeHandler={onChangeHandler}
            changed={changed}
        />
    )
}
