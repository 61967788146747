import * as Styled from './_styled/contact-soc.styled'
import {useDimensions} from '../../../contexts/dimensions/use-dimensions'
import {SubmitArea} from './submit-area/submit-area'
import CLOSE_ICON from '../../../@assets/icons/close-panel.svg'
import {Requirements} from './requirements'
import {Information} from './information'
import {DataRange} from './data-range/data-range'
import {Purpose} from './purpose/purpose'
import {Reference} from './reference'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {isProcessingSaveSelector} from '../../../store/state/extended-data-request/selectors'
import {ProcessingStatus} from '../../../store/state/extended-data-request/state'
import {SaveInProgress} from './data-area/save-in-progress'
import {ChangeSaved} from './data-area/change-saved'
import {ChangeNotSaved} from './data-area/change-not-saved'
import {useDispatch} from 'react-redux'
import {closeModal} from '../../../store/state/extended-data-request/action-creators'

export function ContactSOC(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useDispatch()
    const isProcessingSave = useTypedSelector(isProcessingSaveSelector)

    return (
        <Styled.Section onClick={(e) => e.stopPropagation()} width={width} id="contact-soc-section">
            <Styled.Header id="contact-soc-Header">
                <Styled.Title id="contact-soc-Title">
                    Contact SOC - Extended Data Request
                </Styled.Title>
                <Styled.CloseImage
                    src={CLOSE_ICON}
                    onClick={() => {
                        dispatch(closeModal())
                    }}
                    id="close-details-icon"
                />
            </Styled.Header>
            {isProcessingSave === ProcessingStatus.PROCESSING && <SaveInProgress />}
            {isProcessingSave === ProcessingStatus.PROCESSED && <ChangeSaved />}
            {isProcessingSave === ProcessingStatus.FAILED && <ChangeNotSaved />}
            {isProcessingSave === ProcessingStatus.NOT_PROCESSING && (
                <>
                    <Styled.Content id="contact-soc-Content">
                        <Information />
                        <DataRange />
                        <Requirements />
                        <Purpose />
                        <Reference />
                    </Styled.Content>
                    <Styled.Actions id="contact-soc-Actions">
                        <SubmitArea />
                    </Styled.Actions>
                </>
            )}
        </Styled.Section>
    )
}
