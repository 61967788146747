import {
    IconInventory,
    InventoryItemWrapper,
    InventoryItemsWrapper,
    NumberInventory,
} from './inventory-items.styled'
import ASSETS from '../../../icons/assets_icon.svg'

interface MissingNetworkItemsProps {
    totalMissingAssets: number | undefined
    identifier: string
}
export function MissingNetworkItems({
    totalMissingAssets,
    identifier,
}: MissingNetworkItemsProps): JSX.Element {
    return (
        <InventoryItemsWrapper
            id={`missing-inventory-item-${identifier}-totalMissingItems-${totalMissingAssets}-wrapper`}
        >
            {totalMissingAssets && (
                <InventoryItemWrapper>
                    <NumberInventory
                        newItems={true}
                        id={`missing-inventory-item-${identifier}-totalMissingItems-${totalMissingAssets}`}
                    >
                        {totalMissingAssets}
                    </NumberInventory>
                    <IconInventory
                        stateOfItems="total"
                        src={ASSETS}
                        id={`missing-inventory-item-${identifier}-totalMissingItems-${totalMissingAssets}-icon`}
                    />
                </InventoryItemWrapper>
            )}
        </InventoryItemsWrapper>
    )
}
