/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useRef, TextareaHTMLAttributes, useEffect} from 'react'
import * as Styles from './add-note.styled'

export function CommentEntry(props: TextareaHTMLAttributes<HTMLTextAreaElement>): JSX.Element {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        textAreaRef.current!.style.height = '0px'
        const scrollHeight = textAreaRef.current!.scrollHeight
        textAreaRef.current!.style.height = scrollHeight + 'px'
    }, [props.value])

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event)
        }
    }

    return (
        <Styles.TextAreaWrapper>
            <Styles.TextArea
                {...props}
                ref={textAreaRef}
                rows={1}
                onChange={onChangeHandler}
                id="comment-entry-text-area"
            >
                {props.value}
            </Styles.TextArea>
        </Styles.TextAreaWrapper>
    )
}
