import {LoadingSpinner} from '../../../loading/loading'
import * as Styled from './data-area.styled'
export function SaveInProgress(): JSX.Element {
    return (
        <>
            <LoadingSpinner size={60} />
            <Styled.Text>Saving...</Styled.Text>
        </>
    )
}
