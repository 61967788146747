import {ProcessingStatus} from '../../../../values/processing-save-state-enum'
import {ActionType} from './action-type'
import * as Actions from './actions'

export function setFileValue(value: File | null): Actions.SetFileValueAction {
    return {type: ActionType.SET_FILE_VALUE, payload: value}
}

export function setSaveProcessingStatus(
    value: ProcessingStatus,
): Actions.SetIsProcessingStatusAction {
    return {
        type: ActionType.SET_IS_PROCESSING_STATUS,
        payload: value,
    }
}
export function setErrorMessageValue(value: string): Actions.SetErrorMessageAction {
    return {type: ActionType.SET_ERROR_MESSAGE, payload: value}
}
