import styled from 'styled-components'
import {mediumSmallFont} from '../../../../../theme/font-styles'
import {spacing} from '../../../../../theme/spacing'

export const BREAK_POINT_CARD_REDUCED_TEXT = 500

function getValueWidth(width: number): string {
    if (width > 750) {
        return '240px'
    }
    if (width > 650) {
        return '210px'
    }
    return '160px'
}

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea: string
    centered?: boolean
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
    grid-area: ${(props) => props.gridArea};
`

export const Label = styled.label`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    padding-right: ${spacing(1)};
    width: 130px;
    margin: 0;
`

interface ChildrenProps {
    gridArea: string
    width: number
}

export const Children = styled.div<ChildrenProps>`
    ${mediumSmallFont()}
    width: ${(props) => getValueWidth(props.width)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
