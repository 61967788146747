import useTypedSelector from '../../../../../../../hooks/use-typed-selector'
import {incidentTypesSelector} from '../../../../../../../store/state/incident-types/selectors'
import {IncidentType} from '../../../../../../incidents-v3/contexts/types/incident-type'
import {Panel} from '../_styled/panel-section.styled'
import {UserDropdown} from '../user-dropdown/user-dropdown'
import {DataInput} from '../data-input/data-input'
import {DataReadOnly} from '../data-input/data-input-read-only'
import {IncidentTypeSeveritySection} from '../incident-type-severity-section/incident-type-severity-section'
import {IncidentTypeSeverityTitle} from '../incident-type-severity-section/incident-type-severity-title'
import {IncidentPolicyDetailToggle} from '../incident-type-severity-section/incident-policy-detail-toggle'
import * as Styled from './details-section.styled'
import {useIncidentsPolicy} from '../../../../../contexts/incidents-policy/use-incidents-policy'
import {CcEmailInput} from '../data-input/cc-email-input'
import {isEqual} from 'lodash'

function compareRecord(a: IncidentType, b: IncidentType): number {
    return a.displayName.trim().localeCompare(b.displayName.trim())
}

export function DetailsSection(): JSX.Element {
    const {
        activeIncidentPolicyData,
        initialIncidentPolicyData,
        displayIncidentPolicyData,
        changePolicyName,
        changeCcVesselEmailsTo,
        changeReassignUser,
        changeAutoAssignUser,
        enableAutoAssign,
    } = useIncidentsPolicy()
    const incidentTypes = useTypedSelector(incidentTypesSelector).slice().sort(compareRecord)
    const globalDisabled = activeIncidentPolicyData.defaultPolicy

    return (
        <Panel>
            <Styled.DetailsLayout>
                <Styled.Details>
                    {globalDisabled ? (
                        <DataReadOnly label="Policy Name" value={displayIncidentPolicyData.name} />
                    ) : (
                        <DataInput
                            label="Policy Name"
                            initialValue={displayIncidentPolicyData.name}
                            setChange={changePolicyName}
                            changed={
                                activeIncidentPolicyData?.name !== initialIncidentPolicyData.name
                            }
                        />
                    )}
                </Styled.Details>
                <Styled.Details>
                    <IncidentPolicyDetailToggle
                        label="Auto-assign incidents"
                        onValue={activeIncidentPolicyData.enabledForIncidentManagement}
                        setChange={enableAutoAssign}
                    />
                </Styled.Details>
                <Styled.EditableArea
                    disabled={!activeIncidentPolicyData.enabledForIncidentManagement}
                >
                    <Styled.TypeWrapper>
                        <Styled.Label>Auto-assign incidents to vessel:</Styled.Label>
                        <IncidentTypeSeverityTitle />
                        {incidentTypes.map((type, index) => (
                            <IncidentTypeSeveritySection key={index} incidentType={type} />
                        ))}
                    </Styled.TypeWrapper>
                    <Styled.Details>
                        <CcEmailInput
                            label="When auto-assign, always copy in (cc)"
                            isChanged={
                                !isEqual(
                                    activeIncidentPolicyData?.ccVesselEmailsTo,
                                    initialIncidentPolicyData.ccVesselEmailsTo,
                                )
                            }
                            setChange={changeCcVesselEmailsTo}
                            ccEmails={displayIncidentPolicyData.ccVesselEmailsTo}
                        />
                    </Styled.Details>
                    <Styled.Details>
                        <UserDropdown
                            label="After return from vessel, reassign to"
                            initialValue={displayIncidentPolicyData.reassignToUser}
                            setChange={changeReassignUser}
                            changed={
                                activeIncidentPolicyData?.reassignToUser !==
                                initialIncidentPolicyData.reassignToUser
                            }
                        />
                    </Styled.Details>
                    <Styled.Details>
                        <UserDropdown
                            label="If not assigned to vessel, then automatically assign to"
                            initialValue={displayIncidentPolicyData.otherwiseAssignToUser}
                            setChange={changeAutoAssignUser}
                            changed={
                                activeIncidentPolicyData?.otherwiseAssignToUser !==
                                initialIncidentPolicyData.otherwiseAssignToUser
                            }
                        />
                    </Styled.Details>
                </Styled.EditableArea>
            </Styled.DetailsLayout>
        </Panel>
    )
}
