import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'

export const ContentWrapper = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    padding-right: ${spacing(4)};
    justify-content: center;
    align-items: center;
    flex: 1;
`

export const Container = styled.div`
    background: ${(props) => props.theme.incidents.dataRow.background};
    width: 360px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
`
