import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
interface DataCellProps {
    gridArea?: string
    noBorder?: boolean
}

export const DataCell = styled.div<DataCellProps>`
    flex: 1;
    grid-area: ${(props) => props.gridArea};
    border-right: ${(props) =>
        props.noBorder ? '' : `1px solid ${props.theme.reports.dataRow.separator};`};
    padding: ${spacing(1)} 0 ${spacing(1)} 0;
`
