import {GuidType} from '../../../values/generic-type-defintions'
import AppState from '../../types/app-state'
import {CreationStatus} from './state'

export const isNoteCreationSelector = (state: AppState): boolean =>
    state.unsavedIncidentNoteAlertModal.newNoteCreationStatus === CreationStatus.ENTER_DETAILS ||
    state.unsavedIncidentNoteAlertModal.newNoteCreationStatus === CreationStatus.REVIEW ||
    state.unsavedIncidentNoteAlertModal.editNoteCreationStatus === CreationStatus.ENTER_DETAILS ||
    state.unsavedIncidentNoteAlertModal.editNoteCreationStatus === CreationStatus.REVIEW
export const newNoteToEditSelector = (state: AppState): string =>
    state.unsavedIncidentNoteAlertModal.newNote
export const noteToEditSelector = (state: AppState): Map<GuidType, string> =>
    state.unsavedIncidentNoteAlertModal.editNoteMap
export const noteToEditStatusSelector = (state: AppState): boolean =>
    state.unsavedIncidentNoteAlertModal.editNoteCreationStatus === CreationStatus.REVIEW
export const showModalSelector = (state: AppState): boolean =>
    state.unsavedIncidentNoteAlertModal.showModal &&
    (state.unsavedIncidentNoteAlertModal.newNoteCreationStatus === CreationStatus.ENTER_DETAILS ||
        state.unsavedIncidentNoteAlertModal.newNoteCreationStatus === CreationStatus.REVIEW ||
        state.unsavedIncidentNoteAlertModal.editNoteCreationStatus ===
            CreationStatus.ENTER_DETAILS ||
        state.unsavedIncidentNoteAlertModal.editNoteCreationStatus === CreationStatus.REVIEW)
export const pendingNavigationSelector = (state: AppState): string | null =>
    state.unsavedIncidentNoteAlertModal.pendingNavigation
