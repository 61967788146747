import {AssetFilters} from './asset-filters/asset-filters'
import {VesselFilters} from '../../../../../components/vessel-filters/vessel-filters'
import {VesselOverview} from './vessel-overview/vessel-overview'
import {ResetFilterButton} from './reset-filter-button'
import * as Styles from './filter-bar.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isLoadingMyVesselsFiltersSelector} from '../../../../../store/state/my-vessels-filters/selectors'
import {LoadingSpinner} from '../../../../../components/loading/loading'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function FilterBar(): JSX.Element {
    if (useTypedSelector(isLoadingMyVesselsFiltersSelector)) {
        return (
            <Styles.Loading>
                <LoadingSpinner />
            </Styles.Loading>
        )
    }

    return (
        <Styles.FilterBar id={`${PageType.MY_VESSELS}_filter-bar`}>
            <VesselOverview />
            <AssetFilters />
            <VesselFilters pageType={PageType.MY_VESSELS} />
            <ResetFilterButton />
        </Styles.FilterBar>
    )
}
