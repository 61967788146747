import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const BREAK_POINT_CARD_REDUCED_TEXT = 500

interface CardDataCellProps {
    titleOnSameLine?: boolean
    gridArea: string
}

export const CardDataCell = styled.div<CardDataCellProps>`
    ${(props) => (props.titleOnSameLine ? 'display: flex;' : '')}
    width: 100%;
    align-items: center;
    grid-area: ${(props) => props.gridArea};
    ${(props) =>
        props.gridArea === 'TRUST-DEVICE' ? 'display: flex; justify-content: flex-end;' : ''}
`

export const Label = styled.label`
    ${mediumSmallFont()}
    font-weight: ${(props) => props.theme.font.weight.light};
    align-items: center;
    padding-right: ${spacing(1)};
    width: 110px;
    margin: 0;
`
