import moment from 'moment'
import {createSelector} from 'reselect'
import {formatDate} from '../../../../../helpers/formatting'
import {latestUnknownAssetSelector} from '../../../../../store/state/latest-unknown-asset/selectors'
import {LatestAssetData} from '../../../../../store/state/latest-unknown-asset/types/latest-unknown-asset'
import {
    activeLocationSelector,
    locationMapSelector,
} from '../../../../../store/state/locations/selectors'
import {
    getLocationDescription,
    isActiveLocation,
    Location,
    LocationMap,
} from '../../../../../store/state/locations/state'
import {Level} from '../../../../../values/location-level-enum'
import {WidgetOutputModel} from '../widget.model'

export const latestUnknownAssetDataReselector = createSelector(
    latestUnknownAssetSelector,
    locationMapSelector,
    activeLocationSelector,
    (
        unknownAssetData: LatestAssetData | null,
        locationMap: LocationMap,
        activeLocation: Location,
    ): WidgetOutputModel | null => {
        if (!unknownAssetData || !unknownAssetData.latestAsset) {
            return null
        }
        const locationSelected = isActiveLocation(activeLocation)

        return {
            level: locationSelected ? Level.VESSEL : Level.FLEET,
            firstDetectedHumanReadable: unknownAssetData.latestAsset.firstSeen
                ? moment(unknownAssetData.latestAsset.firstSeen).fromNow()
                : '',
            firstDetectedTimeStamp: unknownAssetData.latestAsset.firstSeen
                ? formatDate(unknownAssetData.latestAsset.firstSeen)
                : '',
            vessel: locationSelected
                ? activeLocation.description
                : getLocationDescription(locationMap, unknownAssetData.latestAsset.location),
            ipAddress: unknownAssetData.latestAsset.mainIpAddress
                ? unknownAssetData.latestAsset.mainIpAddress
                : '',
            recentlyDiscoveredAssets: {
                past24Hours: unknownAssetData.numberOfAssetsByDuration.PT24H
                    ? unknownAssetData.numberOfAssetsByDuration.PT24H
                    : 0,
                past7Days: unknownAssetData.numberOfAssetsByDuration.PT168H
                    ? unknownAssetData.numberOfAssetsByDuration.PT168H
                    : 0,
                past30Days: unknownAssetData.numberOfAssetsByDuration.PT720H
                    ? unknownAssetData.numberOfAssetsByDuration.PT720H
                    : 0,
            },
            identity: unknownAssetData.latestAsset.identity
                ? unknownAssetData.latestAsset.identity
                : undefined,
        }
    },
)
