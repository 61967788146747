import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {mediumSmallFont} from '../../../../theme/font-styles'

export const StyledWrapper = styled.div`
    display: flex;
    column-gap: ${spacing(1)};
    align-items: center;
    ${mediumSmallFont()}
    color: ${(props) => props.theme.colors.link.primary};
    cursor: pointer;
`
export const StyledImage = styled.img`
    width: 25px;
    height: 25px;
`
