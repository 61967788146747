import {Filter} from 'react-feather'
import {usePagedUSBDevices} from '../../../contexts/use-paged-usb-devices'
import * as Styles from './filter-bar-button.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {isActiveFilterSelector} from '../../../../../store/state/usb-inventory-filter/selectors'
import {activeVesselFilterSelector} from '../../../../../store/state/vessel-filter/selectors'
import {removeQueryParam} from '../../../../../helpers/navigation'
import {USB_DEVICES_DETAILS} from '../../data-helpers'
import {PageType} from '../../../../../components/vessel-filters/data-helper'

export function FilterBarButton(): JSX.Element {
    const {displayFilterBar, showFilterBar, setIdForUSBDetailsModal} = usePagedUSBDevices()
    const activeFilter = useTypedSelector(isActiveFilterSelector)
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)

    return (
        <Styles.Container
            onClick={(e) => {
                e.preventDefault()
                removeQueryParam(USB_DEVICES_DETAILS)
                setIdForUSBDetailsModal(null)
                displayFilterBar(!showFilterBar)
            }}
            id={`${PageType.USB_DEVICES}_filter-button-container`}
        >
            <Styles.SearchIcon
                active={activeFilter || activeVesselFilter}
                id={`${PageType.USB_DEVICES}_filter-button-icon`}
            >
                <Filter height={16} width={16} />
            </Styles.SearchIcon>
            <Styles.TextWrapper
                active={activeFilter || activeVesselFilter}
                id={`${PageType.USB_DEVICES}_filter-button-text`}
            >
                <Styles.Text>Filter</Styles.Text>
            </Styles.TextWrapper>
        </Styles.Container>
    )
}
