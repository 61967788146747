import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {PolicyListCard} from './policy-list-card'
import * as Styled from './_styled/policy-management-cards.styled'
import {usePolicyManagement} from '../../contexts/policy-management/use-policy-management'
import {AllPolicyData} from '../../contexts/policy-management/type/policy-management-state'
import {useMemo} from 'react'
import {AddNewPolicyDetailsExpanded} from './add-new-policy-details-expanded'

interface UserListCardsProps {
    policies: AllPolicyData[]
}

export function PolicyListCards({policies}: UserListCardsProps): JSX.Element {
    const {width} = useDimensions()
    const {showPolicyModal, selectedPolicyId} = usePolicyManagement()

    const displayAddNewPolicyDetailsModal = useMemo(() => {
        return !selectedPolicyId && showPolicyModal
    }, [selectedPolicyId, showPolicyModal])

    return (
        <Styled.CardArea width={width}>
            {displayAddNewPolicyDetailsModal && <AddNewPolicyDetailsExpanded />}
            {policies.map((policy) => (
                <PolicyListCard key={policy.identity} policy={policy} />
            ))}
        </Styled.CardArea>
    )
}
