import * as Styled from './_styled/tag-select.styled'
import {TagSearch} from './tag-search'

export function TagSelect(): JSX.Element {
    return (
        <Styled.Wrapper id="selected-tags-wrapper">
            <Styled.Label>Selected Tags</Styled.Label>
            <TagSearch />
        </Styled.Wrapper>
    )
}
