import {UserManagementDetailType} from '../../../../context/type/user-management-state'
import {useUserManagement} from '../../../../context/use-user-management'
import {Panel} from '../_styled/panel-section.styled'
import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {SavedFiltersAlertsContentReadOnly} from './saved-filters-alerts-content-read-only'

export function SavedFiltersAlertsReadOnly(): JSX.Element {
    const {detailExpanded} = useUserManagement()
    return (
        <Panel>
            <ExpandPanelButton headingRow={UserManagementDetailType.SavedFiltersAlerts} />
            {detailExpanded.savedFiltersAlertsExpanded && <SavedFiltersAlertsContentReadOnly />}
        </Panel>
    )
}
