import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

export const Container = styled.div`
    display: flex;
    height: 30px;
`

export const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(props) => props.theme.colors.link.primary};
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-left: 0;
    border-radius: 0 ${spacing(1)} ${spacing(1)} 0;
`

export const SearchInput = styled.input`
    ${mediumSmallFont()};
    width: 105px;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.colors.border.active};
    border-radius: ${spacing(1)} 0 0 ${spacing(1)};
    margin: 0;
    padding: 4px;
    height: 30px;
`
