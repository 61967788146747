import createCachedSelector from 're-reselect'
import {latestTimestampSelector} from '../../../../../../store/state/latest-event-timestamps/selectors'
import {nodesSelector} from '../../../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../../../store/state/sfm-scores/selectors'
import {threatMeasuresSelector} from '../../../../../../store/state/threat-measures/selectors'
import AppState from '../../../../../../store/types/app-state'
import {GuidType} from '../../../../../../values/generic-type-defintions'
import {populateNodeRecord} from '../../../../../../values/nodes/reselectors/helpers'

export const assetDetailsCachedReselector = createCachedSelector(
    [
        nodesSelector,
        sfmScoresSelector,
        latestTimestampSelector,
        threatMeasuresSelector,
        (_state: AppState, assetId: GuidType) => assetId,
    ],
    (nodes, sfmScores, latestEventTimestamps, threatMeasures, assetId) => {
        const nodeData = nodes.get(assetId)
        if (!nodeData) {
            throw Error('Can not find node')
        }
        return populateNodeRecord(nodeData, sfmScores, latestEventTimestamps, threatMeasures)
    },
)((_state, assetId) => assetId)
